import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CategoryService } from './category.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisCrudOptions } from '../../shared/constants/ellipsis-options';
import { categoryEllipsisHeaderOptions } from '../catalog.constant';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RolePermissionService } from '../../common/role-permission.service';
@Component({
  selector: 'category',
  templateUrl: 'category.template.html',
  styleUrls: ['../catalog.scss'],
  providers: [CategoryService, MessageService, FormFieldControlService, ContractFormFieldService],
})
export class CategoryComponent implements OnInit, OnDestroy {
  dataList: any[] = [];
  @Input() formFields: FormField<string>[] = [];
  form = new FormGroup({});
  cols: any[];
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = categoryEllipsisHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;

  header: string;
  submitButton: string;
  constructor(
    private categoryService: CategoryService,
    private contractService: ContractFormFieldService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formFieldService: FormFieldControlService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  @Input() searchParams: any = {};
  querySubscription: Subscription;
  querySubscriptions: Subscription[] = [];
  isLoading = true;
  formData = null;
  disableLoadMore = false;
  displayAdd: boolean;
  addNewCat: boolean;
  lastOffsetVal = 0;
  offsetLimit = 50;

  ngOnInit() {
    this.cols = [
      { field: 'code', header: 'TIER CODE', type: 'text' },
      { field: 'description', header: 'TIER DESCRIPTION', type: 'text' },
      { field: 'parentDescription', header: 'CATEGORY', type: 'text' },
    ];
    this.getCategorySearchData();
    this.querySubscriptions.push(
      this.contractService.getCategoryUpdateFields().subscribe((formModalInfo) => {
        this.formFields = formModalInfo;
        this.form = this.formFieldService.toFormGroup(this.formFields);
      }),
    );
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Categories',
      category: 'Catalogs',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Categories',
      category: 'Catalogs',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getCategorySearchData(reload: boolean = false) {
    this.isLoading = true;
    if (this.searchParams === 'Category') {
      this.searchParams = {};
    }
    const categorySearchParam = this.searchParams.category
      ? this.searchParams.category.map((x) => x.key).join()
      : null;

    if (reload) {
      this.lastOffsetVal = 0;
      this.dataList = [];
    }
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.searchParams.azureUserId = this.azureService.accountId;

    this.lastOffsetVal += this.offsetLimit;
    this.querySubscription = this.categoryService
      .getCategories({
        ...this.searchParams,
        category: categorySearchParam,
      })
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        data.getCategorySearch.map((category) => {
          const dataObj = {
            code: category.tierCode,
            description: category.tierDescription,
            parentDescription: category.category,
            categoriesId: category.categoriesId,
          };
          if (this.dataList.length === 0) {
            this.dataList.push(dataObj);
          } else {
            const index = this.dataList.findIndex((e) => e.categoriesId === category.categoriesId);
            if (index === -1) {
              this.dataList.push(dataObj);
            } else {
              this.dataList[index] = dataObj;
            }
          }
        });
        this.formFields[3].options = data.getCategoryDescriptionList.map((x, index) => ({
          key: x,
        }));
      });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    if (event.value === 'delete') {
      this.deleteCategory();
    } else {
      this.updateCategory();
    }
  }

  updateCategory() {
    this.header = this.selectedItem === 'item' ? 'Create Category' : 'Update Category';
    this.submitButton = this.selectedItem === 'item' ? 'Add' : 'Update';
    this.displayAdd = true;
    this.form.patchValue({
      category: { key: this.selectedItem.parentDescription },
      tierCode: this.selectedItem.code,
      tierDescription: this.selectedItem.description,
    });
  }

  changeEventDetection(event, key) {
    if (key === 'addNewCategory') {
      this.addNewCat = event;
    }
  }

  deleteCategory() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Menu Type?`,
      accept: () => {
        const params = {
          categoriesIdOrigninal: this.selectedItem.categoriesId,
          tierCode: this.selectedItem.code,
          tierDescription: this.selectedItem.description,
          category: this.selectedItem.parentDescription,
          insertUserName: this.azureService.accountId,
          isDeleted: true,
        };
        this.querySubscription = this.categoryService.createUpdateCategory(params).subscribe(
          (data) => {
            this.showToastStatus(true);
            this.getCategorySearchData(true);
          },
          () => {
            this.showToastStatus(false);
          },
        );
      },
    });
  }

  showToastStatus(success: boolean) {
    if (success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Success.`,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed`,
      });
    }
  }

  onRowEdit() {
    this.updateCategory();
  }

  onSubmit() {
    if (this.isLoading) {
      return;
    } // restrict multiple save
    this.isLoading = true;
    const payload: any = this.form.getRawValue();
    if (!this.validateData(payload)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please fill the required fields.`,
      });
      this.isLoading = false;
      return;
    }
    const params = {
      categoriesIdOrigninal: this.selectedItem.categoriesId,
      tierCode: payload.tierCode,
      tierDescription: payload.tierDescription,
      category: this.addNewCat ? payload.newCategory.toUpperCase() : payload.category.key,
      insertUserName: this.azureService.accountId,
      isDeleted: false,
    };
    this.querySubscription = this.categoryService.createUpdateCategory(params).subscribe(
      ({ data }: any) => {
        if (data.createUpdateCategories) {
          this.showToastStatus(true);
          this.form.reset();
          this.displayAdd = false;
          this.getCategorySearchData(true);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Entry Already Exists`,
          });
          this.isLoading = false;
        }
      },
      () => {
        this.showToastStatus(false);
        this.isLoading = false;
      },
    );
  }

  validateData(payload) {
    if (payload.tierCode && ((this.addNewCat && payload.newCategory) || payload.category.key)) {
      return true;
    }
    return false;
  }

  onCancel() {
    this.form.reset();
    this.isLoading = false;
    this.displayAdd = false;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
