import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { ClaimTypeService } from '../claim.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { MessageService } from 'primeng/api';
import { generalDateFormatter } from '../../shared/utilities/date-utilities';
import { copy } from '../../shared/utilities/common-utilities';
import { ExportService } from '../../services/export.service';

@Component({
  selector: 'process-claim',
  templateUrl: 'process-claim.template.html',
  styleUrls: ['process-claim.scss'],
  providers: [ClaimTypeService, FormFieldControlService, MessageService],
})
export class ProcessClaimComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  formFields: FormField<string>[] = [];
  cols: any[];
  dataList: any[];
  btnStatus: any = buttonStatus;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayImport: boolean;
  isLoading: boolean;
  lastOffsetVal = 0;
  offsetLimit = 2500; // 2500 should load all most of the time. If larger, need to click 'Load More' because Lambda data response limit.
  disableLoadMore: boolean;
  selectedClaims: any;
  form: FormGroup;
  selectedColumns: any[];
  totalRecords = 0;
  querySubscription: any;
  constructor(
    private messageService: MessageService,
    private claimService: ClaimTypeService,
    private cdr: ChangeDetectorRef,
    private qcs: FormFieldControlService,
    private exportService: ExportService
  ) {
    super();
    this.displayImport = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();

    this.claimService.getProcessClaimModalFields().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
    });
    this.form = this.qcs.toFormGroup(this.formFields);

    this.cols = [
      { field: 'claimStatusDescription', header: 'CLAIM STATUS', type: 'text' },
      { field: 'serviceOrderNumber', header: 'SERVICE ORDER #', type: 'text' },
      { field: 'insCode', header: 'INS. CODE', type: 'text' },
      {
        field: 'approvedAmount',
        header: 'TOTAL AMOUNT APPROVED FOR PAYMENT',
        type: 'text',
        isAmount: true,
      },
      { field: 'invoiceNumber', header: 'INVOICE NUMBER', type: 'text' },
      { field: 'payeeType', header: 'PAYEE TYPE', type: 'text' },
      { field: 'number', header: 'PAYEE NUMBER', type: 'text' },
      { field: 'name', header: 'PAYEE NAME', type: 'text' },
      { field: 'servicerAddress', header: 'PAYEE ADDRESS', type: 'text' },
      { field: 'servicerAddress2', header: 'PAYEE ADDRESS 2', type: 'text' },
      { field: 'servicerCity', header: 'PAYEE CITY', type: 'text' },
      { field: 'servicerState', header: 'PAYEE STATE', type: 'text' },
      { field: 'servicerZipcode', header: 'PAYEE ZIPCODE', type: 'text' },
      { field: 'fedTax', header: 'SERVICER TAX ID', type: 'text' },
      { field: 'claimPayMethod', header: 'CLAIM PAY METHOD', type: 'text' },
      { field: 'buyingGroupNumber', header: 'BUYING GROUP NUMBER', type: 'text' },
      { field: 'dealerGroupNumber', header: 'DEALER NUMBER', type: 'text' },
      { field: 'dealerGroupName', header: 'DEALER NAME', type: 'text' },
      { field: 'storeLocationNumber', header: 'DEALER LOCATION', type: 'text' },
      { field: 'sUniqueId', header: 'UNIQUE ID', type: 'text' },
      { field: 'customerFirstName', header: 'CUSTOMER FIRST NAME', type: 'text' },
      { field: 'customerLastName', header: 'CUSTOMER LAST NAME', type: 'text' },
      { field: 'customerAddress', header: 'CUSTOMER ADDRESS', type: 'text' },
      { field: 'customerAddress2', header: 'CUSTOMER ADDRESS 2', type: 'text' },
      { field: 'customerCity', header: 'CUSTOMER CITY', type: 'text' },
      { field: 'customerState', header: 'CUSTOMER STATE', type: 'text' },
      { field: 'customerZipcode', header: 'CUSTOMER ZIPCODE', type: 'text' },
      { field: 'productCategory', header: 'CATEGORY', type: 'text' },
      { field: 'tierDescription', header: 'TIER', type: 'text' },
      { field: 'productSubCategory', header: 'SUBCATEGORY', type: 'text' },
      { field: 'productManufacturerName', header: 'MANUFACTURER', type: 'text' },
      { field: 'productModelNumber', header: 'MODEL', type: 'text' },
      { field: 'productSerialNumber', header: 'SERIAL NUMBER', type: 'text' },
      { field: 'productPurchasePrice', header: 'PRODUCT PURCHASE PRICE', type: 'text' },
      { field: '_1', header: 'ADJUSTED PRODUCT VALUE', type: 'text' },
      { field: 'productPurchaseDate', header: 'PRODUCT DATE OF PURCHASE', type: 'text' },
      { field: 'planDateofPurchase', header: 'PLAN DATE OF PURCHASE', type: 'text' },
      { field: 'failureDate', header: 'FAILURE/LOSS DATE', type: 'text' },
      { field: 'failureReportedDate', header: 'REPORTED DATE', type: 'text' },
      { field: 'failure', header: 'FAILURE', type: 'text' },
      { field: 'correctiveAction', header: 'CORRECTIVE ACTION', type: 'text' },
      { field: 'dateOfInvoice', header: 'DATE OF INVOICE', type: 'text' },
      { field: 'dateOfRepair', header: 'DATE OF REPAIR', type: 'text' },
      { field: 'approvedForPaymentDate', header: 'CLAIM APPROVED FOR PAYMENT DATE', type: 'text' },
      { field: 'approvedForPaymentBy', header: 'CLAIM APPROVED FOR PAYMENT BY', type: 'text' },
      { field: 'coverageCode', header: 'SKU/COVERAGE CODE', type: 'text' },
      { field: 'coverageDescription', header: 'SKU/COVERAGE DESCRIPTION', type: 'text' },
      { field: '_3', header: 'MANUFACTURE WARRANTY', type: 'text' },
      { field: 'failureDescription', header: 'FAILURE DESCRIPTION', type: 'text' },
      {
        field: 'correctiveActionDescription',
        header: 'CORRECTIVE ACTION DESCRIPTION',
        type: 'text',
      },
      { field: 'insuranceCarrier', header: 'CARRIER', type: 'text' },
      { field: 'keepHeader1', header: 'ACCUMULATIVE CLAIMS', type: 'text' },
      { field: 'keepHeader2', header: 'CLAIMS MINUS PURCHASE', type: 'text' },
      { field: 'keepHeader3', header: 'LAST PRE APPROVED AMOUNT', type: 'text' },
      { field: 'keepHeader4', header: 'SERVICER ACCOUNT NUMBER', type: 'text' },
      { field: 'keepHeader5', header: 'GL', type: 'text' },
      { field: 'keepHeader6', header: 'SAGE VENDOR NO', type: 'text' },
      { field: 'keepHeader7', header: 'DT BILL DATE', type: 'text' },
    ];
    this.selectedColumns = this.cols.slice(0, 6);
    this.getClaimProcessSearchResult();
  }

  onYes() {
    this.displayImport = false;
    this.cdr.detectChanges();
  }

  remit() {
    if (this.selectedClaims) {
      let totalPayments = 0.0;
      let totalPaymentsStr = '0.00';
      this.selectedClaims.forEach((element) => {
        const approvedAmount = element.approvedAmount ? element.approvedAmount : 0.0;
        totalPayments += parseFloat(approvedAmount);
      });
      if (totalPayments && totalPayments > 0) {
        totalPaymentsStr = (Math.round(totalPayments * 100) / 100).toFixed(2);
      }
      this.form.patchValue({
        claimSelected: this.selectedClaims && this.selectedClaims.length,
        totalPayments: totalPaymentsStr,
      });
      this.displayImport = true;
      this.cdr.detectChanges();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select atleast on claim.',
      });
    }
  }

  getClaimProcessSearchResult() {
    this.isLoading = true;

    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string'
        ? this.searchParams
        : {
          limit: this.offsetLimit,
          offset: 0,
          status: { key: 'APPROVED FOR PAYMENT', value: 'APPROVED FOR PAYMENT' },
        };

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;

    this.lastOffsetVal += this.offsetLimit;

    this.querySubscription = this.claimService
      .getClaimProcessSearchResult(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const rawModifedData = copy(data.getClaimProcessSearchResults);
          // Perform field formatting
          const modifedData = [];
          for (const singleRow of rawModifedData) {
            singleRow.productPurchaseDate = generalDateFormatter(singleRow.productPurchaseDate);
            singleRow.failureDate = generalDateFormatter(singleRow.failureDate);
            singleRow.failureReportedDate = generalDateFormatter(singleRow.failureReportedDate);
            singleRow.planDateofPurchase = generalDateFormatter(singleRow.planDateofPurchase);
            singleRow.approvedForPaymentDate = generalDateFormatter(
              singleRow.approvedForPaymentDate,
            );
            singleRow.dateOfInvoice = generalDateFormatter(singleRow.dateOfInvoice);
            singleRow.dateOfRepair = generalDateFormatter(singleRow.dateOfRepair);
            modifedData.push(singleRow);
          }
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
          this.totalRecords = this.dataList.length;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  exportToCsv(dt) {
    const orginalSelectedColumns = this.selectedColumns;
    this.selectedColumns = this.cols;
    this.cdr.detectChanges();
    dt.exportToCsv({ selectionOnly: true });
    this.selectedColumns = orginalSelectedColumns;
    this.cdr.detectChanges();
    this.displayImport = false;
  }

  exportToCSV() {

    //Converting the array properties to the desired name
    const records = this.selectedClaims.map(item => {
      const returnValue = {}
      this.cols.forEach(col => {
        returnValue[col.header] = item[col.field]
      })
      return returnValue;
    })

    this.exportService.exportToCsv(records,'claims');
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
