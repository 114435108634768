import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ContactTabService {
  constructor() {}
  getContact(): Observable<any[]> {
    return of([
      {
        id: 1,
        position: 'Billing Contract',
        firstName: 'Thomas',
        lastName: 'John',
        businessPhone: '0123456789',
        fax: '',
        email: 'abcd@gmail.com',
        phoneNumber: '9874561230'
      },
      {
        id: 2,
        position: 'Billing Contract',
        firstName: 'Thomas',
        lastName: 'John',
        businessPhone: '0123456789',
        fax: '',
        email: 'abcd@gmail.com',
        phoneNumber: '9874561230'
      },
      {
        id: 3,
        position: 'Service Manager',
        firstName: 'Thomas',
        lastName: 'John',
        businessPhone: '0123456789',
        fax: '',
        email: 'abcd@gmail.com',
        phoneNumber: '9874561230'
      }
    ]);
  }
}
