import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class UploadService {
  constructor(private httpClient: HttpClient) {}

  /**
   * This function uploads files
   * @param formData: type of FormData and expects form data including files.
   */
  public upload(formData, header) {
    return this.httpClient.post<any>(environment.uploadUrl, formData, { headers: header });
  }
}
