import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { SendEmailModalService, LABEL_MAPPING } from './send-email-modal.service';
import { EmailService } from '../../../services/email.service';
import { MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ServiceOrderService } from '../service-order.service';
import { ServiceOrderDataService } from '../service-order-data.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import moment from 'moment';
import {
  dateTimeFormatter,
  dateTimeFormatterCST,
  getMMDDYYYFormat,
  generalDateFormatter,
  getFormattedTimeRangeForSB,
  getFormattedTimeRangeForSP,
} from '../../../shared/utilities/date-utilities';
import { NoteType } from '../../../shared/constants/enums';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { getKeyByValue, getValueByKey } from '../../../shared/utilities/common-utilities';

const CANNOT_DISPATCH_STATUSES = {
  'APPROVED PAID': true,
  'APPROVED FOR PAYMENT': true,
  'SUBMIT FOR PAYMENT': true,
  'VOIDED CLAIM': true,
};

const NO_CUSTOMER_EMAIL_TEMPLATES = ['TEMPLATE_Dispatch50Back_template1'];

const NOTE_DESC_ATTACHMENT = ['External', 'System'];

const NOTE_TYPE_ATTACHMENT = [38, 40];

@Component({
  selector: 'send-email-modal',
  templateUrl: 'send-email-modal.template.html',
  styleUrls: ['./send-email-modal.scss'],
  providers: [FormFieldControlService, SendEmailModalService, MessageService],
})
export class SendEmailModalComponent implements OnInit, OnChanges, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    // this.emailDisplayDialogChange.emit(this.displayDialog);
  }
  @Output() displayDialogChange: EventEmitter<any> = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displayEmailModal = false;
  isLoading = true;
  validForm = true;
  templateResponse = null;
  querySubscriptions: Subscription[] = [];
  isCustomerDispatched = false;
  servicerEmail = null;
  submitted = false;
  inProgress = false;
  constructor(
    private qcs: FormFieldControlService,
    private sendEmailModalService: SendEmailModalService,
    private emailService: EmailService,
    private messageService: MessageService,
    private chRef: ChangeDetectorRef,
    private serviceOrderService: ServiceOrderService,
    private dataService: ServiceOrderDataService,
    private azureService: AzureLoginService,
  ) {
    this.sendEmailModalService.getSendEmailFields().subscribe((data) => {
      this.formFields = data;
    });

    this.serviceOrderService.onCustomerDispatchChange
      .asObservable()
      .subscribe((isCustomerDispatch) => {
        this.isCustomerDispatched = isCustomerDispatch;
        this.updateForm(this.templateResponse, '');
      });
  }
  ngOnInit() {
    const typeOptions = [];
    const codeOptions = [];
    this.querySubscriptions.push(
      this.sendEmailModalService.getTemplateDetails().subscribe(
        // Disable the Rule Shadowed name: 'data'
        /* tslint:disable */
        ({ data }) => {
          this.isLoading = false;
          this.templateResponse = data;
          this.updateForm(data, '');
        },
        (err) => {
          this.isLoading = false;
          window.alert('Email Template Retrieval Failed. Please try again in a few minutes.');
        },
      ),
    );
  }

  ngOnChanges() {
    if (this.templateResponse) this.updateForm(this.templateResponse, '');
    if (this.dataService.serviceOrderDataModel.servicerInfo) {
      this.servicerEmail = this.dataService.serviceOrderDataModel.servicerInfo.contactInfo.email;
      this.updateEmail(this.servicerEmail);
    } else {
      const serviceProviderValues = this.serviceOrderService.selectedServicerValue;
      if (serviceProviderValues) {
        const servicerProfileId = serviceProviderValues.servicerProfilesIdOriginal;
        if (servicerProfileId) {
          this.isLoading = true;
          this.querySubscriptions.push(
            this.dataService.getServicerInfoByIdSingle(servicerProfileId).subscribe(
              ({ data, loading }: any) => {
                this.isLoading = false;
                const servicer = data.getServicerProfileById;
                if (servicer) {
                  this.servicerEmail = servicer.contactInfo.email;
                  this.updateEmail(this.servicerEmail);
                }
              },
              (err) => {
                this.isLoading = false;
                window.alert(
                  'Service Provider Retrieval Failed. Please try again in a few minutes.',
                );
              },
            ),
          );
        }
      }
    }
  }

  onSubmit() {
    this.inProgress = true;
    this.submitted = true;
    const payload = this.form.getRawValue();
    if (payload.code.value === '' || payload.type.value === '' || payload.toEmailAddress === '') {
      window.alert('Required Fields no populated');
      this.formSubmitEvent.emit(this.form);
      this.inProgress = false;
      return;
    }

    const toEmailAddress = payload.toEmailAddress;
    const ccEmailAddress = payload.ccEmailAddress;
    const code = payload.code.value;
    const type = payload.type.value;
    const customText = payload.customText;
    if (!(code in this.sendEmailModalService.typeMapping[type])) {
      window.alert(`Type ${type} does not have Code ${code}. Choose different combination.`);
      this.formSubmitEvent.emit(this.form);
      this.inProgress = false;
      return;
    }

    const sendFollowUpEmail = payload.sendfollowupemail.key === 'Yes' ? true : false;
    if (this.dataService.serviceOrderDataModel.claimData) {
      this.dataService.serviceOrderDataModel.claimData.sendFollowUpEmail = sendFollowUpEmail;
    }

    const templateName = this.sendEmailModalService.typeMapping[type][code];
    const templateDetails = this.sendEmailModalService.templateDetails[templateName];
    const templateVariables = templateDetails.emailTemplateVariables;
    const includeSoAttachment = templateDetails.includeSoAttachment;

    const customerDispatch = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.customerDispatch
      : false;

    let servicer = null;
    if (this.dataService.serviceOrderDataModel.servicerInfo) {
      servicer = this.dataService.serviceOrderDataModel.servicerInfo;
      this.processSubmit(payload, servicer);
    } else {
      const serviceProviderValues = this.serviceOrderService.selectedServicerValue;
      if (this.modalTitle === 'Send Info Email') {
        this.processSubmit(payload, { contactInfo: {} });
      } else {
        if (!serviceProviderValues) {
          window.alert(`Servicer Profile Not Found.`);
          this.formSubmitEvent.emit(this.form);
          this.inProgress = false;
          return;
        }
        const servicerProfileId = serviceProviderValues.servicerProfilesIdOriginal;
        if (servicerProfileId) {
          this.querySubscriptions.push(
            this.dataService.getServicerInfoByIdSingle(servicerProfileId).subscribe(
              ({ data, loading }: any) => {
                this.processSubmit(payload, data.getServicerProfileById);
              },
              (error: any) => {
                console.log(`error on retrieving servicer`);
                this.inProgress = false;
              },
            ),
          );
        }
      }
    }
  }

  processSubmit(payload, servicer): boolean | void {
    const toEmailAddress = payload.toEmailAddress;
    const ccEmailAddress = payload.ccEmailAddress;
    const code = payload.code.value;
    const type = payload.type.value;
    const customText = payload.customText;
    const templateName = this.sendEmailModalService.typeMapping[type][code];
    const templateDetails = this.sendEmailModalService.templateDetails[templateName];
    const templateVariables = templateDetails.emailTemplateVariables;
    const includeSoAttachment = templateDetails.includeSoAttachment;

    if (
      this.dataService.serviceConfig.isFPBClaim &&
      templateName === 'TEMPLATE_50BackApproved_template1'
    ) {
      const { expenseCharges } = this.dataService.serviceOrderDataModel;
      if (
        expenseCharges &&
        expenseCharges.filter((el) => el.expenseType === 'Cashout').length === 0
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please add an Expense Type Cashout',
        });
        this.inProgress = false;
        return false;
      }
    }

    const customerDispatch = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.customerDispatch
      : false;
    const variablesKeyValues = this.getTemplateVariables(servicer, templateVariables);
    if (!this.form.valid || toEmailAddress === '') {
      this.inProgress = false;
      return null;
    }
    // For the 50% Back Approved template, need to change DRAFT/PENDING DISPATCH to IN PROGRESS status.
    if (
      templateName === 'TEMPLATE_50BackApproved_template1' &&
      variablesKeyValues['status'] &&
      ['DRAFT', 'PENDING DISPATCH'].includes(variablesKeyValues['status'])
    ) {
      variablesKeyValues['status'] = 'IN PROGRESS';
    }
    const request = {
      operation: 'send_template',
      data: {
        from: 'donotreply@newleafsc.net',
        receivers: [
          {
            Email: toEmailAddress,
            FirstName: 'FirstName',
            LastName: 'LastName',
          },
        ],
        cc: [ccEmailAddress],
        variables: variablesKeyValues,
        template: templateName,
      },
    };

    this.isLoading = true;
    this.messageService.clear();
    if (includeSoAttachment) {
      const documentDefinition = this.createDocumentDefinition(servicer);
      const pdfDoc = pdfMake.createPdf(documentDefinition);
      pdfMake.createPdf(documentDefinition).getBase64((encodedString) => {
        const data = encodedString;
        const soRawAttachment = [
          {
            filename: `Service Order #${this.dataService.serviceOrderDataModel.serviceOrderInfo.serviceOrder}.pdf`,
            content: data,
            encoding: 'base64',
          },
        ];
        request.data['attachments'] = soRawAttachment;
        if (this.dataService.serviceOrderDataModel.claimData) {
          request['transactionDetails'] = this.addTransactionDetails(templateName, toEmailAddress);
        }
        this.querySubscriptions.push(
          this.emailService.sendEmail(request).subscribe(
            (response) => {
              const noteText = this.createNoteText(templateName, toEmailAddress);
              this.dataService.notesDataModel.type = NoteType.SentEmail;
              this.dataService.notesDataModel.text = noteText;
              this.dataService.newNote.next(true);
              this.isLoading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `${type} Email sent successfully to ${toEmailAddress}.`,
              });
              if (!(this.modalTitle === 'Send Info Email')) {
                if (NO_CUSTOMER_EMAIL_TEMPLATES.includes(templateName)) {
                  if (this.dataService.serviceOrderDataModel.claimData) {
                    this.dataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
                  }
                  this.displayDialog = false;
                  this.displayDialogChange.emit(this.displayDialog);
                  return;
                } else {
                  this.sendCustomerDispatch(servicer, soRawAttachment);
                }
              } else if (type === 'Customer Dispatch' && this.modalTitle === 'Send Info Email') {
                if (this.dataService.serviceOrderDataModel.claimData) {
                  this.dataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
                }
                this.serviceOrderService.isCustomerDispatched.next(true);
                this.displayDialog = false;
                this.displayDialogChange.emit(this.displayDialog);
              } else {
                this.displayDialog = false;
                this.displayDialogChange.emit(this.displayDialog);
              }
              this.chRef.detectChanges();
              this.formSubmitEvent.emit(this.form);
            },
            (err) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: `${type} Email send Failed to ${toEmailAddress}.`,
              });
              this.inProgress = false;
            },
          ),
        );
      });
    } else {
      if (this.dataService.serviceOrderDataModel.claimData) {
        request['transactionDetails'] = this.addTransactionDetails(templateName, toEmailAddress);
      }
      this.querySubscriptions.push(
        this.emailService.sendEmail(request).subscribe(
          async (response) => {
            const noteText = this.createNoteText(templateName, toEmailAddress);
            this.dataService.notesDataModel.type = NoteType.SentEmail;
            this.dataService.notesDataModel.text = noteText;
            this.dataService.newNote.next(true);
            this.isLoading = false;
            const emailResponseArr = [];
            if (templateName === 'TEMPLATE_50BackGiftCertificate_template1') {
              const fbEmailAddress =
                this.dataService.serviceOrderDataModel.customerInfo.customerEmail;
              if (fbEmailAddress) {
                const sReq = this.getEmailRequest(
                  'TEMPLATE_Info50BackCustFeedback_template1',
                  fbEmailAddress,
                  servicer,
                );
                emailResponseArr.push(this.emailService.sendEmail(sReq).toPromise());
              }
              const fpbContact = this.dataService.serviceOrderDataModel.fpbContact;
              if (fpbContact.length > 0) {
                fpbContact.forEach((el) => {
                  const fReq = this.getEmailRequest(
                    'TEMPLATE_DealDispatch50BackRedemption_template1',
                    el.email,
                    servicer,
                  );
                  emailResponseArr.push(this.emailService.sendEmail(fReq).toPromise());
                });
              }
            } else if (templateName === 'TEMPLATE_50BackSubmissionReturn_template1') {
              this.dataService.serviceConfig.isReturnSubmission = true;
            } else if (templateName === 'TEMPLATE_50BackApproved_template1') {
              const sReq = this.getEmailRequest(
                'TEMPLATE_Info50BackCustFeedback_template1',
                toEmailAddress,
                servicer,
              );
              emailResponseArr.push(this.emailService.sendEmail(sReq).toPromise());
            }
            const finalRes = await Promise.all(emailResponseArr);
            console.log(finalRes);
            this.dismissDialog(type, toEmailAddress, servicer);
          },
          (err) => {
            this.isLoading = false;
            this.inProgress = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `${type} Email send Failed to ${toEmailAddress}.`,
            });
          },
        ),
      );
    }
  }

  dismissDialog(type, toEmailAddress, servicer) {
    if (this.dataService.serviceConfig.isFPBClaim) {
      this.serviceOrderService.selectedServicer.next(servicer);
    }
    if (type !== 'Information') {
      if (this.dataService.serviceOrderDataModel.claimData) {
        this.dataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
      }
      // Do not do a this.displayDialogChange.emit(false), it goes to onCancel() for some reason.
    }

    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: `${type} Email sent successfully to ${toEmailAddress}.`,
    });
    this.displayDialog = false;
    this.chRef.detectChanges();
    this.formSubmitEvent.emit(this.form);
    this.displayDialogChange.emit(false);
  }

  getEmailRequest(templateName, toEmailAddress, servicer) {
    console.log(`SENDING EMAIL FOR ${templateName}`);
    const templateDetails = this.sendEmailModalService.templateDetails[templateName];
    const templateVariables = templateDetails.emailTemplateVariables;
    const variablesKeyValues = this.getTemplateVariables(servicer, templateVariables);
    if (!this.form.valid || toEmailAddress === '') {
      return null;
    }
    const request = {
      operation: 'send_template',
      data: {
        from: 'donotreply@newleafsc.net',
        receivers: [
          {
            Email: toEmailAddress,
            FirstName: 'FirstName',
            LastName: 'LastName',
          },
        ],
        cc: [],
        variables: variablesKeyValues,
        template: templateName,
      },
    };
    if (this.dataService.serviceOrderDataModel.claimData) {
      request['transactionDetails'] = this.addTransactionDetails(templateName, toEmailAddress);
    }
    return request;
  }

  onCancel() {
    if (this.inProgress) {
      console.log(`ON CANCELL CLICKED`);
      return;
    }
    /*
    The onHide calls this even when the form was submitted. Need to bypass that when it is submitted.
      If we let this continue for submitted email sends, it will null out the selected servicer, which
      will not set the servicer and dispatch date after a dispatch.
    */
    if (this.modalTitle === 'Send Info Email') {
      this.displayDialog = false;
      this.displayDialogChange.emit(false);
      return;
    }
    const { scheduleJobDetails } = this.dataService.serviceBenchModal;
    const { scheduleCallDetails } = this.dataService.servicePowerModal;
    const isServiceBenchAvailable = scheduleJobDetails && scheduleJobDetails.jobNumber;
    const isServicePowerAvailable = scheduleCallDetails && scheduleCallDetails.jobNumber;
    if (
      this.serviceOrderService.selectedServicerValue &&
      !isServiceBenchAvailable &&
      !isServicePowerAvailable &&
      !this.submitted
    ) {
      this.serviceOrderService.selectedServicer.next(undefined);
      this.serviceOrderService.selectedServicerValue = null;
    } else {
      this.submitted = false;
      this.dataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
    }
    this.displayDialogChange.emit(false);
  }

  onContinueWOEmailFax() {
    this.submitted = true;
    const payload = this.form.getRawValue();
    const customerDispatch = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.customerDispatch
      : false;
    const claimStatusDescription = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.claimStatusDescription
      : null;
    if (claimStatusDescription && claimStatusDescription in CANNOT_DISPATCH_STATUSES) {
      window.alert(
        `Cannot Dispatch (Continue Without Email/Fax) when Claim Status is '${claimStatusDescription}'`,
      );
      return;
    }
    if (this.modalTitle === 'Send Info Email') {
      if (!(this.isCustomerDispatched || customerDispatch)) {
        window.alert(
          `The Customer Dispatch checkbox must be checked before performing a Customer Dispatch.`,
        );
        return;
      }
    }
    const sendFollowUpEmail = payload.sendfollowupemail.key === 'Yes' ? true : false;
    if (
      payload.type &&
      payload.type.key !== 'Information' &&
      this.dataService.serviceOrderDataModel.claimData
    ) {
      this.dataService.serviceOrderDataModel.claimData.sendFollowUpEmail = sendFollowUpEmail;
      this.dataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
      if (this.modalTitle === 'Send Info Email') {
        this.serviceOrderService.isCustomerDispatched.next(true);
      }
    }
    this.displayDialog = false;
    if (!(this.modalTitle === 'Send Info Email')) {
      this.displayDialogChange.emit(this.displayDialog);
    }
  }

  onSendEmail() {
    this.displayEmailModal = true;
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }

  private sendCustomerDispatch(servicer, soRawAttachment) {
    const dealerDispatch = this.dataService.serviceOrderDataModel.dealerInfo.dispatch;
    let templateName =
      this.sendEmailModalService.typeMapping['Customer Dispatch']['Cust. Disp. Stand. A'];
    // Use the Dispatch on the dealer if it is code. If it is the description, use the standard.
    if (dealerDispatch in this.sendEmailModalService.typeMapping['Customer Dispatch']) {
      templateName = this.sendEmailModalService.typeMapping['Customer Dispatch'][dealerDispatch];
    } else {
      // The Customer Dispatch Custom have multiple codes. Just use Standard in that case.
      if (dealerDispatch === 'Customer Dispatch Custom') {
        templateName =
          this.sendEmailModalService.typeMapping['Customer Dispatch']['Cust. Disp. Stand. A'];
      } else {
        // Look up the template name based on description.
        for (const singleTemplateName in this.sendEmailModalService.templateDetails) {
          const singleTemplate = this.sendEmailModalService.templateDetails[singleTemplateName];
          const templateDescription = singleTemplate.emailTemplateDescription;
          const templateType = singleTemplate.emailTemplateType;
          const templateCode = singleTemplate.emailTemplateMessageCode;
          if (templateType === 'Customer Dispatch' && dealerDispatch === templateDescription) {
            templateName =
              this.sendEmailModalService.typeMapping['Customer Dispatch'][templateCode];
          }
        }
      }
    }

    const templateDetails = this.sendEmailModalService.templateDetails[templateName];
    const templateVariables = templateDetails.emailTemplateVariables;
    const includeSoAttachment = templateDetails.includeSoAttachment;

    const servicerNumber = servicer.contactInfo.phoneNumber1;
    const servicerEmail = servicer.contactInfo.email;
    const servicerInformation = {};
    servicerInformation['servicerNumber'] = servicer.servicerNumber;
    servicerInformation['servicerName'] = servicer.servicerName;
    servicerInformation['servicerPhoneNumber1'] = servicerNumber;
    servicerInformation['servicerFax'] = servicer.contactInfo.fax;
    servicerInformation['servicerEmail'] = servicerEmail;

    let emailAddress = '';
    const contactInfo = this.dataService.serviceOrderDataModel.contact;
    const customerInfo = this.dataService.serviceOrderDataModel.customerInfo;
    if (contactInfo && contactInfo.email) {
      emailAddress = contactInfo.email;
    } else if (customerInfo && customerInfo.customerEmail) {
      emailAddress = customerInfo.customerEmail;
    }
    if (!emailAddress || emailAddress === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Customer Dispatch Email Failed. Customer Email is Empty.',
      });
      this.inProgress = false;
      return;
    }
    const variablesKeyValues = this.getTemplateVariables(servicer, templateVariables);
    const request = {
      operation: 'send_template',
      data: {
        from: 'donotreply@newleafsc.net',
        receivers: [
          {
            Email: emailAddress,
            FirstName: 'FirstName',
            LastName: 'LastName',
          },
        ],
        variables: variablesKeyValues,
        template: templateName,
      },
    };
    if (includeSoAttachment) request.data['attachments'] = soRawAttachment;
    if (this.dataService.serviceOrderDataModel.claimData) {
      request['transactionDetails'] = this.addTransactionDetails(templateName, emailAddress);
    }
    this.querySubscriptions.push(
      this.emailService.sendEmail(request).subscribe(
        (response) => {
          const noteText = this.createNoteText(templateName, emailAddress);
          this.dataService.notesDataModel.type = NoteType.SentEmail;
          this.dataService.notesDataModel.text = noteText;
          this.dataService.newNote.next(true);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Customer Dispatch Email sent successfully to ${emailAddress}`,
          });

          this.chRef.detectChanges();
          this.formSubmitEvent.emit(this.form);
          // Close forms to save claim in new status.
          if (this.dataService.serviceOrderDataModel.claimData) {
            this.dataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
          }
          this.displayDialog = false;
          this.displayDialogChange.emit(this.displayDialog);
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Customer Dispatch Email send Failed.',
          });
          this.inProgress = false;
        },
      ),
    );
  }

  private createBody(bodyData) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    let singleRow = [];
    let finalBody = [];
    let index = 0;
    const exclude_list = [
      '__typename',
      'dispatch',
      'followUp',
      'addresses',
      'contactInfo',
      'id',
      'productsId',
    ];
    for (let keyName in bodyData) {
      if (index != 0 && index % 2 == 0) {
        if (singleRow.length > 0) finalBody.push(singleRow);
        singleRow = [];
      }

      if (
        !exclude_list.includes(keyName) &&
        !keyName.includes('IdOriginal') &&
        !keyName.includes('IDOriginal')
      ) {
        let keyValue = null;
        if (bodyData[keyName] && typeof bodyData[keyName] === 'object') {
          keyValue = bodyData[keyName].value;
          if (bodyData[keyName].key === 'No' && !keyValue) {
            keyValue = 'No';
          }
          if (bodyData[keyName].key === 'Yes' && keyValue) {
            keyValue = 'Yes';
          }
        } else {
          keyValue = bodyData[keyName];
        }
        // keyValue = bodyData[keyName];
        if (keyName in LABEL_MAPPING) keyName = LABEL_MAPPING[keyName];
        if (keyName === 'Status' && keyValue === 'DRAFT') keyValue = 'PENDING ACCEPTANCE';
        singleRow.push({
          text: keyName,
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        });
        singleRow.push({ text: keyValue, bold: true, fontSize: 10 });
        index++;
      }
    }
    if (singleRow.length === 2) {
      singleRow.push({ text: '', fillColor: '#8cc640' });
      singleRow.push('');
      if (singleRow.length > 0) finalBody.push(singleRow);
    }
    if (!finalBody || finalBody.length === 0) {
      finalBody = [['NA', 'NA', 'NA', 'NA']];
    }
    return finalBody;
  }

  private removeTags(str) {
    if (str === null || str === '') return '';
    else str = str.toString();
    str = str.replace(/&nbsp;/gi, ' ');
    str = str.replace(/<\/p>/gi, '\n');
    return str.replace(/(<([^>]+)>)/gi, '');
  }

  private createBodyNotes(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    let finalBody = [];
    finalBody.push([
      { text: 'Type', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Entered By', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Note', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Last Updated Time', bold: true, fontSize: 10, fillColor: '#8cc640' },
    ]);

    for (const singleNote of bodyData) {
      // Only add External (38) and System (40) notes
      if (
        NOTE_TYPE_ATTACHMENT.includes(singleNote.noteType) ||
        NOTE_DESC_ATTACHMENT.includes(singleNote.noteTypeDescription)
      ) {
        finalBody.push([
          { text: singleNote['noteTypeDescription'], fontSize: 10 },
          { text: singleNote['enteredBy'], fontSize: 10 },
          { text: this.removeTags(singleNote['noteText']), fontSize: 10 },
          { text: dateTimeFormatterCST(singleNote['enteredTime']), fontSize: 10 },
        ]);
      }
    }
    if (!finalBody || finalBody.length === 0) {
      finalBody = [['NA', 'NA', 'NA', 'NA']];
    }
    return finalBody;
  }

  private createBodyCoverageDetails(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA']];
    }
    let finalBody = [];
    finalBody.push([
      { text: 'Select', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Coverage', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Policy/SKU', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Description', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Method', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Term', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Coverage Start/Exp', bold: true, fontSize: 10, fillColor: '#8cc640' },
      { text: 'Covered', bold: true, fontSize: 10, fillColor: '#8cc640' },
    ]);

    for (const singleNote of bodyData) {
      let startDate = 'N/A';
      let endDate = 'N/A';
      if (singleNote['coverageStDate']) startDate = singleNote['coverageStDate'].substring(0, 10);
      if (singleNote['coverageExpDate']) endDate = singleNote['coverageExpDate'].substring(0, 10);
      if (singleNote['selection'] === true) {
        finalBody.push([
          { text: '√', alignment: 'center', fontSize: 10, fillColor: '#FFFF00' },
          { text: singleNote['name'], fontSize: 10, fillColor: '#FFFF00' },
          { text: singleNote['policySku'], fontSize: 10, fillColor: '#FFFF00' },
          { text: singleNote['description'], fontSize: 10, fillColor: '#FFFF00' },
          { text: singleNote['description'], fontSize: 10, fillColor: '#FFFF00' },
          { text: singleNote['cvTerm'], fontSize: 10, fillColor: '#FFFF00' },
          { text: startDate + ' - ' + endDate, fontSize: 10, fillColor: '#FFFF00' },
          { text: singleNote['covered'], fontSize: 10, fillColor: '#FFFF00' },
        ]);
      } else {
        finalBody.push([
          { text: '', fontSize: 10 },
          { text: singleNote['name'], fontSize: 10 },
          { text: singleNote['policySku'], fontSize: 10 },
          { text: singleNote['description'], fontSize: 10 },
          { text: singleNote['description'], fontSize: 10 },
          { text: singleNote['cvTerm'], fontSize: 10 },
          { text: startDate + ' - ' + endDate, fontSize: 10 },
          { text: singleNote['covered'], fontSize: 10 },
        ]);
      }
    }
    if (!finalBody || finalBody.length === 0) {
      finalBody = [['NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA']];
    }
    return finalBody;
  }

  private createStandardProblem(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    const singleRow = [];
    finalBody.push(
      [
        {
          text: 'Failure/Loss Date',
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.failureDate), bold: true, fontSize: 10 },
        {
          text: 'Reported Date',
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.reportedDate), bold: true, fontSize: 10 },
      ],
      [
        {
          text: 'Functionality Status',
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        },
        { text: bodyData.functionalityStatus, bold: true, fontSize: 10 },
        {
          text: 'Failure Description',
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        },
        { text: bodyData.failureDescription, bold: true, fontSize: 10 },
      ],
      [
        {
          text: 'Failure',
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        },
        { text: bodyData.symptom, bold: true, fontSize: 10 },
        {
          text: '',
          italics: true,
          color: 'white',
          fontSize: 10,
          fillColor: '#8cc640',
        },
        { text: '', bold: true, fontSize: 10 },
      ],
    );

    return finalBody;
  }

  private getTemplateVariables(servicer, templateVariables) {
    const payload = this.form.getRawValue();
    const toEmailAddress = payload.toEmailAddress;
    const ccEmailAddress = payload.ccEmailAddress;
    const code = payload.code.value;
    const type = payload.type.value;
    const customText = payload.customText;
    const servicerBusinessPhone = servicer.contactInfo.businessPhone;
    const servicerPhoneNumber1 = servicer.contactInfo.phoneNumber1;
    let servicerNumber = servicerBusinessPhone;
    if (!servicerNumber || servicerNumber === '') {
      servicerNumber = servicerPhoneNumber1;
    }
    const servicerEmail = servicer.contactInfo.email;
    const servicerName = servicer.servicerName;
    const servicerInformation = {};
    servicerInformation['servicerNumber'] = servicer.servicerNumber;
    servicerInformation['servicerName'] = servicer.servicerName;
    servicerInformation['servicerPhoneNumber1'] = servicerNumber;
    servicerInformation['servicerFax'] = servicer.contactInfo.fax;
    servicerInformation['servicerEmail'] = servicerEmail;

    let customerName = '';
    if (
      this.dataService.serviceOrderDataModel.claimData &&
      this.dataService.serviceOrderDataModel.claimData.contactInfo
    ) {
      const customerFirstName =
        this.dataService.serviceOrderDataModel.claimData.contactInfo.firstName;
      const customerLastName =
        this.dataService.serviceOrderDataModel.claimData.contactInfo.lastName;
      customerName = `${customerFirstName} ${customerLastName}`;
    }

    const emailVariables = {
      customer_name_ct: '',
      customer_address: '',
      customer_address_ct: '',
      customer_city: '',
      customer_city_ct: '',
      customer_state: '',
      customer_state_ct: '',
      customer_zip: '',
      customer_zip_ct: '',
      certificate_amount: '',
      service_order_number: '',
      certificate_expiration: '',
      dealer_name: '',
      current_date: '',
      contract_number: '',
      total_approved: '',
      model_number: '',
      cause_note: '',
      fpb_status: '',
    };
    if (
      this.dataService.serviceOrderDataModel.claimData &&
      this.dataService.serviceOrderDataModel.claimData.addresses
    ) {
      emailVariables.customer_address =
        this.dataService.serviceOrderDataModel.claimData.addresses[0].address1;
      emailVariables.customer_city =
        this.dataService.serviceOrderDataModel.claimData.addresses[0].city;
      emailVariables.customer_state =
        this.dataService.serviceOrderDataModel.claimData.addresses[0].state;
      emailVariables.customer_zip =
        this.dataService.serviceOrderDataModel.claimData.addresses[0].zipCode;
    }

    if (
      this.dataService.serviceOrderDataModel.contractInfo &&
      this.dataService.serviceOrderDataModel.contractInfo.contractAddresses
    ) {
      emailVariables.customer_address_ct =
        this.dataService.serviceOrderDataModel.contractInfo.contractAddresses[0].address1;
      emailVariables.customer_city_ct =
        this.dataService.serviceOrderDataModel.contractInfo.contractAddresses[0].city;
      emailVariables.customer_state_ct =
        this.dataService.serviceOrderDataModel.contractInfo.contractAddresses[0].state;
      emailVariables.customer_zip_ct =
        this.dataService.serviceOrderDataModel.contractInfo.contractAddresses[0].zipCode;
    }

    if (
      this.dataService.serviceOrderDataModel.contractInfo &&
      this.dataService.serviceOrderDataModel.contractInfo.contactInformation
    ) {
      const customer = this.dataService.serviceOrderDataModel.contractInfo.contactInformation;
      emailVariables.customer_name_ct = `${customer.customerFirstName} ${customer.customerLastName}`;
    }

    if (this.dataService.serviceOrderDataModel.dealerInfo) {
      emailVariables.dealer_name =
        this.dataService.serviceOrderDataModel.dealerInfo.storeLocationName;
    }

    if (this.dataService.serviceOrderDataModel.problemReport) {
      let causeNote = this.dataService.serviceOrderDataModel.problemReport.causeNote;
      if (causeNote) {
        causeNote = causeNote.replace(/\n/g, '<br>');
      }
      emailVariables.cause_note = causeNote;
      emailVariables.fpb_status = this.dataService.serviceOrderDataModel.problemReport.cause;
    }

    if (this.dataService.serviceOrderDataModel.expenseCharges) {
      const approvedList = this.dataService.serviceOrderDataModel.expenseCharges.filter(
        (x) => x.status === 'Approved',
      );
      emailVariables.total_approved =
        approvedList.length > 0
          ? approvedList.reduce((acc, curr) => acc + parseFloat(curr.approvedAmount), 0).toFixed(2)
          : (0).toFixed(2);
    }

    let model_number = '';
    let manufacturer = '';
    let sub_category_desc = '';
    let category_desc = '';
    let tier_desc = '';
    if (this.dataService.serviceOrderDataModel.productInfoModel) {
      model_number = this.dataService.serviceOrderDataModel.productInfoModel.modelNumber;
      manufacturer =
        this.dataService.serviceOrderDataModel.productInfoModel.manufacturerManufacturer;
      sub_category_desc =
        this.dataService.serviceOrderDataModel.productInfoModel.subcategoryDescription;
      if (sub_category_desc) {
        sub_category_desc = sub_category_desc
          .replace('- OTHER', '')
          .replace('-OTHER', '')
          .replace('- TOP', '')
          .replace('-TOP', '')
          .replace('- FRONT', '')
          .replace('-FRONT', '')
          .replace('- TOP WITH ICE', '')
          .replace('-TOP WITH ICE', '')
          .replace('- TOP/NO ICE', '')
          .replace('-TOP/NO ICE', '')
          .trim();
      }
      category_desc = this.dataService.serviceOrderDataModel.productInfoModel.categoryDescription;
      tier_desc = this.dataService.serviceOrderDataModel.productInfoModel.tierDescription;
    }
    let contract_number = '';
    let so_status = '';
    if (this.dataService.serviceOrderDataModel.serviceOrderInfo) {
      contract_number = this.dataService.serviceOrderDataModel.serviceOrderInfo.contract;
      so_status = this.dataService.serviceOrderDataModel.serviceOrderInfo.status;
    }
    const { scheduleJobDetails } = this.dataService.serviceBenchModal;
    const { scheduleCallDetails } = this.dataService.servicePowerModal;
    const isServiceBenchAvailable = scheduleJobDetails && scheduleJobDetails.jobNumber;
    const isServicePowerAvailable = scheduleCallDetails && scheduleCallDetails.jobNumber;

    let oem_job_datetime = 'N/A';
    let oem_sp_name = 'N/A';
    if (isServiceBenchAvailable && scheduleJobDetails) {
      if (scheduleJobDetails.scheduledDate && scheduleJobDetails.scheduledDate !== '') {
        const dateStrTemp = scheduleJobDetails.scheduledDate;
        const formattedDate = `${dateStrTemp.slice(0, 4)}-${dateStrTemp.slice(
          4,
          6,
        )}-${dateStrTemp.slice(6, 8)}`;
        oem_job_datetime =
          generalDateFormatter(formattedDate, 'MM/DD/YYYY') +
          ' between ' +
          getFormattedTimeRangeForSB(
            scheduleJobDetails.timeSlotStart,
            scheduleJobDetails.timeSlotLength,
          );
      }
      if (scheduleJobDetails.servicerName && scheduleJobDetails.servicerName !== '') {
        oem_sp_name = scheduleJobDetails.servicerName;
      }
    } else if (isServicePowerAvailable && scheduleCallDetails) {
      if (scheduleCallDetails.scheduledDate && scheduleCallDetails.scheduledDate !== '') {
        oem_job_datetime =
          generalDateFormatter(scheduleCallDetails.scheduledDate, 'MM/DD/YYYY') +
          ' between ' +
          getFormattedTimeRangeForSP(scheduleCallDetails.timePeriod);
      }
      if (scheduleCallDetails.servicerAccount && scheduleCallDetails.servicerAccount !== '') {
        oem_sp_name = scheduleCallDetails.servicerAccount;
      }
    }

    if (customerName === '') {
      customerName = emailVariables.customer_name_ct;
    }
    const variablesKeyValues = {};
    const variablesArray = templateVariables.split(',');
    for (const variableName of variablesArray) {
      let variableValue = 'NOT_FOUND';
      if (variableName === 'service_provider_phone') variableValue = servicerNumber;
      if (variableName === 'service_provider_name') variableValue = servicerName;
      if (variableName === 'service_order_number')
        variableValue = this.dataService.serviceOrderDataModel.serviceOrderInfo.serviceOrder;
      if (variableName === 'custom_text') variableValue = customText;
      if (variableName === 'customer_name' && customerName !== '') variableValue = customerName;
      if (variableName === 'customer_name_ct' && emailVariables.customer_name_ct !== '')
        variableValue = emailVariables.customer_name_ct;
      if (variableName === 'customer_address') variableValue = emailVariables.customer_address;
      if (variableName === 'customer_address_ct')
        variableValue = emailVariables.customer_address_ct;
      if (variableName === 'customer_city') variableValue = emailVariables.customer_city;
      if (variableName === 'customer_city_ct') variableValue = emailVariables.customer_city_ct;
      if (variableName === 'customer_state') variableValue = emailVariables.customer_state;
      if (variableName === 'customer_state_ct') variableValue = emailVariables.customer_state_ct;
      if (variableName === 'customer_zip') variableValue = emailVariables.customer_zip;
      if (variableName === 'customer_zip_ct') variableValue = emailVariables.customer_zip_ct;
      if (variableName === 'dealer_name') variableValue = emailVariables.dealer_name;
      if (variableName === 'current_date')
        variableValue = getMMDDYYYFormat(new Date().toDateString(), 'MM/DD/YYYY');
      if (variableName === 'certificate_amount' || variableName === 'total_approved')
        variableValue = emailVariables.total_approved;
      if (variableName === 'cause_note') variableValue = emailVariables.cause_note;
      if (variableName === 'model_number') variableValue = model_number;
      if (variableName === 'manufacturer') variableValue = manufacturer;
      if (variableName === 'sub_category_desc') variableValue = sub_category_desc;
      if (variableName === 'category_desc') variableValue = category_desc;
      if (variableName === 'tier_desc') variableValue = tier_desc;
      if (variableName === 'contract_number') variableValue = contract_number;
      if (variableName === '50_status') variableValue = emailVariables.fpb_status;
      if (variableName === 'status') variableValue = so_status;
      if (variableName === 'certificate_expiration') {
        const today = new Date();
        today.setDate(today.getDate() + 30);
        variableValue = getMMDDYYYFormat(today.toDateString(), 'MM/DD/YYYY');
      }
      if (variableName === 'oem_job_datetime') variableValue = oem_job_datetime;
      if (variableName === 'oem_sp_name') variableValue = oem_sp_name;

      variablesKeyValues[variableName] = variableValue;
    }
    return variablesKeyValues;
  }

  private updateEmail(servicerEmail) {
    const customerDispatch = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.customerDispatch
      : false;
    const newFormFields: FormField<string>[] = [];
    for (let index = 0; index < this.formFields.length; index++) {
      const singleFormField = this.formFields[index];
      if (!(this.modalTitle === 'Send Info Email')) {
        if (singleFormField['key'] === 'toEmailAddress') {
          singleFormField.value = servicerEmail;
        }
      }
      if (customerDispatch) {
        const customerInfo = this.dataService.serviceOrderDataModel.customerInfo;
        let emailAddress = '';
        for (const fieldName in customerInfo) {
          if (fieldName === 'customerEmail') emailAddress = customerInfo[fieldName];
        }
        if (singleFormField['key'] === 'toEmailAddress') {
          singleFormField.value = emailAddress;
        }
      }
      newFormFields.push(singleFormField);
    }
    this.formFields = newFormFields;
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 6);
    this.formFields2 = this.formFields.slice(11, 30);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
  }

  private updateForm(data, servicerEmail) {
    // Need to check if the form has changed before submitting.
    const serviceOrderForm = this.dataService.currentFormData.getRawValue();
    const customerDispatchFormValue = serviceOrderForm.customerDispatch;
    let dispatchLength = 0;
    if (customerDispatchFormValue) {
      dispatchLength = customerDispatchFormValue.length;
    }

    const typeOptions = [];
    const codeOptions = [];
    for (const singleTemplate of data.getEmailTemplateDetails) {
      const templateType = singleTemplate.emailTemplateType;
      const templateMessageCode = singleTemplate.emailTemplateMessageCode;
      const templateName = singleTemplate.emailTemplateName;
      if (!(templateType in this.sendEmailModalService.typeMapping)) {
        this.sendEmailModalService.typeMapping[templateType] = {};
        this.emailService.typeMapping[templateType] = {};
      }
      this.sendEmailModalService.typeMapping[templateType][templateMessageCode] = templateName;
      this.sendEmailModalService.templateDetails[templateName] = singleTemplate;
      this.emailService.templateDetails[templateName] = singleTemplate;
      this.emailService.typeMapping[templateType][templateMessageCode] = templateName;

      typeOptions.push({
        key: singleTemplate.emailTemplateType,
        value: singleTemplate.emailTemplateType,
      });
      codeOptions.push({
        key: singleTemplate.emailTemplateMessageCode,
        value: singleTemplate.emailTemplateMessageCode,
      });
    }
    const customerInfo = this.dataService.serviceOrderDataModel.customerInfo;
    let emailAddress = '';
    emailAddress = this.servicerEmail;

    const customerDispatch = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.customerDispatch
        ? this.dataService.serviceOrderDataModel.claimData.customerDispatch
        : false
      : false;
    const sendFollowUpEmail = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.sendFollowUpEmail
      : false;
    const claimStatusDescription = this.dataService.serviceOrderDataModel.claimData
      ? this.dataService.serviceOrderDataModel.claimData.claimStatusDescription
      : null;
    let custDispatchCodes = [];
    const custDispatchTypes = [];

    // OEM Flag check
    const { entitledServicers } = this.dataService.serviceBenchModal;
    const { spEntitledServicers } = this.dataService.servicePowerModal;
    let isOemFlag = false;
    const selectedServicerValue = this.serviceOrderService.selectedServicerValue;
    if (selectedServicerValue) {
      selectedServicerValue.servicerName;
      const entitledServicerValue = getKeyByValue(
        entitledServicers,
        selectedServicerValue.servicerName,
      );
      const spEntitledServicerValue = getKeyByValue(
        spEntitledServicers,
        selectedServicerValue.servicerName,
      );
      if (
        (entitledServicerValue && entitledServicerValue !== '') ||
        (spEntitledServicerValue && spEntitledServicerValue !== '')
      ) {
        isOemFlag = true;
      } else {
        isOemFlag = false;
      }
    } else {
      isOemFlag = false;
    }

    // The Customer Reimbursement Dispatch is called from the Info Email form now.
    if (this.modalTitle === 'Send Info Email') {
      custDispatchCodes.push({ key: 'Attach-SO', value: 'Attach-SO' });
      custDispatchTypes.push({ key: 'Information', value: 'Information' });
      if (
        (!this.isCustomerDispatched &&
          customerDispatch &&
          claimStatusDescription &&
          !(claimStatusDescription in CANNOT_DISPATCH_STATUSES)) ||
        (this.dataService.serviceConfig.isFPBClaim && this.isCustomerDispatched)
      ) {
        custDispatchTypes.push({ key: 'Customer Dispatch', value: 'Customer Dispatch' });
        for (const dispatchType in this.sendEmailModalService.typeMapping) {
          if (dispatchType === 'Customer Dispatch') {
            for (const dispatchCode in this.sendEmailModalService.typeMapping[dispatchType]) {
              if (dispatchType === 'Customer Dispatch') {
                if (dispatchCode.includes('CRP')) {
                  custDispatchCodes.push({ key: dispatchCode, value: dispatchCode });
                }
              }
            }
          }
        }
      }
    } else {
      for (const dispatchType in this.sendEmailModalService.typeMapping) {
        if (dispatchType === 'Dispatch') {
          for (const dispatchCode in this.sendEmailModalService.typeMapping[dispatchType]) {
            custDispatchCodes.push({ key: dispatchCode, value: dispatchCode });
          }
        }
      }
    }
    if (!this.dataService.serviceConfig.isFPBClaim) {
      custDispatchCodes = custDispatchCodes.filter((el) => el.key !== '50% Back Gift Certificate');
      custDispatchCodes = custDispatchCodes.filter((el) => el.key !== '50% Back');
      custDispatchCodes = custDispatchCodes.filter(
        (el) => el.key !== 'CRP 50% Back Submission Return',
      );
      custDispatchCodes = custDispatchCodes.filter((el) => el.key !== 'CRP 50% Back Approved');
    }
    const newFormFields: FormField<string>[] = [];
    for (let index = 0; index < this.formFields.length; index++) {
      const singleFormField: any = this.formFields[index];
      if (this.modalTitle === 'Send Info Email') {
        if (singleFormField['key'] === 'code') {
          this.formFields[index].options = custDispatchCodes;
        }
        if (singleFormField['key'] === 'type') {
          this.formFields[index].options = custDispatchTypes;
        }
        if (this.isCustomerDispatched && customerDispatch) {
          const contactInfo = this.dataService.serviceOrderDataModel.contact;
          const customerInfo = this.dataService.serviceOrderDataModel.customerInfo;
          let customerEmailAddress = '';
          if (contactInfo && contactInfo.email) {
            customerEmailAddress = contactInfo.email;
          } else if (customerInfo && customerInfo.customerEmail) {
            customerEmailAddress = customerInfo.customerEmail;
          }
          if (singleFormField['key'] === 'toEmailAddress') {
            singleFormField.value = customerEmailAddress;
          }
        }
      } else {
        if (singleFormField['key'] === 'toEmailAddress') {
          singleFormField.value = emailAddress;
        }
        if (singleFormField['key'] === 'code') {
          singleFormField.options = custDispatchCodes;
          if (isOemFlag) {
            singleFormField.value = {
              key: 'OEM Dispatch',
              value: 'OEM Dispatch',
            };
          }
        }
        if (singleFormField['key'] === 'sendfollowupemail' && sendFollowUpEmail) {
          singleFormField.value = { key: 'Yes', value: 'Yes' };
        }
      }

      newFormFields.push(singleFormField);
    }
    this.formFields = newFormFields;
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 6);
    this.formFields2 = this.formFields.slice(11, 30);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
  }

  createDocumentDefinition(servicer) {
    const servicerBusinessPhone = servicer.contactInfo.businessPhone;
    const servicerPhoneNumber1 = servicer.contactInfo.phoneNumber1;
    let servicerNumber = servicerBusinessPhone;
    if (!servicerNumber || servicerNumber === '') {
      servicerNumber = servicerPhoneNumber1;
    }
    const servicerEmail = servicer.contactInfo.email;
    const servicerInformation = {};
    servicerInformation['servicerNumber'] = servicer.servicerNumber;
    servicerInformation['servicerName'] = servicer.servicerName;
    servicerInformation['servicerPhoneNumber1'] = servicerNumber;
    servicerInformation['servicerFax'] = servicer.contactInfo.fax;
    servicerInformation['servicerEmail'] = servicerEmail;
    const serviceOrderBody = this.createBody(
      this.dataService.serviceOrderDataModel.serviceOrderInfo,
    );
    const servicerInformationBody = this.createBody(servicerInformation);
    const dealerInformationBody = this.createBody(
      this.dataService.serviceOrderDataModel.dealerInfo,
    );
    const soContactInfo = {
      'Caller Type': this.dataService.serviceOrderDataModel.contact.callerType.key,
      'Dispatch Method': this.dataService.serviceOrderDataModel.contact.dispatchMethod.key,
      customerFirstName: this.dataService.serviceOrderDataModel.contact.firstName,
      customerLastName: this.dataService.serviceOrderDataModel.contact.lastName,
      customerPhone: this.dataService.serviceOrderDataModel.contact.phoneNumber1,
      'Phone Type Code 1': this.dataService.serviceOrderDataModel.contact.phoneTypeCode1.value,
      phoneNumber2: this.dataService.serviceOrderDataModel.contact.phoneNumber2,
      'Phone Type Code 2': this.dataService.serviceOrderDataModel.contact.phoneTypeCode2.value,
      customerEmail: this.dataService.serviceOrderDataModel.contact.email,
    };
    const customerInformationBody = this.createBody(soContactInfo);
    const productLocationBody = this.createBody(this.dataService.serviceOrderDataModel.location);
    const productInformationBody = this.createBody(
      this.dataService.serviceOrderDataModel.productInfoModel,
    );
    const notesBody = this.createBodyNotes(this.dataService.serviceOrderDataModel.notes);
    const coverageBody = this.createBodyCoverageDetails(
      this.dataService.serviceOrderDataModel.coverageDetails,
    );
    const standardProblemBody = this.createStandardProblem(
      this.dataService.standardProblemDataModel,
    );

    const currentDatetime = moment().format('DD-MMM-YYYY HH:mm:ss');
    const documentDefinition = {
      header: `Service Order # ${this.dataService.serviceOrderDataModel.serviceOrderInfo.serviceOrder} ${currentDatetime}`,
      content: [
        { text: 'Service Order Information', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: serviceOrderBody,
          },
        },
        '\n',
        { text: 'Servicer Information', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: servicerInformationBody,
          },
        },
        '\n',
        { text: 'Dealer Information', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: dealerInformationBody,
          },
        },
        '\n',
        { text: 'Customer Information', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: customerInformationBody,
          },
        },
        '\n',
        { text: 'Product Location', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: productLocationBody,
          },
        },
        '\n',
        { text: 'Product Information', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: productInformationBody,
          },
        },
        '\n',
        { text: 'Coverage', style: 'header', bold: true },
        {
          table: {
            body: coverageBody,
          },
        },
        '\n',
        { text: 'Problem - Standard Service Order', style: 'header', bold: true },
        {
          table: {
            widths: [90, 170, 100, 120],
            body: standardProblemBody,
          },
        },
        '\n',
        { text: 'Notes', style: 'header', bold: true },
        {
          table: {
            headerRows: 1,
            body: notesBody,
          },
        },
      ],
    };
    return documentDefinition;
  }

  downloadPdf() {
    const payload = this.form.getRawValue();
    const toEmailAddress = payload.toEmailAddress;
    const ccEmailAddress = payload.ccEmailAddress;
    const code = payload.code.value;
    const type = payload.type.value;

    let servicer = null;
    if (this.dataService.serviceOrderDataModel.servicerInfo) {
      servicer = this.dataService.serviceOrderDataModel.servicerInfo;
      const documentDefinition = this.createDocumentDefinition(servicer);
      const pdfDoc = pdfMake.createPdf(documentDefinition).open();
    } else {
      const serviceProviderValues = this.serviceOrderService.selectedServicerValue;
      if (!serviceProviderValues) {
        window.alert(`Servicer Profile Not Found.`);
        this.formSubmitEvent.emit(this.form);
        return;
      }
      const servicerProfileId = serviceProviderValues.servicerProfilesIdOriginal;
      if (servicerProfileId) {
        this.isLoading = true;
        this.querySubscriptions.push(
          this.dataService
            .getServicerInfoByIdSingle(servicerProfileId)
            .subscribe(({ data, loading }: any) => {
              this.isLoading = false;
              if (!this.privateDisplayDialog) return;
              const documentDefinition = this.createDocumentDefinition(data.getServicerProfileById);
              const pdfDoc = pdfMake.createPdf(documentDefinition).open();
            }),
        );
      }
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  addTransactionDetails(templateName, emailAddress) {
    return {
      ClaimId: this.dataService.serviceOrderDataModel.claimData.claimIdOriginal,
      ServiceOrderNumber: this.dataService.serviceOrderDataModel.claimData.serviceOrderNumber,
      EmailAddress: emailAddress,
      EmailTemplate: templateName,
      InsertNote: false,
      InsertUserName: this.azureService.fullName,
      InsertAzureId: this.azureService.accountId,
    };
  }

  createNoteText(templateName, emailAddress) {
    return `Email: Used ${templateName} for ${this.dataService.serviceOrderDataModel.claimData.serviceOrderNumber}, Recipient: ${emailAddress}`;
  }
}

async function getPDFBase64Text(pdfDoc) {
  return new Promise(function (resolve, reject) {
    pdfDoc.getBase64(function (pdfBase64) {
      resolve(pdfBase64);
    });
  });
}
