import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { RadioButtonField } from '../shared/form-field/radio-button-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';

@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {
  constructor(private http: HttpClient) {}

  getDashboardTypes(): Observable<any[]> {
    return of([
      {
        aging: '13 days',
        dealer: 'Dealer 1',
        entered_by: 'John Doe',
        manufacturer: 'GE',
        last_update_date: 'June 1,2020'
      },
      {
        aging: '2 Hours',
        dealer: 'Dealer 2',
        entered_by: 'John Doe',
        manufacturer: 'Whirpool',
        last_update_date: 'January 1,2019'
      },
      {
        aging: '7 days',
        dealer: 'Dealer 3',
        entered_by: 'John Doe',
        manufacturer: 'Whirpool',
        last_update_date: 'January 1,2019'
      }
    ]);
  }
  getServiceCenterLookup(): Observable<any[]> {
    return of([
      {
        servicer_name: 'ABC Systems',
        phone_number: '(123)-456-7890',
        email_address: 'abc@gmail.com',
        address: 'JOHN APPLIANCE 1234 Browser st',
        self_servicing: 'true',
        distance: 'true',
        sealed_systems: 'true',
        customer_satisfaction: '10',
        rating: '10',
        valid_insurance: 'true'
      },
      {
        servicer_name: 'Service 2',
        phone_number: '(321)-456-7890',
        email_address: 'serv2@gmail.com',
        address: 'BEtty Inc 1234 Browser st',
        self_servicing: 'true',
        distance: 'true',
        sealed_systems: 'true',
        customer_satisfaction: '8',
        rating: '8',
        valid_insurance: 'true'
      },
      {
        servicer_name: 'Service 3',
        phone_number: '(231)-456-7890',
        email_address: 'serv3@gmail.com',
        address: 'Banner APPLIANCE 1234 Browser st',
        self_servicing: 'true',
        distance: 'true',
        sealed_systems: 'true',
        customer_satisfaction: '6',
        rating: '6',
        valid_insurance: 'true'
      }
    ]);
  }
  getClaimAdjudicationTypes(): Observable<any[]> {
    return of([
      {
        status: 'INACTIVE',
        question: 'Is the product eligible for coverage',
        answer: 'Yes',
        coverage_name: 'Johns-F9-APP1U3500',
        subcategory: '1',
        category: 'APPLIANCE',
        manufacturer: 'GE'
      },
      {
        status: 'INACTIVE',
        question: 'Is the product eligible for coverage',
        answer: 'Yes',
        coverage_name: 'Johns-F9-APP1U3500',
        subcategory: '1',
        category: 'APPLIANCE',
        manufacturer: 'GE'
      },
      {
        status: 'INACTIVE',
        question: 'Is the product eligible for coverage',
        answer: 'Yes',
        coverage_name: 'Johns-F9-APP1U3500',
        subcategory: '1',
        category: 'APPLIANCE',
        manufacturer: 'GE'
      }
    ]);
  }
  getServiceClaimPaymentTypes(): Observable<any[]> {
    return of([
      {
        status: 'OPEN',
        file_name: 'URGENT_Claims-06-01-2020.csv',
        uploaded_date: '06/01/2020',
        ramittance_date: '',
        claim_count: '1',
        total_paid_amount: '$2000.00'
      },
      {
        status: 'OPEN',
        file_name: 'URGENT_Claims_05.28.2020_ACH.csv',
        uploaded_date: '05/29/2020',
        ramittance_date: '',
        claim_count: '1',
        total_paid_amount: '$139.50'
      },
      {
        status: 'OPEN',
        file_name: 'URGENT_Claims_05.28.2020_CHECKS.csv',
        uploaded_date: '05/29/2020',
        ramittance_date: '',
        claim_count: '3',
        total_paid_amount: '$639.00'
      }
    ]);
  }

  getSeviceFormModalFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'question',
        label: 'Question',
        order: 1
      }),
      new RadioButtonField({
        key: 'answer',
        label: 'Answer',
        options: [{ key: 'yes', value: 'Yes' }, { key: 'no', value: 'No' }],
        order: 2
      }),
      new DropdownField({
        key: 'coverage name',
        label: 'Coverage Name',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 3
      }),
      new DropdownField({
        key: 'category/subcategory',
        label: 'Category/subcategory',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 4
      }),
      new DropdownField({
        key: 'manufacturer',
        label: 'Manufacturer',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 5
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getServicerTypes(): Observable<any[]> {
    return of([
      {
        master_buying_group: true,
        status: 'CANCELLED',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: ''
      },
      {
        master_buying_group: true,
        status: 'CANCELLED',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      }
    ]);
  }
}
