<div class="crm-container">
  <app-section-header [title]="'Claim Payment'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <div class="title">
      <div>Payee</div>
    </div>
    <address *ngIf="isClaimPaymentTxnInfoAvailable" [isVerify]="false"
      [(modelData)]="addressesFromClaimPaymentTxn" [enableModelData]="isClaimPaymentTxnInfoAvailable"></address>
    <address *ngIf="isServicerInfoAvailable" [isVerify]="false" [(modelData)]="addresses"
      [enableModelData]="isServicerInfoAvailable"></address>
    <address *ngIf="isClaimDataAvailable && !isServicerInfoAvailable" [isVerify]="false" [(modelData)]="addressesClaim"
      [enableModelData]="isClaimDataAvailable"></address>
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form" (focusout)="changeEventDetection($event)"></form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<expense-charges-total></expense-charges-total>