<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<div class="crm-container">
  <app-section-header [optionHeader]="opHeader" [title]="'Service Rates'" />
</div>

<p-overlayPanel #opRow><p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-overlayPanel
  #opHeader><p-listbox [options]="ellipsisHeaderCrudOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel>
<div class="refresh" *ngIf="!isRefresh">
  <adjudication-limit-section></adjudication-limit-section><system-approval-section></system-approval-section><parts-markup-section></parts-markup-section><extra-mileage-section></extra-mileage-section><rate-schedule-section></rate-schedule-section>
</div>