/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FailureTypeService } from './failure-type.service';
import { FailureType } from './failure-type';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisCrudOptions } from '../../shared/constants/ellipsis-options';
import { failureTypeEllipsisHeaderOptions } from '../troubleshooting.constant';
import { v4 as uuidv4 } from 'uuid';
import { DropdownDataService } from '../../services/dropdown.data.service';

import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'failure-type',
  templateUrl: 'failure-type.template.html',
  styleUrls: ['failure-type.scss'],
  providers: [FailureTypeService, MessageService],
})
export class FailureTypeComponent implements OnInit {
  searchResults: FailureType[] = [];
  cols: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = failureTypeEllipsisHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  isEditing: boolean;
  userRol: string;
  editConstant: string;
  clonedata: boolean;
  clonedRowData: KeyMap = {};
  @Input() searchParams: any;
  querySubscription: Subscription;
  isLoading = true;
  lastOffsetVal = 0;
  offsetLimit = 50;
  dropdown: any;
  disableLoadMore = false;

  constructor(
    private failureTypeService: FailureTypeService,
    private service: AzureLoginService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dropdownDataService: DropdownDataService,
    private rps: RolePermissionService,
  ) {
    this.userRol = service.roleName;
    this.clonedata = true;
  }

  ngOnInit() {
    let optionTier: any[] = this.dropdownDataService.tierDescriptionData
      ? this.dropdownDataService.tierDescriptionData.map((x) => ({
          key: x.value,
          value: x.key,
        }))
      : [];
    optionTier = optionTier.map((r) => {
      return {
        label: r.key.toUpperCase(),
        value: r.key.toUpperCase(),
      };
    });
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'tierDescription', header: 'TIER', type: 'select' },
      { field: 'failureDescription', header: 'DESCRIPTION', type: 'text' },
    ];
    this.getFailureTypeData();
    this.dropdown = {
      tierDescription: [{ label: 'Choose', value: '' }, ...optionTier],
    };
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Symptoms',
      category: 'Troubleshooting',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Symptoms',
      category: 'Troubleshooting',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getFailureTypeData() {
    this.isLoading = true;
    if (this.searchParams !== 'Failure Type') {
      const tier =
        this.searchParams.tier !== null && this.searchParams.tier !== undefined
          ? this.searchParams.tier !== ''
            ? this.searchParams.tier
            : ''
          : '';
      this.searchParams = {
        tierDescription:
          tier !== ''
            ? tier.key
            : this.searchParams.tierDescription !== undefined
            ? this.searchParams.tierDescription
            : '',
        failureDescriptions:
          this.searchParams.description !== undefined && this.searchParams.description !== false
            ? this.searchParams.description
            : this.searchParams.failureDescriptions !== undefined
            ? this.searchParams.failureDescriptions
            : '',
      };
    } else {
      this.searchParams = {
        tierDescription: '',
        failureDescriptions: '',
      };
    }

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.lastOffsetVal += this.offsetLimit;
    let length = 0;
    this.querySubscription = this.failureTypeService
      .getFailureTypes(this.searchParams)
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        const dataInfo = data.getFailureTypeSearch;
        dataInfo.map((failureType, id) => {
          const failutreTypeInfo = Object.assign({}, failureType);
          failutreTypeInfo.id = id;
          length++;
          if (this.searchResults.length === 0) {
            this.searchResults.push(failutreTypeInfo);
          } else {
            const index = this.searchResults.findIndex(
              (e) => e.failureTypeIdOrigninal === failutreTypeInfo.failureTypeIdOrigninal,
            );
            if (index === -1) {
              this.searchResults.push(failutreTypeInfo);
            } else {
              this.searchResults[index].failureDescription = failutreTypeInfo.failureDescription;
            }
          }
        });
        if (length !== 0) {
          this.disableLoadMore = this.searchResults.length % this.offsetLimit !== 0;
        } else {
          this.disableLoadMore = true;
        }
      });
  }
  selectItem(event, item, overlaypanel: OverlayPanel) {
    if (this.isEditing) {
      const btn = document.getElementById(`${this.editConstant + this.selectedItem.id}cancelBtn`);
      if (btn) {
        btn.click();
      }
      this.isEditing = false;
    }
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  handleOpen(e) {
    if (e.value === 'add') {
      const element: FailureType = {
        tierDescription: '',
        failureDescription: '',
        failureTypeId: 0,
        failureTypeIdOrigninal: 0,
        id: 0,
        __typename: 'new',
      };
      this.searchResults.unshift(element);
      this.resetId();
      this.selectedItem = this.searchResults[0];
      setTimeout(() => {
        this.onRowEdit();
      }, 100);
    } else if (e.value === 'delete') {
      this.confirmationService.confirm({
        message: `
        Are you sure you want to delete this Failure Type:
        ${this.selectedItem.tierDescription}-${this.selectedItem.failureDescription}?`,
        accept: () => {
          const row = this.selectedItem;
          const params: any = {
            tierDescription: this.selectedItem.tierDescription,
            failureDescriptions: this.selectedItem.failureDescription,
            InsertUserName: this.service.accountId,
            failureTypeIdOrigninal: this.selectedItem.failureTypeIdOrigninal,
            isDeleted: true,
          };
          this.createUpdateFailureType(params, row).then(() => {
            this.searchResults.splice(row.id, 1);
            this.resetId();
          });
        },
      });
    } else {
      this.onRowEdit();
    }
    this.cdr.detectChanges();
  }
  onRowEditInit(rowData: any) {
    if (this.clonedata) {
      this.clonedRowData[rowData.id] = { ...rowData };
    }
    this.clonedata = true;
  }
  async createUpdateFailureType(params, rowData) {
    this.failureTypeService.createUpdateFailureTypes(params).subscribe(
      (result: any) => {
        const idOriginal = parseInt(result.data.createUpdateFailureType, 10);
        const index = this.searchResults.findIndex((e) => e.id === rowData.id);
        if (index > -1) {
          this.searchResults[index].failureTypeIdOrigninal = idOriginal;
          this.searchResults[index].__typename = 'FailureType';
        }
        this.resetId();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Failure Type ${params.isDeleted ? 'Deleted' : 'Saved'} Successfully`,
        });
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Could not finish the action`,
        });
      },
    );
  }

  onRowEdit() {
    this.isEditing = true;
    const btn = document.getElementById(this.editConstant + this.selectedItem.id);
    if (btn) {
      btn.click();
    }
  }
  onRowEditSave(rowData) {
    this.validateAndSaveFailureType(rowData);
  }
  validateAndSaveFailureType(rowData) {
    if (rowData.tierDescription.length === 0) {
      this.clonedata = false;
      document.getElementById(this.editConstant + this.selectedItem.id).click();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `The tier is required`,
      });
      this.isLoading = false;
      return;
    }
    if (rowData.failureDescription.length === 0) {
      this.clonedata = false;
      document.getElementById(this.editConstant + this.selectedItem.id).click();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `The failure description is required`,
      });
      this.isLoading = false;
      return;
    }
    this.isLoading = true;

    const params: any = {
      tierDescription: rowData.tierDescription,
      failureDescriptions: rowData.failureDescription,
      InsertUserName: this.service.accountId,
    };
    if (rowData.failureTypeIdOrigninal !== 0) {
      params.failureTypeIdOrigninal = rowData.failureTypeIdOrigninal;
    }
    this.createUpdateFailureType(params, rowData);
    this.isEditing = false;
    this.isLoading = false;
  }
  onRowEditCancel(rowData: any, index: number) {
    this.searchResults[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    if (rowData.__typename === 'new') {
      this.searchResults.shift();
      this.resetId();
    }
  }
  resetId() {
    this.searchResults.forEach((data, id) => {
      data.id = id;
    });
  }
}
