<div class="modal" [class.visible]="visible">
  <div class="modal-container">
    <header *ngIf="title || closeable">
      <div class="title">
        <span class="modal-title" *ngIf="title">{{ title }}</span>
      </div>
      <div class="right-container">
        <div class="expand">
          <div class="expand-icon" [inlineSVG]="'assets/icon-expand.svg'" (click)="expand.emit()"></div>
        </div>
        <div class="close" *ngIf="closeable" (click)="close.emit()" data-testid="closeModalDialog">
          <div class="close-icon" [inlineSVG]="'assets/x-close.svg'"></div>
        </div>
      </div>
    </header>
    <div class="modal-body" [ngStyle]="{'max-width': maxWidth}">
      <ng-content></ng-content>
    </div>
  </div>
</div>
