export class CoverageComponents {
  id: any;
  description: string;
  subcategory: string;

  constructor() {
    this.id = null;
    this.description = '';
    this.subcategory = '';
  }
}

export class CoverageGroup {
  id: any;
  code: string;
  description: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
  }
}

export class AssociatedSubcategories {
  id: any;
  subcategoryCode: string;
  subcategoryDescription: string;
  categoryDescription: string;
  associatedLossCode: string;
  limitOfLiabilityOverride: string;

  constructor() {
    this.id = null;
    this.subcategoryCode = '';
    this.subcategoryDescription = '';
    this.categoryDescription = '';
    this.associatedLossCode = '';
    this.limitOfLiabilityOverride = '';
  }
}

export class CoverageExceptions {
  id: any;
  included: string;
  name: string;

  constructor() {
    this.id = null;
    this.included = '';
    this.name = '';
  }
}

export class LossCode {
  id: any;
  code: string;
  description: string;
  type: string;
  laborHours: string;
  partsCost: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.type = '';
    this.laborHours = '';
    this.partsCost = '';
  }
}

export class PriceTable {
  id: any;
  name: string;
  description: string;
  active: string;
  defaultProfile: string;

  constructor() {
    this.id = null;
    this.name = '';
    this.description = '';
    this.active = '';
    this.defaultProfile = '';
  }
}

export class ProductType {
  id: any;
  code: string;
  description: string;
  sortOrder: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.sortOrder = '';
  }
}

export class CoverageAdmin {
  id: any;
  remitable: string;

  constructor() {
    this.id = null;
    this.remitable = '';
  }
}

export class CoverageCommission {
  id: any;
  remitable: string;

  constructor() {
    this.id = null;
    this.remitable = '';
  }
}

export class CoverageReservesAndFee {
  id: any;
  remitable: string;

  constructor() {
    this.id = null;
    this.remitable = '';
  }
}

export class ServiceType {
  id: any;
  name: string;
  description: string;
  active: string;
  isMFGAuthorized: boolean;
  defaultProfile: string;
  modelName: string;
  beginDate: string;
  endDate: string;

  constructor() {
    this.id = null;
    this.name = '';
    this.description = '';
    this.active = '';
    this.isMFGAuthorized = true;
    this.defaultProfile = '';
    this.modelName = 'ALL';
    this.beginDate = '1999-01-01';
    this.endDate = '2999-12-31';
  }
}
