import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { OEMWarrantyService } from './oem-warranty.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOEMWarrantyOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ViewFileService } from '../../shared/view-file/view-file.service';
import { CrmDocumentType } from '../../shared/constants/enums';
import { MessageService } from 'primeng/api';
import { RolePermissionService } from '../../common/role-permission.service';
@Component({
  selector: 'oem-warranty',
  templateUrl: 'oem-warranty.template.html',
  providers: [OEMWarrantyService, ViewFileService, MessageService],
})
export class OEMWarrantyComponent implements OnInit {
  dataList: any[];
  dataExportList = [];
  cols: any[];
  selectedCols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOEMWarrantyOptions;
  ellipsisOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  isLoading = false;
  @Input() searchParams: any;
  @ViewChild('exportdt') exportTable;
  constructor(
    private oemWarrantyService: OEMWarrantyService,
    private viewFileService: ViewFileService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    (this.selectedCols = [
      { field: 'oEM', header: 'OEM', type: 'text' },
      { field: 'modelNumber', header: 'Model Number', type: 'text' },
      { field: 'category', header: 'Category', type: 'text' },
      { field: 'subCategory', header: 'Sub Category', type: 'text' },
      { field: 'partsTerm', header: 'Parts Term', type: 'text' },
      { field: 'laborTerm', header: 'Labor Term', type: 'text' },
      { field: 'majorComponentTerm', header: 'Major Component Term', type: 'text' },
      { field: 'otherTerm', header: 'Other Term', type: 'text' },
    ]),
      (this.cols = [
        { field: 'fileName', header: 'IMPORTED FILE NAME', type: 'text' },
        { field: 'uploadedDate', header: 'DATE/TIME STAMP', type: 'text' },
        { field: 'uploadedBy', header: 'USERNAME', type: 'text' },
      ]);
    this.isLoading = true;
    let payload = { fileName: '', uploadedDate: null };
    if (this.searchParams) {
      payload = {
        fileName: this.searchParams.fileName,
        uploadedDate: this.searchParams.uploadedDate,
      };
    }
    this.viewFileService
      .getViewFileSearchResult(payload, CrmDocumentType.OEMImport)
      .subscribe(({ data }) => {
        this.dataList = data.getFileAttachments;
        this.isLoading = false;
      });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EXPORT',
      permission: 'OEM Warranty',
      category: 'Contract',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onClickExport(event, opRow) {
    this.isLoading = true;
    this.oemWarrantyService
      .getOEMWarrantyByFile({ fileName: this.selectedItem.fileName })
      .subscribe(
        ({ data }) => {
          this.dataExportList = data.getOEMWarrantyDetailsByFile;
          if (this.dataExportList) {
            this.exportToCsv();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Could not finish the export`,
            });
          }
          opRow.hide();
          this.isLoading = false;
        },
        (e) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Could not finish the export`,
          });
        },
      );
  }

  exportToCsv() {
    this.exportTable.columns = this.selectedCols;
    this.exportTable.value = this.dataExportList;
    this.exportTable.exportCSV();
  }
}
