<app-section-header [title]="'Search Buying Group Results'" [optionHeader]="opHeader"
  [permission]="{type:'tree-dots', operation:'ADD', permission:'Buying Group', category: 'Agent'}" />
<app-tab-spinner *ngIf="isLoading" />
<p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="searchResults" [scrollable]="true" [rows]="10"
  scrollHeight="600px" [hidden]="isLoading">
  <ng-template pTemplate="header" let-columns="">
    <tr>
      <th *ngFor="let col of columns">{{col.header}}</th>
      <th class="last-cell">
        <div class="three-dots" (click)="ellipsisClick($event,'item',opHeader)">
          <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData="" let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <p-cellEditor><ng-template pTemplate="input"><input class="input-text" *ngIf="col.type === 'text'" type="text"
              [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
              [(ngModel)]="rowData[col.field]" />
          </ng-template>
          <ng-template *ngIf="col.type === 'text'" pTemplate="output">{{rowData[col.field]}}</ng-template>
          <ng-template *ngIf="col.type === 'status'" pTemplate="output">
            <button [class]="btnStatus[rowData[col.field].toLowerCase()]">
              {{buyingGroupDisplayStatus[rowData[col.field]] | uppercase}}
            </button></ng-template>
          <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
            <input type="checkbox" [(ngModel)]="rowData[col.field]" />
          </ng-template>
          <ng-template *ngIf="col.field === 'name'" pTemplate="output">
            <a (click)="openTab(rowData)" onmouseover="this.style.cursor='pointer'">{{rowData[col.field] |
              uppercase}}</a></ng-template>
        </p-cellEditor>
      </td>
      <td class="last-cell">
        <div class="three-dots" (click)="ellipsisClick($event,rowData,opRow)"
          *appSetRolePermissionsDirective="{type:'tree-dots', operation:'EDIT-DELETE', permission:'Buying Group', category: 'Agent'}">
          <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event, opRow)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event, opHeader)">
  </p-listbox>
</p-overlayPanel>