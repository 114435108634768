import { Component, OnInit } from '@angular/core';
import { CrmDocumentType } from '../../shared/constants/enums';
import { BuyingGroupDataService } from '../buying-group.service';
import { RequiredColummns } from './buying-group-file.constants';

@Component({
  selector: 'ct-file-upload',
  templateUrl: 'buying-group-file.template.html',
})
export class BuyingGroupDocumentUploadComponent implements OnInit {
  documentType = CrmDocumentType;
  contractRequiredColumns = RequiredColummns.ColumnNames;
  attachmentId: number;
  constructor(private BuyingGroupService: BuyingGroupDataService) {}

  ngOnInit() {
    if (this.BuyingGroupService.buyingGroupConfig) {
      this.attachmentId = this.BuyingGroupService.buyingGroupConfig.searchParams.id;
    }
  }
}
