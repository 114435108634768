import { FormField } from './form-field';

export class TextAreaField extends FormField<string> {
  override controlType = 'textarea';
  override type: string;
  rows: number;

  constructor(options = {}) {
    super(options);
    this.type = options[`type`] || '';
    this.rows = options[`rows`] || 5;
    this.cols = options[`cols`] || 60;
  }
}
