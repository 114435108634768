export const ellipsisHeaderOptions = [
  { label: 'Add New User', value: 'Add New User', callback: 'onRowCreate' }
];
export const ellipsisRowOptions = [
  { label: 'Update', value: 'update', callback: 'onRowUpdate' },
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' }
];
export const ellipsisRestatusHeaderOptions = [
  { label: 'Add', value: 'Add', callback: 'onRowCreate' }
];
export const ellipsisRestatusRowOptions = [
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' }
];

export const permissionEllipsisHeaderOptions = [
  { label: 'Add New Permission', value: 'add', callback: 'onClickAdd' }
];

export const permissionListEllipsisHeaderOptions = [
  { label: 'Add New Permission List', value: 'add', callback: 'onClickAdd' }
];
export const rolePermissionsEllipsisHeaderOptions = [
  { label: 'Add new role permision', value: 'add', callback: 'onClickAdd' }
];
