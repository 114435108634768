import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { Observable } from 'rxjs';
import { NavigationPanel } from '../../../shared/navigation-panel/navigation-panel.model';
import { EmailTemplateService } from '../email-temp.service';

@Component({
  selector: 'modal-template',
  templateUrl: 'modal-temp.template.html',
  styleUrls: ['modal-temp.scss'],
  providers: [FormFieldControlService],
})
export class ModalTempComponent implements OnInit, OnChanges {
  @Input() formFields: FormField<string>[] = [];
  @Input() selectedItem: any;
  form: FormGroup;
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  formContent: string;
  public show: boolean;
  currentScreen = 'des';
  emailTemplate: string;

  constructor(
    private qcs: FormFieldControlService,
    private emailService: EmailTemplateService,
  ) {}
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  ngOnChanges() {
    if (this.selectedItem && this.emailService.templateDetails[this.selectedItem.template_name]) {
      this.emailTemplate =
        this.emailService.templateDetails[this.selectedItem.template_name].template.html;

      const newFormFields: FormField<string>[] = [];
      for (const singleFormField of this.formFields) {
        if (singleFormField.key === 'email subject') {
          singleFormField.value =
            this.emailService.templateDetails[this.selectedItem.template_name].template.subject;
        }
        newFormFields.push(singleFormField);
        this.form = this.qcs.toFormGroup(newFormFields);
      }
    }
  }

  displaySection(currentSetion) {
    this.currentScreen = currentSetion;
  }
}
