import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { CoverageFormFieldService } from '../../coverage-form-field.service';
import { CoverageService } from '../coverage.service';
import { dateSubstring } from '../../../shared/utilities/date-utilities';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { coveredHeaderOptions } from './coverage-details-form.ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'cv-details-form',
  templateUrl: 'coverage-details-form.template.html',
  styleUrls: ['../../../shared/styles/left-details-form.scss'],
  providers: [FormFieldControlService, CoverageFormFieldService],
})
export class CoverageDetailsFormComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  querySubscription: any;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = coveredHeaderOptions;

  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: CoverageFormFieldService,
    private coverageService: CoverageService,
  ) {
    super();
    this.service.getFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: CoverageDetailsFormComponent,
      generalArray: this.formFields,
    };
    // this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: !this.form.valid, type: 'submit' },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: CoverageDetailsFormComponent,
      generalArray: this.button,
    };
    // this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    const profileId = !isNaN(this.searchParams) ? parseInt(this.searchParams, 10) : 0;
    if (profileId) {
      this.querySubscription = this.coverageService
        .getCoverageDetailsForm(profileId)
        .subscribe(({ data, loading }: any) => {
          const modifiedData = data.getCoverageProfileById;
          this.form.patchValue({
            coverageCode: modifiedData.coverageCode,
            coverageName: modifiedData.coverageName,
            coverageGroupCode: modifiedData.coverageGroupCode,
            reportingGroupCode: modifiedData.reportingGroupCode,
            sKUReferenceCode: modifiedData.sKUReferenceCode,
            priceTable: modifiedData.priceTable,
            eligibleFrom: dateSubstring(modifiedData.eligibleFrom),
            eligibleTo: dateSubstring(modifiedData.eligibleTo),
            dop: this.retriveDOPInfo(modifiedData.dop),
            planType: this.retrivePlanTypeInfo(modifiedData.planType),
            effectiveDateBasedOn: this.retriveEffectiveDateBasedOnInfo(
              modifiedData.effectiveDateBasedOn,
            ),
            condition: this.retriveconditionInfo(modifiedData.condition),
            upc: modifiedData.upc,
          });
        });
    }
  }

  retriveDOPInfo(info: string) {
    return info === 'Y' ? { key: 'Yes', value: 'Y' } : { key: 'No', value: 'N' };
  }

  retrivePlanTypeInfo(info: string) {
    return info.toLowerCase() === 'replace'
      ? { key: 'Replace', value: 'Replace' }
      : { key: 'Service', value: 'Service' };
  }

  retriveEffectiveDateBasedOnInfo(info: string) {
    return info === 'Product DOP'
      ? { key: 'Product DOP', value: 'Product DOP' }
      : { key: 'Plan DOP', value: 'Plan DOP' };
  }

  retriveconditionInfo(info: string) {
    switch (info.toLocaleLowerCase()) {
      case 'new':
      case 'n':
        return { key: 'New', value: 'N' };
      case 'used':
      case 'u':
        return { key: 'Used', value: 'U' };
      case 'refurbished':
      case 'r':
        return { key: 'Refurbished', value: 'R' };
      case 'other':
      case 'o':
        return { key: 'Other', value: 'O' };
    }
    return null;
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    this.formValueChanged.emit(this.form.dirty);
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
