<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<div class="crm-container">
  <app-section-header [optionHeader]="opHeader" [title]="'Insurance'" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor><ng-template pTemplate="input"><input class="input-text"
                  *ngIf="col.type === 'text' && col.field !== 'policy_description'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /><input class="input-text"
                  *ngIf="col.type === 'text' && col.field === 'policy_description'" type="text"
                  [(ngModel)]="rowData[col.field]" [disabled]="isDisabled" /><p-dropdown
                  *ngIf="col.type === 'select' && col.field === 'policy_type'" [options]="dropdown[col.field]"
                  (ngModelChange)="onDropDownChange($event, ri)" [(ngModel)]="rowData[col.field]" appendTo="body"></p-dropdown><input
                  class="input-text" *ngIf="col.type === 'date' && col.field === 'policy_begin_date'" type="date"
                  [ngModel]="rowData[col.field]  | date:'yyyy-MM-dd'"
                  (ngModelChange)="policyDateChange($event, rowData)" (keydown)="disableDate()" /><input
                  class="input-text" *ngIf="col.type === 'date' && col.field === 'policy_end_date'" type="date"
                  [ngModel]="rowData[col.field]  | date:'yyyy-MM-dd'"
                  (ngModelChange)="policyEndDateChange($event, rowData)" (keydown)="disableDate()" /><p-dropdown
                  *ngIf="col.type === 'select' && col.field === 'expiration_notification'"
                  [options]="dropdown[col.field]" appendTo="body"
                  [(ngModel)]="rowData[col.field]"></p-dropdown></ng-template><ng-template
                *ngIf="col.type === 'text' || col.type === 'select' || col.type === 'date'"
                pTemplate="output">{{rowData[col.field]}}</ng-template><ng-template *ngIf="col.type === 'checkbox'"
                pTemplate="output"><input type="checkbox" [(ngModel)]="rowData[col.field]" /></ng-template><ng-template
                *ngIf="col.type === 'button'" pTemplate="output"><button class="ui-button-success" pButton="pButton"
                  *ngIf="rowData[col.field]" type="button" pSaveEditableRow="pSaveEditableRow" icon="pi pi-check"
                  style="margin-right: 0.5em"></button><button class="ui-button-danger" pButton="pButton"
                  *ngIf="!rowData[col.field]" type="button" pSaveEditableRow="pSaveEditableRow" icon="pi pi-times"
                  style="margin-right: 0.5em"></button></ng-template><ng-template *ngIf="col.type === 'image'"
                pTemplate="output"><img type="'assets/calendar.svg'"
                  style="margin-right: 0.5em" /></ng-template></p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="ellipsisClick($event,rowData,opRow, ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"></button><button
              class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger" *ngIf="editing"
              pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #opRow><p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-overlayPanel
  #opHeader><p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-toast></p-toast>