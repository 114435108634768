import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { ContractService } from '../contract.service';
import {
  dateSubstring,
  getMMDDYYYFormat,
  dateTimeFormatterCST,
} from '../../shared/utilities/date-utilities';
import { ConfirmationService, MessageService } from 'primeng/api';
import { contractHeaderOptions } from './contract-details-ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AzureLoginService } from 'src/app/services/azure-login.service';
const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');
import { ViewFile } from '../../shared/view-file/view-file.interface';
import { CrmDocumentType } from '../../shared/constants/enums';
import { ViewFileService } from '../../shared/view-file/view-file.service';
import { SYSTEM_USER_ROLES } from '../../shared/constants/user-roles';
import { RolePermissionService } from '../../common/role-permission.service';
import { CancellationModalReponse } from '../contract.constant';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ADDR_UPDATE_ALLOW_ROLES = ['Claims Manager', 'Admin'];

@Component({
  selector: 'contract-details-form',
  templateUrl: 'contract-details-form.template.html',
  styleUrls: ['../../shared/styles/left-details-form.scss'],
  providers: [MessageService, FormFieldControlService, ViewFileService],
})
export class ContractDetailsFormComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  documentSearchParams: ViewFile;
  payLoad: any;
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  querySubscription: any;
  modelData: any;
  isSuccess = false;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = contractHeaderOptions;
  ellipsisHeaderOptionsSec: any;
  nlTransactionDate = null;
  isLoading: boolean;
  contractTerm;
  displayCancelContractModal = false;
  fileData: boolean;
  displayHistory = false;
  historyData: any;
  permissionsHeader: any[] = [
    { type: 'three-dots', operation: 'PRINT', permission: 'Contracts', category: 'Exports' },
    { type: 'three-dots', operation: 'CANCEL', permission: 'Cancel', category: 'Contract' },
  ];
  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private viewFileService: ViewFileService,
    private service: ContractFormFieldService,
    private contractService: ContractService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private azureService: AzureLoginService,
    private confirmationService: ConfirmationService,
    private rps: RolePermissionService,
  ) {
    super();
    this.service.getFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = this.roleService.validateFormFields(data);
    });
    this.documentSearchParams = {
      fileName: '',
      attachmentsType: { key: 'Customer', value: CrmDocumentType.Customer },
      uploadedDate: null,
      offset: 0,
      limit: 20,
      identifierId: null,
    };
  }

  ngOnInit() {
    this.ellipsisHeaderOptions = [];
    this.ellipsisHeaderOptions = contractHeaderOptions;
    this.formFields.map((el) => {
      if (
        el.key === 'status' &&
        ![
          SYSTEM_USER_ROLES.ADMIN,
          SYSTEM_USER_ROLES.CLAIMS_MANAGER,
          SYSTEM_USER_ROLES.CLAIMS_1_PLUS,
          SYSTEM_USER_ROLES.DATA_ADMIN_LIMITED,
        ].includes(this.azureService.roleName)
      ) {
        el.disabled = true;
      }
    });

    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: !this.form.valid, type: 'submit' },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: ContractDetailsFormComponent,
      generalArray: this.button,
    };
    this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.loadContract();
    this.contractService.isContractDataAvailable.subscribe(() => {
      this.loadContract();
    });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'MULTIPLE',
      data: this.permissionsHeader,
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
  }
  loadContract() {
    this.isLoading = true;
    this.contractService.isLoading.next(true);
    const profileId = !isNaN(this.searchParams) ? parseInt(this.searchParams, 10) : 0;
    if (profileId) {
      this.querySubscription = this.contractService
        .getContractDetailsForm(profileId, this.azureService.accountId)
        .subscribe(({ data, loading }: any) => {
          //SR-926913
          if (this.service.contractStatusesToFilterOut.includes(data.getContractProfileById.contractStatusDescription)) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Contract not found. Please close the tab',
            });
            this.contractService.isLoading.next(false);
            return;
          }          
          this.modelData = this.contractService.contractDataModel.leftBaseSection =
            data.getContractProfileById;
          this.ellipsisHeaderOptions = contractHeaderOptions;
          if (
            this.roleService.userRole === 'Admin' ||
            this.roleService.userRole === 'Accounting Manager' ||
            this.roleService.userRole === 'Accounting'
          ) {
            if (
              data.getContractProfileById.contractStatusDescription === 'Cancelled' ||
              data.getContractProfileById.contractStatusDescription === 'Pending' ||
              data.getContractProfileById.contractStatusDescription === 'Void' ||
              data.getContractProfileById.contractStatusDescription === 'Suspended-Cancelled'
            ) {
              this.contractService.isContractCancelled.next(true);
              this.ellipsisHeaderOptions = contractHeaderOptions.filter(
                (el) => el.value !== 'Cancel',
              );
            } else {
              this.contractService.isContractCancelled.next(false);
            }
          } else {
            this.ellipsisHeaderOptions = contractHeaderOptions.filter(
              (el) => el.value !== 'Cancel',
            );
          }
          if (this.roleService.userRole === 'Admin' && this.modelData.isEffectiveDateOverride) {
            this.formFields = this.formFields.map((formField) => {
              if (formField.key === 'effectiveDate') {
                formField = { ...formField, disabled: false };
              }
              return formField;
            });
            this.form = this.qcs.toFormGroup(this.formFields);
          }
          this.updateStatusOptions(this.modelData.contractStatusDescription);
          this.nlTransactionDate = this.modelData.transactionDate;
          let unprocessedFlag =
            dateSubstring(this.modelData.transactionDate) === '1900-01-01' ? true : false;
          if (this.modelData.contractStatusDescription === 'Unprocessed') {
            unprocessedFlag = true;
          }
          this.form.patchValue({
            contractNumber: this.modelData.contractNumber,
            externalId: this.modelData.externalId,
            insurerName: this.modelData.insurerName,
            activationDate: dateSubstring(this.modelData.activationDate),
            uniqueId: this.modelData.uniqueId,
            dop: this.modelData.dop,
            cultureCode: { key: this.modelData.cultureCode, value: this.modelData.cultureCode },
            effectiveDate: dateSubstring(this.modelData.effectiveDate),
            printedDate: dateSubstring(this.modelData.printedDate),
            effectiveBasedOn: this.modelData.effectiveBasedOn,
            expirationDate: dateSubstring(this.modelData.expirationDate),
            shipDate: dateSubstring(this.modelData.shipDate),
            transactionDate:
              dateSubstring(this.modelData.transactionDate) === '1900-01-01'
                ? null
                : dateSubstring(this.modelData.transactionDate),
            billedDate: dateSubstring(this.modelData.billedDate),
            status: {
              key: this.modelData.contractStatusDescription,
              value: this.modelData.contractStatusDescription,
            },
            saleDate: dateSubstring(this.modelData.saleDate),
            agentNumber: this.modelData.agentNumber,
            unprocessedFlag: unprocessedFlag ? ['UNPROCESSED Flag'] : [],
          });
          this.getViewFile();
          this.contractService
            .getContractCoverage(this.contractService.contractConfig.searchParams.id)
            // tslint:disable-next-line: no-shadowed-variable
            .subscribe(({ data }) => {
              this.contractTerm = data.getContractProfileById.coverageInformation.term;
            });
          this.isLoading = false;
          this.contractService.isLoading.next(false);
        });
    }

    this.contractService.onCoveredProductChange.subscribe((data) => {
      this.updateOnCoveredProductChange(data);
    });
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onClickCancelContract(event,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    this.displayCancelContractModal = true;
  }

  onSubmit() {
    window.scroll(0, 0);
    this.messageService.clear();
    this.payLoad = this.populateModel(this.form.getRawValue());
    this.payLoad.contractsIdOriginal = this.modelData && this.modelData.contractsIdOriginal;
    this.payLoad.salesFileName = this.modelData && this.modelData.salesFileName;
    this.payLoad.cancelFileName = this.modelData && this.modelData.cancelFileName;
    if (this.validateContractData()) {
      const errorMessage = this.submitValidations();
      if (errorMessage !== '') {
        let overrideFlag = false;
        if (ADDR_UPDATE_ALLOW_ROLES.includes(this.azureService.roleName)) {
          overrideFlag = true;
        }
        this.confirmationService.confirm({
          message: errorMessage,
          header: 'Contract Validation Error!',
          rejectLabel: 'close',
          acceptLabel: 'override',
          acceptVisible: overrideFlag,
          accept: () => {
            this.saveContract();
          },
        });
      } else {
        this.saveContract();
      }
    }
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  createContractInformation(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Contract Information:',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'Status', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Contract Number', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Ref #Sales Receipt', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Insurance', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'NL Transaction Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.contractStatusDescription, fontSize: 8 },
        { text: bodyData.contractNumber, fontSize: 8 },
        { text: bodyData.externalId, fontSize: 8 },
        { text: bodyData.insurerName, fontSize: 8 },
        { text: getMMDDYYYFormat(bodyData.transactionDate), fontSize: 8 },
      ],
      [
        { text: 'Unique ID', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'DOP', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Sale Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Effect Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Printed Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.uniqueId, fontSize: 8 },
        { text: bodyData.dop, fontSize: 8 },
        { text: getMMDDYYYFormat(bodyData.saleDate), fontSize: 8 },
        { text: getMMDDYYYFormat(bodyData.effectiveDate), fontSize: 8 },
        { text: getMMDDYYYFormat(bodyData.printedDate), fontSize: 8 },
      ],
      [
        { text: 'Effective Based On', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Exp Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Ship Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Dealer Group Name', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Dealer Group Number', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.effectiveBasedOn, fontSize: 8 },
        { text: getMMDDYYYFormat(bodyData.expirationDate), fontSize: 8 },
        { text: getMMDDYYYFormat(bodyData.shipDate), fontSize: 8 },
        { text: bodyData.dealerGroupName, fontSize: 8 },
        { text: bodyData.dealerGroupNumber, fontSize: 8 },
      ],
      [
        { text: 'Self Servicing', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Product DOP', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: '', fillColor: '#D3F8D3' },
        { text: '', fillColor: '#D3F8D3' },
        { text: '', fillColor: '#D3F8D3' },
      ],
      [
        {
          text: this.contractService.contractDataModel.mainTab.additionalInformation.tSelfServicing,
          fontSize: 8,
        },
        { text: bodyData.dop, fontSize: 8 },
        { text: '' },
        { text: '' },
        { text: '' },
      ],
    );

    return finalBody;
  }
  createCustomerInformation(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Customer Information:',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'First Name', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Last Name', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Primary Number', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Secondary Number', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.customerInformation.customerFirstName, fontSize: 8 },
        { text: bodyData.customerInformation.customerLastName, fontSize: 8 },
        { text: bodyData.customerInformation.customerPhone, fontSize: 8 },
        { text: bodyData.customerInformation.phoneNumber2, fontSize: 8 },
      ],
      [
        { text: 'Email Address', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Guardian', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Country', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Address Line 1', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.customerInformation.customerEmail, fontSize: 8 },
        { text: bodyData.customerInformation.guardian, fontSize: 8 },
        { text: bodyData.addresses.country, fontSize: 8 },
        { text: bodyData.addresses.address1, fontSize: 8 },
      ],
      [
        { text: 'Address Line 2', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Zip', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'State', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'City', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.addresses.address2, fontSize: 8 },
        { text: bodyData.addresses.zipCode, fontSize: 8 },
        { text: bodyData.addresses.state, fontSize: 8 },
        { text: bodyData.addresses.city, fontSize: 8 },
      ],
    );
    return finalBody;
  }
  createCoveredProducts(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    for (const singleNote of bodyData) {
      finalBody.push([
        { text: singleNote.productNo, fontSize: 8 },
        { text: singleNote.manufacturerManufacturer, fontSize: 8 },
        { text: singleNote.modelNumber, fontSize: 8 },
        { text: singleNote.serialNumber, fontSize: 8 },
        { text: singleNote.productPurchasePrice, fontSize: 8 },
        { text: singleNote.subcategoryCode, fontSize: 8 },
        { text: singleNote.type, fontSize: 8 },
        { text: getMMDDYYYFormat(singleNote.calculatedFields.mfwExp), fontSize: 8 },
        { text: singleNote.calculatedFields.mfwCov, fontSize: 8 },
        { text: singleNote.partsTerm, fontSize: 8 },
        { text: singleNote.laborTerm, fontSize: 8 },
        { text: singleNote.majorComponentTerm, fontSize: 8 },
        { text: singleNote.serviceLocation, fontSize: 8 },
        { text: singleNote.fulfilled, fontSize: 8 },
        { text: getMMDDYYYFormat(singleNote.productPurchaseDate), fontSize: 8 },
      ]);
    }
    return finalBody;
  }

  createAssociatedContracts(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'Na']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Associated Contracts:',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'Contract #', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Model Number', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Serial Number', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
    );
    for (const singleNote of bodyData.associatedContracts) {
      finalBody.push([
        { text: singleNote.contractNumber, fontSize: 8 },
        { text: singleNote.modelName, fontSize: 8 },
        { text: singleNote.serialNumber, fontSize: 8 },
      ]);
    }
    return finalBody;
  }

  createGeneralFields(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'General Fields',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'General 1', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'General 2', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'General 3', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'General 4', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.generalFields.general1, fontSize: 8 },
        { text: bodyData.generalFields.general2, fontSize: 8 },
        { text: bodyData.generalFields.general3, fontSize: 8 },
        { text: bodyData.generalFields.general4, fontSize: 8 },
      ],
    );
    return finalBody;
  }
  createAdditionalFields(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Additional Information',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        {
          text: '',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        {
          text: '',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
      ],
      [
        { text: 'Selling Retailer Name', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Selling Retailer Code', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Buying Group', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.additionalInformation.storeLocationName, fontSize: 8 },
        { text: bodyData.additionalInformation.storeLocationNumber, fontSize: 8 },
        { text: bodyData.additionalInformation.agentName, fontSize: 8 },
      ],
    );
    return finalBody;
  }
  createCoverageBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Coverage:',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'Coverage Code', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Coverage Description', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Term', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Retail Cost', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
      [
        { text: bodyData.coverageInformation.coverageCode, fontSize: 8 },
        { text: bodyData.coverageInformation.coverageDescription, fontSize: 8 },
        { text: this.contractTerm, fontSize: 8 },
        { text: bodyData.coverageInformation.retailRate, fontSize: 8 },
      ],
    );
    return finalBody;
  }
  createDocumentsBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Documents',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'File Name', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Description', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Uploaded Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Uploaded By', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
    );
    for (const singleDoc of bodyData.fileAttachments) {
      finalBody.push([
        { text: singleDoc.fileName, fontSize: 8 },
        { text: singleDoc.description, fontSize: 8 },
        { text: dateTimeFormatterCST(singleDoc.uploadedDate), fontSize: 8 },
        { text: singleDoc.uploadedBy, fontSize: 8 },
      ]);
    }
    return finalBody;
  }

  onCancellationResponse(event: CancellationModalReponse) {
    this.displayCancelContractModal = false;
    if (event.status != null) {
      if (event.status) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Contract cancelled successfully.`,
        });
        this.loadContract();
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: event?.error?.message || 'something went wrong',
        });
      }
    }
  }

  createClaimsBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Claims:',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'Service Order #', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Status', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Approved Amount', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Payment Amount', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Sub Category', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Model #', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Created Date', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
    );
    for (const singleNote of bodyData.soClaims) {
      finalBody.push([
        { text: singleNote.serviceOrderNumber, fontSize: 8 },
        { text: singleNote.claimStatusDescription, fontSize: 8 },
        { text: `$${singleNote.claimAmount.approvedAmount.toFixed(2)}`, fontSize: 8 },
        { text: `$${singleNote.claimAmount.paymentAmount.toFixed(2)}`, fontSize: 8 },
        { text: singleNote.coveredProduct.subcategoryDescription, fontSize: 8 },
        { text: singleNote.coveredProduct.modelNumber, fontSize: 8 },
        { text: getMMDDYYYFormat(singleNote.claimDate), fontSize: 8 },
      ]);
    }
    return finalBody;
  }
  private removeTags(str) {
    if (str === null || str === '') {
      return '';
    } else {
      str = str.toString();
      str = str.replace(/&nbsp;/gi, ' ');
      str = str.replace(/<\/p>/gi, '\n');
      return str.replace(/(<([^>]+)>)/gi, '');
    }
  }

  createNotesBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Notes:',
          bold: true,
          fontSize: 8,
          fillColor: '#8cc640',
          border: [false, false, false, false],
        },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
        { text: '', fillColor: '#8cc640', border: [false, false, false, false] },
      ],
      [
        { text: 'Type', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Note', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
        { text: 'Date/Time Stamp', bold: true, fontSize: 8, fillColor: '#D3F8D3' },
      ],
    );
    for (const singleNote of bodyData) {
      finalBody.push([
        { text: singleNote.noteTypeDescription, fontSize: 8 },
        { text: this.removeTags(singleNote.noteText), fontSize: 8 },
        { text: dateTimeFormatterCST(singleNote.editDateTime), fontSize: 8 },
      ]);
    }
    return finalBody;
  }

  createLolBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push([
      { text: 'PD#', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'MFW', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'MODEL#', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'SERIAL#', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'PPD', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'PPP', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'LIABILITY SPENT', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'LIABILITY REMAINING', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
      { text: 'TOTAL LIABILITY', fontSize: 8, bold: true, fillColor: '#D3F8D3' },
    ]);
    for (const singleProd of bodyData) {
      finalBody.push([
        { text: singleProd.productNo, fontSize: 8 },
        { text: singleProd.calculatedFields.message, fontSize: 8 },
        { text: singleProd.modelNumber, fontSize: 8 },
        { text: singleProd.serialNumber, fontSize: 8 },
        { text: getMMDDYYYFormat(singleProd.productPurchaseDate), fontSize: 8 },
        { text: singleProd.productPurchasePrice, fontSize: 8 },
        {
          text: singleProd.lolVirtualCalculatedFields.liabilitySpent
            ? `$${singleProd.lolVirtualCalculatedFields.liabilitySpent.toFixed(2)}`
            : '$0.00',
          fontSize: 8,
        },
        {
          text: singleProd.lolVirtualCalculatedFields.liabilityRemaining
            ? `$${singleProd.lolVirtualCalculatedFields.liabilityRemaining.toFixed(2)}`
            : '$0.00',
          fontSize: 8,
        },
        {
          text: singleProd.lolVirtualCalculatedFields.totalLiability
            ? `$${singleProd.lolVirtualCalculatedFields.totalLiability.toFixed(2)}`
            : '$0.00',
          fontSize: 8,
        },
      ]);
    }
    return finalBody;
  }

  createDocumentDefinition() {
    const contractInformationBody = this.createContractInformation(this.modelData);
    const createCustomerInformationBody = this.createCustomerInformation(
      this.contractService.contractDataModel.mainTab,
    );
    const createCoveredProductsBody = this.createCoveredProducts(
      this.contractService.contractDataModel.mainTab.coveredProducts,
    );
    const createAssociatedContractsBody = this.createAssociatedContracts(
      this.contractService.contractDataModel.mainTab,
    );
    const createGeneralFieldsBody = this.createGeneralFields(
      this.contractService.contractDataModel.mainTab,
    );
    const createAdditionalFieldsBody = this.createAdditionalFields(
      this.contractService.contractDataModel.mainTab,
    );
    const coverageBody = this.createCoverageBody(this.contractService.contractDataModel.mainTab);
    const documentsBody = this.createDocumentsBody(this.contractService.contractDataModel);
    const claimsBody = this.createClaimsBody(this.contractService.contractDataModel);
    const notesBody = this.createNotesBody(this.contractService.contractDataModel.notes);
    const lolBody = this.createLolBody(
      this.contractService.contractDataModel.mainTab.coveredProducts,
    );

    const documentDefinition = {
      content: [
        {
          table: {
            widths: [96, 96, 96, 105, 96],
            body: contractInformationBody,
          },
        },
        '\n',
        {
          table: {
            widths: [115, 120, 110, 155],
            body: createCustomerInformationBody,
          },
        },
        '\n',
        {
          table: {
            widths: [527],
            body: [
              [
                {
                  text: 'Covered Products:',
                  bold: true,
                  fontSize: 8,
                  fillColor: '#8cc640',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [18, 29, 38, 42, 30, 32, 21, 39, 20, 15, 15, 18, 30, 15, 39],
            body: [
              [
                {
                  text: 'PD#',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'MFW',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'MODEL#',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'SERIAL#',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'PPP',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'SUBCAT',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'TYPE',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'MFW EXP',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'MFW COV',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'PRT',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'LBR',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'MJC',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'SERVICE LOC',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'FUL',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
                {
                  text: 'PPD',
                  bold: true,
                  fontSize: 8,
                  border: [true, true, true, false],
                  fillColor: '#D3F8D3',
                },
              ],
            ],
          },
        },
        {
          layout: {
            fillColor(rowIndex) {
              return rowIndex % 2 === 0 ? null : '#ADD8E6';
            },
          },
          table: {
            widths: [18, 29, 38, 42, 30, 32, 21, 39, 20, 15, 15, 18, 30, 15, 39],
            body: createCoveredProductsBody,
          },
        },
        '\n',
        {
          table: {
            widths: [528],
            body: [
              [
                {
                  text: 'Limit of Liability:',
                  bold: true,
                  fontSize: 8,
                  fillColor: '#8cc640',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          layout: {
            fillColor(rowIndex) {
              return rowIndex % 2 === 0 ? null : '#ADD8E6';
            },
          },
          table: {
            widths: [18, 137, 50, 55, 40, 30, 40, 48, 38],
            body: lolBody,
          },
        },
        '\n',
        {
          layout: {
            fillColor(rowIndex) {
              return rowIndex % 2 === 0 ? null : '#ADD8E6';
            },
          },
          table: {
            widths: [170, 170, 170],
            body: createAssociatedContractsBody,
          },
        },
        '\n',
        {
          layout: {
            fillColor(rowIndex) {
              return rowIndex % 2 === 0 ? null : '#ADD8E6';
            },
          },
          table: {
            widths: [125, 125, 125, 125],
            body: createGeneralFieldsBody,
          },
        },
        '\n',
        {
          table: {
            widths: [170, 170, 170],
            body: createAdditionalFieldsBody,
          },
        },
        '\n',
        {
          table: {
            widths: [110, 160, 110, 120],
            body: coverageBody,
          },
        },
        '\n',
        {
          table: {
            widths: [110, 160, 110, 120],
            body: documentsBody,
          },
        },
        '\n',
        {
          layout: {
            fillColor(rowIndex) {
              return rowIndex % 2 === 0 ? null : '#ADD8E6';
            },
          },
          table: {
            widths: [60, 74, 74, 74, 74, 60, 58],
            body: claimsBody,
          },
        },
        '\n',
        {
          table: {
            widths: [100, 310, 100],
            body: notesBody,
          },
        },
      ],
    };
    return documentDefinition;
  }
  onClickPrint(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    const documentDefinition = this.createDocumentDefinition();
    const pdfDoc = pdfMake.createPdf(documentDefinition).open();
  }
  getViewFile() {
    let attachmentType;
    this.documentSearchParams.identifierId =
      this.contractService.contractConfig.searchParams.customerId;
    if (this.documentSearchParams.attachmentsType) {
      attachmentType =
        this.documentSearchParams.attachmentsType.key === 'Import Contracts'
          ? { key: 'Customer' }
          : this.documentSearchParams.attachmentsType;
    }
    this.querySubscription = this.viewFileService
      .getViewFileSearchResult(
        this.documentSearchParams,
        this.documentSearchParams.attachmentsType
          ? this.documentSearchParams.attachmentsType.value
          : CrmDocumentType.Agent,
        attachmentType,
      )
      .subscribe(({ data }) => {
        this.contractService.contractDataModel.fileAttachments = data.getFileAttachments;
      });
  }
  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  private populateModel(form) {
    let retailRate = this.contractService.contractDataModel.mainTab.coverageInformation.retailRate;
    if (retailRate && retailRate !== '') {
      retailRate = retailRate.replace('$', '');
      retailRate = parseFloat(retailRate);
    }
    return {
      contractNumber: form.contractNumber,
      externalId: form.externalId,
      insurerName: form.insurerName,
      activationDate: form.activationDate,
      uniqueId: form.uniqueId,
      dop: form.dop,
      cultureCode: form.cultureCode,
      effectiveDate: form.effectiveDate,
      expirationDate: form.expirationDate,
      entryDate: form.entryDate,
      transactionDate:
        dateSubstring(this.nlTransactionDate) === '1900-01-01' && !form.transactionDate
          ? this.nlTransactionDate
          : form.transactionDate,
      printedDate: form.printedDate,
      shipDate: form.shipDate,
      saleDate: form.saleDate,
      agentNumber: form.agentNumber,
      billedDate: form.billedDate,
      effectiveBasedOn: form.effectiveBasedOn,
      isEffectiveDateOverride: this.contractService.contractDataModel
        ? this.contractService.contractDataModel.leftBaseSection.isEffectiveDateOverride
        : false,
      storeLocationName:
        this.contractService.contractDataModel.mainTab.additionalInformation.storeLocationName,
      storeLocationNumber:
        this.contractService.contractDataModel.mainTab.additionalInformation.storeLocationNumber,
      agentName: this.contractService.contractDataModel.mainTab.additionalInformation.agentName,
      salesClerk: this.contractService.contractDataModel.mainTab.additionalInformation.salesClerk,
      general1: this.contractService.contractDataModel.mainTab.generalFields.general1,
      general2: this.contractService.contractDataModel.mainTab.generalFields.general2,
      general3: this.contractService.contractDataModel.mainTab.generalFields.general3,
      general4: this.contractService.contractDataModel.mainTab.generalFields.general4,
      customerInfo: {
        customerFirstName:
          this.contractService.contractDataModel.mainTab.customerInformation.customerFirstName,
        customerLastName:
          this.contractService.contractDataModel.mainTab.customerInformation.customerLastName,
        customerPhone:
          this.contractService.contractDataModel.mainTab.customerInformation.customerPhone,
        phoneNumber2:
          this.contractService.contractDataModel.mainTab.customerInformation.phoneNumber2,
        customerEmail:
          this.contractService.contractDataModel.mainTab.customerInformation.customerEmail,
        customerFax: this.contractService.contractDataModel.mainTab.customerInformation.customerFax,
        guardian: this.contractService.contractDataModel.mainTab.customerInformation.guardian,
        customerIdOriginal:
          this.contractService.contractDataModel.mainTab.customerInformation.customerIdOriginal,
        phoneTypeCode1: this.contractService.contractDataModel.mainTab.customerInformation
          .phoneTypeCode1
          ? this.contractService.contractDataModel.mainTab.customerInformation.phoneTypeCode1.value
          : null,
        phoneTypeCode2: this.contractService.contractDataModel.mainTab.customerInformation
          .phoneTypeCode2
          ? this.contractService.contractDataModel.mainTab.customerInformation.phoneTypeCode2.value
          : null,
      },
      addresses: {
        address1: this.contractService.contractDataModel.mainTab.addresses.address1,
        address2: this.contractService.contractDataModel.mainTab.addresses.address2,
        zipCode: this.contractService.contractDataModel.mainTab.addresses.zipCode,
        state: this.contractService.contractDataModel.mainTab.addresses.state,
        city: this.contractService.contractDataModel.mainTab.addresses.city,
        country: this.contractService.contractDataModel.mainTab.addresses.country,
        addressIDOriginal:
          this.contractService.contractDataModel.mainTab.addresses.addressIDOriginal,
      },
      contractStatusCode: null,
      contractStatusDescription: form.status.key,
      insertUserName: this.azureService.accountId,
      dealerGroupNumber:
        this.contractService.contractDataModel.mainTab.additionalInformation.dealerGroupNumber,
      dealerGroupName:
        this.contractService.contractDataModel.mainTab.additionalInformation.dealerGroupName,
      coverageCode: this.contractService.contractDataModel.mainTab.coverageInformation.coverageCode,
      coverageDescription:
        this.contractService.contractDataModel.mainTab.coverageInformation.coverageDescription,
      productTypeCode:
        this.contractService.contractDataModel.mainTab.coverageInformation.productTypeCode,
      sKUReferenceCode:
        this.contractService.contractDataModel.mainTab.coverageInformation.sKUReferenceCode,
      insurerNumber:
        this.contractService.contractDataModel.mainTab.coverageInformation.insurerNumber,
      retailRate,
    };
  }

  updateStatusOptions(statusDescription) {
    const newFormFields: FormField<string>[] = [];

    const optionList = [];
    const excludeList: any = {};
    let currentOptionList = [];

    // Retrieve all the current statuses as a base.
    for (const singleFormField of this.formFields) {
      if (singleFormField.key === 'status') {
        currentOptionList = singleFormField.options;
      }
    }

    // Only allow Admin and 'Call Center Manager' to change from Pending to Active.
    // Change by permissions´
    if (
      statusDescription &&
      statusDescription === 'Active' &&
      !this.roleService.validateIfPermissionsExist({
        category: 'Contract',
        permission: 'Active to Pending',
        operation: 'EDIT',
        type: 'Code',
      })
    ) {
      excludeList.Pending = true;
    }

    // Only allow Admin and 'Data Admin - Limited' to change from Expired to Active.
    if (
      statusDescription &&
      statusDescription === 'Expired' &&
      !this.roleService.validateIfPermissionsExist({
        category: 'Contract',
        permission: 'Expired to Active',
        operation: 'EDIT',
        type: 'Code',
      })
    ) {
      excludeList.Active = true;
    }
    // Allow update to active in any state
    if (
      statusDescription &&
      statusDescription !== 'Expired' &&
      !this.roleService.validateIfPermissionsExist({
        category: 'Contract',
        permission: 'Update to Active',
        operation: 'EDIT',
        type: 'Code',
      })
    ) {
      excludeList.Active = true;
    }
    // Allow update to void in any state
    if (
      statusDescription &&
      !this.roleService.validateIfPermissionsExist({
        category: 'Contract',
        permission: 'Update to Void',
        operation: 'EDIT',
        type: 'Code',
      })
    ) {
      excludeList.Void = true;
    }

    // Populate the new list, but exclude the ones that do not have permission.
    for (const singleOption of currentOptionList) {
      if (
        (statusDescription && statusDescription === singleOption.key) ||
        !(singleOption.key in excludeList)
      ) {
        optionList.push(singleOption);
      }
    }

    // Replace the option list for status.
    for (const singleFormField of this.formFields) {
      if (singleFormField.key === 'status') {
        singleFormField.options = optionList;
      }
      newFormFields.push(singleFormField);
    }

    this.formFields = [];
    this.formFields = newFormFields;
    this.cdr.detectChanges();
  }

  getNewExpDate(startDate, numOfMonthsCalculation) {
    const sDate = new Date(startDate);
    sDate.setUTCMonth(sDate.getUTCMonth() + numOfMonthsCalculation);
    sDate.setUTCDate(sDate.getUTCDate() - 1);
    return sDate;
  }

  updateOnCoveredProductChange(coveredProdData) {
    let coveredProducts = this.contractService.contractDataModel.mainTab.coveredProducts;
    if (coveredProdData && coveredProducts) {
      if (coveredProdData.rowData) {
        coveredProducts = [
          ...coveredProducts.filter(
            (x) => x.productsIdOriginal !== coveredProdData.rowData.productsIdOriginal,
          ),
          coveredProdData.rowData,
        ];
        coveredProducts = coveredProducts.sort(
          (a, b) =>
            Math.abs(new Date(a.productPurchaseDate).getTime()) -
            Math.abs(new Date(b.productPurchaseDate).getTime()),
        );
      }
      this.onSubmit();
    }
  }

  submitValidations() {
    const OPEN_CLAIMS = ['APPROVED FOR PAYMENT'];
    let errorMessage = '';
    if (
      this.contractService.contractDataModel.mainTab.addresses.address1 !==
        this.contractService.contractDataModel.mainTabPrev.addresses.address1 ||
      this.contractService.contractDataModel.mainTab.addresses.address2 !==
        this.contractService.contractDataModel.mainTabPrev.addresses.address2 ||
      this.contractService.contractDataModel.mainTab.addresses.zipCode !==
        this.contractService.contractDataModel.mainTabPrev.addresses.zipCode ||
      this.contractService.contractDataModel.mainTab.addresses.state !==
        this.contractService.contractDataModel.mainTabPrev.addresses.state ||
      this.contractService.contractDataModel.mainTab.addresses.city !==
        this.contractService.contractDataModel.mainTabPrev.addresses.city ||
      this.contractService.contractDataModel.mainTab.addresses.country !==
        this.contractService.contractDataModel.mainTabPrev.addresses.country
    ) {
      let claimCount = 0;
      const soClaims = this.contractService.contractDataModel.soClaims;
      for (const singleClaim of soClaims) {
        if (OPEN_CLAIMS.includes(singleClaim.claimStatusDescription)) {
          if (claimCount === 0) {
            errorMessage +=
              '<i>The following claim(s) are in the process of being paid and the address cannot ';
            errorMessage +=
              'be changed at this time.  Please contact your System Administrator or Claims Manager ';
            errorMessage += 'for more information</i><br>';
            errorMessage += '<ul>';
          }
          errorMessage += `<li>${singleClaim.serviceOrderNumber} | ${singleClaim.claimStatusDescription}</li>`;
          claimCount++;
        }
      }
      if (errorMessage !== '') {
        errorMessage += '</ul>';
      }
    }
    return errorMessage;
  }

  saveContract() {
    this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = true));
    this.isLoading = true;
    this.contractService.isLoading.next(true);
    this.contractService.saveContractProfileAndMainTab(this.payLoad).subscribe(
      (data: any) => {
        if (this.modelData) {
          this.modelData = {
            ...this.modelData,
            contractsIdOriginal: data.data.createUpdateContract.contractsIdOriginal,
          };
        } else {
          this.modelData = {
            contractsIdOriginal: data.createUpdateContract.contractsIdOriginal,
          };
        }
        if (this.payLoad.contractStatusDescription === 'Active') {
          this.contractService.isContractCancelled.next(false);
          this.ellipsisHeaderOptions = contractHeaderOptions;
        }
        if (this.payLoad.contractStatusDescription === 'Cancelled') {
          this.contractService.isContractCancelled.next(true);
          this.ellipsisHeaderOptions = contractHeaderOptions.filter((el) => el.value !== 'Cancel');
        }
        this.isLoading = false;
        this.contractService.isLoading.next(false);
        this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = false));
        this.isSuccess = true;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Saved successfully.',
        });
        const unprocessedFlag =
          this.payLoad.contractStatusDescription === 'Unprocessed' ? true : false;

        this.form.patchValue({
          effectiveDate: dateSubstring(data.data.createUpdateContract.effectiveDate),
          expirationDate: dateSubstring(data.data.createUpdateContract.expirationDate),
          unprocessedFlag: unprocessedFlag ? ['UNPROCESSED Flag'] : [],
        });
        this.contractService.isEffectiveDateUpdated.next(
          dateSubstring(data.data.createUpdateContract.effectiveDate),
        );
        setTimeout(() => {
          this.isSuccess = false;
        }, 3000);
      },
      (err) => {
        this.isLoading = false;
        this.contractService.isLoading.next(false);
        this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = false));
        throw err;
      },
    );
    this.formValueChanged.emit(this.form.dirty);
  }

  validateContractData() {
    const { addresses, customerInfo, contractStatusDescription } = this.payLoad;
    if (
      !addresses.address1 ||
      !addresses.zipCode ||
      !addresses.state ||
      !addresses.city ||
      !addresses.country ||
      !customerInfo.customerFirstName ||
      !customerInfo.customerLastName ||
      !customerInfo.customerPhone ||
      !customerInfo.phoneTypeCode1
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill required fields',
      });
      return false;
    }

    if (contractStatusDescription === 'Suspended-Cancelled') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Sorry, you cannot change the status to ${contractStatusDescription}`,
      });
      return false;
    }

    if (customerInfo.phoneNumber2 && !customerInfo.phoneTypeCode2) {
      {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Phone Type Code 2 is required if Phone Number 2 is populated.',
        });
        return false;
      }
    }
    return true;
  }

  onViewHistory(event,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    const id = this.modelData && this.modelData.contractsIdOriginal;
    if (id) {
      this.historyData = {
        type: 'contracts',
        id,
      };
      this.displayHistory = true;
    }
  }
}
