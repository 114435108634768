import { NavLeft, SidebarSubMenuItem } from './sidebar-navigation';

export const NAVLEFTITEM: NavLeft[] = [
  { id: 1, name: 'CT', title: 'Contracts' },
  { id: 2, name: 'SV', title: 'Service' },
  { id: 3, name: 'NO', title: 'Notification' },
  { id: 4, name: 'BG', title: 'Buying Group' },
  { id: 5, name: 'TS', title: 'TroubleShooting' },
  { id: 6, name: 'DL', title: 'Dealer' },
  { id: 7, name: 'CV', title: 'Coverage' },
  { id: 8, name: 'CL', title: 'Catalog' },
  { id: 9, name: 'FM', title: 'Forms' },
  { id: 10, name: 'CP', title: 'Claim Payment' },
  { id: 11, name: 'SR', title: 'Sales Register' },
  { id: 12, name: 'IP', title: 'Imports' },
  { id: 13, name: 'SEC', title: 'Security' },
  { id: 14, name: 'CFG', title: 'Configuration' },
  { id: 15, name: 'RPT', title: 'Reports' },
];

export const CONTRACTSLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'My Work Queue',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'My Work Queue',
      category: 'Work Queue',
    },
  },
  {
    id: 2,
    name: 'Contracts',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Contract',
      category: 'Contract',
    },
  },
  {
    id: 3,
    name: 'User',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'User',
      category: 'Contract',
    },
  },
  {
    id: 4,
    name: 'User Registration',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'User Registration',
      category: 'Contract',
    },
  },
  {
    id: 5,
    name: 'My Inquiry Queue',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Inquiry Queue',
      category: 'Contract',
    },
  },
];

export const SERVICELIST: SidebarSubMenuItem[] = [
  { id: 1, name: 'Submit to Provider', hasTab: true },
  { id: 2, name: 'Service Order', hasTab: true },
  {
    id: 3,
    name: 'Servicer',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Servicer',
      category: 'Servicer',
    },
  },
  { id: 4, name: 'Dashboard', hasTab: true },
  { id: 5, name: 'Service Center Lookup', hasTab: true },
  { id: 6, name: 'Claim Adjudication Tool', hasTab: true },
  { id: 7, name: 'Claim Payment Notification', hasTab: true },
];

export const NOTIFICATIONLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Setup Alerts',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'alerts',
      category: 'Notifications',
    },
  },
];

export const BUYINGGROUPLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Buying Group',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Buying Group',
      category: 'Agent',
    },
  },
];

export const TROUBLESHOOTINGLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Failure Type',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Symptoms',
      category: 'Troubleshooting',
    },
  },
  { id: 2, name: 'Resolutions', hasTab: true },
  { id: 3, name: 'Queue', hasTab: true },
];

export const DEALERLOCATIONLIST: SidebarSubMenuItem[] = [
  { id: 1, name: 'Dealer Location', hasTab: true },
  {
    id: 2,
    name: 'Dealer',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Dealer',
      category: 'Seller',
    },
  },
  { id: 3, name: 'Rate Profile', hasTab: true },
  { id: 4, name: 'Payee', hasTab: true },
];

export const COVERAGELIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Coverage',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Coverage',
      category: 'Coverage',
    },
  },
  { id: 2, name: 'Components', hasTab: true },
  { id: 3, name: 'Loss Codes', hasTab: true },
  {
    id: 4,
    name: 'Price Table',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Price Table',
      category: 'Coverage',
    },
  },
  {
    id: 5,
    name: 'Rate Bucket Configuration',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Bucket Configuration',
      category: 'Coverage',
    },
  },
  { id: 6, name: 'Cancellation Methods', hasTab: true },
  { id: 7, name: 'Cancellation Rules', hasTab: true },
  {
    id: 8,
    name: 'Coverage Groups',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Coverage Groups',
      category: 'Coverage',
    },
  },
  {
    id: 9,
    name: 'Insurance Company',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Insurance Company',
      category: 'Insurance Company',
    },
  },
];

export const CATALOGLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'OEM Warranty',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Manufacturers',
      category: 'Catalogs',
    },
  },
  {
    id: 2,
    name: 'Manufacturer',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Manufacturers',
      category: 'Catalogs',
    },
  },
  {
    id: 4,
    name: 'Category',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Categories',
      category: 'Catalogs',
    },
  },
  {
    id: 5,
    name: 'Subcategory',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Subcategories',
      category: 'Catalogs',
    },
  },
  { id: 7, name: 'Price Paid', hasTab: true },
  {
    id: 8,
    name: 'Work Queue List',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Work Queue',
      category: 'Catalogs',
    },
  },
];

export const FORMLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Search Message',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Message',
      category: 'Form Definition',
    },
  },
];

export const CLAIMLIST: SidebarSubMenuItem[] = [
  { id: 1, name: 'Process Claims', hasTab: true },
  { id: 2, name: 'Claim Payment History', hasTab: true },
  { id: 3, name: 'Import Claim Payment', hasTab: true },
  { id: 4, name: 'Search Documents', hasTab: false },
];

export const SECURITYLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Users',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'User Profile',
      category: 'User Security',
    },
  },
  { id: 2, name: 'Roles', hasTab: true },
  { id: 3, name: 'Operation', hasTab: true },
  { id: 4, name: 'Permission List', hasTab: true },
  { id: 5, name: 'Permissions', hasTab: true },
  { id: 6, name: 'Role Permissions', hasTab: true },
  { id: 7, name: 'Restatus', hasTab: true },
];

export const CONFIGURATIONLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Admin Menu Configuration',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Admin Configuration Screen',
      category: 'Configuration',
    },
  },
];

export const DOCUMENTLIST: SidebarSubMenuItem[] = [
  {
    id: 1,
    name: 'Import File',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'ADD',
      permission: 'Documents',
      category: 'Import',
    },
  },
  {
    id: 2,
    name: 'Search Documents',
    hasTab: false,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Documents',
      category: 'Import',
    },
  },
];

export const SALESREGISTERLIST: SidebarSubMenuItem[] = [
  { id: 1, name: 'Batch Processing', hasTab: true },
  {
    id: 2,
    name: 'Transaction Management',
    hasTab: true,
    hasPermissions: true,
    permissions: {
      type: 'sideMenu',
      operation: 'READ_ONLY',
      permission: 'Transaction Management',
      category: 'User Security',
    },
  },
];

export const REPORTSLIST: SidebarSubMenuItem[] = [
  { id: 1, name: 'Insurance Extract', hasTab: true },
];
