<div class="main-tab">
  <app-section-header [title]="'Main'" />
  <contact-information [formFields]="contactInfo" [formData]="contactFormData"
    (modelDataChange)="onContactInfoChange($event)"></contact-information>
  <div class="customer-information">
    <div class="title">Mailing Address</div>
    <address *ngIf="true" [(modelData)]="dealerDataService.mailingAddressFinal" [enableModelData]="true"></address>
  </div>
  <div class="customer-information">
    <div class="title">Billing Address</div>
    <address *ngIf="true" [(modelData)]="dealerDataService.billingAddressFinal" [enableModelData]="true"></address>
  </div>
  <general-fields [formFields]="generalInfo" [formData]="genericFormData" [selected]="false"
    (modelDataChange)="onGeneralInfoChange($event)"></general-fields>
</div>