<p-dialog [header]="modalTitle" [(visible)]="displayDialog" [responsive]="true" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content" *ngIf="!loadingTable">
    <p *ngIf="!loadingTable && dataList && dataList.length === 0">No Records Found</p>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" *ngIf="dataList && dataList.length > 0"
      [columns]="selectedColumns" dataKey="id" [value]="dataList" [rows]="20">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
        <tr>
          <td *ngFor="let col of columns;let indexOfelement=index;">
            <p-cellEditor>
              <ng-template pTemplate="output">
                <div class="change-highlight" *ngIf="rowData[col.field].isChange === true"> {{ rowData[col.field].text
                  }}
                </div>
                <div *ngIf="rowData[col.field].isChange === false"> {{ rowData[col.field].text }} </div>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-footer [style]="{'overflow': 'hidden','max-height': 'max-content','height': 'fit-content'}">
    <div class="ui-dialog-buttonpane ui-helper-clearfix"><button class="crm-button" *ngIf="!loadingTable" type="button"
        (click)="loadHistory()" label="Close">Reload</button><button class="crm-button" type="button"
        (click)="onCancel()" label="Close">Close</button></div>
  </p-footer>
</p-dialog>