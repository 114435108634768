import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SecurityTypeService } from '../../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../../services/contract-form-field.service';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'security-permission-option',
  templateUrl: 'sec-perm-config.template.html',
  providers: [SecurityTypeService, RoleAuthorizationService, ContractFormFieldService],
})
export class SecPermConfigComponent extends FormCanDeactivate implements OnInit {
  cols: any[];
  dataList: any[];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  constructor(
    private configurationService: SecurityTypeService,
    private cdr: ChangeDetectorRef,
    private contractService: ContractFormFieldService,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.configurationService.getSecPermConfigTypes().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'rule_category', header: 'RULE CATEGORY', type: 'text' },
      { field: 'rule_name', header: 'RULE NAME', type: 'text' },
      { field: 'component_name', header: 'COMPONENT NAME', type: 'text' },
      { field: 'list_name', header: 'LIST NAME', type: 'text' },
      { field: 'element_name', header: 'ELEMENT NAME', type: 'text' },
    ];
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
}
