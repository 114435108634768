import { Injectable, ErrorHandler } from '@angular/core';
import { ErrorHandlerService } from './services/error-handler.service';

const enum GLOBAL_ERROR_CODES {
  ERROR_NETWORK = 'Please check your network connection. Please Try Again',
  ERROR_TIME_OUT = 'Request Timeout. Please Try Again',
  ERROR_API = 'Something went wrong. Please Try Again',
  ERROR_SP_AVAILABILITY = 'Something went wrong on service provider availability, Please Try Again',
}

/**
 * Add more error string and mape error codes
 */
const GLOBAL_ERROR_STRINGS = [
  {
    name: 'Error: Network error: Http failure response for',
    code: GLOBAL_ERROR_CODES.ERROR_NETWORK,
  },
];

@Injectable()
export class CrmErrorHandler implements ErrorHandler {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  handleError(error: Error) {
    /*
            Token renews frequently and might get timeout. It is ok, as it tries to renew multiple times before it expires.
            Token renews sometimes get AADB2C90077: User does not have an existing session and request prompt parameter
                has a value of 'None'. Ignore this error as it will work the next few times.
        */
    const ignoreList = [
      'Token renewal operation failed due to timeout',
      'User does not have an existing session and request prompt parameter',
      'ExpressionChangedAfterItHasBeenCheckedError',
      `"There is no FormControl instance attached to form control element with name: 'customText'"`,
      `Failed to set an indexed property on `,
      `R3InjectorError(AppModule)[NgControl -> NgControl -> NgControl]:`,
      `CSSStyleDeclaration`
    ];
    let errorMessage =
      'The application has encountered an error. Please try again or reach out to CSG Support.';
    console.log('Error', error);
    let ignoreFlag = false;
    for (const ignoreStr of ignoreList) {
      if (error.message.includes(ignoreStr)) {
        ignoreFlag = true;
      }
    }
    if (!ignoreFlag) {
      if (typeof error === 'object') {
        if (
          error.hasOwnProperty('name') &&
          error.name === 'HttpErrorResponse' &&
          error.message.includes('serviceprovideravailability')
        ) {
          errorMessage = GLOBAL_ERROR_CODES.ERROR_SP_AVAILABILITY;
        } else if (
          error.hasOwnProperty('name') &&
          error.name === 'HttpErrorResponse' &&
          error.message.includes('504')
        ) {
          errorMessage = GLOBAL_ERROR_CODES.ERROR_TIME_OUT;
        } else if (error.hasOwnProperty('name') && error.name === 'HttpErrorResponse') {
          errorMessage = GLOBAL_ERROR_CODES.ERROR_API;
        } else if (error.hasOwnProperty('name')) {
          errorMessage = `500: ${error.name}`;
        } else {
          const msg: string = error.toString();
          console.log(`ERROR :: ${msg}`);
          // for (const item of GLOBAL_ERROR_STRINGS) {
          //   if (msg.includes(item.name)) {
          //     errorMessage = item.code;
          //     break;
          //   }
          // }
        }
      }
      this.errorHandlerService.errorNotification.next(errorMessage);
    }
  }
}
