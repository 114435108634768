<div class="user-name-information">
  <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-row col-12">
      <div class="title"><div>User Name must be at least 7 characters long</div></div>
    </div>
    <div class="form-row col-4" *ngFor="let formField of formFields;">
      <form-field [formField]="formField" [form]="form"></form-field>
    </div>
  </form>
</div>
