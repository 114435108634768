import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { SecurityTypeService } from '../../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../../shared/constants/button-class';
import {
  permissionListEllipsisHeaderOptions,
  ellipsisRowOptions,
} from '../../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormCanDeactivate } from '../../../../shared/form-field/form-can-deactivate';
import { Subscription } from 'rxjs';
import { PermissionListType } from '../../security';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Component({
  selector: 'security-permission-list-option',
  templateUrl: 'permission-list.template.html',
  providers: [SecurityTypeService, MessageService],
})
export class PermissionListComponent extends FormCanDeactivate implements OnInit {
  @Input() searchParams: any;
  cols: any[];
  dataList: PermissionListType[] = [];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = permissionListEllipsisHeaderOptions;
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  querySubscription: Subscription;
  isLoading = true;

  lastOffsetVal = 0;
  offsetLimint = 25;
  disableLoadMore = false;
  clonedata: boolean;
  selectedEllipsisItem: any;

  constructor(
    private configurationService: SecurityTypeService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleAuthorizationService,
    private messageService: MessageService,
    private service: AzureLoginService,
    private confirmationService: ConfirmationService,
  ) {
    super();
    this.clonedata = true;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'componentName', header: 'COMPONENT NAME', type: 'text' },
      { field: 'elementName', header: 'ELEMENT NAME', type: 'text' },
      { field: 'listName', header: 'LIST NAME', type: 'text' },
      { field: 'parentComponentName', header: 'PARENT COMPONENT NAME', type: 'text' },
    ];
    this.getPermissionValueData();
  }
  getPermissionValueData() {
    this.isLoading = true;
    if (this.searchParams !== 'Permission List') {
      this.searchParams = {
        componentName:
          this.searchParams.componentName !== null ? this.searchParams.componentName : '',
        listName: this.searchParams.listName !== null ? this.searchParams.listName : '',
        elementName: this.searchParams.elementName !== null ? this.searchParams.elementName : '',
        parentComponentName:
          this.searchParams.parentComponentName !== null
            ? this.searchParams.parentComponentName
            : '',
      };
    } else {
      this.searchParams = {
        componentName: '',
        listName: '',
        elementName: '',
        parentComponentName: '',
      };
    }
    this.searchParams.limit = this.offsetLimint;
    this.searchParams.offset = this.lastOffsetVal;
    this.lastOffsetVal += this.offsetLimint;
    let length = 0;
    this.querySubscription = this.roleService
      .getPermissionsListSearch(this.searchParams)
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        const dataInfo = data.getPermissionsListSearch;
        dataInfo.forEach((permissionList, index) => {
          const info = Object.assign({}, permissionList);
          info.id = index;
          length++;
          if (this.dataList.length === 0) {
            this.dataList.push(info);
          } else {
            const id = this.dataList.findIndex(
              (e) => e.permissionsListIdOriginal === info.permissionsListIdOriginal,
            );
            if (id === -1) {
              this.dataList.push(info);
            } else {
              const id = this.dataList.findIndex(
                (e) => e.permissionsListIdOriginal === permissionList.permissionsListIdOriginal,
              );
              if (id === -1) {
                this.dataList.push(permissionList);
              } else {
                this.dataList[id] = permissionList;
              }
            }
          }
        });
        if (length !== 0) {
          this.disableLoadMore = this.dataList.length % this.offsetLimint !== 0;
        } else {
          this.disableLoadMore = true;
        }
      });
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    if (this.isEditing) {
      const btn = document.getElementById(`${this.editConstant + this.selectedItem.id}cancelBtn`);
      if (btn) {
        btn.click();
      }
      this.isEditing = false;
    }
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    if (this.clonedata) {
      this.clonedRowData[rowData.id] = { ...rowData };
    }
    this.clonedata = true;
  }
  onRowEditSave(rowData: any) {
    this.validateAndSavePermission(rowData);
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    if (rowData.__typename === 'new') {
      this.dataList.shift();
      this.resetId();
    }
  }
  handleOpen(e,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    switch (e.value) {
      case 'add':
        const element: PermissionListType = {
          permissionsListId: 0,
          permissionsListIdOriginal: 0,
          componentName: '',
          listName: '',
          elementName: '',
          parentComponentName: '',
          id: 0,
          __typename: 'new',
        };
        this.dataList.unshift(element);
        this.resetId();
        this.selectedItem = this.dataList[0];
        setTimeout(() => {
          this.onRowEdit();
        }, 100);
        break;
      case 'delete':
        this.confirmationService.confirm({
          message: `Are you sure you want to delete this Permission List?`,
          accept: () => {
            const row = this.selectedItem;
            const params: any = {
              isDeleted: true,
              componentName: row.componentName,
              elementName: row.elementName,
              parentComponentName: row.parentComponentName,
              listName: row.listName,
              permissionListIdOriginal: row.permissionsListIdOriginal,
              insertUserName: this.service.accountId,
            };
            this.createUpdatePermissionList(params, row).then(() => {
              this.dataList.splice(row.id, 1);
              this.resetId();
            });
          },
        });
        break;
      default:
        this.onRowEdit();
        break;
    }
    this.cdr.detectChanges();
  }

  async createUpdatePermissionList(params, rowData) {
    this.roleService.createUpdatePermissionsList(params).subscribe((result: any) => {
      const idOriginal = parseInt(result.data.createUpdatePermissionList, 10);
      const index = this.dataList.findIndex((r) => r.id === rowData.id);
      if (index > -1) {
        this.dataList[index].permissionsListIdOriginal = idOriginal;
        this.dataList[index].__typename = 'Permission List';
      }
      this.resetId();
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Permission ${params.isDeleted ? 'Deleted' : 'Saved'}`,
      });
    });
  }
  validateAndSavePermission(rowData: PermissionListType) {
    if (rowData.componentName.length === 0) {
      this.displayError('Component');
      return;
    }
    if (rowData.elementName.length === 0) {
      this.displayError('element');
      return;
    }
    const params: any = {
      componentName: rowData.componentName,
      elementName: rowData.elementName,
      parentComponentName: rowData.parentComponentName,
      listName: rowData.listName,
      insertUserName: this.service.accountId,
      isDeleted: false,
    };
    if (rowData.permissionsListIdOriginal !== 0) {
      params.permissionListIdOriginal = rowData.permissionsListIdOriginal;
    }
    this.createUpdatePermissionList(params, rowData);
    this.isEditing = false;
    this.isLoading = false;
  }
  displayError(type) {
    this.clonedata = false;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: `The ${type} is required`,
    });
    this.isLoading = false;
  }
  resetId() {
    this.dataList.forEach((data, id) => {
      data.id = id;
    });
  }
}
