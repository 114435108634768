import { Component, Input, OnInit } from '@angular/core';
import { FormService } from './form.service';
import { buttonStatus } from '../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions } from '../shared/constants/ellipsis-options';

@Component({
  selector: 'crm-form',
  templateUrl: 'form.template.html',
  styleUrls: ['form.scss'],
  providers: [FormService],
})
export class FormComponent implements OnInit {
  admin: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  selectedEllipsisItem: any;
  constructor(private formService: FormService) {}

  ngOnInit() {
    this.formService.getForm().subscribe((data) => {
      this.admin = data;
    });
    this.cols = [
      { field: 'active', header: 'ACTIVE', type: 'status' },
      { field: 'type', header: 'TYPE', type: 'text' },
      { field: 'message_code', header: 'MESSAGE CODE', type: 'text' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
}
