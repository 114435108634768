<p-accordion [style.width]="'100%'">
  <p-accordionTab [selected]="true" header="Notes">
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" #dt [columns]="selectedColumns" [value]="rows" [customSort]="true"
      [(selection)]="selectedNote" [scrollable]="true" [rows]="displayRows" [scrollHeight]="scrollHeight"
      [lazyLoadOnInit]="false" [lazy]="true" [virtualRowHeight]="50" [loading]="isLoading"
      (onLazyLoad)="loadDataOnScroll($event)">
      <ng-template pTemplate="caption">
        <div class="multi-select" style="display: flex;justify-content: space-between;">
          <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" class="column-filter" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
          <input (input)="dt.filterGlobal($event, 'contains')" type="text" pInputText size="50"
            placeholder="Global Filter" />
          <div (click)="ellipsisClick($event,'item',opHeader, null)" class="three-dots">
            <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="last-cell"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns;let indx = index" [style.width]="col.width">
            <div *ngIf="col.datatype==='date'">{{getFormattedDate(rowData[col.field])}}</div>
            <div *ngIf="col.datatype==='string'" [innerHTML]="rowData[col.field]"></div>
          </td>
          <td class="last-cell" [style.width]="'3%'">
            <div
              (click)="ellipsisClick($event,rowData,opRow,null)" class="three-dots">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData>
        <div class="text-left">
          <button
            *appSetRolePermissionsDirective="{type:'button', operation:'ADD', permission:'Notes', category: parentCategory}"
            (click)="showDialogToAdd()" class="crm-button" type="button" icon="fa fa-plus" label="Add">
            Add
          </button>
          <button [attr.disabled]="disableLoadMore?true:null" [hidden]="isLoading" (click)="getNotes()"
            class="btn-crm-success" type="button">
            Load More
          </button>
        </div>
      </ng-template>
    </p-table>
    <p-dialog [header]="this.newNote ? 'Add Note' : 'Edit Note'" [(visible)]="displayDialog" [modal]="true"
      [styleClass]="'note-dailog'" showeffect="fade">
      <div *ngIf="note" class="ui-g ui-fluid">
        <div class="ui-g-12">
          <div class="label-div ui-g-2">
            <label for="type">Type</label>
          </div>
          <div class="ui-g-10">
            <p-dropdown [disabled]="isViewMode" [options]="typeList" [(ngModel)]="currentType"
              (onChange)="onTypeChange($event)" id="type" appendTo="body" placeholder="Select a Type"
              optionLabel="label">
            </p-dropdown>
          </div>
        </div>
        <div class="ui-g-12">
          <div class="label-div ui-g-2">
            <label for="note">Note</label>
          </div>
          <div class="ui-g-10">
            <p-editor [readonly]="!note || (note && !note.isEditable)" [(ngModel)]="note.noteText"
              [style]="{'height':'220px'}"></p-editor>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane ui-helper-clearfix">
          <div
            *appSetRolePermissionsDirective="{type:'div', operation:'DELETE', permission:'Notes', category: parentCategory}">
            <button *ngIf="note && note.canDelete && buttonAuth.Delete" (click)="delete()" class="crm-button"
              type="button" icon="fa fa-close" label="Delete">
              Delete
            </button>
          </div>
          <div>
            <button *ngIf="note && note.isEditable && ( newNote || (buttonAuth.Save && !newNote))" (click)="save()"
              class="crm-button" type="button" icon="fa fa-check" label="Save">
              Save
            </button>
          </div>
        </div>
      </ng-template>
    </p-dialog>
  </p-accordionTab>
</p-accordion>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)"></p-listbox>
</p-overlayPanel>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox>
</p-overlayPanel>
<crm-export-dialog [(displayDialog)]="displayExport" [filterByColumns]="true" [fileName]="'crm-notes'" [rows]="rows"
  [filterColumnList]="selectedColumns" [dataTable]="dt" [pdfTableOptions]="pdfTableOptions">
</crm-export-dialog>
<div class="alert-dailog">
  <p-dialog [header]="'Alert'" [(visible)]="displayAlert" [modal]="true" [styleClass]="'add-dialog'"
    [blockScroll]="true" showeffect="fade">
    <div class="modal-content">
      <i class="pi pi-exclamation-triangle" style="font-size: 2em"></i>
      <p>{{disclaimer}}</p>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane ui-helper-clearfix">
        <button (click)="displayAlert=false" class="crm-button" type="submit" label="Cancel">
          OK
        </button>
      </div>
    </ng-template>
  </p-dialog>
</div>