import {
  Component,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { TabsService } from '../services/tabs.service';
import { TabView } from 'primeng/tabview';
import { RoleAuthorizationService } from '../../../services/role-authorization.service';
import { MsalService } from '@azure/msal-angular';
import { AzureLoginService } from '../../../services/azure-login.service';
import { ApolloService } from '../../../services/apollo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownDataService } from '../services/dropdown.data.service';
import { CrmDocumentType } from '../shared/constants/enums';
import { UserIdleService } from 'angular-user-idle';
import moment from 'moment';
import { StringService } from '../../../services/string.service';
import { takeUntil } from 'rxjs';
import { TOKEN_REFRESH_TIME, TIMEOUT_COUNT } from 'src/app/app-config';
import { ErrorHandlerService } from '../services/error-handler.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { ApiConfigService } from '../services/api-config.service';
import { copy } from '../shared/utilities/common-utilities';
import { ScmModuleMapping } from './module-mapping';
import { Location } from '@angular/common';
import { SecurityTypeService } from '../administration/security/security.service';

@Component({
  selector: 'crm-layout',
  templateUrl: 'crm-layout.template.html',
  styleUrls: ['crm-layout.scss'],
})
export class CrmLayoutComponent implements OnDestroy, OnInit {
  tabs: any[] = [];
  changeTab: any[] = [];
  subscription: Subscription;
  display: boolean;
  selectedIndex = 0;
  isDirty: boolean;
  closingIndex: number;
  isLoading = true;
  querySubscription: Subscription;
  documentType = CrmDocumentType;
  errorDisplay = false;
  errorMessage = '';
  erMessage = '';
  tokenCreationDate;
  tokenExpireDate;
  isTimingOutFlag = false;
  isDirectAccess = false;
  isDirectAccessModule: any;

  public strings = null;
  private unsubscribe: Subject<void> = new Subject();
  countdownValue = 0;

  public activityCtx = {
    sessionTimeoutStamp: new Date(),
    sessionTimeoutCallback: null,
    sessionTimingOut: false,
    sessionTimingOutCount: 0,
    sessionTimingOutCountText: '0:00',
    sessionTimingOutStamp: null,
    sessionRefreshTokenNeeded: false,
  };

  @ViewChild(TabView) tabView: TabView;

  constructor(
    private tabService: TabsService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleAuthorizationService,
    private msalService: MsalService,
    private azureService: AzureLoginService,
    private apollo: ApolloService,
    private router: Router,
    private dropdownDataService: DropdownDataService,
    private userIdle: UserIdleService,
    private stringService: StringService,
    private errorHandlerService: ErrorHandlerService,
    private featureFlagService: FeatureFlagsService,
    private apiConfigService: ApiConfigService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private configurationService: SecurityTypeService,
  ) {
    this.stringService.strings$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((strings) => (this.strings = strings));

    this.subscription = this.tabService.getTab().subscribe((tab) => {
      if (this.tabs.length && this.tabView.tabs) {
        this.clearTabSelection();
      }
      if (this.isDirectAccess) {
        this.tabs = [];
      }
      tab.selected = true;
      this.tabs.push(tab);
      this.selectedIndex = this.tabs.length - 1;
    });
    this.display = false;
  }

  @HostListener('click') onclick() {
    this.restart();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.isDirectAccess = true;
        const request = {};
        Object.keys(params).map(key=> {
          try {
            const value = params[key].trim();
            const fkey = key.trim();
            if(value !== "N/A" && value !== ''){
              request[fkey]= params[key].trim();
            }
          } catch (error:any) {
            console.log(error.message, key)
          }
        })
        console.log(request)
        this.isDirectAccessModule = request;
      }
    });
    /* Start of User Idle Logic */
    this.tokenCreationDate = moment();
    this.tokenExpireDate = moment();
    this.tokenExpireDate.add(TOKEN_REFRESH_TIME, 'seconds');
    // This is the idle logic.

    // Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      if (count && !this.isTimingOutFlag) this.isTimingOutFlag = true;
      if (TIMEOUT_COUNT >= count) {
        this.activityCtx.sessionTimingOutCountText = (TIMEOUT_COUNT - count).toString();
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log('User Idle Timeout Hit. Logging off...');
      this.logOut();
    });

    // Do check periodically
    this.userIdle.ping$.subscribe(() => {
      const currentDate = moment();
      this.azureService.getAccessToken();
      if (this.tokenExpireDate < moment()) {
        console.log('Token Expiring. Renewing...');
        this.azureService.refreshAccessToken();
        this.tokenCreationDate = moment();
        this.tokenExpireDate = moment();
        this.tokenExpireDate.add(TOKEN_REFRESH_TIME, 'seconds');
      }
    });
    /* End of User Idle Logic */

    let userRole = 'SSD-Med';
    let userStatus = '';
    this.apollo.asyncTokenLookup().then((data: string) => {
      this.azureService.setAccessToken(data);
      this.apollo
        .createCRM()
        .then(() => {
          const accountDetails = this.msalService.instance.getActiveAccount();
          const azureUserId = accountDetails?.localAccountId;
          this.querySubscription = this.roleService.getUserDetails(azureUserId).subscribe(
            ({ data }) => {
              if (data.getAdminSettings.length > 0) {
                userRole = data.getAdminSettings[0].attachedRole;
                userStatus = data.getAdminSettings[0].status;
                this.roleService.userRole = userRole;
                this.azureService.roleName = userRole;
                this.azureService.firstName = data.getAdminSettings[0].firstName;
                this.azureService.lastName = data.getAdminSettings[0].lastName;
                this.azureService.fullName = `${data.getAdminSettings[0].firstName} ${data.getAdminSettings[0].lastName}`;
                this.setUserLinks(data.getAdminSettings[0]);
                this.configurationService
                  .getRolePermissionSearch({ roleName: userRole, limit: 100000 })
                  .subscribe((rolePermissionsQuery) => {
                    this.azureService.rolePermissions =
                      rolePermissionsQuery.data.getRolePermissionSearch;
                  });
              }
              this.querySubscription = this.roleService.getOperationDetails().subscribe(
                // Disable the Rule Shadowed name: 'data'
                /* tslint:disable */
                ({ data }) => {
                  /* tslint:enable */
                  for (const singleOperation of data.getAdminDisplayPermissions) {
                    const operationId = parseInt(singleOperation.operationId, 10);
                    const operationName = singleOperation.operationName;
                    this.roleService.Operations[operationName] = operationId;
                  }
                  this.querySubscription = this.roleService.getPermissionListDetails('%').subscribe(
                    // Disable the Rule Shadowed name: 'data'
                    /* tslint:disable */
                    ({ data }) => {
                      /* tslint:enable */
                      for (const singlePermission of data.getAdminDisplayPermissions) {
                        const component = singlePermission.componentName;
                        const elementName = singlePermission.elementName;
                        const listName = singlePermission.listName;
                        const parentComponent = singlePermission.parentComponentName;
                        if (!(component in this.roleService.PermissionsList)) {
                          this.roleService.PermissionsList[component] = {};
                        }
                        if (parentComponent) {
                          if (!(parentComponent in this.roleService.PermissionsList[component])) {
                            this.roleService.PermissionsList[component][parentComponent] = {};
                          }
                          this.roleService.PermissionsList[component][parentComponent][
                            elementName
                          ] = true;
                        } else {
                          if (listName && listName !== '') {
                            if (!(elementName in this.roleService.PermissionsList[component])) {
                              this.roleService.PermissionsList[component][elementName] = {};
                            }
                            if (
                              !(
                                'Options' in
                                this.roleService.PermissionsList[component][elementName]
                              )
                            ) {
                              this.roleService.PermissionsList[component][elementName].Options = {};
                            }
                            this.roleService.PermissionsList[component][elementName].Options[
                              listName
                            ] = true;
                          } else {
                            this.roleService.PermissionsList[component][elementName] = true;
                          }
                        }
                      }
                      this.querySubscription = this.roleService.getRoleDetails(userRole).subscribe(
                        // Disable the Rule Shadowed name: 'data'
                        /* tslint:disable */
                        ({ data }) => {
                          /* tslint:enable */
                          for (const singlePermission of data.getAdminDisplayPermissions) {
                            const component = singlePermission.componentName;
                            const elementName = singlePermission.elementName;
                            const listName = singlePermission.listName;
                            const operationName = singlePermission.operationName;
                            const parentComponent = singlePermission.parentComponentName;
                            const roleName = singlePermission.roleName;
                            if (roleName !== userRole) {
                              continue;
                            } // Filter out wild card extra rows.
                            if (!(userRole in this.roleService.Permissions)) {
                              this.roleService.Permissions[userRole] = {};
                            }
                            if (!(component in this.roleService.Permissions[userRole])) {
                              this.roleService.Permissions[userRole][component] = {};
                            }
                            if (parentComponent) {
                              if (
                                !(
                                  parentComponent in
                                  this.roleService.Permissions[userRole][component]
                                )
                              ) {
                                this.roleService.Permissions[userRole][component][parentComponent] =
                                  {};
                              }
                              if (
                                !(
                                  elementName in
                                  this.roleService.Permissions[userRole][component][parentComponent]
                                )
                              ) {
                                this.roleService.Permissions[userRole][component][parentComponent][
                                  elementName
                                ] = {};
                              }
                              const operationValue = this.roleService.Operations[operationName];
                              for (const singleOperationName in this.roleService.Operations) {
                                if (
                                  this.roleService.Operations.hasOwnProperty(singleOperationName)
                                ) {
                                  const singleOperationId =
                                    this.roleService.Operations[singleOperationName];
                                  if (operationValue >= singleOperationId) {
                                    if (
                                      !(
                                        'Operation' in
                                        this.roleService.Permissions[userRole][component][
                                          parentComponent
                                        ][elementName]
                                      )
                                    ) {
                                      this.roleService.Permissions[userRole][component][
                                        parentComponent
                                      ][elementName].Operation = {};
                                    }
                                    this.roleService.Permissions[userRole][component][
                                      parentComponent
                                    ][elementName].Operation[singleOperationName] = true;
                                  }
                                }
                              }
                            } else {
                              if (
                                !(elementName in this.roleService.Permissions[userRole][component])
                              ) {
                                this.roleService.Permissions[userRole][component][elementName] = {};
                              }
                              if (listName && listName !== '') {
                                if (
                                  !(
                                    'Options' in
                                    this.roleService.Permissions[userRole][component][elementName]
                                  )
                                ) {
                                  this.roleService.Permissions[userRole][component][
                                    elementName
                                  ].Options = {};
                                }
                                this.roleService.Permissions[userRole][component][
                                  elementName
                                ].Options[listName] = true;
                              } else {
                                const operationValue = this.roleService.Operations[operationName];
                                for (const singleOperationName in this.roleService.Operations) {
                                  if (
                                    this.roleService.Operations.hasOwnProperty(singleOperationName)
                                  ) {
                                    const singleOperationId =
                                      this.roleService.Operations[singleOperationName];
                                    if (operationValue >= singleOperationId) {
                                      if (
                                        !(
                                          'Operation' in
                                          this.roleService.Permissions[userRole][component][
                                            elementName
                                          ]
                                        )
                                      ) {
                                        this.roleService.Permissions[userRole][component][
                                          elementName
                                        ].Operation = {};
                                      }
                                      this.roleService.Permissions[userRole][component][
                                        elementName
                                      ].Operation[singleOperationName] = true;
                                    }
                                  }
                                }
                              }
                            }
                            // this.roleService.Permissions[userRole][component][elementName] = { Operation: { View: true } };
                          }
                          if (userStatus.toLowerCase() === 'inactive') {
                            window.alert(
                              'User login has been disabled.  Please contact CSGHelpDesk@newleafsc.net for assistance.',
                            );
                            this.router.navigate(['']);
                            this.msalService.logout();
                          }
                          this.isLoading = false;

                          if (this.isDirectAccess) {
                            this.isDirectAccess = false;
                            const tInfo = ScmModuleMapping[this.isDirectAccessModule.module];
                            if (tInfo) {
                              this.tabService.createTab({
                                header: tInfo.header,
                                content: this.isDirectAccessModule,
                                type: tInfo.type,
                              });
                            }
                          }
                        },
                        (err) => {
                          userRole = 'Customer';
                          this.isLoading = false;
                          window.alert('Role Retrieval Failed. Please try again in a few minutes.');
                          this.router.navigate(['']);
                        },
                      );
                    },
                    (err) => {
                      userRole = 'Customer';
                      this.isLoading = false;
                      window.alert(
                        'Permission Retrieval Failed. Please try again in a few minutes.',
                      );
                      this.router.navigate(['']);
                    },
                  );
                },
                (err) => {
                  userRole = 'Customer';
                  this.isLoading = false;
                  window.alert('Operation Retrieval Failed. Please try again in a few minutes.');
                  this.router.navigate(['']);
                },
              );
            },
            (err) => {
              userRole = 'Customer';
              this.isLoading = false;
              window.alert('User Retrieval Failed. Please try again in a few minutes.');
              this.router.navigate(['']);
            },
          );
          this.dropdownDataService.getDropdownData().subscribe(({ data }) => {
            this.dropdownDataService.dealerGroupData = data.getDealerGroupCache;
            this.dropdownDataService.buyingGroupData = data.getBuyingGroupCache;
            this.dropdownDataService.masterBuyingGroupData = data.getMasterBuyingGroupCache;
            this.dropdownDataService.userListData = data.getUserListCache;
            this.dropdownDataService.servicerListData = data.getServicerListCache;
            this.dropdownDataService.servicerGroupList = data.getServicerGroupListCache;
            this.dropdownDataService.categoryDescriptionList = data.getCategoryDescriptionList;
            this.dropdownDataService.categoryList = data.getCategoryList;
            this.dropdownDataService.productPlanTypeList = data.getAllProductPlanTypesList;
            this.dropdownDataService.subCategoryDescriptionList =
              data.getAllSubCategoryCodeAndDescription;
            this.dropdownDataService.userProfileTypesList = data.getUserProfileTypes;
            this.dropdownDataService.servicerListLinkData = data.getServicerListLink;
            this.dropdownDataService.roleNameData = data.getRoleNameCache;
            this.dropdownDataService.menuTypeList = data.getMenuTypeList;
            this.dropdownDataService.stateList = data.getStateList;
            this.dropdownDataService.claimStatusData = data.getClaimStatusCache;
            this.dropdownDataService.tierDescriptionData = data.getTierDescriptionList;
            this.dropdownDataService.operationsList = data.getOperationsList;
            this.dropdownDataService.permissionsList = [];
            this.dropdownDataService.permissionDescription = [];
            this.dropdownDataService.operationsListOriginal = data.getPermissionsList;
            this.dropdownDataService.dealerLocationList = data.getAllDealerLocations;
            this.dropdownDataService.workQueueListTypes = data.getWorkQueueListTypes;
            this.dropdownDataService.transactionYear = data.getTransactionYear;
            data.getPermissionsList.forEach((perm) => {
              const tmp = perm.value.split('-');
              const permissionsListIndex = this.dropdownDataService.permissionsList.findIndex(
                (e) => e.value === tmp[0],
              );
              if (permissionsListIndex === -1) {
                this.dropdownDataService.permissionsList.push({
                  key: perm.key,
                  value: tmp[0],
                });
              }
              const dropIndex = this.dropdownDataService.permissionDescription.findIndex(
                (e) => e.value === tmp[1],
              );
              if (dropIndex === -1) {
                this.dropdownDataService.permissionDescription.push({
                  key: perm.key,
                  value: tmp[1],
                });
              }
            });
            this.dropdownDataService.manufacturerDetailList = data.getManufacturerByName;
            this.dropdownDataService.moduleTypeList = data.getAllModuleTypes;

            if (data.getManufacturerByName) {
              this.dropdownDataService.manufacturerList = [];
              data.getManufacturerByName.forEach((mfl) => {
                this.dropdownDataService.manufacturerList.push({
                  label: mfl.manufacturersName,
                  value: mfl.manufacturersName,
                });
              });

              this.dropdownDataService.manufacturerList.push({ label: 'ALL', value: 'ALL' });
            }
          });

          this.featureFlagService.getAllEnabledFeatures().subscribe(({ data }) => {
            const activeApis = copy(data.getActiveApis);
            if (activeApis && activeApis.length > 0) {
              this.featureFlagService.addEnabledFeatures(activeApis.map((el) => el.apiName));
              activeApis.forEach((el) => {
                this.apiConfigService.getApiConfigDetails(el.apiName).subscribe(
                  // tslint:disable-next-line:no-shadowed-variable
                  ({ data }) => {
                    const apiConfigs = copy(data.getApiConfigs);
                    if (apiConfigs) {
                      this.apiConfigService.addApiConfig(el.apiName, apiConfigs);
                    } else {
                      this.apiConfigService.addApiConfig(el.apiName, null);
                    }
                  },
                  (err) => {
                    this.apiConfigService.addApiConfig(el.apiName, null);
                  },
                );
              });
            }
          });
        })
        .catch(() => {
          console.log(`Error on graphql`);
        });

      this.errorHandlerService.errorNotification.subscribe((err) => {
        this.errorDisplay = true;
        this.errorMessage = err;
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleClose(e) {
    this.closingIndex = e.index;
    if (this.tabs[e.index].isDirty) {
      this.display = true;
    } else {
      this.display = false;
      this.clearTabSelection();
      this.setTabHighlight();
      this.tabs.splice(e.index, 1);
      e.close();
      this.cdr.detectChanges();
    }
  }

  handleChange(e) {
    this.selectedIndex = e.index;
    this.clearTabSelection();
    this.tabView.tabs[e.index].selected = true;
  }

  onYes() {
    this.display = false;
    this.clearTabSelection();
    this.setTabHighlight();
    this.display = false;
    this.tabs.splice(this.closingIndex, 1);
    this.cdr.detectChanges();
  }

  // Function to identify if form component is dirty.
  setChangeData(isDirty: boolean) {
    this.tabs[this.selectedIndex].isDirty = isDirty;
  }

  setTabHighlight() {
    if (this.selectedIndex === this.closingIndex) {
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1;
        this.tabView.tabs[this.selectedIndex].selected = true;
      } else {
        this.selectedIndex = 0;
        this.tabView.tabs[this.selectedIndex].selected = true;
      }
    } else if (this.selectedIndex > this.closingIndex) {
      this.tabView.tabs[this.selectedIndex].selected = true;
      this.selectedIndex = this.selectedIndex - 1;
    } else {
      this.tabView.tabs[this.selectedIndex].selected = true;
    }
  }

  clearTabSelection() {
    for (const tab of this.tabView.tabs) {
      if (tab.selected) {
        tab.selected = false;
      }
    }
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
    this.isTimingOutFlag = false;
  }

  isTimingOut() {
    return this.isTimingOutFlag;
  }

  logOut() {
    this.msalService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  setUserLinks(userDetails) {
    if (!userDetails.userProfileType) {
      return;
    }
    const userProfileTypes = userDetails.userProfileType.split('||');
    const userProfileNumber = userDetails.userProfileNumber.split('||');
    for (let index = 0; index < userProfileTypes.length; index++) {
      if (userProfileTypes[index] === 'Servicer') {
        this.azureService.servicerNumberLink.push(userProfileNumber[index]);
      }
      if (userProfileTypes[index] === 'Dealer') {
        this.azureService.dealerNumberLink.push(userProfileNumber[index]);
      }
    }
  }
}
