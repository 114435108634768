import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const getRateBucketSearchQuery = gql`
  query(
    $categoryCode: String
    $bucketCode: String
    $bucketLabel: String
    $offset: Int
    $limit: Int
  ) {
    getRateBucketSearch(
      categoryCode: $categoryCode
      bucketCode: $bucketCode
      bucketLabel: $bucketLabel
      offset: $offset
      limit: $limit
    ) {
      rateBucketIdOriginal
      rateBucketId
      categoryCode
      bucketCode
      bucketLabel
      isDefaultLabel
    }
  }
`;
const createUpdateRateBucket = gql`
  mutation(
    $rateBucketIdOriginal: Int
    $categoryCode: String
    $bucketCode: String
    $bucketLabel: String
    $isHistoryItem: Boolean
    $addedTimeStamp: Date
    $insertUserName: String!
    $isDeleted: Boolean
    $isDefaultLabel: Boolean
  ) {
    createUpdateRateBucket(
      rateBucketInput: {
        rateBucketIdOriginal: $rateBucketIdOriginal
        categoryCode: $categoryCode
        bucketCode: $bucketCode
        bucketLabel: $bucketLabel
        isHistoryItem: $isHistoryItem
        addedTimeStamp: $addedTimeStamp
        insertUserName: $insertUserName
        isDeleted: $isDeleted
        isDefaultLabel: $isDefaultLabel
      }
    ) {
      rateBucketIdOriginal
      rateBucketId
      categoryCode
      bucketCode
      bucketLabel
      isHistoryItem
      addedTimeStamp
      isDeleted
      insertUserName
      isDefaultLabel
    }
  }
`;
@Injectable({
  providedIn: 'root'
})
export class RateBucketService {
  constructor(private apollo: Apollo) {}
  getRateBucketSearch = searchParams =>
    this.apollo.use('crm').query<any>({
      query: getRateBucketSearchQuery,
      variables: {
        categoryCode: searchParams.categoryCode,
        bucketCode: searchParams.bucketCode,
        bucketLabel: searchParams.bucketLabel,
        offset: searchParams.offset,
        limit: searchParams.limit
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
  createUpdateRateBucket = params =>
    this.apollo.use('crm').mutate({
      mutation: createUpdateRateBucket,
      variables: {
        ...params
      }
      // tslint:disable-next-line: semicolon
    });
}
