<div class="crm-container">
  <div class="sub-sec">
    <div class="sub-sec-header">
      <div class="title">
        <div>Covered Products</div>
      </div>
      <div *ngIf="canAdd">
        <div class="three-dots" (click)="ellipsisClick($event,'item',opHeader,null)">
          <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
        </div>
      </div>
    </div>
    <p-toast position="center"></p-toast>
  </div>
  <p-table [columns]="selectedColumns" [value]="coveredProduts" dataKey="productsId" editMode="row"
    [loading]="isLoading" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="caption">
      <div class="multi-select">
        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
          selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
        </p-multiSelect>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
        <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="rowData">
        <td *ngFor="let col of columns">
          <p-cellEditor [style.font-weight]="col.font" [style.width]="'50%'">
            <ng-template pTemplate="input">
              <input class="input-text" *ngIf="col.type === 'text' && permissionsCols[col.label]" type="text"
                [(ngModel)]="rowData[col.field]" />
              <input class="input-text" *ngIf="col.type === 'text' && !permissionsCols[col.label]" type="text"
                [(ngModel)]="rowData[col.field]" disabled="disabled" />
              <input *ngIf="col.type === 'checkbox'" type="checkbox" [(ngModel)]="rowData[col.field]" /><input
                *ngIf="col.type === 'date' && permissionsCols[col.label]" type="date"
                [(ngModel)]="rowData[col.field]" />
              <input *ngIf="col.type === 'date' && !permissionsCols[col.label]" type="date"
                [(ngModel)]="rowData[col.field]" disabled="disabled" />
              <p-dropdown *ngIf="col.type === 'select' && col.field === 'subcategoryDescription'"
                [options]="subcategoryOptions" [filter]="true" placeholder="Select"
                (onChange)="onDropDownChange($event, ri)" optionLabel="value" appendTo="body">
              </p-dropdown>
              <p-dropdown *ngIf="col.type === 'select' && col.field !== 'subcategoryDescription'"
                [options]="dropdown[col.field]" [(ngModel)]="rowData[col.field]" appendTo="body">
              </p-dropdown>
              <div *ngIf="col.type === 'id' || col.type === 'disabled'">
                {{rowData[col.field]}}
              </div>
            </ng-template>
            <ng-template *ngIf="col.type !== 'checkbox'" pTemplate="output">{{rowData[col.field]}}</ng-template>
            <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
              <input type="checkbox" [(ngModel)]="rowData[col.field]" disabled="disabled" /></ng-template>
          </p-cellEditor>
        </td>
        <td class="last-cell">
          <div *ngIf="!editing && canEdit">
            <div class="three-dots" (click)="ellipsisClick($event,rowData,optionRow,ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </div>
          <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData['id']}}"
            pButton="pButton" type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
            (click)="onRowEditInit(rowData)"></button>
          <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
            pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
            (click)="onRowEditSave(rowData, ri)"></button>
          <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
            pCancelEditableRow="pCancelEditableRow" icon="pi pi-times" (click)="onRowEditCancel(rowData, ri)"></button>
        </td>
      </tr>
    </ng-template></p-table>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<history-item-modal modalTitle="Covered Products History" [(displayDialog)]="displayHistory" [formData]="historyData"
  *ngIf="displayHistory">
</history-item-modal>