import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { ModalComponent } from './modal.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, InlineSVGModule.forRoot()],
  exports: [ModalComponent],
  declarations: [ModalComponent],
})
export class ModalModule {}
