import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { Observable, of } from 'rxjs';

const getDashboardSummaryQuery = gql`
  query($buyingGroup: String, $dealer: String, $servicer: String, $status: String) {
    getDashboardSummary(
      buyingGroup: $buyingGroup
      dealer: $dealer
      servicer: $servicer
      status: $status
    ) {
      status
      count
      columnTimeStamp
    }
  }
`;
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private apollo: Apollo) {}
  getDashboardSummary = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getDashboardSummaryQuery,
      variables: {
        buyingGroup: searchParams && searchParams.buyingGroup,
        dealer: searchParams && searchParams.dealer,
        servicer: searchParams && searchParams.servicer,
        status: searchParams && searchParams.status
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getServiceOrderPoolTypes(): Observable<any[]> {
    return of([
      {
        status: 'IN PROGRESS',
        active: 'true',
        date_entered: 'June 1, 2020',
        entered_by: 'John Doe',
        last_update_date: 'June 1, 2020',
        manufacturer: 'GE',
        dealer: 'Dealer 1'
      },
      {
        status: 'PENDING ACCEPTANCE',
        active: 'true',
        date_entered: 'January 1, 2019',
        entered_by: 'John Doe',
        last_update_date: 'January 1, 2019',
        manufacturer: 'Whirpool',
        dealer: 'Dealer 2'
      },
      {
        status: 'WAITING ON CUSTOMER',
        active: 'true',
        date_entered: 'February 1, 2018',
        entered_by: 'John Doe',
        last_update_date: 'February 1, 2018',
        manufacturer: 'Whirpool',
        dealer: 'Dealer 3'
      }
    ]);
  }
}
