import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Note } from './note.model';
import { NoteService } from './note.service';
import { ConfirmationService, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import moment from 'moment';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisNoteHeaderOptions, ellipsisNoteOptions } from './constant';
import { filterTableData, printPDF, parseHTMLToString } from '../utilities/crm-table-utilities';
import { AddressCategoryType } from '../constants/enums';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RolePermissionService } from '../../common/role-permission.service';
import { ServiceOrderService } from '../../service/service-order/service-order.service';

/**
 * This is a generic component that can be used anywhere.
 * To use this, pass the identifierId and the category values
 */
@Component({
  selector: 'crm-notes',
  templateUrl: 'note.template.html',
  styleUrls: ['note.scss'],
  providers: [NoteService, MessageService],
})
export class NoteComponent implements OnInit, OnDestroy {
  // input
  @Input() displayRows = 100; // number of rows to be display. Default - 8
  @Input() scrollHeight = '250px'; // height of the notes section. Default - 200px
  @Input() identifierId;
  @Input() disableAdd;
  @Input() category: AddressCategoryType;
  @Input() disclaimer: string;
  @Input() otherData;
  @Input() parentComponent: Type<any>;
  @Input() parentCategory: string;

  @Output() dataRetrieved: EventEmitter<any> = new EventEmitter();

  // public
  public totalRecords = 1000;
  public rows: any[];
  public displayDialog: boolean;
  public isLoading: boolean;
  public note: Note = {};
  public selectedNote: Note;
  public newNote: boolean;
  public notes: any[] = [];
  public cols: any[];
  public typeList: SelectItem[];
  public currentType: SelectItem;
  public selectedColumns: any[];
  public selectedItem: any;
  public selectedRowIndex: number;
  public displayExport: boolean;
  public pdfTableOptions = {};
  public isViewMode = false;
  ellipsisHeaderOptions: any[] = ellipsisNoteHeaderOptions;
  ellipsisRowOptions: any[] = ellipsisNoteOptions;
  ellipsisRowOptionsSec: any;
  dateFormat = 'ddd MMM DD YYYY HH:mm:ss';

  // private
  private currentUser: any;
  private authDetails: IRoleAuthorizationService;
  querySubscription: any;
  querySubscriptionGetNotes: any;
  querySubscriptionSaveNotes: any;
  private buttons: any;
  buttonAuth: any = {};
  displayAlert: boolean;
  disableLoadMore: boolean;
  offsetLimit: number;
  lastOffsetLimit = 0;
  saveDisable = false;
  deleteDisabled = true;
  constructor(
    private noteService: NoteService,
    private roleService: RoleAuthorizationService,
    private azureService: AzureLoginService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private service: ServiceOrderService,
    private datePipe: DatePipe,
    private rps: RolePermissionService,
  ) {
    azureService.getFullName().subscribe((currentUser: any) => {
      this.currentUser = currentUser;
    });
  }

  getNotes() {
    this.offsetLimit = this.lastOffsetLimit;
    this.lastOffsetLimit += this.displayRows;
    this.querySubscription = this.noteService
      .getNotes(
        this.identifierId,
        this.category,
        this.offsetLimit,
        this.displayRows,
        this.otherData,
      )
      .subscribe(
        ({ data, loading }) => {
          this.isLoading = loading;
          const modifedData = data.getNotesDetails.map((item) => {
            return { ...item };
          });
          const notesResult = this.validateNotes(modifedData);
          this.notes = !!this.notes ? [...this.notes, ...notesResult] : [...notesResult];
          this.rows = !!this.rows ? [...this.rows, ...notesResult] : [...this.notes];
          this.disableLoadMore =
            Boolean(modifedData.length < this.displayRows) || !Boolean(modifedData.length);
          this.dataRetrieved.emit(this.notes);
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ngOnInit() {
    this.buttons = [{ name: 'Delete' }, { name: 'Save' }, { name: 'Add' }];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: NoteComponent,
      generalArray: this.buttons,
    };
    if (this.parentComponent) {
      this.authDetails.parantComponent = this.parentComponent;
    }
    this.buttons = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    for (const singleButton of this.buttons) {
      this.buttonAuth[singleButton.name] = true;
    }

    if (this.identifierId) {
      this.isLoading = true;
      this.offsetLimit = 0;

      this.getNotes();
    }

    // following assignment need to replaced with service call, once availabe
    this.cols = [
      { field: 'noteTypeDescription', header: 'Type', width: '11%', datatype: 'string' },
      { field: 'enteredBy', header: 'Entered By', width: '11%', datatype: 'string' },
      { field: 'noteText', header: 'Note', width: '40%', datatype: 'string' },
      { field: 'editDateTime', header: 'Last Updated Time', width: '20%', datatype: 'date' },
    ];

    this.typeList = [
      { label: 'External', value: 38 },
      { label: 'Internal', value: 39 },
      { label: 'System', value: 40 },
      { label: 'Supervisor', value: 41 },
      { label: 'Customer Knowledge', value: 34 },
      { label: 'Follow Up', value: 35 },
      { label: 'Escalation', value: 36 },
      { label: 'Info', value: 37 },
      { label: 'Status Change/Add Notes', value: 42 },
      { label: 'Inquiry', value: 133 }
    ];
    if (this.parentCategory === 'Claim') {
      const permissionIternal = {
        category: this.parentCategory,
        operation: 'ADD',
        permission: 'Internal Comments',
        type: 'Select-Item',
      };
      if (!this.roleService.validateIfPermissionsExist(permissionIternal)) {
        const index = this.typeList.findIndex((r) => r.label === 'Internal');
        this.typeList.splice(index, 1);
      }
    }

    this.selectedColumns = this.cols;
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: NoteComponent,
      generalArray: this.typeList,
      operation: 'ADD',
    };
    if (this.parentComponent) {
      this.authDetails.parantComponent = this.parentComponent;
    }

    this.typeList = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisRowOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Notes',
      category: this.parentCategory,
    };
    this.ellipsisRowOptions = this.rps.evaluate(
      this.ellipsisRowOptionsSec,
      this.ellipsisRowOptions,
    );
  }

  showDialogToAdd() {
    if (this.disclaimer) {
      if (this.disableAdd) {
        this.displayAlert = true;
      } else {
        this.confirmationService.confirm({
          message: this.disclaimer,
          accept: () => {
            this.addDailogSetup();
          },
        });
      }
    } else {
      if (!this.disableAdd) {
        this.addDailogSetup();
      }
    }
  }

  save(isDelete = false) {
    const errorMessage = this.validateNote();
    if (errorMessage !== '') {
      console.log('Inside Error Message: ' + errorMessage);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to save note: ' + errorMessage,
      });
      return;
    }
    // set user info and validations
    this.note.lastUpdatedBy = this.currentUser;
    this.note.nodeAddedByUser = true;
    this.note.lastUpdatedTime = new Date();
    this.note.insertUserName = this.azureService.accountId;
    this.note.enteredBy = this.note.enteredBy ? this.note.enteredBy : this.azureService.accountId;
    this.note.enteredTime = this.note.enteredTime ? this.note.enteredTime : new Date();

    if (this.category === AddressCategoryType.Customer) {
      this.note.customerId = this.otherData;
    }

    this.querySubscriptionSaveNotes = this.noteService
      .saveNotes(this.note, this.identifierId, this.category)
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        this.service.isNoteAddedByUser.next(true);
        if (isDelete) {
          const index = this.notes.indexOf(this.selectedItem);
          this.notes = this.notes.filter((val, i) => i !== index);
          this.rows = [...this.notes];
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Note deleted successfully',
          });
        } else {
          const noteTypeDescription = this.typeList.find((t) => t.value === this.note.noteType);
          data.insertNotes.isEditable = true;
          data.insertNotes.canDelete = true;
          data.insertNotes.noteTypeDescription = noteTypeDescription && noteTypeDescription.label;

          const notes = [...this.notes];
          if (this.newNote) {
            notes.unshift(data.insertNotes);
          } else {
            notes[this.notes.indexOf(this.selectedItem)] = data.insertNotes;
          }

          this.rows = [...notes];
          this.notes = notes;
          this.dataRetrieved.emit(this.notes);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Note saved successfully',
          });
        }
        this.note.nodeAddedByUser = false;
        this.note = null;
        this.displayDialog = false;
        this.service.isNoteAddedByUser.next(false);
      });
  }

  delete() {
    this.note.isDeleted = true;
    this.save(true);
  }

  onRowSelect(event) {
    const noteToClone = this.selectedItem;
    this.newNote = false;
    // set selected row
    this.note = this.cloneNote(noteToClone);
    this.isViewMode = this.note && this.note.isEditable ? !this.note.isEditable : false;
    // set dropdown model
    this.currentType = this.typeList.find((t) => t.value === this.note.noteType);

    this.displayDialog = true;
  }

  cloneNote(currentNote: Note): Note {
    const note = {};
    for (const prop in currentNote) {
      if (prop in currentNote) {
        note[prop] = currentNote[prop];
      }
    }
    return note;
  }

  loadDataOnScroll(event: LazyLoadEvent) {
    /* Callback to invoke when paging, sorting or filtering happens in lazy mode. Following are the available properties
    event.first = First row offset,
    event.rows = Number of rows per page
    event.sortField = Field name to sort with
    event.sortOrder = Sort order as number, 1 for asc and -1 for dec
    event.multiSortMeta: An array of SortMeta objects used in multiple columns sorting. Each SortMeta has field and order properties.
    event.filters: FilterMetadata object having field as key and filter value, filter matchMode as value
    event.globalFilter: Value of the global filter if available */

    // if row offeset is zero then the scroll has happend upwards
    // if nothing is done with the upward scroll it'll create an empty space, hance the reinitialization
    if (this.identifierId && this.notes && this.notes.length > 0) {
      this.rows = [...this.notes];
      if (event.globalFilter) {
        this.rows = this.rows.filter((row) => this.filterGlobal(row, event.globalFilter.target.value));
      }
      this.customSort(event);
    }
  }

  onTypeChange(event) {
    this.note.noteType = event.value.value;
  }

  getFormattedDate(date) {
    return moment(new Date(date)).format(this.dateFormat);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  onClickExport() {
    this.pdfTableOptions = this.setTableWidths();
    this.displayExport = true;
  }

  onClickPrint(event, overlaypanel: OverlayPanel) {
    let filteredData = filterTableData(this.rows, this.selectedColumns);
    filteredData = parseHTMLToString(filteredData);
    filteredData.forEach((item) => {
      item.editDateTime = this.convertDateTime(item.editDateTime);
    });
    const additionalTableOptions = this.setTableWidths();
    printPDF(this.selectedColumns, filteredData, additionalTableOptions);
    overlaypanel.hide();
  }

  convertDateTime(date) {
    return this.datePipe.transform(date, 'MM/dd/yyyy hh:mm:ss a');
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.querySubscriptionGetNotes) {
      this.querySubscriptionGetNotes.unsubscribe();
    }
    if (this.querySubscriptionSaveNotes) {
      this.querySubscriptionSaveNotes.unsubscribe();
    }
  }

  private customSort(event: LazyLoadEvent) {
    this.rows.sort((data1, data2) => {
      const value1 = data1[event.sortField];
      const value2 = data2[event.sortField];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return event.sortOrder * result;
    });
  }
  private validatePermissionsNotes(notes: Note[]) {
    const editInternal = this.roleService.validateIfPermissionsExist({
      category: this.parentCategory,
      operation: 'EDIT',
      permission: 'Internal Comments',
      type: 'Select-Item',
    });
    const deleteInternal = this.roleService.validateIfPermissionsExist({
      category: this.parentCategory,
      operation: 'DELETE',
      permission: 'Internal Comments',
      type: 'Select-Item',
    });
    const readInternalComments = this.roleService.validateIfPermissionsExist({
      category: this.parentCategory,
      operation: 'READ_ONLY',
      permission: 'Internal Comments',
      type: 'Select-Item',
    });
    const readSystem = this.roleService.validateIfPermissionsExist({
      category: this.parentCategory,
      operation: 'READ_ONLY',
      permission: 'System Notes',
      type: 'Select-Item',
    });
    notes.forEach((element) => {
      if (element.enteredBy === this.currentUser || this.roleService.userRole === 'Admin') {
        if (element.type === 'Internal' || element.noteTypeDescription === 'Internal') {
          element.isEditable = editInternal;
          element.canDelete = deleteInternal;
        } else {
          element.isEditable = true;
          element.canDelete = true;
        }
      }
    });
    notes = notes.filter((note) => {
      if (note.type === 'Internal' || note.noteTypeDescription === 'Internal') {
        if (readInternalComments || this.parentCategory !== 'Claim') {
          return note;
        }
      } else if (note.type === 'System' || note.noteTypeDescription === 'System') {
        if (readSystem) {
          return note;
        }
      } else {
        return note;
      }
    });
    return notes;
  }
  private validateNotes(notes: Note[]) {
    if (this.parentCategory === 'Claim' || this.parentCategory === 'Contract') {
      return this.validatePermissionsNotes(notes);
    }
    notes.forEach((element) => {
      if (element.enteredBy === this.currentUser || this.roleService.userRole === 'Admin') {
        element.isEditable = true;
        element.canDelete = true;
      }
    });
    return notes;
  }
  private filterGlobal(row, value) {
    for (const column of this.cols) {
      if (row[column.field] == null) {
        continue;
      }
      const rowValue: string = row[column.field].toString().toLowerCase();
      if (rowValue.includes(value.toLowerCase())) {
        return true;
      }
    }
    return false;
  }
  private addDailogSetup() {
    this.newNote = true;
    this.note = {
      enteredBy: this.currentUser,
      enteredTime: new Date(),
      isEditable: true,
      canDelete: true,
    };
    this.currentType = this.typeList[0];
    this.note.noteType = this.currentType.value;
    this.displayDialog = true;
  }

  saveSystemNote = (
    type: AddressCategoryType,
    noteText: string,
    noteType,
    enteredBy: string = null,
    enteredTime: string = null,
    editDateTime: string = null,
  ) => {
    const typeList = [{ label: 'System', value: 40 }];
    const category = type;
    const note = {
      tenantId: null,
      noteType: noteType === 133 ? noteType : typeList[0].value,
      noteText,
      enteredBy: enteredBy ? enteredBy : 'System',
      enteredTime: enteredTime ? moment(enteredTime).format() : null,
      noteIdOriginal: null,
      external: null,
      isDeleted: null,
      editDateTime: editDateTime ? moment(editDateTime).format() : null,
      customerId : null
    };

    if (this.category === AddressCategoryType.Customer) {
      this.note.customerId = this.otherData;
      note.customerId = this.otherData;
    }

    return this.noteService.saveNotes(note, this.identifierId, category).subscribe(
      (result: any) => {
        const noteTypeDescription = this.typeList.find(
          (t) => t.value === result.data.insertNotes.noteType,
        );
        result.data.insertNotes.noteTypeDescription =
          noteTypeDescription && noteTypeDescription.label;
        const notes = [...this.notes];
        notes.unshift(result.data.insertNotes);
        this.rows = [...notes];
        this.notes = notes;
        this.dataRetrieved.emit(this.notes);
        this.cdr.detectChanges();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: noteType + ' Note saved successfully',
        });
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to add ' + noteType + ' note',
        });
      },
    );
  }; // tslint:disable-line

  // Need this because user can select different columns to view.
  private setTableWidths() {
    let options = {};
    const columnStyles = {};
    for (const index in this.selectedColumns) {
      if (index) {
        const headerName = this.selectedColumns[index].header;
        if (headerName === 'Type') {
          columnStyles[index] = { cellWidth: 4 };
        }
        if (headerName === 'Entered By') {
          columnStyles[index] = { cellWidth: 6 };
        }
        if (headerName === 'Last Updated Time') {
          columnStyles[index] = { cellWidth: 11 };
        }
      }
    }
    options = {
      columnStyles,
    };
    return options;
  }

  private validateNote() {
    let errorMessage = '';
    if (this.note && !this.note.isDeleted && this.note.noteText && this.note.noteText !== '') {
      if (this.note.noteText.includes('<img src=')) {
        errorMessage = 'Images are not allowed in the notes. Please add them as attachments.';
      }
    }
    return errorMessage;
  }
}
