import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LossCodesService {
  constructor() {}
  getLossCodes(): Observable<any[]> {
    return of([
      {
        id: 1,
        code: '50BACK',
        description: '50% Back',
        type: 'service',
        laborHours: '0.0',
        partsCost: '$0.00'
      },
      {
        id: 2,
        code: 'ADH',
        description: 'ADH',
        type: 'service',
        laborHours: '0.0',
        partsCost: '$0.00'
      },
      {
        id: 3,
        code: 'CABLE',
        description: 'Cable Credit',
        type: 'service',
        laborHours: '0.0',
        partsCost: '$0.00'
      }
    ]);
  }
}
