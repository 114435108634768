<div class="inquery-tab">
  <app-section-header [title]="'Inquiries'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" *ngIf="!isReadOnly" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell" *ngIf="!isReadOnly">
            <div *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event,rowData,opRow,ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<inquiry-tab-form-modal modalTitle="Add Inquiry" [(displayDialog)]="displayServiceInfoModal"
  (formSubmitEvent)="onCreateUpdate($event)" [formData]="selectedRow" *ngIf="displayServiceInfoModal">
</inquiry-tab-form-modal>
<p-toast> </p-toast>