import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Note } from './note.model';
import { NoteComponent } from './note.component';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType
} from '../../../../interfaces/role-authorization.interface';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../constants/graphql-constants';
import gql from 'graphql-tag';
import { AddressCategoryType } from '../constants/enums';

export const notesGetQuery = gql`
  query(
    $id: String!
    $categoryType: AddressCategoryType!
    $offset: Int
    $limit: Int
    $customerId: Int
  ) {
    getNotesDetails(
      id: $id
      categoryType: $categoryType
      offset: $offset
      limit: $limit
      customerId: $customerId
    ) {
      noteId
      tenantId
      noteType
      customerId
      claimId
      contractUniqueId
      noteText
      enteredBy
      enteredTime
      editDateTime
      external
      noteIdOriginal
      noteTypeDescription
      isDeleted
    }
  }
`;

export const notesGetCountQuery = gql`
  query($id: String!, $categoryType: AddressCategoryType!, $customerId: Int) {
    getNotesCountById(id: $id, categoryType: $categoryType, customerId: $customerId)
  }
`;

export const notesPostQuery = gql`
  mutation(
    $notesCategoryType: AddressCategoryType!
    $tenantId: Int
    $noteType: Int
    $customerId: Int
    $claimId: Int
    $servicersId: Int
    $contractUniqueId: String
    $noteText: String
    $enteredBy: String
    $enteredTime: Date
    $editDateTime: Date
    $external: Boolean
    $noteIdOriginal: Int
    $isDeleted: Boolean
    $agentId: Int
    $sellerId: Int
    $dealerGroupsId: Int
    $insertUserName: String
    $contractIdOriginal: Int
    $coveragePlanId: Int
    $nodeAddedByUser: Boolean
  ) {
    insertNotes(
      notesInput: {
        notesCategoryType: $notesCategoryType
        tenantId: $tenantId
        noteType: $noteType
        customerId: $customerId
        claimId: $claimId
        servicersId: $servicersId
        contractUniqueId: $contractUniqueId
        noteText: $noteText
        enteredBy: $enteredBy
        enteredTime: $enteredTime
        editDateTime: $editDateTime
        external: $external
        noteIdOriginal: $noteIdOriginal
        isDeleted: $isDeleted
        agentId: $agentId
        sellerId: $sellerId
        dealerGroupsId: $dealerGroupsId
        insertUserName: $insertUserName
        contractIdOriginal: $contractIdOriginal
        coveragePlanId: $coveragePlanId
        nodeAddedByUser: $nodeAddedByUser
      }
    ) {
      noteId
      tenantId
      noteType
      customerId
      claimId
      servicersId
      contractUniqueId
      noteText
      enteredBy
      enteredTime
      editDateTime
      external
      noteIdOriginal
      noteTypeDescription
      isDeleted
      agentId
      contractIdOriginal
      coveragePlanId
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  constructor(private roleService: RoleAuthorizationService, private apollo: Apollo) {}

  getNotes = (
    identifierId,
    category = AddressCategoryType.Customer,
    pageOffset = 0,
    limit = 8,
    otherData = null
  ) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: notesGetQuery,
      variables: {
        id: identifierId.toString(),
        categoryType: AddressCategoryType[category],
        offset: pageOffset,
        limit,
        customerId: otherData
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getNotesCount = (identifierId, category = AddressCategoryType.Customer, otherData = null) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: notesGetCountQuery,
      variables: {
        id: identifierId.toString(),
        categoryType: AddressCategoryType[category],
        customerId: otherData
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  saveNotes = (note, identifierId, category = AddressCategoryType.Customer) => {
    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: notesPostQuery,
      variables: {
        notesCategoryType: AddressCategoryType[category],
        tenantId: parseInt(note.tenantId, 10),
        noteType: parseInt(note.noteType, 10),
        customerId: note.customerId,
        servicersId: parseInt(identifierId, 10),
        sellerId: parseInt(identifierId, 10),
        contractIdOriginal: parseInt(identifierId, 10),
        noteText: note.noteText,
        enteredBy: note.enteredBy,
        enteredTime: note.enteredTime,
        editDateTime: note.editDateTime,
        claimId: parseInt(identifierId, 10),
        external: note.external,
        noteIdOriginal: parseInt(note.noteIdOriginal, 10),
        agentId: parseInt(identifierId, 10),
        dealerGroupsId: parseInt(identifierId, 10),
        isDeleted: note.isDeleted,
        insertUserName: note.insertUserName,
        coveragePlanId: parseInt(identifierId, 10),
        nodeAddedByUser: note.nodeAddedByUser
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };
}
