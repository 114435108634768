<div class="crm-container">
  <app-section-header [title]="'Expense Charges'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [scrollable]="true" scrollHeight="400px"
      [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="col.width?{'width':col.width}:[]" class = "custom-header">
            {{col.header}}
          </th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /><p-dropdown *ngIf="col.type === 'select'"
                  [options]="dropdown[col.field]" [(ngModel)]="rowData[col.field]">
                </p-dropdown>
              </ng-template>
              <ng-template
                *ngIf="(col.type === 'text' || col.type === 'select' || col.type === 'number') && col.isAmount"
                pTemplate="output">${{ rowData[col.field] | number : '1.2-2'}}</ng-template>
              <ng-template
                *ngIf="(col.type === 'text' || col.type === 'select'|| col.type === 'number') && !col.isAmount"
                pTemplate="output">{{ rowData[col.field]}}</ng-template>
              <ng-template *ngIf="col.type === 'select' && col.field === 'status'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()] ? btnStatus[rowData[col.field].toLowerCase()] : 'btn-status-green'">
                  {{rowData[col.field] | uppercase}}
                </button></ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" disabled="disabled" /></ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,opRow,ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData,ri)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" [style.margin-right]="'0.5em'"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="summary-section">
    <div class="summary-sub-section">
      <div class="label">Total Requested Amount</div>
      <div class="amount">${{totalRequestedAmount}}</div>
    </div>
    <div class="summary-sub-section">
      <div class="label">Total Adjusted Amount</div>
      <div class="amount">${{totalAdjustedAmount}}</div>
    </div>
    <div class="summary-sub-section">
      <div class="label">Total Discount</div>
      <div class="amount">${{totalDiscount}}</div>
    </div>
    <div class="summary-sub-section">
      <div class="label">Total Approved Amount</div>
      <div class="amount">${{totalApprovedAmount}}</div>
    </div>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<expense-charges-modal modalTitle="Add/Edit Expense Charges" [(displayDialog)]="displayExpenseChargeModal"
  (formSubmitEvent)="onCreateUpdate($event)" [formData]="selectedRow" *ngIf="displayExpenseChargeModal"
  [servicerMarkupRates]="servicerMarkupRates" [servicerRatesSchedule]="servicerRatesSchedule">
</expense-charges-modal>
<p-toast></p-toast>