<div class="rate-tab">
  <app-section-header [title]="'Rate Details'" />
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
  <div class="sub-section" [hidden]="isLoading">
    <div class="title">Admin</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="admin">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-editing="editing" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of cols">
            <p-cellEditor>
              <ng-template *ngIf="rowData['bucketCode'] === 'TOTAL'" pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template>
              <ng-template *ngIf="rowData['bucketCode'] !== 'TOTAL'"
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="title">Reserves and Fees</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="reserves">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-editing="editing" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of cols">
            <p-cellEditor><ng-template *ngIf="rowData['bucketCode'] === 'TOTAL'" pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template><ng-template *ngIf="rowData['bucketCode'] !== 'TOTAL'"
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="title">Commissions</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="commission">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-editing="editing" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of cols">
            <p-cellEditor>
              <ng-template *ngIf="rowData['bucketCode'] === 'TOTAL'" pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template>
              <ng-template *ngIf="rowData['bucketCode'] !== 'TOTAL'" pTemplate="output"> {{rowData[col.field]}}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="title">Total</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="total">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of cols">{{rowData[col.field]}}</td>
        </tr>
      </ng-template>
    </p-table>
    <form class="content" [formGroup]="form">
      <div class="grid" *ngFor="let formField of formFields">
        <div class="col-8"></div>
        <div class="col-4"><form-field [formField]="formField" [form]="form"></form-field></div>
      </div>
    </form>
  </div>
</div>