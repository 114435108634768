import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const getCancellationRefundAmountQuery = gql`
  query(
    $mjcContract: String
    $contractNumber: String
    $cancellationMethod: String
    $customerCancellationDate: Date
    $cancellationReason: String
    $azureUserId: String
  ) {
    getCancellationRefundAmount(
      azureUserId: $azureUserId
      contractCancellation: {
        mjcContract: $mjcContract
        contractNumber: $contractNumber
        cancellationMethod: $cancellationMethod
        customerCancellationDate: $customerCancellationDate
        cancellationReason: $cancellationReason
      }
    ) {
      refundAmount
      contractNumber
      cancellationFee
      hasError
      errorMessage
    }
  }
`;

const createUpdateContractCancellationQuery = gql`
  mutation(
    $contractNumber: String
    $cancellationMethod: String
    $customerCancellationDate: Date
    $cancellationReason: String
    $dealerRefundOverride: String
    $mjcContract: String
    $azureUserId: String
    $cancellationTransactionDate: Date
  ) {
    createUpdateContractCancellation(
      azureUserId: $azureUserId
      contractCancellation: {
        mjcContract: $mjcContract
        contractNumber: $contractNumber
        cancellationMethod: $cancellationMethod
        customerCancellationDate: $customerCancellationDate
        cancellationReason: $cancellationReason
        dealerRefundOverride: $dealerRefundOverride
        cancellationTransactionDate: $cancellationTransactionDate
      }
    ) {
      referenceNumber
      responseCode
      responseMessage
      hasError
      errorMessage
    }
  }
`;
@Injectable()
export class CancelContractService {
  constructor(private apollo: Apollo) {}

  getCancellationRefundAmount = data =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getCancellationRefundAmountQuery,
      variables: {
        ...data
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createUpdateContractCancellation = data =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateContractCancellationQuery,
      variables: {
        ...data
      }
      // tslint:disable-next-line: semicolon
    });
}
