import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { FormField } from '../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ServiceOrderService } from '../service-order.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';
import { copy } from '../../../shared/utilities/common-utilities';

@Component({
  selector: 'service-audit',
  templateUrl: 'service-audit.template.html',
  styleUrls: ['service-audit.scss'],
  providers: [FormFieldControlService],
})
export class ServiceAuditComponent extends FormCanDeactivate implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);

  constructor(
    private qcs: FormFieldControlService,
    private service: ServiceOrderService,
    private configurationService: ConfigurationTypeService,
  ) {
    super();
    this.service.getAuditTabFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.form = this.qcs.toFormGroup(this.formFields);
    });
  }

  ngOnInit() {
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'audit_reason,audit_status,audit_outcome',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const auditReasonOptions = configList
          .filter((el) => el.menuType === 'audit_reason')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        const auditStatusOptions = configList
          .filter((el) => el.menuType === 'audit_status')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        const auditOutcomeOptions = configList
          .filter((el) => el.menuType === 'audit_outcome')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.formFields.find((el) => el.key === 'auditReason').options = auditReasonOptions;
        this.formFields.find((el) => el.key === 'auditStatus').options = auditStatusOptions;
        this.formFields.find((el) => el.key === 'auditOutcome').options = auditOutcomeOptions;
        this.formValueChanged.emit(true);
      });
  }

  onSubmit() {
    // functionality for on submit
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
