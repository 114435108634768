import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { NgForm } from '@angular/forms';
import { FormGroupDirective } from '@angular/forms';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import { UsersFormComponent } from './users.component';
import { RolesComponent } from './roles.component';
import { PermissionsConfigComponent } from './permissions-configuration.component';
import { PermissionsComponent } from './permissions.component';
import { CfgFormComponent } from './cfg.component';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { DealerLocationItem } from '../../sidebar-navigation';
import { TabsService } from '../../../services/tabs.service';
import { Tab } from '../../../services/tab';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { removeDuplicatesFromArray } from '../../../shared/utilities/common-utilities';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dealer-details-form',
  templateUrl: 'dealer-details-form.template.html',
  styleUrls: ['dealer-details-form.scss'],
  providers: [FormFieldControlService],
})
export class DealerDetailsFormComponent extends FormCanDeactivate implements OnInit, OnChanges {
  @Input() formFields: FormField<string>[] = [];
  @Input() advancedFormFields: FormField<string>[] = [];
  @Input() isAdvanced: boolean;
  @Input() isClearSearch: boolean;
  @Input() dealeritem: any;
  displayModal: boolean;
  authDetails: IRoleAuthorizationService;
  roleService: RoleAuthorizationService;
  form: FormGroup;
  modalForm: FormGroup;
  isModalFormValid = false;
  payLoad = '';
  @Input() modalHeader: string;
  @Input() navSelectedItem: string;
  newTab: Tab;
  @Input() selectedItem: any;

  searchComponentList: any[] = [
    { header: 'Search Payee', parent: 'Payee', type: 'SearchPayee' },
    { header: 'Search Dealer', parent: 'Dealer', type: 'SearchProfile' },
    { header: 'Search Dealer Location', parent: 'Dealer Location', type: 'SearchDealerLocation' },
    { header: 'Search Buying Group', parent: 'Buying Group', type: 'SearchBuyingGroup' },
    { header: 'Search Setup Alerts', parent: 'Setup Alerts', type: 'SearchSetupAlerts' },
    {
      header: 'Search Permissions Configuration',
      parent: 'Permissions Configuration',
      type: 'PermissionConfigurationSearch',
    },
    { header: 'Search Service Order', parent: 'Service Order', type: 'SearchServiceOrder' },
    { header: 'Search Setup Alerts', parent: 'Setup Alerts', type: 'SearchSetupAlerts' },
    { header: 'Search Contract', parent: 'Contracts', type: 'SearchContract' },
    { header: 'Search Coverage', parent: 'Coverage', type: 'SearchCoverage' },
    {
      header: 'Search Insurance Company',
      parent: 'Insurance Company',
      type: 'SearchInsuranceCompany',
    },
    { header: 'Search Servicer', parent: 'Servicer', type: 'SearchServicer' },
    {
      header: 'Search Service Center Lookup',
      parent: 'Service Center Lookup',
      type: 'Service Center Lookup',
    },
    { header: 'Search Service Order', parent: 'ServiceOrder', type: 'SearchServiceOrder' },
    { header: 'Search Documents', parent: 'Search Documents', type: 'Search Documents' },
    { header: 'My Work Queue', parent: 'My Work Queue', type: 'My Work Queue' },
    {
      header: 'Search My Inquiry Queue',
      parent: 'My Inquiry Queue',
      type: 'My Inquiry Queue',
    },
    { header: 'Batch Processing', parent: 'Batch Processing', type: 'Batch Processing' },
    {
      header: 'Transaction Management',
      parent: 'Transaction Management',
      type: 'Transaction Management',
    },
    { header: 'Process Claims', parent: 'Process Claims', type: 'Process Claims' },
    {
      header: 'Claim Payment Notification',
      parent: 'Claim Payment Notification',
      type: 'Claim Payment Notification',
    },
    { header: 'Users', parent: 'Users', type: 'Users' },
    { header: 'Restatus', parent: 'Restatus', type: 'Restatus' },
    { header: 'Search Manufacturer', parent: 'Manufacturer', type: 'Manufacturer' },
    { header: 'Search Failure Type', parent: 'Failure Type', type: 'Failure Type' },
    { header: 'Search Subcategory', parent: 'Subcategory', type: 'Subcategory' },
    { header: 'Search Category', parent: 'Category', type: 'Category' },
    { header: 'Search Permission', parent: 'Permissions', type: 'Permissions' },
    { header: 'Search Permission List', parent: 'Permission List', type: 'Permission List' },
    { header: 'Role Permissions', parent: 'Role Permissions', type: 'Role Permissions' },
    {
      header: 'Admin Menu Configuration',
      parent: 'Admin Menu Configuration',
      type: 'Admin Menu Configuration',
    },
    { header: 'Work Queue List', parent: 'Work Queue List', type: 'Work Queue List' },
    { header: 'OEM Warranty', parent: 'OEM Warranty', type: 'OEM Warranty' },
  ];

  querySubscription: Subscription;

  constructor(
    private qcs: FormFieldControlService,
    private tabService: TabsService,
    roleService: RoleAuthorizationService,
    private dropdownDataService: DropdownDataService,
  ) {
    super();
    this.roleService = roleService;
  }

  ngOnInit() {
    // this.form = this.qcs.toFormGroup(this.formFields);
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: DealerDetailsFormComponent,
      generalArray: this.formFields,
    };
    switch (this.dealeritem.name) {
      case 'Users':
        this.authDetails.component = UsersFormComponent;
        break;
      case 'Roles':
        this.authDetails.component = RolesComponent;
        break;
      case 'Permissions Configuration':
        this.authDetails.component = PermissionsConfigComponent;
        break;
      case 'Permissions':
        this.authDetails.component = PermissionsComponent;
        break;
      case 'Admin Menu Configuration':
        this.authDetails.component = CfgFormComponent;
        break;
    }

    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    this.form = this.qcs.toFormGroup(this.formFields);
    this.handleMyWorkQueue();
  }

  ngOnChanges() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: DealerDetailsFormComponent,
      generalArray: this.formFields,
    };
    this.modalForm = this.advancedFormFields && this.qcs.toFormGroup(this.advancedFormFields);
    if (this.modalForm && this.modalForm.valid) {
      this.isModalFormValid = true;
    }

    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.handleMyWorkQueue();
  }

  onSubmit(form: FormGroup) {
    if (this.advancedFormFields) {
      this.displayModal = false;
    }

    this.payLoad = form.getRawValue();
    const findComponent = this.searchComponentList.find(
      (item) => item.parent === this.selectedItem.name,
    );

    if (findComponent) {
      this.newTab = {
        header: findComponent.header,
        content: this.payLoad,
        type: findComponent.type,
      };
      this.tabService.createTab(this.newTab);
    }
    window.scroll(0, 0);
  }

  clear(form: FormGroup) {
    form.reset();
  }

  advancedSearch(flag: boolean) {
    if (this.advancedFormFields) {
      this.displayModal = flag;
    }
  }

  handleMyWorkQueue() {
    if (this.dealeritem.name === 'My Work Queue') {
      this.querySubscription = this.form
        .get('buyingGroup')
        .valueChanges.subscribe((buyingGroup) => {
          console.log(`${buyingGroup}`);
          if (buyingGroup) {
            const dealerGroupData = this.dropdownDataService.dealerGroupData;
            const filtereddealersData = dealerGroupData
              ? dealerGroupData.filter((d) => d.agentId === buyingGroup.value)
              : [];
            const ddfilteredDealersData = filtereddealersData.map((x) => ({
              key: x.dealerGroupName,
              value: x.dealerGroupsIdOriginal,
            }));

            if (ddfilteredDealersData) {
              this.formFields[4].options = ddfilteredDealersData;
            } else {
              const optionsDealerGroup = this.dropdownDataService.dealerGroupData
                ? this.dropdownDataService.dealerGroupData.map((x) => ({
                    key: x.dealerGroupName,
                    value: x.dealerGroupsIdOriginal,
                  }))
                : [];
              this.formFields[4].options = optionsDealerGroup;
            }
          } else {
            const optionsDealerGroup = this.dropdownDataService.dealerGroupData
              ? this.dropdownDataService.dealerGroupData.map((x) => ({
                  key: x.dealerGroupName,
                  value: x.dealerGroupsIdOriginal,
                }))
              : [];
            this.formFields[4].options = optionsDealerGroup;
          }
        });
    } else if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
