import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';

export const createUpdateServicerContactQuery = gql`
  mutation (
    $contactPosition: String
    $businessPhone: String
    $firstName: String
    $lastName: String
    $middleName: String
    $fax: String
    $email: String
    $phoneNumber1: String
    $servicerProfilesId: Int
    $isDeleted: Boolean
    $contactIdOriginal: Int
    $categoryType: AddressCategoryType!
    $paymentNotification: Boolean
  ) {
    createUpdateContacts(
      categoryType: $categoryType
      contactInput: {
        contactPosition: $contactPosition
        businessPhone: $businessPhone
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        fax: $fax
        email: $email
        phoneNumber1: $phoneNumber1
        servicerProfilesId: $servicerProfilesId
        isDeleted: $isDeleted
        contactIdOriginal: $contactIdOriginal
        paymentNotification: $paymentNotification
      }
    ) {
      servicerProfilesId
      contactIdOriginal
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ServicerContactService {
  constructor(private apollo: Apollo) {}
  getServicerContact(): Observable<any[]> {
    return of([
      {
        id: 1,
        position: 'Billing Contract',
        firstName: 'Thomas',
        lastName: 'John',
        businessPhone: '0123456789',
        fax: '',
        email: 'abcd@gmail.com',
        phoneNumber: '9874561230',
        paymentNotification: true,
      },
      {
        id: 2,
        position: 'Billing Contract',
        firstName: 'Thomas',
        lastName: 'John',
        businessPhone: '0123456789',
        fax: '',
        email: 'abcd@gmail.com',
        phoneNumber: '9874561230',
        paymentNotification: true,
      },
      {
        id: 3,
        position: 'Service Manager',
        firstName: 'Thomas',
        lastName: 'John',
        businessPhone: '0123456789',
        fax: '',
        email: 'abcd@gmail.com',
        phoneNumber: '9874561230',
        paymentNotification: true,
      },
    ]);
  }
  createUpdateServicerContact = (payload) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerContactQuery,
      variables: {
        ...payload,
      },
      // tslint:disable-next-line: semicolon
    });
}
