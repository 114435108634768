<div class="crm-container">
  <app-section-header [title]="'Search Alerts Results'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner><p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [value]="dataList" [scrollable]="true"
      [hidden]="isLoading" [rows]="10" scrollHeight="700px"><ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
        </div>
      </ng-template><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.field==='status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <div *ngIf="col.field==='message'" [innerHTML]="rowData[col.field]">{{rowData[col.field]}}</div>
            <div *ngIf="col.field !=='status' && col.field !=='message'">{{rowData[col.field]}}</div>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event,rowData,opRow, ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button type="button" [class]="'btn-crm-success'" (click)="loadAlerts(false)"
      [attr.disabled]="disableLoadMore ? true : null" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader><p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)"></p-listbox>
</p-overlayPanel>
<setup-alert-modal [modalTitle]="createUpdateAlertTitle" [(displayDialog)]="displayAlertInfoModal"
  (formSubmitEvent)="onCreateUpdate($event)" [formData]="selectedRow" *ngIf="displayAlertInfoModal">
</setup-alert-modal>