<ul class="navleftitem">
  <li
    *ngFor="let navleft of navleftitem"
    [class.selected]="navleft === selectedNavleft"
    (click)="onSelect(navleft)"
    pTooltip="{{navleft. title}}"
  >
    {{navleft.name}}
  </li>
</ul>
<div class="build-number">{{ buildNumber }}</div>
