<div class="crm-container" *ngIf="displayTable">
  <app-section-header [title]="'Search User Results ( ' + rowCount + '  item(s) found )'"
    [optionHeader]="optionHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="cols" [value]="dataList"
      dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <input *ngIf="col.field === 'is_template'" type="checkbox" [(ngModel)]="rowData[col.field]"
              value="col.isChecked" />
            <p-cellEditor *ngIf="col.type === 'text'">
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output">{{rowData[col.field]}}</ng-template>
              <ng-template *ngIf="col.field === 'status'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field] |
                  uppercase}}</button></ng-template>
              <ng-template *ngIf="col.field === 'userName'" pTemplate="output">
                <a (click)="openProfile($event, rowData)" onmouseover="this.style.cursor='pointer'">
                  {{rowData[col.field] | uppercase}} </a>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event,rowData,optionRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, optionRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, optionHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="header" [(visible)]="displayModal" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <app-tab-spinner *ngIf="isModelLoading" />
  <div class="modal-content" [hidden]="isModelLoading">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <button class="crm-button" type="submit" id="btn-submit-form" label="!form.valid" hidden="hidden"></button>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button left-align" *ngIf="!isModelLoading" type="button" (click)="onPasswordUpdate()"
        label="UpdatePassword">
        Update Password
      </button>
      <button class="crm-button label-btn" (click)="onSubmit()" *ngIf="form.valid && !isModelLoading"
        for="btn-submit-form" tabindex="0">
        {{isCreateMode ? 'Add' : 'Save'}}
      </button>
      <button class="crm-button label-btn btn-disabled" *ngIf="!form.valid || isModelLoading" tabindex="0">
        {{isCreateMode ? 'Add' : 'Save'}}
      </button>
      <button class="crm-button" *ngIf="!isModelLoading" type="button" (click)="displayModal = false"
        label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>