<div class="crm-container">
  <p-toast></p-toast>
  <app-section-header [title]="'Sales Extract'" />
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
  <div class="sub-section" *ngIf="!isLoading">
    <form (ngSubmit)="onSubmit()" [formGroup]="form"></form>
    <div class="grid">
      <div class="col-8" *ngFor="let formField of formFields">
        <form-field [formField]="formField" [form]="form"></form-field>
      </div>
    </div>
    <div>
      <button class="btn-crm-success" [disabled]="isGenerateButtonDisabled" type="button" (click)="onSubmit()">Generate
        Report</button>
    </div>
  </div>
</div>