import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PriceTable } from '../../../../crm-coverage/coverage-models';
import { ServicersTableService } from './servicers-table.service';
import { ServiceOrderService } from '../../service-order.service';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { Subscription } from 'rxjs';
import { AzureMapsService } from 'src/app/services/azure-maps.service';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { Table } from 'primeng/table';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Component({
  selector: 'servicers-table',
  templateUrl: 'servicers-table.template.html',
  styleUrls: ['./servicers-table.scss'],
  providers: [ServicersTableService, AzureMapsService, MessageService],
})
export class ServicersTableComponent implements OnInit, OnDestroy {
  @Output() selectedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() dispatchModelEvent: EventEmitter<boolean> = new EventEmitter();
  dataList = [];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  querySubscriptions: Subscription[] = [];
  selectedServicer: any;
  isLoading: boolean;
  selectedState: any;
  latitude: any;
  longitude: any;
  offset = 0;
  limit = 500;
  displayOnInvalidAddress: boolean = false;
  suggestedAddress: string;
  ratingBelowWhichPermissionToBeChecked: number = 4;

  constructor(
    private servicersTableService: ServicersTableService,
    private confirmationService: ConfirmationService,
    private serviceOrderService: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private cdr: ChangeDetectorRef,
    private azureMaps: AzureMapsService,
    private roleService: RoleAuthorizationService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    const { location } = this.serviceOrderDataService.serviceOrderDataModel;
    this.selectedState = location && location.state && location.state.value;
    if (this.selectedState) {
      const address1 = location.address1;
      const city = location.city;
      const state = location.state.value;
      const zipCode = location.zipCode;
      const addressQueryStr = `${address1} ${city} ${state} ${zipCode}`;
      if (address1 && city && state && zipCode && address1 !== 'undefined') {
        this.isLoading = true;
        this.querySubscriptions.push(
          this.azureMaps.getAddressDetails(addressQueryStr).subscribe(
            (response) => {
              if (response.body) {                
                this.serviceOrderDataService.azureMapAddressInfo = { ...response.body };
                this.latitude = response.body.latitude;
                this.longitude = response.body.longitude;
                this.suggestedAddress =  response.body.suggestedFullAddress;
                response.body.zipCode === zipCode ? this.getEligibleServicerDetails() :
                //popup the alert to confirm to proceed with dispatch if the address is invalid
                this.displayOnInvalidAddress = true;
              } else {
                this.isLoading = false;
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Please enter a valid address before proceeding with Dispatch',
                });
              }
            },
            (err) => {
              this.isLoading = false;
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please enter a valid address before proceeding with Dispatch',
              });
            },
          ),
        );
      }
    }
    this.cols = [
      { field: 'select', header: 'SELECT', type: 'radio' },
      { field: 'servicerName', header: 'SERVICER NAME', type: 'text' },
      { field: 'selfServicing', header: 'SELF SERVICING', type: 'checkbox' },
      { field: 'servicerStatus', header: 'SERVICER STATUS', type: 'text' },
      { field: 'servicerGroup', header: 'SERVICER GROUP', type: 'text' },
      { field: 'distance', header: 'DISTANCE', type: 'text' },
      { field: 'sealedSystemsRepair', header: 'SEALED SYSTEMS', type: 'checkbox' },
      { field: 'isMFGAuthorized', header: 'MFG AUTHORIZED', type: 'checkbox' },
      { field: 'rating', header: 'RATING', type: 'text' },
      { field: 'customerSatisfaction', header: 'CUSTOMER SATISFACTION', type: 'text' },
      { field: 'validInsurance', header: 'DEFAULT PROFILE', type: 'checkbox' },
      { field: 'facilityType', header: 'FACILITY TYPE', type: 'text' },
      { field: 'contracted', header: 'CONTRACTED', type: 'checkbox' },
    ];
    this.selectedColumns = this.cols.slice(0, 9);
  }

  filter(table: Table, $event: any) {
    table.filterGlobal($event.target.value, 'contains')
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  onRadioButtonClick(event, rowData) {
    this.serviceOrderService.selectedServicerValue = rowData;
    this.selectedEvent.emit(true);
  }

  OnConfirmToDispatch() {
    this.displayOnInvalidAddress = false;
    this.getEligibleServicerDetails();
  }

  OnCancelToDispatch() {
    this.displayOnInvalidAddress = false;
    this.dispatchModelEvent.emit(this.displayOnInvalidAddress);
  }

  getEligibleServicerDetails() {
    const canSelectServicerHavingLowerRating = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'Dispatch claims to lower rating servicer',
      operation: 'ADD',
      type: 'Code',
    });

    const queryParams = {
      state: this.selectedState,
      latitude: this.latitude,
      longitude: this.longitude,
      serviceType:
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceType
          ? this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceType.value
          : null,
      city: this.serviceOrderDataService.serviceOrderDataModel.location
        ? this.serviceOrderDataService.serviceOrderDataModel.location.city
        : null,
      zipCode: this.serviceOrderDataService.serviceOrderDataModel.location
        ? this.serviceOrderDataService.serviceOrderDataModel.location.zipCode
        : null,
      manufacturerName: this.serviceOrderDataService.serviceOrderDataModel.productInfoModel
        ? this.serviceOrderDataService.serviceOrderDataModel.productInfoModel
            .manufacturerManufacturer
        : null,
      modelName: this.serviceOrderDataService.serviceOrderDataModel.productInfoModel
        ? this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.modelNumber
        : null,
      contractNumber: this.serviceOrderDataService.serviceOrderDataModel.contractInfo
        ? this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractNumber
        : null,
      productId: this.serviceOrderDataService.serviceOrderDataModel.productInfoModel
        ? this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal
        : null,
      limit: this.limit,
      offset: this.offset,
    };
    this.isLoading = true;
    this.querySubscriptions.push(
      this.servicersTableService.getServiceDetails(queryParams).subscribe(
        ({ data, loading }: any) => {
          if (data.getServicerEligibleProfiles && data.getServicerEligibleProfiles.length) {
            const responseRows = [];
            for (const it of data.getServicerEligibleProfiles) {
              const singleRow = Object.assign({},it);
              let servicerStatus = '';
              let populated = false;
              if (singleRow.preferred === 1) {
                servicerStatus += 'Preferred';
                populated = true;
              }
              if (singleRow.restricted === 1) {
                if (populated) {
                  servicerStatus += '\n';
                }
                servicerStatus += 'Restricted';
              }
              if (servicerStatus) {
                servicerStatus += ', ' + singleRow.servicerStatus;
              } else {
                servicerStatus = singleRow.servicerStatus;
              }
              singleRow.servicerStatus = servicerStatus;
              singleRow.allowToSelect = singleRow.rating <= this.ratingBelowWhichPermissionToBeChecked ? canSelectServicerHavingLowerRating : true;
              responseRows.push(singleRow);
            }
            this.dataList = [...this.dataList, ...responseRows];
            this.offset = this.offset + this.limit;
          }
          this.isLoading = loading;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      ),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
