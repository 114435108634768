import { Component, Input, OnInit } from '@angular/core';
import { CoverageFormService } from './coverage-form.service';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from '../../../../shared/constants/ellipsis-options';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'coverage-form',
  templateUrl: 'coverage-form.template.html',
  providers: [CoverageFormService],
})
export class CoverageFormComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  selectedEllipsisItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  constructor(private coverageFormService: CoverageFormService) {}

  ngOnInit() {
    this.coverageFormService.getCoverageForm().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'state', header: 'State', type: 'text' },
      { field: 'form', header: 'Form', type: 'text' },
      { field: 'dealerException', header: 'Dealer Exception', type: 'text' },
      { field: 'action', header: 'Action', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
