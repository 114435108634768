import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { NgForm } from '@angular/forms';
import { FormGroupDirective } from '@angular/forms';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';

@Component({
  templateUrl: 'permissions-configuration.template.html',
  providers: [FormFieldControlService],
})
export class PermissionsConfigComponent extends FormCanDeactivate {
  @Input() formFields: FormField<string>[] = [];
  @Input() isAdvanced: boolean;
  @Input() isClearSearch: boolean;
  authDetails: IRoleAuthorizationService;
  roleService: RoleAuthorizationService;
  form: FormGroup;
  payLoad = '';

  constructor(
    private qcs: FormFieldControlService,
    roleService: RoleAuthorizationService,
  ) {
    super();
  }
}
