<div class="form-details-container">
  <div class="form-details-title">
    <div class="form-thumnail"></div>
    <div class="form-name"><input disabled="disabled" value="Payee Profile" /></div>
    <div class="form-dot-button"></div>
    <div class="last-cell">
      <div class="three-dots-heading" (click)="ellipsisClick($event,'item',opHeader)">
        <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
      </div>
    </div>
  </div>
  <div class="form-details-body">
    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
        <div class="button-bar">
          <div *ngFor="let singleButton of button">
            <button type="singleButton.type" [disabled]="singleButton.disabled" [hidden]="singleButton.hidden">
              {{singleButton.name}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<p-overlayPanel #opHeader><p-listbox [options]="ellipsisHeaderOptions"></p-listbox></p-overlayPanel>
