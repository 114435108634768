<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true" [closable]="!isDisabled">
  <app-tab-spinner *ngIf="isLoading" />
  <div class="modal-content" *ngIf="!isLoading && isClaimDataSync">
    <service-bench-table [displayDialog]="displayDialog" [isReschedule]="isReschedule">
    </service-bench-table>
    <!-- [(displayDialog)] -->
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" *ngIf="!isReschedule" type="submit" (click)="onSubmit()" label="Schedule"
        [disabled]="isDisabled">
        Schedule Job
      </button>
      <button class="crm-button" *ngIf="isReschedule" type="submit" (click)="onReschedule()" label="Reschedule"
        [disabled]="isDisabled">
        Reschedule Job
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel" [disabled]="isDisabled">
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog>