<div class="crm-container">
  <app-section-header [title]="'Forms'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id"
      editMode="row"><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing"
        let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button><p-cellEditor
              *ngIf="col.type === 'text'"><ng-template pTemplate="input"><input class="input-text" type="text"
                  [(ngModel)]="rowData[col.field]" /></ng-template><ng-template
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>