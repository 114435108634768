<div class="user-contact-information">
  <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-row col-12">
      <div class="title">
        <div>Enter either your Zip Code, Phone Number or Plan Purchase Recepit Number</div>
      </div>
    </div>
    <div class="form-row col-4" *ngFor="let formField of formFields; let i = index;">
      <span class="optional-text" *ngIf="i !== 2">OR </span
      ><form-field [formField]="formField" [form]="form"></form-field>
    </div>
  </form>
</div>
