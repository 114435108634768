// ###VERIFIED_FILE###
import { Injectable } from '@angular/core';
import { loginRequest } from '../app-config';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable()
export class AzureLoginService {
  public static readonly ACCESS_TOKEN_NAME = 'kairos_access_token';
  public static readonly EXPIRES_IN_OFFSET_SECONDS = 3;
  public LOGIN_ERROR_MESSAGE = 'The user name or password is incorrect. Please try again.';
  public headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  public accessToken: any;
  public userLoggedIn: boolean;
  public currentUser: string;
  public fullName: string;
  public userPhoto;
  public authorityInstance;
  public homeAccountIdentifier: string;
  public emailAddress: string;
  public accountId: string;
  public accountIdBase64: string;
  public roleName: string;
  public servicerNumberLink: any = [];
  public dealerNumberLink: any = [];
  public firstName: string;
  public lastName: string;
  public displayName: string;
  public rolePermissions: any[];

  constructor(private azureService: MsalService) {
    this.accessToken = null;
    const accountDetails: any = this.azureService.instance.getActiveAccount();
    if (accountDetails) {
      const emails = accountDetails.idTokenClaims.emails;
      if (emails.length > 0) {
        this.emailAddress = emails[0];
      }
      this.displayName = accountDetails.name;
      this.homeAccountIdentifier = accountDetails.homeAccountIdentifier;
      this.accountId = accountDetails.localAccountId;
      this.accountIdBase64 = btoa(accountDetails.accountIdentifier);
    }
  }

  public setAccessToken(token: string) {
    // const newToken: any = {
    //   token: token.accessToken || token.token,
    //   expiresIn: token.expiresIn,
    //   refreshToken: token.refreshToken,
    //   personnelId: token.PersonnelId || token.personnelId,
    //   currentUser: token.currentUser,
    //   firstName: token.FirstName || token.firstName,
    //   lastName: token.LastName || token.lastName,
    //   isSupervisor: token.IsSupervisor || token.isSupervisor,
    //   authorizations: token.authorizations,
    // };

    // localStorage.setItem(AzureLoginService.ACCESS_TOKEN_NAME, JSON.stringify(newToken));
    this.accessToken = token;
    this.userLoggedIn = true;
  }

  public getAccessToken() {
    return this.accessToken;
  }

  public getFullName(): Observable<string> {
    return of(`${this.firstName} ${this.lastName}`);
  }

  public getUserName(): Observable<string> {
    return of(this.displayName);
  }

  public getUserPhoto(): Observable<string> {
    return of(this.userPhoto);
  }

  public checkTokenExpiry() {
    const accountDetails = this.azureService.instance.getActiveAccount();
    console.log('Account Details: ' + JSON.stringify(accountDetails));
  }

  public async getAccessTokenAsync() {
    const result = await this.azureService.acquireTokenSilent(loginRequest);
    return result;
  }

  public refreshAccessToken() {
    this.azureService.acquireTokenSilent(loginRequest).subscribe((result: AuthenticationResult) => {
      this.accessToken = result.idToken;
    });
  }

  public getAccountId(): Observable<string> {
    return of(this.accountId);
  }
}
