import { Injectable } from '@angular/core';

import { DropdownField } from '../form-field/dropdown-field';
import { FormField } from '../form-field/form-field';
import { TextboxField } from '../form-field/textbox-field';
import { of } from 'rxjs';
import { FaxPattern, PhonePattern } from '../constants/validation-regex';

@Injectable()
export class ContactInformationFormFieldService {
  // TODO: get from a remote source of formField metadata

  getContactTabInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'firstName',
        label: 'Primary Contact First Name',
        required: true,
        order: 1
      }),
      new TextboxField({
        key: 'lastName',
        label: 'Primary Contact Last Name',
        order: 2
      }),
      new TextboxField({
        key: 'businessPhone',
        label: 'Business Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 3
      }),
      new DropdownField({
        key: 'businessPhoneTypeCode',
        label: 'Business Phone Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        required: true,
        order: 4
      }),
      new TextboxField({
        key: 'fax',
        label: 'Fax',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid fax number.',
        order: 5
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
