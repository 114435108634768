import { Component, OnInit, Input } from '@angular/core';
import { SidebarSubMenuItem } from '../sidebar-navigation';
import { DEALERLOCATIONLIST } from '../sidebar-navigation-constants';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { ServiceOrderService } from '../../service/service-order/service-order.service';
import { ServiceOrderDataService } from '../../service/service-order/service-order-data.service';
import { SecurityTypeService } from '../../administration/security/security.service';
import { TabsService } from '../../services/tabs.service';
import { FormField } from '../../shared/form-field/form-field';
import { Observable } from 'rxjs';
import { Tab } from '../../services/tab';

@Component({
  selector: 'sidebar-right-navigation',
  templateUrl: 'sidebar-right-navigation.template.html',
  styleUrls: ['sidebar-right-navigation.scss'],
  providers: [ServiceOrderService, ServiceOrderDataService],
})
export class SidebarRightComponent {
  @Input() public data: any;
  @Input() public id: number;
  dealerlist = DEALERLOCATIONLIST;
  navSelectedItem = null;
  selectedTopEleId = null;
  selectedItem: SidebarSubMenuItem;
  dealerInfoFormFields$: Observable<FormField<any>[]>;
  dealerFormFields$: Observable<FormField<any>[]>;
  public isAdvanced: boolean;
  public isClearSearch: boolean;
  rateProfileFormFields$: Observable<FormField<any>[]>;
  formFields$: Observable<FormField<any>[]>;
  advancedFormFields$: Observable<FormField<any>[]>;
  modalHeader: string;
  newTab: Tab;
  showSearchBox = true;
  constructor(
    private service: ContractFormFieldService,
    private securityService: SecurityTypeService,
    private tabService: TabsService,
    private servicerOrderService: ServiceOrderService,
  ) {
    this.dealerInfoFormFields$ = service.getDealerInfoFields();
    this.dealerFormFields$ = service.getDealerFields();
    this.rateProfileFormFields$ = service.getRateProfileFields();
    this.formFields$ = service.getPayeeFields();
  }

  enableForm(navtype, id) {
    this.isAdvanced = false;
    this.isClearSearch = true;
    this.showSearchBox = true;
    switch (navtype + ' ' + id) {
      case 'CT 1':
        this.formFields$ = this.service.getWorkQueueFields();
        break;
      case 'CT 2':
        this.isAdvanced = true;
        this.formFields$ = this.service.getContractsFields();
        this.advancedFormFields$ = this.service.getAdvancedContractsFields();
        this.modalHeader = 'Advanced Contract Search';
        break;
      case 'CT 3':
        this.formFields$ = null;
        this.showSearchBox = false;
        break;
      case 'CT 4':
        this.formFields$ = null;
        this.showSearchBox = false;
        break;
      case 'CT 5':
        this.formFields$ = this.service.getInquiryQueueFields();
        break;

      case 'SV 1':
        this.formFields$ = this.service.getSubmitProviderFields();
        break;
      case 'SV 2':
        this.isAdvanced = true;
        this.formFields$ = this.service.getServiceOrderFields();
        this.advancedFormFields$ = this.servicerOrderService.getAdvancedServiceOrderFields();
        this.modalHeader = 'Advanced Service Order Search';
        break;
      case 'SV 3':
        this.isAdvanced = true;
        this.formFields$ = this.service.getServicerFields();
        this.advancedFormFields$ = null;
        break;
      case 'SV 4':
        this.formFields$ = null;
        this.showSearchBox = false;
        break;
      case 'SV 5':
        this.formFields$ = this.service.getServiceCenterLookupFields();
        break;
      case 'SV 6':
        this.formFields$ = this.service.getClaimAdjudicationToolFields();
        break;
      case 'SV 7':
        this.formFields$ = this.service.getClaimPaymentNotificationFields();
        break;

      case 'NO 1':
        this.formFields$ = this.service.getSetupAlertsFields();
        this.navSelectedItem = 'Search';
        break;

      case 'BG 1':
        this.formFields$ = this.service.getBuyingGroupFields();
        this.navSelectedItem = 'Search';
        break;

      case 'TS 1':
        this.formFields$ = this.service.getFailureTypeFields();
        break;
      case 'TS 2':
        this.formFields$ = this.service.getResolutionsFields();
        break;
      case 'TS 3':
        this.formFields$ = this.service.getQueueFields();
        break;

      case 'DL 1':
        this.isAdvanced = true;
        this.formFields$ = this.service.getDealerInfoFields();
        break;
      case 'DL 2':
        this.isAdvanced = true;
        this.formFields$ = this.service.getDealerFields();
        break;
      case 'DL 3':
        this.formFields$ = this.service.getRateProfileFields();
        break;
      case 'DL 4':
        this.formFields$ = this.service.getPayeeFields();
        break;

      case 'CV 1':
        this.formFields$ = this.service.getCoverageFields();
        break;
      case 'CV 2':
        this.formFields$ = this.service.getComponentsFields();
        break;
      case 'CV 3':
        this.formFields$ = this.service.getLossCodesFields();
        break;
      case 'CV 4':
        this.formFields$ = this.service.getPriceTableFields();
        break;
      case 'CV 5':
        this.isClearSearch = false;
        this.formFields$ = this.service.getRateBucketConfigurationFields();
        break;
      case 'CV 6':
        this.formFields$ = this.service.getCancellationMethodsFields();
        break;
      case 'CV 7':
        this.formFields$ = this.service.getCancellationRulesFields();
        break;
      case 'CV 8':
        this.formFields$ = this.service.getCoverageGroupsFields();
        break;
      case 'CV 9':
        this.isAdvanced = true;
        this.formFields$ = this.service.getInsuranceCompanyFields();
        break;
      case 'CV 10':
        this.isClearSearch = false;
        this.formFields$ = this.service.getProductTypesFields();
        break;

      case 'CL 1':
        this.formFields$ = this.service.getOEMFields();
        break;
      case 'CL 2':
        this.formFields$ = this.service.getManufacturerFields();
        break;
      case 'CL 3':
        this.formFields$ = this.service.getPositionFields();
        break;
      case 'CL 4':
        this.formFields$ = this.service.getCategoryFields();
        break;
      case 'CL 5':
        this.formFields$ = this.service.getSubCategoryFields();
        break;
      case 'CL 6':
        this.formFields$ = this.service.getSaleRepresentativesFields();
        break;
      case 'CL 7':
        this.formFields$ = this.service.getPricePaidFields();
        break;
      case 'CL 8':
        this.formFields$ = this.service.getWorkQueueListFields();
        break;
      case 'FM 1':
        this.formFields$ = this.service.getSearchMessageFields();
        break;
      case 'CP 1':
        this.formFields$ = this.service.getProcessClaimsFields();
        break;
      case 'CP 2':
        this.formFields$ = this.service.getProcessClaimsFields();
        break;
      case 'CP 3':
        this.formFields$ = this.service.getProcessClaimsFields();
        break;
      case 'CP 4':
        this.formFields$ = this.service.getClaimsDocumentSearchFields();
        break;
      case 'SR 1':
        this.formFields$ = this.service.getBatchProcessingFields();
        break;
      case 'SR 2':
        this.formFields$ = this.service.getTransactionManagementFields();
        break;
      case 'DO 1':
        this.showSearchBox = false;
        this.formFields$ = null;
        break;
      case 'DO 2':
        this.formFields$ = this.service.getDocumentSearchFields();
        break;
      case 'SEC 1':
        this.formFields$ = this.service.getSecurityUsersFields();
        break;
      case 'SEC 2':
        this.formFields$ = this.service.getSecurityRolesFields();
        break;
      case 'SEC 3':
        this.formFields$ = this.securityService.getOperationFields();
        break;
      case 'SEC 4':
        this.formFields$ = this.securityService.getPermissionListFields();
        break;
      case 'SEC 5':
        this.formFields$ = this.service.getSecurityPermissionConfigurationFields();
        break;
      case 'SEC 6':
        this.formFields$ = this.service.getSecurityPermissionFields();
        break;
      case 'SEC 7':
        this.formFields$ = this.service.getSecurityRestatusFields();
        break;
      case 'CFG 1':
        this.formFields$ = this.service.getAdminMenuConfigurationFields();
        break;
      case 'RPT 1':
        this.formFields$ = null;
        this.showSearchBox = false;
        break;
      default:
        this.formFields$ = this.service.getEmailTemplateConfigurationFields();
        break;
    }
  }

  onSelect(dealeritem: SidebarSubMenuItem, type: string): void {
    this.selectedItem = dealeritem;
    this.selectedTopEleId = dealeritem.id;
    this.enableForm(type, dealeritem.id);
    if (dealeritem.hasTab) {
      this.newTab = { header: dealeritem.name, content: dealeritem.name, type: dealeritem.name };
      this.tabService.createTab(this.newTab);
    }
  }
}
