import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { ServicerService } from '../servicer.service';
import { TabsService } from '../../../services/tabs.service';
import { Tab } from '../../../services/tab';
import { OverlayPanel } from 'primeng/overlaypanel';
import { dateSubstring } from '../../../shared/utilities/date-utilities';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { ServicerProfileFormService } from './servicer-profile-form.service';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { AzureMapsService } from 'src/app/services/azure-maps.service';
import { Subscription } from 'rxjs';
import { servicerProfileFormHeaderOptions } from './servicer-profile-form.ellipsis';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';

@Component({
  selector: 'servicer-profile-form',
  templateUrl: 'servicer-profile-form.template.html',
  styleUrls: ['../../../shared/styles/left-details-form.scss'],
  providers: [
    FormFieldControlService,
    ServicerProfileFormService,
    MessageService,
    AzureMapsService,
  ],
})
export class ServicerProfileFormComponent implements OnInit {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  globalOptions: any[];
  ellipsisHeaderOptions: any[] = servicerProfileFormHeaderOptions;
  payLoad = '';
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  querySubscription: any;
  modelData: any;
  servicerProfilesIdOriginal: any;
  isSuccess = false;
  isFailed = false;
  servicerIdReady = false;
  isLoading = false;
  servicersId: any;
  querySubscriptions: Subscription[] = [];
  displayHistory = false;
  historyData: any;
  readOnlyPermissionList = [
    'Account Manager',
    'Back Office',
    'Back Office Lead',
    'Call Center Manager',
    'Call Center Supervisor',
    'Claims 1',
    'Claims 1 Plus',
    'CSR 2',
    'CSR 2 Plus',
    'CSR 3',
    'Data Admin - Limited',
    'Accounting',
    'Accounting Manager',
    '!Service Provider Independent',
  ];
  constructor(
    private fcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: ServicerService,
    private tabService: TabsService,
    private servicerService: ServicerService,
    private dropdownDataService: DropdownDataService,
    private servicerProfileFormService: ServicerProfileFormService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private azureMaps: AzureMapsService,
    private configurationService: ConfigurationTypeService,
  ) {
    this.service.getServicerProfileFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = this.roleService.validateFormFields(data);
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: ServicerProfileFormComponent,
      generalArray: this.formFields,
    };
    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.fcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    const modifiedData = this.servicerService.serviceFormData;
    const servicerGroupValues = [];
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'servicer_group',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.globalOptions = configList;
        const servicerGroupOptions = configList
          .filter((el) => el.menuType === 'servicer_group')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.formFields.find((el) => el.key === 'servicerGroup').options = servicerGroupOptions;
        if (modifiedData && modifiedData.servicerGroup) {
          const servicerGroupElements = modifiedData.servicerGroup.split(',');
          for (const singleServicerGroup of servicerGroupElements) {
            const matchingServicerGroup =
              servicerGroupOptions &&
              servicerGroupOptions.find(
                (x) =>
                  x.key === singleServicerGroup.trim() || x.value === singleServicerGroup.trim(),
              );
            if (matchingServicerGroup) {
              servicerGroupValues.push({
                key: matchingServicerGroup.key,
                value: matchingServicerGroup.value,
              });
            }
          }
          this.form.get('servicerGroup').setValue(servicerGroupValues, { emitEvent: true });
        }
      });
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      {
        name: 'Submit',
        hidden: false,
        // disabled: !this.form.valid,
        type: 'button',
        callBack: 'onSubmit',
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: ServicerProfileFormComponent,
      generalArray: this.button,
    };

    this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    if (this.servicerService.serviceFormData) {
      this.servicerProfilesIdOriginal =
        this.servicerService.serviceFormData.servicerProfilesIdOriginal;
      this.servicersId = this.servicerService.serviceFormData.servicersId;
      if (this.servicerProfilesIdOriginal) {
        this.servicerIdReady = true;
        this.form.get('servicerNumber').disable();
      }

      const contractDate = dateSubstring(modifiedData.contractDate);
      const contractExpireDate = dateSubstring(modifiedData.contractExpireDate);
      this.form.patchValue({
        ...modifiedData,
        servicerStatus: {
          key: modifiedData.servicerStatus,
          value: modifiedData.servicerStatus,
        },
        currency: {
          key: modifiedData.currency,
          value: modifiedData.currency,
        },
        configPaymentNotification: {
          key: modifiedData.configPaymentNotification,
          value: modifiedData.configPaymentNotification,
        },
        servicerNotification: {
          key: modifiedData.servicerNotification ? 'Yes' : 'No',
          value: modifiedData.servicerNotification ? 'Yes' : 'No',
        },
        claimPayMethod: { key: modifiedData.claimPayMethod, value: modifiedData.claimPayMethod },
        dispatchMethod: { key: modifiedData.dispatchMethod, value: modifiedData.dispatchMethod },
        selfServicingDealer: modifiedData.selfServicingDealer ? ['Self Servicing Dealer'] : [],
        isCreditCardProfile: modifiedData.isCreditCardProfile ? ['CC only'] : [],
        contractDate,
        contractExpireDate,
        nationalServiceProvider: modifiedData.nationalServiceProvider ? ['National Service Provider'] : [],
        routingNumber: modifiedData.accountingNumber2,
      });
      this.button
        .filter((c) => c.name === 'Submit')
        .forEach((x) => (x.disabled = !this.form.valid));
    }
    if (this.readOnlyPermissionList.includes(this.azureService.roleName)) {
      this.button
        .filter((c) => c.name === 'Submit' || c.name === 'Cancel')
        .forEach((x) => (x.disabled = true));
    }
  }
  onSubmit() {
    if (this.servicerProfilesIdOriginal && !this.form.get('servicerNumber').dirty) {
      this.saveServicerInfo();
    } else {
      this.servicerService
        .getServicerCodeExist({ servicerCode: this.form.get('servicerNumber').value })
        .subscribe((isExists) => {
          if (isExists.data.getServicerCodeExist) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Servicer Code is already exists`,
            });
          } else {
            this.saveServicerInfo();
          }
        });
    }
  }

  getCheckboxValue(checkBoxValue) {
    const isChecked = !checkBoxValue || checkBoxValue.length === 0 ? false : true;
    return isChecked;
  }

  // Function to compare two objects.
  deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }
    return true;
  }

  isObject(object) {
    return object != null && typeof object === 'object';
  }

  // In this function we are checking whether we need to execute Azure Maps API or not.
  saveServicerInfo() {
    const mailingAddressFinal = this.servicerService.mailingAddressFinal;
    const mailingAddressOriginal = this.servicerService.mailingAddress;
    const address1 = mailingAddressFinal.address1;
    const city = mailingAddressFinal.city;
    const state = mailingAddressFinal.state;
    const zipCode = mailingAddressFinal.zipCode;
    const addressQueryStr = `${address1} ${city} ${state} ${zipCode}`;

    // Copying mailing Address which we are passing in payload and deleting exta valid key from it before comparison.
    const mailingAddressFinalCopy = Object.assign({}, mailingAddressFinal);
    delete mailingAddressFinalCopy.valid;

    if (address1 && city && state && zipCode !== 'undefined') {
      if (
        !mailingAddressOriginal ||
        !this.deepEqual(mailingAddressFinalCopy, mailingAddressOriginal)
      ) {
        this.querySubscriptions.push(
          this.azureMaps.getAddressDetails(addressQueryStr).subscribe((response) => {
            if (response.body) {
              this.submitServicerInfo(response.body.latitude, response.body.longitude);
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Please enter a valid address before Submit.',
              });
            }
          }),
        );
      } else {
        this.submitServicerInfo(
          this.servicerService.servicerLatLongData.latitude,
          this.servicerService.servicerLatLongData.longitude,
        );
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter a valid address before Submit.',
      });
    }
  }

  // Common function to submit Servicer Info with Lat and Long.
  submitServicerInfo(latitude, longitude) {
    this.payLoad = {
      ...this.servicerService.serviceFormData,
      ...this.form.getRawValue(),
      servicerProfilesIdOriginal: this.servicerProfilesIdOriginal,
      servicersId: this.servicersId,
      insertUserName: this.azureService.accountId,
      valid: this.form.valid,
      isCreditCardProfile: this.getCheckboxValue(this.form.value.isCreditCardProfile),
      selfServicingDealer: this.getCheckboxValue(this.form.value.selfServicingDealer),
      nationalServiceProvider: this.getCheckboxValue(this.form.value.nationalServiceProvider),
      latitude,
      longitude,
    };
    this.formValueChanged.emit(this.form.dirty);
    if (this.service.formValidate(this.payLoad)) {
      this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = true));
      this.isLoading = true;
      this.querySubscription = this.servicerService
        .createUpdateServicerProfile(this.payLoad)

        .subscribe(
          ({ data, isLoading }: any) => {
            this.servicerProfilesIdOriginal =
              data.createUpdateServicerProfile.servicerProfilesIdOriginal;
            this.servicerService.servicerProfilesIdOriginal = this.servicerProfilesIdOriginal;
            this.servicerService.isServicerProfilesIdOriginalAvailable.next(true);
            this.isLoading = false;
            this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = false));
            this.isSuccess = true;
            setTimeout(() => {
              this.isSuccess = false;
            }, 3000);
          },
          (err) => {
            this.isLoading = false;
            this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = false));
            throw err;
          },
        );
    } else {
      this.isFailed = true;
      setTimeout(() => {
        this.isFailed = false;
      }, 5000);
    }
  }

  onDefault(event) {
    // TODO: Callback
  }

  openTab(tabType) {
    const newTab: Tab = { header: tabType, content: '', type: tabType };
    this.tabService.createTab(newTab);
  }

  onValidatorSubmit(event) {}

  resendPaymentNotification() {
    if (this.servicerProfilesIdOriginal) {
      this.isLoading = true;
      this.servicerProfileFormService
        .resendPaymentNotification(this.servicerProfilesIdOriginal)
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.statusCode === 200) {
              if (data.body.emailList.length > 0) {
                const emailList = data.body.emailList.join(', ');
                const totalPaidEntries = data.body.totalPaidEntries;
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: `Resent Payment Notification Successfully. Total Paid Records: ${totalPaidEntries}. Email List: ${emailList}`,
                });
              } else {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: `No Records Found to Resend`,
                });
              }
            }
          },
          (err) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `ERROR: Issue occurred while trying to resend payment notification.`,
            });
          },
        );
    }
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onViewHistory() {
    const id = this.servicerProfilesIdOriginal;
    if (id) {
      this.historyData = {
        type: 'servicer_profile',
        id,
      };
      this.displayHistory = true;
    }
  }
}
