<div class="crm-container">
  <app-section-header [title]="'User Registration'" />
  <card-info [formFields]="cardInfo"></card-info><customer-info
    [formFields]="customerInfo"></customer-info><service-plan-info
    [formFields]="servicePlanInfo"></service-plan-info><product-covered
    [formFields]="productCoveredInfo"></product-covered>
  <div class="buttons">
    <button class="crm-button" type="button" label="Print">Print</button><button class="crm-button" type="button"
      label="Submit to Register">Submit to Register</button>
  </div>
</div>