import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const categorySearchQuery = gql`
  query($tierCode: String, $tierDescription: String, $category: String, $offset: Int, $limit: Int) {
    getCategorySearch(
      tierCode: $tierCode
      tierDescription: $tierDescription
      category: $category
      offset: $offset
      limit: $limit
    ) {
      categoriesId
      category
      tierCode
      tierDescription
    }
    getCategoryDescriptionList
  }
`;

const createUpdateCategory = gql`
  mutation(
    $categoriesIdOrigninal: Int
    $tierCode: String
    $tierDescription: String
    $category: String
    $insertUserName: String!
    $isDeleted: Boolean
  ) {
    createUpdateCategories(
      CategoriesInput: {
        tierCode: $tierCode
        tierDescription: $tierDescription
        category: $category
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        CategoriesIdOrigninal: $categoriesIdOrigninal
      }
    )
  }
`;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private apollo: Apollo) {}
  getCategories = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: categorySearchQuery,
      variables: {
        ...searchParams
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateCategory = params =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateCategory,
      variables: {
        categoriesIdOrigninal: params.categoriesIdOrigninal,
        tierCode: params.tierCode,
        tierDescription: params.tierDescription,
        category: params.category,
        insertUserName: params.insertUserName,
        isDeleted: params.isDeleted
      }
      // tslint:disable-next-line: semicolon
    });
}
