import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServiceOrderHistoryService {
  constructor() {}
  getServiceOrderHistory(): Observable<any[]> {
    return of([
      {
        serviceOrder: 'soxxxx-x',
        status: 'Approved paid',
        claim: 'soxxxx-x',
        contract: 'NLxxxx',
        model: 'MDxxx',
        serial: 'SNxxxx',
        dateResolved: '4/29/2020',
        lastUpdateData: '4/29/2020',
        lastUpdatedBy: 'John Doe'
      },
      {
        serviceOrder: 'soxxxx-x',
        status: 'Approved paid',
        claim: 'soxxxx-x',
        contract: 'NLxxxx',
        model: 'MDxxx',
        serial: 'SNxxxx',
        dateResolved: '4/29/2020',
        lastUpdateData: '4/29/2020',
        lastUpdatedBy: 'John Doe'
      }
    ]);
  }
}
