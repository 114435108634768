import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormField } from '../shared/form-field/form-field';
import { NavigationPanel } from '../shared/navigation-panel/navigation-panel.model';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DealerFormFieldService } from '../services/dealer-form-field.service';
import { NavigationPanelComponent } from '../shared/navigation-panel';
import { DealerProfileTabComponent } from './dealer-profile-tab/dealer-profile-tab.component';
import { DealerService } from './dealer.service';
import { NoteComponent } from '../shared/notes/note.component';

import { DealerContactComponent } from './dealer-contact/dealer-contact.component';
import { DealerServicerComponent } from './dealer-servicer/dealer-servicer.component';
import { AddressCategoryType } from '../shared/constants/enums';
import { DealerDocumentsTabComponent } from './documents-tab/documents-tab.component';
import { DealerDocumentUploadComponent } from './dealer-document-upload-tab/dealer-document-upload-tab.component';

@Component({
  selector: 'dealer',
  templateUrl: 'dealer.template.html',
  styleUrls: ['dealer.scss'],
  providers: [DealerFormFieldService, DealerService],
})
export class DealerComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  isLoading = false;
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  addressCategoryType = AddressCategoryType;
  dealerId = 0;
  querySubscription: Subscription;
  querySubscriptions: Subscription[] = [];
  component = DealerComponent;
  componentDisplay = false;
  constructor(
    service: DealerFormFieldService,
    private roleService: RoleAuthorizationService,
    private dealerService: DealerService,
  ) {
    this.formFields$ = service.getDealerMainFormFields();

    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: DealerProfileTabComponent,
        data: { name: 'Main' },
        title: 'MA',
        tabname: 'Main',
        tabKey: 'main-tab',
      },
      {
        component: DealerContactComponent,
        data: { name: 'Contact' },
        title: 'CON',
        tabname: 'Contact',
        tabKey: 'contact-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Contacts',
          category: 'Seller',
        },
      },
      {
        component: DealerDocumentsTabComponent,
        data: { name: 'Documents' },
        title: 'DC',
        tabname: 'Documents',
        tabKey: 'documents-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Documents',
          category: 'Seller',
        },
      },
      {
        component: DealerDocumentUploadComponent,
        data: { name: 'Import Documents' },
        title: 'ID',
        tabname: 'Import Documents',
        tabKey: 'import-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'ADD',
          permission: 'Documents',
          category: 'Seller',
        },
      },
      {
        component: DealerServicerComponent,
        data: { name: 'Servicer' },
        title: 'SV',
        tabname: 'Servicer',
        tabKey: 'app-dealer-servicer',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Servicer',
          category: 'Seller',
        },
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: NavigationPanelComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(roleService.applyAuthorization(this.authDetails));
  }

  ngOnInit() {
    this.componentDisplay = this.roleService.isVisible(NoteComponent, DealerComponent);
    const baseId = (this.dealerId = !isNaN(this.searchParams)
      ? parseInt(this.searchParams, 10)
      : 0);
    if (baseId) {
      this.isLoading = true;
      this.querySubscriptions.push(
        this.dealerService.getDealerDetailsForm(baseId).subscribe(
          ({ data, loading }: any) => {
            const {
              dealerGroupsIdOriginal,
              addresses,
              contactInfo,
              general1,
              general2,
              general3,
              general4,
              ...dealerFormData
            } = data.getDealerProfileById;
            this.dealerService.dealerFormData = dealerFormData;
            this.dealerService.billingAddress = addresses[0];
            this.dealerService.mailingAddress = addresses[1];
            this.dealerService.contactFormData = contactInfo;
            this.dealerService.dealerGroupsIdOriginal = dealerGroupsIdOriginal;
            this.dealerService.generalFormData = { general1, general2, general3, general4 };
            this.isLoading = loading;
            // this.dealerService.setMainTabAvailability(true);
            this.dealerService.isdealerGroupsIdAvailable.next(true);
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        ),
      );
    }
    this.querySubscriptions.push(
      this.dealerService.isdealerGroupsIdAvailable.subscribe({
        next: (isdealerGroupsId) => {
          if (isdealerGroupsId) {
            this.dealerId = this.dealerService.dealerGroupsIdOriginal;
          }
        },
      }),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
}
