import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormField } from '../form-field/form-field';
import { AddressFormFieldService } from '../../services/address.service';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { AddressService } from './address.service';
import { FormCanDeactivate } from '../form-field/form-can-deactivate';
import { DropdownDataService } from '../../services/dropdown.data.service';

@Component({
  selector: 'address',
  templateUrl: 'address.template.html',
  styleUrls: ['address.scss'],
  providers: [FormFieldControlService, AddressFormFieldService, AddressService],
})
export class AddressComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() isVerify = true;
  @Input() searchParams: any;
  @Input() caller: string;
  @Input() parentComponent: Type<any>;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  authDetails: IRoleAuthorizationService;
  querySubscription: any;

  @Output() change: EventEmitter<number> = new EventEmitter<number>();

  // to use model instead of service
  @Input() enableModelData: boolean;
  private privateModelData: any;
  @Output() modelDataChange = new EventEmitter();

  get modelData(): any {
    return this.privateModelData;
  }
  @Input() set modelData(value: any) {
    this.privateModelData = value;
    this.modelDataChange.emit(this.modelData);
  }

  constructor(
    private qcs: FormFieldControlService,
    private addressService: AddressFormFieldService,
    private roleService: RoleAuthorizationService,
    private dataService: AddressService,
    private dropdownDataService: DropdownDataService,
  ) {
    super();
  }

  ngOnInit() {
    this.addressService.getAddressFields().subscribe((address) => (this.formFields = address));
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: AddressComponent,
      generalArray: this.formFields,
    };
    if (this.parentComponent) {
      this.authDetails.parantComponent = this.parentComponent;
    }
    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.modelDataChange.emit(x);
      const updatedAdd = {
        address1: x.address1,
        address2: x.address2,
        zipCode: x.zipCode,
        state: x.state && x.state.value,
        city: x.city,
        country: x.country && x.country.value,
        valid: this.form.valid,
      };
      const finalRef = Object.assign({}, this.modelData, updatedAdd);
      this.modelData = finalRef;
    });

    if (this.searchParams && !this.enableModelData) {
      this.querySubscription = this.dataService
        .getAddress(this.caller, this.searchParams)
        .subscribe(({ data, loading }: any) => {
          let modifiedData: any;
          switch (this.caller) {
            case 'contract-main':
              modifiedData = data.getContractProfileById;
              break;
            case 'dealer-main':
              modifiedData = data.getDealerProfileById;
              break;
          }
          if (modifiedData.addresses) {
            const zip = this.getZipCode(modifiedData.addresses[0].zipCode);
            this.form.patchValue({
              address1: modifiedData.addresses[0].address1,
              address2: modifiedData.addresses[0].address2,
              zipCode: zip,
              state: {
                key: modifiedData.addresses[0].state,
                value: modifiedData.addresses[0].state,
              },
              city: modifiedData.addresses[0].city,
              country: {
                key: modifiedData.addresses[0].country,
                value: modifiedData.addresses[0].country,
              },
            });
          }
        });
    } else if (this.enableModelData && this.modelData) {
      const zip = this.getZipCode(this.modelData.zipCode);
      this.form.patchValue({
        address1: this.modelData.address1,
        address2: this.modelData.address2,
        zipCode: zip,
        state: { key: this.getStateNameByCode(this.modelData.state), value: this.modelData.state },
        city: this.modelData.city,
        country: { key: 'United States', value: 'United States' },
      });
    }
  }

  getZipCode(zipCode) {
    if (zipCode) {
      if (zipCode.length < 5) {
        return Array(5 - zipCode.length)
          .fill(0)
          .concat(zipCode)
          .join('');
      }
    }
    return zipCode;
  }
  getStateNameByCode(stateCode) {
    const optionsState =
      this.dropdownDataService.stateList &&
      this.dropdownDataService.stateList.find((x) => x.stateCode === stateCode);
    if (optionsState) {
      return optionsState.stateName;
    }
    return null;
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
