import { Injectable } from '@angular/core';

import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { AddressRegex, ZipCodePlus4Pattern } from '../shared/constants/validation-regex';
import { DropdownDataService } from './dropdown.data.service';

@Injectable()
export class AddressFormFieldService {
  constructor(private dropdownDataService: DropdownDataService) {}
  // TODO: get from a remote source of formField metadata
  getAddressFields() {
    const optionsState = this.dropdownDataService.stateList
      ? this.dropdownDataService.stateList.map(x => ({
          key: x.stateName,
          value: x.stateCode
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'country',
        label: 'Country',
        isSingleselect: true,
        value: { key: 'United States', value: 'United States' },
        options: [{ key: 'United States', value: 'United States' }],
        required: true,
        order: 1
      }),
      new TextboxField({
        key: 'address1',
        label: 'Address Line 1',
        validationRegex: AddressRegex,
        validationMessage: 'Please enter a valid Address.',
        required: true,
        order: 2
      }),
      new TextboxField({
        key: 'address2',
        label: 'Address Line 2',
        validationRegex: AddressRegex,
        validationMessage: 'Please enter a valid Address.',
        order: 3
      }),
      new TextboxField({
        key: 'zipCode',
        label: 'Zip',
        validationRegex: ZipCodePlus4Pattern,
        validationMessage: 'Please enter a valid zip code with 5 digits.',
        maxLength: 10,
        required: true,
        order: 4
      }),
      new DropdownField({
        key: 'state',
        label: 'State',
        options: optionsState,
        filter: true,
        isSingleselect: true,
        required: true,
        order: 5
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        required: true,
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
