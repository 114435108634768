import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs';
import { mergeMap } from 'rxjs';
import { takeUntil } from 'rxjs';
import { getMso, getMsoBase } from '../../app-base-factory';
import { IApiResult } from '../interfaces/api-result.interface';
import { IApiSuccess } from '../interfaces/api-success.interface';
import {
  ILoginApiResponse,
  ILoginApiResponseSuccess,
} from '../interfaces/login-api-response.interface';
import { ApiService } from './api.service';
import { StringService } from './string.service';

@Injectable()
export class LoginService implements OnDestroy {
  public static readonly ACCESS_TOKEN_NAME = 'kairos_access_token';
  public static readonly EXPIRES_IN_OFFSET_SECONDS = 3;
  public LOGIN_ERROR_MESSAGE = 'The user name or password is incorrect. Please try again.';
  public headers = new Headers({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  public accessToken: any;
  public userLoggedIn: boolean;
  public currentUser: string | undefined;
  public expirationTimeout: any;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private stringService: StringService,
    private apiService: ApiService,
    private apollo: Apollo,
  ) {
    this.stringService.strings$.pipe(takeUntil(this.unsubscribe)).subscribe((strings: any) => {
      this.LOGIN_ERROR_MESSAGE = strings.loginErrorMessage;
    });

    let storageToken = localStorage.getItem(LoginService.ACCESS_TOKEN_NAME);
    if (!storageToken) {
      return;
    }

    storageToken = JSON.parse(storageToken);
    this.setAccessToken(storageToken);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  public isLoggedIn = (): boolean => this.userLoggedIn;
  public getCurrentUser = (): string => (this.userLoggedIn ? this.currentUser : '');

  public getAccessToken() {
    return this.accessToken;
  }

  public getAuthorizations(): string[] {
    if (!this.accessToken || !this.accessToken.authorizations) {
      return [];
    }
    return this.accessToken.authorizations.map((e) => e.name);
  }

  public setAccessToken(token) {
    const newToken: any = {
      token: token.accessToken || token.token,
      expiresIn: token.expiresIn,
      refreshToken: token.refreshToken,
      personnelId: token.PersonnelId || token.personnelId,
      currentUser: token.currentUser,
      firstName: token.FirstName || token.firstName,
      lastName: token.LastName || token.lastName,
      isSupervisor: token.IsSupervisor || token.isSupervisor,
      authorizations: token.authorizations,
    };

    if (!newToken.expiration) {
      const expiration = moment().add(
        newToken.expiresIn - LoginService.EXPIRES_IN_OFFSET_SECONDS,
        'seconds',
      );
      newToken.expiration = expiration;
    }

    if (moment().isAfter(newToken.expiration)) {
      return;
    }

    localStorage.setItem(LoginService.ACCESS_TOKEN_NAME, JSON.stringify(newToken));

    const timeTillExpiration = moment(newToken.expiration).diff(moment(), 'milliseconds');
    this.expirationTimeout = setTimeout(() => {
      this.removeAccessToken.bind(this);
    }, timeTillExpiration);

    this.accessToken = newToken;
    this.userLoggedIn = true;
  }

  public removeAccessToken(): Observable<IApiResult<any>> {
    const tokenInfo = this.getAccessToken();

    if (!tokenInfo) {
      return of({ status: 200, result: { status: 'NOOP' } });
    }
    this.userLoggedIn = false;
    this.currentUser = undefined;
    this.accessToken = undefined;
    localStorage.removeItem(LoginService.ACCESS_TOKEN_NAME);
    this.resetApollo();

    if (this.expirationTimeout) {
      clearTimeout(this.expirationTimeout);
      this.expirationTimeout = undefined;
    }

    const options = {
      errorMessage: this.LOGIN_ERROR_MESSAGE,
    };

    return this.apiService.revoke<any>(tokenInfo.refreshToken, options).pipe(
      mergeMap((loginResponse: IApiResult<any>) => {
        if (loginResponse.status !== 200) {
          return of(loginResponse);
        }

        const successResult = (loginResponse as IApiSuccess<ILoginApiResponse>).result;
        const result = successResult as any;

        const response: IApiResult<any> = {
          status: loginResponse.status,
          result: {
            ...loginResponse,
            status: result.status,
          },
        };

        return of(response as any);
      }),
    );
  }

  public login(userName: string, password: string): Observable<IApiResult<ILoginApiResponse>> {
    const options = {
      errorMessage: this.LOGIN_ERROR_MESSAGE,
    };

    return this.apiService.login<ILoginApiResponse>(userName, password, options).pipe(
      mergeMap((loginResponse: IApiResult<ILoginApiResponse>) => {
        if (loginResponse.status !== 200) {
          return of(loginResponse);
        }

        this.currentUser = userName;

        const successResult = (loginResponse as IApiSuccess<ILoginApiResponse>).result;
        const result = successResult as any;

        const response: ILoginApiResponseSuccess = {
          status: loginResponse.status,
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          tokenType: result.token_type,
          expiresIn: result.expires_in,
          authorizations: result.scope
            ? result.scope.split(' ').map((item) => {
                const splits = item.split(':');
                return { afId: splits[0], name: splits[1].replace('_', ' ') };
              })
            : undefined,
          currentUser: userName,
          Status: null,
        };

        // If the call to login was successful, make yet another call to get the personnel information.
        // We should eventually just return this with login rather than making another API call.
        const personnelData = {
          mso: getMso(),
          source: 'API_SOURCE',
          bearer_token: response.accessToken,
          target: `1.0/personnel?LoginName=${response.currentUser}`,
        };

        const encodedBody = encodeURIComponent(JSON.stringify(personnelData));
        const personnelBody = 'rp=EntWexis::wp_wexis_request&args=' + encodedBody;

        return this.http
          .post(getMsoBase() + 'post.pl', personnelBody, {
            observe: 'response',
          })
          .pipe(
            map((personnelResponse) => {
              const fullToken = {
                ...response,
                ...personnelResponse.body,
              };
              this.setAccessToken(fullToken);

              return fullToken as any;
            }),
          );
      }),
    );
  }

  public refreshToken(token: string): Observable<IApiResult<ILoginApiResponse>> {
    if (!this.isLoggedIn()) {
      let msg = '';
      this.stringService.strings$.subscribe((strings: any) => {
        msg = strings.NotLoggedIn;
      });

      return of({
        status: 401,
        message: msg, // 'Not logged in..',
        payload: {},
      } as IApiResult<ILoginApiResponse>);
    }

    const options = {
      errorMessage: this.LOGIN_ERROR_MESSAGE,
    };

    return this.apiService.refresh<ILoginApiResponse>(token, options).pipe(
      mergeMap((loginResponse: IApiResult<ILoginApiResponse>) => {
        if (loginResponse.status !== 200) {
          return of(loginResponse);
        }

        const successResult = (loginResponse as IApiSuccess<ILoginApiResponse>).result;
        const result = successResult as any;

        const accessToken = this.getAccessToken();
        const newToken = {
          status: loginResponse.status,
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
          tokenType: result.token_type,
          expiresIn: result.expires_in,
          authorizations: accessToken.authorizations,
          currentUser: accessToken.currentUser,
          PersonnelId: accessToken.personnelId,
          FirstName: accessToken.firstName,
          LastName: accessToken.lastName,
          IsSupervisor: accessToken.isSupervisor,
          Status: null,
        };

        this.setAccessToken(newToken);
        const response: IApiResult<ILoginApiResponseSuccess> = {
          status: loginResponse.status,
          result: newToken,
        };

        return of(response);
      }),
    );
  }
  private async resetApollo() {
    const client = this.apollo.getClient();
    client.cache.reset();
    await client.reFetchObservableQueries();
  }
}
