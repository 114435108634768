import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_TYPE = 'text/plain;charset=utf-8';
const CSV_EXTENSION = '.csv';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  exportToExcel(arrayItems: any[], fileName: string, headers: string[] = []) {

    if (!arrayItems || !arrayItems.length) {
      return;
    }

    import('xlsx').then((xlsx) => {
      const workbook = xlsx.utils.book_new();
      const worksheet = xlsx.utils.json_to_sheet([]);
      if (headers?.length > 0) {
        xlsx.utils.sheet_add_aoa(worksheet, [headers]);
        xlsx.utils.sheet_add_json(worksheet, arrayItems, { origin: 'A2', skipHeader: true });
      }
      else {
        xlsx.utils.sheet_add_json(worksheet, arrayItems, { origin: 'A1', skipHeader: false });
      }

      xlsx.utils.book_append_sheet(workbook, worksheet, 'data');

      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.save(excelBuffer, `${fileName}${EXCEL_EXTENSION}`, EXCEL_TYPE);
    });
  }

  public exportToCsv(arrayItems: object[], fileName: string): string {
    if (!arrayItems || !arrayItems.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(arrayItems[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      arrayItems.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""').replace('–', '-');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.save(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }

  private save(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }
}
