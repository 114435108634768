import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { CoverageFormFieldService } from '../../../coverage-form-field.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'approved-state',
  templateUrl: 'approved-state.template.html',
  styleUrls: ['approved-state.scss'],
  providers: [FormFieldControlService],
})
export class ApprovedStateComponent extends FormCanDeactivate {
  formFields: FormField<string>[] = [];
  form: FormGroup;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);

  constructor(
    private qcs: FormFieldControlService,
    private service: CoverageFormFieldService,
  ) {
    super();
    this.service.getApprovedStateFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.form = this.qcs.toFormGroup(this.formFields);
    });
  }

  onSubmit() {
    // functionality for on submit
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
