<div class="crm-container">
  <app-section-header [title]="'Queue Results'" [optionHeader]="op3" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="searchResults" [scrollable]="true" [rows]="10"
      scrollHeight="600px">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell">
            <div class="three-dots" (click)="selectItem($event,'item',op3)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData="" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output"> {{rowData[col.field]}} </ng-template>
              <ng-template *ngIf="col.field === 'status'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field] |
                  uppercase}}</button></ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" />
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" (click)="selectItem($event,'item',op3)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #op3> <p-listbox [options]="options"> </p-listbox> </p-overlayPanel>