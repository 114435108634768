<modal class="dark" id="session-modal" [visible]="isSessionTimingOut">
  <div id="session-body">
    <div class="countdown-container">
      <div class="icon" [inlineSVG]="'assets/TimeOut.svg'"></div>
      <div class="countdown">{{ activityCtx.sessionTimingOutCountText }}</div>
    </div>
    <div class="message">{{ strings?.YourSessionWillExpire }}</div>
    <div class="buttons">
      <button (click)="sessionTimeoutRefresh()">{{ strings?.KeepMeLoggedIn }}</button>
      <button (click)="sessionLogout()">{{ strings?.CloseSession }}</button>
    </div>
  </div>
</modal>
