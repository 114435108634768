<div class="crm-container" *ngIf="!isLoading">
  <app-section-header [title]="'Rate Bucket Configuration Table Results'" />
  <div class="sub-section">
    <cv-rate-bucket-common [dataList]="adminList" [title]="'Admin'" [categoryCode]="'Admin'"
      (formValueChanged)="getRateBucketData($event)">
    </cv-rate-bucket-common>
    <cv-rate-bucket-common [dataList]="commissionList" [title]="'Commission'" [categoryCode]="'Commissions'"
      (formValueChanged)="getRateBucketData($event)">
    </cv-rate-bucket-common>
    <cv-rate-bucket-common [dataList]="reservesAndFeesList" [title]="'Reserves and Fees'"
      [categoryCode]="'Reserves and Fees'" (formValueChanged)="getRateBucketData($event)">
    </cv-rate-bucket-common>
  </div>
</div>