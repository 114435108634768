<p-dialog [header]="modalTitle" [(visible)]="displayDialog" (onHide)="onCancel()" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <div class="grid">
      <div class="col-4">
        <div class="grid">
          <div [ngClass]="'col-12 title'">Dealer</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Dealer</div>
          <div class="col-6">{{serviceInfo.dealer}}</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Selling Retailer</div>
          <div class="col-6">{{serviceInfo.sellingRetailer}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="grid">
          <div [ngClass]="'col-12 title'">Product Information</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Manufacturer</div>
          <div class="col-6">{{serviceInfo.productManufacturer}}</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Model</div>
          <div class="col-6">{{serviceInfo.productModel}}</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Condition</div>
          <div class="col-6">{{serviceInfo.productCondition}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="grid">
          <div [ngClass]="'col-12 title'">Product Location</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Address1</div>
          <div class="col-6">{{serviceInfo.address1}}</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">State</div>
          <div class="col-6">{{serviceInfo.state}}</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-6 label-color'">Zip</div>
          <div class="col-6">{{serviceInfo.zipCode}}</div>
        </div>
      </div>
    </div>
    <servicers-table (selectedEvent)="onSelectedServicer($event)" (dispatchModelEvent)="onDispatchDisplayChange($event)"></servicers-table>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button left-align" type="button" (click)="onDispatch()" label="Cancel"
        [disabled]="dispatchDisabled">
        Dispatch</button><button class="crm-button left-align" *ngIf="!sbNoJobDispatchDisabled" type="button"
        (click)="onDispatchNoJob()" label="Cancel">
        Dispatch No Job</button><button class="crm-button left-align" *ngIf="!spNoJobDispatchDisabled" type="button"
        (click)="onDispatchNoJob()" label="Cancel">
        Dispatch No Job</button><span class="info-span left-align"
        *ngIf="isSuspendedServicer">{{suspendedInfo}}</span><button class="crm-button" type="button"
        (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>
<send-email-modal modalTitle="Send Email" [(displayDialog)]="displayEmailModal"
  (formSubmitEvent)="onValidatorSubmit($event)" (displayDialogChange)="onDisplayChange($event)">
</send-email-modal>
<service-bench-modal *ngIf="displayServiceBenchModal" modalTitle="Schedule Service Bench"
  [(displayDialog)]="displayServiceBenchModal" (formSubmitEvent)="onServicerBenchSchedule($event)">
</service-bench-modal>
<service-power-modal *ngIf="displayServicePowerModal" modalTitle="Schedule Service Power"
  [(displayDialog)]="displayServicePowerModal" (formSubmitEvent)="onServicerPowerSchedule($event)">
</service-power-modal>
<crm-info-modal *ngIf="displayInfoModal" [modalTitle]="sbInfoTitle" [modalContent]="sbInfoMessage"
  submitButtonlabel="OK" [(displayDialog)]="displayInfoModal"
  (displayDialogChange)="onInfoDisplayChange($event)"></crm-info-modal><p-dialog header="Dispatch No Job"
  [(visible)]="displayDispatchNoJobModal" showeffect="fade" [modal]="true" [styleClass]="'note-dailog'">
  <div class="modal-content">
    <form class="content" (ngSubmit)="onSubmitNoJob()" [formGroup]="form">
      <div class="form-row" *ngFor="let formField of formFieldsNoJob">
        <form-field [formField]="formField" [form]="formNoJob"></form-field>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onSubmitNoJob()" label="Yes">
        Dispatch</button><button class="crm-button" type="button" icon="fa fa-close"
        (click)="displayDispatchNoJobModal=false" label="No">
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>