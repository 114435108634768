export interface SetupAlerts {
  alert_id?;
  status?;
  message?;
  prod_type?;
  mfw?;
  cat?;
  sub_cat?;
  dealer?;
  dealer_loc?;
  buying_group?;
  module_type?;
  eff_date_from?;
  eff_date_to?;
}

export enum AlertFilterType {
  ModuleType = 'ModuleType',
  ProductPlanType = 'ProductPlanType',
  Category = 'Category',
  SubCategory = 'SubCategory',
  Manufacturer = 'Manufacturer',
  Dealer = 'Dealers',
  DealerLocation = 'DealerLocations',
  BuyingGroup = 'BuyingGroup'
}
