import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractService } from '../../contract.service';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';

@Component({
  selector: 'additional-info',
  templateUrl: 'additional-info.template.html',
  styleUrls: ['additional-info.scss'],
  providers: [FormFieldControlService],
})
export class AdditionalInfoComponent extends FormCanDeactivate implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  form: FormGroup;
  @Output() modelDataChange = new EventEmitter();
  authDetails: IRoleAuthorizationService;
  constructor(
    private qcs: FormFieldControlService,
    private contractService: ContractService,
    private roleService: RoleAuthorizationService,
  ) {
    super();
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: AdditionalInfoComponent,
      generalArray: this.formFields,
    };
    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.modelDataChange.emit(x);
    });
    this.contractService.isLoading.next(true);
    this.contractService.isMainTabDataAvailable.subscribe({
      next: (isMainTabDataAvailable) => {
        if (isMainTabDataAvailable) {
          this.form.patchValue({
            storeLocationName:
              this.contractService.contractDataModel.mainTab.additionalInformation
                .storeLocationName,
            storeLocationNumber:
              this.contractService.contractDataModel.mainTab.additionalInformation
                .storeLocationNumber,
            salesClerk:
              this.contractService.contractDataModel.mainTab.additionalInformation.salesClerk,
            agentName:
              this.contractService.contractDataModel.mainTab.additionalInformation.agentName,
            masterBuyingGroup:
              this.contractService.contractDataModel.mainTab.additionalInformation
                .masterBuyingGroup,
            dealerGroupName:
              this.contractService.contractDataModel.mainTab.additionalInformation.dealerGroupName,
            dealerGroupNumber:
              this.contractService.contractDataModel.mainTab.additionalInformation
                .dealerGroupNumber,
            phoneNumber1:
              this.contractService.contractDataModel.mainTab.additionalInformation.phoneNumber1,
            tSelfServicing:
              this.contractService.contractDataModel.mainTab.additionalInformation.tSelfServicing,
          });
        }
        this.contractService.isLoading.next(false);
      },
    });
  }

  onSubmit() {
    // functionality for on submit
  }
}
