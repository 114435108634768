import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { DropdownDataService } from '../../services/dropdown.data.service';

const inquiryQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      contractInquiry {
        contractInquiryEventsId
        contractsId
        status
        assignedTo
        userName
        content
        enteredBy
        enteredTime
        contractInquiryEventsIdOriginal
      }
    }
  }
`;

const createUpdateContractInquiry = gql`
  mutation (
    $contractsId: Int!
    $contractNumber: String
    $status: String
    $assignedTo: String
    $content: String
    $enteredBy: String
    $contractInquiryEventsIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String
    $addedTimeStamp: Date
  ) {
    createUpdateContractInquiry(
      contractInquiry: {
        contractsId: $contractsId
        contractNumber: $contractNumber
        status: $status
        assignedTo: $assignedTo
        content: $content
        enteredBy: $enteredBy
        contractInquiryEventsIdOriginal: $contractInquiryEventsIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        addedTimeStamp: $addedTimeStamp
      }
    ) {
      contractInquiryEventsId
      contractsId
      contractNumber
      status
      assignedTo
      content
      enteredBy
      enteredTime
      contractInquiryEventsIdOriginal
      insertUserName
      addedTimeStamp
    }
  }
`;

const getUserDetails = gql`
  query ($azureUserId: String) {
    getUserDetails(azureUserId: $azureUserId) {
      key
      value
    }
  }
`;

@Injectable()
export class InquiryTabService {
  templateDetails = {};
  typeMapping = {};
  isLoading: boolean;
  constructor(
    private apollo: Apollo,
    private dropdownDataService: DropdownDataService,
  ) {}

  getInquiryFormModalFields() {
    const optionsUserList = this.dropdownDataService.userListData
      ? this.dropdownDataService.userListData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    optionsUserList.unshift({ key: '', value: '' });
    const formFields: FormField<string>[] = [
      new DropdownField({
        required: true,
        key: 'assignedTo',
        label: 'Assigned To',
        filter: true,
        isSingleselect: true,
        options: optionsUserList,
        order: 0,
      }),

      new DropdownField({
        required: true,
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Open', value: 'Open' },
          { key: 'Closed', value: 'Closed' },
          { key: 'InProgress', value: 'InProgress' },
          { key: 'New', value: 'New' },
          { key: 'OnHold', value: 'OnHold' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        required: true,
        key: 'content',
        label: 'Content',
        order: 2,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getInquiry = (searchParams) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: inquiryQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateContractInquiry = (contractInquiry) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateContractInquiry,
      variables: {
        ...contractInquiry,
      },
      // tslint:disable-next-line: semicolon
    });

  getInquiryQueueEmail = (searchParams) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getUserDetails,
      variables: { ...searchParams },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getEmailRequest(emailAddress, contractNumber, dealerName) {
    const templateName = 'TEMPLATE_ShortCode_template1';

    if (emailAddress === '') {
      // If there is no email address, skip.
      return null;
    }
    const inquiryQueueDetails = this.addInquiryQueueDetails(templateName, emailAddress);
    const request = {
      operation: 'send_template',
      data: {
        from: 'donotreply@newleafsc.net',
        receivers: [
          {
            Email: emailAddress,
            FirstName: 'FirstName',
            LastName: 'LastName',
          },
        ],
        variables: {
          custom_subject: `New Inquiry Assignment - Contract # ${contractNumber} - ${dealerName}`,
          custom_text: ` `,
        },
        template: templateName,
      },
      transactionDetails: inquiryQueueDetails,
    };
    return request;
  }

  addInquiryQueueDetails(templateName, emailAddress) {
    return {
      EmailAddress: emailAddress,
      EmailTemplate: templateName,
      InsertNote: false,
    };
  }
}
