import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SalesRepresentativeService {
  constructor() {}
  getSalesRepresentative(): Observable<any[]> {
    return of([
      {
        id: 1,
        code: 'SREPR1',
        name: 'Sales Representative 1'
      },
      {
        id: 2,
        code: 'SREPR2',
        name: 'Sales Representative 2'
      },
      {
        id: 3,
        code: 'SREPR3',
        name: 'Sales Representative 3'
      }
    ]);
  }
}
