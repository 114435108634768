import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const defaultStatus = 'ACTIVE';

const getCoverageSearchResultsQuery = gql`
  query($code: String, $skuCode: String, $offset: Int, $limit: Int) {
    getCoverageSearchResults(code: $code, skuCode: $skuCode, offset: $offset, limit: $limit) {
      code
      activeFrom
      activeTo
      skuCode
      coveragePlansIdOriginal
      priceTable
    }
  }
`;

const getCoverageProfileByIdQuery = gql`
  query($coverageId: Int) {
    getCoverageProfileById(coverageId: $coverageId) {
      coveragePlansIdOriginal
      coverageName
      priceTable
      coveragePlansId
    }
  }
`;

@Injectable()
export class SearchCoverageService {
  constructor(private apollo: Apollo) {}

  getCoverageSearchResults = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getCoverageSearchResultsQuery,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getCoverageProfileByIdResult = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getCoverageProfileByIdQuery,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
