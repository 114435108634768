import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { FormGroup } from '@angular/forms';
import _find from 'lodash/find';
import { result } from 'lodash-es';
import { numberWithCommas, stringToNumber } from '../../shared/utilities/common-utilities';

@Component({
  selector: 'coverage-tab',
  templateUrl: 'coverage-tab.template.html',
  styleUrls: ['coverage-tab.scss'],
  providers: [FormFieldControlService],
})
export class CoverageTabComponent extends FormCanDeactivate implements OnInit {
  coverageList: any[];
  cols: any[];
  tabKey: string;
  querySubscription: any;
  isLoading: boolean;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  disableLoadMore: boolean;
  lastOffsetVal = 0;
  offsetLimit = 10;
  isTableLoading: boolean;
  constructor(
    private contractService: ContractService,
    private tabHandlerService: TabHandlerService,
    private contractFormFieldService: ContractFormFieldService,
    private qcs: FormFieldControlService,
  ) {
    super();
  }

  ngOnInit() {
    this.contractFormFieldService.getcoverageTabFormFields().subscribe((formFields) => {
      this.formFields = formFields;
      this.form = this.qcs.toFormGroup(this.formFields);
    });

    this.cols = [
      { field: 'name', header: 'Name', width: '20%' },
      { field: 'description', header: 'Description', width: '35%' },
      { field: 'benefitItem', header: 'Benefit Term', width: '10%' },
      { field: 'expirationDate', header: 'Expiration Date', width: '18%' },
      { field: 'cost', header: 'Cost', width: '17%' },
    ];

    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab && this.contractService.contractConfig.searchParams.id) {
          this.isLoading = true;
          this.querySubscription = this.contractService
            .getContractCoverage(this.contractService.contractConfig.searchParams.id)
            .subscribe(
              ({ data, loading }: any) => {
                const modifiedData = data.getContractProfileById;
                this.disableLoadMore =
                  Boolean(
                    modifiedData && modifiedData.coverageLossCodes.length < this.offsetLimit,
                  ) || Boolean(!modifiedData);
                if (modifiedData && modifiedData.coveredProducts) {
                  // populate loss code table
                  if (modifiedData.coverageLossCodes && modifiedData.coverageLossCodes.length > 0) {
                    this.coverageList = [];
                    for (const coverage of modifiedData.coverageLossCodes) {
                      this.coverageList.push({
                        name: coverage.lossCode,
                        description: coverage.description,
                        benefitItem: coverage.termMonth,
                        expirationDate: null,
                        cost: coverage.cost,
                      });
                    }
                  }

                  this.form.patchValue({
                    coverageDescription: modifiedData.coverageDescription,
                    coverageCode: modifiedData.coverageCode,
                    partsTerm:
                      modifiedData.coverageInformation && modifiedData.coverageInformation.term,
                    serviceLocation:
                      modifiedData.coverageInformation &&
                      modifiedData.coverageInformation.serviceLocation,
                    retailRate: modifiedData.retailRate
                      ? '$' + numberWithCommas(stringToNumber(String(modifiedData.retailRate)))
                      : '$0.00',
                    retailTax1: modifiedData.retailTax1
                      ? '$' + numberWithCommas(stringToNumber(String(modifiedData.retailTax1)))
                      : '$0.00',
                    retailTax2: modifiedData.retailTax2
                      ? '$' + numberWithCommas(stringToNumber(String(modifiedData.retailTax2)))
                      : '$0.00',
                    grandTotal: modifiedData.grandTotal
                      ? '$' + numberWithCommas(stringToNumber(String(modifiedData.grandTotal)))
                      : '$0.00',
                  });
                }
                this.isLoading = loading;
              },
              (err) => {
                this.isLoading = false;
                throw err;
              },
            );
        }
      },
    });
  }

  getKeyValue(array, key) {
    return _find(array, 'key', key);
  }

  onSubmit() {
    // functionality for on submit
  }

  getCoverageLossCodes() {
    this.isTableLoading = true;

    this.lastOffsetVal += this.offsetLimit;
    this.contractService
      .getContractCoverage(
        this.contractService.contractConfig.searchParams.id,
        this.offsetLimit,
        this.lastOffsetVal,
      )
      .subscribe(({ data, loading }: any) => {
        this.isTableLoading = false;

        const modifiedData = data.getContractProfileById;

        if (
          modifiedData &&
          modifiedData.coverageLossCodes &&
          modifiedData.coverageLossCodes.length > 0
        ) {
          this.disableLoadMore = Boolean(modifiedData.coverageLossCodes.length < this.offsetLimit);
          for (const coverage of modifiedData.coverageLossCodes) {
            this.coverageList.push({
              name: coverage.lossCode,
              description: coverage.description,
              benefitItem: coverage.termMonth,
              expirationDate: null,
              cost: coverage.cost,
            });
          }
        } else {
          this.disableLoadMore = false;
        }
      });
  }
}
