<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content">
    <div class="grid">
      <div [ngClass]="'col-12 title'">Main</div>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-4" *ngFor="let formField of formFields1; let i = index">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-4">
          <form-field [formField]="formFields[3]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[4]" [form]="form"></form-field>
        </div>
        <div class="col-4"></div>
        <div class="col-4">
          <form-field [formField]="formFields[5]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[6]" [form]="form"></form-field>
        </div>
        <div class="col-4"></div>
        <div class="col-4">
          <form-field [formField]="formFields[7]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[8]" [form]="form"></form-field>
        </div>
        <div class="col-4"></div>
        <div class="col-8">
          <form-field [formField]="formFields[9]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-4" *ngFor="let formField of formFields2; let i = index">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div [ngClass]="'col-12 title'">Summary</div>
        <div class="col-12">
          <form-field [formField]="formFields[29]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button label-btn btn-disabled" *ngIf="!form.valid" tabindex="0" (click)="onSubmit()" >Add</button>
      <button class="crm-button left-align" type="button" (click)="onSendEmail()" label="Cancel">
        Send Email
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>
<send-vt-short-key-email modalTitle="Send Email" [selectedCode]="selectedCode" [(displayDialog)]="displayEmailModal"
  (formSubmitEvent)="onValidatorSubmit($event)">
</send-vt-short-key-email>