import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { ServiceTypeService } from '../services.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../service/service-ellipsis';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ServiceCenterLookupService } from './service-center-lookup.service';
import { TabsService } from '../../services/tabs.service';
import { AzureMapsService } from 'src/app/services/azure-maps.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { copy } from '../../shared/utilities/common-utilities';

@Component({
  selector: 'service-center-lookup',
  templateUrl: 'service-center-lookup.template.html',
  styleUrls: ['./service-center-lookup.scss'],
  providers: [AzureMapsService, MessageService],
})
export class ServiceCenterLookupComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  cols: any[];
  dataList: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayVerify: boolean;
  isLoading: boolean;
  offsetLimit = 10;
  lastOffsetVal = 0;
  disableLoadMore = false;
  resultCount: number;
  currentMaxId: number;
  newTab: any;
  querySubscriptions: Subscription[] = [];
  displayInfoModal = false;
  constructor(
    private search: ServiceCenterLookupService,
    private tabService: TabsService,
    private cdr: ChangeDetectorRef,
    private azureMaps: AzureMapsService,
    private messageService: MessageService,
  ) {
    this.displayVerify = false;
  }

  ngOnInit() {
    this.cols = [
      { field: 'servicer_name', header: 'SERVICER NAME', type: 'text' },
      { field: 'self_servicing', header: 'SELF SERVICING', type: 'button' },
      { field: 'mfg_authorized', header: 'MFG AUTHORIZED', type: 'button' },
      { field: 'distance', header: 'DISTANCE', type: 'text' },
      { field: 'sealed_systems', header: 'SEALED SYSTEMS', type: 'button' },
      { field: 'customer_satisfaction', header: 'CUSTOMER SATISFACTION', type: 'text' },
      { field: 'rating', header: 'RATING', type: 'text' },
      { field: 'contracted', header: 'CONTRACTED', type: 'button' },
      { field: 'valid_insurance', header: 'VALID INSURANCE', type: 'button' },
      { field: 'national_servicer', header: 'NATIONAL SERVICER', type: 'button' },
    ];
    this.loadServiceCenterLookupData();
  }

  getServiceCenterLookup() {
    this.isLoading = true;
    this.querySubscriptions.push(
      this.search.getServiceCenterLookup(this.searchParams).subscribe(({ data }) => {
        this.isLoading = false;
        this.currentMaxId = 0;
        const response = data.getServiceCenterLookup;
        if (response) {
          const modifedData = response.map((el) => {
            el = this.formatData(el);
            this.currentMaxId += 1;
            el.id = this.currentMaxId;
            return el;
          });
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
          this.resultCount = this.dataList.length;
        } else {
          this.dataList = [];
          this.disableLoadMore = false;
        }
      }),
    );
  }

  loadServiceCenterLookupData() {
    let isSearchExists = false;
    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string' ? this.searchParams : {};
    Object.keys(this.searchParams).map((el) => {
      if (this.searchParams[el] !== '') {
        isSearchExists = true;
      }
    });
    if (isSearchExists) {
      this.searchParams.limit = this.offsetLimit;
      this.searchParams.offset = this.lastOffsetVal;
      let isContract = null;
      if (typeof this.searchParams.contract === 'object' && this.searchParams.contract !== null) {
        if (this.searchParams.contract.key === 'Yes' || this.searchParams.contract.key === 'No') {
          isContract = this.searchParams.contract.key === 'Yes' ? true : false;
        }
      }
      if (typeof this.searchParams.contract === 'boolean') {
        isContract = this.searchParams.contract;
      }
      this.searchParams.contract = isContract;
      this.lastOffsetVal += this.offsetLimit;
      if (this.searchParams.radius && !this.searchParams.zip) {
        console.log('Please enter a Zip Code along with Radius');
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please enter Radius along with Zip Code.',
        });
      } else {
        if (this.searchParams.zip) {
          this.isLoading = true;
          this.querySubscriptions.push(
            this.azureMaps.getAddressDetails(this.searchParams.zip).subscribe((address) => {
              this.isLoading = false;
              if (address.body) {
                const zipLat = address.body.latitude;
                const zipLong = address.body.longitude;
                this.searchParams.zipLat = zipLat;
                this.searchParams.zipLong = zipLong;
                this.getServiceCenterLookup();
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Please enter a valid Zip Code.',
                });
              }
            }),
          );
        } else {
          this.getServiceCenterLookup();
        }
      }
    }
  }

  // FROM API row to table row data
  formatData(item) {
    return {
      id: 0,
      servicerProfilesIdOriginal: item.servicerProfilesIdOriginal,
      isNew: false,
      servicer_name: item.servicerName,
      phone_number: item.phoneNumber,
      email_address: item.emailAddress,
      address: item.address1 + ', ' + item.city + ', ' + item.state + ' ' + item.zipcode,
      self_servicing: item.selfServicing,
      mfg_authorized: item.mfgAuthorized,
      distance: item.distance,
      sealed_systems: item.sealedSystems,
      customer_satisfaction: item.customerSatisfaction,
      rating: item.rating,
      contracted: item.contracted,
      valid_insurance: item.validInsurance,
      national_servicer: item.nationalServiceProvider,
    };
  }

  openTab(data: any) {
    this.newTab = {
      header: 'Servicer Profile',
      content: data,
      type: 'Servicer',
    };
    this.tabService.createTab(this.newTab);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e) {
    if (e.value === 'Add') {
      this.displayVerify = true;
    }
    this.cdr.detectChanges();
  }
  formData(el: any): any {
    throw new Error('Method not implemented.');
  }
  onYes() {
    this.displayVerify = false;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
