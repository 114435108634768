<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-4">
          <form-field [formField]="formFields[0]" [form]="form" (changeEvent)="onChange($event)"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[1]" [form]="form" (changeEvent)="onChange($event)"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[3]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[4]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[5]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[6]" [form]="form" (changeEvent)="onChange($event)"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[7]" [form]="form" (changeEvent)="onChange($event)"
            (keydown)="priceFieldValidation($event)"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[8]" [form]="form"></form-field>
        </div>
        <div class="col-4 requested-amount">
          <form-field [formField]="formFields[9]" [form]="form"></form-field>
        </div>
        <div class="col-4"></div>
        <div class="col-4"></div>
        <div class="col-4">
          <form-field [formField]="formFields[10]" [form]="form" (changeEvent)="onChange($event)"
            (keydown)="priceFieldValidation($event)"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[11]" [form]="form" (changeEvent)="onChange($event)"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[12]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[13]" [form]="form" (changeEvent)="onChange($event)"
            (keypress)="discountFieldValidation($event)"></form-field>
        </div>
        <div class="col-4 approved-amount">
          <form-field [formField]="formFields[14]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Submit" [disabled]="!form.valid">
        Submit
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>