<div class="crm-container">
  <app-section-header [title]="'Search Message Results'" [optionHeader]="optionHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" *ngIf="!isLoading" [columns]="cols" [value]="dataList" dataKey="id"
      editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output">{{rowData[col.field]}}</ng-template>
              <ng-template *ngIf="col.field === 'status'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field] |
                  uppercase}}</button></ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.field === 'message_code'" pTemplate="output">
                <a (click)="openTemp(rowData)" onmouseover="this.style.cursor='pointer'">{{rowData[col.field] |
                  uppercase}}</a>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event,rowData,optionRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button><button class="ui-button-success" *ngIf="editing"
              pButton="pButton" type="button" pSaveEditableRow="pSaveEditableRow" icon="pi pi-check"
              style="margin-right: 0.5em" (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger"
              *ngIf="editing" pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions" (onClick)="handleOpen($event)"> </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionHeader>
  <p-listbox [options]="ellipsisHeaderOptions" (onClick)="handleOpen($event)"> </p-listbox>
</p-overlayPanel>
<p-dialog header="Verify Email Address" [(visible)]="displayVerify" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <p>
    Details:The email address must be verified before it can be used to send the email as the source. An email
    verification will be sent to
    verify.
  </p>
  <label>Email Address</label><input /><ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="displayVerify=false"
        label="No">Cancel</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onYes()" label="Yes">Save</button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog header="Email Template" [(visible)]="displayAdd" showeffect="fade" [modal]="true" [styleClass]="'note-dailog'"
  [blockScroll]="true">
  <div class="email-temp">
    <email-temp-form [formFields]="formModalInfo" [selectedItem]="selectedItemTrigger"></email-temp-form>
  </div>
  <div class="image-file">
    <images-import-file [formFields]="modalImgInfo" [selectedItem]="selectedItemTrigger"></images-import-file>
  </div>
  <div class="modal-temp">
    <modal-template [formFields]="modalTempInfo"
      [selectedItem]="selectedItemTrigger"></modal-template><file-attachment></file-attachment>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="displayAdd=false" label="No">Cancel</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onYes()" label="Yes">Save</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="sendEmail()" label="Yes">Test Send</button>
    </div>
  </ng-template>
</p-dialog>