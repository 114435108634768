'use strict';

export function getMsoBase(): string {
  const splits: string[] = window.location.pathname.slice(1).split('/');

  if (splits[0] === 'ent') {
    return '/' + splits.splice(0, 2).join('/') + '/';
  } else {
    return '/';
  }
}

export function getMso(): string {
  const splits: string[] = window.location.pathname.slice(1).split('/');
  if (splits[0] === 'ent') {
    return splits[1];
  } else {
    return '';
  }
}

export function getAppName(): string {
  const splits: string[] = window.location.pathname.slice(1).split('/');
  if (splits[0] === 'ent') {
    return splits[2];
  } else {
    return '';
  }
}

const pathnameSplits = window.location.pathname.slice(1).split('/');
export const appBase = '/' + pathnameSplits.splice(0, 3).join('/') + '/';
