// import { Configuration } from 'msal';
// import { MsalAngularConfiguration } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { appBase } from 'src/app-base-factory';

/* Idle User Configuration
 *  Make sure to stop your local app before new values take into affect.
 *  The TOKEN_REFRESH_TIME must be under 300 seconds, as 300 is the default value that a token
 *    will refresh:
 *      tokenRenewalOffsetSeconds    - sets the window of offset needed to renew the token before expiry
 */
export const IDLE_TIME = 18000; // in sec -- 5 hrs
export const TIMEOUT_COUNT = 60; // in sec -- 1 mins
export const PING_PERIOD = 300; // in sec -- 5 mins
export const TOKEN_REFRESH_TIME = 600; // in sec -- 10 mins

// this checks if the app is running on IE
export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

/** =================== REGIONS ====================
 * 1) B2C policies and user flows
 * 2) Web API configuration parameters
 * 3) Authentication configuration parameters
 * 4) MSAL-Angular specific configuration parameters
 * =================================================
 */

// #region 1) B2C policies and user flows
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: environment.signInName,
    resetPassword: environment.passwordResetName,
  },
  authorities: {
    signUpSignIn: {
      authority: environment.signInAuthority,
    },
    resetPassword: {
      authority: environment.passwordResetAuthority,
    },
  },
};
// #endregion

// #region 2) Web API Configuration
/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: { b2cScopes: string[]; webApi: string } = {
  b2cScopes: [environment.b2cScope],
  webApi: environment.webApi,
};

export const clearRedirect = () => {
  localStorage.removeItem('Redirect');
};
export const getRedirect = () => {
  return localStorage.getItem('Redirect');
};

// #endregion

// #region 3) Authentication Configuration
/**
 * Config object to be passed to Msal on creation. For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 */
// export const msalConfig: Configuration = {
//   auth: {
//     clientId: environment.clientId,
//     authority: b2cPolicies.authorities.signUpSignIn.authority,
//     redirectUri: environment.redirectUrl,
//     postLogoutRedirectUri: environment.redirectUrl,
//     navigateToLoginRequestUrl: true,
//     validateAuthority: false,
//   },
//   cache: {
//     cacheLocation: 'sessionStorage',
//   },
// };

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest: { scopes: string[] } = {
  scopes: ['openid', 'profile'],
};

// Scopes you enter will be used for the access token request for your web API
export const tokenRequest: { scopes: string[] } = {
  scopes: apiConfig.b2cScopes, // i.e. [https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read]
};
// #endregion

// #region 4) MSAL-Angular Configuration
// here you can define the coordinates and required permissions for your protected resources
export const protectedResourceMap: [string, string[]][] = [
  [`${appBase}assets/languages/strings.en-us.json`, null],
  [apiConfig.webApi, apiConfig.b2cScopes],
];

/**
 *  MSAL-Angular specific authentication parameters. For a full list of available options,
 *  visit
 *  https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular#config-options-for-msal-initialization
 */
// export const msalAngularConfig: MsalAngularConfiguration = {
//   popUp: !isIE,
//   consentScopes: [...loginRequest.scopes, ...tokenRequest.scopes],
//   unprotectedResources: [], // API calls to these coordinates will NOT activate MSALGuard
//   protectedResourceMap, // API calls to these coordinates will activate MSALGuard
//   extraQueryParameters: {}
// };
// #endregion
