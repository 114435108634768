import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';

@Component({
  selector: 'user-contact-info',
  templateUrl: 'user-contact-info.template.html',
  styleUrls: ['user-contact-info.scss'],
  providers: [FormFieldControlService],
})
export class UserContactInfoComponent implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  form: FormGroup;

  constructor(private qcs: FormFieldControlService) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  onSubmit() {
    // functionality for on submit
  }
}
