export const DealerLocationDisplayStatus = {
  A: 'Active',
  C: 'Cancelled',
  T: 'Terminated',
  P: 'Pending'
};

export const DealerLocationStatus = {
  A: 'A',
  C: 'C',
  T: 'T'
};

export const GenericDealerStatus = {
  Active: 'Active',
  Cancel: 'Cancel',
  Terminated: 'Terminated'
};
