import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { UploadService } from '../../../../services/upload.service';
import { AddressCategoryType } from '../../../../shared/constants/enums';
import { ImportFile, FieldNames } from '../../../../shared/import-file/import-file';

@Component({
  selector: 'servicer-import-section',
  templateUrl: 'servicer-import-file.template.html',
  styleUrls: ['servicer-import-file.scss'],
  providers: [MessageService, UploadService],
})
export class ServicerImportFileComponent implements OnInit {
  @Input() title: any;
  @Input() isGeneric: boolean;
  @Input() documentType: any;
  @Input() attachmentId: any;
  public records: ImportFile;
  public fieldsNames: FieldNames;
  public validCSV: string;
  public headersRow: any[];
  public index: any;
  public fileResponse: any;
  msgs: any[];
  documentOptions: { key: string; value: number }[];
  selectedDocument: any;
  documentDescription: string;
  currentUser: any;
  constructor(
    private messageService: MessageService,
    private uploadService: UploadService,
    private azureService: AzureLoginService,
  ) {}

  ngOnInit() {
    this.selectedDocument = {
      key: AddressCategoryType[this.documentType],
      value: this.documentType,
    };
    this.cleanRecords();
  }

  // Upload and read CSV file
  onUpload(event: { files: any }) {
    const uploadedFiles: any[] = [];

    for (const file of event.files) {
      uploadedFiles.push(file);
    }

    // TODO: Only basic integration is done. Need to refactor te code
    const formData = new FormData();
    console.log(this.attachmentId);
    if (!this.attachmentId) {
      window.alert(`Servicer Profile Not Found.`);
      return;
    }

    formData.append('AttachmentId', this.attachmentId.toString());
    formData.append('AttachmentsType', this.selectedDocument.key);
    formData.append('filename', uploadedFiles[0].name);
    formData.append('description', this.documentDescription ? this.documentDescription : '');
    formData.append('user', this.currentUser);
    formData.append('fileContent', uploadedFiles[0], uploadedFiles[0].name);
    this.messageService.clear();
    const header = new HttpHeaders({
      Authorization: `Bearer ${this.azureService.accessToken}`,
    });
    this.uploadService.upload(formData, header).subscribe(
      (response) => {
        console.log(response);
        if (response.Body.fileUrl) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'File is uploaded successfully.',
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'File Upload is Failed.',
          });
        }
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'File Upload is Failed.',
        });
      },
    );

    // TODO: Refactor

    // const reader = new FileReader();
    // reader.readAsText(uploadedFiles[0]);

    // reader.onload = () => {
    //   const csvData = reader.result;
    //   const csvRecordsArray = (csvData as string).split(/\r\n|\n/);

    //   if (this.getHeaderArray(csvRecordsArray)) {
    //     let csvArr = [];

    //     this.showSuccess();
    //     this.headersRow = this.getHeaderArray(csvRecordsArray);
    //     csvArr = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, this.headersRow);
    //     this.validCSV = 'Passed';

    //     this.fieldsNames = {
    //       validation_name: 'Validation',
    //       total_count_name: 'Total Count',
    //       failed_count_name: 'Failded Count',
    //       warning_count_name: 'Warning Count',
    //       duplicated_count_name: 'Duplicated Count',
    //       ready_to_process_count_name: 'Ready To Process Count',
    //       processed_count_name: 'Processed Count',
    //       count_name: this.fileResponse.ModuleName + ' Count',
    //       cost_name: this.fileResponse.ModuleName + ' Cost'
    //     };

    //     this.records = {
    //       validation: this.validCSV,
    //       total_count: csvArr.length,
    //       failed_count: 0,
    //       warning_count: 0,
    //       duplicated_count: 0,
    //       ready_to_process_count: csvArr.length,
    //       processed_count: 0,
    //       cost: '0',
    //       contract_count: csvArr.length
    //     };
    //   } else {
    //     this.showError();
    //     this.validCSV = 'Failed';
    //     this.records = {};
    //   }
    // };
  }

  // Extract header from CSV array and validate name of the columns with the array of mandatory column data
  getHeaderArray(csvRecordsArr: any) {
    let headers: string[];

    for (let i = 0; i < csvRecordsArr.length; i++) {
      if (
        this.fileResponse.AcceptedColumns.every((v: any) => csvRecordsArr[i].split(',').includes(v))
      ) {
        this.index = i;
        headers = (csvRecordsArr[i] as string).split(',');
      }
    }
    if (headers) {
      const headerArray = [];
      for (const row of headers) {
        headerArray.push(row);
      }
      return headerArray;
    }

    return [];
  }

  // Extract only data rows from CSV array.
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, header: any) {
    const csvArr = [];

    for (let i = this.index + 1; i < csvRecordsArray.length - 1; i++) {
      const currentRecord = (csvRecordsArray[i] as string).split(',');
      csvArr.push(currentRecord);
    }

    return csvArr;
  }

  splitByColumns(headersRow: any, csvArr: any) {
    const rowToObject = (headers: any[], cells: { [x: string]: any }) =>
      headers.reduce((acc, header, i) => {
        acc[header] = cells[i];
        return acc;
      }, {});

    return csvArr.map((row) => rowToObject(headersRow, row));
  }

  sumCostColumn(namedColumns: any[]) {
    const sumTotal = namedColumns.reduce((prev, cur) => {
      return prev + Number(cur.ExtendedDealerCost.replace(/[^0-9.-]+/g, ''));
    }, 0);

    return sumTotal;
  }

  showError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Validation failed.' });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Validation completed successfully.',
    });
  }

  cleanRecords() {
    this.records = {
      validation: '',
      total_count: null,
      failed_count: null,
      warning_count: null,
      duplicated_count: null,
      ready_to_process_count: null,
      processed_count: null,
      cost: '',
      contract_count: null,
    };

    this.fieldsNames = {
      validation_name: '',
      total_count_name: '',
      failed_count_name: '',
      warning_count_name: '',
      duplicated_count_name: '',
      ready_to_process_count_name: '',
      processed_count_name: '',
      count_name: '',
      cost_name: '',
    };
  }

  fileReset() {
    this.cleanRecords();
  }
}
