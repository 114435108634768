import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const limitQuery = gql`
  query($servicerNumber: String!) {
    getServicerPreAuthDetails(servicerNumber: $servicerNumber) {
      servicerRatesId
      servicerName
      servicerNumber
      servicersId
      effectiveDate
      description
      limit
      categoryDescription
      subcategoryDescription
      rateType
      servicerRatesIdOriginal
      isDeleted
      insertUserName
      addedTimeStamp
    }
  }
`;

const getPreAuthQuery = gql`
  query($serviceOrderNumber: String!) {
    getExpensePreAuthDetails(serviceOrderNumber: $serviceOrderNumber) {
      preAuthRequestId
      preAuthRequestIdOriginal
      approvedAmount
      authorizationCode
      preAuthNotes
      isDeleted
      claimId
      servicerId
      tenantId
      userName
      addedTimeStamp
      serviceOrderNumber
    }
  }
`;

const createUpdatePreAuthQuery = gql`
  mutation(
    $claimNumber: String!
    $insertUserName: String!
    $approvedAmount: Float!
    $servicerId: Int!
    $preAuthNotes: String
    $serviceOrderNumber: String!
  ) {
    createOrUpdateExpensePreAuth(
      expensePreAuthorization: {
        insertUserName: $insertUserName
        approvedAmount: $approvedAmount
        preAuthNotes: $preAuthNotes
        claimNumber: $claimNumber
        servicerId: $servicerId
        serviceOrderNumber: $serviceOrderNumber
      }
    ) {
      preAuthRequestId
      preAuthRequestIdOriginal
      approvedAmount
      authorizationCode
      preAuthNotes
      isDeleted
      claimId
      servicerId
      tenantId
      addedTimeStamp
      userName
      serviceOrderNumber
    }
  }
`;

@Injectable()
export class GeneratePreAuthService {
  constructor(private apollo: Apollo) {}

  getLimitDetails = queryParams =>
    this.apollo.use('crm').query<any>({
      query: limitQuery,
      variables: { servicerNumber: queryParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getPreAuthDetails = queryParams =>
    this.apollo.use('crm').query<any>({
      query: getPreAuthQuery,
      variables: { serviceOrderNumber: queryParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createUpdatePreAuthDetails = queryParams =>
    this.apollo.use('crm').mutate({
      mutation: createUpdatePreAuthQuery,
      variables: {
        ...queryParams,
        claimNumber: queryParams.claimNumber,
        insertUserName: queryParams.insertUserName,
        approvedAmount: queryParams.approvedAmount,
        serviceId: queryParams.serviceId,
        preAuthNotes: queryParams.preAuthNotes,
        serviceOrderNumber: queryParams.serviceOrderNumber
      }
      // tslint:disable-next-line: semicolon
    });
}
