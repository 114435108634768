import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import gql from 'graphql-tag';

const deleteCrmModule = gql`
  mutation($idOriginal: Int!, $moduleName: ModuleType!, $azureUserId: String) {
    deleteCrmModule(idOriginal: $idOriginal, moduleName: $moduleName, azureUserId: $azureUserId)
  }
`;

@Injectable()
export class CrmService {
  constructor(private apollo: Apollo) {}

  deleteCrmModule = serviceArea =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: deleteCrmModule,
      variables: {
        ...serviceArea,
        servicersServiceAreasIdOriginal: parseInt(serviceArea.servicersServiceAreasIdOriginal, 10),
        servicersId: parseInt(serviceArea.servicersId, 10),
        radius: parseInt(serviceArea.radius, 10)
      }
      // tslint:disable-next-line: semicolon
    });
}
