<div class="refund-information-tab">
  <app-section-header [title]="'Credit Card Info'" />
  <div class="sub-section">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid col-12">
        <div class="form-row" *ngFor="let formField of formFields; let i = index;" [ngClass]="{'col-6': true}">
          <form-field *ngIf="!formField.isChangeEvent" [formField]="formField" [form]="form">
          </form-field>
          <form-field *ngIf="formField.isChangeEvent" [formField]="formField" [form]="form"
            (focusout)="changeEventDetection($event)">
          </form-field>
        </div>
      </div>
    </form>
  </div>
</div>