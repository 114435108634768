import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CancellationMethodService {
  constructor() {}
  getCancellationMethods(): Observable<any[]> {
    return of([
      {
        id: 1,
        name: 'Pro-Rata',
        code: 'Pro-Rata',
        description: 'Pro-Rated Plan Cancellation for plans older than 30 days',
        active: true
      },
      {
        id: 2,
        name: 'Override',
        code: 'Override',
        description: 'Override Code Fix Cancellation Method',
        active: true
      },
      {
        id: 3,
        name: 'Extension Plan Pro-Rata',
        code: 'Ext Pro-Rata',
        description: 'Extension Plan Pro-Rata',
        active: true
      }
    ]);
  }
}
