import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { FormField } from 'src/app/modules/crm/shared/form-field/form-field';
import { Apollo } from 'apollo-angular';
import { DropdownField } from 'src/app/modules/crm/shared/form-field/dropdown-field';
import { CrmGraphql } from 'src/app/modules/crm/shared/constants/graphql-constants';
import { createupdateAlertDataQuery, ratingsSearchQuery } from './rating-data-queries.constants';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  constructor(private apollo: Apollo) {}

  getRatingFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'primaryRatingProvider',
        label: 'Primary Rating Provider',
        options: [{ key: 'Internal', value: 'Internal' }],
        isSingleselect: true,
        order: 1,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getRatingsSearch = (searchParams) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: ratingsSearchQuery,
      variables: { coverageCode: searchParams },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
  createUpdateCoverageRates = (params) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createupdateAlertDataQuery,
      variables: { coverageRates: params },
      // tslint:disable-next-line: semicolon
    });
}
