<div class="name-information">
  <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-row col-12">
      <div class="title">
        <div>
          If you didn't provide an email address when you purchase your Protection Plan, you can
          access your account by creating an User Name below:
        </div>
      </div>
    </div>
    <div class="form-row col-12">
      <div class="title">
        <div>Enter your name exactly as it is on the Protection Plan Purchase Recepit</div>
      </div>
    </div>
    <div class="form-row col-4" *ngFor="let formField of formFields">
      <form-field [formField]="formField" [form]="form"></form-field>
    </div>
  </form>
</div>
