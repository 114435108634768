import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-spinner',
  templateUrl: './tab-spinner.component.html',
  styleUrls: ['./tab-spinner.component.scss'],
})
export class TabSpinnerComponent {
  @Input() spinnerType = 'regular';
}
