import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ModalModule } from 'src/app/components/presentation/modal/modal.module';
import { KairosNavBarComponent } from '../../components/presentation/kairos-nav-bar/kairos-nav-bar.component';
import { KairosComponent } from './kairos.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, ModalModule, RouterModule, InlineSVGModule.forRoot()],
  exports: [KairosComponent, KairosNavBarComponent],
  declarations: [KairosComponent, KairosNavBarComponent],
})
export class KairosModule {}
