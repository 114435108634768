import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CancellationMethodService } from './cancellation-method.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormField } from '../../shared/form-field/form-field';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { CoverageFormFieldService } from '../coverage-form-field.service';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { copy } from '../../shared/utilities/common-utilities';

@Component({
  selector: 'cv-cancellation-method',
  templateUrl: 'cancellation-method.template.html',
  providers: [CancellationMethodService, CoverageFormFieldService, FormFieldControlService],
})
export class CancellationMethodComponent extends FormCanDeactivate implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  displayDialog: boolean;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private cancellationMethodService: CancellationMethodService,
    private confirmationService: ConfirmationService,
    private service: CoverageFormFieldService,
    private qcs: FormFieldControlService,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cancellationMethodService.getCancellationMethods().subscribe((data) => {
      this.dataList = data;
    });
    this.service.getCancellationMethodFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.form = this.qcs.toFormGroup(this.formFields);
      this.form.valueChanges.subscribe((x) => {
        this.formValueChanged.emit(true);
      });
    });
    this.cols = [
      { field: 'name', header: 'NAME', type: 'text' },
      { field: 'code', header: 'CODE', type: 'text' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'active', header: 'IS ACTIVE', type: 'checkbox' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  addNewRowItem() {
    const rowItem = this.form.getRawValue();
    rowItem.id = this.dataList.length + 1;
    rowItem.lossCodeType = rowItem.lossCodeType ? rowItem.lossCodeType.key : '';
    this.dataList.push(rowItem);
    this.displayDialog = false;
    this.formValueChanged.emit(this.form.dirty);
  }

  onClickAdd(event) {
    this.displayDialog = true;
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }
}
