import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const payeeDetailsFormQuery = gql`
  query($id: Int!) {
    getPayeeProfileById(id: $id) {
      id
      status
      number
      name
      fedTaxId
      adminBucket
      reserveAndFees
      refundPayMethod
      commission
      addresses(categoryType: ClaimPayee) {
        id
        address1
        state
        city
        zipCode
      }
      contactInfo(categoryType: ClaimPayee) {
        id
        categoryType
        firstName
        lastName
        phoneNumber1
        email
        fax
      }
    }
  }
`;
@Injectable()
export class PayeeService {
  public isLoading = false;
  public payeeMailingAddress: any;
  public payeeBillingAddress: any;
  public payeeFormData: any;
  public payeeContactInfo: any;
  public payeeConfig = {
    searchParams: null
  };
  constructor(private apollo: Apollo) {}
  getPayeeDetailsForm = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: payeeDetailsFormQuery,
      variables: { id: searchParams }
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
