import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { DealerFormFieldService } from '../../../services/dealer-form-field.service';
import { FormField } from '../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { PayeeService } from '../payee.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { dealerLocationFormHeaderOptions } from '../../dealer-location/dealer-location-form/dealer-location-form.ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'payee-main-form',
  templateUrl: 'payee-main-form.template.html',
  styleUrls: ['../../../shared/styles/left-details-form.scss'],
  providers: [FormFieldControlService],
})
export class PayeeMainFormComponent extends FormCanDeactivate implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = dealerLocationFormHeaderOptions;

  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: DealerFormFieldService,
    private payeeDataService: PayeeService,
  ) {
    super();
    this.service.getPayeeMainFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: PayeeMainFormComponent,
      generalArray: this.formFields,
    };
    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: !this.form.valid, type: 'submit' },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: PayeeMainFormComponent,
      generalArray: this.button,
    };
    this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    const formData = this.payeeDataService.payeeFormData;
    if (formData) {
      this.form.patchValue({
        ...formData,
        status: { key: formData.status, value: formData.status },
        refundPayMethod: { key: formData.refundPayMethod, value: formData.refundPayMethod },
        adminBucket: formData.adminBucket ? ['Admin'] : null,
        reserveAndFees: formData.reserveAndFees ? ['Reserves Fees'] : null,
        commission: formData.commission ? ['Commission'] : null,
      });
    }
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  onSubmit() {
    this.payLoad = this.form.getRawValue();
    this.formValueChanged.emit(this.form.dirty);
  }
}
