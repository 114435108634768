<div class="crm-container">
  <app-section-header [title]="'Technical Information'" />
  <div class="sub-section">
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="col.width ? [ { width:col.width } ] : []">
            {{col.header}}
          </th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }" [style.width]="'10%'"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template
                *ngIf="(col.type === 'text' || col.type === 'select' || col.type==='Date' || col.type === 'fixed')"
                pTemplate="output">
                {{ rowData[col.field]}}
              </ng-template>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text' && permissionsCols[col.field]" type="text"
                  [(ngModel)]="rowData[col.field]" />
                <input class="input-text" *ngIf="col.type === 'text' && !permissionsCols[col.field]" type="text"
                  [(ngModel)]="rowData[col.field]" disabled="disabled" />
                <input class="resize-input" *ngIf="col.type === 'Date' && !permissionsCols[col.field]" type="Date"
                  [(ngModel)]="rowData[col.field]" disabled="disabled" />
                <input class="resize-input"
                  *ngIf="col.type === 'Date' && permissionsCols[col.field] && col.field==='fPBRedemptionDate'"
                  type="Date" [(ngModel)]="rowData[col.field]" />
                <input class="resize-input"
                  *ngIf="col.type === 'Date' && permissionsCols[col.field] && col.field==='serviceScheduleDate'"
                  type="Date" [(ngModel)]="rowData[col.field]" />
                <input class="resize-input"
                  *ngIf="col.type === 'Date' && permissionsCols[col.field] && col.field ==='dateOfInvoice'" type="Date"
                  [(ngModel)]="rowData[col.field]" pInputText="pInputText" [disabled]="dateOfInvoiceFlag" />
                <input class="resize-input"
                  *ngIf="col.type === 'Date' && permissionsCols[col.field] && col.field==='dateOfRepair'" type="Date"
                  [(ngModel)]="rowData[col.field]" pInputText="pInputText" [disabled]="dateOfRepairFlag" />
                <p-dropdown *ngIf="col.type === 'select' && permissionsCols[col.field]" [options]="dropdown[col.field]"
                  [(ngModel)]="rowData[col.field]" appendTo="body">
                </p-dropdown>
                <p-dropdown *ngIf="col.type === 'select' && !permissionsCols[col.field]" [options]="dropdown[col.field]"
                  [(ngModel)]="rowData[col.field]" appendTo="body">
                </p-dropdown>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,opRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" [style.margin-right]="'0.5em'"
              (click)="onRowEditSave(rowData, ri)"></button><button class="ui-button-danger" *ngIf="editing"
              pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>