import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { FormField } from '../../../shared/form-field/form-field';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import { EmailTemplateService } from '../email-temp.service';

@Component({
  selector: 'email-temp-form',
  templateUrl: 'form.template.html',
  styleUrls: ['form.scss'],
  providers: [FormFieldControlService],
})
export class EmailTempFormComponent implements OnInit, OnChanges {
  @Input() formFields: FormField<string>[] = [];
  @Input() selectedItem: any;
  form: FormGroup;
  constructor(
    private qcs: FormFieldControlService,
    private emailService: EmailTemplateService,
  ) {}
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }
  ngOnChanges() {
    const newFormFields: FormField<string>[] = [];
    if (this.selectedItem) {
      for (const singleFormField of this.formFields) {
        if (singleFormField.key === 'code') {
          singleFormField.value = this.selectedItem.message_code;
        }
        if (singleFormField.key === 'description') {
          singleFormField.value = this.selectedItem.description;
        }
        if (singleFormField.key === 'type') {
          singleFormField.value = this.selectedItem.type;
        }
        newFormFields.push(singleFormField);
        this.form = this.qcs.toFormGroup(newFormFields);
      }
    }
  }
}
