<div class="crm-container">
  <app-section-header [title]="'Settings'" [optionHeader]="opHeader" />
  <app-tab-spinner *ngIf="isLoading" />
  <div class="sub-section" [hidden]="isLoading">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>