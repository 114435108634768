export const RequiredColummns = {
  ColumnNames: [
    'Dealer Number',
    'Dealer Number Location',
    'Dealer Name',
    'PLAN Unique Identifier',
    'Transaction Reason',
    'First Name',
    'Last Name',
    'Address',
    'City',
    'ST',
    'ZIP',
    'Phone',
    'SKU',
    'Term Months',
    'Plan DOP',
    'Plan Purchase Price',
    'Product Type (CODE)',
    'Manufacturer',
    'Model Number',
    'N  U  R  Other',
    'New Leaf Selling Retailer Code',
    'Selling Retailer Name',
    'Dealer Cost(Reported)',
    'Country',
    'Contract Status',
    'Transaction Date'
  ]
};
