import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ExceptionsService {
  constructor() {}
  getExceptions(): Observable<any[]> {
    return of([
      {
        id: 1,
        included: true,
        name: '!Service Provider Independent'
      },
      {
        id: 2,
        included: true,
        name: 'Account Manager'
      },
      {
        id: 3,
        included: false,
        name: 'Accounting'
      }
    ]);
  }
}
