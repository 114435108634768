<div class="crm-panel">
    <app-tab-spinner *ngIf="isLoading" />
    <div class="crm-top" [hidden]="isLoading">
        <div class="crm-panel__left"><cv-insurance-company-profile
                (formValueChanged)="setChangeData($event)"></cv-insurance-company-profile></div>
        <div class="crm-panel__main"><navigation-panel [navigationPanel]="navigationPanel"></navigation-panel></div>
    </div>
    <div class="crm-bottom" *ngIf="componentDisplay">
        <crm-notes [parentComponent]="component" style="width: 100%;"></crm-notes>
    </div>
</div>