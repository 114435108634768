import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SearchContractService } from './search-contract.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from './search-contract.ellipsis';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';

import { Subscription } from 'rxjs';
import { copy } from '../../shared/utilities/common-utilities';
import { generalDateFormatter } from '../../shared/utilities/date-utilities';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfigService } from '../../services/api-config.service';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'search-contract',
  templateUrl: 'search-contract.template.html',
  styleUrls: ['search-contract.component.scss', '../../shared/styles/crm-table.scss'],
  providers: [SearchContractService],
})
export class SearchContractComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  status: any[];
  newTab: Tab;
  op: OverlayPanel;
  querySubscriptions: Subscription[] = [];
  isLoading: boolean;
  authDetails: IRoleAuthorizationService;
  lastOffsetVal = 0;
  offsetLimit = 10;
  disableLoadMore = false;
  resultCount = 0;
  selectedColumns: any[];
  // URL ACCESS
  isDirectOpenInfo = null;
  urlSubscriptions: Subscription;
  baseSearchParams = null;

  constructor(
    private searchContractService: SearchContractService,
    private tabService: TabsService,
    private roleService: RoleAuthorizationService,
    private azureService: AzureLoginService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiConfigService: ApiConfigService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.urlSubscriptions = this.activatedRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.isDirectOpenInfo = {};
        const request = {};
        Object.keys(params).map(key=> {
          request[key.trim()]= params[key].trim();
        })
        this.isDirectOpenInfo = request;
      }
      this.router.navigate([], { queryParams: null });
    });
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SearchContractComponent,
      generalArray: this.ellipsisOptions,
    };
    this.ellipsisOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.editConstant = uuidv4();
    this.status = [
      { label: 'Active', value: 'Active' },
      { label: 'Terminated', value: 'Terminated' },
    ];
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'contractNumber', header: 'CONTRACT#', type: 'text' },
      { field: 'uniqueId', header: 'UNIQUE ID', type: 'text' },
      { field: 'firstName', header: 'FIRSTNAME', type: 'text' },
      { field: 'lastName', header: 'LASTNAME', type: 'text' },
      { field: 'phoneNumber', header: 'PRIMARY PHONE', type: 'text' },
      { field: 'modelNumber', header: 'MODEL #', type: 'text' },
      { field: 'serialNumber', header: 'SERIAL #', type: 'text' },
      { field: 'subcategoryDescription', header: 'SUBCAT DESC', type: 'text' },
      { field: 'manufacturer', header: 'MFG', type: 'text' },
      { field: 'externalId', header: 'SALES RECEIPT/REF #', type: 'text' },
      { field: 'address1', header: 'ADDRESS', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'guardian', header: 'GUARDIAN', type: 'text' },
      { field: 'transactionDate', header: 'NL TRANSACTION DATE', type: 'Date' },
      { field: 'effectiveDate', header: 'PLAN EFFECTIVE DATE', type: 'Date' },
      { field: 'entryDate', header: 'ENTRY DATE', type: 'Date' },
      { field: 'dealerGroupName', header: 'DEALER', type: 'text' },
      { field: 'productPurchaseDate', header: 'PRODUCT PURCHASE DATE', type: 'Date' },
    ];
    this.selectedColumns = this.cols.slice(0, 9);
    if (this.isDirectOpenInfo) {
      this.searchParams = Object.assign(
        this.searchParams ? this.searchParams : {},
        this.isDirectOpenInfo,
      );
      this.searchParams.isOr = true;
      this.offsetLimit = 2000; // Need more rows for Contract Number Priority filtering.
    }
    this.getContractSearchData();
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Contract',
      category: 'Contract',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Contract',
      category: 'Contract',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    setTimeout(() => {
      event.preventDefault();
      this.selectedItem = item;
      overlaypanel.toggle(event);
    }, 500);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.tabService.createTab({
      header: 'Contract',
      content: this.selectedItem,
      type: 'Contract',
    });
  }

  openTab(data: any) {
    this.newTab = {
      header: `Contract [ ${data.contractNumber} ]`,
      content: data,
      type: 'Contract',
    };
    this.tabService.createTab(this.newTab);
  }

  getContractSearchData() {
    this.isLoading = true;
    let phoneNumber = null;
    if (this.searchParams && typeof this.searchParams !== 'string') {
      if (this.searchParams.phoneNumber) {
        phoneNumber = this.searchParams.phoneNumber
          ? this.searchParams.phoneNumber.replace(/[^0-9]/g, '')
          : this.searchParams.phoneNumber;
      }
    } else {
      this.searchParams = { status: { key: 'ACTIVE', value: 'ACTIVE' } };
    }
    this.baseSearchParams = Object.assign({}, this.searchParams);
    const finalSerachParams = {};
    Object.keys(this.searchParams).map(key=>{
      try{
        const fkey = key.trim();
        if(typeof(this.searchParams[key]) === 'object'){
          finalSerachParams[fkey]=this.searchParams[key];
        }
        else if(this.searchParams[key].trim() !== "N/A" && this.searchParams[key].trim() !== ''){
          finalSerachParams[fkey]=this.searchParams[key].trim();
        }
      }catch(e:any){
        console.log(e.message, key)
      }
    })
    const searchQuery = Object.assign(
      {},
      finalSerachParams,
      {
        limit: this.offsetLimit,
        offset: this.lastOffsetVal,
        azureUserId: this.azureService.accountId,
        phoneNumber
      },
    );

    this.lastOffsetVal += this.offsetLimit;
    this.querySubscriptions.push(
      this.searchContractService.getContractSearchResult(searchQuery).subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifedData = copy(data.getContractSearchResults);
          this.displayData(modifedData);
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      ),
    );
  }

  displayData(modifedData) {
    let primaryKeyUsed = false;
    if (modifedData.length) {
      if (this.isDirectOpenInfo && Object.keys(this.isDirectOpenInfo).length > 0) {
        const contractNumberData = this.searchParams.contractNumber
          ? modifedData.filter(
              (el) =>
                el.contractNumber === this.searchParams.contractNumber ||
                el.contractNumber === 'NL' + this.searchParams.contractNumber,
            )
          : [];
        // If Contract Number was matched, then use those rows instead of all for priority.
        if (contractNumberData.length > 0) {
          modifedData = contractNumberData;
          primaryKeyUsed = true;
        }
      }
      if (
        this.isDirectOpenInfo &&
        (modifedData.length === 1 ||
          (this.searchParams.contractNumber &&
            modifedData.filter((el) => el.contractNumber !== this.searchParams.contractNumber)
              .length === 0))
      ) {
        if (this.searchParams.isOr) {
          this.insertQueryParameter([modifedData[0]], primaryKeyUsed);
        }
        this.isDirectOpenInfo = null;
        this.searchParams = null;
        this.urlSubscriptions.unsubscribe();
        this.tabService.createTab({
          header: 'Contract',
          content: modifedData[0],
          type: 'Contract',
        });
      } else {
        this.disableLoadMore =
          Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
        const formattedData = this.setDateFormat(modifedData);
        this.dataList = !!this.dataList ? [...this.dataList, ...formattedData] : [...formattedData];
        this.resultCount = this.dataList.length;
        if (this.searchParams.isOr) {
          this.insertQueryParameter(modifedData, primaryKeyUsed);
        }
      }
    } else {
      if (this.searchParams.isOr) {
        this.insertQueryParameter([], primaryKeyUsed);
      }
    }
  }

  setDateFormat(data: any[]) {
    const dataList = data.map((d) => {
      d.transactionDate = generalDateFormatter(d.transactionDate);
      d.effectiveDate = generalDateFormatter(d.effectiveDate);
      d.entryDate = generalDateFormatter(d.entryDate);
      d.productPurchaseDate = generalDateFormatter(d.productPurchaseDate);
      return { ...d };
    });
    return dataList;
  }

  insertQueryParameter(rows, primaryKeyUsed = false) {
    const queryParameterResultsConfig = this.apiConfigService.getApiConfig('QueryParameterResults');
    let queryParameterResultsConfigValues = [];
    if (queryParameterResultsConfig) {
      queryParameterResultsConfigValues = Object.values(queryParameterResultsConfig);
    }
    const contractSearchValues = queryParameterResultsConfigValues.filter(
      (el) => el.configType === 'ContractSearch',
    );
    let insertTableValues = [];
    if (contractSearchValues && contractSearchValues.length > 0) {
      insertTableValues = contractSearchValues.map((x) => ({
        key: x.configKey,
        value: x.configValue,
      }));
    }
    const insertTableActive =
      insertTableValues && insertTableValues.length > 0 && insertTableValues[0].value === 'yes'
        ? true
        : false;
    delete this.baseSearchParams.limit;
    delete this.baseSearchParams.offset;
    delete this.baseSearchParams.azureUserId;
    delete this.baseSearchParams.isOr;
    if (insertTableActive) {
      this.querySubscriptions.push(
        this.searchContractService
          .addQueryParameterResults({
            moduleName: 'contracts',
            resultsCount: rows.length ? rows.length : 0,
            allParameters: JSON.stringify(this.baseSearchParams),
            insertUserName: this.azureService.accountId,
            parameter1: this.searchParams.phoneNumber,
            parameter2: this.searchParams.contractNumber,
            tenantId: 1,
            primaryKeyUsed,
          })
          .subscribe(
            ({ data, loading }: any) => {},
            (err) => {
              console.log('Failed addQueryParameterResults');
            },
          ),
      );
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
