// CLEAR
import _pickBy from 'lodash/pickBy';
import _find from 'lodash/find';
import _isObject from 'lodash/isObject';
import _includes from 'lodash/includes';
import * as jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { htmlToText } from 'html-to-text';

/**
 * This will filter the rows based on column filters
 * * Example: if columns list has multiple field
 * "{ field: 'type', header: 'Type', width: '11%', datatype: 'string' }"
 * and has to be filtered based on field property then pass 'field' as columnKey
 * @rows      : list of rows that needs to be filtered
 * @columns   : list of columns
 * @columnKey : key in the columns list to filter rows. Default is 'field'
 */
export function filterTableData(rows, columns, columnKey = 'field') {
  const filterResult: any[] = [];
  const filteredColumns: any[] = [];
  columns.forEach((cols) => {
    filteredColumns.push(cols[columnKey]);
  });
  rows.forEach((element) => {
    const tempFilter = _pickBy(element, (value, key) => {
      return _includes(filteredColumns, key);
    });
    filterResult.push(tempFilter);
  });

  return filterResult;
}

/**
 * This will create an excell file and will download it in the browser
 * @data      : list of rows that needs to be filtered
 * @filename   : name of the exported file. Default 'crm-file'
 */
export function exportExcel(data, fileName = 'crm-file') {
  import('xlsx').then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, fileName);
  });
}

/**
 * This will generate a PDF document and will download it in the browser
 * @columns : column list for table to be generated
 * @rows    : table content (row data)
 * @filename   : name of the exported file. Default 'crm-file'
 */
export function exportPdf(columns, rows, fileName = 'crm-file', additionalTableOptions = {}) {
  const doc = createJSPDFDoc(columns, rows, additionalTableOptions);
  doc.save(fileName + '_' + new Date().getTime() + '.pdf');
}

/**
 * This will generate a JSPDF document and will open it for print in a new window
 * @columns : column list for table to be generated
 * @rows    : table content (row data)
 */
export function printPDF(columns, rows, additionalTableOptions = {}) {
  const doc = createJSPDFDoc(columns, rows, additionalTableOptions);
  doc.autoPrint();
  doc.output('dataurlnewwindow');
}

/**
 * Private functions
 */

function saveAsExcelFile(buffer: any, fileName: string): void {
  import('file-saver').then((FileSaver) => {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  });
}

function createJSPDFDoc(columns, rows, additionalTableOptions = {}) {
  const filteredColumns: any[] = [];
  columns.forEach((cols) => {
    filteredColumns.push({ header: cols[`header`], dataKey: cols[`field`] });
  });
  const doc = new jsPDF.default('p', 'em');
  const rowData: any[] = [];
  rowData.push(rows[0]);
  let tableOptions = { columns: filteredColumns, body: rows };
  tableOptions = { ...tableOptions, ...additionalTableOptions };
  autoTable(doc, tableOptions);
  return doc;
}

export function parseHTMLToString(filteredData) {
  const field = 'noteText';
  const parsedString = filteredData.map((el) => {
    if (el.hasOwnProperty(field)) {
      el[field] = htmlToText(String(el[field]).replace(/&nbsp;/g, ' '), { wordwrap: 130 });
    }
    return el;
  });

  return parsedString;
}
