export class ServiceClaimPayment {
  id: any;
  fileStatus: any;
  uploadDate: any;
  remittanceDate: string;
  claimCount: any;
  totalPaid: any;

  constructor() {
    (this.id = ''), (this.fileStatus = ' ');
    this.uploadDate = ' ';
    this.remittanceDate = ' ';
    this.claimCount = ' ';
    this.totalPaid = ' ';
  }
}
