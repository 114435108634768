<app-tab-spinner *ngIf="isLoading" />
<div class="crm-panel" *ngIf="!isLoading">
  <div class="crm-top">
    <div class="crm-panel__left">
      <dealer-profile-form (formValueChanged)="setChangeData($event)"
        [searchParams]="searchParams"></dealer-profile-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <ng-container *ngIf="componentDisplay && dealerId">
    <crm-notes
      *appSetRolePermissionsDirective="{type:'component', operation:'READ_ONLY', permission:'Notes', category: 'Seller'}"
      [parentCategory]="'Seller'" [category]="addressCategoryType.DealersGroup" [identifierId]="dealerId"
      [parentComponent]="component"></crm-notes>
  </ng-container>
</div>