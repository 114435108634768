import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SearchCoverageService } from './search-coverage.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';
import { Subscription } from 'rxjs';
import { getMMDDYYYFormat } from '../../shared/utilities/date-utilities';
import { copy } from '../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'search-coverage',
  templateUrl: 'search-coverage.template.html',
  styleUrls: ['../../shared/styles/crm-table.scss'],
  providers: [SearchCoverageService],
})
export class SearchCoverageComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  status: any[];
  newTab: Tab;
  op: OverlayPanel;
  querySubscription: Subscription;
  isLoading: boolean;
  resultCount = 0;
  selectedColumns: any[];
  lastOffsetVal = 0;
  offsetLimit = 10;
  disableLoadMore = false;
  constructor(
    private searchCoverageService: SearchCoverageService,
    private tabService: TabsService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'code', header: 'CODE', type: 'text' },
      { field: 'activeFrom', header: 'ACTIVE FROM', type: 'text' },
      { field: 'activeTo', header: 'ACTIVE TO', type: 'text' },
      { field: 'skuCode', header: 'SKU', type: 'text' },
      { field: 'priceTable', header: 'PRICE TABLE NAME', type: 'text' },
    ];
    this.selectedColumns = this.cols.slice(0, 6);
    this.getCoverageSearchData();

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Coverage',
      category: 'Coverage',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Coverage',
      category: 'Coverage',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.tabService.createTab({
      header: 'Coverage',
      content: this.selectedItem.coveragePlansIdOriginal,
      type: 'Coverage',
    });
  }

  openTab(data: any) {
    this.newTab = {
      header: 'Coverage',
      content: data.coveragePlansIdOriginal,
      type: 'Coverage',
    };
    this.tabService.createTab(this.newTab);
  }

  getCoverageSearchData() {
    this.isLoading = true;
    const tmpParams: object = {
      ...this.searchParams,
      limit: this.offsetLimit,
      offset: this.lastOffsetVal,
    };

    this.lastOffsetVal += this.offsetLimit;

    this.querySubscription = this.searchCoverageService
      .getCoverageSearchResults(tmpParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifedData = data.getCoverageSearchResults.map((item) => {
            return {
              ...item,
              activeFrom: item.activeFrom
                ? getMMDDYYYFormat(item.activeFrom, 'MM/DD/YYYY')
                : item.activeFrom,
              activeTo: item.activeTo
                ? getMMDDYYYFormat(item.activeTo, 'MM/DD/YYYY')
                : item.activeTo,
            };
          });
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          if (this.dataList) {
            [...modifedData].forEach((tmp) => {
              this.dataList.push(tmp);
            });
          } else {
            this.dataList = [...modifedData];
          }

          this.resultCount = this.dataList.length;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
