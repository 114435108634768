import { Component, OnInit, OnDestroy } from '@angular/core';
import { CrmDocumentType } from '../../../shared/constants/enums';
import { ServiceOrderDataService } from '../service-order-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ct-file-upload',
  templateUrl: 'service-order-upload-tab.template.html',
})
export class ServiceOrderUploadComponent implements OnInit, OnDestroy {
  documentType = CrmDocumentType;
  attachmentId: number;
  querySubscriptions: Subscription[] = [];
  constructor(private serviceOrderDataService: ServiceOrderDataService) {}

  ngOnInit() {
    this.attachmentId = this.serviceOrderDataService.serviceConfig.searchParams.claimId;
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          if (
            isClaimDataAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal
          ) {
            this.attachmentId =
              this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
          }
        },
      }),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
