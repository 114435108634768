import { Component, OnInit } from '@angular/core';
import { TabHandlerService } from '../../services/tab-handler.service';
import { CrmDocumentType } from '../../shared/constants/enums';
import { ViewFile } from '../../shared/view-file/view-file.interface';
// import { DealerLocationService } from '../dealer-location.service';
import { DealerService } from '../dealer.service';
import { documentHeaderOptions } from './document-tab.ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormField } from '../../shared/form-field/form-field';

@Component({
  selector: 'documents-tab',
  templateUrl: 'documents-tab.template.html',
  styleUrls: ['documents-tab.scss'],
})
export class DealerDocumentsTabComponent implements OnInit {
  documentSearchParams: ViewFile;
  tabKey: string;
  displayAdd: boolean;
  displayVerify: boolean;
  header: string;
  ellipsisHeaderOptions: any[] = documentHeaderOptions;
  formModalInfo: FormField<any>[] = [];
  selectedItem: any;
  constructor(
    private dealerService: DealerService,
    private tabHandlerService: TabHandlerService,
  ) {
    this.displayAdd = false;
    this.displayVerify = false;
    this.documentSearchParams = {
      fileName: '',
      attachmentsType: { key: 'DealersGroup', value: CrmDocumentType.DealersGroup },
      uploadedDate: null,
      offset: 0,
      limit: 20,
      identifierId: null,
    };
  }

  ngOnInit() {
    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab && this.dealerService.dealerFormData) {
          this.documentSearchParams.identifierId = this.dealerService.dealerGroupsIdOriginal;
        }
      },
    });
    // this.documentsTabService
    //   .getDocumentFormModalFields()
    //   .subscribe(formModalInfo => (this.formModalInfo = formModalInfo));
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  handleOpen(e) {
    if (e.value === 'Add New Document') {
      this.displayAdd = true;
      this.header = 'Add Document';
    } else {
      this.displayVerify = true;
    }
    // this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayVerify = false;
    // this.cdr.detectChanges();
  }
}
