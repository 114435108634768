<div class="crm-container">
  <app-section-header [title]="'Failure Type Results ( ' + searchResults.length + ' )'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner><p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="searchResults" [scrollable]="true"
      [hidden]="isLoading" dataKey="id" editMode="row" [rows]="10" scrollHeight="600px">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData="" let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text" [(ngModel)]="rowData[col.field]" />
                <p-dropdown *ngIf="col.type === 'select'" emptyMessage="Select" [options]="dropdown[col.field]"
                  [filter]="true" [(ngModel)]="rowData[col.field]">
                </p-dropdown>
              </ng-template>
              <ng-template *ngIf="col.type === 'text' || col.type === 'select'" pTemplate="output">
                {{rowData[col.field]}} </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="selectItem($event,rowData,opRows)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              id="{{editConstant + rowData.id}}cancelBtn" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button class="btn-crm-success" type="button" [attr.disabled]="disableLoadMore?true:null"
      (click)="getFailureTypeData()" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #opRows>
  <p-listbox [options]="ellipsisOptions" [(ngModel)]="selectedEllipsisItem" (onClick)="handleOpen($event)"> </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions" [(ngModel)]="selectedEllipsisItem" (onClick)="handleOpen($event)">
  </p-listbox>
</p-overlayPanel>