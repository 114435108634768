<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content" *ngIf="isLoading">
    <div class="spinner"><p-progressSpinner></p-progressSpinner></div>
  </div>
  <div class="modal-content" *ngIf="!isLoading">
    <service-power-table [displayDialog]="displayDialog"></service-power-table>
    <!-- [(displayDialog)] -->
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" *ngIf="!isReschedule" type="submit" (click)="onSubmit()" label="Schedule">
        Schedule Job
      </button>
      <button class="crm-button" *ngIf="isReschedule" type="submit" (click)="onReschedule()" label="Reschedule">
        Reschedule Job
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>