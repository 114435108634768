import { FormField } from './form-field';

export class CheckBoxField extends FormField<string> {
  override controlType = 'checkbox';
  override type: string;
  override options: { key: string; value: string }[] = [];

  constructor(options: any) {
    super(options);
    this.type = options[`type`] || '';
    this.options = options[`options`] || [];
  }
}
