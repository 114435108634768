<app-tab-spinner *ngIf="isLoading" />
<div class="crm-container" [hidden]="isLoading">
  <app-section-header [title]="'SO Summary'" />
  <summary-section *ngIf="isMainTabDataAvailable && isClaimDataAvailable" [title]="'Dealer Information'"
    [data]="dealerInfo">
  </summary-section>
  <summary-section *ngIf="isMainTabDataAvailable && isClaimDataAvailable" [title]="'Customer Information'"
    [data]="customerInfo" [formFields]="contactInfoFormFields"
    [(formData)]="serviceOrderDataService.serviceOrderDataModel.contact"
    (formDataChange)="formDataChanged($event, 'contact')"></summary-section><summary-section
    *ngIf="isMainTabDataAvailable && isClaimDataAvailable" [title]="'Product Location'" [formFields]="productLocation"
    [(formData)]="serviceOrderDataService.serviceOrderDataModel.location"
    (formDataChange)="formDataChanged($event, 'location')">
  </summary-section>
  <summary-section *ngIf="isMainTabDataAvailable && isClaimDataAvailable" [title]="'Product Information'"
    [data]="productInfo" [formFields]="productInfoFormFields"
    [(formData)]="serviceOrderDataService.serviceOrderDataModel.productInfo">
  </summary-section>
  <coverage-details> </coverage-details>
  <problem></problem>
</div>