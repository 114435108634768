import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisOptions } from '../../shared/constants/ellipsis-options';

@Component({
  selector: 'resolution',
  templateUrl: 'resolution.template.html',
  styleUrls: ['resolution.scss'],
})
export class ResolutionComponent implements OnInit {
  selectedItem: any;
  btnStatus: any = buttonStatus;
  options: any[] = ellipsisOptions;
  selectedEllipsisItem: any;

  ngOnInit() {
    this.options = [
      { label: 'Add', value: 'add' },
      { label: 'Update', value: 'update' },
    ];
  }
  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  handleClose(event) {
    // TODO: Close event
  }
  handleChange(event) {
    // TODO: Change event
  }
}
