import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
const getServiceCenterLookupQuery = gql`
  query (
    $type: String
    $categoryDescription: String
    $tierDescription: String
    $subcategoryDescription: String
    $contract: Boolean
    $manufacturerName: String
    $zipCode: String
    $zipLat: Float
    $zipLong: Float
    $city: String
    $state: String
    $radius: String
    $offset: Int
    $limit: Int
  ) {
    getServiceCenterLookup(
      type: $type
      categoryDescription: $categoryDescription
      tierDescription: $tierDescription
      subcategoryDescription: $subcategoryDescription
      contract: $contract
      manufacturerName: $manufacturerName
      zipCode: $zipCode
      zipLat: $zipLat
      zipLong: $zipLong
      city: $city
      state: $state
      radius: $radius
      offset: $offset
      limit: $limit
    ) {
      servicerProfilesIdOriginal
      servicerName
      phoneNumber
      emailAddress
      address1
      city
      state
      zipcode
      selfServicing
      mfgAuthorized
      distance
      sealedSystems
      customerSatisfaction
      rating
      contracted
      validInsurance
      nationalServiceProvider
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ServiceCenterLookupService {
  constructor(private apollo: Apollo) {}

  getServiceCenterLookup = (data) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getServiceCenterLookupQuery,
      variables: {
        ...data,
        type:
          data.service_type !== null && data.service_type.value ? data.service_type.value : null,
        categoryDescription:
          data.category && data.category.length > 0
            ? data.category.map((e) => e.key).join(',')
            : null,
        tierDescription:
          data.tier && data.tier.length > 0 ? data.tier.map((e) => e.key).join(',') : null,
        subcategoryDescription:
          data.subcategory && data.subcategory.length > 0
            ? data.subcategory.map((e) => e.key).join(',')
            : null,
        contract: data.contract,
        manufacturerName:
          data.manufacturer && data.manufacturer.length > 0
            ? data.manufacturer.map((e) => e.key).join(',')
            : null,
        zipCode: data.zip ? data.zip : null,
        city: data.city ? data.city : null,
        state: data.state ? data.state.value : '',
        radius: data.radius ? data.radius : null,
      },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
