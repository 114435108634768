import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SetupAlertModalService } from './setup-alert-modal.service';
import {
  generalDateFormatterWithoutTZ,
  generalDateFormatter,
} from '../../../shared/utilities/date-utilities';
import moment from 'moment';
import { SearchNotificationService } from '../set-up.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { SearchDealerLocationService } from '../../../dealer/search-location/seach-dealer-location.service';
import { DropdownDataService } from '../../../services/dropdown.data.service';

@Component({
  selector: 'setup-alert-modal',
  templateUrl: 'setup-alert-modal.template.html',
  styleUrls: ['./setup-alert-modal.scss'],
  providers: [
    FormFieldControlService,
    SetupAlertModalService,
    MessageService,
    SearchDealerLocationService,
  ],
})
export class SetupAlertModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Output() formSubmitEvent = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  isLoading = false;
  validForm = true;
  querySubscription: Subscription;
  templateResponse = null;
  selectedRowIndex: number;
  alertMessage: any;
  dataList: any[] = [];
  modelNames = [];
  selectedItem: any;
  isTableLoading: boolean;
  subcategoryDescription: [];
  subCategoryDetails: [];
  dealerLocationInfo = [];
  defaultOption = { key: 'ALL', value: 0 };
  querySubscriptions: Subscription[] = [];
  constructor(
    private qcs: FormFieldControlService,
    private setupAlertModalService: SetupAlertModalService,
    private alertService: SearchNotificationService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private dropdownDataService: DropdownDataService,
  ) {
    this.setupAlertModalService.getAlertFormFields().subscribe((data) => {
      this.formFields = data;
      this.formFields.find((el) => el.key === 'type').options = this.alertService.productTypeList;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.formData && this.formData.message) {
      this.updateCategoryOptions();
      this.alertMessage = this.formData.message;
      this.form.patchValue({
        alertMessage: this.formData.message,
        beginDate: generalDateFormatterWithoutTZ(this.formData.effectiveDateFrom),
        endDate: generalDateFormatterWithoutTZ(this.formData.effectiveDateTo),
        alertStatus: {
          key: this.formData.status,
          value: this.formData.status,
        },
        moduleType:
          this.formData.moduleType.length > 0
            ? [...this.formData.moduleType]
            : [this.defaultOption],
        categoryDescription:
          this.formData.cat.length > 0 ? [...this.formData.cat] : [this.defaultOption],
        subcategoryDescription:
          this.formData.subCat.length > 0 ? [...this.formData.subCat] : [this.defaultOption],
        type:
          this.formData.prodType.length > 0 ? [...this.formData.prodType] : [this.defaultOption],
        manufacturerName:
          this.formData.mfw.length > 0 ? [...this.formData.mfw] : [this.defaultOption],
        dealers: this.formData.dealer.length > 0 ? [...this.formData.dealer] : [this.defaultOption],
        dealerLocations:
          this.formData.dealerLoc.length > 0 ? [...this.formData.dealerLoc] : [this.defaultOption],
        buyingGroup:
          this.formData.buyingGroup.length > 0
            ? [...this.formData.buyingGroup]
            : [this.defaultOption],
      });
      if (this.formData.cat) {
        const selectedCatList = this.formData.cat.map((catDesc) => catDesc.key);
        this.getSubCatByCategory(selectedCatList);
      }
      if (this.formData.dealer) {
        this.getDealerLocations(this.formData.dealer);
      }
    }
    this.updateAlertInfo();
    this.querySubscriptions.push(
      this.form.get('categoryDescription').valueChanges.subscribe((categoryDescriptions) => {
        if (categoryDescriptions) {
          const selectedCatList = categoryDescriptions.map((catDesc) => catDesc.key);
          this.getSubCatByCategory(selectedCatList, true);
        }
      }),
    );
    this.querySubscriptions.push(
      this.form.get('dealers').valueChanges.subscribe((dealerGroup) => {
        this.getDealerLocations(dealerGroup);
      }),
    );
  }

  updateCategoryOptions() {
    if (this.formData.cat) {
      this.formData.cat.forEach((element, index) => {
        const catIndex = this.formFields[4].options.findIndex(
          (catOptions) => catOptions.key === element.key,
        );
        if (catIndex > -1) {
          this.formFields[4].options[catIndex] = element;
        }
      });
    }
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.formSubmitEvent.emit(false);
  }

  updateAlertInfo() {
    const updatedInfo = this.form.getRawValue();
    Object.keys(updatedInfo).forEach((key) => {
      switch (key) {
        case 'moduleType':
        case 'type':
        case 'manufacturerName':
        case 'dealers':
        case 'dealerLocations':
        case 'buyingGroup':
        case 'categoryDescription':
        case 'subcategoryDescription':
          this.querySubscriptions.push(
            this.form.get(key).valueChanges.subscribe((keyValue) => {
              const defaultOptionExists = keyValue.some(
                (option) =>
                  option.key === this.defaultOption.key &&
                  option.value === this.defaultOption.value,
              );
              if (keyValue.length > 1 && defaultOptionExists) {
                const defaultOptionExisted = this.form.value[key].some(
                  (option) =>
                    option.key === this.defaultOption.key &&
                    option.value === this.defaultOption.value,
                );
                if (defaultOptionExisted) {
                  const updatedOptions = keyValue.filter(
                    (option) =>
                      option.key !== this.defaultOption.key &&
                      option.value !== this.defaultOption.value,
                  );
                  this.form.get(key).setValue(updatedOptions, { emitEvent: false });
                } else {
                  this.form.get(key).setValue([this.defaultOption], { emitEvent: false });
                }
                if (key === 'dealers') {
                  this.getDealerLocations(this.formData.dealer);
                }
              }
            }),
          );
          break;
        default:
          break;
      }
    });
  }

  // Show error if no subCategories selected for any one selected Category.
  validateSubcategorySelection(rowData) {
    const errField = [];
    const errObj = { isValid: true, errMessage: '' };
    if (
      !rowData.subcategoryDescription.length ||
      rowData.subcategoryDescription.some((s) => s.key === this.defaultOption.key) ||
      rowData.categoryDescription.some((s) => s.key === this.defaultOption.key)
    ) {
      return errObj;
    }
    const selectSubCats = this.subCategoryDetails.filter((o) =>
      rowData.subcategoryDescription.some(
        ({ key, value }) => o[`description`] === key && o[`subCategoryId`] === value,
      ),
    );
    const selectedCats: string[] = selectSubCats.map((scat) => scat[`parentCategory`]);
    const catNotSelected = rowData.categoryDescription.filter(
      (cat) => !selectedCats.includes(cat.key),
    );
    if (catNotSelected.length > 0) {
      errField.push('SubCategories not selected for one or more selected Categories.');
    }
    if (errField.length > 0) {
      errObj.isValid = false;
      errObj.errMessage = `${errField.join()}`;
    }

    return errObj;
  }

  private validateMessage(rowData) {
    const errField = [];
    const errObj = { isValid: true, errMessage: '' };
    if (rowData && rowData.alertMessage) {
      if (rowData.alertMessage.includes('<img src=')) {
        errField.push('Images are not allowed in the message');
      }
    }
    if (errField.length > 0) {
      errObj.isValid = false;
      errObj.errMessage = `${errField.join()}`;
    }
    return errObj;
  }

  // Validate dates.
  validateDates(rowData) {
    const errField = [];
    const errObj = { isValid: true, errMessage: '' };
    const endDate = moment(rowData.endDate);
    const startDate = moment(rowData.beginDate);
    if (endDate < startDate) {
      errField.push('EFFECTIVE DATE TO should be greater than EFFECTIVE DATE FROM.');
    }
    if (errField.length > 0) {
      errObj.isValid = false;
      errObj.errMessage = `${errField.join()}`;
    }

    return errObj;
  }

  getSelectedCategories(params) {
    if (params.categoryDescription.includes(this.defaultOption)) {
      return [0];
    } else if (params.subcategoryDescription.includes(this.defaultOption)) {
      const allCatList = this.subCategoryDetails
        .map((item) => item[`categoryId`])
        .filter((value, index, self) => self.indexOf(value) === index);
      return allCatList;
    } else {
      const selectedSubCats = this.subCategoryDetails.filter((o) =>
        params.subcategoryDescription.some(
          ({ key, value }) => o[`description`] === key && o[`subCategoryId`] === value,
        ),
      );
      const selectedCatList = selectedSubCats
        .map((item) => item[`categoryId`])
        .filter((value, index, self) => self.indexOf(value) === index);
      return selectedCatList;
    }
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    if (this.form.valid) {
      const params = {
        alertIdOriginal: this.formData && this.formData.alertId ? this.formData.alertId : null,
        message: payload.alertMessage,
        status: payload.alertStatus.key,
        effectiveDateFrom: generalDateFormatter(payload.beginDate, 'YYYY/MM/DD'),
        effectiveDateTo: generalDateFormatter(payload.endDate, 'YYYY/MM/DD'),
        productPlanType: payload.type.length
          ? payload.type.map((productPlanType) => productPlanType.value)
          : [0],
        manufacturer: payload.manufacturerName.length
          ? payload.manufacturerName.map((mfw) => mfw.value)
          : [0],
        category: payload.categoryDescription.length ? this.getSelectedCategories(payload) : [0],
        subCategory: payload.subcategoryDescription.length
          ? payload.subcategoryDescription.map((subCat) => subCat.value)
          : [0],
        dealers: payload.dealers.length ? payload.dealers.map((dealer) => dealer.value) : [0],
        dealerLocations: payload.dealerLocations.length
          ? payload.dealerLocations.map((dealerLoc) => parseInt(dealerLoc.value, 10))
          : [0],
        buyingGroup: payload.buyingGroup.length ? payload.buyingGroup.map((bg) => bg.value) : [0],
        moduleType: payload.moduleType.length
          ? payload.moduleType.map((module) => module.value)
          : [0],
        insertUserName: this.azureService.accountId,
      };
      let errObj = this.validateDates(payload);
      if (errObj.isValid) {
        errObj = this.validateSubcategorySelection(payload);
      }
      if (errObj.isValid) {
        errObj = this.validateMessage(payload);
      }

      if (errObj.isValid) {
        this.isLoading = true;
        this.querySubscription = this.alertService
          .createupdateAlertData({ ...params })
          .subscribe((data) => {
            this.isLoading = false;
            this.formSubmitEvent.emit(true);
            this.displayDialog = false;
            this.displayDialogChange.emit(this.displayDialog);
          });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: errObj.errMessage,
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please fill the required fields.`,
      });
    }
  }

  formatData(item) {
    return [
      {
        alertId: item.alertIdOriginal,
        effectiveDateFrom: item.effectiveDateFrom,
        effectiveDateTo: item.effectiveDateTo,
        status: item.alertStatus,
        message: item.message,
        prodType: this.formatDataArray(item.prodType),
        mfw: this.formatDataArray(item.mfw),
        cat: this.formatDataArray(item.cat),
        subCat: this.formatDataArray(item.subCat),
        dealer: this.formatDataArray(item.dealer),
        dealerLoc: this.formatDataArray(item.dealerLoc),
        buyingGroup: this.formatDataArray(item.buyingGroup),
        moduleType: this.formatDataArray(item.moduleType),
      },
    ];
  }

  formatDataArray(itemArray) {
    const resultArray = itemArray
      ? itemArray.refData.map((d) => {
          return { key: d.referenceLabel, value: d.referenceData };
        })
      : [];
    return resultArray;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  getDealerLocations(dealerGroup) {
    this.dealerLocationInfo = [];
    if (
      dealerGroup &&
      dealerGroup.length > 0 &&
      dealerGroup.some((dg) => dg.value !== this.defaultOption.value)
    ) {
      const selectedDGs = dealerGroup.map((dG) => dG.value.toString());
      this.pullDealerLocationInformation(selectedDGs);
    } else {
      this.pullDealerLocationInformation(null);
    }
  }

  getSubCatByCategory(categoryDescriptions, isChangeEvent = false) {
    this.isLoading = true;
    this.querySubscriptions.push(
      this.alertService.getSubListByCategory(categoryDescriptions).subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          this.subCategoryDetails = data.getSubcategoryListByCategory;
          let subcategoryDescriptionOptions = [];
          subcategoryDescriptionOptions =
            this.subCategoryDetails && this.subCategoryDetails.length > 0
              ? this.subCategoryDetails.map((el) => {
                  return {
                    key: el[`description`],
                    value: el[`subCategoryId`],
                  };
                })
              : [];
          subcategoryDescriptionOptions.unshift(this.defaultOption);
          this.formFields[5].options = subcategoryDescriptionOptions;

          // To remove selected subCategories if corresponding Category is unselected
          if (this.form.value[`subcategoryDescription`].length > 0) {
            const subCatSelections = this.form.value[`subcategoryDescription`].filter((o) =>
              subcategoryDescriptionOptions.some(
                ({ key, value }) => o.key === key && o.value === value,
              ),
            );
            this.form
              .get('subcategoryDescription')
              .setValue(subCatSelections, { emitEvent: false });
          }
        },
        (err) => {
          throw err;
        },
      ),
    );
  }

  pullDealerLocationInformation(dgNumbers) {
    this.isLoading = true;
    const dealerLocationSubscription = this.alertService
      .getDealerLocationByDealer(dgNumbers)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          this.dealerLocationInfo = data.getAllDealerLocations;
          const dealerLocationOptions =
            this.dealerLocationInfo && this.dealerLocationInfo.length > 0
              ? this.dealerLocationInfo.map((el) => {
                  return {
                    key: el[`value`],
                    value: el[`key`],
                  };
                })
              : [];
          dealerLocationOptions.unshift(this.defaultOption);
          this.formFields[7].options = dealerLocationOptions;

          // To remove selected dealerLocations if corresponding dealer is unselected
          if (this.form.value[`dealerLocations`].length > 0) {
            const dealerLocationSelections = this.form.value[`dealerLocations`].filter((o) =>
              dealerLocationOptions.some(({ key, value }) => o.key === key && o.value === value),
            );
            this.form
              .get('dealerLocations')
              .setValue(dealerLocationSelections, { emitEvent: false });
          }
          dealerLocationSubscription.unsubscribe();
        },
        (err) => {
          throw err;
        },
      );
  }

  populateList(listData) {
    const arr = [];
    listData.forEach((element) => {
      arr.push({ key: element, value: element });
    });
    return arr;
  }
}
