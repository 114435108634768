import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ResolutionService } from '../resolution.service';
import { Resolution } from '../resolution';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import { ellipsisOptions } from '../../../shared/constants/ellipsis-options';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';

@Component({
  selector: 'symptom-one',
  templateUrl: 'symptom-one.template.html',
  styleUrls: ['symptom-one.scss'],
  providers: [ResolutionService, ContractFormFieldService],
})
export class SymptomOneComponent extends FormCanDeactivate implements OnInit {
  formModalInfo: FormField<any>[] = [];
  searchResults: Resolution[];
  cols: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  options: any[] = ellipsisOptions;
  selectedEllipsisItem: any;
  displayAdd: boolean;
  displayUpdate: boolean;

  constructor(
    private search: ResolutionService,
    private cdr: ChangeDetectorRef,
    private contractService: ContractFormFieldService,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
  }

  ngOnInit() {
    this.searchResults = this.search.getSymptomOnes();
    this.cols = [{ field: 'symptom' }];
    this.options = [
      { label: 'Add', value: 'add' },
      { label: 'Update', value: 'update' },
    ];
    this.contractService
      .getFormModalFields()
      .subscribe((formModalInfo) => (this.formModalInfo = formModalInfo));
  }
  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  handleOpen(e) {
    if (e.value === 'add') {
      this.displayAdd = true;
    } else {
      this.displayUpdate = true;
    }
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayUpdate = false;
    this.cdr.detectChanges();
  }
}
