import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const oemWarrantyQueryByFile = gql`
  query($fileName: String!) {
    getOEMWarrantyDetailsByFile(fileName: $fileName) {
      oEMWarrantyId
      country
      oEM
      modelName
      modelNumber
      condition
      category
      subCategory
      partsTerm
      laborTerm
      majorComponentTerm
      otherTerm
    }
  }
`;
@Injectable()
export class OEMWarrantyService {
  constructor(private apollo: Apollo) {}

  getOEMWarrantyByFile = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: oemWarrantyQueryByFile,
      variables: {
        ...searchParams
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
