import { Component, Input, OnInit } from '@angular/core';
import { TabsService } from '../../../services/tabs.service';
import { WorkQueueService } from './work-queue.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Component({
  selector: 'work-queue',
  templateUrl: 'work-queue.template.html',
  styleUrls: ['work-queue.scss', '../../../shared/styles/crm-table.scss'],
  providers: [WorkQueueService],
})
export class WorkQueueComponent implements OnInit {
  @Input() searchParams: any;
  admin: any[];
  cols: any[];
  resultCount: number; // todo: need to be replace with actual value
  isLoading: boolean;
  constructor(
    private workQueueService: WorkQueueService,
    private tabService: TabsService,
    private azureService: AzureLoginService,
  ) {}

  ngOnInit() {
    this.resultCount = 0;
    this.isLoading = true;
    this.workQueueService.getWorkQueue(this.searchParams, this.azureService.accountId).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        if (data.getQueueStatusGroup) {
          this.resultCount = data.getQueueStatusGroup.length;
        }
        this.admin = data.getQueueStatusGroup;
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'count', header: 'COUNT', type: 'link' },
    ];
  }

  openTab(rowData) {
    const queueType = rowData.type && rowData.type.toLowerCase();
    let searchItem = {};
    switch (queueType) {
      case 'contract':
      case 'contracts':
        searchItem = {
          header: 'SearchContract',
          type: 'SearchContract',
          searchParams: {
            status: { key: rowData[`status`], value: rowData[`status`] },
            buyingGroup: this.searchParams.buyingGroup ? this.searchParams.buyingGroup.key : null,
            servicer: this.searchParams.servicer ? this.searchParams.servicer.key : null,
            dealer: this.searchParams.dealerGroupNumber
              ? this.searchParams.dealerGroupNumber.key
              : null,
          },
        };
        break;
      case 'claims':
        searchItem = {
          header: 'SearchServiceOrder',
          type: 'SearchServiceOrder',
          searchParams: {
            status: { key: rowData[`status`], value: rowData[`status`] },
            totalRecords: rowData[`count`],
            buyingGroup: this.searchParams.buyingGroup ? this.searchParams.buyingGroup.key : null,
            servicer: this.searchParams.servicer ? this.searchParams.servicer.key : null,
            dealer: this.searchParams.dealerGroupNumber
              ? this.searchParams.dealerGroupNumber.key
              : null,
            workQueueFlag: true,
          },
        };
        break;
    }

    this.tabService.createTab({
      header: searchItem[`header`],
      content: searchItem[`searchParams`],
      type: searchItem[`type`],
    });
  }
}
