import { Component, OnDestroy, OnInit } from '@angular/core';
import { BuyingGroupService } from '../../services/buying-group.service';
import { FormField } from '../../shared/form-field/form-field';
import { BuyingGroupDataService } from '../buying-group.service';
import _find from 'lodash/find';

@Component({
  selector: 'buying-group-main-tab',
  templateUrl: 'buying-group-main-tab.template.html',
  styleUrls: ['buying-group-main-tab.scss'],
})
export class BuyingGroupMainTabComponent implements OnInit, OnDestroy {
  mailAddressInfo: FormField<any>[] = [];
  contactInfo: FormField<any>[] = [];
  isLoading: any;
  isMainTabDataAvailable: boolean;
  querySubscription: any;
  isNew: any;

  constructor(
    private buyingService: BuyingGroupService,
    public buyingGroupDataService: BuyingGroupDataService,
  ) {}
  ngOnInit() {
    this.buyingService
      .getContactInfoFields()
      .subscribe((contactInfo) => (this.contactInfo = contactInfo));
    this.buyingService
      .getMailingBillingAddressFields()
      .subscribe((mailAddressInfo) => (this.mailAddressInfo = mailAddressInfo));

    if (
      this.buyingGroupDataService.buyingGroupConfig.searchParams &&
      this.buyingGroupDataService.buyingGroupConfig.searchParams.id
    ) {
      this.isLoading = true;
      this.querySubscription = this.buyingGroupDataService
        .getBuyingGroupMainTab(this.buyingGroupDataService.buyingGroupConfig.searchParams.id)
        .subscribe(
          ({ data, loading }: any) => {
            if (data) {
              const customerInformation = {
                ...data.getContactDetails,
                businessPhoneTypeCode: data.getContactDetails.businessPhoneTypeCode
                  ? {
                      key: data.getContactDetails.businessPhoneTypeCode,
                      value: data.getContactDetails.businessPhoneTypeCode,
                    }
                  : null,
              };

              this.buyingGroupDataService.buyingGroupDataModel.mainTab = {
                customerInformation,
                maillingAddresses: _find(data.getAddressesDetails, (add) => add.isMailingAddress),
                billingAddresses: _find(data.getAddressesDetails, (add) => add.isBillingAddress),
              };
              this.isMainTabDataAvailable = true;
              this.isLoading = loading;
            }
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    } else {
      this.isNew = true;
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
