<div class="coverage-details">
  <app-section-header [title]="'Coverage'" />
  <!-- <p-blockUI [blocked]="disableCoverageSelection" [target]="pnl"></p-blockUI> -->
  <p-panel #pnl>
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="coveredDetails" [selectionMode]="'single'"
      [(selection)]="selectedCoverage" dataKey="id" (onRowSelect)="onRowSelect($event)">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th [style.width]="'3em'"></th>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData="" let-rowIndex="rowIndex" let-columns="columns">
        <tr [pSelectableRow]="rowData" [pSelectableRowDisabled]="rowData.blockEdit">
          <td>
            <p-tableRadioButton [value]="rowData" [disabled]="rowData.blockEdit" [index]="rowIndex">
            </p-tableRadioButton>
          </td>
          <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
  <div class="grid">
    <div class="col-6">
      <div style="{padding: 10px;}">
        Coverage Reason
        <p-dropdown [options]="coverageReasonList" optionLabel="key"
          [(ngModel)]="serviceOrderDataService.serviceOrderDataModel.coverageReason" placeholder="Select Reason"
          [disabled]="isEditEnable === false"></p-dropdown>
      </div>
    </div>
    <div class="col-6">
      <div *ngIf="isGoodWillVisible">
        Goodwill Reason<p-dropdown [options]="goodwillReasonList" optionLabel="value"
          [(ngModel)]="serviceOrderDataService.serviceOrderDataModel.goodwillReason"
          placeholder="Select Goodwill Reason"></p-dropdown>
      </div>
    </div>
  </div>
  <p-dialog [header]="'OEM Warranty'" [(visible)]="display" showeffect="fade" [modal]="false"
    [styleClass]="'add-dialog'" [blockScroll]="true">
    <div class="modal-content">
      <i class="pi pi-exclamation-triangle" [style.font-size]="'2em'"></i>
      <p>Product is still under manufacturer warranty.</p>
    </div>
    <ng-template pTemplate="footer">
      <div class="p-dialog-buttonpane ui-helper-clearfix">
        <button class="crm-button" type="submit" (click)="display=false" label="Cancel">OK</button>
      </div>
    </ng-template>
  </p-dialog>
</div>