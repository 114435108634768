import { Injectable } from '@angular/core';
import { CheckBoxField } from '../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { FormField } from '../../../shared/form-field/form-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { optionEllipsisHeaderOptions } from '../../../catalog/catalog.constant';

@Injectable()
export class ServicerServiceFormService {
  // TODO: get from a remote source of formField metadata

  getServicerFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'serviceRecallPeriod',
        label: 'Service Recall Period',
        isSingleselect: true,
        options: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 3, value: 3 },
          { key: 6, value: 6 },
          { key: 12, value: 12 },
          { key: 30, value: 30 },
          { key: 60, value: 60 },
          { key: 90, value: 90 },
          { key: 120, value: 120 }
        ],
        order: 1
      }),

      new DropdownField({
        key: 'invoiceSubmission',
        label: 'Invoice Submission',
        isSingleselect: true,
        options: [
          { key: 0, value: 0 },
          { key: 3, value: 3 },
          { key: 30, value: 30 },
          { key: 60, value: 60 },
          { key: 90, value: 90 }
        ],
        order: 2
      }),
      new DropdownField({
        key: 'partsVerificationRequired',
        label: 'parts verification required',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'true' }, { key: 'No', value: 'false' }],
        order: 3
      }),
      new DropdownField({
        key: 'contracted',
        label: 'Contracted',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'true' }, { key: 'No', value: 'false' }],
        order: 4
      }),
      new DropdownField({
        key: 'sealedSystemsRepair',
        label: 'Sealed System Repair',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'true' }, { key: 'No', value: 'false' }],
        order: 5
      }),
      new DropdownField({
        key: 'customerSatisfaction',
        label: 'Customer Satisfaction',
        isSingleselect: true,
        options: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 2, value: 2 },
          { key: 3, value: 3 },
          { key: 4, value: 4 },
          { key: 5, value: 5 },
          { key: 6, value: 6 },
          { key: 7, value: 7 },
          { key: 8, value: 8 },
          { key: 9, value: 9 },
          { key: 10, value: 10 }
        ],
        order: 6
      }),
      new DropdownField({
        key: 'rating',
        label: 'Rating',
        isSingleselect: true,
        options: [
          { key: 0, value: 0 },
          { key: 1, value: 1 },
          { key: 2, value: 2 },
          { key: 3, value: 3 },
          { key: 4, value: 4 },
          { key: 5, value: 5 },
          { key: 6, value: 6 },
          { key: 7, value: 7 },
          { key: 8, value: 8 },
          { key: 9, value: 9 },
          { key: 10, value: 10 }
        ],
        order: 7
      }),
      new DropdownField({
        key: 'facilityType',
        label: 'Facility Type',
        isSingleselect: true,
        options: [
          { key: '', value: '' },
          { key: 'Depot', value: 'D' },
          { key: 'Depot & Standard', value: 'DS' },
          { key: 'Standard', value: 'S' }
        ],
        order: 8
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
