import { FormField } from './form-field';

export class RichTextAreaField extends FormField<string> {
  controlType = 'richtextarea';
  type: string;
  rows: number;
  cols: number;

  constructor(options: any = {}) {
    super(options);
    this.type = options[`type`] || '';
    this.rows = options[`rows`] || 5;
    this.cols = options[`cols`] || 60;
  }
}
