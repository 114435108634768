<div class="problem">
  <app-section-header [title]="'Problem'" [optionHeader]="opHeader" />
  <div class="sub-title">Standard Problem Description</div>
  <div class="row">
    <div class="column" *ngFor="let item of problemInfo">
      <div class="row">
        <div class="column label">{{item.label}}</div>
        <div class="column value">{{item.value}}</div>
      </div>
    </div>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox
    [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event,opHeader)"
  >
  </p-listbox>
</p-overlayPanel>
<problem-modal
  modalTitle="Problem"
  *ngIf="displayValidator"
  [(displayDialog)]="displayValidator"
  [formFields]=""
  (formSubmitEvent)="addItem($event)"
>
</problem-modal>
