<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <app-tab-spinner *ngIf="isLoading" />
  <div class="modal-content" *ngIf="!isLoading">
    <form (ngSubmit)="onSubmit()">
      <div class="grid">
        <div class="col-4">
          <p-dropdown [options]="shortCodeList" [(ngModel)]="shortCodeSelected" (onChange)="onShortCodeChange($event)"
            optionLabel="key" name="shortCodes"></p-dropdown>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[1]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <form-field [formField]="formFields[3]" [form]="form"> </form-field>
        </div>
      </div>
    </form>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button left-align" type="submit" (click)="onSubmit()" label="Send">
        Send Mail
      </button>
      <button class="crm-button left-align" type="button" (click)="onSaveNotes()" label="Save">
        Save To Notes
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>