import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { CoverageFormFieldService } from '../../../coverage-form-field.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { TabHandlerService } from 'src/app/modules/crm/services/tab-handler.service';
import { CoverageService } from '../../coverage.service';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'coverage-setting',
  templateUrl: 'coverage-setting.template.html',
  styleUrls: ['coverage-setting.scss'],
  providers: [FormFieldControlService],
})
export class CoverageSettingComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  formFields: FormField<string>[] = [];
  form: FormGroup;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  tabKey: string;
  querySubscription: any;
  isLoading: boolean;

  constructor(
    private qcs: FormFieldControlService,
    private service: CoverageFormFieldService,
    private tabHandlerService: TabHandlerService,
    private coverageService: CoverageService,
  ) {
    super();
    this.service.getSettingFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.form = this.qcs.toFormGroup(this.formFields);
    });
  }

  ngOnInit() {
    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.isLoading = true;
          this.querySubscription = this.coverageService
            .getCoverageSettingTab(this.coverageService.coverageDataModel.coverageCode)
            .subscribe(
              ({ data, loading }: any) => {
                this.isLoading = loading;
                const modifiedData = data.getCoverageSettingsById;
                if (modifiedData) {
                  this.form.patchValue({
                    effectiveDateLag: modifiedData.effectiveDateLag,
                    effectiveDateLagBy: {
                      key:
                        modifiedData.effectiveDateLagBy !== 'Month'
                          ? 'Days'
                          : modifiedData.effectiveDateLagBy,
                      value:
                        modifiedData.effectiveDateLagBy !== 'Month'
                          ? 'Days'
                          : modifiedData.effectiveDateLagBy,
                    },
                    exclusionDays: modifiedData.exclusionDays,
                    serviceLocation: {
                      key: modifiedData.serviceLocation,
                      value: modifiedData.serviceLocation,
                    },
                    purchaseTime: modifiedData.purchaseTime,
                    msrp: [modifiedData.msrp], // radio button
                    minMsrp: modifiedData.minMsrp,
                    maxMsrp: modifiedData.maxMsrp,
                    // regulatedRetail: [modifiedData.regulatedRetail], // radio button
                    // discountable: [modifiedData.discountable], // radio button
                    // minMarkup: modifiedData.minMarkup,
                    // maxMarkup: modifiedData.maxMarkup,
                    // maxAmount: modifiedData.maxAmount,
                    // maxPartMarkup: modifiedData.maxPartMarkup,
                    productCountSetting: modifiedData.productCountSetting,
                    insurerName: { key: modifiedData.insurerName, value: modifiedData.insurerName },
                    limitOfLiabilityBasedOn: {
                      key: 'Product',
                      value: 'Product',
                    },
                    deductibleCollectedBy: {
                      key: modifiedData.deductibleCollectedBy,
                      value: modifiedData.deductibleCollectedBy,
                    },
                    defaultDispatchMethod: {
                      key:
                        modifiedData.defaultDispatchMethod &&
                        modifiedData.defaultDispatchMethod === 'E'
                          ? 'Email Account'
                          : 'Fax',
                      value: modifiedData.defaultDispatchMethod,
                    }, // dropdown
                  });
                }
              },
              (err) => {
                this.isLoading = false;
                throw err;
              },
            );
        }
      },
    });
  }

  onSubmit() {
    // functionality for on submit
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
