<div class="crm-container">
  <app-section-header [title]="'Subcategory Results ( ' + dataList.length + ' item(s) found )'"
    [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner><p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="cols" [value]="dataList"
      dataKey="id" editMode="row" [hidden]="isLoading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="'row-edit'"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor><ng-template *ngIf="col.type === 'text'"
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="ellipsisClick($event,rowData,opRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template></p-table><button class="btn-crm-success" type="button" (click)="getSubcategorySearchData()"
      [hidden]="isLoading" [disabled]="dataList.length % offsetLimit !== 0">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="header" [(visible)]="displayAdd" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field *ngIf="!formField.isChangeEvent" [formField]="formField" [form]="form"> </form-field>
          <form-field *ngIf="formField.isChangeEvent" [formField]="formField" [form]="form"
            (changeEvent)="changeEventDetection($event, formField.key)">
          </form-field>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Cancel"
        [disabled]="!form.valid || !form.dirty || isLoading">
        Save
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>