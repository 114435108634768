import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SearchPayeeService } from './seach-payee.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';
import { Subscription } from 'rxjs';
import { copy } from '../../shared/utilities/common-utilities';
@Component({
  selector: 'search-payee',
  templateUrl: 'search-payee.template.html',
  providers: [SearchPayeeService],
})
export class SearchPayeeComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  status: any[];
  newTab: Tab;
  querySubscription: Subscription;
  isLoading = false;
  op: OverlayPanel;
  constructor(
    private searchPayeeService: SearchPayeeService,
    private tabService: TabsService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.isLoading = true;
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'name', header: 'NAME', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
      { field: 'associationType', header: 'ASSOCIATION TYPE', type: 'text' },
      { field: 'associationName', header: 'ASSOCIATION NAME', type: 'text' },
    ];
    this.querySubscription = this.searchPayeeService.getPayeeDetails(this.searchParams).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.dataList = [...data.getPayeeSearchResults];
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    this.newTab = {
      header: 'Payee',
      content: this.selectedItem.claimPayeeidOriginal,
      type: 'Payee',
    };
    this.tabService.createTab(this.newTab);
    overlaypanel.hide();
  }
  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
