import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const contractSearchQuery = gql`
  query(
    $status: String
    $contractNumber: String
    $uniqueId: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $startDate: Date
    $endDate: Date
    $modelNumber: String
    $serialNumber: String
    $externalId: String
    $address1: String
    $limit: Int
    $offset: Int
    $azureUserId: String
    $guardian: String
    $city: String
    $state: String
    $manufacturer: String
    $subcategory: String
    $transactionStartDate: Date
    $transactionEndDate: Date
    $effectiveStartDate: Date
    $effectiveEndDate: Date
    $entryStartDate: Date
    $entryEndDate: Date
    $dealer: String
    $productPurchaseStartDate: Date
    $productPurchaseEndDate: Date
    $fileType: String
    $fileName: String
    $isOr: Boolean
    $userName: String
    $inquiryStatus: String
  ) {
    getContractSearchResults(
      status: $status
      contractNumber: $contractNumber
      uniqueId: $uniqueId
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      startDate: $startDate
      endDate: $endDate
      modelNumber: $modelNumber
      serialNumber: $serialNumber
      externalId: $externalId
      address1: $address1
      offset: $offset
      limit: $limit
      azureUserId: $azureUserId
      guardian: $guardian
      city: $city
      state: $state
      manufacturer: $manufacturer
      subcategory: $subcategory
      transactionStartDate: $transactionStartDate
      transactionEndDate: $transactionEndDate
      effectiveStartDate: $effectiveStartDate
      effectiveEndDate: $effectiveEndDate
      entryStartDate: $entryStartDate
      entryEndDate: $entryEndDate
      dealer: $dealer
      productPurchaseStartDate: $productPurchaseStartDate
      productPurchaseEndDate: $productPurchaseEndDate
      fileName: $fileName
      fileType: $fileType
      isOr: $isOr
      userName: $userName
      inquiryStatus: $inquiryStatus
    ) {
      contractsId
      status
      uniqueId
      number
      firstName
      lastName
      phoneNumber
      contractNumber
      modelNumber
      serialNumber
      externalId
      address1
      customerId
      originalId
      customerIdOriginal
      city
      state
      entryDate
      effectiveDate
      guardian
      transactionDate
      dealerGroupName
      manufacturer
      subcategoryDescription
      productPurchaseDate
    }
  }
`;

const addQueryParameterResultsQuery = gql`
  mutation(
    $moduleName: String
    $resultsCount: Int
    $allParameters: String
    $insertUserName: String
    $parameter1: String
    $parameter2: String
    $tenantId: Int
    $primaryKeyUsed: Boolean
  ) {
    addQueryParameterResultsRequest(
      requestInput: {
        moduleName: $moduleName
        resultsCount: $resultsCount
        allParameters: $allParameters
        insertUserName: $insertUserName
        parameter1: $parameter1
        parameter2: $parameter2
        tenantId: $tenantId
        primaryKeyUsed: $primaryKeyUsed
      }
    ) {
      queryParameterResultsIdOriginal
      queryParameterResultsId
      moduleName
      resultsCount
      allParameters
      parameter1
      parameter2
      tenantId
      primaryKeyUsed
    }
  }
`;

@Injectable()
export class SearchContractService {
  constructor(private apollo: Apollo) {}

  getContractSearchResult = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: contractSearchQuery,
      variables: {
        ...searchParams,
        dealer: searchParams.dealer && searchParams.dealer.key,
        subcategory: searchParams.subcategory && searchParams.subcategory.key,
        transactionStartDate: searchParams.transactionDate && searchParams.transactionDate[0],
        transactionEndDate: searchParams.transactionDate && searchParams.transactionDate[1],
        effectiveStartDate: searchParams.effectiveDate && searchParams.effectiveDate[0],
        effectiveEndDate: searchParams.effectiveDate && searchParams.effectiveDate[1],
        entryStartDate: searchParams.entryDate && searchParams.entryDate[0],
        entryEndDate: searchParams.entryDate && searchParams.entryDate[1],
        productPurchaseStartDate:
          searchParams.productPurchaseDate && searchParams.productPurchaseDate[0],
        productPurchaseEndDate:
          searchParams.productPurchaseDate && searchParams.productPurchaseDate[1],
        status: searchParams.status && searchParams.status.value,
        offset: searchParams.offset ? parseInt(searchParams.offset, 10) : 0,
        limit: searchParams.limit ? parseInt(searchParams.limit, 10) : 20,
        fileType: searchParams.fileType ? searchParams.fileType : null,
        isOr: searchParams.isOr ? searchParams.isOr : false,
        userName: searchParams.userName ? searchParams.userName : null,
        inquiryStatus: searchParams.inquiryStatus ? searchParams.inquiryStatus : null,
        state: searchParams.state ? searchParams.state.value : ''
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  addQueryParameterResults = data =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: addQueryParameterResultsQuery,
      variables: { ...data }
      // tslint:disable-next-line: semicolon
    });
}
