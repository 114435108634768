<div class="crm-container">
  <app-section-header [title]="'Claim Payment Notification Results'" [optionHeader]="optionHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [value]="dataList" dataKey="id"
      editMode="row" [hidden]="isLoading">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'" [class]="btnStatus[rowData[col.field].toLowerCase()]">
              {{rowData[col.field]}}
            </button>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'Date'" type="Date"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text' || col.type === 'Date'" pTemplate="output">
                {{rowData[col.field]}}
              </ng-template>
              <ng-template *ngIf="col.field === 'fileStatus'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()]">
                  {{rowData[col.field] | uppercase}}
                </button></ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!rowData['remittanceDate']"
              (click)="ellipsisClick($event,rowData,optionRow,ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button [style.display]="'none'" id="{{editConstant + rowData.id}}" type="button" icon="pi pi-pencil"
              (click)="displayEditModal()"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, optionHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="'Remittance Date'" [(visible)]="displayDialog" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="updateRemittanceDate()" [formGroup]="form">
      <div class="grid">
        <div class="col-12" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <button class="crm-button" type="submit" id="btn-submit-form" label="!form.valid" hidden="hidden"></button>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button label-btn" *ngIf="form.valid" (click)="updateRemittanceDate()" for="btn-submit-form" tabindex="0">
        Finalize
      </button>
      <button class="crm-button label-btn btn-disabled" *ngIf="!form.valid" tabindex="0">
        Finalize
      </button>
    </div>
  </ng-template>
</p-dialog>