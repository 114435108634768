<div class="form-details-container">
  <div class="form-details-title">
    <div class="form-thumnail" [inlineSVG]="'assets/icon-tech-list.svg'"></div>
    <div class="form-name"><input disabled="disabled" value="Search Order Details" /></div>
    <div class="last-cell">
      <div class="three-dots-heading" (click)="ellipsisClick($event,'item',opHeader, null)">
        <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
      </div>
    </div>
  </div>
  <div class="sub-title-bar">Service Order Information</div>
  <div class="form-details-body">
    <div class="detail-group push-bottom-small">
      <div class="details-section" *ngFor="let info of serviceOrderInfo">
        <div class="detail-label">{{info.label}}</div>
        <div class="detail-value" *ngIf="!info.type">{{info.value}}</div>
        <div class="detail-value__link" *ngIf="info.type" (click)="openTab(info.type)">
          {{info.value}}
        </div>
      </div>
    </div>
    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields; let i = index">
          <form-field [formField]="formField" [form]="form" *ngIf="i&lt;7"></form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="sub-title-bar">
    <div class="title">Servicer Information</div>
  </div>
  <div class="form-details-body">
    <div class="details-section" *ngFor="let info of servicerInfo">
      <div class="detail-label">{{info.label}}</div>
      <div class="detail-value" *ngIf="!info.type">{{info.value}}</div>
      <div class="detail-value__link" *ngIf="info.type" (click)="openTab(info.type)">
        {{info.value}}
      </div>
    </div>
    <div class="details-section" *ngIf="isServiceBenchFeatureOn && isScheduleJobAvailable">
      <div class="detail-label" *ngIf="!isReschedule">Scheduled Date</div>
      <div class="detail-label" *ngIf="isReschedule">Rescheduled Date</div>
      <div class="detail-value">
        {{dateFormatter(serviceOrderDataService.serviceBenchModal.scheduleJobDetails.scheduledDate,'MM/DD/YYYY')}}
      </div>
    </div>
    <div class="details-section" *ngIf="isServicePowerFeatureOn && isServicePowerDispatch">
      <div class="detail-label" *ngIf="!isReschedule">Scheduled Date</div>
      <div class="detail-label" *ngIf="isReschedule">Rescheduled Date</div>
      <div class="detail-value">
        {{dateFormatter(serviceOrderDataService.servicePowerModal.scheduleCallDetails.scheduledDate,'MM/DD/YYYY')}}
      </div>
    </div>
    <div class="details-section" *ngIf="isServiceBenchFeatureOn && isScheduleJobAvailable">
      <div class="detail-label">Scheduled Time Range</div>
      <div class="detail-value">
        {{timeRangeFormatter(serviceOrderDataService.serviceBenchModal.scheduleJobDetails.timeSlotStart,serviceOrderDataService.serviceBenchModal.scheduleJobDetails.timeSlotLength)}}
      </div>
    </div>
    <div class="details-section" *ngIf="isServicePowerFeatureOn && isServicePowerDispatch">
      <div class="detail-label">Scheduled Time Range</div>
      <div class="detail-value">
        {{spTimeRangeFormatter(serviceOrderDataService.servicePowerModal.scheduleCallDetails.timePeriod)}}
      </div>
    </div>
    <div class="details-section" *ngIf="isServiceBenchFeatureOn && isScheduleJobAvailable">
      <div class="detail-label">Scheduled Service Provider Account</div>
      <div class="detail-value">
        {{serviceOrderDataService.serviceBenchModal.scheduleJobDetails.servicerAccount}}
      </div>
    </div>
    <div class="details-section" *ngIf="isServicePowerFeatureOn && isServicePowerDispatch">
      <div class="detail-label">Scheduled Service Provider Account</div>
      <div class="detail-value">
        {{serviceOrderDataService.servicePowerModal.scheduleCallDetails.servicerAccount}}
      </div>
    </div>
    <div class="details-section"
      *ngIf="((isServiceBenchFeatureOn && isScheduleJobAvailable) || (isServicePowerFeatureOn && isServicePowerDispatch)) && stServiceProviderName">
      <div class="detail-label">Scheduled Service Provider Name</div>
      <div class="detail-value">{{stServiceProviderName}}</div>
    </div>
    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields; let i = index">
          <form-field [formField]="formField" [form]="form" *ngIf="i>=7"></form-field>
        </div>
      </form>
    </div>
    <div class="claim-button">
      <button (click)="onClickLol()" [disabled]="lolReady" [ngClass]="{'disabled': lolReady }">
        Limit of Liability
      </button>
      <button *ngIf="!subClaim && canEdit" (click)="onSendEmail()">Send Email Notification</button>
      <button *ngIf="buttonAuth['Proceed with Dispatch'] && canDispatch && !subClaim && canEdit"
        (click)="onClickClaim()" [disabled]="customerDispatch" [ngClass]="{'disabled': customerDispatch }">
        Proceed with Dispatch
      </button>
      <button *ngIf="isServiceBenchFeatureOn && isScheduleJobAvailable && canEdit && isRCButtonsEnabled"
        (click)="onCancelJob()" [disabled]="customerDispatch"
        [ngClass]="(isWithInElegibleTime) ? 'button-gray' : 'button-green'">
        Cancel Job
      </button>
      <button *ngIf="isServiceBenchFeatureOn && isScheduleJobAvailable && canEdit && isRCButtonsEnabled"
        (click)="onRescheduleJob()" [disabled]="customerDispatch"
        [ngClass]="(isWithInElegibleTime) ? 'button-gray' : 'button-green'">
        Reschedule Job</button><button
        *ngIf="isServicePowerFeatureOn && isServicePowerDispatch && canEdit && isRCButtonsEnabled"
        (click)="onCancelCall()" [disabled]="customerDispatch"
        [ngClass]="(isWithInElegibleTimeSp) ? 'button-gray' : 'button-green'">
        Cancel Job
      </button>
      <button *ngIf="isServicePowerFeatureOn && isServicePowerDispatch && canEdit && isRCButtonsEnabled"
        (click)="onRescheduleCall()" [disabled]="customerDispatch"
        [ngClass]="(isWithInElegibleTimeSp) ? 'button-gray' : 'button-green'">
        Reschedule Job
      </button>
    </div>
    <div class="button-bar">
      <div *ngFor="let singleButton of button">
        <button type="singleButton" [disabled]="singleButton.disabled || !canEdit" [hidden]="singleButton.hidden"
          (click)="this[singleButton.callBack] ? this[singleButton.callBack]($event) : onDefault($event) ">
          {{singleButton.name}}
        </button>
      </div>
    </div>
    <p-toast></p-toast>
  </div>
  <p-message severity="success" text="Record Saved" *ngIf="isSuccess"> </p-message>
</div>
<servicer-dispatch-modal modalTitle="Servicer Dispatch" [displayDialog]="displayDispatch" [formFields]="formFields1"
  (formSubmitEvent)="onValidatorSubmit($event)" *ngIf="displayDispatch"
  (claimDisplayDialogChange)="onDispatchDisplayChange($event)">
</servicer-dispatch-modal>
<service-bench-modal *ngIf="displayDirectServiceBench" modalTitle="Reschedule Service Bench"
  [(displayDialog)]="displayDirectServiceBench" (formSubmitEvent)="onServicerBenchDirectSchedule($event)"
  [isReschedule]="true">
</service-bench-modal>
<service-power-modal *ngIf="displayDirectServicePower" modalTitle="Reschedule Service Power"
  [(displayDialog)]="displayDirectServicePower" (formSubmitEvent)="onServicerPowerDirectSchedule($event)"
  [isReschedule]="true">
</service-power-modal>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<send-email-modal modalTitle="Send Info Email" [displayDialog]="displayEmailModal"
  (formSubmitEvent)="onValidatorSubmit($event)" (displayDialogChange)="onDisplayChange($event)">
</send-email-modal>
<limit-of-liability-modal modalTitle="Limit of Liability" [displayDialog]="displayLolModal"
  (formSubmitEvent)="onValidatorSubmit($event)" (displayDialogChange) = "onLolModalChange($event)">
</limit-of-liability-modal>
<crm-export-dialog [displayDialog]="displayExport" [filterByColumns]="true" [fileName]="'crm-notes'" [rows]="rows"
  [filterColumnList]="selectedColumns" [dataTable]="exportDataTable" [pdfTableOptions]="pdfTableOptions">
</crm-export-dialog>
<crm-form-modal *ngIf="displayCancelDialog" modalTitle="Cancellation Note" [displayDialog]="displayCancelDialog"
  [formFields]="cancelFormFields" (formSubmitEvent)="onCancelConfirmation($event)" [submitButtonlabel]="'Submit'"
  [cancelButtonlabel]="'Cancel'" (displayDialogChange)="onCancelModalCancel($event)" [singleRow]="true">
</crm-form-modal>
<crm-form-modal *ngIf="displaySpCancelDialog" modalTitle="Cancellation Reason" [displayDialog]="displaySpCancelDialog"
  [formFields]="spCancelFormFields" (formSubmitEvent)="onSpCancelConfirmation($event)" [submitButtonlabel]="'Submit'"
  [cancelButtonlabel]="'Cancel'" (displayDialogChange)="onCancelModalCancel($event)" [singleRow]="true">
</crm-form-modal>
<crm-info-modal *ngIf="displayInfoModal" [modalTitle]="sbInfoTitle" [modalContent]="sbInfoMessage"
  submitButtonlabel="OK" [displayDialog]="displayInfoModal" (displayDialogChange)="onInfoDisplayChange($event)">
</crm-info-modal>
<p-dialog [header]="'Missing Technical Infomation Alert'" [(visible)]="displayNoTechInfo" showeffect="fade"
  [modal]="true" [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <i class="pi pi-exclamation-triangle" [style.font-size]="'2em'"></i>
    <p>
      {{ technInfoMissingField }} missing in Technical Information Tab. Must populate details
      correctly to continue.
    </p>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="displayNoTechInfo=false" label="Cancel">
        OK
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [header]="'Missing Problem Report Cause Alert'" [(visible)]="displayNoPRCause" showeffect="fade"
  [modal]="true" [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <i class="pi pi-exclamation-triangle" [style.font-size]="'2em'"></i>
    <p>No Problem Report Cause Found. Must populate details to continue.</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="displayNoPRCause=false" label="Cancel">
        OK
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [header]="'Missing Problem Report Cause or Corrective Action Alert'" [(visible)]="displayNoPRCauseAndCA"
  showeffect="fade" [modal]="true" [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <i class="pi pi-exclamation-triangle" [style.font-size]="'2em'"></i>
    <p>
      {{ problemReportMissingField }} missing in Problem Report Tab . Must populate details
      correctly to continue.
    </p>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="displayNoPRCauseAndCA=false" label="Cancel">
        OK
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [header]="'Please Select Corrective Action Alert'" [(visible)]="displayNoCorrectiveAction" showeffect="fade"
  [modal]="true" [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <i class="pi pi-exclamation-triangle" [style.font-size]="'2em'"></i>
    <p>Please add Corrective Action and Note before changing the status.</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="displayNoCorrectiveAction=false" label="Cancel">
        OK
      </button>
    </div>
  </ng-template>
</p-dialog>
<problem-report-modal [displayDialog]="displayNoCorrectiveActionConfirm"
  (formValueChanged)="onCorrectiveAction($event)">
</problem-report-modal>