import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ServicerServiceInfo {
  constructor() {}
  getServiceInfo(): Observable<any[]> {
    return of([
      {
        id: 1,
        type: 'open',
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
      },
      {
        id: 2,
        type: 'close',
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
      },
    ]);
  }

  tranformServicerInfoForDisplay = (servicerInfo) => {
    const dataList = [
      {
        id: 1,
        type: 'open',
        sunday: servicerInfo.openSunday,
        monday: servicerInfo.openMonday,
        tuesday: servicerInfo.openTuesday,
        wednesday: servicerInfo.openWednesday,
        thursday: servicerInfo.openThursday,
        friday: servicerInfo.openFriday,
        saturday: servicerInfo.openSaturday,
      },
      {
        id: 2,
        type: 'close',
        sunday: servicerInfo.closeSunday,
        monday: servicerInfo.closeMonday,
        tuesday: servicerInfo.closeTuesday,
        wednesday: servicerInfo.closeWednesday,
        thursday: servicerInfo.closeThursday,
        friday: servicerInfo.closeFriday,
        saturday: servicerInfo.closeSaturday,
      },
    ];

    return dataList;
    // tslint:disable-next-line: semicolon
  };
}
