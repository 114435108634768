import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ServiceOrderService } from '../service-order.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ServiceOrderDataService } from '../service-order-data.service';
import { dateSubstring } from '../../../shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Component({
  selector: 'service-creditcard-information',
  templateUrl: 'creditcard-information-tab.template.html',
  styleUrls: ['creditcard-information-tab.scss'],
  providers: [FormFieldControlService],
})
export class ServiceCreditCardInfoComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  formFields: FormField<string>[] = [];
  allFormFields: FormField<string>[] = [];
  form: FormGroup;
  tabKey: string;
  creditCardInfo: any;
  currentClaimStatus: any;
  dispatchMethods: string[] = ['email', 'phone'];
  querySubscriptions: Subscription[] = [];
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private qcs: FormFieldControlService,
    private service: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private azureService: AzureLoginService,
    private tabHandlerService: TabHandlerService,
    private roleService: RoleAuthorizationService,
  ) {
    super();
    this.querySubscriptions.push(
      this.service.getCreditCardInfoTabFields().subscribe((data: FormField<string>[]) => {
        this.allFormFields = data;
        this.formFields = this.allFormFields;
      }),
    );
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.updateEditPermissions();
    this.form.get('partOrderNumber').setValidators(this.partOrderConditionalValidator);
    this.form.get('customerAddressVerified').setValidators(this.partOrderConditionalValidator);
    this.querySubscriptions.push(
      this.serviceOrderDataService.isCreditCardInfoAvailable.subscribe({
        next: (isCreditCardInfoAvailable) => {
          if (isCreditCardInfoAvailable) {
            const creditCardInfo =
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo;
            this.form.patchValue({
              companyName: creditCardInfo.companyName,
              nameCC: creditCardInfo.nameCC,
              providerAddress: creditCardInfo.providerAddress,
              phoneNumber: creditCardInfo.phoneNumber,
              initialFee: creditCardInfo.initialFee
                ? parseFloat(creditCardInfo.initialFee).toFixed(2)
                : '',
              poc: creditCardInfo.poc,
              dispatchMethod: {
                key: creditCardInfo.dispatchMethod,
                value: creditCardInfo.dispatchMethod,
              },
              email: creditCardInfo.dispatchMethod === 'Email' ? creditCardInfo.emailFax : '',
              phone: creditCardInfo.dispatchMethod === 'Phone' ? creditCardInfo.emailFax : '',
              partOrder: {
                key: creditCardInfo.partOrderNumber !== null ? 'Yes' : 'No',
                value: creditCardInfo.partOrderNumber !== null,
              },
              partOrderNumber: creditCardInfo.partOrderNumber,
              customerAddressVerified: {
                key: creditCardInfo.customerAddressVerified ? 'Yes' : 'No',
                value: creditCardInfo.customerAddressVerified,
              },
              cCProvidedDate: dateSubstring(creditCardInfo.cCProvidedDate),
              approvedAmount: creditCardInfo.approvedAmount
                ? parseFloat(creditCardInfo.approvedAmount).toFixed(2)
                : '',
              agentInitials: creditCardInfo.agentInitials,
            });
          }
        },
      }),
    );
    this.updateCreditCardInfo();
    this.querySubscriptions.push(
      this.tabHandlerService.selectedTab.subscribe({
        next: (tab) => {
          if (this.tabKey === tab) {
            // Update permissions when the loading is completed.
            this.updateEditPermissions();
            const partOrderValue =
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo.partOrder;
            this.updatePartOrderFields(partOrderValue);
          }
        },
      }),
    );
  }

  updateEditPermissions() {
    this.currentClaimStatus =
      this.serviceOrderDataService.serviceOrderDataModel.serviceOrderInfo.status;
    switch (this.currentClaimStatus) {
      case 'IN PROGRESS':
        this.serviceOrderDataService.isCreditCardInfoEditable =
          this.roleService.validateIfPermissionsExist({
            category: 'Credit Card',
            permission: 'In Progress',
            operation: 'EDIT',
            type: 'Code',
          });
        break;
      case 'PENDING DISPATCH':
        this.serviceOrderDataService.isCreditCardInfoEditable =
          this.roleService.validateIfPermissionsExist({
            category: 'Credit Card',
            permission: 'Pending Dispatch',
            operation: 'EDIT',
            type: 'Code',
          });
        break;
      case 'PENDING ACCEPTANCE':
        this.serviceOrderDataService.isCreditCardInfoEditable =
          this.roleService.validateIfPermissionsExist({
            category: 'Credit Card',
            permission: 'Pending Acceptance',
            operation: 'EDIT',
            type: 'Code',
          });
        break;
      default:
        this.serviceOrderDataService.isCreditCardInfoEditable =
          this.roleService.validateIfPermissionsExist({
            category: 'Credit Card',
            permission: 'Any Status',
            operation: 'EDIT',
            type: 'Code',
          });
        break;
    }
    if (!this.serviceOrderDataService.isCreditCardInfoEditable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  partOrderConditionalValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }
    const partOrderSelection = formControl.parent.get('partOrder').value;
    if (partOrderSelection.value) {
      return Validators.required(formControl);
    }
    return null;
  }

  updateCreditCardInfo() {
    const updatedInfo = this.form.getRawValue();
    Object.keys(updatedInfo).forEach((key) => {
      switch (key) {
        case 'dispatchMethod':
          this.querySubscriptions.push(
            this.form.get(key).valueChanges.subscribe((dispatchMethodChanged) => {
              const dispatchMethodValue = dispatchMethodChanged.value
                ? dispatchMethodChanged.value
                : 'Email';
              const otherMethods = this.dispatchMethods.filter(
                (method) => method !== dispatchMethodValue.toLowerCase(),
              );
              this.formFields = this.allFormFields.filter(
                (formField) => !otherMethods.includes(formField.key),
              );
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo[key] =
                dispatchMethodValue;
              this.serviceOrderDataService.isCreditCardInfoUpdated = this.form.dirty;
            }),
          );
          break;
        case 'partOrder':
          this.querySubscriptions.push(
            this.form.get('partOrder').valueChanges.subscribe((partOrderChanged) => {
              this.formFields.find((formField) => formField.key === 'partOrderNumber').required =
                partOrderChanged.value;
              this.formFields.find(
                (formField) => formField.key === 'customerAddressVerified',
              ).required = partOrderChanged.value;
              this.form.get('partOrderNumber').updateValueAndValidity();
              this.form.get('customerAddressVerified').updateValueAndValidity();
              this.updatePartOrderFields(partOrderChanged.value);
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo[key] =
                partOrderChanged.value;
              this.serviceOrderDataService.isCreditCardInfoUpdated = this.form.dirty;
            }),
          );
          break;
        case 'customerAddressVerified':
          this.querySubscriptions.push(
            this.form.get(key).valueChanges.subscribe((customerAddressVerified) => {
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo[key] =
                customerAddressVerified.value;
              this.serviceOrderDataService.isCreditCardInfoUpdated = this.form.dirty;
            }),
          );
          break;
        case 'initialFee':
        case 'approvedAmount':
          this.querySubscriptions.push(
            this.form.get(key).valueChanges.subscribe((keyValue) => {
              let currencyValue;
              if (keyValue) {
                currencyValue = keyValue.replace(/[^0-9\.]+/g, '');
                // remove multiple dots and round to 2 decimal points
                if (currencyValue.includes('.')) {
                  let index = 0;
                  currencyValue = currencyValue.replace(/\./g, (item) => (!index++ ? item : ''));
                  currencyValue =
                    currencyValue.split('.')[1].length > 2
                      ? parseFloat(currencyValue).toFixed(2)
                      : currencyValue;
                }
                this.form.get(key).setValue('$'.concat(currencyValue), { emitEvent: false });
              }
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo[key] =
                currencyValue;
              this.serviceOrderDataService.isCreditCardInfoUpdated = this.form.dirty;
            }),
          );
          break;
        default:
          this.querySubscriptions.push(
            this.form.get(key).valueChanges.subscribe((keyValue) => {
              this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo[key] = keyValue;
              this.serviceOrderDataService.isCreditCardInfoUpdated = this.form.dirty;
            }),
          );
          break;
      }
    });
  }

  changeEventDetection(event) {
    if (event.target.id === 'initialFee' || event.target.id === 'approvedAmount') {
      let currencyValue = event.target.value.replace('$', '');
      if (
        currencyValue &&
        (!currencyValue.includes('.') || currencyValue.split('.')[1].length !== 2)
      ) {
        currencyValue = parseFloat(currencyValue).toFixed(2);
        this.form.get(event.target.id).setValue('$'.concat(currencyValue), { emitEvent: false });
        this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo[event.target.id] =
          currencyValue;
      }
    }
  }

  updatePartOrderFields(partOrderValue) {
    if (partOrderValue) {
      if (this.serviceOrderDataService.isCreditCardInfoEditable) {
        this.form.get('partOrderNumber').enable();
        this.form.get('customerAddressVerified').enable();
      }
    } else {
      this.form.get('partOrderNumber').disable();
      this.form.get('partOrderNumber').setValue(null);
      this.form.get('customerAddressVerified').disable();
      this.form.get('customerAddressVerified').setValue(false);
    }
  }

  onSubmit() {}

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
