<div class="crm-container">
  <app-section-header [title]="'Accounting'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="form-row" *ngFor="let formField of formFields">
        <form-field [formField]="formField" [form]="form"></form-field>
      </div>
    </form>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>