<p-dialog
  [header]="modalTitle"
  [(visible)]="displayDialog"
  showeffect="fade"
  [modal]="true"
  [styleClass]="'add-dialog'"
  [blockScroll]="true"
>
  <div class="modal-content">
    <i class="pi pi-exclamation-triangle modal-style"></i>
    <p>{{modalContent}}</p>
  </div>
  <ng-template pTemplate="footer" class="modal-footer"
    ><div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Cancel">{{submitButtonlabel}}</button>
    </div>
  </ng-template>
</p-dialog>
