<div class="tab-spinner" *ngIf="spinnerType === 'regular'">
  <p>
    This a text for creating Max Dynamic Width, not for viewing purpose. If you are reading this please contact CSG
    representatives
    _______________________________________________________ _______________________________________________________ ___
    line end
  </p>
</div>
<div class="overlay" *ngIf="spinnerType === 'regular'">
  <p-progressSpinner styleClass="custome-spinner"></p-progressSpinner>
</div>
<div class="overlay" *ngIf="spinnerType === 'transparent'">
  <p-progressSpinner styleClass="custome-spinner"></p-progressSpinner>
</div>