import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { of } from 'rxjs';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';

const getClaimPaymentsResultsQuery = gql`
  query (
    $fileName: String
    $status: String
    $uploadedDate: Date
    $remittanceDate: Date
    $claimCount: Int
  ) {
    getClaimPaymentsResults(
      fileName: $fileName
      status: $status
      uploadedDate: $uploadedDate
      remittanceDate: $remittanceDate
      claimCount: $claimCount
    ) {
      claimPaymentsId
      claimPaymentsIdOriginal
      fileStatus
      uploadDate
      remittanceDate
      claimCount
      totalPaid
      addedTimeStamp
      insertUserName
      isDeleted
      fileName
    }
  }
`;

export const saveClaimPaymentNotificationQuery = gql`
  mutation (
    $claimPaymentsIdOriginal: Int
    $fileStatus: String
    $fileName: String
    $uploadDate: Date
    $remittanceDate: Date
    $claimCount: Int
    $totalPaid: String
    $insertUserName: String
    $isDeleted: Boolean
  ) {
    createUpdateClaimPayments(
      claimPayment: {
        claimPaymentsIdOriginal: $claimPaymentsIdOriginal
        fileStatus: $fileStatus
        fileName: $fileName
        uploadDate: $uploadDate
        remittanceDate: $remittanceDate
        claimCount: $claimCount
        totalPaid: $totalPaid
        insertUserName: $insertUserName
        isDeleted: $isDeleted
      }
    ) {
      claimPaymentsIdOriginal
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ServiceClaimPaymentService {
  constructor(private apollo: Apollo) {}

  getClaimPaymentsResults = (data) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimPaymentsResultsQuery,
      variables: { ...data },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  saveClaimPaymentNotification = (claimPayment) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: saveClaimPaymentNotificationQuery,
      variables: {
        ...claimPayment,
      },
      // tslint:disable-next-line: semicolon
    });

  getRemittanceFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'remittanceDate',
        label: 'Remittance Date',
        type: 'Date',
        required: true,
        order: 1,
      }),

      new TextboxField({
        key: 'totalPaid',
        label: 'Total Paid',
        required: true,
        order: 2,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
