<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid" *ngIf="!singleRow">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid" *ngIf="singleRow">
        <div class="col-10" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <button class="crm-button" type="submit" id="btn-submit-form" label="!form.valid" hidden="hidden"></button>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button label-btn" *ngIf="form.valid" for="btn-submit-form" (click)="onSubmit()"
        tabindex="0">{{submitButtonlabel}}</button>
      <button class="crm-button label-btn btn-disabled" *ngIf="!form.valid" tabindex="0">{{submitButtonlabel}}</button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">{{cancelButtonlabel}}</button>
    </div>
  </ng-template>
</p-dialog>