import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RateProfileService {
  constructor() {}
  getRateProfile(): Observable<any[]> {
    return of([
      {
        id: 1,
        name: 'X',
        product_types: 'X',
        status: 'Active',
        master_profile: 'X'
      },
      {
        id: 2,
        name: 'X',
        product_types: 'X',
        status: 'Active',
        master_profile: 'X'
      },
      {
        id: 3,
        name: 'X',
        product_types: 'X',
        status: 'Active',
        master_profile: 'X'
      }
    ]);
  }
}
