import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  contractDetailsFormQuery,
  contractMainTabQuery,
  soClaimsQuery,
  rateDetailsQuery,
  contractCoverageQuery,
  getCoveredProductQuery,
  saveContractProfileAndMainTab,
  saveContractCoveredProducts,
  contractCancellationQuery,
  getSubcategoryDescriptionListQuery
} from './contract-data-queries.constant';

@Injectable()
export class ContractService {
  constructor(private apollo: Apollo) {}

  /**
   * Properties start
   */
  public contractConfig = {
    searchParams: {
      id: null,
      customerId: null,
      data: null
    }
  };

  public contractDataModel = {
    leftBaseSection: null,
    mainTab: {
      customerInformation: null,
      addresses: null,
      coveredProducts: null,
      associatedContracts: null,
      generalFields: null,
      additionalInformation: null,
      coverageInformation: null,
      productTypeDescription: null
    },
    coverageInformation: null,
    rateDetails: null,
    documents: null,
    inquiry: null,
    soClaims: null,
    importContract: null,
    fileAttachments: [],
    salesFileName: null,
    cancelFileName: null,
    notes: [],
    mainTabPrev: {
      addresses: null
    },
    coveragePlanDetails: null
  };
  /**
   * Properties end
   */

  /**
   * Subscriber subjects start
   */
  public isMainTabDataAvailable = new Subject<boolean>();
  public onCoveredProductChange = new BehaviorSubject(undefined);
  public isContractDataAvailable = new Subject<boolean>();
  public fileDataAvailable = new BehaviorSubject(true);
  public isContractCancelled = new Subject<boolean>();
  public isEffectiveDateUpdated = new BehaviorSubject('');
  public isEnquiryNotesAdded = new Subject<any>();

  public isLoading = new BehaviorSubject(false);
  public setMainTabAvailability(value) {
    this.isMainTabDataAvailable.next(value);
  }

  /**
   * subscriber subjects end
   */

  /**
   * Methods start
   */
  getContractDetailsForm = (id, azureUserId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: contractDetailsFormQuery,
      variables: { id, azureUserId },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getContractMainTab = (id, azureUserId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: contractMainTabQuery,
      variables: { id, azureUserId },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getSoClaims = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: soClaimsQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getRateDetails = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: rateDetailsQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getContractCoverage = (searchParams, offset = 0, limit = 10) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: contractCoverageQuery,
      variables: {
        id: searchParams,
        offset,
        limit
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getCoveredProducts = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getCoveredProductQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  // mutations

  saveContractProfileAndMainTab = contractProfile =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: saveContractProfileAndMainTab,
      variables: {
        ...contractProfile,
        contractsIdOriginal: parseInt(contractProfile.contractsIdOriginal, 10),
        cultureCode: contractProfile.cultureCode ? contractProfile.cultureCode.value : '',
        addresses: [contractProfile.addresses]
      }
      // tslint:disable-next-line: semicolon
    });

  saveContractCoveredProducts = contractProfile =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: saveContractCoveredProducts,
      variables: {
        ...contractProfile,
        contractsIdOriginal: parseInt(contractProfile.contractsIdOriginal, 10),
        productsId: parseInt(contractProfile.productsId, 10),
        contractsId: parseInt(contractProfile.contractsId, 10),
        productNo: parseInt(contractProfile.productNo, 10),
        partsTerm: parseInt(contractProfile.partsTerm, 10),
        laborTerm: parseInt(contractProfile.laborTerm, 10),
        majorComponentTerm: parseInt(contractProfile.majorComponentTerm, 10),
        otherTerm: parseInt(contractProfile.otherTerm, 10),
        productsIdOriginal: parseInt(contractProfile.productsIdOriginal, 10),
        cultureCode: contractProfile.cultureCode ? contractProfile.cultureCode.value : '',
        addresses: [contractProfile.addresses]
      }
      // tslint:disable-next-line: semicolon
    });

  getContractCancellation = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: contractCancellationQuery,
      variables: { contractNumber: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getSubcategoryDescriptionList = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getSubcategoryDescriptionListQuery,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  /**
   * Methods end
   */
}
