import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from 'src/app/modules/crm/shared/constants/graphql-constants';

const getExpenseChargesQuery = gql`
  query($serviceOrderNumber: String!, $userId: String) {
    getExpenseChargesByClaim(serviceOrderNumber: $serviceOrderNumber) {
      claimNumber
      claimId
      expenseType
      description
      partNumber
      requestedPrice
      requestedMarkup
      requestedAmount
      adjustedPrice
      adjustedMarkup
      adjustedAmount
      discount
      status
      expenseChargesIdOriginal
      isDeleted
      modelNumber
      paidAmount
      serialNumber
      quantity
      serviceOrderNumber
      componentModifySettings(userId: $userId, componentType: "ExpenseCharges") {
        readOnly
        enableAdd
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ExpenseChargeService {
  constructor(private apollo: Apollo) {}

  getExpenseCharges = (serviceOrderNumber, userId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getExpenseChargesQuery,
      variables: { serviceOrderNumber, userId },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getRateProfile(): Observable<any[]> {
    return of([
      {
        id: 1,
        expenseType: 'type1',
        description: 'Main Brd',
        partNumber: 'WH12X93',
        modelNumber: '',
        serialNumber: '',
        quantity: '',
        requestedPrice: '270.66',
        requestedMarkup: '0.00',
        requestedAmount: '270.66',
        adjustedPrice: '270.66',
        adjustedMarkup: '0.00',
        adjustedAmount: '270.66',
        discount: '0.00',
        approvedAmount: '270.66',
        status: 'Paid'
      },
      {
        id: 2,
        expenseType: 'type1',
        description: 'Main Brd',
        partNumber: 'WH12X93',
        modelNumber: '',
        serialNumber: '',
        quantity: '',
        requestedPrice: '270.66',
        requestedMarkup: '0.00',
        requestedAmount: '270.66',
        adjustedPrice: '270.66',
        adjustedMarkup: '0.00',
        adjustedAmount: '270.66',
        discount: '0.00',
        approvedAmount: '270.66',
        status: 'Paid'
      }
    ]);
  }
}
