<div class="work-queue-container">
  <div class="sub-section">
    <div class="table-content">
      <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="admin">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{col.header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>