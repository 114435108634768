import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const defaultStatus = 'A';

const searchInsuranceCompanyQuery = gql`
  query(
    $status: String!
    $number: String
    $name: String
    $city: String
    $state: String
    $phoneNumber: String
  ) {
    getInsuranceCompanySearchResults(
      status: $status
      number: $number
      name: $name
      city: $city
      state: $state
      phoneNumber: $phoneNumber
    ) {
      insuranceComapaniesIdOriginal
      status
      number
      name
      city
      state
      phoneNumber
      type
    }
  }
`;
@Injectable()
export class SearchInsuranceCompanyService {
  constructor(private apollo: Apollo) {}

  getInsuranceCompanySearchResult = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: searchInsuranceCompanyQuery,
      variables: { ...searchParams, status: searchParams.status.value || defaultStatus }
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
