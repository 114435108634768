import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../contract.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { dateSubstring } from '../../shared/utilities/date-utilities';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { FormGroup } from '@angular/forms';
import _find from 'lodash/find';

@Component({
  selector: 'refund-information-tab',
  templateUrl: 'refund-information-tab.template.html',
  styleUrls: ['refund-information-tab.scss'],
  providers: [FormFieldControlService],
})
export class RefundInfomationTabComponent extends FormCanDeactivate implements OnInit {
  tabKey: string;
  querySubscription: any;
  isLoading: boolean;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  constructor(
    private contractService: ContractService,
    private tabHandlerService: TabHandlerService,
    private contractFormFieldService: ContractFormFieldService,
    private qcs: FormFieldControlService,
  ) {
    super();
  }

  ngOnInit() {
    this.contractFormFieldService.getContractCancellationTabFormFields().subscribe((formFields) => {
      this.formFields = formFields;
      this.form = this.qcs.toFormGroup(this.formFields);
    });

    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (
          this.tabKey === tab &&
          this.contractService.contractDataModel.leftBaseSection.contractNumber
        ) {
          this.loadRefundInformation();
        }
      },
    });
  }

  loadRefundInformation() {
    this.isLoading = true;
    this.querySubscription = this.contractService
      .getContractCancellation(
        this.contractService.contractDataModel.leftBaseSection.contractNumber,
      )
      .subscribe(
        ({ data, loading }: any) => {
          const cancellationData = data.getContractCancellation;
          if (cancellationData) {
            this.form.patchValue({
              contractNumber: cancellationData.contractNumber,
              contractId: cancellationData.contractId,
              customerCancellationDate: dateSubstring(cancellationData.customerCancellationDate),
              cancellationProcessDate: dateSubstring(cancellationData.cancellationProcessDate),
              cancellationTransactionDate: this.checkUnProcessedDate(
                cancellationData.cancellationTransactionDate,
              ),
              retailCost: cancellationData.retailCost,
              baseDealerCost: cancellationData.baseDealerCost,
              claimsApprovedPaid: cancellationData.claimsApprovedPaid,
              cancellationReason: cancellationData.cancellationReason,
              cancellationMethod: cancellationData.cancellationMethod,
              refundToCustomerSysGen: cancellationData.refundToCustomerSysGen,
              refundToCustomerOverride: cancellationData.refundToCustomerOverride,
              refundToCustomerAdjusted: cancellationData.refundToCustomerAdjusted,
              dealerRefundSysGen: cancellationData.dealerRefundSysGen,
              dealerRefundOverride: cancellationData.dealerRefundOverride,
              dealerRefundAdjusted:
                this.getFloat(cancellationData.dealerRefundAdjusted) > 0 &&
                cancellationData.dealerRefundAdjusted.toString() !== ''
                  ? cancellationData.dealerRefundAdjusted
                  : '$0.00',
              cancellationFee: cancellationData.cancellationFee,
              termMonth: cancellationData.termMonth,
              refundFactor: this.getRefundFactor(
                cancellationData.baseDealerCost,
                cancellationData.dealerRefundAdjusted,
                cancellationData.cancellationFee,
              ),
              startDate: dateSubstring(cancellationData.startDate),
              usedDays: cancellationData.usedDays,
              typeOfMethod: cancellationData.typeOfMethod,
              proRatePercentage: cancellationData.proRatePercentage,
              refundBeforeCancellation: cancellationData.refundBeforeCancellation,
              freeLookUpPeriod: cancellationData.freeLookUpPeriod,
              cancellationValue: cancellationData.cancellationValue,
              refundAfterCancellation: cancellationData.refundAfterCancellation,
              finalRefund: cancellationData.finalRefund,
              finalCancellationFee: cancellationData.finalCancellationFee,
              dealerExclusion: cancellationData.dealerExclusion,
              contractCancelationidOriginal: cancellationData.contractCancelationidOriginal,
              isDeleted: cancellationData.isDeleted,
            });
          }
          this.isLoading = loading;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  checkUnProcessedDate(transactionDate) {
    try {
      const dateStr = dateSubstring(transactionDate);
      if (dateStr === '1900-01-01') {
        return 'UNPROCESSED';
      }
      if (dateStr && dateStr.includes('-')) {
        const d = dateStr.split('-');
        return `${d[1]}/${d[2]}/${d[0]}`;
      }
      return dateStr;
    } catch (e) {
      return transactionDate;
    }
  }

  getKeyValue(array, key) {
    return _find(array, 'key', key);
  }

  onSubmit() {
    // functionality for on submit
  }

  private getFloat(value) {
    const rawString = value ? value.replace('$', '') : value;
    return parseFloat(rawString);
  }

  getRefundFactor(baseDealerCost, adj, canFee) {
    const mAdj = this.getFloat(adj);
    const mCanFee = this.getFloat(canFee);
    const mBaseDealerCost = this.getFloat(baseDealerCost);
    if ([mAdj, mCanFee, mBaseDealerCost].filter((el) => el !== 0).length > 0) {
      if (mBaseDealerCost !== 0) {
        const refundPercentage = ((mAdj + mCanFee) / mBaseDealerCost) * 100;
        return parseFloat(refundPercentage.toString()).toFixed(2) + '%';
      }
    }
    return '0%';
  }
}
