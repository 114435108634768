import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { SessionModalComponent } from './session-modal.component';
import { ModalModule } from '../modal/modal.module';

@NgModule({
  imports: [CommonModule, HttpClientModule, InlineSVGModule, ModalModule],
  exports: [SessionModalComponent],
  declarations: [SessionModalComponent],
})
export class SessionModalModule {}
