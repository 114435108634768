import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckBoxField } from '../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { TextboxSearchField } from '../../../shared/form-field/textbox-search';
import { FormField } from '../../../shared/form-field/form-field';
import { TextAreaField } from '../../../shared/form-field/textarea-field';
import { RichTextAreaField } from '../../../shared/form-field/richtextarea-field';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const templateQuery = gql`
  query {
    getEmailTemplateDetails(limit: 250) {
      emailTemplateName
      emailTemplateType
      emailTemplateMessageCode
      emailTemplateDescription
      emailTemplateLocation
      emailTemplateVariables
      emailTemplateVersion
      emailTemplateIsActive
      emailTemplateInsertDateTime
      emailTemplateUser
      includeSoAttachment
    }
  }
`;

export const LABEL_MAPPING = {
  serviceOrder: 'Service Order #',
  uniqueIdentifier: 'Unique ID',
  status: 'Status',
  contract: 'Contract',
  model: 'Model',
  serial: 'Serial #',
  dateResolved: 'Date Resolved',
  lastUpdateDate: 'Last Update Date',
  lastUpdatedBy: 'Last Updated By',
  servicerNumber: 'Servicer Number',
  servicerName: 'Servicer Name',
  servicerPhoneNumber1: 'Phone Number',
  servicerFax: 'Fax',
  servicerEmail: 'Email',
  name: 'Dealer Name',
  number: 'Dealer Code',
  tSelfServicing: 'Self Servicing',
  storeLocationName: 'Selling Retailer',
  buyingGroup: 'Buying Group',
  customerFirstName: 'Caller First Name',
  customerLastName: 'Caller Last Name',
  customerPhone: 'Primary Phone',
  phoneNumber2: 'Secondary Phone',
  customerFax: 'Fax',
  customerEmail: 'Email',
  guardian: 'Guardian',
  address1: 'Address Line 1',
  address2: 'Address Line 2',
  state: 'State',
  city: 'City',
  zipCode: 'Zip Code',
  country: 'Country',
  isBusinessLocation: 'Business',
  fulfilled: 'Fulfilled',
  modelName: 'Model',
  serialNumber: 'Serial Number',
  type: 'Type',
  productPurchasePrice: 'Product Purchase Price',
  productPurchaseDate: 'Product Purchase Date',
  installDate: 'Install Date',
  categoryDescription: 'Category',
  subcategoryDescription: 'Subcategory',
  tierDescription: 'Tier',
  partsTerm: 'Parts Term',
  laborTerm: 'Labor Term',
  majorComponentTerm: 'Major Component Term',
  otherTerm: 'Other Term',
  serviceLocation: 'Service Location',
  manufacturerManufacturer: 'Manufacturer',
  deductibleCollectedBy: 'Deductible Collected By',
  productCondition: 'Condition',
};

@Injectable()
export class SendEmailModalService {
  templateDetails = {};
  typeMapping = {};

  constructor(private apollo: Apollo) {}

  getSendEmailFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        required: false,
        key: 'sendfollowupemail',
        label: 'Send Follow Up Email',
        value: { key: 'Yes', value: 'Yes' },
        options: [
          { key: 'No', value: 'No' },
          { key: 'Yes', value: 'Yes' },
        ],
        isSingleselect: true,
        order: 1,
      }),
      new DropdownField({
        required: true,
        key: 'type',
        label: 'Type ',
        value: { key: 'Dispatch', value: 'Dispatch' },
        options: [
          { key: 'Customer Dispatch', value: 'Customer Dispatch' },
          { key: 'Dispatch', value: 'Dispatch' },
        ],
        isSingleselect: true,
        order: 2,
      }),
      new DropdownField({
        required: true,
        key: 'code',
        label: 'Code',
        options: [{ key: 'standardDispatch', value: 'Standard Dispatch' }],
        isSingleselect: true,
        order: 3,
      }),
      new TextboxField({
        key: 'ccEmailAddress',
        label: 'CC Email Address',
        order: 4,
      }),
      new TextboxField({
        required: true,
        key: 'toEmailAddress',
        label: 'To Email Address',
        order: 5,
      }),
      new RichTextAreaField({
        key: 'customText',
        label: 'Custom Text',
        rows: 6,
        order: 6,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getTemplateDetails = () =>
    this.apollo.use('crm').query<any>({
      query: templateQuery,
      variables: {},
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
}
