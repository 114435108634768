import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../../services/form-field-control.service';
import { FormField } from '../../../../../shared/form-field/form-field';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'rate-form-section',
  templateUrl: 'rate-form.template.html',
  providers: [FormFieldControlService],
})
export class RateFormComponent implements OnInit, OnChanges {
  @Input() formFields: FormField<string>[] = [];
  @Input() isSubmit: boolean;

  form: FormGroup;
  payLoad = '';

  constructor(
    private qcs: FormFieldControlService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  public ngOnChanges(changes: SimpleChanges) {
    console.log('changes.strings.currentValue', changes.strings);
    // if(changes.isSubmit.currentValue) {
    //   this.onSubmit();
    // }
  }

  onSubmit() {
    console.log(this.form.getRawValue());
    const createRequest = this.form.value;
  }
}
