import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Observable, of } from 'rxjs';
import { ServiceTypeModalService } from './service-type-modal.service';
import { ServicerService } from '../../servicer.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { getStringFromDate } from '../../../../shared/utilities/date-utilities';
import moment from 'moment';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
@Component({
  selector: 'service-type-modal',
  templateUrl: 'service-type-modal.template.html',
  styleUrls: ['./service-type-modal.scss'],
  providers: [FormFieldControlService, ServiceTypeModalService, MessageService],
})
export class ServiceTypeModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Output() formSubmitEvent = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  displayEmailModal: true;
  isLoading = true;
  querySubscription: Subscription;
  validForm = true;
  templateResponse = null;
  selectedRowIndex: number;
  dataList: any[] = [];
  modelNames = [];
  selectedItem: any;
  isTableLoading: boolean;
  subcategoryDescription: [];
  tierDescription: [];
  defaultOption = { key: 'ALL', value: 'ALL' };
  querySubscriptions: Subscription[] = [];
  constructor(
    private qcs: FormFieldControlService,
    private serviceTypeModalService: ServiceTypeModalService,
    private servicerService: ServicerService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
  ) {
    this.serviceTypeModalService.getServicerFormFields().subscribe((data) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.formData && this.formData.categoryDescription) {
      const selectedFormCategoryList = this.formData.categoryDescription.split(',');
      const selectedFormSubCategoryList = this.formData.subcategoryDescription.split(',');
      const selectedFormTierList = this.formData.tierDescription.split(',');
      const selectedFormManufacturerList = this.formData.manufacturerName.split(',');
      const selectedFormModelList = this.formData.modelName.split(',');
      const mappedCategoryDescription = selectedFormCategoryList.map(item => ({
        key: item,
        value: item
      }));
      const mappedSubCategoryDescription = selectedFormSubCategoryList.map(item => ({
        key: item,
        value: item
      }));
      const mappedManufacturer = selectedFormManufacturerList.map(item => ({
        key: item,
        value: item
      }));
      const mappedTierDescription = selectedFormTierList.map(item => ({
        key: item,
        value: item
      }));
      const mappedModelName = selectedFormModelList.map(item => ({
        key: item,
        value: item
      }));
      this.form.patchValue({
        ...this.formData,
        categoryDescription: mappedCategoryDescription
      ,
        subcategoryDescription: mappedSubCategoryDescription,
        tierDescription: mappedTierDescription,
        type: { key: this.formData.type, value: this.formData.type },
        manufacturerName: mappedManufacturer,
        modelName: mappedModelName,
        coverageCode: this.formData.coverageCode,
        beginDate: getStringFromDate(this.formData.beginDate),
        endDate: this.formData.endDate ? getStringFromDate(this.formData.endDate) : null,
        isActive: {
          key: this.formData.isActive ? 'Yes' : 'No',
          value: this.formData.isActive ? 'Yes' : 'No',
        },
        isMFGAuthorized: {
          key: this.formData.isMFGAuthorized ? 'Yes' : 'No',
          value: this.formData.isMFGAuthorized ? 'Yes' : 'No',
        },
      });
      this.getSubCatByCategory(this.formData.categoryDescription);
      this.getTierByCategory(this.formData.categoryDescription);
      this.getModelNumbersByMfg(this.formData.manufacturerName);
    }
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.formSubmitEvent.emit(false);
  }

  onChangeManufacturer(event) {
    const manufacturer = event.value.map(category => category.value);
    this.getModelNumbersByMfg(manufacturer, true);
  }

  onChangeDate(event) {
    return false;
  }

  onChangeCategory(event) {
    const categoryDescription = event.value.map(category => category.value);
    this.getSubCatByCategory(categoryDescription, true);
    this.getTierByCategory(categoryDescription, true);
  }

  formatData(item) {
    return [
      {
        categoryDescription: item.categoryDescription,
        subcategoryDescription: item.subcategoryDescription,
        tierDescription: item.tierDescription,
        type: item.type,
        manufacturerName: item.manufacturerName,
        modelName: item.modelName,
        coverageCode: item.coverageCode,
        beginDate: item.beginDate,
        endDate: item.endDate,
        isActive: item.isActive ? 'Yes' : 'No',
        isMFGAuthorized: item.isMFGAuthorized ? 'Yes' : 'No',
      },
    ];
  }

  // Validate dates.
  validateDates(rowData) {
    const errField = [];
    const errObj = { isValid: true, errMessage: '' };
    const endDate = moment(rowData.endDate);
    const startDate = moment(rowData.beginDate);
    if (endDate < startDate) {
      errField.push(' END DATE should be greater than BEGIN DATE.');
    }
    if (errField.length > 0) {
      errObj.isValid = false;
      errObj.errMessage = `${errField.join()}`;
    }
    return errObj;
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    if (this.form.valid) {
      payload.serviceTypesIdOriginal =
        this.formData && this.formData.serviceTypesIdOriginal
          ? this.formData.serviceTypesIdOriginal
          : null;
      payload.servicerId = this.servicerService.serviceFormData.servicersId;
      payload.servicerNumber = this.servicerService.serviceFormData.servicerNumber;
      payload.categoryDescription = payload.categoryDescription.map(x => x.value).join();
      payload.subcategoryDescription = payload.subcategoryDescription.map(x => x.value).join();
      payload.tierDescription = payload.tierDescription.map(x => x.value).join();
      payload.type = payload.type.value;
      payload.manufacturerName = payload.manufacturerName.map(x => x.value).join();
      payload.modelName = payload.modelName.map(x => x.value).join();
      payload.isActive = payload.isActive.value === 'Yes' ? true : false;
      payload.isMFGAuthorized =
        payload.isMFGAuthorized.value === 'Yes' ||
        payload.isMFGAuthorized.value === null ||
        payload.isMFGAuthorized.value === undefined
          ? true
          : false;
      payload.insertUserName = this.azureService.accountId;
      const errObj = this.validateDates(payload);
      if (errObj.isValid) {
        const lastOffsetVal = 0;
        const offsetLimit = 1000;
        this.querySubscriptions.push(
          this.servicerService
            .getServicerServiceTypes(
              this.servicerService.serviceFormData.servicerNumber,
              lastOffsetVal,
              offsetLimit,
            )
            .subscribe((result) => {
              const servicerData = result.data;
              if (servicerData && servicerData.getServicerServiceTypesByNumber) {
                const modiftData = copy(servicerData.getServicerServiceTypesByNumber);
                const servicerTypePartsVendorAvail = modiftData.find(
                  (x) => x.type === 'Parts Vendor',
                );
                const partsVendorAvail = servicerTypePartsVendorAvail ? true : false;
                if (
                  (partsVendorAvail &&
                    servicerTypePartsVendorAvail.serviceTypesIdOriginal !==
                      payload.serviceTypesIdOriginal) ||
                  (!partsVendorAvail && modiftData.length > 1 && payload.type === 'Parts Vendor')
                ) {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'error',
                    detail: 'Service Type already exists',
                  });
                } else {
                  this.createUpdateServiceTypes(payload);
                }
              } else {
                this.createUpdateServiceTypes(payload);
              }
            }),
        );
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: errObj.errMessage,
        });
      }
    }
  }

  createUpdateServiceTypes(payload) {
    this.querySubscription = this.servicerService
      .createUpdateServicerTypes({ ...payload })
      .subscribe((data: any) => {
        this.isLoading = false;
        const response = data.data.createUpdateServicerTypes;
        if (response) {
          const item = this.formatData(response);
          item[`isNew`] = false;
          this.dataList[this.selectedRowIndex] = item;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'successMessage',
          });
          this.formSubmitEvent.emit(true);
          this.displayDialog = false;
          this.displayDialogChange.emit(this.displayDialog);
        }
      });
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  getModelNumbersByMfg(manufacturerName, isChangeEvent = false) {
    this.isLoading = true;
    const isManufacturerList = manufacturerName.includes(',');
    let manufacturersName;
    if (!isManufacturerList) {
      if (Array.isArray(manufacturerName) && manufacturerName.length > 0) {
        const manufacturerItems = [manufacturerName.join()];
        manufacturersName = manufacturerName.length > 1 ? manufacturerItems[0] : manufacturerName[0];
      } else if (manufacturerName.length === 0) {
        manufacturersName = "";
      } else {
        manufacturersName = manufacturerName;
      }
    } else {
      manufacturersName = manufacturerName;
    }
    this.serviceTypeModalService.getModelNumbersByMfg(manufacturersName).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.servicerService.isLoading.next(false);
        this.modelNames = data.getServicerServiceAreaModelNumber;
        let modelOptions =
          this.modelNames && this.modelNames.length > 0
            ? this.modelNames.map((el) => {
              return {
                key: el,
                value: el,
              };
            })
            : [];
        modelOptions = [{ key: 'ALL', value: 'ALL' }, ...modelOptions];
        this.formFields[5].options = modelOptions;
        if (!isChangeEvent) {
          const modelList = this.selectedItem && this.selectedItem.modelName.split('|');
          if (modelList) {
            const modelSelectedList = modelList.map((e) => ({
              key: e.trim(),
              value: e.trim(),
            }));
            this.form.patchValue({
              ...this.selectedItem,
              manufacturerName: {
                key: this.selectedItem.manufacturerName,
                value: this.selectedItem.manufacturerName,
              },
              modelName: modelSelectedList,
            });
          }
        } else {
          const mappedManufacturerName = manufacturerName.map(item => ({
            key: item,
            value: item
          }));
          this.form.patchValue({
            ...this.selectedItem,
            manufacturerName: mappedManufacturerName,
            modelName: [],
          });
        }
        this.displayDialog = true;
      },
      (err) => {
        this.servicerService.isLoading.next(false);
        throw err;
      },
    );
  }

  getSubCatByCategory(categoryDescription, isChangeEvent = false) {
    this.isLoading = true;
    const isCategoryList = categoryDescription.includes(',');
    let categoryDescList;
    if (!isCategoryList) {
      if (Array.isArray(categoryDescription) && categoryDescription.length > 0) {
        const categoryDescItems = [categoryDescription.join()];
        categoryDescList = categoryDescription.length > 1 ? categoryDescItems[0] : categoryDescription[0];
      } else if (categoryDescription.length === 0) {
        categoryDescList = "";
      } else {
        categoryDescList = categoryDescription;
      }
    } else {
      categoryDescList = categoryDescription;
    }
    this.servicerService.getServicerSubListByCategory(categoryDescList).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.servicerService.isLoading.next(false);
        this.subcategoryDescription = data.getSubcategoryDescriptionListByCategory;
        let subcategoryDescriptionOptions = [];
        subcategoryDescriptionOptions =
          this.subcategoryDescription && this.subcategoryDescription.length > 0
            ? this.subcategoryDescription.map((el) => {
              return {
                key: el,
                value: el,
              };
            })
            : [];
        subcategoryDescriptionOptions.unshift(this.defaultOption);
        this.formFields[1].options = subcategoryDescriptionOptions;
        if (!isChangeEvent) {
          const subcategoryDescriptionList =
            this.selectedItem && this.selectedItem.subcategoryDescription.split('|');
          if (subcategoryDescriptionList) {
            const subcategoryDescriptionSelectedList = subcategoryDescriptionList.map((e) => ({
              key: e.trim(),
              value: e.trim(),
            }));
            this.form.patchValue({
              ...this.selectedItem,
              categoryDescription:
              {
                key: this.selectedItem.categoryDescription,
                value: this.selectedItem.categoryDescription,
              }
              ,
              subcategoryDescription: subcategoryDescriptionSelectedList,
            });
          }
        } else {
          const mappedCategoryDesc = categoryDescription.map(item => ({
            key: item,
            value: item
          }));
          this.form.patchValue({
            ...this.selectedItem,
            categoryDescription: mappedCategoryDesc,
            subcategoryDescription: [],
          });
        }
        this.displayDialog = true;
      },
      (err) => {
        this.servicerService.isLoading.next(false);
        throw err;
      },
    );
  }

  getTierByCategory(categoryDescription, isChangeEvent = false) {
    this.isLoading = true;
    const isCategoryList = categoryDescription.includes(',');
    let categoryDescList;
    if (!isCategoryList) {
      if (Array.isArray(categoryDescription) && categoryDescription.length > 0) {
        const categoryDescItems = [categoryDescription.join()];
        categoryDescList = categoryDescription.length > 1 ? categoryDescItems[0] : categoryDescription[0];
      } else if (categoryDescription.length === 0) {
        categoryDescList = "";
      } else {
        categoryDescList = categoryDescription;
      }
    } else {
      categoryDescList = categoryDescription;
    }
    this.servicerService.getServicerSubListByCategory(categoryDescList).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.servicerService.isLoading.next(false);
        this.tierDescription = data.getTierDescriptionListByCategory;
        let tierDescriptionOptions = [];
        tierDescriptionOptions =
          this.tierDescription && this.tierDescription.length > 0
            ? this.tierDescription.map((el) => {
              return {
                key: el,
                value: el,
              };
            })
            : [];
        tierDescriptionOptions.unshift(this.defaultOption);
        this.formFields[2].options = tierDescriptionOptions;
        if (!isChangeEvent) {
          const tierDescriptionList =
            this.selectedItem && this.selectedItem.tierDescription.split('|');
          if (tierDescriptionList) {
            const tierDescriptionSelectedList = tierDescriptionList.map((e) => ({
              key: e.trim(),
              value: e.trim(),
            }));
            this.form.patchValue({
              ...this.selectedItem,
              categoryDescription:
              {
                key: this.selectedItem.categoryDescription,
                value: this.selectedItem.categoryDescription,
              }
              ,
              tierDescription: tierDescriptionSelectedList,
            });
          }
        } else {
          const mappedCategoryDesc = categoryDescription.map(item => ({
            key: item,
            value: item
          }))
          this.form.patchValue({
            ...this.selectedItem,
            categoryDescription: mappedCategoryDesc,
            tierDescription: [],
          });
        }
        this.displayDialog = true;
      },
      (err) => {
        this.servicerService.isLoading.next(false);
        throw err;
      },
    );
  }

  populateList(listData) {
    const arr = [];
    listData.forEach((element) => {
      arr.push({ key: element, value: element });
    });
    return arr;
  }
}
