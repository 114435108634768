import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatingService } from './rating.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisHeaderOptions,
  coverageEllipsisOptions,
  coverageEllipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { FormFieldControlService } from 'src/app/modules/crm/services/form-field-control.service';
import { FormField } from 'src/app/modules/crm/shared/form-field/form-field';
import { CoverageFormFieldService } from '../../../coverage-form-field.service';
import { FormGroup } from '@angular/forms';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { CoverageService } from '../../coverage.service';
import { MessageService } from 'primeng/api';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { RolePermissionService } from 'src/app/modules/crm/common/role-permission.service';

@Component({
  selector: 'cv-rating',
  templateUrl: 'rating.template.html',
  styleUrls: ['rating.scss'],
  providers: [RatingService, FormFieldControlService, CoverageFormFieldService, MessageService],
})
export class RatingComponent extends FormCanDeactivate implements OnInit {
  coveredRatesList: any[];
  deducibleList: any[];
  coveredRatesCols: any[];
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  coverageRatings: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptions: any[] = coverageEllipsisOptions;
  ellipsisOptionsSec: any;

  coverageEllipsisHeaderOptions: any[] = coverageEllipsisHeaderOptions;
  isEditing: boolean;
  isLoading: boolean;
  displayRatingModal: boolean;
  editConstant: string;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  constructor(
    private ratingService: RatingService,
    private qcs: FormFieldControlService,
    private coverageService: CoverageService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.coveredRatesCols = [
      { field: 'term', header: 'TERM', type: 'text' },
      { field: 'admin', header: 'ADMIN', type: 'text' },
      { field: 'reserve', header: 'RESERVE', type: 'text' },
      { field: 'commission', header: 'COMMISSION', type: 'text' },
      { field: 'total', header: 'TOTAL', type: 'text' },
      { field: 'suggestedTotal', header: 'SUGGESTED TOTAL', type: 'text' },
    ];
    this.coverageService.isMainTabDataAvailable.subscribe(() => {
      this.onRatingUpdate(false);
    });
    this.ratingService.getRatingFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.form = this.qcs.toFormGroup(this.formFields);
    });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT',
      permission: 'Override Rates Bucket',
      category: 'Contract',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.displayRatingModal = true;
  }

  onRatingUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
    }
    this.displayRatingModal = false;
    this.isLoading = true;
    this.ratingService
      .getRatingsSearch(this.coverageService.coverageDataModel.coverageCode)
      .subscribe(({ data }) => {
        this.isLoading = false;
        const ratingData = {
          term: data.getRatingsSearch.term,
          admin: '$' + data.getRatingsSearch.admin.toFixed(2),
          reserve: '$' + data.getRatingsSearch.reserve.toFixed(2),
          commission: '$' + data.getRatingsSearch.commission.toFixed(2),
          total: '$' + data.getRatingsSearch.total.toFixed(2),
          suggestedTotal: '$' + data.getRatingsSearch.suggestedTotal.toFixed(2),
        };
        this.coverageRatings = data.getRatingsSearch.coverageRates;
        this.coveredRatesList = [ratingData];
        this.form.patchValue({
          primaryRatingProvider: {
            key: data.getRatingsSearch.primaryRatingProvider,
            value: data.getRatingsSearch.primaryRatingProvider,
          },
        });
      });
  }

  onSubmit() {}
}
