<div class="crm-container">
  <app-section-header [title]="'Cancellation Methods Results'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input"><input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /></ng-template><ng-template *ngIf="col.type === 'text'"
                pTemplate="output">{{rowData[col.field]}}</ng-template><ng-template *ngIf="col.type === 'checkbox'"
                pTemplate="output"><input type="checkbox" [(ngModel)]="rowData[col.field]"
                  disabled="disabled" /></ng-template></p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,opRow, ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button><button class="ui-button-success" *ngIf="editing"
              pButton="pButton" type="button" pSaveEditableRow="pSaveEditableRow" icon="pi pi-check"
              style="margin-right: 0.5em" (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger"
              *ngIf="editing" pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #opRow><p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-overlayPanel
  #opHeader><p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-dialog
  [header]="'Add Cancellation Method'" [(visible)]="displayDialog" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="addNewRowItem()" [formGroup]="form">
      <div class="grid">
        <div class="col-12" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <button class="crm-button" type="submit" id="btn-submit-form" label="!form.valid" hidden="hidden"></button>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button label-btn" *ngIf="form.valid" (click)="addNewRowItem()" for="btn-submit-form" tabindex="0">Add</button>
      <button class="crm-button label-btn btn-disabled" *ngIf="!form.valid" tabindex="0">Add</button>
      <button class="crm-button" type="button" (click)="displayDialog = false" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>