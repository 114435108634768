import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import { Subject } from 'rxjs';
import {
  getBuyingGroupMainTabQuery,
  getBuyingGroupProfileByIdQuery,
  createUpdateBuyingGroupQuery
} from './buying-group-data-queries.constant';

@Injectable()
export class BuyingGroupDataService {
  public buyingGroupFormData: any;
  constructor(private apollo: Apollo) {}

  /**
   * Properties start
   */
  public buyingGroupConfig = {
    searchParams: {
      id: null
    }
  };

  public buyingGroupDataModel = {
    leftBaseSection: null,
    mainTab: {
      customerInformation: null,
      maillingAddresses: null,
      billingAddresses: null
    },
    documents: null,
    contact: null
  };
  /**
   * Properties end
   */

  /**
   * Subscriber subjects start
   */
  public isMainTabDataAvailable = new Subject<boolean>();
  public isAgentIdOriginalAvailable = new Subject<boolean>();
  public setMainTabAvailability(value) {
    this.isMainTabDataAvailable.next(value);
  }

  /**
   * subscriber subjects end
   */

  /**
   * Methods start
   */
  getBuyingGroupProfileById = (id, bgNumber = null) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getBuyingGroupProfileByIdQuery,
      variables: { id, number: bgNumber },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getBuyingGroupMainTab = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getBuyingGroupMainTabQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  /**
   * Methods end
   */

  createUpdateBuyingGroup = formData => {
    const addressMailing = {
      ...this.buyingGroupDataModel.mainTab.maillingAddresses,
      isMailingAddress: true,
      country: this.buyingGroupDataModel.mainTab.maillingAddresses.country || 'United States'
    };
    const addressBilling = {
      ...this.buyingGroupDataModel.mainTab.billingAddresses,
      isBillingAddress: true,
      country: this.buyingGroupDataModel.mainTab.billingAddresses.country || 'United States'
    };

    const contactinfo = { ...this.buyingGroupDataModel.mainTab.customerInformation };
    contactinfo.businessPhoneTypeCode =
      contactinfo.businessPhoneTypeCode && contactinfo.businessPhoneTypeCode.value
        ? contactinfo.businessPhoneTypeCode.value
        : null;

    delete addressMailing.__typename;
    delete addressBilling.__typename;
    delete contactinfo.__typename;
    delete addressBilling.valid;
    delete addressMailing.valid;
    delete contactinfo.valid;

    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateBuyingGroupQuery,
      variables: {
        status: formData.status ? formData.status.value : 'A',
        number: formData.number,
        name: formData.name,
        fedTaxId: formData.fedTaxId,
        agentType: formData.agentType,
        sProfileName: null,
        activationDate: formData.activationDate,
        expirationDate: formData.expirationDate,
        accountingNumber: formData.accountingNumber,
        masterBuyingGroupId: formData.masterBuyingGroup ? formData.masterBuyingGroup.value : null,
        agentIdOriginal: formData.agentIdOriginal,
        addresses: [addressMailing, addressBilling],
        contactInfo: contactinfo,
        priceTableName: formData.priceTableName.value,
        insertUserName: formData.insertUserName
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };

  formValidate = formData => {
    return (
      formData.valid &&
      this.buyingGroupDataModel.mainTab.billingAddresses &&
      this.buyingGroupDataModel.mainTab.maillingAddresses &&
      this.buyingGroupDataModel.mainTab.customerInformation &&
      this.buyingGroupDataModel.mainTab.billingAddresses.valid &&
      this.buyingGroupDataModel.mainTab.maillingAddresses.valid &&
      this.buyingGroupDataModel.mainTab.customerInformation.valid
    );
    // tslint:disable-next-line: semicolon
  };
}
