<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; width:100%;height: 100%;"></app-tab-spinner>
<div class="form-details-container">
  <div class="form-details-title">
    <div class="form-thumnail"></div>
    <div class="form-name">
      <input disabled="disabled" value="Buying Group Profile" />
    </div>
    <div class="last-cell">
      <div (click)="selectItem($event,'item',op3)" class="three-dots-heading">
        <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
      </div>
    </div>
    <p-overlayPanel #op3>
      <p-listbox [options]="options"></p-listbox>
    </p-overlayPanel>
  </div>
  <div class="form-details-body">
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div *ngFor="let formField of formFields" class="form-row">
          <form-field [formField]="formField" [form]="form" (changeEvent)="onChangeEvent($event, formField.key)"></form-field>
        </div>
        <div class="button-bar">
          <button>Cancel</button>
          <button
            *appSetRolePermissionsDirective="{type:'button', operation:'ADD', permission:'Buying Group', category: 'Agent'}"
            [disabled]="!form.valid"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
      <p-message *ngIf="isSuccess" severity="success" text="Record Saved"></p-message>
      <p-message *ngIf="isFailed" severity="error" text="Please fill required fields"></p-message>
      <p-toast></p-toast>
    </div>
  </div>
</div>
