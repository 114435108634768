import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ServiceOrderService } from '../../service-order.service';
import {
  ellipsisHeaderOptions,
  ellipsisCrudOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { Table } from 'primeng/table';
import { v4 as uuidv4 } from 'uuid';
import { KeyMap } from 'src/app/modules/crm/shared/interface/key-map.interface';
import { buttonStatus } from 'src/app/modules/crm/shared/constants/button-class';
import { ExpenseCharges } from '../expense-charges/expense-charges';
import { Problem } from './problem';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfigurationTypeService } from 'src/app/modules/crm/administration/configuration/configuration.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { SYSTEM_USER_ROLES } from 'src/app/modules/crm/shared/constants/user-roles';

const DENIED_STATUS_ROLES: string[] = [
  SYSTEM_USER_ROLES.ACCOUNT_MANAGER,
  SYSTEM_USER_ROLES.ACCOUNTING,
  SYSTEM_USER_ROLES.ACCOUNTING_MANAGER,
  SYSTEM_USER_ROLES.ADMIN,
  SYSTEM_USER_ROLES.BACK_OFFICE,
  SYSTEM_USER_ROLES.BACK_OFFICE_LEAD,
  SYSTEM_USER_ROLES.CALL_CENTER_MANAGER,
  SYSTEM_USER_ROLES.CALL_CENTER_SUPERVISOR,
  SYSTEM_USER_ROLES.CLAIMS_1,
  SYSTEM_USER_ROLES.CLAIMS_1_PLUS,
  SYSTEM_USER_ROLES.CLAIMS_MANAGER,
  SYSTEM_USER_ROLES.CSR_1,
  SYSTEM_USER_ROLES.CSR_2,
  SYSTEM_USER_ROLES.CSR_2_PLUS,
  SYSTEM_USER_ROLES.CSR_3,
  SYSTEM_USER_ROLES.DATA_ADMIN_LIMITED,
  SYSTEM_USER_ROLES.DATA_ADMINISTRATION,
  SYSTEM_USER_ROLES.SPECIAL_TEAM_A_CC_SN,
];

@Component({
  selector: 'problem-report',
  templateUrl: './problem-report.component.html',
  styleUrls: ['./problem-report.scss'],
  providers: [MessageService],
})
export class ProblemReportComponent implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  // Need a copy of the objects within the array so we do not update those by reference for other uses.
  ellipsisOptions: any[] = ellipsisCrudOptions.map((object) => ({ ...object }));
  ellipsisHeaderOptions: any[] = ellipsisHeaderOptions.map((object) => ({ ...object }));
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  dropdown: any;
  totalRequestedAmount: any;
  totalAdjustedAmount: any;
  totalDiscount: any;
  totalApprovedAmount: any;
  isEditEnable = true;
  isDeniedStatus = false;
  caDDOptions: any = {
    normal: [],
    denied: [
      { label: 'Choose', value: '' },
      { label: 'MFG Coverage Denial', value: 'MFG Coverage Denial' },
      { label: 'Self-Servicing Dealer Denial', value: 'Self-Servicing Dealer Denial' },
      { label: 'Recall Claim Denial', value: 'Recall Claim Denial' },
      { label: 'Terms & Conditions Denial', value: 'Terms & Conditions Denial' },
    ],
  };

  isDeniedListAllowed = false;

  querySubscriptions: Subscription[] = [];
  @ViewChild(Table) table: Table;

  dataList = [
    {
      id: 0,
      verticalTitle: 'FAILURE',
      value: this.serviceOrderDataService.standardProblemDataModel.symptom,
      note: '',
    },
    {
      id: 1,
      verticalTitle: 'CAUSE',
      value: '',
      note: '',
    },
    {
      id: 2,
      verticalTitle: 'CORRECTIVE ACTION',
      value: '',
      note: '',
    },
  ];
  constructor(
    private serviceOrderService: ServiceOrderService,
    private cdr: ChangeDetectorRef,
    private serviceOrderDataService: ServiceOrderDataService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private roleService: RoleAuthorizationService,
    private configurationService: ConfigurationTypeService,
  ) {}

  ngOnInit() {
    this.isDeniedListAllowed = DENIED_STATUS_ROLES.includes(this.roleService.userRole);
    this.editConstant = uuidv4();
    this.querySubscriptions.push(
      this.serviceOrderDataService.isServiceOrderInfoAvailable.subscribe({
        next: (isServiceOrderInfoAvailable) => {
          if (
            isServiceOrderInfoAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave
          ) {
            this.ellipsisOptions
              .filter((c) => c.label === 'Update' || c.label === 'Delete')
              .forEach((x) => (x.disabled = true));
          }
        },
      }),
    );
    // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
    // No user can edit the details anymore(PD-907057)
    this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
      this.isEditEnable = isEnable;
      this.ellipsisOptions
        .filter((c) => c.label === 'Update' || c.label === 'Delete')
        .forEach((x) => (x.disabled = !this.isEditEnable));
    });
    this.dropdown = {
      correctiveAction: [],
      cause: [],
    };
    this.checkStatusDenied();
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          if (isClaimDataAvailable) {
            const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
            if (claimData && claimData.claimStatusDescription === 'DENIED') {
              this.isDeniedStatus = true;
              this.updateCorrectiveActionOptions(true);
            }
            // Default the data for new or empty Problem Reports
            this.dataList = [
              {
                id: 0,
                verticalTitle: 'FAILURE',
                value: this.serviceOrderDataService.standardProblemDataModel.symptom,
                note: null,
              },
              {
                id: 1,
                verticalTitle: 'CAUSE',
                value: null,
                note: null,
              },
              {
                id: 2,
                verticalTitle: 'CORRECTIVE ACTION',
                value: null,
                note: null,
              },
            ];
            if (claimData.claimIdOriginal) {
              this.updateProblemReport(claimData);
            }
          }
        },
      }),
    );

    this.configurationService
      .getAdminConfigMenu({
        menuType: 'corrective_action,cause',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const correctiveActionOptions = configList
          .filter((el) => el.menuType === 'corrective_action')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        const causeOptions = configList
          .filter((el) => el.menuType === 'cause')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        if (['Claims 1', 'Claims 1 Plus', 'CSR 3'].includes(this.azureService.roleName)) {
          correctiveActionOptions.push({ label: 'Contract Refund', value: 'Contract Refund' });
        }
        correctiveActionOptions.unshift({
          label: 'Choose',
          value: '',
        });
        this.caDDOptions.normal.push(...correctiveActionOptions);
        this.dropdown.correctiveAction = [...correctiveActionOptions];
        this.dropdown.cause = causeOptions;
        this.formValueChanged.emit(true);
      });
    this.cols = [
      { field: 'verticalTitle', header: '', type: 'verticalHeader', width: '30%' },
      { field: 'value', header: 'VALUE', type: 'select', width: '30%' },
      { field: 'note', header: 'NOTE', type: 'text', width: '30%' },
    ];
  }
  updateCorrectiveActionOptions(isDenied) {
    if (this.dropdown) {
      if (isDenied && this.isDeniedListAllowed) {
        this.dropdown.correctiveAction = this.caDDOptions.denied;
      } else {
        this.dropdown.correctiveAction = this.caDDOptions.normal;
      }
    }
  }
  updateProblemReport(claimData) {
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getClaimServicerByClaim(claimData.claimIdOriginal)
        .subscribe(({ data }) => {
          const claimServicerData = data.getClaimServicerByclaimId;
          if (claimServicerData) {
            const causeData = claimServicerData.find(
              (x) => x.cause !== null || x.causeNote !== null,
            );
            const correctiveActionData = claimServicerData[0];
            const failureData = claimServicerData ? claimServicerData[0] : undefined;
            this.dataList = [
              {
                id: 0,
                verticalTitle: 'FAILURE',
                value: this.serviceOrderDataService.standardProblemDataModel.failure,
                note:
                  failureData && failureData.failureNote
                    ? failureData.failureNote
                    : this.serviceOrderDataService.standardProblemDataModel.symptom,
              },
              {
                id: 1,
                verticalTitle: 'CAUSE',
                value: causeData && causeData.cause ? causeData.cause : null,
                note: causeData && causeData.causeNote ? causeData.causeNote : null,
              },
              {
                id: 2,
                verticalTitle: 'CORRECTIVE ACTION',
                value:
                  correctiveActionData && correctiveActionData.correctiveAction
                    ? correctiveActionData.correctiveAction
                    : null,
                note:
                  correctiveActionData && correctiveActionData.correctiveActionNote
                    ? correctiveActionData.correctiveActionNote
                    : null,
              },
            ];
            const claimServicer = {
              claimServicerIdOriginal: claimServicerData[0].claimServicerIdOriginal,
              cause: causeData && causeData.cause ? causeData.cause : null,
              correctiveAction:
                correctiveActionData && correctiveActionData.correctiveAction
                  ? correctiveActionData.correctiveAction
                  : null,
              claimId: this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal,
              failureNote: this.serviceOrderDataService.standardProblemDataModel.symptom,
              causeNote: causeData && causeData.causeNote ? causeData.causeNote : null,
              correctiveActionNote:
                correctiveActionData && correctiveActionData.correctiveActionNote
                  ? correctiveActionData.correctiveActionNote
                  : null,
            };
            this.serviceOrderDataService.serviceOrderDataModel.problemReport = claimServicer;
            this.triggerFPBRedemptionFlag(claimServicer.correctiveAction);
          } else {
            // Clear out the fields if nothing was found (mainly for new sub claims)
            this.dataList = [
              {
                id: 0,
                verticalTitle: 'FAILURE',
                value: this.serviceOrderDataService.standardProblemDataModel.failure,
                note: this.serviceOrderDataService.standardProblemDataModel.symptom,
              },
              {
                id: 1,
                verticalTitle: 'CAUSE',
                value: null,
                note: null,
              },
              {
                id: 2,
                verticalTitle: 'CORRECTIVE ACTION',
                value: null,
                note: null,
              },
            ];
            const claimServicer = {
              cause: null,
              claimServicerIdOriginal: null,
              correctiveAction: null,
              claimId: this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal,
              failureNote: this.serviceOrderDataService.standardProblemDataModel.symptom,
              causeNote: null,
              correctiveActionNote: null,
            };
            this.serviceOrderDataService.serviceOrderDataModel.problemReport = claimServicer;
          }
        }),
    );
  }
  checkStatusDenied() {
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimStatusChanged.subscribe({
        next: (isStatusChanged) => {
          if (isStatusChanged) {
            const { isDenied, make_empty } = this.serviceOrderDataService.requestedOperation;
            if (isDenied !== undefined) {
              this.updateCorrectiveActionOptions(isDenied);
              if (make_empty) {
                const problemReport = Object.assign(
                  {},
                  this.serviceOrderDataService.serviceOrderDataModel.problemReport,
                );
                problemReport.correctiveAction = '';
                problemReport.correctiveActionNote = '';
                this.saveProblemReport(problemReport, 2);
              }
              this.isDeniedStatus = isDenied;
            }
            this.serviceOrderDataService.requestedOperation = {
              make_empty: false,
              isDenied: undefined,
            };
          }
        },
      }),
    );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    if (
      (rowData.value === null ||
        !this.caDDOptions.denied.map((x) => x.value).includes(rowData.value)) &&
      this.isDeniedStatus
    ) {
      rowData.value = this.caDDOptions.denied[0].value;
    }
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
    if (!this.serviceOrderDataService.serviceOrderDataModel.problemReport) {
      this.serviceOrderDataService.serviceOrderDataModel.problemReport = {
        claimServicerIdOriginal: rowData.claimServicerIdOriginal || null,
        claimId: claimData.claimIdOriginal,
      };
    }
    if (rowData.value || rowData.note) {
      switch (rowData.verticalTitle) {
        case 'FAILURE':
          this.serviceOrderDataService.serviceOrderDataModel.problemReport.failureNote =
            rowData.note;
          break;
        case 'CAUSE':
          this.serviceOrderDataService.serviceOrderDataModel.problemReport.cause = rowData.value;
          this.serviceOrderDataService.serviceOrderDataModel.problemReport.causeNote = rowData.note;
          break;
        case 'CORRECTIVE ACTION':
          this.serviceOrderDataService.serviceOrderDataModel.problemReport.correctiveAction =
            rowData.value;
          this.serviceOrderDataService.serviceOrderDataModel.problemReport.correctiveActionNote =
            rowData.note;
          break;
      }
      this.saveProblemReport(
        this.serviceOrderDataService.serviceOrderDataModel.problemReport,
        rowData.id,
      );
    }
  }
  saveProblemReport(problemReport, id) {
    const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
    const claimServicer = Object.assign({}, problemReport, {
      claimId: claimData.claimIdOriginal,
      insertUserName: this.azureService.accountId
    });
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .createUpdateClaimServicer(claimServicer)
        .subscribe(({ data, loading }: any) => {
          this.serviceOrderService.refreshClaimOnUpdate.next(true);
          this.isEditing = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Saved successfully.',
          });
          this.updateProblemReport(claimData);
        }),
    );
  }
  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onClickAdd($event) {}

  onRowDelete(event, overlaypanel: OverlayPanel) {
    const index = this.dataList.indexOf(this.selectedItem);
    this.dataList = this.dataList.filter((val, i) => i !== index);
    overlaypanel.hide();
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  triggerFPBRedemptionFlag(correctiveAction) {
    if (correctiveAction) {
      if (correctiveAction.toLowerCase() in { '50% back sc': true, '50% back co': true }) {
        this.serviceOrderDataService.fPBFlag.next(true);
      } else {
        this.serviceOrderDataService.fPBFlag.next(false);
      }
    }
  }
}
