/* eslint-disable no-prototype-builtins */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs';
import { catchError } from 'rxjs';
import { takeUntil } from 'rxjs';
// import { Store } from '@ngrx/store';

import { getMsoBase, getMso, getAppName } from '../../app-base-factory';

import { StringService } from './string.service';
import { IApiResult } from '../interfaces/api-result.interface';
import { IApiSuccess } from '../interfaces/api-success.interface';
import { IApiFailure } from '../interfaces/api-failure.interface';
import { timeout } from 'rxjs';

@Injectable()
export class ApiService implements OnDestroy {
  public static readonly ACCESS_TOKEN_NAME = 'kairos_access_token';
  public GENERIC_API_ERROR_MESSAGE: string;
  public FALL_BACK_ERROR_MESSAGE = 'An error occured while contacting the system.';

  private msKairosBaseUrl = '';

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private http: HttpClient,
    private stringService: StringService, // private store: Store<IAppState>
  ) {
    const mso = getMso();
    const appName = getAppName();

    this.stringService.strings$.pipe(takeUntil(this.unsubscribe)).subscribe((strings: any) => {
      this.GENERIC_API_ERROR_MESSAGE = strings.genericApiErrorMessage;
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public wexis<T>(
    url: string,
    options?: { payload?: any; errorMessage?: string },
  ): Observable<IApiResult<T>> {
    return this.serverCallAsObservable('wp_wexis_request', url, options);
  }

  public wexisAsPromise<T>(
    url: string,
    options?: { payload?: any; errorMessage?: string; dataCallBack?: any },
  ): Promise<IApiResult<T>> {
    return this.serverCallAsPromise('wp_wexis_request', url, options);
  }

  public login<T>(
    login: string,
    password: string,
    options: { errorMessage?: string },
  ): Observable<IApiResult<T>> {
    return this.serverCallAsObservable('wp_wexis_login', '1.0/login', {
      errorMessage: options.errorMessage ? options.errorMessage : this.FALL_BACK_ERROR_MESSAGE,
      args: { login, password },
    });
  }

  public refresh<T>(token: string, options: { errorMessage?: string }): Observable<IApiResult<T>> {
    return this.serverCallAsObservable('wp_wexis_refresh_token', '1.0/login', {
      errorMessage: options.errorMessage ? options.errorMessage : this.FALL_BACK_ERROR_MESSAGE,
      args: { token },
    });
  }

  public revoke<T>(token: string, options: { errorMessage?: string }): Observable<IApiResult<T>> {
    return this.serverCallAsObservable('wp_wexis_revoke_token', '1.0/login', {
      errorMessage: options.errorMessage ? options.errorMessage : this.FALL_BACK_ERROR_MESSAGE,
      args: { token },
    });
  }

  public getMsKairosBaseUrl() {
    return this.msKairosBaseUrl;
  }

  private serverCall<T>(
    rp: string,
    url: string,
    options?: { payload?: any; errorMessage?: string; args?: any },
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'source-id': 'kairos',
    });

    const tokenObj = this.getAccessToken();
    const data: any = {
      mso: getMso(),
      bearer_token: tokenObj ? tokenObj.token : '',
      target: url,
      source: 'API_SOURCE',
    };
    options = options || {};

    if (options.payload) {
      data.payload = options.payload;
    }

    if (options.args) {
      for (const prop in options.args) {
        if (options.args.hasOwnProperty(prop)) {
          data[prop] = options.args[prop];
        }
      }
    }

    const body = `rp=EntWexis::${rp}&args=` + encodeURIComponent(JSON.stringify(data));

    return this.http
      .post(getMsoBase() + 'post.pl', body, {
        headers,
        observe: 'response',
      })
      .pipe(timeout(20000));
  }

  private serverCallAsObservable<T>(
    rp: string,
    url: string,
    options?: { payload?: any; errorMessage?: string; args?: any },
  ): Observable<IApiResult<T>> {
    return this.serverCall(rp, url, options).pipe(
      map((response) => {
        if (response.status !== 200) {
          return {
            status: response.status,
            message:
              (options && options.errorMessage) ||
              this.GENERIC_API_ERROR_MESSAGE ||
              this.FALL_BACK_ERROR_MESSAGE,
          } as IApiFailure;
        }

        const result: T = response.body;

        return {
          status: response.status,
          result,
        } as IApiSuccess<T>;
      }),
      catchError((err) => {
        const status = err.status || 500;
        try {
          return of({
            status,
            message:
              (options && options.errorMessage) ||
              this.GENERIC_API_ERROR_MESSAGE ||
              err.json().message,
          } as IApiFailure);
        } catch (e) {
          return of({
            status,
            message: 'An error calling the API has occured.',
          } as IApiFailure);
        }
      }),
    );
  }

  private async serverCallAsPromise<T>(
    rp: string,
    url: string,
    options?: {
      payload?: any;
      errorMessage?: string;
      args?: any;
      dataCallBack?: any;
    },
  ): Promise<IApiResult<T>> {
    try {
      const response = await this.serverCall(rp, url, options).toPromise();

      if (response.status !== 200) {
        if (response.status === 401) {
          // this.store.dispatch({
          //   type: EXPIRE_LOGIN
          // });
        }

        return {
          status: response.status,
          message:
            (options && options.errorMessage) ||
            (response.error && response.error.message) ||
            this.GENERIC_API_ERROR_MESSAGE ||
            this.FALL_BACK_ERROR_MESSAGE,
        } as IApiFailure;
      }

      return {
        status: response.status,
        result:
          typeof options !== 'undefined' && typeof options.dataCallBack !== 'undefined'
            ? options.dataCallBack(response.body)
            : response.body,
      } as IApiSuccess<T>;
    } catch (error: any) {
      if (error.status && error.status === 401) {
        // this.store.dispatch({
        //   type: EXPIRE_LOGIN
        // });
      }

      let jsonError: any | null = null;
      if (error.error) {
        jsonError = error.error;
      }
      return {
        status: error.status,
        code: jsonError ? jsonError.code : null,
        data: jsonError ? jsonError.data || jsonError.details : null,
        message:
          (options && options.errorMessage) ||
          (jsonError ? jsonError.message : null) ||
          this.GENERIC_API_ERROR_MESSAGE ||
          this.FALL_BACK_ERROR_MESSAGE,
      } as IApiFailure;
    }
  }

  private getAccessToken() {
    const tokenString = localStorage.getItem(ApiService.ACCESS_TOKEN_NAME);
    return tokenString !== null ? JSON.parse(tokenString) : null;
  }
}
