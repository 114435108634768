import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AzureMapsService {
  public selectedValue = [];
  constructor(
    private http: HttpClient,
    private azureService: AzureLoginService,
  ) {}

  getAddressDetails(address): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const payload = {
      query: address,
    };
    const response = this.http.post(environment.azureMapsUrl, JSON.stringify(payload), {
      headers,
    });

    return response;
  }
}
