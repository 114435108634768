import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';

const getQueueStatusGroupQuery = gql`
  query(
    $queueType: Int!,
    $azureUserId: String!,
    $buyingGroup: String,
    $createdBy: String,
    $dealerGroupNumber: String,
    $servicer: String,
    $status: String) {
    getQueueStatusGroup(
      queueTypeId: $queueType,
      azureUserId: $azureUserId,
      buyingGroup: $buyingGroup,
      createdBy: $createdBy,
      dealerGroupNumber: $dealerGroupNumber,
      servicer: $servicer,
      status: $status
      ) {
      status
      count
      type
      queueGroup
    }
  }
`;

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WorkQueueService {
  constructor(private apollo: Apollo) {}
  getWorkQueue = (searchParams, azureUserId) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getQueueStatusGroupQuery,
      variables: {
        queueType: searchParams.queueType ? parseInt(searchParams.queueType.value, 10) : 10,
        azureUserId,
        status: searchParams.status ? searchParams.status.key : null,
        buyingGroup: searchParams.buyingGroup ? searchParams.buyingGroup.key : null,
        servicer: searchParams.servicer ? searchParams.servicer.key : null,
        dealerGroupNumber: searchParams.dealerGroupNumber ? searchParams.dealerGroupNumber.key : null,
        createdBy: searchParams.createdBy ? searchParams.createdBy.key : null,
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
