import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';
const getInquiryQueue = gql`
  query($groupName: String, $userName: String, $email: String, $offset: Int, $limit: Int) {
    getInquiryQueue(
      groupName: $groupName
      userName: $userName
      email: $email
      offset: $offset
      limit: $limit
    ) {
      roleName
      userName
      email
      openCount
      closedCount
      inProgressCount
      newCount
      onHoldCount
      viewAllCount
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class InquiryQueueService {
  constructor(private apollo: Apollo) {}

  getInquiryQueue = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getInquiryQueue,
      variables: {
        groupName: searchParams.groupname ? searchParams.groupname : null,
        userName: searchParams.username ? searchParams.username : null,
        email: searchParams.email ? searchParams.email : null,
        offset: searchParams.offset ? searchParams.offset : null,
        limit: searchParams.limit ? searchParams.limit : null
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
