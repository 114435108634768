import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { Subject } from 'rxjs';

const dealerLocationQuery = gql`
  query($id: Int, $number: String) {
    getDealerLocationProfileById(id: $id, number: $number) {
      sellerId
      status
      name
      number
      fedTaxId
      dealerGroupNumber
      refundPayMethod
      claimPayMethod
      storeType
      insuranceOverride
      obligor
      sellerIdOriginal
      addresses(categoryType: Seller) {
        addressIDOriginal
        address1
        address2
        state
        city
        country
        zipCode
        isMailingAddress
        isBillingAddress
      }
      contactInfo(categoryType: Seller) {
        contactIdOriginal
        firstName
        lastName
        businessPhone
        phoneNumber1
        email
        fax
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
    }
  }
`;

const createDealerLocationQuery = gql`
  mutation(
    $status: String
    $name: String
    $number: String
    $fedTaxId: String
    $dealerGroupNumber: String
    $refundPayMethod: String
    $claimPayMethod: String
    $obligor: String
    $sellerIdOriginal: Int
    $contactInfo: ContactInputType
    $addresses: [AddressInputType]
    $insertUserName: String
  ) {
    createUpdateDealerLocation(
      sellerProfileInput: {
        status: $status
        name: $name
        number: $number
        fedTaxId: $fedTaxId
        dealerGroupNumber: $dealerGroupNumber
        obligor: $obligor
        sellerIdOriginal: $sellerIdOriginal
        refundPayMethod: $refundPayMethod
        claimPayMethod: $claimPayMethod
        contactInfo: $contactInfo
        addresses: $addresses
        insertUserName: $insertUserName
      }
    ) {
      sellerId
      sellerIdOriginal
      fedTaxId
      number
      name
    }
  }
`;

const getDealerGroupContactInfoQuery = gql`
  query($sellerId: Int!) {
    getDealerGroupContactInfo(dealerGroupId: 1, sellerId: $sellerId) {
      contactId
      contactIdOriginal
      firstName
      lastName
      contactType
      position
      phoneNumber1
      cellphone
      businessPhone
      email
      fax
      phoneTypeCode1
      phoneTypeCode2
      businessPhoneTypeCode
    }
  }
`;

@Injectable()
export class DealerLocationService {
  public dealerLocationData: any;
  public contactFormData: any;
  public mailingAddress: any;
  public billingAddress: any;
  public generalFormData: any;
  public contactFormDataFinal: any;
  public mailingAddressFinal: any;
  public billingAddressFinal: any;
  public generalFormDataFinal: any;

  public dealerConfig = {
    searchParams: null
  };
  constructor(private apollo: Apollo) {}

  getDealerLocationDetails = (id, dealerLocationNumber = null) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: dealerLocationQuery,
      variables: { id, number: dealerLocationNumber },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createDealerLocation = formData => {
    const addressMailing = {
      ...this.mailingAddress,
      ...this.mailingAddressFinal,
      isMailingAddress: true,
      country:
        this.mailingAddressFinal &&
        this.mailingAddressFinal.country &&
        this.mailingAddressFinal.country.key &&
        this.mailingAddressFinal.country.key !== null
          ? this.mailingAddressFinal.country.key
          : 'United States'
    };
    const addressBilling = {
      ...this.billingAddress,
      ...this.billingAddressFinal,
      isBillingAddress: true,
      country:
        this.billingAddressFinal &&
        this.billingAddressFinal.country &&
        this.billingAddressFinal.country.key &&
        this.billingAddressFinal.country.key !== null
          ? this.billingAddressFinal.country.key
          : 'United States'
    };
    const contactinfo = { ...this.contactFormData, ...this.contactFormDataFinal };
    contactinfo.businessPhoneTypeCode =
      contactinfo.businessPhoneTypeCode && contactinfo.businessPhoneTypeCode.value
        ? contactinfo.businessPhoneTypeCode.value
        : null;

    delete addressMailing.__typename;
    delete addressBilling.__typename;
    delete contactinfo.__typename;
    delete addressBilling.valid;
    delete addressMailing.valid;
    delete contactinfo.valid;

    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createDealerLocationQuery,
      variables: {
        status: formData.status.value || 'A',
        name: formData.name,
        number: formData.number,
        fedTaxId: formData.fedTaxId,
        dealerGroupNumber:
          formData.dealerGroupNumber && formData.dealerGroupNumber.value
            ? formData.dealerGroupNumber.value.toString()
            : null,
        refundPayMethod: formData.refundPayMethod.value || null,
        claimPayMethod: formData.claimPayMethod.value || null,
        sellerIdOriginal: formData.sellerIdOriginal,
        obligor: formData.obligor ? formData.obligor.value : null,
        addresses: [addressMailing, addressBilling],
        contactInfo: contactinfo,
        insertUserName: formData.insertUserName
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };

  getDealerGroupContactInfo = data =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getDealerGroupContactInfoQuery,
      variables: { ...data },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
}
