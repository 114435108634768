<div class="crm-container">
  <app-section-header [title]="'ADH'" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="problemInfo">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData="" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
        </tr>
      </ng-template>
    </p-table>
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFieldsPart1">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <form-field [formField]="formFields[10]" [form]="form"></form-field>
        </div>
      </div>
    </form>
    <p-table styleClass="p-datatable-gridlines" [columns]="colsTs" [value]="problemTs">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData="" let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
        </tr>
      </ng-template>
    </p-table>
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-12">
          <form-field [formField]="formFields[11]" [form]="form"></form-field>
        </div>
        <div class="col-12">
          <form-field [formField]="formFields[12]" [form]="form"></form-field>
        </div>
        <div class="col-12">
          <form-field [formField]="formFields[13]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>