<div class="form-modal">
  <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-row" *ngFor="let formField of formFields">
      <form-field [formField]="formField" [form]="form"></form-field>
    </div>
    <div class="button-bar">
      <button>Cancel</button><button type="submit" [disabled]="!form.valid">Save</button>
    </div>
  </form>
</div>
