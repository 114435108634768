import {
  Component,
  Output,
  EventEmitter,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormField } from '../../shared/form-field/form-field';
import { NavigationPanel } from '../../shared/navigation-panel/navigation-panel.model';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DealerFormFieldService } from '../../services/dealer-form-field.service';
import { NavigationPanelComponent } from '../../shared/navigation-panel';
import { ServiceOrderSummaryComponent } from '../service-order/service-order-summary/service-order-summary.component';
import { ServiceOrderService } from '../service-order/service-order.service';
import { ServiceDetailsComponent } from '../service-order/service-details/service-details.comonent';
import { ServiceOrderHistoryComponent } from '../service-order/service-order-history/service-order-history.component';
import { ServiceAttachmentsComponent } from '../service-order/service-attachments/service-attachments.component';
import { ServiceClaimPaymentComponent } from '../service-order/service-claim-payment/service-claim-payement.component';
import { ServiceStatusLogComponent } from '../service-order/service-status-log/service-status-log.component';
import { ServiceAuditComponent } from '../service-order/service-audit/service-audit.component';
import { ServicerService } from './servicer.service';
import { ServicerMainTabComponent } from './servicer-main-tab/servicer-main-tab.component';
import { ServicerDocumentsComponent } from './servicer-documents/servicer-documents.component';
import { ServicerContactComponent } from './servicer-contact/servicer-contact.component';
import { ServicerServiceTypeComponent } from './servicer-service-type/servicer-service-type.component';
import { ServicerInsuranceComponent } from './servicer-insurance/servicer-insurance.component';
import { ServicerServiceAreaComponent } from './servicer-service-area/service-area.component';
import { ServicerServiceInfoComponent } from './servicer-service-info/servicer-service-info.component';
import { ServicerRatesComponent } from './servicer-rates/servicer-rates.component';
import { NoteComponent } from '../../shared/notes/note.component';
import { AddressCategoryType } from '../../shared/constants/enums';
import { ServicerDocumentUploadComponent } from './servicer-import-file/servicer-import-file.component';

@Component({
  selector: 'servicer',
  templateUrl: 'servicer.template.html',
  styleUrls: ['../../shared/styles/left-details-form.scss', 'servicer.scss'],
  providers: [ServiceOrderService, ServicerService],
})
export class ServicerComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  buttons: any[];
  displayValidator: boolean;
  displayClaim: boolean;
  component = ServicerComponent;
  componentDisplay = false;
  servicerProfileId = 0;
  querySubscription: any;
  querySubscriptions: Subscription[] = [];
  isLoading: boolean;
  addressCategoryType = AddressCategoryType;
  isDataLoaded: boolean;
  constructor(
    private roleService: RoleAuthorizationService,
    private servicerService: ServicerService,
    private cdr: ChangeDetectorRef,
  ) {
    this.displayValidator = false;
    this.displayClaim = false;
    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: ServicerMainTabComponent,
        data: { name: 'Main' },
        title: 'MN',
        tabname: 'Main',
        tabKey: 'main',
      },
      {
        component: ServicerContactComponent,
        data: { name: 'Servicer Contact' },
        title: 'CON',
        tabname: 'Servicer Contact',
        tabKey: 'contact',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Contacts',
          category: 'Servicer',
        },
      },
      {
        component: ServicerServiceTypeComponent,
        data: { name: 'Main' },
        title: 'ST',
        tabname: 'Service Types',
        tabKey: 'types',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Service Types',
          category: 'Servicer',
        },
      },
      {
        component: ServicerInsuranceComponent,
        data: { name: 'Main' },
        title: 'IN',
        tabname: 'Insurance',
        tabKey: 'insurance',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Insurance',
          category: 'Servicer',
        },
      },
      {
        component: ServicerServiceAreaComponent,
        data: { name: 'Main' },
        title: 'SA',
        tabname: 'Service Area',
        tabKey: 'service_area',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Service Area',
          category: 'Servicer',
        },
      },
      {
        component: ServicerServiceInfoComponent,
        data: { name: 'Main' },
        title: 'SI',
        tabname: 'Service Info',
        tabKey: 'info',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Service Info',
          category: 'Servicer',
        },
      },
      {
        component: ServicerRatesComponent,
        data: { name: 'Main' },
        title: 'RT',
        tabname: 'Rates',
        tabKey: 'rates',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Service Rates',
          category: 'Servicer',
        },
      },
      {
        component: ServicerDocumentsComponent,
        data: { name: 'Documents' },
        title: 'DC',
        tabname: 'Documents',
        tabKey: 'documents-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Documents',
          category: 'Servicer',
        },
      },
      {
        component: ServicerDocumentUploadComponent,
        data: { name: 'Import Documents' },
        title: 'ID',
        tabname: 'Import Documents',
        tabKey: 'import-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'ADD',
          permission: 'Documents',
          category: 'Servicer',
        },
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: ServicerComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(roleService.applyAuthorization(this.authDetails));

    this.buttons = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: false, type: 'button', callBack: 'onSubmit' },
    ];
  }

  ngOnInit() {
    this.querySubscriptions.push(
      this.servicerService.isLoading.subscribe({
        next: (isLoading) => {
          this.isLoading = isLoading;
          this.cdr.detectChanges();
        },
      }),
    );
    this.componentDisplay = this.roleService.isVisible(NoteComponent, ServicerComponent);
    const baseId = (this.servicerProfileId = !isNaN(this.searchParams?.servicerProfilesIdOriginal)
      ? parseInt(this.searchParams.servicerProfilesIdOriginal, 10)
      : 0);
    this.servicerService.selectedServicerProfileId = baseId;
    if (baseId) {
      this.isLoading = true;
      this.querySubscriptions.push(
        this.servicerService.getServicerDetailsForm(baseId).subscribe(
          ({ data, loading }: any) => {
            const {
              addresses,
              contactInfo,
              servicerPreAuth,
              servicerMarkupRates,
              servicerExtraMilage,
              servicerRatesSchedule,
              ...serviceFormData
            } = data.getServicerProfileById;
            this.servicerService.serviceFormData = serviceFormData;
            this.servicerService.billingAddress = addresses[0];
            this.servicerService.mailingAddress = addresses[1];
            this.servicerService.servicerLatLongData = Object.assign(
              {},
              {
                latitude: serviceFormData.latitude,
                longitude: serviceFormData.longitude,
              },
            );
            this.servicerService.contactFormData = contactInfo;
            this.servicerService.preAuth = servicerPreAuth;
            this.servicerService.markupRates = servicerMarkupRates;
            this.servicerService.servicerExtraMilage = servicerExtraMilage;
            this.servicerService.servicerRatesSchedule = servicerRatesSchedule;
            this.isDataLoaded = true;
            this.isLoading = loading;
          },
          (err) => {
            this.isDataLoaded = true;
            this.isLoading = false;
            throw err;
          },
        ),
      );
    } else {
      this.isDataLoaded = true;
      this.isLoading = false;
    }
    this.querySubscriptions.push(
      this.servicerService.isServicerProfilesIdOriginalAvailable.subscribe({
        next: (isServicerGroupsId) => {
          if (isServicerGroupsId) {
            this.servicerProfileId = this.servicerService.servicerProfilesIdOriginal;
          }
        },
      }),
    );
  }
  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
}
