import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { SecurityTypeService } from '../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import {
  ellipsisRowOptions,
  rolePermissionsEllipsisHeaderOptions,
} from '../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { Subscription } from 'rxjs';
import _forEach from 'lodash/forEach';
import { DropdownDataService } from '../../../services/dropdown.data.service';

import { AzureLoginService } from 'src/app/services/azure-login.service';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'admin-security-role-permission',
  templateUrl: 'sec-role-permission.template.html',
  styleUrls: ['../security.scss'],
  providers: [
    SecurityTypeService,
    ContractFormFieldService,
    FormFieldControlService,
    MessageService,
  ],
})
export class SecurityRolePermissionsComponent extends FormCanDeactivate implements OnInit {
  cols: any[];
  dataList: any[] = [];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  rolePermissionsEllipsisHeaderOptions: any[] = rolePermissionsEllipsisHeaderOptions;
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  editConstant: string;
  displayAdd: boolean;
  displayUpdate: boolean;
  formModalInfo: FormField<any>[] = [];
  querySubscriptionRoles: Subscription;
  isLoading = true;

  @Input() searchParams: any = {};
  originalSearchParams: any = {};
  lastOffsetVal = 0;
  offsetLimit = 50;
  querySubscription: Subscription;
  isEditing: boolean;
  selectedColumns: any[];
  globalIndex: number;
  @Input() formFields: FormField<string>[] = [];
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private configurationService: SecurityTypeService,
    private cdr: ChangeDetectorRef,
    private contractService: ContractFormFieldService,
    private roleService: RoleAuthorizationService,
    private azureService: AzureLoginService,
    private formFieldService: FormFieldControlService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dropdownDataService: DropdownDataService,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'roleName', header: 'ROLE NAME', type: 'text' },
      { field: 'permissionCategory', header: 'PERM CAT', type: 'text' },
      { field: 'permissionDescription', header: 'PERM DESC', type: 'text' },
      { field: 'operationName', header: 'OPERATION', type: 'text' },
      { field: 'componentName', header: 'COMPONENT NAME', type: 'text' },
      { field: 'elementName', header: 'ELEMENT NAME', type: 'text' },
      { field: 'parentComponentName', header: 'PARENT COMPONENT', type: 'text' },
    ];
    this.selectedColumns = this.cols.slice(0, 4);
    this.contractService.getRolePermissionsFormAdd().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
      this.form = this.formFieldService.toFormGroup(this.formFields);
      this.form.valueChanges.subscribe((x) => {
        this.formValueChanged.emit(true);
      });
    });
    this.originalSearchParams = { ...this.searchParams };
    this.getRolePermission();
  }

  getRolePermission(more?: boolean) {
    this.isLoading = true;
    if (this.searchParams === 'Role Permissions') {
      this.searchParams = {};
    } else if (!more) {
      this.searchParams.operation =
        this.searchParams.operation && this.searchParams.operation.key
          ? this.searchParams.operation.key
          : this.searchParams.operation;
      this.searchParams.roleName = this.searchParams.roleName
        ? this.searchParams.roleName.map((e) => e.key).join()
        : '';
      this.searchParams.permissionCategory = this.searchParams.permissionCategory
        ? this.searchParams.permissionCategory.map((e) => e.key).join()
        : '';
      this.searchParams.permissionDescription =
        this.searchParams.permissionDescription && this.searchParams.permissionDescription.key
          ? this.searchParams.permissionDescription.key
          : this.searchParams.permissionDescription;
    }
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.searchParams.azureUserId = this.azureService.accountId;

    this.lastOffsetVal += this.offsetLimit;

    this.querySubscription = this.configurationService
      .getRolePermissionSearch(this.searchParams)
      .subscribe((data) => {
        data.data.getRolePermissionSearch.map((role) => {
          const dataObj = {
            ...role,
            id: new Date().getTime(),
          };
          if (this.dataList.length === 0) {
            this.dataList.push(dataObj);
          } else {
            const index = this.dataList.findIndex((e) => e.id === role.id);
            if (index === -1) {
              this.dataList.push(dataObj);
            } else {
              this.dataList[index].name = role.manufacturersName;
            }
          }
        });
        this.dataList.sort((a, b) => a.rolPermissionId - b.rolPermissionId);
        this.isLoading = false;
      });
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    if (event.value === 'delete') {
      this.deleteRolePermission();
    } else {
      this.updateRolePermision();
    }
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e) {
    if (e.value === 'add') {
      this.header = 'Add new Role Permission';
      this.displayAdd = true;
    }
    this.form.reset();
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayUpdate = false;
    this.cdr.detectChanges();
  }

  onSubmit() {
    this.isLoading = true;
    const payload = this.form.getRawValue();
    let params: MutationValues = {
      insertUserName: this.azureService.accountId,
      roleId: payload.roleName.map((e) => parseInt(e.value, 10)),
      operationId: parseInt(payload.operation.value, 10),
      permissionId: parseInt(payload.permissionCategory.value, 10),
    };
    if (this.header === 'Update Role Permission') {
      params = {
        ...params,
        rolePermissionIdOriginal: this.selectedItem.rolPermissionId,
      };
    }
    this.querySubscription = this.configurationService
      .createUpdateRolePermissions(params)
      .subscribe(
        (response: any) => {
          if (typeof response.data.createUpdateRolePermissions === 'object') {
            this.showToastStaus(true);
          }
          this.form.reset();
          this.dataList = [];
          this.displayAdd = false;
          this.searchParams = { ...this.originalSearchParams };
          this.getRolePermission();
        },
        () => {
          this.showToastStaus(false);
          this.isLoading = false;
        },
      );
  }

  updateRolePermision() {
    this.header = 'Update Role Permission';
    const operationIndex = this.getIndexOfItem('o');
    const permissionIndex = this.getIndexOfItem('p');
    const roleNameIndex = this.getIndexOfItem('rn');
    this.form.patchValue({
      operation: {
        key: this.dropdownDataService.operationsList[operationIndex].value,
        value: this.dropdownDataService.operationsList[operationIndex].key,
      },
      permissionCategory: {
        key: this.dropdownDataService.operationsListOriginal[permissionIndex].value,
        value: this.dropdownDataService.operationsListOriginal[permissionIndex].key,
      },
      roleName: [
        {
          value: this.dropdownDataService.roleNameData[roleNameIndex].value,
          key: this.dropdownDataService.roleNameData[roleNameIndex].key,
        },
      ],
    });
    this.displayAdd = true;
  }

  deleteRolePermission() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this role permission?`,
      accept: () => {
        const operationIndex = this.getIndexOfItem('o');
        const permissionIndex = this.getIndexOfItem('p');
        const params: MutationValues = {
          insertUserName: this.azureService.accountId,
          rolePermissionIdOriginal: this.selectedItem.rolPermissionId,
          operationId: parseInt(this.dropdownDataService.operationsList[operationIndex].key, 10),
          permissionId: parseInt(
            this.dropdownDataService.operationsListOriginal[permissionIndex].key,
            10,
          ),
          roleId: [parseInt(this.selectedItem.roleId, 10)],
          isDeleted: true,
        };
        this.querySubscription = this.configurationService
          .createUpdateRolePermissions(params)
          .subscribe(
            (data) => {
              this.dataList = [];
              this.searchParams = { ...this.originalSearchParams };
              this.getRolePermission();
              this.showToastStaus(true);
            },
            () => {
              this.showToastStaus(false);
            },
          );
      },
    });
  }

  showToastStaus(success: boolean) {
    if (success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Success.`,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed`,
      });
    }
  }

  getIndexOfItem(operation: string) {
    switch (operation) {
      case 'o':
        const operationIndex = this.dropdownDataService.operationsList.findIndex(
          (e) => this.selectedItem.operationName === e.value,
        );
        return operationIndex;
      case 'p':
        const permissionIndex = this.dropdownDataService.operationsListOriginal.findIndex(
          (e) =>
            `${this.selectedItem.permissionCategory}-${this.selectedItem.permissionDescription}` ===
            e.value,
        );
        return permissionIndex;
      default:
        const roleNameIndex = this.dropdownDataService.roleNameData.findIndex(
          (e) => this.selectedItem.roleName === e.key,
        );
        return roleNameIndex;
      // constRoleNameindex
    }
  }

  onCancel() {
    this.form.reset();
    this.displayAdd = false;
  }
}

export interface MutationValues {
  roleId: Array<number>;
  permissionId: number;
  operationId: number;
  rolePermissionIdOriginal?: number;
  isDeleted?: boolean;
  insertUserName: string;
}
