import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpenseChargeService } from '../../service-details/expense-charges/expense-charges.service';
import {
  ellipsisCrudOptions,
  ellipsisHeaderOptions,
  ellipsisHeaderCrudOptions,
} from '../../../../shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
@Component({
  selector: 'expense-charges-total',
  templateUrl: 'expense-charges-total.template.html',
  styleUrls: [
    '../../../../shared/styles/crm-styles.scss',
    '../../../../shared/styles/crm-table.scss',
    '../../service-details/expense-charges/expense-charges.scss',
  ],
  providers: [ExpenseChargeService],
})
export class ExpenseChargesTotalComponent implements OnInit, OnDestroy {
  dataList: any[];
  colsTotal: any[];
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = ellipsisHeaderCrudOptions;
  selectedEllipsisItem: any;
  editConstant: string;
  selectedRowIndex: number;
  selectedRow: any;
  querySubscription: Subscription;
  isEditing = false;

  @ViewChild(Table) table: Table;
  constructor(
    private expenseChargeService: ExpenseChargeService,
    private serviceOrderDataService: ServiceOrderDataService,
    private azureService: AzureLoginService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();

    this.colsTotal = [
      { field: 'expenseType', header: 'EXPENSE TYPE', type: 'text' },
      { field: 'requestedAmount', header: 'REQUESTED TOTAL', type: 'text', isAmount: true },
      { field: 'adjustedAmount', header: 'ADJUSTED TOTAL', type: 'text', isAmount: true },
      { field: 'approvedAmount', header: 'APPROVED TO PAY', type: 'text', isAmount: true },
      { field: 'paidAmount', header: 'PAID', type: 'text', isAmount: true },
    ];

    this.serviceOrderDataService.isClaimDataAvailable.subscribe({
      next: (isClaimDataAvailable) => {
        if (
          isClaimDataAvailable &&
          this.serviceOrderDataService.serviceOrderDataModel.claimData &&
          this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber
        ) {
          // Retrieve call
          const { claimStatusDescription } =
            this.serviceOrderDataService.serviceOrderDataModel.claimData;
          if (
            claimStatusDescription &&
            (claimStatusDescription.toUpperCase() === 'IN PROGRESS' ||
              this.azureService.roleName === 'Admin')
          ) {
            this.ellipsisHeaderOptions = copy(ellipsisHeaderOptions);
          }
          const { claimNumber } = this.serviceOrderDataService.serviceOrderDataModel.claimData;
          this.querySubscription = this.expenseChargeService
            .getExpenseCharges(claimNumber, this.azureService.accountId)
            .subscribe(({ data, loading }: any) => {
              if (data.getExpenseChargesByClaim && data.getExpenseChargesByClaim.length) {
                const expenseCharges = data.getExpenseChargesByClaim.map((ex) => {
                  if (ex.__typename) {
                    delete ex.__typename;
                  }
                  return ex;
                });

                const floatExpenseCharges = expenseCharges.map(
                  ({
                    expenseType,
                    requestedAmount,
                    adjustedAmount,
                    approvedAmount,
                    paidAmount,
                    status,
                  }) =>
                    status.toUpperCase() !== 'DENIED' && {
                      expenseType,
                      requestedAmount: Number(
                        requestedAmount ? parseFloat(requestedAmount).toFixed(2) : 0,
                      ),
                      adjustedAmount: Number(
                        adjustedAmount ? parseFloat(adjustedAmount).toFixed(2) : 0,
                      ),
                      paidAmount: Number(parseFloat(paidAmount || 0).toFixed(2)),
                      approvedAmount: Number(parseFloat(approvedAmount || 0).toFixed(2)),
                      status,
                    },
                );

                const expensedTotalGrouped = this.mergeExpensesAmount(floatExpenseCharges);
                const totalCalculated = this.totalExpenses(expensedTotalGrouped);
                expensedTotalGrouped.push(totalCalculated[0]);

                this.dataList = [...expensedTotalGrouped];
              }
            });
        }
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, index?: number) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
    this.selectedRowIndex = index;
    this.selectedRow = item;
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEditInit(rowData: any, rowIndex: number) {
    // this.clonedRowData[rowData.id] = { ...rowData };
  }

  mergeExpensesAmount(data: any[]) {
    return data.reduce((ex, item) => {
      const total = ex.find(({ expenseType }) => item.expenseType === expenseType);
      if (total) {
        total.requestedAmount += item.requestedAmount;
        total.adjustedAmount += item.adjustedAmount;
        total.approvedAmount += item.approvedAmount;
        total.paidAmount += item.paidAmount;
      } else {
        if (item.expenseType) {
          ex.push(item);
        }
      }
      return ex;
    }, []);
  }

  totalExpenses(data: any[]) {
    return [
      data.reduce((acc, n) => {
        for (const prop in n) {
          if (acc.hasOwnProperty(prop)) {
            acc[prop] += n[prop];
          } else {
            /* tslint:disable:no-string-literal */
            acc[prop] = n[prop];
          }
        }
        acc['expenseType'] = 'Total';
        return acc;
      }, {}),
    ];
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
