import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { CrmGraphql } from '../constants/graphql-constants';
import { FileImport } from './import-file.model';
import { CrmDocumentType } from '../constants/enums';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { AzureLoginService } from 'src/app/services/azure-login.service';

export const documentUploadStatusQuery = gql`
  query($attachmentsType: AttachmentsType!, $fileName: String!) {
    getDocumentUploadState(attachmentsType: $attachmentsType, fileName: $fileName) {
      uploadState
      uploadInfo
      fileUrl
    }
  }
`;

export const fileExistsQuery = gql`
  query($attachmentsType: String!, $fileName: String!) {
    getFileExists(attachmentsType: $attachmentsType, fileName: $fileName)
  }
`;

export const removeFileFromAttachmentsTableQuery = gql`
  mutation($attachmentId: Int!) {
    removeFileFromAttachmentsTable(attachmentId: $attachmentId)
  }
`;

export const addFileToAttachmentsTableQuery = gql`
  mutation(
    $attachmentsType: String
    $user: String
    $isBankContracts: String
    $filename: String
    $attachmentId: String
    $description: String
    $insertUserName: String
    $servicerProfilesId: Int
  ) {
    addFileToAttachmentsTable(
      attachmentInput: {
        attachmentsType: $attachmentsType
        user: $user
        isBankContracts: $isBankContracts
        filename: $filename
        attachmentId: $attachmentId
        description: $description
        insertUserName: $insertUserName
        servicerProfilesId: $servicerProfilesId
      }
    ) {
      attachmentsID
      fileS3URL
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ImportFileService {
  constructor(
    private apollo: Apollo,
    private azureService: AzureLoginService,
    private http: HttpClient
  ) {}

  getFileImportResponse(): Observable<FileImport> {
    const fileimport = {
      ModuleName: 'Contracts',
      ModuleID: 'CT',
      AcceptedFormats: ['CSV', 'TXT'],
      AcceptedColumns: ['ContractNumber', 'InvoiceNumber'],
      DisplaySummaryResults: {
        View: 'True',
        GenericContents: ['Validation', 'TotalCount', 'DuplicatedCount', 'ProcessedCount']
      },
      ValidationColumn: {
        Column1: 'PhoneNumber',
        ValidationRegex: '/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/'
      }
    };
    return of(fileimport);
  }

  getDocumentOptions() {
    const documentOption = [
      {
        key: 'Import Contracts',
        value: 17,
        hasPermissions: true,
        permissions: {
          type: 'element',
          operation: 'ADD',
          permission: 'Import Contracts',
          category: 'Import'
        }
      },
      {
        key: 'Import Dealers',
        value: 4,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Dealers',
          category: 'Import'
        }
      },
      {
        key: 'Import Dealer Locations',
        value: 5,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Dealer Locations',
          category: 'Import'
        }
      },
      {
        key: 'Import Buying Group',
        value: 0,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Buying Group',
          category: 'Import'
        }
      },
      {
        key: 'Import Claim Product',
        value: 2,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Claim Product',
          category: 'Import'
        }
      },
      {
        key: 'Import Cancellations',
        value: 18,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Cancellations',
          category: 'Import'
        },
        hasPermissionsSearch: true,
        permissionSearch: {
          type: 'dpElement',
          operation: 'READ_ONLY',
          permission: 'Import Cancellations',
          category: 'Import'
        }
      },
      {
        key: 'Import Contract History',
        value: 6,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Contract History',
          category: 'Import'
        }
      },
      {
        key: 'Import Servicer Profile',
        value: 7,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Servicer Profile',
          category: 'Import'
        }
      },
      {
        key: 'Import Insurace Company',
        value: 8,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Insurace Company',
          category: 'Import'
        }
      },
      {
        key: 'Import Contract History Customer',
        value: 30,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Contract History Customer',
          category: 'Import'
        }
      },
      {
        key: 'Import Product Location',
        value: 28,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Product Location',
          category: 'Import'
        }
      },
      {
        key: 'Import Remittance Recipient',
        value: 29,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Remittance Recipient',
          category: 'Import'
        }
      },
      {
        key: 'Import Contact',
        value: 31,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Contact',
          category: 'Import'
        }
      },
      {
        key: 'ImportPriceTable',
        value: 33,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import PriceTable',
          category: 'Import'
        }
      },
      {
        key: 'Servicer Claim Submission',
        value: 2,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Claim Submission',
          category: 'Import'
        }
      },
      {
        key: 'Service Area',
        value: 34,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Service Area',
          category: 'Import'
        }
      },
      {
        key: 'Service Type',
        value: 35,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Service Type',
          category: 'Import'
        }
      },
      {
        key: 'Contract/Claim Notes',
        value: 36,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'Import Contract/Claim Notes',
          category: 'Import'
        }
      },
      { key: 'Servicer Rates Import', value: 37 },
      { key: 'Manufacturer Import', value: 38 },
      {
        key: 'OEM Import',
        value: 39,
        hasPermissions: true,
        permissions: {
          type: 'dpElement',
          operation: 'ADD',
          permission: 'OEM Import',
          category: 'Import'
        }
      },
      { key: 'Insurance Carrier Import', value: 40 }
    ];
    return documentOption;
  }

  getDocumentUploadState = (type, fileName) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: documentUploadStatusQuery,
      variables: {
        attachmentsType: CrmDocumentType[type],
        fileName
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  addFileToAttachmentsTable = fileData =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: addFileToAttachmentsTableQuery,
      variables: {
        ...fileData
      }
      // tslint:disable-next-line: semicolon
    });

  removeFileFromAttachmentsTable = fileData =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: removeFileFromAttachmentsTableQuery,
      variables: {
        ...fileData
      }
      // tslint:disable-next-line: semicolon
    });

  sendFileToS3(fileS3URL: string, file): Observable<HttpEvent<any>> {
    return this.http.put(fileS3URL, file, {
      reportProgress: true,
      observe: 'events'
    });
  }

  fileExistsCheck = (type, fileName) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: fileExistsQuery,
      variables: {
        attachmentsType: type,
        fileName
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
