import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetRolePermissionsDirective } from './set-role-permissions.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SetRolePermissionsDirective],
  exports: [SetRolePermissionsDirective]
})
export class DirectivesModule {}
