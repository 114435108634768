import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { FormField } from '../../shared/form-field/form-field';
import { NavigationPanel } from '../../shared/navigation-panel/navigation-panel.model';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DealerFormFieldService } from '../../services/dealer-form-field.service';
import { NavigationPanelComponent } from '../../shared/navigation-panel';
import { DealerProfileTabComponent } from '../dealer-profile-tab/dealer-profile-tab.component';
import { DealerLocationTabComponent } from './dealer-location-tab/dealer-location-tab.component';
import { DealerLocationService } from './dealer-location.service';
import { NoteComponent } from '../../shared/notes/note.component';
import { AddressCategoryType } from '../../shared/constants/enums';
import { DealerLocationDocumentsTabComponent } from './documents-tab/documents-tab.component';
import { DealerLocationUploadComponent } from './dealer-location-upload-tab/dealer-location-upload.component';
import { DealerContactComponent } from '../dealer-contact/dealer-contact.component';
import { DealerLocationContactComponent } from './dealer-location-contact/dealer-contact.component';

@Component({
  selector: 'dealer-location',
  templateUrl: 'dealer-location.template.html',
  styleUrls: ['dealer-location.scss'],
  providers: [DealerFormFieldService, DealerLocationService],
})
export class DealerLocationComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  isLoading = false;
  querySubscription: any;
  component = DealerLocationComponent;
  componentDisplay = false;
  addressCategoryType = AddressCategoryType;
  sellerId = 0;
  constructor(
    service: DealerFormFieldService,
    private roleService: RoleAuthorizationService,
    private dealerLocationService: DealerLocationService,
  ) {
    this.formFields$ = service.getDealerMainFormFields();

    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: DealerLocationTabComponent,
        data: { name: 'Main' },
        title: 'MN',
        tabname: 'Main',
        tabKey: 'main-tab',
      },
      {
        component: DealerLocationContactComponent,
        data: { name: 'Contact' },
        title: 'CON',
        tabname: 'Contact',
        tabKey: 'contact-tab',
      },
      {
        component: DealerLocationDocumentsTabComponent,
        data: { name: 'Documents' },
        title: 'DC',
        tabname: 'Documents',
        tabKey: 'documents-tab',
      },
      {
        component: DealerLocationUploadComponent,
        data: { name: 'Import Documents' },
        title: 'ID',
        tabname: 'Import Documents',
        tabKey: 'import-tab',
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: NavigationPanelComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(roleService.applyAuthorization(this.authDetails));
  }

  ngOnInit() {
    this.componentDisplay = this.roleService.isVisible(NoteComponent, DealerLocationComponent);
    const baseId = (this.sellerId = !isNaN(this.searchParams)
      ? parseInt(this.searchParams, 10)
      : 0);
    if (baseId) {
      this.isLoading = true;
      this.querySubscription = this.dealerLocationService
        .getDealerLocationDetails(baseId)
        .subscribe(
          ({ data, loading }: any) => {
            const { addresses, contactInfo, ...dealerLocationData } =
              data.getDealerLocationProfileById;
            this.dealerLocationService.dealerLocationData = dealerLocationData;
            this.dealerLocationService.billingAddress = addresses[0];
            this.dealerLocationService.mailingAddress = addresses[1];
            const x = Object.assign({}, data.getDealerLocationProfileById.contactInfo);
            this.dealerLocationService.contactFormData = x;
            x.businessPhoneTypeCode = x.businessPhoneTypeCode
              ? {
                  key: this.dealerLocationService.contactFormData.businessPhoneTypeCode,
                  value: this.dealerLocationService.contactFormData.businessPhoneTypeCode,
                }
              : null;

            this.isLoading = loading;
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
}
