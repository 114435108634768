import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { AzureLoginService } from '../../../../services/azure-login.service';

import { CheckBoxField } from '../../shared/form-field/checkbox-field';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { optionEllipsisHeaderOptions } from '../../catalog/catalog.constant';

const userQuery = gql`
  query(
    $userName: String!
    $email: String
    $roleName: String
    $userProfileType: String
    $userProfileNumber: String
  ) {
    getAdminSettings(
      userName: $userName
      email: $email
      roleName: $roleName
      userProfileType: $userProfileType
      userProfileNumber: $userProfileNumber
      offset: 0
      limit: 100
    ) {
      usersId
      role_id
      userName
      email
      status
      azureUserId
      attachedRole
      firstName
      lastName
      phoneNumber
      phoneNumberExt
      userProfileLinkId
      userProfileType
      userProfileName
      userProfileNumber
    }
  }
`;

const roleQuery = gql`
  query($roleName: String!) {
    getAdminSettingRoles(roleName: $roleName) {
      role_id
      role_name
    }
  }
`;

const rolePermissionsQuery = gql`
  query($permissionsType: PermissionsType!, $roleName: String!) {
    getAdminDisplayPermissions(permissionsType: $permissionsType, roleName: $roleName) {
      rolePermissionId
      roleName
      permissionCategory
      permissionDescription
      operationName
    }
  }
`;

const restatusQuery = gql`
  query(
    $roleName: [String]
    $fromStatus: [String]
    $toStatus: [String]
    $offset: Int
    $limit: Int
  ) {
    getAdminRestatus(
      roleName: $roleName
      fromStatus: $fromStatus
      toStatus: $toStatus
      offset: $offset
      limit: $limit
    ) {
      statusToAllowableNextStatusId
      statusToAllowableNextStatusIdOriginal
      allowedNextStatusId
      roleName
      fromStatus
      toStatus
    }
  }
`;

const saveRestatusQuery = gql`
  mutation(
    $statusToAllowableNextStatusIdOriginal: Int
    $statusID: Int
    $allowedNextStatusId: Int
    $roleId: [Int]
    $insertUserName: String
    $isDeleted: Boolean
  ) {
    createUpdateRestatus(
      restatus: {
        statusToAllowableNextStatusIdOriginal: $statusToAllowableNextStatusIdOriginal
        statusID: $statusID
        allowedNextStatusId: $allowedNextStatusId
        roleId: $roleId
        insertUserName: $insertUserName
        isDeleted: $isDeleted
      }
    ) {
      statusToAllowableNextStatusIdOriginal
    }
  }
`;

const getRolePermissionSearchQuery = gql`
  query(
    $roleName: String
    $permissionCategory: String
    $permissionDescription: String
    $componentName: String
    $parentComponentName: String
    $operation: String
    $elementName: String
    $offset: Int
    $limit: Int
  ) {
    getRolePermissionSearch(
      roleName: $roleName
      permissionCategory: $permissionCategory
      permissionDescription: $permissionDescription
      componentName: $componentName
      parentComponentName: $parentComponentName
      operation: $operation
      elementName: $elementName
      offset: $offset
      limit: $limit
    ) {
      rolPermissionId
      roleName
      permissionCategory
      permissionDescription
      componentName
      listName
      elementName
      parentComponentName
      operationName
      roleId
      __typename
    }
  }
`;

const createUpdateRolePermissionsMutation = gql`
  mutation(
    $roleId: [Int]!
    $permissionId: Int!
    $operationId: Int!
    $rolePermissionIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String!
  ) {
    createUpdateRolePermissions(
      rolePermissionsInput: {
        roleId: $roleId
        permissionId: $permissionId
        operationId: $operationId
        rolePermissionIdOriginal: $rolePermissionIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
      }
    )
  }
`;

@Injectable({
  providedIn: 'root'
})
export class SecurityTypeService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private azureService: AzureLoginService
  ) {}

  getSecurityPermissionTypes(): Observable<any[]> {
    return of([
      {
        role_name: 'Account Manager',
        permission_category: 'Cancellation Methods and Rules',
        permission_description: 'Cancellation Method',
        operation: 'READ ONLY'
      },
      {
        role_name: 'Back Office Lead',
        permission_category: 'Claim',
        permission_description: 'Internal Comments',
        operation: 'ADD'
      }
    ]);
  }
  getSecurityRolesTypes(): Observable<any[]> {
    return of([
      {
        role_name: 'Admin'
      },
      {
        role_name: 'Account Manager'
      }
    ]);
  }
  getOperationTypes(): Observable<any[]> {
    return of([
      {
        operation_id: '1',
        operation: 'READ ONLY'
      },
      {
        operation_id: '2',
        operation: 'ADD'
      }
    ]);
  }
  getSecPermConfigTypes(): Observable<any[]> {
    return of([
      {
        rule_category: 'Cancellation Methods and Rules',
        rule_name: 'Cancellation Methods',
        component_name: 'CrmSidebarComponent',
        list_name: '',
        element_name: 'Cancellation Methods'
      },
      {
        rule_category: 'Cancellation Methods and Rules',
        rule_name: 'Cancellation Methods',
        component_name: 'CrmSidebarComponent',
        list_name: '',
        element_name: 'Cancellation Methods'
      }
    ]);
  }
  getSecPermConfigPermissionList(): Observable<any[]> {
    return of([
      {
        component_name: 'CrmSidebarComponent',
        list_name: '',
        element_name: 'Cancellation Methods'
      },
      {
        component_name: 'CrmSidebarComponent',
        list_name: '',
        element_name: 'Cancellation Rules'
      }
    ]);
  }
  getBuyingGroup() {
    return of([
      {
        user_name: 'John Doe',
        email: 'Jodoe@gmail.com',
        status: 'ACTIVE',
        attached_roles: 'Account Manager',
        is_template: 'NY'
      },
      {
        user_name: 'Jane Doe',
        email: 'Jadoe@gmail.com',
        status: 'ACTIVE',
        attached_roles: 'Admin',
        is_template: 'NY'
      }
    ]);
  }
  getOperationFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'configuration type',
        label: 'Configuration Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),
      new TextboxField({
        key: 'operation',
        label: 'operation',
        order: 2
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getPermissionListFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'componentName',
        label: 'Component name',
        order: 1
      }),
      new TextboxField({
        key: 'listName',
        label: 'List name',
        order: 2
      }),
      new TextboxField({
        key: 'elementName',
        label: 'Element name',
        order: 3
      }),
      new TextboxField({
        key: 'parentComponentName',
        label: 'Parent Component name',
        order: 4
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getRolePermissionFields(): Observable<any[]> {
    return of([
      {
        role_permission_id: '1',
        role_name: 'Admin',
        permission_id: '331',
        permission_category: 'Contract',
        permission_description: 'Primary Number',
        operation: 'EDIT'
      },
      {
        role_permission_id: '2',
        role_name: 'Admin',
        permission_id: '332',
        permission_category: 'Contract',
        permission_description: 'Secondary Number',
        operation: 'EDIT'
      }
    ]);
  }

  listUsers(): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    const response = this.http.post(environment.userUrl, JSON.stringify({ ListUsers: true }), {
      headers
    });

    return response;
  }
  createUsers(payload): Observable<any> {
    const singleRecord = {
      DisplayName: payload.username,
      EmailAddress: payload.email,
      FirstName: payload.firstname,
      LastName: payload.lastname,
      RoleName: payload.role.key,
      PhoneNumber: payload.phonenumber,
      PhoneNumberExt: payload.phoneextension,
      Password: null,
      UserProfileLinks: payload.UserProfileLinks,
      InsertUserName: payload.InsertUserName
    };
    if (payload.createuserpassword && payload.createuserpassword !== '') {
      singleRecord.Password = payload.createuserpassword;
    } else {
      delete singleRecord.Password;
    }

    const createRequest = {
      Records: [singleRecord]
    };

    const headers = {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    const response = this.http.post(environment.userUrl, JSON.stringify(createRequest), {
      headers
    });

    return response;
  }
  updateUser(payload): Observable<any> {
    const updateRequest = {
      Records: [
        {
          EmailAddress: payload.email,
          FirstName: payload.firstname,
          LastName: payload.lastname,
          UserId: payload.azureUserId,
          PhoneNumber: payload.phonenumber,
          PhoneNumberExt: payload.phoneextension,
          DisplayName: payload.username,
          RoleName: payload.role.key,
          Status: payload.accountstatus.key,
          UserProfileLinks: payload.UserProfileLinks,
          InsertUserName: payload.InsertUserName
        }
      ]
    };
    const headers = {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    return this.http.post(environment.userUrl, JSON.stringify(updateRequest), { headers });
  }

  deleteUser(payload): Observable<any> {
    const deleteRequest = {
      Records: [
        {
          UserId: payload.azureUserId,
          DeleteUser: true
        }
      ]
    };

    const headers = {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };

    return this.http.post(environment.userUrl, JSON.stringify(deleteRequest), { headers });
  }

  getUserDetails = (userName, email, roleName, userProfileType, userProfileNumber) =>
    this.apollo.use('crm').query<any>({
      query: userQuery,
      variables: { userName, email, roleName, userProfileType, userProfileNumber },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getRoleDetails = searchParams =>
    this.apollo.use('crm').query<any>({
      query: roleQuery,
      variables: { roleName: searchParams }
      // tslint:disable-next-line: semicolon
    });

  getRolePermissionDetails = searchParams =>
    this.apollo.use('crm').query<any>({
      query: rolePermissionsQuery,
      variables: { roleName: searchParams }
      // tslint:disable-next-line: semicolon
    });

  getRestatusDetails = searchParams =>
    this.apollo.use('crm').query<any>({
      query: restatusQuery,
      variables: {
        roleName: searchParams.roleName,
        fromStatus: searchParams.fromStatus,
        toStatus: searchParams.toStatus,
        offset: searchParams.offset,
        limit: searchParams.limit
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getRolePermissionSearch = searchParams =>
    this.apollo.use('crm').query<any>({
      query: getRolePermissionSearchQuery,
      variables: {
        roleName: searchParams.roleName,
        permissionCategory: searchParams.permissionCategory,
        permissionDescription: searchParams.permissionDescription,
        componentName: searchParams.componentName,
        parentComponentName: searchParams.parentComponentName,
        operation: searchParams.operation,
        elementName: searchParams.elementName,
        offset: searchParams.offset,
        limit: searchParams.limit
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createUpdateRolePermissions = params =>
    this.apollo.use('crm').mutate({
      mutation: createUpdateRolePermissionsMutation,
      variables: {
        ...params
      }
      // tslint:disable-next-line: semicolon
    });
  saveRestatus = params =>
    this.apollo.use('crm').mutate({
      mutation: saveRestatusQuery,
      variables: {
        ...params
      }
      // tslint:disable-next-line: semicolon
    });
}
