import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SearchNotificationService } from './set-up.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SetupAlerts, AlertFilterType } from '../setup-alerts/setup-alerts';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import {
  ellipsisHeaderOptions,
  ellipsisModificationOptions,
} from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { ConfigurationTypeService } from '../../administration/configuration/configuration.service';
import { copy } from '../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'setup-Alerts',
  templateUrl: 'setup-alerts.template.html',
  styleUrls: ['setup-alerts.scss'],
  providers: [SearchNotificationService, MessageService],
})
export class SearchSetupAlertsComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  [x: string]: any;
  alertsList: any[];
  dataList: SetupAlerts[];
  cols: any[];
  selectedItem: any;
  selectedRowIndex: number;
  isLoading: boolean;
  isCreatingNew: boolean;
  btnStatus: any = buttonStatus;
  ellipsisOptions: any[] = ellipsisModificationOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  disableLoadMore: boolean;
  lastOffsetVal = 0;
  offsetLimit = 10;
  isTableLoading: boolean;
  createUpdateAlertTitle: any;
  clonedRowData: KeyMap = {};
  displayAlertInfoModal = false;
  querySubscriptions: Subscription[] = [];
  querySubscription: Subscription;
  selectedColumns: any[];
  selectedRow: any;

  constructor(
    private search: SearchNotificationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.alertsList = [];
    this.loadAlerts(true);
    this.cols = [
      { field: 'status', header: 'STATUS' },
      { field: 'module_type', header: 'MODULE' },
      { field: 'message', header: 'MESSAGE' },
      { field: 'prod_type', header: 'PROD TYPE' },
      { field: 'mfw', header: 'MANUFACTURER' },
      { field: 'cat', header: 'CATEGORY' },
      { field: 'sub_cat', header: 'SUB-CAT' },
      { field: 'dealer', header: 'DEALERS' },
      { field: 'dealer_loc', header: 'DEALER LOC' },
      { field: 'buying_group', header: 'BUYING GROUP' },
      { field: 'eff_date_from', header: 'EFFECTIVE DATE FROM' },
      { field: 'eff_date_to', header: 'EFFECTIVE DATE TO' },
    ];
    this.selectedColumns = this.cols.slice(0, 10);

    this.configurationService
      .getAdminConfigMenu({
        menuType: 'product_type',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList
          .filter((el) => el.menuType === 'product_type')
          .map((e) => {
            return {
              key: e.key,
              value: e.sCMConfigEntryIdOriginal,
            };
          });
        const options = [{ key: 'ALL', value: 0 }, ...configList];
        this.search.productTypeList = options;
      });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'alerts',
      category: 'Notifications',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'alerts',
      category: 'Notifications',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  loadAlerts(isReload) {
    this.formatSearchParams();

    // reset defaults in case of a reload/refresh
    if (isReload) {
      this.lastOffsetVal = 0;
      this.offsetLimit = 10;

      this.searchParams.limit = this.offsetLimit;
    } else {
      this.lastOffsetVal += this.offsetLimit;
    }

    this.searchParams.offset = this.lastOffsetVal;
    this.isLoading = true;
    this.querySubscription = this.search
      .getAllAlertData(this.searchParams)
      .subscribe(({ data }) => {
        this.dataList = isReload
          ? this.processAlertsData(data.getAlertData, isReload)
          : this.dataList.concat(this.processAlertsData(data.getAlertData, isReload));
        this.disableLoadMore =
          Boolean(data.getAlertData && data.getAlertData.length < this.offsetLimit) ||
          Boolean(!data.getAlertData);
        this.isLoading = false;
      });
  }

  formatSearchParams() {
    if (!!this.searchParams) {
      Object.keys(this.searchParams).map((el) => {
        if (this.searchParams[el] && this.searchParams[el].key) {
          this.searchParams[el] =
            this.searchParams[el].key === 'All' ? null : this.searchParams[el].key;
        }
      });
    } else {
      this.searchParams = {};
    }
  }

  processAlertsData(alertsData, isReload) {
    let alData = {} as SetupAlerts;
    if (isReload) {
      this.alertsList = [];
    }
    const resultData = [];
    alertsData.forEach((alert) => {
      const prodType = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.ProductPlanType,
      )[0];
      const mfw = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.Manufacturer,
      )[0];
      const cat = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.Category,
      )[0];
      const subCat = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.SubCategory,
      )[0];
      const dealer = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.Dealer,
      )[0];
      const dealerLoc = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.DealerLocation,
      )[0];
      const buyingGroup = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.BuyingGroup,
      )[0];
      const moduleType = alert.data.filter(
        (alertData) => alertData.alertFilterType === AlertFilterType.ModuleType,
      )[0];

      const alertDetail = {
        alertId: alert.alertIdOriginal,
        effectiveDateFrom: alert.effectiveDateFrom,
        effectiveDateTo: alert.effectiveDateTo,
        status: alert.alertStatus,
        message: alert.message,
        prodType: this.formatDataArray(prodType),
        mfw: this.formatDataArray(mfw),
        cat: this.formatCatDataArray(cat),
        subCat: this.formatDataArray(subCat),
        dealer: this.formatDataArray(dealer),
        dealerLoc: this.formatDealerLocationDataArray(dealerLoc),
        buyingGroup: this.formatDataArray(buyingGroup),
        moduleType: this.formatDataArray(moduleType),
      };

      this.alertsList.push(alertDetail);
      alData = {
        alert_id: alertDetail.alertId,
        status: alertDetail.status,
        message: alertDetail.message,
        eff_date_from: alert.effectiveDateFrom,
        eff_date_to: alert.effectiveDateTo,
        prod_type:
          alertDetail.prodType.length > 0
            ? alertDetail.prodType.map((rData) => rData.key).join(' | ')
            : 'ALL',
        mfw:
          alertDetail.mfw.length > 0
            ? alertDetail.mfw.map((rData) => rData.key).join(' | ')
            : 'ALL',
        cat:
          alertDetail.cat.length > 0
            ? alertDetail.cat.map((rData) => rData.key).join(' | ')
            : 'ALL',
        sub_cat:
          alertDetail.subCat.length > 0
            ? alertDetail.subCat.map((rData) => rData.key).join(' | ')
            : 'ALL',
        dealer:
          alertDetail.dealer.length > 0
            ? alertDetail.dealer.map((rData) => rData.key).join(' | ')
            : 'ALL',
        dealer_loc:
          alertDetail.dealerLoc.length > 0
            ? alertDetail.dealerLoc.map((rData) => rData.key).join(' | ')
            : 'ALL',
        buying_group:
          alertDetail.buyingGroup.length > 0
            ? alertDetail.buyingGroup.map((rData) => rData.key).join(' | ')
            : 'ALL',
        module_type:
          alertDetail.moduleType.length > 0
            ? alertDetail.moduleType.map((rData) => rData.key).join(' | ')
            : 'ALL',
      };
      resultData.push(alData);
    });
    return resultData;
  }

  ellipsisOptionClick(event) {
    // not implemented
  }

  formatDataArray(itemArray) {
    const resultArray = itemArray
      ? itemArray.refData.map((d) => {
          return { key: d.referenceLabel, value: d.referenceData };
        })
      : [];
    return resultArray;
  }

  formatDealerLocationDataArray(itemArray) {
    const resultArray = itemArray
      ? itemArray.refData.map((d) => {
          return { key: d.referenceLabel, value: d.referenceData.toString() };
        })
      : [];
    return resultArray;
  }

  formatCatDataArray(itemArray) {
    let resultArray = itemArray
      ? itemArray.refData.map((d) => {
          return { key: d.referenceLabel, value: d.referenceData };
        })
      : [];
    resultArray = resultArray.filter((a, i) => resultArray.findIndex((s) => a.key === s.key) === i);
    return resultArray;
  }

  onCreateUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
      this.loadAlerts(true);
    }
  }

  onRowDeleteSave(rowData: any) {
    this.isEditing = false;
    rowData.insertUserName = this.azureService.accountId;
    this.isLoading = true;

    const params = {
      isDeleted: true,
      alertIdOriginal: rowData.alert_id,
      insertUserName: this.azureService.accountId,
    };
    this.querySubscriptions.push(
      this.search.createupdateAlertData(params).subscribe(
        ({ data, loading }: any) => {
          this.dataList.splice(this.selectedRowIndex, 1);
          const successMessage = 'Deleted successfully.';
          this.isLoading = false;

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: successMessage,
          });
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving, please try again.',
          });
        },
      ),
    );
  }

  onClickAdd(event, overlaypanel: OverlayPanel) {
    this.isCreatingNew = true;
    this.onRowEdit(event, overlaypanel);
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.onRowDeleteSave(this.selectedItem);
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  onRowEdit(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.createUpdateAlertTitle = this.isCreatingNew ? 'Create New Alert' : 'Update Alert';

    this.selectedRow = this.isCreatingNew ? {} : this.alertsList[this.selectedRowIndex];
    this.isCreatingNew = false;
    this.displayAlertInfoModal = true;
  }

  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
