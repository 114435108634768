<div class="crm-container">
  <app-section-header [title]="'Generate Pre-Authorization'" *ngIf="buttonAuth['Authorize'] && !disableRequest"
    [permission]="{type:'div', operation:'ADD', permission:'Pre-Authorization', category: 'Claim'}"
    [optionHeader]="opHeader" />
  <app-section-header [title]="'Generate Pre-Authorization'" *ngIf="!buttonAuth['Authorize'] || disableRequest" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="col.width?{'width':col.width}:[]">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template *ngIf="(col.type === 'text' || col.type === 'select' || col.type === 'fixed')"
                pTemplate="output">{{ rowData[col.field]}}</ng-template>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text" [(ngModel)]="rowData[col.field]" />
                <p-dropdown *ngIf="col.type === 'select'" [options]="dropdown[col.field]"
                  [(ngModel)]="rowData[col.field]">
                </p-dropdown>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="'Authorize Approved Amount'" [(visible)]="displayPreAuthDialog" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <app-tab-spinner *ngIf="isLoading" />
  <div class="modal-content" *ngIf="!isLoading">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="form-row" *ngFor="let formField of formFields">
        <form-field [formField]="formField" [form]="form"></form-field>
      </div>
    </form>
  </div>
  <p-toast></p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" [disabled]="isClicked" label="Cancel">
        Pre-Authorization Request
      </button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>