import { Component, OnInit } from '@angular/core';
import { CrmDocumentType } from '../../shared/constants/enums';
import { DealerService } from '../dealer.service';

@Component({
  selector: 'dl-file-upload',
  templateUrl: 'dealer-document-upload-tab.template.html',
})
export class DealerDocumentUploadComponent implements OnInit {
  documentType = CrmDocumentType;
  attachmentId: number;
  constructor(private dealerService: DealerService) {}

  ngOnInit() {
    if (this.dealerService.dealerGroupsIdOriginal) {
      this.attachmentId = this.dealerService.dealerGroupsIdOriginal;
    }
  }
}
