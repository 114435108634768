import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { DealerLocationStatus } from '../dealer.constants';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const dealerLocation = gql`
  query(
    $status: String!
    $number: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $phoneNumber: String
    $dealerGroupNumber: String
    $limit: Int
    $offset: Int
  ) {
    getDealerLocationSearchResults(
      status: $status
      number: $number
      name: $name
      city: $city
      state: $state
      zipcode: $zipCode
      phoneNumber: $phoneNumber
      dealerGroupNumber: $dealerGroupNumber
      offset: $offset
      limit: $limit
    ) {
      sellerIdOriginal
      status
      number
      name
      city
      state
      phoneNumber
      status
      zipCode
    }
  }
`;

@Injectable()
export class SearchDealerLocationService {
  constructor(private apollo: Apollo) {}

  getDealerLocationSearchDetails = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: dealerLocation,
      variables: {
        ...searchParams,
        status: searchParams.status.value || '',
        state: (searchParams.state && searchParams.state.value) || ''
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
