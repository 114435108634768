import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../../interfaces/role-authorization.interface';
import { CoverageFormFieldService } from '../../../coverage-form-field.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { InsuranceCompanyService } from '../../insurance-company.service';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'cv-insurance-company-accounting',
  templateUrl: 'insurance-company-accounting.template.html',
  styleUrls: ['insurance-company-accounting.scss'],
  providers: [FormFieldControlService, CoverageFormFieldService],
})
export class InsuranceCompanyAccountingComponent extends FormCanDeactivate implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  authDetails: IRoleAuthorizationService;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);

  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: CoverageFormFieldService,
    private insuranceCompanyService: InsuranceCompanyService,
  ) {
    super();
    this.service.getInsuranceCompanyAccountFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: InsuranceCompanyAccountingComponent,
      generalArray: this.formFields,
    };
    // this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      const request = this.form.getRawValue()
      this.insuranceCompanyService.insuranceCompanyDataModel.account.arAccount = request.arAccount;
      this.insuranceCompanyService.insuranceCompanyDataModel.account.apAccount = request.apAccount;
      this.insuranceCompanyService.insuranceCompanyDataModel.account.glAccount = request.glAccount;
      this.formValueChanged.emit(true);
    });

    this.insuranceCompanyService.isDataAvailable.subscribe({
      next: (isDataAvailable) => {
        if (isDataAvailable) {
          this.form.patchValue({
            arAccount: this.insuranceCompanyService.insuranceCompanyDataModel.account.arAccount,
            apAccount: this.insuranceCompanyService.insuranceCompanyDataModel.account.apAccount,
            glAccount: this.insuranceCompanyService.insuranceCompanyDataModel.account.glAccount,
          });
        }
      },
    });
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    this.formValueChanged.emit(this.form.dirty);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
