import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { FormField } from '../../../shared/form-field/form-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TextAreaField } from '../../../shared/form-field/textarea-field';
import { CheckBoxField } from '../../../shared/form-field/checkbox-field';

@Injectable()
export class ProblemService {
  getStandardInfo(): Observable<any[]> {
    return of([
      {
        model: '1154',
        ofClaims: '157',
        sameFailure: '1',
      },
    ]);
  }
  getTroubleshootInfo(): Observable<any[]> {
    return of([
      {
        fixed: '',
        notFixed: '',
        troubleshooting: 'Does the unit have any power',
        success: '16',
      },
    ]);
  }
  getFailureFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'failureDate',
        label: 'Failure/Loss Date',
        type: 'Date',
        required: true,
        isPDate: true,
        maxDate: new Date(),
        order: 1,
      }),
      new TextboxField({
        key: 'failureProductDOP',
        label: 'Failure Date - Product DOP [Days]',
        type: 'text',
        value: '',
        disabled: true,
        order: 2,
      }),
      new TextboxField({
        key: 'reportedDate',
        label: 'Report Date',
        type: 'Date',
        required: true,
        isPDate: true,
        maxDate: new Date(),
        order: 3,
      }),
      new TextboxField({
        key: 'failurePlanDOP',
        label: 'Failure Date - Plan DOP [Days]',
        type: 'text',
        value: '',
        disabled: true,
        order: 4,
      }),
      new DropdownField({
        isSingleselect: true,
        key: 'functionalStatus',
        label: 'Functional Status',
        required: true,
        options: [
          { key: 'Not Functional', value: 'Not Functional' },
          { key: 'Intermittently Functional', value: 'Intermittently Functional' },
          { key: 'Functional', value: 'Functional' },
        ],

        order: 5,
      }),
      new TextboxField({
        key: 'failureMfgExpiration',
        label: 'Failure Date - Mfg Expiration [Days]',
        value: '',
        type: 'text',
        disabled: true,
        order: 6,
      }),
      new DropdownField({
        key: 'failure',
        label: 'Failure',
        isSingleselect: true,
        required: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 7,
      }),
      new TextAreaField({
        key: 'failureDescription',
        label: 'Failure Description',
        required: true,
        rows: 6,
        order: 8,
      }),
      new CheckBoxField({
        key: 'proposedNewSolution',
        label: '',
        type: 'checkbox',
        options: [{ key: 'proposedNewSolution', value: 'Proposed a New Technical Solution' }],
        order: 9,
      }),
      new TextAreaField({
        key: 'additionalTroubleshootingDesc',
        label: 'Additional Troubleshooting Description',
        rows: 6,
        order: 10,
      }),
      new TextAreaField({
        key: 'resolutionDescription',
        label: 'Resolution Description',
        rows: 6,
        order: 11,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getADHFailureFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'failureDate',
        label: 'Failure/Loss Date',
        type: 'Date',
        order: 1,
      }),
      new TextboxField({
        key: 'failureProductDOP',
        label: 'Failure Date - Product DOP',
        type: 'Date',
        order: 2,
      }),
      new TextboxField({
        key: 'reportedDate',
        label: 'Report Date',
        type: 'Date',
        order: 3,
      }),
      new TextboxField({
        key: 'failurePlanDOP',
        label: 'Failure Date - Plan DOP',
        type: 'Date',
        order: 4,
      }),
      new DropdownField({
        key: 'functionalStatus',
        label: 'Functional Status',
        options: [
          { key: 'Not Functional', value: 'Not Functional' },
          { key: 'Intermittently Functional', value: 'Intermittently Functional' },
          { key: 'Functional', value: 'Functional' },
        ],
        order: 5,
      }),
      new TextboxField({
        key: 'failureMfgExpiration',
        label: 'Failure Date - Mfg Expiration',
        type: 'Date',
        order: 6,
      }),
      new DropdownField({
        key: 'usedBy',
        label: 'Who was using it?',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 7,
      }),
      new DropdownField({
        key: 'functionalityStatus',
        label: 'Functionality Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 8,
      }),
      new DropdownField({
        key: 'locationOfAccident',
        label: 'Location of Accident',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 9,
      }),
      new DropdownField({
        key: 'failure',
        label: 'Failure',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 10,
      }),
      new TextAreaField({
        key: 'failureDescription',
        label: 'Failure Description',
        rows: 6,
        order: 11,
      }),
      new CheckBoxField({
        key: 'proposedNewSolution',
        label: '',
        type: 'checkbox',
        options: [{ key: 'proposedNewSolution', value: 'Proposed a New Technical Solution' }],
        order: 12,
      }),
      new TextAreaField({
        key: 'additionalTroubleshootingDesc',
        label: 'Additional Troubleshooting Description',
        rows: 6,
        order: 13,
      }),
      new TextAreaField({
        key: 'resolutionDescription',
        label: 'Resolution Description',
        rows: 6,
        order: 14,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getTheftLossFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'failureDate',
        label: 'Failure/Loss Date',
        type: 'Date',
        order: 1,
      }),
      new TextboxField({
        key: 'failureProductDOP',
        label: 'Failure Date - Product DOP',
        type: 'Date',
        order: 2,
      }),
      new TextboxField({
        key: 'reportedDate',
        label: 'Report Date',
        type: 'Date',
        order: 3,
      }),
      new TextboxField({
        key: 'failurePlanDOP',
        label: 'Failure Date - Plan DOP',
        type: 'Date',
        order: 4,
      }),
      new DropdownField({
        key: 'claimType',
        label: 'Claim Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 5,
      }),
      new TextboxField({
        key: 'failureMfgExpiration',
        label: 'Failure Date - Mfg Expiration',
        type: 'Date',
        order: 6,
      }),
      new DropdownField({
        key: 'policeReportFiled',
        label: 'Police Report Filed?',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 7,
      }),
      new DropdownField({
        key: 'case',
        label: 'Case #',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 8,
      }),
      new DropdownField({
        key: 'usedBy',
        label: 'Who was using it?',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 9,
      }),
      new DropdownField({
        key: 'location',
        label: 'Where did it happen?',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 10,
      }),

      new DropdownField({
        key: 'locationLocked',
        label: 'Was the location locked?',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 11,
      }),

      new TextAreaField({
        key: 'descriptionOfTheft',
        label: 'Description of theft or loss',
        rows: 6,
        order: 12,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
