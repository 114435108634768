import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';
import { transactionManagementQuery } from '../../contract-data-queries.constant';
import { FormField } from '../../../shared/form-field/form-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { of } from 'rxjs';

const createUpdateTransaction = gql`
  mutation(
    $transactionManagementIdOriginal: Int
    $transactionYear: String
    $transactionMonth: String
    $closed: Boolean
    $insertUserName: String
    $isDeleted: Boolean
  ) {
    createUpdateTransaction(
      transactionManagementInput: {
        transactionManagementIdOriginal: $transactionManagementIdOriginal
        transactionYear: $transactionYear
        transactionMonth: $transactionMonth
        closed: $closed
        insertUserName: $insertUserName
        isDeleted: $isDeleted
      }
    ) {
      transactionManagementId
      transactionManagementIdOriginal
      transactionYear
      transactionMonth
      closed
      addedTimeStamp
      insertUserName
      updatedTimeStamp
      isDeleted
    }
  }
`;

const createTransactionYear = gql`
  mutation($transactionYear: String, $insertUserName: String) {
    createTransactionYear(transactionYear: $transactionYear, insertUserName: $insertUserName) {
      transactionManagementId
      transactionManagementIdOriginal
      transactionYear
      transactionMonth
      closed
      addedTimeStamp
      insertUserName
      updatedTimeStamp
      isDeleted
    }
  }
`;

const getTransactionYear = gql`
  query {
    getTransactionYear {
      key
      value
    }
  }
`;
@Injectable({
  providedIn: 'root'
})
export class TransactionManagementService {
  constructor(private apollo: Apollo) {}

  getTransactinYearFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        required: true,
        key: 'transactionYear',
        label: 'Transaction Year',
        order: 0
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getTransactionManagement = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: transactionManagementQuery,
      variables: { transactionYear: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateTransaction = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createUpdateTransaction,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });

  createTransactionYear = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createTransactionYear,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });

  getTransactionYear = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getTransactionYear,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
