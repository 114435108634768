<div class="refund-information-tab">
  <app-section-header [title]="'Refund Information'" />
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
  <div class="sub-section" [hidden]="isLoading">
    <p-table>
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns"></th>
        </tr>
      </ng-template>
    </p-table>
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid col-12">
        <div class="form-row" *ngFor="let formField of formFields; let i = index;"
          [ngClass]="{'col-12': (i === 6 || i === 7), 'col-4': true}">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
</div>