import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { FormField } from '../../../shared/form-field/form-field';
import { DealerFormFieldService } from '../../../services/dealer-form-field.service';
import { DealerLocationService } from '../dealer-location.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'dealer-loaction-tab',
  templateUrl: 'dealer-location-tab.template.html',
  styleUrls: ['dealer-location-tab.scss'],
})
export class DealerLocationTabComponent implements OnInit, OnDestroy {
  contactInfo: FormField<any>[] = [];
  generalInfo: FormField<any>[] = [];
  mailAddressInfo: any;
  billingAddressInfo: any;
  contactFormData = {};
  querySubscriptions: Subscription[] = [];
  constructor(
    private dealerService: DealerFormFieldService,
    private roleService: RoleAuthorizationService,
    public dealerLocationService: DealerLocationService,
  ) {
    this.querySubscriptions.push(
      this.dealerService.getContactInformationFields().subscribe((data) => {
        this.contactInfo = data;
      }),
    );
    this.querySubscriptions.push(
      this.dealerService.getGeneralInfoFields().subscribe((data) => {
        this.generalInfo = data;
      }),
    );
  }

  ngOnInit() {
    this.dealerLocationService.billingAddressFinal = {
      ...this.dealerLocationService.billingAddress,
    };
    this.dealerLocationService.mailingAddressFinal = {
      ...this.dealerLocationService.mailingAddress,
    };
    this.contactFormData = { ...this.dealerLocationService.contactFormData };
  }

  // onBillingAddressChange(data) {
  //   debugger;
  //   this.dealerLocationService.billingAddressFinal = { ...data };
  // }
  // onMailAddressChange(data) {
  //   this.dealerLocationService.mailingAddressFinal = { ...data };
  // }
  onContactInfoChange(data) {
    this.dealerLocationService.contactFormDataFinal = { ...data };
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
