<div class="crm-container">
  <app-section-header [title]="'Servicers'" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table #dt1 styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="selectedColumns"
      [value]="dataList" dataKey="servicerProfilesIdOriginal" editMode="row" [hidden]="isLoading"
      [globalFilterFields]="['servicerName']" [scrollable]="true" scrollHeight="500px">
      <ng-template pTemplate="caption">
        <span class="p-input-icon-left ml-1">
          <i class="pi pi-search search-icon"></i>
          <input class="input-text" pInputText type="text" (input)="filter(dt1,$event)" placeholder="Search keyword" />
        </span>
        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header" class="multi-select"
          selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
      </ng-template><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" width="10px">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing"
        let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor><ng-template pTemplate="input"><input class="input-text" *ngIf="col.type === 'text'"
                  type="text" [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'radio'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /><input type="radio" *ngIf="col.type === 'radio'" name="rowData[col.id]"
                  label="" (click)="onRadioButtonClick($event,rowData)"
                  value="rowData"></ng-template><ng-template *ngIf="col.type === 'text'"
                pTemplate="output">{{rowData[col.field]}}</ng-template><ng-template *ngIf="col.type === 'checkbox'"
                pTemplate="output"><input type="checkbox" [(ngModel)]="rowData[col.field]"
                  disabled="disabled" /></ng-template><ng-template *ngIf="col.type === 'radio'"
                pTemplate="output"><input type="radio" name="rowData[col.id]" label=""
                  (click)="onRadioButtonClick($event,rowData)"
                  value="rowData"></ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #opRow appendTo="body"><p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader appendTo="body">
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-toast position="center"></p-toast>
<p-dialog header="Alert" [(visible)]="displayOnInvalidAddress" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <div class="modal-content">
    <p>Looks like address is invalid. Are you sure want to proceed with dispatch?</p>
    <p>Suggested address : {{suggestedAddress}}</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="OnConfirmToDispatch()">
        Proceed</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="OnCancelToDispatch()">
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog>