import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisHeaderOptions,
  ellipsisModificationOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ServicerInsuranceService } from './servicer-insurance.service';
import { buttonStatus } from '../../../shared/constants/button-class';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { ServicerService } from '../servicer.service';
import { generalDateFormatter } from '../../../shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { CrmService } from '../../../services/crm-service';
import { ModuleType } from '../../../shared/constants/enums';
import { TabHandlerService } from '../../../services/tab-handler.service';
import moment from 'moment';
import { copy } from '../../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'insurance-menu',
  templateUrl: 'servicer-insurance.template.html',
  styleUrls: ['servicer-insurance.scss'],
  providers: [ServicerInsuranceService, MessageService],
})
export class ServicerInsuranceComponent implements OnInit {
  dataList: any[] = [];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisModificationOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  currentMaxId = 0;
  isTableLoading: boolean;
  querySubscriptions: Subscription[] = [];
  servicerTypeService: any;
  servicersId: any;
  isLoading: boolean;
  tabKey: any;
  isDisabled = true;
  item: any;
  constructor(
    private servicerInsurancService: ServicerInsuranceService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private servicerService: ServicerService,
    private azureService: AzureLoginService,
    private messageService: MessageService,
    private crmService: CrmService,
    private tabHandlerService: TabHandlerService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    // Commented - As of now it is not require. Anyone can edit the records.
    // const editPermissionList = [
    //   'Admin',
    //   'Claims Manager',
    //   'Special Team A-CC-SN'
    // ];
    const deletePermissionList = ['Admin', 'Claims Manager', 'Special Team A-CC-SN'];
    // Commented - As of now it is not require. Anyone can edit the records.
    // if (!editPermissionList.includes(this.azureService.roleName)) {
    //   this.ellipsisOptions = this.ellipsisOptions.filter(el => el.value !== 'edit');
    // }
    if (!deletePermissionList.includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((el) => el.value !== 'delete');
    }
    this.editConstant = uuidv4();
    if (this.servicerService.serviceFormData) {
      this.servicersId = this.servicerService.serviceFormData.servicersId;
    }
    this.cols = [
      { field: 'provider', header: 'PRODUCER', type: 'text' },
      { field: 'policy_type', header: 'POLICY TYPE', type: 'select' },
      { field: 'policy_description', header: 'POLICY DESCRIPTION', type: 'text' },
      { field: 'name', header: 'INSURER', type: 'text' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'policy_begin_date', header: 'POLICY BEGIN DATE', type: 'date' },
      { field: 'policy_end_date', header: 'POLICY END DATE', type: 'date' },
      { field: 'comments', header: 'COMMENTS', type: 'text' },
      { field: 'valid_insurance', header: 'VALID INSURANCE ', type: 'button' },
      { field: 'expiration_notification', header: 'EXPIRATION NOTIFICATION', type: 'select' },
    ];

    this.dropdown = {
      expiration_notification: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      policy_type: [
        { label: '', value: '' },
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'G', value: 'G' },
        { label: 'U', value: 'U' },
        { label: 'W', value: 'W' },
        { label: 'Other', value: 'Other' },
      ],
    };
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.loadInsurance();
        }
      },
    });
    this.loadInsurance();

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Insurance',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Insurance',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  loadInsurance() {
    const servicerProfilesId =
      this.servicerService.serviceFormData &&
      this.servicerService.serviceFormData.servicerProfilesIdOriginal;
    if (servicerProfilesId) {
      this.isLoading = true;
      this.servicerInsurancService
        .getServicerInsurance({ servicerProfilesId })
        .subscribe(({ data }) => {
          this.isLoading = false;
          this.currentMaxId = 0;
          const response = data.getServicerInsurance;
          if (response) {
            this.dataList = response.map((el) => {
              el = this.formatData(el);
              this.currentMaxId += 1;
              el.id = this.currentMaxId;
              return el;
            });
          } else {
            this.dataList = [];
          }
        });
    }
  }

  // FROM API row to table row data
  formatData(item) {
    return {
      id: 0,
      isNew: false,
      provider: item.provider,
      name: item.name,
      policy_type: item.type,
      policy_description: this.getTypeDescription(item.type, item.typeDescription),
      number: item.number,
      policy_begin_date: generalDateFormatter(item.dateFrom),
      policy_end_date: generalDateFormatter(item.dateTo),
      comments: item.comments,
      valid_insurance: item.validInsurance,
      expiration_notification: item.notify ? 'Yes' : 'No',
      servicerInsurancesIdOriginal: item.servicerInsurancesIdOriginal,
    };
  }

  getTypeDescription(event, description) {
    if (event === 'G' || event === 'C') {
      return 'General Liability';
    }
    if (event === 'A') {
      return 'Automobile Liability';
    }
    if (event === 'W') {
      return 'Workers Compensation';
    }
    if (event === 'B') {
      return 'Bailee Insurance Coverage';
    }
    if (event === 'U') {
      return 'Umbrella Liability';
    }
    return description;
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onDropDownChange(event, index) {
    // tslint:disable:no-string-literal
    if (event === 'G' || event === 'C') {
      const description = 'General Liability';
      this.dataList[index]['policy_description'] = description;
      this.isDisabled = true;
    }
    if (event === 'A') {
      const description = 'Automobile Liability';
      this.dataList[index]['policy_description'] = description;
      this.isDisabled = true;
    }
    if (event === 'W') {
      const description = 'Workers Compensation';
      this.dataList[index]['policy_description'] = description;
      this.isDisabled = true;
    }
    if (event === 'B') {
      const description = 'Bailee Insurance Coverage';
      this.dataList[index]['policy_description'] = description;
      this.isDisabled = true;
    }
    if (event === 'U') {
      const description = 'Umbrella Liability';
      this.dataList[index]['policy_description'] = description;
      this.isDisabled = true;
    }
    if (event === 'Other') {
      this.dataList[index]['policy_description'] = '';
      this.isDisabled = false;
    }
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }

  policyDateChange(value: any, rowData: any) {
    rowData.policy_begin_date = value;
  }

  policyEndDateChange(value: any, rowData: any) {
    rowData.policy_end_date = value;
  }

  disableDate() {
    return false;
  }

  // Validate the fields and should not be empty.
  validateServicerInsurance(rowData) {
    const errField = [];
    const errObj = { isValid: true, errMessage: '' };
    if (!rowData.provider) {
      errField.push(' PRODUCER');
    }
    if (!rowData.name) {
      errField.push(' INSURER');
    }
    if (!rowData.policy_type) {
      errField.push(' POLICY TYPE');
    }
    if (!rowData.policy_description) {
      errField.push(' POLICY DESCRIPTION');
    }
    if (!rowData.policy_begin_date) {
      errField.push(' POLICY BEGIN DATE');
    }
    if (!rowData.policy_end_date) {
      errField.push(' POLICY END DATE');
    }
    if (!rowData.expiration_notification) {
      errField.push(' EXPIRATION NOTIFICATION');
    }
    if (!rowData.number) {
      errField.push(' NUMBER');
    }
    const endDate = moment(rowData.policy_end_date);
    const startDate = moment(rowData.policy_begin_date);
    if (endDate < startDate) {
      errField.push(' POLICY END DATE should be greater than POLICY BEGIN DATE.');
    }
    if (errField.length > 0) {
      errObj.isValid = false;
      errObj.errMessage = `Please fill ${errField.join()}`;
    }
    return errObj;
  }

  onRowEditSave(rowData: any, isDelete: boolean = false) {
    const errObj = this.validateServicerInsurance(rowData);
    if (errObj.isValid) {
      delete this.clonedRowData[rowData.id];
      rowData.insertUserName = this.azureService.accountId;
      this.isLoading = true;
      if (isDelete) {
        rowData.isDeleted = true;
      } else {
        rowData.isDeleted = false;
      }
      const payload = Object.assign({}, rowData);
      payload.servicersId = this.servicersId;
      payload.notify = rowData.expiration_notification === 'Yes' ? true : false;
      if (payload.isNew) {
        payload.servicerInsurancesIdOriginal = null;
      }
      this.servicerInsurancService.createServicerInsurance(payload).subscribe(
        ({ data }) => {
          this.isLoading = false;
          const response = data.createUpdateServicerInsurance;
          if (response) {
            let successMessage = 'Saved successfully.';
            if (isDelete) {
              this.dataList.splice(this.selectedRowIndex, 1);
              successMessage = 'Deleted successfully.';
            } else {
              const item = this.formatData(response);
              item.id = this.dataList[this.selectedRowIndex].id;
              item[`isNew`] = false;
              this.dataList[this.selectedRowIndex] = item;
            }
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: successMessage,
            });
          }
          this.isEditing = false;
        },
        (err) => {
          this.isLoading = false;
          this.isEditing = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving, please try again.',
          });
        },
      );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errObj.errMessage,
      });
      this.isEditing = true;
      document.getElementById(this.editConstant + this.selectedItem.id).click();
    }
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.isEditing = false;
      this.loadInsurance();
    }
  }

  onClickAdd(event) {
    let rowItem = {
      id: 0,
      provider: '',
      name: '',
      policy_type: '',
      policy_description: '',
      number: '',
      policy_begin_date: '',
      policy_end_date: '',
      comments: '',
      valid_insurance: '',
      expiration_notification: 'No',
      servicerInsurancesIdOriginal: null,
    };
    this.currentMaxId += 1;
    rowItem.id = this.currentMaxId;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(servicerInsurancesIdOriginal = null) {
    let servicerInsurancesId = servicerInsurancesIdOriginal;
    if (this.selectedItem && this.selectedItem.servicerInsurancesIdOriginal) {
      servicerInsurancesId = this.selectedItem.servicerInsurancesIdOriginal;
    }
    const payload = {
      idOriginal: servicerInsurancesId,
      moduleName: ModuleType.SERVICERINSURANCE,
    };
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Servicer Insurance ?',
      accept: () => {
        this.isLoading = true;
        this.crmService.deleteCrmModule(payload).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            if (!data.deleteCrmModule) {
              throw new Error('Error in deleting the Servicer Insurance.');
            }
            this.isLoading = true;
            this.loadInsurance();
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
      },
    });
  }
}
