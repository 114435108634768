import { Injectable } from '@angular/core';

@Injectable()
export class PollingService {
  private polling = true;

  public isPolling(): boolean {
    return this.polling;
  }

  public togglePolling() {
    this.polling = !this.polling;
  }

  public startPolling() {
    this.polling = true;
  }

  public stopPolling() {
    this.polling = false;
  }
}
