import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const getFailureTypeSearch = gql`
  query($tierDescription: String, $failureDescriptions: String, $offset: Int, $limit: Int) {
    getFailureTypeSearch(
      tierDescription: $tierDescription
      failureDescriptions: $failureDescriptions
      offset: $offset
      limit: $limit
    ) {
      failureTypeId
      failureTypeIdOrigninal
      tierDescription
      failureDescription
    }
  }
`;
const createUpdateFailureType = gql`
  mutation(
    $tierDescription: String
    $failureDescriptions: String
    $failureTypeIdOrigninal: Int
    $isDeleted: Boolean
    $InsertUserName: String!
  ) {
    createUpdateFailureType(
      tierDescription: $tierDescription
      failureDescriptions: $failureDescriptions
      failureTypeIdOrigninal: $failureTypeIdOrigninal
      isDeleted: $isDeleted
      InsertUserName: $InsertUserName
    )
  }
`;
@Injectable({
  providedIn: 'root'
})
export class FailureTypeService {
  constructor(private apollo: Apollo) {}

  getFailureTypes = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getFailureTypeSearch,
      variables: {
        ...searchParams
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateFailureTypes = params =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateFailureType,
      variables: {
        ...params
      }
      // tslint:disable-next-line: semicolon
    });
}
