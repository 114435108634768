import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sec-modal',
  templateUrl: 'sec-modal.template.html',
  styleUrls: ['sec-modal.scss'],
  providers: [FormFieldControlService],
})
export class SecModalComponent implements OnInit, OnChanges {
  @Input() formFields: FormField<string>[] = [];
  @Input() isSubmit: boolean;

  form: FormGroup;
  payLoad = '';

  constructor(
    private qcs: FormFieldControlService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  public ngOnChanges(changes: SimpleChanges) {
    console.log('changes.strings.currentValue', changes.strings);
    // if(changes.isSubmit.currentValue) {
    //   this.onSubmit();
    // }
  }

  onSubmit() {
    console.log(this.form.getRawValue());

    const createRequest = this.form.value;

    const headers = {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
    };
    const response = this.http.post(environment.userUrl, JSON.stringify({ CreateUsers: true }), {
      headers,
    });
    console.log('Create Users Response: ' + response);
    // TODO: on submit
  }
}
