import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { FormField } from '../../../shared/form-field/form-field';
import { DealerFormFieldService } from '../../../services/dealer-form-field.service';
import { PayeeService } from '../payee.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'payee-main-tab',
  templateUrl: 'payee-main-tab.template.html',
  styleUrls: ['payee-main-tab.scss'],
})
export class PayeeMainTabComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  contactInfo: FormField<any>[] = [];
  generalInfo: FormField<any>[] = [];
  mailingAddress: any[];
  billingAddress: any[];
  contactFormData: any;
  querySubscriptions: Subscription[] = [];
  constructor(
    private dealerService: DealerFormFieldService,
    private roleService: RoleAuthorizationService,
    private payeeService: PayeeService,
  ) {
    this.querySubscriptions.push(
      this.dealerService.getContactInformationFields().subscribe((data) => {
        this.contactInfo = data;
      }),
    );
    this.dealerService.getGeneralInfoFields().subscribe((data) => {
      this.generalInfo = data;
    });
    this.searchParams = this.payeeService.payeeConfig && this.payeeService.payeeConfig.searchParams;
  }

  ngOnInit() {
    this.billingAddress = { ...this.payeeService.payeeBillingAddress };
    this.mailingAddress = { ...this.payeeService.payeeBillingAddress };
    this.contactFormData = { ...this.payeeService.payeeContactInfo };
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
