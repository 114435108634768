import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { EmailService } from '../../../../services/email.service';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ServiceOrderService } from '../../service-order.service';
import { ServiceOrderDataService } from '../../service-order-data.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { AddressCategoryType } from '../../../../shared/constants/enums';
import { NoteService } from '../../../../shared/notes/note.service';
import { ExpenseChargeModalService } from './expense-charges-modal.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfigurationTypeService } from 'src/app/modules/crm/administration/configuration/configuration.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import {
  getFloat,
  getMenuTypeKey,
  getMenuTypeValue,
} from 'src/app/modules/crm/shared/utilities/common-utilities';

const PRE_APPROVAL_REQUEST_ALLOW_ROLES = [
  'Call Center Manager',
  'Call Center Supervisor',
  'Claims Manager',
  'Claims 1',
  'Claims 1 Plus',
  'CSR 2',
  'CSR 2 Plus',
  'CSR 3',
  'Data Administration',
  'Special Team A-CC-SN',
  'Back Office',
  'Back Office Lead',
  'Admin',
];

const EXPENSE_STATUS_ROLES = ['Admin', 'Claims Manager', 'Claims 1', 'Claims 1 Plus'];

const EXPENSE_TYPE_ROLES = ['Admin', 'Claims Manager', 'Claims 1 Plus'];

@Component({
  selector: 'expense-charges-modal',
  templateUrl: 'expense-charges-modal.template.html',
  styleUrls: ['./expense-charges-modal.scss'],
  providers: [FormFieldControlService, ExpenseChargeModalService, MessageService],
})
export class ExpenseChargesModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Input() servicerMarkupRates: any[];
  @Input() servicerRatesSchedule: any[];
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  globalOptions: any[];
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displayEmailModal: true;
  isLoading = true;
  querySubscription: Subscription;
  validForm = true;
  templateResponse = null;
  currentExpenseType = null;
  productInfoModel = null;
  constructor(
    private qcs: FormFieldControlService,
    private expenseChargeModalService: ExpenseChargeModalService,
    private serviceOrderDataService: ServiceOrderDataService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
    private roleService: RoleAuthorizationService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private serviceOrderService: ServiceOrderService
  ) {
    this.expenseChargeModalService.getExpenseChargesFields().subscribe((data) => {
      this.formFields = this.roleService.validateFormFields(data);
    });
  }

  ngOnInit() {
    if (this.formData && this.formData === 'header') {
      this.formFields = this.formFields.map((input) => {
        if (input.disabled) {
          input.disabled = false;
        }
        return input;
      });
    }
    if (
      this.serviceOrderDataService.serviceOrderDataModel.claimData &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.claimStatusDescription ===
        'PRE-APPROVAL REQUEST' &&
      !PRE_APPROVAL_REQUEST_ALLOW_ROLES.includes(this.azureService.roleName)
    ) {
      this.formFields[7] = { ...this.formFields[7], disabled: true };
    }

    if (!EXPENSE_STATUS_ROLES.includes(this.azureService.roleName)) {
      this.formFields[0].options = [
        {
          key: 'Requested',
          value: 'Requested',
        },
        {
          key: 'Denied',
          value: 'Denied',
        },
      ];
    }
    if (EXPENSE_TYPE_ROLES.includes(this.azureService.roleName)) {
      this.formFields[1].options.push({ key: 'Contract Refund', value: 'Contract Refund' });
    }
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.formData && this.formData !== 'header') {
      // Approved amount
      const approvedAmount = this.formData.adjustedAmount
        ? this.getApprovedAmount(
            parseFloat(this.formData.adjustedPrice),
            parseFloat(this.formData.adjustedMarkup),
            parseFloat(this.formData.discount),
            parseInt(this.formData.quantity, 10),
          )
        : 0;

      this.form.patchValue({
        ...this.formData,
        status: { key: this.formData.status, value: this.formData.status },
        expenseType: {
          key: getMenuTypeKey(this.globalOptions, 'expense_type', this.formData.expenseType),
          value: this.formData.expenseType,
        },
        approvedAmount,
      });
      this.currentExpenseType = this.formData.expenseType;
    }
    this.productInfoModel = this.serviceOrderDataService.serviceOrderDataModel.productInfoModel;
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'expense_type,high_end',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.globalOptions = configList;
        if (
          this.formData &&
          this.formData !== 'header' &&
          this.formData.expenseType.startsWith('Labor')
        ) {
          this.form.patchValue({
            expenseType: {
              key: this.formData.expenseType,
              value: getMenuTypeValue(
                this.globalOptions,
                'expense_type',
                this.formData.expenseType,
              ),
            },
          });
        }
        const highEndBrands = configList
          .filter((el) => el.menuType === 'high_end')
          .map((e) => e.key.toLowerCase());
        let options = configList
          .filter((el) => el.menuType === 'expense_type' && !el.value.startsWith('Labor-'))
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        let laborList = [];
        if (this.productInfoModel) {
          if (
            highEndBrands.includes(this.productInfoModel.manufacturerManufacturer.toLowerCase())
          ) {
            laborList = configList
              .filter((el) => el.value.startsWith('Labor-highEnd'))
              .map((e) => {
                return {
                  key: e.key,
                  value: e.value,
                };
              });
          } else {
            laborList = configList
              .filter(
                (el) => el.value.startsWith('Labor-') && !el.value.startsWith('Labor-highEnd'),
              )
              .map((e) => {
                return {
                  key: e.key,
                  value: e.value,
                };
              });
          }
        }
        options.push(...laborList);
        options = options.sort((a, b) =>
          a.value.toLowerCase().localeCompare(b.value.toLowerCase()),
        );
        this.formFields.find((el) => el.key === 'expenseType').options = options;
        this.formValueChanged.emit(true);
      });
  }

  onChange(event) {
    let expenseTypeChangeFlag = false;
    // Only Parts have Markup.
    const payload = this.form.getRawValue();
    const expenseType = payload.expenseType.value;
    let requestedMarkup = 0;
    let adjustedMarkup = 0;
    const requestedPrice = parseFloat(this.form.controls[`requestedPrice`].value);
    const adjustedPrice = parseFloat(this.form.controls[`adjustedPrice`].value);
    const quantity = parseInt(this.form.controls[`quantity`].value, 10);
    const discount = parseFloat(this.form.controls[`discount`].value);

    // Dropdowns do not have event.target.id correctly. Need to track internally.
    if (payload && payload.expenseType && payload.expenseType.value) {
      if (this.currentExpenseType && payload.expenseType.value !== this.currentExpenseType) {
        expenseTypeChangeFlag = true;
      }
      this.currentExpenseType = payload.expenseType.value;
    }

    if (
      (event.target && event.target.id === 'requestedPrice' && event.target.value) ||
      expenseTypeChangeFlag
    ) {
      if (this.servicerMarkupRates && this.servicerMarkupRates.length) {
        const selectedMarkup = this.servicerMarkupRates.find(
          (x) => x.rangeFrom <= requestedPrice && x.rangeTo >= requestedPrice,
        );
        if (
          selectedMarkup &&
          selectedMarkup.effectiveDate &&
          new Date(selectedMarkup.effectiveDate) < new Date() &&
          expenseType === 'Parts'
        ) {
          requestedMarkup = this.getMarkup(requestedPrice, parseFloat(selectedMarkup.percentage));
        } else {
          requestedMarkup = 0;
        }
      }
      this.form.controls[`requestedMarkup`].setValue(this.getRoundUp(requestedMarkup));
      const requestedAmount = this.getTotalAmount(requestedPrice, requestedMarkup, quantity);
      this.form.controls[`requestedAmount`].setValue(this.getRoundUp(requestedAmount));
      this.form.controls[`adjustedPrice`].setValue(this.getRoundUp(requestedPrice));
      // Defaulting Adjusted fields
      const approvedAmount = this.getApprovedAmount(
        requestedPrice,
        requestedMarkup,
        discount,
        quantity,
      );
      this.form.controls[`adjustedAmount`].setValue(this.getRoundUp(requestedAmount));
      this.form.controls[`adjustedMarkup`].setValue(this.getRoundUp(requestedMarkup));
      this.form.controls[`approvedAmount`].setValue(
        approvedAmount
          ? this.getRoundUp(parseFloat(approvedAmount))
          : this.getRoundUp(approvedAmount),
      );
    } else if (
      event.target &&
      (event.target.id === 'discount' ||
        event.target.id === 'adjustedPrice' ||
        event.target.id === 'adjustedMarkup')
    ) {
      if (event.target.id === 'adjustedMarkup' || event.target.id === 'discount') {
        if (
          this.form.controls[`adjustedMarkup`].value !== '' &&
          this.form.controls[`adjustedMarkup`].value !== null &&
          !isNaN(this.form.controls[`adjustedMarkup`].value)
        ) {
          adjustedMarkup = parseFloat(this.form.controls[`adjustedMarkup`].value);
        } else {
          adjustedMarkup = null;
        }
      }
      this.calculateAdjustedValues(
        requestedPrice,
        expenseType,
        adjustedMarkup,
        adjustedPrice,
        quantity,
        discount,
      );
    } else if (event.target && event.target.id === 'quantity' && event.target.value) {
      if (this.servicerMarkupRates && this.servicerMarkupRates.length) {
        const selectedMarkup = this.servicerMarkupRates.find(
          (x) => x.rangeFrom <= requestedPrice && x.rangeTo >= requestedPrice,
        );
        if (
          selectedMarkup &&
          selectedMarkup.effectiveDate &&
          new Date(selectedMarkup.effectiveDate) < new Date() &&
          expenseType === 'Parts'
        ) {
          requestedMarkup = this.getMarkup(requestedPrice, parseFloat(selectedMarkup.percentage));
          adjustedMarkup = this.getMarkup(adjustedPrice, parseFloat(selectedMarkup.percentage));
        }
      } else {
        requestedMarkup = 0;
        adjustedMarkup = 0;
      }
      const requestedAmount = this.getTotalAmount(requestedPrice, requestedMarkup, quantity);
      const adjustedAmount = this.getTotalAmount(adjustedPrice, adjustedMarkup, quantity);
      const approvedAmount = this.getApprovedAmount(
        adjustedPrice,
        adjustedMarkup,
        discount,
        quantity,
      );
      this.form.controls[`requestedAmount`].setValue(this.getRoundUp(requestedAmount));
      this.form.controls[`adjustedAmount`].setValue(this.getRoundUp(adjustedAmount));
      this.form.controls[`adjustedMarkup`].setValue(this.getRoundUp(adjustedMarkup));
      this.form.controls[`approvedAmount`].setValue(
        approvedAmount ? parseFloat(approvedAmount).toFixed(2) : approvedAmount,
      );
    }

    if (event.value && event.value.key !== 'Denied' && this.formData.status === 'Denied') {
      this.calculateAdjustedValues(
        requestedPrice,
        expenseType,
        adjustedMarkup,
        adjustedPrice,
        quantity,
        discount,
      );
    } else if (event.value && event.value.key === 'Denied' && this.formData.status === 'Denied') {
      this.form.controls[`adjustedAmount`].setValue(0);
    }
  }

  calculateAdjustedValues(
    requestedPrice,
    expenseType,
    adjustedMarkup,
    adjustedPrice,
    quantity,
    discount,
  ) {
    if (this.servicerMarkupRates && this.servicerMarkupRates.length) {
      const selectedMarkup = this.servicerMarkupRates.find(
        (x) => x.rangeFrom <= requestedPrice && x.rangeTo >= requestedPrice,
      );
      if (
        selectedMarkup &&
        selectedMarkup.effectiveDate &&
        new Date(selectedMarkup.effectiveDate) < new Date() &&
        expenseType === 'Parts'
      ) {
        if (
          ((this.form.controls[`adjustedMarkup`].value !== '' &&
            this.form.controls[`adjustedMarkup`].value !== null &&
            !isNaN(this.form.controls[`adjustedMarkup`].value)) ||
            parseFloat(this.form.controls[`adjustedMarkup`].value) === 0) &&
          adjustedMarkup !== this.getMarkup(adjustedPrice, parseFloat(selectedMarkup.percentage))
        ) {
          if (
            adjustedMarkup === 0 &&
            parseFloat(this.form.controls[`adjustedMarkup`].value) !== 0
          ) {
            adjustedMarkup = this.getMarkup(adjustedPrice, parseFloat(selectedMarkup.percentage));
          } else {
            adjustedMarkup = parseFloat(this.form.controls[`adjustedMarkup`].value);
          }
        } else if (adjustedMarkup === null) {
          adjustedMarkup = null;
        } else {
          adjustedMarkup = this.getMarkup(adjustedPrice, parseFloat(selectedMarkup.percentage));
        }
      } else {
        adjustedMarkup = 0;
      }
    }
    const adjustedAmount = this.getTotalAmount(adjustedPrice, adjustedMarkup, quantity);
    const approvedAmount = this.getApprovedAmount(
      adjustedPrice,
      adjustedMarkup,
      discount,
      quantity,
    );

    this.form.controls[`adjustedAmount`].setValue(this.getRoundUp(adjustedAmount));
    this.form.controls[`adjustedMarkup`].setValue(this.getRoundUp(adjustedMarkup));
    this.form.controls[`approvedAmount`].setValue(
      approvedAmount ? parseFloat(approvedAmount).toFixed(2) : approvedAmount,
    );
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
  }

  onSubmit() {
    const payload = this.transformExpenseCharges({ ...this.form.getRawValue() });
    // const {expenseCharges} = this.serviceOrderDataService.serviceOrderDataModel;
    if (this.validateLaborCharges(this.form.getRawValue())) {
      this.querySubscription = this.serviceOrderDataService
        .createUpdateExpenseCharges([{ ...payload }])
        .subscribe((res: any) => {
          this.serviceOrderService.refreshClaimOnUpdate.next(true);
          this.serviceOrderDataService.serviceOrderDataModel.expenseCharges = [
            ...res.data.createUpdateExpenseCharges,
          ];
          this.formSubmitEvent.emit(res.data.createUpdateExpenseCharges);
          this.displayDialog = false;
          this.displayDialogChange.emit(this.displayDialog);
          this.serviceOrderDataService.isExpenseDataAvailable.next(true);
        });
    }
  }

  validateLaborCharges(payload) {
    if (
      this.servicerRatesSchedule &&
      this.productInfoModel &&
      this.servicerRatesSchedule.length &&
      payload.expenseType.value.startsWith('Labor-')
    ) {
      const currentDispatchDate =
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchDate;
      const dispatchDate = currentDispatchDate
        ? currentDispatchDate.includes('.')
          ? this.datePipe.transform(currentDispatchDate, 'yyyy-MM-dd')
          : currentDispatchDate.substring(0, 10)
        : null;
      const claimDispatchDate = dispatchDate && new Date(dispatchDate);
      let servicerRates = this.servicerRatesSchedule
        .filter((sch) => sch.effectiveDate && new Date(sch.effectiveDate) <= claimDispatchDate)
        .filter(
          (sRates) =>
            (!sRates.categoryDescription ||
              sRates.categoryDescription === 'ALL' ||
              sRates.categoryDescription === this.productInfoModel.categoryDescription) &&
            (!sRates.subcategoryDescription ||
              sRates.subcategoryDescription === 'ALL' ||
              sRates.subcategoryDescription === this.productInfoModel.subcategoryDescription),
        );
      if (servicerRates.length) {
        servicerRates = servicerRates.sort(
          (objA, objB) =>
            new Date(objB.addedTimeStamp).getTime() - new Date(objA.addedTimeStamp).getTime(),
        );
        const laborType = payload.expenseType.value.split('-')[1];
        const { expenseCharges } = this.serviceOrderDataService.serviceOrderDataModel;
        let totalReqAmount = getFloat(payload.requestedAmount);
        if (expenseCharges && expenseCharges.length) {
          const similarExpense = expenseCharges.filter(
            (exCh) =>
              exCh.expenseType === payload.expenseType.key &&
              exCh.expenseChargesIdOriginal !== this.formData.expenseChargesIdOriginal,
          );
          totalReqAmount += similarExpense.reduce((accumulator, obj) => {
            return accumulator + getFloat(obj.requestedAmount);
          }, 0);
        }
        if (totalReqAmount > getFloat(servicerRates[0][laborType])) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Total Requested Amount for the selected expense type is greater than Servicer Rate!`,
          });
          return false;
        }
      }
    }
    return true;
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }

  getMarkup(price, percentage) {
    if (price && percentage) {
      let markupPrice = (price * percentage) / 100;
      // Since Adjusted Price and Adjusted Markup are Money fields with 2 decimal places, round markup for calculations.
      if (markupPrice !== 0) {
        markupPrice = Math.round(markupPrice * 100 + Number.EPSILON) / 100;
      }
      return markupPrice;
    }
    return null;
  }
  getTotalAmount(price, markup, quantity) {
    if (price) {
      markup = markup || 0;
      return (price + markup) * quantity;
    }
    return null;
  }

  getApprovedAmount(adjustedPrice, adjustedMarkup, discount, quantity) {
    if (adjustedPrice) {
      adjustedMarkup = adjustedMarkup || 0;
      discount = discount || 0;
      const adjustedAmount = (adjustedPrice + adjustedMarkup) * quantity;
      return (adjustedAmount - (adjustedAmount * discount) / 100).toFixed(2);
    }
    return null;
  }

  transformExpenseCharges(payload) {
    let claimIdOriginal = null;
    let claimNumber = null;
    let serviceOrderNumber = null;

    if (this.serviceOrderDataService.serviceOrderDataModel.claimData) {
      claimIdOriginal =
        this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
      claimNumber = this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber
        ? this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber
        : this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
      serviceOrderNumber =
        this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber;
    }

    payload = {
      ...payload,
      adjustedAmount: parseFloat(payload.adjustedAmount) || null,
      adjustedMarkup: parseFloat(payload.adjustedMarkup) || null,
      adjustedPrice: parseFloat(payload.adjustedPrice) || null,
      requestedAmount: parseFloat(payload.requestedAmount) || null,
      requestedMarkup: parseFloat(payload.requestedMarkup) || null,
      requestedPrice: parseFloat(payload.requestedPrice) || null,
      discount: parseFloat(payload.discount) || null,
      quantity: parseInt(payload.quantity, 10) || null,
      status: payload.status && payload.status.key,
      expenseChargesIdOriginal: this.formData?.expenseChargesIdOriginal || null,
      expenseType: payload.expenseType && payload.expenseType.key,
      claimNumber: String(claimNumber),
      claimId: claimIdOriginal,
      serviceOrderNumber,
      insertUserName: this.azureService.accountId || null,
      isAddedFromApp: true
    };
    delete payload.approvedAmount;
    return payload;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  getRoundUp(amount) {
    if (amount) {
      return amount.toFixed(2);
    }
    return amount;
  }

  discountFieldValidation(evt) {
    if (evt.key === '.') {
      return false;
    }
    return true;
  }

  priceFieldValidation(event) {
    if (event.key === 'Backspace') {
      return true;
    }
    const checkValue = (this.form.controls[event.target.id].value.toString() + event.key).split(
      '.',
    );
    if (checkValue.length === 2 && checkValue[1].length >= 3) {
      return false;
    }
    return true;
  }
}
