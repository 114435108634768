import { Component, OnInit } from '@angular/core';
import { QueueService } from './queue.service';
import { Queue } from './queue';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisOptions } from '../../shared/constants/ellipsis-options';

@Component({
  selector: 'queue',
  templateUrl: 'queue.template.html',
  styleUrls: ['queue.scss'],
  providers: [QueueService],
})
export class QueueComponent implements OnInit {
  searchResults: Queue[];
  cols: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  options: any[] = ellipsisOptions;
  selectedEllipsisItem: any;

  constructor(private search: QueueService) {}

  ngOnInit() {
    this.searchResults = this.search.getQueues();
    this.cols = [
      { field: 'reviewed', header: 'REVIEWED', type: 'checkbox' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'category', header: 'CATEGORY', type: 'text' },
      { field: 'tier', header: 'TIER', type: 'text' },
    ];
    this.options = [
      { label: 'Add', value: 'add' },
      { label: 'Update', value: 'update' },
    ];
  }
  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
}
