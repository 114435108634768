import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractFormFieldService } from '../services/contract-form-field.service';
import { FormField } from '../shared/form-field/form-field';
import { Observable } from 'rxjs';
import { RoleAuthorizationService } from '../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../interfaces/role-authorization.interface';

import {
  CONTRACTSLIST,
  SERVICELIST,
  NOTIFICATIONLIST,
  BUYINGGROUPLIST,
  TROUBLESHOOTINGLIST,
  DEALERLOCATIONLIST,
  COVERAGELIST,
  CATALOGLIST,
  FORMLIST,
  CLAIMLIST,
  SALESREGISTERLIST,
  DOCUMENTLIST,
  SECURITYLIST,
  CONFIGURATIONLIST,
  REPORTSLIST,
} from './sidebar-navigation-constants';

const sidebarTitles = ['', 'Contractor', 'Dealer'];
@Component({
  selector: 'crm-sidebar',
  templateUrl: 'crm-sidebar.template.html',
  styleUrls: ['crm-sidebar.scss'],
  providers: [ContractFormFieldService],
})
export class CrmSidebarComponent implements OnInit {
  public strings: any;
  public sidebarCollapsed = false;
  public sidebarTitle = '';
  public selectedTab = 2;
  public contractsList = CONTRACTSLIST;
  public serviceList = SERVICELIST;
  public notificationList = NOTIFICATIONLIST;
  public buyingGroupList = BUYINGGROUPLIST;
  public troubleshootingList = TROUBLESHOOTINGLIST;
  public dealerLocationList = DEALERLOCATIONLIST;
  public coverageList = COVERAGELIST;
  public catalogList = CATALOGLIST;
  public formList = FORMLIST;
  public claimList = CLAIMLIST;
  public salesregisterlist = SALESREGISTERLIST;
  public documentList = DOCUMENTLIST;
  public securityList = SECURITYLIST;
  public configurationList = CONFIGURATIONLIST;
  public reportsList = REPORTSLIST;
  public authDetails: IRoleAuthorizationService;

  formFields$: Observable<FormField<any>[]>;
  public theBoundCallback: any;
  public selectedNavItemId: number;
  public CloseDialog: any;

  constructor(
    private router: Router,
    private currentActivatedRoute: ActivatedRoute,
    service: ContractFormFieldService,
    private roleService: RoleAuthorizationService,
  ) {
    this.formFields$ = service.getFormFields();
  }

  ngOnInit() {
    const allLists = [
      this.contractsList,
      this.serviceList,
      this.notificationList,
      this.buyingGroupList,
      this.troubleshootingList,
      this.dealerLocationList,
      this.coverageList,
      this.catalogList,
      this.formList,
      this.claimList,
      this.salesregisterlist,
      this.documentList,
      this.securityList,
      this.configurationList,
      this.reportsList,
    ];
    /* old way */
    for (let index = 0; index < allLists.length; index++) {
      this.authDetails = {
        authorizationType: AuthorizationType.LeftPanel,
        component: CrmSidebarComponent,
        generalArray: allLists[index],
      };
      allLists[index] = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    }
    /* New way */
    for (let index = 0; index < allLists.length; index++) {
      allLists[index] = Object.assign(this.roleService.validateSideMenu(allLists[index]));
    }
    this.contractsList = allLists[0];
    this.serviceList = allLists[1];
    this.notificationList = allLists[2];
    this.buyingGroupList = allLists[3];
    this.troubleshootingList = allLists[4];
    this.dealerLocationList = allLists[5];
    this.coverageList = allLists[6];
    this.catalogList = allLists[7];
    this.formList = allLists[8];
    this.claimList = allLists[9];
    this.salesregisterlist = allLists[10];
    this.documentList = allLists[11];
    this.securityList = allLists[12];
    this.configurationList = allLists[13];
    this.reportsList = allLists[14];
    this.theBoundCallback = this.theCallback.bind(this);
  }
  public theCallback(id) {
    this.selectedNavItemId = id;
  }

  public toggleCollapse() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }
}
