import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckBoxField } from '../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { FormField } from '../../../shared/form-field/form-field';
import { TextAreaField } from '../../../shared/form-field/textarea-field';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { ZipCodePattern } from '../../../shared/constants/validation-regex';

const getServiceAreaQuery = gql`
  query($serviceOrderNumber: String!) {
    getExpensePreAuthDetails(serviceOrderNumber: $serviceOrderNumber) {
      preAuthRequestId
      preAuthRequestIdOriginal
      approvedAmount
      authorizationCode
      preAuthNotes
      isDeleted
      claimId
      servicerId
      tenantId
      userName
      addedTimeStamp
      serviceOrderNumber
    }
  }
`;
const getModelNumbersByMfgQuery = gql`
  query($manufacturerName: String!) {
    getServicerServiceAreaModelNumber(manufacturerName: $manufacturerName)
  }
`;
const createUpdateServiceAreaQuery = gql`
  mutation(
    $claimNumber: String!
    $insertUserName: String!
    $approvedAmount: Float!
    $servicerId: Int!
    $preAuthNotes: String
    $serviceOrderNumber: String!
  ) {
    createOrUpdateExpensePreAuth(
      expensePreAuthorization: {
        insertUserName: $insertUserName
        approvedAmount: $approvedAmount
        preAuthNotes: $preAuthNotes
        claimNumber: $claimNumber
        servicerId: $servicerId
        serviceOrderNumber: $serviceOrderNumber
      }
    ) {
      preAuthRequestId
      preAuthRequestIdOriginal
      approvedAmount
      authorizationCode
      preAuthNotes
      isDeleted
      claimId
      servicerId
      tenantId
      addedTimeStamp
      userName
      serviceOrderNumber
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ServicerServiceArea {
  constructor(private apollo: Apollo, private dropdownDataService: DropdownDataService) {}
  getServiceArea(): Observable<any[]> {
    return of([
      {
        id: 1,
        manufacturerName: 'ALL',
        modelNumber: 'ALL',
        zipCode: '0701',
        radius: '0'
      },
      {
        id: 2,
        manufacturerName: 'ALL',
        modelNumber: 'ALL',
        zipCode: '0702',
        radius: '1'
      },
      {
        id: 3,
        manufacturerName: 'ALL',
        modelNumber: 'ALL',
        zipCode: '0703',
        radius: '2'
      }
    ]);
  }

  getServiceAreaFields(): Observable<any[]> {
    const optionsManufacturerList = this.dropdownDataService.manufacturerList
      ? this.dropdownDataService.manufacturerList.map(x => ({
          key: x.value,
          value: x.value
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'manufacturerName',
        label: 'Manufacturer',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsManufacturerList],
        isSingleselect: true,
        isChangeEvent: true,
        virtualScroll: true,
        filter: true,
        disabled: true,
        order: 1
      }),
      new DropdownField({
        key: 'modelNumber',
        label: 'Model',
        options: [],
        virtualScroll: true,
        showToggleAll: false,
        required: true,
        order: 2
      }),
      new TextboxField({
        key: 'zipCode',
        label: 'Zip Code',
        disabled: true,
        required: true,
        validationRegex: ZipCodePattern,
        validationMessage: 'Please enter a valid zip code with 5 digits.',
        order: 3
      }),
      new TextboxField({
        key: 'radius',
        label: 'Radius',
        required: true,
        order: 4
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServiceAreaDetails = queryParams =>
    this.apollo.use('crm').query<any>({
      query: getServiceAreaQuery,
      variables: { serviceOrderNumber: queryParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getModelNumbersByMfg = queryParam => {
    return this.apollo.use('crm').query<any>({
      query: getModelNumbersByMfgQuery,
      variables: { manufacturerName: queryParam },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };

  createUpdateServiceAreaDetails = queryParams =>
    this.apollo.use('crm').mutate({
      mutation: createUpdateServiceAreaQuery,
      variables: {
        ...queryParams,
        claimNumber: queryParams.claimNumber,
        insertUserName: queryParams.insertUserName,
        approvedAmount: queryParams.approvedAmount,
        serviceId: queryParams.serviceId,
        preAuthNotes: queryParams.preAuthNotes,
        serviceOrderNumber: queryParams.serviceOrderNumber
      }
      // tslint:disable-next-line: semicolon
    });
}
