import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ImportClaimFieldService } from '../import-claim/import-claim.service';
import { ImportFile, FieldNames } from '../../shared/import-file/import-file';
import { ImportFileService } from '../../shared/import-file/import-file.service';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { CrmDocumentType } from '../../shared/constants/enums';

@Component({
  selector: 'import-claim',
  templateUrl: 'import-claim.template.html',
  styleUrls: ['import-claim.scss'],
  providers: [
    MessageService,
    ImportFileService,
    FormFieldControlService,
    ContractFormFieldService,
    ImportClaimFieldService,
  ],
})
export class ImportClaimComponent extends FormCanDeactivate implements OnInit {
  formFields: FormField<string>[] = [];
  form: FormGroup;
  public records: ImportFile;
  public fieldsNames: FieldNames;
  public validCSV: string;
  public headersRow: any[];
  public index: any;
  public fileResponse: any;
  formModalInfo: FormField<any>[] = [];
  documentType = CrmDocumentType;
  constructor(
    private messageService: MessageService,
    private importFileService: ImportFileService,
    private service: ContractFormFieldService,
    private qcs: FormFieldControlService,
    private formService: ImportClaimFieldService,
  ) {
    super();
    this.formService
      .getImportClaimFormFields()
      .subscribe((formModalInfo) => (this.formModalInfo = formModalInfo));
  }
  ngOnInit() {
    this.importFileService.getFileImportResponse().subscribe((res) => {
      this.fileResponse = res;
    });

    this.cleanRecords();
  }

  // Upload and read CSV file
  onUpload(event: { files: any }) {
    const uploadedFiles: any[] = [];

    for (const file of event.files) {
      uploadedFiles.push(file);
    }

    const reader = new FileReader();
    reader.readAsText(uploadedFiles[0]);

    reader.onload = () => {
      const csvData = reader.result;
      const csvRecordsArray = (csvData as string).split(/\r\n|\n/);

      if (this.getHeaderArray(csvRecordsArray)) {
        let csvArr = [];

        this.showSuccess();
        this.headersRow = this.getHeaderArray(csvRecordsArray);
        csvArr = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, this.headersRow);
        this.validCSV = 'Passed';

        this.fieldsNames = {
          validation_name: 'Validation',
          total_count_name: 'Total Count',
          failed_count_name: 'Failded Count',
          warning_count_name: 'Warning Count',
          duplicated_count_name: 'Duplicated Count',
          ready_to_process_count_name: 'Ready To Process Count',
          processed_count_name: 'Processed Count',
          count_name: this.fileResponse.ModuleName + ' Count',
          cost_name: this.fileResponse.ModuleName + ' Cost',
        };

        this.records = {
          validation: this.validCSV,
          total_count: csvArr.length,
          failed_count: 0,
          warning_count: 0,
          duplicated_count: 0,
          ready_to_process_count: csvArr.length,
          processed_count: 0,
          cost: '0',
          contract_count: csvArr.length,
        };
      } else {
        this.showError();
        this.validCSV = 'Failed';
        this.records = {};
      }
    };
  }

  // Extract header from CSV array and validate name of the columns with the array of mandatory column data
  getHeaderArray(csvRecordsArr: any) {
    let headers: string[];

    for (let i = 0; i < csvRecordsArr.length; i++) {
      if (
        this.fileResponse.AcceptedColumns.every((v: any) => csvRecordsArr[i].split(',').includes(v))
      ) {
        this.index = i;
        headers = (csvRecordsArr[i] as string).split(',');
      }
    }
    if (headers) {
      const headerArray = [];
      for (const row of headers) {
        headerArray.push(row);
      }
      return headerArray;
    }
    return [];
  }

  // Extract only data rows from CSV array.
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, header: any) {
    const csvArr = [];

    for (let i = this.index + 1; i < csvRecordsArray.length - 1; i++) {
      const currentRecord = (csvRecordsArray[i] as string).split(',');
      csvArr.push(currentRecord);
    }

    return csvArr;
  }

  splitByColumns(headersRow: any, csvArr: any) {
    const rowToObject = (headers: any[], cells: { [x: string]: any }) =>
      headers.reduce((acc, header, i) => {
        acc[header] = cells[i];
        return acc;
      }, {});

    return csvArr.map((row) => rowToObject(headersRow, row));
  }

  sumCostColumn(namedColumns: any[]) {
    const sumTotal = namedColumns.reduce((prev, cur) => {
      return prev + Number(cur.ExtendedDealerCost.replace(/[^0-9.-]+/g, ''));
    }, 0);

    return sumTotal;
  }

  showError() {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Validation failed.' });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Validation completed successfully.',
    });
  }

  cleanRecords() {
    this.records = {
      validation: '',
      total_count: null,
      failed_count: null,
      warning_count: null,
      duplicated_count: null,
      ready_to_process_count: null,
      processed_count: null,
      cost: '',
      contract_count: null,
    };

    this.fieldsNames = {
      validation_name: '',
      total_count_name: '',
      failed_count_name: '',
      warning_count_name: '',
      duplicated_count_name: '',
      ready_to_process_count_name: '',
      processed_count_name: '',
      count_name: '',
      cost_name: '',
    };
  }

  fileReset() {
    this.cleanRecords();
  }
}
