import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../constants/graphql-constants';

const getHistoryItemsQuery = gql`
  query ($id: Int, $type: String, $offset: Int, $limit: Int) {
    getHistoryItems(
      historySearchInput: { refId: $id, offset: $offset, limit: $limit, type: $type }
    ) {
      dataValues
      header
      uiLabels
      dataTypes
    }
  }
`;

@Injectable()
export class HistoryItemsService {
  constructor(private apollo: Apollo) {}

  getHistoryItems = (data) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getHistoryItemsQuery,
      variables: {
        ...data,
      },
      fetchPolicy: 'network-only',
    });
}
