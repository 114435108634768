import { Injectable, Type } from '@angular/core';
import {
  IRoleAuthorizationService,
  IRoleAuthorizationRouteService,
  AuthorizationType,
} from '../interfaces/role-authorization.interface';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AzureLoginService } from './azure-login.service';

const userQuery = gql`
  query ($azureUserId: String!) {
    getAdminSettings(userName: "", azureUserId: $azureUserId) {
      usersId
      role_id
      userName
      email
      status
      azureUserId
      attachedRole
      userProfileType
      userProfileName
      userProfileNumber
      firstName
      lastName
    }
  }
`;

const rolePermissionsQuery = gql`
  query ($roleName: String!) {
    getAdminDisplayPermissions(permissionsType: RolePermissions, roleName: $roleName) {
      rolePermissionId
      roleName
      permissionCategory
      permissionDescription
      operationName
      componentName
      listName
      elementName
      parentComponentName
    }
  }
`;

const permissionsListQuery = gql`
  query ($permissionCategory: String!) {
    getAdminDisplayPermissions(
      permissionsType: PermissionsList
      permissionCategory: $permissionCategory
    ) {
      permissionsListId
      listName
      componentName
      elementName
      parentComponentName
    }
  }
`;

const operationQuery = gql`
  query {
    getAdminDisplayPermissions(permissionsType: Operations) {
      operationId
      operationName
    }
  }
`;

const permissionsQuery = gql`
  query ($roleName: String!) {
    getAdminDisplayPermissions(permissionsType: ModulePermissions, roleName: $roleName) {
      permissionId
      permissionCategory
      permissionDescription
      componentName
      listName
      elementName
      parentComponentName
    }
  }
`;
const getPermissionsSearchQuery = gql`
  query (
    $category: String
    $description: String
    $component: String
    $listName: String
    $elementName: String
    $parentComponentName: String
    $offset: Int
    $limit: Int
  ) {
    getPermissionsSearch(
      category: $category
      description: $description
      component: $component
      listName: $listName
      elementName: $elementName
      parentComponentName: $parentComponentName
      offset: $offset
      limit: $limit
    ) {
      permissionIdOriginal
      permissionCategory
      permissionDescription
      componentName
      listName
      elementName
      parentComponentName
    }
  }
`;
const getPermissionsListSearchQuery = gql`
  query (
    $componentName: String
    $listName: String
    $elementName: String
    $parentComponentName: String
    $offset: Int
    $limit: Int
  ) {
    getPermissionsListSearch(
      componentName: $componentName
      listName: $listName
      elementName: $elementName
      parentComponentName: $parentComponentName
      offset: $offset
      limit: $limit
    ) {
      permissionsListId
      permissionsListIdOriginal
      componentName
      listName
      elementName
      parentComponentName
    }
  }
`;

const createUpdatePermissions = gql`
  mutation (
    $permissionCategory: String
    $permissionDescription: String
    $componentName: String
    $listName: String
    $elementName: String
    $permissionIdOriginal: Int
    $isDeleted: Boolean
    $parentComponentName: String
    $insertUserName: String!
  ) {
    createUpdatePermissions(
      permissionInput: {
        permissionCategory: $permissionCategory
        permissionDescription: $permissionDescription
        componentName: $componentName
        listName: $listName
        elementName: $elementName
        permissionIdOriginal: $permissionIdOriginal
        isDeleted: $isDeleted
        parentComponentName: $parentComponentName
        insertUserName: $insertUserName
      }
    )
  }
`;
const createUpdatePermissionsList = gql`
  mutation (
    $componentName: String
    $elementName: String
    $parentComponentName: String
    $listName: String
    $isDeleted: Boolean
    $permissionListIdOriginal: Int
    $insertUserName: String
  ) {
    createUpdatePermissionsList(
      permissionsListInput: {
        componentName: $componentName
        elementName: $elementName
        parentComponentName: $parentComponentName
        listName: $listName
        isDeleted: $isDeleted
        permissionListIdOriginal: $permissionListIdOriginal
        insertUserName: $insertUserName
      }
    )
  }
`;

/*
########################################################################################
Structures
########################################################################################
Left Main Navigation:
-----------------------------------
  { id: 1, name: 'CT' }
########################################################################################
Left Sub Navigation:
-----------------------------------
    { id: 1, name: 'My Work Queue' }
########################################################################################
Form Fields
-----------------------------------
  new TextboxField({ key: 'contractNumber', label: 'Contract Number', order: 1 })
     new DropdownField({key: 'cultureCode',label: 'Culture Code',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],order: 8})
########################################################################################
Right Panel
-----------------------------------
    { component: ContractMainTabComponent, data: { name: 'Main' }, title: 'MA'}
########################################################################################
Option List
-----------------------------------
    { label: 'Print', value: 'print' }
########################################################################################
Buttons
-----------------------------------
{ name: 'Cancel',hidden: false,disabled: false,type: "button" },
*/

@Injectable()
export class RoleAuthorizationService {
  isLoading = false;
  querySubscription: Subscription;
  userDetails = {};
  PermissionsList = {};
  Permissions = {};
  Operations = {};
  userRole = 'SSD-Med'; // Default User Role if not in system.
  debug = false;
  debugElementName = 'ALL'; // Set to your element name you want to debug.

  constructor(
    private apollo: Apollo,
    private azureService: AzureLoginService,
  ) {}

  getUserDetails = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: userQuery,
      variables: { azureUserId: searchParams },
      fetchPolicy: 'network-only',
    });

  getRoleDetails = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: rolePermissionsQuery,
      variables: { roleName: searchParams },
      fetchPolicy: 'network-only',
    });

  getPermissionListDetails = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: permissionsListQuery,
      variables: { permissionCategory: searchParams },
      fetchPolicy: 'network-only',
    });

  getOperationDetails = () =>
    this.apollo.use('crm').query<any>({
      query: operationQuery,
      fetchPolicy: 'network-only',
    });

  getPermissionDetails = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: permissionsQuery,
      variables: { roleName: searchParams },
      fetchPolicy: 'network-only',
    });

  getPermissionsSearch = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: getPermissionsSearchQuery,
      variables: {
        ...searchParams,
      },
      fetchPolicy: 'network-only',
    });

  getPermissionsListSearch = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: getPermissionsListSearchQuery,
      variables: {
        ...searchParams,
      },
      fetchPolicy: 'network-only',
    });

  createUpdatePermissions = (params) =>
    this.apollo.use('crm').mutate({
      mutation: createUpdatePermissions,
      variables: {
        ...params,
      },
    });

  createUpdatePermissionsList = (params) =>
    this.apollo.use('crm').mutate({
      mutation: createUpdatePermissionsList,
      variables: {
        ...params,
      },
    });

  // public user = JSON.parse(localStorage.getItem('user'));
  public applyAuthorization(authDetails: IRoleAuthorizationService): object {
    const userPermissions = this.Permissions[this.userRole];
    const finalFormFields: any[] = [];
    const componentName = authDetails.component.name;
    let parentComponentName: any;
    if (authDetails.parantComponent) {
      parentComponentName = authDetails.parantComponent.name;
    }
    // If the component is not part of the permissions list, then return without any processing.
    if (
      !(componentName in this.PermissionsList) ||
      (parentComponentName && !(parentComponentName in this.PermissionsList[componentName]))
    ) {
      return authDetails.generalArray;
    }

    const arrayFields = authDetails.generalArray;
    for (const singleField of arrayFields) {
      // The fields are in different key locations based on the type.
      let fieldName = '';
      let options: any;
      if (
        authDetails.authorizationType === AuthorizationType.LeftPanel ||
        authDetails.authorizationType === AuthorizationType.Button
      ) {
        fieldName = singleField.name;
      }
      if (authDetails.authorizationType === AuthorizationType.RightPanel) {
        fieldName = singleField.data.name;
      }
      if (authDetails.authorizationType === AuthorizationType.OptionList) {
        fieldName = singleField.label;
        options = singleField.options;
      }
      if (authDetails.authorizationType === AuthorizationType.NoteType) {
        fieldName = singleField.type;
      }
      if (authDetails.authorizationType === AuthorizationType.FormField) {
        fieldName = singleField.label;
        options = singleField.options;
      }
      this.debugOut(fieldName, 'Found Field Name');
      // Only check permissions if it is in the list to check.
      if (
        componentName in this.PermissionsList &&
        ((parentComponentName &&
          parentComponentName in this.PermissionsList[componentName] &&
          fieldName in this.PermissionsList[componentName][parentComponentName]) ||
          (!parentComponentName && fieldName in this.PermissionsList[componentName]))
      ) {
        let fieldPermissions: any;
        this.debugOut(fieldName, 'Found in Permission List');

        if (parentComponentName) {
          fieldPermissions = this.PermissionsList[componentName][parentComponentName][fieldName];
        } else {
          fieldPermissions = this.PermissionsList[componentName][fieldName];
        }
        if (typeof fieldPermissions !== 'boolean' && 'Options' in fieldPermissions && options) {
          this.debugOut(fieldName, 'Found Options');
          const optionPermissions = this.retrieveSinglePermission(
            userPermissions,
            componentName,
            fieldName,
            parentComponentName,
          );
          const newOptionsList = [];
          const permissionListOptions = fieldPermissions.Options;
          const userPermissionOptions = optionPermissions.Options;
          for (const singleOption of options) {
            if (
              !singleOption.key ||
              (permissionListOptions && !(singleOption.key in permissionListOptions)) ||
              (userPermissionOptions && singleOption.key in userPermissionOptions)
            ) {
              newOptionsList.push(singleOption);
            }
          }
          singleField.options = newOptionsList;
          // Need to change the value if not in updated options.
          let foundValueKey = false;
          if (singleField && singleField.key !== null) {
            for (const singleOption of newOptionsList) {
              if (singleOption?.key === singleField.key) {
                foundValueKey = true;
              }
            }
          }
          if (!foundValueKey) {
            singleField.value = newOptionsList[0];
          }

          finalFormFields.push(singleField);
        } else {
          this.debugOut(fieldName, 'No Options Found');
          if (
            userPermissions &&
            componentName in userPermissions &&
            ((parentComponentName &&
              parentComponentName in userPermissions[componentName] &&
              fieldName in userPermissions[componentName][parentComponentName]) ||
              (!parentComponentName && fieldName in userPermissions[componentName]))
          ) {
            this.debugOut(fieldName, 'Permissions Found');
            let operations: any = {} as any;
            if (parentComponentName) {
              operations = userPermissions[componentName][parentComponentName][fieldName];
            } else {
              operations = userPermissions[componentName][fieldName];
            }
            if ('operation' in authDetails) {
              const operationName = authDetails.operation;
              if (operationName in operations.Operation) {
                finalFormFields.push(singleField);
              }
            } else {
              if (
                authDetails.authorizationType === AuthorizationType.Button &&
                'READ_ONLY' in operations.Operation
              ) {
                singleField.disabled = true;
              }
              // If the field only has READ_ONLY permissions, disable the field to view only.
              if (
                authDetails.authorizationType === AuthorizationType.FormField &&
                'READ_ONLY' in operations.Operation &&
                !('ADD' in operations.Operation)
              ) {
                singleField.disabled = true;
              }
              // If the field is defaulted to disabled and the user has edit permissions, enable it.
              if (
                authDetails.authorizationType === AuthorizationType.FormField &&
                'disabled' in singleField &&
                singleField.disabled === true &&
                'EDIT' in operations.Operation
              ) {
                singleField.disabled = false;
              }
              // eslint-disable-next-line no-unsafe-optional-chaining
              if ('READ_ONLY' in operations?.Operation) {
                finalFormFields.push(singleField);
              }
            }
          }
          this.debugOut(fieldName, 'No Permissions Found. Blocked');
        }
      } else {
        this.debugOut(fieldName, 'Not in Permissions List. Allowed');
        finalFormFields.push(singleField);
      }
    }
    if (this.debug) {
      console.log('Final Role Authorization Return: ' + JSON.stringify(finalFormFields));
    }
    return finalFormFields;
  }

  public isAuthorized(authDetails: IRoleAuthorizationRouteService) {
    const userPermissions = this.Permissions[this.userRole];
    const componentName = authDetails.component.name;
    const path = authDetails.path;
    if (componentName in this.PermissionsList && path in this.PermissionsList[componentName]) {
      if (componentName in userPermissions && path in userPermissions[componentName]) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  public isVisible(component: Type<any>, parentComponent?: Type<any>) {
    const userPermissions = this.Permissions[this.userRole];
    const componentName = component.name;
    let parentComponentName: any;
    if (parentComponent) {
      parentComponentName = parentComponent.name;
      if (
        componentName in this.PermissionsList &&
        parentComponentName in this.PermissionsList[componentName] &&
        'AngularComponent' in this.PermissionsList[componentName][parentComponentName]
      ) {
        if (
          componentName in userPermissions &&
          parentComponentName in userPermissions[componentName] &&
          'AngularComponent' in userPermissions[componentName][parentComponentName]
        ) {
          return true;
        }
        return false;
      }
      return true;
    } else if (
      componentName in this.PermissionsList &&
      'AngularComponent' in this.PermissionsList[componentName]
    ) {
      if (
        componentName in userPermissions &&
        'AngularComponent' in userPermissions[componentName]
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  private retrieveSinglePermission(
    userPermissions: object,
    componentName: string,
    fieldName: string,
    parentComponentName: string,
  ) {
    if (
      componentName in userPermissions &&
      ((parentComponentName &&
        parentComponentName in userPermissions[componentName] &&
        fieldName in userPermissions[componentName][parentComponentName]) ||
        (!parentComponentName && fieldName in userPermissions[componentName]))
    ) {
      return userPermissions[componentName][fieldName];
    }
    return {};
  }

  private debugOut(fieldName, message) {
    if (this.debug && (this.debugElementName === 'ALL' || this.debugElementName === fieldName)) {
      console.log(`Field Name [${fieldName}] ${message}`);
    }
  }
  public validateSideMenu(arr: any[]) {
    if (!arr.length) {
      return [];
    }
    const resp = arr.filter((nav) => {
      if (nav.hasPermissions) {
        const ocur = this.azureService.rolePermissions.filter(
          (e) =>
            e.permissionDescription === nav.permissions.permission &&
            e.permissionCategory === nav.permissions.category &&
            (e.operationName === nav.permissions.operation || e.operationName === 'ALL'),
        );
        if (ocur.length > 0) {
          return nav;
        }
      } else {
        return nav;
      }
    });
    return resp;
  }
  public validateSearch(arr: any[]) {
    const resp = arr.filter((nav) => {
      if (nav.hasPermissionsSearch) {
        const ocur = this.azureService.rolePermissions.filter(
          (e) =>
            e.permissionDescription === nav.permissionSearch.permission &&
            e.permissionCategory === nav.permissionSearch.category &&
            (e.operationName === nav.permissionSearch.operation || e.operationName === 'ALL'),
        );
        if (ocur.length > 0) {
          return nav;
        }
      } else {
        return nav;
      }
    });
    return resp;
  }
  validateFormFields(form: any[]) {
    const result = form.filter((input) => {
      if (input.hasPermissions) {
        const ocur = this.azureService.rolePermissions.filter(
          (e) =>
            e.permissionDescription === input.permissions.permission &&
            e.permissionCategory === input.permissions.category &&
            (e.operationName === input.permissions.operation || e.operationName === 'ALL'),
        );
        if (ocur.length > 0) {
          return input;
        } else {
          if (input.permissions.operation === 'EDIT') {
            input.disabled = true;
            return input;
          }
          if (input.permissions.operation === 'EDIT-READ') {
            const check = this.azureService.rolePermissions.filter(
              (e) =>
                e.permissionDescription === input.permissions.permission &&
                e.permissionCategory === input.permissions.category,
            );
            const canRead = check.findIndex((r) => r.operationName === 'READ_ONLY') !== -1;
            const canEdit = check.findIndex((r) => r.operationName === 'EDIT') !== -1;
            input.disabled = !canEdit;
            if (canRead) {
              return input;
            }
          }
        }
      } else {
        return input;
      }
    });
    return result;
  }
  validateIfPermissionsExist(permission: {
    category: string;
    operation: string;
    permission: string;
    type: string;
  }): boolean {
    const ocur = this.azureService.rolePermissions.filter(
      (e) =>
        e.permissionDescription === permission.permission &&
        e.permissionCategory === permission.category &&
        (e.operationName === permission.operation || e.operationName === 'ALL'),
    );
    return ocur.length > 0;
  }
}
