import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';

@Component({
  selector: 'form-modal',
  templateUrl: 'form-modal.template.html',
  styleUrls: ['form-modal.scss'],
  providers: [FormFieldControlService],
})
export class FormModalComponent extends FormCanDeactivate implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: FormFieldControlService) {
    super();
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  onSubmit() {
    // TODO: on submit
  }
}
