<div class="import-file">
  <div class="title" *ngIf="title">{{title}}</div>
  <div class="title" *ngIf="!title">Import Documents</div>
  <p-toast></p-toast>
  <p-table styleClass="p-datatable-gridlines" class="import-file__content">
    <ng-template pTemplate="header" let-columns="">
      <tr>
        <th *ngFor="let col of columns"></th>
        <label class="import-file__label crm-label">File</label>
        <p-fileUpload name="myfile[]" [disabled]="!(selectedDocument && selectedDocument.key)" chooseLabel="Browse"
          (uploadHandler)="onUpload($event)" [customUpload]="true" (onRemove)="fileReset()">
        </p-fileUpload>
      </tr>
    </ng-template>
  </p-table>
</div>