import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { FormField } from '../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ServiceOrderService } from '../service-order.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ServiceOrderDataService } from '../service-order-data.service';
import { dateSubstring } from '../../../shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { copy } from '../../../shared/utilities/common-utilities';
@Component({
  selector: 'sservice-claim-payement',
  templateUrl: 'service-claim-payement.template.html',
  styleUrls: ['service-claim-payement.scss'],
  providers: [FormFieldControlService],
})
export class ServiceClaimPaymentComponent extends FormCanDeactivate implements OnInit {
  formFields: FormField<string>[] = [];
  form: FormGroup;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  addresses: any;
  addressesClaim: any;
  contactInfo: any;
  servicerInfo: any;
  claimInfo: any;
  isServicerInfoAvailable: boolean;
  isClaimDataAvailable: boolean;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private qcs: FormFieldControlService,
    private service: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private azureService: AzureLoginService,
    private roleService: RoleAuthorizationService,
  ) {
    super();
    this.service.getClaimPaymentPayeeFields().subscribe((data: FormField<string>[]) => {
      this.formFields = this.roleService.validateFormFields(data);
      this.form = this.qcs.toFormGroup(this.formFields);
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.serviceOrderDataService.isServicerInfoAvailable.subscribe({
      next: (isServicerInfoAvailable) => {
        if (isServicerInfoAvailable) {
          if (this.serviceOrderDataService.serviceOrderDataModel.servicerInfo) {
            // The Claim Process uses the mailing address in the query, so do the same for Servicers.
            this.addresses = this.getMailingAddress(
              this.serviceOrderDataService.serviceOrderDataModel.servicerInfo.addresses,
            );
            this.contactInfo =
              this.serviceOrderDataService.serviceOrderDataModel.servicerInfo.contactInfo;
            this.servicerInfo = this.serviceOrderDataService.serviceOrderDataModel.servicerInfo;
            this.form.patchValue({
              servicerName: this.servicerInfo.servicerName,
              firstName: this.contactInfo.firstName,
              lastName: this.contactInfo.lastName,
              id: this.contactInfo.id ? this.contactInfo.id : '',
              phoneNumber1: this.contactInfo.phoneNumber1,
              email: this.contactInfo.email,
              claimPayMethod: this.servicerInfo.claimPayMethod,
              servicerNumber: this.servicerInfo.servicerNumber,
              fedTax: this.servicerInfo.fedTax,
            });
          }
          this.isServicerInfoAvailable = isServicerInfoAvailable;
        }
      },
    });
    this.serviceOrderDataService.isClaimDataAvailable.subscribe({
      next: (isClaimDataAvailable) => {
        if (isClaimDataAvailable) {
          if (this.serviceOrderDataService.serviceOrderDataModel.claimData) {
            this.addressesClaim = this.serviceOrderDataService.serviceOrderDataModel.claimData
              .addresses
              ? this.serviceOrderDataService.serviceOrderDataModel.claimData.addresses[0]
              : null;
            this.claimInfo = this.serviceOrderDataService.serviceOrderDataModel.claimData;
            this.form.patchValue({
              claimPaymentDate: dateSubstring(this.claimInfo.claimPaymentDate),
              checkAmount: this.claimInfo.checkAmount,
              checkNumber: this.claimInfo.checkNumber,
              externalBatchNumber: this.claimInfo.externalBatchNumber,
              externalBatchSequenceNumber: this.claimInfo.externalBatchSequenceNumber,
            });
            if (this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch) {
              this.contactInfo =
                this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contactInformation;
              if (this.contactInfo) {
                this.addressesClaim = this.serviceOrderDataService.serviceOrderDataModel
                  .contractInfo.contractAddresses
                  ? this.serviceOrderDataService.serviceOrderDataModel.contractInfo
                      .contractAddresses[0]
                  : null;
                this.form.patchValue({
                  servicerName: `${this.contactInfo.customerFirstName} ${this.contactInfo.customerLastName}`,
                  firstName: this.contactInfo.customerFirstName,
                  lastName: this.contactInfo.customerLastName,
                  phoneNumber1: this.contactInfo.customerPhone,
                  email: this.contactInfo.customerEmail,
                  claimPayMethod: 'CHK',
                });
              }
            }
          }
          this.isClaimDataAvailable = isClaimDataAvailable;
        }
      },
    });
  }

  onSubmit() {
    // functionality for on submit
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  changeEventDetection(event) {
    const payload = this.form.getRawValue();
    if (payload.checkNumber) {
      this.serviceOrderDataService.serviceOrderDataModel.claimData.checkNumber =
        payload.checkNumber;
    }
    if (payload.claimPaymentDate) {
      this.serviceOrderDataService.serviceOrderDataModel.claimData.claimPaymentDate =
        payload.claimPaymentDate;
    }
  }

  getMailingAddress(addresses) {
    let mailingAddress = null;
    for (const singleAddress of addresses) {
      if (singleAddress.isMailingAddress) {
        mailingAddress = singleAddress;
      }
    }
    return mailingAddress;
  }
}
