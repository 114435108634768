import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CoveredProduct } from './covered_product';
import { ContractService } from '../../contract.service';
import {
  coveredProductsHeaderOptions,
  coveredProductsRowOptions,
} from './covered-product.ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { ConfirmationService, MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import _each from 'lodash/forEach';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { getMMDDYYYFormat } from '../../../shared/utilities/date-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'covered-products',
  templateUrl: './covered-products.template.html',
  styleUrls: ['covered-products.scss'],
  providers: [MessageService],
})
export class CoveredProductsComponent implements OnInit, OnDestroy {
  coveredProduts: CoveredProduct[] = [];
  cols: any[];
  selectedColumns: any[];
  selectedItem: any;
  ellipsisRowOptions: any[] = coveredProductsRowOptions;
  ellipsisHeaderOptions: any[] = coveredProductsHeaderOptions;
  ellipsisRowOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  querySubscriptions: Subscription[] = [];
  selectedRowIndex: number;
  currentMaxId = 0;
  isLoading: boolean;
  authDetails: IRoleAuthorizationService;
  permissionsColsRows = [];
  permissionsCols = {};
  subcategoryOptions: any[];
  dropdown: any;
  canAdd = false;
  canEdit = true;
  displayHistory = false;
  historyData: any;
  constructor(
    private contractService: ContractService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private roleService: RoleAuthorizationService,
    private dropdownDataService: DropdownDataService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.dropdown = {
      fulfilled: [
        { label: '', value: '' },
        { label: 'NO', value: 'NO' },
        { label: 'YES', value: 'YES' },
      ],
    };
    this.cols = [
      { field: 'productNo', header: 'PROD#', type: 'id', label: 'productsId' },
      {
        field: 'productPurchaseDate',
        header: 'PROD PURC. DATE',
        type: 'date',
        label: 'productPurchaseDate',
      },
      { field: 'manufacturerManufacturer', header: 'MFW', type: 'text', label: 'mfw' },
      { field: 'modelNumber', header: 'MODEL NUMBER', type: 'text', label: 'modelNumber' },
      { field: 'serialNumber', header: 'SERIAL#', type: 'text', label: 'serialNumber' },
      {
        field: 'productPurchasePrice',
        header: 'PRODUCT PURCHASE PRICE',
        type: 'text',
        label: 'productPurchasePrice',
      },
      {
        field: 'subcategoryDescription',
        header: 'SUB CAT.',
        type: 'select',
        label: 'subcategoryDescription',
      },
      { field: 'partsTerm', header: 'PARTS', type: 'text', label: 'partsTerm' },
      { field: 'laborTerm', header: 'LABOUR', type: 'text', label: 'laborTerm' },
      {
        field: 'majorComponentTerm',
        header: 'MAJ. COMP.',
        type: 'text',
        label: 'majorComponentTerm',
      },
      { field: 'otherTerm', header: 'OTHER', type: 'text', label: 'otherTerm' },
      { field: 'fulfilled', header: 'FULFILLED', type: 'select', label: 'fulfilled' },
      { field: 'mfwExp', header: 'MFW EXP', type: 'text', label: 'mfw_exp' },
      { field: 'mfwCov', header: 'MFW COV.', type: 'text', label: 'mfw_cov', font: 'bold' },
      {
        field: 'categoryDescription',
        header: 'CAT.',
        type: 'disabled',
        label: 'categoryDescription',
      },
      { field: 'serviceLocation', header: 'SERV. LOC.', type: 'text', label: 'serviceLocation' },
      { field: 'type', header: 'TYPE', type: 'text', label: 'type' },
      { field: 'installDate', header: 'INST. DATE', type: 'date', label: 'installDate' },
      { field: 'tierDescription', header: 'TIER', type: 'disabled', label: 'tierDescription' },
    ];

    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: CoveredProductsComponent,
      generalArray: this.cols,
    };
    this.permissionsColsRows = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    for (const singlePermission of this.permissionsColsRows) {
      this.permissionsCols[singlePermission.label] = true;
    }

    if (this.azureService.roleName === 'Admin') {
      this.canAdd = true;
    }
    if (['CSR 1', 'CSR 2'].includes(this.azureService.roleName)) {
      this.canEdit = false;
    }
    this.selectedColumns = this.cols.slice(0, 12);
    this.contractService.isLoading.next(true);
    this.querySubscriptions.push(
      this.contractService.isMainTabDataAvailable.subscribe({
        next: (isMainTabDataAvailable) => {
          if (
            isMainTabDataAvailable &&
            this.contractService.contractDataModel.mainTab.coveredProducts &&
            this.coveredProduts.length === 0
            // this subscription is triggered on dropdown api calls if producst loaded then no need to repeat below code
          ) {
            this.coveredProduts = this.populateRowId(
              this.contractService.contractDataModel.mainTab.coveredProducts,
            );
          }
          this.contractService.isLoading.next(false);
        },
      }),
    );

    if (
      this.dropdownDataService.subCategoryDescriptionList &&
      this.dropdownDataService.subCategoryDescriptionList.length
    ) {
      this.subcategoryOptions = this.dropdownDataService.subCategoryDescriptionList;
    } else {
      this.contractService.isLoading.next(true);
      this.updateSubcategoryOptions();
    }
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Covered Products',
      category: 'Contract',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisRowOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Covered Products',
      category: 'Contract',
    };
    this.ellipsisRowOptions = this.rps.evaluate(
      this.ellipsisRowOptionsSec,
      this.ellipsisRowOptions,
    );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    this.updateSubcategoryOptions();
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  updateSubcategoryOptions() {
    this.querySubscriptions.push(
      this.contractService.getSubcategoryDescriptionList().subscribe(({ data }) => {
        this.subcategoryOptions = this.dropdownDataService.subCategoryDescriptionList =
          data.getAllSubCategoryCodeAndDescription;
        this.contractService.isLoading.next(false);
      }),
    );
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }
  onRowEditInit(rowData: any) {
    if (rowData) {
      if (rowData.productPurchaseDate && rowData.productPurchaseDate !== '') {
        rowData.productPurchaseDate = getMMDDYYYFormat(rowData.productPurchaseDate, 'YYYY-MM-DD');
      }
    }
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any, index: number) {
    const validationErr = this.validateFields(rowData);
    if (validationErr !== '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: validationErr,
      });
      this.revertData(rowData, index);
      return;
    }

    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    rowData.contractsIdOriginal = this.contractService.contractConfig.searchParams.id;
    rowData.insertUserName = this.azureService.accountId;
    this.isLoading = true;
    this.querySubscriptions.push(
      this.contractService.saveContractCoveredProducts(rowData).subscribe(
        ({ data, loading }: any) => {
          this.coveredProduts[this.selectedRowIndex] = {
            ...data[`CreateOrUpdateCoveredProducts`],
            id: this.coveredProduts[this.selectedRowIndex][`id`],
            mfwExp:
              data[`CreateOrUpdateCoveredProducts`][`calculatedFields`] &&
              getMMDDYYYFormat(
                data[`CreateOrUpdateCoveredProducts`][`calculatedFields`].mfwExp,
                'YYYY-MM-DD',
              ),
            mfwCov:
              data[`CreateOrUpdateCoveredProducts`][`calculatedFields`] &&
              data[`CreateOrUpdateCoveredProducts`][`calculatedFields`].mfwCov,
            productPurchaseDate: getMMDDYYYFormat(
              data[`CreateOrUpdateCoveredProducts`][`productPurchaseDate`],
              'YYYY-MM-DD',
            ),
            installDate: getMMDDYYYFormat(
              data[`CreateOrUpdateCoveredProducts`][`installDate`],
              'YYYY-MM-DD',
            ),
          };

          this.coveredProduts[this.selectedRowIndex][`isNew`] = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Product saved successfully.',
          });
          this.isLoading = false;
          this.contractService.onCoveredProductChange.next({ rowData });
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving, please try again.',
          });
          this.isLoading = false;
        },
      ),
    );
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.coveredProduts[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = this.checkEditMode();
          this.coveredProduts.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.coveredProduts[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = this.checkEditMode();
    }
  }

  onViewHistory() {
    const { productsIdOriginal } = this.coveredProduts[this.selectedRowIndex];
    if (productsIdOriginal) {
      this.historyData = {
        type: 'covered_products',
        id: productsIdOriginal,
      };
      this.displayHistory = true;
    }
  }

  onClickAdd(event) {
    let rowItem = {
      id: null,
      productsId: null,
      fulfilled: null,
      mfw: null,
      modelNumber: null,
      serialNumber: null,
      type: null,
      productPurchasePrice: null,
      productPurchaseDate: null,
      installDate: null,
      categoryDescription: null,
      subcategoryDescription: null,
      subcategoryCode: null,
      tierDescription: null,
      partsTerm: null,
      laborTerm: null,
      majorComponentTerm: null,
      otherTerm: null,
      serviceLocation: null,
      mfw_exp: null,
      mfw_cov: null,
    };

    rowItem[`id`] = this.currentMaxId = this.currentMaxId + 1;
    rowItem[`isNew`] = true;
    rowItem[`productNo`] = this.coveredProduts.length + 1;
    rowItem = { ...rowItem };
    this.coveredProduts.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.coveredProduts.length - 1;
    this.onRowEdit();
  }

  onDropDownChange(event, index) {
    this.coveredProduts[index][`subcategoryCode`] = event.value.key;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  private populateRowId(modelData: any) {
    _each(modelData, (data) => {
      data[`id`] = this.currentMaxId = this.currentMaxId + 1;
      data[`mfwExp`] =
        data[`calculatedFields`] && getMMDDYYYFormat(data[`calculatedFields`].mfwExp);
      data[`mfwCov`] = data[`calculatedFields`] && data[`calculatedFields`].mfwCov;
      data[`productPurchaseDate`] = getMMDDYYYFormat(data[`productPurchaseDate`]);
      data[`installDate`] = getMMDDYYYFormat(data[`installDate`]);
    });
    return modelData;
  }

  private checkEditMode() {
    let isEditing = false;
    _each(this.coveredProduts, (data) => {
      if (data[`isNew`]) {
        isEditing = true;
      }
    });
    return isEditing;
  }

  private validateFields(rowData) {
    const errorMessage = '';
    const productPurchaseDate = rowData.productPurchaseDate;
    const mfwExpDate = rowData.mfwExp;
    const installDate = rowData.installDate;
    if (productPurchaseDate && productPurchaseDate !== '') {
      console.log('productPurchaseDate: ' + productPurchaseDate);
      const results = productPurchaseDate.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
      const results2 = productPurchaseDate.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
      if (!results && !results2) {
        return `The Product Purchase Date [${productPurchaseDate}] is not in the correct YYYY-MM-DD or MM/DD/YYYY format.`;
      }
    }
    if (installDate && installDate !== '') {
      const results = installDate.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
      const results2 = installDate.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      if (!results && !results2) {
        return `The Install Date [${installDate}] is not in the correct YYYY-MM-DD or MM/DD/YYYY format.`;
      }
    }
    if (mfwExpDate && mfwExpDate !== '') {
      const results = mfwExpDate.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
      const results2 = mfwExpDate.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      if (!results && !results2) {
        return `The MFW Expire Date [${mfwExpDate}] is not in the correct YYYY-MM-DD or MM/DD/YYYY format.`;
      }
    }
    return errorMessage;
  }

  revertData(rowData: any, index: number) {
    this.coveredProduts[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = this.checkEditMode();
  }
}
