<p-dialog [header]="'Update Rates'" [(visible)]="displayDialog" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <div class="title">Admin</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="admin"><ng-template pTemplate="header"
        let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of cols">
            <p-cellEditor><ng-template *ngIf="rowData['bucketLabel'] === 'TOTAL'" pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template><ng-template *ngIf="rowData['bucketLabel'] !== 'TOTAL' && col.field !== 'rateAmount'"
                pTemplate="output">{{rowData[col.field]}}</ng-template><ng-template
                *ngIf="rowData['bucketLabel'] !== 'TOTAL' && col.field === 'rateAmount'" pTemplate="output"><input
                  class="input-text" type="text" [(ngModel)]="rowData[col.field]"
                  (focusout)="changeEventDetection(rowData)" /></ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
    <div class="title">Reserves and Fees</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="reserves"><ng-template pTemplate="header"
        let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of cols">
            <p-cellEditor><ng-template *ngIf="rowData['bucketLabel'] === 'TOTAL'" pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template><ng-template *ngIf="rowData['bucketLabel'] !== 'TOTAL' && col.field !== 'rateAmount'"
                pTemplate="output">{{rowData[col.field]}}</ng-template><ng-template
                *ngIf="rowData['bucketLabel'] !== 'TOTAL' && col.field === 'rateAmount'" pTemplate="output"><input
                  class="input-text" type="text" [(ngModel)]="rowData[col.field]"
                  (focusout)="changeEventDetection(rowData)" /></ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
    <div class="title">Commissions</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="commission"><ng-template pTemplate="header"
        let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of cols">
            <p-cellEditor><ng-template *ngIf="rowData['bucketLabel'] === 'TOTAL'" pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template><ng-template *ngIf="rowData['bucketLabel'] !== 'TOTAL' && col.field !== 'rateAmount'"
                pTemplate="output">{{rowData[col.field]}}</ng-template><ng-template
                *ngIf="rowData['bucketLabel'] !== 'TOTAL' && col.field === 'rateAmount'" pTemplate="output"><input
                  class="input-text" type="text" [(ngModel)]="rowData[col.field]"
                  (focusout)="changeEventDetection(rowData)" /></ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
    <div class="title">Total</div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="total"><ng-template pTemplate="body"
        let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of cols">
            <p-cellEditor><ng-template *ngIf="rowData['bucketLabel'] !== 'MARKUP' || col.field !== 'rateAmount'"
                pTemplate="output">
                <p><b>{{rowData[col.field]}}</b></p>
              </ng-template><ng-template *ngIf="rowData['bucketLabel'] === 'MARKUP' && col.field === 'rateAmount'"
                pTemplate="output"><input class="input-text" type="text" [(ngModel)]="rowData[col.field]"
                  (focusout)="changeEventDetection(rowData)" /></ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer" [style]="{'overflow': 'hidden','max-height': 'max-content','height': 'fit-content'}">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="!isRateChanged">Save</button><button
        class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>