import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServiceOrderHistoryService } from './service-order-history.service';

@Component({
  selector: 'service-order-history',
  templateUrl: 'service-order-history.template.html',
  styleUrls: ['service-order-history.scss'],
  providers: [ServiceOrderHistoryService],
})
export class ServiceOrderHistoryComponent implements OnInit, OnDestroy {
  admin: any[];
  cols: any[];
  selectedColumns: any[];
  querySubscription: Subscription;
  constructor(private serviceOrderHistoryService: ServiceOrderHistoryService) {}

  ngOnInit() {
    this.querySubscription = this.serviceOrderHistoryService
      .getServiceOrderHistory()
      .subscribe((data) => {
        this.admin = data;
      });
    this.cols = [
      { field: 'serviceOrder', header: 'Service Order #' },
      { field: 'status', header: 'Status' },
      { field: 'claim', header: 'Claim' },
      { field: 'contract', header: 'Contract #' },
      { field: 'model', header: 'Model #' },
      { field: 'serial', header: 'Serial #' },
      { field: 'dateResolved', header: 'Date Resolved' },
      { field: 'lastUpdateData', header: 'Last Update Data' },
      { field: 'lastUpdatedBy', header: 'Last Updated ByS' },
    ];

    this.selectedColumns = this.cols;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
