import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ViewFileService } from './view-file.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';

import { Subscription } from 'rxjs';
import { ellipsisDocumentOptions } from './view-file.contant';
import { CrmDocumentType } from '../constants/enums';
import { base64ToArrayBuffer, getContentType, copy } from '../utilities/common-utilities';
import { ChangeDetectorRef } from '@angular/core';
import { TabHandlerService } from '../../services/tab-handler.service';
import { convertToLocale } from '../utilities/date-utilities';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'view-file',
  templateUrl: 'view-file.template.html',
  styleUrls: ['view-file.scss'],
  providers: [ViewFileService, MessageService],
})
export class ViewFileComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Input() tabKey: string;
  @Input() isGeneric: boolean;
  @Input() parentCategory: string;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisDocumentOptions;
  ellipsisOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  status: any[];
  newTab: Tab;
  op: OverlayPanel;
  querySubscription: Subscription;
  downloadSubscription: any;
  isLoading: boolean;
  downloadingSpinner: boolean;
  @Input() title = 'Documents Search Results';
  constructor(
    private viewFileService: ViewFileService,
    private tabService: TabsService,
    private chRef: ChangeDetectorRef,
    private tabHandlerService: TabHandlerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    const deletePermissionList = ['Admin'];
    if (!deletePermissionList.includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((el) => el.value !== 'delete');
    }
    this.editConstant = uuidv4();
    this.status = [
      { label: 'Active', value: 'Active' },
      { label: 'Terminated', value: 'Terminated' },
    ];
    this.cols = [
      { field: 'fileName', header: 'FILE_NAME' },
      { field: 'description', header: 'DESCRIPTION' },
      { field: 'uploadedDate', header: 'UPLOADED DATE' },
      { field: 'uploadedBy', header: 'UPLOADED BY' },
      { field: 'action', header: 'ACTION' },
    ];
    if (this.isGeneric) {
      this.cols.push({ field: 'uploadState', header: 'UPLOAD STATE' });
      this.cols.push({ field: 'uploadProcessState', header: 'STATUS' });
    }
    this.downloadingSpinner = false;
    this.isLoading = true;
    if (this.searchParams) {
      this.getViewFile();
      this.tabHandlerService.selectedTab.subscribe({
        next: (tab) => {
          if (this.tabKey === tab) {
            this.isLoading = true;
            this.getViewFile();
          }
        },
      });
    }
    this.doEvaluateRolePermissions();
  }

  private doEvaluateRolePermissions() {
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'READ-DELETE',
      permission: 'Documents',
      category: this.parentCategory,
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getViewFile() {
    let attachmentType;
    if (this.searchParams.attachmentsType) {
      attachmentType =
        this.searchParams.attachmentsType.key === 'Import Contracts'
          ? { key: 'Customer' }
          : this.searchParams.attachmentsType;
    }
    this.querySubscription = this.viewFileService
      .getViewFileSearchResult(
        this.searchParams,
        this.searchParams.attachmentsType
          ? this.searchParams.attachmentsType.value
          : CrmDocumentType.Agent,
        attachmentType,
      )
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        const getFileAttachments = copy(data.getFileAttachments);
        const modifedData = getFileAttachments.map((item) => {
          item.fileState = 0;
          if (item.uploadProcessState === 0) {
            item.uploadProcessState = 'Processing';
          }
          if (item.uploadProcessState === 1) {
            item.uploadProcessState = 'Succesfully uploaded';

            if (item.validationResults) {
              item.uploadProcessState += this.getStatusMessage(item.validationResults);
            }
          }
          if (item.uploadProcessState === 2) {
            item.fileState = 2;
            item.uploadProcessState = 'Failed '; /* + item.failedValidationS3URL; */
          }
          if (item.uploadState) {
            item.uploadState = 'Valid';
          } else {
            item.uploadState = 'Invalid';
          }
          item.uploadedDate = convertToLocale(item.uploadedDate);
          return { ...item };
        });
        this.dataList = [...modifedData];
      });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    // default ellipsis functionality here
  }

  onRowView(event, overlaypanel: OverlayPanel) {
    // actual url this.selectedItem.fileS3URL
    // test url
    // const url = `https://gmophy6eo2.execute-api.us-east-1.amazonaws.com/dev/
    // csg-wfx-crm-newleaf-dev-email-templates-us-east-1/crm-notes_1600940833907.xlsx`;
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
    this.downloadingSpinner = true;
    this.downloadSubscription = this.viewFileService
      .getPreSignedUrl(this.selectedItem.attachmentsID, 'get')
      .subscribe(
        (preSignedInfo) => {
          const preSignedUrl = preSignedInfo.data.getPreSignedUrl;
          console.log(`get preSignedUrl : ${preSignedUrl}`);
          this.viewFileService.getFile(preSignedUrl).subscribe(
            (response) => {
              let rawFileName = this.selectedItem.fileName;
              if (this.selectedItem.fileState === 2) {
                rawFileName = `Invalid_${this.selectedItem.fileName}`;
              }
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(response);
              link.download = rawFileName;
              this.downloadingSpinner = false;
              this.chRef.detectChanges();
              link.click();
            },
            (err) => {
              const buffer = base64ToArrayBuffer(err.error.text);
              const file = new Blob([buffer as BlobPart], {
                type: getContentType(null, this.selectedItem.fileName),
              });
              const fileURL = URL.createObjectURL(file);
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              let rawFileName = this.selectedItem.fileName;
              if (this.selectedItem.fileState === 2) {
                rawFileName = `Invalid_${this.selectedItem.fileName}`;
              }
              fileLink.download = rawFileName;
              fileLink.click();
              this.downloadingSpinner = false;
              this.chRef.detectChanges();

              // fileURL = fileURL + '.' + getFileExtension(this.selectedItem.fileS3URL);
              // window.open(fileURL, '_blank');
            },
          );
        },
        (err) => {
          this.isLoading = false;
          this.showConfirmationMsg('Failed', 'Failed to download the file');
        },
      );
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Document ?',
      accept: () => {
        this.isLoading = true;
        this.viewFileService.deleteAttachment(this.selectedItem.attachmentsID).subscribe(
          (isDeleted) => {
            this.isLoading = false;
            if (isDeleted) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `Successfully deleted document`,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: `Failed to delete document`,
              });
            }
            this.isLoading = true;
            this.getViewFile();
          },
          (err) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Failed to delete document`,
            });
          },
        );
        overlaypanel.hide();
      },
    });
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  showConfirmationMsg(header, message) {
    this.confirmationService.confirm({
      header,
      message,
      rejectVisible: false,
      acceptLabel: 'OK',
    });
    this.isLoading = false;
  }

  getStatusMessage(status) {
    let statusMessage = '';
    if (typeof status === 'string') {
      try {
        status = JSON.parse(status);
      } catch {
        status = undefined;
      }
    }
    if (status && status.importSummary) {
      if (
        status.importSummary.hasOwnProperty('totalRecordsImported') &&
        status.importSummary.hasOwnProperty('totalSubmitForPayment') &&
        status.importSummary.hasOwnProperty('totalApprovedForPayment')
      ) {
        const totalRecord = `Record Imported: ${status.importSummary.totalRecordsImported}`;
        const totalSubPay = `Submitted for Payment: ${status.importSummary.totalSubmitForPayment}`;
        const totalAppPay = `Approved For Payment: ${status.importSummary.totalApprovedForPayment}`;
        statusMessage = '\n' + [totalRecord, totalSubPay, totalAppPay].join('\n');
      }
    } else if (status && (status.contractsCreated || status.createdContractsNum)) {
      let numberCreated = status.contractsCreated;
      if (!numberCreated) {
        numberCreated = status.createdContractsNum;
      }
      const totalRecords = `Contracts Imported: ${numberCreated ? numberCreated : 'NA'}`;
      const totalDealerCost = `DealerCost : $${status.dealerCost ? status.dealerCost : 'NA'}`;
      statusMessage = '\n' + [totalRecords, totalDealerCost].join('\n');
    }
    return statusMessage;
  }
}
