import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {
  constructor() {}
  getProductType(): Observable<any[]> {
    return of([
      {
        id: 1,
        code: 'PSP',
        description: 'Service Contract',
        sortOrder: 1
      },
      {
        id: 2,
        code: 'PRP',
        description: 'Single Replacement Contract',
        sortOrder: 2
      }
    ]);
  }
}
