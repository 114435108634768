import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormField } from '../../shared/form-field/form-field';
import { NavigationPanel } from '../../shared/navigation-panel/navigation-panel.model';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { ServiceOrderSummaryComponent } from './service-order-summary/service-order-summary.component';
import { ServiceOrderService } from './service-order.service';
import { ServiceDetailsComponent } from './service-details/service-details.comonent';
import { ServiceOrderHistoryComponent } from './service-order-history/service-order-history.component';
import { ServiceAttachmentsComponent } from './service-attachments/service-attachments.component';
import { ServiceClaimPaymentComponent } from './service-claim-payment/service-claim-payement.component';
import { ServiceStatusLogComponent } from './service-status-log/service-status-log.component';
import { ServiceAuditComponent } from './service-audit/service-audit.component';
import { ServiceOrderDataService } from './service-order-data.service';
import { AddressCategoryType } from '../../shared/constants/enums';
import { NoteComponent } from '../../shared/notes/note.component';
import { ServiceOrderUploadComponent } from './service-order-upload-tab/service-order-upload-tab.component';
import { Subscription } from 'rxjs';

import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ApiConfigService } from '../../services/api-config.service';
import { ServiceCreditCardInfoComponent } from './service-cc-tab/creditcard-information-tab.component';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'service-order',
  templateUrl: 'service-order.template.html',
  styleUrls: ['../../shared/styles/left-details-form.scss', 'service-order.scss'],
  providers: [ServiceOrderService, ServiceOrderDataService, AlertService],
})
export class ServiceOrderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  buttons: any[];
  displayValidator: boolean;
  displayClaim: boolean;
  identifierId: any;
  notesCategoryType: AddressCategoryType;
  disclaimer: any;
  component = ServiceOrderComponent;
  componentDisplay = false;
  isLoading: boolean;
  querySubscriptions: Subscription[] = [];
  subClaim = false;
  componentsToLoad = 0; // will be set dynamically
  componentsLoaded = -1; // will be set dynamically, set to -1, as the loader is false by default
  loadNote: boolean;
  disableValidatorToolBtn: boolean = true;
  @ViewChild('notesComponent') notesCompoenent: NoteComponent;

  constructor(
    public service: ServiceOrderService,
    private roleService: RoleAuthorizationService,
    public serviceOrderDataService: ServiceOrderDataService,
    private cdr: ChangeDetectorRef,
    private azureService: AzureLoginService,
    private apiConfigService: ApiConfigService,
    public alertService: AlertService,
  ) {
    this.querySubscriptions.push(
      service
        .getValidatorToolFields(this.serviceOrderDataService.validatorDataModel)
        .subscribe((data: FormField<string>[]) => {
          this.formFields = data;
        }),
    );
    this.displayValidator = false;
    this.displayClaim = false;

    this.setNavigationPanel(true);

    this.buttons = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: false, type: 'button', callBack: 'onSubmit' },
    ];
  }

  ngOnInit() {
    this.disableValidatorToolBtn = !this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'Validator Tool Button',
      operation: 'ADD',
      type: 'Code'
    });
    this.serviceOrderDataService.serviceBenchModal.serviceBenchConfig =
      this.apiConfigService.getApiConfig('ServiceBench');
    this.serviceOrderDataService.servicePowerModal.servicePowerConfig =
      this.apiConfigService.getApiConfig('ServicePower');
    const { serviceBenchConfig } = this.serviceOrderDataService.serviceBenchModal;
    const { servicePowerConfig } = this.serviceOrderDataService.servicePowerModal;
    let serviceBenchConfigValues = [];
    let servicePowerConfigValues = [];
    if (serviceBenchConfig) {
      serviceBenchConfigValues = Object.values(serviceBenchConfig);
      const sbStatus = serviceBenchConfigValues.filter(
        (el) => el.configType === 'ServiceBenchStatus',
      );
      const sbProductLine = serviceBenchConfigValues.filter(
        (el) => el.configType === 'ServiceBenchProductLine',
      );
      const sbCommon = serviceBenchConfigValues.filter((el) => el.configType === 'Common');
      const sbPaymentTypes = serviceBenchConfigValues.filter(
        (el) => el.configType === 'PaymentType',
      );
      const cancellationReasons = serviceBenchConfigValues.filter(
        (el) => el.configType === 'CancellationReason',
      );
      const noUpdateClaimStatusList = serviceBenchConfigValues.filter(
        (el) => el.configType === 'NoUpdateClaimStatus',
      );
      const hideRCButtonsClaimStatusList = serviceBenchConfigValues.filter(
        (el) => el.configType === 'HideRCButtonsClaimStatus',
      );
      const sbCanReasons = serviceBenchConfigValues.filter(
        (el) => el.configType === 'SbCancellationReason',
      );
      const sbBrands = serviceBenchConfigValues.filter(
        (el) => el.configType === 'ServiceBenchBrand',
      );
      const sbEntitledServicers = serviceBenchConfigValues.filter(
        (el) => el.configType === 'EntitledServicer',
      );
      if (sbStatus && sbStatus.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.serviceBenchJobStatus = sbStatus.map(
          (x) => ({
            key: x.configKey,
            value: x.configValue,
          }),
        );
      }
      if (sbProductLine && sbProductLine.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.serviceBenchProductLineList =
          sbProductLine.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }

      if (sbCommon && sbCommon.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.serviceBenchCommon = sbCommon.map((x) => ({
          key: x.configKey,
          value: x.configValue,
        }));
      }

      if (cancellationReasons && cancellationReasons.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.cancellationReasons =
          cancellationReasons.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }

      if (sbCanReasons && sbCanReasons.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.sbCancellationReasons = sbCanReasons.map(
          (x) => ({
            key: x.configKey,
            value: x.configValue,
          }),
        );
      }

      if (sbPaymentTypes && sbPaymentTypes.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.serviceBenchPaymentTypes =
          sbPaymentTypes.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }
      if (sbBrands && sbBrands.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.serviceBenchBrands = sbBrands.map((x) => ({
          key: x.configKey,
          value: x.configValue,
        }));
      }
      if (sbEntitledServicers && sbEntitledServicers.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.entitledServicers = sbEntitledServicers.map(
          (x) => ({
            key: x.configKey,
            value: x.configValue,
          }),
        );
      }

      if (noUpdateClaimStatusList && noUpdateClaimStatusList.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.noUpdateClaimStatusList =
          noUpdateClaimStatusList.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }

      if (hideRCButtonsClaimStatusList && hideRCButtonsClaimStatusList.length > 0) {
        this.serviceOrderDataService.serviceBenchModal.hideRCButtonsClaimStatusList =
          hideRCButtonsClaimStatusList.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }
    }
    // Set Service Power Configurations
    if (servicePowerConfig) {
      servicePowerConfigValues = Object.values(servicePowerConfig);
      const spEntitledServicers = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpEntitledServicer',
      );
      if (spEntitledServicers.length) {
        this.serviceOrderDataService.servicePowerModal.spEntitledServicers =
          spEntitledServicers.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }
      const spPrefferedServicers = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpPrefferedServicer',
      );
      if (spPrefferedServicers.length) {
        this.serviceOrderDataService.servicePowerModal.spPrefferedServicerList =
          spPrefferedServicers.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }
      const spCommon = servicePowerConfigValues.filter((el) => el.configType === 'Common');
      if (spCommon.length) {
        this.serviceOrderDataService.servicePowerModal.servicePowerCommon = spCommon.map((x) => ({
          key: x.configKey,
          value: x.configValue,
        }));
      }
      const spWarrantyTypes = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpWarrantyType',
      );
      if (spWarrantyTypes.length) {
        this.serviceOrderDataService.servicePowerModal.spWarrantyTypes = spWarrantyTypes.map(
          (x) => ({
            key: x.configKey,
            value: x.configValue,
          }),
        );
      }
      const spServiceSites = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpServiceSite',
      );
      if (spServiceSites.length) {
        this.serviceOrderDataService.servicePowerModal.spServiceSites = spServiceSites.map((x) => ({
          key: x.configKey,
          value: x.configValue,
        }));
      }
      const spServiceTypes = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpServiceType',
      );
      if (spServiceTypes.length) {
        this.serviceOrderDataService.servicePowerModal.spServiceTypes = spServiceTypes.map((x) => ({
          key: x.configKey,
          value: x.configValue,
        }));
      }
      const spBrands = servicePowerConfigValues.filter(
        (el) => el.configType === 'ServicePowerBrand',
      );
      if (spBrands.length) {
        this.serviceOrderDataService.servicePowerModal.servicePowerBrands = spBrands.map((x) => ({
          key: x.configKey,
          value: x.configValue,
        }));
      }
      const spProductLines = servicePowerConfigValues.filter(
        (el) => el.configType === 'ServicePowerProductLine',
      );
      if (spProductLines.length) {
        this.serviceOrderDataService.servicePowerModal.servicePowerProductLines =
          spProductLines.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }
      const spLgGroups = servicePowerConfigValues.filter(
        (el) => el.configType === 'ServicePowerLgGroup',
      );
      const spGeGroups = servicePowerConfigValues.filter(
        (el) => el.configType === 'ServicePowerGeGroup',
      );
      const spCancellationReasons = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpCancellationReason',
      );
      if (spLgGroups.length) {
        this.serviceOrderDataService.servicePowerModal.servicePowerLgGroup = spLgGroups.map(
          (x) => ({
            key: x.configKey,
            value: x.configValue,
          }),
        );
      }
      if (spGeGroups.length) {
        this.serviceOrderDataService.servicePowerModal.servicePowerGeGroup = spGeGroups.map(
          (x) => ({
            key: x.configKey,
            value: x.configValue,
          }),
        );
      }
      if (spCancellationReasons.length) {
        this.serviceOrderDataService.servicePowerModal.spCancellationReasons =
          spCancellationReasons.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }

      const spNotEligibleGeSubStaus = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpNotEligibleGeSubStaus',
      );

      if (spNotEligibleGeSubStaus.length) {
        this.serviceOrderDataService.servicePowerModal.spNotEligibleGeSubStaus =
          spNotEligibleGeSubStaus.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }

      const spHideRCButtonsClaimStatusList = servicePowerConfigValues.filter(
        (el) => el.configType === 'SpHideRCButtonsClaimStatus',
      );

      if (spHideRCButtonsClaimStatusList.length) {
        this.serviceOrderDataService.servicePowerModal.spHideRCButtonsClaimStatusList =
          spHideRCButtonsClaimStatusList.map((x) => ({
            key: x.configKey,
            value: x.configValue,
          }));
      }
    }
    const { serviceBenchJobStatus, sbCancellationReasons, serviceBenchCommon } =
      this.serviceOrderDataService.serviceBenchModal;

    this.querySubscriptions.push(
      this.serviceOrderDataService.isLoading.subscribe({
        next: (isLoading) => {
          if (isLoading) {
            this.isLoading = isLoading;
            this.componentsToLoad += 1;
            this.cdr.detectChanges();
          } else {
            this.componentsLoaded += 1;
          }

          // this.componentsLoaded will be eqaual to this.componentsToLoad
          // once all the components are loaded, but need to check greater than considering save/update click
          if (this.componentsLoaded >= this.componentsToLoad) {
            this.isLoading = false;
            this.componentsLoaded = this.componentsToLoad;
            this.cdr.detectChanges();
            this.serviceOrderDataService.isFullyLoaded.next(true);
          }
        },
      }),
    );
    this.componentDisplay = this.roleService.isVisible(NoteComponent, ServiceOrderComponent);
    this.populateData();

    this.notesCategoryType = AddressCategoryType.ClaimContact;

    if (this.searchParams && this.searchParams.claimId) {
      this.loadNote = true;
      if (this.searchParams.subClaimFlag) {
        this.subClaim = true;
        this.loadNote = false;
      }
      this.identifierId = this.searchParams.claimId;
      this.disclaimer = null;
    } else {
      this.identifierId = null;
      this.disclaimer = `Please save the service order before you add a note.`;
      this.loadNote = true;
    }
    this.setNavigationPanel();
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: ServiceOrderComponent,
      generalArray: this.navigationPanel,
    };
    // this.navigationPanel = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    this.querySubscriptions.push(
      this.serviceOrderDataService.newClaimsId.subscribe({
        next: (newClaimsId) => {
          if (newClaimsId && newClaimsId !== 0) {
            // re-initiate notes once the claim is saved
            this.identifierId = newClaimsId;
            this.disclaimer = null;
            this.loadNote = true;
            this.setNavigationPanel();
          }
        },
      }),
    );
    this.querySubscriptions.push(
      this.serviceOrderDataService.loadNote.subscribe({
        next: (loadingNote) => {
          this.loadNote = false;
          if (loadingNote) {
            setTimeout(() => {
              this.loadNote = true;
            }, 3000);
          }
        },
      }),
    );
  }

  updateCredCardInformationTab(addTab) {
    if (addTab) {
      this.populateCreditCardInfo();
      const creditCardInformationItem = {
        component: ServiceCreditCardInfoComponent,
        data: { name: 'Credit Card Info' },
        title: 'CC',
        tabname: 'Credit Card Info',
        tabKey: 'cci',
      };
      if (this.navigationPanel.filter((el) => el.title === 'CC').length === 0) {
        this.navigationPanel.push(creditCardInformationItem);
        this.authDetails.generalArray = this.navigationPanel;
        this.navigationPanel = Object.assign(this.roleService.applyAuthorization(this.authDetails));
      }
    } else {
      this.serviceOrderDataService.isCreditCardInfoEditable = false;
      this.navigationPanel = this.navigationPanel.filter((el) => el.title !== 'CC');
    }
  }

  populateCreditCardInfo() {
    const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getCreditCardInfoBySONumber(claimData.serviceOrderNumber)
        .subscribe(({ data }) => {
          if (data.getOutOfNetworkInfo) {
            this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo =
              data.getOutOfNetworkInfo;
          }
          this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo = Object.assign(
            {},
            this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo,
            {
              serviceOrderNumber: claimData.serviceOrderNumber,
            },
          );
          this.serviceOrderDataService.isCreditCardInfoAvailable.next(true);
        }),
    );
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getCreditCardInfoCountBySONumber(claimData.serviceOrderNumber)
        .subscribe(({ data }) => {
          this.serviceOrderDataService.serviceOrderDataModel.creditCardInfoCount =
            data.getOutOfNetworkInfoCount;
        }),
    );
  }

  ngAfterViewInit() {
    this.serviceOrderDataService.newNote.subscribe({
      next: (newNote) => {
        if (newNote) {
          const presentNotes = this.serviceOrderDataService.serviceOrderDataModel.notes;
          const soStatusNoteList = presentNotes.filter((el) =>
            el.noteText.startsWith(` Service order`),
          );
          let isAddNewNote = true;
          if (soStatusNoteList.length > 0) {
            const sortedArray = soStatusNoteList.sort((a, b) => b.data - a.data);
            const latestNote = sortedArray[0].noteText.trim();
            const newNoteText = this.serviceOrderDataService.notesDataModel.text.trim();
            if (latestNote === newNoteText) {
              isAddNewNote = false;
            }
          }
          if (isAddNewNote) {
            this.notesCompoenent.saveSystemNote(
              AddressCategoryType.ClaimContact,
              this.serviceOrderDataService.notesDataModel.text,
              this.serviceOrderDataService.notesDataModel.type,
              this.serviceOrderDataService.notesDataModel.enteredBy,
            );
          }
        }
      },
    });
  }

  onClickValidator() {
    this.serviceOrderDataService.isValidatorDataAvailable.next(true);
    this.displayValidator = true;
  }
  onClickClaim() {
    this.displayClaim = true;
  }
  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
  onValidatorSubmit($event) {
    // TODO
  }

  public onNotesData(noteData: any): void {
    this.serviceOrderDataService.serviceOrderDataModel.notes = noteData;
  }

  populateData() {
    if (this.searchParams && typeof this.searchParams !== 'string') {
      this.serviceOrderDataService.serviceConfig.searchParams = {
        isExpiredContract: false,
        contractId: this.searchParams.id ? this.searchParams.id : this.searchParams.contractId,
        customerId: this.searchParams.customerId,
        claimId: this.searchParams.claimId,
        subClaimFlag: this.searchParams.subClaimFlag,
      };

      // if it is a new claim from contract it will have
      // contract related data in searchParams.data
      // in case of search get it with the respective contract id
      if (this.searchParams.data) {
        this.serviceOrderDataService.serviceConfig.searchParams.isExpiredContract =
          this.searchParams.data.contractData.contractStatusDescription === 'Expired'
            ? true
            : false;
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel = {
          ...this.searchParams.data.coveredProduct,
          saleDate: this.searchParams.data.contractData.saleDate,
          deductibleCollectedBy: this.searchParams.data.coverageInformation
            ? this.searchParams.data.coverageInformation.deductibleCollectedBy
            : '',
        };

        if ('coveredProduct' in this.searchParams.data) {
          this.serviceOrderDataService.serviceOrderDataModel.productInfo = {
            serialNumber: this.searchParams.data.coveredProduct.serialNumber,
          };
        }

        if ('coverageInformation' in this.searchParams.data) {
          this.serviceOrderDataService.serviceOrderDataModel.coveragePlanDetails = {
            ...this.searchParams.data.coverageInformation,
          };
        }

        // above model properties may changes as per user interactions
        // contractinfo can be treated as a history and also for data subscriptions
        const claimAddress =
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo.addresses;
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo = {
          ...this.searchParams.data.contractData,
          coveredProducts: this.searchParams.data.coveredProduct,
          addresses: claimAddress,
          contractAddresses: (this.searchParams.data.contractData &&
            this.searchParams.data.contractData.addresses) || [this.searchParams.data.address],
        };
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contactInformation =
          this.searchParams.data.customerInformation;
        this.serviceOrderDataService.serviceInfo.next(
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo,
        );
        this.serviceOrderDataService.isMainTabDataAvailable.next(true);
      } else {
        if (this.serviceOrderDataService.serviceConfig.searchParams.contractId) {
          this.serviceOrderDataService.isLoading.next(true);
          this.querySubscriptions.push(
            this.serviceOrderDataService
              .getServiceMainTab(
                parseInt(this.serviceOrderDataService.serviceConfig.searchParams.contractId, 10),
              )
              .subscribe(({ data, loading }: any) => {
                const modifiedData = data.getContractProfileById;
                if (modifiedData) {
                  if (modifiedData.contractStatusDescription === 'Expired') {
                    this.serviceOrderDataService.serviceContractExpired.next(true);
                  }
                  this.serviceOrderDataService.serviceConfig.searchParams.isExpiredContract =
                    modifiedData.contractStatusDescription === 'Expired' ? true : false;
                  this.serviceOrderDataService.serviceOrderDataModel.customerInfo =
                    modifiedData.contactInformation;
                  const claimAddress =
                    this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
                    this.serviceOrderDataService.serviceOrderDataModel.contractInfo.addresses;
                  this.serviceOrderDataService.serviceOrderDataModel.contractInfo = {
                    ...modifiedData,
                    addresses: claimAddress,
                    contractAddresses: modifiedData && modifiedData.addresses,
                  };
                  this.serviceOrderDataService.serviceInfo.next(
                    this.serviceOrderDataService.serviceOrderDataModel.contractInfo,
                  );
                }
                this.serviceOrderDataService.serviceOrderDataModel.productInfoModel = {
                  saleDate: modifiedData.saleDate,
                  deductibleCollectedBy: modifiedData.coverageInformation
                    ? modifiedData.coverageInformation.deductibleCollectedBy
                    : '',
                };
                this.serviceOrderDataService.serviceOrderDataModel.coveragePlanDetails =
                  modifiedData.coverageInformation;
                this.serviceOrderDataService.isMainTabDataAvailable.next(true);
                this.serviceOrderDataService.isLoading.next(false);
              }),
          );
        }
      }
    } else {
      this.serviceOrderDataService.isLoading.next(false);
    }
  }

  onAlertDisplayChange(event) {
    if (!event) {
      this.alertService.hideAlert();
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  private setNavigationPanel(initialLoad = false) {
    // set up left-tab navigation
    if (initialLoad || this.loadNote) {
      this.navigationPanel = [
        {
          component: ServiceOrderSummaryComponent,
          data: { name: 'SO Summary' },
          title: 'SS',
          tabname: 'SO Summary',
          tabKey: 'Sos',
        },
        {
          component: ServiceDetailsComponent,
          data: { name: 'Service Details' },
          title: 'SD',
          tabname: 'Service Details',
          tabKey: 'ServiceDetails',
          hasPermissions: true,
          permissions: {
            type: 'sideMenu',
            operation: 'READ_ONLY',
            permission: 'Service Details Tab',
            category: 'Claim',
          },
        },
        {
          component: ServiceOrderHistoryComponent,
          data: { name: 'Service Order History' },
          title: 'HIS',
          tabname: 'Service Order History',
          tabKey: 'Soh',
          hasPermissions: true,
          permissions: {
            type: 'sideMenu',
            operation: 'READ_ONLY',
            permission: 'SO/Claim History Tab',
            category: 'Claim',
          },
        },
        {
          component: ServiceAttachmentsComponent,
          data: { name: 'Documents' },
          title: 'DC',
          tabname: 'Documents',
          tabKey: 'Attachments',
          hasPermissions: true,
          permissions: {
            type: 'sideMenu',
            operation: 'READ_ONLY',
            permission: 'Documents',
            category: 'Claim',
          },
        },
        {
          component: ServiceClaimPaymentComponent,
          data: { name: 'Claim Payment' },
          title: 'CP',
          tabname: 'Claim Payment',
          tabKey: 'Cp',
          hasPermissions: true,
          permissions: {
            type: 'sideMenu',
            operation: 'READ_ONLY',
            permission: 'Claim Payment Tab',
            category: 'Claim',
          },
        },
        {
          component: ServiceStatusLogComponent,
          data: { name: 'Status Log' },
          title: 'SL',
          tabname: 'Status Log',
          tabKey: 'Sl',
          hasPermissions: true,
          permissions: {
            type: 'sideMenu',
            operation: 'READ_ONLY',
            permission: 'Status Log Tab',
            category: 'Claim',
          },
        },
        {
          component: ServiceAuditComponent,
          data: { name: 'Service Audit' },
          title: 'AUD',
          tabname: 'Service Audit',
          tabKey: 'Sau',
        },
      ];
      const serviceOrderUploadItem = {
        component: ServiceOrderUploadComponent,
        data: { name: 'Import Documents' },
        title: 'ID',
        tabname: 'Import Documents',
        tabKey: 'Id',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'ADD',
          permission: 'Documents',
          category: 'Claim',
        },
      };
      if (this.navigationPanel) {
        this.navigationPanel.push(serviceOrderUploadItem);
      }
    } else {
      this.navigationPanel = [
        {
          component: ServiceOrderSummaryComponent,
          data: { name: 'SO Summary' },
          title: 'SS',
          tabname: 'SO Summary',
          tabKey: 'Sos',
          hasPermissions: true,
          permissions: {
            type: 'sideMenu',
            operation: 'READ_ONLY',
            permission: 'SO/Claim History Tab',
            category: 'Claim',
          },
        },
      ];
    }
    this.querySubscriptions.push(
      this.service.isCreditCardServicerDispatch.subscribe((isCCOnlyDispatch) => {
        this.updateCredCardInformationTab(isCCOnlyDispatch);
      }),
    );
  }
}
