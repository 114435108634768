// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: {
  production: boolean;
  buildNumber: string;
  apiUrl: string;
  clientId: string;
  authority: string;
  redirectUrl: string;
  backendUrl: string;
  signInAuthority: string;
  signInName: string;
  passwordResetAuthority: string;
  passwordResetName: string;
  b2cScope: string;
  webApi: string;
  userUrl: string;
  dashUrl: string;
  uploadUrl: string;
  emailUrl: string;
  serviceBenchSpaUrl: string;
  serviceBenchSjsUrl: string;
  serviceBenchSjstUrl: string;
  serviceBenchSjcanUrl: string;
  serviceBenchSjresUrl: string;
  azureMapsUrl: string;
  servicePowerAsUrl: string;
  servicePowerCarUrl: string;
  servicePowerCseUrl: string;
  servicePowerCcanUrl: string;
  triggerScheduledEmailsUrl: string;
  favIcon: string;
} = {
  production: false,
  buildNumber: '2.0.205',
  favIcon: 'favicon.ico',
  apiUrl: 'https://newleaf-crm-dev.csgfsm.com',
  clientId: '2dcf5b96-65a0-4d50-936a-3f9cd0bafb8c',
  authority: 'https://login.microsoftonline.com/194a267b-82ba-4fa6-b6b9-f95966ab3815/',
  redirectUrl: 'https://newleaf-crm-dev.csgfsm.com',
  backendUrl: 'https://sehfyr0f3g.execute-api.us-east-1.amazonaws.com/dev/backend',
  signInAuthority: 'https://CSGB2CDEV.b2clogin.com/CSGB2CDEV.onmicrosoft.com/B2C_1_Signin',
  signInName: 'B2C_1_Signin',
  passwordResetAuthority:
    'https://CSGB2CDEV.b2clogin.com/CSGB2CDEV.onmicrosoft.com/B2C_1_PasswordReset',
  passwordResetName: 'B2C_1_PasswordReset',
  b2cScope: 'https://CSGB2CDEV.onmicrosoft.com/helloapi/demo.read',
  webApi: 'https://CSGB2CDEV.onmicrosoft.com/2dcf5b96-65a0-4d50-936a-3f9cd0bafb8c/retrieve',
  userUrl: 'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/msgraph',
  dashUrl: 'https://csgi-newleaf.domo.com',
  uploadUrl: 'https://gmophy6eo2.execute-api.us-east-1.amazonaws.com/dev/files',
  emailUrl: 'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/email',
  serviceBenchSpaUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicebench/serviceprovideravailability',
  serviceBenchSjsUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicebench/servicejobschedule',
  serviceBenchSjstUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicebench/servicejobstatus',
  serviceBenchSjcanUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicebench/servicejobcancellation',
  serviceBenchSjresUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicebench/servicejobRescheduling',
  azureMapsUrl: 'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/azuremaps',
  servicePowerAsUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicepower/appointmentsearch',
  servicePowerCarUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicepower/callassignmentreq',
  servicePowerCseUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicepower/callstatusenquiry',
  servicePowerCcanUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/servicepower/callcancellation',
  triggerScheduledEmailsUrl:
    'https://295ce8gla6.execute-api.us-east-1.amazonaws.com/newleaf_dev_1/triggerscheduleemails'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
