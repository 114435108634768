<app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
<div class="crm-panel" *ngIf="isDataLoaded">
  <div class="crm-top">
    <div class="crm-panel__left">
      <servicer-profile-form (formValueChanged)="setChangeData($event)"
        [searchParams]="searchParams"></servicer-profile-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <ng-container *ngIf="componentDisplay && servicerProfileId">
    <crm-notes class="crm-bottom"
      *appSetRolePermissionsDirective="{type:'component', operation:'READ_ONLY', permission:'Notes', category: 'Servicer'}"
      [parentCategory]="'Servicer'" [category]="addressCategoryType.ServicerProfile" [identifierId]="servicerProfileId"
      [parentComponent]="component">
    </crm-notes>
  </ng-container>
</div>