import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  serviceDetailsFormQuery,
  serviceMainTabQuery,
  servicerProfileByIdQuery,
  getServicerEligibleProfilesQuery,
  getClaimProfileByIdQuery,
  saveClaimsQuery,
  getClaimCoverageDetailsQuery,
  getClaimDealerInfoQuery,
  getSymptomByProductTierQuery,
  getSymptomOptionsByTierAndSymptomQuery,
  claimProblemSymptomQuery,
  createUpdateClaimCoverageQuery,
  getRealLimitOfLiabilityQuery,
  createUpdateExpenseChargesQuery,
  getExpenseChargesByClaimQuery,
  createUpdateClaimServicerquery,
  getClaimServicerByClaimQuery,
  getScheduledJobQuery,
  createUpdateBookingAppointmentQuery,
  getClaimLastUpdateTimeQuery,
  getOutOfNetworkInfoQuery,
  createUpdateCreditCardInfoQuery,
  getOutOfNetworkInfoCountQuery,
} from './so-data-queries.constant';
import { sanitizeObject } from '../../shared/utilities/common-utilities';
import { HttpClient } from '@angular/common/http';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { generalDateFormatter } from '../../shared/utilities/date-utilities';

@Injectable()
export class ServiceOrderDataService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private azureService: AzureLoginService,
  ) {}
  serviceInfo = new Subject<any>();
  productLocationInfo = new Subject<any>();

  serviceContractExpired = new Subject<any>();
  expiredContractAuthorized = new Subject<any>();
  fPBFlag = new Subject<any>();
  /**
   * Properties start
   */
  public serviceConfig = {
    searchParams: {
      contractId: null,
      customerId: null,
      claimId: null,
      subClaimFlag: null,
      isExpiredContract: null,
    },
    isFPBClaim: false,
    isReturnSubmission: false,
  };

  public serviceOrderDataModel = {
    leftForm: null,
    contact: null,
    customerInfo: null,
    location: null,
    productInfo: null,
    productInfoModel: null,
    contractInfo: null,
    serviceOrderInfo: null,
    claimData: null,
    dealerInfo: null,
    notes: [],
    coverageDetails: [],
    servicerInfo: null,
    selectedCoverage: null,
    coverageReason: null,
    expenseCharges: [],
    techInfo: null,
    preAuthInfo: null,
    lolDetailsInfo: null,
    problemReport: null,
    fpbContact: [],
    isThirdPartyDispatch: false,
    creditCardInfo: {
      outOfNetworkInfoId: null,
      outOfNetworkInfoIdOriginal: null,
      companyName: null,
      nameCC: null,
      providerAddress: null,
      phoneNumber: null,
      initialFee: null,
      poc: null,
      dispatchMethod: 'Email',
      emailFax: null,
      email: null,
      phone: null,
      partOrder: null,
      partOrderNumber: null,
      customerAddressVerified: null,
      cCProvidedDate: null,
      approvedAmount: null,
      agentInitials: null,
      serviceOrderNumber: null,
      addedTimeStamp: null,
      insertUserName: null,
      isDeleted: false,
    },
    creditCardInfoCount: null,
    formValidation: {
      contact: true,
      location: true,
    },
    expiredContractAuthorization: {
      azureUserId: null,
      reason: null,
      authDate: null,
    },
    coveragePlanDetails: null,
    goodwillReason: null,
  };

  public showLoader = false;

  public standardProblemDataModel = {
    failureDate: null,
    reportedDate: null,
    functionalityStatus: null,
    failureDescription: null,
    symptom: null,
    symptomFixNote: null,
    claimProblemSymptomIdOriginal: null,
    enteredBy: null,
    insertUserName: null,
    failure: null,
    currentFailureDate: null,
    currentReportedDate: null,
  };

  public standardProblemDataModelInitial = {
    failureDate: null,
    reportedDate: null,
    functionalityStatus: null,
    failureDescription: null,
    symptom: null,
    symptomFixNote: null,
    claimProblemSymptomIdOriginal: null,
    enteredBy: null,
    insertUserName: null,
    failure: null,
    currentFailureDate: null,
    currentReportedDate: null,
  };

  public standardProblemDataModelExtened = {
    productDop: null,
    planDop: null,
    mfgDop: null,
  };

  public azureMapAddressInfo = {
    address1: null,
    city: null,
    latitude: null,
    longitude: null,
    state: null,
    suggestedFullAddress: null,
    zipCode: null,
  };

  public currentFormData = null;

  public validatorDataModel = {
    soNumber: null,
    spCode: null,
    rsp: null,
    caller: null,
    servicer: null,
    dop: null,
    manufacturer: null,
    model: null,
    serial: null,
    failureDescription: null,
    warranty: null,
    planSKU: null,
    opp: null,
    ppc: null,
    lol: null,
    lolPercent: null,
    parts: null,
    labor: null,
    tax: null,
    mileage: null,
    misc: null,
    deductible: null,
    total: null,
    lolAfter: null,
    lolAfterPercent: null,
    attempt: null,
    attemptFee: null,
    totalFee: null,
    partDist: null,
    estimate: null,
    preAuth: null,
  };

  public notesDataModel = {
    type: null,
    text: null,
    enteredBy: null,
  };

  public serviceBenchModal = {
    scheduleJobDetails: null,
    jobStatusDetails: null,
    cancelJobDescription: {
      cancelReason: null,
      cancelNote: null,
    },
    previousJobDetails: null,
    serviceBenchConfig: null,
    serviceBenchJobStatus: [],
    serviceBenchProductLineList: [],
    serviceBenchCommon: [],
    serviceBenchPaymentTypes: [],
    cancellationReasons: [],
    sbCancellationReasons: [],
    serviceBenchBrands: [],
    entitledServicers: [],
    noUpdateClaimStatusList: [],
    hideRCButtonsClaimStatusList: [],
  };

  public servicePowerModal = {
    scheduleCallDetails: null,
    spEntitledServicers: [],
    servicePowerCommon: [],
    servicePowerConfig: null,
    spWarrantyTypes: [],
    spServiceSites: [],
    spServiceTypes: [],
    spCallStatusDetails: null,
    servicePowerBrands: [],
    servicePowerProductLines: [],
    servicePowerLgGroup: [],
    servicePowerGeGroup: [],
    spCancellationReasons: [],
    spNotEligibleGeSubStaus: [],
    spHideRCButtonsClaimStatusList: [],
    spPrefferedServicerList: [],
    spRejectedCancelled: false,
  };

  public claimLastUpdateTime = null;
  public isCreditCardInfoUpdated = false;
  public isCreditCardInfoEditable = false;

  /**
   * Properties end
   */

  /**
   * Subscriber subjects start
   */
  public isMainTabDataAvailable = new BehaviorSubject(false);
  public isClaimDataAvailable = new BehaviorSubject(false);
  public isServicerInfoAvailable = new BehaviorSubject(false);
  public saveStandardFormData = new BehaviorSubject(false);
  public saveDataComplete = new BehaviorSubject(false);
  public isLoading = new BehaviorSubject(false);
  public isFullyLoaded = new BehaviorSubject(false);
  public isExpenseDataAvailable = new BehaviorSubject(false);
  public isValidatorDataAvailable = new BehaviorSubject(false);
  public dispatchDateChanged = new BehaviorSubject(undefined);
  public disableCoverageSelection = new BehaviorSubject(false);
  public newClaimsId = new BehaviorSubject(0);
  public newNote = new BehaviorSubject(false);
  public isServiceOrderInfoAvailable = new BehaviorSubject(false);
  public isClaimStatusChanged = new BehaviorSubject(undefined);
  public isCreditCardInfoAvailable = new BehaviorSubject(false);
  public isServiceTypeNotService = new BehaviorSubject(false);

  public requestedOperation = {
    make_empty: false,
    isDenied: undefined,
  };

  public isServiceBenchError = new BehaviorSubject({
    errorNo: '',
    errorText: '',
  });

  public isLastUpdateTimeError = new BehaviorSubject(false);
  public loadNote = new Subject();
  public isServiceOrderEditEnable = new BehaviorSubject(true);
  /**
   * subscriber subjects end
   */
  /**
   * Methods start
   */
  getServiceDetailsForm = (searchParams) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: serviceDetailsFormQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getServiceMainTab = (searchParams) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: serviceMainTabQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getServicerInfoById = (servicerId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: servicerProfileByIdQuery,
      variables: { id: servicerId },
      fetchPolicy: 'no-cache',
      // tslint:disable-next-line: semicolon
    });

  getServicerEligibleProfiles = (searchParams) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServicerEligibleProfilesQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getClaimProfileById = (id, dealerGroupNumber, userId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimProfileByIdQuery,
      variables: { id, dealerGroupNumber, userId },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getClaimLastUpdatedTime = (id, userId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimLastUpdateTimeQuery,
      variables: { id, userId },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getClaimCoverageDetails = (id, claimId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimCoverageDetailsQuery,
      variables: { id, claimId },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getClaimDealerInfo = (dealerGroupNumber) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimDealerInfoQuery,
      variables: { dealerGroupNumber },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getServicerInfoByIdSingle = (servicerId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: servicerProfileByIdQuery,
      variables: { id: servicerId },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
  getClaimServicerByClaim = (claimId) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimServicerByClaimQuery,
      variables: { id: claimId },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getSymptomByProductTierResult = (prodIdOriginal) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getSymptomByProductTierQuery,
      variables: { productsIdOriginal: prodIdOriginal },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getSymptomOptionsByTierAndSymptomResult = (prodIdOriginal: any, failType: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getSymptomOptionsByTierAndSymptomQuery,
      variables: { productsIdOriginal: prodIdOriginal, failureType: failType },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getRealLimitOfLiabilityResult = (prodIdOriginal: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getRealLimitOfLiabilityQuery,
      variables: { productsIdOriginal: prodIdOriginal },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  createUpdateClaimProblemSymptom = (probData) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: claimProblemSymptomQuery,
      variables: { ...probData },
      // tslint:disable-next-line: semicolon
    });

  createUpdateExpenseCharges = (expenseCharges) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateExpenseChargesQuery,
      variables: { expenseCharges: [...expenseCharges] },
      // tslint:disable-next-line: semicolon
    });

  getExpenseChargesByClaimResult = (serviceOrderNumber: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getExpenseChargesByClaimQuery,
      variables: { serviceOrderNumber },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getCreditCardInfoBySONumber = (serviceOrderNumber: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getOutOfNetworkInfoQuery,
      variables: { serviceOrderNumber },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getCreditCardInfoCountBySONumber = (serviceOrderNumber: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getOutOfNetworkInfoCountQuery,
      variables: { serviceOrderNumber },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  createUpdateCreditCardInfo = (creditCardInfo) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateCreditCardInfoQuery,
      variables: { outOfNetworkInfo: creditCardInfo },
      // tslint:disable-next-line: semicolon
    });

  getScheduledJob = (serviceOrderNumber: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getScheduledJobQuery,
      variables: { serviceOrderNumber, insertUserName: this.azureService.accountId },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });

  getSpCallStatus = (serviceOrderNumber: any) => {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const payload = {
      CallNo: serviceOrderNumber,
    };
    const response = this.http.post(environment.servicePowerCseUrl, JSON.stringify(payload), {
      headers,
    });
    return response;
    // tslint:disable-next-line: semicolon
  };

  cancelScheduledCall = (serviceOrderNumber: any, cancelReasonCode) => {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const payload = {
      CancelRequest: {
        CallNo: serviceOrderNumber,
        CancelReasonCode: cancelReasonCode,
      },
      InsertUserName: this.azureService.accountId,
    };
    const response = this.http.post(environment.servicePowerCcanUrl, JSON.stringify(payload), {
      headers,
    });
    return response;
    // tslint:disable-next-line: semicolon
  };

  cancelScheduledJob(serviceJobID, cancelReasonCode, cancelReason): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const payload = {
      cancelRequest: {
        serviceJobID,
        cancelReasonCode,
        cancelReason,
        cancelDate: generalDateFormatter(new Date(), 'YYYYMMDD'),
      },
      insertUserName: this.azureService.accountId,
    };

    const payloadFinal = sanitizeObject(payload);

    const response = this.http.post(
      environment.serviceBenchSjcanUrl,
      JSON.stringify(payloadFinal),
      {
        headers,
      },
    );
    return response;
  }

  saveClaim = (claim) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: saveClaimsQuery,
      variables: {
        ...claim,
        contractsId: parseInt(claim.contractsId, 10),
        contractsIdOriginal: parseInt(claim.contractsIdOriginal, 10),
        addresses: claim.addresses ? [claim.addresses] : [],
        productsId: claim.coveredProduct ? claim.coveredProduct.productsId : null,
        productNo: claim.coveredProduct ? claim.coveredProduct.productNo : null,
        contractUniqueId: claim.coveredProduct ? claim.coveredProduct.contractUniqueId : null,
        uniqueId: claim.coveredProduct ? claim.coveredProduct.uniqueId : null,
        manufacturerManufacturer: claim.coveredProduct
          ? claim.coveredProduct.manufacturerManufacturer
          : null,
        modelName: claim.coveredProduct ? claim.coveredProduct.modelNumber : null,
        modelNumber: claim.coveredProduct ? claim.coveredProduct.modelNumber : null,
        serialNumber: claim.coveredProduct ? claim.coveredProduct.serialNumber : null,
        modelSuffix: claim.coveredProduct ? claim.coveredProduct.modelSuffix : null,
        type: claim.coveredProduct ? claim.coveredProduct.type : null,
        subcategoryCode: claim.coveredProduct ? claim.coveredProduct.subcategoryCode : null,
        subcategoryDescription: claim.coveredProduct
          ? claim.coveredProduct.subcategoryDescription
          : null,
        categoryCode: claim.coveredProduct ? claim.coveredProduct.categoryCode : null,
        categoryDescription: claim.coveredProduct ? claim.coveredProduct.categoryDescription : null,
        tierCode: claim.coveredProduct ? claim.coveredProduct.tierCode : null,
        tierDescription: claim.coveredProduct ? claim.coveredProduct.tierDescription : null,
        productPurchaseDate: claim.coveredProduct ? claim.coveredProduct.productPurchaseDate : null,
        productSerialNumber: claim.productSerialNumber
          ? claim.productSerialNumber
          : claim.coveredProduct
          ? claim.coveredProduct.serialNumber
          : null,
        installDate: claim.coveredProduct ? claim.coveredProduct.installDate : null,
        productPurchasePrice: claim.coveredProduct
          ? claim.coveredProduct.productPurchasePrice
          : null,
        productRetailAmount: claim.coveredProduct ? claim.coveredProduct.productRetailAmount : null,
        partsTerm: claim.coveredProduct ? claim.coveredProduct.partsTerm : null,
        laborTerm: claim.coveredProduct ? claim.coveredProduct.laborTerm : null,
        majorComponentTerm: claim.coveredProduct ? claim.coveredProduct.majorComponentTerm : null,
        otherTerm: claim.coveredProduct ? claim.coveredProduct.otherTerm : null,
        serviceLocation: claim.coveredProduct ? claim.coveredProduct.serviceLocation : null,
        fulfilled: claim.coveredProduct ? claim.coveredProduct.fulfilled : null,
        productsIdOriginal: claim.coveredProduct ? claim.coveredProduct.productsIdOriginal : null,
        lastUpdatedBy: claim.lastUpdatedBy ? claim.lastUpdatedBy : null,
        lastUpdateTime: claim.lastUpdateTime ? claim.lastUpdateTime : null,
        createdBy: claim.createdBy ? claim.createdBy : null,
      },
      // tslint:disable-next-line: semicolon
    });

  saveClaimCoverage = (claimCoverage) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateClaimCoverageQuery,
      variables: {
        ...claimCoverage,
      },
      // tslint:disable-next-line: semicolon
    });

  createUpdateClaimServicer = (claimServicer) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateClaimServicerquery,
      variables: {
        ...claimServicer,
      },
      // tslint:disable-next-line: semicolon
    });

  setvalidatorDataModel = (data) => {
    this.validatorDataModel.soNumber = data.serviceOrderInfo.serviceOrder;
    this.validatorDataModel.spCode = data.servicerInfo && data.servicerInfo.servicerNumber;
    this.validatorDataModel.rsp = '';
    this.validatorDataModel.caller = data.contact.firstName;
    this.validatorDataModel.servicer = data.servicerInfo && data.servicerInfo.servicerName;
    this.validatorDataModel.dop = data.productInfoModel.productPurchaseDate
      ? new Date(data.productInfoModel.productPurchaseDate).toISOString().substring(0, 10)
      : null;
    this.validatorDataModel.manufacturer = data.productInfoModel.manufacturerManufacturer;
    this.validatorDataModel.model = data.productInfoModel.modelName;
    this.validatorDataModel.serial = data.productInfoModel.serialNumber
      ? data.productInfoModel.serialNumber
      : data.claimData.coveredProduct
      ? data.claimData.coveredProduct.serialNumber
      : null;
    this.validatorDataModel.failureDescription = data.problemReport
      ? data.problemReport.failureNote
      : null;
    this.validatorDataModel.warranty = '';
    this.validatorDataModel.planSKU = this.getPlanSKUDetail(data.coverageDetails, 'Service Plan');
    this.validatorDataModel.partDist = '';
    this.validatorDataModel.estimate = '';
    // tslint:disable-next-line: semicolon
  };

  setvalidatorPricesDataModel = (data) => {
    if (data) {
      this.validatorDataModel.parts = this.getTotalAmountsByCategory(data, 'Parts').toFixed(2);
      this.validatorDataModel.labor =
        (this.getTotalAmountsByCategory(data, 'Labor') +
        this.getTotalAmountsByCategory(data, 'Labor - Highend Repair') +
        this.getTotalAmountsByCategory(data, 'Labor - Highend SS/TIC') +
        this.getTotalAmountsByCategory(data, 'Labor - Repair') +
        this.getTotalAmountsByCategory(data, 'Labor - SS/TIC') +
        this.getTotalAmountsByCategory(data, 'Labor - Trip/Diag')).toFixed(2);
      this.validatorDataModel.tax = this.getTotalAmountsByCategory(data, 'Tax').toFixed(2);
      this.validatorDataModel.mileage = this.getTotalAmountsByCategory(data, 'Mileage').toFixed(2);
      this.validatorDataModel.misc =
        (this.getTotalAmountsByCategory(data, 'Freight & Shipping') +
        this.getTotalAmountsByCategory(data, 'Misc. Charges')).toFixed(2);
      this.validatorDataModel.deductible = this.getTotalAmountsByCategory(data, 'Deductible').toFixed(2);
      this.validatorDataModel.attempt = this.getTotalAmountsByCategory(data, 'Diagnosis Fee').toFixed(2);
      this.validatorDataModel.attemptFee = this.getTotalAmountsByCategory(data, 'Diagnosis Fee').toFixed(2);
      this.validatorDataModel.total = this.getTotalAmount(data).toFixed(2);
      this.validatorDataModel.totalFee = this.getTotalAmount(data).toFixed(2);
    }
    this.validatorDataModel.partDist = '';
    // tslint:disable-next-line: semicolon
  };

  setvalidatorDataLolModel = (data) => {
    this.validatorDataModel.opp = data.productPurchasePrice
      ? parseFloat(data.productPurchasePrice).toFixed(2)
      : null;
    this.validatorDataModel.ppc = data.usedForProduct ? parseFloat(data.usedForProduct).toFixed(2) : '0.00';
    this.validatorDataModel.lol = (
      parseFloat(this.validatorDataModel.opp) - parseFloat(this.validatorDataModel.ppc)
    ).toFixed(2);

    this.validatorDataModel.lolPercent = this.getPercent(
      this.validatorDataModel.lol,
      this.validatorDataModel.opp,
      2,
    );

    const lolAfter =
      parseFloat(this.validatorDataModel.lol) - parseFloat(this.validatorDataModel.total);
    this.validatorDataModel.lolAfter = !isNaN(lolAfter) ? lolAfter.toFixed(2) : '0';
    this.validatorDataModel.lolAfterPercent = this.getPercent(
      this.validatorDataModel.lolAfter,
      this.validatorDataModel.opp,
      2,
    );
    // tslint:disable-next-line: semicolon
  };

  setValidatorSku = (data) => {
    this.validatorDataModel.planSKU = this.getPlanSKUDetail(data, 'Service Plan');
    this.isValidatorDataAvailable.next(true);
    // tslint:disable-next-line: semicolon
  };

  getPlanSKUDetail = (data, skuKey) => {
    let skuVal = null;
    if (Array.isArray(data)) {
      const tempData = data.filter((el) => el.name === skuKey);
      skuVal = data.filter((el) => el.name === skuKey).length ? tempData[0].policySku : skuVal;
    }
    return skuVal;
    // tslint:disable-next-line: semicolon
  };

  getTotalAmount = (data) => {
    let totalSum = 0;
    const expenseList = [
      'Parts',
      'Labor',
      'Labor - Highend Repair',
      'Labor - Highend SS/TIC',
      'Labor - Repair',
      'Labor - SS/TIC',
      'Labor - Trip/Diag',
      'Tax',
      'Mileage',
      'Freight & Shipping',
      'Misc. Charges',
      'Deductible',
      'Diagnosis Fee',
    ];
    if (Array.isArray(data)) {
      data.forEach((el) => {
        if (
          expenseList.indexOf(el.expenseType) !== -1 &&
          (el.status === 'Requested' || el.status === 'Approved')
        ) {
          totalSum += parseFloat(el.requestedAmount) ? parseFloat(el.requestedAmount) : 0;
        }
      });
    }
    return totalSum;
    // tslint:disable-next-line: semicolon
  };
  getPercent = (num1, num2, pos) => {
    return parseFloat(num2) !== 0 ? ((parseFloat(num1) / parseFloat(num2)) * 100).toFixed(pos) : 0;
    // tslint:disable-next-line: semicolon
  };
  getPropertySum = (data, keyName) => {
    let totalSum = 0;
    if (Array.isArray(data)) {
      totalSum = data.reduce(
        (total, currval) => parseFloat(total) + parseFloat(currval[keyName] ? currval[keyName] : 0),
        0,
      );
    }
    return totalSum;
    // tslint:disable-next-line: semicolon
  };
  getPropertyValue = (data, keyName, keyValue, findValueOf) => {
    let res;
    if (Array.isArray(data)) {
      const filterData = data.filter((el) => el[keyName] === keyValue);
      res = filterData.length ? filterData[0][findValueOf] : undefined;
    }
    return res;
    // tslint:disable-next-line: semicolon
  };

  getTotalAmountsByCategory(data, category) {
    let totalSum = 0;
    if (Array.isArray(data)) {
      const list = data.filter(
        (el) =>
          el.expenseType === category && (el.status === 'Requested' || el.status === 'Approved'),
      );
      list.forEach((el) => {
        totalSum += parseFloat(el.requestedAmount) ? parseFloat(el.requestedAmount) : 0;
      });
    }
    return totalSum;
    // tslint:disable-next-line: semicolon
  }

  jobStatus(jn = null): Observable<any> {
    const { jobNumber, serviceOrderNumber } = this.serviceBenchModal.scheduleJobDetails;

    const payload = {
      statusRequest: {
        serviceJobID: jn || jobNumber,
        crmNumber: serviceOrderNumber,
      },
      insertUserName: this.azureService.accountId,
    };

    const payloadFinal = sanitizeObject(payload);
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const response = this.http.post(environment.serviceBenchSjstUrl, JSON.stringify(payloadFinal), {
      headers,
    });

    return response;
  }

  createUpdateBookingAppointment = (bookingAppointment) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateBookingAppointmentQuery,
      variables: { bookingAppointment },
      // tslint:disable-next-line: semicolon
    });
}
