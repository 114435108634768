import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { FormField } from '../../../shared/form-field/form-field';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { TextAreaField } from '../../../shared/form-field/textarea-field';

@Injectable()
export class SendVtShortKeyEmailService {
  // tslint:disable
  shortCodeTemp = {
    CSHOFR: `
Customer has been informed and accepted cash out amount of: $     
Is the customer’s ADDRESS on the service order the MAILING ADDRESS? Yes ( ) No ( )   
Is the customer’s MAILING ADDRESS different than the address on the Contract? Yes ( ) No ( )
***IF YES, CORRECT ADDRESS ON CONTRACT TO MATCH MAILING ADDRESS***   
Customer address has been verified: Yes ( ) No ( )
Mailing Address:              
Mailing City:      
Mailing State:     
Mailing Zip:        
Phone:         
Customer’s Email: (REQUIRED)
*Is Proof of Purchase required? Yes _____        No _____ (If Yes make sure it been received).
*Is Customer required to send in Cord? ___Y ____N (If Yes, advise customer they have 10 days to send that to New Leaf and Confirm customer has received the “Cut Cord” email out of Zeacom, if not resend)
*Advise customer: WE TRY TO GET CHECKS OUT AS QUICKLY AS WE CAN BUT PLEASE ALLOW UP TO 30 DAYS.  
* ALL CLAIMS ARE SUBJECT TO INSURANCE CARRIER REVIEW AND APPROVAL BEFORE FINAL PAYMENT**
*Make sure to do approval for the Cashout
*SEND SHORTKEY TO CLAIMS@NEWLEAFSC.NET mailto:CLAIMS@NEWLEAFSC.NET  FOR PAYMENT PROCESSING (UNLESS CUSTOMER IS REQUIRED TO SEND IN CORD AND TAG or unit)
*IF CUSTOMER REQUIRED TO SEND IN CORD AND TAG or UNIT, AGENT WILL COMPLETE APPROVAL AND CHANGE STATUS TO PREAPPROVED (Once cord and tag or unit received send Shortkey to claims *)
THIS WILL FULFILL ALL OBLIGATIONS OF THIS CONTRACT FOR THIS UNIT
`,

    RPLOFR: `
Customer has been informed of the replacement model: _____________________.
Is the customer's MAILING ADDRESS different than the address on the service order: Yes ( ) No ( )
Customer address has been verified: Yes ( ) No ( )
Mailing Address:             
Mailing City:      
Mailing State:    
Mailing Zip:        
Phone:         
AS A REMINDER, YOU MUST NOT DISPOSE OF THE UNIT FOR 30 DAYS FROM THE DATE THE OFFER IS TENDERED.

***SEND SHORTKEY TO PENDINGREVIEW@NEWLEAFSC.NET FOR DISPATCH TO SELLING RETAILER***
`,
    CRDOFR: `
Customer has been informed of the store credit in the amount of: $_____________________.
Is the customer’s MAILING ADDRESS different than the address on the service order: Yes ( ) No ( )
Customer address has been verified: Yes ( ) No ( )
Mailing Address:
Mailing City:
Mailing State:
Mailing Zip:
Phone:
We will send the information over to the place of purchase within one business day.
We ask that you go to the place of purchase within 30 days to use your store credit.
WE TRY TO GET CHECKS OUT AS QUICKLY AS WE CAN BUT PLEASE ALLOW UP TO 30 DAYS.
AS A REMINDER, YOU MUST NOT DISPOSE OF THE UNIT FOR 30 DAYS FROM THE DATE THE OFFER IS TENDERED.
IF AFTER 30 DAYS WE OR OUR UNDERWRITER HAVE NOT ASKED FOR IT YOU ARE FREE TO DISPOSE OF THE UNIT.

***SEND SHORTKEY TO PENDINGREVIEW@NEWLEAFSC.NET mailto:PENDINGREVIEW@NEWLEAFSC.NET FOR DISPATCH TO SELLING RETAILER***
`,
    JFRR: `
Justification for Replacement Review (why) :
Initial purchase price of unit (OPP):
Attempted Repair or Diagnostic Fee: $ {{attempt}}
Total of all previous paid, pending and current owed claims:
Remaining LOL:
(If Diag was done please enter total amount of estimate here):
Is Proof of Purchase required? Yes _____        No _____'
If Yes make sure it been received'
If open SO was dispatched you will need to create Sub SO and assigned to Pending Review'
`,
    EDGPRT: `
Subject Line: 
NEW LEAF PARTS ORDER REQUEST/ CUSTOMERNAME / SO / MODEL / SERIAL

Hello,

Would you please continue with placing the part order and shipping parts to the customer?
Could you also provide the tracking number for the order once it becomes available?

CUSTOMER INFORMATION:
CUSTOMERNAME
STREETADDRESS
CITY, ST ZIP

PARTS:
PARTNAME - PARTNUMBER - PRICE
SHIPPING: $
TAX: $

APPROVAL:
Total Estimate: $
Estimate: Approved (  ), Pending (  ), DENIED (  ), REVISED (  )
Pre-Approval Code: CODE
`,
    ADGCACP: `
Customer has been informed of the Adorama Electronic Gift Card in the amount of $
The electronic gift card will be sent to customer via email
This can be used at Adorama online, in store or over the phone.
Customer’s Email Address: (THIS IS REQUIRED)
It can take up to 5 business days for customer to receive the email.
If you do not receive after 5 business days please contact us back at 855-608-4501 and select opt 2
(If customer calls back for Status transfer them to Service Center Questions queue ext 1102)
***NEVER REFER CUSTOMER BACK TO ADORAMA FOR STATUS***
WHAT IS THE DISPOSITION OF CUSTOMER’S ORIGINAL UNIT? (Delete all that does not apply)
Sent To Anew (order will not be placed till unit received)
Depot Facility scrapping unit (PROCEED)
Other (explain)
SEND SHORTKEY TO PENDINGREVIEW@NEWLEAFSC.NET FOR DISPATCH TO ADORAMA*
Advise Customer: THIS WILL FULFILL ALL OBLIGATIONS OF THIS SERVICE CONTRACT FOR THIS UNIT.
`,
    LIEU: `
Calling customer to offer cash in lieu of repairs in the amount of _______. 
If customer does not accept offer, we can move forward with repair but that will leave ________ for the remaining of the contract.
    `,
    CCI: `
Who Called:
|| Reason for Call:
|| Outcome:
    `,
    CHKMSN: `
Check #:
Check Date:
Amount:
SO:
Cust/Serv Prov:
Carrier:

Please confirm that the above referenced check has not been cashed.  If not, please issue a stop payment and reissue check.
    `,
    ADHHELP: `
Subject Line must have ADH Help, SO#, Customers Name,

Example: ADH Help, SO10152631, Jane Smith

a. Customers Name
b. Dealers Name
c. Contract Number and/ or Service Order Number
d. Insurance Carrier Number
e. SKU
f. Type of Unit
g. Manufacture of Unit
h. Model Number
i. Product Date of Purchase
j. ADH short key details
k. Full name at end of email of representative who is submitting verification
    `,
    VESTS: `
Name of s/p and person supplying estimate: {{servicer}} @ {{caller}}
Model number: {{model}}
Serial number: {{serial}}
Cause of Failure: {{failureDescription}}
Manufacturer's Warranty Link: {{warranty}}
Plan SKU: {{planSKU}}
*If exception exists for this MFG refer service provider to the manufacturer if within exception period.* 
 
OPP: $ {{opp}} Previous Paid Claims: $ {{ppc}} Limit of Liability: $ {{lol}}
Where were part prices verified: {{partDist}}
Total Parts: $ {{parts}} Labor: $ {{labor}} Tax: $ {{tax}}
Mileage: $ {{mileage}} Misc.: $ {{misc}}
Deductible (if applicable): $ {{deductible}}
Total Estimate: $ {{total}}
___________________________
Estimate: {{estimate}}
Attempted Repair or Diagnostic Fee if Pending: $ {{attempt}}
Pre-Approval Code: {{preAuth}}

"As a reminder, all pre-approvals are contingent upon verification of your contracted or agreed upon service rates. Contracted rates supersede preliminary estimates and approvals".
    `,
  };
  constructor() {}

  getShortKeyEmailFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        required: true,
        key: 'shortCode',
        label: 'Short Code',
        options: this.generateKeyValObj(Object.keys(this.shortCodeTemp)),
        isSingleselect: true,
        order: 1,
      }),
      new TextboxField({
        key: 'ccEmailAddress',
        label: 'CC Email Address',
        order: 2,
      }),
      new TextboxField({
        key: 'toEmailAddress',
        label: 'To Email Address',
        value: this.getDefaultToEmailAddress(),
        order: 3,
      }),
      new TextAreaField({
        key: 'customText',
        label: 'Custom Text',
        value: this.getEmailTemaplate(Object.keys(this.shortCodeTemp)[0]),
        rows: 30,
        cols: 150,
        order: 4,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getDefaultToEmailAddress() {
    return 'boesc@newleafsc.net';
  }

  getEmailTemaplate(shortCode) {
    return this.shortCodeTemp[shortCode];
  }

  generateKeyValObj = (data) => {
    const finalObj = [];
    data.map((el) => {
      const obj = { key: '', value: '' };
      obj.key = el;
      obj.value = el;
      finalObj.push(obj);
    });
    return finalObj;
  };
}
