import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { CrmDocumentType } from '../constants/enums';
import { Observable } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { HttpClient } from '@angular/common/http';

export const getPreSignedUrlQuery = gql`
  query ($attachmentsId: Int!, $requestType: String!) {
    getPreSignedUrl(attachmentsId: $attachmentsId, requestType: $requestType)
  }
`;

export const deleteAttachmentMutation = gql`
  mutation ($attachmentId: Int!) {
    deleteAttachment(attachmentId: $attachmentId)
  }
`;

const fileSearchQuery = gql`
  query (
    $fileName: String!
    $attachmentsType: AttachmentsType
    $uploadedDate: String
    $offset: Int
    $limit: Int
    $identifierId: Int
  ) {
    getFileAttachments(
      fileName: $fileName
      attachmentsType: $attachmentsType
      uploadedDate: $uploadedDate
      offset: $offset
      limit: $limit
      identifierId: $identifierId
    ) {
      attachmentsID
      attachmentsIDIdOriginal
      fileName
      description
      fileS3URL
      uploadedDate
      uploadedBy
      attachmentsType
      action
      isDeleted
      uploadState
      failedValidationS3URL
      uploadProcessState
      validationResults
    }
  }
`;
@Injectable()
export class ViewFileService {
  constructor(
    private apollo: Apollo,
    private azureService: AzureLoginService,
    private http: HttpClient,
  ) {}

  getViewFileSearchResult = (searchParams, category = CrmDocumentType.Agent, attachmentType?) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: fileSearchQuery,
      variables: {
        ...searchParams,
        fileName: searchParams.fileName ? searchParams.fileName : '',
        attachmentsType: CrmDocumentType[category]
          ? CrmDocumentType[category]
          : CrmDocumentType[CrmDocumentType.Agent],
        limit: parseInt(searchParams.limit, 10),
      },
      fetchPolicy: 'network-only',
    }).valueChanges;

  getFile(url: string): Observable<any> {
    const decodedUrl = url.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25');
    const response = this.http.get(decodedUrl, { responseType: 'blob' });
    return response;
  }

  getPreSignedUrl = (attachmentId, requestType) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getPreSignedUrlQuery,
      variables: {
        attachmentsId: attachmentId,
        requestType,
      },
      fetchPolicy: 'network-only',
    }).valueChanges;

  deleteAttachment = (attachmentId) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: deleteAttachmentMutation,
      variables: {
        attachmentId,
      },
    });
}
