import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ClaimTypeService } from '../claim.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'claim-payment',
  templateUrl: 'claim-payment.template.html',
  providers: [ClaimTypeService],
})
export class ClaimPaymentComponent implements OnInit {
  cols: any[];
  dataList: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  isEditing = false;
  constructor(
    private claimService: ClaimTypeService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.claimService.getClaimPaymentTypes().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'history_action', header: 'HISTORY ACTION', type: 'text' },
      { field: 'updated_time', header: 'UPDATED TIME', type: 'text' },
      { field: 'user', header: 'USER', type: 'text' },
      { field: 'update_details', header: 'UPDATE DETAILS', type: 'text' },
    ];
  }
}
