<ul class="dealerlist">
  <li *ngFor="let dealeritem of data.list" [class.selected]="dealeritem === selectedItem"
    (click)="onSelect(dealeritem, data.navtype)">
    {{dealeritem.name}}
  </li>
</ul>
<div class="seperator"></div>
<div *ngIf="selectedTopEleId !== null && showSearchBox">
  <dealer-details-form [formFields]="formFields$ | async" [advancedFormFields]="advancedFormFields$ | async"
    [isAdvanced]="isAdvanced" [isClearSearch]="isClearSearch" [dealeritem]="selectedItem"
    [navSelectedItem]="navSelectedItem" [selectedItem]="selectedItem" [modalHeader]="modalHeader">
  </dealer-details-form>
</div>