import { Component, OnInit } from '@angular/core';
import { ServiceOrderService } from '../../service-order.service';
@Component({
  selector: 'product-info-table',
  templateUrl: './product-info-table.template.html',
})
export class ProductInfoTableComponent implements OnInit {
  productInfoTable: any[];
  cols: any[];
  constructor(private serviceOrderService: ServiceOrderService) {}

  ngOnInit() {
    this.serviceOrderService.getSOProductTableInfo().subscribe((data) => {
      this.productInfoTable = data;
    });
    this.cols = [
      { field: 'title', header: '' },
      { field: 'paid_claim_amount', header: 'PAID CLAIM AMOUNT' },
      { field: 'limitOfLiability', header: 'LIMIT OF LIABILITY' },
    ];
  }
}
