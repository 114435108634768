<p-dialog
  [header]="modalTitle"
  [(visible)]="displayDialog"
  showeffect="fade"
  [modal]="isBackgroundFreeze"
  [focusTrap]="false"
  [styleClass]="'alert-dialog'"
  [blockScroll]="true"
>
  <div class="modal-content">
    <div [innerHTML]="modalContent"></div>
  </div>
  <ng-template pTemplate="footer" class="modal-footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Cancel">{{submitButtonlabel}}</button>
    </div>
  </ng-template>
</p-dialog>
