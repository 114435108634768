<div class="crm-container">
  <app-section-header [title]="'Expense Charges'" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="colsTotal" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="col.width?{'width':col.width}:[]">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template *ngIf="col.type === 'text' && col.isAmount" pTemplate="output">${{
                rowData[col.field]}}</ng-template>
              <ng-template *ngIf="col.type === 'text' && !col.isAmount" pTemplate="output">{{
                rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,opRow,ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>