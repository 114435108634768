<p-accordion>
  <p-accordionTab header="General Fields" [selected]="selected">
    <div class="general-fields">
      <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </form>
    </div>
  </p-accordionTab>
</p-accordion>
