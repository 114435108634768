import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import { environment } from 'src/environments/environment';

// b2c Policies
const b2cPolicies = {
  names: {
    signUpSignIn: environment.signInName,
    resetPassword: environment.passwordResetName,
  },
  authorities: {
    signUpSignIn: {
      authority: environment.signInAuthority,
    },
    resetPassword: {
      authority: environment.passwordResetAuthority,
    },
  },
};

// msal instance
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUrl,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorities.signUpSignIn.authority],
      postLogoutRedirectUri: environment.redirectUrl,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  });
}

// masal interception
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  // environment.apiScopes)
  // protectedResourceMap.set(environment.apiUrl, ;
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

// msal guard config

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}
