import { Component, Input, OnInit, Type, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractService } from '../../contract.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';

@Component({
  selector: 'customer-information',
  templateUrl: 'customer-information.template.html',
  styleUrls: ['customer-information.scss'],
  providers: [FormFieldControlService],
})
export class CustomerInformationComponent extends FormCanDeactivate implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  @Input() parentComponent: Type<any>;
  form: FormGroup;
  @Output() modelDataChange = new EventEmitter();
  payLoad = '';
  querySubscription: any;
  isFormDataUpdated = false;
  private authDetails: IRoleAuthorizationService;

  constructor(
    private qcs: FormFieldControlService,
    private contractService: ContractService,
    private roleService: RoleAuthorizationService,
  ) {
    super();
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: CustomerInformationComponent,
      generalArray: this.formFields,
    };
    if (this.parentComponent) {
      this.authDetails.parantComponent = this.parentComponent;
    }

    this.form = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.modelDataChange.emit(x);
    });
    this.contractService.isLoading.next(true);
    this.contractService.isMainTabDataAvailable.subscribe({
      next: (isMainTabDataAvailable) => {
        if (
          isMainTabDataAvailable &&
          this.contractService.contractDataModel.mainTab.customerInformation
        ) {
          if (!this.isFormDataUpdated) {
            this.isFormDataUpdated = true;
            const phoneTypeCode1 =
              this.contractService.contractDataModel.mainTab.customerInformation.phoneTypeCode1;
            const phoneTypeCode2 =
              this.contractService.contractDataModel.mainTab.customerInformation.phoneTypeCode2;
            this.form.patchValue({
              customerFirstName:
                this.contractService.contractDataModel.mainTab.customerInformation
                  .customerFirstName,
              customerLastName:
                this.contractService.contractDataModel.mainTab.customerInformation.customerLastName,
              customerPhone:
                this.contractService.contractDataModel.mainTab.customerInformation.customerPhone,
              phoneNumber2:
                this.contractService.contractDataModel.mainTab.customerInformation.phoneNumber2,
              customerEmail:
                this.contractService.contractDataModel.mainTab.customerInformation.customerEmail,
              customerFax:
                this.contractService.contractDataModel.mainTab.customerInformation.customerFax,
              guardian: this.contractService.contractDataModel.mainTab.customerInformation.guardian,
              phoneTypeCode1: phoneTypeCode1
                ? { key: phoneTypeCode1, value: phoneTypeCode1 }
                : null,
              phoneTypeCode2: phoneTypeCode2
                ? { key: phoneTypeCode2, value: phoneTypeCode2 }
                : null,
            });
          }
        }
        this.contractService.isLoading.next(false);
      },
    });
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }
}
