<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<div class="crm-container">
  <app-section-header [title]="'Servicer Contact'" [optionHeader]="opHeader" />

  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [value]="dataList" dataKey="id"
      editMode="row"><ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" [disabled]="paymentNotifEdit" /><p-dropdown
                  *ngIf="col.type === 'select'" [options]="dropdown[col.field]" [(ngModel)]="rowData[col.field]"
                  [filter]="true" appendTo="body">
                </p-dropdown>
                <p-radioButton *ngIf="col.type === 'radio'" name="rowData[col.id]" label="" [(ngModel)]="primaryAddress"
                  value="rowData[col.field]">
                </p-radioButton>
              </ng-template>
              <ng-template *ngIf="col.type === 'text' || col.type === 'select'" pTemplate="output">
                {{rowData[col.field]}} </ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" [disabled]="true" />
              </ng-template>
              <ng-template *ngIf="col.type === 'radio'" pTemplate="output">
                <p-radioButton name="rowData[col.id]" label="" [(ngModel)]="primaryAddress" value="rowData[col.field]">
                </p-radioButton>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event,rowData,opRow,ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData,opRow)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-toast></p-toast>
<p-overlayPanel #opRow appendTo="body">
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader appendTo="body">
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>