import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ResolutionService {
  constructor(private http: HttpClient) {}
  // TODO: Integrate with remote data
  getSymptomOnes() {
    return [
      {
        symptom: 'Have you referred to your owner  manual for any troubleshooting?'
      },
      {
        symptom:
          'Have you reset the unit by pressing the reset button or unplugging for 30 mins and plugging back in?'
      }
    ];
  }

  getSymptomTwos() {
    return [
      {
        symptom: 'Have you referred to your owner manual for any troubleshooting?'
      },
      {
        symptom:
          'Have you reset the unit by pressing the reset button or unplugging for 30 mins and plugging back in?'
      }
    ];
  }

  getSymptomThrees() {
    return [
      {
        symptom:
          'Have you checked to make sure the water line is intact, not bent, clogged, frozen or damaged?'
      },
      {
        symptom: 'Have you referred to your owner manual for any troubleshooting?'
      },
      {
        symptom: 'Is the door closing properly?'
      }
    ];
  }
}
