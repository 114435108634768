import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CrmModule } from './modules/crm/crm.module';

import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './auth/msal.setup';

import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PollingService } from './services/polling.service';
import { provideUserIdleConfig } from 'angular-user-idle';
import { IDLE_TIME, PING_PERIOD, TIMEOUT_COUNT } from './app-config';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, MsalModule, BrowserAnimationsModule, CrmModule, AppRoutingModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    provideUserIdleConfig({ idle: IDLE_TIME, timeout: TIMEOUT_COUNT, ping: PING_PERIOD }),
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    PollingService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
