import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ServicerProfileFormService {
  constructor(
    private http: HttpClient,
    private azureService: AzureLoginService,
  ) {}

  resendPaymentNotification(servicerProfilesIdOriginal): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };

    const payload = {
      operation: 'payment_notification',
      servicer_profile_id: servicerProfilesIdOriginal,
    };

    const response = this.http.post(
      environment.triggerScheduledEmailsUrl,
      JSON.stringify(payload),
      {
        headers,
      },
    );

    return response;
  }
}
