import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormField } from '../../../shared/form-field/form-field';
import { ServiceOrderService } from '../service-order.service';
import { ServiceOrderDataService } from '../service-order-data.service';
import { AddressFormFieldService } from '../../../services/address.service';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { ContractService } from '../../../contracts/contract.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
@Component({
  selector: 'service-order-summary',
  templateUrl: 'service-order-summary.template.html',
  providers: [AddressFormFieldService, ContractService],
})
export class ServiceOrderSummaryComponent implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  searchParams: any;
  isLoading: boolean;
  dealerInfo: any[] = [];
  customerInfo: any[] = [];
  productLocation: FormField<string>[];
  productInfo: any[] = [];
  querySubscription: any;
  contactInfoFormFields: FormField<string>[];
  productInfoFormFields: FormField<string>[];
  deductibleCollectedBy: any;
  isMainTabDataAvailable: boolean;
  isClaimDataAvailable: boolean;
  constructor(
    private contractService: ContractService,
    private serviceOrderService: ServiceOrderService,
    public serviceOrderDataService: ServiceOrderDataService,
    private addressFormFieldService: AddressFormFieldService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
    private roleService: RoleAuthorizationService,
  ) {}

  ngOnInit() {
    this.dealerInfo = this.serviceOrderService.getSODealerInfoList();
    this.customerInfo = this.serviceOrderService.getSOCustomerInfoList();
    this.productInfo = this.serviceOrderService.getSOProductInfoList();
    this.serviceOrderService.getContactFormFields().subscribe((data) => {
      this.contactInfoFormFields = data;
    });
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'caller_type',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const options = configList
          .filter((el) => el.menuType === 'caller_type')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.contactInfoFormFields.find((el) => el.key === 'callerType').options = options;
        this.formValueChanged.emit(true);
      });
    // temp
    // this.isMainTabDataAvailable = true;
    // this.isClaimDataAvailable = true;
    this.addressFormFieldService.getAddressFields().subscribe((fields) => {
      fields.push(
        new DropdownField({
          key: 'isBusinessLocation',
          label: 'Business',
          isSingleselect: true,
          required: true,
          value: { key: 'No', value: false },
          options: [
            { key: 'Yes', value: true },
            { key: 'No', value: false },
          ],
          order: 7,
        }),
      );
      this.productLocation = fields;
    });
    this.serviceOrderService.getProductInfoFormFields().subscribe((data) => {
      this.productInfoFormFields = this.roleService.validateFormFields(data);
    });

    this.serviceOrderDataService.isMainTabDataAvailable.subscribe({
      next: (isMainTabDataAvailable) => {
        if (isMainTabDataAvailable) {
          if (this.serviceOrderDataService.serviceOrderDataModel.productInfoModel) {
            this.deductibleCollectedBy =
              this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.deductibleCollectedBy;
            this.productInfo = this.serviceOrderService.getSOProductInfoList(
              this.serviceOrderDataService.serviceOrderDataModel.productInfoModel,
            );
          }

          this.querySubscription = this.contractService
            .getContractMainTab(
              this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractsIdOriginal,
              this.azureService.accountId,
            )
            .subscribe(({ data }) => {
              if (data.getContractProfileById) {
                this.serviceOrderDataService.serviceOrderDataModel.customerInfo =
                  data.getContractProfileById.contactInformation;
                this.customerInfo = this.serviceOrderService.getSOCustomerInfoList(
                  this.serviceOrderDataService.serviceOrderDataModel.customerInfo,
                );
              }
            });

          this.isMainTabDataAvailable = isMainTabDataAvailable;
        }
      },
    });

    this.serviceOrderDataService.isClaimDataAvailable.subscribe({
      next: (isClaimDataAvailable) => {
        this.serviceOrderDataService.serviceOrderDataModel.contact = this.serviceOrderDataService
          .serviceOrderDataModel.contact
          ? this.serviceOrderDataService.serviceOrderDataModel.contact
          : {};
        if (this.serviceOrderDataService.serviceOrderDataModel.productInfoModel) {
          const productInfoFinal = {
            ...this.serviceOrderDataService.serviceOrderDataModel.productInfoModel,
            deductibleCollectedBy: this.deductibleCollectedBy,
          };
          this.productInfo = this.serviceOrderService.getSOProductInfoList(productInfoFinal);
        }
        this.dealerInfo = this.serviceOrderService.getSODealerInfoList(
          this.serviceOrderDataService.serviceOrderDataModel.dealerInfo,
        );

        this.serviceOrderDataService.serviceOrderDataModel.location = this.serviceOrderDataService
          .serviceOrderDataModel.location
          ? this.serviceOrderDataService.serviceOrderDataModel.location
          : {};
        this.isClaimDataAvailable = false;
        this.isClaimDataAvailable = isClaimDataAvailable;
      },
    });
  }

  formDataChanged(event, model) {
    switch (model) {
      case 'location':
        if (event && 'formValidation' in event) {
          this.serviceOrderDataService.serviceOrderDataModel.formValidation.location =
            event.formValidation;
        }
        if (
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.state
        ) {
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo = {
            ...this.serviceOrderDataService.serviceOrderDataModel.contractInfo,
            addresses: [this.serviceOrderDataService.serviceOrderDataModel.location],
          };
          this.serviceOrderDataService.serviceInfo.next(
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo,
          );
        }
        break;
      case 'contact':
        if (event && 'formValidation' in event) {
          this.serviceOrderDataService.serviceOrderDataModel.formValidation.contact =
            event.formValidation;
        }
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
