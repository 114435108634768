import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { ServiceOrderDataService } from '../service-order-data.service';
import { ServiceOrderService } from '../service-order.service';
import { MessageService } from 'primeng/api';
import { FeatureFlagsService } from '../../../services/feature-flags.service';
import { getKeyByValue, getValueByKey } from '../../../shared/utilities/common-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Component({
  selector: 'servicer-dispatch-modal',
  templateUrl: 'servicer-dispatch-modal.template.html',
  styleUrls: ['./servicer-dispatch-modal.scss'],
  providers: [FormFieldControlService, MessageService],
})
export class ServicerDispatchModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modalTitle: string;
  @Input() formFields: FormField<string>[] = [];
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() claimDisplayDialogChange = new EventEmitter();
  @Input() displayDialog;
  form: FormGroup;
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  formFieldsNoJob: FormField<string>[] = [];
  formNoJob: FormGroup;
  serviceInfo = {
    dealer: '',
    sellingRetailer: '',
    productManufacturer: '',
    productModel: '',
    productCondition: '',
    zipCode: '',
    address1: '',
    city: '',
    state: '',
  };
  productLocationInfo = {
    zipCode: '',
    address1: '',
    city: '',
    state: '',
  };
  displayEmailModal: true;
  displayServiceBenchModal: true;
  displayServicePowerModal: true;
  servicerTableUpdate = false;
  serviceBenchDisabled = true;
  dispatchDisabled = true;
  isServiceBenchFeatureOn = false;
  isServicePowerFeatureOn = false;
  displayInfoModal = false;
  sbInfoTitle = '';
  sbInfoMessage = '';
  isSuspendedServicer = false;
  suspendedInfo = '';
  sbNoJobDispatchDisabled = true;
  spNoJobDispatchDisabled = true;
  displayDispatchNoJobModal = false;
  querySubscriptions: Subscription[] = [];

  constructor(
    private qcs: FormFieldControlService,
    private serviceOrderService: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private featureFlagService: FeatureFlagsService,
    private azureService: AzureLoginService,
    private roleService: RoleAuthorizationService,
  ) {}
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 6);
    this.formFields2 = this.formFields.slice(11, 30);
    this.querySubscriptions.push(
      this.serviceOrderService.getDispatchNoJobFields().subscribe((data: FormField<string>[]) => {
        this.formFieldsNoJob = data;
      }),
    );
    this.formNoJob = this.qcs.toFormGroup(this.formFieldsNoJob);
    this.formNoJob.patchValue({
      dispatch_date: new Date().toISOString().substring(0, 10),
    });
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.formNoJob.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.isServiceBenchFeatureOn = this.featureFlagService.isFeatureFlagEnabled('ServiceBench');
    this.isServicePowerFeatureOn = this.featureFlagService.isFeatureFlagEnabled('ServicePower');
    const { contractInfo, dealerInfo, productInfoModel } =
      this.serviceOrderDataService.serviceOrderDataModel;
    const { serviceBenchCommon } = this.serviceOrderDataService.serviceBenchModal;
    this.sbInfoTitle = getValueByKey(serviceBenchCommon, 'duplicateSbjErrorTitle');
    this.sbInfoMessage = getValueByKey(serviceBenchCommon, 'duplicateSbjErrorMsg');
    if (contractInfo) {
      let coveredProducts = '';
      if (contractInfo.coveredProducts) {
        coveredProducts = contractInfo.coveredProducts.modelName;
      }
      let sellingRetailer = '';
      if (dealerInfo.storeLocationName) {
        sellingRetailer = dealerInfo.storeLocationName;
      }
      let productManufacturer = '';
      if (productInfoModel.manufacturerManufacturer) {
        productManufacturer = productInfoModel.manufacturerManufacturer;
      }
      let productCondition = '';
      if (productInfoModel.type) {
        productCondition = productInfoModel.type;
      }
      this.serviceInfo = {
        ...this.serviceInfo,
        dealer: contractInfo.dealerGroupNumber,
        productModel: coveredProducts,
        address1:
          contractInfo.addresses && contractInfo.addresses[0] && contractInfo.addresses[0].address1,
        state:
          contractInfo.addresses &&
          contractInfo.addresses[0] &&
          contractInfo.addresses[0].state &&
          contractInfo.addresses[0].state.value,
        zipCode:
          contractInfo.addresses && contractInfo.addresses[0] && contractInfo.addresses[0].zipCode,
        sellingRetailer,
        productManufacturer,
        productCondition,
      };
    }
    const { location } = this.serviceOrderDataService.serviceOrderDataModel;
    this.productLocationInfo = {
      zipCode: location.zipCode,
      address1: location.address1,
      city: location.city,
      state: location.state && location.state.value,
    };

    this.querySubscriptions.push(
      this.serviceOrderDataService.isServiceBenchError.subscribe({
        next: (obj) => {
          if (obj.errorNo && obj.errorText) {
            this.displayInfoModal = true;
          } else {
            this.displayInfoModal = false;
          }
          obj.errorNo = '';
          obj.errorText = '';
        },
      }),
    );
  }
  ngOnChanges() {
    if (
      this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
      this.serviceOrderDataService.serviceOrderDataModel.contractInfo.coveredProduct
    ) {
      const manufacturer =
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.coveredProduct
          .manufacturerManufacturer;
      if (manufacturer && manufacturer.toUpperCase().indexOf('WHIRLPOOL') !== -1) {
        this.serviceBenchDisabled = false;
      }
    }
  }
  onSubmit() {
    this.displayDialog = false;
    if (this.serviceOrderService.selectedServicerValue) {
      this.serviceOrderService.selectedServicer.next(
        this.serviceOrderService.selectedServicerValue,
      );
      this.claimDisplayDialogChange.emit(this.displayDialog);
    }
  }
  onCancel() {
    this.displayDialog = false;
    this.claimDisplayDialogChange.emit(this.displayDialog);
  }
  onDispatch() {
    const { productInfoModel } = this.serviceOrderDataService.serviceOrderDataModel;
    const { entitledServicers } = this.serviceOrderDataService.serviceBenchModal;
    const { spEntitledServicers } = this.serviceOrderDataService.servicePowerModal;
    const { selectedServicerValue } = this.serviceOrderService;
    if (
      selectedServicerValue &&
      this.isServiceBenchFeatureOn &&
      getKeyByValue(entitledServicers, selectedServicerValue.servicerName)
    ) {
      this.displayServiceBenchModal = true;
    } else if (
      selectedServicerValue &&
      this.isServicePowerFeatureOn &&
      getKeyByValue(spEntitledServicers, selectedServicerValue.servicerName)
    ) {
      this.displayServicePowerModal = true;
    } else if (this.serviceOrderService.selectedServicerValue) {
      this.displayEmailModal = true;
    }
  }
  onDispatchSB() {
    this.displayServiceBenchModal = true;
  }
  onDispatchSP() {
    this.displayServicePowerModal = true;
  }
  onValidatorSubmit($event) {
    // TODO
  }

  onServicerBenchSchedule($event) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: `Job scheduled successfully.`,
    });
    this.serviceOrderDataService.serviceOrderDataModel.claimData.isServiceBenchDispatch = true;
    this.displayEmailModal = true;
  }

  onServicerPowerSchedule($event) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: `Job scheduled successfully.`,
    });
    this.serviceOrderDataService.serviceOrderDataModel.claimData.isServicePowerDispatch = true;
    this.displayEmailModal = true;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  onDisplayChange(emailDisplay): void {
    this.displayEmailModal = emailDisplay;
    this.displayDialog = false;
    if (this.serviceOrderService.selectedServicerValue) {
      this.serviceOrderService.selectedServicer.next(
        this.serviceOrderService.selectedServicerValue,
      );
    }
    this.claimDisplayDialogChange.emit(this.displayDialog);
  }

  onSelectedServicer(selectedItem): void {
    const { selectedServicerValue } = this.serviceOrderService;
    const { entitledServicers } = this.serviceOrderDataService.serviceBenchModal;
    const { spEntitledServicers } = this.serviceOrderDataService.servicePowerModal;
    this.dispatchDisabled = false;
    const permissionList = [
      'Admin',
      'Claims Manager',
      'Call Center Manager',
      'Back Office Lead',
      'Special Team A-CC-SN',
    ];
    const entitledServicerValue = getKeyByValue(
      entitledServicers,
      selectedServicerValue.servicerName,
    );
    const spEntitledServicerValue = getKeyByValue(
      spEntitledServicers,
      selectedServicerValue.servicerName,
    );
    if (
      this.azureService.roleName === 'Admin' &&
      this.isServiceBenchFeatureOn &&
      entitledServicerValue &&
      entitledServicerValue !== ''
    ) {
      this.sbNoJobDispatchDisabled = false;
    } else {
      this.sbNoJobDispatchDisabled = true;
    }
    if (
      this.azureService.roleName === 'Admin' &&
      this.isServicePowerFeatureOn &&
      spEntitledServicerValue &&
      spEntitledServicerValue !== ''
    ) {
      this.spNoJobDispatchDisabled = false;
    } else {
      this.spNoJobDispatchDisabled = true;
    }
    this.isSuspendedServicer = false;
    this.suspendedInfo = '';
    if (selectedServicerValue.servicerStatus === 'Suspended') {
      this.isSuspendedServicer = true;
      this.suspendedInfo = 'The Selected Servicer is Suspended.';
      if (
        !this.roleService.validateIfPermissionsExist({
          category: 'Claim',
          permission: 'Dispatch claims to Suspended Servicers',
          operation: 'EDIT',
          type: 'Code',
        })
      ) {
        this.dispatchDisabled = true;
        this.sbNoJobDispatchDisabled = true;
        this.spNoJobDispatchDisabled = true;
      }
    }
    if (selectedServicerValue.restricted === 1) {
      this.isSuspendedServicer = true;
      this.suspendedInfo = 'The Selected Servicer is Restricted.';
      if (
        !this.roleService.validateIfPermissionsExist({
          category: 'Claim',
          permission: 'Dispatch claims to Restricted Servicers',
          operation: 'EDIT',
          type: 'Code',
        })
      ) {
        this.dispatchDisabled = true;
        this.sbNoJobDispatchDisabled = true;
        this.spNoJobDispatchDisabled = true;
      }
    }
  }

  onInfoDisplayChange(isDisplay): void {
    this.displayInfoModal = isDisplay;
  }

  onSubmitNoJob() {
    const payload = this.formNoJob.getRawValue();
    this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchedFlag = true;
    this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchDate =
      payload.dispatch_date;
    this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchNoJobFlag = true;
    this.displayDispatchNoJobModal = false;
    this.displayDialog = false;
    if (this.serviceOrderService.selectedServicerValue) {
      this.serviceOrderService.selectedServicer.next(
        this.serviceOrderService.selectedServicerValue,
      );
    }
    this.claimDisplayDialogChange.emit(this.displayDialog);
  }

  onDispatchNoJob() {
    this.displayDispatchNoJobModal = true;
  }

  onDispatchDisplayChange(claimDisplay): void {
    this.displayDialog = claimDisplay;
  }
  
}
