import { Type } from '@angular/core';

/* Operations
 * - The valid operations to check are:
 *     READ_ONLY
 *     EDIT
 *     ADD
 *     DELETE
 */

export enum AuthorizationType {
  FormField,
  RightPanel,
  LeftPanel,
  OptionList,
  Button,
  NoteType
}

export interface IRoleAuthorizationService {
  component: Type<any>;
  authorizationType: AuthorizationType;
  generalArray: Array<any>;
  operation?: string; // Optional to check the type of operation for same component name.
  parantComponent?: Type<any>; // Optional when using common components with different parents.
}

export interface IRoleAuthorizationRouteService {
  component: Type<any>;
  path: string;
}
