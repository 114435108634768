import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ConfigurationTypeService } from './configuration.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { adminConfigMenuEllipsisHeaderOptions, ellipsisRowOptions } from './admin-ellipsis';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { FormField } from '../../shared/form-field/form-field';
import { copy } from '../../shared/utilities/common-utilities';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'admin-menu-configuration',
  templateUrl: 'configuration.template.html',
  styleUrls: ['configuration.scss'],
  providers: [
    ConfigurationTypeService,
    MessageService,
    FormFieldControlService,
    ContractFormFieldService,
  ],
})
export class ConfigurationComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  cols: any[];
  dataList: any[] = [];
  @Input() formFields: FormField<string>[] = [];
  selectedItem: any;
  itemSelected: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = adminConfigMenuEllipsisHeaderOptions;
  ellipsisOptions: any[] = ellipsisRowOptions;
  ellipsisHeaderOptionsSec: any;
  ellipsisOptionsSec: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  header: string;
  displayUpdate: boolean;
  // @Input() searchParams: any = {};
  @Input() searchParams: any;
  editConstant: string;
  form = new FormGroup({});
  isLoading = false;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  querySubscription: Subscription;
  lastOffsetVal = 0;
  disableLoadMore = false;
  menuTypeNameDetails: [];
  defaultOption = { key: 'ALL', value: 'ALL' };
  displayAdd: boolean;
  resultCount = 0;
  params = {
    module: null,
    menuTypeName: null,
    key: null,
    value: null,
    azureUserId: null,
    limit: null,
    offset: null,
  };
  querySubscriptions: Subscription[] = [];
  offsetLimit = 50;
  constructor(
    private qcs: FormFieldControlService,
    private configurationService: ConfigurationTypeService,
    private confirmationService: ConfirmationService,
    private contractService: ContractFormFieldService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private dropdownDataService: DropdownDataService,
    private formFieldService: FormFieldControlService,
    private cdr: ChangeDetectorRef,
    private rps: RolePermissionService,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.getAdminConfigMenuData();
    this.cols = [
      { field: 'module', header: 'Module', type: 'text' },
      { field: 'menuTypeName', header: 'MENU TYPE', type: 'text' },
      { field: 'key', header: 'DESCRIPTION', type: 'text' },
      { field: 'value', header: 'CODE', type: 'text' },
    ];
    this.contractService.getAdminMenuConfigFields().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
      this.form = this.formFieldService.toFormGroup(this.formFields);
      this.form.valueChanges.subscribe((x) => {
        this.formValueChanged.emit(true);
      });
    });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Admin Configuration Screen',
      category: 'Configuration',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Admin Configuration Screen',
      category: 'Configuration',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    if (event.value === 'delete') {
      this.deleteAdminConfigMenu();
    } else {
      this.updateAdminConfigMenu();
    }
  }
  onRowEdit(rowData: any) {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    if (e.value === 'add') {
      this.header = 'Add New Menu Type';
      this.displayAdd = true;
    }
    this.form.reset();
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayUpdate = false;
    this.cdr.detectChanges();
  }
  onChangeModule(event) {
    const module = event.value;
    this.getMenuTypeByModule(module.key, true);
  }
  onSubmit() {
    this.isLoading = true;
    const payload: any = this.form.getRawValue();
    let params: MutationValues = {
      insertUserName: this.azureService.accountId,
      sCMConfigId: payload.menuTypeName.value,
      module: payload.module.key,
      menuTypeName: payload.menuTypeName.key,
      key: payload.key ? payload.key : '',
      value: payload.value ? payload.value : '',
    };
    if (this.header === 'Update Admin Menu Configuration') {
      params = {
        ...params,
        sCMConfigEntryIdOriginal: this.selectedItem.sCMConfigEntryIdOriginal,
      };
    }
    this.querySubscription = this.configurationService
      .createUpdateAdminConfigMenu(params)
      .subscribe(
        ({ data }: any) => {
          if (data.createUpdateAdminConfigMenu) {
            this.showToastStatus(true);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Entry Already Exists`,
            });
          }
          this.form.reset();
          this.dataList = [];
          this.displayAdd = false;
          this.getAdminConfigMenuData(true);
        },
        () => {
          this.showToastStatus(false);
          this.isLoading = false;
        },
      );
  }

  showToastStatus(success: boolean) {
    if (success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Success.`,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed`,
      });
    }
  }
  updateAdminConfigMenu() {
    this.header = 'Update Admin Menu Configuration';
    const menuTypeNameIndex = this.getIndexOfItem();
    const moduleNameIndex = this.formFields[0].options.findIndex(
      (e) => this.selectedItem.module === e.key,
    );
    this.form.patchValue({
      module: {
        value: this.formFields[0].options[moduleNameIndex].value,
        key: this.formFields[0].options[moduleNameIndex].key,
      },
      menuTypeName: {
        value: this.formFields[1].options[menuTypeNameIndex].value,
        key: this.formFields[1].options[menuTypeNameIndex].key,
      },
      key: this.selectedItem.key,
      value: this.selectedItem.value,
    });
    this.displayAdd = true;
  }
  deleteAdminConfigMenu() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Menu Type?`,
      accept: () => {
        const params: MutationValues = {
          insertUserName: this.azureService.accountId,
          sCMConfigEntryIdOriginal: this.selectedItem.sCMConfigEntryIdOriginal,
          sCMConfigId: this.selectedItem.sCMConfigId,
          module: this.selectedItem.module,
          menuTypeName: this.selectedItem.menuTypeName,
          key: this.selectedItem.key ? this.selectedItem.key : '',
          value: this.selectedItem.value ? this.selectedItem.value : '',
          isDeleted: true,
        };
        this.querySubscription = this.configurationService
          .createUpdateAdminConfigMenu(params)
          .subscribe(
            (data) => {
              this.showToastStatus(true);
              this.getAdminConfigMenuData(true);
            },
            () => {
              this.showToastStatus(false);
            },
          );
      },
    });
  }
  onCancel() {
    this.form.reset();
    this.displayAdd = false;
  }
  getIndexOfItem() {
    const menuTypeNameIndex = this.formFields[1].options.findIndex(
      (e) => this.selectedItem.menuTypeName === e.key,
    );
    return menuTypeNameIndex;
  }
  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  getAdminConfigMenuData(reload: boolean = false) {
    this.isLoading = true;
    if (this.lastOffsetVal === 0 && this.searchParams && typeof this.searchParams === 'object') {
      this.params = Object.assign({}, this.searchParams);
      this.params.module = this.params.module ? this.params.module.key : '';
      this.params.menuTypeName = this.params.menuTypeName ? this.params.menuTypeName.key : '';
    }

    this.params.limit = this.offsetLimit;
    this.lastOffsetVal = reload ? 0 : this.lastOffsetVal;
    this.params.offset = this.lastOffsetVal;
    this.params.azureUserId = this.azureService.accountId;
    this.lastOffsetVal += this.offsetLimit;
    if (reload) {
      this.dataList = [];
    }

    this.querySubscription = this.configurationService.getAdminConfigMenu(this.params).subscribe(
      ({ data }) => {
        this.isLoading = false;
        const modifedData = copy(data.getAdminConfigMenuSearchList);
        this.disableLoadMore =
          Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
        const configList = [];
        for (const singleRole of data.getAdminConfigMenuSearchList) {
          const configDetails = {
            sCMConfigId: singleRole.sCMConfigId,
            sCMConfigEntryIdOriginal: singleRole.sCMConfigEntryIdOriginal,
            module: singleRole.module,
            menuTypeName: singleRole.menuTypeName,
            key: singleRole.key,
            value: singleRole.value,
          };
          configList.push(configDetails);
        }
        this.dataList = [...this.dataList, ...configList];
      },
      (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to retrieving Data`,
        });
      },
    );
  }
  getMenuTypeByModule(module, isChangeEvent = false) {
    this.isLoading = true;
    this.configurationService.getMenuTypeNameByModule(module).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.configurationService.isLoading.next(false);
        this.menuTypeNameDetails = copy(data.getMenuTypeNameByModule);
        let menuTypeOptions = [];
        menuTypeOptions =
          this.menuTypeNameDetails && this.menuTypeNameDetails.length > 0
            ? this.menuTypeNameDetails.map((el) => {
                return {
                  key: el[`menuTypeName`],
                  value: el[`sCMConfigIdOriginal`],
                };
              })
            : [];
        this.formFields[1].options = menuTypeOptions;
        this.displayAdd = true;
      },
      (err) => {
        this.configurationService.isLoading.next(false);
        throw err;
      },
    );
  }
}
export interface MutationValues {
  sCMConfigId: number;
  sCMConfigEntryIdOriginal?: number;
  key: string;
  value: string;
  menuType?: string;
  module: string;
  menuTypeName: string;
  isDeleted?: boolean;
  insertUserName: string;
}
