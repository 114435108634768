<div class="crm-container">
  <app-section-header [title]="'Standard'" />
  <div class="sub-section">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFieldsPart1[0]" [form]="form"
            (changeEvent)="onChange($event,'failureDate')"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFieldsPart1[1]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFieldsPart1[2]" [form]="form"
            (changeEvent)="onChange($event,'reportedDate')"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFieldsPart1[3]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFieldsPart1[4]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFieldsPart1[5]" [form]="form"></form-field>
        </div>
      </div>
    </form>
    <div class="failure">
      <div class="grid">
        <div class="col-6">
          <div class="form-label">Failure</div>
          <p-dropdown [options]="failureList" [(ngModel)]="selectedFailure" optionLabel="key">
          </p-dropdown>
        </div>
      </div>
      <app-tab-spinner *ngIf="isLoading" />
      <p-table styleClass="p-datatable-gridlines" class="sympTable" *ngIf="isProdIdOrigFound"
        [columns]="symptomsTableHeaders" [hidden]="isLoading" [value]="symptomsList">
        <ng-template pTemplate="header" let-columns="">
          <tr>
            <th *ngFor="let col of symptomsTableHeaders">{{ col }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData="" let-columns="columns" let-ri="rowIndex">
          <tr>
            <td>{{ rowData }}</td>
            <td class="option-allign">
              <input type="radio" [name]="ri" value="val1" (click)="resolutionSelection(ri,1)">
            </td>
            <td class="option-allign">
              <input type="radio" [name]="ri" value="val1" (click)="resolutionSelection(ri,0)">
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-12 padding-right-md">
          <form-field [formField]="formFields[7]" [form]="form"></form-field>
        </div>
      </div>
    </form>
    <p-toast></p-toast>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>