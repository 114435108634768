<div class="import-file">
  <div class="main-tab__titles">
    <div class="title">File Attachments</div>
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" class="import-file__content">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns"></th>
          <label class="import-file__label">File</label>
          <p-fileUpload name="myfile[]" accept=".csv, .txt" chooseLabel="Browse" (uploadHandler)="onUpload($event)"
            [customUpload]="true" (onRemove)="fileReset()">
          </p-fileUpload>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'">{{rowData[col.field]}}</button>
            <p-cellEditor *ngIf="col.type === 'text'">
              <ng-template pTemplate="input">
                <input class="input-text" type="text" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,optionRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #optionRow><p-listbox></p-listbox></p-overlayPanel>
<p-overlayPanel #optionHeader><p-listbox></p-listbox></p-overlayPanel>