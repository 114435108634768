export const RequiredColummns = {
  contractColumnNames: [
    'Dealer Number',
    'Dealer Number Location',
    'Dealer Name',
    'PLAN Unique Identifier',
    'Transaction Reason',
    'First Name',
    'Last Name',
    'Address',
    'City',
    'ST',
    'ZIP',
    'Phone',
    'SKU',
    'Term Months',
    'Plan DOP',
    'Plan Purchase Price',
    'Product Type (CODE)',
    'Manufacturer',
    'Model Number',
    'N  U  R  Other',
    'New Leaf Selling Retailer Code',
    'Selling Retailer Name',
    'Dealer Cost(Reported)',
    'Country',
    'Contract Status',
    'Transaction Date'
  ],
  cancellationColumnNames: [
    'Dealer Refund (Override)',
    'Dealer Refund (Override) ',
    'Dealer #',
    'Contract #',
    'Unique ID',
    'Day of Cancellation',
    'Cancellation Method Code',
    'Reason for Cancellation',
    'Customer First Name',
    'Customer Last Name',
    'Cancellation Transaction Date',
    'Refund To Customer (Override)',
    'Cancellation Fee'
  ],
  claimPayeeCoulmnNames: [
    'SO#',
    'New Status',
    'Currency',
    'Form',
    'Date Paid',
    'Chk/Ref',
    'Amt Pd'
  ]
};

export const DisableList = {
  ImportPriceTable: ['priceTableWorkingTable', 'coverageRates']
};
