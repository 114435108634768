import { Component, Input, OnInit } from '@angular/core';
import { PositionService } from './position.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisCrudOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { optionEllipsisHeaderOptions } from '../catalog.constant';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'position',
  templateUrl: 'position.template.html',
  providers: [PositionService],
})
export class PositionComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = optionEllipsisHeaderOptions;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  constructor(private positionService: PositionService) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.positionService.getPosition().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [{ field: 'name', header: 'Name', type: 'text' }];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
}
