import { Injectable } from '@angular/core';
import { ConfigurationTypeService } from '../../administration/configuration/configuration.service';

@Injectable()
export class ClaimsLookup {
  claimsLookup: any;
  constructor(private configurationService: ConfigurationTypeService) {
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'claims_lookup',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.claimsLookup = configList
          .filter((el) => el.menuType === 'claims_lookup')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
      });
  }
}
