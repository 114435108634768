import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrmLayoutComponent } from './modules/crm/crm-layout/crm-layout.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'crm',
    component: CrmLayoutComponent,
    canActivate: [MsalGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
