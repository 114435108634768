import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ServiceTypeService } from '../services.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../service/service-ellipsis';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType
} from '../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../shared/form-field/form-field';
import { ServiceModuleService } from '../../services/service-module.service';
import { copy } from '../../shared/utilities/common-utilities';

@Component({
  selector: 'claim-adjudication-tool',
  templateUrl: 'claim-adjudication.template.html',
  providers: [ServiceTypeService, ServiceModuleService]
})
export class ClaimAdjudicationComponent implements OnInit {
  cols: any[];
  dataList: any[];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  formModalInfo: FormField<any>[] = [];
  constructor(
    private configurationService: ServiceTypeService,
    private cdr: ChangeDetectorRef,
    private claimService: ServiceModuleService
  ) {
    this.displayAdd = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.configurationService.getClaimAdjudicationTypes().subscribe(data => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'question', header: 'QUESTION', type: 'text' },
      { field: 'answer', header: 'ANSWER', type: 'text' },
      { field: 'coverage_name', header: 'COVERAGE NAME', type: 'text' },
      { field: 'subcategory', header: 'SUBCATEGORY', type: 'text' },
      { field: 'category', header: 'CATEGORY', type: 'text' },
      { field: 'manufacturer', header: 'MANUFACTURER', type: 'text' }
    ];
    this.claimService
      .getSeviceFormModalFields()
      .subscribe(formModalInfo => (this.formModalInfo = formModalInfo));
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e) {
    if (e.value === 'Add') {
      this.displayAdd = true;
      this.header = 'Claim Adjudicatiom Modal';
    }
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.cdr.detectChanges();
  }
}
