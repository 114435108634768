import { Component, OnInit } from '@angular/core';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { FormField } from '../../../shared/form-field/form-field';
import { DealerFormFieldService } from '../../../services/dealer-form-field.service';
import { ServiceOrderService } from '../service-order.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { TechnicalInformationComponent } from './technical-information/technical-information.component';
import { ProblemReportComponent } from './problem-report/problem-report.component';
import { ExpenseChargesComponent } from './expense-charges/expense-charges.component';
import { GeneratePreAuthComponent } from './generate-pre-auth/generate-pre-auth.component';
@Component({
  selector: 'service-details',
  templateUrl: 'service-details.template.html',
  styleUrls: ['service-details.scss'],
})
export class ServiceDetailsComponent {
  techformFields: FormField<any>[] = [];
  preAuthformFields: FormField<any>[] = [];
  componentDisplay = {
    TechnicalInformationComponent: null,
    ProblemReportComponent: null,
    ExpenseChargesComponent: null,
    GeneratePreAuthComponent: null,
  };
  constructor(
    private serviceOrderService: ServiceOrderService,
    private roleService: RoleAuthorizationService,
  ) {
    this.componentDisplay = {
      TechnicalInformationComponent: this.roleService.isVisible(TechnicalInformationComponent),
      ProblemReportComponent: this.roleService.isVisible(ProblemReportComponent),
      ExpenseChargesComponent: this.roleService.isVisible(ExpenseChargesComponent),
      GeneratePreAuthComponent: this.roleService.isVisible(GeneratePreAuthComponent),
    };
    this.serviceOrderService.getTechnicalFormFields().subscribe((data) => {
      this.techformFields = data;
    });
    this.serviceOrderService.getGenerateAuthRequestFormFields().subscribe((data) => {
      this.preAuthformFields = data;
    });
  }
}
