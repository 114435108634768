import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BuyingGroupService } from '../../services/buying-group.service';
import { BuyingGroup } from './buying-group';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { buyingGroupEllipsisOptions } from '../constants/buying-group-ellipsis';
import { SearchBuyingGroupService } from './seach-buying-group.service';
import { TabsService } from '../../services/tabs.service';
import { ellipsisOptions } from '../../shared/constants/ellipsis-options';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { debug } from 'console';
import { BuyingGroupDisplayStatus } from '../constants/buying-group-constant';
import { Tab } from '../../services/tab';

@Component({
  selector: 'search-buying-group',
  templateUrl: 'search-buying-group.template.html',
  styleUrls: ['search-buying-group.scss'],
  providers: [BuyingGroupService, SearchBuyingGroupService],
})
export class SearchBuyingGroupComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  searchResults: BuyingGroup[];
  cols: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  buyingGroupDisplayStatus = BuyingGroupDisplayStatus;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = buyingGroupEllipsisOptions;
  querySubscription: any;
  isLoading: boolean;
  authDetails: IRoleAuthorizationService;
  newTab: Tab;
  constructor(
    private search: BuyingGroupService,
    private searchBuyingGroupService: SearchBuyingGroupService,
    private tabService: TabsService,
    private roleService: RoleAuthorizationService,
    private dropdownDataService: DropdownDataService,
  ) {}

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SearchBuyingGroupComponent,
      generalArray: this.ellipsisOptions,
    };
    this.ellipsisOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.cols = [
      { field: 'masterBuyingGroupName', header: 'MASTER BUYING GROUP', type: 'text' },
      { field: 'status', header: 'STATUS', type: 'status' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'name', header: 'BUYING GROUP NAME', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'zipCode', header: 'ZIP', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
    ];
    this.searchParams.status = this.searchParams.status ? this.searchParams.status : '';
    this.searchParams.masterBuyingGroupId = this.searchParams.masterBuyingGroupId
      ? this.searchParams.masterBuyingGroupId
      : '';
    this.isLoading = true;
    this.querySubscription = this.searchBuyingGroupService
      .getBuyingGroupSearchResult(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifedData = data.getBuyingGroupSearchResults.map((item) => {
            const masterBuyingGroup = this.dropdownDataService.masterBuyingGroupData.find(
              (x) => x.masterBuyingGroupIDOriginal === item.masterBuyingGroupId,
            );
            return {
              ...item,
              masterBuyingGroupName: masterBuyingGroup ? masterBuyingGroup.name : '',
            };
          });

          this.searchResults = [...modifedData];
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  OnSelect() {}

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.tabService.createTab({
      header: 'Buying Group',
      content: this.selectedItem,
      type: 'Buying Group',
    });
  }
  openTab(data: any) {
    this.newTab = {
      header: 'Buying Group Profile',
      content: data,
      type: 'Buying Group',
    };
    this.tabService.createTab(this.newTab);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
