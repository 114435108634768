import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const contractAddress = gql`
  query($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      addresses(categoryType: Contract) {
        id
        address1
        address2
        state
        city
        zipCode
        country
      }
    }
  }
`;

const dealerAddress = gql`
  query($id: Int!) {
    getDealerProfileById(id: $id) {
      id
      addresses(categoryType: DealersGroup) {
        id
        state
        city
        zipCode
        address1
        address2
      }
    }
  }
`;
@Injectable()
export class AddressService {
  constructor(private apollo: Apollo) {}

  getAddress = (caller, searchParams) => {
    let searchQuery = contractAddress;
    switch (caller) {
      case 'contract-main':
        searchParams = { id: parseInt(searchParams, 10) };
        searchQuery = contractAddress;
        break;
      case 'dealer-main':
        searchParams = { id: parseInt(searchParams, 10) };
        searchQuery = dealerAddress;
        break;
    }
    return this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: searchQuery,
      variables: { ...searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };
}
