<div class="crm-container">
  <app-section-header [title]="'Insurance Company Search Results'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row"
      [hidden]="isLoading" [scrollable]="true" [rows]="10" scrollHeight="700px">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text" [(ngModel)]="rowData[col.field]" />
                <input *ngIf="col.type === 'checkbox'" type="checkbox" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output"> {{rowData[col.field]}} </ng-template>
              <ng-template *ngIf="col.type === 'status'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field] |
                  uppercase}}</button></ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.field === 'number'" pTemplate="output">
                <a (click)="openTab(rowData)" onmouseover="this.style.cursor='pointer'">{{rowData[col.field] |
                  uppercase}}</a>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="div" *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event,rowData,opRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event, opRow)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event, opHeader)">
  </p-listbox>
</p-overlayPanel>