import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationPanel } from '../../shared/navigation-panel/navigation-panel.model';
import { NavigationPanelComponent } from '../../shared/navigation-panel/navigation-panel.component';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { CoverageFormFieldService } from '../coverage-form-field.service';
import { InsuranceCompanyMainComponent } from './tabs/insurance-company-main/insurance-company-main.component';
import { InsuranceCompanyAccountingComponent } from './tabs/accounting/insurance-company-accounting.component';
import { InsuranceCompanyService } from './insurance-company.service';
import _find from 'lodash/find';
import { NoteComponent } from '../../shared/notes/note.component';

@Component({
  selector: 'cv-insurance-company',
  templateUrl: 'insurance-company.template.html',
  styleUrls: ['insurance-company.scss'],
  providers: [CoverageFormFieldService, InsuranceCompanyService],
})
export class InsuranceCompanyComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  querySubscription: any;
  isLoading: boolean;
  component = InsuranceCompanyComponent;
  componentDisplay = false;
  constructor(
    private roleService: RoleAuthorizationService,
    private insuranceCompanyService: InsuranceCompanyService,
  ) {
    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: InsuranceCompanyMainComponent,
        data: { name: 'Main' },
        title: 'MN',
        tabname: 'Main',
        tabKey: 'main',
      },
      {
        component: InsuranceCompanyAccountingComponent,
        data: { name: 'Account' },
        title: 'ACT',
        tabname: 'Account',
        tabKey: 'acc',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Accounting Tab',
          category: 'Insurance Company',
        },
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: InsuranceCompanyComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = roleService.validateSideMenu(this.navigationPanel);
  }

  ngOnInit() {
    this.componentDisplay = this.roleService.isVisible(NoteComponent, InsuranceCompanyComponent);
    this.insuranceCompanyService.insuranceCompanyConfig.searchParams = !isNaN(this.searchParams)
      ? parseInt(this.searchParams, 10)
      : 0;

    if (
      this.insuranceCompanyService.insuranceCompanyConfig &&
      this.insuranceCompanyService.insuranceCompanyConfig.searchParams
    ) {
      this.isLoading = true;
      this.querySubscription = this.insuranceCompanyService
        .getInsuranceCompanyData(this.insuranceCompanyService.insuranceCompanyConfig.searchParams)
        .subscribe(
          ({ data, loading }: any) => {
            this.isLoading = loading;
            const modifiedData = data.getInsuranceCompanyProfileById;
            if (modifiedData) {
              this.insuranceCompanyService.insuranceCompanyDataModel.leftBaseSection = {
                insuranceComapaniesIdOriginal: modifiedData.insuranceComapaniesIdOriginal,
                status: modifiedData.status,
                number: modifiedData.number,
                name: modifiedData.name,
                companyType: modifiedData.companyType,
                fedTaxId: modifiedData.fedTaxId,
                billingMethod: modifiedData.billingMethod,
                pendingBillingTransaction: modifiedData.pendingBillingTransaction,
                currency: modifiedData.currency,
                showOnRemittance: modifiedData.showOnRemittance,
                includeVoidContractOnRemittance: modifiedData.includeVoidContractOnRemittance,
                activateContractUponRemittance: modifiedData.activateContractUponRemittance,
                externalId: modifiedData.externalId,
              };
              this.insuranceCompanyService.insuranceCompanyDataModel.mainTab = {
                contactInformation: {
                  ...modifiedData.contactInfo,
                  businessPhoneTypeCode: modifiedData.contactInfo.businessPhoneTypeCode
                    ? {
                        key: modifiedData.contactInfo.businessPhoneTypeCode,
                        value: modifiedData.contactInfo.businessPhoneTypeCode,
                      }
                    : null,
                },
                mailingAddress: _find(modifiedData.addresses, (add) => add.isMailingAddress),
                billingAddress: _find(modifiedData.addresses, (add) => add.isBillingAddress),
              };
              this.insuranceCompanyService.insuranceCompanyDataModel.account = {
                arAccount: modifiedData.arAccount,
                apAccount: modifiedData.apAccount,
                glAccount: modifiedData.glAccount,
              };
              this.insuranceCompanyService.setDataAvailability(true);
            }
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    }
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
