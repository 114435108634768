<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
  <div class="modal-content" *ngIf="!isLoading">
    <form [formGroup]="form" >
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[0]" [form]="form" (changeEvent)="onChange($event)"> </form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[1]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[6]" [form]="form"></form-field>
        </div>
        <div class="col-12">
          <form-field [formField]="formFields[7]" [form]="form"
          (changeEvent)="showCalculateRefund($event)"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[5]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[4]" [form]="form" (change)="onDisable($event, formFields[3])">
          </form-field>
        </div>

        <div class="col-12">
          <button class="crm-button" *ngIf="isProRataSelected" type="submit" (click)="calculateCancellationRefund()"
            label="Calculate Refund" [disabled]="!form.valid">
            Calculate Refund
          </button>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[8]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[9]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[10]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[11]" [form]="form"></form-field>
        </div>
        <div class="col-12">
          <form-field [formField]="formFields[12]" [form]="form" (changeEvent)="updateMJC($event)"></form-field>
        </div>
        <div class="col-6" *ngIf="isMJC">
          <form-field [formField]="formFields[13]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Proceed Cancel"
        [disabled]="!form.valid">Proceed Cancel</button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Close">Close</button>
    </div>
  </ng-template>
</p-dialog>