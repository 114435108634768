import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { Apollo } from 'apollo-angular';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';

const getAdminConfigMenuSearchQuery = gql`
  query (
    $module: String
    $menuType: String
    $menuTypeName: String
    $key: String
    $value: String
    $offset: Int
    $limit: Int
  ) {
    getAdminConfigMenuSearchList(
      module: $module
      menuType: $menuType
      menuTypeName: $menuTypeName
      key: $key
      value: $value
      offset: $offset
      limit: $limit
    ) {
      sCMConfigEntryId
      sCMConfigEntryIdOriginal
      sCMConfigId
      module
      menuType
      menuTypeName
      key
      value
      __typename
    }
  }
`;

const getMenuTypeNameByModuleQuery = gql`
  query ($module: String!) {
    getMenuTypeNameByModule(module: $module) {
      menuTypeName
      sCMConfigIdOriginal
    }
  }
`;

const createUpdateAdminConfigMenu = gql`
  mutation (
    $sCMConfigEntryIdOriginal: Int
    $module: String
    $menuType: String
    $menuTypeName: String!
    $key: String!
    $value: String!
    $isHistoryItem: Boolean
    $addedTimeStamp: Date
    $insertUserName: String!
    $isDeleted: Boolean
  ) {
    createUpdateAdminConfigMenu(
      adminConfigMenu: {
        sCMConfigEntryIdOriginal: $sCMConfigEntryIdOriginal
        module: $module
        menuType: $menuType
        menuTypeName: $menuTypeName
        key: $key
        value: $value
        isHistoryItem: $isHistoryItem
        addedTimeStamp: $addedTimeStamp
        insertUserName: $insertUserName
        isDeleted: $isDeleted
      }
    ) {
      sCMConfigEntryIdOriginal
      sCMConfigEntryId
      sCMConfigId
      module
      menuType
      menuTypeName
      key
      value
      isHistoryItem
      addedTimeStamp
      insertUserName
      isDeleted
    }
  }
`;
@Injectable({
  providedIn: 'root',
})
export class ConfigurationTypeService {
  constructor(private apollo: Apollo) {}
  public isLoading = new BehaviorSubject(true);
  getEmailConfigurationTypes(): Observable<any[]> {
    return of([
      {
        id: 1,
        active: 'true',
        type: 'Dispatch',
        message_code: '!Standard Dispatch',
        description: 'Dispatch',
      },
      {
        id: 2,
        active: 'true',
        type: 'Customer Dispatch',
        message_code: 'CRP Apple Designer',
        description: 'Customer Reimbursement Apple Designer',
      },
      {
        id: 3,
        active: 'true',
        type: 'Customer Follow Up',
        message_code: 'Customer Follow Up',
        description: 'Customer Follow Up Standard',
      },
    ]);
  }
  getFileAttachmentTypes(): Observable<any[]> {
    return of([
      {
        file: '!Standard',
        uploaded_by: 'John Doe',
        uploaded_bys: '12/12/2017 3:56:24 P.M',
      },
      {
        file: 'Doc2',
        uploaded_by: 'John Doe',
        uploaded_bys: '12/12/2017 3:34:24 P.M',
      },
    ]);
  }
  getAdminConfigMenu = (searchParams) =>
    this.apollo.use('crm').query<any>({
      query: getAdminConfigMenuSearchQuery,
      variables: {
        module: searchParams.module,
        menuType: searchParams.menuType,
        menuTypeName: searchParams.menuTypeName,
        key: searchParams.key,
        value: searchParams.value,
        offset: searchParams.offset,
        limit: searchParams.limit,
      },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
  createUpdateAdminConfigMenu = (params) =>
    this.apollo.use('crm').mutate({
      mutation: createUpdateAdminConfigMenu,
      variables: {
        ...params,
      },
      // tslint:disable-next-line: semicolon
    });
  getMenuTypeNameByModule = (queryParam) => {
    return this.apollo.use('crm').query<any>({
      query: getMenuTypeNameByModuleQuery,
      variables: { module: queryParam },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };
}
