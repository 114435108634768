import { SYSTEM_USER_ROLES } from '../shared/constants/user-roles';

export const coverageTabEllipsisHeaderOptions = [
  { label: 'Add Covered Item', value: 'add', callback: 'onClickAdd' },
  { label: 'Multiple Entry', value: 'addMultiple', callback: 'onClickMultipleAdd' }
];

export const exceptionsEllipsisHeaderOptions = [
  { label: 'Add', value: 'add', callback: 'onClickAdd' }
];

export const attachementEllipsisHeaderOptions = [
  { label: 'Add', value: 'add', callback: 'onClickAdd' }
];
export const attachementEllipsisRowOptions = [
  { label: 'Delete', value: 'delete', callback: 'onClickDelete' }
];

export const statusLogHeaderOptions = [
  { label: 'Print', value: 'print', callback: 'onClickPrint' },
  { label: 'Export', value: 'export', callback: 'onClickExport' }
];

export const soServicerOptions = [
  { label: 'Dispatch', value: 'dispatch', callback: 'onClickDisptch' }
];

// CREATED AS PER PD-1008198
export const ALLOWED_ROLES_AWAITING_INVOICE_STATUS = [
  SYSTEM_USER_ROLES.CSR_2_PLUS,
  SYSTEM_USER_ROLES.CSR_3,
  SYSTEM_USER_ROLES.BACK_OFFICE,
  SYSTEM_USER_ROLES.BACK_OFFICE_LEAD,
  SYSTEM_USER_ROLES.SERVICE_PROVIDER_INDEPENDENT,
  SYSTEM_USER_ROLES.ADMIN,
  SYSTEM_USER_ROLES.CALL_CENTER_MANAGER,
  SYSTEM_USER_ROLES.CALL_CENTER_SUPERVISOR,
  SYSTEM_USER_ROLES.CLAIMS_1_PLUS,
  SYSTEM_USER_ROLES.CLAIMS_MANAGER,
  SYSTEM_USER_ROLES.DATA_ADMINISTRATION,
  SYSTEM_USER_ROLES.SSD_LITE,
  SYSTEM_USER_ROLES.SSD_MED,
  SYSTEM_USER_ROLES.SSD_PLUS
];
