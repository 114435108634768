<div class="so-claims-tab">
  <app-section-header [title]="'Claims'" [optionHeader]="opHeader">
    <button *ngIf="buttonAuth['Add Claim'] && enableAddClaim" class="header-button" type="button" id="btn-add"
      label="Add" (click)="onClickAdd($event, opHeader)">
      Add Claim
    </button>
  </app-section-header>
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading">

    </app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [value]="admin" [hidden]="isLoading">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
        </div>
      </ng-template><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <div *ngIf='col.field === "serviceOrderNumber"'>
              <div class="crm-link" (click)="openTab(rowData)" onmouseover="this.style.cursor='pointer'">
                {{rowData[col.field]}}
              </div>
            </div>
            <div *ngIf='col.field !== "serviceOrderNumber"'>{{rowData[col.field]}}</div>
          </td>
          <td class="last-cell">
            <div *ngIf="!isEditing">
              <div class="three-dots"
                *appSetRolePermissionsDirective="{type:'optionRow', operation:'ADD', permission:'Subclaim', category: 'Claim'}"
                (click)="ellipsisClick($event,rowData,optionRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event,optionRow) : ellipsisOptionClick($event,optionRow)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event,optionRow)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="'Add Claim'" [(visible)]="displayDialog" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="modal-content">
    <div class="crm-container">
      <app-tab-spinner *ngIf="isAddClaimLoading"></app-tab-spinner>
      <p-table styleClass="p-datatable-gridlines" [columns]="dialogCols" [value]="dialogDataList"
        [hidden]="isDialogLoading">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{col.header}}</th>
            <th style="width: 120px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
          <tr>
            <td *ngFor="let col of columns">
              <div *ngIf="col.type==='text'">{{rowData[col.field]}}</div>
              <div *ngIf="col.type==='dropdown'">
                <p-dropdown id="type" [options]="this[col.typeList]" [(ngModel)]="rowData[col.field]"
                  placeholder="Select" optionLabel="label">
                </p-dropdown>
              </div>
            </td>
            <td>
              <button class="crm-button"
                *ngIf="(azureService.roleName === 'Admin') || (rowData['fulfilled'] && rowData['fulfilled'].toUpperCase() !== 'YES')"
                type="button" id="btn-add" label="Add" (click)="addNewClaim(rowData, ri)">
                Add
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>
<p-dialog [header]="'Limits of Liability'" [(visible)]="displayLolDialog" showeffect="fade" [modal]="true"
  [styleClass]="'lol-dailog'" [blockScroll]="true">
  <div class="modal-content">
    <div class="crm-container">
      <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
      <p-table styleClass="p-datatable-gridlines" [columns]="dialogLolCols" [value]="dialogLolDataList"
        [hidden]="isDialogLolLoading">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{col.header}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
          <tr>
            <td *ngFor="let col of columns">
              <div *ngIf="col.type==='text'">{{rowData[col.field]}}</div>
              <div *ngIf="col.type==='dropdown'">
                <p-dropdown id="type" [options]="this[col.typeList]" [(ngModel)]="rowData[col.field]"
                  placeholder="Select" optionLabel="label">
                </p-dropdown>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>
<p-dialog [header]="'Product Purchase Price Issue'" [(visible)]="displayPpp" showeffect="fade" [modal]="true">
  {{pppPopupDescription}}
  <ng-template pTemplate="footer">
    <button class="crm-button" type="button" id="btn-ok" label="ok" (click)="closeDialog()">
      OK
    </button></ng-template>
</p-dialog>