import gql from 'graphql-tag';

export const getBuyingGroupProfileByIdQuery = gql`
  query($id: Int, $number: String) {
    getBuyingGroupProfileById(id: $id, number: $number) {
      agentId
      status
      number
      name
      fedTaxId
      agentType
      sProfileName
      activationDate
      expirationDate
      accountingNumber
      agentIdOriginal
      isDeleted
      masterBuyingGroupId
      priceTableName
    }
  }
`;

export const getBuyingGroupMainTabQuery = gql`
  query($id: Int!) {
    getAddressesDetails(id: $id, categoryType: Agent) {
      addressIDOriginal
      address1
      address2
      zipCode
      state
      city
      isMailingAddress
      isBillingAddress
      primary
      residential
      country
    }
    getContactDetails(id: $id, categoryType: Agent) {
      contactIdOriginal
      firstName
      lastName
      businessPhone
      phoneNumber1
      phoneNumber2
      guardian
      email
      fax
      phoneTypeCode1
      phoneTypeCode2
      businessPhoneTypeCode
    }
  }
`;

export const createUpdateBuyingGroupQuery = gql`
  mutation(
    $status: String
    $number: String
    $name: String
    $fedTaxId: String
    $agentType: String
    $sProfileName: String
    $activationDate: Date
    $expirationDate: Date
    $accountingNumber: String
    $masterBuyingGroupId: Int
    $agentIdOriginal: Int
    $contactInfo: ContactInputType
    $addresses: [AddressInputType]
    $priceTableName: String
    $insertUserName: String
  ) {
    createUpdateBuyingGroup(
      agentInput: {
        status: $status
        number: $number
        name: $name
        fedTaxId: $fedTaxId
        agentType: $agentType
        sProfileName: $sProfileName
        activationDate: $activationDate
        expirationDate: $expirationDate
        accountingNumber: $accountingNumber
        masterBuyingGroupId: $masterBuyingGroupId
        agentIdOriginal: $agentIdOriginal
        contactInfo: $contactInfo
        addresses: $addresses
        priceTableName: $priceTableName
        insertUserName: $insertUserName
      }
    ) {
      agentId
      agentIdOriginal
      accountingNumber
    }
  }
`;
