import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServiceAuditTableService {
  constructor() {}
  getAuditTableDetails(): Observable<any[]> {
    return of([
      {
        id: 1,
        verticalTitle: 'Audit Comments',
        auditReason: '',
        auditOutcome: ''
      },
      {
        id: 2,
        verticalTitle: 'External Audit Comments',
        auditReason: '',
        auditOutcome: ''
      }
    ]);
  }
}
