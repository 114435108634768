import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwServerError } from '@apollo/client';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { AngularFaviconService } from 'angular-favicon';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, filter, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly destroy$ = new Subject();

  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private ngxFavicon: AngularFaviconService,
  ) {}

  ngOnInit() {
    this.ngxFavicon.setFavicon(`assets/${environment.favIcon}`);
    this.primengConfig.ripple = true;
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 0) {
          this.authService.instance
            .acquireTokenSilent({
              scopes: [],
              account: this.authService.instance.getAllAccounts()[0],
            })
            .then((data: AuthenticationResult) => {
              this.router.navigate(['crm']);
            });
        }
      });
    if (!this.authService.instance.getActiveAccount()) {
      if (this.authService.instance.getAllAccounts().length > 0) {
        this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
        this.router.navigate(['crm']);
      }
    }
  }
}
