import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { ServiceOrderService } from '../service-order.service';
import { TabsService } from '../../../services/tabs.service';
import { Tab } from '../../../services/tab';
import { OverlayPanel } from 'primeng/overlaypanel';

import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { Subscription } from 'rxjs';
import { ServiceOrderDataService } from '../service-order-data.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {
  dateSubstring,
  generalDateFormatter,
  getFormattedTimeRangeForSB,
  getFormattedTimeRangeForSP,
  dateTimeFormatter,
  getMMDDYYYFormat,
} from '../../../shared/utilities/date-utilities';
import { MessageService } from 'primeng/api';
import { SendAutoEmailComponent } from './service-order-form.service';
import { serviceOrderHeaderOptions } from './service-order-form-ellipsis';
import { ConfirmationService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { TechnicalInformationService } from '../../service-order/service-details/technical-information/technical-information.service';

import moment from 'moment';
import { NoteType } from '../../../shared/constants/enums';
import {
  copy,
  getKeyByValue,
  getValueByKey,
  getMenuTypeKey,
} from '../../../shared/utilities/common-utilities';
import { FeatureFlagsService } from '../../../services/feature-flags.service';
import { DealerService } from '../../../dealer/dealer.service';
import { EmailPattern, PhonePattern } from '../../../shared/constants/validation-regex';
import {
  manufacturer250Amounts,
  cannotDispatchStatuses,
  lolAllowedServiceTypes,
  lolExcludeInsurance,
  lolExcludeUniqueIdPrefixes,
  REDEMPTION_DATE_OVERRIDE_ROLES,
} from '../../../shared/constants/service-order-constants';
import { sofDisableFormStatuses } from '../../../shared/constants/status-constants';
import { AlertService } from '../../../services/alert.service';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { RolePermissionService } from '../../../common/role-permission.service';
const NOTE_DESC_ATTACHMENT = ['External', 'System'];

const NOTE_TYPE_ATTACHMENT = [34, 35, 36, 37, 38, 39, 40, 41, 42];

const RESTRICTED_CLAIM_STATUSES = ['APPROVED FOR PAYMENT','APPROVED PAID','SUBMIT FOR PAYMENT','COMPLETED - NO CHARGE TO NEW LEAF',
  'CLOSED','CLOSED - NO SERVICE REQUIRED', 'CLOSED  - RESOLVED BY TS','AWAITING INVOICE','MANAGER REVIEW','PENDING CLAIMS REVIEW',
  'PENDING REVIEW','PENDING GOODWILL','PRE-APPROVAL PENDING - NEEDS REVIEW','RESCHEDULING REQUIRED','SERVICE LOCATE','SETTLEMENT PENDING']

@Component({
  selector: 'service-order-form',
  templateUrl: 'service-order-form.template.html',
  styleUrls: ['../../../shared/styles/left-details-form.scss', 'service-order-form.scss'],
  providers: [FormFieldControlService, MessageService, SendAutoEmailComponent, DealerService],
})
export class ServiceOrderFormComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  formFields1: FormField<string>[] = [];
  cancelFormFields: FormField<string>[] = [];
  spCancelFormFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad: any;
  globalOptions: any[];
  authDetails: IRoleAuthorizationService;
  selectedItem: any;
  originalDispatchDate: string;
  // ellipsisOptions: any[] = soServicerOptions;
  querySubscription: Subscription;
  displayDispatch = false;
  lolResponseData = [];
  button: Array<any>;
  serviceOrderInfo: any[];
  servicerInfo: any[];
  querySubscriptions: Subscription[] = [];
  displayEmailModal = false;
  modelData: any;
  isLoading: boolean;
  isSuccess: boolean;
  currentClaimStatus: string;
  selectedServicer: any;
  currentProductLocation: any;
  servicerModel: any;
  customerDispatch = false;
  displayLolModal = false;
  ellipsisHeaderOptions: any[] = serviceOrderHeaderOptions;
  ellipsisHeaderOptionsSec: any;
  selectedRowIndex: number;
  displayExport: boolean;
  lolReady = true;
  exportDataTable = [];
  subClaim: boolean;
  dispatched = false;
  currentClaimIdOriginal = null;
  displayNoTechInfo = false;
  currentServicerProfilesIdOriginal = null;
  private buttons: any;
  buttonAuth: any = {};
  canDispatch = true;
  displayNoPRCauseAndCA = false;
  displayNoCorrectiveAction = false;
  displayNoCorrectiveActionConfirm = false;
  displayNoPRCause = false;
  displayDirectServiceBench = false;
  displayDirectServicePower = false;
  clearedDispatchDate = false;
  initialCustomerDispatchSelection = false;
  isSubClaimProblem = false;
  user;
  technInfoMissingField = '';
  problemReportMissingField = '';
  pdfTableOptions = {};
  isScheduleJobAvailable = false;
  isScheduledJobCancelled = false;
  isRCButtonsEnabled = true;
  isReschedule = false;
  dateFormatter = generalDateFormatter;
  timeRangeFormatter = getFormattedTimeRangeForSB;
  spTimeRangeFormatter = getFormattedTimeRangeForSP;
  displayCancelDialog = false;
  displaySpCancelDialog = false;
  displayInfoModal = false;
  displayRescheduleDialog = false;
  isServiceBenchFeatureOn = false;
  isServicePowerFeatureOn = false;
  isServicePowerDispatch = false;
  servicePowerRefreshRequest = false;
  canEdit = true;
  isWithInElegibleTime = false;
  isWithInElegibleTimeSp = false;
  stServiceProviderName = null;
  sbInfoTitle = '';
  sbInfoMessage = '';
  isContractExpired = false;
  isfPBFlag = false;
  isServicerProfileIdSet = false;
  isExpiredContractAuthorized = true;
  isGoodwill = false;
  initialLoad = false;
  statusAvailable = false;
  servicerEditFlag = true;
  isEditEnable = true;
  isFullyLoaded = false;
  constructor(
    private fcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: ServiceOrderService,
    private tabService: TabsService,
    public serviceOrderDataService: ServiceOrderDataService,
    private dataService: ServiceOrderDataService,
    private messageService: MessageService,
    private autoEmailService: SendAutoEmailComponent,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService,
    private technicalInformationService: TechnicalInformationService,
    private featureFlagService: FeatureFlagsService,
    private dealerService: DealerService,
    private alertService: AlertService,
    private configurationService: ConfigurationTypeService,
    private dropdownDataService: DropdownDataService,
    private datePipe: DatePipe,
    private rps: RolePermissionService,
  ) {
    super();
    this.querySubscriptions.push(
      service.getValidatorToolFields().subscribe((data: FormField<string>[]) => {
        this.formFields1 = data;
      }),
    );
    this.querySubscriptions.push(
      this.service.getSearchOrderFormFields().subscribe((data: FormField<string>[]) => {
        this.formFields = this.roleService.validateFormFields(data);
      }),
    );

    this.querySubscriptions.push(
      this.service.claimDataChanged.subscribe({
        next: (claimDataChanged) => {
          if (claimDataChanged) {
            this.servicePowerRefreshRequest = true;
            this.refreshClaimProfile();
          }
        },
      }),
    );

    this.querySubscriptions.push(
      this.service.isNoteAddedByUser.subscribe({
        next: (noteAdded) => {
          if (noteAdded) {
            this.refreshClaimProfile();
          }
        },
      }),
    );

    this.querySubscriptions.push(
      this.service.refreshClaimOnUpdate.subscribe({
        next: (ClaimUpdate) => {
          if (ClaimUpdate) {
            this.refreshClaimProfile();
          }
        },
      }),
    );

    this.querySubscriptions.push(
      this.service.claimNotesChanged.subscribe({
        next: (claimNotesChanged) => {
          if (claimNotesChanged) {
            this.serviceOrderDataService.loadNote.next(true);
          }
        },
      }),
    );

    this.serviceOrderDataService.serviceContractExpired.subscribe((isExpired) => {
      if (!this.isContractExpired) {
        this.isContractExpired = isExpired;
      }
    });

    this.serviceOrderDataService.expiredContractAuthorized.subscribe((isAuthorized) => {
      this.isExpiredContractAuthorized = isAuthorized;
      this.canEdit = true;
    });

    this.querySubscriptions.push(
      this.service.selectedServicer.asObservable().subscribe((selectedServicer) => {
        // this.serviceOrderInfo = data;
        if (selectedServicer) {
          this.selectedServicer = selectedServicer;
          this.clearedDispatchDate = false;
          this.getServicerInfo(selectedServicer.servicerProfilesIdOriginal);
        } else {
          if (!this.serviceOrderDataService.serviceConfig.searchParams.isExpiredContract) {
            this.serviceOrderDataService.serviceOrderDataModel.servicerInfo = null;
            this.servicerInfo = null;
            this.selectedServicer = null;
            this.clearedDispatchDate = true;
            this.patchDispatchDate('', true);
          }
        }
      }),
    );
  }

  loadDealerGroupContacts(dealerGroupId) {
    this.querySubscriptions.push(
      this.dealerService.getDealerGroupContactInfo({ dealerGroupId }).subscribe(
        ({ data, errors }) => {
          if (!errors) {
            const { getDealerGroupContactInfo } = data;
            if (getDealerGroupContactInfo && getDealerGroupContactInfo.length) {
              this.serviceOrderDataService.serviceOrderDataModel.fpbContact = [];
              getDealerGroupContactInfo.forEach((contact, index) => {
                if (contact.position === '50% Back') {
                  this.serviceOrderDataService.serviceOrderDataModel.fpbContact.push(contact);
                }
              });
            }
          }
        },
        (err) => {
          throw err;
        },
      ),
    );
  }

  ngOnInit() {

    this.serviceOrderDataService.isFullyLoaded.subscribe({
      next: (isLoading) => {
        if(isLoading){
          this.isFullyLoaded = true;
        }
      }
    });

    this.initialLoad = true;
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: ServiceOrderFormComponent,
      generalArray: this.formFields,
    };
    this.isServiceBenchFeatureOn = this.featureFlagService.isFeatureFlagEnabled('ServiceBench');
    this.isServicePowerFeatureOn = this.featureFlagService.isFeatureFlagEnabled('ServicePower');
    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.user = this.azureService.fullName;
    // if claim data is null then set it to empty object for further assignments
    this.serviceOrderDataService.serviceOrderDataModel.claimData = this.serviceOrderDataService
      .serviceOrderDataModel.claimData
      ? this.serviceOrderDataService.serviceOrderDataModel.claimData
      : {};
    this.querySubscriptions.push(
      this.service.getServiceBenchCancellationFields().subscribe((data: FormField<string>[]) => {
        this.cancelFormFields = data;
      }),
    );

    this.querySubscriptions.push(
      this.service.getServicePowerCancellationFields().subscribe((data: FormField<string>[]) => {
        this.spCancelFormFields = data;
      }),
    );

    this.serviceOrderDataService.fPBFlag.subscribe((fPBFlag) => {
      this.isfPBFlag = fPBFlag;
    });
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'action_required,customer_feedback,caller_type',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.globalOptions = configList;
        const actionRequiredOptions = configList
          .filter((el) => el.menuType === 'action_required')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        actionRequiredOptions.unshift({ key: '', value: '' });
        const customerFeedbackOptions = configList
          .filter((el) => el.menuType === 'customer_feedback')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.formFields.find((el) => el.key === 'actionRequired').options =
        actionRequiredOptions;
        this.formFields.find((el) => el.key === 'customerFeedback').options =
          customerFeedbackOptions;
      });
    this.form = this.fcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      if (x.customerDispatch && x.customerDispatch.length) {
        this.customerDispatch = true;
        if (this.serviceOrderDataService.serviceOrderDataModel.claimData) {
          this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch = true;
        }
      } else {
        if (x.customerDispatch) {
          this.customerDispatch = false;
          this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch = false;
        }
      }
      if (
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel &&
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal
      ) {
        this.lolReady = false;
      }
      if (this.serviceOrderDataService.serviceOrderDataModel.claimData) {
        this.serviceOrderDataService.serviceOrderDataModel.claimData = Object.assign(
          {},
          this.serviceOrderDataService.serviceOrderDataModel.claimData,
          { serviceType: x.serviceType },
        );
      }
      if(this.isFullyLoaded){
        console.log(`KKTEST:: EVENT TRUE`)
        this.formValueChanged.emit(true);
      }
    });
    this.serviceOrderDataService.currentFormData = this.form;
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      {
        name: 'Submit',
        hidden: false,
        type: 'button',
        callBack: 'onSubmit',
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: ServiceOrderFormComponent,
      generalArray: this.button,
    };
    this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    this.buttons = [
      { name: 'Proceed with Dispatch' },
      { name: 'Send Email Notification' },
      { name: 'Limit of Liability' },
    ];
    this.buttonAuth = {
      'Proceed with Dispatch': false,
      'Send Email Notification': false,
      'Limit of Liability': false,
    };
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: ServiceOrderFormComponent,
      generalArray: this.buttons,
    };
    this.buttons = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    for (const singleButton of this.buttons) {
      this.buttonAuth[singleButton.name] = true;
    }

    this.querySubscriptions.push(
      this.serviceOrderDataService.isLastUpdateTimeError.subscribe({
        next: (err) => {
          if (err) {
            this.sbInfoTitle = 'Reopen the Tab';
            this.sbInfoMessage = 'Claim data is changed. Please reopen the tab and try again.';
            this.displayInfoModal = true;
          } else {
            this.displayInfoModal = false;
          }
        },
      }),
    );

    this.querySubscriptions.push(
      this.serviceOrderDataService.isMainTabDataAvailable.subscribe({
        next: (isMainTabDataAvailable) => {
          if (this.subClaim !== true && this.subClaim !== false) {
            if (this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag) {
              this.subClaim = true;
            }
          }
          if (!this.currentClaimIdOriginal) {
            this.currentClaimIdOriginal =
              this.serviceOrderDataService.serviceConfig.searchParams.claimId;
          }
          if (isMainTabDataAvailable && this.currentClaimIdOriginal) {
            this.refreshClaimProfile();
          } else if (
            isMainTabDataAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo
          ) {
            this.canDispatch = false;
            if (this.selectedServicer) {
              this.patchDispatchDate(new Date().toISOString());
            }
            this.serviceOrderDataService.serviceOrderDataModel.claimData = {
              ...this.serviceOrderDataService.serviceOrderDataModel.claimData,
              productsId:
                this.serviceOrderDataService.serviceOrderDataModel.productInfoModel &&
                this.serviceOrderDataService.serviceOrderDataModel.productInfoModel
                  .productsIdOriginal,
            };
            this.serviceOrderDataService.isLoading.next(true);
            this.querySubscriptions.push(
              this.serviceOrderDataService
                .getClaimDealerInfo(
                  this.serviceOrderDataService.serviceOrderDataModel.contractInfo.dealerGroupNumber,
                )
                .subscribe(
                  ({ data, loading }) => {
                    this.serviceOrderDataService.serviceOrderDataModel.dealerInfo = {
                      ...data.getDealerProfileById,
                      storeLocationName:
                        this.serviceOrderDataService.serviceOrderDataModel.contractInfo
                          .storeLocationName,
                      buyingGroup:
                        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.buyingGroup,
                    };
                    if (
                      this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
                      this.serviceOrderDataService.serviceOrderDataModel.contractInfo
                        .contractStatusDescription &&
                      this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractStatusDescription.toUpperCase() ===
                      'EXPIRED'
                    ) {
                      this.isContractExpired = true;
                    }
                    this.serviceOrderDataService.isLoading.next(false);
                    this.serviceOrderDataService.isClaimDataAvailable.next(true);
                  },
                  (err) => {
                    this.serviceOrderDataService.isLoading.next(false);
                    this.serviceOrderDataService.isClaimDataAvailable.next(true);
                  },
                ),
            );
          }
        },
      }),
    );
    this.checkStatus();
    this.onChanges();
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'PRINT',
      permission: 'Claims',
      category: 'Exports',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
  }

  refreshClaimProfile() {
    this.serviceOrderDataService.isLoading.next(true);
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getClaimProfileById(
          parseInt(this.currentClaimIdOriginal, 10),
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo.dealerGroupNumber,
          this.azureService.accountId,
        )
        .subscribe(
          ({ data, loading }) => {
            this.canDispatch = true;
            if (data.getDealerProfileById && data.getDealerProfileById.dealerGroupsIdOriginal) {
              this.loadDealerGroupContacts(data.getDealerProfileById.dealerGroupsIdOriginal);
            }
            this.modelData = {
              ...data.getClaimProfileById,
              uniqueId: this.serviceOrderDataService.serviceOrderDataModel.contractInfo.uniqueId,
            };
            this.updateFormFields(); // Update the valid new statuses to change to.
            this.serviceOrderDataService.serviceOrderDataModel.claimData = Object.assign(
              {},
              data.getClaimProfileById,
              { disableClaimSave: false },
            );
            this.serviceOrderDataService.claimLastUpdateTime = this.modelData.lastUpdateTime;
            if (!this.subClaim && this.isServiceBenchFeatureOn) {
              this.getServiceBenchDetails(
                data.getClaimProfileById.serviceOrderNumber,
                data.getClaimProfileById.dispatchDate,
              );
            }
            this.service.onCustomerDispatchChange.next(
              this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch,
            );
            // Do not show the Payment details from Parent on Sub Claims
            if (this.subClaim) {
              this.serviceOrderDataService.serviceOrderDataModel.claimData.claimPaymentDate = null;
              this.serviceOrderDataService.serviceOrderDataModel.claimData.checkAmount = null;
              this.serviceOrderDataService.serviceOrderDataModel.claimData.checkNumber = null;
              this.serviceOrderDataService.serviceOrderDataModel.claimData.claimDate = null;
              this.isScheduleJobAvailable = false;
            }
            if (!this.selectedServicer) {
              this.customerDispatch = this.serviceOrderDataService.serviceOrderDataModel.claimData
                ? this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch
                : false;
            }
            if (data.getClaimProfileById.customerDispatch) {
              this.customerDispatch = true;
            }
            this.serviceOrderDataService.serviceOrderDataModel.location = {
              ...data.getClaimProfileById.addresses[0],
              country: {
                key:
                  data.getClaimProfileById.addresses[0] &&
                  data.getClaimProfileById.addresses[0].country,
                value:
                  data.getClaimProfileById.addresses[0] &&
                  data.getClaimProfileById.addresses[0].country,
              },
              isBusinessLocation: {
                key: data.getClaimProfileById.isBusinessLocation ? 'Yes' : 'No',
                value: data.getClaimProfileById.isBusinessLocation,
              },
              state: {
                key:
                  data.getClaimProfileById.addresses[0] &&
                  this.getStateNameByCode(data.getClaimProfileById.addresses[0].state),
                value:
                  data.getClaimProfileById.addresses[0] &&
                  data.getClaimProfileById.addresses[0].state,
              },
            };
            this.serviceOrderDataService.productLocationInfo.next(
              this.serviceOrderDataService.serviceOrderDataModel.location,
            );
            this.currentProductLocation =
              this.serviceOrderDataService.serviceOrderDataModel.location;
            const contactInfo = data.getClaimProfileById.contactInfo
              ? data.getClaimProfileById.contactInfo
              : {};
            const { phoneTypeCode1, phoneTypeCode2 } = contactInfo;
            this.serviceOrderDataService.serviceOrderDataModel.contact = {
              ...contactInfo,
              callerType: {
                key: getMenuTypeKey(
                  this.globalOptions,
                  'caller_type',
                  data.getClaimProfileById.callerType,
                ),
                value: data.getClaimProfileById.callerType,
              },
              dispatchMethod: {
                key: this.getKey(data.getClaimProfileById.dispatchMethod),
                value: data.getClaimProfileById.dispatchMethod,
              },
              phoneTypeCode1: {
                key: phoneTypeCode1 ? phoneTypeCode1 : '',
                value: phoneTypeCode1 ? phoneTypeCode1 : '',
              },
              phoneTypeCode2: {
                key: phoneTypeCode2 ? phoneTypeCode2 : '',
                value: phoneTypeCode2 ? phoneTypeCode2 : '',
              },
            };
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo = {
              ...this.serviceOrderDataService.serviceOrderDataModel.contractInfo,
              coveredProduct: data.getClaimProfileById.coveredProduct,
              addresses: data.getClaimProfileById.addresses,
            };
            this.serviceOrderDataService.serviceInfo.next(
              this.serviceOrderDataService.serviceOrderDataModel.contractInfo,
            );
            this.serviceOrderDataService.serviceOrderDataModel.productInfoModel = {
              ...this.serviceOrderDataService.serviceOrderDataModel.productInfoModel,
              ...data.getClaimProfileById.coveredProduct,
              deductibleCollectedBy: data.getClaimProfileById.deductibleCollectedBy,
              productCondition: data.getClaimProfileById.productCondition,
            };
            this.serviceOrderDataService.serviceOrderDataModel.productInfo = {
              modelSuffix: data.getClaimProfileById.modelSuffix,
              serialNumber: data.getClaimProfileById.productSerialNumber
                ? data.getClaimProfileById.productSerialNumber
                : data.getClaimProfileById.coveredProduct
                  ? data.getClaimProfileById.coveredProduct.serialNumber
                  : null,
            };
            this.serviceOrderDataService.serviceOrderDataModel.dealerInfo = {
              ...data.getDealerProfileById,
              storeLocationName:
                this.serviceOrderDataService.serviceOrderDataModel.contractInfo.storeLocationName,
              buyingGroup:
                this.serviceOrderDataService.serviceOrderDataModel.contractInfo.buyingGroup,
            };

            if (!this.subClaim && this.isServicePowerFeatureOn) {
              this.getServicePowerCallStatus(data.getClaimProfileById.serviceOrderNumber);
            }

            if (this.serviceOrderDataService.serviceOrderDataModel.claimData) {
              this.initialCustomerDispatchSelection =
                this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch;
            }

            this.serviceOrderDataService.standardProblemDataModel = data.getClaimProfileById.problem
              ? { ...data.getClaimProfileById.problem }
              : this.serviceOrderDataService.standardProblemDataModel;

            this.serviceOrderDataService.standardProblemDataModelInitial = {
              ...this.serviceOrderDataService.standardProblemDataModel,
            };
            if (!this.servicePowerRefreshRequest) {
              this.serviceOrderDataService.isClaimDataAvailable.next(true);
            }
            // populate data
            if (!this.subClaim && data.getClaimProfileById.servicerProfilesIdOriginal) {
              this.currentServicerProfilesIdOriginal =
                data.getClaimProfileById.servicerProfilesIdOriginal;
              this.getServicerInfo(data.getClaimProfileById.servicerProfilesIdOriginal);
            } else {
              this.serviceOrderDataService.serviceOrderDataModel.servicerInfo = null;
              this.servicerInfo = null;
            }
            if (this.modelData.claimStatusDescription in sofDisableFormStatuses) {
              this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave = true;
            }
            // If the user has a servicer link, do not allow them to edit claims that are customer dispatched.
            if (this.azureService.servicerNumberLink.length > 0 && this.customerDispatch) {
              this.canEdit = false;
            }
            if (this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave) {
              this.canEdit = false;
            }
            if (data.getClaimProfileById.goodwillReason && !this.subClaim) {
              this.serviceOrderDataService.serviceOrderDataModel.goodwillReason = {
                key: data.getClaimProfileById.goodwillReason,
                value: data.getClaimProfileById.goodwillReason,
              };
            }
            const newClaimStatus =
              this.subClaim && !this.isGoodwill
                ? 'DRAFT'
                : this.subClaim && this.isGoodwill
                  ? 'PENDING GOODWILL'
                  : this.modelData.claimStatusDescription;
            this.patchDispatchDate(this.modelData.dispatchDate, this.clearedDispatchDate);
            this.form.patchValue({
              claimStatusDescription: {
                key:
                  this.subClaim && !this.isGoodwill
                    ? 'DRAFT'
                    : this.subClaim && this.isGoodwill
                      ? 'PENDING GOODWILL'
                      : this.modelData.claimStatusDescription,
                value:
                  this.subClaim && !this.isGoodwill
                    ? 'DRAFT'
                    : this.subClaim && this.isGoodwill
                      ? 'PENDING GOODWILL'
                      : this.modelData.claimStatusDescription,
              },
              actionRequired: {
                key: getMenuTypeKey(
                  this.globalOptions,
                  'action_required',
                  this.modelData.actionRequired,
                ),
                value: this.modelData.actionRequired,
              },
              claimFollowUpDate: dateSubstring(this.modelData.claimFollowUpDate),
              paymentMethodCode: {
                key: this.modelData.paymentMethodCode,
                value: this.modelData.paymentMethodCode,
              },
              serviceType: {
                key: this.modelData.serviceType || 'Service',
                value: this.modelData.serviceType || 'Service',
              },
              customerDispatch: this.customerDispatch ? ['Customer Dispatch'] : [],
              jsyFlag:
                this.serviceOrderDataService.serviceOrderDataModel.claimData &&
                  this.serviceOrderDataService.serviceOrderDataModel.claimData.jsyFlag
                  ? ['JSY']
                  : [],
              customerFeedback: {
                key: getMenuTypeKey(
                  this.globalOptions,
                  'customer_feedback',
                  this.modelData.customerFeedback,
                ),
                value: this.modelData.customerFeedback,
              },
              assignedTo: {
                key: this.modelData.assignedToName,
                value: this.modelData.assignedTo,
              },
            });
            this.updateCustomerDispatchOptions();
            if (this.subClaim) {
              this.modelData.createdBy = this.azureService.fullName;
              this.modelData.claimDate = new Date();
              this.modelData.lastUpdatedBy = null;
              this.modelData.lastUpdateTime = null;
              this.modelData.startedOn = new Date();
              this.modelData.serviceOrderNumber = this.modelData.claimNumber + '-XXX';
              if (!this.isGoodwill) {
                this.modelData.claimStatusDescription = 'DRAFT';
              }
              this.customerDispatch = false;
              this.patchDispatchDate('', true);
              this.form.patchValue({
                customerDispatch: this.customerDispatch ? ['Customer Dispatch'] : [],
              });
            }
            this.serviceOrderDataService.isLoading.next(true);
            this.querySubscriptions.push(
              this.service.getServiceOrderInfo(this.modelData).subscribe((serviceOrderInfo) => {
                this.serviceOrderInfo = serviceOrderInfo;
                this.serviceOrderDataService.isLoading.next(false);
                const serviceOrderInfoDataModel = {};
                for (const singleField of serviceOrderInfo) {
                  serviceOrderInfoDataModel[singleField.key] = singleField.value;
                }
                this.serviceOrderDataService.serviceOrderDataModel.serviceOrderInfo =
                  serviceOrderInfoDataModel;
                this.currentClaimStatus =
                  this.serviceOrderDataService.serviceOrderDataModel.serviceOrderInfo.status;
                const { hideRCButtonsClaimStatusList } =
                  this.serviceOrderDataService.serviceBenchModal;
                if (getKeyByValue(hideRCButtonsClaimStatusList, this.currentClaimStatus)) {
                  this.isRCButtonsEnabled = false;
                }
                const { spHideRCButtonsClaimStatusList } =
                  this.serviceOrderDataService.servicePowerModal;
                if (getKeyByValue(spHideRCButtonsClaimStatusList, this.currentClaimStatus)) {
                  this.isRCButtonsEnabled = false;
                }
                this.serviceOrderDataService.isServiceOrderInfoAvailable.next(true);
              }),
            );
            if (this.initialLoad) {
              this.getAlertData();
            }
            if (!this.canDispatchSO(data.getClaimProfileById.dispatchDate)) {
              this.canDispatch = false;
            }
            this.serviceOrderDataService.isLoading.next(false);
          },
          (err) => {
            this.serviceOrderDataService.isLoading.next(false);
            throw err;
          },
        ),
    );
  }

  getAlertData() {
    this.initialLoad = false;
    const { dealerInfo, claimData, contractInfo, coveragePlanDetails } =
      this.serviceOrderDataService.serviceOrderDataModel;
    const alertData = {
      moduleName: ['ServiceOrder'],
      productPlanTypes: coveragePlanDetails ? [coveragePlanDetails.planType] : [],
      subCategories: [
        claimData.coveredProduct ? claimData.coveredProduct.subcategoryDescription : null,
      ],
      manufacturers: [
        claimData.coveredProduct ? claimData.coveredProduct.manufacturerManufacturer : null,
      ],
      buyingGroups: dealerInfo ? [dealerInfo.buyingGroup] : [],
      dealers: dealerInfo ? [dealerInfo.dealerGroupName] : [],
      dealerLocations: dealerInfo ? [dealerInfo.storeLocationName] : [],
      category: [claimData.coveredProduct ? claimData.coveredProduct.categoryDescription : null],
    };
    this.querySubscriptions.push(
      this.alertService.getApplicableAlerts(alertData).subscribe(({ data, loading }) => {
        this.alertService.alerts = copy(data.getApplicableAlerts);
        this.alertService.showAlert();
      }),
    );
  }

  onChanges(): void {
    this.form.get('dispatchDate').valueChanges.subscribe((val) => {
      this.serviceOrderDataService.dispatchDateChanged.next(val);
      this.serviceOrderDataService.disableCoverageSelection.subscribe({
        next: (disableCoverageSelection) => {
          if (disableCoverageSelection) {
            this.form.get('serviceType').disable();
          }
        },
      });
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  onClickExport() {
    this.displayExport = true;
  }

  private removeTags(str) {
    if (str === null || str === '') {
      return '';
    } else {
      str = str.toString();
      str = str.replace(/&nbsp;/gi, ' ');
      str = str.replace(/<\/p>/gi, '\n');
      return str.replace(/(<([^>]+)>)/gi, '');
    }
  }

  private createBodyNotes(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    let finalBody = [];
    finalBody.push([
      { text: 'Type', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Entered By', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Note', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Last Updated Time', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
    ]);

    for (const singleNote of bodyData) {
      // Only add External (38) and System (40) notes
      if (
        NOTE_TYPE_ATTACHMENT.includes(singleNote.noteType) ||
        NOTE_DESC_ATTACHMENT.includes(singleNote.noteTypeDescription)
      ) {
        finalBody.push([
          { text: singleNote.noteTypeDescription, fontSize: 8 },
          { text: singleNote.enteredBy, fontSize: 8 },
          { text: this.removeTags(singleNote.noteText), fontSize: 8 },
          {
            text: this.datePipe.transform(singleNote.enteredTime, 'MM/dd/yyyy, hh:mm:ss a '),
            fontSize: 8,
          },
        ]);
      }
    }
    if (!finalBody || finalBody.length === 0) {
      finalBody = [['NA', 'NA', 'NA', 'NA']];
    }
    return finalBody;
  }

  private createBodyCoverageDetails(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA']];
    }
    let finalBody = [];
    finalBody.push([
      { text: 'Select', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Coverage', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Policy/SKU', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Description', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Method', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Term', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Coverage Start/Exp', fontSize: 8, fillColor: '#8cc640' },
      { text: 'Covered', fontSize: 8, fillColor: '#8cc640' },
    ]);

    for (const singleNote of bodyData) {
      let startDate = 'N/A';
      let endDate = 'N/A';
      if (singleNote.coverageStDate) {
        startDate = singleNote.coverageStDate.substring(0, 10);
      }
      if (singleNote.coverageExpDate) {
        endDate = singleNote.coverageExpDate.substring(0, 10);
      }
      if (singleNote.selection === true) {
        finalBody.push([
          { text: '√', alignment: 'center', fontSize: 8, fillColor: '#FFFF00' },
          { text: singleNote.name, fontSize: 8, fillColor: '#FFFF00' },
          { text: singleNote.policySku, fontSize: 8, fillColor: '#FFFF00' },
          { text: singleNote.description, fontSize: 8, fillColor: '#FFFF00' },
          { text: singleNote.description, fontSize: 8, fillColor: '#FFFF00' },
          { text: singleNote.cvTerm, fontSize: 8, fillColor: '#FFFF00' },
          { text: startDate + ' - ' + endDate, fontSize: 8, fillColor: '#FFFF00' },
          { text: singleNote.covered, fontSize: 8, fillColor: '#FFFF00' },
        ]);
      } else {
        finalBody.push([
          { text: '', fontSize: 8 },
          { text: singleNote.name, fontSize: 8 },
          { text: singleNote.policySku, fontSize: 8 },
          { text: singleNote.description, fontSize: 8 },
          { text: singleNote.description, fontSize: 8 },
          { text: singleNote.cvTerm, fontSize: 8 },
          { text: startDate + ' - ' + endDate, fontSize: 8 },
          { text: singleNote.covered, fontSize: 8 },
        ]);
      }
    }
    if (!finalBody || finalBody.length === 0) {
      finalBody = [['NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA']];
    }
    return finalBody;
  }

  private createBodyExpenseCharges(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push([
      { text: 'Status', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Expense Type', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Desc', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Part#', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Model#', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Serial#', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Quantity', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Req.Price', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
      { text: 'Req.Markup', bold: true, fontSize: 8, fillColor: '#D3D3D3' },
    ]);
    for (const singleNote of bodyData.expenseCharges) {
      finalBody.push([
        { text: singleNote.status, fontSize: 8 },
        { text: singleNote.expenseType, fontSize: 8 },
        { text: singleNote.description, fontSize: 8 },
        { text: singleNote.partNumber, fontSize: 8 },
        { text: singleNote.modelNumber, fontSize: 8 },
        { text: singleNote.serialNumber, fontSize: 8 },
        { text: singleNote.quantity, fontSize: 8 },
        { text: `$${singleNote.requestedPrice}`, fontSize: 8 },
        {
          text: singleNote.requestedMarkup ? `$${singleNote.requestedMarkup}` : null,
          fontSize: 8,
        },
      ]);
    }
    return finalBody;
  }

  private createStandardProblem(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    const singleRow = [];
    finalBody.push(
      [
        {
          text: 'Failure/Loss Date',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.failureDate), fontSize: 8 },
        {
          text: 'Reported Date',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.reportedDate), fontSize: 8 },
      ],
      [
        {
          text: 'Functionality Status',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.functionalityStatus, fontSize: 8 },
        {
          text: 'Failure Description',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.failureDescription, fontSize: 8 },
      ],
      [
        {
          text: 'Failure',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.symptom, fontSize: 8 },
        {
          text: '',
          border: [false, false, false, false],
        },
        { text: '', border: [false, false, false, false] },
      ],
    );

    return finalBody;
  }

  createLolBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const usedForProductPercent = Math.round(
      (bodyData.usedForProduct / bodyData.productPurchasePrice) * 100,
    );
    const remainingForProductPercent = Math.round(
      (bodyData.remainingForProduct / bodyData.productPurchasePrice) * 100,
    );
    const inflightAmountPercent = Math.round(
      (bodyData.approvedForProduct / bodyData.productPurchasePrice) * 100,
    );
    const finalBody = [];
    finalBody.push(
      [
        {
          text: '',
        },
        { text: 'Paid Claim Amount', color: 'green', italics: true, fontSize: 8 },
        { text: 'Limit Of Liability', color: 'green', italics: true, fontSize: 8 },
      ],
      [
        {
          text: 'Product Purchase Price',
          color: 'green',
          italics: true,
          fontSize: 8,
        },
        { text: `$${bodyData.productPurchasePrice}`, fontSize: 8 },
        { text: '' },
      ],
      [
        {
          text: 'Amount Paid',
          color: 'green',
          italics: true,
          fontSize: 8,
        },
        { text: `$${bodyData.usedForProduct}`, fontSize: 8 },
        {
          text: `${usedForProductPercent}%`,
          fontSize: 8,
        },
      ],
      [
        {
          text: 'Inflight Amounts',
          color: 'green',
          italics: true,
          fontSize: 8,
        },
        { text: `$${bodyData.approvedForProduct}`, fontSize: 8 },
        {
          text: `${inflightAmountPercent}%`,
          fontSize: 8,
        },
      ],
      [
        {
          text: 'Remaining For Product',
          color: 'green',
          italics: true,
          fontSize: 8,
        },
        { text: `$${bodyData.remainingForProduct}`, fontSize: 8 },
        {
          text: `${remainingForProductPercent}%`,
          fontSize: 8,
        },
      ],
    );
    return finalBody;
  }
  createTechnicalInfoBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Technical Information',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
        {
          text: '',
          background: '#DCDCDC',
          fillColor: '#D3D3D3',
          border: [false, false, false, false],
        },
      ],
      [
        {
          text: 'Technical Name',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.technicianName, fontSize: 8 },
        {
          text: 'Number of Visits',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.numberOfVisits, fontSize: 8 },
        {
          text: 'Service Status',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.serviceStatus, fontSize: 8 },
        {
          text: 'Date of Invoice',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.dateOfInvoice), fontSize: 8 },
      ],
      [
        {
          text: 'Invoice Number',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.invoiceNumber, fontSize: 8 },
        {
          text: 'Insurance Carrier',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.insuranceCarrier, fontSize: 8 },
        {
          text: 'Date of Repair',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.dateOfRepair), fontSize: 8 },
        {
          text: 'Service Schedule Date',
          italics: true,
          color: 'black',
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: dateTimeFormatter(bodyData.serviceScheduleDate), fontSize: 8 },
      ],
    );
    return finalBody;
  }
  createProblemReport(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: '',
          color: 'black',
          fontSize: 8,
          fillColor: '#D3D3D3',
        },
        { text: 'Value', fontSize: 8, fillColor: '#D3D3D3', color: 'black' },
        {
          text: 'Note',
          color: 'black',
          fontSize: 8,
          fillColor: '#D3D3D3',
        },
      ],
      [
        {
          text: 'Failure',
          fontSize: 8,
        },
        { text: '', fontSize: 8 },
        {
          text: bodyData.failureNote,
          fontSize: 8,
          italics: true,
        },
      ],
      [
        {
          text: 'Cause',
          fontSize: 8,
        },
        { text: bodyData.cause, fontSize: 8, italics: true },
        { text: bodyData.causeNote, fontSize: 8, italics: true },
      ],
      [
        {
          text: 'Corrective Action',
          fontSize: 8,
        },
        { text: bodyData.correctiveAction, fontSize: 8, italics: true },
        { text: bodyData.correctiveActionNote, fontSize: 8, italics: true },
      ],
    );
    return finalBody;
  }
  creategeneratePreAuthBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push([
      {
        text: 'Approved User',
        fontSize: 8,
        fillColor: '#D3D3D3',
      },
      { text: 'Approval Date', fontSize: 8, fillColor: '#D3D3D3' },
      {
        text: 'Approved Amount',
        fontSize: 8,
        fillColor: '#D3D3D3',
      },
      { text: 'Authorization Code', fontSize: 8, fillColor: '#D3D3D3' },
      {
        text: 'Note',
        fontSize: 8,
        fillColor: '#D3D3D3',
      },
    ]);
    if(bodyData.preAuthInfo.length > 0){
      for (const singleNote of bodyData.preAuthInfo) {
        finalBody.push([
          {
            text: singleNote.userName,
            fontSize: 8,
            italics: true,
          },
          { text: singleNote.addedTimeStampFormatted, fontSize: 8, italics: true },
          {
            text: singleNote.approvedAmountFormatted,
            fontSize: 8,
            italics: true,
          },
          { text: singleNote.authorizationCode, fontSize: 8, italics: true },
          {
            text: singleNote.preAuthNotes,
            fontSize: 8,
            italics: true,
          },
        ]);
      }
    }else {
      finalBody.push([
        {
          text: bodyData.preAuthInfo.userName,
          fontSize: 8,
          italics: true,
        },
        { text: bodyData.preAuthInfo.addedTimeStampFormatted, fontSize: 8, italics: true },
        {
          text: bodyData.preAuthInfo.approvedAmountFormatted,
          fontSize: 8,
          italics: true,
        },
        { text: bodyData.preAuthInfo.authorizationCode, fontSize: 8, italics: true },
        {
          text: bodyData.preAuthInfo.preAuthNotes,
          fontSize: 8,
          italics: true,
        },
      ]);
    }
    return finalBody;
  }
  createProductInfoBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'saleDate',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: getMMDDYYYFormat(bodyData.saleDate), fontSize: 8 },
        {
          text: 'Deductible Collected By',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.deductibleCollectedBy, fontSize: 8 },
      ],
      [
        {
          text: 'Manufacturer',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.manufacturerManufacturer, fontSize: 8 },
        {
          text: 'Model',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.modelNumber, fontSize: 8 },
      ],
      [
        {
          text: 'Serial Number',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.serialNumber, fontSize: 8 },
        {
          text: 'Type',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.type, fontSize: 8 },
      ],
      [
        {
          text: 'Subcategory',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.subcategoryDescription, fontSize: 8 },
        {
          text: 'Category',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.categoryDescription, fontSize: 8 },
      ],
      [
        {
          text: 'Tier',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.tierDescription, fontSize: 8 },
        {
          text: 'Product Purchase Date',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: getMMDDYYYFormat(bodyData.productPurchaseDate), fontSize: 8 },
      ],
      [
        {
          text: 'Product Purchase Price',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.productPurchasePrice, fontSize: 8 },
        {
          text: 'Parts Term',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.partsTerm, fontSize: 8 },
      ],
      [
        {
          text: 'Labor Term',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.laborTerm, fontSize: 8 },
        {
          text: 'Major Component Term',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.majorComponentTerm, fontSize: 8 },
      ],
      [
        {
          text: 'Other Term',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.otherTerm, fontSize: 8 },
        {
          text: 'Service Location',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.serviceLocation, fontSize: 8 },
      ],
    );
    return finalBody;
  }
  createServiceOrderInfo(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Service Order #',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.serviceOrder, fontSize: 8 },
        {
          text: 'Unique ID',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.uniqueIdentifier, fontSize: 8 },
      ],
      [
        {
          text: 'Status',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.status, fontSize: 8 },
        {
          text: 'Contract',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.contract, fontSize: 8 },
      ],
      [
        {
          text: 'createdBy',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.createdBy, fontSize: 8 },
        {
          text: 'startedOn',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: getMMDDYYYFormat(bodyData.startedOn), fontSize: 8 },
      ],
    );
    return finalBody;
  }

  createDealerBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Dealer Name',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.dealerGroupName, fontSize: 8 },
        {
          text: 'Dealer Code',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.dealerGroupNumber, fontSize: 8 },
      ],
      [
        {
          text: 'Self Servicing',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.tSelfServicing, fontSize: 8 },
        {
          text: 'Selling Retailer',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.storeLocationName, fontSize: 8 },
      ],
      [
        {
          text: 'Buying Group',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.buyingGroup, fontSize: 8 },
        {
          text: '',
          border: [false, false, false, false],
        },
        { text: '', border: [false, false, false, false] },
      ],
    );
    return finalBody;
  }
  createCustomerInfoBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const phoneTypeCode1 =
      bodyData.phoneTypeCode1 && bodyData.phoneTypeCode1.value ? bodyData.phoneTypeCode1.value : '';
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Caller First Name',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.firstName, fontSize: 8 },
        {
          text: 'Caller Last Name',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.lastName, fontSize: 8 },
      ],
      [
        {
          text: 'Claim Contact Phone 1',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.phoneNumber1, fontSize: 8 },
        {
          text: 'Claim Contact Phone 1 Type',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: phoneTypeCode1, fontSize: 8 },
      ],
      [
        {
          text: 'Claim Contact Email',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.email, fontSize: 8 },
        {
          text: '',
          border: [false, false, false, false],
        },
        { text: '', border: [false, false, false, false] },
      ],
    );
    return finalBody;
  }
  createProdLocBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA', 'NA', 'NA', 'NA']];
    }
    const finalBody = [];
    finalBody.push(
      [
        {
          text: 'Address Line 1',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.address1, fontSize: 8 },
        {
          text: 'Address Line 2',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.address2, fontSize: 8 },
      ],
      [
        {
          text: 'State',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.state, fontSize: 8 },
        {
          text: 'City',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.city, fontSize: 8 },
      ],
      [
        {
          text: 'Zip Code',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.zipCode, fontSize: 8 },
        {
          text: 'Country',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.country.key, fontSize: 8 },
      ],
      [
        {
          text: 'Business',
          italics: true,
          fontSize: 8,
          fillColor: '#8cc640',
        },
        { text: bodyData.isBusinessLocation.key, fontSize: 8 },
        {
          text: '',
          border: [false, false, false, false],
        },
        { text: '', border: [false, false, false, false] },
      ],
    );
    return finalBody;
  }
  createCoverageReasonBody(bodyData: any) {
    if (!bodyData || bodyData.length === 0) {
      return [['NA']];
    }
    const finalBody = [];
    finalBody.push([
      { text: bodyData.coverageReason ? bodyData.coverageReason : null, fontSize: 8 },
    ]);
    return finalBody;
  }

  createServiceInfoBody(bodyData: any) {
    const servicerInfoBody = [];
    if (!bodyData || bodyData.length === 0) {
      if (this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch) {
        servicerInfoBody.push([
          { text: 'Customer Dipatch', italics: true, fontSize: 8, fillColor: '#8cc640' },
          { text: 'Yes', fontSize: 8 },
        ]);
        return servicerInfoBody;
      } else {
        return [['NA', 'NA', 'NA', 'NA']];
      }
    }
    const finalBody = [];
    finalBody.push(
      [
        { text: 'Service Center Code', italics: true, fontSize: 8, fillColor: '#8cc640' },
        { text: bodyData.servicerNumber, fontSize: 8 },
        { text: 'Service Center Name', italics: true, fontSize: 8, fillColor: '#8cc640' },
        { text: bodyData.servicerName, fontSize: 8 },
      ],
      [
        { text: 'Service Center Phone', italics: true, fontSize: 8, fillColor: '#8cc640' },
        { text: bodyData.contactInfo.businessPhone, fontSize: 8 },
        { text: 'Service Center Fax', italics: true, fontSize: 8, fillColor: '#8cc640' },
        { text: bodyData.contactInfo.fax, fontSize: 8 },
      ],
      [
        { text: 'Service Center Email', italics: true, fontSize: 8, fillColor: '#8cc640' },
        { text: bodyData.contactInfo.email, fontSize: 8 },
        { text: 'Preferred Payment Method', italics: true, fontSize: 8, fillColor: '#8cc640' },
        { text: bodyData.claimPayMethod, fontSize: 8 },
      ],
    );
    return finalBody;
  }

  createDocumentDefinition(servicer) {
    const totalRequestedAmount = this.serviceOrderDataService.serviceOrderDataModel.expenseCharges
      .reduce((a, b) => a + (parseFloat(b.approvedAmount) || 0), 0)
      .toFixed(2);
    const totalAdjustedAmount = this.serviceOrderDataService.serviceOrderDataModel.expenseCharges
      .reduce((a, b) => a + (parseFloat(b.adjustedAmount) || 0), 0)
      .toFixed(2);
    const totalDiscount = totalAdjustedAmount - totalRequestedAmount;
    const totalApprovedAmount = this.serviceOrderDataService.serviceOrderDataModel.expenseCharges
      .reduce((a, b) => a + (parseFloat(b.approvedAmount) || 0), 0)
      .toFixed(2);
    const serviceOrderBody = this.createServiceOrderInfo(
      this.dataService.serviceOrderDataModel.serviceOrderInfo,
    );
    const servicerInformationBody = this.createServiceInfoBody(servicer);
    const dealerInformationBody = this.createDealerBody(
      this.dataService.serviceOrderDataModel.dealerInfo,
    );
    const customerInformationBody = this.createCustomerInfoBody(
      this.serviceOrderDataService.serviceOrderDataModel.contact,
    );
    const productLocationBody = this.createProdLocBody(
      this.dataService.serviceOrderDataModel.location,
    );
    const productInformationBody = this.createProductInfoBody(
      this.dataService.serviceOrderDataModel.productInfoModel,
    );
    const notesBody = this.createBodyNotes(this.dataService.serviceOrderDataModel.notes);
    const coverageBody = this.createBodyCoverageDetails(
      this.dataService.serviceOrderDataModel.coverageDetails,
    );
    const limitOfLiabilityBody = this.createLolBody(
      this.serviceOrderDataService.serviceOrderDataModel.lolDetailsInfo,
    );
    const coverageReasonBody = this.createCoverageReasonBody(
      this.serviceOrderDataService.serviceOrderDataModel.selectedCoverage,
    );
    const standardProblemBody = this.createStandardProblem(
      this.dataService.standardProblemDataModel,
    );

    const serviceDetailsBody = this.createTechnicalInfoBody(
      this.dataService.serviceOrderDataModel.techInfo,
    );
    const problemReportBody = this.createProblemReport(
      this.dataService.serviceOrderDataModel.problemReport,
    );
    const expenseChargesBody = this.createBodyExpenseCharges(
      this.dataService.serviceOrderDataModel,
    );
    const generatePreAuthBody = this.creategeneratePreAuthBody(
      this.dataService.serviceOrderDataModel,
    );

    const currentDatetime = moment().format('DD-MMM-YYYY HH:mm:ss');
    const documentDefinition = {
      header: `Service Order # ${this.dataService.serviceOrderDataModel.serviceOrderInfo.serviceOrder} ${currentDatetime}`,
      content: [
        { text: 'Service Order Information', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: serviceOrderBody,
          },
        },
        '\n',
        { text: 'Servicer Information', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: servicerInformationBody,
          },
        },
        '\n',
        { text: 'Dealer Information', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: dealerInformationBody,
          },
        },
        '\n',
        { text: 'Customer Information', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: customerInformationBody,
          },
        },
        '\n',
        { text: 'Product Location', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: productLocationBody,
          },
        },
        '\n',
        { text: 'Product Information', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: productInformationBody,
          },
        },
        '\n',
        { text: 'Coverage', style: 'header', bold: true },
        {
          table: {
            body: coverageBody,
          },
        },
        '\n',
        { text: 'Coverage Reason', fontSize: 10 },
        {
          // layout:'noBorders',
          table: {
            heights: 12,
            widths: [140],
            body: coverageReasonBody,
          },
        },
        '\n',
        { text: 'Limit of Liability', pageBreak: 'before', style: 'header', bold: true },
        {
          layout: 'noBorders',
          table: {
            widths: [100, 120, 110],
            body: limitOfLiabilityBody,
          },
        },
        '\n',
        { text: 'Problem - Standard Service Order', style: 'header', bold: true },
        {
          table: {
            widths: [100, 120, 110, 150],
            body: standardProblemBody,
          },
        },
        '\n',
        { text: 'Service Details', style: 'header', bold: true },
        {
          table: {
            widths: [80, 30, 60, 50, 50, 50, 60, 60],
            body: serviceDetailsBody,
          },
        },
        '\n',
        { text: 'Problem Report', style: 'header', bold: true },
        {
          table: {
            widths: [150, 150, 190],
            body: problemReportBody,
          },
        },
        '\n',
        { text: 'Expense Charges', style: 'header', bold: true },
        {
          table: {
            widths: [40, 65, 75, 40, 40, 40, 40, 44, 48],
            body: expenseChargesBody,
          },
        },
        '\n',
        {
          columns: [
            {
              width: 150,
              text: '',
            },
            {
              width: 150,
              text: '',
            },
            {
              width: 180,
              text: 'Total Requested Amount',
              fontSize: 8,
            },
            {
              text: `$${totalRequestedAmount}`,
              fontSize: 8,
            },
          ],
        },
        '\n',
        {
          columns: [
            {
              width: 150,
              text: '',
            },
            {
              width: 150,
              text: '',
            },
            {
              width: 180,
              text: 'Total Adjusted Amount',
              fontSize: 8,
            },
            {
              text: `$${totalAdjustedAmount}`,
              fontSize: 8,
            },
          ],
        },
        '\n',
        {
          columns: [
            {
              width: 150,
              text: '',
            },
            {
              width: 150,
              text: '',
            },
            {
              width: 180,
              text: 'Total Discount',
              fontSize: 8,
            },
            {
              text: `$${totalDiscount}`,
              fontSize: 8,
            },
          ],
        },
        '\n',
        {
          columns: [
            {
              width: 150,
              text: '',
            },
            {
              width: 150,
              text: '',
            },
            {
              width: 180,
              text: 'Total Approved Amount',
              fontSize: 8,
            },
            {
              text: `$${totalApprovedAmount}`,
              fontSize: 8,
            },
          ],
        },
        '\n',
        { text: 'Generate Pre-Authorization', style: 'header', bold: true },
        {
          table: {
            widths: [90, 90, 90, 90, 120],
            body: generatePreAuthBody,
          },
        },
        '\n',
        { text: 'Notes', style: 'header', bold: true },
        {
          table: {
            headerRows: 1,
            widths: [50, 80, 260, 100],
            body: notesBody,
          },
        },
      ],
    };
    return documentDefinition;
  }

  onClickPrint(event, overlaypanel: OverlayPanel) {
    let servicer = null;
    if (
      this.dataService.serviceOrderDataModel.servicerInfo ||
      this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch
    ) {
      servicer = this.dataService.serviceOrderDataModel.servicerInfo;
      const documentDefinition = this.createDocumentDefinition(servicer);
      const pdfDoc = pdfMake.createPdf(documentDefinition).open();
    } else {
      const serviceProviderValues = this.service.selectedServicerValue;
      if (!serviceProviderValues) {
        const documentDefinition = this.createDocumentDefinition(null);
        const pdfDoc = pdfMake.createPdf(documentDefinition).open();
      } else {
        const servicerProfileId = serviceProviderValues.servicerProfilesIdOriginal;
        if (servicerProfileId) {
          this.isLoading = true;
          this.querySubscriptions.push(
            this.dataService
              .getServicerInfoByIdSingle(servicerProfileId)
              .subscribe(({ data, loading }) => {
                this.isLoading = false;
                const documentDefinition = this.createDocumentDefinition(
                  data.getServicerProfileById,
                );
                const pdfDoc = pdfMake.createPdf(documentDefinition).open();
              }),
          );
        }
      }
    }
  }
  //   const filteredData = filterTableData(this.rows, this.selectedColumns);
  //   printPDF(this.selectedColumns, filteredData);
  //   overlaypanel.hide();
  selectedColumns(rows: (rows: any, selectedColumns: any) => void, selectedColumns: any) {
    throw new Error('Method not implemented.');
  }
  rows(rows: any, selectedColumns: any) {
    throw new Error('Method not implemented.');
  }
  ellipsisOptionClick(event) {
    this.displayDispatch = false;
  }

  getServiceBenchDetails(serviceOrderNumber, dispatchDate) {
    const { serviceBenchJobStatus, serviceBenchCommon } =
      this.serviceOrderDataService.serviceBenchModal;
    this.sbInfoTitle = getValueByKey(serviceBenchCommon, 'SERVICE_BENCH_INFO_TITLE');
    this.sbInfoMessage = getValueByKey(serviceBenchCommon, 'SERVICE_BENCH_INFO_MESSAGE');
    this.isReschedule = false;
    this.serviceOrderDataService.isLoading.next(true);
    this.serviceOrderDataService.getScheduledJob(serviceOrderNumber).subscribe(
      ({ data }) => {
        if (
          data.getBookAppointmentByServiceOrder &&
          data.getBookAppointmentByServiceOrder.serviceType === 'ServiceBench'
        ) {
          this.serviceOrderDataService.serviceBenchModal.scheduleJobDetails = copy(
            data.getBookAppointmentByServiceOrder,
          );
          const { serviceBenchModal } = this.serviceOrderDataService;
          if (
            serviceBenchModal.scheduleJobDetails &&
            serviceBenchModal.scheduleJobDetails.jobNumber
          ) {
            if (
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'Reschedule') ||
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'Reassign') ||
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'SbReschedule') ||
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'SbReassign')
            ) {
              this.isReschedule = true;
            }
            if (
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'Cancelled') ||
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'Service Bench Cancelled') ||
              serviceBenchModal.scheduleJobDetails.status ===
              getValueByKey(serviceBenchJobStatus, 'Service Bench Rejected')
            ) {
              if (this.canDispatchSO(dispatchDate)) {
                this.canDispatch = true;
              }
              this.isScheduleJobAvailable = false;
            }
            else {
              this.isScheduleJobAvailable = true;
            }
            if (this.isScheduleJobAvailable) {
              this.isWithInElegibleTime = this.checkSBCanAndRes();
            }
            if (!RESTRICTED_CLAIM_STATUSES.includes(this.serviceOrderDataService.serviceOrderDataModel.claimData.claimStatusDescription)) {
              this.getJobStatus(null, dispatchDate);
            }
          }
        }
        this.serviceOrderDataService.isLoading.next(false);
      },
      (err) => {
        this.serviceOrderDataService.isLoading.next(false);
        throw err;
      },
    );
  }

  getServicePowerCallStatus(serviceOrderNumber) {
    this.serviceOrderDataService.isLoading.next(true);
    this.isReschedule = false;
    this.serviceOrderDataService.getScheduledJob(serviceOrderNumber).subscribe(({ data }) => {
      this.serviceOrderDataService.isLoading.next(false);
      this.isServicePowerDispatch = false;
      if (
        data.getBookAppointmentByServiceOrder &&
        data.getBookAppointmentByServiceOrder.serviceType === 'ServicePower'
      ) {
        if (
          data.getBookAppointmentByServiceOrder.status !== 'REJECTED' &&
          data.getBookAppointmentByServiceOrder.status !== 'CANCELLED') {
          this.serviceOrderDataService.servicePowerModal.scheduleCallDetails = copy(
            data.getBookAppointmentByServiceOrder,
          );
          this.isServicePowerDispatch = true;
          this.serviceOrderDataService.servicePowerModal.spRejectedCancelled = false;
          if (!RESTRICTED_CLAIM_STATUSES.includes(this.serviceOrderDataService.serviceOrderDataModel.claimData.claimStatusDescription)) {
            this.servicePowerCallStatus(serviceOrderNumber);
          }
        } else {
          this.serviceOrderDataService.servicePowerModal.spRejectedCancelled = true;
        }
        this.isReschedule = data.getBookAppointmentByServiceOrder.isRescheduled;
      }
    });
  }

  servicePowerCallStatus(serviceOrderNumber) {
    this.serviceOrderDataService.isLoading.next(true);
    this.querySubscriptions.push(
      this.serviceOrderDataService.getSpCallStatus(serviceOrderNumber).subscribe(
        (data) => {
          const modifiedData = copy(data);
          this.serviceOrderDataService.isLoading.next(false);
          const body = modifiedData.spStatusDetails['soapenv:Envelope']['soapenv:Body'];
          const response = body.callStatusEnquiryRsp;
          const callsCollection = response.CallsCollection;
          const ackMessage = body && body.callStatusEnquiryRsp.AckMsg;
          if (ackMessage._text && ackMessage._text.startsWith('FSS') && !callsCollection) {
            const errorMsgArray = ackMessage._text.split(': ');
            let errorMsgDetail = ackMessage._text;
            if (errorMsgArray.length > 1) {
              errorMsgDetail = `${errorMsgArray[1]}. Error Code:${errorMsgArray[0]}`;
            }
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `ERROR: ${errorMsgDetail}.`,
            });
          } else {
            this.serviceOrderDataService.servicePowerModal.spCallStatusDetails = {
              callNo: callsCollection.CallNo._text,
              callStatus: callsCollection.CallStatus._text,
              mfgID: callsCollection.MFGID._text,
              reasonCode: callsCollection.ReasonCode._text,
              spSvcrAcct: callsCollection.SPSvcrAcct._text,
              schdDate: callsCollection.SchdDate._text,
              schdTimePeriod: callsCollection.SchdTimePeriod._text,
              secStatus: callsCollection.SecStatus,
              serviceCenter: callsCollection.ServiceCenter._text,
              subStatus: callsCollection.SubStatus._text,
              updatedDate: callsCollection.UpdatedDate._text,
            };
            this.stServiceProviderName = callsCollection.SPSvcrAcct._text;
          }
          const { spEntitledServicers } = this.serviceOrderDataService.servicePowerModal;
          const { servicerInfo } = this.serviceOrderDataService.serviceOrderDataModel;
          if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'LG')) {
            if (this.checkSPCanAndResLg()) {
              this.isWithInElegibleTimeSp = true;
            }
          } else if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'GE')) {
            if (this.checkSPCanAndResGE()) {
              this.isWithInElegibleTimeSp = true;
            }
          } else if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'A&E')) {
            if (this.checkSPCanAndResAE()) {
              this.isWithInElegibleTimeSp = true;
            }
          }
          if (modifiedData.isClaimDataChanged === true) {
            this.refreshClaimProfile();
          }
          if (modifiedData.isNoteChanged === true) {
            this.serviceOrderDataService.loadNote.next(true);
          }
        },
        (err) => {
          this.serviceOrderDataService.isLoading.next(false);
          throw err;
        },
      ),
    );
  }

  getJobStatus(jn, dispatchDate) {
    this.serviceOrderDataService.isLoading.next(true);
    this.serviceOrderDataService.jobStatus(jn).subscribe(
      (jStatusDetails) => {
        this.serviceOrderDataService.serviceBenchModal.jobStatusDetails = copy(jStatusDetails);
        const { scheduleJobDetails, jobStatusDetails, serviceBenchJobStatus } =
          this.serviceOrderDataService.serviceBenchModal;
        const sbJobStatus =
          jobStatusDetails.statusResponse.serviceJobStatusDetails.serviceJobStatusDetail[0];
        this.stServiceProviderName =
          jobStatusDetails.statusResponse.serviceJobStatusDetails.serviceJobStatusDetail[0].serviceProviderName;
        const { jobNumber } = scheduleJobDetails;

        if (jStatusDetails.isNoteAdded) {
          this.serviceOrderDataService.loadNote.next(true);
        }

        if (sbJobStatus.serviceJobStatus === 'RAS') {
          this.getJobStatus(
            jobNumber.split('-')[0].trim() + '-' + (parseInt(jobNumber.split('-')[1], 10) + 1),
            dispatchDate,
          );
        }

        if (
          jStatusDetails &&
          jStatusDetails.statusResponse.serviceJobStatusDetails.serviceJobStatusDetail[0] &&
          (jStatusDetails.statusResponse.serviceJobStatusDetails.serviceJobStatusDetail[0]
            .serviceJobStatus === getValueByKey(serviceBenchJobStatus, 'Cancelled') ||
            jStatusDetails.statusResponse.serviceJobStatusDetails.serviceJobStatusDetail[0]
              .serviceJobStatus === getValueByKey(serviceBenchJobStatus, 'Rejected'))
        ) {
          if (this.canDispatchSO(dispatchDate)) {
            this.canDispatch = true;
          }
          this.isScheduleJobAvailable = false;
        } else {
          this.isScheduleJobAvailable = true;
        }
        this.serviceOrderDataService.isLoading.next(false);
        if (this.isScheduleJobAvailable) {
          this.isWithInElegibleTime = this.checkSBCanAndRes();
        }
        if (jStatusDetails.isClaimUpdated) {
          this.refreshClaimProfile();
          if (jStatusDetails.isNoteAdded) {
            // Timeout added to complete the refresh claim process.
            setTimeout(() => {
              this.serviceOrderDataService.loadNote.next(true);
            }, 1000);
          }
        }
      },
      (err) => {
        this.serviceOrderDataService.isLoading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error while getting job status',
        });
      },
    );
  }

  validateCoverageReason() {
    const soModel = this.serviceOrderDataService.serviceOrderDataModel;
    if (!soModel.selectedCoverage) {
      return true;
    }
    if (soModel.selectedCoverage.name === 'Service Plan') {
      return true;
    }
    if (
      soModel.selectedCoverage &&
      soModel.contractInfo.contractStatusDescription === 'Expired' &&
      (!soModel.coverageReason ||
        soModel.coverageReason.key === '' ||
        soModel.coverageReason.key === null)
    ) {
      return false;
    }
    if (
      soModel.selectedCoverage &&
      soModel.selectedCoverage.name !== 'Service Plan' &&
      soModel.contractInfo.contractStatusDescription === 'Active' &&
      (!soModel.coverageReason ||
        soModel.coverageReason.key === '' ||
        soModel.coverageReason.key === null)
    ) {
      return false;
    }
    return true;
  }

  askForDenialStatus() {}

  onSubmit() {
    if (!this.validateCoverageReason()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please select coverage Reason`,
      });
      return;
    }

    const lolResponse = this.lolCheck(false);
    if (lolResponse.alertMessage !== '') {
      this.confirmationService.confirm({
        header: `Limit Of Liability Remaining less than $${lolResponse.lolAmount}`,
        message: lolResponse.alertMessage,
        acceptLabel: 'override',
        acceptVisible: lolResponse.overrideFlag,
        rejectLabel: 'close',
        accept: () => {
          this.saveLogic();
        },
        reject: () => {
          return;
        },
      });
    } else {
      this.saveLogic();
    }
  }

  onDefault(event) {
    // TODO: Callback
  }

  openTab(tabType) {
    let tabContent = {};
    let refId = null;
    switch (tabType) {
      case 'Servicer':
        tabContent = {
          servicerProfilesIdOriginal: this.selectedServicer
            ? this.selectedServicer.servicerProfilesIdOriginal
            : this.currentServicerProfilesIdOriginal,
        };
        break;
      default:
        refId =
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
          this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractNumber;
        tabContent = {
          originalId:
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractsIdOriginal,
          customerId:
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
            this.serviceOrderDataService.serviceOrderDataModel.contractInfo.customerId,
        };
        break;
    }
    const newTab: Tab = {
      header: refId ? `${tabType} [ ${refId} ]` : tabType,
      content: tabContent,
      type: tabType,
    };
    this.tabService.createTab(newTab);
  }
  onClickClaim() {
    const goodWillReasonValue = this.serviceOrderDataService.serviceOrderDataModel.goodwillReason
      ? this.serviceOrderDataService.serviceOrderDataModel.goodwillReason.value
      : '';
    const lolResponse = this.lolCheck(true);
    if (
      (this.currentClaimStatus === 'PENDING GOODWILL' && goodWillReasonValue === '') ||
      (this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractStatusDescription &&
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractStatusDescription.toUpperCase() ===
        'EXPIRED' &&
        this.currentClaimStatus === 'DRAFT' &&
        goodWillReasonValue === '')
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please add a Goodwill Reason before dispatching',
      });
      return false;
    } else if (lolResponse.alertMessage !== '') {
      this.confirmationService.confirm({
        header: `Limit Of Liability Remaining less than $${lolResponse.lolAmount}`,
        message: lolResponse.alertMessage,
        acceptLabel: 'override',
        acceptVisible: lolResponse.overrideFlag,
        rejectLabel: 'close',
        accept: () => {
          this.proceedWithDispatch();
        },
        reject: () => {
          return;
        },
      });
    } else {
      this.proceedWithDispatch();
    }
    return true; // ADDED FOR CODE RETURN
  }

  checkSBCanAndRes() {
    const { scheduleJobDetails, serviceBenchCommon } =
      this.serviceOrderDataService.serviceBenchModal;
    const scheduledDate = scheduleJobDetails && scheduleJobDetails.scheduledDate;
    const duration = moment.duration(moment(scheduledDate).diff(moment(new Date())));
    const hoursDiff = duration.asHours();
    const minHoursDiff = getValueByKey(serviceBenchCommon, 'MinHoursDiffCan');
    if (hoursDiff < parseInt(minHoursDiff, 10)) {
      if (this.roleService.userRole === 'Admin') {
        return false;
      }
      return true;
    }
    return false;
  }

  onCancelJob() {
    const { scheduleJobDetails, serviceBenchJobStatus, serviceBenchCommon } =
      this.serviceOrderDataService.serviceBenchModal;
    const scheduledDate = scheduleJobDetails && scheduleJobDetails.scheduledDate;
    const duration = moment.duration(moment(scheduledDate).diff(moment(new Date())));
    const hoursDiff = duration.asHours();
    const minHoursDiff = getValueByKey(serviceBenchCommon, 'MinHoursDiffCan');
    if (hoursDiff < parseInt(minHoursDiff, 10) && this.roleService.userRole !== 'Admin') {
      this.displayInfoModal = true;
    } else {
      const isServiceJobOpen =
        scheduleJobDetails &&
        scheduleJobDetails.jobNumber &&
        (scheduleJobDetails.status === null ||
          scheduleJobDetails.status.trim().toUpperCase() ===
          getValueByKey(serviceBenchJobStatus, 'New') ||
          scheduleJobDetails.status.trim().toUpperCase() ===
          getValueByKey(serviceBenchJobStatus, 'Reassign') ||
          scheduleJobDetails.status.trim().toUpperCase() ===
          getValueByKey(serviceBenchJobStatus, 'Reschedule') ||
          scheduleJobDetails.status.trim().toUpperCase() ===
          getValueByKey(serviceBenchJobStatus, 'SbReschedule') ||
          scheduleJobDetails.status.trim().toUpperCase() ===
          getValueByKey(serviceBenchJobStatus, 'SbReassign'));
      if (isServiceJobOpen) {
        this.displayCancelDialog = true;
      }
    }
  }

  onCancelConfirmation(form) {
    this.displayCancelDialog = false;
    const formData = form.getRawValue();
    const { scheduleJobDetails, serviceBenchCommon } =
      this.serviceOrderDataService.serviceBenchModal;
    this.serviceOrderDataService.serviceBenchModal.cancelJobDescription.cancelNote =
      formData.cancelNote;
    this.serviceOrderDataService.serviceBenchModal.cancelJobDescription.cancelReason =
      formData.cancelReason.value;
    const finalNote = formData.cancelReason.value + ' | ' + formData.cancelNote;
    this.serviceOrderDataService.isLoading.next(true);
    this.serviceOrderDataService
      .cancelScheduledJob(
        scheduleJobDetails.jobNumber,
        getValueByKey(serviceBenchCommon, 'DefaultCancelReason'),
        finalNote,
      )
      .subscribe(
        (res) => {
          this.refreshClaimProfile();
          this.serviceOrderDataService.loadNote.next(true);
          this.serviceOrderDataService.isLoading.next(false);
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error while cancelling the job',
          });
          this.serviceOrderDataService.isLoading.next(false);
        },
      );
  }

  checkSPCanAndResLg() {
    const { scheduleCallDetails, servicePowerCommon } =
      this.serviceOrderDataService.servicePowerModal;
    const scheduledDate = scheduleCallDetails && scheduleCallDetails.scheduledDate;
    const duration = moment.duration(moment(scheduledDate).diff(moment(new Date())));
    const hoursDiff = duration.asHours();
    const minHoursDiff = getValueByKey(servicePowerCommon, 'MinHoursDiffCanLg');
    if (hoursDiff < parseInt(minHoursDiff, 10)) {
      return true;
    }
    return false;
  }

  checkSPCanAndResAE() {
    const { scheduleCallDetails, servicePowerCommon } =
      this.serviceOrderDataService.servicePowerModal;
    const scheduledDate = scheduleCallDetails && scheduleCallDetails.scheduledDate;
    const duration = moment.duration(moment(scheduledDate).diff(moment(new Date())));
    const hoursDiff = duration.asHours();
    const minHoursDiff = getValueByKey(servicePowerCommon, 'MinHoursDiffCanAE');
    if (hoursDiff < parseInt(minHoursDiff, 10)) {
      return true;
    }
    return false;
  }

  checkSPCanAndResGE() {
    const { spCallStatusDetails, spNotEligibleGeSubStaus } =
      this.serviceOrderDataService.servicePowerModal;
    if (getKeyByValue(spNotEligibleGeSubStaus, spCallStatusDetails.subStatus)) {
      return true;
    }
    return false;
  }

  onCancelCall() {
    const { servicePowerCommon, spEntitledServicers } =
      this.serviceOrderDataService.servicePowerModal;
    const { servicerInfo } = this.serviceOrderDataService.serviceOrderDataModel;
    // LG and GE group
    if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'LG')) {
      if (this.checkSPCanAndResLg()) {
        this.sbInfoTitle = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_TITLE_LG');
        this.sbInfoMessage = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_MESSAGE_LG');
        this.displayInfoModal = true;
      } else {
        this.displaySpCancelDialog = true;
      }
    } else if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'GE')) {
      if (this.checkSPCanAndResGE()) {
        this.sbInfoTitle = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_TITLE_GE');
        this.sbInfoMessage = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_MESSAGE_GE');
        this.displayInfoModal = true;
      } else {
        this.displaySpCancelDialog = true;
      }
    } else if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'A&E')) {
      if (this.checkSPCanAndResAE()) {
        this.sbInfoTitle = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_TITLE_AE');
        this.sbInfoMessage = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_MESSAGE_AE');
        this.displayInfoModal = true;
      } else {
        this.displaySpCancelDialog = true;
      }
    }
  }

  onSpCancelConfirmation(form) {
    const formData = form.getRawValue();
    const { scheduleCallDetails } = this.serviceOrderDataService.servicePowerModal;
    this.displaySpCancelDialog = false;
    const cancelReasonCode = formData.cancelReason.value;
    this.serviceOrderDataService.isLoading.next(true);
    this.serviceOrderDataService
      .cancelScheduledCall(scheduleCallDetails.serviceOrderNumber, cancelReasonCode)
      .subscribe(
        (data) => {
          const modifiedData = copy(data);
          const body = modifiedData.spCallCancellationDetails['soapenv:Envelope']['soapenv:Body'];
          const ackMessage = body && body.CallCancelRsp.AckMsg;
          this.serviceOrderDataService.isLoading.next(false);
          if (ackMessage._text && ackMessage._text.startsWith('FSS')) {
            const errorMsgArray = ackMessage._text.split(': ');
            let errorMsgDetail = ackMessage._text;
            if (errorMsgArray.length > 1) {
              errorMsgDetail = `${errorMsgArray[1]}. Error Code:${errorMsgArray[0]}`;
            }
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `ERROR: ${errorMsgDetail}.`,
            });
          } else {
            if (modifiedData.isClaimDataChanged === true) {
              this.servicerInfo = null;
              this.refreshClaimProfile();
            }
            if (modifiedData.isNoteChanged === true) {
              this.serviceOrderDataService.loadNote.next(true);
            }
          }
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error while cancelling the call',
          });
          this.serviceOrderDataService.isLoading.next(false);
        },
      );
  }

  onValidatorSubmit(event) {}

  onSendEmail() {
    this.displayEmailModal = true;
  }

  onClickLol() {
    this.displayLolModal = true;
  }

  transformExpenseCharges(claimNumber) {
    this.serviceOrderDataService.serviceOrderDataModel.expenseCharges.map((ex) => {
      ex.claimNumber = String(claimNumber);
      ex.claimId = claimNumber;
    });
  }
  private populateModel(form) {
    const servicerProfilesIdOriginal = this.selectedServicer
      ? this.selectedServicer.servicerProfilesIdOriginal
      : this.modelData && this.modelData.servicerProfilesIdOriginal;
    return this.populateModelCommon(form, servicerProfilesIdOriginal);
  }

  private getServicerInfo(servicerProfilesId) {
    if (servicerProfilesId) {
      this.isServicerProfileIdSet = true;
      this.patchDispatchDate(new Date().toISOString());
      this.updateCustomerDispatchOptions();

      this.serviceOrderDataService.isLoading.next(true);
      this.querySubscriptions.push(
        this.serviceOrderDataService.getServicerInfoById(servicerProfilesId).subscribe(
          ({ data, loading }) => {
            this.servicerModel = data.getServicerProfileById;
            const temp = [
              {
                label: 'Service Center Code',
                value: this.servicerModel && this.servicerModel.servicerNumber,
                type: '',
              },
              {
                label: 'Service Center Name',
                value: this.servicerModel && this.servicerModel.servicerName,
              },
              {
                label: 'Service Center Phone',
                value: this.servicerModel && this.servicerModel.contactInfo.businessPhone,
              },
              {
                label: 'Service Center Fax',
                value: this.servicerModel && this.servicerModel.contactInfo.fax,
              },
              {
                label: 'Service Center Email',
                value: this.servicerModel && this.servicerModel.contactInfo.email,
              },
              {
                label: 'Preferred Payment Method',
                value: this.servicerModel && this.servicerModel.claimPayMethod,
              },
            ];
            // Permission for check Servicer in Servicer Order
            if (
              this.roleService.validateIfPermissionsExist({
                category: 'Claim',
                permission: 'Servicer',
                operation: 'READ_ONLY',
                type: 'Code',
              })
            ) {
              temp[0].type = 'Servicer';
            }
            this.servicerInfo = [...temp];
            this.serviceOrderDataService.serviceOrderDataModel.servicerInfo = this.servicerModel;
            if (this.azureService.servicerNumberLink.length > 0) {
              if (
                this.servicerModel &&
                this.servicerModel.servicerNumber &&
                !this.azureService.servicerNumberLink.includes(this.servicerModel.servicerNumber)
              ) {
                this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave =
                  true;
                this.canEdit = false;
              }
            }
            if (this.servicerModel && this.servicerModel.isCreditCardProfile) {
              this.service.isCreditCardServicerDispatch.next(true);
            } else {
              this.service.isCreditCardServicerDispatch.next(false);
            }
            if (this.servicePowerRefreshRequest) {
              this.service.isClaimDataUpdated.next(true);
              this.servicePowerRefreshRequest = false;
            } else {
              this.serviceOrderDataService.isServicerInfoAvailable.next(true);
            }
            this.serviceOrderDataService.isLoading.next(false);
            this.serviceOrderDataService.setvalidatorDataModel(
              this.serviceOrderDataService.serviceOrderDataModel,
            );
            this.serviceOrderDataService.isValidatorDataAvailable.next(true);
          },
          (err) => {
            this.serviceOrderDataService.isLoading.next(false);
            this.serviceOrderDataService.isServicerInfoAvailable.next(false);
            this.service.isCreditCardServicerDispatch.next(false);
            throw err;
          },
        ),
      );
    } else {
      this.isServicerProfileIdSet = false;
      this.service.isCreditCardServicerDispatch.next(false);
    }
  }

  private getKey(value) {
    let key = value;
    value = value ? value.toLowerCase() : '';
    switch (value) {
      case 'cu':
        key = 'Customer';
        break;
      case 'dr':
        key = 'Dealer Rep';
        break;
      case 'sp':
        key = 'Service Provider';
        break;
      case 'tn':
        key = 'Tenants';
        break;
      case 'ot':
        key = 'Other';
        break;
      case 'e':
        key = 'Email';
        break;
      case 'f':
        key = 'Fax';
        break;
      case 'w':
        key = 'Work';
        break;
      case 'm':
        key = 'Mobile';
        break;
      case 'h':
        key = 'Home';
        break;
    }

    return key;
  }

  saveClaim() {
    if (this.subClaim) {
      this.isSubClaimProblem = true;
      this.currentClaimStatus = undefined;
      delete this.payLoad.claimId;
      delete this.payLoad.claimIdOriginal;
      delete this.payLoad.serviceOrderNumber;
      delete this.payLoad.servicerProfilesIdOriginal;
      delete this.payLoad.servicerName;
      delete this.payLoad.dispatchDate;
      delete this.payLoad.customerDispatch;
      delete this.payLoad.checkNumber;
      delete this.payLoad.claimPaymentDate;
      delete this.payLoad.checkAmount;
      delete this.payLoad.importFileName;
      delete this.payLoad.claimDate;
    }
    this.payLoad.dispatchDate = this.originalDispatchDate;
    this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = true));
    this.serviceOrderDataService.isLoading.next(true);
    this.querySubscriptions.push(
      this.serviceOrderDataService.saveClaim(this.payLoad).subscribe(
        (data: any) => {
          this.subClaim = false; // Change this back to note remove new sub claim fields
          this.isGoodwill = false; // Can't be good will after first save.
          delete this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag;
          const result = data && data.data ? data.data.createUpdateClaims : {};
          this.serviceOrderDataService.newClaimsId.next(result.claimIdOriginal);
          this.serviceOrderDataService.isLoading.next(false);
          this.button.filter((c) => c.name === 'Submit').forEach((x) => (x.disabled = false));
          this.updateCustomerDispatchOptions();
          // if(result && result.serviceOrderNumber) {
          //   this.getServiceBenchDetails(result.serviceOrderNumber);
          // }
          if (this.modelData) {
            this.modelData = {
              ...this.modelData,
              ...result,
              uniqueId: this.serviceOrderDataService.serviceOrderDataModel.contractInfo.uniqueId,
            };
          } else {
            this.modelData = {
              ...result,
              uniqueId: this.serviceOrderDataService.serviceOrderDataModel.contractInfo.uniqueId,
            };
          }
          if(this.form.getRawValue().serviceType.value !== 'Service'){
            this.serviceOrderDataService.isServiceTypeNotService.next(true);
          }

          // update form fields
          if (this.form.getRawValue().claimStatusDescription !== 'CLOSED - NO SERVICE REQUIRED') {
            this.serviceOrderDataService.serviceConfig.isReturnSubmission = false;
          }
          const status = this.validateClaimStatus(this.form.getRawValue().claimStatusDescription);
          this.updateFormFields({
            key: status,
            value: status,
          });
          this.form.patchValue({
            claimStatusDescription: {
              key: status,
              value: status,
            },
          });

          // populate service order info
          this.serviceOrderDataService.isLoading.next(true);
          this.querySubscriptions.push(
            this.service.getServiceOrderInfo(this.modelData).subscribe(
              (serviceOrderInfo) => {
                this.serviceOrderInfo = serviceOrderInfo;
                this.serviceOrderDataService.isLoading.next(false);
                this.serviceOrderDataService.isServiceOrderInfoAvailable.next(true);
              },
              (err) => {
                this.serviceOrderDataService.isLoading.next(false);
                this.isLoading = false;
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'There was an error.',
                });
              },
            ),
          );

          // Mutation for CreateProblem
          if (data.data.createUpdateClaims) {
            const claimId = data.data.createUpdateClaims.claimIdOriginal;
            this.currentClaimIdOriginal = data.data.createUpdateClaims.claimIdOriginal;
            let probOb = {
              claimNumber: String(claimId),
              serviceOrderNumber: String(claimId),
              claimId,
              enteredBy: null,
              insertUserName: null,
              claimProblemSymptomIdOriginal: null,
              failureDate: null,
              reportedDate: null,
            };
            probOb = {
              ...probOb,
              ...this.serviceOrderDataService.standardProblemDataModel,
              enteredBy: this.serviceOrderDataService.standardProblemDataModel.enteredBy
                ? this.serviceOrderDataService.standardProblemDataModel.enteredBy
                : this.azureService.accountId,
              insertUserName: this.azureService.accountId,
            };
            probOb = {
              ...probOb,
              failureDate: this.serviceOrderDataService.standardProblemDataModel.currentFailureDate
                ? this.serviceOrderDataService.standardProblemDataModel.currentFailureDate
                : this.serviceOrderDataService.standardProblemDataModel.failureDate,
              reportedDate: this.serviceOrderDataService.standardProblemDataModel
                .currentReportedDate
                ? this.serviceOrderDataService.standardProblemDataModel.currentReportedDate
                : this.serviceOrderDataService.standardProblemDataModel.reportedDate,
            };

            probOb = {
              ...probOb,
              claimProblemSymptomIdOriginal: this.isSubClaimProblem
                ? null
                : this.serviceOrderDataService.standardProblemDataModel
                  .claimProblemSymptomIdOriginal,
            };
            const { selectedCoverage, coverageReason } =
              this.serviceOrderDataService.serviceOrderDataModel;

            if (selectedCoverage) {
              const claimCoverageOb = {
                claimId,
                name: selectedCoverage.name,
                covered: selectedCoverage.covered === 'YES' ? true : false,
                coverageStart: selectedCoverage.coverageStDate,
                coverageExp: selectedCoverage.coverageExpDate,
                policy: selectedCoverage.policySku,
                description: selectedCoverage.description,
                term: String(selectedCoverage.cvTerm),
                coverageReason: coverageReason && coverageReason.value,
                claimCoverageIdOriginal: selectedCoverage.claimCoverageIdOriginal || null,
              };

              this.querySubscriptions.push(
                this.serviceOrderDataService
                  .saveClaimCoverage(claimCoverageOb)
                  .subscribe((res) => {}),
              );
            }

            // Creating or Updating Expense Charges
            // if (this.serviceOrderDataService.serviceOrderDataModel.expenseCharges.length) {
            //   this.transformExpenseCharges(claimId);
            //   const {expenseCharges} = this.serviceOrderDataService.serviceOrderDataModel;
            //   this.serviceOrderDataService.createUpdateExpenseCharges(expenseCharges).subscribe(res => {
            //     this.serviceOrderDataService.serviceOrderDataModel.expenseCharges = [...res.data.createUpdateExpenseCharges];
            //   });
            // }
            this.querySubscriptions.push(
              this.serviceOrderDataService
                .createUpdateClaimProblemSymptom(probOb)
                .subscribe((res: any) => {
                  this.isLoading = false;
                  this.isSuccess = true;
                  this.isSubClaimProblem = false;
                  setTimeout(() => {
                    this.isSuccess = false;
                  }, 3000);
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Saved successfully.',
                  });

                  this.serviceOrderDataService.standardProblemDataModel.claimProblemSymptomIdOriginal =
                    res.claimProblemSymptomIdOriginal;
                  // Send out the automatic Email Notifications
                  if (this.payLoad.claimStatusDescription !== this.currentClaimStatus) {
                    let statusChangeNote =
                      ' Service order ' +
                      this.serviceOrderDataService.serviceOrderDataModel.claimData
                        .serviceOrderNumber +
                      ' status changed from ' +
                      this.currentClaimStatus +
                      ' to ' +
                      this.payLoad.claimStatusDescription;
                    // Add the Servicer details on the SO change not if it was dispatched to a Servicer.
                    if (
                      this.payLoad.claimStatusDescription === 'PENDING ACCEPTANCE' &&
                      this.serviceOrderDataService.serviceOrderDataModel.servicerInfo
                    ) {
                      const servicerName =
                        this.serviceOrderDataService.serviceOrderDataModel.servicerInfo
                          .servicerName;
                      const servicerNumber =
                        this.serviceOrderDataService.serviceOrderDataModel.servicerInfo
                          .servicerNumber;
                      statusChangeNote += `, dispatched to ${servicerName} | ${servicerNumber}.`;
                    }
                    this.serviceOrderDataService.notesDataModel.type = NoteType.StatusChange;
                    this.serviceOrderDataService.notesDataModel.text = statusChangeNote;
                    this.serviceOrderDataService.notesDataModel.enteredBy =
                      this.azureService.fullName;
                    this.serviceOrderDataService.newNote.next(true);

                    this.autoEmailService.sendAutoEmail(
                      this.payLoad.claimStatusDescription,
                      this.currentClaimStatus,
                    );
                  }
                  this.currentClaimStatus = this.payLoad.claimStatusDescription;
                  this.clearedDispatchDate = false;
                  // Delete to fix Send Email Form Back clearing of Dispatch Date.
                  delete this.service.selectedServicerValue;
                  this.serviceOrderDataService.isMainTabDataAvailable.next(true);
                }),
            );

            // Update CreditCardInfo if there is any changes
            if (this.serviceOrderDataService.isCreditCardInfoUpdated) {
              const creditCardInfo =
                this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo;
              const creditCardInfoInputOb = {
                outOfNetworkInfoIdOriginal: creditCardInfo.outOfNetworkInfoIdOriginal,
                companyName: creditCardInfo.companyName,
                nameCC: creditCardInfo.nameCC,
                providerAddress: creditCardInfo.providerAddress,
                phoneNumber: creditCardInfo.phoneNumber,
                initialFee: parseFloat(
                  creditCardInfo.initialFee ? creditCardInfo.initialFee.replace('$', '') : null,
                ),
                poc: creditCardInfo.poc,
                dispatchMethod: creditCardInfo.dispatchMethod,
                emailFax:
                  creditCardInfo.dispatchMethod === 'Email'
                    ? creditCardInfo.email
                    : creditCardInfo.phone,
                partOrderNumber: creditCardInfo.partOrderNumber,
                customerAddressVerified: creditCardInfo.customerAddressVerified,
                cCProvidedDate: dateSubstring(creditCardInfo.cCProvidedDate),
                approvedAmount: parseFloat(
                  creditCardInfo.approvedAmount
                    ? creditCardInfo.approvedAmount.replace('$', '')
                    : null,
                ),
                agentInitials: creditCardInfo.agentInitials,
                serviceOrderNumber: creditCardInfo.serviceOrderNumber,
                insertUserName: this.azureService.accountId,
              };

              // Add CC details to note (only for new details)
              if (!creditCardInfo.outOfNetworkInfoIdOriginal && creditCardInfoInputOb.companyName) {
                const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
                const claimDispatchDate = claimData.dispatchDate.includes('.')
                  ? this.datePipe.transform(claimData.dispatchDate, 'MM/dd/yyyy')
                  : getMMDDYYYFormat(claimData.dispatchDate, 'MM/DD/YYYY');
                let creditCardInfoNote = 'Date of Dispatch: ' + claimDispatchDate;
                if (this.serviceOrderDataService.serviceOrderDataModel.creditCardInfoCount > 0) {
                  creditCardInfoNote = 'Date of Redispatch: ' + claimDispatchDate;
                }
                creditCardInfoNote +=
                  ', Service Provider: ' +
                  creditCardInfoInputOb.companyName +
                  ', Dispatch Method: ' +
                  creditCardInfoInputOb.dispatchMethod +
                  '(' +
                  creditCardInfoInputOb.emailFax +
                  '), ' +
                  'Point of Contact: ' +
                  creditCardInfoInputOb.poc +
                  ', Initial Diag Fee: $' +
                  creditCardInfoInputOb.initialFee +
                  ', Date CC Provided: ' +
                  getMMDDYYYFormat(creditCardInfoInputOb.cCProvidedDate, 'MM/DD/YYYY') +
                  ', Agent Initials: ' +
                  creditCardInfoInputOb.agentInitials;

                this.serviceOrderDataService.notesDataModel.type = NoteType.CreditCardInfo;
                this.serviceOrderDataService.notesDataModel.text = creditCardInfoNote;
                this.serviceOrderDataService.notesDataModel.enteredBy = this.azureService.fullName;
                this.serviceOrderDataService.newNote.next(true);
              }

              this.querySubscriptions.push(
                this.serviceOrderDataService
                  .createUpdateCreditCardInfo(creditCardInfoInputOb)
                  .subscribe(({}) => {
                    this.serviceOrderDataService.isCreditCardInfoUpdated = false;
                  }),
              );
            }
          }

          if (result.claimIdOriginal !== this.currentClaimIdOriginal) {
            this.refreshClaimProfile();
          }
        },
        (err) => {
          this.serviceOrderDataService.isLoading.next(false);
          this.isLoading = false;
          if (err.message && err.message.includes('NOTINSYNC')) {
            this.sbInfoTitle = 'Reopen the Tab';
            this.sbInfoMessage = 'Claim data is changed. Please reopen the tab and try again.';
            this.displayInfoModal = true;
          } else if (err.message && err.message.includes('TECHINFOMISSING')) {
            this.sbInfoTitle = 'Technical Info Missing';
            this.sbInfoMessage = 'Claim Technical Info Missing. Please update the technical info and try again.';
            this.displayInfoModal = true;
          }
           else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'There was an error.',
            });
          }
        },
      ),
    );
    this.formValueChanged.emit(this.form && this.form.dirty);
  }

  // Validation for claim data
  validateClaimData(isFromDispatch = false) {
    // Required Field Validatons
    if (this.payLoad) {
      const { addresses, contactInput, callerType } = this.payLoad;
      if (
        !callerType ||
        !addresses.address1 ||
        !addresses.zipCode ||
        !addresses.state ||
        !addresses.city ||
        !addresses.country ||
        !contactInput.firstName ||
        !contactInput.lastName ||
        !contactInput.phoneNumber1 ||
        !contactInput.phoneTypeCode1
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill required fields',
        });
        return false;
      }
      if (contactInput.phoneNumber2 && !contactInput.phoneTypeCode2) {
        {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Phone Type Code 2 is required if Phone Number 2 is populated.',
          });
          return false;
        }
      }

      if (!this.serviceOrderDataService.serviceOrderDataModel.formValidation.contact) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Customer Information has form errors.',
        });
        return false;
      }

      if (!this.serviceOrderDataService.serviceOrderDataModel.formValidation.location) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Product Location has form errors.',
        });
        return false;
      }
    }

    if (!this.validateCustomerDispatch(isFromDispatch)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'This claim has not been dispatched',
      });
      return false;
    }

    // Problem Validation
    const {
      failureDate,
      reportedDate,
      functionalityStatus,
      failureDescription,
      symptom,
      symptomFixNote,
    } = this.serviceOrderDataService.standardProblemDataModel;

    if (!failureDate || !reportedDate || !functionalityStatus || !failureDescription || !symptom) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please add a problem',
      });
      return false;
    }
    // VALIDATION FOR 50% Back - RedemptionDate
    const form = this.form.getRawValue();
    const formClaimStatus = form.claimStatusDescription && form.claimStatusDescription.value;
    if (
      this.isContractExpired &&
      this.isfPBFlag &&
      this.currentClaimStatus !== 'CLOSED - NO SERVICE REQUIRED' &&
      !['REJECT SO', 'ACCEPT SO'].includes(formClaimStatus)
    ) {
      const { dispatchDate } = this.serviceOrderDataService.serviceOrderDataModel.claimData;
      const { correctiveAction } = this.serviceOrderDataService.serviceOrderDataModel.problemReport;
      const { fPBRedemptionDate } = this.serviceOrderDataService.serviceOrderDataModel.techInfo;
      if (dispatchDate && correctiveAction) {
        if (!fPBRedemptionDate) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Redemption Date is required',
          });
          return false;
        }
        if (fPBRedemptionDate && !this.isGivenDateInRange(dispatchDate, fPBRedemptionDate)) {
          if (!REDEMPTION_DATE_OVERRIDE_ROLES.includes(this.azureService.roleName)) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Redemption Date is should be with in 30 Days of dispatch Date',
            });
            return false;
          }
        }
      }
    }
    // Validation for CreditCard Info
    if (
      this.serviceOrderDataService.isCreditCardInfoEditable &&
      !(
        formClaimStatus === 'REJECT SO' && formClaimStatus !== this.modelData.claimStatusDescription
      )
    ) {
      const { creditCardInfo } = this.serviceOrderDataService.serviceOrderDataModel;
      if (
        !creditCardInfo.companyName ||
        !creditCardInfo.nameCC ||
        !creditCardInfo.providerAddress ||
        !creditCardInfo.phoneNumber ||
        !creditCardInfo.initialFee ||
        !creditCardInfo.poc ||
        !creditCardInfo.dispatchMethod ||
        (!creditCardInfo.email && !creditCardInfo.phone) ||
        !creditCardInfo.agentInitials ||
        creditCardInfo.partOrder === null ||
        creditCardInfo.partOrder === undefined ||
        (creditCardInfo.partOrder &&
          (!creditCardInfo.partOrderNumber ||
            creditCardInfo.customerAddressVerified === null ||
            creditCardInfo.customerAddressVerified === undefined))
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill required fields in CC tab.',
        });
        return false;
      }
      if (
        (creditCardInfo.dispatchMethod === 'Email' &&
          !RegExp(EmailPattern).test(creditCardInfo.email)) ||
        (creditCardInfo.dispatchMethod === 'Phone' &&
          !RegExp(PhonePattern).test(creditCardInfo.phone)) ||
        !RegExp(PhonePattern).test(creditCardInfo.phoneNumber)
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill valid data in CC tab.',
        });
        return false;
      }
      const ccDate = new Date(creditCardInfo.cCProvidedDate);
      if (ccDate && ccDate.getFullYear() > 9999) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill valid date in CC tab.',
        });
        return false;
      }
    }

    return true;
  }
  isGivenDateInRange(start, tDate) {
    const mStart = new Date(start);
    const allowedFPBRedemptionDate = new Date(mStart);
    allowedFPBRedemptionDate.setDate(allowedFPBRedemptionDate.getDate() + 30);
    const mEnd = new Date(allowedFPBRedemptionDate);
    const mfPBRedemptionDate = new Date(tDate);
    const sHalf = (mEnd.getTime() - mfPBRedemptionDate.getTime()) / (1000 * 3600 * 24);
    const fHalf = (mfPBRedemptionDate.getTime() - mStart.getTime()) / (1000 * 3600 * 24);
    if (sHalf >= 0 && fHalf >= 0) {
      return true;
    }
    return false;
  }

  private updateFormFields(newStatus = null) {
    const updatedStatusOptionsList = newStatus
      ? [newStatus]
      : [
        {
          key: this.modelData.claimStatusDescription,
          value: this.modelData.claimStatusDescription,
        },
      ];
    const eligibleStatus = this.modelData.eligibleStatus;
    if (eligibleStatus) {
      for (const singleStatus of eligibleStatus) {
        updatedStatusOptionsList.push({ key: singleStatus, value: singleStatus });
      }
    }
    const newFormFields: FormField<string>[] = [];
    for (const singleFormField of this.formFields) {
      if (singleFormField.key === 'claimStatusDescription') {
        if (this.subClaim && !this.isGoodwill) {
          singleFormField.options = [{ key: 'DRAFT', value: 'DRAFT' }];
        } else {
          singleFormField.options = updatedStatusOptionsList;
        }
      }
      newFormFields.push(singleFormField);
    }
    this.formFields = newFormFields;
  }

  private validateClaimStatus(status) {
    let result = status && status.value;
    if (this.dispatched) {
      result = 'PENDING ACCEPTANCE';
      this.dispatched = false;
    } else {
      switch (result) {
        case 'ACCEPT SO':
          result = 'IN PROGRESS';
          break;
        case 'REJECT SO':
          result = 'PENDING DISPATCH';
          break;
      }
    }

    if (this.serviceOrderDataService.serviceConfig.isReturnSubmission) {
      result = 'CLOSED - NO SERVICE REQUIRED';
    }

    if (
      result === 'PENDING GOODWILL' &&
      this.serviceOrderDataService.serviceOrderDataModel.goodwillReason
    ) {
      result = 'DRAFT';
    }

    const isGoodwill = this.checkGoodWill(result);
    if (isGoodwill) {
      this.isGoodwill = true;
      result = 'PENDING GOODWILL';
      let goodwillMsg = '<ul><li>Claim requires Good Will Authorization to continue.</li>';
      goodwillMsg += '<li>Claim Status changed to [PENDING GOODWILL].</li></ul>';
      this.confirmationService.confirm({
        header: `Status Update`,
        message: goodwillMsg,
        acceptLabel: 'OK',
        rejectVisible: false,
        accept: () => {
          return;
        },
        reject: () => {
          return;
        },
      });
    }
    return result;
  }

  patchDispatchDate(date, isDispatchDateNull = false) {
    this.originalDispatchDate = date;
    let finalFormattedDispatchDate = null;
    if (!isDispatchDateNull && date !== null) {
      const dateFormatWithTimeZone = date.includes('.');
      finalFormattedDispatchDate = dateFormatWithTimeZone
        ? this.datePipe.transform(date, 'yyyy-MM-dd')
        : date.substring(0, 10);
    }
    this.form.patchValue({
      dispatchDate: finalFormattedDispatchDate,
    });
  }

  onDispatchDisplayChange(claimDisplay): void {
    this.displayDispatch = claimDisplay;
    if (
      this.serviceOrderDataService.serviceOrderDataModel.claimData &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchDate
    ) {
      this.patchDispatchDate(
        this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchDate,
      );
    }

    if (
      this.serviceOrderDataService.serviceOrderDataModel.claimData &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchNoJobFlag
    ) {
      const statusChangeNote = 'Backend Dispatch';
      this.serviceOrderDataService.notesDataModel.type = NoteType.StatusChange;
      this.serviceOrderDataService.notesDataModel.text = statusChangeNote;
      this.serviceOrderDataService.notesDataModel.enteredBy = this.azureService.fullName;
      this.serviceOrderDataService.newNote.next(true);
    }
    this.dispatchAutoSave();
  }

  // Customer Dispatch Flow to Be Verified
  onDisplayChange(emailDisplay): void {
    this.displayEmailModal = emailDisplay;
    if (this.validateClaimData(true)) {
      if (
        !this.initialCustomerDispatchSelection &&
        this.customerDispatch &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchedFlag
      ) {
        this.patchDispatchDate(new Date().toISOString());
      }
      this.dispatchAutoSave();
    }
  }

  onInfoDisplayChange(isDisplay): void {
    this.displayInfoModal = isDisplay;
  }

  dispatchAutoSave() {
    if (
      this.serviceOrderDataService.serviceOrderDataModel &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData
    ) {
      if (
        this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchedFlag &&
        this.serviceOrderDataService.serviceOrderDataModel.isThirdPartyDispatch
      ) {
        this.serviceOrderDataService.serviceOrderDataModel.isThirdPartyDispatch = false;
        this.refreshClaimProfile();
      } else if (
        this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchedFlag &&
        this.currentClaimStatus !== 'PENDING ACCEPTANCE'
      ) {
        this.dispatched = true;
        this.payLoad = this.populateModel(this.form.getRawValue());
        this.payLoad.claimIdOriginal = this.modelData && this.modelData.claimIdOriginal;
        this.saveClaim();
      } else if (
        this.serviceOrderDataService.serviceConfig.isReturnSubmission &&
        this.form.getRawValue().claimStatusDescription.value !== 'CLOSED - NO SERVICE REQUIRED'
      ) {
        this.payLoad = this.populateModel(this.form.getRawValue());
        this.payLoad.claimIdOriginal = this.modelData && this.modelData.claimIdOriginal;
        this.saveClaim();
      }
    }
  }

  cancelJobAutoSave() {
    if (
      this.serviceOrderDataService.serviceOrderDataModel &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData
    ) {
      this.dispatched = false;
      this.payLoad = this.populateModelCommon(this.form.getRawValue(), null);
      this.payLoad.claimIdOriginal = this.modelData && this.modelData.claimIdOriginal;
      this.saveClaim();
    }
  }

  onCancelModalCancel(event) {
    if (!event) {
      this.displaySpCancelDialog = false;
      this.displayCancelDialog = false;
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  validateCustomerDispatch(isFromDispatch = false) {
    if (isFromDispatch && this.customerDispatch && !this.initialCustomerDispatchSelection) {
      return true;
    } else if (
      this.customerDispatch &&
      !this.initialCustomerDispatchSelection &&
      !this.dispatched
    ) {
      return false;
    }
    return true;
  }

  private populateModelAfterValidation(form) {
    let servicerProfilesIdOriginal = this.selectedServicer
      ? this.selectedServicer.servicerProfilesIdOriginal
      : this.modelData && this.modelData.servicerProfilesIdOriginal;
    const formClaimStatus = form.claimStatusDescription && form.claimStatusDescription.value;
    if (
      (this.currentClaimStatus !== formClaimStatus && formClaimStatus === 'REJECT SO') ||
      (this.currentClaimStatus !== formClaimStatus &&
        this.currentClaimStatus === 'CLOSED - NO SERVICE REQUIRED')
    ) {
      servicerProfilesIdOriginal = null;
      this.serviceOrderDataService.serviceOrderDataModel.servicerInfo = null;
      this.servicerInfo = null;
      this.selectedServicer = null;
      this.clearedDispatchDate = true;
      this.canDispatch = true;
      this.customerDispatch = false;
      this.service.onCustomerDispatchChange.next(false);
      this.patchDispatchDate('', true);
      this.form.patchValue({
        customerDispatch: [],
      });
    }
    return this.populateModelCommon(form, servicerProfilesIdOriginal);
  }

  private populateModelCommon(form, servicerProfilesIdOriginal) {
    let checkAmount =
      this.modelData && this.modelData.checkAmount ? this.modelData.checkAmount : null;
    if (checkAmount) {
      checkAmount = checkAmount.replace('$', '').replace(',', '');
    }
    this.form.patchValue({
      customerDispatch: this.customerDispatch ? ['Customer Dispatch'] : [],
    });
    const goodwillReason =
      this.serviceOrderDataService.serviceOrderDataModel &&
        this.serviceOrderDataService.serviceOrderDataModel.goodwillReason &&
        this.serviceOrderDataService.serviceOrderDataModel.goodwillReason.value
        ? this.serviceOrderDataService.serviceOrderDataModel.goodwillReason.value
        : null;
    return {
      contractsId: this.serviceOrderDataService.serviceConfig.searchParams.contractId,
      contractsIdOriginal: this.serviceOrderDataService.serviceConfig.searchParams.contractId,
      contractNumber:
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractNumber,
      sUniqueId: this.serviceOrderDataService.serviceOrderDataModel.contractInfo.uniqueId
        ? this.serviceOrderDataService.serviceOrderDataModel.contractInfo.uniqueId
        : null,
      claimNumber: this.modelData && this.modelData.claimNumber ? this.modelData.claimNumber : null,
      claimStatusDescription: this.validateClaimStatus(form.claimStatusDescription),
      claimFollowUpDate: form.claimFollowUpDate,
      actionRequired:
        form.actionRequired && form.actionRequired.value,
      paymentMethodCode: form.paymentMethodCode && form.paymentMethodCode.value,
      dispatchDate: this.clearedDispatchDate ? null : form.dispatchDate,
      jsyFlag: form.jsyFlag && form.jsyFlag.length ? true : false,
      customerFeedback:
        form.customerFeedback && form.customerFeedback.value ? form.customerFeedback.value : null,
      customerDispatch: this.customerDispatch,
      callerType:
        this.serviceOrderDataService.serviceOrderDataModel.contact &&
        this.serviceOrderDataService.serviceOrderDataModel.contact.callerType &&
        this.serviceOrderDataService.serviceOrderDataModel.contact.callerType.value,
      dispatchMethod:
        this.serviceOrderDataService.serviceOrderDataModel.contact &&
        this.serviceOrderDataService.serviceOrderDataModel.contact.dispatchMethod &&
        this.serviceOrderDataService.serviceOrderDataModel.contact.dispatchMethod.value,
      isBusinessLocation:
        this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.isBusinessLocation
          ? this.serviceOrderDataService.serviceOrderDataModel.location.isBusinessLocation.value
          : false,
      servicerProfilesIdOriginal,
      serviceType: form.serviceType ? form.serviceType.value : null,
      addresses: {
        address1:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.address1,
        address2:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.address2,
        zipCode:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.zipCode,
        state:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.state &&
          this.serviceOrderDataService.serviceOrderDataModel.location.state.value,
        city:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.city,
        country:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.country &&
          this.serviceOrderDataService.serviceOrderDataModel.location.country.value,
        addressIDOriginal:
          this.serviceOrderDataService.serviceOrderDataModel.location &&
          this.serviceOrderDataService.serviceOrderDataModel.location.addressIDOriginal,
      },
      contactInput: {
        firstName:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.firstName,
        lastName:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.lastName,
        phoneNumber1:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.phoneNumber1,
        phoneNumber2:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.phoneNumber2,
        email:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.email,
        phoneTypeCode1:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.phoneTypeCode1 &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.phoneTypeCode1.value,
        phoneTypeCode2:
          this.serviceOrderDataService.serviceOrderDataModel.contact &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.phoneTypeCode2 &&
          this.serviceOrderDataService.serviceOrderDataModel.contact.phoneTypeCode2.value,
      },
      coveredProduct: {
        ...this.serviceOrderDataService.serviceOrderDataModel.productInfoModel,
        modelSuffix: this.serviceOrderDataService.serviceOrderDataModel.productInfo.modelSuffix,
        serialNumber:
          this.serviceOrderDataService.serviceOrderDataModel.productInfo &&
            this.serviceOrderDataService.serviceOrderDataModel.productInfo.serialNumber
            ? this.serviceOrderDataService.serviceOrderDataModel.productInfo.serialNumber
            : this.serviceOrderDataService.serviceOrderDataModel.productInfoModel &&
            this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.serialNumber,
        insertUserName: this.azureService.accountId,
      },
      addedTimeStamp:
        this.modelData && this.modelData.addedTimeStamp ? this.modelData.addedTimeStamp : null,
      insertUserName: this.azureService.accountId,
      sendFollowUpEmail:
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData.sendFollowUpEmail,
      buyingGroup:
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.buyingGroup,
      servicerName:
        this.serviceOrderDataService.serviceOrderDataModel.servicerInfo &&
        this.serviceOrderDataService.serviceOrderDataModel.servicerInfo.servicerName,
      dealerName:
        this.serviceOrderDataService.serviceOrderDataModel.dealerInfo &&
        this.serviceOrderDataService.serviceOrderDataModel.dealerInfo.name,
      serviceOrderNumber:
        this.modelData && this.modelData.serviceOrderNumber
          ? this.modelData.serviceOrderNumber
          : null,
      checkNumber:
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
          this.serviceOrderDataService.serviceOrderDataModel.claimData.checkNumber
          ? this.serviceOrderDataService.serviceOrderDataModel.claimData.checkNumber
          : this.modelData && this.modelData.checkNumber
            ? this.modelData.checkNumber
            : null,
      claimPaymentDate:
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
          this.serviceOrderDataService.serviceOrderDataModel.claimData.claimPaymentDate
          ? this.serviceOrderDataService.serviceOrderDataModel.claimData.claimPaymentDate
          : this.modelData && this.modelData.claimPaymentDate
            ? this.modelData.claimPaymentDate
            : null,
      checkAmount,
      importFileName:
        this.modelData && this.modelData.importFileName ? this.modelData.importFileName : null,
      assignedTo: form.assignedTo && form.assignedTo.value ? form.assignedTo.value : null,
      lastUpdatedBy: this.user,
      lastUpdateTime:
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
          this.serviceOrderDataService.serviceOrderDataModel.claimData.lastUpdateTime
          ? this.serviceOrderDataService.serviceOrderDataModel.claimData.lastUpdateTime
          : null,
      createdBy: this.modelData && this.modelData.createdBy ? this.modelData.createdBy : this.azureService.fullName,
      startedOn: this.modelData && this.modelData.startedOn ? this.modelData.startedOn : null,
      claimDate: this.modelData && this.modelData.claimDate ? this.modelData.claimDate : null,
      transferredContractsId:
        this.modelData && this.modelData.transferredContractsId
          ? this.modelData.transferredContractsId
          : null,
      goodwillReason,
      goodwillInsertUserName:
        this.modelData && this.modelData.goodwillInsertUserName
          ? this.modelData.goodwillInsertUserName
          : goodwillReason
            ? this.azureService.accountId
            : null,
      goodwillAddedTimeStamp:
        this.modelData && this.modelData.goodwillAddedTimeStamp
          ? this.modelData.goodwillAddedTimeStamp
          : goodwillReason
            ? new Date().toISOString().substring(0, 10)
            : null,
    };
  }

  updateCustomerDispatchOptions() {
    if (this.customerDispatch || this.isServicerProfileIdSet) {
      this.form.get('customerDispatch').disable();
    } else {
      this.form.get('customerDispatch').enable();
    }
  }

  hasEmptyValue(value) {
    if (value === null || value === '' || value === undefined) {
      return true;
    }
    return false;
  }

  hasNotTechnicalInfoForPayment(data, formData) {
    const dateOfInvoice = data.techInfo.dateOfInvoice;
    const dateOfRepair = data.techInfo.dateOfRepair;
    const user = this.azureService.roleName;
    if (this.service.getAdminsList().includes(user)) {
      return (
        data.techInfo &&
        (this.hasEmptyValue(data.techInfo.invoiceNumber) ||
          this.hasEmptyValue(data.techInfo.serviceStatus) ||
          this.hasEmptyValue(data.techInfo.numberOfVisits) ||
          this.hasEmptyValue(data.techInfo.dateOfRepair))
      );
    } else {
      return (
        data.techInfo &&
        (this.hasEmptyValue(data.techInfo.invoiceNumber) ||
          this.hasEmptyValue(data.techInfo.serviceStatus) ||
          this.hasEmptyValue(data.techInfo.numberOfVisits) ||
          this.hasEmptyValue(data.techInfo.dateOfRepair))
      );
    }
  }

  hasNotProblemReportInfoForpayment(data) {
    return (
      !data.problemReport ||
      (data.problemReport &&
        (this.hasEmptyValue(data.problemReport.cause) ||
          this.hasEmptyValue(data.problemReport.correctiveAction) ||
          this.hasEmptyValue(data.problemReport.correctiveActionNote) ||
          this.hasEmptyValue(data.problemReport.causeNote)))
    );
  }

  techInfoMissingData(data, formData) {
    let technInfoMissingField = '';
    const dateOfInvoice = data.techInfo.dateOfInvoice;
    const dateOfRepair = data.techInfo.dateOfRepair;
    const user = this.azureService.roleName;

    if (this.service.getAdminsList().includes(user)) {
      if (this.hasEmptyValue(dateOfRepair)) {
        technInfoMissingField += 'Date of Repair, ';
      }
    } else {
      if (this.hasEmptyValue(dateOfInvoice) === false) {
        if (dateOfInvoice < formData.value.dispatchDate) {
          technInfoMissingField += 'Date of Invoice can not be prior Dispatch Date, ';
        }
        if (this.hasEmptyValue(dateOfRepair) === false) {
          if (dateOfRepair < formData.value.dispatchDate) {
            technInfoMissingField += 'Date of Repair can not be prior than Dispatch Date, ';
          }
        } else {
          technInfoMissingField += 'Date of Repair, ';
        }
      }
    }
    if (this.hasEmptyValue(data.techInfo.invoiceNumber)) {
      technInfoMissingField += 'Invoice Number, ';
    }
    if (this.hasEmptyValue(data.techInfo.serviceStatus)) {
      technInfoMissingField += 'Service Status, ';
    }
    if (this.hasEmptyValue(data.techInfo.numberOfVisits)) {
      technInfoMissingField += 'Number Of Visits, ';
    }
    return technInfoMissingField;
  }

  problemReportMissingData(data) {
    let problemReportMissingField = '';
    if (this.hasEmptyValue(data.problemReport.cause)) {
      problemReportMissingField += 'Cause Value, ';
    }
    if (this.hasEmptyValue(data.problemReport.correctiveAction)) {
      problemReportMissingField += 'Corrective Action Value, ';
    }
    if (this.hasEmptyValue(data.problemReport.correctiveActionNote)) {
      problemReportMissingField += 'Corrective Action Note, ';
    }
    if (this.hasEmptyValue(data.problemReport.causeNote)) {
      problemReportMissingField += 'Cause Note, ';
    }
    return problemReportMissingField;
  }

  correctiveActionValueMissing(data) {
    let correctiveActionValueMissing = '';
    if (this.hasEmptyValue(data.problemReport.correctiveAction)) {
      correctiveActionValueMissing += 'Corrective Action Value, ';
    }
    if (this.hasEmptyValue(data.problemReport.correctiveActionNote)) {
      correctiveActionValueMissing += 'Corrective Action Note, ';
    }
    return correctiveActionValueMissing;
  }

  correctiveActionErrorMessage() {
    if (this.serviceOrderDataService.serviceOrderDataModel.problemReport) {
      this.problemReportMissingField = this.correctiveActionValueMissing(
        this.serviceOrderDataService.serviceOrderDataModel,
      );
      this.problemReportMissingField = this.problemReportMissingField.slice(0, -2);
    } else {
      this.problemReportMissingField = 'Problem Report Information';
    }
    this.displayNoCorrectiveAction = true;
  }

  hasNoCorrectiveActionInfo(data) {
    return (
      !data ||
      (data &&
        (this.hasEmptyValue(data.correctiveAction) ||
          this.hasEmptyValue(data.correctiveActionNote)))
    );
  }

  hasDeniedCorrectiveActionInfo(data) {
    return (
      data &&
      [
        'MFG Coverage Denial',
        'Self-Servicing Dealer Denial',
        'Recall Claim Denial',
        'Terms & Conditions Denial',
      ].includes(data.correctiveAction)
    );
  }

  problemReportErrorMessage() {
    if (this.serviceOrderDataService.serviceOrderDataModel.problemReport) {
      this.problemReportMissingField = this.problemReportMissingData(
        this.serviceOrderDataService.serviceOrderDataModel,
      );
      this.problemReportMissingField = this.problemReportMissingField.slice(0, -2);
    } else {
      this.problemReportMissingField = 'Problem Report Information';
    }
    this.displayNoPRCauseAndCA = true;
  }

  techInfoErrorMessage() {
    if (this.serviceOrderDataService.serviceOrderDataModel.techInfo) {
      this.technInfoMissingField = this.techInfoMissingData(
        this.serviceOrderDataService.serviceOrderDataModel,
        this.form,
      );
    } else {
      this.technInfoMissingField = 'Technical Information, ';
    }
  }

  validateDateCriterias(data: any, updatedDispatchDate: any) {
    // Dates should be in below order to be functionally correct
    // Failure Date <= Reported Date <= Dispatch Date <= Repair Date <= Invoice Date
    // except Only admins can use a dispatch date which is
    const invoiceDate = moment(new Date()).format('YYYY-MM-DD'); // SUBMIT FOR PAYMENT defaults to today.
    const repairDate = data.techInfo.dateOfRepair ? data.techInfo.dateOfRepair.slice(0, 10) : 0;
    const dispatchDate = updatedDispatchDate ? updatedDispatchDate.slice(0, 10) : 0;
    const failureDate = data.claimData.problem.failureDate
      ? this.datePipe.transform(data.claimData.problem.failureDate, 'yyyy-MM-dd')
      : 0;
    const reportedDate = data.claimData.problem.reportedDate
      ? this.datePipe.transform(data.claimData.problem.reportedDate, 'yyyy-MM-dd')
      : 0;
    let errorMsg = '';

    if (dispatchDate) {
      if (failureDate > reportedDate) {
        errorMsg = 'Reported Date cannot be earlier than Failure Date';
      } else if (reportedDate > dispatchDate) {
        errorMsg = 'Dispatch Date cannot be earlier than Reported Date';
      } else if (
        !this.service.getAdminsList().includes(this.azureService.roleName) &&
        dispatchDate > repairDate
      ) {
        errorMsg = 'Repair Date cannot be earlier than Dispatch Date';
      } else if (repairDate > invoiceDate) {
        errorMsg = `[${invoiceDate}] Invoice Date (Current Date) cannot be earlier than Repair Date`;
      }
    } else {
      errorMsg = 'Dispatch date need to be set before submitting';
    }
    if (errorMsg) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: errorMsg,
      });
      return false;
    }
    return true;
  }

  onServicerBenchDirectSchedule(isSaveSO) {
    this.displayDirectServiceBench = false;
    if (isSaveSO) {
      if (
        this.serviceOrderDataService.serviceOrderDataModel &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData
      ) {
        this.dispatched = true;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Job re-scheduled successfully.`,
        });
        this.refreshClaimProfile();
      }
    }
  }

  onServicerPowerDirectSchedule(isSaveSO) {
    this.displayDirectServicePower = false;
    if (isSaveSO) {
      if (
        this.serviceOrderDataService.serviceOrderDataModel &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData
      ) {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Job re-scheduled successfully.`,
        });
        this.dispatched = true;
      }
    }
  }

  onRescheduleJob() {
    const { scheduleJobDetails, serviceBenchCommon } =
      this.serviceOrderDataService.serviceBenchModal;
    const scheduledDate = scheduleJobDetails && scheduleJobDetails.scheduledDate;
    const duration = moment.duration(moment(scheduledDate).diff(moment(new Date())));
    const hoursDiff = duration.asHours();
    const minHoursDiff = getValueByKey(serviceBenchCommon, 'MinHoursDiffRes');
    if (hoursDiff < parseInt(minHoursDiff, 10)) {
      this.displayInfoModal = true;
    } else {
      this.displayDirectServiceBench = true;
    }
  }

  onRescheduleCall() {
    const { spEntitledServicers, servicePowerCommon } =
      this.serviceOrderDataService.servicePowerModal;
    const { servicerInfo } = this.serviceOrderDataService.serviceOrderDataModel;
    // LG and GE group
    if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'LG')) {
      if (this.checkSPCanAndResLg()) {
        this.sbInfoTitle = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_TITLE_LG');
        this.sbInfoMessage = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_MESSAGE_LG');
        this.displayInfoModal = true;
      } else {
        this.displayDirectServicePower = true;
      }
    } else if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'GE')) {
      if (this.checkSPCanAndResGE()) {
        this.sbInfoTitle = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_TITLE_GE');
        this.sbInfoMessage = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_MESSAGE_GE');
        this.displayInfoModal = true;
      } else {
        this.displayDirectServicePower = true;
      }
    } else if (servicerInfo.servicerName === getValueByKey(spEntitledServicers, 'A&E')) {
      if (this.checkSPCanAndResAE()) {
        this.sbInfoTitle = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_TITLE_AE');
        this.sbInfoMessage = getValueByKey(servicePowerCommon, 'SERVICE_POWER_INFO_MESSAGE_AE');
        this.displayInfoModal = true;
      } else {
        this.displayDirectServicePower = true;
      }
    }
  }

  canDispatchSO(dispatchDate) {
    // You are not able to dispatch the SO if there is a dispatch date (already dispatched).
    if (dispatchDate) {
      return false;
    }
    // Certain statuses cannot dispatch.
    if (
      this.currentClaimStatus &&
      this.currentClaimStatus !== '' &&
      this.currentClaimStatus in cannotDispatchStatuses
    ) {
      return false;
    }
    return true;
  }

  lolCheck(dispatchFlag) {
    const response = {
      alertMessage: '',
      lolAmount: 0,
      overrideFlag: false,
    };
    const claimTechInfoInsCarrier =
      this.serviceOrderDataService.serviceOrderDataModel.techInfo &&
      this.serviceOrderDataService.serviceOrderDataModel.techInfo.insuranceCarrier;
    const contractInsuranceCarrier =
      this.serviceOrderDataService.serviceOrderDataModel.contractInfo.insurerName;
    const finalCarrier = claimTechInfoInsCarrier
      ? claimTechInfoInsCarrier
      : contractInsuranceCarrier;
    let lolAmount = 300; // Default limit
    if (
      this.serviceOrderDataService.serviceOrderDataModel.claimData &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
        .manufacturerManufacturer
    ) {
      const manufacturer =
        this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
          .manufacturerManufacturer;
      if (manufacturer && manufacturer250Amounts.includes(manufacturer.trim())) {
        lolAmount = 250;
      }
    }
    const uniqueId = this.serviceOrderDataService.serviceOrderDataModel.contractInfo.uniqueId;
    let excludeFlag = false;
    for (const singlePrefix of lolExcludeUniqueIdPrefixes) {
      if (uniqueId.indexOf(singlePrefix) === 0) {
        excludeFlag = true;
      }
    }
    this.payLoad = this.populateModel(this.form.getRawValue());
    const serviceType = this.payLoad.serviceType;
    const allowedServiceTypeFlag = lolAllowedServiceTypes.includes(serviceType) ? true : false;
    const form = this.form.getRawValue();
    const formClaimStatus = form.claimStatusDescription && form.claimStatusDescription.value;
    if (
      (dispatchFlag || this.currentClaimStatus !== formClaimStatus) &&
      !lolExcludeInsurance.includes(finalCarrier) &&
      !excludeFlag &&
      !allowedServiceTypeFlag &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
        .lolVirtualCalculatedFields &&
      (dispatchFlag ||
        this.payLoad.claimStatusDescription === 'PRE-APPROVED' ||
        this.payLoad.claimStatusDescription === 'PRE-APPROVED WITH MODIFICATIONS') &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
        .lolVirtualCalculatedFields.totalLiability > 0 &&
      this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
        .lolVirtualCalculatedFields.liabilityRemaining < lolAmount
    ) {
      response.lolAmount = lolAmount;
      const remainingLiability =
        this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
          .lolVirtualCalculatedFields.liabilityRemaining;
      let alertMessage = `<ul><li>LOL Remaining $[${remainingLiability}] < $${lolAmount}</li>`;
      if (!dispatchFlag) {
        alertMessage += `<li>You are unable to move Claim to PRE-APPROVED or PRE-APPROVED WITH MODIFICATIONS.</li>`;
      } else {
        alertMessage += `<li>Claim cannot be dispatched for service.</li>`;
      }
      alertMessage += `<li>Please proceed with Pending Review.</li></ul>`;
      let overrideFlag = false;
      if (
        this.roleService.validateIfPermissionsExist({
          category: 'Claim',
          permission: 'Create/Dispatch LOL Less Than Override',
          operation: 'ADD',
          type: 'Code',
        })
      ) {
        overrideFlag = true;
        response.overrideFlag = true;
      }
      response.alertMessage = alertMessage;
      return response;
    }
    return response;
  }

  saveLogic() {
    this.payLoad = this.populateModel(this.form.getRawValue());
    if (this.validateClaimData()) {
      const form = this.form.getRawValue();
      const formClaimStatus = form.claimStatusDescription && form.claimStatusDescription.value;
      const { scheduleJobDetails, noUpdateClaimStatusList, serviceBenchJobStatus } =
        this.serviceOrderDataService.serviceBenchModal;

      if (
        this.currentClaimStatus !== formClaimStatus &&
        formClaimStatus === 'REJECT SO' &&
        this.serviceOrderDataService.serviceOrderDataModel.creditCardInfo.companyName
      ) {
        const { creditCardInfo } = this.serviceOrderDataService.serviceOrderDataModel;
        const creditCardInfoInputOb = {
          outOfNetworkInfoIdOriginal: creditCardInfo.outOfNetworkInfoIdOriginal,
          serviceOrderNumber: creditCardInfo.serviceOrderNumber,
          insertUserName: this.azureService.accountId,
          isDeleted: true,
        };

        // Add Previous CC details to note
        const creditCardInfoNote = `Previous Dispatch Info: Reject SO Date: ${getMMDDYYYFormat(
          new Date(),
          'MM/DD/YYYY',
        )}, Service Provider: ${creditCardInfo.companyName}, Dispatch Method: ${
          creditCardInfo.dispatchMethod
          }(${
          creditCardInfo.dispatchMethod === 'Email' ? creditCardInfo.email : creditCardInfo.phone
          }), Point of Contact: ${creditCardInfo.poc}, Initial Diag Fee: $${
          creditCardInfo.initialFee
          }`;

        this.serviceOrderDataService.notesDataModel.type = NoteType.CreditCardInfo;
        this.serviceOrderDataService.notesDataModel.text = creditCardInfoNote;
        this.serviceOrderDataService.notesDataModel.enteredBy = this.azureService.fullName;
        this.serviceOrderDataService.newNote.next(true);

        // Reset values in the model data
        Object.keys(creditCardInfo).forEach((i) => (creditCardInfo[i] = null));
        creditCardInfo.dispatchMethod = 'Email';

        // Delete CreditCardInfo
        this.serviceOrderDataService
          .createUpdateCreditCardInfo(creditCardInfoInputOb)
          .subscribe(({}) => {
            this.service.isCreditCardServicerDispatch.next(false);
            this.serviceOrderDataService.isCreditCardInfoUpdated = false;
          });
      } else if (
        formClaimStatus === 'REJECT SO' &&
        formClaimStatus !== this.modelData.claimStatusDescription
      ) {
        this.service.isCreditCardServicerDispatch.next(false);
      }
      if (
        this.currentClaimStatus !== formClaimStatus &&
        getKeyByValue(noUpdateClaimStatusList, formClaimStatus) &&
        scheduleJobDetails !== null &&
        scheduleJobDetails.status !== null &&
        this.checkSBCanAndRes() &&
        scheduleJobDetails.status !== getValueByKey(serviceBenchJobStatus, 'Cancelled') &&
        scheduleJobDetails.status !==
        getValueByKey(serviceBenchJobStatus, 'Service Bench Cancelled') &&
        scheduleJobDetails.status !==
        getValueByKey(serviceBenchJobStatus, 'Service Bench Rejected') &&
        scheduleJobDetails.status !== getValueByKey(serviceBenchJobStatus, 'Expired') &&
        scheduleJobDetails.status !== getValueByKey(serviceBenchJobStatus, 'Completed')
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Sorry, you can not change claim status to ${formClaimStatus} [ ${scheduleJobDetails.status} ]`,
        });
      } else if (
        this.currentClaimStatus !== formClaimStatus &&
        formClaimStatus === 'REJECT SO' &&
        scheduleJobDetails &&
        scheduleJobDetails.status !== 'CAN' &&
        scheduleJobDetails.status !== 'SBCAN'
      ) {
        this.onCancelJob();
      } else if (
        this.currentClaimStatus !== formClaimStatus &&
        formClaimStatus === 'REJECT SO' &&
        this.isServicePowerDispatch
      ) {
        this.onCancelCall();
      } else {
        this.payLoad = this.populateModelAfterValidation(form);
        this.payLoad.claimIdOriginal = this.modelData && this.modelData.claimIdOriginal;
        // Require the Problem Report Cause if changing to 'PRE-APPROVAL REQUEST'
        if (
          this.payLoad.claimStatusDescription !== this.currentClaimStatus &&
          this.payLoad.claimStatusDescription === 'PRE-APPROVAL REQUEST' &&
          (!this.serviceOrderDataService.serviceOrderDataModel.problemReport ||
            (this.serviceOrderDataService.serviceOrderDataModel.problemReport &&
              !this.serviceOrderDataService.serviceOrderDataModel.problemReport.cause))
        ) {
          this.displayNoPRCause = true;
          return;
        }

        // FROM DENIED TO OTHER
        if (
          this.currentClaimStatus === 'DENIED' &&
          this.payLoad.claimStatusDescription !== this.currentClaimStatus
        ) {
          const { problemReport } = this.serviceOrderDataService.serviceOrderDataModel;
          if (this.hasDeniedCorrectiveActionInfo(problemReport)) {
            this.serviceOrderDataService.requestedOperation = {
              make_empty: true,
              isDenied: false,
            };
            this.serviceOrderDataService.isClaimStatusChanged.next(true);
            const { make_empty, isDenied } = this.serviceOrderDataService.requestedOperation;
            if (this.payLoad.claimStatusDescription === 'SUBMIT FOR PAYMENT') {
              this.problemReportErrorMessage();
              return;
            }
          } else {
            this.serviceOrderDataService.requestedOperation = {
              make_empty: false,
              isDenied: false,
            };
            this.serviceOrderDataService.isClaimStatusChanged.next(true);
          }
        }

        // Require the Problem Report Cause, Corrective, Cause Note and Corrective Action Note if changing to 'SUBMIT FOR PAYMENT'
        if (
          this.payLoad.claimStatusDescription !== this.currentClaimStatus &&
          (this.payLoad.claimStatusDescription === 'SUBMIT FOR PAYMENT' ||
            this.payLoad.claimStatusDescription === 'APPROVED FOR PAYMENT') &&
          this.hasNotProblemReportInfoForpayment(this.serviceOrderDataService.serviceOrderDataModel)
        ) {
          this.problemReportErrorMessage();
          return;
        }

        // MOVING TO DENIED FROM OTHER STATUS OR ON DENIED
        if (
          (this.payLoad.claimStatusDescription !== this.currentClaimStatus &&
            this.payLoad.claimStatusDescription === 'DENIED') ||
          this.payLoad.claimStatusDescription === 'DENIED'
        ) {
          const { problemReport } = this.serviceOrderDataService.serviceOrderDataModel;
          if (!this.hasDeniedCorrectiveActionInfo(problemReport)) {
            this.displayNoCorrectiveActionConfirm = true;
            this.serviceOrderDataService.requestedOperation = {
              make_empty: false,
              isDenied: true,
            };
            const { make_empty, isDenied } = this.serviceOrderDataService.requestedOperation;
            console.log(`CR::SO-TO-DENIED:: ${make_empty} ${isDenied}`);
            this.serviceOrderDataService.isClaimStatusChanged.next(true);
            return;
          }
        }

        // When changing to 'SUBMIT FOR PAYMENT', need a confirmation of the approved amount before saving and
        // Require the Invoice Number, Service Status, Number of visits and date of invoice & date of Reapair
        // should not be earlier than date of dispatch
        if (
          this.payLoad.claimStatusDescription !== this.currentClaimStatus &&
          this.payLoad.claimStatusDescription === 'SUBMIT FOR PAYMENT'
        ) {
          if (
            !this.serviceOrderDataService.serviceOrderDataModel.techInfo ||
            this.hasNotTechnicalInfoForPayment(
              this.serviceOrderDataService.serviceOrderDataModel,
              this.form,
            )
          ) {
            this.techInfoErrorMessage();
            this.technInfoMissingField = this.technInfoMissingField.slice(0, -2);
            if (this.technInfoMissingField.length > 0) {
              this.displayNoTechInfo = true;
            }
          } else if (
            this.validateDateCriterias(
              this.serviceOrderDataService.serviceOrderDataModel,
              this.payLoad.dispatchDate,
            )
          ) {
            const totalRequestedAmount =
              this.serviceOrderDataService.serviceOrderDataModel.expenseCharges
                .reduce((a, b) => a + (parseFloat(b.approvedAmount) || 0), 0)
                .toFixed(2);
            let alertMessage =
              'Alert: Please make sure that the total amount is the correct amount that you would like ';
            alertMessage += `to submit for payment/preapproval. <br><br>Is $${totalRequestedAmount} the correct amount?`;
            this.confirmationService.confirm({
              header: 'Submit for Payment Alert',
              message: alertMessage,
              accept: () => {
                this.saveClaim();
              },
            });
          }
        } else if (
          this.payLoad.claimStatusDescription !== this.currentClaimStatus &&
          this.payLoad.claimStatusDescription === 'APPROVED FOR PAYMENT'
        ) {
          if (
            !this.serviceOrderDataService.serviceOrderDataModel.techInfo ||
            this.hasNotTechnicalInfoForPayment(
              this.serviceOrderDataService.serviceOrderDataModel,
              this.form,
            ) ||
            this.hasEmptyValue(
              this.serviceOrderDataService.serviceOrderDataModel.techInfo.insuranceCarrier,
            )
          ) {
            this.techInfoErrorMessage();
            this.technInfoMissingField = this.technInfoMissingField.slice(0, -2);
            if (
              this.serviceOrderDataService.serviceOrderDataModel.techInfo &&
              this.hasEmptyValue(
                this.serviceOrderDataService.serviceOrderDataModel.techInfo.insuranceCarrier,
              )
            ) {
              this.technInfoMissingField += ' Insurance Carrier';
            }
            this.displayNoTechInfo = true;
          } else {
            this.saveClaim();
          }
        } else {
          this.saveClaim();
        }
      }
    }
  }

  proceedWithDispatch() {
    const { goodwillReason } = this.serviceOrderDataService.serviceOrderDataModel.claimData;
    const { symptom } = this.serviceOrderDataService.serviceOrderDataModel.claimData.problem;
    if (
      this.isContractExpired &&
      (symptom === '50% Back' || goodwillReason === '50% back Program')
    ) {
      const { expenseCharges } = this.serviceOrderDataService.serviceOrderDataModel;
      if (
        expenseCharges &&
        expenseCharges.filter((el) => el.expenseType === 'Store Credit').length === 0
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please add an Expense Type Store Credit',
        });
        return false;
      }
    }
    const productLocation = this.serviceOrderDataService.serviceOrderDataModel.location;
    if (
      !productLocation ||
      !productLocation.zipCode ||
      !productLocation.state ||
      productLocation.zipCode === '' ||
      productLocation.state === ''
    ) {
      window.alert('Production Location Zip Code and State are required.');
    } else {
      this.serviceOrderDataService.productLocationInfo.next(productLocation);
      this.displayDispatch = true;
    }
    return true;
  }

  checkGoodWill(newStatus) {
    if (
      ((this.currentClaimStatus !== newStatus && !this.currentClaimStatus) || this.subClaim) &&
      newStatus === 'DRAFT' &&
      this.isContractExpired &&
      !this.serviceOrderDataService.serviceOrderDataModel.goodwillReason
    ) {
      return true;
    }
    return false;
  }

  checkStatus() {
    this.serviceOrderDataService.isServiceOrderInfoAvailable.subscribe({
      next: (isServiceOrderInfoAvailable) => {
        this.statusAvailable = isServiceOrderInfoAvailable;
        // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
        // No user can edit the details anymore(PD-907057)
        if (
          this.statusAvailable &&
          this.serviceOrderDataService.serviceOrderDataModel.serviceOrderInfo &&
          this.service
            .getServicerProhibitedstatuses()
            .includes(this.serviceOrderDataService.serviceOrderDataModel.serviceOrderInfo.status)
        ) {
          this.servicerEditFlag = false;
        } else {
          this.servicerEditFlag = true;
        }
        this.serviceOrderDataService.isServiceOrderEditEnable.next(this.servicerEditFlag);
      },
    });
  }
  getStateNameByCode(stateCode) {
    const optionsState =
      this.dropdownDataService.stateList &&
      this.dropdownDataService.stateList.find((x) => x.stateCode === stateCode);
    if (optionsState) {
      return optionsState.stateName;
    }
    return null;
  }

  onCorrectiveAction(updated) {
    if (this.displayNoCorrectiveActionConfirm) {
      this.displayNoCorrectiveActionConfirm = false;
      if (updated) {
        this.onSubmit();
      } else {
        this.serviceOrderDataService.requestedOperation = {
          make_empty: false,
          isDenied: false,
        };
        this.serviceOrderDataService.isClaimStatusChanged.next(true);
        this.form.patchValue({
          claimStatusDescription: {
            key: this.modelData.claimStatusDescription,
            value: this.modelData.claimStatusDescription,
          },
        });
      }
    }
  }

  onLolModalChange(event) {
    if (!event) {
      this.displayLolModal = false;
    }
  }
}