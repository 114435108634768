import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisCrudOptions,
  ellipsisSubHeaderOptionsWithExport,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, LazyLoadEvent, SelectItem } from 'primeng/api';
import { ServicerServiceArea } from './service-area.service';
import { buttonStatus } from '../../../shared/constants/button-class';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { CrmDocumentType, ModuleType } from '../../../shared/constants/enums';
import { ServicerService } from '../servicer.service';
import { MessageService } from 'primeng/api';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { CrmService } from '../../../services/crm-service';
import { groupBy } from '../../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'service-area-tab',
  templateUrl: 'service-area.template.html',
  styleUrls: ['servicer-service-area.scss'],
  providers: [ServicerServiceArea, MessageService, FormFieldControlService],
})
export class ServicerServiceAreaComponent implements OnInit {
  @Input() formFields: FormField<any>[] = [];
  form: FormGroup;
  dataList: any[] = [];
  totalRecords: number;
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = ellipsisSubHeaderOptionsWithExport;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  documentType = CrmDocumentType;
  attachmentId: any;
  displaySADialog: boolean;
  isLoading = false;
  isAdd = false;
  querySubscription: Subscription;
  lastOffsetVal = 0;
  offsetLimit = 1000;
  resultCount = 0;
  modelNumbers = [];
  tabKey = 'service_area';
  items: SelectItem[];
  item: string;
  searchParams = {
    limit: this.offsetLimit,
    offset: this.lastOffsetVal,
    servicersId: null,
    zipCode: '',
  };
  loadingTable = false;
  currentLazyLoadEvent: LazyLoadEvent;
  zipCodes = [];
  displayExport: boolean;
  pdfTableOptions = {};
  serviceAreaDbList = [];
  @ViewChild('dt') dataTableRef;
  constructor(
    private servicerServiceArea: ServicerServiceArea,
    private confirmationService: ConfirmationService,
    private servicerService: ServicerService,
    private messageService: MessageService,
    private qcs: FormFieldControlService,
    private tabHandlerService: TabHandlerService,
    private azureService: AzureLoginService,
    private crmService: CrmService,
    private rps: RolePermissionService,
  ) {
    this.servicerServiceArea.getServiceAreaFields().subscribe((data) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.attachmentId = null;
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.servicerService.serviceFormData) {
      this.attachmentId = this.servicerService.serviceFormData.servicerProfilesIdOriginal;
    }
    const editPermissionList = [
      'Admin',
      'Claims Manager',
      'Special Team A-CC-SN',
      'Call Center Manager',
    ];
    const addPermissionList = [
      'Admin',
      'Claims Manager',
      'Special Team A-CC-SN',
      'Call Center Manager',
    ];
    const deletePermissionList = ['Admin', 'Claims Manager', 'Special Team A-CC-SN'];
    if (!editPermissionList.includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((el) => el.value !== 'edit');
    }
    if (!addPermissionList.includes(this.azureService.roleName)) {
      this.ellipsisHeaderOptions = this.ellipsisHeaderOptions.filter((el) => el.value !== 'add');
    }

    if (!deletePermissionList.includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((el) => el.value !== 'delete');
    }

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.searchParams.servicersId = this.servicerService.selectedServicerProfileId;
    this.searchParams.zipCode = '';
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'manufacturerName', header: 'Manufacturer Name', type: 'text' },
      { field: 'modelNumber', header: 'Model Number', type: 'text' },
      { field: 'zipCode', header: 'Zip Code', type: 'text' },
      { field: 'radius', header: 'Radius', type: 'text' },
    ];

    this.selectedColumns = this.cols;
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.getServicerServiceArea('');
        }
      },
    });

    this.doEvaluateRolePermissions();
  }
  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Insurance',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Service Area',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getZipSearch(event) {
    const userInput = event.target.value;
    if (userInput && userInput.length > 3) {
      this.getServicerServiceArea(userInput);
    }
  }

  onChange(event) {
    const manufacturer = event.value;
    this.getModelNumbersByMfg(manufacturer.value, true);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  getServicerServiceArea(zipCode: string) {
    this.isLoading = true;
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.zipCode = zipCode;
    this.searchParams.offset = 0;
    this.searchParams.servicersId = this.servicerService.selectedServicerProfileId;
    this.querySubscription = this.servicerService
      .getServicerServiceArea(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = false;
          const modifedData = data.getServicerServiceArea;
          this.serviceAreaDbList = data.getServicerServiceArea;
          const groupByZipAndMfg = groupBy(['zipCode', 'manufacturerName']);
          const groupedData = modifedData ? groupByZipAndMfg(modifedData) : null;
          const saList = [];
          if (groupedData && Object.keys(groupedData).length > 0) {
            Object.keys(groupedData).forEach((key) => {
              let sa = {
                manufacturerName: groupedData[key][0].manufacturerName,
                radius: groupedData[key][0].radius,
                zipCode: groupedData[key][0].zipCode,
                servicersId: groupedData[key][0].servicersId,
                modelNumber: null,
              };
              let modelNumbers = '';
              groupedData[key].forEach((el, i) => {
                if (i < groupedData[key].length - 1) {
                  modelNumbers = modelNumbers + el.modelNumber.concat(' | ');
                } else {
                  modelNumbers = modelNumbers + el.modelNumber;
                }
              });
              sa = { ...sa, modelNumber: modelNumbers };
              saList.push(sa);
            });

            this.dataList = [...saList];
          }
          if (modifedData && modifedData.length) {
            const zipCodesArr = this.dataList.map((el) => el.zipCode);
            const uniqueZipCodes = zipCodesArr.filter((x, i, a) => a.indexOf(x) === i);
            this.zipCodes = uniqueZipCodes.map((el) => {
              return {
                label: el,
                value: el,
              };
            });
            this.resultCount = this.dataList.length;
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  getModelNumbersByMfg(manufacturerName, isChangeEvent = false) {
    this.isLoading = true;
    this.servicerServiceArea.getModelNumbersByMfg(manufacturerName).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.servicerService.isLoading.next(false);
        this.modelNumbers = data.getServicerServiceAreaModelNumber;
        let modelOptions =
          this.modelNumbers && this.modelNumbers.length > 0
            ? this.modelNumbers.map((el) => {
                return {
                  key: el,
                  value: el,
                };
              })
            : [];
        modelOptions = [{ key: 'ALL', value: 'ALL' }, ...modelOptions];
        this.formFields[1].options = modelOptions;
        if (!isChangeEvent) {
          const modelList = this.selectedItem.modelNumber.split('|');
          const modelSelectedList = modelList.map((e) => ({
            key: e.trim(),
            value: e.trim(),
          }));
          this.form.patchValue({
            ...this.selectedItem,
            manufacturerName: {
              key: this.selectedItem.manufacturerName,
              value: this.selectedItem.manufacturerName,
            },
            modelNumber: modelSelectedList,
          });
        } else {
          this.form.patchValue({
            ...this.selectedItem,
            manufacturerName: { key: manufacturerName, value: manufacturerName },
            modelNumber: [],
          });
        }
        this.displaySADialog = true;
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }
  onRowEditInit(event) {}
  onRowEditSave(event) {}
  onRowEditCancel(event, index) {}

  onRowEdit(event, element) {
    element.hide();
    this.formFields[0].disabled = true;
    this.formFields[2].disabled = true;
    this.form = this.qcs.toFormGroup(this.formFields);
    this.isAdd = false;
    this.isLoading = true;
    this.getModelNumbersByMfg(this.selectedItem.manufacturerName);
  }

  onClickAdd(event, element) {
    element.hide();
    this.isAdd = true;
    this.formFields[0].disabled = false;
    this.formFields[2].disabled = false;
    this.form = this.qcs.toFormGroup(this.formFields);
    this.displaySADialog = true;
  }

  onRowDelete(servicersServiceAreasIdOriginal = null) {
    let servicersServiceAreasId = servicersServiceAreasIdOriginal;
    if (this.selectedItem) {
      const serviceAreaItem = this.serviceAreaDbList.find(
        (el) =>
          el.zipCode === this.selectedItem.zipCode &&
          el.manufacturerName === this.selectedItem.manufacturerName &&
          el.servicersId === this.selectedItem.servicersId,
      );
      servicersServiceAreasId = serviceAreaItem.servicersServiceAreasIdOriginal;
    }
    const payload = {
      idOriginal: servicersServiceAreasId,
      moduleName: ModuleType.SERVICEAREA,
    };
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this Service Area ?',
      accept: () => {
        this.isLoading = true;
        this.crmService.deleteCrmModule(payload).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            if (!data.deleteCrmModule) {
              this.isLoading = false;
              throw new Error('Error in deleting the Service Area.');
            }
            this.getServicerServiceArea('');
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
      },
    });
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    const serviceAreas = payload.modelNumber.map((mn) => {
      return {
        manufacturerName: payload.manufacturerName.value,
        modelNumber: mn.value,
        radius: payload.radius ? parseInt(payload.radius, 10) : 0,
        zipCode: payload.zipCode,
        servicersId: this.servicerService.selectedServicerProfileId,
        insertUserName: this.azureService.accountId,
      };
    });
    this.isLoading = true;
    if (this.serviceAreaValidations(serviceAreas, this.dataList)) {
      this.servicerService.createUpdateServicerServiceArea(serviceAreas).subscribe((res) => {
        this.isLoading = false;
        this.displaySADialog = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Record Saved Successfully.`,
        });
        this.getServicerServiceArea('');
      });
    } else {
      this.isLoading = false;
    }
  }

  onCancel() {
    this.displaySADialog = false;
  }

  serviceAreaValidations(serviceAreas, dataList = []) {
    const manufacturerName = serviceAreas[0].manufacturerName;
    const zipCode = serviceAreas[0].zipCode;
    if (this.isAdd) {
      // service area table already having entry for this zipcode (ALL)
      if (manufacturerName === 'ALL' && dataList.some((el) => el.zipCode === zipCode)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `There is already an entry for this zip code`,
        });
        return false;
      }
      // check zip code and manufacturer already exists
      if (
        dataList.some((el) => el.manufacturerName === manufacturerName && el.zipCode === zipCode)
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `There is already an entry for this zip code`,
        });
        return false;
      }
      // check ALL is selected for the manufacturer name and zipcode
      if (dataList.some((el) => el.manufacturerName === 'ALL' && el.zipCode === zipCode)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `There is already an entry for this zip code`,
        });
        return false;
      }
    }
    // check model numbers both ALL and other values
    if (serviceAreas.length > 1 && serviceAreas.some((el) => el.modelNumber === 'ALL')) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please select either ALL or set of model numbers`,
      });
      return false;
    }
    return true;
  }

  onClickExport(event, opHeader) {
    this.exportToCsv(opHeader);
  }

  exportToCsv(opHeader) {
    this.dataTableRef.value = this.dataList;
    this.dataTableRef.exportCSV();
    opHeader.hide();
  }

  // Need this because user can select different columns to view.
  private setTableWidths() {
    let options = {};
    const columnStyles = {};
    for (const index in this.selectedColumns) {
      if (index) {
        const headerName = this.selectedColumns[index].header;
        if (headerName === 'Manufacturer Name') {
          columnStyles[index] = { cellWidth: 6 };
        }
        if (headerName === 'Model Number') {
          columnStyles[index] = { cellWidth: 6 };
        }
        if (headerName === 'Zip Code') {
          columnStyles[index] = { cellWidth: 4 };
        }
      }
    }
    options = {
      columnStyles,
    };
    return options;
  }
}
