import {Injectable} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class ErrorHandlerService {
    errorNotification = new Subject<any>();
    constructor() {
    }
}
