<div class="generic-form" [formGroup]="form">
  <label class="generic-form__label" *ngIf="formField.label" [attr.for]="formField.key"> {{formField.label}} </label>
  <span class="required-mark" *ngIf="formField.required">*</span>
  <div class="generic-form__input" [ngSwitch]="formField.controlType">
    <ng-container *ngSwitchCase="'richtextarea'">
      <quill-editor [formControlName]="formField.key" format="html"></quill-editor>
    </ng-container>
    <ng-container *ngSwitchCase="'textbox'">
      <input *ngIf="!formField.isChangeEvent && !formField.isPDate" [formControlName]="formField.key"
        [id]="formField.key" [type]="formField.type" [pattern]="formField.validationRegex"
        [maxLength]="formField.maxLength" />
      <input *ngIf="formField.isChangeEvent && !formField.isPDate" [formControlName]="formField.key"
        [id]="formField.key" [type]="formField.type" [pattern]="formField.validationRegex"
        [maxLength]="formField.maxLength" (focusout)="changeEventDetection($event)" />
      <p-calendar *ngIf="formField.isPDate" [formControlName]="formField.key" [minDate]="formField.minDate"
        [maxDate]="formField.maxDate" [selectionMode]="formField.selectionMode" [readonlyInput]="true"
        [disabled]="formField.disabled" [yearNavigator]="true" [monthNavigator]="true" [yearRange]="yearRange"
        (onSelect)="changeEventDetection($event)">
      </p-calendar>
    </ng-container>
    <ng-container *ngSwitchCase="'dropdown'">
      <p-multiSelect *ngIf="!formField.isSingleselect" [id]="formField.key" [options]="formField.options"
        optionLabel="key" selectedItemsLabel="{0} items selected" defaultLabel="Choose Columns"
        [formControlName]="formField.key"  [virtualScroll]="formField.virtualScroll" (onChange)="changeEventDetection($event)"
        [virtualScrollItemSize]="30"
        [showToggleAll]="formField.showToggleAll">
      </p-multiSelect>
      <p-dropdown *ngIf="formField.isSingleselect && !formField.isChangeEvent" [id]="formField.key"
        [options]="formField.options" optionLabel="key" placeholder="Choose" [formControlName]="formField.key"
        [filter]="formField.filter" [virtualScroll]="formField.virtualScroll" [virtualScrollItemSize]="30"
        appendTo="body">
      </p-dropdown>
      <p-dropdown *ngIf="formField.isSingleselect && formField.isChangeEvent" [id]="formField.key"
        [options]="formField.options" optionLabel="key" placeholder="Choose" [formControlName]="formField.key"
        [filter]="formField.filter" (onChange)="changeEventDetection($event)" [virtualScroll]="formField.virtualScroll"
        [virtualScrollItemSize]="30" appendTo="body">
      </p-dropdown>
    </ng-container>
    <ng-container *ngSwitchCase="'autocomplete'">
      <ng-autocomplete #ngAutoCompleteStatic [data]="suggestions" [formControlName]="formField.key" [initialValue]=""
        [searchKeyword]="keyword" historyIdentifier="formField.suggestions" [historyListMaxNumber]="3"
        notFoundText="Not found" [itemTemplate]="itemTemplateStatic" [minQueryLength]="2" placeholder="Type to search"
        (inputChanged)="onChangeSearch($event)" [notFoundTemplate]="notFoundTemplate">
      </ng-autocomplete>
      <ng-template #itemTemplateStatic let-item="item">
        <a [innerHTML]="item[keyword]"></a>
      </ng-template>
      <ng-template #notFoundTemplate let-notFound="notFound">
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </ng-container>
    <div class="checkbox" *ngSwitchCase="'checkbox'">
      <p-checkbox [formControlName]="item.key" [disabled]="formField.disabled" *ngFor="let item of formField.options"
        [label]="item.value" [value]="item.value" (onChange)="changeEventDetection($event)">
      </p-checkbox>
    </div>
    <div class="ui-inputgroup" *ngSwitchCase="'textboxsearch'">
      <input [formControlName]="formField.key" [id]="formField.key" [type]="formField.type" /><img
        [src]="'assets/search.svg'" />
    </div>
    <textarea *ngSwitchCase="'textarea'" [formControlName]="formField.key" [id]="formField.key" [rows]="formField.rows"
      [cols]="formField.cols" [maxLength]="formField.maxLength">
    </textarea>
    <div class="radio-button" *ngSwitchCase="'radio-button'">
      <p-radioButton class="radio-instance" [formControlName]="formField.key" [name]="formField.key"
        *ngFor="let item of formField.options" [label]="item.value" [value]="item.key">
      </p-radioButton>
    </div>
  </div>
  <div class="errorMessage"
    *ngIf="form.controls[formField.key] && !form.controls[formField.key].valid && form.controls[formField.key].errors && (form.controls[formField.key].dirty || form.controls[formField.key].touched)">
    <div *ngIf="form.controls[formField.key].errors.pattern">{{formField.validationMessage}}</div>
    <div *ngIf="form.controls[formField.key].errors.required">{{formField.label}} is required</div>
    <div
      *ngIf="form.controls[formField.key].errors && !form.controls[formField.key].errors.required && !form.controls[formField.key].errors.pattern">
      {{getErrorMessages(formField.errorMessages,form.controls[formField.key].errors.key)}}
    </div>
  </div>
</div>