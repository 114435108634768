import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { TextboxSearchField } from '../shared/form-field/textbox-search';
import { of } from 'rxjs';
import { DropdownDataService } from './dropdown.data.service';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import gql from 'graphql-tag';
import { ZipCodePlus4Pattern } from '../shared/constants/validation-regex';

const getElegiblePriceTablesQuery = gql`
  query($number: String!, $groupType: String) {
    getElegiblePriceTables(number: $number, groupType: $groupType)
  }
`;
@Injectable({ providedIn: 'root' })
export class BuyingGroupService {
  constructor(
    private http: HttpClient,
    private dropdownDataService: DropdownDataService,
    private apollo: Apollo
  ) {}
  // TODO: Integrate with remote data
  getBuyingGroup() {
    return [
      {
        master_buying_group: true,
        status: 'CANCELLED',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: ''
      },
      {
        master_buying_group: true,
        status: 'CANCELLED',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      }
    ];
  }
  getBuyingGroupDetailsFormFields() {
    const optionsBuyingGroup = this.dropdownDataService.masterBuyingGroupData
      ? this.dropdownDataService.masterBuyingGroupData.map(x => ({
          key: x.name,
          value: x.masterBuyingGroupIDOriginal
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'A' },
          { key: 'Cancelled', value: 'C' },
          { key: 'Expired ', value: 'E ' },
          { key: 'Pending', value: 'P' }
        ],
        isSingleselect: true,
        order: 1
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2,
        isChangeEvent: true,
        errorMessages: [
          { key: 'duplicateBGNumber', value: 'This number is already present in the system' }
        ],
        required: true
      }),
      new TextboxSearchField({
        key: 'fedTaxId',
        label: 'Fed Tax ID',
        order: 3
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 4,
        required: true
      }),
      new DropdownField({
        key: 'masterBuyingGroup',
        label: 'Master Buying Group',
        options: optionsBuyingGroup,
        isSingleselect: true,
        order: 5
      }),
      new DropdownField({
        key: 'rateProfile_',
        label: 'Rates Profile',
        options: [],
        order: 6
      }),
      new TextboxField({
        key: 'activationDate',
        label: 'Contract Date',
        type: 'Date',
        order: 7,
        required: true
      }),
      new TextboxField({
        key: 'expirationDate',
        label: 'Contract Exp Date',
        type: 'Date',
        order: 8
      }),
      new TextboxField({
        key: 'effectiveBasedOn_',
        label: 'Effective Based On',
        order: 9
      }),
      new DropdownField({
        key: 'priceTableName',
        label: 'Price table',
        isSingleselect: true,
        options: [],
        order: 10
      }),
      new TextboxField({
        key: 'insuranceCarrierCode_',
        label: 'Insurance Carrier Code',
        order: 11
      }),
      new TextboxField({
        key: 'insuranceCarrierName_',
        label: 'Insurance Carrier Name',
        order: 12
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContactInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'Contact firstName',
        label: 'Contact First Name',
        order: 1
      }),

      new TextboxField({
        key: 'Contact lastName',
        label: 'Contact Last Name',
        order: 2
      }),

      new TextboxField({
        key: 'BusinessPhone',
        label: 'Business Phone',
        required: true,
        order: 3
      }),

      new DropdownField({
        key: 'businessPhoneTypeCode',
        label: 'Business Phone Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        required: true,
        order: 4
      }),

      new TextboxField({
        key: 'fax',
        label: 'Fax',
        order: 5
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 6
      }),
      new CheckBoxField({
        key: 'masterbuyingGroup',
        label: '',
        order: 7,
        type: 'checkbox',
        options: [{ key: 'Primary Contact', value: 'Primary Contact' }]
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getMailingBillingAddressFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'country',
        label: 'Country',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),
      new TextboxField({
        key: 'addressLine1',
        label: 'Address Line 1',
        order: 2
      }),
      new TextboxField({
        key: 'addressLine2',
        label: 'Address Line 2',
        order: 3
      }),
      new TextboxField({
        key: 'zip',
        label: 'Zip',
        validationRegex: ZipCodePlus4Pattern,
        validationMessage: 'Please enter a valid zip code with 5 digits.',
        order: 4
      }),
      new TextboxField({
        key: 'state',
        label: 'State',
        order: 5
      }),
      new TextboxField({
        key: 'city',
        label: 'City',
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContactTabInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'Primary Contact firstName',
        label: 'Primary Contact First Name',
        order: 1
      }),
      new TextboxField({
        key: 'Primary Contact lastName',
        label: 'Primary Contact Last Name',
        order: 2
      }),
      new TextboxField({
        key: 'Business phone',
        label: 'Business Phone',
        order: 3
      }),
      new TextboxField({
        key: 'fax',
        label: 'Fax',
        order: 4
      }),
      new TextboxField({
        key: 'email-address',
        label: 'Email Address',
        order: 5
      }),
      new TextboxField({
        key: 'cellphone',
        label: 'CellPhone',
        order: 5
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getPositionInfoFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'position',
        label: 'Position',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getElegiblePriceTables = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getElegiblePriceTablesQuery,
      variables: { number: searchParams.number, groupType: searchParams.groupType },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
}
