import { Injectable } from '@angular/core';

import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { TextboxSearchField } from '../shared/form-field/textbox-search';
import { RadioButtonField } from '../shared/form-field/radio-button-field';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { TextAreaField } from '../shared/form-field/textarea-field';

@Injectable()
export class CatalogFormFieldService {
  // TODO: get from a remote source of formField metadata

  getSalesRepresentativeFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'retailer',
        label: 'Retailer',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),

      new DropdownField({
        key: 'store',
        label: 'Store',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 2
      }),

      new TextboxField({
        key: 'firstName',
        label: 'First Name',
        order: 3
      }),

      new TextboxField({
        key: 'lastName',
        label: 'Last Name',
        order: 4
      }),

      new DropdownField({
        key: 'active',
        label: 'Active',
        isSingleselect: true,
        options: [{ key: 'true', value: 'true' }, { key: 'false', value: 'false' }],
        order: 5
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getWorkQueFormFields() {
    const formFields: FormField<string>[] = [
      new CheckBoxField({
        key: 'newLeafEmployee',
        options: [{ key: 'newLeafEmployee', value: 'New Leaf Employee' }],
        order: 1
      }),

      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 2
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
