import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { buttonStatus } from '../constants/button-class';
import { KeyMap } from '../interface/key-map.interface';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
  ellipsisCrudOptions,
  ellipsisSubHeaderOptionsWithExport,
} from '../constants/ellipsis-options';
import { dateTimeFormatterCST } from '../utilities/date-utilities';

import { HistoryItemsService } from './history-item-modal.service';
@Component({
  selector: 'history-item-modal',
  templateUrl: 'history-item-modal.template.html',
  styleUrls: ['history-item-modal.scss'],
  providers: [MessageService, HistoryItemsService],
})
export class HistoryItemModalComponent implements OnInit {
  private privateDisplayDialog: boolean;
  @Input() formData: any;
  @Input() modalTitle: string;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  @Output() displayDialogChange = new EventEmitter();

  form: FormGroup;
  dataList: any[] = [];
  totalRecords: number;
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = ellipsisSubHeaderOptionsWithExport;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  isLoading: boolean;
  isLoadMore = false;
  querySubscription: Subscription;
  item: string;
  loadingTable = false;
  @ViewChild('dt') dataTableRef;
  constructor(private historyService: HistoryItemsService) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.loadHistory();
  }

  ellipsisClick(event, item, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
  }

  loadHistory() {
    this.loadingTable = true;
    this.formData.offset = 0;
    this.formData.limit = 100;
    this.historyService.getHistoryItems(this.formData).subscribe(({ data }) => {
      this.loadingTable = false;
      this.dataList = [];
      if (data.getHistoryItems) {
        const { header, dataValues, uiLabels, dataTypes } = data.getHistoryItems;
        this.cols = header.map((el, rowIndex) => {
          return {
            field: el,
            header: uiLabels ? uiLabels[rowIndex].toUpperCase() : el.toUpperCase(),
            type: 'text',
            dataType: dataTypes ? dataTypes[rowIndex] : 'text',
          };
        });
        this.selectedColumns = this.cols;
        if (this.cols.length > 10) {
          this.selectedColumns = this.cols.slice(0, 8);
        }
        const temp = dataValues.map((el) => {
          return el.map((x, rowIndex) => {
            if (x === 'true' && this.cols[rowIndex].field === 'servicerNotification') {
              return {
                text: x ? 'Yes' : 'No',
                field: this.cols[rowIndex].field,
                isChange: false,
                dataType: this.cols[rowIndex].dataType,
              };
            } else if (x === 'false' && this.cols[rowIndex].field === 'servicerNotification') {
              return {
                text: x ? 'No' : 'Yes',
                field: this.cols[rowIndex].field,
                isChange: false,
                dataType: this.cols[rowIndex].dataType,
              };
            } else if (x === 'true' && this.cols[rowIndex].field === 'closed') {
              return {
                text: x ? 'Closed' : 'Open',
                field: this.cols[rowIndex].field,
                isChange: false,
                dataType: this.cols[rowIndex].dataType,
              };
            } else if (x === 'false' && this.cols[rowIndex].field === 'closed') {
              return {
                text: x ? 'Open' : 'Closed',
                field: this.cols[rowIndex].field,
                isChange: false,
                dataType: this.cols[rowIndex].dataType,
              };
            } else {
              return {
                text: x,
                field: this.cols[rowIndex].field,
                isChange: false,
                dataType: this.cols[rowIndex].dataType,
              };
            }
          });
        });
        const finalList = [];
        for (let i = temp.length - 1; i >= 0; i--) {
          const record = [];
          if (i !== temp.length - 1) {
            let iIndex = 0;
            for (const ii of temp[i]) {
              if (ii.text !== temp[i + 1][iIndex].text) {
                ii.isChange = true;
              }
              record.push(ii);
              iIndex += 1;
            }
          } else {
            for (const ii of temp[i]) {
              record.push(ii);
            }
          }
          finalList.push(this.transformArrayToObject(record));
        }
        this.dataList = [...finalList.reverse()];
      }
    });
  }

  transformArrayToObject(data) {
    const result = {};
    for (const item of data) {
      result[item.field] = Object.assign({}, item);
    }
    return result;
  }
}
