import { SYSTEM_USER_ROLES } from './user-roles';

export const manufacturer250Amounts = [
  'ADMIRAL',
  'AMANA',
  'GE CAF+',
  'GE CAFE',
  'GE MONOGRAM',
  'GENERAL ELECTRIC',
  'GENERAL ELECTRIC COMPANY',
  'GE PROFILE',
  'JENNAIR',
  'JENN AIR',
  'KITCHENAID',
  'KITCHEN AID',
  'LG',
  'LG ELECTRONICS',
  'MAYTAG',
  'WHIRLPOOL',
  'WHIRLPOOL CORPORATION'
];

export const cannotDispatchStatuses = {
  'APPROVED PAID': true,
  'APPROVED FOR PAYMENT': true,
  'SUBMIT FOR PAYMENT': true,
  'VOIDED CLAIM': true
};

export const lolOverride = [
  'Claims Manager',
  'Admin',
  'Call Center Manager',
  'Data Administration',
  'CSR 3'
];

export const lolExcludeInsurance = ['PEI - Generali'];

export const lolExcludeUniqueIdPrefixes = [
  'CC-FER',
  'ZLI-BANK',
  'CC-FER-BANK',
  'NOF-BANK',
  'EDG-BANK',
  'NLCE',
  'MTX-',
  'LVD-'
];

export const lolAllowedServiceTypes = ['Replacement/Store Credit', 'Other'];

export const expiredOverrideRoles = [
  'Admin',
  'Call Center Manager',
  'Call Center Supervisor',
  'Back Office Lead',
  'Claims Manager',
  'Claims 1 Plus'
];

export const REDEMPTION_DATE_OVERRIDE_ROLES = [
  SYSTEM_USER_ROLES.CLAIMS_MANAGER,
  SYSTEM_USER_ROLES.CLAIMS_1_PLUS,
  SYSTEM_USER_ROLES.ADMIN
];
