<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <label class="import-file__label">File</label><p-fileUpload name="myfile[]" accept=".csv, .txt"
            chooseLabel="Browse" (uploadHandler)="onUpload($event)" [customUpload]="true"
            (onRemove)="fileReset($event)"></p-fileUpload>
        </div>
      </div>
      <button class="crm-button" type="submit" id="btn-submit-form" label="!form.valid" hidden="hidden"></button>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button label-btn" (click)="onSubmit()" *ngIf="form.valid" for="btn-submit-form" tabindex="0">Add</button>
      <button class="crm-button label-btn btn-disabled" *ngIf="!form.valid" tabindex="0">Add</button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>