import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { AssociatedSubcategories } from '../../../coverage-models';
import { TabHandlerService } from 'src/app/modules/crm/services/tab-handler.service';
import { CoverageService } from '../../coverage.service';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'cv-associated-subcategories',
  templateUrl: 'associated-subcategories.template.html',
})
export class AssociatedSubcategoriesComponent implements OnInit, OnDestroy {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  tabKey: string;
  querySubscription: any;
  isLoading: boolean;
  constructor(
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private tabHandlerService: TabHandlerService,
    private coverageService: CoverageService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'subCategoryCode', header: 'SUBCATEGORY CODE', type: 'text' },
      { field: 'subcategoryDescription', header: 'SUBCATEGORY DESCRIPTION', type: 'text' },
      { field: 'categoryDescription', header: 'CATEGORY DESCRIPTION', type: 'text' },
      { field: 'lossCodesId', header: 'ASSOCIATED LOSS CODE', type: 'text' },
      { field: 'limitOfLiabilityOvr', header: 'LIMIT OF LIABILITY OVERRIDE', type: 'text' },
    ];

    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.isLoading = true;
          this.querySubscription = this.coverageService
            .getAssociatedSubcategoriesTab(this.coverageService.coverageDataModel.coverageCode)
            .subscribe(
              ({ data, loading }: any) => {
                this.isLoading = loading;
                const modifiedData = data.getCoverageSettingsById;
                if (modifiedData) {
                  this.dataList = this.coverageService.coverageDataModel.associatedSubcategories =
                    modifiedData.associatedSubCategory;
                }
              },
              (err) => {
                this.isLoading = false;
                throw err;
              },
            );
        }
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new AssociatedSubcategories();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
