import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';
import { GenericDealerStatus } from '../../../dealer/dealer.constants';

const service = gql`
  query(
    $status: String!
    $number: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $phoneNumber: String
    $limit: Int
    $offset: Int
  ) {
    getServicerSearchResults(
      status: $status
      number: $number
      name: $name
      city: $city
      state: $state
      zipCode: $zipCode
      phoneNumber: $phoneNumber
      offset: $offset
      limit: $limit
    ) {
      servicerProfilesIdOriginal
      status
      number
      name
      city
      state
      phoneNumber
      status
      zipCode
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class SearchServicerService {
  constructor(private apollo: Apollo) {}

  getServiceDetails = data =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: service,
      variables: {
        ...data,
        status: data.status && data.status.value ? data.status.value : '',
        state: data.state && data.state.value ? data.state.value : ''
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
