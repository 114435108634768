import { Injectable } from '@angular/core';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { TextboxSearchField } from '../shared/form-field/textbox-search';

@Injectable()
export class AdminConfigFieldService {
  getEmailTemplateFormFields() {
    const formFields: FormField<string>[] = [
      new CheckBoxField({
        key: '',
        label: '',
        type: 'checkbox',
        options: [{ key: 'Active', value: 'Active' }],
        order: 1
      }),
      new TextboxField({
        key: 'code',
        label: 'Code',
        order: 2
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 3
      }),
      new TextboxField({
        key: 'culturalCode',
        label: 'Cultural Code',
        value: 'English - United States',
        order: 4
      }),
      new TextboxField({
        key: 'type',
        label: 'Type',
        order: 5
      }),
      /*
      new DropdownField({
        key: 'culturalCode',
        label: 'Cultural Code',
        isSingleselect: true,
        options: [
          { key: 'English - United States', value: 'English - United States' }
        ],
        optionLabel: 'English - United States',
        order: 4
      }),
      new DropdownField({
        key: 'type',
        label: 'Type',
        isSingleselect: true,
        options: [
          { key: 'Claim Status Changed Notification', value: 'Claim Status Changed Notification' },
          { key: 'Customer Dispatch', value: 'Customer Dispatch' },
          { key: 'Customer Follow Up', value: 'Customer Follow Up' },
          { key: 'Dispatch', value: 'Dispatch' },
          { key: 'Information', value: 'Information' },
          { key: 'Payment Notification', value: 'Payment Notification' },
          { key: 'Servicer Notification', value: 'Servicer Notification' }
        ],
        order: 5
      })
      */
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContactInformationFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'primaryFirstName',
        label: 'Primary Contact First Name',
        order: 1
      }),
      new TextboxField({
        key: 'primaryLastName',
        label: 'Primary Contact First Name',
        order: 2
      }),
      new TextboxField({
        key: 'businessPhone',
        label: 'Business Phone',
        order: 3
      }),
      new TextboxField({
        key: 'fax',
        label: 'Fax',
        order: 4
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 5
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getGeneralInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'general1',
        label: 'General1',
        order: 1
      }),
      new TextboxField({
        key: 'general2',
        label: 'General2',
        order: 2
      }),
      new TextboxField({
        key: 'general3',
        label: 'General3',
        order: 3
      }),
      new TextboxField({
        key: 'general4',
        label: 'General4',
        order: 4
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getDealerLocationMainFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2
      }),
      new TextboxSearchField({
        key: 'fedTaxID',
        label: 'Fed Tax ID',
        order: 3
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 4
      }),
      new DropdownField({
        key: 'refundPayMethod',
        label: 'Refund Pay Method',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 5
      }),
      new DropdownField({
        key: 'dealer',
        label: 'Dealer',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 6
      }),
      new DropdownField({
        key: 'claimPayMethod',
        label: 'Claim Pay Method',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 7
      }),
      new DropdownField({
        key: 'storeType',
        label: 'Store Type',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 8
      }),
      new DropdownField({
        key: 'insuranceOverride',
        label: 'Insurance Override',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 9
      }),
      new DropdownField({
        key: 'obligor',
        label: 'Obligor',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 10
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getPayeeMainFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2
      }),
      new TextboxSearchField({
        key: 'fedTaxID',
        label: 'Fed Tax ID',
        order: 3
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 4
      }),
      new DropdownField({
        key: 'refundPayMethod',
        label: 'Refund Pay Method',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 5
      }),
      new CheckBoxField({
        key: 'buckets',
        label: 'Applicable Buckets',
        type: 'checkbox',
        options: [
          { key: 'admin', value: 'Admin' },
          { key: 'reservesFees', value: 'Reserves Fees' },
          { key: 'commission', value: 'Commission' }
        ],
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getImagesFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'image name',
        label: 'Image Name',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getModalTemplateFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'email subject',
        label: 'Email Subject',
        order: 1
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
