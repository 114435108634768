<p-dialog [header]="modalTitle" [(visible)]="displayDialog" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <app-tab-spinner *ngIf="isLoading" />
  <div class="modal-content" *ngIf="!isLoading">
    <div class="grid">
      <div class="col-12">
        <div class="grid">
          <div [ngClass]="'col-12 title'">Legend</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-4 label-color'"></div>
          <div [ngClass]="'col-4 label-color'">Paid Claim Amount</div>
          <div [ngClass]="'col-4 label-color'">Limit of Liability</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-4 label-color'">Product Purchase Price</div>
          <div class="col-4">${{total}}</div>
          <div class="col-4"></div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-4 label-color'">Amount Paid</div>
          <div class="col-4">${{usedForProduct}}</div>
          <div class="col-4">{{usedForProductPercent}}%</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-4 label-color'">Inflight Amounts</div>
          <div class="col-4">${{inflightAmount}}</div>
          <div class="col-4">{{inflightAmountPercent}}%</div>
        </div>
        <div class="grid">
          <div [ngClass]="'col-4 label-color'">Remaining for Product</div>
          <div class="col-4">${{remainingForProduct}}</div>
          <div class="col-4">{{remainingForProductPercent}}%</div>
        </div>
      </div>
    </div>
    <p-chart #chart type="pie" [data]="data" [options]="options"></p-chart>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer"> </ng-template>
</p-dialog>