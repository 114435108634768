<div class="crm-container">
  <app-section-header [title]="'Search Permission List Results (' + dataList.length + ')'"
    [optionHeader]="optionHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" />
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" [hidden]="isLoading" dataKey="id"
      editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <p-cellEditor *ngIf="col.type === 'text'">
              <ng-template pTemplate="input">
                <input class="input-text" type="text" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,optionRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              id="{{editConstant + rowData.id}}cancelBtn" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button class="btn-crm-success" type="button" [attr.disabled]="disableLoadMore?true:null"
      (click)="getPermissionValueData()" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions" [(ngModel)]="selectedEllipsisItem" (onClick)="handleOpen($event,optionRow)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionHeader>
  <p-listbox [options]="ellipsisHeaderOptions" [(ngModel)]="selectedEllipsisItem" (onClick)="handleOpen($event,optionHeader)">
  </p-listbox>
</p-overlayPanel>