import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { WorkQueueListService } from '../work-queue-list.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'work-queue-list-modal',
  templateUrl: 'work-queue-list-modal.template.html',
  styleUrls: ['./work-queue-list-modal.scss'],
  providers: [FormFieldControlService, MessageService, ConfirmationService],
})
export class WorkQueueListModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Output() formSubmitEvent = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  displayEmailModal: true;
  isLoading: boolean;
  querySubscription: Subscription;
  selectedRowIndex: number;
  dataList: any[] = [];
  querySubscriptions: Subscription[] = [];
  constructor(
    private qcs: FormFieldControlService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private workQueueListService: WorkQueueListService,
  ) {
    this.workQueueListService.getWorkQueue().subscribe((data) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    if (this.form.valid) {
      payload.workQueueIdOriginal =
        this.formData && this.formData.workQueueIdOriginal
          ? this.formData.workQueueIdOriginal
          : null;
      payload.insertUserName = this.azureService.accountId;
      this.isLoading = true;
      this.querySubscriptions.push(
        this.workQueueListService.createUpdateWorkQueue({ ...payload }).subscribe((data: any) => {
          this.isLoading = false;
          const response = data.createUpdateContractInquiry;
          if (response) {
            const item = this.formatData(response);
            item[`isNew`] = false;
            this.dataList[this.selectedRowIndex] = item;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'successMessage',
            });
          }
          this.isLoading = false;
          this.formSubmitEvent.emit(true);
          this.displayDialog = false;
          this.displayDialogChange.emit(this.displayDialog);
        }),
      );
    }
  }

  formatData(item) {
    return [
      {
        workQueueId: item.workQueueId,
        queueType: item.queueType,
      },
    ];
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.formSubmitEvent.emit(false);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
