import { Component, Input, OnInit } from '@angular/core';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { FormField } from '../../../shared/form-field/form-field';

@Component({
  selector: 'user',
  templateUrl: 'user.template.html',
  styleUrls: ['user.scss'],
  providers: [ContractFormFieldService],
})
export class UserComponent implements OnInit {
  nameInfo: FormField<any>[] = [];
  userContactInfo: FormField<any>[] = [];
  usernameInfo: FormField<any>[] = [];

  constructor(private contractService: ContractFormFieldService) {}

  ngOnInit() {
    this.contractService.getNameFields().subscribe((nameInfo) => (this.nameInfo = nameInfo));
    this.contractService
      .getUserContactFields()
      .subscribe((userContactInfo) => (this.userContactInfo = userContactInfo));
    this.contractService
      .getUsernameFields()
      .subscribe((usernameInfo) => (this.usernameInfo = usernameInfo));
  }

  onSubmit() {
    // functionality for on submit
  }
}
