import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubmitProviderTypeService {
  constructor(private http: HttpClient) {}

  getSubmitProviderTypes(): Observable<any[]> {
    return of([
      {
        select: 'true',
        dealer_name: 'Keith Appliances',
        paid_claim_count: '6',
        paid_amount: '$2,000.00'
      },
      {
        select: 'true',
        dealer_name: 'Daniel Appliance Company',
        paid_claim_count: '1',
        paid_amount: '$139.50'
      },
      {
        select: 'true',
        dealer_name: 'APPLIANCE CENTER',
        paid_claim_count: '3',
        paid_amount: '$639.00'
      }
    ]);
  }
  getBuyingGroup() {
    return [
      {
        master_buying_group: true,
        status: 'CANCELLED',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: ''
      },
      {
        master_buying_group: true,
        status: 'CANCELLED',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      },
      {
        master_buying_group: true,
        status: 'ACTIVE',
        number: 'SP-2661-FSC',
        name: '1 STOP CAMERA & ELECTRONICS',
        city: 'BROOKLYN',
        state: 'NY',
        zip: '1230',
        phone: '(123) 456-7890'
      }
    ];
  }
}
