import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckBoxField } from '../../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../../shared/form-field/textbox-field';
import { FormField } from '../../../../shared/form-field/form-field';
import { TextAreaField } from '../../../../shared/form-field/textarea-field';
import { Apollo } from 'apollo-angular';
@Injectable()
export class ServiceInfoModalService {
  templateDetails = {};
  typeMapping = {};

  constructor(private apollo: Apollo) {}

  getServicerFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'serviceRecallPeriod',
        label: 'Service Recall Period',
        required: true,
        order: 0
      }),
      new DropdownField({
        required: true,
        key: 'serviceRecallPeriodTerm',
        label: 'Days/Months from Date of Repair',
        isSingleselect: true,
        options: [{ key: 'Days', value: 'Days' }, { key: 'Months', value: 'Months' }],
        order: 1
      }),
      new TextboxField({
        key: 'invoiceSubmission',
        label: 'Invoice Submission',
        required: true,
        order: 2
      }),
      new DropdownField({
        required: true,
        key: 'invoiceSubmissionTerm',
        label: 'Days/Months from Date of Repair',
        isSingleselect: true,
        options: [{ key: 'Days', value: 'Days' }, { key: 'Months', value: 'Months' }],
        order: 3
      }),
      new DropdownField({
        required: true,
        key: 'partsVerificationRequired',
        label: 'Parts verification required',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'Yes' }, { key: 'No', value: 'No' }],
        order: 4
      }),
      new DropdownField({
        required: true,
        key: 'contracted',
        label: 'Contracted',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'Yes' }, { key: 'No', value: 'No' }],
        order: 5
      }),
      new DropdownField({
        required: true,
        key: 'sealedSystemsRepair',
        label: 'Sealed System Repair',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'Yes' }, { key: 'No', value: 'No' }],
        order: 6
      }),
      new DropdownField({
        required: true,
        key: 'customerSatisfaction',
        label: 'Customer Satisfaction',
        isSingleselect: true,
        options: [
          { key: '1', value: '1' },
          { key: '2', value: '2' },
          { key: '3', value: '3' },
          { key: '4', value: '4' },
          { key: '5', value: '5' },
          { key: '6', value: '6' },
          { key: '7', value: '7' },
          { key: '8', value: '8' },
          { key: '9', value: '9' },
          { key: '10', value: '10' }
        ],
        order: 7
      }),
      new DropdownField({
        required: true,
        key: 'rating',
        label: 'Rating',
        isSingleselect: true,
        options: [
          { key: '1', value: '1' },
          { key: '2', value: '2' },
          { key: '3', value: '3' },
          { key: '4', value: '4' },
          { key: '5', value: '5' },
          { key: '6', value: '6' },
          { key: '7', value: '7' },
          { key: '8', value: '8' },
          { key: '9', value: '9' },
          { key: '10', value: '10' }
        ],
        order: 8
      }),
      new DropdownField({
        required: true,
        key: 'facilityType',
        label: 'Facility Type',
        isSingleselect: true,
        options: [
          { key: 'Depot', value: 'Depot' },
          { key: 'Depot & Standard', value: 'Depot & Standard' },
          { key: 'Standard', value: 'Standard' }
        ],
        order: 9
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
