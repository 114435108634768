<div class="address-section">
  <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-row" *ngFor="let formField of formFields">
      <form-field [formField]="formField" [form]="form"></form-field>
    </div>
    <!-- <div class="form-row" *ngIf="isVerify">
      <button type="submit" [disabled]="!form.valid">Verify</button>
    </div> -->
  </form>
</div>
