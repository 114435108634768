import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ComponentsService {
  constructor() {}
  getComponents(): Observable<any[]> {
    return of([
      {
        id: 1,
        description: 'SKU',
        subcategory: 'n/a'
      }
    ]);
  }
}
