import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { TabsService } from '../../../services/tabs.service';
import { copy } from '../../../shared/utilities/common-utilities';
import { InquiryQueueService } from './inquiry-queue.service';

@Component({
  selector: 'inquiry-queue',
  templateUrl: 'inquiry-queue.template.html',
  styleUrls: ['inquiry-queue.scss'],
  providers: [InquiryQueueService],
})
export class InquiryQueueComponent implements OnInit {
  @Input() searchParams: any;
  admin: any[];
  cols: any[];
  resultCount: number;
  querySubscription: any;
  querySubscriptions: Subscription[] = [];
  isLoading: boolean;
  currentMaxId: number;
  disableLoadMore = false;
  offsetLimit = 10;
  dataList: any[];
  lastOffsetVal = 0;
  tabKey: any;
  constructor(
    private inquiryQueueService: InquiryQueueService,
    private azureService: AzureLoginService,
    private tabService: TabsService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'group_name', header: 'GROUP NAME', type: 'text' },
      { field: 'userName', header: 'USER NAME', type: 'text' },
      { field: 'email', header: 'EMAIL', type: 'text' },
      { field: 'new', header: 'NEW', type: 'link' },
      { field: 'inprogress', header: 'IN PROGRESS', type: 'link' },
      { field: 'onhold', header: 'ON HOLD', type: 'link' },
      { field: 'closed', header: 'CLOSED', type: 'link' },
      { field: 'open', header: 'OPEN', type: 'link' },
      { field: 'viewall', header: 'VIEW ALL', type: 'link' },
    ];
    this.getInquiryQueue();
  }

  getInquiryQueue() {
    let isSearchExists = false;
    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string' ? this.searchParams : {};
    Object.keys(this.searchParams).map((el) => {
      if (this.searchParams[el] !== '') {
        isSearchExists = true;
      }
    });
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.lastOffsetVal += this.offsetLimit;
    this.isLoading = true;
    this.resultCount = 0;
    this.querySubscription = this.inquiryQueueService.getInquiryQueue(this.searchParams).subscribe(
      ({ data }) => {
        this.isLoading = false;
        this.currentMaxId = 0;
        const response = copy(data.getInquiryQueue);
        if (response) {
          const modifedData = response.map((el) => {
            el = this.formatData(el);
            this.currentMaxId += 1;
            el.id = this.currentMaxId;
            return el;
          });
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
          this.resultCount = this.dataList.length;
        } else {
          this.dataList = [];
          this.disableLoadMore = false;
        }
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }

  // FROM API row to table row data
  formatData(item) {
    return {
      id: 0,
      group_name: item.roleName,
      userName: item.userName,
      email: item.email,
      new: item.newCount,
      inprogress: item.inProgressCount,
      onhold: item.onHoldCount,
      closed: item.closedCount,
      open: item.openCount,
      viewall: item.viewAllCount,
    };
  }

  openTab(rowData, inquiryStatus) {
    const search = {
      azureUserId: this.azureService.accountId,
      userName: rowData.userName,
      inquiryStatus: inquiryStatus !== 'viewall' ? inquiryStatus : null,
    };
    const newTab = {
      header: 'Search Contract',
      content: search,
      type: 'SearchContract',
    };
    this.tabService.createTab(newTab);
  }
}
