<div class="crm-container">
  <app-section-header [title]="'My Inquiry Queue Results  ( ' + resultCount + ' item(s) found )'" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id"
      editMode="row"><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <div class="crm-link" *ngIf="col.type === 'link'" (click)="openTab(rowData,col.field)">
              {{rowData[col.field]}}</div>
            <div *ngIf="col.type === 'text'">{{rowData[col.field]}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <button class="btn-crm-success" type="button" (click)="getInquiryQueue()" [attr.disabled]="disableLoadMore?true:null"
    [hidden]="isLoading">
    Load More
  </button>
</div>