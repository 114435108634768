import { Injectable } from '@angular/core';

import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { TextboxSearchField } from '../shared/form-field/textbox-search';
import { RadioButtonField } from '../shared/form-field/radio-button-field';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { TextAreaField } from '../shared/form-field/textarea-field';

@Injectable()
export class CoverageFormFieldService {
  // TODO: get from a remote source of formField metadata
  getFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'coverageCode',
        label: 'Code',
        order: 1
      }),

      new TextboxField({
        key: 'coverageName',
        label: 'Name',
        order: 2
      }),

      new DropdownField({
        key: 'coverageGroupCode',
        label: 'Coverage Group',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 3
      }),

      new DropdownField({
        key: 'reportingGroupCode',
        label: 'Report Group',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 4
      }),

      new TextboxField({
        key: 'sKUReferenceCode',
        label: 'Reference Code (SKU)',
        order: 5
      }),

      new TextboxSearchField({
        key: 'priceTable',
        label: 'Price Table',
        order: 6
      }),

      new TextboxField({
        key: 'eligibleFrom',
        label: 'Eligible From',
        type: 'Date',
        order: 7
      }),

      new TextboxField({
        key: 'eligibleTo',
        label: 'Eligible To',
        type: 'Date',
        order: 8
      }),

      new DropdownField({
        key: 'dop',
        label: 'DOP',
        isSingleselect: true,
        options: [{ key: 'Yes', value: 'Y' }, { key: 'No', value: 'N' }],
        order: 9
      }),

      new DropdownField({
        key: 'planType',
        label: 'Plan Type',
        isSingleselect: true,
        options: [{ key: 'Replace', value: 'Replace' }, { key: 'Service', value: 'Service' }].sort((a, b) => a.key.localeCompare(b.key)),
        order: 10
      }),

      new DropdownField({
        key: 'effectiveDateBasedOn',
        label: 'Effective Based On',
        isSingleselect: true,
        options: [
          { key: 'Product DOP', value: 'Product DOP' },
          { key: 'Plan DOP', value: 'Plan DOP' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        order: 11
      }),

      new DropdownField({
        key: 'condition',
        label: 'Condition',
        isSingleselect: true,
        options: [
          { key: 'New', value: 'N' },
          { key: 'Used', value: 'U' },
          { key: 'Refurbished', value: 'R' },
          { key: 'Other', value: 'O' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        order: 12
      }),

      new TextboxField({
        key: 'upc',
        label: 'UPC',
        order: 13
      }),
      new TextboxField({
        key: 'sentOrder',
        label: 'Sent Order',
        order: 14
      }),

      new TextboxField({
        key: 'salesDateOfPurchaseFrom',
        label: 'Sales Date Of Purchase From',
        type: 'Date',
        order: 15
      }),

      new TextboxField({
        key: 'salesDateOfPurchaseTo',
        label: 'Sales Date Of Purchase To',
        type: 'Date',
        order: 16
      }),

      new TextboxField({
        key: 'priceTableDateFrom',
        label: 'Price Table From',
        type: 'Date',
        order: 17
      }),

      new TextboxField({
        key: 'priceTableDateTo',
        label: 'Price Table To',
        type: 'Date',
        order: 18
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSettingFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'effectiveDateLag',
        label: 'Effective Date Lag',
        order: 1
      }),

      new DropdownField({
        key: 'effectiveDateLagBy',
        label: 'Date Lag By',
        isSingleselect: true,
        options: [{ key: 'Days', value: 'Days' }, { key: 'Month', value: 'Month' }].sort((a, b) => a.key.localeCompare(b.key)),
        value: { key: 'Days', value: 'Days' },
        order: 2
      }),

      new TextboxField({
        key: 'exclusionDays',
        label: 'Exclusion Days',
        type: 'number',
        order: 3
      }),

      new DropdownField({
        key: 'serviceLocation',
        label: 'Service Location',
        isSingleselect: true,
        options: [
          { key: 'Depot', value: 'Depot' },
          { key: 'In Home', value: 'In Home' },
          { key: 'Carry In', value: 'Carry In' }
        ],
        order: 4
      }),

      new TextboxField({
        key: 'purchaseTime',
        label: 'Purchase Time',
        type: 'number',
        order: 5
      }),

      new RadioButtonField({
        key: 'msrp',
        options: [{ key: 'P', value: 'MSRP Per Product' }],
        order: 6
      }),

      new RadioButtonField({
        key: 'msrp',
        options: [{ key: 'A', value: 'MSRP All Product' }],
        order: 7
      }),

      new TextboxField({
        key: 'minMsrp',
        label: 'Minimum MSRP',
        order: 8
      }),

      new TextboxField({
        key: 'maxMsrp',
        label: 'Maximum MSRP',
        order: 9
      }),

      // new RadioButtonField({
      //   key: 'regulatedRetail',
      //   options: [{ key: 'true', value: 'Regulated Retail' }],
      //   order: 10
      // }),

      // new RadioButtonField({
      //   key: 'discountable',
      //   options: [{ key: 'true', value: 'Discountable' }],
      //   order: 11
      // }),

      // new TextboxField({
      //   key: 'minMarkup',
      //   label: 'Minimum Markup',
      //   order: 12
      // }),

      // new TextboxField({
      //   key: 'maxMarkup',
      //   label: 'Maximum Markup',
      //   order: 13
      // }),

      // new TextboxField({
      //   key: 'maxAmount',
      //   label: 'Max Amount Allowed',
      //   order: 14
      // }),

      // new TextboxField({
      //   key: 'maxPartMarkup',
      //   label: 'Max Part Markup',
      //   order: 15
      // }),

      new TextboxField({
        key: 'productCountSetting',
        label: 'Product Count Setting',
        order: 16
      }),

      new DropdownField({
        key: 'insurerName',
        label: 'Insurer',
        isSingleselect: true,
        options: [
          { key: 'Fortegra', value: 'Fortegra' },
          { key: 'Generali Warranty Services', value: 'Generali Warranty Services' },
          { key: 'Carrier On File', value: 'Carrier On File' },
          { key: 'Just Say Yes', value: 'Just Say Yes' },
          { key: 'Starr', value: 'Starr' },
          { key: 'New Leaf - OEM - ZLine', value: 'New Leaf - OEM - ZLine' },
          { key: 'DAC', value: 'DAC' },
          { key: 'Brandsource Portfolio', value: 'Brandsource Portfolio' },
          { key: 'Goodwill-NLF Error', value: 'Goodwill-NLF Error' },
          { key: 'DAC - Goodwill', value: 'DAC - Goodwill' },
          { key: 'Starr- Goodwill', value: 'Starr- Goodwill' },
          { key: 'New Leaf – 50%Back', value: 'New Leaf – 50%Back' },
          {
            key: 'New Leaf - Customer Care -  Ferguson',
            value: 'New Leaf - Customer Care -  Ferguson'
          },
          { key: 'New Leaf - OEM - Edgestar', value: 'New Leaf - OEM - Edgestar' },
          { key: 'Laundry Lux', value: 'Laundry Lux' },
          { key: 'TEST CARRIER', value: 'TEST CARRIER' },
          { key: 'Dealer Reimbursement - Collected', value: 'Dealer Reimbursement - Collected' },
          { key: 'Fortegra - Theft & Loss', value: 'Fortegra - Theft & Loss' },
          { key: 'Customer Care - Pirch Funded', value: 'Customer Care - Pirch Funded' },
          {
            key: 'New Leaf - Customer Care - MyColders',
            value: 'New Leaf - Customer Care - MyColders'
          },
          { key: 'New Leaf – NOF BANK', value: 'New Leaf – NOF BANK' },
          { key: 'Goodwill-Dan', value: 'Goodwill-Dan' },
          { key: 'Dealer Reimbursement', value: 'Dealer Reimbursement' },
          { key: 'Goodwill-Sean', value: 'Goodwill-Sean' },
          { key: 'Goodwill-Operations ', value: 'Goodwill-Operations ' },
          { key: 'Customer Care - Ferguson Funded', value: 'Customer Care - Ferguson Funded' },
          { key: 'New Leaf - Customer Care - Pirch', value: 'New Leaf - Customer Care - Pirch' },
          {
            key: 'Lexington National Warranty Services',
            value: 'Lexington National Warranty Services'
          },
          { key: 'PEI - Generali', value: 'PEI - Generali' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        order: 17
      }),

      new DropdownField({
        key: 'deductibleCollectedBy',
        label: 'Deductible Collected By',
        isSingleselect: true,
        options: [{ key: 'Servicer', value: 'Servicer' }, { key: 'TPA', value: 'TPA' }],
        order: 18
      }),

      new DropdownField({
        key: 'limitOfLiabilityBasedOn',
        label: 'Limit Of Liability Based On',
        isSingleselect: true,
        options: [{ key: 'Product', value: 'Product' }],
        order: 19
      }),

      new DropdownField({
        key: 'defaultDispatchMethod',
        label: 'Default Dispatch Method',
        isSingleselect: true,
        options: [{ key: 'Email Account', value: 'E' }, { key: 'Fax', value: 'Fax' }],
        order: 20
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getApprovedStateFormFields() {
    const formFields: FormField<string>[] = [
      new RadioButtonField({
        key: 'transferRule',
        label: 'Transfer Rule',
        options: [
          { key: 'perCoverage', value: 'Sale Date' },
          { key: 'inServiceDate', value: 'In-Service Date' }
        ],
        order: 1
      }),

      new TextboxField({
        key: 'minNumberOfTransfers',
        label: 'Minimum Number Of Transfers',
        order: 2
      }),

      new TextboxField({
        key: 'maxNumberOfTransfers',
        label: 'Max Number Of Transfers',
        order: 4
      }),

      new CheckBoxField({
        key: 'allowTransfers',
        options: [{ key: 'allowTransfers', value: 'Allow Transfers' }],
        order: 5
      }),

      new DropdownField({
        key: 'coverageCountry',
        label: 'Country',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 6
      }),

      new RadioButtonField({
        key: 'approvedItems',
        options: [{ key: 'all', value: 'All' }, { key: 'selected', value: 'Selected' }],
        order: 7
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getInsuranceCompanyFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        isSingleselect: true,
        required: true,
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Terminated', value: 'Terminated' }
        ],
        order: 1
      }),

      new TextboxField({
        key: 'number',
        label: 'Number',
        required: true,
        isChangeEvent: true,
        errorMessages: [
          { key: 'duplicateInsuranceNUmber', value: 'This number is already present in the system' }
        ],
        order: 2
      }),

      new TextboxField({
        key: 'name',
        label: 'Name',
        required: true,
        order: 3
      }),

      new DropdownField({
        key: 'companyType',
        label: 'Company Type',
        isSingleselect: true,
        options: [],
        order: 4
      }),

      new TextboxField({
        key: 'fedTaxId',
        label: 'Fed Tax ID',
        order: 5
      }),

      new DropdownField({
        key: 'billingMethod',
        label: 'Billing Method',
        isSingleselect: true,
        options: [
          { key: 'Remittance', value: 'Remittance' },
          { key: 'Direct Bill', value: 'Direct Bill' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        order: 6
      }),

      new DropdownField({
        key: 'pendingBillingTransaction',
        label: 'Pending Billing Transaction',
        isSingleselect: true,
        options: [{ key: 'Per TPA', value: 'Per TPA' }],
        order: 7
      }),

      new DropdownField({
        key: 'currency',
        label: 'Currency',
        isSingleselect: true,
        options: [
          { key: 'BRL', value: 'brl' },
          { key: 'CAD', value: 'cad' },
          { key: 'CLP', value: 'clp' },
          { key: 'EUR', value: 'eur' },
          { key: 'USD', value: 'usd' }
        ],
        order: 8
      }),

      new DropdownField({
        key: 'showOnRemittance',
        label: 'Show On Remittance',
        isSingleselect: true,
        options: [{ key: 'Per TPA', value: 'Per TPA' }],
        order: 9
      }),

      new DropdownField({
        key: 'includeVoidContractOnRemittance',
        label: 'Include Void Contract On Remittance',
        isSingleselect: true,
        options: [{ key: 'Per TPA', value: 'Per TPA' }],
        order: 10
      }),

      new DropdownField({
        key: 'activateContractUponRemittance',
        label: 'Activate Contract Upon Remittance',
        isSingleselect: true,
        options: [{ key: 'Per TPA', value: 'Per TPA' }],
        order: 11
      }),

      new TextboxField({
        key: 'externalId',
        label: 'External ID',
        order: 12
      }),

      new TextboxField({
        key: 'insuranceCompanyUser',
        label: 'User',
        order: 13
      }),

      new TextboxField({
        key: 'insuranceCompanyPassword',
        label: 'Password',
        order: 14
      }),

      new TextboxField({
        key: 'insuranceCompanyHost',
        label: 'Host',
        order: 15
      }),

      new TextboxField({
        key: 'insuranceCompanyPort',
        label: 'Port',
        order: 16
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getInsuranceCompanyAccountFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'arAccount',
        label: 'AR Account',
        order: 1
      }),

      new TextboxField({
        key: 'apAccount',
        label: 'AP Account',
        order: 2
      }),

      new TextboxField({
        key: 'glAccount',
        label: 'G/L Account',
        order: 3
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAddCoveredItemFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'lossCode',
        label: 'LOSS CODE',
        required: true,
        order: 1
      }),

      new TextAreaField({
        key: 'description',
        label: 'DESCRIPTION',
        rows: 4,
        order: 2
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCancellationMethodFormFields() {
    const formFields: FormField<string>[] = [
      new CheckBoxField({
        key: 'active',
        options: [{ key: 'active', value: 'Active' }],
        order: 1
      }),

      new TextboxField({
        key: 'code',
        label: 'Code',
        order: 2
      }),

      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 3
      }),

      new TextAreaField({
        key: 'description',
        label: 'Description',
        rows: 6,
        order: 4
      }),

      new DropdownField({
        key: 'calculation',
        label: 'Calculation',
        isSingleselect: true,
        options: [
          { key: 'option1', value: '100%' },
          { key: 'option2', value: '75%' },
          { key: 'option3', value: '50%' },
          { key: 'option4', value: '25%' }
        ],
        order: 6
      }),

      new RadioButtonField({
        key: 'factorBasedOn',
        label: 'Factor Based On',
        options: [
          { key: 'lesserOf', value: 'Lessor Of' },
          { key: 'greaterOf', value: 'Greater Of' }
        ],
        order: 7
      }),

      new DropdownField({
        key: 'term',
        label: 'Term (Months)',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 8
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCancellationRuleFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'ruleName',
        label: 'Rule Name',
        order: 1
      }),

      new DropdownField({
        key: 'productType',
        label: 'Product Type',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 2
      }),

      new TextboxField({
        key: 'dateFrom',
        label: 'Date From',
        type: 'Date',
        order: 3
      }),

      new TextboxField({
        key: 'dateTo',
        label: 'Date To',
        type: 'Date',
        order: 4
      }),

      new DropdownField({
        key: 'filterByCountry',
        label: 'Filter By Country',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 5
      }),

      new DropdownField({
        key: 'ageOfContractBasedOn',
        label: 'Age Of Contract Based On',
        isSingleselect: true,
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 6
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getRateBucketSearchFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'bucketCode',
        label: 'Bucket Code',
        required: true,
        order: 1
      }),
      new TextboxField({
        key: 'bucketLabel',
        label: 'Bucket Label',
        required: true,
        order: 2
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
