import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { ClaimsLookup } from '../../shared/constants/lookup-list';

const getWorkQueueListQuery = gql`
  query($queueType: String, $status: String, $offset: Int, $limit: Int) {
    getWorkQueueList(queueType: $queueType, status: $status, offset: $offset, limit: $limit) {
      queueMappingIdOriginal
      queueMappingId
      queueType
      statusName
    }
  }
`;
const createUpdateWorkQueueStatusMapping = gql`
  mutation(
    $queueMappingIdOriginal: Int
    $queueType: String
    $statusName: String
    $isDeleted: Boolean
    $insertUserName: String
  ) {
    createUpdateWorkQueueStatusMapping(
      workQueueStatusMappingInput: {
        queueMappingIdOriginal: $queueMappingIdOriginal
        queueType: $queueType
        statusName: $statusName
        isDeleted: $isDeleted
        insertUserName: $insertUserName
      }
    ) {
      queueMappingId
      queueMappingIdOriginal
      workQueueId
      statusID
      insertUserName
      addedTimeStamp
    }
  }
`;
const createUpdateWorkQueue = gql`
  mutation(
    $queueType: String
    $isDeleted: Boolean
    $insertUserName: String!
    $workQueueIdOriginal: Int
  ) {
    createUpdateWorkQueue(
      workQueueInput: {
        queueType: $queueType
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        workQueueIdOriginal: $workQueueIdOriginal
      }
    ) {
      workQueueId
      queueType
      insertUserName
      workQueueIdOriginal
    }
  }
`;
const getWorkQueueListTypes = gql`
  query {
    getWorkQueueListTypes {
      key
      value
    }
  }
`;
@Injectable()
export class WorkQueueListService {
  constructor(private apollo: Apollo, private claimsLookupService: ClaimsLookup) {}
  getWorkQueueList() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        required: true,
        key: 'queueType',
        label: 'Queue Type',
        order: 0
      }),
      new DropdownField({
        key: 'statusName',
        label: 'Status',
        options: this.claimsLookupService.claimsLookup,
        isSingleselect: true,
        filter: true,
        order: 1
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getWorkQueue() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        required: true,
        key: 'queueType',
        label: 'Queue Type',
        order: 0
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getWorkQueueListQuery = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getWorkQueueListQuery,
      variables: {
        ...searchParams,
        queueType:
          searchParams.queueType && searchParams.queueType.key ? searchParams.queueType.key : null,
        status: searchParams.status && searchParams.status.key ? searchParams.status.key : null
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createUpdateWorkQueueStatusMapping = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createUpdateWorkQueueStatusMapping,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });

  createUpdateWorkQueue = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createUpdateWorkQueue,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });

  getWorkQueueListTypes = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getWorkQueueListTypes,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
