import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PriceTableService {
  constructor() {}
  getPriceTable(): Observable<any[]> {
    return of([
      {
        id: 1,
        name: 'CPR',
        description: 'Cell Phone Repair',
        active: true,
        defaultProfile: false
      },
      {
        id: 2,
        name: 'GFJ',
        description: 'Given Fine Jewelers',
        active: true,
        defaultProfile: false
      },
      {
        id: 3,
        name: 'TCC',
        description: 'The Clearance Center',
        active: true,
        defaultProfile: false
      }
    ]);
  }
}
