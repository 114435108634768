import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { CrmGraphql } from '../../shared/constants/graphql-constants';

const getDealerServicerQuery = gql`
  query($dealerGroupId: Int!) {
    getDealerServicer(dealerGroupId: $dealerGroupId) {
      dealerGroupsServicersIdOriginal
      number
      name
      preferred
      restricted
      selfServicing
    }
  }
`;

export const createUpdateDealerServicerMutation = gql`
  mutation(
    $dealerGroupNumber: String
    $dealerGroupId: Int
    $dealerGroupName: String
    $selfServicing: Boolean
    $preferred: Boolean
    $restricted: Boolean
    $number: String
    $name: String
    $city: String
    $state: String
    $dealerGroupsServicersIdOriginal: Int
  ) {
    createUpdateDealerServicer(
      dealerServicerInput: {
        dealerGroupNumber: $dealerGroupNumber
        dealerGroupId: $dealerGroupId
        dealerGroupName: $dealerGroupName
        selfServicing: $selfServicing
        preferred: $preferred
        restricted: $restricted
        number: $number
        name: $name
        city: $city
        state: $state
        dealerGroupsServicersIdOriginal: $dealerGroupsServicersIdOriginal
      }
    ) {
      dealerGroupId
      dealerGroupsServicersIdOriginal
      number
      name
      preferred
      restricted
      selfServicing
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DealerServicerService {
  constructor(private apollo: Apollo) {}

  getDealerServicer = data =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getDealerServicerQuery,
      variables: { ...data },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createUpdateDealerServicer = dealerServicerInput =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateDealerServicerMutation,
      variables: {
        ...dealerServicerInput,
        dealerGroupNumber: dealerServicerInput.dealerGroupNumber,
        dealerGroupId: dealerServicerInput.dealerGroupId,
        dealerGroupName: dealerServicerInput.dealerGroupName,
        selfServicing: dealerServicerInput.selfServicing,
        preferred: dealerServicerInput.preferred,
        restricted: dealerServicerInput.restricted,
        number: dealerServicerInput.number,
        name: dealerServicerInput.name,
        city: dealerServicerInput.city,
        state: dealerServicerInput.state,
        dealerGroupsServicersIdOriginal: dealerServicerInput.dealerGroupsServicersIdOriginal
      }
      // tslint:disable-next-line: semicolon
    });
}
