import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Observable, of } from 'rxjs';
import { ServiceInfoModalService } from './service-info-modal.service';
import { ServicerService } from '../../servicer.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
@Component({
  selector: 'service-info-modal',
  templateUrl: 'service-info-modal.template.html',
  styleUrls: ['./service-info-modal.scss'],
  providers: [FormFieldControlService, ServiceInfoModalService, MessageService],
})
export class ServiceInfoModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Output() formSubmitEvent = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  displayEmailModal: true;
  isLoading = true;
  querySubscription: Subscription;
  validForm = true;
  templateResponse = null;
  selectedRowIndex: number;
  dataList: any[] = [];
  constructor(
    private qcs: FormFieldControlService,
    private serviceInfoModalService: ServiceInfoModalService,
    private servicerService: ServicerService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
  ) {
    this.serviceInfoModalService.getServicerFormFields().subscribe((data) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.formData.serviceRecallPeriod) {
      this.form.patchValue({
        ...this.formData,
        serviceRecallPeriodTerm: {
          key: this.formData.serviceRecallPeriodTerm,
          value: this.formData.serviceRecallPeriodTerm,
        },
        invoiceSubmissionTerm: {
          key: this.formData.invoiceSubmissionTerm,
          value: this.formData.invoiceSubmissionTerm,
        },
        partsVerificationRequired: {
          key: this.formData.partsVerificationRequired,
          value: this.formData.partsVerificationRequired,
        },
        contracted: { key: this.formData.contracted, value: this.formData.contracted },
        sealedSystemsRepair: {
          key: this.formData.sealedSystemsRepair,
          value: this.formData.sealedSystemsRepair,
        },
        customerSatisfaction: {
          key: this.formData.customerSatisfaction
            ? this.formData.customerSatisfaction.toString()
            : null,
          value: this.formData.customerSatisfaction
            ? this.formData.customerSatisfaction.toString()
            : null,
        },
        rating: {
          key: this.formData.rating ? this.formData.rating.toString() : null,
          value: this.formData.rating ? this.formData.rating.toString() : null,
        },
        facilityType: { key: this.formData.facilityType, value: this.formData.facilityType },
      });
    }
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.formSubmitEvent.emit(true);
  }

  formatData(item) {
    return [
      {
        serviceRecallPeriod: item.serviceRecallPeriod,
        serviceRecallPeriodTerm: item.serviceRecallPeriodTerm,
        invoiceSubmission: item.invoiceSubmission,
        invoiceSubmissionTerm: item.invoiceSubmissionTerm,
        partsVerificationRequired: item.partsVerificationRequired,
        contracted: item.contracted,
        sealedSystemsRepair: item.sealedSystemsRepair,
        customerSatisfaction: item.customerSatisfaction,
        rating: item.rating,
        facilityType: item.facilityType,
      },
    ];
  }

  validateServicerInfo(formData) {
    const errField = [];
    const errObj = { isValid: true, errMessage: '' };
    if (!formData.serviceRecallPeriod) {
      errField.push(' Service Recall Period');
    }
    if (!formData.serviceRecallPeriodTerm.value) {
      errField.push(' Service Recall Period Term');
    }
    if (!formData.invoiceSubmission) {
      errField.push(' Invoice Submission');
    }
    if (!formData.partsVerificationRequired.value) {
      errField.push(' parts Verification Required');
    }
    if (!formData.contracted.value) {
      errField.push(' Contracted');
    }
    if (!formData.sealedSystemsRepair.value) {
      errField.push(' Sealed Systems Repair');
    }
    if (!formData.facilityType.value) {
      errField.push('Facility Type');
    }
    if (!formData.invoiceSubmissionTerm.value) {
      errField.push(' Invoice Submission Term');
    }
    if (!formData.customerSatisfaction.value) {
      errField.push(' Customer Satisfaction');
    }
    if (!formData.rating.value) {
      errField.push(' Rating');
    }
    if (errField.length > 0) {
      errObj.isValid = false;
      errObj.errMessage = `Please fill ${errField.join()}`;
    }
    return errObj;
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    const errObj = this.validateServicerInfo(payload);
    if (errObj.isValid && this.form.valid) {
      if (payload.isNew) {
        payload.servicerProfilesInfoIdOriginal = null;
      } else {
        payload.servicerProfilesInfoIdOriginal = this.formData.servicerProfilesInfoIdOriginal;
      }
      payload.servicerProfilesId = this.formData.servicerProfilesId;
      payload.serviceRecallPeriod = parseInt(payload.serviceRecallPeriod, 10);
      payload.invoiceSubmission = parseInt(payload.invoiceSubmission, 10);
      payload.partsVerificationRequired =
        payload.partsVerificationRequired.value === 'Yes' ? true : false;
      payload.contracted = payload.contracted.value === 'Yes' ? true : false;
      payload.sealedSystemsRepair = payload.sealedSystemsRepair.value === 'Yes' ? true : false;
      payload.facilityType = payload.facilityType.value;
      payload.serviceRecallPeriodTerm = payload.serviceRecallPeriodTerm.value;
      payload.invoiceSubmissionTerm = payload.invoiceSubmissionTerm.value;
      payload.customerSatisfaction = parseInt(payload.customerSatisfaction.value, 10);
      payload.rating = parseInt(payload.rating.value, 10);
      payload.insertUserName = this.azureService.accountId || null;
      this.querySubscription = this.servicerService
        .createUpdateServicerProfileInfo({ ...payload })
        .subscribe((data: any) => {
          this.isLoading = false;
          const response = data.createUpdateServicerProfileInfo;
          if (response) {
            const item = this.formatData(response);
            item[`isNew`] = false;
            this.dataList[this.selectedRowIndex] = item;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'successMessage',
            });
          }
          this.formSubmitEvent.emit(true);
          this.displayDialog = false;
          this.displayDialogChange.emit(this.displayDialog);
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: errObj.errMessage,
      });
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
