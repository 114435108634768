import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';

const dropdownDataQuery = gql`
  query {
    getDealerGroupCache {
      dealerGroupNumber
      dealerGroupName
      dealerGroupsIdOriginal
      agentId
    }
    getBuyingGroupCache {
      agentIdOriginal
      name
      number
      masterBuyingGroupId
    }
    getMasterBuyingGroupCache {
      masterBuyingGroupIDOriginal
      name
    }
    getUserListCache {
      key
      value
    }
    getServicerListCache {
      key
      value
    }
    getServicerGroupListCache {
      key
      value
    }
    getAllSubCategoryCodeAndDescription {
      key
      value
    }
    getManufacturerByName(name: "", limit: 10000) {
      manufacturersName
      ManufacturesIDOriginal
    }
    getAllProductPlanTypesList {
      key
      value
    }
    getCategoryDescriptionList
    getCategoryList {
      key
      value
    }
    getAllModuleTypes {
      key
      value
    }
    getAllDealerLocations {
      key
      value
    }
    getUserProfileTypes {
      userProfileType
    }
    getServicerListLink {
      key
      value
    }
    getClaimStatusCache {
      key
      value
    }
    getRoleNameCache {
      key
      value
    }
    getMenuTypeList {
      menuTypeName
      sCMConfigIdOriginal
      module
    }
    getTierDescriptionList {
      key
      value
    }
    getPermissionsList {
      key
      value
    }
    getOperationsList {
      key
      value
    }
    getWorkQueueListTypes {
      key
      value
    }
    getTransactionYear {
      key
      value
    }
    getStateList {
      stateName
      stateCode
      stateIdOriginal
    }
  }
`;

const dropdownUserListDataQuery = gql`
  query {
    getUserListCache {
      key
      value
    }
  }
`;

@Injectable()
export class DropdownDataService {
  dealerGroupData: any[];
  buyingGroupData: any[];
  masterBuyingGroupData: any[];
  userListData: any[];
  productPlanTypeList: any[];
  servicerListData: any[];
  servicerGroupList: any[];
  categoryDescriptionList = [];
  categoryList: any[];
  claimPaymentMethodData: any[];
  subCategoryDescriptionList: any[];
  manufacturerList: any[];
  manufacturerDetailList: any[];
  moduleTypeList: any[];
  dealerLocationList: any[];
  userProfileTypesList: any[];
  servicerListLinkData: any[];
  roleNameData: any[];
  menuTypeList: any[];
  stateList: any[];
  claimStatusData: any[];
  tierDescriptionData: any[];
  permissionsList: any[];
  operationsListOriginal: any[];
  operationsList: any[];
  permissionDescription: any[];
  workQueueListTypes: any[];
  transactionYear: any[];

  constructor(private apollo: Apollo) {}

  getDropdownData = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: dropdownDataQuery,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getUpdatedUserLists = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: dropdownUserListDataQuery,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}