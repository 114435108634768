import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { error } from 'console';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  public buildNumber = `v${environment.buildNumber}`;

  onPopState(event: any) {
    if (this.authService.instance.getAllAccounts().length <= 0) {
      this.router.navigate(['']);
    }
  }
  loggedIn = false;
  constructor(
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
  ) {}

  ngOnInit(): void {
    this.checkAccount();
    if (this.loggedIn) {
      this.router.navigate(['crm']);
    }
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    this.authService.instance
      .loginPopup({ scopes: [] })
      .then((data: AuthenticationResult) => {
        this.redirectToApplication();
      })
      .catch((error) => {
        if (error && error.errorCode === 'interaction_in_progress') {
          localStorage.clear();
          sessionStorage.clear();
        }
        if(error && error.errorMessage.includes('AADB2C90118')){
            this.authService.instance.loginPopup({
                scopes: [] ,
                authority: environment.passwordResetAuthority
            })
        }
        console.log(error);
      });
  }

  redirectToApplication() {
    this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
    this.router.navigate(['crm']);
  }
}
