import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServicerDocumentService {
  constructor() {}
  getServicerDocuments(): Observable<any[]> {
    return of([
      {
        id: 1,
        fileName: 'file1.pdf',
        description: 'file1.pdf',
        uploadedDate: 'June 1, 2019',
        uploadedBy: 'ABCD1',
        limitOfLiabilityOverride: ''
      },
      {
        id: 2,
        fileName: 'file1.pdf',
        description: 'file1.pdf',
        uploadedDate: 'June 1, 2019',
        uploadedBy: 'ABCD1',
        limitOfLiabilityOverride: ''
      },
      {
        id: 3,
        fileName: 'file1.pdf',
        description: 'file1.pdf',
        uploadedDate: 'June 1, 2019',
        uploadedBy: 'ABCD1',
        limitOfLiabilityOverride: ''
      }
    ]);
  }
}
