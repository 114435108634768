import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { StringService } from '../../../services/string.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.template.html',
  styleUrls: ['./modal.scss'],
})
export class ModalComponent implements OnDestroy {
  @Input() public visible = true;
  @Input() public title: string;
  @Input() public closeable = false;
  @Input() public maxWidth = 'calc(100% - 10px)';

  @Output() public close: EventEmitter<void> = new EventEmitter<void>();
  @Output() public expand: EventEmitter<void> = new EventEmitter<void>();

  public strings: any = null;
  private unsubscribe: Subject<void> = new Subject();

  constructor(public stringService: StringService) {
    this.stringService.strings$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((strings) => (this.strings = strings));
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
