import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServicerServiceArea {
  public isLoading = new BehaviorSubject(false);
  constructor() {}
  getServiceArea(): Observable<any[]> {
    return of([
      {
        id: 1,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },
      {
        id: 2,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },
      {
        id: 3,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },

      {
        id: 4,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },
      {
        id: 5,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },
      {
        id: 6,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },
      {
        id: 7,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      },
      {
        id: 8,
        include_manufacturer: 'ALL',
        include_model: 'ALL',
        include_zipcode: '0701',
        radius: '0'
      }
    ]);
  }
  getRatesPreauth(): Observable<any[]> {
    return of([
      {
        id: 1,
        effective_date: '1/21/2013',
        category: 'APPLIANCE',
        subcategory: 'Subcategory',
        description: 'APPLIANCES',
        pre_authorization_limit: '$225.0'
      }
    ]);
  }
  getRatesSysApproval(): Observable<any[]> {
    return of([
      {
        id: 1,
        effective_date: '',
        category: '',
        subcategory: '',
        description: '',
        pre_authorization_limit: ''
      }
    ]);
  }
  getRatesPartsMarkup(): Observable<any[]> {
    return of([
      {
        id: 1,
        effective_date: '2/18/2013',
        description: '0-100.00',
        range: '$0.00-$100.00',
        percentage: '17.00%'
      },
      {
        id: 2,
        effective_date: '2/18/2013',
        description: '100.00+',
        range: '$100.00-$5999.00',
        percentage: '12.00%'
      }
    ]);
  }
}
