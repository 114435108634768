<div class="crm-panel">
  <div class="form"></div>
  <app-section-header [title]="'Search Message Results'" [optionHeader]="op3" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="admin">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell">
            <div class="three-dots" (click)="ellipsisClick($event,'item',op3)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <div *ngIf="col.type === 'text'">{{rowData[col.field]}}</div>
          </td>
          <td class="last-cell">
            <div class="three-dots" (click)="ellipsisClick($event,'item',op3)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
  <p-overlayPanel #op3>
    <p-listbox [options]="ellipsisOptions" [(ngModel)]="selectedEllipsisItem"></p-listbox>
  </p-overlayPanel>
</div>