import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CheckBoxField } from '../../shared/form-field/checkbox-field';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { TextboxSearchField } from '../../shared/form-field/textbox-search';
import { FormField } from '../../shared/form-field/form-field';
import { TextAreaField } from '../../shared/form-field/textarea-field';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { Subject } from 'rxjs';
import {
  servicerDetailsFormQuery,
  createUpdateServicerProfileQuery,
  getServicerInfoByIdQuery,
  createUpdateServicerPreAuthDetails,
  createUpdategetServicerMarkup,
  createUpdateServicerExtraMilage,
  createUpdateServicerRatesSchedule,
  getCategoryDescriptionListQuery,
  getSubcategoryDescriptionListByCategoryQuery,
  getServicerProfilesContactInfoQuery,
  getServicerServiceTypesByNumberQuery,
  getServicerServiceAreaQuery,
  createUpdateServicerServiceAreaQuery,
  getServicerTypesOptionListQuery,
  createUpdateServicerTypes,
  getServicerSubListByCategoryQuery,
  getServicerCodeExist,
  createUpdateServicerProfileInfo
} from './servicer-data-queries.constant';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { FaxPattern, PhonePattern } from '../../shared/constants/validation-regex';

@Injectable()
export class ServicerService {
  Data: any;
  selectedServicerProfileId: any;
  servicerProfilesIdOriginal: any;
  constructor(private apollo: Apollo, private dropdownDataService: DropdownDataService) {}
  public serviceFormData: any;
  public contactFormData: any;
  public mailingAddress: any;
  public billingAddress: any;
  public generalFormData: any;
  public preAuth: any;
  public markupRates: any;
  public contactFormDataFinal: any;
  public mailingAddressFinal: any;
  public billingAddressFinal: any;
  public servicerLatLongData: any;
  public generalFormDataFinal: any;
  public preAuthFinal: any;
  public markupRatesFinal: any;
  public servicerExtraMilage: any;
  public servicerRatesSchedule: any;
  public contractConfig = {
    searchParams: {
      id: null,
      customerId: null
    }
  };
  public isLoading = new BehaviorSubject(true);
  public isServicerProfilesIdOriginalAvailable = new BehaviorSubject(false);

  getServicerCodeExist = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServicerCodeExist,
      variables: { servicerCode: searchParams.servicerCode },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getServicerDetailsForm = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: servicerDetailsFormQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getServicerInfoById = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServicerInfoByIdQuery,
      variables: { id: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getServicerProfilesContactInfo = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServicerProfilesContactInfoQuery,
      variables: { servicerProfileId: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getCategoryDescriptionList = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getCategoryDescriptionListQuery,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getSubcategoryDescriptionListByCategory = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getSubcategoryDescriptionListByCategoryQuery,
      variables: { categoryDescription: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getServicerServiceTypes = (servicerNumber, offset = 0, limit = 10) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServicerServiceTypesByNumberQuery,
      variables: {
        servicerNumber,
        offset,
        limit
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  formValidate = formData => {
    return formData.valid &&
      this.billingAddressFinal.valid &&
      this.mailingAddressFinal.valid &&
      this.contactFormDataFinal.valid
      ? true
      : false;
    // tslint:disable-next-line: semicolon
  };

  getServicerServiceArea = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServicerServiceAreaQuery,
      variables: { ...searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getServicerTypesOptionList = () =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getServicerTypesOptionListQuery,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getServicerSubListByCategory = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getServicerSubListByCategoryQuery,
      variables: { categoryDescription: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateServicerProfile = formData => {
    const addressMailing = {
      ...this.mailingAddress,
      ...this.mailingAddressFinal,
      isMailingAddress: true,
      country: this.mailingAddressFinal.country || 'United States'
    };
    const addressBilling = {
      ...this.billingAddress,
      ...this.billingAddressFinal,
      isBillingAddress: true,
      country: this.billingAddressFinal.country || 'United States'
    };

    const contactinfo = { ...this.contactFormData, ...this.contactFormDataFinal };
    contactinfo.businessPhoneTypeCode =
      contactinfo.businessPhoneTypeCode && contactinfo.businessPhoneTypeCode.value
        ? contactinfo.businessPhoneTypeCode.value
        : null;
    const servicerGroup = formData.servicerGroup ? formData.servicerGroup.map(x => x.value) : '';

    delete addressMailing.__typename;
    delete addressBilling.__typename;
    delete contactinfo.__typename;
    delete addressBilling.valid;
    delete addressMailing.valid;
    delete contactinfo.valid;
    //  delete contactinfo.contactId;
    //  delete contactinfo.categoryType;
    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerProfileQuery,
      variables: {
        servicerStatus: formData.servicerStatus ? formData.servicerStatus.value : 'A',
        servicerNumber: formData.servicerNumber,
        servicerGroup: servicerGroup ? servicerGroup.join() : '',
        servicerName: formData.servicerName,
        fedTax: formData.fedTax,
        dispatchMethod: formData.dispatchMethod ? formData.dispatchMethod.value : '',
        claimPayMethod: formData.claimPayMethod ? formData.claimPayMethod.value : '',
        isCreditCardProfile: formData.isCreditCardProfile ? true : false,
        configPaymentNotification: formData.configPaymentNotification
          ? formData.configPaymentNotification.value
          : '',
        accountingNumber: formData.accountingNumber,
        stateLicense: formData.stateLicense,
        currency: formData.currency ? formData.currency.value : 'USD',
        contractDate: formData.contractDate,
        selfServicingDealer: formData.selfServicingDealer ? true : false,
        servicerNotification:
          formData.servicerNotification && formData.servicerNotification.value === 'Yes'
            ? true
            : false,
        contractExpireDate: formData.contractExpireDate,
        servicerProfilesIdOriginal: formData.servicerProfilesIdOriginal,
        servicersId: formData.servicersId,
        addresses: [addressMailing, addressBilling],
        contactInfo: contactinfo,
        nationalServiceProvider: formData.nationalServiceProvider ? true : false,
        refundPayMethod: formData.refundPayMethod,
        serviceRecallPeriod: formData.serviceRecallPeriod,
        serviceRecallPeriodTerm: formData.serviceRecallPeriodTerm,
        invoiceSubmission: formData.invoiceSubmission,
        invoiceSubmissionTerm: formData.invoiceSubmissionTerm,
        contracted: formData.contracted,
        sealedSystemsRepair: formData.sealedSystemsRepair,
        partsVerificationRequired: formData.partsVerificationRequired,
        customerSatisfaction: formData.customerSatisfaction,
        rating: formData.rating,
        facilityType: formData.facilityType,
        latitude: formData.latitude,
        longitude: formData.longitude,
        statusCode: formData.statusCode,
        number: formData.number,
        name: formData.name,
        dealerGroupName: formData.dealerGroupName,
        dealerGroupNumber: formData.dealerGroupNumber,
        servicerCountry: formData.servicerCountry,
        dealerGroupId: formData.dealerGroupId,
        validInsurance: formData.validInsurance,
        insertUserName: formData.insertUserName,
        accountingNumber2: formData.routingNumber
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };

  createUpdateServicerPreAuthDetails = servicerPreAuth =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerPreAuthDetails,
      variables: {
        ...servicerPreAuth,
        servicerRatesIdOriginal: parseInt(servicerPreAuth.servicerRatesIdOriginal, 10),
        servicersId: parseInt(servicerPreAuth.servicersId, 10)
      }
      // tslint:disable-next-line: semicolon
    });

  createUpdategetServicerMarkup = servicerMarkup =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdategetServicerMarkup,
      variables: {
        ...servicerMarkup,
        servicerMarkupIdOriginal: parseInt(servicerMarkup.servicerMarkupIdOriginal, 10),
        percentage: parseFloat(servicerMarkup.percentage)
      }
      // tslint:disable-next-line: semicolon
    });

  createUpdateServicerExtraMilage = servicerMarkup =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerExtraMilage,
      variables: {
        ...servicerMarkup,
        servicerMarkupIdOriginal: parseInt(servicerMarkup.servicerMarkupIdOriginal, 10),
        percentage: parseFloat(servicerMarkup.percentage),
        term: parseInt(servicerMarkup.term, 10),
        rate: parseInt(servicerMarkup.rate, 10)
      }
      // tslint:disable-next-line: semicolon
    });

  createUpdateServicerRatesSchedule = servicerMarkup =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerRatesSchedule,
      variables: {
        ...servicerMarkup,
        servicerMarkupIdOriginal: parseInt(servicerMarkup.servicerMarkupIdOriginal, 10),
        percentage: parseFloat(servicerMarkup.percentage)
      }
      // tslint:disable-next-line: semicolon
    });

  createUpdateServicerServiceArea = serviceArea => {
    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerServiceAreaQuery,
      variables: {
        serviceAreas: [...serviceArea]
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };

  createUpdateServicerTypes = servicerType =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateServicerTypes,
      variables: {
        ...servicerType
      }
      // tslint:disable-next-line: semicolon
    });

  getContactInformationFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'firstName',
        label: 'Primary Contact First Name',
        required: true,
        order: 1
      }),
      new TextboxField({
        key: 'lastName',
        label: 'Primary Contact Last Name',
        order: 2
      }),
      new TextboxField({
        key: 'businessPhone',
        label: 'Business Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 3
      }),
      new DropdownField({
        key: 'businessPhoneTypeCode',
        label: 'Business Phone Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        required: true,
        order: 4
      }),
      new TextboxField({
        key: 'fax',
        label: 'Fax',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid fax number.',
        order: 5
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServicerProfileFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'servicerStatus',
        label: 'Status',
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Inactive', value: 'Inactive' },
          { key: 'Suspended', value: 'Suspended' }
        ],
        isSingleselect: true,
        order: 1
      }),
      new TextboxField({
        key: 'servicerNumber',
        label: 'Number',
        required: true,
        order: 2,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'Servicer Number',
          category: 'Servicer'
        }
      }),
      new DropdownField({
        key: 'servicerGroup',
        label: 'Servicer Group',
        options: [],
        isSingleselect: false,
        order: 3
      }),
      new TextboxField({
        key: 'servicerName',
        label: 'Name',
        required: true,
        order: 4
      }),
      new TextboxField({
        key: 'fedTax',
        label: 'Fed Tax ID',
        order: 5
      }),
      new DropdownField({
        key: 'dispatchMethod',
        label: 'Dispatch Method',
        options: [{ key: 'Email', value: 'Email' }, { key: 'Fax', value: 'Fax' }],
        isSingleselect: true,
        order: 6
      }),
      new DropdownField({
        key: 'claimPayMethod',
        label: 'Claim Pay Method',
        options: [
          { key: 'ACH', value: 'ACH' },
          { key: 'Check', value: 'Check' },
          { key: 'Credit', value: 'Credit' },
          { key: 'Credit Card', value: 'Credit Card' },
          { key: 'Dealer Statement Credit', value: 'Dealer Statement Credit' },
          { key: 'New Leaf Gift Card', value: 'New Leaf Gift Card' }
        ],
        order: 7,
        isSingleselect: true
      }),
      new CheckBoxField({
        key: 'isCreditCardProfile',
        label: '',
        type: 'checkbox',
        options: [{ key: 'isCreditCardProfile', value: 'CC only' }],
        order: 8
      }),
      new TextboxField({
        key: 'accountingNumber',
        label: 'Account Number',
        order: 9
      }),
      new TextboxField({
        key: 'stateLicense',
        label: 'State License',
        order: 10
      }),
      new DropdownField({
        key: 'currency',
        label: 'Currency',
        options: [{ key: 'USD', value: 'USD' }],
        order: 11,
        isSingleselect: true
      }),
      new TextboxField({
        key: 'contractDate',
        label: 'Contract Date',
        type: 'date',
        order: 12
      }),
      new TextboxField({
        key: 'routingNumber',
        label: 'Routing Number',
        order: 13
      }),
      new CheckBoxField({
        key: 'selfServicingDealer',
        label: '',
        type: 'checkbox',
        options: [{ key: 'selfServicingDealer', value: 'Self Servicing Dealer' }],
        order: 14
      }),
      new TextboxSearchField({
        key: 'selfServicingDealerSearch',
        label: '',
        order: 15
      }),
      new DropdownField({
        key: 'servicerNotification',
        label: 'Servicer Notification',
        options: [{ key: 'Yes', value: 'Yes' }, { key: 'No', value: 'No' }],
        isSingleselect: true,
        order: 16
      }),
      new TextboxField({
        key: 'contractExpireDate',
        label: 'Contract Exp Date',
        type: 'Date',
        order: 17
      }),
      new DropdownField({
        key: 'configPaymentNotification',
        label: 'Configure Payment Notification',
        options: [
          { key: 'No Notification', value: 'No Notification' },
          { key: 'New Leaf Payment Notification', value: 'New Leaf Payment Notification' }
        ],
        isSingleselect: true,
        order: 8
      }),
      new CheckBoxField({
        key: 'nationalServiceProvider',
        label: '',
        type: 'checkbox',
        options: [{ key: 'nationalServiceProvider', value: 'National Service Provider' }],
        order: 10
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getRatesFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'category',
        label: 'Category',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' }
        ],
        order: 1
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  createUpdateServicerProfileInfo = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createUpdateServicerProfileInfo,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });
}
