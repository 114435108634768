<div class="crm-container">
  <app-section-header [title]="'Theft'" />
  <div class="sub-section">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFieldsPart1">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
    </form>
    <contact-info [formFields]="contactInfo"></contact-info>
    <div class="title">Address</div>
    <address></address>
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-12">
          <form-field [formField]="formFields[11]" [form]="form"> </form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>