import gql from 'graphql-tag';

export const coverageDetailsFormQuery = gql`
  query($id: Int!) {
    getCoverageProfileById(coverageId: $id) {
      coverageCode
      coveragePlansId
      coverageCode
      coverageName
      coverageGroupCode
      coverageGroupDesc
      reportingGroupCode
      sKUReferenceCode
      priceTable
      eligibleFrom
      eligibleTo
      dop
      planType
      effectiveDateBasedOn
      condition
      upc
    }
  }
`;

export const coverageLossCodesFormQuery = gql`
  query($coverageId: Int!) {
    getCoverageProfileById(coverageId: $coverageId) {
      coverageCode
      coverageLossCodes {
        coverageLossCodeIdOriginal
        coveragePlanId
        coverageCode
        lossCode
        description
        termMonth
        type
        cost
      }
    }
  }
`;

export const coverageSettingFormQuery = gql`
  query($coverageCode: String) {
    getCoverageSettingsById(coverageCode: $coverageCode) {
      coveragePlansId
      coveragePlansIdOriginal
      coverageCode
      coverageName
      coverageGroupCode
      coverageGroupDesc
      reportingGroupCode
      reportingGroupDesc
      skuCode
      sKUReferenceCode
      priceTable
      eligibleFrom
      eligibleTo
      dop
      planType
      effectiveDateBasedOn
      condition
      upc
      sortOrder
      effectiveDateLag
      effectiveDateLagBy
      exclusionDays
      serviceLocation
      formTemplate
      purchaseTime
      msrp
      minMsrp
      maxMsrp
      regulatedRetail
      discountable
      minMarkup
      maxMarkup
      maxAmount
      maxPartMarkup
      paymentModel
      productCountSetting
      insurerNumber
      insurerName
      deductibleCollectedBy
      taxProfile
      cancellationRuleName
      defaultDispatchMethod
      aggregateContractLol
      remmitanceReceipient
      replacementCalculation
      ratingProviderCode
      contractId
      term
      isDeleted
      insertUserName
      addedTimeStamp
      isHistoryItem
      __typename
    }
  }
`;

export const associatedSubcategoriesQuery = gql`
  query($coverageCode: String) {
    getCoverageSettingsById(coverageCode: $coverageCode) {
      contractId
      coveragePlansId
      associatedSubCategory {
        contractsId
        subCategoryCode
        subcategoryDescription
        categoryDescription
        isSelected
        lossCodesId
        limitOfLiabilityOvr
      }
    }
  }
`;

export const insuranceCompanyQuery = gql`
  query($id: Int, $number: String) {
    getInsuranceCompanyProfileById(id: $id, number: $number) {
      insuranceComapaniesId
      status
      number
      name
      companyType
      fedTaxId
      billingMethod
      pendingBillingTransaction
      currency
      showOnRemittance
      includeVoidContractOnRemittance
      activateContractUponRemittance
      externalId
      insuranceComapaniesIdOriginal
      arAccount
      apAccount
      glAccount
      addresses(categoryType: InsuranceComapany) {
        addressIDOriginal
        address1
        address2
        state
        city
        zipCode
        country
        isMailingAddress
        isBillingAddress
      }
      contactInfo(categoryType: InsuranceComapany) {
        contactIdOriginal
        categoryType
        firstName
        lastName
        businessPhone
        fax
        email
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
    }
  }
`;

export const createUpdateCoverageLossCodeQuery = gql`
  mutation($coverageLossCodeInput: CoverageLossCodeInput) {
    createUpdateCoverageLossCode(coverageLossCodeInput: $coverageLossCodeInput) {
      coverageLossCodeIdOriginal
    }
  }
`;

export const createUpdateInsuranceCompanyQuery = gql`
  mutation(
    $insuranceComapaniesIdOriginal : Int
    $status : String
    $number : String
    $name : String
    $companyType : Int
    $fedTaxId : String
    $billingMethod : String
    $pendingBillingTransaction : String
    $currency : String
    $showOnRemittance : String
    $includeVoidContractOnRemittance : String
    $activateContractUponRemittance : String
    $externalId : String
    $arAccount : String
    $apAccount : String
    $glAccount : String
    $contactInfo: ContactInputType
    $addresses: [AddressInputType]
    $insertUserName : String
  ) {
    createUpdateInsuranceCompany(
      insuranceCompanyInput: {
        insuranceComapaniesIdOriginal : $insuranceComapaniesIdOriginal
        status : $status
        number : $number
        name : $name
        companyType : $companyType
        fedTaxId : $fedTaxId
        billingMethod : $billingMethod
        pendingBillingTransaction : $pendingBillingTransaction
        currency : $currency
        showOnRemittance : $showOnRemittance
        includeVoidContractOnRemittance : $includeVoidContractOnRemittance
        activateContractUponRemittance : $activateContractUponRemittance
        externalId : $externalId
        arAccount : $arAccount
        apAccount : $apAccount
        glAccount : $glAccount
        contactInfo: $contactInfo
        addresses: $addresses
        insertUserName : $insertUserName
      }
    ) {
      insuranceComapaniesId
      insuranceComapaniesIdOriginal
      number
      name
    }
  }
`;
