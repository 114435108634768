import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { RoleAuthorizationService } from '../../../services/role-authorization.service';
import { IRoleAuthorizationRouteService } from '../../../interfaces/role-authorization.interface';

@Injectable()
export class CrmTabsGuard implements CanActivate {
  constructor(private router: Router, private roleService: RoleAuthorizationService) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    const path = route.routeConfig.path;
    const routeService: IRoleAuthorizationRouteService = { component: CrmTabsGuard, path };
    if (this.roleService.isAuthorized(routeService)) {
      return true;
    }
    alert('You are not allowed to view this page');
    this.router.navigateByUrl('');
    return false;
  }
}
