import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisCrudOptions,
  ellipsisSubHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { ServicerServiceArea } from '../servicer-rates.service';
import { PriceTable } from '../../../../crm-coverage/coverage-models';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { ServicerService } from '../../servicer.service';
import { MessageService } from 'primeng/api';
import { validateInput } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { generalDateFormatter } from 'src/app/modules/crm/shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RolePermissionService } from 'src/app/modules/crm/common/role-permission.service';

@Component({
  selector: 'parts-markup-section',
  templateUrl: 'parts-markup.template.html',
  providers: [MessageService],
})
export class RatesPartsMarkupComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = ellipsisSubHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  currentId = 0;
  validateInput = validateInput;
  constructor(
    private servicerServiceArea: ServicerServiceArea,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private servicerService: ServicerService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {
    if (!['Admin', 'Claims Manager', 'Special Team A-CC-SN'].includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((x) => x.value !== 'delete');
    }
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    const tableData = [];
    if (this.servicerService.markupRates) {
      for (const singleMarkupRate of this.servicerService.markupRates) {
        const partsMarkup = Object.assign({}, singleMarkupRate);
        partsMarkup.effectiveDateFormatted = null;
        if (partsMarkup.effectiveDate && partsMarkup.effectiveDate !== '') {
          partsMarkup.effectiveDateFormatted = generalDateFormatter(partsMarkup.effectiveDate);
        }
        partsMarkup.range = `$${partsMarkup.rangeFrom.toFixed(2)}-$${partsMarkup.rangeTo.toFixed(
          2,
        )}`;
        partsMarkup.percentageFormatted = `${partsMarkup.percentage}%`;

        // set id for edit
        partsMarkup[`id`] = this.currentId = this.currentId + 1;
        tableData.push(partsMarkup);
      }
    }
    this.dataList = tableData;
    this.cols = [
      { field: 'effectiveDateFormatted', header: 'EFFECTIVE DATE', type: 'date' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'range', header: 'RANGE', type: 'textMask' },
      { field: 'percentageFormatted', header: 'PERCENTAGE', type: 'text' },
    ];
    this.doEvaluateRolePermissions();
  }
  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Service Rates',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Service Rates',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisHeaderOptionClick(event) {
    // ellipsis functionality goes here
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any, index: number) {
    delete this.clonedRowData[rowData.id];
    rowData.effectiveDateFormatted = generalDateFormatter(rowData.effectiveDateFormatted);

    [rowData.rangeFrom, rowData.rangeTo] = rowData.range ? rowData.range.split('-') : ['$0', '$0'];

    let payLoad = { ...rowData };

    if (rowData.isNew) {
      payLoad = {
        servicerName: this.servicerService.serviceFormData.servicerName,
        servicerNumber: this.servicerService.serviceFormData.servicerNumber,
        servicersId: this.servicerService.serviceFormData.servicerProfilesIdOriginal,
        effectiveDate: rowData.effectiveDateFormatted,
        description: rowData.description,
        rangeFrom: rowData.rangeFrom,
        rangeTo: rowData.rangeTo,
        percentage: `${
          parseFloat(
            rowData.percentageFormatted
              ? rowData.percentageFormatted.replace('$', '').replace(',', '')
              : 0,
          ) / 100
        }`,
        servicerMarkupIdOriginal: rowData.servicerMarkupIdOriginal,
        isDeleted: false,
        insertUserName: '',
        expenseType: rowData.expenseType,
      };
    } else {
      payLoad = {
        ...payLoad,
        percentage: `${
          parseFloat(
            rowData.percentageFormatted
              ? rowData.percentageFormatted.replace('$', '').replace(',', '')
              : 0,
          ) / 100
        }`,
        effectiveDate: rowData.effectiveDateFormatted,
      };
    }

    this.servicerServiceArea.isLoading.next(true);
    this.servicerService.createUpdategetServicerMarkup(payLoad).subscribe(
      ({ data }: any) => {
        this.isEditing = false;
        this.servicerServiceArea.isLoading.next(false);
        this.dataList[index].servicerMarkupIdOriginal = data.createUpdategetServicerMarkup
          ? data.createUpdategetServicerMarkup.servicerMarkupIdOriginal
          : null;
        this.dataList[index].servicerMarkupId = data.createUpdategetServicerMarkup
          ? data.createUpdategetServicerMarkup.servicerMarkupId
          : null;
        this.dataList[index].servicerName = this.servicerService.serviceFormData.servicerName;
        this.dataList[index].servicersId =
          this.servicerService.serviceFormData.servicerProfilesIdOriginal;
        this.dataList[index].servicerNumber = this.servicerService.serviceFormData.servicerNumber;

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Saved successfully.',
        });
      },
      (err) => {
        this.isEditing = false;
        this.servicerServiceArea.isLoading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error.',
        });
      },
    );
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.currentId = this.currentId + 1;
    rowItem[`isNew`] = true;
    rowItem[`range`] = '';
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  deletePartMarkup() {
    const rowData = this.selectedItem;
    rowData.effectiveDateFormatted = generalDateFormatter(rowData.effectiveDateFormatted);

    [rowData.rangeFrom, rowData.rangeTo] = rowData.range ? rowData.range.split('-') : ['$0', '$0'];

    let payLoad = { ...rowData };
    payLoad = {
      ...payLoad,
      percentage: `${
        parseFloat(
          rowData.percentageFormatted
            ? rowData.percentageFormatted.replace('$', '').replace(',', '')
            : 0,
        ) / 100
      }`,
      effectiveDate: rowData.effectiveDateFormatted,
      isDeleted: true,
    };
    this.servicerServiceArea.isLoading.next(true);
    this.servicerService.createUpdategetServicerMarkup(payLoad).subscribe(
      (data: any) => {
        this.servicerServiceArea.isLoading.next(false);
        this.dataList.splice(this.selectedRowIndex, 1);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Deleted successfully.',
        });
      },
      (err) => {
        this.servicerServiceArea.isLoading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error.',
        });
      },
    );
  }

  onRowDelete(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.deletePartMarkup();
      },
    });
  }
}
