import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { CrmGraphql } from 'src/app/modules/crm/shared/constants/graphql-constants';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { getKeyByValue } from 'src/app/modules/crm/shared/utilities/common-utilities';

// TODO : Replace  list with config values from db
export const SPCountryList = [{ key: 'USA', value: 'United States' }];
export const getServiceOrderRecallQuery = gql`
  query (
    $contractNumber: String
    $serviceOrderNumber: String
    $symptom: String
    $modelNumber: String
  ) {
    getServiceOrderRecall(
      contractNumber: $contractNumber
      serviceOrderNumber: $serviceOrderNumber
      symptom: $symptom
      modelNumber: $modelNumber
    ) {
      recall
      servicerName
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ServicePowerTableService {
  public selectedValue = [];
  public storedValues = {};
  constructor(
    private http: HttpClient,
    private azureService: AzureLoginService,
    private serviceOrderDataService: ServiceOrderDataService,
    private apollo: Apollo,
  ) {}

  getAppointmentSearch(availibilityParams, noDays = '14'): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const { productInfoModel } = this.serviceOrderDataService.serviceOrderDataModel;
    const productCode = getKeyByValue(
      this.serviceOrderDataService.servicePowerModal.servicePowerProductLines,
      productInfoModel.subcategoryCode,
    );
    const brandCode = getKeyByValue(
      this.serviceOrderDataService.servicePowerModal.servicePowerBrands,
      productInfoModel.manufacturerManufacturer,
    );
    const payload = {
      ProductInfo: {
        BrandCode:
          brandCode && brandCode.length > 0 ? brandCode : productInfoModel.manufacturerManufacturer,
        ProductCode:
          productCode && productCode.length > 0
            ? productCode
            : productInfoModel.subcategoryCode.replace('-', '').slice(0, 5),
      },
      ProductLocation: {
        PostcodeLevel1: availibilityParams.state,
        Postcode: availibilityParams.zipCode,
        Country: 'USA',
      },
      ServiceType: 'REP',
      WarrantyType: 'SC',
      SortBy: 'DATE',
      MulPrefServiceCenter: {
        PrefServiceCenterID: availibilityParams.prefferedServiceCenter,
      },
      SearchProcedure: [
        {
          ElementAttributes: {
            Name: 'StartDate',
            Value: availibilityParams.startDate,
          },
        },
        {
          ElementAttributes: {
            Name: 'NoDays',
            Value: noDays,
          },
        },
        {
          ProcedureCode: 'FS',
        },
      ],
    };
    const response = this.http.post(environment.servicePowerAsUrl, JSON.stringify(payload), {
      headers,
    });

    return response;
  }

  scheduleJob(selectedValues, isReschedule): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`,
    };
    const {
      claimData,
      productInfoModel,
      productInfo,
      customerInfo,
      contact,
      location,
      coverageDetails,
    } = this.serviceOrderDataService.serviceOrderDataModel;
    const { standardProblemDataModel } = this.serviceOrderDataService;
    const { state } = this.serviceOrderDataService.azureMapAddressInfo;
    const postalCode = location && location.zipCode ? location.zipCode.trim() : '';
    const address1 = location && location.address1 ? location.address1.trim() : '';
    const address2 = location && location.address2 ? location.address2.trim() : '';
    const city = location && location.city ? location.city.trim() : '';
    const stateProvince = state
      ? state.trim()
      : location && location.state
      ? location.state.value.trim()
      : '';
    let emailAddress =
      customerInfo && customerInfo.customerEmail ? customerInfo.customerEmail.trim() : '';
    const country = location && location.country ? location.country.value : '';
    const countryCode = getKeyByValue(SPCountryList, country);
    let phone = '';
    let lastName = '';
    let firstName = '';
    if (contact && contact.phoneNumber1) {
      phone = contact.phoneNumber1;
    } else if (customerInfo && customerInfo.customerPhone) {
      phone = customerInfo.customerPhone;
    }
    phone = phone.replace(/[^A-Za-z0-9]/g, "");

    if (this.serviceOrderDataService.servicePowerModal.spRejectedCancelled) {
      isReschedule = true;
    }

    if (contact && Object.keys(contact).length > 0) {
      if (contact.lastName) {
        lastName = contact.lastName;
      }
      if (contact.firstName) {
        firstName = contact.firstName;
      }
      if (contact.email && contact.email.length > 0) {
        emailAddress = contact.email.trim();
      }
    } else if (customerInfo && Object.keys(customerInfo).length > 0) {
      if (customerInfo.customerLastName) {
        lastName = contact.customerLastName;
      }
      if (customerInfo.customerLastName) {
        firstName = customerInfo.customerFirstName;
      }
    }
    const productCode = getKeyByValue(
      this.serviceOrderDataService.servicePowerModal.servicePowerProductLines,
      productInfoModel.subcategoryCode,
    );
    const brandCode = getKeyByValue(
      this.serviceOrderDataService.servicePowerModal.servicePowerBrands,
      productInfoModel.manufacturerManufacturer,
    );
    const probType = standardProblemDataModel.symptom;
    let failureDetails = standardProblemDataModel.failureDescription;
    if (selectedValues.recall === true) {
      failureDetails = 'REWORK' + '\n' + failureDetails;
    }

    let warrantyType = 'Service Contract';
    const oemWarranty = coverageDetails.find(
      (x) => x.name === 'OEM Warranty' && x.covered === 'YES' && x.selection,
    );
    if (oemWarranty) {
      warrantyType = 'In Warranty';
    }
    const geServicer = getKeyByValue(
      this.serviceOrderDataService.servicePowerModal.spPrefferedServicerList,
      'GEA Parts Services',
    );

    const payload = {
      ScheduleRequest: {
        CallNo: claimData.serviceOrderNumber,
        CallStatus: 'OPEN',
        ProductInfo: {
          BrandCode:
            brandCode && brandCode.length > 0
              ? brandCode
              : productInfoModel.manufacturerManufacturer,
          ProductCode:
            productCode && productCode.length > 0
              ? productCode
              : productInfoModel.subcategoryCode.replace('-', '').slice(0, 5),
          ModelNo: productInfoModel.modelNumber,
          SerialNo: productInfo.serialNumber,
        },
        ...(selectedValues.serviceProviderCenterId === geServicer && {
          PaymentInfo: {
            SvcContKey: claimData.serviceOrderNumber,
          },
        }),
        WarrantyType: getKeyByValue(
          this.serviceOrderDataService.servicePowerModal.spWarrantyTypes,
          warrantyType,
        ),
        ServiceType: getKeyByValue(
          this.serviceOrderDataService.servicePowerModal.spServiceTypes,
          'Repair',
        ),
        ServiceSite: getKeyByValue(
          this.serviceOrderDataService.servicePowerModal.spServiceSites,
          'In Home',
        ),
        OfferID: {
          GroupId: selectedValues.groupId,
          OfferToken: selectedValues.offerToken,
          Date: selectedValues.availableDate,
          TimePeriod: selectedValues.timePeriod,
        },
        ServiceCenterID: selectedValues.serviceProviderCenterId,
        ReSchedule: isReschedule ? 'YES' : 'NO',
        ProbCode: 10,
        ProbType: probType,
        ProbDesc: failureDetails,
        ConsumerInfo: {
          FirstName: firstName,
          LastName: lastName,
          Phone: phone,
          Email: emailAddress,
          Address1: address1,
          Address2: address2,
          Postcode: postalCode,
          PostcodeLevel1: stateProvince,
          PostcodeLevel3: city,
          Country: countryCode,
        },
      },
      ServicerDetails: {
        ServicerProfileID: selectedValues.servicerProfileID,
        ServiceProviderAddress: selectedValues.serviceProviderAddress,
        ServiceProviderPhone: selectedValues.serviceProviderPhone,
      },
      InsertUserName: this.azureService.accountId,
    };
    const response = this.http.post(environment.servicePowerCarUrl, JSON.stringify(payload), {
      headers,
    });

    return response;
  }

  getPriceTable(): Observable<any[]> {
    return of([
      {
        id: 1,
        select: true,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false,
      },
      {
        id: 3,
        select: false,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false,
      },
      {
        id: 3,
        select: false,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false,
      },
    ]);
  }
  getServiceOrderRecall = (data) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServiceOrderRecallQuery,
      variables: { ...data },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
}
