import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { CoverageFormFieldService } from '../../coverage-form-field.service';
import { InsuranceCompanyService } from '../insurance-company.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { MessageService } from 'primeng/api';
import _find from 'lodash/find';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cv-insurance-company-profile',
  templateUrl: 'insurance-company-profile.template.html',
  styleUrls: ['../../../shared/styles/left-details-form.scss'],
  providers: [FormFieldControlService, CoverageFormFieldService, MessageService],
})
export class InsuranceCompanyProfileComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  isLoading = false;
  insuranceComapaniesIdOriginal : number;
  querySubscription: Subscription[] = [];
  isSuccess: boolean = false;

  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: CoverageFormFieldService,
    private insuranceCompanyService: InsuranceCompanyService,
    private configurationService: ConfigurationTypeService,
    private azureService: AzureLoginService,
    private messageService: MessageService
  ) {
    super();
    this.service.getInsuranceCompanyFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: InsuranceCompanyProfileComponent,
      generalArray: this.formFields,
    };
    // this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'company_type',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const options = configList
          .filter((el) => el.menuType === 'company_type')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.formFields.find((el) => el.key === 'companyType').options = options;
        this.formValueChanged.emit(true);
      });
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: false, type: 'submit' },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: InsuranceCompanyProfileComponent,
      generalArray: this.button,
    };
    // this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    // if(this.insuranceCompanyService.insuranceCompanyDataModel.leftBaseSection){
    //   this.setFormValues();
    // }

    this.insuranceCompanyService.isDataAvailable.subscribe({
      next: (isDataAvailable) => {
        if (isDataAvailable) {            
          
          this.setFormValues();
        }
      }
    });
  }

  setFormValues() {
    this.formFields = this.formFields.map((formField) => {
      if (formField.key === 'number') {
        formField = { ...formField, disabled: true };
      }
      return formField;
    });
    this.form = this.qcs.toFormGroup(this.formFields);
    const data = this.insuranceCompanyService.insuranceCompanyDataModel.leftBaseSection;
    this.insuranceComapaniesIdOriginal = data.insuranceComapaniesIdOriginal;    
    this.form.patchValue({
      insuranceComapaniesId: data.insuranceComapaniesIdOriginal,
      status: {
        key: data.status,
        value: data.status,
      },
      number: data.number,
      name: data.name,
      companyType: {
        key: data.companyType?.toString(),
        value: data.companyType?.toString(),
      },
      fedTaxId: data.fedTaxId,
      billingMethod: {
        key: data.billingMethod,
        value: data.billingMethod,
      },
      pendingBillingTransaction: {
        key: data.pendingBillingTransaction,
        value: data.pendingBillingTransaction,
      },
      currency: {
        key: data.currency?.toUpperCase(),
        value: data.currency,
      },
      showOnRemittance: {
        key: data.showOnRemittance,
        value: data.showOnRemittance,
      },
      includeVoidContractOnRemittance: {
        key: data.includeVoidContractOnRemittance,
        value: data.includeVoidContractOnRemittance,
      },
      activateContractUponRemittance: {
        key: data.activateContractUponRemittance,
        value: data.activateContractUponRemittance,
      },
      externalId: data.externalId,
    });
  }

  private disableSubmit(param: boolean) {
    this.button = this.button.map(row => {
      if (row.name === 'Submit') {
        row.disabled = param;
      }
      return row;
    });
  }

  private validateFields() {
    const contact = this.insuranceCompanyService.insuranceCompanyDataModel.mainTab.contactInformation;
    const mailingAddress = this.insuranceCompanyService.insuranceCompanyDataModel.mainTab.mailingAddress;
    const billingAddress = this.insuranceCompanyService.insuranceCompanyDataModel.mainTab.billingAddress;

    if (!contact.firstName || !contact.businessPhone || !contact.businessPhoneTypeCode) {
      return false;
    }
    if (!mailingAddress.country || !mailingAddress.address1 || !mailingAddress.zipCode || !mailingAddress.state || !mailingAddress.city) {
      return false;
    }
    if (!billingAddress.country || !billingAddress.address1 || !billingAddress.zipCode || !billingAddress.state || !billingAddress.city) {
      return false;
    }
    return true;
  }

  onSubmit() {
    window.scroll(0, 0);
    if (!this.form.valid || !this.validateFields()) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please enter all mandatory fields`
      });
      return;
    }

    this.payLoad = {
      ...this.form.getRawValue(),
      insuranceComapaniesIdOriginal: this.insuranceComapaniesIdOriginal,
      insertUserName: this.azureService.accountId || null,
    };
    this.formValueChanged.emit(this.form.dirty);
    this.isLoading = true;
    this.disableSubmit(true);
    this.querySubscription.push(this.insuranceCompanyService
      .createUpdateInsuranceCompany(this.payLoad)
      .subscribe(
        ({ data, isLoading }: any) => {
          if (data.createUpdateInsuranceCompany) {
            this.insuranceComapaniesIdOriginal = data.createUpdateInsuranceCompany.insuranceComapaniesIdOriginal;
            this.refreshInsuranceCompany(this.insuranceComapaniesIdOriginal);
            this.disableSubmit(false);
            this.isLoading = isLoading;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Record Saved Successfully.`,
            });
            // setTimeout(() => {
            //   this.isLoading = isLoading;
            // }, 3000);
            // this.isSuccess = !isLoading;
            this.isLoading = isLoading;
          }
        },
        (err) => {
          this.isLoading = false;
          this.disableSubmit(false);
          throw err;
        },
      )
    )
  }

  refreshInsuranceCompany(id) {
    if (id) {
      this.isLoading = true;
      this.querySubscription.push(this.insuranceCompanyService.getInsuranceCompanyData(id).subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifiedData = data.getInsuranceCompanyProfileById;
          if (modifiedData) {
            this.insuranceCompanyService.insuranceCompanyDataModel.leftBaseSection = {
              insuranceComapaniesIdOriginal: modifiedData.insuranceComapaniesIdOriginal,
              status: modifiedData.status,
              number: modifiedData.number,
              name: modifiedData.name,
              companyType: modifiedData.companyType,
              fedTaxId: modifiedData.fedTaxId,
              billingMethod: modifiedData.billingMethod,
              pendingBillingTransaction: modifiedData.pendingBillingTransaction,
              currency: modifiedData.currency,
              showOnRemittance: modifiedData.showOnRemittance,
              includeVoidContractOnRemittance: modifiedData.includeVoidContractOnRemittance,
              activateContractUponRemittance: modifiedData.activateContractUponRemittance,
              externalId: modifiedData.externalId,
            };
            this.insuranceCompanyService.insuranceCompanyDataModel.mainTab = {
              contactInformation: {
                ...modifiedData.contactInfo,
                businessPhoneTypeCode: modifiedData.contactInfo.businessPhoneTypeCode
                  ? {
                    key: modifiedData.contactInfo.businessPhoneTypeCode,
                    value: modifiedData.contactInfo.businessPhoneTypeCode,
                  }
                  : null,
              },
              mailingAddress: _find(modifiedData.addresses, (add) => add.isMailingAddress),
              billingAddress: _find(modifiedData.addresses, (add) => add.isBillingAddress),
            };
            this.insuranceCompanyService.insuranceCompanyDataModel.account = {
              arAccount: modifiedData.arAccount,
              apAccount: modifiedData.apAccount,
              glAccount: modifiedData.glAccount,
            };
          }
          //this.setFormValues();
          this.insuranceCompanyService.setDataAvailability(true);
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      )
      )
    }
  }

  onChangeEvent(event, key) {
    if (key === 'number' && event.target.value && event.target.value.length > 0) {
      this.isLoading = true;
      this.insuranceCompanyService.getInsuranceCompanyData(null, event.target.value).subscribe(
        ({ data, loading }: any) => {
          if (data.getInsuranceCompanyProfileById) {
            this.form.controls.number.setErrors({ key: 'duplicateInsuranceNUmber' });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }

  ngOnDestroy() {
    this.querySubscription?.forEach((subs) => subs.unsubscribe());
  }
}
