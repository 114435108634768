import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
// Modules

import { QuillModule } from 'ngx-quill';

import { InlineSVGModule } from 'ng-inline-svg-2';

import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';
import { BlockUIModule } from 'primeng/blockui';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';

// Components
import { CrmLayoutComponent } from './crm-layout/crm-layout.component';
import { CrmSidebarComponent } from './crm-sidebar/crm-sidebar.component';
import { ContractsComponent } from './contracts/contracts.component';
import { DealerComponent } from './dealer/dealer.component';
import { NoteComponent } from './shared/notes/note.component';

import * as NavigationPanel from './shared/navigation-panel/index';
import * as Catalog from './catalog/index';
import { WorkQueueListModalComponent } from './catalog/work-queue-list/work-queue-list-modal/work-queue-list-modal.component';
import * as Coverage from './crm-coverage/index';
import * as Service from './service/index';

import { ContractDetailsFormComponent } from './contracts/contract-details-form/contract-details-form.component';

import { GenericFormFieldComponent } from './shared/form-field/generic-form-field.component';
import { CancelContractModalComponent } from './contracts/cancel-contract-modal/cancel-contract-modal.component';
import { HistoryItemModalComponent } from './shared/history-item-modal/history-item-modal.component';
import { ContractMainTabComponent } from './contracts/contract-main-tab/contract-main-tab.component';
import { CoveredProductsComponent } from './contracts/contract-main-tab/covered-products/covered-products.component';
import { AssociatedContractsComponent } from './contracts/contract-main-tab/associated-contracts/associated-contracts.component';
import { CustomerInformationComponent } from './contracts/contract-main-tab/customer-information/customer-information.component';
import { GeneralFieldsComponent } from './contracts/contract-main-tab/general-fields/general-fields.component';
import { AdditionalInfoComponent } from './contracts/contract-main-tab/additional-info/additional-info.component';
import { AddressComponent } from './shared/address/address.component';
import { SidebarLeftComponent } from './crm-sidebar/sidebar-left-navigation/sidebar-left-navigation.component';
import { SidebarRightComponent } from './crm-sidebar/sidebar-right-navigation/sidebar-right-navigation.component';
import { DealerDetailsFormComponent } from './crm-sidebar/sidebar-right-navigation/dealer-details-form/dealer-details-form.component';
import { SearchDealerLocationComponent } from './dealer/search-location/search-dealer-location.component';

import { CoverageTabComponent } from './contracts/coverage-tab/coverage-tab.component';
import { RateDetailsTabComponent } from './contracts/rate-details-tab/rate-details-tab.component';
import { DocumentsTabComponent } from './contracts/documents-tab/documents-tab.component';
import { InquiryTabComponent } from './contracts/inquiry-tab/inquiry-tab.component';
import { SoClaimsTabComponent } from './contracts/so-claims-tab/so-claims-tab.component';
import { RefundInfomationTabComponent } from './contracts/refund-information-tab/refund-information-tab.component';
import { ImportFileComponent } from './shared/import-file/import-file.component';
import { UserComponent } from './contracts/sidebar-components/user/user.component';
import { NameInfoComponent } from './contracts/sidebar-components/user/name-info/name-info.component';
import { UserContactInfoComponent } from './contracts/sidebar-components/user/user-contact-info/user-contact-info.component';
import { UsernameInfoComponent } from './contracts/sidebar-components/user/user-name-info/user-name-info.component';
import { UserRegistrationComponent } from './contracts/sidebar-components/user-registration/user-registration.component';
import { CardInfoComponent } from './contracts/sidebar-components/user-registration/card-info/card-info.component';
import { CustomerInfoComponent } from './contracts/sidebar-components/user-registration/customer-info/customer-info.component';
import { ServicePlanInfoComponent } from './contracts/sidebar-components/user-registration/service-plan-info/service-plan-info.component';
import { ProductCoveredInfoComponent } from './contracts/sidebar-components/user-registration/product-covered/product-covered.component';
import { WorkQueueComponent } from './contracts/sidebar-components/work-queue/work-queue.component';
import { InquiryQueueComponent } from './contracts/sidebar-components/inquiry-queue/inquiry-queue.component';
import { BatchProcessingComponent } from './contracts/sidebar-components/batch-processing/batch-processing.component';
import { TransactionManagementComponent } from './contracts/sidebar-components/transaction-management/transaction-management.component';

// BUYING GROUP
import { BuyingGroupComponent } from './buyingGroup/buyingGroup.component';
import { BuyingGroupMainTabComponent } from './buyingGroup/buying-group-main-tab/buying-group-main-tab.component';
import { SearchBuyingGroupComponent } from './buyingGroup/search-buying-group/search-buying-group.component';
import { BuyingGroupDetailsFormComponent } from './buyingGroup/buying-group-details-form/buying-group-details-form.component';
import { ContactInformationTabComponent } from './buyingGroup/buying-group-main-tab/contact-info/contact-info.component';
import { BuyingContactTabComponent } from './buyingGroup/contact-tab/contact-tab.component';
import { BuyingGroupDocumentsTabComponent } from './buyingGroup/document/document.component';
import { MailingAddressComponent } from './buyingGroup/buying-group-main-tab/mail-address/mail-address.component';
import { BillingAddressComponent } from './buyingGroup/buying-group-main-tab/billing-address/billing-address.component';
import { BuyingGroupDocumentUploadComponent } from './buyingGroup/buying-group-import-file/buying-group-file.component';

import { FormComponent } from './form/form.component';
import { TabsService } from './services/tabs.service';
import { RoleAuthorizationService } from '../../services/role-authorization.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { CrmTabsGuard } from './guards/tabs.guard';
import { SearchSetupAlertsComponent } from './notification/setup-alerts/setup-alerts.component';
import { SetupAlertModalComponent } from './notification/setup-alerts/setup-alerts-modal/setup-alert-modal.component';
import { DealerProfileFormComponent } from './dealer/dealer-profile-form/dealer-profile-form.component';
import { DealerProfileTabComponent } from './dealer/dealer-profile-tab/dealer-profile-tab.component';
import { ContactInformationComponent } from './dealer/dealer-profile-tab/contact-information/contact-information.component';
import { DealerLocationComponent } from './dealer/dealer-location/dealer-location.component';
import { PayeeComponent } from './dealer/payee/payee.component';
import { DealerLocationFormComponent } from './dealer/dealer-location/dealer-location-form/dealer-location-form.component';
import { DealerLocationTabComponent } from './dealer/dealer-location/dealer-location-tab/dealer-location-tab.component';
import { DealerLocationDocumentsTabComponent } from './dealer/dealer-location/documents-tab/documents-tab.component';
import { DealerServicerComponent } from './dealer/dealer-servicer/dealer-servicer.component';
import { PayeeMainFormComponent } from './dealer/payee/payee-main-form/payee-main-form.component';
import { PayeeMainTabComponent } from './dealer/payee/payee-main-tab/payee-main-tab.component';
import { RateProfileComponent } from './dealer/rate-profile/rate-profile.component';
import { SearchPayeeComponent } from './dealer/search-payee/search-payee.component';
import { SearchProfileComponent } from './dealer/search-profile/search-profile.component';

import { FailureTypeComponent } from './troubleshooting/failure-type/failure-type.component';
import { ResolutionComponent } from './troubleshooting/resolution/resolution.component';
import { SymptomOneComponent } from './troubleshooting/resolution/symptom-one/symptom-one.component';
import { FormModalComponent } from './troubleshooting/resolution/symptom-one/form-modal/form-modal.component';
import { SymptomTwoComponent } from './troubleshooting/resolution/symptom-two/symptom-two.component';
import { SymptomThreeComponent } from './troubleshooting/resolution/symptom-three/symptom-three.component';
import { QueueComponent } from './troubleshooting/queue/queue.component';

import { UsersFormComponent } from './crm-sidebar/sidebar-right-navigation/dealer-details-form/users.component';
import { RolesComponent } from './crm-sidebar/sidebar-right-navigation/dealer-details-form/roles.component';
import { PermissionsComponent } from './crm-sidebar/sidebar-right-navigation/dealer-details-form/permissions.component';
import { PermissionsConfigComponent } from './crm-sidebar/sidebar-right-navigation/dealer-details-form/permissions-configuration.component';
import { CfgFormComponent } from './crm-sidebar/sidebar-right-navigation/dealer-details-form/cfg.component';

import { SecurityPermissionsComponent } from './administration/security/sec-permission/sec-permission.component';
import { SecurityRolesComponent } from './administration/security/sec-roles/sec-roles.component';
import { SecUserComponent } from './administration/security/sec-user/sec-user.component';
import { SecPermConfigComponent } from './administration/security/sec-permission-configuration/sec-perm-config/sec-perm-config.component';
import { SecurityRolePermissionsComponent } from './administration/security/sec-role-permission/sec-role-permission.component';
import { SecurityRestatusComponent } from './administration/security/sec-restatus/sec-restatus.components';
import { PermissionOperationComponent } from './administration/security/sec-permission-configuration/operation/operation.component';
import { PermissionListComponent } from './administration/security/sec-permission-configuration/permission-list/permission-list.component';
import { SecModalComponent } from './administration/security/sec-modal/sec-modal.component';
import { ConfigurationComponent } from './administration/configuration/configuration.component';
import { EmailTemplateComponent } from './administration/email-temp/email-temp.component';
import { EmailTempFormComponent } from './administration/email-temp/form/form.component';
import { EmailTempImagesComponent } from './administration/email-temp/images/images.component';
import { ModalTempComponent } from './administration/email-temp/modal-temp/modal-temp.component';
import { FileAttachmentComponent } from './administration/email-temp/file-attachment/file-attachment.component';

import { ProcessClaimComponent } from './claims/process-claim/process-claim.component';
import { ClaimPaymentComponent } from './claims/claim-payment/claim-payment.component';
import { ImportClaimComponent } from './claims/import-claim/import-claim.component';
import { ResultComponent } from './claims/import-claim/result/result.component';
import { ImportFormComponent } from './claims/import-claim/form/import-form.component';

import { CRMIssueResolverComponent } from './crm-layout/issue-resolver.component';
import { SearchContractComponent } from './contracts/search-contract/search-contract.component';
import { CRMFormModalComponent } from './shared/form-modal/form-modal.component';
import { CRMInfoModalComponent } from './shared/info-modal/crm-info-modal.component';
import { ExportDialogComponent } from './shared/export-dialog/export-dialog.component';

import { DealerContactComponent } from './dealer/dealer-contact/dealer-contact.component';
import { ServicerDocumentUploadComponent } from './service/servicer/servicer-import-file/servicer-import-file.component';

import { InsuranceExtractComponent } from './reports/insurance-extract/insurance-extract.component';

// service
import { ConfirmationService, SharedModule } from 'primeng/api';
import { AzureLoginService } from '../../services/azure-login.service';
import { CRMDocumentModalComponent } from './shared/document-modal/document-modal.component';
import { GenericContactInformationComponent } from './shared/contact-information/contact-information.component';
import { ViewFileComponent } from './shared/view-file/view-file.component';
// import { DocumentFormModalComponent } from './contracts/documents-tab/document-form-modal/document-form-modal.component';
import { InquiryFormModalComponent } from './contracts/inquiry-tab/inquiry-tab-modal/inquiry-tab-modal.component';
import { DropdownDataService } from './services/dropdown.data.service';
import { ContractDocumentUploadComponent } from './contracts/contract-document-upload-tab/contract-document-upload-tab.component';
import { EmailService } from './services/email.service';
import { TabSpinnerComponent } from './shared/tab-spinner/tab-spinner.component';
import { ProblemReportComponent } from './service/service-order/service-details/problem-report/problem-report.component';
import { ProblemReportModalComponent } from './service/service-order/service-details/problem-report-modal/problem-report-modal.component';
import { TabHandlerService } from './services/tab-handler.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { CrmErrorHandler } from './crm-error-handler';
import { DealerLocationUploadComponent } from './dealer/dealer-location/dealer-location-upload-tab/dealer-location-upload.component';
import { DealerDocumentsTabComponent } from './dealer/documents-tab/documents-tab.component';
import { DealerDocumentUploadComponent } from './dealer/dealer-document-upload-tab/dealer-document-upload-tab.component';
import { SendVtShortKeyEmailComponent } from './service/service-order/send-vt-short-key-email/send-vt-short-key-email.component';
import { CrmService } from './services/crm-service';
import { DealerLocationContactComponent } from './dealer/dealer-location/dealer-location-contact/dealer-contact.component';
import { FeatureFlagsService } from './services/feature-flags.service';
import { ApiConfigService } from './services/api-config.service';
import { ServiceInfoModalComponent } from './service/servicer/servicer-service-info/service-info-modal/service-info-modal.component';
import { ServiceTypeModalComponent } from './service/servicer/servicer-service-type/service-type-modal/service-type-modal.component';
import { CrmAlertModalComponent } from './shared/crm-alert-modal/crm-alert-modal.component';
// tslint:disable-next-line:max-line-length
import { TransactionModalComponent } from './contracts/sidebar-components/transaction-management/transaction-management-modal/transaction-management-modal.component';

import { DirectivesModule } from '../../directives/directives.module';
import { SessionModalModule } from 'src/app/components/presentation/session-modal/session-modal.module';
import { KairosModule } from '../kairos/kairos.module';
import { Apollo } from 'apollo-angular';
import { ApolloService } from 'src/app/services/apollo.service';
import { LoginService } from 'src/app/services/login.service';
import { ApiService } from 'src/app/services/api.service';
import { ClaimsLookup } from './shared/constants/lookup-list';
import { SectionHeaderComponent } from './common/section-header/section-header.component';
import { RolePermissionService } from './common/role-permission.service';
import { ServiceOrderDataService } from './service/service-order/service-order-data.service';
import { ServiceOrderService } from './service/service-order/service-order.service';

@NgModule({
  imports: [
    QuillModule,
    ChartModule,
    SessionModalModule,
    DirectivesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    AccordionModule,
    OverlayPanelModule,
    ListboxModule,
    FormsModule,
    TabViewModule,
    DropdownModule,
    EditorModule,
    MultiSelectModule,
    FileUploadModule,
    // RouterModule.forChild(routes),
    PanelModule,
    CheckboxModule,
    ToastModule,
    RadioButtonModule,
    ButtonModule,
    TooltipModule,
    InputTextModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    SharedModule,
    MessageModule,
    ProgressBarModule,
    BlockUIModule,
    AutocompleteLibModule,
    CalendarModule,
    InlineSVGModule,
    KairosModule,
  ],
  declarations: [
    CrmLayoutComponent,
    CrmSidebarComponent,
    ContractsComponent,
    DealerComponent,
    NavigationPanel.NavDirective,
    NavigationPanel.NavLoaderComponent,
    NavigationPanel.NavigationPanelComponent,
    NavigationPanel.TabComponent,
    NavigationPanel.TabsComponent,
    CancelContractModalComponent,
    HistoryItemModalComponent,
    ContractDetailsFormComponent,
    GenericFormFieldComponent,
    ContractMainTabComponent,
    CoveredProductsComponent,
    AssociatedContractsComponent,
    CustomerInformationComponent,
    GeneralFieldsComponent,
    AdditionalInfoComponent,
    AddressComponent,
    SidebarLeftComponent,
    SidebarRightComponent,
    DealerDetailsFormComponent,
    NoteComponent,
    SearchDealerLocationComponent,
    CoverageTabComponent,
    RateDetailsTabComponent,
    DocumentsTabComponent,
    InquiryTabComponent,
    SoClaimsTabComponent,
    RefundInfomationTabComponent,
    ImportFileComponent,
    UserComponent,
    NameInfoComponent,
    UserContactInfoComponent,
    UsernameInfoComponent,
    UserRegistrationComponent,
    CardInfoComponent,
    CustomerInfoComponent,
    ServicePlanInfoComponent,
    ProductCoveredInfoComponent,
    WorkQueueComponent,
    InquiryQueueComponent,
    BatchProcessingComponent,
    TransactionManagementComponent,
    BuyingGroupComponent,
    SearchBuyingGroupComponent,
    BuyingGroupMainTabComponent,
    BuyingGroupDetailsFormComponent,
    ContactInformationTabComponent,
    BuyingGroupDocumentsTabComponent,
    BuyingContactTabComponent,
    MailingAddressComponent,
    BillingAddressComponent,
    Catalog.CatalogComponent,
    Catalog.OEMWarrantyComponent,
    Catalog.ManufacturerComponent,
    Catalog.PositionComponent,
    Catalog.CategoryComponent,
    Catalog.SubcategoryComponent,
    Catalog.SalesRepresentativeComponent,
    Catalog.PricePaidComponent,
    Catalog.WorkQueueListComponent,
    FormComponent,
    SearchSetupAlertsComponent,
    SetupAlertModalComponent,
    FailureTypeComponent,
    ResolutionComponent,
    QueueComponent,

    // Symptom
    SymptomOneComponent,
    SymptomTwoComponent,
    SymptomThreeComponent,
    FormModalComponent,
    DealerProfileTabComponent,
    DealerLocationTabComponent,
    DealerLocationDocumentsTabComponent,
    DealerLocationUploadComponent,
    DealerDocumentsTabComponent,
    DealerDocumentUploadComponent,
    PayeeMainTabComponent,
    PayeeComponent,
    DealerLocationComponent,
    DealerProfileFormComponent,
    ContactInformationComponent,
    PayeeMainFormComponent,
    DealerLocationFormComponent,
    ConfigurationComponent,
    RateProfileComponent,
    SearchPayeeComponent,
    SearchProfileComponent,
    UsersFormComponent,
    RolesComponent,
    PermissionsComponent,
    PermissionsConfigComponent,
    PermissionOperationComponent,
    PermissionListComponent,
    CfgFormComponent,
    SecUserComponent,
    SecurityRolesComponent,
    SecurityPermissionsComponent,
    SecurityRolePermissionsComponent,
    SecurityRestatusComponent,
    SecPermConfigComponent,
    SecModalComponent,
    DealerLocationFormComponent,
    InsuranceExtractComponent,
    Coverage.CoverageComponent,
    Coverage.CoverageDetailsFormComponent,
    Coverage.CoverageTabComponent,
    Coverage.CoverageSettingComponent,
    Coverage.ExceptionsComponent,
    Coverage.RatingComponent,
    Coverage.RatingModalComponent,
    Coverage.CoverageFormComponent,
    Coverage.ApprovedStateComponent,
    Coverage.AssociatedSubcategoriesComponent,
    Coverage.ComponentsComponent,
    Coverage.LossCodesComponent,
    Coverage.PriceTableComponent,
    Coverage.RateBucketConfigComponent,
    Coverage.RateBucketCommonComponent,
    Coverage.CancellationMethodComponent,
    Coverage.CancellationRuleComponent,
    Coverage.CoverageGroupComponent,
    Coverage.InsuranceCompanyComponent,
    Coverage.InsuranceCompanyProfileComponent,
    Coverage.InsuranceCompanyMainComponent,
    Coverage.InsuranceCompanyAccountingComponent,
    Coverage.ProductTypeComponent,
    Coverage.SearchCoverageComponent,
    Coverage.SearchInsuranceCompanyComponent,
    ProcessClaimComponent,
    ClaimPaymentComponent,
    ImportClaimComponent,
    ResultComponent,
    CRMIssueResolverComponent,
    CRMFormModalComponent,
    EmailTemplateComponent,
    EmailTempFormComponent,
    EmailTempImagesComponent,
    ModalTempComponent,
    FileAttachmentComponent,
    CRMIssueResolverComponent,
    Service.ServiceOrderComponent,
    Service.ServiceOrderFormComponent,
    Service.ServiceOrderSummaryComponent,
    Service.SummarySectionComponent,
    Service.ProductInfoTableComponent,
    Service.CoverageDetailsComponent,
    Service.ProblemComponent,
    Service.ServiceDetailsComponent,
    Service.TechnicalInformationComponent,
    Service.ExpenseChargesComponent,
    Service.GeneratePreAuthComponent,
    Service.ServiceOrderHistoryComponent,
    Service.ServiceAttachmentsComponent,
    Service.ServiceClaimPaymentComponent,
    Service.ServiceCreditCardInfoComponent,
    Service.ExpenseChargesTotalComponent,
    Service.ServiceStatusLogComponent,
    Service.ServiceAuditComponent,
    Service.ServiceAuditTableComponent,
    Service.SearchServiceOrderComponent,
    Service.ProblemModalComponent,
    Service.ValidatorToolModalComponent,
    Service.StandardProblemComponent,
    Service.ADHProblemComponent,
    Service.TheftProblemComponent,
    Service.ServicerDispatchModalComponent,
    Service.ServicersTableComponent,
    Service.SendEmailModalComponent,
    Service.ServiceBenchModalComponent,
    Service.ServiceBenchTableComponent,
    Service.ServicePowerModalComponent,
    Service.ServicePowerTableComponent,
    Service.ServiceAttachmentsComponent,
    Service.LimitOfLiabilityModalComponent,
    CRMFormModalComponent,
    CRMInfoModalComponent,
    ExportDialogComponent,
    CRMIssueResolverComponent,
    SearchContractComponent,
    ImportFormComponent,
    Service.SubmitProviderComponent,
    Service.SearchServicerComponent,
    Service.ServiceDashboardComponent,
    Service.ServiceCenterLookupComponent,
    Service.ClaimAdjudicationComponent,
    Service.ServiceClaimPaymentsComponent,
    Service.ClaimAdjudicationModalComponent,
    Service.ServiceSearchModalComponent,
    Service.ServicerComponent,
    Service.ServicerMainTabComponent,
    Service.ServicerProfileFormComponent,
    Service.ServicerDocumentsComponent,
    Service.ServicerContactComponent,
    Service.ServicerServiceTypeComponent,
    Service.ServicerInsuranceComponent,
    Service.ServicerServiceAreaComponent,
    Service.ServicerServiceInfoComponent,
    Service.ServicerRatesComponent,
    Service.RatesAdjudicationLimitComponent,
    Service.RatesSystemApprovalComponent,
    Service.RatesPartsMarkupComponent,
    Service.RatesExtraMileageComponent,
    Service.RatesScheduleComponent,
    Service.RateFormComponent,
    Service.ServicerServiceFormComponent,
    Service.ServicerImportFileComponent,
    Service.ExpenseChargesModalComponent,
    CRMDocumentModalComponent,
    GenericContactInformationComponent,
    ViewFileComponent,
    // DocumentFormModalComponent,
    InquiryFormModalComponent,
    ContractDocumentUploadComponent,
    BuyingGroupDocumentUploadComponent,
    ServicerDocumentUploadComponent,
    Service.ServiceOrderUploadComponent,
    TabSpinnerComponent,
    ProblemReportComponent,
    ProblemReportModalComponent,
    SendVtShortKeyEmailComponent,
    DealerContactComponent,
    DealerLocationContactComponent,
    DealerServicerComponent,
    ServiceInfoModalComponent,
    ServiceTypeModalComponent,
    CrmAlertModalComponent,

    // SKIP
    WorkQueueListModalComponent,
    TransactionModalComponent,
    SectionHeaderComponent,
  ],
  entryComponents: [
    ContractMainTabComponent,
    CoverageTabComponent,
    CustomerInformationComponent,
    SidebarLeftComponent,
    SidebarRightComponent,
    DealerDetailsFormComponent,
    RateDetailsTabComponent,
    DocumentsTabComponent,
    UserComponent,
    InquiryTabComponent,
    SoClaimsTabComponent,
    RefundInfomationTabComponent,
    ImportFileComponent,
    SearchSetupAlertsComponent,
    SetupAlertModalComponent,
    BuyingGroupComponent,
    SearchBuyingGroupComponent,
    BuyingGroupMainTabComponent,
    BuyingGroupDetailsFormComponent,
    BuyingGroupDocumentsTabComponent,
    BuyingContactTabComponent,
    MailingAddressComponent,
    BillingAddressComponent,
    DealerProfileTabComponent,
    DealerLocationTabComponent,
    DealerLocationDocumentsTabComponent,
    DealerLocationUploadComponent,
    DealerDocumentsTabComponent,
    DealerDocumentUploadComponent,
    PayeeMainTabComponent,
    Coverage.CoverageTabComponent,
    Coverage.CoverageSettingComponent,
    Coverage.ExceptionsComponent,
    Coverage.RatingComponent,
    Coverage.RatingModalComponent,
    Coverage.CoverageFormComponent,
    Coverage.ApprovedStateComponent,
    Coverage.AssociatedSubcategoriesComponent,
    Coverage.InsuranceCompanyMainComponent,
    Coverage.InsuranceCompanyAccountingComponent,
    ImportClaimComponent,
    Service.ServiceOrderSummaryComponent,
    Service.ServiceDetailsComponent,
    Service.ServiceOrderHistoryComponent,
    Service.ServiceAttachmentsComponent,
    Service.ServiceClaimPaymentComponent,
    Service.ServiceCreditCardInfoComponent,
    Service.ServiceStatusLogComponent,
    Service.ServiceAuditComponent,
    Service.StandardProblemComponent,
    Service.ADHProblemComponent,
    Service.TheftProblemComponent,
    Service.ServicerMainTabComponent,
    Service.ServicerDocumentsComponent,
    Service.ServicerContactComponent,
    Service.ServicerServiceTypeComponent,
    Service.ServicerInsuranceComponent,
    Service.ServicerServiceAreaComponent,
    Service.ServicerServiceAreaComponent,
    Service.ServicerServiceInfoComponent,
    Service.ServicerRatesComponent,
    Service.RatesAdjudicationLimitComponent,
    Service.RatesSystemApprovalComponent,
    Service.RatesPartsMarkupComponent,
    Service.RatesExtraMileageComponent,
    Service.RatesScheduleComponent,
    Service.RateFormComponent,
    Service.ServicerServiceFormComponent,
    Service.ServicerImportFileComponent,
    // DocumentFormModalComponent,
    InquiryFormModalComponent,
    ContractDocumentUploadComponent,
    BuyingGroupDocumentUploadComponent,
    ServicerDocumentUploadComponent,
    Service.ServiceOrderUploadComponent,
    DealerContactComponent,
    DealerLocationContactComponent,
    DealerServicerComponent,
    CrmAlertModalComponent,
    WorkQueueListModalComponent,
    TransactionModalComponent,
  ],
  providers: [
    RolePermissionService,
    DatePipe,
    CrmTabsGuard,
    RoleAuthorizationService,
    TabsService,
    Apollo,
    ApolloService,
    ConfirmationService,
    AzureLoginService,
    CanDeactivateGuard,
    DropdownDataService,
    EmailService,
    TabHandlerService,
    {
      provide: ErrorHandler,
      useClass: CrmErrorHandler,
    },
    ErrorHandlerService,
    CrmService,
    FeatureFlagsService,
    ApiConfigService,
    LoginService,
    ApiService,
    ClaimsLookup,
    ServiceOrderDataService,
    ServiceOrderService
  ],
  exports: [ToastModule],
  bootstrap: [CrmLayoutComponent],
})
export class CrmModule {}
