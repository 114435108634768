import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';

@Component({
  selector: 'contact-info',
  templateUrl: 'contact-info.template.html',
  styleUrls: ['contact-info.scss'],
  providers: [FormFieldControlService],
})
export class ContactInformationTabComponent extends FormCanDeactivate implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: FormFieldControlService) {
    super();
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }
}
