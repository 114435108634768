import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { ServiceTypeService } from '../services.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisHeaderOptions } from '../../service/service-ellipsis';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ServiceClaimPayment } from './service-claim-payment.model';
import { ServiceClaimPaymentService } from './service-claim-payment.service';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { FormField } from '../../shared/form-field/form-field';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { generalDateFormatter } from '../../shared/utilities/date-utilities';
import { copy } from '../../shared/utilities/common-utilities';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'service-claim-payment',
  templateUrl: 'service-claim-payment.template.html',
  providers: [ServiceTypeService, FormFieldControlService],
})
export class ServiceClaimPaymentsComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  cols: any[];
  dataList: any[];
  claimPaymentList: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = [{ label: 'Edit', value: 'Edit', callback: 'onRowEdit' }];
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayVerify: boolean;
  selectedColumns: any[];
  selectedRowIndex: any;
  isLoading: boolean;
  lastMaxId: any;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  displayDialog: boolean;
  querySubscriptions: Subscription[] = [];
  constructor(
    private configurationService: ServiceTypeService,
    private cdr: ChangeDetectorRef,
    private serviceClaimPaymentService: ServiceClaimPaymentService,
    private qcs: FormFieldControlService,
    private datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.lastMaxId = 1;
    this.isLoading = true;
    const queryParam = {
      fileName: this.searchParams.fileName ? this.searchParams.fileName.trim() : null,
      status: this.searchParams.status ? this.searchParams.status.key : '',
      uploadedDate: this.searchParams.uploadedDate ? this.searchParams.uploadedDate : null,
      remittanceDate: this.searchParams.remittanceDate ? this.searchParams.remittanceDate : null,
      claimCount: !isNaN(this.searchParams?.claimCount) ? parseInt(this.searchParams.claimCount, 10) : null
    };

    this.getClaimPayeeResults(queryParam);

    this.cols = [
      { field: 'fileStatus', header: 'FILE STATUS', type: 'text' },
      { field: 'fileName', header: 'FILE NAME', type: 'text' },
      { field: 'uploadDate', header: 'UPLOAD DATE', type: 'Date' },
      { field: 'remittanceDate', header: 'REMITTANCE DATE', type: 'Date' },
      { field: 'claimCount', header: 'CLAIM COUNT', type: 'text' },
      { field: 'totalPaid', header: 'TOTAL PAID', type: 'text' },
    ];
    this.selectedColumns = this.cols.slice(0, 10);

    this.querySubscriptions.push(
      this.serviceClaimPaymentService
        .getRemittanceFormFields()
        .subscribe((data: FormField<string>[]) => {
          this.formFields = data;
          this.form = this.qcs.toFormGroup(this.formFields);
          this.form.valueChanges.subscribe((x) => {
            this.formValueChanged.emit(true);
          });
        }),
    );
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onClickAdd(event) {
    console.log('On Add');
    console.log(event);
  }

  getClaimPayeeResults(queryParam){
    this.querySubscriptions.push(
      this.serviceClaimPaymentService.getClaimPaymentsResults(queryParam).subscribe(({ data }) => {
        this.dataList = data.getClaimPaymentsResults;
        this.setDataId(this.dataList);
        this.isLoading = false;
      }),
    );
  }

  onClickPrint(event) {
    let rowItem = new ServiceClaimPayment();
    rowItem.id = this.lastMaxId++;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  displayEditModal() {
    this.displayDialog = true;

    this.form.patchValue({
      remittanceDate: null,
      totalPaid: this.selectedItem.totalPaid,
    });
  }

  setDataId(data) {
    this.claimPaymentList = this.dataList;
    this.dataList = data.map((e) => {
      const d = Object.assign({}, e);
      d.id = this.lastMaxId++;
      d.uploadDate = this.datePipe.transform(d.uploadDate,'MM/dd/YYYY');
      d.remittanceDate = this.datePipe.transform(d.remittanceDate,'MM/dd/YYYY');
      return { ...d };
    });
  }

  updateRemittanceDate() {
    const rowItem = this.form.getRawValue();
    this.selectedItem.remittanceDate = rowItem.remittanceDate;
    this.selectedItem.totalPaid = rowItem.totalPaid;
    this.selectedItem.fileStatus = 'REMITTED';
    this.selectedItem.uploadDate = this.claimPaymentList.find(m => m.claimPaymentsIdOriginal === this.selectedItem.claimPaymentsIdOriginal).uploadDate;

    this.isLoading = true;
    this.querySubscriptions.push(
      this.serviceClaimPaymentService.saveClaimPaymentNotification(this.selectedItem).subscribe(
        ({ data, loading }: any) => {
          this.isLoading = true;
          const queryParam = { fileName: '' };
          if (this.searchParams && (this.searchParams.fileName || this.searchParams.status)) {
              if (Boolean(this.searchParams.fileName)) {
                queryParam[`fileName`] = this.searchParams.fileName;
              }
              if (Boolean(this.searchParams.status)) {
                if(this.selectedItem.fileStatus === this.searchParams.status.key){
                queryParam[`status`] = this.searchParams.status.key;
                }
              }
          }
          this.getClaimPayeeResults(queryParam);
        },
        (err) => {
          this.isLoading = false;
        },
      ),
    );
    this.displayDialog = false;
    this.formValueChanged.emit(this.form.dirty);
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
