import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { CheckBoxField } from '../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { FormField } from '../../../shared/form-field/form-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';

const unassociateContractsQuery = gql`
  mutation($contractList: String, $insertUserName: String) {
    unassociateContract(
      contractListInput: { contractList: $contractList, insertUserName: $insertUserName }
    ) {
      contractList
    }
  }
`;

@Injectable()
export class AssociatedContractService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  getFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'contractList',
        label: 'Contract Number List',
        isSingleselect: false,
        options: [{ key: 'file1', value: 'file1' }, { key: 'file2', value: 'file2' }],
        showToggleAll: true,
        order: 1
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  unassociateContractsRequest = data =>
    this.apollo.use('crm').mutate<any>({
      mutation: unassociateContractsQuery,
      variables: { ...data }
      // tslint:disable-next-line: semicolon
    });
}
