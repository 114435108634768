<app-tab-spinner *ngIf="isLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; width:100%;height: 100%;" />
<div class="crm-panel" *ngIf="!isLoading" style="position: relative; box-shadow: 0 0 100px rgba(0,0,0,0.3);">
  <div class="crm-top">
    <div class="crm-panel__left">
      <dealer-location-form (formValueChanged)="setChangeData($event)"></dealer-location-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <div class="crm-bottom" *ngIf="componentDisplay">
    <crm-notes [category]="addressCategoryType.Seller" [identifierId]="sellerId" style="width: 100%;"
      [parentComponent]="component"></crm-notes>
  </div>
</div>