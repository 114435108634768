import gql from 'graphql-tag';

export const getSubListByCategoryQuery = gql`
  query($categoryDescription: [String]!) {
    getSubcategoryListByCategory(categoryDescription: $categoryDescription) {
      categoryId
      subCategoryId
      description
      parentCategory
    }
  }
`;

export const getDealerLocationByDealerQuery = gql`
  query($dealerNumbers: [String]) {
    getAllDealerLocations(dealerNumber: $dealerNumbers) {
      key
      value
    }
  }
`;

export const createupdateAlertDataQuery = gql`
  mutation($alertInput: AlertInput) {
    createUpdateAlertData(alertInput: $alertInput) {
      alertIdOriginal
    }
  }
`;

export const getAllAlertDataQuery = gql`
  query(
    $alertStatus: String
    $alertMessage: String
    $moduleName: String
    $productPlanTypes: String
    $subCategories: String
    $manufacturers: String
    $buyingGroups: String
    $dealers: String
    $dealerLocations: String
    $category: String
    $offset: Int
    $limit: Int
  ) {
    getAlertData(
      alertStatus: $alertStatus
      alertMessage: $alertMessage
      moduleName: $moduleName
      productPlanTypes: $productPlanTypes
      subCategories: $subCategories
      manufacturers: $manufacturers
      buyingGroups: $buyingGroups
      dealers: $dealers
      dealerLocations: $dealerLocations
      category: $category
      offset: $offset
      limit: $limit
    ) {
      alertIdOriginal
      message
      alertStatus
      effectiveDateTo
      effectiveDateFrom
      data {
        alertFilterId
        alertFilterType
        refData {
          referenceData
          referenceLabel
        }
      }
    }
  }
`;
