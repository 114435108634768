import { Component, Output, EventEmitter, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormField } from '../../shared/form-field/form-field';
import { NavigationPanel } from '../../shared/navigation-panel/navigation-panel.model';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DealerFormFieldService } from '../../services/dealer-form-field.service';
import { NavigationPanelComponent } from '../../shared/navigation-panel';
import { PayeeMainTabComponent } from './payee-main-tab/payee-main-tab.component';
import { PayeeService } from './payee.service';
import { NoteComponent } from '../../shared/notes/note.component';

@Component({
  selector: 'payee',
  templateUrl: 'payee.template.html',
  styleUrls: ['payee.scss'],
  providers: [DealerFormFieldService, PayeeService],
})
export class PayeeComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  querySubscription: any;
  isLoading = false;
  component = PayeeComponent;
  componentDisplay = false;
  constructor(
    service: DealerFormFieldService,
    private roleService: RoleAuthorizationService,
    private payeeDataService: PayeeService,
  ) {
    this.formFields$ = service.getDealerMainFormFields();

    // set up left-tab navigation
    this.navigationPanel = [
      { component: PayeeMainTabComponent, data: { name: 'Main' }, title: 'MN', tabname: 'Main' },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: NavigationPanelComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(roleService.applyAuthorization(this.authDetails));
  }

  ngOnInit() {
    this.componentDisplay = this.roleService.isVisible(NoteComponent, PayeeComponent);
    const profileId = !isNaN(this.searchParams) ? parseInt(this.searchParams, 10) : 0;
    if (profileId) {
      this.isLoading = true;
      this.querySubscription = this.payeeDataService.getPayeeDetailsForm(profileId).subscribe(
        ({ data, loading }: any) => {
          const { addresses, contactInfo, ...payeeFormData } = data.getPayeeProfileById;
          this.payeeDataService.payeeBillingAddress = addresses ? addresses[0] : null;
          this.payeeDataService.payeeMailingAddress = addresses ? addresses[0] : null;
          this.payeeDataService.payeeContactInfo = contactInfo;
          this.payeeDataService.payeeFormData = payeeFormData;
          this.isLoading = loading;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
}
