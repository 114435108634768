import { Component, Output, EventEmitter, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ContractFormFieldService } from '../services/contract-form-field.service';
import { FormField } from '../shared/form-field/form-field';
import { Observable, Subscription } from 'rxjs';
import { NavigationPanel } from '../shared/navigation-panel/navigation-panel.model';
import { ContractMainTabComponent } from './contract-main-tab/contract-main-tab.component';
import { CoverageTabComponent } from './coverage-tab/coverage-tab.component';
import { RateDetailsTabComponent } from './rate-details-tab/rate-details-tab.component';
import { DocumentsTabComponent } from './documents-tab/documents-tab.component';
import { InquiryTabComponent } from './inquiry-tab/inquiry-tab.component';
import { SoClaimsTabComponent } from './so-claims-tab/so-claims-tab.component';
import { RefundInfomationTabComponent } from './refund-information-tab/refund-information-tab.component';
import { ImportFileComponent } from '../shared/import-file/import-file.component';
import { NavigationPanelComponent } from '../shared/navigation-panel/navigation-panel.component';
import { RoleAuthorizationService } from '../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../interfaces/role-authorization.interface';
import { ContractService } from './contract.service';
import { AddressCategoryType } from '../shared/constants/enums';
import { NoteComponent } from '../shared/notes/note.component';
import { ContractDocumentUploadComponent } from './contract-document-upload-tab/contract-document-upload-tab.component';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'contracts',
  templateUrl: 'contracts.template.html',
  styleUrls: ['contracts.scss'],
  providers: [ContractFormFieldService, ContractService, AlertService],
})
export class ContractsComponent implements OnInit {
  contractId: number;
  addressCategoryType = AddressCategoryType;
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  customerId: number;
  component = ContractsComponent;
  componentDisplay = false;
  uniqueId: any;
  componentsToLoad = 0; // will be set dynamically
  componentsLoaded = -1; // will be set dynamically, set to -1, as the loader is false by default
  querySubscription: Subscription[] = [];
  isLoading: boolean;
  @ViewChild('notesComponent') notesComponent: NoteComponent;
  constructor(
    service: ContractFormFieldService,
    private roleService: RoleAuthorizationService,
    private contractService: ContractService,
    private cdr: ChangeDetectorRef,
    public alertService: AlertService,
  ) {
    this.formFields$ = service.getFormFields();

    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: ContractMainTabComponent,
        data: { name: 'Main' },
        title: 'MA',
        tabname: 'Main',
        tabKey: 'main-tab',
      },
      {
        component: CoverageTabComponent,
        data: { name: 'Coverage Information' },
        title: 'CV',
        tabname: 'Coverage Information',
        tabKey: 'coverage-tab',
      },
      {
        component: RateDetailsTabComponent,
        data: { name: 'Rate Details' },
        title: 'RD',
        tabname: 'Rate Details',
        tabKey: 'rate-details-tab',
      },
      {
        component: DocumentsTabComponent,
        data: { name: 'Documents' },
        title: 'DC',
        tabname: 'Documents',
        tabKey: 'documents-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Documents',
          category: 'Contract',
        },
      },
      {
        component: InquiryTabComponent,
        data: { name: 'Inquiry' },
        title: 'IQ',
        tabname: 'Inquiry',
        tabKey: 'inquiry-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Inquiry',
          category: 'Contract',
        },
      },
      {
        component: SoClaimsTabComponent,
        data: { name: 'Claims' },
        title: 'SC',
        tabname: 'Claims',
        tabKey: 'claim-tab',
      },
      {
        component: ContractDocumentUploadComponent,
        data: { name: 'Import Documents' },
        title: 'ID',
        tabname: 'Import Documents',
        tabKey: 'import-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'ADD',
          permission: 'Documents',
          category: 'Claim',
        },
      },
      {
        component: RefundInfomationTabComponent,
        data: { name: 'Refund Information' },
        title: 'RI',
        tabname: 'Refund Information',
        tabKey: 'refund-information-tab',
      },
    ];
  }

  ngOnInit() {
    // Only display the Refund Information tab if the status is Cancelled.
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: ContractsComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.navigationPanel = this.roleService.validateFormFields(this.navigationPanel);
    this.componentDisplay = this.roleService.isVisible(NoteComponent, ContractsComponent);
    this.contractId = this.contractService.contractConfig.searchParams.id = !isNaN(
      this.searchParams?.originalId,
    )
      ? parseInt(this.searchParams?.originalId, 10)
      : parseInt(this.searchParams?.contractsId, 10);

    this.customerId = this.contractService.contractConfig.searchParams.customerId = !isNaN(
      this.searchParams?.customerIdOriginal,
    )
      ? parseInt(this.searchParams?.customerIdOriginal, 10)
      : parseInt(this.searchParams?.customerId, 10);

    this.uniqueId = this.searchParams?.uniqueId;

    this.querySubscription.push(
      this.contractService.isLoading.subscribe({
        next: (isLoading) => {
          if (isLoading) {
            this.isLoading = isLoading;
            this.componentsToLoad += 1;
            this.cdr.detectChanges();
          } else {
            this.componentsLoaded += 1;
          }

          // this.componentsLoaded will be eqaual to this.componentsToLoad
          // once all the components are loaded, but need to check greater than considering save/update click
          if (this.componentsLoaded >= this.componentsToLoad) {
            this.isLoading = false;
          }
        },
      }),
    );
    this.contractService.isContractCancelled.subscribe((isCancelled) => {
      this.updateRefundInformationTab(isCancelled);
    });
    this.contractService.isEnquiryNotesAdded.subscribe((note) => {
      this.notesComponent.saveSystemNote(
        AddressCategoryType.Customer,
        note.text,
        note.type,
        note.enteredBy
      );
    });
  }

  public onNotesData(noteData: any): void {
    this.contractService.contractDataModel.notes = noteData;
  }

  updateRefundInformationTab(addTab) {
    if (addTab) {
      const refundInformationItem = {
        component: RefundInfomationTabComponent,
        data: { name: 'Refund Information' },
        title: 'RI',
        tabname: 'Refund Information',
        tabKey: 'refund-information-tab',
      };
      if (this.navigationPanel.filter((el) => el.title === 'RI').length === 0) {
        this.navigationPanel = [...this.navigationPanel, refundInformationItem];
      }
    } else {
      this.navigationPanel = this.navigationPanel.filter((el) => el.title !== 'RI');
    }
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }

  onAlertDisplayChange(event) {
    if (!event) {
      this.alertService.hideAlert();
    }
  }
}
