import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisCrudOptions,
  ellipsisSubHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { ServicerServiceArea } from '../servicer-rates.service';
import { PriceTable } from '../../../../crm-coverage/coverage-models';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { ServicerService } from '../../servicer.service';
import { MessageService } from 'primeng/api';
import { DropdownDataService } from 'src/app/modules/crm/services/dropdown.data.service';
import { generalDateFormatter } from 'src/app/modules/crm/shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RolePermissionService } from 'src/app/modules/crm/common/role-permission.service';

@Component({
  selector: 'adjudication-limit-section',
  templateUrl: 'adjudication-limit.template.html',
  styleUrls: ['./adjudication-limit.scss'],
  providers: [MessageService],
})
export class RatesAdjudicationLimitComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = ellipsisSubHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown = {
    categoryDescription: [],
    subcategoryDescription: [],
  };
  currentId = 0;
  categoryDescription = [];
  constructor(
    private servicerServiceArea: ServicerServiceArea,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private servicerService: ServicerService,
    private messageService: MessageService,
    private dropdownDataService: DropdownDataService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {
    if (!['Admin', 'Claims Manager', 'Special Team A-CC-SN'].includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((x) => x.value !== 'delete');
    }
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    const tableData = [];
    if (this.servicerService.preAuth) {
      for (const singlePreAuth of this.servicerService.preAuth) {
        const item = Object.assign({}, singlePreAuth);
        item.effectiveDateFormatted = null;
        if (item.effectiveDate && item.effectiveDate !== '') {
          item.effectiveDateFormatted = generalDateFormatter(item.effectiveDate);
        }
        if (item.rateType === 'PreAuthorizationLimit') {
          // set id for edit
          item[`id`] = this.currentId = this.currentId + 1;
          tableData.push(item);
        }
      }
    }
    this.dataList = tableData;
    if (this.dropdownDataService.categoryDescriptionList.length) {
      const categoryList = this.dropdownDataService.categoryDescriptionList;
      this.transformAndSetCategoryList(categoryList);
    } else {
      this.servicerService.getCategoryDescriptionList().subscribe(({ data }) => {
        const categoryList = data.getCategoryDescriptionList;
        this.transformAndSetCategoryList(categoryList);
      });
    }

    this.cols = [
      { field: 'effectiveDateFormatted', header: 'EFFECTIVE DATE', type: 'date' },
      { field: 'categoryDescription', header: 'CATEGORY', type: 'select' },
      { field: 'subcategoryDescription', header: 'SUBCATEGORY', type: 'select' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'limit', header: 'PRE-AUTHORIZATION LIMIT', type: 'text' },
    ];
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Service Rates',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Service Rates',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  onDropDownChange(field, rowData) {
    if (field === 'categoryDescription' && rowData.categoryDescription) {
      rowData.subcategoryDescription = '';
      this.getSubcategoryList(rowData.categoryDescription);
    }
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisHeaderOptionClick(event) {
    // ellipsis functionality goes here
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    if (rowData.categoryDescription) {
      this.getSubcategoryList(rowData.categoryDescription);
    }
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any, index: number) {
    rowData.effectiveDateFormatted = generalDateFormatter(rowData.effectiveDateFormatted);
    delete this.clonedRowData[rowData.id];
    let payLoad = {
      ...rowData,
      effectiveDate: rowData.effectiveDateFormatted,
    };
    if (rowData.isNew) {
      payLoad = {
        rateType: 'PreAuthorizationLimit',
        servicerName: this.servicerService.serviceFormData.servicerName,
        servicerNumber: this.servicerService.serviceFormData.servicerNumber,
        servicersId: this.servicerService.serviceFormData.servicerProfilesIdOriginal,
        effectiveDate: rowData.effectiveDateFormatted,
        description: rowData.description,
        limit: rowData.limit,
        categoryDescription: rowData.categoryDescription,
        subcategoryDescription: rowData.subcategoryDescription,
        servicerRatesIdOriginal: rowData.servicerRatesIdOriginal,
        isDeleted: false,
        insertUserName: '',
      };
    }

    this.servicerServiceArea.isLoading.next(true);
    this.servicerService.createUpdateServicerPreAuthDetails(payLoad).subscribe(
      (data) => {
        this.isEditing = false;
        this.servicerServiceArea.isLoading.next(false);

        this.dataList[index].servicerRatesIdOriginal =
          data[`data`][`createUpdateServicerPreAuthDetails`] &&
          data[`data`][`createUpdateServicerPreAuthDetails`][`servicerRatesIdOriginal`];
        this.dataList[index].servicerName = this.servicerService.serviceFormData.servicerName;
        this.dataList[index].servicerNumber = this.servicerService.serviceFormData.servicerNumber;
        this.dataList[index].servicersId =
          this.servicerService.serviceFormData.servicerProfilesIdOriginal;

        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Saved successfully.',
        });
      },
      (err) => {
        this.isEditing = false;
        this.servicerServiceArea.isLoading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error.',
        });
      },
    );
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.currentId = this.currentId + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  deletePreAuthLimit() {
    const rowData = this.selectedItem;
    rowData.effectiveDateFormatted = generalDateFormatter(rowData.effectiveDateFormatted);
    const payLoad = {
      ...rowData,
      effectiveDate: rowData.effectiveDateFormatted,
      isDeleted: true,
    };

    this.servicerServiceArea.isLoading.next(true);
    this.servicerService.createUpdateServicerPreAuthDetails(payLoad).subscribe(
      (data) => {
        this.servicerServiceArea.isLoading.next(false);
        this.dataList.splice(this.selectedRowIndex, 1);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Deleted successfully.',
        });
      },
      (err) => {
        this.servicerServiceArea.isLoading.next(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error.',
        });
      },
    );
  }

  onRowDelete(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.deletePreAuthLimit();
      },
    });
  }

  getSubcategoryList(category) {
    this.servicerService.getSubcategoryDescriptionListByCategory(category).subscribe(({ data }) => {
      const subCategoryList = data.getSubcategoryDescriptionListByCategory;
      const temArray = [];
      if (subCategoryList && subCategoryList.length) {
        subCategoryList.forEach((element) => {
          temArray.push({ label: element, value: element });
        });
      }
      temArray.unshift({ label: 'ALL', value: 'ALL' });
      this.dropdown = { ...this.dropdown, subcategoryDescription: temArray };
    });
  }

  transformAndSetCategoryList(categoryList) {
    const temArray = [];
    categoryList.forEach((element) => {
      temArray.push({ label: element, value: element });
    });
    temArray.unshift({ label: 'ALL', value: 'ALL' });
    this.categoryDescription = temArray;
    this.dropdown = {
      ...this.dropdown,
      categoryDescription: temArray,
    };
  }
}
