import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ResultService {
  constructor() {}
  getResult(): Observable<any[]> {
    return of([
      {
        status: 'Validation',
        count: 'Passed',
        link: 'Get Validation Return File'
      },
      {
        status: 'Total Count',
        count: '8,091'
      },
      {
        status: 'Failed Count',
        count: '0',
        link: 'View Error Log File'
      },
      {
        status: 'Warning Count',
        count: '0',
        link: 'Download Reimport File'
      },
      {
        status: 'Duplicated Count',
        count: '0'
      },
      {
        status: 'Ready To Process Count',
        count: '8,091'
      },
      {
        status: 'Processed Count',
        count: ''
      },
      {
        status: 'Dealer Cost',
        count: '$0.00'
      },
      {
        status: 'Contract Count',
        count: '3,144'
      }
    ]);
  }
}
