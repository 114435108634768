<div class="crm-container">
  <app-section-header [title]="'Coverage'" [optionHeader]="opHeader" />
  <gn-contact-information *ngIf="isDataAvailable || !searchParams" [(modelData)]="insuranceCompanyService.insuranceCompanyDataModel.mainTab.contactInformation"
    [enableModelData]="searchParams"></gn-contact-information>
  <div class="sub-section">
    <div class="title">Mailing Address</div>
    <address *ngIf="isDataAvailable || !searchParams" [(modelData)]="insuranceCompanyService.insuranceCompanyDataModel.mainTab.mailingAddress" [enableModelData]="searchParams">
    </address>
  </div>
  <div class="sub-section">
    <div class="title">Billing Address</div>
    <address *ngIf="isDataAvailable || !searchParams" [(modelData)]="insuranceCompanyService.insuranceCompanyDataModel.mainTab.billingAddress" [enableModelData]="searchParams">
    </address>
  </div>
</div>
<p-overlayPanel #opHeader><p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel>