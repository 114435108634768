<div class="work-queue-container">
  <app-section-header [title]="'Dashboard'" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" />
    <div class="batch-filters">
      <div class="group">
        <label [attr.for]="'btch_buying_group'">Buying Group</label>
        <p-multiSelect [id]="'btch_buying_group'" [options]="optionsBuyingGroup" name="btch_buying_group"
          [(ngModel)]="selectedBuyingGroup" optionLabel="key" selectedItemsLabel="{0} columns selected"
          defaultLabel="Choose Columns">
        </p-multiSelect>
      </div>
      <div class="group">
        <label [attr.for]="'btch_dealer'">Dealer</label>
        <p-multiSelect [id]="'btch_dealer'" [options]="optionsDealerGroup" name="btch_dealer"
          [(ngModel)]="selectedDealer" optionLabel="key" selectedItemsLabel="{0} columns selected"
          defaultLabel="Choose Columns">
        </p-multiSelect>
      </div>
      <div class="group">
        <label [attr.for]="'btch_servicer'">Servicer</label>
        <p-multiSelect [id]="'btch_servicer'" [options]="optionsServicerList" name="btch_servicer"
          [(ngModel)]="selectedServicer" optionLabel="key" selectedItemsLabel="{0} columns selected"
          defaultLabel="Choose Columns">
        </p-multiSelect>
      </div>
      <div class="group">
        <label [attr.for]="'btch_status'">Status</label>
        <p-multiSelect [id]="'btch_status'" [options]="optionsStatus" name="btch_status" [(ngModel)]="selectedStatus"
          optionLabel="key" selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
        </p-multiSelect>
      </div>
      <div class="button">
        <button class="crm-button" (click)="refreshDashboard()">Refresh</button>
      </div>
    </div>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="cols" [value]="summaryData"
      dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
        <tr>
          <td *ngFor="let col of columns" [style.width]="col.width">
            <div class="crm-link" *ngIf="col.type === 'link'" (click)="searchClaim(rowData['status'],col.field)">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.type === 'text'">{{rowData[col.field]}}</div>
            <button class="btn-status-default" *ngIf="col.type === 'status'">
              {{rowData[col.field] | uppercase}}
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>