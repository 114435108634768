// CLEAR
// import moment from 'moment';
import moment from 'moment-timezone';

/**
 * This will return date-substring for the passed value
 * if the value is null/undifined/0 it will return null
 * @date     : string
 */
export function dateSubstring(date) {
  if (date && !(date.includes('-') || date.includes('/'))) {
    date = new Date(parseInt(date, 10));
  }

  return date && date.length > 1 ? new Date(date).toISOString().substring(0, 10) : null;
}

export function dateTimeFormatter(date, format = 'MM/DD/YYYY, hh:mm:ss A') {
  if (!date) {
    return null;
  }
  const res = moment.utc(date).format(format);
  return res;
}

export function dateTimeFormatterCST(date, format = 'MM/DD/YYYY, hh:mm:ss A') {
  if (!date) {
    return null;
  }

  const res = moment.utc(date).tz('America/Chicago').format(format);
  return res;
}
// TODO: Move timezone logic to gereneric function
export function dateFormatterCST(date, format = 'MM/DD/YYYY') {
  if (!date) {
    return null;
  }

  const res = moment.utc(date).tz('America/Chicago').format(format);
  return res;
}

export function generalDateFormatter(dateStr, format = 'MM/DD/YYYY') {
  return commonDateTimeFormatter(dateStr, format);
}

export function generalDateTimeFormatter(dateStr, format = 'MM/DD/YYYY, hh:mm:ss A') {
  return commonDateTimeFormatter(dateStr, format);
}

export function generalDateFormatterWithoutTZ(dateStr, format = 'YYYY-MM-DD') {
  if (!dateStr) {
    return null;
  }
  const date = new Date(dateStr);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dStr = new Date(date.getTime() - userTimezoneOffset).toISOString();
  return getMMDDYYYFormat(dStr, format);
}

function commonDateTimeFormatter(dateStr, format) {
  try {
    if (dateStr) {
      const date = new Date(dateStr).toISOString();
      const res = moment.utc(date).format(format);
      return res;
    }
  } catch (ex) {
    console.log(`${dateStr} is not valide datetime string`);
  }
  return null;
}

/**
 * This will format the date in dd mm yyyy format
 * @date     : date
 * @separator   : string
 */
export function getFormattedDate(date, separator = '/') {
  return date.getDay() + separator + date.getMonth() + separator + date.getFullYear();
}

/**
 * This will return the difference between 2 date (date1 - date2)
 * @date1     : date
 * @date2     : date
 * @duration   : string (options: milliseconds, hours, days and months)
 */
export function getDateDiff(date1, date2, duration = 'milliseconds') {
  date1 = moment(date1).isValid() ? moment(date1) : moment();
  date2 = moment(date2).isValid() ? moment(date2) : moment();
  const ms = date1.diff(date2);
  const diff = moment.duration(ms);
  switch (duration) {
    case 'days':
      return diff.asDays().toFixed(0);
    case 'hours':
      return diff.asHours();
    case 'months':
      return diff.asMonths();
    default:
      return diff.asMilliseconds();
  }
}
/**
 * Get date in MM/DD/YYYY
 * @param date : Date
 * @param separator : String
 * @returns String
 */

export function getMMDDYYYFormat(date, format = 'M/D/YYYY') {
  if (!date) {
    return null;
  }
  const res = moment.utc(date).format(format);
  return res;
}

export function getMMDDYYYFormatCST(date, format = 'M/D/YYYY') {
  if (!date) {
    return null;
  }
  const res = moment.utc(date).tz('America/Chicago').format(format);
  return res;
}

export function getDateOnly(date: string) {
  return new Date(new Date(date).toDateString());
}

/**
 * Get formatted time range for Service bench
 * @param timeString String
 * @param length String
 * @param type String
 * @returns String
 */
export function getFormattedTimeRangeForSB(timeString, length = '0') {
  if (timeString) {
    const st = moment(timeString, 'hhmm').format('LT').toString();
    const ft = moment(timeString, 'hhmm')
      .add(parseInt(length, 10), 'hours')
      .format('LT')
      .toString();
    return st + '-' + ft;
  } else {
    return 'Emergency';
  }
}

export function getMMDDYYYFormatWithoutTimeZone(date, format = 'M/D/YYYY') {
  if (!date) {
    return null;
  }
  // Need to add .utc() or else will convert to users timezone.
  const res = moment(date).utc().format(format);
  return res;
}

export function getMMDDYYYFormatDate(date) {
  if (date.includes('.')) {
    const datePart = date.substring(5, 7) + '/' + date.substring(8, 10) + '/' + date.substring(0, 4);
    return datePart;
  } else {
    return date;
  }
}

/**
 * Converting EST time to local time for Service Bench Comment Notes
 * @param date String: yyyymmdd
 * @param time String: hh:mm
 * @returns String
 */
export function getFormattedESTDateTimeSB(date, time) {
  if (date && time) {
    const hours = time.slice(0, 2);
    const mints = time.slice(2);
    const d = moment(date, 'YYYYMMDD').format('YYYY-MM-DD').toString();
    const dateStr = `${d}T${hours}:${mints}:00.000-05:00`;
    const utcDt = moment(dateStr).utc().format();
    const localTime = moment(utcDt).toDate().toString();
    return localTime;
  }
  return null;
}

/**
 * Get formatted time range for Service Power
 * @param timeRangeString String
 * @returns String
 */
export function getFormattedTimeRangeForSP(timeRangeString) {
  if (timeRangeString) {
    const timeRange = timeRangeString.split('-');
    const st = moment(timeRange[0], 'hhmm').format('LT').toString();
    const ft = moment(timeRange[1], 'hhmm').format('LT').toString();
    return st + ' - ' + ft;
  } else {
    return 'Emergency';
  }
}

/**
 * Formatted timeperiod for service power
 * @param timeRange string (Ex: 8-17)
 * @returns string (Ex: 8:00 AM - 5:00 PM)
 */

export function spFormattedTimezone(timeRange) {
  let formattedRange = '';
  if (timeRange) {
    const timeRageArr = timeRange.split('-');
    if (!isNaN(timeRageArr[0]) && !isNaN(timeRageArr[1])) {
      if (parseInt(timeRageArr[0], 10) < 12) {
        formattedRange = parseInt(timeRageArr[0], 10).toLocaleString() + ':00 AM - ';
      } else if (parseInt(timeRageArr[0], 10) === 12) {
        formattedRange = parseInt(timeRageArr[0], 10).toLocaleString() + ':00 PM - ';
      } else {
        formattedRange =
          (parseInt(timeRageArr[0], 10) - 12).toLocaleString().padStart(2, '0') + ':00 PM - ';
      }

      if (parseInt(timeRageArr[1], 10) < 12) {
        formattedRange = formattedRange + parseInt(timeRageArr[1], 10).toLocaleString() + ':00 AM';
      } else if (parseInt(timeRageArr[1], 10) === 12) {
        formattedRange = formattedRange + parseInt(timeRageArr[1], 10).toLocaleString() + ':00 PM';
      } else {
        formattedRange =
          formattedRange + (parseInt(timeRageArr[1], 10) - 12).toLocaleString() + ':00 PM';
      }
    }
  }
  return formattedRange;
}

/**
 * Formatted dates without Timezone
 * @param dateString string (Ex: "2022-05-31T00:00:00")
 * @returns string (Ex: 2022-05-31)
 */
export function getDateFromString(dateString) {
  if (dateString && dateString.includes('T')) {
    const dateStr = dateString.split('T')[0];
    return `${dateStr.split('-')[1]}/${dateStr.split('-')[2]}/${dateStr.split('-')[0]}`;
  }
  return dateString;
}

export function getStringFromDate(dateString) {
  return `${dateString.split('/')[2]}-${dateString.split('/')[0]}-${dateString.split('/')[1]}`;
}

export function convertToLocale(dateTime) {
  return new Date(dateTime).toLocaleString();
}
