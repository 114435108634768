import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class QueueService {
  constructor(private http: HttpClient) {}
  // TODO: Integrate with remote data
  getQueues() {
    return [
      {
        reviewed: false,
        description: 'Checked plug and circuit',
        category: 'Air Conditioner',
        tier: 'Air Conditioner'
      },
      {
        reviewed: false,
        description: 'TS DIDNT WORK',
        category: 'APPLIANCE',
        tier: 'RangeOven'
      },
      {
        reviewed: true,
        description: 'PERFORMING HARD REST',
        category: 'APPLIANCE',
        tier: 'Refrigerator'
      }
    ];
  }
}
