import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SecurityTypeService } from '../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { Observable, Subscription } from 'rxjs';
import { copy } from '../../../shared/utilities/common-utilities';

@Component({
  selector: 'admin-security-roles',
  templateUrl: 'sec-roles.template.html',
  providers: [SecurityTypeService, RoleAuthorizationService, ContractFormFieldService],
})
export class SecurityRolesComponent extends FormCanDeactivate implements OnInit {
  formModalInfo: FormField<any>[] = [];
  cols: any[];
  header: string;
  dataList: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayUpdate: boolean;
  constructor(
    private configurationService: SecurityTypeService,
    private contractService: ContractFormFieldService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
  }

  isLoading = true;
  querySubscription: Subscription;

  ngOnInit() {
    this.editConstant = uuidv4();
    const tableValues = [];
    this.querySubscription = this.configurationService.getRoleDetails('%').subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        for (const singleRole of data.getAdminSettingRoles) {
          tableValues.push({ role_id: singleRole.role_id, role_name: singleRole.role_name });
          this.dataList = tableValues;
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );

    this.cols = [{ field: 'role_name', header: 'ROLE NAME', type: 'text' }];
    this.contractService
      .getSecFormModalFields()
      .subscribe((formModalInfo) => (this.formModalInfo = formModalInfo));
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e) {
    console.log(e);
    if (e.value === 'Add New User') {
      this.displayAdd = true;
      this.header = 'Add New User';
    } else {
      this.displayUpdate = true;
      this.header = 'Update User';
    }
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayUpdate = false;
    this.cdr.detectChanges();
  }
}
