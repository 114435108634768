import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'crm-issue-resolver',
  template: '',
  styleUrls: ['issue-resolver.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CRMIssueResolverComponent {
  constructor() {}
}
