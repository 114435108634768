<div class="main-tab">
  <div class="main-tab__title">
    <div class="icon"></div>
    <div class="title"><div>Main</div></div>
  </div>
  <contact-information [formFields]="contactInfo" [formData]="contactFormData" (modelDataChange)="onContactInfoChange($event)">
  </contact-information>
  <div class="customer-information">
    <div class="title">Mailing Address</div>
    <address *ngIf="true" [(modelData)]="dealerLocationService.mailingAddressFinal" [enableModelData]="true"></address>
  </div>
  <div class="customer-information">
    <div class="title">Billing Address</div>
    <address *ngIf="true" [(modelData)]="dealerLocationService.billingAddressFinal" [enableModelData]="true"></address>
  </div>
</div>
