<div class="crm-container">
  <app-section-header [title]="'Search Role Permissions Results ( ' + dataList.length + ' item(s) found )'"
    [optionHeader]="optionHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" *ngIf="!isLoading"
      [columns]="selectedColumns" [value]="dataList" dataKey="id" editMode="row" sortMode="multiple">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn="pResizableColumn">
            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-ri="rowIndex" let-columns="columns" let-editing="editing">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <p-cellEditor *ngIf="col.type === 'text'">
              <ng-template pTemplate="input">
                <input class="input-text" type="text" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <ng-container *ngIf="!editing"></ng-container>
            <div class="three-dots" (click)="ellipsisClick($event,rowData,opRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              id="{{editConstant + rowData.id}}cancelBtn" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <button class="btn-crm-success" type="button" (click)="getRolePermission(true)" [hidden]="isLoading"
    [disabled]="dataList.length % offsetLimit !== 0">
    Load More
  </button>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="rolePermissionsEllipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions" (onClick)="handleOpen($event)"> </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionHeader>
  <p-listbox [options]="rolePermissionsEllipsisHeaderOptions" (onClick)="handleOpen($event)"> </p-listbox>
</p-overlayPanel>
<p-dialog [header]="header" [(visible)]="displayAdd" showeffect="fade" [modal]="true" [styleClass]="'add-dialog'"
  [blockScroll]="true">
  <div class="modal-content">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-8" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Cancel"
        [disabled]="!form.valid">Save</button>
      <button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>