import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { PriceTable } from '../../../../crm-coverage/coverage-models';
import { ServicePowerTableService } from './service-power-table.service';
import { ServiceOrderService } from '../../service-order.service';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import {
  copy,
  getKeyByValue,
  getValueByKey,
} from 'src/app/modules/crm/shared/utilities/common-utilities';
import {
  getMMDDYYYFormat,
  spFormattedTimezone,
} from 'src/app/modules/crm/shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
@Component({
  selector: 'service-power-table',
  templateUrl: 'service-power-table.template.html',
  styleUrls: ['./service-power-table.scss'],
  providers: [],
})
export class ServicePowerTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() displayDialog: boolean;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  selectedServicer: any;
  isLoading = false;
  daysOptions = [
    { label: '1', value: '1' },
    { label: '7', value: '7' },
    { label: '14', value: '14' },
    { label: '30', value: '30' },
  ];
  selectedNoDays = '14';
  customerDetails = {
    zipCode: 'N/A',
    address1: 'N/A',
    city: 'N/A',
    state: 'N/A',
    customerPhone: 'N/A',
    customerLastName: 'N/A',
  };
  querySubscriptions: Subscription[] = [];
  appointmentSearchSubscription: Subscription;
  servicerProviders = [];
  timeRanges = [];
  availableDates = [];
  constructor(
    private sbTableService: ServicePowerTableService,
    private confirmationService: ConfirmationService,
    private serviceOrderService: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private azureService: AzureLoginService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'select', header: 'SELECT', type: 'radio' },
      { field: 'serviceProviderCenterId', header: 'SERVICE CENTER ID', type: 'text' },
      { field: 'recall', header: 'RECALL', type: 'checkbox' },
      { field: 'formattedAvailableDate', header: 'AVAILABLE DATE', type: 'text' },
      { field: 'weekDay', header: 'WEEKDAY', type: 'text' },
      { field: 'formattedTimePeriod', header: 'TIME PERIOD', type: 'text' },
    ];
    if (this.azureService.roleName === 'Admin') {
      this.cols = [
        ...this.cols,
        { field: 'serviceProviderName', header: 'SERVICE PROVIDER NAME', type: 'text' },
      ];
    }
    this.selectedColumns = this.cols.slice(0, 8);
    const { servicePowerCommon } = this.serviceOrderDataService.servicePowerModal;
    this.selectedNoDays = getValueByKey(servicePowerCommon, 'defaultNoDays');
    this.ngOnChanges();
  }

  ngOnChanges() {
    const servicer = null;
    if (this.serviceOrderDataService.serviceOrderDataModel.servicerInfo) {
      this.getServiceAvailability(this.serviceOrderDataService.serviceOrderDataModel.servicerInfo);
    } else {
      const serviceProviderValues = this.serviceOrderService.selectedServicerValue;
      if (!serviceProviderValues) {
        return;
      }
      const servicerProfileId = serviceProviderValues.servicerProfilesIdOriginal;
      this.isLoading = true;
      this.querySubscriptions.push(
        this.serviceOrderDataService.getServicerInfoByIdSingle(servicerProfileId).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            this.getServiceAvailability(data.getServicerProfileById);
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        ),
      );
    }
  }
  onDaysChange(event) {
    this.servicerProviders = this.timeRanges = this.availableDates = [];
    this.selectedNoDays = event && event.value;
    this.ngOnChanges();
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  onRadioButtonClick(event, rowData) {
    this.sbTableService.selectedValue = rowData;
  }

  getServiceAvailability(servicer) {
    const selectedState =
      servicer.addresses && servicer.addresses[0] && servicer.addresses[0].state;
    const { spPrefferedServicerList } = this.serviceOrderDataService.servicePowerModal;
    const prefferedServicer = getKeyByValue(
      spPrefferedServicerList,
      servicer && servicer.servicerName,
    );
    if (selectedState) {
      const locationDetails = this.serviceOrderDataService.serviceOrderDataModel.location;
      const customerInfo = this.serviceOrderDataService.serviceOrderDataModel.customerInfo;
      this.customerDetails.zipCode = locationDetails.zipCode;
      this.customerDetails.address1 = locationDetails.address1;
      this.customerDetails.city = locationDetails.city;
      this.customerDetails.state = locationDetails.state.value;
      if (customerInfo) {
        this.customerDetails.customerPhone = customerInfo.customerPhone;
        this.customerDetails.customerLastName = customerInfo.customerLastName;
      }
      this.isLoading = true;
      const availibilityParams = {
        zipCode: locationDetails.zipCode,
        state: locationDetails.state.value,
        startDate: moment(new Date()).format('YYYYMMDD'),
        prefferedServiceCenter: prefferedServicer,
      };
      this.appointmentSearchSubscription = this.sbTableService
        .getAppointmentSearch(availibilityParams, this.selectedNoDays)
        .subscribe(
          (data) => {
            this.isLoading = false;
            this.appointmentSearchSubscription.unsubscribe();
            const finalTableValues = [];
            const body = data['soapenv:Envelope']['soapenv:Body'];
            const response = body.AppointmentSearchResponse;
            const servicerCollection = response.SvcrCollection;
            const ackMessage = response && response.AckMsg;
            if (ackMessage._text && ackMessage._text.startsWith('FSS')) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: `ERROR: ${ackMessage._text.split(': ').pop()}.`,
              });
            } else {
              if (servicerCollection.length > 1) {
                for (const singleServicer of servicerCollection) {
                  const availableSlots =
                    singleServicer.OfferIDCollection && singleServicer.OfferIDCollection.length
                      ? singleServicer.OfferIDCollection
                      : singleServicer.OfferIDCollection
                      ? [singleServicer.OfferIDCollection]
                      : [];
                  const servicerAddress = [
                    singleServicer.SvcrAddress1._text,
                    singleServicer.SvcrAddress2._text,
                    singleServicer.SvcrPostcode._text,
                    singleServicer.SvcrPostcodeLevel1._text,
                    singleServicer.SvcrPostcodeLevel2._text,
                    singleServicer.SvcrPostcodeLevel3._text,
                  ]
                    .filter(Boolean)
                    .join(', ');
                  for (const singleTime of availableSlots) {
                    // console.log('Single Time Slot: ' + JSON.stringify(singleTime));
                    const formattedDate = getMMDDYYYFormat(
                      singleTime.SchdDelDate._text,
                      'MM/DD/YYYY',
                    );
                    finalTableValues.push({
                      serviceProviderCenterId: singleServicer.ServiceCenterID._text,
                      serviceProviderName: singleServicer.SvcrName._text,
                      serviceProviderAddress: servicerAddress,
                      serviceProviderPhone: singleServicer.SvcrPhone._text,
                      servicerProfileID: servicer.servicerProfilesIdOriginal,
                      availableDate: singleTime.SchdDelDate._text,
                      timePeriod: singleTime.TimePeriod._text,
                      customerZipCode: servicer.addresses[0].zipCode,
                      customerAddress1: servicer.addresses[0].address1,
                      customerCity: servicer.addresses[0].city,
                      customerState: servicer.addresses[0].state.value,
                      customerPhone: this.customerDetails.customerPhone,
                      customerLastName: this.customerDetails.customerLastName,
                      groupId: singleTime.GroupId._text,
                      offerToken: singleTime.OfferToken._text,
                      formattedTimePeriod: spFormattedTimezone(singleTime.TimePeriod._text),
                      formattedAvailableDate: formattedDate,
                      weekDay: moment(formattedDate).format('dddd'),
                    });
                  }
                }
              } else {
                const availableSlots =
                  servicerCollection.OfferIDCollection &&
                  servicerCollection.OfferIDCollection.length
                    ? servicerCollection.OfferIDCollection
                    : servicerCollection.OfferIDCollection
                    ? [servicerCollection.OfferIDCollection]
                    : [];
                const servicerAddress = [
                  servicerCollection.SvcrAddress1._text,
                  servicerCollection.SvcrAddress2._text,
                  servicerCollection.SvcrPostcode._text,
                  servicerCollection.SvcrPostcodeLevel1._text,
                  servicerCollection.SvcrPostcodeLevel2._text,
                  servicerCollection.SvcrPostcodeLevel3._text,
                ]
                  .filter(Boolean)
                  .join(', ');
                for (const singleTime of availableSlots) {
                  // console.log('Single Time Slot: ' + JSON.stringify(singleTime));
                  const formattedDate = getMMDDYYYFormat(
                    singleTime.SchdDelDate._text,
                    'MM/DD/YYYY',
                  );
                  finalTableValues.push({
                    serviceProviderCenterId: servicerCollection.ServiceCenterID._text,
                    serviceProviderName: servicerCollection.SvcrName._text,
                    serviceProviderAddress: servicerAddress,
                    serviceProviderPhone: servicerCollection.SvcrPhone._text,
                    servicerProfileID: servicer.servicerProfilesIdOriginal,
                    availableDate: singleTime.SchdDelDate._text,
                    timePeriod: singleTime.TimePeriod._text,
                    customerZipCode: servicer.addresses[0].zipCode,
                    customerAddress1: servicer.addresses[0].address1,
                    customerCity: servicer.addresses[0].city,
                    customerState: servicer.addresses[0].state.value,
                    customerPhone: this.customerDetails.customerPhone,
                    customerLastName: this.customerDetails.customerLastName,
                    groupId: singleTime.GroupId._text,
                    offerToken: singleTime.OfferToken._text,
                    formattedTimePeriod: spFormattedTimezone(singleTime.TimePeriod._text),
                    formattedAvailableDate: formattedDate,
                    weekDay: moment(formattedDate).format('dddd'),
                  });
                }
              }
              finalTableValues.sort(compare);
              this.dataList = finalTableValues;
              this.sbTableService.storedValues[servicer.addresses[0].zipCode] = this.dataList;
              this.getRecallStatus();
            }
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  getRecallStatus() {
    let temporalRecall: object = {};
    const queryInfo: object = {
      contractNumber: this.serviceOrderDataService.serviceOrderDataModel.claimData.contractNumber,
      serviceOrderNumber:
        this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber,
      symptom: this.serviceOrderDataService.serviceOrderDataModel.claimData.problem.symptom,
      modelNumber: this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
        ? this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct.modelNumber
        : null,
    };
    this.querySubscriptions.push(
      this.sbTableService.getServiceOrderRecall(queryInfo).subscribe(
        (recallResultQuery) => {
          if (recallResultQuery.data.getServiceOrderRecall) {
            temporalRecall = {
              [recallResultQuery.data.getServiceOrderRecall.servicerName]:
                recallResultQuery.data.getServiceOrderRecall.recall,
              [recallResultQuery.data.getServiceOrderRecall.servicerAccount]:
                recallResultQuery.data.getServiceOrderRecall.recall,
            };
            this.transformRecallStatus(temporalRecall);
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      ),
    );
  }

  transformRecallStatus(recall: object) {
    this.dataList.forEach((data) => {
      const isRecall =
        recall[data.serviceProviderCenterId] != null
          ? recall[data.servicerProfileID]
          : recall[data.serviceProviderName];
      data.recall = isRecall;
    });

    this.dataList.sort((act, comp) => {
      if (act.recall > comp.recall) {
        return -1;
      }
      if (act.recall < comp.recall) {
        return 1;
      }
      return 0;
    });
    this.isLoading = false;
  }
}

function compare(a, b) {
  const availableDateA = a.availableDate;
  const availableDateB = b.availableDate;
  const timeSlotStartTimeA = a.timePeriod;
  const timeSlotStartTimeB = b.timePeriod;

  let comparison = 0;
  if (availableDateA === availableDateB) {
    if (timeSlotStartTimeA > timeSlotStartTimeB) {
      comparison = 1;
    } else if (timeSlotStartTimeA < timeSlotStartTimeB) {
      comparison = -1;
    }
  } else if (availableDateA > availableDateB) {
    comparison = 1;
  } else if (availableDateA < availableDateB) {
    comparison = -1;
  }
  return comparison;
}
