import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CancelContractService } from './cancel-contract-modal.service';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormField } from '../../shared/form-field/form-field';
import { Subscription } from 'rxjs';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { ContractService } from '../contract.service';
import { generalDateFormatter } from '../../shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { MessageService } from 'primeng/api';
import { CancellationModalReponse } from '../contract.constant';

@Component({
  selector: 'cancel-contract-modal',
  templateUrl: 'cancel-contract-modal.template.html',
  styleUrls: ['./cancel-contract-modal.scss'],
  providers: [FormFieldControlService, CancelContractService, MessageService],
})
export class CancelContractModalComponent implements OnInit, OnDestroy {
  cancellationData = {};
  isProRataSelected = false;
  @Input() formData: any;
  @Input() modalTitle: string;
  @Input() displayDialog;
  @Output() displayDialogChange: EventEmitter<CancellationModalReponse> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  isLoading: boolean;
  querySubscription: Subscription;
  isMJC: boolean;

  constructor(
    private azureService: AzureLoginService,
    private contractService: ContractService,
    private cancelContractService: CancelContractService,
    private messageService: MessageService,
    private qcs: FormFieldControlService,
    private contractFormFieldService: ContractFormFieldService,
  ) {
    this.contractFormFieldService
      .getContractCancellationModalFormFields()
      .subscribe((formFields) => {
        this.formFields = formFields;
        this.form = this.qcs.toFormGroup(this.formFields);
      });
    this.form.patchValue({
      cancellationProcessDate: new Date().toISOString().substring(0, 10),
    });
  }

  ngOnInit() {
    this.cancellationData = {
      contractNumber: this.contractService.contractDataModel.leftBaseSection.contractNumber,
    };
    this.isMJC = false;
  }

  onChange(event) {
    const payload = this.form.getRawValue();
  }

  onDisable(event, input) {
    if (event.target.value !== '') {
      this.form.controls[input.key].reset();
      this.form.controls[input.key].disable();
    } else {
      this.form.controls[input.key].enable();
    }
  }

  disableRefundData(enable) {
    this.form.controls[this.formFields[3].key].reset();
    this.form.controls[this.formFields[4].key].reset();
    if (enable) {
      this.form.controls[this.formFields[3].key].enable();
      this.form.controls[this.formFields[4].key].enable();
    } else {
      this.form.controls[this.formFields[3].key].disable();
      this.form.controls[this.formFields[4].key].disable();
    }
  }

  showCalculateRefund(event) {
    if (event.value.key === 'Override') {
      this.disableRefundData(true);
    } else {
      this.disableRefundData(false);
    }
    if (event.value.key === 'Pro-Rata') {
      this.isProRataSelected = true;
    } else {
      this.isProRataSelected = false;
    }
  }

  calculateCancellationRefund() {
    const payload = this.form.getRawValue();
    this.cancellationData[`mjcContract`] = payload.mjcContract;
    this.cancellationData[`azureUserId`] = this.azureService.accountId;
    this.cancellationData[`cancellationMethod`] = payload.cancellationMethod.value;
    this.cancellationData[`customerCancellationDate`] = generalDateFormatter(
      payload.customerCancellationDate,
    );
    this.cancellationData[`cancellationReason`] = payload.cancellationReason.value;

    this.isLoading = true;

    this.querySubscription = this.cancelContractService
      .getCancellationRefundAmount(this.cancellationData)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          if (data.getCancellationRefundAmount) {
            if (data.getCancellationRefundAmount.hasError) {
              this.messageService.add({
                severity: 'error',
                summary: 'error',
                detail: data.getCancellationRefundAmount.errorMessage,
              });
            } else {
              this.form.patchValue({
                cancellationFee: parseFloat(
                  data.getCancellationRefundAmount.cancellationFee,
                ).toFixed(2),
                dealerRefundSysGen: parseFloat(
                  data.getCancellationRefundAmount.refundAmount,
                ).toFixed(2),
              });
            }
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit({
      status: null,
      error: null,
    });
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    this.cancellationData[`mjcContract`] = payload.mjcContract;
    this.cancellationData[`cancellationMethod`] = payload.cancellationMethod.value;
    this.cancellationData[`customerCancellationDate`] = generalDateFormatter(
      payload.customerCancellationDate,
    );
    this.cancellationData[`cancellationReason`] = payload.cancellationReason.value;
    this.cancellationData[`dealerRefundOverride`] = payload.dealerRefundOverride
      ? payload.dealerRefundOverride
      : payload.refundToCustomerOverride;
    this.cancellationData[`azureUserId`] = this.azureService.accountId;
    this.cancellationData[`cancellationTransactionDate`] = generalDateFormatter(
      payload.cancellationTransactionDate,
    );

    this.isLoading = true;

    this.querySubscription = this.cancelContractService
      .createUpdateContractCancellation(this.cancellationData)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const cancellationRes: CancellationModalReponse = {
            status: false,
          };
          if (
            data.createUpdateContractCancellation &&
            data.createUpdateContractCancellation.responseMessage === 'Success'
          ) {
            cancellationRes.status = true;
          } else {
            cancellationRes.status = false;
            cancellationRes.error = {
              name: data.createUpdateContractCancellation.errorMessage[0],
              message: data.createUpdateContractCancellation.errorMessage.join(','),
            };
          }
          this.displayDialogChange.emit(cancellationRes);
        },
        (err) => {
          this.isLoading = false;
          this.displayDialogChange.emit({
            status: false,
            error: err,
          });
          throw err;
        },
      );
  }

  updateMJC(event) {
    this.isMJC = event;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
