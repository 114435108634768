<div class="crm-container">
  <app-section-header [title]="'Search Order Results ( ' + resultCount + ' item(s) found )'"
    [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '20rem' }" class="scrollableDataTable"
      [columns]="selectedColumns" [value]="dataList" [resizableColumns]="true" dataKey="id" editMode="row"
      sortMode="multiple" [loading]="isLoading" [hidden]="isLoading">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="snum-cell" [style.width]="'2%'" pResizableColumn="pResizableColumn">S. No.</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn="pResizableColumn">
            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td>{{ri+1}}</td>
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output">
                {{rowData[col.field]}}
              </ng-template>
              <ng-template *ngIf="col.type === 'text' && col.field === 'status'" pTemplate="output">
                <button
                  [class]="btnStatus[rowData[col.field].toLowerCase()] ? btnStatus[rowData[col.field].toLowerCase()] : getStatusClass(rowData[col.field].toLowerCase())">
                  {{rowData[col.field] | uppercase}}
                </button></ng-template>
              <ng-template *ngIf="col.field === 'serviceOrderNumber'" pTemplate="output">
                <a (click)="openTab(rowData)" onmouseover="this.style.cursor='pointer'">
                  {{rowData[col.field] | uppercase}}
                </a>
              </ng-template>
              <ng-template *ngIf="col.field === 'contractNumber'" pTemplate="output">
                <a (click)="openContract(rowData)" onmouseover="this.style.cursor='pointer'">{{rowData[col.field] |
                  uppercase}}</a>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="ellipsisClick($event,rowData,opRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button class="btn-crm-success" type="button" (click)="loadServiceOrders()"
      [attr.disabled]="disableLoadMore?true:null" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions" (onClick)="ellipsisOptionClick($event,opRow)"></p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event,opHeader)">
  </p-listbox>
</p-overlayPanel>