import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { buttonStatus } from '../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { adminEllipsisHeaderOptions, ellipsisOptions } from '../../coverage.constant';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { CoverageFormFieldService } from '../../coverage-form-field.service';
import { RateBucketService } from '../rate-bucket-config.service';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'cv-rate-bucket-common',
  templateUrl: 'rate-bucket-common.template.html',
  providers: [CoverageFormFieldService, MessageService, FormFieldControlService, RateBucketService],
})
export class RateBucketCommonComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Input() dataList: any[] = [];
  @Input() title: string;
  @Input() categoryCode: string;
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = adminEllipsisHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  submitButton: string;
  lastOffsetVal = 0;
  offsetLimit = 25;
  querySubscription: Subscription;
  disableLoadMore = false;
  isLoading = false;
  editConstant: string;
  selectedRowIndex: number;
  @Input() formFields: FormField<string>[] = [];
  header: string;
  displayAdd: boolean;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  displayUpdate: boolean;
  form = new FormGroup({});
  formData = null;
  params = {
    categoryCode: null,
    bucketCode: null,
    bucketLabel: null,
    azureUserId: null,
    limit: null,
    offset: null,
  };
  constructor(
    private service: CoverageFormFieldService,
    private rateBucketService: RateBucketService,
    private azureService: AzureLoginService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formFieldService: FormFieldControlService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'bucketCode', header: 'Bucket Code', type: 'text' },
      { field: 'bucketLabel', header: 'Bucket Label', type: 'text' },
    ];
    this.service.getRateBucketSearchFields().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
      this.form = this.formFieldService.toFormGroup(this.formFields);
    });

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Bucket Configuration',
      category: 'Coverage',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Bucket Configuration',
      category: 'Coverage',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    this.formData = {
      categoryCode: this.categoryCode,
      bucketCode: this.selectedItem.bucketCode,
      bucketLabel: this.selectedItem.bucketLabel,
    };
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
    if (event.value === 'delete') {
      this.deleteRateBucket();
    } else {
      this.updateRateBucket();
    }
  }

  onRowEdit() {
    this.updateRateBucket();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  onSubmit(formData: FormGroup) {
    this.isLoading = true;
    const payload = formData.getRawValue();
    let params: MutationValues = {
      insertUserName: this.azureService.accountId,
      rateBucketIdOriginal: payload.rateBucketIdOriginal,
      categoryCode: this.categoryCode,
      bucketLabel: payload.bucketLabel,
      bucketCode: payload.bucketCode,
    };
    if (this.header === 'Update Rate Bucket') {
      params = {
        ...params,
        rateBucketIdOriginal: this.selectedItem.rateBucketIdOriginal,
      };
    }
    this.querySubscription = this.rateBucketService.createUpdateRateBucket(params).subscribe(
      ({ data }: any) => {
        if (data.createUpdateRateBucket) {
          this.showToastStatus(true);
          this.formValueChanged.emit(true);
        } else {
          this.formValueChanged.emit(false);
        }
        this.form.reset();
        this.dataList = [];
        this.displayAdd = false;
      },
      () => {
        this.showToastStatus(false);
        this.isLoading = false;
      },
    );
  }

  showToastStatus(success: boolean) {
    if (success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Success.`,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed`,
      });
    }
  }
  updateRateBucket() {
    this.header = 'Update Rate Bucket';
    this.submitButton = this.selectedItem === 'item' ? 'Add' : 'Update';
    this.displayAdd = true;
  }
  deleteRateBucket() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this ?`,
      accept: () => {
        const params: MutationValues = {
          insertUserName: this.azureService.accountId,
          rateBucketIdOriginal: this.selectedItem.rateBucketIdOriginal,
          categoryCode: this.selectedItem.categoryCode,
          bucketCode: this.selectedItem.bucketCode,
          bucketLabel: this.selectedItem.bucketLabel,
          isDeleted: true,
        };
        this.querySubscription = this.rateBucketService.createUpdateRateBucket(params).subscribe(
          (data) => {
            this.showToastStatus(true);
            this.formValueChanged.emit(true);
          },
          () => {
            this.showToastStatus(false);
          },
        );
      },
    });
  }
  onCancel() {
    this.form.reset();
    this.displayAdd = false;
  }
  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
export interface MutationValues {
  rateBucketIdOriginal?: number;
  categoryCode: string;
  bucketCode: string;
  bucketLabel: string;
  isDeleted?: boolean;
  insertUserName: string;
}
