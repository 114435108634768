<div class="crm-container">
  <app-section-header [title]="'Search Roles Results'" [optionHeader]="optionHeader" />
  <div class="sub-section">
    <app-tab-spinner class="spinner" *ngIf="isLoading" />
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing"
        let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button><p-cellEditor
              *ngIf="col.type === 'text'"><ng-template pTemplate="input"><input class="input-text" type="text"
                  [(ngModel)]="rowData[col.field]" /></ng-template><ng-template
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,optionRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button><button class="ui-button-success" *ngIf="editing"
              pButton="pButton" type="button" pSaveEditableRow="pSaveEditableRow" icon="pi pi-check"
              style="margin-right: 0.5em" (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger"
              *ngIf="editing" pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #optionRow><p-listbox [options]="ellipsisRowOptions"
    (onClick)="handleOpen($event)"></p-listbox></p-overlayPanel><p-overlayPanel #optionHeader><p-listbox
    [options]="ellipsisHeaderOptions" (onClick)="handleOpen($event)"> </p-listbox></p-overlayPanel><p-dialog
  [header]="header" [(visible)]="displayAdd" showeffect="fade" [modal]="true" [styleClass]="'note-dailog'"><sec-modal
    [formFields]="formModalInfo"></sec-modal><ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="displayAdd=false" label="No">Cancel</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onYes()" label="Yes">Save</button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [header]="header" [(visible)]="displayUpdate" showeffect="fade" [modal]="true" [styleClass]="'note-dailog'">
  <sec-modal [formFields]="formModalInfo"></sec-modal>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="displayUpdate=false"
        label="No">Cancel</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onYes()" label="Yes">Save</button>
    </div>
  </ng-template>
</p-dialog>