import { Component, Input, OnInit } from '@angular/core';
import { ServiceStatusLogService } from './service-status-log.service';
import { buttonStatus } from '../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { statusLogHeaderOptions } from '../../service.constant';

@Component({
  selector: 'service-status-log',
  templateUrl: 'service-status-log.template.html',
  providers: [ServiceStatusLogService],
})
export class ServiceStatusLogComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  selectedEllipsisItem: any;
  ellipsisHeaderOptions: any[] = statusLogHeaderOptions;
  constructor(private serviceStatusLogService: ServiceStatusLogService) {}

  ngOnInit() {
    this.serviceStatusLogService.getServiceStatusLog().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'statusChangeTime', header: 'STATUS CHANGE TIME', type: 'text' },
      { field: 'userName', header: 'USER NAME', type: 'text' },
      { field: 'statusChangeFrom', header: 'STATUS CHANGE FROM', type: 'text' },
      { field: 'statusChangeTo', header: 'STATUS CHANGE TO', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
