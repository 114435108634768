import { Injectable } from '@angular/core';

import { DropdownField } from '../../shared/form-field/dropdown-field';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { RadioButtonField } from '../../shared/form-field/radio-button-field';

@Injectable()
export class ImportClaimFieldService {
  // TODO: get from a remote source of formField metadata
  getImportClaimFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'Select file type',
        label: 'Select File type ',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),
      new RadioButtonField({
        key: 'Upload',
        label: '',
        options: [{ key: 'upload', value: 'Upload' }],
        order: 2
      }),

      new RadioButtonField({
        key: 'ftp(uploaded files)',
        label: '',
        options: [{ key: 'FTP(Uploaded Files)', value: 'FTP(Uploaded Files)' }],
        order: 3
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
