import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { GenericDealerStatus } from '../dealer.constants';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const dealerLocation = gql`
  query(
    $status: String!
    $number: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $phoneNumber: String
    $agentIdOriginal: Int
    $limit: Int
    $offset: Int
  ) {
    getDealerSearchResults(
      status: $status
      number: $number
      name: $name
      city: $city
      state: $state
      zipcode: $zipCode
      phoneNumber: $phoneNumber
      agentIdOriginal: $agentIdOriginal
      offset: $offset
      limit: $limit
    ) {
      dealerGroupsIdOriginal
      status
      number
      name
      city
      state
      phoneNumber
      status
      agentId
      zipCode
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class SearchProfileService {
  constructor(private apollo: Apollo) {}

  getProfileDetails = data =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: dealerLocation,
      variables: { ...data, status: data.status?.value || '', state: data.state?.value || '' },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
