export const ellipsisHeaderOptions = [
  {
    label: 'Add New Email Template Row',
    value: 'Add New Email Template Row',
    callback: 'onClickPrint'
  },
  { label: 'Verify New Email', value: 'Verify New Email', callback: 'onClickPrint' }
];
export const ellipsisRowOptions = [
  { label: 'Update Row', value: 'Update Row', callback: 'onRowEdit' }
];
