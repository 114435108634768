<div class="dealer-details-container">
  <div class="dealer-details-body">
    <div>
      <form (ngSubmit)="onSubmit(form)" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
        <div class="button-bar">
          <button type="button" (click)="advancedSearch(true)" *ngIf="advancedFormFields && isAdvanced">Advanced</button
          ><button *ngIf="!advancedFormFields && isAdvanced">Advanced</button>
        </div>
        <div class="button-bar">
          <button type="button" (click)="clear(form)" *ngIf="isClearSearch">Clear</button
          ><button type="submit" [disabled]="!form.valid" *ngIf="isClearSearch">Search</button>
        </div>
      </form>
      <p-dialog
        *ngIf="advancedFormFields"
        [header]="modalHeader"
        [(visible)]="displayModal"
        showeffect="fade"
        [modal]="true"
        [styleClass]="'add-dialog'"
      >
        <div class="modal-content">
          <form *ngIf="modalForm" (ngSubmit)="onSubmit(modalForm)" [formGroup]="modalForm">
            <div class="grid">
              <div class="col-4" *ngFor="let formField of advancedFormFields">
                <form-field [formField]="formField" [form]="modalForm"></form-field>
              </div>
            </div>
            <button class="crm-button" type="submit" id="btn-submit-form" label="!isModalFormValid" hidden="hidden"></button>
          </form>
        </div>
        <ng-template pTemplate="footer">
          <div class="button-bar">
            <button type="button" (click)="clear(modalForm)" *ngIf="isClearSearch">Clear</button>
            <button class="crm-button label-btn" (click)="onSubmit(modalForm)" *ngIf="isModalFormValid" for="btn-submit-form" tabindex="0">Search</button>
            <button class="crm-button label-btn btn-disabled" *ngIf="!isModalFormValid" tabindex="0">Search</button>
          </div>
        </ng-template>
      </p-dialog>
    </div>
  </div>
</div>
