import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BuyingGroupDocumentsTabService {
  constructor() {}
  getBuyingGroupDocuments(): Observable<any[]> {
    return of([
      {
        file_name: 'file1.pdf',
        description: 'File 1 PDF',
        uploaded_date: 'June 1 2020',
        uploaded_by: 'ABCJ01',
        action: 'Add'
      },
      {
        file_name: 'file2.png',
        description: 'File 2 PDF',
        uploaded_date: 'January 1 2020',
        uploaded_by: 'DEF01',
        action: 'Add'
      }
    ]);
  }
}
