import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { SecurityTypeService } from '../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import _forEach from 'lodash/forEach';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { MessageService } from 'primeng/api';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { SearchDealerLocationService } from '../../../dealer/search-location/seach-dealer-location.service';
import { Tab } from '../../../services/tab';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { copy } from '../../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

const LINK_REQUIRED = {
  '!Service Provider Independent': true,
  'BG/SERV Hybrid': true,
  Dealer: true,
  'Dealer PLUS': true,
  'SSD - Limited': true,
  'SSD - Lite': true,
  'SSD - Med Internal Notes': true,
  'SSD-Med': true,
  'SSD-Plus': true,
  Customer: true,
};

interface KeyValuePair {
  key: string;
  value: string;
}

@Component({
  selector: 'admin-sec-user',
  templateUrl: 'sec-user.template.html',
  styleUrls: ['sec-user.scss'],
  providers: [
    SecurityTypeService,
    ContractFormFieldService,
    FormFieldControlService,
    MessageService,
    SearchDealerLocationService,
  ],
})
export class SecUserComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  cols: any[];
  dataList: any[];
  @Input() formFields: FormField<string>[] = [];
  selectedItem: any;
  header: string;
  isSubmit: boolean;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  ellipsisHeaderOptionSec: any;
  ellipsisRowOptionsSec: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  formModalInfo: FormField<any>[] = [];
  resetFormModalInfo: FormField<any>[] = [];
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  displayModal: boolean;
  @ViewChild('secModal') public secModal;
  @ViewChild('supportTaskSidebar') public supportTaskSidebar;
  form = new FormGroup({});
  isCreateMode: boolean;
  isModelLoading: boolean;
  querySubscriptionRoles: Subscription;
  authDetails: IRoleAuthorizationService;
  displayTable: false;
  rowCount = 0;
  querySubscription: Subscription;
  dealerName: string;
  dealerLocationInfo = [];
  dealerLocationMap: KeyValuePair[] = [];
  initialLinikInfo = [];
  selectedList = [];
  newTab: Tab;
  constructor(
    private securityTypeService: SecurityTypeService,
    private cdr: ChangeDetectorRef,
    private contractService: ContractFormFieldService,
    private qcs: FormFieldControlService,
    private http: HttpClient,
    private roleService: RoleAuthorizationService,
    private messageService: MessageService,
    private dropdownDataService: DropdownDataService,
    private searchDealerLocationService: SearchDealerLocationService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {
    super();
  }

  isLoading = false;
  displayUpdate: boolean;
  addSpace = /([A-Z])/g;

  ngOnInit() {
    this.displayTable =
      this.searchParams &&
      (this.searchParams.username ||
        this.searchParams.email ||
        this.searchParams.attached_roles ||
        this.searchParams.userProfileType ||
        this.searchParams.userProfileNumber);
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SecUserComponent,
      generalArray: this.ellipsisRowOptions,
    };
    this.ellipsisRowOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));

    this.editConstant = uuidv4();
    this.getData();

    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'userName', header: 'USER NAME', type: 'text' },
      { field: 'email', header: 'EMAIL', type: 'text' },
      { field: 'attachedRole', header: 'ATTACHED ROLES', type: 'text' },
      { field: 'userProfileType', header: 'Link Type', type: 'text' },
      { field: 'userProfileNumber', header: 'Link Number', type: 'text' },
    ];

    this.querySubscriptionRoles = this.securityTypeService
      .getRoleDetails('')
      .subscribe(({ data, loading }: any) => {
        const options = { roles: [] };
        _forEach(data.getAdminSettingRoles, (item) => {
          options.roles.push({ key: item.role_name, value: item.role_id });
        });

        this.contractService.getSecFormModalFields(options).subscribe((formModalInfo) => {
          this.formFields = this.roleService.validateFormFields(formModalInfo);
          this.pullDealerLocationInformation('%');
          this.form = this.qcs.toFormGroup(this.formFields);
          this.form.valueChanges.subscribe((x) => {
            this.formValueChanged.emit(true);
          });
          this.onDealerChanges();
        });
      });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'User Profile',
      category: 'User Security',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisRowOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'User Profile',
      category: 'User Security',
    };
    this.ellipsisRowOptions = this.rps.evaluate(
      this.ellipsisRowOptionsSec,
      this.ellipsisRowOptions,
    );
  }

  removeDuplicatesBuyingGroup() {
    const buyingGroupData = this.dropdownDataService.buyingGroupData;
    const filterData = this.uniqueVlauesFromArray(buyingGroupData, (it) => it.agentIdOriginal);

    const ddfilteredBuyingGroupData = filterData.map((x) => ({
      key: x.name,
      value: x.agentIdOriginal,
    }));
    if (ddfilteredBuyingGroupData.length > 0) {
      this.formFields[9].options = ddfilteredBuyingGroupData;
      this.setDealers(ddfilteredBuyingGroupData);
    } else {
      this.setDealers([]);
    }
  }

  setDealers(ddfilteredBuyingGroupData) {
    const dealerGroupData = this.dropdownDataService.dealerGroupData;
    const filtereddealersData = dealerGroupData
      ? dealerGroupData.filter(
          (d) => ddfilteredBuyingGroupData.find((b) => d.agentId === b.value),
          // d => d.agentId === null || ddfilteredBuyingGroupData.find(b => d.agentId === b.value)
        )
      : [];
    const ddfilteredDealersData = filtereddealersData.map((x) => ({
      key: x.dealerGroupName,
      value: x.dealerGroupsIdOriginal,
    }));

    if (ddfilteredDealersData) {
      this.formFields[10].options = ddfilteredDealersData;
    } else {
      const optionsDealerGroup = this.dropdownDataService.dealerGroupData
        ? this.dropdownDataService.dealerGroupData.map((x) => ({
            key: x.dealerGroupName,
            value: x.dealerGroupsIdOriginal,
          }))
        : [];
      this.formFields[10].options = optionsDealerGroup;
      // this.pullDealerLocationInformation('%');
    }
  }

  onDealerChanges() {
    this.removeDuplicatesBuyingGroup();
    this.form.get('masterBuyingGroup').valueChanges.subscribe((masterBuyingGroup: any) => {
      const buyingGroupData = this.dropdownDataService.buyingGroupData;
      let filteredBuyingGroupData = buyingGroupData
        ? buyingGroupData.filter((m) =>
            masterBuyingGroup.find((e) => e.value === m.masterBuyingGroupId),
          )
        : [];
      filteredBuyingGroupData = this.uniqueVlauesFromArray(
        filteredBuyingGroupData,
        (it) => it.agentIdOriginal,
      );

      const ddfilteredBuyingGroupData = filteredBuyingGroupData.map((x) => ({
        key: x.name,
        value: x.agentIdOriginal,
      }));

      if (ddfilteredBuyingGroupData.length > 0) {
        this.formFields[9].options = ddfilteredBuyingGroupData; // BuyingData
        this.setDealers(ddfilteredBuyingGroupData);
      } else {
        this.removeDuplicatesBuyingGroup();
      }
    });

    this.form.get('buyingGroup').valueChanges.subscribe((buyingGroup: any) => {
      if (buyingGroup && buyingGroup.length > 0) {
        const dealerGroupData = this.dropdownDataService.dealerGroupData;
        let filtereddealersData = dealerGroupData
          ? dealerGroupData.filter(
              // d => d.agentId === null || buyingGroup.find(b => d.agentId === b.value)
              (d) => buyingGroup.find((b) => d.agentId === b.value),
            )
          : [];
        filtereddealersData = this.uniqueVlauesFromArray(
          filtereddealersData,
          (it) => it.dealerGroupsIdOriginal,
        );
        const ddfilteredDealersData = filtereddealersData.map((x) => ({
          key: x.dealerGroupName,
          value: x.dealerGroupsIdOriginal,
        }));

        if (ddfilteredDealersData) {
          this.formFields[10].options = ddfilteredDealersData;
        } else {
          const optionsDealerGroup = this.dropdownDataService.dealerGroupData
            ? this.dropdownDataService.dealerGroupData.map((x) => ({
                key: x.dealerGroupName,
                value: x.dealerGroupsIdOriginal,
              }))
            : [];
          this.formFields[10].options = optionsDealerGroup;
        }
      } else {
        const optionsDealerGroup = this.dropdownDataService.dealerGroupData
          ? this.dropdownDataService.dealerGroupData.map((x) => ({
              key: x.dealerGroupName,
              value: x.dealerGroupsIdOriginal,
            }))
          : [];
        this.formFields[10].options = optionsDealerGroup;
      }
    });

    this.form.get('dealerGroupNumber').valueChanges.subscribe((dealerGroupNumber: any) => {
      // console.log(`CHANGE IN DG ${JSON.stringify(dealerGroupNumber.length)}`);
      this.dealerLocationInfo = [];
      if (dealerGroupNumber && dealerGroupNumber.length > 0) {
        const dealerGroupSelected = this.dropdownDataService.dealerGroupData
          ? this.dropdownDataService.dealerGroupData.filter((e) =>
              dealerGroupNumber.find((x) => x.value === e.dealerGroupsIdOriginal),
            )
          : [];
        for (const dg of dealerGroupSelected) {
          const d = this.dealerLocationMap.find(
            (x) => x.key === dg.dealerGroupsIdOriginal.toString(),
          );
          if (d !== undefined) {
            if (d.value !== 'NO_DATA') {
              this.dealerLocationInfo.push(...JSON.parse(d.value));
              this.dealerLocationInfo = this.uniqueVlauesFromArray(
                this.dealerLocationInfo,
                (it) => it.sellerIdOriginal,
              );
              const locations = this.dealerLocationInfo.map((x) => ({
                key: `${x.name} | ${x.number} `,
                value: x.sellerIdOriginal,
              }));
              if (locations) {
                this.formFields[11].options = locations;
              }
            }
          } else {
            this.pullDealerLocationInformation(dg.dealerGroupsIdOriginal.toString());
          }
        }
      } else {
        this.pullDealerLocationInformation('%');
      }
    });
  }

  pullDealerLocationInformation(dgNumber) {
    let query = {};
    if (dgNumber === '%') {
      query = { name: '%', status: '', limit: 1000 };
    } else {
      query = { dealerGroupNumber: dgNumber, status: '', limit: 4000 };
    }
    // console.log(`query ${JSON.stringify(query)}`);
    this.querySubscription = this.searchDealerLocationService
      .getDealerLocationSearchDetails(query)
      .subscribe(
        ({ data }) => {
          const dealerLocations = data.getDealerLocationSearchResults;
          // console.log(`RESULT COUNT FOR ${dgNumber} is ${dealerLocations.length}`);
          if (dealerLocations && dealerLocations.length > 0) {
            this.dealerLocationMap.push({
              key: dgNumber,
              value: JSON.stringify(dealerLocations),
            });
          } else {
            this.dealerLocationMap.push({
              key: dgNumber,
              value: 'NO_DATA',
            });
          }
          this.dealerLocationInfo.push(...dealerLocations);
          this.dealerLocationInfo = this.uniqueVlauesFromArray(
            this.dealerLocationInfo,
            (it) => it.sellerIdOriginal,
          );

          const locations = this.dealerLocationInfo.map((x) => ({
            key: `${x.name} | ${x.number} `,
            value: x.sellerIdOriginal,
          }));
          if (locations) {
            this.formFields[11].options = locations;
          }
          this.populateDealerLocation();
        },
        (err) => {
          throw err;
        },
      );
  }

  getData() {
    this.isLoading = true;
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    let userName = '';
    if (this.searchParams && this.searchParams.username) {
      userName = this.searchParams.username;
    }
    let email = '';
    if (this.searchParams && this.searchParams.email) {
      email = this.searchParams.email;
    }
    let roleName = '';
    if (this.searchParams && this.searchParams.attached_roles) {
      roleName = this.searchParams.attached_roles.value;
    }
    let userProfileType = '';
    if (this.searchParams && this.searchParams.userProfileType) {
      userProfileType =
        this.searchParams.userProfileType.length > 0
          ? this.searchParams.userProfileType.map(({ value }) => value).toString()
          : null;
    }
    let userProfileNumber = '';
    if (this.searchParams && this.searchParams.userProfileNumber) {
      userProfileNumber = this.searchParams.userProfileNumber;
    }
    this.querySubscription = this.securityTypeService
      .getUserDetails(userName, email, roleName, userProfileType, userProfileNumber)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          this.dataList = [...data.getAdminSettings];
          if (this.dataList) {
            this.rowCount = this.dataList.length;
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );

    this.dropdownDataService.getUpdatedUserLists().subscribe(({ data }) => {
      this.dropdownDataService.userListData = data.getUserListCache;
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  openProfile(event: any, rowData: any) {
    this.selectedItem = rowData;
    this.onRowUpdate(event);
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowUpdate(event) {
    let phoneNumber = null;
    let phoneNumberExt = null;
    if (this.selectedItem.phoneNumber && this.selectedItem.phoneNumber !== 'null') {
      phoneNumber = this.selectedItem.phoneNumber;
    }
    if (this.selectedItem.phoneNumberExt && this.selectedItem.phoneNumberExt !== 'null') {
      phoneNumberExt = this.selectedItem.phoneNumberExt;
    }
    let userProfileName = null;
    let userProfileNumber = null;
    const matchingUserProfileTypeValues = [];
    if (this.selectedItem.userProfileType && this.selectedItem.userProfileType.length > 0) {
      const userProfileTypeElements = this.selectedItem.userProfileType.split('||');
      for (const singleUserProfileType of userProfileTypeElements) {
        const matchingUserProfileType = this.dropdownDataService.userProfileTypesList
          ? this.dropdownDataService.userProfileTypesList.find(
              (x) => x.userProfileType === singleUserProfileType.trim(),
            )
          : null;
        if (matchingUserProfileType) {
          matchingUserProfileTypeValues.push({
            key: matchingUserProfileType.userProfileType.replace(this.addSpace, ' $1'),
            value: matchingUserProfileType.userProfileType,
          });
        }
      }
    }
    if (this.selectedItem.userProfileName && this.selectedItem.userProfileName !== 'null') {
      userProfileName = this.selectedItem.userProfileName;
    }
    if (this.selectedItem.userProfileNumber && this.selectedItem.userProfileNumber !== 'null') {
      userProfileNumber = this.selectedItem.userProfileNumber;
    }
    this.form.patchValue({
      username: this.selectedItem.userName,
      Istemplate: this.selectedItem.isTemplate,
      email: this.selectedItem.email,
      firstname: this.selectedItem.firstName,
      lastname: this.selectedItem.lastName,
      phonenumber: phoneNumber,
      phoneextension: phoneNumberExt,
      accountstatus: {
        key: this.selectedItem.status,
        value: this.selectedItem.status,
      },
      role: { key: this.selectedItem.attachedRole, value: this.selectedItem.role_id },
    });
    this.selectedList = [];
    this.initialLinikInfo = [];
    if (this.selectedItem.userProfileLinkId) {
      const linkIdList = this.selectedItem.userProfileLinkId.split('||');
      const linkTypeList = this.selectedItem.userProfileType.split('||');
      const linkNameList = this.selectedItem.userProfileNumber.split('||');
      const linkNumberList = this.selectedItem.userProfileNumber.split('||');
      if (linkIdList.length > 0) {
        for (const x in linkIdList) {
          if (linkIdList.hasOwnProperty(x)) {
            this.initialLinikInfo.push({
              UserProfileLinkId: linkIdList[x],
              UserProfileType: linkTypeList[x],
              UserProfileNumber: linkNumberList[x],
              UserProfileName: linkNameList[x],
            });
            switch (linkTypeList[x]) {
              case 'Dealer':
                this.selectedList.push({
                  type: 'Dealer',
                  key: linkNameList[x],
                  value: linkNumberList[x],
                });
                break;
              case 'MasterBuyingGroup':
                this.selectedList.push({
                  type: 'MasterBuyingGroup',
                  key: linkNameList[x],
                  value: linkNumberList[x],
                });
                break;
              case 'Servicer':
                this.selectedList.push({
                  type: 'Servicer',
                  key: linkNameList[x],
                  value: linkNumberList[x],
                });
                break;
              case 'BuyingGroup':
                this.selectedList.push({
                  type: 'BuyingGroup',
                  key: linkNameList[x],
                  value: linkNumberList[x],
                });
                break;
              case 'MyWorkQueue':
                this.selectedList.push({
                  type: 'MyWorkQueue',
                  key: linkNameList[x],
                  value: linkNumberList[x],
                });
                break;
              case 'DealerLocation':
                this.selectedList.push({
                  type: 'DealerLocation',
                  key: linkNameList[x],
                  value: linkNumberList[x],
                });
                break;
            }
          }
        }

        let masterBuyingGroupSelected = this.selectedList.filter(
          (e) => e.type === 'MasterBuyingGroup',
        );
        const masterBuyingGroupData = this.dropdownDataService.masterBuyingGroupData;
        masterBuyingGroupSelected = masterBuyingGroupData
          ? masterBuyingGroupData.filter((e) =>
              masterBuyingGroupSelected.find((b) => b.value === e.name),
            )
          : [];
        masterBuyingGroupSelected = masterBuyingGroupSelected.map((e) => ({
          key: e.name,
          value: e.masterBuyingGroupIDOriginal,
        }));
        this.form.get('masterBuyingGroup').setValue(masterBuyingGroupSelected as never);

        let buyingGroupSelected = this.selectedList.filter((e) => e.type === 'BuyingGroup');
        const buyingGroupData = this.dropdownDataService.buyingGroupData;
        buyingGroupSelected = buyingGroupData
          ? buyingGroupData.filter((e) => buyingGroupSelected.find((b) => b.value === e.number))
          : [];

        // UNIQUE CHECK
        buyingGroupSelected = this.uniqueVlauesFromArray(
          buyingGroupSelected,
          (it) => it.agentIdOriginal,
        );

        const buyingGroupSelectedFilter = buyingGroupSelected.map((e) => ({
          key: e.name,
          value: e.agentIdOriginal,
        }));
        this.form.get('buyingGroup').setValue(buyingGroupSelectedFilter as never);

        let dealerSelected = this.selectedList.filter((e) => e.type === 'Dealer');
        const dealerGroupData = this.dropdownDataService.dealerGroupData;
        // console.log(dealerSelected);
        dealerSelected = dealerGroupData
          ? dealerGroupData.filter((e) =>
              dealerSelected.find((b) => b.value === e.dealerGroupNumber),
            )
          : [];

        // UNIQUE CHECK
        dealerSelected = this.uniqueVlauesFromArray(
          dealerSelected,
          (it) => it.dealerGroupsIdOriginal,
        );

        const dealerSelectedItems = dealerSelected.map((e) => ({
          key: e.dealerGroupName,
          value: e.dealerGroupsIdOriginal,
        }));
        this.form.get('dealerGroupNumber').setValue(dealerSelectedItems as never);

        for (const x of buyingGroupSelected) {
          this.pullDealerLocationInformation(x.key);
        }

        for (const dg of dealerSelected) {
          this.pullDealerLocationInformation(dg.dealerGroupNumber);
        }

        let workQueueSelected = this.selectedList.filter((e) => e.type === 'MyWorkQueue');
        workQueueSelected = workQueueSelected.map((e) => ({
          key: e.value,
          value: e.value,
        }));
        this.form.get('queueType').setValue(workQueueSelected as never);

        let servicerSelected = this.selectedList.filter((e) => e.type === 'Servicer');
        const servicerData = this.dropdownDataService.servicerListLinkData;
        const correctServicerSelected = servicerData
          ? servicerData.filter((e) => servicerSelected.find((x) => x.value === e.value))
          : [];
        servicerSelected = correctServicerSelected.map((e) => ({
          key: e.key,
          value: e.value,
        }));
        this.form.get('servicer').setValue(servicerSelected as never);
      }
    } else {
      this.form.patchValue({
        masterBuyingGroup: [],
        buyingGroup: [],
        dealerGroupNumber: [],
        dealerLocation: [],
        queueType: [],
        servicer: [],
      });
    }
    this.displayModal = true;
    this.header = 'Update User';
  }

  populateDealerLocation() {
    let dealerLocationSelected: any[] = this.selectedList.filter(
      (e) => e.type === 'DealerLocation',
    );
    const dealerLocationData = this.dealerLocationInfo;
    dealerLocationSelected = dealerLocationData
      ? dealerLocationData.filter((e) => dealerLocationSelected.find((b) => b.value === e.number))
      : [];

    // UNIQUE CHECK
    dealerLocationSelected = this.uniqueVlauesFromArray(
      dealerLocationSelected,
      (it) => it.sellerIdOriginal,
    );

    dealerLocationSelected = dealerLocationSelected.map((e) => ({
      key: e.name + ' | ' + e.number + ' ',
      value: e.sellerIdOriginal,
    }));
    this.form.get('dealerLocation').setValue(dealerLocationSelected as never); // ###CHANGE####
  }

  onRowDelete(event) {
    const payload = this.selectedItem;
    if (
      window.confirm(
        `Are you sure you want to delete user [UserName: ${payload.userName} | EmailAddress: ${payload.email}`,
      )
    ) {
      this.isLoading = true;
      payload.azureUserId = this.selectedItem.azureUserId;
      this.securityTypeService.deleteUser(payload).subscribe(
        (data) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Successfully deleted user [UserName: ${payload.userName} | EmailAddress: ${payload.email}`,
          });
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to delete user [UserName: ${payload.userName} | EmailAddress: ${payload.email}`,
          });
          this.getData();
        },
      );
    }
  }

  onRowReset(event) {
    this.header = 'Update Password';
    this.displayUpdate = true;
  }

  passwordValidation(payLoad, creationFlag) {
    // If this is from Creation, it doesn't require a password, so just return good.
    if (creationFlag && payLoad.createuserpassword.length === 0) {
      return true;
    }

    if (!creationFlag && payLoad.createuserpassword.length === 0) {
      window.alert('Password cannot be empty');
      return false;
    }

    if (
      payLoad.createuserpassword.length > 0 &&
      payLoad.createuserpassword !== payLoad.confirmcreateuserpassword
    ) {
      window.alert('Password and Confirmation Password do not match.');
      return false;
    }

    if (payLoad.createuserpassword.length > 0 && payLoad.createuserpassword.length < 8) {
      window.alert('Password must be at least 8 characters long');
      return false;
    }

    const hasNumber = /\d/;
    if (payLoad.createuserpassword.length > 0 && !hasNumber.test(payLoad.createuserpassword)) {
      window.alert('Password must contain at least one number');
      return false;
    }

    const hasUpper = /([A-Z])/;
    if (payLoad.createuserpassword.length > 0 && !hasUpper.test(payLoad.createuserpassword)) {
      window.alert('Password must contain at least one upper case letter');
      return false;
    }

    const hasLower = /([a-z])/;
    if (payLoad.createuserpassword.length > 0 && !hasLower.test(payLoad.createuserpassword)) {
      window.alert('Password must contain at least one lower case letter');
      return false;
    }

    const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (payLoad.createuserpassword.length > 0 && !hasSpecial.test(payLoad.createuserpassword)) {
      window.alert('Password must contain at least one special character');
      return false;
    }
    return true;
  }

  onPasswordUpdate() {
    const payLoad: any = this.form.getRawValue();
    const selectedPayload = this.selectedItem;

    if (!this.passwordValidation(payLoad, false)) {
      return;
    }

    this.isModelLoading = true;
    payLoad.UserProfileLinks = [];
    payLoad.UserProfileLinks = this.getPresentLinks(payLoad);
    this.securityTypeService.deleteUser(selectedPayload).subscribe((deleteData) => {
      this.securityTypeService.createUsers(payLoad).subscribe(
        (data) => {
          this.isModelLoading = false;
          this.isCreateMode = false;
          this.displayModal = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Successfully updated password`,
          });
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to update password`,
          });
          this.getData();
        },
      );
    });
  }

  onRowCreate(event) {
    this.header = 'Create User';
    this.isCreateMode = true;
    this.displayModal = true;
    this.initialLinikInfo = [];
    this.form.patchValue({
      username: null,
      Istemplate: null,
      email: null,
      firstname: null,
      lastname: null,
      phonenumber: null,
      phoneextension: null,
      accountstatus: null,
      role: null,
      userProfileType: null,
      userProfileName: null,
      userProfileNumber: null,
      masterBuyingGroup: [],
      buyingGroup: [],
      dealerGroupNumber: [],
      dealerLocation: [],
      queueType: [],
      servicer: [],
    });
  }

  makeUserProfileLink(linkType, linkNumber, name, id = null) {
    return {
      UserProfileLinkId: id,
      UserProfileType: linkType,
      UserProfileNumber: linkNumber,
      UserProfileName: name,
    };
  }

  getPresentLinks(payLoad) {
    const userProfileLinks = [];
    for (const masterBuyingGroup of payLoad.masterBuyingGroup) {
      userProfileLinks.push(
        this.makeUserProfileLink('MasterBuyingGroup', masterBuyingGroup.key, masterBuyingGroup.key),
      );
    }
    for (const buyingGroup of payLoad.buyingGroup) {
      const bg = this.dropdownDataService.buyingGroupData
        ? this.dropdownDataService.buyingGroupData.find(
            (e) => e.agentIdOriginal === buyingGroup.value,
          )
        : null;
      if (bg) {
        userProfileLinks.push(this.makeUserProfileLink('BuyingGroup', bg.number, bg.name));
      }
    }
    for (const dealerGroup of payLoad.dealerGroupNumber) {
      const dg = this.dropdownDataService.dealerGroupData
        ? this.dropdownDataService.dealerGroupData.find(
            (e) => e.dealerGroupsIdOriginal === dealerGroup.value,
          )
        : null;
      if (dg) {
        userProfileLinks.push(
          this.makeUserProfileLink('Dealer', dg.dealerGroupNumber, dg.dealerGroupName),
        );
      }
    }
    for (const dealerLocation of payLoad.dealerLocation) {
      const dl = this.dealerLocationInfo
        ? this.dealerLocationInfo.find((e) => e.sellerIdOriginal === dealerLocation.value)
        : null;
      if (dl) {
        userProfileLinks.push(this.makeUserProfileLink('DealerLocation', dl.number, dl.name));
      }
    }
    for (const servicer of payLoad.servicer) {
      userProfileLinks.push(this.makeUserProfileLink('Servicer', servicer.value, servicer.key));
    }
    for (const queueData of payLoad.queueType) {
      userProfileLinks.push(
        this.makeUserProfileLink('MyWorkQueue', queueData.value, queueData.key),
      );
    }
    return userProfileLinks;
  }

  onSubmit() {
    const payLoad: any = this.form.getRawValue();
    payLoad.UserProfileLinks = [];
    payLoad.UserProfileLinks = this.getPresentLinks(payLoad);
    const curLinks = payLoad.UserProfileLinks;
    const finalUserProfileLinks = this.validateLinks(
      this.initialLinikInfo,
      payLoad.UserProfileLinks,
    );
    payLoad.UserProfileLinks = finalUserProfileLinks;
    payLoad.InsertUserName = this.azureService.accountId;
    // console.log(`FINAL PAYLOAD PREPARED \n : ${JSON.stringify(payLoad)}`);
    if (payLoad.role.key in LINK_REQUIRED && curLinks.length === 0) {
      window.alert(`The Role '${payLoad.role.key}' requires atleast one Link`);
      return;
    }
    if (this.isCreateMode) {
      this.isModelLoading = true;
      if (!this.passwordValidation(payLoad, true)) {
        this.isModelLoading = false;
        return;
      }
      this.securityTypeService.createUsers(payLoad).subscribe(
        (data) => {
          this.isModelLoading = false;
          this.isCreateMode = false;
          this.displayModal = false;
          if (data.statusCode === 400) {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail: `User already exists`,
            });
          } else {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Successfully created user`,
            });
          }
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to create user`,
          });
          this.getData();
        },
      );
    } else {
      if (
        (payLoad.createuserpassword && payLoad.createuserpassword !== '') ||
        payLoad.confirmcreateuserpassword ||
        payLoad.confirmcreateuserpassword !== ''
      ) {
        window.alert(`Password fields are populated. Please press Update Password and not Save`);
        return;
      }
      this.isModelLoading = true;
      payLoad.azureUserId = this.selectedItem.azureUserId;
      payLoad.InsertUserName = this.azureService.accountId;
      this.securityTypeService.updateUser(payLoad).subscribe(
        (data) => {
          this.isModelLoading = false;
          this.displayModal = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Successfully updated user`,
          });
          this.getData();
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to update user`,
          });
          this.getData();
        },
      );
    }
  }

  findAddDelete(initList, presList) {
    const result = [];
    const qAdded = presList.filter(
      (e) => !initList.find((x) => x.UserProfileNumber === e.UserProfileNumber),
    );
    for (const x of qAdded) {
      x.UserProfileAction = 'INSERT';
      result.push(x);
    }
    const qRemoved = initList.filter(
      (e) => !presList.find((x) => x.UserProfileNumber === e.UserProfileNumber),
    );
    for (const x of qRemoved) {
      x.UserProfileAction = 'DELETE';
      result.push(x);
    }
    return result;
  }

  validateLinks(init, pres) {
    const result = [];
    const linkTypes = [
      'MyWorkQueue',
      'Servicer',
      'MasterBuyingGroup',
      'BuyingGroup',
      'Dealer',
      'DealerLocation',
    ];
    for (const type of linkTypes) {
      const initQList = init.filter((e) => e.UserProfileType === type);
      const presQList = pres.filter((e) => e.UserProfileType === type);
      const res = this.findAddDelete(initQList, presQList);
      result.push(...res);
    }
    return result;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.querySubscriptionRoles) {
      this.querySubscriptionRoles.unsubscribe();
    }
  }

  handleOpen(e) {
    this.displayUpdate = true;
    this.header = 'Update User';
    this.cdr.detectChanges();
  }

  uniqueVlauesFromArray(arr, key) {
    const seen = new Set();
    return arr
      ? arr.filter((item) => {
          const k = key(item);
          return seen.has(k) ? false : seen.add(k);
        })
      : [];
  }
}
