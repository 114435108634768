<div class="main-tab">
  <app-section-header [title]="'Main'" />
  <contact-information [formFields]="contactInfo" [formData]="contactFormData"></contact-information>
  <div class="customer-information">
    <div class="title">Mailing Address</div>
    <address [(modelData)]="mailingAddress" [enableModelData]="true"></address>
  </div>
  <div class="customer-information">
    <div class="title">Billing Address</div>
    <address [(modelData)]="billingAddress" [enableModelData]="true"></address>
  </div>
</div>