import { Component, OnInit } from '@angular/core';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { CrmDocumentType } from '../../../shared/constants/enums';
import { ViewFile } from '../../../shared/view-file/view-file.interface';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormField } from '../../../shared/form-field/form-field';
import { ServiceOrderDataService } from '../service-order-data.service';

@Component({
  selector: 'service-attacments',
  templateUrl: 'service-attachments.template.html',
})
export class ServiceAttachmentsComponent implements OnInit {
  documentSearchParams: ViewFile;
  tabKey: string;
  displayAdd: boolean;
  displayVerify: boolean;
  header: string;
  formModalInfo: FormField<any>[] = [];
  selectedItem: any;
  constructor(
    private serviceOrderDataService: ServiceOrderDataService,
    private tabHandlerService: TabHandlerService,
  ) {
    this.displayAdd = false;
    this.displayVerify = false;
    this.documentSearchParams = {
      fileName: '',
      attachmentsType: { key: 'ClaimContact', value: CrmDocumentType.ClaimContact },
      uploadedDate: null,
      offset: 0,
      limit: 20,
      identifierId: null,
    };
  }

  ngOnInit() {
    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        let claimId = this.serviceOrderDataService.serviceConfig.searchParams.claimId;
        if (
          this.serviceOrderDataService.serviceOrderDataModel.claimData &&
          this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal
        ) {
          claimId = this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
        }
        if (this.tabKey === tab && claimId) {
          this.documentSearchParams.identifierId = claimId;
        }
      },
    });
  }
}
