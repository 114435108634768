<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner
><p-dialog
  [header]="modalTitle"
  [(visible)]="displayDialog"
  showeffect="fade"
  [modal]="true"
  [styleClass]="'add-dialog'"
  [blockScroll]="true"
  ><div class="modal-content">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-4">
          <form-field [formField]="formFields[0]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[1]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[3]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[4]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[5]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[6]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[7]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[8]" [form]="form"></form-field>
        </div>
        <div class="col-12">
          <label for="alertMessage">MESSAGE</label><span class="required-mark">*</span
          ><p-editor formControlName="alertMessage" required="required" [style]="{'height':'220px'}"></p-editor>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[10]" [form]="form"></form-field>
        </div>
        <div class="col-4">
          <form-field [formField]="formFields[11]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <p-toast></p-toast
  ><ng-template pTemplate="footer" [style]="{'overflow': 'hidden','max-height': 'max-content','height': 'fit-content'}"
    ><div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" [attr.disabled]="isLoading ? true : null" label="Submit">Submit</button
      ><button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div></ng-template
  ></p-dialog
>
