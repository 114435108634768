<div class="work-queue-container">
  <app-section-header [title]="'Search Work Queue Results ( '  + resultCount + ' item(s) found )'" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="cols" [value]="admin"
      dataKey="id" editMode="row" [hidden]="isLoading"><ng-template pTemplate="header" let-columns>
        <tr>
          <th class="snum-cell">S. No.</th>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
        <tr>
          <td>{{ri+1}}</td>
          <td *ngFor="let col of columns">
            <div class="crm-link" *ngIf="col.type === 'link'" (click)="openTab(rowData)">{{rowData[col.field]}}</div>
            <div *ngIf="col.type === 'text'">{{rowData[col.field]}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>