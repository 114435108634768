<div class="crm-panel" style="position: relative; box-shadow: 0 0 100px rgba(0,0,0,0.3);">
  <app-tab-spinner *ngIf="isLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; width:100%;height: 100%;"></app-tab-spinner>
  <div class="crm-top">
    <div class="crm-panel__left">
      <contract-details-form (formValueChanged)="setChangeData($event)" [searchParams]="contractId">
      </contract-details-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <div class="crm-bottom" *ngIf="componentDisplay">
    <crm-notes
      *appSetRolePermissionsDirective="{type:'component', operation:'READ_ONLY', permission:'Notes', category: 'Contract'}"
      [parentCategory]="'Contract'" [category]="addressCategoryType.Customer" [identifierId]="contractId"
      [parentComponent]="component" [otherData]="customerId" (dataRetrieved)="onNotesData($event)" #notesComponent>
    </crm-notes>
  </div>
</div>
<crm-alert-modal *ngIf="alertService.displayAlertModal" [modalTitle]="alertService.alertTitle"
  [modalContent]="alertService.alert && alertService.alert.message" [isBackgroundFreeze]="false"
  [(displayDialog)]="alertService.displayAlertModal" (displayDialogChange)="onAlertDisplayChange($event)">
</crm-alert-modal>