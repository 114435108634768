import { Component, OnInit } from '@angular/core';
import { CrmDocumentType } from '../../../shared/constants/enums';
import { DealerLocationService } from '../dealer-location.service';

@Component({
  selector: 'dl-file-upload',
  templateUrl: 'dealer-location-upload.template.html',
})
export class DealerLocationUploadComponent implements OnInit {
  documentType = CrmDocumentType;
  attachmentId: number;
  constructor(private dealerLocationService: DealerLocationService) {}

  ngOnInit() {
    if (this.dealerLocationService.dealerLocationData) {
      this.attachmentId = this.dealerLocationService.dealerLocationData.sellerId;
    }
  }
}
