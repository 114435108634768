import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import { Injectable } from '@angular/core';

const getActiveApis = gql`
  query($apiName: String) {
    getActiveApis(apiName: $apiName) {
      apiId
      apiName
    }
  }
`;

@Injectable()
export class FeatureFlagsService {
  private featureFlags = [];

  constructor(private apollo: Apollo) {}

  getAllEnabledFeatures = () =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getActiveApis,
      variables: {
        apiName: null
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  isFeatureFlagEnabled(flag: string) {
    return this.featureFlags && this.featureFlags.includes(flag);
  }

  addEnabledFeatures(flags = []) {
    this.featureFlags = flags;
  }

  removeDisabledFeature(flag: string) {
    this.featureFlags = this.featureFlags.filter(el => el !== flag);
  }
}
