import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NavigationPanel } from './navigation-panel.model';
import { TabHandlerService } from '../../services/tab-handler.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Component({
  selector: 'navigation-panel',
  templateUrl: 'navigation-panel.template.html',
  providers: [TabHandlerService],
})
export class NavigationPanelComponent implements OnInit, OnChanges {
  @Input() navigationPanel: NavigationPanel[];
  @Input() reload: boolean;
  selectedTab: any;
  constructor(
    private roleAuth: RoleAuthorizationService,
    private tabHandlerService: TabHandlerService,
  ) {}
  ngOnInit() {
    this.navigationPanel = this.roleAuth.validateSideMenu(this.navigationPanel);
    this.tabHandlerService.selectedTab.subscribe((tab) => {
      if(tab){
        this.selectedTab = tab;
      }
    });
  }
  ngOnChanges() {
    this.navigationPanel = this.roleAuth.validateSideMenu(this.navigationPanel);
    this.tabHandlerService.selectedTab.next(this.navigationPanel[0]?.tabKey);
  }
}
