<p-dialog [header]="modalTitle" [(visible)]="displayDialog" (onHide)="onCancel()" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <app-tab-spinner *ngIf="isLoading" />
  <div class="modal-content" *ngIf="!isLoading">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-4">
          <form-field [formField]="formFields[0]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[1]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[3]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <form-field [formField]="formFields[4]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid" *ngIf="modalTitle === 'Send Info Email'">
        <div class="col-12">
          <form-field [formField]="formFields[5]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <p-toast> </p-toast>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button left-align" type="button" (click)="downloadPdf()" label="DownloadPDF">
        Download PDF
      </button>
      <button class="crm-button" type="button" (click)="onContinueWOEmailFax()" label="ContinueWOEmailFax">
        Continue without Email/Fax
      </button>
      <button class="crm-button" type="submit" (click)="onSubmit()" [disabled]="inProgress" label="Send">Send</button>
      <button class="crm-button" type="button" (click)="onCancel()" [disabled]="inProgress" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>