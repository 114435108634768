import { Component, OnInit, Input } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRoute,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { NavLeft } from '../sidebar-navigation';
import { NAVLEFTITEM } from '../sidebar-navigation-constants';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sidebar-left-navigation',
  templateUrl: 'sidebar-left-navigation.template.html',
  styleUrls: ['sidebar-left-navigation.scss'],
})
export class SidebarLeftComponent implements OnInit {
  @Input() public myCallback: any;
  navleftitem = NAVLEFTITEM;
  selectedNavleft: NavLeft;
  authDetails: IRoleAuthorizationService;
  buildNumber = `v${environment.buildNumber}`;

  constructor(
    private router: Router,
    private currentActivatedRoute: ActivatedRoute,
    private roleService: RoleAuthorizationService,
  ) {}

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.LeftPanel,
      component: SidebarLeftComponent,
      generalArray: this.navleftitem,
    };
    this.navleftitem = Object.assign(this.roleService.applyAuthorization(this.authDetails));
  }

  onSelect(navleft: NavLeft): void {
    this.selectedNavleft = navleft;
    this.myCallback(navleft.id);
  }
}
