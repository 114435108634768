import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { CheckBoxField } from '../../shared/form-field/checkbox-field';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { FormField } from '../../shared/form-field/form-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';

const getSalesAndCancelFileListQuery = gql`
  query($days: Int) {
    getSalesAndCancelFilesList(days: $days) {
      fileType
      fileName
      s3FileName
      uploadedDate
    }
  }
`;

const getExportStatusQuery = gql`
  query($exportRequestsId: Int!) {
    getExportStatus(exportRequestsId: $exportRequestsId) {
      fileName
      summary
      bucketName
      requestStatus
    }
  }
`;

const addExportRequestQuery = gql`
  mutation($exportRequestsType: String, $requestInfo: String, $insertUserName: String) {
    addExportRequest(
      requestInput: {
        exportRequestsType: $exportRequestsType
        requestInfo: $requestInfo
        insertUserName: $insertUserName
      }
    ) {
      exportRequestsIdOriginal
      exportRequestsType
      fileName
      requestInfo
      summary
      bucketName
      requestStatus
    }
  }
`;

@Injectable()
export class InsuranceExtractService {
  constructor(private http: HttpClient, private apollo: Apollo) {}
  getFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'daysToRetrieve',
        label: 'Days to Retrieve',
        isSingleselect: true,
        value: { key: '1', value: 1 },
        options: [
          { key: '1', value: 1 },
          { key: '2', value: 2 },
          { key: '7', value: 7 },
          { key: '14', value: 14 },
          { key: '30', value: 30 }
        ],
        order: 1
      }),
      new DropdownField({
        key: 'salesFiles',
        label: 'Sales Files',
        isSingleselect: false,
        options: [{ key: 'file1', value: 'file1' }, { key: 'file2', value: 'file2' }],
        showToggleAll: true,
        order: 2
      })
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getSalesAndCancelFileList = searchParams =>
    this.apollo.use('crm').query<any>({
      query: getSalesAndCancelFileListQuery,
      variables: { days: searchParams }
      // tslint:disable-next-line: semicolon
    });

  getExportStatus = searchParams =>
    this.apollo.use('crm').query<any>({
      query: getExportStatusQuery,
      variables: { exportRequestsId: searchParams },
      fetchPolicy: 'no-cache'
      // tslint:disable-next-line: semicolon
    });

  addExportRequest = data =>
    this.apollo.use('crm').mutate<any>({
      mutation: addExportRequestQuery,
      variables: { ...data }
      // tslint:disable-next-line: semicolon
    });
}
