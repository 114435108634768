import { FormField } from './form-field';

export class DropdownField extends FormField<string> {
  override controlType = 'dropdown';
  override options: { key: string; value: string }[] = [];
  isSingleselect: boolean; // will be true by default
  filter = false;
  virtualScroll = false;
  showToggleAll = true;

  constructor(options: any = {}) {
    super(options);
    this.options = options[`options`] || [];
    this.isSingleselect = options[`isSingleselect`];
    this.filter = options[`filter`];
    this.virtualScroll = options[`virtualScroll`];
    this.showToggleAll = options[`showToggleAll`];
  }
}
