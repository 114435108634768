import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { SendEmailModalService, LABEL_MAPPING } from '../send-email-modal/send-email-modal.service';
import { EmailService } from '../../../services/email.service';
import { MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject, zip } from 'rxjs';
import { ServiceOrderService } from '../service-order.service';
import { ServiceOrderDataService } from '../service-order-data.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import moment from 'moment';
import { SendVtShortKeyEmailService } from './send-vt-short-key-email.service';
import { NoteService } from '../../../shared/notes/note.service';
import { AddressCategoryType } from '../../../shared/constants/enums';
import { Note } from '../../../shared/notes/note.model';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Component({
  selector: 'send-vt-short-key-email',
  templateUrl: './send-vt-short-key-email.component.html',
  styleUrls: ['./send-vt-short-key-email.component.scss'],
  providers: [
    FormFieldControlService,
    SendEmailModalService,
    SendVtShortKeyEmailService,
    MessageService,
  ],
})
export class SendVtShortKeyEmailComponent implements OnInit, OnDestroy, OnChanges {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() selectedCode: string;
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displayEmailModal: true;
  isLoading = true;
  querySubscriptions: Subscription[] = [];
  validForm = true;
  templateResponse = null;
  shortCodeList: any;
  selectedShortCode;
  mailBody: any;
  public currentUser: any;
  shortCodeSelected: any;
  public note: Note = {};

  constructor(
    private qcs: FormFieldControlService,
    private sendEmailModalService: SendEmailModalService,
    private sendVtShortKeyEmailService: SendVtShortKeyEmailService,
    private emailService: EmailService,
    private messageService: MessageService,
    private chRef: ChangeDetectorRef,
    private serviceOrderDataService: ServiceOrderDataService,
    private service: ServiceOrderService,
    private noteService: NoteService,
    private azureService: AzureLoginService,
  ) {
    this.sendVtShortKeyEmailService.getShortKeyEmailFields().subscribe((data) => {
      this.formFields = data;
    });

    this.shortCodeList = this.sendVtShortKeyEmailService.generateKeyValObj(
      Object.keys(this.sendVtShortKeyEmailService.shortCodeTemp),
    );
    this.selectedShortCode = this.shortCodeList[0].value;
    azureService.getFullName().subscribe((currentUser: any) => {
      this.currentUser = currentUser;
    });
  }
  ngOnInit() {
    this.querySubscriptions.push(
      this.sendEmailModalService.getTemplateDetails().subscribe(
        // Disable the Rule Shadowed name: 'data'
        /* tslint:disable */
        ({ data }) => {
          this.isLoading = false;
          this.templateResponse = data;
          this.updateForm(data, '');
        },
        (err) => {
          this.isLoading = false;
          window.alert('Email Template Retrieval Failed. Please try again in a few minutes.');
        },
      ),
    );
  }

  ngOnChanges() {
    if (this.selectedCode) {
      this.selectedShortCode = this.selectedCode;
      this.shortCodeSelected = { key: this.selectedShortCode, value: this.selectedShortCode };
      const items = this.shortCodeList;
      this.shortCodeList = [];
      this.shortCodeList.push({ key: this.selectedCode, value: this.selectedCode });
      this.shortCodeList = [
        ...this.shortCodeList,
        ...items.filter((el) => el.key !== this.selectedCode),
      ];
      this.setTemplateField(this.selectedShortCode);
    } else {
      if (this.form) {
        this.selectedShortCode = this.shortCodeList[0].value;
        this.shortCodeSelected = { key: this.selectedShortCode, value: this.selectedShortCode };
        this.setTemplateField(this.selectedShortCode);
      }
    }
  }

  setTemplateField(shortCode) {
    const payload = this.form.getRawValue();
    const template = this.sendVtShortKeyEmailService.getEmailTemaplate(shortCode);
    const customerDispatch = this.serviceOrderDataService.serviceOrderDataModel.claimData
      ? this.serviceOrderDataService.serviceOrderDataModel.claimData.customerDispatch
      : false;
    if (
      shortCode === 'VESTS' &&
      customerDispatch &&
      this.serviceOrderDataService.serviceOrderDataModel.customerInfo &&
      this.serviceOrderDataService.serviceOrderDataModel.customerInfo.customerEmail
    ) {
      payload.toEmailAddress =
        this.serviceOrderDataService.serviceOrderDataModel.customerInfo.customerEmail;
    } else if (
      shortCode === 'VESTS' &&
      this.serviceOrderDataService.serviceOrderDataModel.servicerInfo
    ) {
      const servicerEmail =
        this.serviceOrderDataService.serviceOrderDataModel.servicerInfo.contactInfo.email;
      payload.toEmailAddress = servicerEmail;
    } else {
      payload.toEmailAddress = this.sendVtShortKeyEmailService.getDefaultToEmailAddress();
    }
    let finalTemplate = this.replaceVariables(
      template,
      this.serviceOrderDataService.validatorDataModel,
    );
    finalTemplate = finalTemplate.replace(/\$ /g, '$');
    payload.customText = finalTemplate;
    payload.shortCode = this.selectedShortCode;
    this.form.setValue(payload);
  }

  onShortCodeChange(event) {
    this.selectedShortCode = event.value.value;
    this.setTemplateField(this.selectedShortCode);
  }

  replaceVariables(templateStr, variables) {
    const finalHtml = Object.keys(variables).reduce(function (result, item) {
      const replacementVariable = '{{' + item + '}}';
      const replacer = new RegExp(replacementVariable, 'g');
      templateStr = templateStr.replace(replacer, variables[item]);
      return templateStr;
    }, '');

    return finalHtml;
  }

  onSubmit() {
    this.isLoading = true;
    const finalPayload = this.form.getRawValue();
    const { firstName, lastName } = this.serviceOrderDataService.serviceOrderDataModel.contact;
    const strFirstName = firstName ? firstName : '';
    const strLastName = lastName ? lastName : '';
    const request = {
      operation: 'send_template',
      data: {
        from: 'donotreply@newleafsc.net',
        receivers: [
          {
            Email: finalPayload.toEmailAddress,
            FirstName: strFirstName,
            LastName: strLastName,
          },
        ],
        cc: [finalPayload.ccEmailAddress],
        variables: {
          custom_text: finalPayload.customText,
          customer_name: `${strFirstName} ${strLastName}`,
          service_order_number:
            this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber,
        },
        template: 'TEMPLATE_Information_template1',
      },
    };

    this.querySubscriptions.push(
      this.emailService.sendEmail(request).subscribe(
        (response) => {
          this.isLoading = false;

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Email sent successfully to ${finalPayload.toEmailAddress}.`,
          });

          this.chRef.detectChanges();
          this.formSubmitEvent.emit(this.form);
          this.saveNote(
            finalPayload.customText,
            `Validator Tool Email: Used Short Code_${this.selectedShortCode} for ${this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber}, Recipient: ${finalPayload.toEmailAddress}`,
          );
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Email send Failed to ${finalPayload.toEmailAddress}.`,
          });
        },
      ),
    );
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
  }

  onSendEmail() {
    this.displayEmailModal = true;
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }

  private updateForm(data, servicerEmail) {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 6);
    this.formFields2 = this.formFields.slice(11, 30);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
  }

  onSaveNotes() {
    const payload = this.form.getRawValue();
    if (!payload.customText || payload.customText === '') {
      return;
    }
    this.saveNote(payload.customText);
  }

  saveNote(mainNotes, secondNotes = null) {
    const typeList = [
      { label: 'External', value: 38 },
      { label: 'System', value: 40 },
    ];
    const identifierId =
      this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
    const category = AddressCategoryType.ClaimContact;

    const templaeNote = {
      tenantId: null,
      noteType: typeList[0].value,
      contractUniqueId:
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractsId.toString(),
      noteText: mainNotes,
      enteredBy: this.currentUser,
      claimId: identifierId,
      noteIdOriginal: null,
      external: null,
      isDeleted: null,
    };
    const emailAddressNotes = Object.assign({}, templaeNote);
    emailAddressNotes.noteText = secondNotes;
    emailAddressNotes.noteType = typeList[1].value;
    this.querySubscriptions.push(
      secondNotes
        ? zip(
            this.noteService.saveNotes(templaeNote, identifierId, category),
            this.noteService.saveNotes(emailAddressNotes, identifierId, category),
          ).subscribe(([response1, response2]) => {
            this.serviceOrderDataService.loadNote.next(true);
            this.displayDialog = false;
            this.displayDialogChange.emit(this.displayDialog);
          })
        : this.noteService.saveNotes(templaeNote, identifierId, category).subscribe((data) => {
            this.serviceOrderDataService.loadNote.next(true);
            this.displayDialog = false;
            this.displayDialogChange.emit(this.displayDialog);
          }),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
