export const buyingGroupEllipsisOptions = [
  { label: 'Save', value: 'Save' },
  { label: 'Add', value: 'Add' },
  { label: 'History', value: 'History' }
];

export const buyingGroupContactEllipsisOptions = [
  { label: 'Contact Deactivate ', value: 'Contact Deactivate' },
  { label: 'Contact Delete', value: 'Contact Delete' }
];

export const buyingGroupDocumentEllipsisOptions = [
  { label: 'Document Deactivate ', value: 'Document Deactivate' },
  { label: 'Document Delete', value: 'Document Delete' }
];

export const buyingGroupNotesEllipsisOptions = [
  { label: 'Notes Deactivate', value: 'Notes Deactivate' },
  { label: ' Notes Delete', value: ' Notes Delete' }
];

export const buyingGroupFormEllipsisOptions = [
  { label: 'Add New', value: 'Add New' },
  { label: ' Delete', value: 'Delete' }
];
