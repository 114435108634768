<div class="import-file">
  <div class="main-tab__titles">
    <div class="title">Images</div>
    <form class="content" [formGroup]="form">
      <div class="form-row" *ngFor="let formField of formFields">
        <form-field [formField]="formField" [form]="form"></form-field>
      </div>
    </form>
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" class="import-file__content">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns"></th>
          <label class="import-file__label">File</label>
          <p-fileUpload name="myfile[]" accept=".csv, .txt" chooseLabel="Browse" (uploadHandler)="onUpload($event)"
            [customUpload]="true" (onRemove)="fileReset()">
          </p-fileUpload>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>