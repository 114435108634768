<div class="so-history-tab">
  <app-section-header [title]="'Service Order History'" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [value]="admin">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">{{rowData[col.field]}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>