import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { CrmGraphql } from '../../../shared/constants/graphql-constants';

export const createUpdateDealerContactQuery = gql`
  mutation(
    $contactPosition: String
    $businessPhone: String
    $firstName: String
    $lastName: String
    $middleName: String
    $fax: String
    $email: String
    $phoneNumber1: String
    $dealerGroupId: Int
    $isDeleted: Boolean
    $contactIdOriginal: Int
    $categoryType: AddressCategoryType!
    $sellerNumber: String
    $cellPhone: String
  ) {
    createUpdateContacts(
      categoryType: $categoryType
      contactInput: {
        contactPosition: $contactPosition
        businessPhone: $businessPhone
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        fax: $fax
        email: $email
        phoneNumber1: $phoneNumber1
        dealerGroupId: $dealerGroupId
        isDeleted: $isDeleted
        contactIdOriginal: $contactIdOriginal
        sellerNumber: $sellerNumber
        cellPhone: $cellPhone
      }
    ) {
      dealerGroupId
      contactIdOriginal
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class DealerContactService {
  constructor(private apollo: Apollo) {}

  createUpdateServicerContact = payload =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateDealerContactQuery,
      variables: {
        ...payload
      }
      // tslint:disable-next-line: semicolon
    });
}
