import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { PriceTable } from '../../../../crm-coverage/coverage-models';
import { ServiceBenchTableService } from './service-bench-table.service';
import { ServiceOrderService } from '../../service-order.service';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import {
  copy,
  getValueByKey,
  removeDuplicatesFromArray,
} from 'src/app/modules/crm/shared/utilities/common-utilities';
import {
  generalDateFormatter,
  getFormattedTimeRangeForSB,
} from 'src/app/modules/crm/shared/utilities/date-utilities';
import moment from 'moment';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfigurationTypeService } from '../../../../administration/configuration/configuration.service';

@Component({
  selector: 'service-bench-table',
  templateUrl: 'service-bench-table.template.html',
  styleUrls: ['./service-bench-table.scss'],
  providers: [],
})
export class ServiceBenchTableComponent implements OnInit, OnDestroy {
  @Input() displayDialog: boolean;
  @Input() isReschedule = false;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  querySubscription: any;
  selectedServicer: any;
  querySubscriptions: Subscription[] = [];
  servicerProviders = [];
  timeRanges = [];
  availableDates = [];
  isLoading = false;
  doNotDispatchSpaList = [];
  daysOptions = [
    { label: '1', value: '1' },
    { label: '7', value: '7' },
    { label: '14', value: '14' },
    { label: '30', value: '30' },
  ];
  selectedDaysNeeded = '14';
  customerDetails = {
    zipCode: 'N/A',
    address1: 'N/A',
    city: 'N/A',
    state: 'N/A',
    customerPhone: 'N/A',
    customerLastName: 'N/A',
    email: '',
    fax: '',
  };
  constructor(
    private sbTableService: ServiceBenchTableService,
    private confirmationService: ConfirmationService,
    private serviceOrderService: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'select', header: 'SELECT', type: 'radio' },
      { field: 'serviceProviderAccount', header: 'SERVICE PROVIDER ACCOUNT', type: 'text' },
      { field: 'recall', header: 'RECALL', type: 'checkbox' },
      { field: 'availableDateFormatted', header: 'AVAILABLE DATE', type: 'text' },
      { field: 'weekDay', header: 'WEEKDAY', type: 'text' },
      { field: 'timeRange', header: 'TIME RANGE', type: 'text' },
    ];
    if (this.azureService.roleName === 'Admin') {
      this.cols = [
        ...this.cols,
        { field: 'serviceProviderName', header: 'SERVICE PROVIDER NAME', type: 'text' },
      ];
    }
    const { serviceBenchCommon } = this.serviceOrderDataService.serviceBenchModal;
    this.selectedDaysNeeded = getValueByKey(serviceBenchCommon, 'defaultDaysNeeded');
    const { contact, location, customerInfo } = this.serviceOrderDataService.serviceOrderDataModel;
    this.customerDetails.zipCode = location.zipCode;
    this.customerDetails.address1 = location.address1;
    this.customerDetails.city = location.city;
    this.customerDetails.state = location.state.value;
    this.customerDetails.email = customerInfo.customerEmail;
    this.customerDetails.fax = customerInfo.customerFax;

    if (contact && contact.phoneNumber1) {
      this.customerDetails.customerPhone = contact.phoneNumber1;
    } else if (customerInfo && customerInfo.customerPhone) {
      this.customerDetails.customerPhone = customerInfo.customerPhone;
    }

    if (contact && contact.lastName) {
      this.customerDetails.customerLastName = contact.lastName;
    } else if (customerInfo && customerInfo.customerLastName) {
      this.customerDetails.customerLastName = contact.customerLastName;
    }
    this.selectedColumns = this.cols.slice(0, 6);
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'do_not_dispatch_spa_list',
        module: 'service_bench',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.doNotDispatchSpaList = configList
          .filter((el) => el.menuType === 'do_not_dispatch_spa_list')
          .map((e) => {
            return e.value;
          });
      });
    this.getServicerInfo();
  }

  getServicerInfo() {
    if (this.serviceOrderDataService.serviceOrderDataModel.servicerInfo) {
      this.getServiceAvailability(this.serviceOrderDataService.serviceOrderDataModel.servicerInfo);
    } else {
      const serviceProviderValues = this.serviceOrderService.selectedServicerValue;
      if (!serviceProviderValues) {
        return;
      }
      const servicerProfileId = serviceProviderValues.servicerProfilesIdOriginal;
      this.isLoading = true;
      this.querySubscriptions.push(
        this.serviceOrderDataService.getServicerInfoByIdSingle(servicerProfileId).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            this.serviceOrderDataService.serviceOrderDataModel.servicerInfo = copy(
              data.getServicerProfileById,
            );
            this.getServiceAvailability(data.getServicerProfileById);
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        ),
      );
    }
  }
  onDaysChange(event) {
    this.servicerProviders = this.timeRanges = this.availableDates = [];
    this.selectedDaysNeeded = event && event.value;
    this.getServicerInfo();
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  onRadioButtonClick(event, rowData) {
    this.sbTableService.selectedValue = rowData;
  }

  getServiceAvailability(servicer) {
    const selectedState =
      servicer.addresses && servicer.addresses[0] && servicer.addresses[0].state;
    if (selectedState) {
      this.isLoading = true;
      this.querySubscriptions.push(
        this.sbTableService
          .getServiceAvailability(this.customerDetails.zipCode, this.selectedDaysNeeded)
          .subscribe(
            (data) => {
              let finalTableValues = [];
              if (data && data.msgStatus === 'ERROR') {
                if (data.statusDetails.statusDetail.length > 0) {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: `ERROR: ${data.statusDetails.statusDetail[0].errorText} [${servicer.addresses[0].zipCode}].`,
                  });
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: `ERROR: ${data.statusDetails.statusDetail.errorText} [${servicer.addresses[0].zipCode}].`,
                  });
                }
                this.isLoading = false;
              }
              if (
                data &&
                data.availabilityResponse &&
                data.availabilityResponse.availabilityFound === 'Y'
              ) {
                for (const singleDate of data.availabilityResponse.serviceJobTypes.serviceJobType[0]
                  .dates.date) {
                  let serviceProvidersList = [];
                  if (!Array.isArray(singleDate.serviceProviders.serviceProvider)) {
                    serviceProvidersList.push(singleDate.serviceProviders.serviceProvider);
                  } else {
                    serviceProvidersList = singleDate.serviceProviders.serviceProvider;
                  }
                  for (const singleServiceProvider of serviceProvidersList) {
                    const timeSlot = singleServiceProvider.timeSlots.timeSlot;
                    let timeSlotList = [];
                    if (!Array.isArray(timeSlot)) {
                      timeSlotList.push(timeSlot);
                    } else {
                      timeSlotList = timeSlot;
                    }
                    for (const singleTimeSlot of timeSlotList) {
                      const timeRange = getFormattedTimeRangeForSB(
                        singleTimeSlot.slotStartTime,
                        singleTimeSlot.slotLength,
                      );
                      const formattedDate = this.getFormattedDate(singleDate.availableDate);
                      if (
                        !this.doNotDispatchSpaList.includes(
                          singleServiceProvider.serviceProviderAccount,
                        )
                      ) {
                        finalTableValues.push({
                          serviceProviderAccount: singleServiceProvider.serviceProviderAccount,
                          serviceProviderName: singleServiceProvider.serviceProviderName,
                          availableDate: singleDate.availableDate,
                          timeSlotType: singleTimeSlot.availableType,
                          timeSlotStartTime: singleTimeSlot.slotStartTime,
                          timeSlotLength: singleTimeSlot.slotLength,
                          timeRange,
                          availableDateFormatted: formattedDate,
                          weekDay: moment(formattedDate).format('dddd'),
                          servicerProfileID: servicer.servicerProfilesIdOriginal,
                        });
                        finalTableValues = finalTableValues.filter(
                          (el) => el.timeRange !== 'Emergency',
                        );
                        this.timeRanges = [
                          { label: timeRange, value: timeRange },
                          ...this.timeRanges,
                        ];
                        this.availableDates = [
                          { label: formattedDate, value: formattedDate },
                          ...this.availableDates,
                        ];
                        this.servicerProviders = [
                          {
                            label: singleServiceProvider.serviceProviderName,
                            value: singleServiceProvider.serviceProviderName,
                          },
                          ...this.servicerProviders,
                        ];
                      }
                    }
                  }
                }
                this.servicerProviders = removeDuplicatesFromArray(this.servicerProviders, 'label');
                this.availableDates = removeDuplicatesFromArray(this.availableDates, 'label');
                this.timeRanges = removeDuplicatesFromArray(this.timeRanges, 'label');
                finalTableValues.sort(compare);
                if (this.isReschedule) {
                  const part1 = finalTableValues.filter(
                    (el) =>
                      el.serviceProviderName ===
                      this.serviceOrderDataService.serviceBenchModal.scheduleJobDetails
                        .servicerName,
                  );
                  const part2 = finalTableValues.filter(
                    (el) =>
                      el.serviceProviderName !==
                      this.serviceOrderDataService.serviceBenchModal.scheduleJobDetails
                        .servicerName,
                  );
                  this.dataList = [...part1, ...part2];
                } else {
                  this.dataList = finalTableValues;
                }
                this.sbTableService.storedValues[servicer.addresses[0].zipCode] = this.dataList;

                this.getRecallStatus();
              }
            },
            (err) => {
              this.isLoading = false;
              throw err;
            },
          ),
      );
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  getRecallStatus() {
    let temporalRecall: object = {};
    const queryInfo: object = {
      contractNumber: this.serviceOrderDataService.serviceOrderDataModel.claimData.contractNumber,
      serviceOrderNumber:
        this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber,
      symptom: this.serviceOrderDataService.serviceOrderDataModel.claimData.problem.symptom,
      modelNumber: this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct
        ? this.serviceOrderDataService.serviceOrderDataModel.claimData.coveredProduct.modelNumber
        : null,
    };
    this.querySubscriptions.push(
      this.sbTableService.getServiceOrderRecall(queryInfo).subscribe(
        (recallResultQuery) => {
          if (recallResultQuery.data.getServiceOrderRecall) {
            temporalRecall = {
              [recallResultQuery.data.getServiceOrderRecall.servicerName]:
                recallResultQuery.data.getServiceOrderRecall.recall,
              [recallResultQuery.data.getServiceOrderRecall.servicerAccount]:
                recallResultQuery.data.getServiceOrderRecall.recall,
            };
            this.transformRecallStatus(temporalRecall);
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      ),
    );
  }

  transformRecallStatus(recall: object) {
    this.dataList.forEach((data) => {
      const isRecall =
        recall[data.servicerProfileID] != null
          ? recall[data.servicerProfileID]
          : recall[data.serviceProviderName];
      data.recall = isRecall;
    });

    this.dataList.sort((act, comp) => {
      if (act.recall > comp.recall) {
        return -1;
      }
      if (act.recall < comp.recall) {
        return 1;
      }
      return 0;
    });
    this.isLoading = false;
  }

  getFormattedDate(dateString) {
    const d = moment(dateString, 'YYYYMMDD').toDate().toString();
    return moment(d).format('MM/DD/YYYY');
  }
}

function compare(a, b) {
  const availableDateA = a.availableDate;
  const availableDateB = b.availableDate;
  const timeSlotStartTimeA = a.timeSlotStartTime;
  const timeSlotStartTimeB = b.timeSlotStartTime;

  let comparison = 0;
  if (availableDateA === availableDateB) {
    if (timeSlotStartTimeA > timeSlotStartTimeB) {
      comparison = 1;
    } else if (timeSlotStartTimeA < timeSlotStartTimeB) {
      comparison = -1;
    }
  } else if (availableDateA > availableDateB) {
    comparison = 1;
  } else if (availableDateA < availableDateB) {
    comparison = -1;
  }
  return comparison;
}
