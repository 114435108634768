import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import gql from 'graphql-tag';

const getApplicableAlertsQuery = gql`
  query(
    $moduleName: [String]
    $productPlanTypes: [String]
    $subCategories: [String]
    $manufacturers: [String]
    $buyingGroups: [String]
    $dealers: [String]
    $dealerLocations: [String]
    $category: [String]
  ) {
    getApplicableAlerts(
      moduleName: $moduleName
      productPlanTypes: $productPlanTypes
      subCategories: $subCategories
      manufacturers: $manufacturers
      buyingGroups: $buyingGroups
      dealers: $dealers
      dealerLocations: $dealerLocations
      category: $category
    ) {
      alertIdOriginal
      message
      alertStatus
      effectiveDateTo
      effectiveDateFrom
    }
  }
`;

@Injectable()
export class AlertService {
  alerts = [];
  displayAlertModal = false;
  alert = null;
  alertTitle = 'Alert';
  constructor(private apollo: Apollo) {}

  showAlert() {
    if (this.alerts.length > 0) {
      this.alert = { ...this.alerts[0] };
      this.displayAlertModal = true;
    }
  }

  hideAlert() {
    this.displayAlertModal = false;
    this.alerts = [...this.alerts.slice(1)];
    if (this.alerts.length > 0) {
      setTimeout(() => {
        this.showAlert();
      }, 10);
    }
  }

  getApplicableAlerts = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getApplicableAlertsQuery,
      fetchPolicy: 'network-only',
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });
}
