import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { WorkQueueListService } from './work-queue-list.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisCrudOptions,
  ellipsisWorkQueueListOptions,
} from '../../shared/constants/ellipsis-options';
import { FormField } from '../../shared/form-field/form-field';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { ClaimsLookup } from '../../shared/constants/lookup-list';
import {
  copy,
  getLabelValueList,
  getSameLabelValueList,
} from '../../shared/utilities/common-utilities';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'work-queue-list',
  templateUrl: 'work-queue-list.template.html',
  providers: [WorkQueueListService, MessageService],
})
export class WorkQueueListComponent implements OnInit {
  @Input() searchParams: any = {};
  dataList: any[];
  cols: any[];
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  selectedEllipsisItem: any;
  ellipsisHeaderOptions: any[] = ellipsisWorkQueueListOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedRowIndex: number;
  isLoading: boolean;
  currentMaxId: number;
  tabKey: any;
  lastOffsetVal = 0;
  offsetLimit = 15;
  querySubscription: Subscription;
  isDelete = false;
  isEditing: boolean;
  editConstant: string;
  clonedRowData: KeyMap = {};
  displayServiceInfoModal = false;
  selectedRow: any;
  disableLoadMore = false;
  resultCount: number;
  dropdown: any;
  querySubscriptions: Subscription[] = [];
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  constructor(
    private workQueueListService: WorkQueueListService,
    private azureService: AzureLoginService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dropdownDataService: DropdownDataService,
    private claimsLookupService: ClaimsLookup,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    const deletePermissionList = ['Admin'];
    if (deletePermissionList.includes(this.azureService.roleName)) {
      this.ellipsisOptions = this.ellipsisOptions.filter((el) => el.value !== 'edit');
      this.isDelete = true;
    }
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'queueType', header: 'Work Queue Type', type: 'select' },
      { field: 'statusName', header: 'Status', type: 'select' },
    ];

    this.dropDownWorkQueueType();
    const statusName = getLabelValueList(this.claimsLookupService.claimsLookup);
    this.dropdown = {
      queueType: [],
      statusName,
    };
    this.loadWorkQueueList();
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Work Queue',
      category: 'Catalogs',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Work Queue',
      category: 'Catalogs',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  dropDownWorkQueueType() {
    this.querySubscriptions.push(
      this.workQueueListService.getWorkQueueListTypes().subscribe(({ data, loading }: any) => {
        let dt = getSameLabelValueList(data.getWorkQueueListTypes);

        dt = [...dt];
        dt.unshift({ label: '', value: '' });
        this.dropdown.queueType = dt;
        this.dropdownDataService.workQueueListTypes = data.getWorkQueueListTypes;
      }),
    );
  }

  loadWorkQueueList() {
    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string' ? this.searchParams : {};
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.lastOffsetVal += this.offsetLimit;
    this.isLoading = true;
    this.resultCount = 0;
    this.querySubscription = this.workQueueListService
      .getWorkQueueListQuery(this.searchParams)
      .subscribe(({ data }) => {
        this.isLoading = false;
        this.currentMaxId = 0;
        const response = copy(data.getWorkQueueList);
        if (response) {
          const modifedData = response.map((el) => {
            el = this.formatData(el);
            this.currentMaxId += 1;
            el.id = this.currentMaxId;
            return el;
          });
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
          this.resultCount = this.dataList.length;
        } else {
          this.dataList = [];
          this.disableLoadMore = false;
        }
      });
  }

  formatData(item) {
    return {
      id: 0,
      isNew: false,
      queueMappingIdOriginal: item.queueMappingIdOriginal,
      queueMappingId: item.queueMappingId,
      queueType: item.queueType,
      statusName: item.statusName,
    };
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }

  onClickAdd(event) {
    let rowItem = {
      id: 0,
      queueType: '',
      StatusName: '',
      queueMappingIdOriginal: null,
    };
    this.currentMaxId += 1;
    rowItem.id = this.currentMaxId;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowEditSave(rowData: any, isDelete: boolean = false) {
    delete this.clonedRowData[rowData.id];
    rowData.insertUserName = this.azureService.accountId;
    this.isLoading = true;
    if (isDelete) {
      rowData.isDeleted = true;
    } else {
      rowData.isDeleted = false;
    }
    const payload = Object.assign({}, rowData);
    if (payload.isNew) {
      payload.queueMappingIdOriginal = null;
    }
    this.workQueueListService.createUpdateWorkQueueStatusMapping(payload).subscribe(
      ({ data }) => {
        this.isLoading = false;
        const response = data.createUpdateServicerInsurance;
        if (response) {
          let successMessage = 'Saved successfully.';
          if (isDelete) {
            this.dataList.splice(this.selectedRowIndex, 1);
            successMessage = 'Deleted successfully.';
          } else {
            const item = this.formatData(response);
            item.id = this.dataList[this.selectedRowIndex].id;
            item[`isNew`] = false;
            this.dataList[this.selectedRowIndex] = item;
          }
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: successMessage,
          });
        }
        this.isEditing = false;
      },
      (err) => {
        this.isLoading = false;
        this.isEditing = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error while saving, please try again.',
        });
      },
    );
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  deleteWorkQueueList() {
    const rowData = this.selectedItem;
    rowData.insertUserName = this.azureService.accountId;
    let payLoad = { ...rowData };
    payLoad = {
      ...rowData,
      isDeleted: true,
    };
    this.isLoading = true;
    this.workQueueListService.createUpdateWorkQueueStatusMapping(payLoad).subscribe(
      (data) => {
        this.isLoading = false;
        this.dataList.splice(this.selectedRowIndex, 1);
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Deleted successfully.',
        });
      },
      (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error.',
        });
      },
    );
  }

  onRowDelete(event) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.deleteWorkQueueList();
      },
    });
  }

  onClickAddType(event) {
    this.isEditing = true;
    this.selectedRow = this.dataList[this.selectedRowIndex];
    this.displayServiceInfoModal = true;
  }

  onCreateUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
    }
    this.dropDownWorkQueueType();
  }
}
