<div class="external-login">
  <div class="login-container">
    <div class="form-group">
      <img [src]="'assets/NLeafLogo.png'" />
      <div class="input-container">
        <button class="button" type="button" (click)="login()">Login</button>
      </div>
      <div class="build-info">{{ buildNumber }}</div>
    </div>
  </div>
</div>
