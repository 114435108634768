import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { generalDateFormatter } from 'src/app/modules/crm/shared/utilities/date-utilities';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { SecurityTypeService } from '../../../../administration/security/security.service';
import { expiredOverrideRoles } from '../../../../shared/constants/service-order-constants';
import { MessageService } from 'primeng/api';
import { ConfigurationTypeService } from 'src/app/modules/crm/administration/configuration/configuration.service';
import { getMenuTypeKey } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Component({
  selector: 'coverage-details',
  templateUrl: './coverage-details.template.html',
  styleUrls: ['coverage-details.scss'],
  providers: [SecurityTypeService, MessageService, FormFieldControlService],
})
export class CoverageDetailsComponent implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  coveredDetails: any[];
  globalOptions: any[];
  cols: any[];
  selectedCoverage: any;
  currentIdIndex = 0;
  coverageReasonList: any[];
  coverageReason: any;
  display = false;
  hasWarrantyDisplayed: any;
  disableCoverageSelection = false;
  isdispatchDateAvail: any;
  querySubscriptions: Subscription[] = [];
  formFields: FormField<string>[] = [];
  form: FormGroup;
  isLoading = false;
  isContractExpired = false;
  goodwillReasonList: any[];
  isGoodWillVisible = false;
  isEditEnable = true;
  constructor(
    public serviceOrderDataService: ServiceOrderDataService,
    public confirmationService: ConfirmationService,
    private qcs: FormFieldControlService,
    private securityTypeService: SecurityTypeService,
    private messageService: MessageService,
    private configurationService: ConfigurationTypeService,
    private azureService: AzureLoginService,
    private roleService: RoleAuthorizationService,
  ) {}

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.cols = [
      { field: 'name', header: 'NAME' },
      { field: 'policySku', header: 'POLICY/SKU' },
      { field: 'description', header: 'DESCRIPTION' },
      { field: 'cvTerm', header: 'TERM' },
      { field: 'covDateSummary', header: 'COVERAGE START/EXP' },
      { field: 'covered', header: 'COVERED' },
    ];

    this.coverageReasonList = [];
    this.configurationService
      .getAdminConfigMenu({
        module: 'service_order',
        menuType: 'coverage_reason',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.globalOptions = configList;
        const options = configList
          .filter((el) => el.menuType === 'coverage_reason')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.coverageReasonList = [
          ...options.filter((e) => e.key === null),
          ...options.filter((e) => e.key !== null),
        ];
        // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
        // No user can edit the details anymore(PD-907057)
        this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
          this.isEditEnable = isEnable;
        });
        this.formValueChanged.emit(true);
      });

    this.goodwillReasonList = [
      { key: '', value: '' },
      { key: '50% back Program', value: '50% back Program' },
      { key: 'Failure occurred before expiration', value: 'Failure occurred before expiration' },
      { key: 'Escalation', value: 'Escalation' },
      { key: 'Executive Order', value: 'Executive Order' },
      { key: 'Just Say Yes', value: 'Just Say Yes' },
    ];

    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          if (
            isClaimDataAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.productsId
          ) {
            this.serviceOrderDataService.isLoading.next(true);
            this.querySubscriptions.push(
              this.serviceOrderDataService
                .getClaimCoverageDetails(
                  this.serviceOrderDataService.serviceOrderDataModel.claimData.productsId,
                  this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal,
                )
                .subscribe(({ data, loading }: any) => {
                  this.serviceOrderDataService.isLoading.next(false);
                  const oemWarranty = data.getClaimCoverageDetails.find(
                    (x) => x.name === 'OEM Warranty' && x.covered === 'YES',
                  );
                  if (oemWarranty && !this.hasWarrantyDisplayed) {
                    this.display = true;
                    this.hasWarrantyDisplayed = true;
                  }
                  this.coveredDetails = this.populateCoveredDetails(data.getClaimCoverageDetails);

                  this.serviceOrderDataService.serviceOrderDataModel.coverageDetails =
                    this.coveredDetails;
                  this.serviceOrderDataService.setValidatorSku(
                    this.serviceOrderDataService.serviceOrderDataModel.coverageDetails,
                  );
                  if (
                    this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
                    this.serviceOrderDataService.serviceOrderDataModel.contractInfo
                      .contractStatusDescription &&
                    this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractStatusDescription.toUpperCase() ===
                      'EXPIRED'
                  ) {
                    this.isContractExpired = true;
                    if (
                      this.roleService.validateIfPermissionsExist({
                        category: 'Claim',
                        permission: 'Good Will Authorization',
                        operation: 'READ_ONLY',
                        type: 'Code',
                      })
                    ) {
                      this.isGoodWillVisible = true;
                    }
                  }
                }),
            );
          }
        },
      }),
    );

    this.querySubscriptions.push(
      this.serviceOrderDataService.disableCoverageSelection.subscribe((data) => {
        if (data) {
          this.disableCoverageSelection = true;
        }
      }),
    );

    // Coverage Selection Check
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          this.querySubscriptions.push(
            this.serviceOrderDataService.isServicerInfoAvailable.subscribe({
              next: (isServicerInfoAvailable) => {
                if (isClaimDataAvailable && isServicerInfoAvailable) {
                  const claimOrigId =
                    this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
                  const servProfileId = this.serviceOrderDataService.serviceOrderDataModel
                    .servicerInfo
                    ? this.serviceOrderDataService.serviceOrderDataModel.servicerInfo
                        .servicerProfilesId
                    : undefined;
                  this.querySubscriptions.push(
                    this.serviceOrderDataService.dispatchDateChanged.subscribe({
                      next: (dispatchdateChanged) => {
                        if (claimOrigId && servProfileId && dispatchdateChanged) {
                          this.serviceOrderDataService.disableCoverageSelection.next(true);
                        }
                      },
                    }),
                  );
                }
              },
            }),
          );
        },
      }),
    );
  }
  onRowSelect(event) {
    this.serviceOrderDataService.serviceOrderDataModel.selectedCoverage = event.data;
  }

  populateCoveredDetails(coverageList) {
    const canEditOem = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      operation: 'EDIT',
      permission: 'OEM Warranty Selection',
      type: 'Select-Item',
    });
    const result = [];
    coverageList.forEach((element) => {
      if (element) {
        // calculate date summary
        const t = Object.assign({}, element);
        t.covDateSummary =
          element.coverageStDate && element.coverageExpDate
            ? generalDateFormatter(element.coverageStDate) +
              ' - ' +
              generalDateFormatter(element.coverageExpDate)
            : '';

        // set if covered to 'no' if not availalbe
        t.covered = element.covered ? element.covered : 'NO';

        // generate id for each element for unique selection
        t.id = this.currentIdIndex = this.currentIdIndex + 1;
        t.blockEdit = false;
        if (element.name.toLowerCase().includes('oem') && !canEditOem) {
          t.blockEdit = true;
        }
        result.push(t);

        if (t.selection === true) {
          this.selectedCoverage = t;
          this.serviceOrderDataService.serviceOrderDataModel.selectedCoverage = t;
          this.serviceOrderDataService.serviceOrderDataModel.coverageReason = {
            key: getMenuTypeKey(this.globalOptions, 'coverage_reason', t.coverageReason),
            value: t.coverageReason,
          };
        }
      }
    });
    return result;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
