<div class="coverage-tab">
  <app-section-header [title]="'Coverage Information'" />
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
  <div class="sub-section" [hidden]="isLoading">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-10">
          <form-field [formField]="formFields[0]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[1]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-5">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-3">
          <form-field [formField]="formFields[3]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="coverageList" [loading]="isTableLoading"
            [scrollable]="true" scrollHeight="200px">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of cols" [style.width]="col.width">{{rowData[col.field]}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary" let-rowData="">
              <div class="text-left">
                  <button class="btn-crm-success" type="button" (click)="getCoverageLossCodes()"
                    [attr.disabled]="disableLoadMore?true:null">
                    Load More
                  </button>
              </div>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="push-bottom-medium"></div>
      <div class="sub-section">
        <div class="title">
          <div></div>
        </div>
      </div>
      <div class="grid">
        <div class="col-7">
          <form-field [formField]="formFields[4]" [form]="form"></form-field>
        </div>
        <div class="col-5">
          <form-field [formField]="formFields[5]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-7"></div>
        <div class="col-5">
          <form-field [formField]="formFields[6]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-7"></div>
        <div class="col-5">
          <form-field [formField]="formFields[7]" [form]="form"></form-field>
        </div>
      </div>
      <div class="grid">
        <div class="col-7"></div>
        <div class="col-5">
          <form-field [formField]="formFields[8]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
</div>