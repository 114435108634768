<div class="form-details-container">
  <div class="form-details-title">
    <div class="form-thumnail"></div>
    <div class="form-name"><input disabled="disabled" value="Dealer Location" /></div>
    <div class="form-dot-button"></div>
    <div class="last-cell">
      <div class="three-dots-heading" (click)="ellipsisClick($event,'item',opHeader)">
        <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
      </div>
    </div>
  </div>
  <div class="form-details-body">
    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form" (changeEvent)="onChangeEvent($event,formField.key)"></form-field>
        </div>
        <div class="button-bar">
          <div *ngFor="let singleButton of button">
            <button type="singleButton.type" [disabled]="singleButton.disabled" [hidden]="singleButton.hidden">
              {{singleButton.name}}
            </button>
          </div>
        </div>
        <p-message severity="success" text="Record Saved" *ngIf="isSuccess"> </p-message><p-toast></p-toast>
      </form>
    </div>
  </div>
</div>
<p-overlayPanel #opHeader
  ><p-listbox
    [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)"
  >
  </p-listbox></p-overlayPanel
><history-item-modal
  modalTitle="Dealer Location History"
  [(displayDialog)]="displayHistory"
  [formData]="historyData"
  *ngIf="displayHistory"
></history-item-modal>
