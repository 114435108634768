import { Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../../shared/form-field/form-can-deactivate';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../../interfaces/role-authorization.interface';
import { RoleAuthorizationService } from '../../../../../../services/role-authorization.service';
import { ServiceOrderDataService } from '../../service-order-data.service';

@Component({
  selector: 'summary-section',
  templateUrl: 'summary-section.template.html',
  styleUrls: ['summary-section.scss'],
  providers: [FormFieldControlService],
})
export class SummarySectionComponent extends FormCanDeactivate implements OnInit {
  @Input() title: any;
  @Input() formFields: FormField<string>[];
  @Input() parentComponent: Type<any>;
  form: FormGroup;
  querySubscription: any;
  isEditEnable = true;
  private authDetails: IRoleAuthorizationService;

  private privateFormData: any;
  private privateData: any[];
  @Output() formDataChange = new EventEmitter();

  get formData(): any {
    return this.privateFormData;
  }
  @Input() set formData(value: any) {
    this.privateFormData = value;
    this.formDataChange.emit(this.formData);
  }

  get data(): any[] {
    return this.privateData;
  }
  @Input() set data(value: any[]) {
    this.privateData = value;
    const temp = this.privateData.find((x) => x.label === 'Prime Phone');
    if (temp) {
      const f = this.form;
      if (f && !f.controls.phoneNumber1.value) {
        f.controls.phoneNumber1.setValue(temp.value);
      }
    }
  }

  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private serviceOrderDataService: ServiceOrderDataService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.formFields) {
      this.authDetails = {
        authorizationType: AuthorizationType.FormField,
        component: SummarySectionComponent,
        generalArray: this.formFields,
      };
      if (this.parentComponent) {
        this.authDetails.parantComponent = this.parentComponent;
      }

      this.form = Object.assign(this.roleService.applyAuthorization(this.authDetails));
      this.form = this.qcs.toFormGroup(this.formFields);
      // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
      // No user can edit the details anymore(PD-907057)
      this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
        this.isEditEnable = isEnable;
        if (!this.isEditEnable) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });

      for (const property in this.formData) {
        if (this.form.controls[property]) {
          if (property === 'firstName' || property === 'lastName') {
            this.form.controls[property].setValue(this.formData[property].toUpperCase());
          } else {
            this.form.controls[property].setValue(this.formData[property]);
          }
        }
      }
      this.form.valueChanges.subscribe((x) => {
        for (const property in x) {
          if (property) {
            this.formData[property] = x[property];
          }
        }
        // Add formValidation results since main component doesn't get this correctly.
        this.formDataChange.emit(
          Object.assign({}, this.formData, {
            formValidation: this.form.valid || !this.isEditEnable,
          }),
        );
      });
    }
  }
}
