import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisCrudOptions,
  ellipsisSubHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { ServicerServiceInfo } from './servicer-service-info.service';
import { ServicerServiceFormService } from '../servicer-service-form/servicer-service-form.service';
import { PriceTable } from '../../../crm-coverage/coverage-models';
import { buttonStatus } from '../../../shared/constants/button-class';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { FormField } from '../../../shared/form-field/form-field';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { ServicerService } from '../servicer.service';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { MessageService } from 'primeng/api';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'service-info-tab',
  templateUrl: 'servicer-service-info.template.html',
  providers: [
    ServicerServiceInfo,
    ServicerServiceFormService,
    FormFieldControlService,
    MessageService,
  ],
})
export class ServicerServiceInfoComponent implements OnInit, OnDestroy {
  dataList: any[];
  dataListDefault: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = ellipsisSubHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedRow: any;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  formModalInfo: FormField<any>[] = [];
  tabKey: string;
  isLoading = false;
  querySubscription: Subscription;
  form: FormGroup;
  displayServiceInfoModal = false;
  currentMaxId = 0;
  isAddEnable = true;
  constructor(
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private ServicerFormService: ServicerServiceFormService,
    private tabHandlerService: TabHandlerService,
    private qcs: FormFieldControlService,
    private servcerService: ServicerService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.ellipsisOptions = this.ellipsisOptions.filter((el) => el.value !== 'delete');
    this.cols = [
      { field: 'serviceRecallPeriod', header: 'Service Recall Period', type: 'text' },
      { field: 'serviceRecallPeriodTerm', header: 'Days/Months from Date of Repair', type: 'text' },
      { field: 'invoiceSubmission', header: 'Invoice Submission', type: 'text' },
      { field: 'invoiceSubmissionTerm', header: 'Days/Months from Date of Repair', type: 'text' },
      { field: 'partsVerificationRequired', header: 'Parts verification required', type: 'text' },
      { field: 'contracted', header: 'Contracted', type: 'text' },
      { field: 'sealedSystemsRepair', header: 'Sealed System Repair', type: 'text' },
      { field: 'customerSatisfaction', header: 'Customer Satisfaction', type: 'text' },
      { field: 'rating', header: 'Rating', type: 'text' },
      { field: 'facilityType', header: 'Facility Type', type: 'text' },
    ];

    this.editConstant = uuidv4();
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.loadServiceInfo();
        }
      },
    });
    this.dataList = [];
    // this.loadServiceInfo();
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Service Info',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Service Info',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  formatData(item) {
    return {
      servicerProfilesId: this.servcerService.selectedServicerProfileId,
      servicerProfilesInfoIdOriginal: item.servicerProfilesInfoIdOriginal,
      serviceRecallPeriod: item.serviceRecallPeriod === null ? '0' : item.serviceRecallPeriod,
      serviceRecallPeriodTerm: item.serviceRecallPeriodTerm,
      invoiceSubmission: item.invoiceSubmission === null ? '0' : item.invoiceSubmission,
      invoiceSubmissionTerm: item.invoiceSubmissionTerm,
      partsVerificationRequired: item.partsVerificationRequired ? 'Yes' : 'No',
      contracted: item.contracted ? 'Yes' : 'No',
      sealedSystemsRepair: item.sealedSystemsRepair ? 'Yes' : 'No',
      customerSatisfaction: item.customerSatisfaction,
      rating: item.rating,
      facilityType: item.facilityType,
    };
  }

  loadServiceInfo() {
    this.isLoading = true;
    this.querySubscription = this.servcerService
      .getServicerInfoById(this.servcerService.selectedServicerProfileId)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = false;
          if (data.getServicerProfileInfoByServicerProfile) {
            this.isAddEnable = false;
            this.dataList = [this.formatData(data.getServicerProfileInfoByServicerProfile)];
          } else {
            this.dataList = this.dataListDefault;
            this.isAddEnable = true;
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
    this.selectedRow = item;
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    this.selectedRow = this.dataList[0];
    this.displayServiceInfoModal = true;
    // document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any, rowIndex: number) {
    // this.clonedRowData[rowData.id] = { ...rowData };
    // this.displayServiceInfoModal = true;
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    if (!this.dataList) {
      this.dataList = [];
    }
    let rowItem = new PriceTable();
    rowItem.id = this.dataList ? this.dataList.length + 1 : 1;
    rowItem[`isNew`] = true;
    rowItem[`servicerProfilesId`] = this.servcerService.selectedServicerProfileId;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.displayServiceInfoModal = true;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  onCreateUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
    }
    this.loadServiceInfo();
  }

  onCancel(event) {
    if (event) {
      this.loadServiceInfo();
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
