import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from 'src/app/modules/crm/shared/constants/graphql-constants';

const getInsuranceCompaniesListQuery = gql`
  query {
    getInsuranceCompaniesList
  }
`;

const getClaimTechInfoByClaimQuery = gql`
  query($serviceOrderNumber: String!) {
    getClaimTechInfoByClaim(serviceOrderNumber: $serviceOrderNumber) {
      claimNumber
      claimId
      dateOfRepair
      numberOfVisits
      technicianName
      invoiceNumber
      serviceStatus
      dateOfInvoice
      fPBRedemptionDate
      serviceScheduleDate
      insuranceCarrier
      claimTechInfoIdOriginal
      isDeleted
      serviceOrderNumber
      insertUserName
      addedTimeStamp
      serviceOrderNumber
    }
  }
`;

const createUpdateClaimTechInfoQuery = gql`
  mutation(
    $claimNumber: String
    $dateOfRepair: Date
    $numberOfVisits: Int
    $technicianName: String
    $invoiceNumber: String
    $serviceStatus: String
    $dateOfInvoice: Date
    $serviceScheduleDate: Date
    $fPBRedemptionDate: Date
    $insuranceCarrier: String
    $claimTechInfoIdOriginal: Int
    $serviceOrderNumber: String
    $insertUserName: String
  ) {
    createUpdateClaimTechInfo(
      claimTechInfo: {
        claimNumber: $claimNumber
        dateOfRepair: $dateOfRepair
        numberOfVisits: $numberOfVisits
        fPBRedemptionDate: $fPBRedemptionDate
        technicianName: $technicianName
        invoiceNumber: $invoiceNumber
        serviceStatus: $serviceStatus
        dateOfInvoice: $dateOfInvoice
        serviceScheduleDate: $serviceScheduleDate
        insuranceCarrier: $insuranceCarrier
        claimTechInfoIdOriginal: $claimTechInfoIdOriginal
        serviceOrderNumber: $serviceOrderNumber
        insertUserName: $insertUserName
      }
    ) {
      claimNumber
      claimId
      claimTechInfoIdOriginal
      serviceOrderNumber
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class TechnicalInformationService {
  constructor(private apollo: Apollo) {}

  getInsuranceCompaniesList = () =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getInsuranceCompaniesListQuery,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getLabelValueList = data => {
    const finalObj = [];
    data.map(el => {
      const obj = { label: '', value: '' };
      obj.label = el;
      obj.value = el;
      finalObj.push(obj);
    });
    return finalObj;
    // tslint:disable-next-line: semicolon
  };

  getClaimTechInfoByClaimQuery = serviceOrderNumber =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getClaimTechInfoByClaimQuery,
      variables: { serviceOrderNumber },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createUpdateClaimTechInfo = data =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createUpdateClaimTechInfoQuery,
      variables: { ...data }
      // tslint:disable-next-line: semicolon
    });
}
