export const SYSTEM_USER_ROLES = {
  ACCOUNT_MANAGER: 'Account Manager',
  ACCOUNTING: 'Accounting',
  ACCOUNTING_MANAGER: 'Accounting Manager',
  ADMIN: 'Admin',
  BACK_OFFICE: 'Back Office',
  BACK_OFFICE_LEAD: 'Back Office Lead',
  CALL_CENTER_MANAGER: 'Call Center Manager',
  CALL_CENTER_SUPERVISOR: 'Call Center Supervisor',
  CLAIMS_1: 'Claims 1',
  CLAIMS_1_PLUS: 'Claims 1 Plus',
  CLAIMS_MANAGER: 'Claims Manager',
  CSR_1: 'CSR 1',
  CSR_2: 'CSR 2',
  CSR_2_PLUS: 'CSR 2 Plus',
  CSR_3: 'CSR 3',
  DATA_ADMINISTRATION: 'Data Administration',
  DATA_ADMIN_LIMITED: 'Data Admin - Limited',
  SERVICE_PROVIDER_INDEPENDENT: '!Service Provider Independent',
  SPECIAL_TEAM_A_CC_SN: 'Special Team A-CC-SN',
  SSD_LITE: 'SSD - Lite',
  SSD_MED: 'SSD-Med',
  SSD_PLUS: 'SSD-Plus'
};
