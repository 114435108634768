import gql from 'graphql-tag';

export const ratingsSearchQuery = gql`
  query($coverageCode: String) {
    getRatingsSearch(coverageCode: $coverageCode) {
      primaryRatingProvider
      coverageCode
      dateRangeFrom
      dateRangeTo
      term
      admin
      reserve
      commission
      total
      suggestedTotal
      coverageRates {
        coverageRatesId
        coveragePlanId
        coverageCode
        dateRangeFrom
        dateRangeTo
        term
        bucketCode
        bucketLabel
        rateAmount
        contractId
        categoryCode
      }
    }
  }
`;

export const createupdateAlertDataQuery = gql`
  mutation($coverageRates: [CoverageRatesInput]) {
    createUpdateCoverageRates(coverageRates: $coverageRates) {
      coverageRatesId
    }
  }
`;
