import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { DealerServicer } from './dealer_servicer';
import { dealerServicerHeaderOptions, dealerServicerRowOptions } from './dealer-servicer.ellipsis';
import { FormField } from '../../shared/form-field/form-field';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DealerService } from '../dealer.service';
import { v4 as uuidv4 } from 'uuid';
import _each from 'lodash/forEach';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { Subscription } from 'rxjs';
import { DealerServicerService } from './dealer-servicer.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import { DealerFormFieldService } from '../../services/dealer-form-field.service';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { CrmService } from '../../services/crm-service';
import { ModuleType } from '../../shared/constants/enums';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'app-dealer-servicer',
  templateUrl: './dealer-servicer.component.html',
  styleUrls: ['dealer-servicer.scss'],
  providers: [FormFieldControlService, DealerFormFieldService, MessageService],
})
export class DealerServicerComponent implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  dealerServicer: DealerServicer[] = [];
  isLoading: boolean;
  selectedItem: any;
  selectedRowIndex: any;
  editConstant: any;
  isEditing: boolean;
  clonedRowData: KeyMap = {};
  cols: any[];
  selectedColumns: any[];
  tabKey: any;
  dropdown: any;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  querySubscriptions: Subscription[] = [];
  ellipsisRowOptions: any[] = dealerServicerRowOptions;
  ellipsisRowOptionsSec: any;
  ellipsisHeaderOptions: any[] = dealerServicerHeaderOptions;
  ellipsisHeaderOptionsSec: any;

  serviceProviderOptions: any[];
  serviceType: any[];
  currentMaxId = 0;
  selfServicing: any;

  constructor(
    private azureService: AzureLoginService,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private dealerService: DealerService,
    private dealerServicerService: DealerServicerService,
    private dropdownDataService: DropdownDataService,
    private messageService: MessageService,
    private tabHandlerService: TabHandlerService,
    private qcs: FormFieldControlService,
    private crmService: CrmService,
    private service: DealerFormFieldService,
    private rps: RolePermissionService,
  ) {
    if (
      !['Admin', 'Account Manager', 'Special Team A-CC-SN'].includes(this.azureService.roleName)
    ) {
      this.ellipsisRowOptions = this.ellipsisRowOptions.filter((x) => x.value !== 'Remove');
    }
    this.service.getServicerDealerFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });

    this.editConstant = uuidv4();

    this.cols = [
      { field: 'id', header: 'SERVICER#', type: 'id', label: 'servicerNo' },
      {
        field: 'servicerProvider',
        header: 'SERVICE PROVIDER#',
        type: 'select',
        label: 'servicerProvider',
      },
      { field: 'type', header: 'TYPE#', type: 'select', label: 'type' },
    ];

    this.selectedColumns = this.cols.slice(1, 3);

    this.serviceProviderOptions = this.dropdownDataService.servicerListLinkData
      ? this.dropdownDataService.servicerListLinkData.map((x) => ({
          key: `${x.key} | ${x.value} `,
          value: x.value,
        }))
      : [];

    this.serviceType = [
      { key: 'Preferred', value: 'Preferred' },
      { key: 'Restricted', value: 'Restricted' },
    ];

    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab && this.dealerService && this.dealerService.dealerFormData) {
          this.isLoading = true;
          this.loadDealerServicers();
        }
      },
    });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisRowOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Servicer',
      category: 'Seller',
      data: this.ellipsisRowOptions,
    };
    this.ellipsisRowOptions = this.rps.evaluate(
      this.ellipsisRowOptionsSec,
      this.ellipsisRowOptions,
    );

    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Servicer',
      category: 'Seller',
      data: this.ellipsisRowOptions,
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
  }

  loadDealerServicers() {
    const dealerGroupId = this.dealerService.dealerGroupsIdOriginal;
    if (dealerGroupId) {
      this.dealerServicerService.getDealerServicer({ dealerGroupId }).subscribe(
        ({ data, errors }) => {
          if (!errors) {
            const { getDealerServicer } = data;
            const selfServicingServicer = getDealerServicer.find(
              (servicer: { selfServicing: number }) => servicer.selfServicing === 1,
            );
            const preferenceServicers = getDealerServicer.filter(
              (servicer: { preferred: number; restricted: number }) =>
                servicer.preferred === 1 || servicer.restricted,
            );

            if (selfServicingServicer) {
              this.form.patchValue({
                servicer: {
                  key: `${selfServicingServicer.name} | ${selfServicingServicer.number} `,
                  value: selfServicingServicer.number,
                },
              });

              this.selfServicing = {
                dealerGroupsServicersIdOriginal:
                  selfServicingServicer.dealerGroupsServicersIdOriginal,
                name: selfServicingServicer.name,
                number: selfServicingServicer.number,
                selfServicing: true,
              };
            } else {
              this.formFields = this.formFields.map((formField) => {
                if (formField.key === 'servicer') {
                  formField = { ...formField, disabled: true };
                }
                return formField;
              });
              this.form = this.qcs.toFormGroup(this.formFields);
            }
            this.form.patchValue({
              tSelfServicing: selfServicingServicer ? ['Self Servicing'] : null,
            });
            this.dealerServicer = [];
            if (preferenceServicers) {
              preferenceServicers.forEach(
                (servicer: {
                  name: any;
                  number: number;
                  preferred: number;
                  dealerGroupsServicersIdOriginal: number;
                }) => {
                  const rowItem = {
                    id: null,
                    servicerNo: null,
                    servicerProvider: null,
                    type: null,
                  };

                  rowItem[`id`] = this.currentMaxId = this.currentMaxId + 1;
                  rowItem[`servicerNo`] = this.dealerServicer.length + 1;
                  rowItem[`servicerProvider`] = `${servicer.name} | ${servicer.number} `;
                  rowItem[`type`] = servicer.preferred === 1 ? 'Preferred' : 'Restricted';
                  rowItem[`dealerGroupsServicersIdOriginal`] =
                    servicer.dealerGroupsServicersIdOriginal;

                  this.dealerServicer.push(rowItem);
                },
              );
            }
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onClickAdd(event) {
    let rowItem = {
      id: null,
      servicerNo: null,
      servicerProvider: null,
      type: null,
    };

    rowItem[`id`] = this.currentMaxId = this.currentMaxId + 1;
    rowItem[`isNew`] = true;
    rowItem[`servicerNo`] = this.dealerServicer.length + 1;
    rowItem = { ...rowItem };

    this.dealerServicer.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dealerServicer.length - 1;
    this.onRowEdit();
  }

  onServicerDropDownChange(event, index) {
    this.dealerServicer[index][`servicerProvider`] = event.value;
    this.dealerServicer[index][`number`] = event.value.value;
    this.dealerServicer[index][`name`] = event.value.key.split(' |')[0];
  }

  onTypeDropDownChange(event, index) {
    this.dealerServicer[index][`type`] = event.value;
    this.dealerServicer[index][`preferred`] = event.value.key === 'Preferred' ? true : false;
    this.dealerServicer[index][`restricted`] = event.value.key === 'Restricted' ? true : false;
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onClickDelete(dealerGroupsServicersIdOriginal = null) {
    let dealerGroupsServicersId = dealerGroupsServicersIdOriginal;
    if (this.selectedItem && this.selectedItem.dealerGroupsServicersIdOriginal) {
      dealerGroupsServicersId = this.selectedItem.dealerGroupsServicersIdOriginal;
    }
    const payload = {
      idOriginal: dealerGroupsServicersId,
      moduleName: ModuleType.DEALERSERVICER,
    };
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this attached Servicer ?',
      accept: () => {
        this.isLoading = true;
        this.crmService.deleteCrmModule(payload).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            if (!data.deleteCrmModule) {
              throw new Error('Error in deleting the attached servicer.');
            }
            this.isLoading = true;
            this.loadDealerServicers();
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
      },
    });
  }

  onRowEditInit(rowData: any) {
    if (rowData.servicerProvider || rowData.type) {
      rowData.servicerProvider = this.serviceProviderOptions.find(
        (option) => option.key === rowData.servicerProvider,
      );
      rowData.type = this.serviceType.find((option) => option.key === rowData.type);
    }
    this.clonedRowData[rowData.id] = { ...rowData };
  }

  onRowEditSave(rowData: any, index: number) {
    if (!('selfServicing' in rowData)) {
      // if (rowData.isNew) {
      //   this.dealerServicer.splice(this.selectedRowIndex, 1);
      // }
      rowData.servicerProvider = rowData.servicerProvider.key;
      rowData.type = rowData.type.key;
      rowData.name = rowData.servicerProvider.split(' |')[0];
      rowData.number = rowData.servicerProvider.split(' |')[1].trim();
    }
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    rowData.dealerGroupNumber = this.dealerService.dealerFormData.dealerGroupNumber;
    rowData.dealerGroupId = this.dealerService.dealerGroupsIdOriginal;
    rowData.dealerGroupName = this.dealerService.dealerFormData.dealerGroupName;

    if (
      ('selfServicing' in rowData) &&
      (rowData.selfServicing === false || rowData.selfServicing === 0)
    ) {
      this.onClickDelete(rowData.dealerGroupsServicersIdOriginal);
      delete this.selfServicing.name;
      delete this.selfServicing.number;
      return;
    }
    rowData.selfServicing =
      rowData.selfServicing === 1 || rowData.selfServicing === true ? true : false;
    rowData.insertUserName = this.azureService.accountId;
    rowData.preferred = rowData.type === 'Preferred' ? true : false;
    rowData.restricted = rowData.type === 'Restricted' ? true : false;
    this.isLoading = true;
    this.querySubscriptions.push(
      this.dealerServicerService.createUpdateDealerServicer(rowData).subscribe(
        ({ data, loading }: any) => {
          // if (index > -1) {
          //   this.dealerServicer[index] = {
          //     ...data[`createUpdateDealerServicer`],
          //     id: this.dealerServicer[index][`id`],
          //     servicerProvider: `${data[`createUpdateDealerServicer`].name} | ${data[`createUpdateDealerServicer`].number
          //       } `,
          //     type:
          //       data[`createUpdateDealerServicer`].preferred === 1 ? 'Preferred' : 'Restricted',
          //     dealerGroupsServicersIdOriginal:
          //       data[`createUpdateDealerServicer`].dealerGroupsServicersIdOriginal,
          //   };
          // }

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Servicer saved successfully.',
          });
          this.isLoading = false;
          this.loadDealerServicers();
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving, please try again.',
          });
          this.isLoading = false;
        },
      ),
    );
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dealerServicer[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = this.checkEditMode();
          this.dealerServicer.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.clonedRowData[rowData.id].servicerProvider = rowData.servicerProvider.key;
      this.clonedRowData[rowData.id].type = rowData.type.key;
      this.dealerServicer[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = this.checkEditMode();
    }
  }

  private checkEditMode() {
    let isEditing = false;
    _each(this.dealerServicer, (data) => {
      if (data[`isNew`]) {
        isEditing = true;
      }
    });
    return isEditing;
  }

  onChangeEvent(event, key) {
    if (key === 'selfServicing') {
      if (event || !event) {
        if (this.selfServicing) {
          this.selfServicing[`selfServicing`] = event;
        } else {
          this.selfServicing = {
            selfServicing: event,
          };
        }

        if (event.checked.length > 0 && event.checked[0] == 'Self Servicing') {
          this.selfServicing = {
            selfServicing: true
          };
          this.form.controls.servicer.enable();
        } else {
          this.form.controls.servicer.reset();
          this.form.controls.servicer.disable();
        }
      }
    }

    if (key === 'servicer') {
      if (event.originalEvent) {
        if (this.selfServicing) {
          this.selfServicing[`number`] = event.value.value;
          this.selfServicing[`name`] = event.value.key.split(' |')[0];
        } else {
          this.selfServicing = {
            name: event.value.value,
            number: event.value.key.split(' |')[0],
          };
        }
      }
    }

    if (this.selfServicing.name && this.selfServicing.number) {
      this.onRowEditSave(this.selfServicing, -1);
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  onSubmit() {}
}
