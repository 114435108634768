import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { FormField } from '../../../shared/form-field/form-field';
import { Apollo } from 'apollo-angular';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { TextAreaField } from '../../../shared/form-field/textarea-field';

@Injectable()
export class SetupAlertModalService {
  templateDetails = {};
  typeMapping = {};
  constructor(private apollo: Apollo, private dropdownDataService: DropdownDataService) {}

  getAlertFormFields() {
    const optionsManufacturerList = this.dropdownDataService.manufacturerDetailList
      ? this.dropdownDataService.manufacturerDetailList.map(x => ({
          key: x.manufacturersName,
          value: x.ManufacturesIDOriginal
        }))
      : [];
    const productPlanTypeList = this.dropdownDataService.productPlanTypeList
      ? this.dropdownDataService.productPlanTypeList.map(x => ({
          key: x.value,
          value: parseInt(x.key, 10)
        }))
      : [];
    const moduleTypeList = this.dropdownDataService.moduleTypeList
      ? this.dropdownDataService.moduleTypeList.map(x => ({
          key: x.value,
          value: parseInt(x.key, 10)
        }))
      : [];
    const optionsDealerList = this.dropdownDataService.dealerGroupData
      ? this.dropdownDataService.dealerGroupData.map(x => ({
          key: x.dealerGroupName,
          value: x.dealerGroupsIdOriginal
        }))
      : [];
    const optionsCategoryDescriptionList = this.dropdownDataService.categoryList
      ? this.dropdownDataService.categoryList.map(x => ({
          key: x.value,
          value: parseInt(x.key, 10)
        }))
      : [];
    const optionsDealerLocationList = this.dropdownDataService.dealerLocationList
      ? this.dropdownDataService.dealerLocationList.map(x => ({
          key: x.value,
          value: x.key
        }))
      : [];
    const optionsBuyingGroupDescriptionList = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map(x => ({
          key: x.name,
          value: x.agentIdOriginal
        }))
      : [];
    // const optionsSubCategoryDescriptionList = this.dropdownDataService.subCategoryDescriptionList;
    const formFields: FormField<string>[] = [
      new DropdownField({
        required: true,
        key: 'alertStatus',
        label: 'STATUS',
        isSingleselect: true,
        options: [{ key: 'Active', value: 'Active' }, { key: 'Inactive', value: 'Inactive' }],
        order: 0
      }),
      new DropdownField({
        required: true,
        key: 'moduleType',
        label: 'MODULE',
        options: [{ key: 'ALL', value: 0 }, ...moduleTypeList],
        isSingleselect: false,
        virtualScroll: true,
        filter: true,
        order: 1
      }),
      new DropdownField({
        required: true,
        key: 'type',
        label: 'PRODUCT TYPE',
        isSingleselect: false,
        options: [{ key: 'ALL', value: 0 }, ...productPlanTypeList],
        order: 2
      }),
      new DropdownField({
        key: 'manufacturerName',
        label: 'MANUFACTURER',
        options: [{ key: 'ALL', value: 0 }, ...optionsManufacturerList],
        isSingleselect: false,
        virtualScroll: true,
        filter: true,
        order: 3
      }),
      new DropdownField({
        key: 'categoryDescription',
        label: 'CATEGORY',
        options: [{ key: 'ALL', value: 0 }, ...optionsCategoryDescriptionList],
        isSingleselect: false,
        virtualScroll: true,
        filter: true,
        order: 4
      }),
      new DropdownField({
        key: 'subcategoryDescription',
        label: 'SUB-CATEGORY',
        isSingleselect: false,
        options: [],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 5
      }),
      new DropdownField({
        key: 'dealers',
        label: 'DEALERS',
        isSingleselect: false,
        options: [{ key: 'ALL', value: 0 }, ...optionsDealerList],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 6
      }),
      new DropdownField({
        key: 'dealerLocations',
        label: 'DEALER LOCATIONS',
        isSingleselect: false,
        options: [{ key: 'ALL', value: 0 }, ...optionsDealerLocationList],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 7
      }),
      new DropdownField({
        key: 'buyingGroup',
        label: 'BUYING GROUP',
        isSingleselect: false,
        options: [{ key: 'ALL', value: 0 }, ...optionsBuyingGroupDescriptionList],
        virtualScroll: true,
        showToggleAll: false,
        filter: true,
        order: 8
      }),
      new TextAreaField({
        required: true,
        key: 'alertMessage',
        label: 'MESSAGE',
        rows: 6,
        order: 9
      }),
      new TextboxField({
        required: true,
        key: 'beginDate',
        label: 'EFFECTIVE DATE FROM',
        type: 'Date',
        isChangeEvent: true,
        order: 10
      }),
      new TextboxField({
        key: 'endDate',
        label: 'EFFECTIVE DATE TO',
        type: 'Date',
        order: 11
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  private populateList(listData) {
    const arr = [];
    listData.forEach(element => {
      arr.push({ key: element, value: element });
    });
    return arr;
  }
}
