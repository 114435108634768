import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AzureLoginService } from '../services/azure-login.service';
import { RolePermissionInterface } from '../interfaces/role-permission-directive.interface';
@Directive({
  selector: '[appSetRolePermissionsDirective]',
})
export class SetRolePermissionsDirective implements OnInit {
  @Input() appSetRolePermissionsDirective: RolePermissionInterface;
  hasAllPermission = false;
  isAlreadyDisplayed = false;
  constructor(
    private templateRef: TemplateRef<unknown>,
    private vcr: ViewContainerRef,
    private azureService: AzureLoginService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    /* tslint:disable:no-string-literal */
    if (this.appSetRolePermissionsDirective) {
      if (!this.appSetRolePermissionsDirective?.operation) {
        this.appSetRolePermissionsDirective.operation = '';
      }
    }
    if (this.appSetRolePermissionsDirective?.operation === 'MULTIPLE') {
      this.appSetRolePermissionsDirective.data.forEach((item) => {
        const ocur = this.azureService.rolePermissions.filter(
          (e) =>
            e.permissionDescription === item.permission && e.permissionCategory === item.category,
        );
        this.hasAllPermission = false;
        this.setPermissions(ocur, item);
      });
    } else {
      const ocur = this.azureService.rolePermissions.filter(
        (e) =>
          e.permissionDescription === this.appSetRolePermissionsDirective?.permission &&
          e.permissionCategory === this.appSetRolePermissionsDirective.category,
      );
      this.setPermissions(ocur, this.appSetRolePermissionsDirective);
    }
  }
  setPermissions(permissions, permissionItem) {
    if (
      permissions.findIndex((e) => e?.operationName === 'ALL') !== -1 ||
      permissionItem?.category === undefined
    ) {
      this.hasAllPermission = true;
    }

    if (permissionItem?.operation) {
      /* logic for ALL" */
      switch (permissionItem?.operation) {
        case 'ADD':
          this.evaluateAdd(permissions, permissionItem);
          break;
        case 'READ_ONLY':
          this.evaluateReadOnly(permissions);
          break;
        case 'EDIT-DELETE':
          this.evaluateEditDelete(permissions);
          break;
        case 'READ-DELETE':
          this.evaluateReadDelete(permissions);
          break;
        case 'DELETE':
          this.evaluateDelete(permissions, permissionItem);
          break;
        case 'PRINT':
          this.evaluateEllipsisCustom(permissions, 'READ_ONLY', 'print', false);
          break;
        case 'CANCEL':
          this.evaluateEllipsisCustom(permissions, 'EDIT', 'cancel', true);
          break;
        case 'EXPORT':
          this.evaluateEllipsisCustom(permissions, 'EDIT', 'export', false);
          break;
        default:
          this.evaluateEllipsisCustom(permissions, 'EDIT', permissionItem.operation, false);
          break;
      }
    }
  }
  /**
   * This Method evaluates when the permissions is for add in case for a button or three dots.
   * remove the element in case the permission is missing or
   * if the three dots has more options only remove the add one
   * @param permissions permissions filtered by category and description
   */
  evaluateAdd(permissions, permissionItem) {
    const canAdd =
      permissions.findIndex((e) => e.operationName === 'ADD') !== -1 || this.hasAllPermission;
    if (permissionItem.type === 'button') {
      if (canAdd) {
        this.displayItem();
      }
    } else if (permissionItem.type === 'optionRow') {
      if (!canAdd) {
        const ellipsis = this.vcr['_view']?.component?.ellipsisOptions;
        if (ellipsis.length > 1) {
          const index = ellipsis.findIndex((e) => e.label.toLowerCase().includes('create'));
          if (index !== -1) {
            ellipsis.splice(index, 1);
          }
          this.displayItem();
        }
      } else {
        this.displayItem();
      }
    } else {
      if (!canAdd) {
        const ellipsisHeader = this.vcr['_view']?.component?.ellipsisHeaderOptions;
        if (ellipsisHeader.length > 1) {
          let add = true;
          while (add) {
            const index = ellipsisHeader.findIndex((e) => e.label.toLowerCase().includes('add'));
            if (index !== -1) {
              ellipsisHeader.splice(index, 1);
            } else {
              add = false;
            }
          }
          if (ellipsisHeader.length > 1) {
            this.displayItem();
          }
        }
      } else {
        this.displayItem();
      }
    }
  }
  /**
   * This Method evaluates when the permissions are for Edit or deletes,
   * it validates if has both permissions before altering the dom
   * if the permissions are there don't alter the dom but in case one of the permissions is missing
   * it removes in the ellipsis the corresponding element
   * @param permissions permissions filtered
   */
  evaluateEditDelete(permissions) {
    let ellipsisOptions = this.vcr['_view']?.component?.ellipsisOptions;
    if (ellipsisOptions === undefined) {
      ellipsisOptions = this.vcr['_view']?.component?.ellipsisRowOptions;
    }
    const canDelete =
      permissions.findIndex((e) => e.operationName === 'DELETE') !== -1 || this.hasAllPermission;
    const canEdit =
      permissions.findIndex((e) => e.operationName === 'EDIT') !== -1 || this.hasAllPermission;
    if (!canDelete || !canEdit) {
      if (!ellipsisOptions) {
        this.displayItem();
        return;
      }
      if (!canEdit) {
        const idEdit =
          ellipsisOptions &&
          ellipsisOptions.findIndex(
            (e) =>
              e.value.toUpperCase().includes('EDIT') || e.value.toUpperCase().includes('UPDATE'),
          );
        if (idEdit !== -1) {
          ellipsisOptions.splice(idEdit, 1);
        }
      }
      if (!canDelete) {
        const idDelete = ellipsisOptions?.findIndex(
          (e) =>
            e.value.toUpperCase().includes('DELETE') || e.value.toUpperCase().includes('REMOVE'),
        );
        if (idDelete !== -1) {
          ellipsisOptions?.splice(idDelete, 1);
        }
      }
      if (ellipsisOptions?.length > 0) {
        this.displayItem();
      } else {
        const el: HTMLElement = this.elementRef.nativeElement;
        el.parentNode.removeChild(el);
      }
    } else {
      this.displayItem();
    }
  }
  /**
   * This Method evaluates when the permissions are for read or deletes,
   * it validates if has both permissions before altering the dom
   * if the permissionsare there don't alter the dom but in case one of the permissions is missing
   * it removes in the ellipsis the corresponding element
   * @param permissions permissions filtered by category and description
   */
  evaluateReadDelete(permissions) {
    const ellipsisOptions = this.vcr['_view']?.component?.ellipsisOptions;
    const canRead =
      permissions.findIndex((e) => e.operationName === 'READ_ONLY') !== -1 || this.hasAllPermission;
    const canDelete =
      permissions.findIndex((e) => e.operationName === 'DELETE') !== -1 || this.hasAllPermission;
    if (!canDelete || !canRead) {
      if (!ellipsisOptions) {
        this.displayItem();
        return;
      }
      if (!canRead) {
        const idEdit =
          ellipsisOptions &&
          ellipsisOptions.findIndex((e) => e.label.toUpperCase().includes('VIEW'));
        if (idEdit !== -1) {
          ellipsisOptions.splice(idEdit, 1);
        }
      }
      if (!canDelete) {
        const idDelete =
          ellipsisOptions &&
          ellipsisOptions.findIndex((e) => e.label.toUpperCase().includes('DELETE'));
        if (idDelete !== -1) {
          ellipsisOptions.splice(idDelete, 1);
        }
      }
      if (ellipsisOptions.length > 0) {
        this.displayItem();
      } else {
        const el: HTMLElement = this.elementRef.nativeElement;
        el.parentNode.removeChild(el);
      }
    } else {
      this.displayItem();
    }
  }
  /**
   * This Method evaluates when the permissions are for reading only
   * if the permission exists displayed in dom else remove the item
   * @param permissions permissions filtered by category and description
   */
  evaluateReadOnly(permissions) {
    const hasPermissions = permissions.findIndex((e) => e.operationName === 'READ_ONLY') !== -1;
    if (hasPermissions || this.hasAllPermission) {
      this.displayItem();
    }
  }
  /**
   * For the items who only contains delete operation hide if is missing the permission
   * else display the element
   * @param permissions permissions filtered by category and description
   */
  evaluateDelete(permissions, permissionItem) {
    const canDelete =
      permissions.findIndex((e) => e.operationName === 'DELETE') !== -1 || this.hasAllPermission;
    if (permissionItem.type === 'three-dots') {
      if (!canDelete) {
        const ellipsisHeader = this.vcr['_view']?.component?.ellipsisHeaderOptions;
        if (ellipsisHeader) {
          this.displayItem();
          return;
        }
        if (ellipsisHeader) {
          const idDelete = ellipsisHeader.findIndex(
            (e) =>
              e.label.toUpperCase().includes('DELETE') ||
              e.label.toUpperCase().includes('UN-ASSOCIATE'),
          );
          if (idDelete !== -1) {
            if (ellipsisHeader) {
              ellipsisHeader.splice(idDelete, 1);
            }
          }
          if (ellipsisHeader.length > 1) {
            this.displayItem();
          }
        }
      } else {
        this.displayItem();
      }
    } else {
      if (canDelete) {
        this.displayItem();
      }
    }
  }
  /**
   * Single case for export is included if the permissions exist keep the custom element else remove it
   * @param permissions permissions filtered by category and description
   */
  evaluateEllipsisCustom(permissions, operationToDo, ellipsisCase, isHeader) {
    const canDisplay =
      permissions.findIndex((e) => e.operationName === operationToDo) !== -1 ||
      this.hasAllPermission;
    if (isHeader) {
      if (canDisplay) {
        this.displayItem();
        return;
      }
      const ellipsisHeader = this.vcr['_view']?.component?.ellipsisHeaderOptions;
      if (ellipsisHeader && ellipsisHeader.length > 1) {
        const index =
          ellipsisHeader &&
          ellipsisHeader.findIndex((e) => e.label.toLowerCase().includes(ellipsisCase));
        if (index !== -1) {
          ellipsisHeader.splice(index, 1);
        }
        this.displayItem();
      }
    } else {
      let ellipsisOptions = this.vcr['_view']?.component?.ellipsisOptions;
      if (ellipsisOptions === undefined) {
        ellipsisOptions = this.vcr['_view']?.component?.ellipsisRowOptions;
      }
      if (canDisplay) {
        this.displayItem();
        return;
      }
      const idDisplay =
        ellipsisOptions &&
        ellipsisOptions.findIndex((e) =>
          e.value.toLowerCase().includes(ellipsisCase.toLowerCase()),
        );
      if (idDisplay !== -1 && ellipsisOptions) {
        ellipsisOptions.splice(idDisplay, 1);
      }
      if (ellipsisOptions && ellipsisOptions.length > 0) {
        this.displayItem();
      }
    }
  }
  displayItem() {
    if (this.isAlreadyDisplayed) {
      return;
    }
    this.vcr.createEmbeddedView(this.templateRef);
    this.isAlreadyDisplayed = true;
  }
}
