import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SearchServiceOrderService } from './search-service-order.service';
import { buttonStatus, getStatusClass } from '../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../../shared/constants/ellipsis-options';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../../services/tabs.service';
import { Tab } from '../../../services/tab';
import { Subscription } from 'rxjs';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { generalDateFormatter } from '../../../shared/utilities/date-utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { copy } from '../../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'search-service-order',
  templateUrl: 'search-service-order.template.html',
  styleUrls: ['../../../shared/styles/crm-table.scss'],
  providers: [SearchServiceOrderService],
})
export class SearchServiceOrderComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  status: any[];
  newTab: Tab;
  op: OverlayPanel;
  querySubscription: Subscription;
  isLoading: boolean;
  getStatusClass: any = getStatusClass;
  authDetails: IRoleAuthorizationService;
  offsetLimit = 10;
  lastOffsetVal = 0;
  resultCount = 0;
  disableLoadMore = false;
  selectedColumns: any[];
  isDirectOpenInfo = null;
  urlSubscriptions: Subscription;
  title: string = 'Search Order Results ( ' + this.resultCount + ' item(s) found)';
  constructor(
    private searchServiceOrderService: SearchServiceOrderService,
    private tabService: TabsService,
    private roleService: RoleAuthorizationService,
    private azureService: AzureLoginService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.urlSubscriptions = this.activatedRoute.queryParams.subscribe((params) => {
      if (Object.keys(params).length) {
        this.isDirectOpenInfo = {};
        this.isDirectOpenInfo = params;
      }
      this.router.navigate([], {
        queryParams: null,
        replaceUrl: true,
        relativeTo: this.activatedRoute,
      });
    });
    this.dataList = [];
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SearchServiceOrderComponent,
      generalArray: this.ellipsisOptions,
    };
    this.ellipsisOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.editConstant = uuidv4();
    this.status = [
      { label: 'Active', value: 'Active' },
      { label: 'Terminated', value: 'Terminated' },
    ];
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'claimNumber', header: 'CLAIM #', type: 'text' },
      { field: 'serviceOrderNumber', header: 'Service Order #', type: 'text' },
      { field: 'contractNumber', header: 'CONTRACT#', type: 'text' },
      { field: 'uniqueId', header: 'UNIQUE ID', type: 'text' },
      { field: 'invoiceNumber', header: 'INVOICE#', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE NUMBER', type: 'text' },
      { field: 'contactFirstName', header: 'CONTACT FIRST NAME', type: 'text' },
      { field: 'contactLastName', header: 'CONTACT LAST NAME', type: 'text' },
      { field: 'dispatchDate', header: 'DISPATCH DATE', type: 'text' },
      { field: 'servicer', header: 'SERVICER NAME/PROVIDER', type: 'text' },
      { field: 'checkNumber', header: 'CHECK #', type: 'text' },
      { field: 'checkAmount', header: 'CHECK AMOUNT', type: 'text' },
      { field: 'insuranceCarrier', header: 'INSURANCE CARRIER', type: 'text' },
      { field: 'preApprovedDate', header: 'PRE-APPROVED DATE', type: 'text' },
      { field: 'claimPriorityCode', header: 'CLAIM PRIO CODE', type: 'text' },
      { field: 'claimDate', header: 'CLAIM START DATE', type: 'text' },
      { field: 'dateOfRepair', header: 'DATE OF REPAIR', type: 'text' },
      { field: 'submitForPaymentDate', header: 'SUBMIT FOR PAYMENT DATE', type: 'text' },
      { field: 'approvedForPaymentDate', header: 'APPROVED FOR PAYMENT DATE', type: 'text' },
      { field: 'manufacturer', header: 'MANUFACTURER', type: 'text' },
      { field: 'modelNumber', header: 'MODEL NUMBER', type: 'text' },
      { field: 'customerFeedback', header: 'CUST FEEDBACK', type: 'text' },
      { field: 'authorizationCode', header: 'AUTH CODE', type: 'text' },
      { field: 'technicianName', header: 'TECH NAME', type: 'text' },
      { field: 'assignedTo', header: 'ASSIGNED TO', type: 'text' },
      { field: 'claimPaymentDate', header: 'CLAIM PAYMENT DATE', type: 'text' },
      { field: 'createdBy', header: 'CREATED BY', type: 'text' },
    ];
    this.selectedColumns = this.cols.slice(0, 12);

    if (this.isDirectOpenInfo) {
      this.searchParams = Object.assign(
        this.searchParams ? this.searchParams : {},
        this.isDirectOpenInfo,
      );
    }
    this.loadServiceOrders();

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Claim',
      category: 'Claim',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Claim',
      category: 'Claim',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.hide();
    setTimeout(() => {
      event.preventDefault();
      this.selectedItem = item;
      overlaypanel.toggle(event);
    }, 500);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    this.newTab = { header: 'Service Order', content: this.selectedItem, type: 'Service Order' };
    this.tabService.createTab(this.newTab);
    overlaypanel.hide();
  }
  openTab(data: any) {
    this.newTab = {
      header: `Service Order [ ${data && data.serviceOrderNumber} ]`,
      content: data,
      type: 'Service Order',
    };
    this.tabService.createTab(this.newTab);
  }
  openContract(data: any) {
    // For the dashboard, it has contractsId from ClaimHistory, and no contractsIdOriginal.
    const searchId = data.contractsIdOriginal ? data.contractsIdOriginal : data.contractId;
    const tabContent = {
      originalId: searchId,
      customerIdOriginal: data.customerId,
      uniqueId: data.uniqueId,
    };
    this.newTab = {
      header: `Contract [ ${data && data.contractNumber} ]`,
      content: tabContent,
      type: 'Contract',
    };
    this.tabService.createTab(this.newTab);
  }

  loadServiceOrders() {
    this.isLoading = true;
    this.searchParams = this.searchParams
      ? this.searchParams
      : { status: { key: 'APPROVED', value: 'APPROVED' } };
    const searchQuery = Object.assign(
      {},
      this.searchParams,
      {
        limit: this.offsetLimit,
        offset: this.lastOffsetVal,
        azureUserId: this.azureService.accountId,
      },
    );


    this.lastOffsetVal += this.offsetLimit;
    this.querySubscription = this.searchServiceOrderService
      .getServiceOrderSearchResult(
        searchQuery,
        searchQuery.offset,
        searchQuery.limit,
      )
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifedData = this.searchParams.fromDashboard
            ? data.getDashboardRecordDetails
            : data.getClaimsSearchResults.map((item) => {
                return { ...item };
              });
          if (modifedData.length) {
            if (
              this.isDirectOpenInfo &&
              Object.keys(this.isDirectOpenInfo).length > 0 &&
              (modifedData.length === 1 ||
                (this.searchParams.claimNumber &&
                  modifedData.filter((el) => el.claimNumber !== this.searchParams.claimNumber)
                    .length === 0))
            ) {
              this.isDirectOpenInfo = null;
              this.searchParams = null;
              this.urlSubscriptions.unsubscribe();
              this.tabService.createTab({
                header: 'Service Order',
                content: modifedData[0],
                type: 'Service Order',
              });
            } else {
              this.disableLoadMore =
                Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
              const formattedData = this.setDateFormat(modifedData);
              this.dataList = !!this.dataList
                ? [...this.dataList, ...formattedData]
                : [...formattedData];
              this.resultCount = this.dataList.length;
            }
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  setDateFormat(data: any[]) {
    const dataList = data.map((e) => {
      const d = Object.assign({}, e);
      d.preApprovedDate = generalDateFormatter(d.preApprovedDate);
      d.claimDate = generalDateFormatter(d.claimDate);
      d.dateOfRepair = generalDateFormatter(d.dateOfRepair);
      d.submitForPaymentDate = generalDateFormatter(d.submitForPaymentDate);
      d.approvedForPaymentDate = generalDateFormatter(d.approvedForPaymentDate);
      d.claimPaymentDate = generalDateFormatter(d.claimPaymentDate);
      d.dispatchDate = generalDateFormatter(d.dispatchDate);
      return { ...d };
    });
    return dataList;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
