import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormField } from '../form-field/form-field';
import { FormCanDeactivate } from '../form-field/form-can-deactivate';

@Component({
  selector: 'crm-form-modal',
  templateUrl: 'form-modal.template.html',
  styleUrls: ['form-modal.scss'],
  providers: [FormFieldControlService],
})
export class CRMFormModalComponent extends FormCanDeactivate implements OnInit {
  private privateDisplayDialog: boolean;

  @Input() modalTitle: string;
  @Input() formFields: FormField<string>[] = [];
  @Input() submitButtonlabel = 'Add';
  @Input() cancelButtonlabel = 'Cancel';
  @Input() singleRow = false;
  @Input() formData = null;
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  form: FormGroup;

  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }

  constructor(private qcs: FormFieldControlService) {
    super();
  }
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.formData) {
      this.form.patchValue({
        ...this.formData,
      });
    }
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
  }
  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.formSubmitEvent.emit(this.form);
  }
  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
  }
}
