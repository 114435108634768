import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from './form-field';

import Quill from 'quill';

@Component({
  selector: 'form-field',
  templateUrl: 'generic-form-field.template.html',
  styleUrls: ['generic-form-field.scss'],
})
export class GenericFormFieldComponent implements OnInit {
  @Input() formField: FormField<string>;
  @Input() form: FormGroup;
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();
  currentDate = new Date();
  yearRange = `2000:${this.currentDate.getFullYear().toString()}`;
  keyword: any;
  suggestions = [];
  constructor() {}
  ngOnInit() {
    this.keyword = this.formField.keyword || 'key';
  }
  get isValid() {
    if (this.formField.type === 'checkbox' || this.formField.type === 'radio-button') {
      return true;
    }
    return this.form.controls[this.formField.key].disabled
      ? true
      : this.form.controls[this.formField.key].valid ||
          this.form.controls[this.formField.key].pristine;
  }

  changeEventDetection(event) {
    this.changeEvent.emit(event);
  }

  onChangeSearch(val: string) {
    if (val.length >= 2 && this.formField.suggestions) {
      this.suggestions = this.formField.suggestions.filter((item) =>
        String(item[this.keyword]).toLowerCase().includes(val.toLowerCase()),
      );
    } else {
      this.suggestions = [];
    }
  }

  getErrorMessages(errorMessages = [], key) {
    if (errorMessages && errorMessages.length > 0 && key) {
      return errorMessages.find((x) => x.key === key).value;
    }
    return null;
  }
}
