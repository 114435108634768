import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { Subject } from 'rxjs';
import { createUpdateInsuranceCompanyQuery, insuranceCompanyQuery } from '../coverage-data-queries.constant';

@Injectable()
export class InsuranceCompanyService {
  constructor(private apollo: Apollo) {}

  /**
   * Properties start
   */
  public insuranceCompanyConfig = {
    searchParams: null
  };

  public insuranceCompanyDataModel = {
    leftBaseSection: null,
    mainTab: {
      contactInformation: null,
      mailingAddress: null,
      billingAddress: null
    },
    account: {
      arAccount: null,
      apAccount: null,
      glAccount: null
    }
  };
  /**
   * Properties end
   */

  /**
   * Subscriber subjects start
   */
  public isDataAvailable = new Subject<boolean>();
  public setDataAvailability(value) {
    this.isDataAvailable.next(value);
  }

  /**
   * subscriber subjects end
   */

  /**
   * Methods start
   */
  getInsuranceCompanyData = (id, insuranceNumber = null) =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: insuranceCompanyQuery,
      variables: { id, number: insuranceNumber },
      //context: { queryDeduplication: false },
      fetchPolicy : 'no-cache'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateInsuranceCompany = formData => {
    const addressMailing = {
      ...this.insuranceCompanyDataModel.mainTab.mailingAddress,
      isMailingAddress: true,
      country: this.insuranceCompanyDataModel?.mainTab?.mailingAddress?.country?.key ?? 'United States'
    };
    const addressBilling = {
      ...this.insuranceCompanyDataModel.mainTab.billingAddress,
      isBillingAddress: true,
      country: this.insuranceCompanyDataModel?.mainTab?.billingAddress?.country?.key ?? 'United States'
    };
    const contactinfo = this.insuranceCompanyDataModel.mainTab.contactInformation;
    contactinfo.businessPhoneTypeCode =
      contactinfo.businessPhoneTypeCode && contactinfo.businessPhoneTypeCode.value
        ? contactinfo.businessPhoneTypeCode.value
        : null;

    delete addressMailing.__typename;
    delete addressBilling.__typename;
    delete contactinfo.__typename;
    delete addressBilling.valid;
    delete addressMailing.valid;
    delete contactinfo.valid;
    delete contactinfo.categoryType;

    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateInsuranceCompanyQuery,
      variables: {
        insuranceComapaniesIdOriginal: formData.insuranceComapaniesIdOriginal,
        status: formData.status.value || 'A',
        name: formData.name,
        number: formData.number,
        companyType: !isNaN(formData.companyType?.value)? parseInt(formData.companyType?.value, 10): null,
        fedTaxId: formData.fedTaxId,
        billingMethod: formData.billingMethod.value || null,
        pendingBillingTransaction: formData.pendingBillingTransaction.value || null,
        currency: formData.currency.value || null,
        showOnRemittance: formData.showOnRemittance.value || null,
        includeVoidContractOnRemittance: formData.includeVoidContractOnRemittance.value || null,
        activateContractUponRemittance: formData.activateContractUponRemittance.value || null,
        externalId: formData.externalId,
        arAccount: this.insuranceCompanyDataModel.account.arAccount,
        apAccount: this.insuranceCompanyDataModel.account.apAccount,
        glAccount: this.insuranceCompanyDataModel.account.glAccount,
        addresses: [addressMailing, addressBilling],
        contactInfo: contactinfo,
        insertUserName: formData.insertUserName
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };
}
