<div class="main-tab">
  <app-section-header [title]="'Service Details'" />
  <technical-information *ngIf="componentDisplay.TechnicalInformationComponent" [formFields]="techformFields">
  </technical-information>
  <problem-report *ngIf="componentDisplay.ProblemReportComponent"></problem-report>
  <expense-charges *ngIf="componentDisplay.ExpenseChargesComponent"></expense-charges>
  <div *ngIf="componentDisplay.GeneratePreAuthComponent">
    <generate-pre-auth
      *appSetRolePermissionsDirective="{type:'div', operation:'READ_ONLY', permission:'Pre-Authorization', category: 'Claim'}"
      [formFields]="preAuthformFields">
    </generate-pre-auth>
  </div>
</div>