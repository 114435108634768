import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { BuyingGroupService } from '../../services/buying-group.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { buyingGroupFormEllipsisOptions } from '../constants/buying-group-ellipsis';
import { BuyingGroupDataService } from '../buying-group.service';
import { dateSubstring } from '../../shared/utilities/date-utilities';
import { BuyingGroupDisplayStatus } from '../constants/buying-group-constant';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Component({
  selector: 'buying-group-details-form',
  templateUrl: 'buying-group-details-form.template.html',
  styleUrls: ['buying-group-details-form.scss'],
  providers: [FormFieldControlService, BuyingGroupService, MessageService],
})
export class BuyingGroupDetailsFormComponent
  extends FormCanDeactivate
  implements OnInit, OnDestroy
{
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  selectedItem: any;
  btnStatus: any = buttonStatus;
  options: any[] = buyingGroupFormEllipsisOptions;
  isLoading: any;
  isMainTabDataAvailable: boolean;
  querySubscription: any;
  isSuccess = false;
  isFailed = false;

  constructor(
    private service: BuyingGroupService,
    private qcs: FormFieldControlService,
    private buyingGroupDataService: BuyingGroupDataService,
    private dropdownDataService: DropdownDataService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
  ) {
    super();
    this.service.getBuyingGroupDetailsFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });

    if (
      this.buyingGroupDataService.buyingGroupConfig.searchParams &&
      this.buyingGroupDataService.buyingGroupConfig.searchParams.id
    ) {
      this.isLoading = true;
      this.getBuyingGroup(this.buyingGroupDataService.buyingGroupConfig.searchParams.id);
    }
  }

  getPriceTableNameOption(priceTableNameList) {
    if (Array.isArray(priceTableNameList)) {
      this.formFields.find((el) => el.key === 'priceTableName').options = priceTableNameList.map(
        (el) => ({
          key: el,
          value: el,
        }),
      );
    }
  }

  onSubmit() {
    window.scroll(0, 0);
    this.payLoad = {
      ...this.form.getRawValue(),
      agentIdOriginal: this.buyingGroupDataService.buyingGroupFormData
        ? this.buyingGroupDataService.buyingGroupFormData.agentIdOriginal
        : null,
      valid: this.form.valid,
      insertUserName: this.azureService.accountId,
    };
    this.formValueChanged.emit(this.form.dirty);
    if (this.buyingGroupDataService.formValidate(this.payLoad)) {
      this.isLoading = true;
      this.querySubscription = this.buyingGroupDataService
        .createUpdateBuyingGroup(this.payLoad)

        .subscribe(
          ({ data, isLoading }: any) => {
            if (data.createUpdateBuyingGroup && data.createUpdateBuyingGroup.agentIdOriginal) {
              this.getBuyingGroup(data.createUpdateBuyingGroup.agentIdOriginal);
            }
            this.isSuccess = true;
            this.isLoading = isLoading;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Record Saved Successfully.`,
            });
            setTimeout(() => {
              this.isSuccess = false;
            }, 3000);
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    } else {
      this.isFailed = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please fill the required fields.`,
      });
      setTimeout(() => {
        this.isFailed = false;
      }, 5000);
    }
  }

  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  onChangeEvent(event, key) {
    if (key === 'number' && event.target.value && event.target.value.length > 0) {
      this.isLoading = true;
      this.buyingGroupDataService.getBuyingGroupProfileById(null, event.target.value).subscribe(
        ({ data, loading }: any) => {
          if (data.getBuyingGroupProfileById) {
            this.form.controls.number.setErrors({ key: 'duplicateBGNumber' });
          }
          this.isLoading = loading;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }

  getBuyingGroup(id) {
    this.querySubscription = this.buyingGroupDataService.getBuyingGroupProfileById(id).subscribe(
      ({ data, loading }: any) => {
        const modifiedData = data.getBuyingGroupProfileById;
        this.buyingGroupDataService.buyingGroupFormData = data.getBuyingGroupProfileById;
        this.buyingGroupDataService.isAgentIdOriginalAvailable.next(true);
        const masterBuyingGroup = modifiedData.masterBuyingGroupId
          ? this.dropdownDataService.masterBuyingGroupData.find(
              (x) =>
                x.masterBuyingGroupIDOriginal === parseInt(modifiedData.masterBuyingGroupId, 10),
            )
          : null;
        this.formFields = this.formFields.map((formField) => {
          if (formField.key === 'number') {
            formField = { ...formField, disabled: true };
          }
          return formField;
        });
        this.form = this.qcs.toFormGroup(this.formFields);
        this.form.patchValue({
          status: {
            key: BuyingGroupDisplayStatus[modifiedData.status],
            value: modifiedData.status,
          },
          masterBuyingGroup: {
            key: masterBuyingGroup ? masterBuyingGroup.name : null,
            value: masterBuyingGroup ? masterBuyingGroup.masterBuyingGroupIDOriginal : null,
          },
          number: modifiedData.number,
          name: modifiedData.name,
          fedTaxId: modifiedData.fedTaxId,
          sProfileName: modifiedData.sProfileName,
          activationDate: dateSubstring(modifiedData.activationDate),
          expirationDate: dateSubstring(modifiedData.expirationDate),
          priceTableName: {
            key: modifiedData.priceTableName,
            value: modifiedData.priceTableName,
          },
        });

        if (modifiedData.number != null) {
          this.service
            .getElegiblePriceTables({
              number: modifiedData.number,
              groupType: 'BuyingGroup',
            })
            .subscribe(
              // tslint:disable-next-line: no-shadowed-variable
              ({ data }) => {
                if (data.getElegiblePriceTables) {
                  this.getPriceTableNameOption(data.getElegiblePriceTables);
                }
                this.isLoading = false;
              },
              (err) => {
                this.isLoading = false;
                throw err;
              },
            );
        } else {
          this.isLoading = false;
        }
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
