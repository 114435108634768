import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import { BehaviorSubject, Subject } from 'rxjs';

const dealerDetailsFormQuery = gql`
  query($id: Int, $number: String) {
    getDealerProfileById(id: $id, number: $number) {
      dealerGroupsId
      dealerGroupStatus
      dealerGroupNumber
      dealerGroupName
      fedTaxId
      contractDate
      contractExpireDate
      accountingNumber
      salesRepresentative
      dealerCatalogNumber
      dealerGroupsIdOriginal
      agentId
      csmCode
      insuranceCarrierCode
      insuranceCarrierName
      dispatch
      billingCycle
      regionCode
      priceTable
      followUp
      isExcludedForCancellation
      freeLookUpPeriod
      insuranceOverride
      salesChannel
      jsyCheckBox
      tSelfServicing
      addresses(categoryType: DealersGroup) {
        addressIDOriginal
        country
        state
        city
        zipCode
        address1
        address2
        isMailingAddress
        isBillingAddress
      }
      contactInfo(categoryType: DealersGroup) {
        contactIdOriginal
        firstName
        lastName
        businessPhone
        email
        fax
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
      general1
      general2
      general3
      general4
      priceTableName
    }
  }
`;

const createDealerQuery = gql`
  mutation(
    $dealerGroupNumber: String
    $dealerGroupName: String
    $dealerGroupStatus: String
    $dealerGroupsIdOriginal: Int
    $accountingNumber: String
    $dealerCatalogNumber: String
    $agentId: Int
    $addresses: [AddressInputType]
    $contactInfo: ContactInputType
    $general1: String
    $general2: String
    $general3: String
    $general4: String
    $salesRepresentative: String
    $contractExpireDate: Date
    $contractDate: Date
    $followUp: String
    $isExcludedForCancellation: Boolean
    $freeLookUpPeriod: Int
    $dealerDispatch: String
    $tSelfServicing: Boolean
    $priceTableName: String
    $insertUserName: String
    $csmCode: String
  ) {
    createUpdateDealerProfile(
      dealerProfileInput: {
        dealerGroupNumber: $dealerGroupNumber
        dealerGroupName: $dealerGroupName
        dealerGroupStatus: $dealerGroupStatus
        statusDesc: $dealerGroupStatus
        agentId: $agentId
        general1: $general1
        general2: $general2
        general3: $general3
        general4: $general4
        salesRepresentative: $salesRepresentative
        dealerGroupsIdOriginal: $dealerGroupsIdOriginal
        accountingNumber: $accountingNumber
        dealerCatalogNumber: $dealerCatalogNumber
        addresses: $addresses
        contactInfo: $contactInfo
        contractExpireDate: $contractExpireDate
        contractDate: $contractDate
        followUp: $followUp
        isExcludedForCancellation: $isExcludedForCancellation
        freeLookUpPeriod: $freeLookUpPeriod
        dealerDispatch: $dealerDispatch
        tSelfServicing: $tSelfServicing
        priceTableName: $priceTableName
        insertUserName: $insertUserName
        csmCode: $csmCode
      }
    ) {
      dealerGroupNumber
      dealerGroupName
      dealerGroupStatus
      dealerGroupsId
      dealerGroupsIdOriginal
      general1
      salesRepresentative
    }
  }
`;

const getDealerGroupContactInfoQuery = gql`
  query($dealerGroupId: Int!) {
    getDealerGroupContactInfo(dealerGroupId: $dealerGroupId) {
      contactId
      contactIdOriginal
      firstName
      lastName
      contactType
      position
      phoneNumber1
      cellphone
      businessPhone
      email
      fax
      phoneTypeCode1
      phoneTypeCode2
      businessPhoneTypeCode
    }
  }
`;

@Injectable()
export class DealerService {
  constructor(private apollo: Apollo) {}
  public dealerFormData: any;
  public contactFormData: any;
  public mailingAddress: any;
  public billingAddress: any;
  public generalFormData: any;
  public contactFormDataFinal: any;
  public mailingAddressFinal: any;
  public billingAddressFinal: any;
  public generalFormDataFinal: any;
  public dealerGroupsIdOriginal: any;

  /* Subscriber Subject Start */
  public isdealerGroupsIdAvailable = new BehaviorSubject(false);
  /* Subscriber Subject End   */

  public dealerConfig = {
    searchParams: null
  };
  fetchPolicy: 'network-only';
  getDealerDetailsForm = (id, dealerGroupNumber = null) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: dealerDetailsFormQuery,
      variables: { id, number: dealerGroupNumber },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createDealerProfile = formData => {
    const addressMailing = {
      ...this.mailingAddress,
      ...this.mailingAddressFinal,
      isMailingAddress: true,
      country:
        this.mailingAddressFinal.country.key && this.mailingAddressFinal.country.key !== null
          ? this.mailingAddressFinal.country.key
          : 'United States'
    };
    const addressBilling = {
      ...this.billingAddress,
      ...this.billingAddressFinal,
      isBillingAddress: true,
      country:
        this.billingAddressFinal.country.key && this.billingAddressFinal.country.key !== null
          ? this.billingAddressFinal.country.key
          : 'United States'
    };

    const contactinfo = { ...this.contactFormData, ...this.contactFormDataFinal };
    contactinfo.businessPhoneTypeCode =
      contactinfo.businessPhoneTypeCode && contactinfo.businessPhoneTypeCode.value
        ? contactinfo.businessPhoneTypeCode.value
        : null;
    const generalInfo = { ...this.generalFormData, ...this.generalFormDataFinal };

    delete addressMailing.__typename;
    delete addressBilling.__typename;
    delete contactinfo.__typename;
    delete generalInfo.__typeame;
    delete addressBilling.valid;
    delete addressMailing.valid;
    delete contactinfo.valid;
    return this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createDealerQuery,
      variables: {
        dealerGroupNumber: formData.dealerGroupNumber,
        dealerGroupName: formData.dealerGroupName,
        dealerGroupStatus: formData.dealerGroupStatus.value || 'Active',
        agentId: formData.buyingGroup.value || null,
        dealerGroupsIdOriginal: formData.dealerGroupsIdOriginal,
        accountingNumber: formData.accountingNumber,
        dealerCatalogNumber: formData.dealerCatalogNumber,
        salesRepresentative: formData.salesRepresentative
          ? formData.salesRepresentative.value
          : null,
        contractDate: formData.contractDate,
        contractExpireDate: formData.contractExpireDate,
        followUp: formData.followUp.value || null,
        dealerDispatch: formData.dealerDispatch.value || null,
        tSelfServicing: formData.tSelfServicing && formData.tSelfServicing.length ? true : false,
        isExcludedForCancellation:
          formData.isExcludedForCancellation && formData.isExcludedForCancellation.length
            ? true
            : false,
        freeLookUpPeriod: formData.freeLookUpPeriod.value || null,
        addresses: [addressMailing, addressBilling],
        contactInfo: contactinfo,
        ...generalInfo,
        priceTableName: formData.priceTable ? formData.priceTable.value : null,
        insertUserName: formData.insertUserName,
        csmCode: formData.csmCode
      }
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };

  getDealerGroupContactInfo = data =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getDealerGroupContactInfoQuery,
      variables: { ...data },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
}
