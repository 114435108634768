import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { CrmGraphql } from 'src/app/modules/crm/shared/constants/graphql-constants';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { generalDateFormatter } from 'src/app/modules/crm/shared/utilities/date-utilities';
import {
  getValueByKey,
  sanitizeObject
} from 'src/app/modules/crm/shared/utilities/common-utilities';
import { getKeyByValue } from 'src/app/modules/crm/shared/utilities/common-utilities';

// TODO : Replace  list with config values from db
export const ServiceBenchJobStatus = [
  { key: 'NEW', value: 'New' },
  { key: 'ACC', value: 'Accepted' },
  { key: 'CAN', value: 'Cancelled' },
  { key: 'CMP', value: 'Completed' },
  { key: 'EXP', value: 'Expired' },
  { key: 'REC', value: 'Received' },
  { key: 'REJ', value: 'Rejected' }
];

// TODO : Replace  list with config values from db
export const ServiceBenchJobSubStatus = [
  { key: 'AAA', value: 'Advance Exchange – Complete' },
  { key: 'AAB', value: 'Advance Exchange – In Transit from Customer' },
  { key: 'AAC', value: 'Advance Exchange – In Transit to Customer' },
  { key: 'AAD', value: 'Advance Exchange – Preparing to Ship' },
  { key: 'AAE', value: 'Advance Exchange – Product on Backorder' },
  { key: 'AWT', value: 'Call Complete – Awaiting Payment' },
  { key: 'RTC', value: 'Call Complete – Ready to Claim' },
  { key: 'AAF', value: 'Carry-In – Assigned to Technician' },
  { key: 'AAG', value: 'Carry-In – Waiting for Product from Customer' },
  { key: 'AAH', value: 'Carry-In – In Process' },
  { key: 'AAI', value: 'Carry-In – Awaiting Customer Authorization' },
  { key: 'AAJ', value: 'Carry-In – Repair Complete and Product Not Returned' },
  { key: 'AAK', value: 'Carry-In – Repair Complete' },
  { key: 'AAL', value: 'Depot – Assigned to Technician' },
  { key: 'AAN', value: 'Depot – Awaiting In Depth Repair Testing' },
  { key: 'AAO', value: 'Depot – Awaiting Repair Testing' },
  { key: 'AAP', value: 'Depot – Awaiting Technical Assistance' },
  { key: 'AAQ', value: 'Depot – Estimate Approved' },
  { key: 'AAR', value: 'Depot – Estimate Declined' },
  { key: 'AAT', value: 'Depot – In Process Sent to Manufacturer for Repair' },
  { key: 'AAU', value: 'Depot – In Transit from Customer' },
  { key: 'AAV', value: 'Depot – Not Replaceble/Pay Customer' },
  { key: 'AAX', value: 'Depot – Parts No Longer Needed' },
  { key: 'AAY', value: 'Depot – Preparing to Ship' },
  { key: 'AAZ', value: 'Depot – Product Returned from Manufacturer' },
  { key: 'ABA', value: 'Depot – Received Notification' },
  { key: 'ABB', value: 'Depot – Repair Complete' },
  { key: 'ABC', value: 'Depot – Repair Complete and Product Not Returned' },
  { key: 'ABD', value: 'Depot – Returned to Customer' },
  { key: 'AAM', value: 'Depot – In Process Box/Label Sent to Customer' },
  { key: 'ABE', value: 'Depot – In Process Label Only Sent to Customer' },
  { key: 'AAS', value: 'Depot – Unrepairable / Pay Customer' },
  { key: 'ACA', value: 'Depot – Awaiting Customer Authorization' },
  { key: 'DIP', value: 'Depot – In Process' },
  { key: 'DTD', value: 'Depot – In Transit from Depot' }
];

// TODO : Replace  list with config values from db
export const ServiceBenchServiceOrderTypes = [
  { key: 'RR', value: 'Repair' },
  { key: 'WS', value: 'Warehouse Service' },
  { key: 'CE', value: 'Call Event' },
  { key: 'OS', value: 'Other' }
];

// TODO : Replace  list with config values from db
export const ServiceJobTypes = [
  { key: 'OSR', value: 'OnSiteRepair' },
  { key: 'DPM', value: 'Depot' }
];

export const saveBookingAppointmentsQuery = gql`
  mutation(
    $serviceType: String
    $serviceOrderNumber: String
    $status: String
    $jobNumber: String
    $servicerName: String
    $address: String
    $phone: String
    $scheduledDate: Date
    $timePeriod: String
    $appointmentsIdOriginal: Int
    $insertUserName: String
    $appointmentResponse: String
    $apiResponseOriginal: String
    $apiRequestOriginal: String
    $servicerAccount: String
    $timeSlotStart: String
    $timeSlotLength: String
  ) {
    createUpdateBookingAppointment(
      bookingAppointment: {
        serviceType: $serviceType
        serviceOrderNumber: $serviceOrderNumber
        status: $status
        jobNumber: $jobNumber
        servicerName: $servicerName
        address: $address
        phone: $phone
        scheduledDate: $scheduledDate
        timePeriod: $timePeriod
        appointmentsIdOriginal: $appointmentsIdOriginal
        insertUserName: $insertUserName
        appointmentResponse: $appointmentResponse
        apiResponseOriginal: $apiResponseOriginal
        apiRequestOriginal: $apiRequestOriginal
        servicerAccount: $servicerAccount
        timeSlotStart: $timeSlotStart
        timeSlotLength: $timeSlotLength
      }
    ) {
      appointmentsIdOriginal
      serviceOrderNumber
      jobNumber
    }
  }
`;

export const getServiceOrderRecallQuery = gql`
  query(
    $contractNumber: String
    $serviceOrderNumber: String
    $symptom: String
    $modelNumber: String
  ) {
    getServiceOrderRecall(
      contractNumber: $contractNumber
      serviceOrderNumber: $serviceOrderNumber
      symptom: $symptom
      modelNumber: $modelNumber
    ) {
      recall
      servicerName
      servicerAccount
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ServiceBenchTableService {
  public selectedValue = [];
  public storedValues = {};
  constructor(
    private http: HttpClient,
    private azureService: AzureLoginService,
    private serviceOrderDataService: ServiceOrderDataService,
    private apollo: Apollo
  ) {}

  getServiceAvailability(zipCode, daysNeeded = '14'): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    const { productInfoModel } = this.serviceOrderDataService.serviceOrderDataModel;
    const manufacturer = productInfoModel.manufacturerManufacturer;
    const {
      serviceBenchProductLineList,
      serviceBenchPaymentTypes,
      serviceBenchBrands
    } = this.serviceOrderDataService.serviceBenchModal;
    const payload = {
      availabilityRequest: {
        postalCode: zipCode,
        collateResponse: 'N',
        startingDate: '',
        datesNeeded: daysNeeded,
        paymentType: getValueByKey(serviceBenchPaymentTypes, 'SERVICECONTRACT'),
        serviceJobTypes: {
          serviceJobType: getKeyByValue(ServiceJobTypes, 'OnSiteRepair')
        },
        formatType: 'B',
        models: {
          model: {
            productLine: getKeyByValue(
              serviceBenchProductLineList,
              productInfoModel.tierDescription
            ),
            brand: getKeyByValue(serviceBenchBrands, manufacturer)
          }
        }
      },
      insertUserName: this.azureService.accountId
    };
    const response = this.http.post(environment.serviceBenchSpaUrl, JSON.stringify(payload), {
      headers
    });

    return response;
  }

  scheduleJob(selectedValues): Observable<any> {
    const {
      claimData,
      productInfoModel,
      productInfo,
      customerInfo,
      contact,
      location
    } = this.serviceOrderDataService.serviceOrderDataModel;
    const {
      serviceBenchProductLineList,
      serviceBenchPaymentTypes,
      serviceBenchBrands
    } = this.serviceOrderDataService.serviceBenchModal;
    const manufacturer = productInfoModel.manufacturerManufacturer;
    const { state } = this.serviceOrderDataService.azureMapAddressInfo;
    const postalCode = location && location.zipCode ? location.zipCode.trim() : '';
    const address1 = location && location.address1 ? location.address1.trim() : '';
    const city = location && location.city ? location.city.trim() : '';
    const stateProvince = state
      ? state.trim()
      : location && location.state
      ? location.state.value.trim()
      : '';
    const emailAddress =
      customerInfo && customerInfo.customerEmail ? customerInfo.customerEmail.trim() : '';
    const fax = customerInfo && customerInfo.customerFax ? customerInfo.customerFax.trim() : '';
    let homePhone = '';
    let lastName = '';
    let firstName = '';
    if (contact && contact.phoneNumber1) {
      homePhone = contact.phoneNumber1;
    } else if (customerInfo && customerInfo.customerPhone) {
      homePhone = customerInfo.customerPhone;
    }
    homePhone = homePhone.replace(/[^A-Za-z0-9]/g, "");

    if (contact && Object.keys(contact).length > 0) {
      if (contact.lastName) {
        lastName = contact.lastName;
      }
      if (contact.firstName) {
        firstName = contact.firstName;
      }
    } else if (customerInfo && Object.keys(customerInfo).length > 0) {
      if (customerInfo.customerLastName) {
        lastName = contact.customerLastName;
      }
      if (customerInfo.customerLastName) {
        firstName = customerInfo.customerFirstName;
      }
    }

    const { standardProblemDataModel } = this.serviceOrderDataService;
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    let failureDetails =
      standardProblemDataModel.symptom + '\n' + standardProblemDataModel.failureDescription;
    if (selectedValues.recall === true) {
      failureDetails = 'REWORK' + '\n' + failureDetails;
    }
    const payload = {
      scheduleRequest: {
        serviceOrderType: getKeyByValue(ServiceBenchServiceOrderTypes, 'Repair'),
        lastName,
        firstName,
        address1,
        city,
        stateProvince,
        postalCode,
        homePhone,
        emailAddress,
        serviceDescription: failureDetails,
        availableType: selectedValues.timeSlotType,
        availableDate: selectedValues.availableDate,
        slotStartTime: selectedValues.timeSlotStartTime,
        slotLength: selectedValues.timeSlotLength,
        serviceJobType: getKeyByValue(ServiceJobTypes, 'OnSiteRepair'),
        serviceProviderAccount: selectedValues.serviceProviderAccount,
        crmNumber: claimData.serviceOrderNumber,
        paymentType: getValueByKey(serviceBenchPaymentTypes, 'SERVICECONTRACT'),
        models: {
          model: {
            productLine: getKeyByValue(
              serviceBenchProductLineList,
              productInfoModel.tierDescription
            ),
            modelID: productInfoModel.modelNumber,
            serialNumber: productInfo.serialNumber,
            purchaseDate: generalDateFormatter(productInfoModel.productPurchaseDate, 'YYYYMMDD'),
            failureDate: generalDateFormatter(standardProblemDataModel.failureDate, 'YYYYMMDD'),
            brand: getKeyByValue(serviceBenchBrands, manufacturer)
          }
        },
        serviceProviderPreferenceList: {
          serviceProviderPreference: {
            serviceProviderAccount: selectedValues.serviceProviderAccount
          }
        }
      },
      servicerProfileID: selectedValues.servicerProfileID,
      insertUserName: this.azureService.accountId
    };

    const payloadFinal = sanitizeObject(payload);
    const response = this.http.post(environment.serviceBenchSjsUrl, JSON.stringify(payloadFinal), {
      headers
    });

    return response;
  }

  reScheduleJob(selectedValues): Observable<any> {
    const { scheduleJobDetails } = this.serviceOrderDataService.serviceBenchModal;
    const headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    const payload = {
      rescheduleRequest: {
        serviceJobID: scheduleJobDetails.jobNumber,
        availableType: selectedValues.timeSlotType,
        availableDate: selectedValues.availableDate,
        slotStartTime: selectedValues.timeSlotStartTime,
        slotLength: selectedValues.timeSlotLength,
        serviceProviderAccount: selectedValues.serviceProviderAccount
      },
      insertUserName: this.azureService.accountId
    };

    const payloadFinal = sanitizeObject(payload);

    const response = this.http.post(
      environment.serviceBenchSjresUrl,
      JSON.stringify(payloadFinal),
      {
        headers
      }
    );

    return response;
  }

  getPriceTable(): Observable<any[]> {
    return of([
      {
        id: 1,
        select: true,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false
      },
      {
        id: 3,
        select: false,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false
      },
      {
        id: 3,
        select: false,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false
      }
    ]);
  }

  saveBookingApointment = bookAppointment =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: saveBookingAppointmentsQuery,
      variables: {
        ...bookAppointment
      }
      // tslint:disable-next-line: semicolon
    });

  getServiceOrderRecall = data =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getServiceOrderRecallQuery,
      variables: { ...data },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
}
