import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import {
  getSubListByCategoryQuery,
  getAllAlertDataQuery,
  createupdateAlertDataQuery,
  getDealerLocationByDealerQuery
} from '../notifications-data-queries.constants';

@Injectable()
export class SearchNotificationService {
  constructor(private apollo: Apollo) {}
  productTypeList = [];
  getSubListByCategory = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getSubListByCategoryQuery,
      variables: { categoryDescription: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getDealerLocationByDealer = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getDealerLocationByDealerQuery,
      variables: { dealerNumbers: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getAllAlertData = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getAllAlertDataQuery,
      variables: {
        alertStatus: searchParams.alertStatus,
        alertMessage: searchParams.alertMessage,
        moduleName: searchParams.moduleType,
        productPlanTypes: searchParams.type,
        subCategories: searchParams.subcategoryDescription,
        manufacturers: searchParams.manufacturerName,
        buyingGroups: searchParams.buyingGroup,
        dealers: searchParams.dealers,
        dealerLocations: searchParams.dealerLocations,
        category: searchParams.categoryDescription,
        offset: searchParams.offset,
        limit: searchParams.limit
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createupdateAlertData = params =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createupdateAlertDataQuery,
      variables: { alertInput: params }
      // tslint:disable-next-line: semicolon
    });
}
