<div class="resolutions">
  <app-section-header [title]="'Resolutions'" [optionHeader]="op3" />
  <p-overlayPanel #op3>
    <p-listbox [options]="options" [(ngModel)]="selectedEllipsisItem" (onClick)="handleClose($event)"
      (onChange)="handleChange($event)">
    </p-listbox>
  </p-overlayPanel>

  <symptom-one></symptom-one>
  <symptom-two></symptom-two>
  <symptom-three></symptom-three>
</div>