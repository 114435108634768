import gql from 'graphql-tag';

export const servicerDetailsFormQuery = gql`
  query($id: Int!) {
    getServicerProfileById(id: $id) {
      servicerStatus
      servicerNumber
      servicerGroup
      servicerName
      fedTax
      servicerProfilesIdOriginal
      dispatchMethod
      claimPayMethod
      isCreditCardProfile
      configPaymentNotification
      accountingNumber
      accountingNumber2
      stateLicense
      currency
      contractDate
      selfServicingDealer
      servicerNotification
      contractExpireDate
      nationalServiceProvider
      servicersId
      refundPayMethod
      serviceRecallPeriod
      serviceRecallPeriodTerm
      invoiceSubmission
      invoiceSubmissionTerm
      contracted
      sealedSystemsRepair
      partsVerificationRequired
      customerSatisfaction
      rating
      facilityType
      latitude
      longitude
      statusCode
      number
      name
      dealerGroupName
      dealerGroupNumber
      servicerCountry
      dealerGroupId
      validInsurance
      addresses(categoryType: ServicerProfile) {
        addressIDOriginal
        country
        state
        city
        zipCode
        address1
        address2
        isMailingAddress
        isBillingAddress
      }
      contactInfo(categoryType: ServicerProfile) {
        id
        contactIdOriginal
        firstName
        lastName
        businessPhone
        email
        fax
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
      servicerPreAuth {
        servicerRatesId
        servicerName
        servicerNumber
        servicersId
        effectiveDate
        description
        limit
        categoryDescription
        subcategoryDescription
        rateType
        servicerRatesIdOriginal
        isDeleted
        insertUserName
        addedTimeStamp
      }
      servicerMarkupRates {
        servicerMarkupId
        servicerName
        effectiveDate
        rangeFrom
        rangeTo
        percentage
        description
        servicersId
        servicerNumber
        servicerMarkupIdOriginal
      }
      servicerExtraMilage {
        servicerExtraMilageId
        servicerExtraMilageIdOriginal
        servicerProfileId
        servicerNumber
        effectiveDate
        description
        mileRageFrom
        mileRangeTo
        rate
        term
        insertUserName
        addedTimeStamp
        isDeleted
      }
      servicerRatesSchedule {
        servicerRatesScheduleId
        servicerName
        servicerNumber
        servicersId
        effectiveDate
        description
        tripDiag
        tripDiagRateType
        repair
        repairRateType
        highEndRepair
        highEndRepairRateType
        sealedSys
        sealedSysRateType
        highEndSealedSys
        highEndSealedSysRateType
        categoryDescription
        subcategoryDescription
        servicerRatesScheduleIdOriginal
        isDeleted
        insertUserName
        addedTimeStamp
      }
    }
  }
`;

export const getServicerInfoByIdQuery = gql`
  query($id: Int!) {
    getServicerProfileInfoByServicerProfile(servicerProfileId: $id) {
      servicerProfilesInfoId
      servicerProfilesInfoIdOriginal
      servicerProfilesId
      serviceRecallPeriod
      serviceRecallPeriodTerm
      invoiceSubmission
      invoiceSubmissionTerm
      partsVerificationRequired
      contracted
      sealedSystemsRepair
      customerSatisfaction
      rating
      facilityType
      openSunday
      openMonday
      openTuesday
      openWednesday
      openThursday
      openFriday
      openSaturday
      closeSunday
      closeMonday
      closeTuesday
      closeWednesday
      closeThursday
      closeFriday
      closeSaturday
      timeZone
      insertUserName
      addedTimeStamp
    }
  }
`;

export const getCategoryDescriptionListQuery = gql`
  query {
    getCategoryDescriptionList
  }
`;

export const getServicerCodeExist = gql`
  query($servicerCode: String!, $azureUserId: String) {
    getServicerCodeExist(servicerCode: $servicerCode, azureUserId: $azureUserId)
  }
`;

export const getSubcategoryDescriptionListByCategoryQuery = gql`
  query($categoryDescription: String!) {
    getSubcategoryDescriptionListByCategory(categoryDescription: $categoryDescription)
  }
`;

export const getServicerProfilesContactInfoQuery = gql`
  query($servicerProfileId: Int!) {
    getServicerProfilesContactInfo(servicerProfileId: $servicerProfileId) {
      contactId
      contactIdOriginal
      firstName
      lastName
      businessPhone
      phoneNumber1
      email
      fax
      servicerProfilesId
      position
      notification
    }
  }
`;

export const createUpdateServicerProfileQuery = gql`
  mutation(
    $servicerStatus: String
    $servicerNumber: String
    $servicerGroup: String
    $servicerName: String
    $fedTax: String
    $dispatchMethod: String
    $claimPayMethod: String
    $isCreditCardProfile: Boolean
    $configPaymentNotification: String
    $accountingNumber: String
    $stateLicense: String
    $currency: String
    $contractDate: Date
    $selfServicingDealer: Boolean
    $servicerNotification: Boolean
    $contractExpireDate: Date
    $servicerProfilesIdOriginal: Int
    $contactInfo: ContactInputType
    $addresses: [AddressInputType]
    $nationalServiceProvider: Boolean
    $servicersId: Int
    $refundPayMethod: String
    $serviceRecallPeriod: Int
    $serviceRecallPeriodTerm: String
    $invoiceSubmission: Int
    $invoiceSubmissionTerm: String
    $contracted: Boolean
    $sealedSystemsRepair: Boolean
    $partsVerificationRequired: Boolean
    $customerSatisfaction: Int
    $rating: Int
    $facilityType: String
    $latitude: Float
    $longitude: Float
    $statusCode: String
    $number: String
    $name: String
    $dealerGroupName: String
    $dealerGroupNumber: String
    $servicerCountry: String
    $dealerGroupId: Int
    $validInsurance: Int
    $insertUserName: String
    $accountingNumber2: String
  ) {
    createUpdateServicerProfile(
      servicerProfileInput: {
        servicerStatus: $servicerStatus
        servicerNumber: $servicerNumber
        servicerGroup: $servicerGroup
        servicerName: $servicerName
        fedTax: $fedTax
        dispatchMethod: $dispatchMethod
        claimPayMethod: $claimPayMethod
        isCreditCardProfile: $isCreditCardProfile
        configPaymentNotification: $configPaymentNotification
        accountingNumber: $accountingNumber
        stateLicense: $stateLicense
        currency: $currency
        contractDate: $contractDate
        selfServicingDealer: $selfServicingDealer
        servicerNotification: $servicerNotification
        contractExpireDate: $contractExpireDate
        servicerProfilesIdOriginal: $servicerProfilesIdOriginal
        contactInfo: $contactInfo
        addresses: $addresses
        nationalServiceProvider: $nationalServiceProvider
        servicersId: $servicersId
        refundPayMethod: $refundPayMethod
        serviceRecallPeriod: $serviceRecallPeriod
        serviceRecallPeriodTerm: $serviceRecallPeriodTerm
        invoiceSubmission: $invoiceSubmission
        invoiceSubmissionTerm: $invoiceSubmissionTerm
        contracted: $contracted
        sealedSystemsRepair: $sealedSystemsRepair
        partsVerificationRequired: $partsVerificationRequired
        customerSatisfaction: $customerSatisfaction
        rating: $rating
        facilityType: $facilityType
        latitude: $latitude
        longitude: $longitude
        statusCode: $statusCode
        number: $number
        name: $name
        dealerGroupName: $dealerGroupName
        dealerGroupNumber: $dealerGroupNumber
        servicerCountry: $servicerCountry
        dealerGroupId: $dealerGroupId
        validInsurance: $validInsurance
        insertUserName: $insertUserName
        accountingNumber2: $accountingNumber2
      }
    ) {
      servicersId
      servicerProfilesId
      servicerProfilesIdOriginal
      servicerStatus
    }
  }
`;

export const createUpdateServicerPreAuthDetails = gql`
  mutation(
    $servicerRatesId: Int
    $servicerName: String
    $servicerNumber: String
    $servicersId: Int
    $effectiveDate: Date
    $description: String
    $limit: String
    $categoryDescription: String
    $subcategoryDescription: String
    $rateType: String
    $servicerRatesIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String
    $addedTimeStamp: Date
  ) {
    createUpdateServicerPreAuthDetails(
      servicerPreAuthDetails: {
        servicerRatesId: $servicerRatesId
        servicerName: $servicerName
        servicerNumber: $servicerNumber
        servicersId: $servicersId
        effectiveDate: $effectiveDate
        description: $description
        limit: $limit
        categoryDescription: $categoryDescription
        subcategoryDescription: $subcategoryDescription
        rateType: $rateType
        servicerRatesIdOriginal: $servicerRatesIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        addedTimeStamp: $addedTimeStamp
      }
    ) {
      servicerRatesIdOriginal
      servicerRatesId
    }
  }
`;

export const createUpdategetServicerMarkup = gql`
  mutation(
    $servicerName: String
    $servicerNumber: String
    $servicersId: Int
    $effectiveDate: Date
    $description: String
    $rangeFrom: String
    $rangeTo: String
    $percentage: Float
    $servicerMarkupIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String
    $addedTimeStamp: Date
    $expenseType: String
  ) {
    createUpdategetServicerMarkup(
      servicerMarkup: {
        servicerName: $servicerName
        servicerNumber: $servicerNumber
        servicersId: $servicersId
        effectiveDate: $effectiveDate
        description: $description
        rangeFrom: $rangeFrom
        rangeTo: $rangeTo
        percentage: $percentage
        servicerMarkupIdOriginal: $servicerMarkupIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        addedTimeStamp: $addedTimeStamp
        expenseType: $expenseType
      }
    ) {
      servicerMarkupIdOriginal
      servicerMarkupId
    }
  }
`;

export const createUpdateServicerExtraMilage = gql`
  mutation(
    $servicerExtraMilageIdOriginal: Int
    $servicerProfileId: Int
    $servicerNumber: String
    $effectiveDate: Date
    $description: String
    $mileRageFrom: String
    $mileRangeTo: String
    $rate: Float
    $term: Int
    $insertUserName: String
    $addedTimeStamp: Date
    $isDeleted: Boolean
  ) {
    createUpdateServicerExtraMilage(
      servicerExtraMilage: {
        servicerExtraMilageIdOriginal: $servicerExtraMilageIdOriginal
        servicerProfileId: $servicerProfileId
        servicerNumber: $servicerNumber
        effectiveDate: $effectiveDate
        description: $description
        mileRageFrom: $mileRageFrom
        mileRangeTo: $mileRangeTo
        rate: $rate
        term: $term
        insertUserName: $insertUserName
        addedTimeStamp: $addedTimeStamp
        isDeleted: $isDeleted
      }
    ) {
      servicerExtraMilageIdOriginal
    }
  }
`;

export const createUpdateServicerRatesSchedule = gql`
  mutation(
    $servicerName: String
    $servicerNumber: String
    $servicersId: Int
    $effectiveDate: Date
    $description: String
    $tripDiag: String
    $tripDiagRateType: String
    $repair: String
    $repairRateType: String
    $highEndRepair: String
    $highEndRepairRateType: String
    $sealedSys: String
    $sealedSysRateType: String
    $highEndSealedSys: String
    $highEndSealedSysRateType: String
    $categoryDescription: String
    $subcategoryDescription: String
    $servicerRatesScheduleIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String
    $addedTimeStamp: Date
  ) {
    createUpdateServicerRatesSchedule(
      servicerRatesSchedule: {
        servicerName: $servicerName
        servicerNumber: $servicerNumber
        servicersId: $servicersId
        effectiveDate: $effectiveDate
        description: $description
        tripDiag: $tripDiag
        tripDiagRateType: $tripDiagRateType
        repair: $repair
        repairRateType: $repairRateType
        highEndRepair: $highEndRepair
        highEndRepairRateType: $highEndRepairRateType
        sealedSys: $sealedSys
        sealedSysRateType: $sealedSysRateType
        highEndSealedSys: $highEndSealedSys
        highEndSealedSysRateType: $highEndSealedSysRateType
        categoryDescription: $categoryDescription
        subcategoryDescription: $subcategoryDescription
        servicerRatesScheduleIdOriginal: $servicerRatesScheduleIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        addedTimeStamp: $addedTimeStamp
      }
    ) {
      servicerRatesScheduleIdOriginal
    }
  }
`;

export const getServicerServiceTypesByNumberQuery = gql`
  query($servicerNumber: String!, $offset: Int, $limit: Int) {
    getServicerServiceTypesByNumber(
      servicerNumber: $servicerNumber
      offset: $offset
      limit: $limit
    ) {
      serviceTypesId
      servicerId
      servicerNumber
      type
      beginDate
      endDate
      isActive
      isMFGAuthorized
      subcategoryDescription
      tierDescription
      categoryDescription
      modelName
      manufacturerName
      coverageCode
      serviceTypesIdOriginal
      isDeleted
      insertUserName
      addedTimeStamp
    }
  }
`;

export const getServicerServiceAreaQuery = gql`
  query($servicersId: Int!, $zipCode: String, $offset: Int, $limit: Int) {
    getServicerServiceArea(
      servicersId: $servicersId
      zipCode: $zipCode
      offset: $offset
      limit: $limit
    ) {
      servicersServiceAreasId
      zipCode
      radius
      manufacturerName
      modelNumber
      servicersServiceAreasIdOriginal
      isDeleted
      servicersId
      insertUserName
      addedTimeStamp
    }
  }
`;

export const createUpdateServicerServiceAreaQuery = gql`
  mutation($serviceAreas: [ServicerServiceAreaInput]) {
    createUpdateServicerServiceArea(servicerServiceAreaInput: $serviceAreas) {
      servicersServiceAreasId
      zipCode
      radius
      manufacturerName
      modelNumber
      servicersServiceAreasIdOriginal
      isDeleted
      servicersId
      insertUserName
      addedTimeStamp
    }
  }
`;

export const getServicerTypesOptionListQuery = gql`
  query {
    getCategoryDescriptionList

    getManufacturerByName(name: "") {
      manufacturersName
    }
  }
`;

export const createUpdateServicerTypes = gql`
  mutation(
    $servicerId: Int
    $servicerNumber: String
    $type: String
    $beginDate: Date
    $endDate: Date
    $isActive: Boolean
    $isMFGAuthorized: Boolean
    $subcategoryDescription: String
    $tierDescription: String
    $categoryDescription: String
    $modelName: String
    $manufacturerName: String
    $coverageCode: String
    $serviceTypesIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String
    $addedTimeStamp: Date
  ) {
    createUpdateServicerTypes(
      servicerServiceType: {
        servicerId: $servicerId
        servicerNumber: $servicerNumber
        type: $type
        beginDate: $beginDate
        endDate: $endDate
        isActive: $isActive
        isMFGAuthorized: $isMFGAuthorized
        subcategoryDescription: $subcategoryDescription
        tierDescription: $tierDescription
        categoryDescription: $categoryDescription
        modelName: $modelName
        manufacturerName: $manufacturerName
        coverageCode: $coverageCode
        serviceTypesIdOriginal: $serviceTypesIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        addedTimeStamp: $addedTimeStamp
      }
    ) {
      servicerId
      servicerNumber
      type
      beginDate
      endDate
      isActive
      isMFGAuthorized
      subcategoryDescription
      tierDescription
      categoryDescription
      modelName
      manufacturerName
      coverageCode
      serviceTypesIdOriginal
      isDeleted
      insertUserName
      addedTimeStamp
    }
  }
`;

export const getServicerSubListByCategoryQuery = gql`
  query($categoryDescription: String!) {
    getSubcategoryDescriptionListByCategory(categoryDescription: $categoryDescription)
    getTierDescriptionListByCategory(categoryDescription: $categoryDescription)
  }
`;

export const createUpdateServicerProfileInfo = gql`
  mutation(
    $servicerProfilesInfoId: Int
    $servicerProfilesInfoIdOriginal: Int
    $servicerProfilesId: Int
    $serviceRecallPeriod: Int
    $serviceRecallPeriodTerm: String
    $invoiceSubmission: Int
    $invoiceSubmissionTerm: String
    $partsVerificationRequired: Boolean
    $contracted: Boolean
    $sealedSystemsRepair: Boolean
    $customerSatisfaction: Int
    $rating: Int
    $facilityType: String
    $openSunday: Date
    $openMonday: Date
    $openTuesday: Date
    $openWednesday: Date
    $openThursday: Date
    $openFriday: Date
    $openSaturday: Date
    $closeSunday: Date
    $closeMonday: Date
    $closeTuesday: Date
    $closeWednesday: Date
    $closeThursday: Date
    $closeFriday: Date
    $closeSaturday: Date
    $timeZone: String
    $isDeleted: Boolean
    $insertUserName: String
  ) {
    createUpdateServicerProfileInfo(
      servicerServiceInfo: {
        servicerProfilesInfoId: $servicerProfilesInfoId
        servicerProfilesInfoIdOriginal: $servicerProfilesInfoIdOriginal
        servicerProfilesId: $servicerProfilesId
        serviceRecallPeriod: $serviceRecallPeriod
        serviceRecallPeriodTerm: $serviceRecallPeriodTerm
        invoiceSubmission: $invoiceSubmission
        invoiceSubmissionTerm: $invoiceSubmissionTerm
        partsVerificationRequired: $partsVerificationRequired
        contracted: $contracted
        sealedSystemsRepair: $sealedSystemsRepair
        customerSatisfaction: $customerSatisfaction
        rating: $rating
        facilityType: $facilityType
        openSunday: $openSunday
        openMonday: $openMonday
        openTuesday: $openTuesday
        openWednesday: $openWednesday
        openThursday: $openThursday
        openFriday: $openFriday
        openSaturday: $openSaturday
        closeSunday: $closeSunday
        closeMonday: $closeMonday
        closeTuesday: $closeTuesday
        closeWednesday: $closeWednesday
        closeThursday: $closeThursday
        closeFriday: $closeFriday
        closeSaturday: $closeSaturday
        timeZone: $timeZone
        isDeleted: $isDeleted
        insertUserName: $insertUserName
      }
    ) {
      servicerProfilesInfoId
      servicerProfilesInfoIdOriginal
      servicerProfilesId
      serviceRecallPeriod
      serviceRecallPeriodTerm
      invoiceSubmission
      invoiceSubmissionTerm
      partsVerificationRequired
      contracted
      sealedSystemsRepair
      customerSatisfaction
      rating
      facilityType
      openSunday
      openMonday
      openTuesday
      openWednesday
      openThursday
      openFriday
      openSaturday
      closeSunday
      closeMonday
      closeTuesday
      closeWednesday
      closeThursday
      closeFriday
      closeSaturday
      timeZone
      isDeleted
      insertUserName
      addedTimeStamp
    }
  }
`;
