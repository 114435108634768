import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DropdownField } from '../../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../../shared/form-field/textbox-field';
import { FormField } from '../../../../shared/form-field/form-field';
import { Apollo } from 'apollo-angular';
import { DropdownDataService } from '../../../../services/dropdown.data.service';
import { ServicerService } from '../../servicer.service';
import gql from 'graphql-tag';

const getModelNumbersByMfgQuery = gql`
  query ($manufacturerName: String!) {
    getServicerServiceAreaModelNumber(manufacturerName: $manufacturerName)
  }
`;

@Injectable()
export class ServiceTypeModalService {
  templateDetails = {};
  typeMapping = {};
  constructor(
    private apollo: Apollo,
    private dropdownDataService: DropdownDataService,
    private servicerTypeService: ServicerService,
  ) {}

  getServicerFormFields() {
    const optionsManufacturerList = this.dropdownDataService.manufacturerList
      ? this.dropdownDataService.manufacturerList.map((x) => ({
          key: x.value,
          value: x.value,
        }))
      : [];
    const optionsCategoryDescriptionList = this.populateList(
      this.dropdownDataService.categoryDescriptionList,
    );
    // const optionsSubCategoryDescriptionList = this.dropdownDataService.subCategoryDescriptionList;
    const formFields: FormField<string>[] = [
      new DropdownField({
        required: true,
        key: 'categoryDescription',
        label: 'CATEGORY',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsCategoryDescriptionList],
        showToggleAll : true,
        isChangeEvent: true,
        filter: true,
        order: 0,
      }),
      new DropdownField({
        required: true,
        key: 'subcategoryDescription',
        label: 'SUB-CATEGORY',
        options: [],
        virtualScroll: true,
        showToggleAll: true,
        filter: true,
        order: 1,
      }),
      new DropdownField({
        required: true,
        key: 'tierDescription',
        label: 'TIER',
        showToggleAll : true,
        options: [],
        filter: true,
        order: 2,
      }),
      new DropdownField({
        required: true,
        key: 'type',
        label: 'SERVICE TYPE',
        isSingleselect: true,
        options: [
          { key: 'Service', value: 'Service' },
          { key: 'Replacement/Store Credit', value: 'Replacement/Store Credit' },
          { key: 'Parts Vendor', value: 'Parts Vendor' },
          { key: 'Other', value: 'Other' },
        ],
        order: 3,
      }),
      new DropdownField({
        required: true,
        key: 'manufacturerName',
        label: 'MANUFACTURER',
        options: [{ key: 'ALL', value: 'ALL' }, ...optionsManufacturerList],
        showToggleAll : true,
        isChangeEvent: true,
        virtualScroll: true,
        filter: true,
        order: 4,
      }),
      new DropdownField({
        required: true,
        key: 'modelName',
        label: 'MODEL',
        options: [],
        virtualScroll: true,
        showToggleAll: true,
        filter: true,
        order: 5,
      }),
      new TextboxField({
        required: true,
        key: 'coverageCode',
        label: 'SKU',
        order: 6,
      }),
      new TextboxField({
        required: true,
        key: 'beginDate',
        label: 'BEGIN DATE',
        type: 'Date',
        isChangeEvent: true,
        order: 7,
      }),
      new TextboxField({
        key: 'endDate',
        label: 'END DATE',
        type: 'Date',
        order: 8,
      }),
      new DropdownField({
        key: 'isActive',
        label: 'ACTIVE',
        isSingleselect: true,
        options: [
          { key: 'Yes', value: 'Yes' },
          { key: 'No', value: 'No' },
        ],
        order: 9,
      }),
      new DropdownField({
        key: 'isMFGAuthorized',
        label: 'MFGAuthorized',
        isSingleselect: true,
        options: [
          { key: 'Yes', value: 'Yes' },
          { key: 'No', value: 'No' },
        ],
        order: 10,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  private populateList(listData) {
    const arr = [];
    listData.forEach((element) => {
      arr.push({ key: element, value: element });
    });
    return arr;
  }

  getModelNumbersByMfg = (queryParam) => {
    return this.apollo.use('crm').query<any>({
      query: getModelNumbersByMfgQuery,
      variables: { manufacturerName: queryParam },
      fetchPolicy: 'network-only',
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };
}
