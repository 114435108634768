import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormField } from '../../shared/form-field/form-field';
import { Observable } from 'rxjs';
import { NavigationPanel } from '../../shared/navigation-panel/navigation-panel.model';
import { NavigationPanelComponent } from '../../shared/navigation-panel/navigation-panel.component';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { CoverageFormFieldService } from '../coverage-form-field.service';
import { CoverageTabComponent } from './coverage-tabs/coverage-main/coverage-tab.component';
import { CoverageSettingComponent } from './coverage-tabs/coverage-setting/coverage-setting.component';
import { ExceptionsComponent } from './coverage-tabs/exceptions/exceptions.component';
import { RatingComponent } from './coverage-tabs/rating/rating.component';
import { CoverageFormComponent } from './coverage-tabs/form/coverage-form.component';
import { ApprovedStateComponent } from './coverage-tabs/approved-state/approved-state.component';
import { AssociatedSubcategoriesComponent } from './coverage-tabs/associated-subcategories/associated-subcategories.component';
import { CoverageService } from './coverage.service';
import { NoteComponent } from '../../shared/notes/note.component';
import { AddressCategoryType } from '../../shared/constants/enums';

@Component({
  selector: 'cv-coverage',
  templateUrl: 'coverage.template.html',
  styleUrls: ['coverage.scss'],
  providers: [CoverageFormFieldService, CoverageService],
})
export class CoverageComponent implements OnInit {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  addressCategoryType = AddressCategoryType;
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  component = CoverageComponent;
  componentDisplay = false;
  constructor(
    service: CoverageFormFieldService,
    private roleService: RoleAuthorizationService,
    private coverageService: CoverageService,
  ) {
    this.formFields$ = service.getFormFields();

    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: CoverageTabComponent,
        data: { name: 'Coverage' },
        title: 'COV',
        tabname: 'Coverage',
        tabKey: 'coverage-tab',
      },
      {
        component: CoverageSettingComponent,
        data: { name: 'Setting' },
        title: 'SET',
        tabname: 'Setting',
        tabKey: 'settings-tab',
      },
      {
        component: ExceptionsComponent,
        data: { name: 'Exceptions' },
        title: 'EXP',
        tabname: 'Exceptions',
        tabKey: 'exception-tab',
      },
      { component: RatingComponent, data: { name: 'Rating' }, title: 'RTG', tabname: 'Rating',  tabKey: 'rating-tab', },
      { component: CoverageFormComponent, data: { name: 'Form' }, title: 'FRM', tabname: 'Form', tabKey: 'form-tab', },
      {
        component: ApprovedStateComponent,
        data: { name: 'Approved State' },
        title: 'APS',
        tabname: 'Approved State',
        tabKey: 'approved-state-tab',
      },
      {
        component: AssociatedSubcategoriesComponent,
        data: { name: 'Associated Subcategories' },
        title: 'ASC',
        tabname: 'Associated Subcategories',
        tabKey: 'associated-subcategories-tab',
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: CoverageComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(roleService.applyAuthorization(this.authDetails));
  }

  ngOnInit() {
    this.searchParams = this.searchParams === 'Coverage' ? null : this.searchParams;

    this.componentDisplay = this.roleService.isVisible(NoteComponent, CoverageComponent);
    this.coverageService.coverageConfig.searchParams = !isNaN(this.searchParams)
      ? parseInt(this.searchParams, 10)
      : 0;
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
}
