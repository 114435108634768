import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';
const getInsuranceQuery = gql`
  query($servicerProfilesId: Int!) {
    getServicerInsurance(servicerProfilesId: $servicerProfilesId) {
      servicersId
      servicerNumber
      servicerInsurancesIdOriginal
      provider
      type
      typeDescription
      name
      number
      dateTo
      dateFrom
      comments
      notify
      validInsurance
      addedTimeStamp
    }
  }
`;

const createServicerInsuranceMutation = gql`
  mutation(
    $servicerName: String
    $servicerNumber: String
    $servicersId: Int
    $provider: String
    $name: String
    $policy_type: String
    $policy_description: String
    $number: String
    $policy_begin_date: Date
    $policy_end_date: Date
    $comments: String
    $notify: Boolean
    $servicerInsurancesIdOriginal: Int
    $isDeleted: Boolean
    $insertUserName: String
  ) {
    createUpdateServicerInsurance(
      servicerInsurance: {
        servicerName: $servicerName
        servicerNumber: $servicerNumber
        servicersId: $servicersId
        provider: $provider
        name: $name
        type: $policy_type
        typeDescription: $policy_description
        number: $number
        dateFrom: $policy_begin_date
        dateTo: $policy_end_date
        comments: $comments
        notify: $notify
        servicerInsurancesIdOriginal: $servicerInsurancesIdOriginal
        isDeleted: $isDeleted
        insertUserName: $insertUserName
      }
    ) {
      servicerInsurancesIdOriginal
      servicersId
      servicerNumber
      provider
      type
      typeDescription
      number
      name
      dateTo
      dateFrom
      notify
      comments
      validInsurance
      addedTimeStamp
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ServicerInsuranceService {
  constructor(private apollo: Apollo) {}

  getServicerInsurance = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getInsuranceQuery,
      variables: {
        servicerProfilesId: searchParams.servicerProfilesId
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  createServicerInsurance = (searchParams: any) =>
    this.apollo.use(CrmGraphql.NameSpace).mutate<any>({
      mutation: createServicerInsuranceMutation,
      variables: {
        ...searchParams
      }
      // tslint:disable-next-line: semicolon
    });
}
