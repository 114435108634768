import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input } from '@angular/core';
import { SearchDealerLocationService } from './seach-dealer-location.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';
import { Subscription } from 'rxjs';
import { DealerLocationDisplayStatus } from '../dealer.constants';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { copy } from '../../shared/utilities/common-utilities';

@Component({
  selector: 'search-dealer-location',
  templateUrl: 'search-dealer-location.template.html',
  styleUrls: ['../../shared/styles/crm-table.scss'],
  providers: [SearchDealerLocationService],
})
export class SearchDealerLocationComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  newTab: Tab;
  op: OverlayPanel;
  querySubscription: Subscription;
  isLoading = false;
  authDetails: IRoleAuthorizationService;
  lastOffsetVal = 0;
  offsetLimit = 10;
  disableLoadMore = false;
  resultCount = 0;
  constructor(
    private searchDealerLocationService: SearchDealerLocationService,
    private tabService: TabsService,
    private roleService: RoleAuthorizationService,
  ) {}

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SearchDealerLocationComponent,
      generalArray: this.ellipsisOptions,
    };
    this.ellipsisOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'name', header: 'DEALER LOCATION NAME', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'zipCode', header: 'ZIP', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
    ];
    this.getSearchData();
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    this.newTab = {
      header: 'Dealer Location',
      content: event.value == 'edit' ? this.selectedItem.sellerIdOriginal : null,
      type: 'Dealer Location',
    };
    this.tabService.createTab(this.newTab);
    overlaypanel.hide();
  }
  openTab(data: any) {
    this.newTab = {
      header: 'Dealer Location',
      content: data.sellerIdOriginal,
      type: 'Dealer Location',
    };
    this.tabService.createTab(this.newTab);
  }

  getSearchData() {
    this.isLoading = true;
    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string' ? this.searchParams : {};

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;

    this.lastOffsetVal += this.offsetLimit;
    this.searchParams.status = this.searchParams.status ? this.searchParams.status : '';
    this.querySubscription = this.searchDealerLocationService
      .getDealerLocationSearchDetails(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifedData = data.getDealerLocationSearchResults;
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
          this.resultCount = this.dataList.length;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
