import { FormField } from './form-field';

export class TextboxField extends FormField<string> {
  override controlType = 'textbox';
  override type: string;

  constructor(options: any = {}) {
    super(options);
    this.type = options[`type`] || '';
  }
}
