/* @ts-disable  */
import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/es-AR';
import localeFrCa from '@angular/common/locales/fr-CA';
import locales from 'src/assets/primeng-locales/locales.json';

import moment from 'moment';

import { IPrimeNgLocaleInfo } from '../interfaces/primeng-locale-info.interface';

@Injectable()
export class LanguageUtilsService {
  public static readonly EN_US = 'en-us';
  public static readonly ES_AR = 'es-ar';
  public static readonly FR_CA = 'fr-ca';
  public static readonly SUPPORTED_LANGUAGES: string[] = [
    LanguageUtilsService.EN_US,
    LanguageUtilsService.ES_AR,
    LanguageUtilsService.FR_CA,
  ];

  private static readonly navigator: Navigator = window.navigator;
  private static defaultLang = 'en-us';

  private primeNgLocaleInfo: IPrimeNgLocaleInfo;

  public static localizeMoment(language: string) {
    moment.locale(language);
  }

  public static browserLanguage(): string {
    let langObjMatches = 'en-us';
    if (typeof this.navigator !== 'undefined') {
      langObjMatches = this.navigator.language;
    }

    return this.getLanguage([langObjMatches]);
  }

  public static getRelativeLang(supportedLangs: string[], selectedLang: string): string {
    if (supportedLangs.some((lang) => lang === selectedLang)) {
      return selectedLang;
    }

    const entryLang: string = selectedLang.split('-')[0];
    const relativeValue: string | undefined = supportedLangs.find((value: string) => {
      return value.indexOf(entryLang) !== -1;
    });

    return typeof relativeValue !== 'undefined' && relativeValue !== null
      ? relativeValue
      : this.defaultLang;
  }

  public static getSelectedLanguage(): string {
    const browserLang: string = this.browserLanguage();
    const result = this.getRelativeLang(this.SUPPORTED_LANGUAGES, browserLang);

    return result;
  }

  public static registerSupportedLocales(): void {
    registerLocaleData(localeAr, 'es-AR');
    registerLocaleData(localeFrCa, 'fr-CA');
  }

  private static getLanguage(langOptions: any): string {
    const findOption = langOptions.find((option: Navigator) => {
      return typeof option !== 'undefined' && option !== null;
    });

    if (findOption) {
      return Array.isArray(findOption)
        ? findOption[0].toString().toLowerCase()
        : (findOption as string).toLowerCase();
    }

    return this.defaultLang;
  }

  public getPrimeNgLocaleInfo() {
    this.primeNgLocaleInfo = locales[LanguageUtilsService.getSelectedLanguage().substr(0, 2)];
    return this.primeNgLocaleInfo;
  }
}
