<div class="main-tab">
  <app-section-header [title]="'Main'" />
  <gn-contact-information *ngIf="isMainTabDataAvailable || isNew"
    [(modelData)]="buyingGroupDataService.buyingGroupDataModel.mainTab.customerInformation"
    [enableModelData]="isMainTabDataAvailable">
  </gn-contact-information>
  <div class="customer-information">
    <div class="title">Mailing Address</div>
    <address *ngIf="isMainTabDataAvailable || isNew"
      [(modelData)]="buyingGroupDataService.buyingGroupDataModel.mainTab.maillingAddresses"
      [enableModelData]="isMainTabDataAvailable"></address>
  </div>
  <div class="customer-information">
    <div class="title">Billing Address</div>
    <address *ngIf="isMainTabDataAvailable || isNew"
      [(modelData)]="buyingGroupDataService.buyingGroupDataModel.mainTab.billingAddresses"
      [enableModelData]="isMainTabDataAvailable"></address>
  </div>
</div>