import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { FormFieldControlService } from 'src/app/modules/crm/services/form-field-control.service';
import { FormField } from 'src/app/modules/crm/shared/form-field/form-field';
import { ProblemService } from '../problem.service';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'adh-problem',
  templateUrl: 'adh-problem.template.html',
  styleUrls: ['./adh-problem.scss'],
  providers: [FormFieldControlService],
})
export class ADHProblemComponent {
  formFields: FormField<string>[] = [];
  formFieldsPart1: FormField<string>[] = [];
  form: FormGroup;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  cols: any[];
  colsTs: any[];
  problemInfo: any[];
  problemTs: any[];
  constructor(
    private qcs: FormFieldControlService,
    private service: ProblemService,
  ) {
    this.service.getADHFailureFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.formFieldsPart1 = this.formFields.slice(0, 10);
      this.form = this.qcs.toFormGroup(this.formFields);
    });
    this.cols = [
      { field: 'model', header: 'MODEL #' },
      { field: 'ofClaims', header: '# OF CLAIMS' },
      { field: 'sameFailure', header: 'SAME FAILURE' },
    ];
    this.colsTs = [
      { field: 'fixed', header: 'FIXED' },
      { field: 'notFixed', header: 'NOT FIXED' },
      { field: 'troubleshooting', header: 'TROUBLESHOOTING' },
      { field: 'success', header: 'SUCCESS' },
    ];
    this.service.getStandardInfo().subscribe((data) => {
      this.problemInfo = data;
    });
    this.service.getTroubleshootInfo().subscribe((data) => {
      this.problemTs = data;
    });
  }

  onSubmit() {
    // functionality for on submit
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
