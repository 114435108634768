import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import _forEach from 'lodash/forEach';
import { FormGroup } from '@angular/forms';
import { rateDetailType } from 'src/app/modules/crm/contracts/rate-details-tab/rate-details.constant';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { FormField } from 'src/app/modules/crm/shared/form-field/form-field';
import { RatingService } from '../rating.service';
import { CoverageService } from '../../../coverage.service';
import { MessageService } from 'primeng/api';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Component({
  selector: 'rating-modal',
  templateUrl: 'rating-modal.template.html',
  styleUrls: ['rating-modal.scss'],
  providers: [MessageService],
})
export class RatingModalComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  // @Input() displayDialog: boolean;
  private privateDisplayDialog: boolean;
  @Input() formData: any;
  admin: any[];
  commission: any[];
  reserves: any[];
  total: any[];
  updatedRates: any[];
  cols: any[];
  referenceData = null;
  querySubscription: any;
  isLoading: boolean;
  isRateChanged: boolean;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  @Output() displayDialogChange = new EventEmitter();
  @Output() formSubmitEvent = new EventEmitter();

  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }

  constructor(
    private ratingService: RatingService,
    private coverageService: CoverageService,
    private azureService: AzureLoginService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.cols = [
      { field: 'bucketCode', header: 'CODE' },
      { field: 'bucketLabel', header: 'DESCRIPTION' },
      { field: 'rateAmount', header: 'RATE' },
    ];

    this.isRateChanged = false;
    if (this.formData) {
      this.fillRateDetailsModel(copy(this.formData));
    } else {
      this.isLoading = true;
      this.querySubscription = this.ratingService
        .getRatingsSearch(this.coverageService.coverageDataModel.coverageCode)
        .subscribe(
          ({ data }) => {
            const modifiedData = data.getRatingsSearch;
            if (modifiedData) {
              this.formData = modifiedData.coverageRates;
              this.fillRateDetailsModel(copy(this.formData));
            }
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  private fillRateDetailsModel(data: any) {
    this.admin = [];
    this.commission = [];
    this.reserves = [];

    let adminRatesTotal = 0;
    let reservesRatesTotal = 0;
    let commissionsRatesTotal = 0;
    let markup = null;

    // populate other tables
    _forEach(data, (obj) => {
      // check for $ sign
      if (typeof obj.rateAmount === 'string') {
        obj.rateAmount =
          obj.rateAmount && obj.rateAmount.indexOf('$') > -1
            ? obj.rateAmount
            : '$' + parseFloat(obj.rateAmount).toFixed(2);
      } else {
        obj.rateAmount = '$' + parseFloat(obj.rateAmount).toFixed(2);
      }

      const rate = this.getFloat(obj.rateAmount);

      // separate rates
      switch (obj.categoryCode.toLowerCase()) {
        case rateDetailType.admin:
          this.admin.push(obj);
          adminRatesTotal += rate;
          break;
        case rateDetailType.commission:
        case rateDetailType.commissions:
          this.commission.push(obj);
          commissionsRatesTotal += rate;
          break;
        case rateDetailType.reserve:
        case rateDetailType.reserves:
          this.reserves.push(obj);
          reservesRatesTotal += rate;
          break;
      }

      if (markup === null && obj.bucketLabel && obj.bucketLabel.toLowerCase() === 'dealermarkup') {
        markup = this.getFloat(obj.rateAmount);
      }
    });

    // populate total
    this.admin.push({
      bucketLabel: 'TOTAL',
      bucketCode: null,
      rateAmount: '$' + adminRatesTotal.toFixed(2),
    });
    this.commission.push({
      bucketLabel: 'TOTAL',
      bucketCode: null,
      rateAmount: '$' + commissionsRatesTotal.toFixed(2),
    });
    this.reserves.push({
      bucketLabel: 'TOTAL',
      bucketCode: null,
      rateAmount: '$' + reservesRatesTotal.toFixed(2),
    });

    // populate total
    const grandTotal = adminRatesTotal + commissionsRatesTotal + reservesRatesTotal;
    const extendedDealerCost = markup + grandTotal;

    this.total = [
      {
        bucketLabel: 'BASE DEALER COST',
        bucketCode: '',
        rateAmount: '$' + grandTotal.toFixed(2),
      },
      {
        bucketLabel: 'MARKUP',
        bucketCode: '',
        rateAmount: '$' + markup.toFixed(2),
      },
      {
        bucketLabel: 'EXTENDED DEALER COST',
        bucketCode: '',
        rateAmount: '$' + extendedDealerCost.toFixed(2),
      },
    ];
  }

  changeEventDetection(updatedRate) {
    let adminRatesTotal = 0;
    let reservesRatesTotal = 0;
    let commissionsRatesTotal = 0;
    let markup = null;

    this.isRateChanged = true;
    updatedRate.rateAmount = this.getFloat(updatedRate.rateAmount);
    updatedRate.rateAmount = isNaN(updatedRate.rateAmount)
      ? '$0.00'
      : '$' + updatedRate.rateAmount.toFixed(2);
    markup = this.getFloat(
      this.total.find((rate) => rate.bucketLabel.toLowerCase() === 'markup').rateAmount,
    );

    if (updatedRate.bucketLabel.toLowerCase() === 'markup') {
      markup = this.getFloat(updatedRate.rateAmount);
    } else {
      this.updateRatesInCategories(updatedRate);
    }

    _forEach(this.admin, (obj) => {
      if (obj.bucketLabel !== 'TOTAL') {
        const rate = this.getFloat(obj.rateAmount);
        adminRatesTotal += rate;
      }
    });
    _forEach(this.reserves, (obj) => {
      if (obj.bucketLabel !== 'TOTAL') {
        const rate = this.getFloat(obj.rateAmount);
        reservesRatesTotal += rate;
      }
    });
    _forEach(this.commission, (obj) => {
      if (obj.bucketLabel !== 'TOTAL') {
        const rate = this.getFloat(obj.rateAmount);
        commissionsRatesTotal += rate;
      }
    });

    this.admin.find((rate) => rate.bucketLabel.toLowerCase() === 'total').rateAmount =
      '$' + adminRatesTotal.toFixed(2);
    this.reserves.find((rate) => rate.bucketLabel.toLowerCase() === 'total').rateAmount =
      '$' + reservesRatesTotal.toFixed(2);
    this.commission.find((rate) => rate.bucketLabel.toLowerCase() === 'total').rateAmount =
      '$' + commissionsRatesTotal.toFixed(2);

    // populate total
    const grandTotal = adminRatesTotal + commissionsRatesTotal + reservesRatesTotal;
    const extendedDealerCost = markup + grandTotal;

    this.total = [
      {
        bucketLabel: 'BASE DEALER COST',
        bucketCode: '',
        rateAmount: '$' + grandTotal.toFixed(2),
      },
      {
        bucketLabel: 'MARKUP',
        bucketCode: '',
        rateAmount: '$' + markup.toFixed(2),
      },
      {
        bucketLabel: 'EXTENDED DEALER COST',
        bucketCode: '',
        rateAmount: '$' + extendedDealerCost.toFixed(2),
      },
    ];
  }

  private updateRatesInCategories(updatedRate) {
    let updatedRateIndex = 0;
    // separate rates
    switch (updatedRate.categoryCode.toLowerCase()) {
      case rateDetailType.admin:
        updatedRateIndex = this.admin.findIndex(
          (rate) => rate.bucketCode.toLowerCase() === updatedRate.bucketCode.toLowerCase(),
        );
        if (updatedRateIndex >= 0) {
          this.admin[updatedRateIndex] = updatedRate;
        }
        break;
      case rateDetailType.commission:
      case rateDetailType.commissions:
        updatedRateIndex = this.commission.findIndex(
          (rate) => rate.bucketCode.toLowerCase() === updatedRate.bucketCode.toLowerCase(),
        );
        if (updatedRateIndex >= 0) {
          this.commission[updatedRateIndex] = updatedRate;
        }
        break;
      case rateDetailType.reserve:
      case rateDetailType.reserves:
        updatedRateIndex = this.reserves.findIndex(
          (rate) => rate.bucketCode.toLowerCase() === updatedRate.bucketCode.toLowerCase(),
        );
        if (updatedRateIndex >= 0) {
          this.reserves[updatedRateIndex] = updatedRate;
        }
        break;
    }
  }

  private getFloat(value) {
    const rawString = value ? value.replace('$', '') : value;
    return parseFloat(rawString);
  }

  onSubmit() {
    let markupRate = null;
    this.updatedRates = [];
    _forEach(this.admin, (obj) => {
      this.filterUpdatedRates(obj);
    });
    _forEach(this.reserves, (obj) => {
      this.filterUpdatedRates(obj);
    });
    _forEach(this.commission, (obj) => {
      this.filterUpdatedRates(obj);
    });

    markupRate = copy(
      this.formData.find((rate) => rate.bucketLabel.toLowerCase() === 'dealermarkup'),
    );
    if (markupRate) {
      markupRate.rateAmount = this.total.find((rt) => rt.bucketLabel === 'MARKUP').rateAmount;
      this.filterUpdatedRates(markupRate);
    } else {
      this.updatedRates.push({
        coverageRatesIdOriginal: null,
        coveragePlanId: this.formData[0].coveragePlanId,
        coverageCode: this.formData[0].coverageCode,
        dateRangeFrom: this.formData[0].dateRangeFrom,
        dateRangeTo: this.formData[0].dateRangeTo,
        term: this.formData[0].term,
        bucketCode: 'DealerMarkUp',
        bucketLabel: 'DealerMarkUp',
        rateAmount: this.getFloat(
          this.total.find((rt) => rt.bucketLabel === 'MARKUP').rateAmount,
        ).toFixed(2),
        categoryCode: 'DealerMarkUp',
        isDeleted: false,
        insertUserName: this.azureService.accountId,
      });
    }

    if (!this.isLoading && this.updatedRates.length > 0) {
      this.isLoading = true;
      this.ratingService.createUpdateCoverageRates(this.updatedRates).subscribe(
        (data) => {
          if (data) {
            this.isLoading = false;
            this.formSubmitEvent.emit(true);
            this.displayDialog = false;
          }
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving data!',
          });
        },
      );
    }
  }

  filterUpdatedRates(latestRateData) {
    const rateData = this.formData.find(
      (rate) =>
        rate.bucketCode === latestRateData.bucketCode &&
        rate.categoryCode === latestRateData.categoryCode,
    );
    const refData = this.formData.find((rate) => !!rate.coverageRatesId);
    if (
      rateData &&
      this.getFloat(rateData.rateAmount) !== this.getFloat(latestRateData.rateAmount) &&
      latestRateData.bucketLabel.toUpperCase() !== 'TOTAL'
    ) {
      this.updatedRates.push({
        coverageRatesIdOriginal: latestRateData.coverageRatesId,
        coveragePlanId: rateData.coveragePlanId ? rateData.coveragePlanId : refData.coveragePlanId,
        coverageCode: rateData.coverageCode ? rateData.coverageCode : refData.coverageCode,
        dateRangeFrom: rateData.dateRangeFrom ? rateData.dateRangeFrom : refData.dateRangeFrom,
        dateRangeTo: rateData.dateRangeTo ? rateData.dateRangeTo : refData.dateRangeTo,
        term: rateData.term ? rateData.term : refData.term,
        bucketCode: rateData.bucketCode,
        bucketLabel: rateData.bucketLabel,
        rateAmount: this.getFloat(latestRateData.rateAmount).toFixed(2),
        categoryCode: rateData.categoryCode,
        isDeleted: false,
        insertUserName: this.azureService.accountId,
      });
    }
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(false);
  }
}
