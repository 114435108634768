export const ScmModuleMapping = {
  contract: {
    header: 'Search Contract',
    type: 'SearchContract'
  },
  claim: {
    header: 'Service Order',
    type: 'SearchServiceOrder'
  }
};
