<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<div class="crm-container">
  <app-section-header [title]="'Servicer Service Type'" [optionHeader]="opHeader" />

  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row"
      [loading]="isTableLoading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text" [(ngModel)]="rowData[col.field]" />
                <input *ngIf="col.type === 'checkbox'" type="checkbox" [(ngModel)]="rowData[col.field]" />
                <ng-template #itemTemplateStatic let-item="item">
                  <a [innerHTML]="item"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound="notFound">
                  <div [innerHTML]="notFound"></div>
                </ng-template>
                <div *ngIf="col.type === 'id' || col.type === 'disabled'">{{rowData[col.field]}}</div>
                <input class="input-text" *ngIf="col.type === 'date'" type="date" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type !== 'checkbox'" pTemplate="output"> {{rowData[col.field]}} </ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" disabled="disabled" />
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="ellipsisClick($event,rowData,opRow, ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger" *ngIf="editing"
              pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary" let-rowData="">
        <div class="text-left">
          <button type="button" [class]="disableLoadMore || isTableLoading ? 'btn-disabled' : 'crm-button'"
            (click)="loadTableData()" [attr.disabled]="disableLoadMore || isTableLoading?true:null">
            Load More
          </button>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<service-type-modal modalTitle="Service Type" [(displayDialog)]="displayServiceInfoModal"
  (formSubmitEvent)="onCreateUpdate($event)" [formData]="selectedRow" *ngIf="displayServiceInfoModal">
</service-type-modal>
<p-toast></p-toast>