<div class="crm-container">
  <app-section-header [title]="title" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <div class="spinner-download" *ngIf="downloadingSpinner">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" [scrollable]="true"
      editMode="row" [hidden]="isLoading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr>
          <td *ngFor="let col of cols">{{rowData[col.field]}}</td>
          <td class="last-cell">
            <div class="three-dots"
              (click)="ellipsisClick($event,rowData,opRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-toast></p-toast>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event, opRow)">
  </p-listbox>
</p-overlayPanel>