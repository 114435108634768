import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { RadioButtonField } from '../shared/form-field/radio-button-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { PhonePattern } from '../shared/constants/validation-regex';

@Injectable({
  providedIn: 'root'
})
export class ServiceModuleService {
  getSeviceFormModalFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'question',
        label: 'Question*',
        order: 1
      }),
      new RadioButtonField({
        key: 'answer',
        label: 'Answer*',
        options: [{ key: 'yes', value: 'Yes' }, { key: 'no', value: 'No' }],
        order: 2
      }),
      new DropdownField({
        key: 'coverage name',
        label: 'Coverage Name',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 3
      }),
      new DropdownField({
        key: 'category/subcategory',
        label: 'Category/subcategory',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 4
      }),
      new DropdownField({
        key: 'manufacturer',
        label: 'Manufacturer',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 5
      }),
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServiceSearchModalFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' }
        ],
        order: 1
      }),
      new TextboxField({
        key: 'priority',
        label: 'Priority',
        order: 2
      }),
      new TextboxField({
        key: 'service provider',
        label: 'Service Provider',
        order: 3
      }),
      new TextboxField({
        key: 'priority',
        label: 'Priority',
        order: 4
      }),
      new TextboxField({
        key: 'claim#',
        label: 'Claim#',
        order: 5
      }),
      new TextboxField({
        key: 'claim import#',
        label: 'Claim Import#',
        order: 6
      }),
      new TextboxField({
        key: 'unique id',
        label: 'Unique Id',
        order: 7
      }),
      new TextboxField({
        key: 'invoice#',
        label: 'Invoice#',
        order: 8
      }),

      new TextboxField({
        key: 'created date',
        label: 'Created date',
        type: 'Date',
        order: 9
      }),
      new TextboxField({
        key: 'created date',
        label: '',
        type: 'Date',
        order: 9
      }),
      new TextboxField({
        key: 'repair date',
        label: 'Repair date',
        type: 'Date',
        order: 10
      }),
      new TextboxField({
        key: 'repair date',
        label: '',
        type: 'Date',
        order: 10
      }),
      new TextboxField({
        key: 'submit for payment date',
        label: 'Submit for payment date',
        type: 'Date',
        order: 11
      }),
      new TextboxField({
        key: 'submit for payment date',
        label: '',
        type: 'Date',
        order: 11
      }),
      new TextboxField({
        key: 'approved date',
        label: 'Approved Date',
        type: 'Date',
        order: 12
      }),
      new TextboxField({
        key: 'approved date',
        label: '',
        type: 'Date',
        order: 12
      }),
      new TextboxField({
        key: 'first name',
        label: 'First Name',
        order: 13
      }),
      new TextboxField({
        key: 'last name',
        label: 'Last Name',
        order: 14
      }),
      new TextboxField({
        key: 'phone number',
        label: 'phone number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 15
      }),
      new TextboxField({
        key: 'manufacturer',
        label: 'Manufacturer',
        order: 16
      }),
      new TextboxField({
        key: 'model',
        label: 'Model',
        order: 17
      }),
      new TextboxField({
        key: 'customer feedback',
        label: 'Customer feedback',
        order: 18
      }),
      new TextboxField({
        key: 'preauthorize',
        label: 'Preauthorize',
        order: 19
      }),
      new TextboxField({
        key: 'technician name',
        label: 'Technician Name',
        order: 20
      }),
      new TextboxField({
        key: 'assigned to',
        label: 'Assigned to',
        order: 21
      }),
      new TextboxField({
        key: 'check number',
        label: 'Check Number',
        order: 22
      }),
      new TextboxField({
        key: 'check date',
        label: 'Check Date',
        order: 23,
        type: 'Date'
      }),
      new TextboxField({
        key: 'check date',
        label: '',
        order: 23,
        type: 'Date'
      }),
      new TextboxField({
        key: 'created by',
        label: 'Created By',
        order: 24
      }),
      new TextboxField({
        key: 'audit status',
        label: 'Audit Status',
        order: 25
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
