<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<p-toast> </p-toast>
<div class="crm-container">

  <app-section-header [title]="'Servicer'" />

  <div class="sub-section">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="form-row" *ngFor="let formField of formFields" [ngClass]="{
            'checkbox col-1': formField.controlType === 'checkbox',
            'col-5': formField.controlType === 'dropdown'
          }">
          <form-field [formField]="formField" [form]="form" (changeEvent)="onChangeEvent($event, formField.key)">
          </form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="sub-section">
    <app-section-header [title]="'Servicer'" [optionHeader]="opHeader" />
    <p-table styleClass="p-datatable-gridlines" [columns]="selectedColumns" [value]="dealerServicer" dataKey="id"
      editMode="row" [loading]="isLoading">
      <ng-template pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns">
          </p-multiSelect>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit': isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor [style.font-weight]="col.font">
              <ng-template pTemplate="input">
                <p-dropdown *ngIf="col.type === 'select' && col.field === 'servicerProvider'"
                  [options]="serviceProviderOptions" [filter]="true" placeholder="Select"
                  (onChange)="onServicerDropDownChange($event, ri)" optionLabel="key" appendTo="body"
                  [(ngModel)]="rowData.servicerProvider">
                </p-dropdown>
                <p-dropdown *ngIf="col.type === 'select' && col.field === 'type'" [options]="serviceType"
                  [filter]="true" placeholder="Select" (onChange)="onTypeDropDownChange($event, ri)" optionLabel="value"
                  appendTo="body" [(ngModel)]="rowData.type">
                </p-dropdown>
                <div *ngIf="col.type === 'id'">{{ rowData[col.field] }}</div>
              </ng-template>
              <ng-template pTemplate="output">{{ rowData[col.field] }}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" (click)="ellipsisClick($event, rowData, optionRow, ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{ editConstant + rowData['id'] }}"
              pButton="pButton" type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData, ri)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>