import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderCrudOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { ServicerServiceArea } from './servicer-rates.service';
import { PriceTable } from '../../../crm-coverage/coverage-models';
import { buttonStatus } from '../../../shared/constants/button-class';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'service-rate-tab',
  templateUrl: 'servicer-rates.template.html',
  providers: [ServicerServiceArea],
})
export class ServicerRatesComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderCrudOptions: any[] = ellipsisHeaderCrudOptions;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  tabKey: string;
  isRefresh: boolean;
  querySubscriptions: Subscription[] = [];
  isLoading: boolean;
  componentsToLoad = 0; // will be set dynamically
  componentsLoaded = -1; // will be set dynamically, set to -1, as the loader is false by default
  constructor(
    private servicerServiceArea: ServicerServiceArea,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private tabHandlerService: TabHandlerService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'include_manufacturer', header: 'INCLUDE MANUFACTURER', type: 'text' },
      { field: 'include_model', header: 'INCLUDE MODEL', type: 'text' },
      { field: 'include_zipcode', header: 'INCLUDE ZIPCODE', type: 'text' },
      { field: 'radius', header: 'RADIUS', type: 'text' },
    ];

    this.querySubscriptions.push(
      this.servicerServiceArea.isLoading.subscribe({
        next: (isLoading) => {
          if (isLoading) {
            this.isLoading = isLoading;
            this.componentsToLoad += 1;
            this.cdr.detectChanges();
          } else {
            this.componentsLoaded += 1;
          }

          // this.componentsLoaded will be eqaual to this.componentsToLoad
          // once all the components are loaded, but need to check greater than considering save/update click
          if (this.componentsLoaded >= this.componentsToLoad) {
            this.isLoading = false;
            this.cdr.detectChanges();
          }
        },
      }),
    );

    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          // reload child components
          this.isRefresh = true;
          this.isRefresh = false;
          this.servicerServiceArea.getServiceArea().subscribe((data) => {
            this.dataList = data;
          });
        }
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }
}
