<div class="crm-container">
  <app-section-header [title]="'Work Queue List Results  ( ' + resultCount + ' item(s) found )'"
    [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" *ngIf="isDelete" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="rowData[col.field].toLowerCase()">{{rowData[col.field]}}</button>
            <p-cellEditor>
              <ng-template pTemplate="input"><input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><p-dropdown *ngIf="col.type === 'select'"
                  [options]="dropdown[col.field]"
                  [(ngModel)]="rowData[col.field]"></p-dropdown></ng-template><ng-template
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
          <td class="last-cell" *ngIf="isDelete">
            <div *ngIf="!editing"></div>
            <div class="three-dots" 
              (click)="ellipsisClick($event,rowData,opRow, ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"></button><button
              class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger" *ngIf="editing"
              pButton="pButton" type="button" pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button class="btn-crm-success" type="button" (click)="loadWorkQueueList()"
      [attr.disabled]="disableLoadMore?true:null" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<work-queue-list-modal [modalTitle]="'Add Work Queue'" [(displayDialog)]="displayServiceInfoModal"
  (formSubmitEvent)="onCreateUpdate($event)" *ngIf="displayServiceInfoModal">
</work-queue-list-modal>
<p-toast> </p-toast>