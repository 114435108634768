<p-toast></p-toast>
<div class="crm-container">
  <app-section-header [title]="'Service Center Lookup Results'" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'" [class]="btnStatus[rowData[col.field].toLowerCase()]">
              {{rowData[col.field]}}
            </button>
            <p-cellEditor>
              <ng-template pTemplate="input"><input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output">
                {{rowData[col.field]}}
              </ng-template>
              <ng-template *ngIf="col.type === 'button'" pTemplate="output">
                <button class="ui-button-success" pButton="pButton" *ngIf="rowData[col.field]" type="button"
                  pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"></button>
                <button class="ui-button-danger" pButton="pButton" *ngIf="!rowData[col.field]" type="button"
                  pSaveEditableRow="pSaveEditableRow" icon="pi pi-times" style="margin-right: 0.5em"></button>
              </ng-template>
              <ng-template *ngIf="col.field === 'servicer_name'" pTemplate="output">
                <a (click)="openTab(rowData)" onmouseover="this.style.cursor='pointer'">
                  {{rowData[col.field] | uppercase}}
                </a>
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button class="btn-crm-success" type="button" (click)="loadServiceCenterLookupData()"
      [attr.disabled]="disableLoadMore?true:null" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #optionRow>
  <p-listbox [options]="ellipsisRowOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #optionHeader>
  <p-listbox [options]="ellipsisHeaderOptions" (onClick)="handleOpen($event)"> </p-listbox>
</p-overlayPanel>
<p-dialog header="Verify Email Address" [(visible)]="displayVerify" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <p>
    The email address must be verified before it can be used to send the email as the source. An
    email verification will be sent to verify.<label>Email Address</label><input />
  </p>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="displayVerify=false" label="No">
        Cancel
      </button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onYes()" label="Yes">
        Save
      </button>
    </div>
  </ng-template>
</p-dialog>