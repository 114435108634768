import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TabHandlerService {
  constructor() {}

  public selectedTab = new Subject<string>();
  public setSelectedTab(value) {
    this.selectedTab.next(value);
  }
}
