<app-tab-spinner *ngIf="isLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; width:100%"></app-tab-spinner>
<div class="crm-panel" style="position: relative; box-shadow: 0 0 100px rgba(0,0,0,0.3);">  
  <div class="crm-top">
    <div class="crm-panel__left">
      <service-order-form (formValueChanged)="setChangeData($event)"></service-order-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <div class="button-bar">
    <div><button (click)="onClickValidator()">Validator Tool</button></div>
  </div>
  <div class="crm-bottom" *ngIf="componentDisplay">
    <div *ngIf="loadNote">
      <crm-notes
        *appSetRolePermissionsDirective="{type:'component', operation:'READ_ONLY', permission:'Notes', category: 'Claim'}"
        [parentCategory]="'Claim'" [category]="notesCategoryType" [identifierId]="identifierId"
        [disclaimer]="disclaimer" [disableAdd]="disclaimer" [parentComponent]="component"
        (dataRetrieved)="onNotesData($event)" #notesComponent>
      </crm-notes>
    </div>
  </div>
</div>
<crm-alert-modal *ngIf="alertService.displayAlertModal" [modalTitle]="alertService.alertTitle"
  [modalContent]="alertService.alert && alertService.alert.message" [isBackgroundFreeze]="false"
  [(displayDialog)]="alertService.displayAlertModal" (displayDialogChange)="onAlertDisplayChange($event)">
</crm-alert-modal>
<validator-tool-modal modalTitle="Validator Tool" [(displayDialog)]="displayValidator" [formFields]="formFields"
  (formSubmitEvent)="onValidatorSubmit($event)">
</validator-tool-modal>
<p-dialog header="Proceed With Dispatch" [(visible)]="displayClaim" [style]="{width: '300px', height: 'auto'}">
  <div>Proceed With Dispatch</div>
</p-dialog>