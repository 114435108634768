import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssociatedContract } from './associated-contracts';
import { ContractService } from '../../contract.service';
import { TabsService } from '../../../services/tabs.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { associatedContractsHeaderOptions } from './associated-contracts.ellipsis';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { MessageService } from 'primeng/api';
import { AssociatedContractService } from './associated-contracts.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RolePermissionService } from '../../../common/role-permission.service';
@Component({
  selector: 'associated-contracts',
  templateUrl: './associated-contracts.template.html',
  styleUrls: ['associated-contracts.scss'],
  providers: [FormFieldControlService, MessageService, AssociatedContractService],
})
export class AssociatedContractsComponent implements OnInit, OnDestroy {
  associatedContracts: AssociatedContract[];
  cols: any[];
  ellipsisHeaderOptions: any[] = associatedContractsHeaderOptions;
  ellipsisHeaderOptionsSec: any;
  formFields: FormField<string>[] = [];
  form = new FormGroup({});
  displayOptions = false;
  displayUnassociate = false;
  isLoading = false;
  disableSave = true;
  querySubscriptions: Subscription[] = [];
  constructor(
    private contractService: ContractService,
    private tabService: TabsService,
    private qcs: FormFieldControlService,
    private messageService: MessageService,
    private associatedContractService: AssociatedContractService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'contractNumber', header: 'CONTRACT#', type: 'link' },
      { field: 'modelName', header: 'MODEL', type: 'text' },
      { field: 'serialNumber', header: 'SERIAL#', type: 'text' },
      { field: 'contractsIdOriginal', header: 'CONTRACT ID', type: 'link' },
    ];
    this.associatedContractService.getFormFields().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
      this.form = this.qcs.toFormGroup(this.formFields);
    });
    this.contractService.isLoading.next(true);
    this.contractService.isMainTabDataAvailable.subscribe({
      next: (isMainTabDataAvailable) => {
        if (isMainTabDataAvailable) {
          this.associatedContracts =
            this.contractService.contractDataModel.mainTab.associatedContracts;
          if (this.associatedContracts && this.associatedContracts.length > 0) {
            this.displayOptions = true;
            this.refreshAssociatedList();
          }
        }
        this.contractService.isLoading.next(false);
      },
    });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'DELETE',
      permission: 'Associated Contracts',
      category: 'Contract',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
  }

  openTab(rowData) {
    rowData.customerId = this.contractService.contractConfig.searchParams.customerId;
    rowData.originalId = rowData.contractsIdOriginal;
    this.tabService.createTab({
      header: 'Contract',
      content: rowData,
      type: 'Contract',
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    overlaypanel.toggle(event);
  }

  onClickAdd(event) {
    this.displayUnassociate = true;
  }

  onSubmit() {
    const payload: any = this.form.getRawValue();
    if (payload.contractList) {
      const contractListArray = payload.contractList.map(
        (contractNames) => `'${contractNames.value}'`,
      );
      const contractList = contractListArray.join(',');
      const requestPayload = {
        contractList,
        insertUserName: this.azureService.accountId,
      };
      this.isLoading = true;
      this.querySubscriptions.push(
        this.associatedContractService.unassociateContractsRequest(requestPayload).subscribe(
          ({ data }) => {
            if (data && data.unassociateContract) {
              const contractsReturned = data.unassociateContract.contractList;
              this.removeUnassociatedList(contractsReturned);
              this.isLoading = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `[Count=${contractsReturned.length}] Contracts Un-Associated Successfully.`,
              });
              this.displayUnassociate = false;
            }
          },
          (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error adding file to export process.',
            });
            this.isLoading = false;
            throw err;
          },
        ),
      );
    }
  }

  removeUnassociatedList(contractsReturned) {
    const contractNumberKey = 'contractNumber';
    const newAssociatedList = [];
    for (const singleContract of this.associatedContracts) {
      const contractNumber = singleContract[contractNumberKey];
      if (!contractsReturned.includes(contractNumber)) {
        newAssociatedList.push(singleContract);
      }
    }
    this.associatedContracts = newAssociatedList;
    this.refreshAssociatedList();
  }

  refreshAssociatedList() {
    const contractNumberKey = 'contractNumber';
    const newOptions = [];
    this.associatedContracts.map((contractDetails) => {
      newOptions.push({
        key: contractDetails[contractNumberKey],
        value: contractDetails[contractNumberKey],
      });
    });
    const newFormFields: FormField<any>[] = [];
    for (const singleForm of this.formFields) {
      const singleFormField: FormField<any> = singleForm;
      if (singleFormField.key === 'contractList') {
        singleFormField.options = newOptions;
      }
      newFormFields.push(singleFormField);
    }
    this.formFields = newFormFields;
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x: any) => {
      const contractList = x.contractList;
      if (contractList.length > 0) {
        this.disableSave = false;
      } else {
        this.disableSave = true;
      }
    });
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onCancel() {
    this.displayUnassociate = false;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
