import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders
} from '@angular/common/http';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';

@Injectable()
export class EmailService {
  templateDetails = {};
  typeMapping = {};

  constructor(private httpClient: HttpClient, private azureService: AzureLoginService) {}

  public sendEmail(request): Observable<any> {
    const header = new HttpHeaders({
      'content-type': 'application/json',
      Authorization: `Bearer ${this.azureService.accessToken}`
    });
    const response = this.httpClient.post(environment.emailUrl, JSON.stringify(request), {
      headers: header
    });
    return response;
  }
}
