import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab',
  templateUrl: 'tab.template.html',
  styleUrls: ['tab.scss'],
})
export class TabComponent {
  @Input() title: string;
  @Input() active: boolean;
  @Input() tabname: string;
  @Input() tabKey: string;
}
