import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { SecurityTypeService } from '../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import {
  ellipsisRestatusHeaderOptions,
  ellipsisRestatusRowOptions,
} from '../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { Observable, Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { copy } from '../../../shared/utilities/common-utilities';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'admin-security-restatus',
  templateUrl: 'sec-restatus.template.html',
  styleUrls: ['sec-restatus.scss'],
  providers: [
    SecurityTypeService,
    RoleAuthorizationService,
    ContractFormFieldService,
    FormFieldControlService,
    MessageService,
  ],
})
export class SecurityRestatusComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Input() formFields: FormField<string>[] = [];
  form = new FormGroup({});
  cols: any[];
  header: string;
  dataList: any[] = [];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = ellipsisRestatusHeaderOptions;
  ellipsisRowOptions: any[] = ellipsisRestatusRowOptions;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayUpdate: boolean;
  lastOffsetVal = 0;
  offsetLimit = 50;
  disableLoadMore = false;
  resultCount = 0;
  params = {
    roleName: null,
    fromStatus: null,
    toStatus: null,
    limit: null,
    offset: null,
    azureUserId: null,
  };
  constructor(
    private configurationService: SecurityTypeService,
    private contractService: ContractFormFieldService,
    private cdr: ChangeDetectorRef,
    private azureService: AzureLoginService,
    private qcs: FormFieldControlService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
  }

  isLoading = false;
  querySubscription: Subscription;

  ngOnInit() {
    this.editConstant = uuidv4();
    this.getRestatusData();
    this.cols = [
      { field: 'roleName', header: 'Role Name', type: 'text' },
      { field: 'fromStatus', header: 'From Status', type: 'text' },
      { field: 'toStatus', header: 'To Status', type: 'text' },
    ];
    this.contractService.getAddSecurityRestatusFields().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
      this.form = this.qcs.toFormGroup(this.formFields);
      this.form.valueChanges.subscribe((x) => {
        this.formValueChanged.emit(true);
      });
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onSubmit() {
    this.isLoading = true;
    const payload: any = this.form.getRawValue();
    let roleIds = [];
    if (typeof payload.roleName === 'object') {
      roleIds = payload.roleName.map((singleRole) => parseInt(singleRole.value, 10));
    }
    const params = {
      roleId: roleIds,
      statusID: parseInt(payload.fromStatus.value, 10),
      allowedNextStatusId: parseInt(payload.toStatus.value, 10),
      insertUserName: this.azureService.accountId,
    };
    this.configurationService.saveRestatus(params).subscribe(
      (results) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Record Saved Successfully.`,
        });
        this.displayAdd = false;
        this.getRestatusData(true);
      },
      (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to update restatus`,
        });
      },
    );
  }

  onCancel() {
    this.displayAdd = false;
  }

  onRowCreate(event) {
    this.displayAdd = true;
    this.header = 'Add Restatus';
  }

  onRowDelete(event) {
    const payload = this.selectedItem;
    const params = {
      isDeleted: true,
      statusToAllowableNextStatusIdOriginal: payload.statusToAllowableNextStatusIdOriginal,
      insertUserName: this.azureService.accountId,
    };
    console.log('payload: ' + JSON.stringify(payload));
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the restatus?<br>
       <br><b>Role Name=</b>${payload.roleName}
       <br><b>From Status=</b>${payload.fromStatus}
       <br><b>To Status=</b>${payload.toStatus}`,
      accept: () => {
        this.isLoading = true;
        this.configurationService.saveRestatus(params).subscribe(
          (results) => {
            this.isLoading = false;
            console.log('Results: ' + JSON.stringify(results));
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Record Saved Successfully.`,
            });
            this.getRestatusData(true);
          },
          (err) => {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Failed to delete restatus`,
            });
          },
        );
      },
    });
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  getRestatusData(reload: boolean = false) {
    this.isLoading = true;
    if (this.lastOffsetVal === 0 && this.searchParams && typeof this.searchParams === 'object') {
      this.params = this.searchParams;
      this.params.roleName = this.getValuesArray(this.params.roleName);
      this.params.fromStatus = this.getValuesArray(this.params.fromStatus);
      this.params.toStatus = this.getValuesArray(this.params.toStatus);
    }

    this.params.limit = this.offsetLimit;
    this.params.offset = reload ? 0 : this.lastOffsetVal;
    this.params.azureUserId = this.azureService.accountId;
    this.lastOffsetVal += this.offsetLimit;
    if (reload) {
      this.dataList = [];
    }

    this.querySubscription = this.configurationService.getRestatusDetails(this.params).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = false;
        const modifedData = copy(data.getAdminRestatus);
        this.disableLoadMore =
          Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
        const tableValues = [];
        for (const singleRole of data.getAdminRestatus) {
          this.dataList.push({
            AllowId: singleRole.allowedNextStatusId,
            roleName: singleRole.roleName,
            fromStatus: singleRole.fromStatus,
            toStatus: singleRole.toStatus,
            statusToAllowableNextStatusIdOriginal: singleRole.statusToAllowableNextStatusIdOriginal,
          });
        }
        this.resultCount = this.dataList.length;
      },
      (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to retrieving restatus`,
        });
      },
    );
  }

  getValuesArray(keyValues) {
    if (keyValues === null) {
      return null;
    }
    if (typeof keyValues === 'object') {
      const keys = [];
      for (const singleObject of keyValues) {
        if (singleObject.key !== '') {
          keys.push(singleObject.key);
        }
      }
      if (keys.length === 0) {
        return null;
      }
      return keys;
    } else {
      return null;
    }
  }
}
