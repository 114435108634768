import { Component, Output, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { ServiceTypeService } from '../../services.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../../service/service-ellipsis';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { Observable, Subscription } from 'rxjs';
import { SearchServicerService } from './search-servicer.service';
import { Tab } from '../../../services/tab';
import { TabsService } from '../../../services/tabs.service';
import { ServicerDisplayStatus } from '../servicer.constant';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ModuleType } from '../../../shared/constants/enums';
import { CrmService } from '../../../services/crm-service';
import { copy } from '../../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'search-servicer',
  templateUrl: 'search-servicer.template.html',
  styleUrls: ['../../../shared/styles/crm-table.scss'],
  providers: [SearchServicerService, MessageService],
})
export class SearchServicerComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  cols: any[];
  dataList: any[];
  data: any;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  ellipsisHeaderOptionsSec: any;
  ellipsisRowOptionsSec: any;
  servicerDisplayStatus = ServicerDisplayStatus;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  querySubscription: Subscription;
  isLoading = false;
  newTab: Tab;
  authDetails: IRoleAuthorizationService;
  lastOffsetVal = 0;
  offsetLimit = 10;
  disableLoadMore = false;
  resultCount = 0;
  selectedRowIndex: number;
  constructor(
    private search: SearchServicerService,
    private tabService: TabsService,
    private roleService: RoleAuthorizationService,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService,
    private crmService: CrmService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {
    if (this.azureService.roleName !== 'Admin') {
      this.ellipsisRowOptions = this.ellipsisRowOptions.filter((x) => x.value !== 'Delete');
    }
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SearchServicerComponent,
      generalArray: this.ellipsisRowOptions,
    };
    this.ellipsisRowOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'name', header: 'SERVICER NAME', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'zipCode', header: 'ZIP', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
    ];

    this.getSearchData();

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Servicer',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisRowOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Servicer',
      category: 'Servicer',
    };
    this.ellipsisRowOptions = this.rps.evaluate(
      this.ellipsisRowOptionsSec,
      this.ellipsisRowOptions,
    );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    this.newTab = {
      header: 'Servicer Profile',
      content: event.value == 'edit' ? this.selectedItem : null,
      type: 'Servicer'
    };
    this.tabService.createTab(this.newTab);
    overlaypanel.hide();
  }
  openTab(data: any) {
    this.newTab = { header: 'Servicer Profile', content: data, type: 'Servicer' };
    this.tabService.createTab(this.newTab);
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.servicerProfilesIdOriginal).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  getSearchData() {
    this.isLoading = true;
    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string' ? this.searchParams : {};

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;

    this.lastOffsetVal += this.offsetLimit;
    if (!this.searchParams.status) {
      this.searchParams.status = { key: '', value: '' };
    }
    this.querySubscription = this.search.getServiceDetails(this.searchParams).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        const modifedData = data.getServicerSearchResults;
        this.disableLoadMore =
          Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
        this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
        this.resultCount = this.dataList.length;
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    const payload = {
      idOriginal: this.selectedItem.servicerProfilesIdOriginal,
      moduleName: ModuleType.SERVICE,
      azureUserId: this.azureService.accountId,
    };
    this.confirmationService.confirm({
      message: 'Are you sure, you want to delete this servicer ?',
      accept: () => {
        this.isLoading = true;
        this.crmService.deleteCrmModule(payload).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            if (!data.deleteCrmModule) {
              // throw new Error('Error in deleting the servicer.');
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Servicer cannot be deleted. ',
              });
            }
            this.dataList.splice(this.selectedRowIndex, 1);
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
      },
    });
    overlaypanel.hide();
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
