import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import gql from 'graphql-tag';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { GenericDealerStatus } from '../dealer.constants';
import { Apollo } from 'apollo-angular';

const payeeSearch = gql`
  query(
    $status: String!
    $number: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $phoneNumber: String
    $associationType: String
    $associationName: String
  ) {
    getPayeeSearchResults(
      status: $status
      number: $number
      name: $name
      city: $city
      state: $state
      zipcode: $zipCode
      phoneNumber: $phoneNumber
      associationType: $associationType
      associationName: $associationName
    ) {
      claimPayeeidOriginal
      status
      number
      name
      city
      state
      status
      associationType
      associationName
      phoneNumber
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class SearchPayeeService {
  constructor(private apollo: Apollo) {}

  getPayeeDetails = data =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: payeeSearch,
      variables: { ...data, status: data.status.value || GenericDealerStatus.Active }
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
