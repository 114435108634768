<div class="crm-container">
  <app-section-header [title]="'Manufacturer Results ' + dataList.length " [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-toast></p-toast>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="cols" [value]="dataList"
      dataKey="id" editMode="row" [hidden]="isLoading"><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing"
        let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button><p-cellEditor
              *ngIf="col.type === 'text'"><ng-template pTemplate="input"><input class="input-text" type="text"
                  [(ngModel)]="rowData[col.field]" style="text-transform: uppercase" /></ng-template><ng-template
                pTemplate="output">{{rowData[col.field] | uppercase }}</ng-template></p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="ellipsisClick($event,rowData,opRow)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData,opRow); globalIndex=ri"></button><button class="ui-button-success"
              *ngIf="editing" pButton="pButton" type="button" pSaveEditableRow="pSaveEditableRow" icon="pi pi-check"
              style="margin-right: 0.5em" (click)="onRowEditSave(rowData)"></button><button class="ui-button-danger"
              *ngIf="editing" pButton="pButton" type="button" id="{{editConstant + rowData.id}}cancelBtn"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template></p-table><button class="btn-crm-success" type="button" (click)="getManufacturerSearchData()"
      [hidden]="isLoading">Load More</button>
  </div>
</div>
<p-overlayPanel #opRow><p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-overlayPanel
  #opHeader><p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel>