import { Component, Input, OnInit, ViewChild, OnDestroy, ViewContainerRef } from '@angular/core';
import { NavDirective } from './nav.directive';
import { NavItem } from './nav-item';
import { NavBase } from './nav-base';

@Component({
  selector: 'nav-loader',
  templateUrl: 'nav-loader.template.html',
})
export class NavLoaderComponent implements OnInit, OnDestroy {
  @Input() navItem: NavItem;
  @ViewChild('appNavigHost', { read: ViewContainerRef }) navHost: NavDirective;
  interval: any;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  loadComponent() {
    const componentRef = this.viewContainerRef.createComponent(this.navItem.component);
    (componentRef.instance as NavBase).data = this.navItem.data;
    (componentRef.instance as NavBase).tabKey = this.navItem.tabKey;
  }
}
