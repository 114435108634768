import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BatchProcessingService } from './batch-processing.service';
import { buttonStatus } from '../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisBatchProcessOptions,
  ellipsisBatchProcessingMainOptions,
} from '../../../shared/constants/ellipsis-options';
import { copy, numberWithCommas } from '../../../shared/utilities/common-utilities';
import { getDateFromString, getMMDDYYYFormat } from '../../../shared/utilities/date-utilities';
import { TabsService } from '../../../services/tabs.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { RolePermissionService } from '../../../common/role-permission.service';
@Component({
  selector: 'batch-processing',
  templateUrl: 'batch-processing.template.html',
  styleUrls: ['batch-processing.scss'],
  providers: [BatchProcessingService],
})
export class BatchProcessingComponent implements OnInit {
  @Input() searchParams: any;
  admin: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  selectedRow: any;
  ellipsisOptions: any[] = ellipsisBatchProcessOptions;
  ellipsisMainOptions: any[] = ellipsisBatchProcessingMainOptions;
  ellipsisOptionsSec: any;
  selectedEllipsisItem: any;
  display = false;
  isDate = false;
  isComment = false;
  title = '';
  label = '';
  buttonLabel = '';
  selectedDate = '';
  enteredComment = '';
  selectedType = '';
  isLoading = false;
  isApproved = true;
  totalDealerCost: any;
  planCount: any;
  filterOn = false;
  pageNo = 0;
  filteredValues: any[];
  batchProcessResults: any;
  permissionOptions: any[] = [
    {
      type: 'three-dots',
      operation: 'NLF',
      permission: 'Override Transaction Date',
      category: 'User Security',
    },
    {
      type: 'three-dots',
      operation: 'CANCELLATION',
      permission: 'Override Cancellation Transaction Date',
      category: 'User Security',
    },
    { type: 'three-dots', operation: 'VOID', permission: 'Void Files', category: 'User Security' },
  ];
  @ViewChild('dt') dataTableRef;
  constructor(
    private batchProcessingService: BatchProcessingService,
    private tabService: TabsService,
    private azureService: AzureLoginService,
    private roleService: RoleAuthorizationService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.getBatchProcessResults();
    this.cols = [
      { field: 'fileStatus', header: 'STATUS', type: 'status' },
      { field: 'fileType', header: 'FILE TYPE', type: 'text' },
      { field: 'dealerName', header: 'DEALER NAME', type: 'text' },
      { field: 'fileName', header: 'FILE NAME', type: 'text' },
      { field: 'uploadDate', header: 'UPLOAD DATE', type: 'text' },
      { field: 'planCount', header: 'PLAN COUNT', type: 'text' },
      { field: 'contractRange', header: 'CONTRACT RANGE', type: 'text' },
      { field: 'dealerCost', header: 'DEALER COST', type: 'text' },
      { field: 'nlfTransactionDate', header: 'NLF TRANSACTION DATE', type: 'text' },
      {
        field: 'cancellationTransactionDate',
        header: 'CANCELLATION TRANSACTION DATE',
        type: 'text',
      },
      { field: 'dEdit', header: 'EDIT', type: 'text' },
    ];
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'MULTIPLE',
      data: this.permissionOptions,
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getBatchProcessResults() {
    let searchParams = {};
    if (this.searchParams === 'Batch Processing') {
      searchParams = {
        fileStatus: 'COMPLETE',
      };
    } else {
      searchParams = { ...this.searchParams };
    }
    this.isLoading = true;
    this.batchProcessingService
      .getBatchProcessSearchResults(searchParams)
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        const response = copy(data.getBatchProcessSearchResults);
        this.batchProcessResults = copy(data.getBatchProcessSearchResults);
        const canSeeUnprocessed = this.roleService.validateIfPermissionsExist({
          category: 'User Security',
          permission: 'Unprocessed Contracts',
          operation: 'READ_ONLY',
          type: 'Code',
        });
        this.admin = response.map((el) => {
          el.uploadDate = getDateFromString(el.uploadDate);
          if (el.fileType === 'C') {
            el.contractRange = '';
            el.dealerCost = `-${el.dealerCost}`;
            el.extendedDealerCost = `-${el.extendedDealerCost}`;
            el.cancellationTransactionDate = this.getDatePart(
              el.cancellationTransactionDate,
              el.fileStatus,
            );
          } else {
            el.nlfTransactionDate = this.getDatePart(el.nlfTransactionDate, el.fileStatus);
          }
          if (el.fileStatus === 'UNPROCESSED') {
            if (canSeeUnprocessed) {
              return el;
            }
          } else {
            return el;
          }
        });
        this.admin = this.admin.filter((el) => el !== undefined);
        this.calculatePerPageInfo(0);
      });
  }

  calculatePerPageInfo(pageNo) {
    this.totalDealerCost = this.admin.filter((e, index) => index >= pageNo && index < (pageNo + 10))
      .reduce(
        (a, b) => a + (parseFloat(String(b.dealerCost).replace('$', '').replace(',', '')) || 0),
        0,
      )
      .toFixed(2);
    this.planCount = this.admin.filter((e, index) => index >= pageNo && index < (pageNo + 10)).reduce(
      (a, b) => a + (parseInt(String(b.planCount).replace('$', ''), 10) || 0),
      0,
    );
  }

  onFilterChange(event) {
    if (event.filteredValue.length > 0 && event.filteredValue.length !== this.admin.length) {
      this.filteredValues = event;
      this.filterOn = true;
      if (event.filteredValue.length === 1) {
        this.planCount = event.filteredValue[0].planCount;
        this.totalDealerCost = event.filteredValue[0].dealerCost;
      } else {
        this.totalDealerCost = event.filteredValue.filter((e, index) => index >= this.pageNo && index < (this.pageNo + 10))
          .reduce(
            (a, b) => a + (parseFloat(String(b.dealerCost).replace('$', '').replace(',', '')) || 0),
            0,
          )
          .toFixed(2);
        this.planCount = event.filteredValue.filter((e, index) => index >= this.pageNo && index < (this.pageNo + 10)).reduce(
          (a, b) => a + (parseInt(String(b.planCount).replace('$', ''), 10) || 0),
          0,
        );
      }
    } else {
      this.filterOn = false;
      this.calculatePerPageInfo(0);
    }
  }

  onPageChange(event) {
    if (this.filterOn) {
      this.pageNo = event.first;
      this.onFilterChange(this.filteredValues)
    } else {
      this.calculatePerPageInfo(event.first);
    }
  }

  getDatePart(dateStr, fileStatus = '') {
    if (
      fileStatus.toUpperCase() === 'PARTIAL' &&
      (dateStr === null || dateStr === '' || dateStr === 'UNPROCESSED' || dateStr === '1/1/1990')
    ) {
      return 'PARTIAL';
    }
    if (dateStr === null || dateStr === '' || dateStr === 'UNPROCESSED' || dateStr === '1/1/1990') {
      return 'UNPROCESSED';
    }
    return getMMDDYYYFormat(dateStr, 'MM/DD/YYYY');
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    this.selectedRow = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    overlaypanel.toggle(event);
  }

  openTab(rowData) {
    this.tabService.createTab({
      header: 'SearchContract',
      content: rowData,
      type: 'SearchContract',
    });
  }

  onEllipsisItemSelect(event, overlaypanel: OverlayPanel) {
    switch (event.option.value) {
      case 'voidFile':
        if (String(this.selectedRow.fileType).toUpperCase() === 'S') {
          this.onOpeningModal('VF', this.selectedRow);
        }
        break;
      case 'nlfTransactionDateOverride':
        if (
          String(this.selectedRow.fileType).toUpperCase() === 'S' &&
          String(this.selectedRow.nlfTransactionDate).toUpperCase() !== 'UNPROCESSED' &&
          String(this.selectedRow.nlfTransactionDate).toUpperCase() !== 'PARTIAL'
        ) {
          this.onOpeningModal('NLF', undefined);
        }
        break;
      case 'cancellationDateOverride':
        if (
          String(this.selectedRow.fileType).toUpperCase() === 'C' &&
          String(this.selectedRow.cancellationTransactionDate).toUpperCase() !== 'UNPROCESSED' &&
          String(this.selectedRow.cancellationTransactionDate).toUpperCase() !== 'PARTIAL'
        ) {
          this.onOpeningModal('CAN', undefined);
        }
        break;
      default:
        break;
    }
    overlaypanel.hide();
  }

  onOpeningModal(item, rowdata) {
    this.enteredComment = '';
    if (rowdata) {
      this.selectedRow = rowdata;
    }
    if (this.onValidate(this.selectedRow, item)) {
      this.selectedType = '';
      switch (item) {
        case 'S':
          this.title = 'ADD NLF TRANSACTION DATE';
          this.label = 'NLF TRANSACTION DATE';
          this.buttonLabel = 'COMPLETE CONTRACT ACTIVATION';
          this.isDate = true;
          this.isComment = false;
          this.selectedType = 'S';
          this.display = true;
          break;
        case 'C':
          this.title = 'ADD CANCELLATION TRANSACTION DATE';
          this.label = 'CANCELLATION TRANSACTION DATE';
          this.buttonLabel = 'COMPLETE CANCELLATION';
          this.isDate = true;
          this.isComment = false;
          this.selectedType = 'C';
          this.display = true;
          break;
        case 'VF':
          this.title = 'VOID FILE';
          this.buttonLabel = 'COMPLETE VOID';
          this.isDate = false;
          this.isComment = true;
          this.selectedType = 'VF';
          this.display = true;
          break;
        case 'NLF':
          this.title = 'NLF TRANSACTION DATE OVERRIDE';
          this.label = 'NEW TRANSACTION DATE';
          this.buttonLabel = 'COMPLETE OVERRIDE';
          this.isDate = true;
          this.isComment = true;
          this.selectedType = 'NLF';
          this.display = true;
          break;
        case 'CAN':
          this.title = 'CANCELLATION DATE OVERRIDE';
          this.label = 'NEW TRANSACTION DATE';
          this.buttonLabel = 'COMPLETE OVERRIDE';
          this.isDate = true;
          this.isComment = true;
          this.selectedType = 'CAN';
          this.display = true;
          break;
        default:
          break;
      }
    }
  }

  showAddOption(rowData) {
    if (rowData.fileStatus === 'PROCESSING') {
      return false;
    }
    if (rowData.fileType.toUpperCase() === 'C') {
      if (
        rowData.fileStatus !== 'VOIDED' &&
        rowData.fileStatus !== 'COMPLETE' &&
        (rowData.cancellationTransactionDate === 'UNPROCESSED' ||
          rowData.cancellationTransactionDate === 'PARTIAL')
      ) {
        return true;
      }
    }
    if (rowData.fileType.toUpperCase() === 'S') {
      if (
        (rowData.nlfTransactionDate === 'UNPROCESSED' ||
          rowData.nlfTransactionDate === 'PARTIAL') &&
        rowData.fileStatus !== 'VOIDED' &&
        rowData.fileStatus !== 'COMPLETE'
      ) {
        return true;
      }
    }
    return false;
  }

  openModelAdd(item, rowdata) {
    if (rowdata) {
      this.selectedRow = rowdata;
    }
    this.selectedType = '';
    switch (item) {
      case 'S':
        this.title = 'ADD NLF TRANSACTION DATE';
        this.label = 'NLF TRANSACTION DATE';
        this.buttonLabel = 'COMPLETE CONTRACT ACTIVATION';
        this.isDate = true;
        this.isComment = false;
        this.selectedType = 'S';
        this.display = true;
        this.isApproved = this.roleService.validateIfPermissionsExist({
          category: 'User Security',
          permission: 'Transaction Date',
          operation: 'ADD',
          type: 'Code',
        });
        break;
      case 'C':
        this.title = 'ADD CANCELLATION TRANSACTION DATE';
        this.label = 'CANCELLATION TRANSACTION DATE';
        this.buttonLabel = 'COMPLETE CANCELLATION';
        this.isDate = true;
        this.isComment = false;
        this.selectedType = 'C';
        this.display = true;
        this.isApproved = this.roleService.validateIfPermissionsExist({
          category: 'User Security',
          permission: 'Cancellation Transaction Date',
          operation: 'ADD',
          type: 'Code',
        });
        break;
    }
  }

  onValidate(rowData, item) {
    if (rowData.fileStatus === 'PROCESSING') {
      return false;
    }
    if (rowData.fileType.toUpperCase() === 'C') {
      if (
        item === 'VF' &&
        (rowData.cancellationTransactionDate === 'UNPROCESSED' ||
          rowData.cancellationTransactionDate === 'PARTIAL') &&
        rowData.fileStatus !== 'COMPLETE'
      ) {
        return true;
      } else if (
        item === 'CAN' &&
        rowData.cancellationTransactionDate !== 'UNPROCESSED' &&
        rowData.cancellationTransactionDate !== 'PARTIAL' &&
        rowData.fileStatus !== 'VOIDED'
      ) {
        return true;
      }
    }
    if (rowData.fileType.toUpperCase() === 'S') {
      if ( item === 'VF') {
        return true;
      } else if (
        item === 'NLF' &&
        rowData.nlfTransactionDate !== 'UNPROCESSED' &&
        rowData.nlfTransactionDate !== 'PARTIAL' &&
        rowData.fileStatus !== 'VOIDED'
      ) {
        return true;
      }
    }
    return false;
  }

  onComplete() {
    // TODO: Implementation of comments
    let selectedRow = this.selectedRow;
    const filteredRow = this.batchProcessResults.filter(el => el.batchProcessingIdOriginal === selectedRow.batchProcessingIdOriginal);
    const uploadDateSelected = filteredRow[0].uploadDate;
    switch (this.selectedType) {
      case 'S':
        if (this.selectedDate) {
          selectedRow = {
            ...selectedRow,
            uploadDate : uploadDateSelected,
            fileStatus: 'PROCESSING',
            operationType: 'update',
            nlfTransactionDate: this.selectedDate,
            hasStatusChanged: true,
            hasNLFDateChanged: true,
          };
        }
        break;
      case 'C':
        if (this.selectedDate) {
          selectedRow = {
            ...selectedRow,
            uploadDate : uploadDateSelected,
            cancellationTransactionDate: this.selectedDate,
            fileStatus: 'PROCESSING',
            operationType: 'update',
            hasStatusChanged: true,
            hasCancellationDateChanged: true,
          };
        }
        break;
      case 'VF':
        selectedRow = {
          ...selectedRow,
          uploadDate : uploadDateSelected,
          fileStatus: 'PROCESSING',
          operationType: 'void',
          hasStatusChanged: true,
        };
        break;
      case 'NLF':
        selectedRow = {
          ...selectedRow,
          uploadDate : uploadDateSelected,
          fileStatus: 'PROCESSING',
          operationType: 'override',
          nlfTransactionDate: this.selectedDate,
          hasNLFDateChanged: true,
        };
        break;
      case 'CAN':
        selectedRow = {
          ...selectedRow,
          uploadDate : uploadDateSelected,
          fileStatus: 'PROCESSING',
          operationType: 'override',
          cancellationTransactionDate: this.selectedDate,
          hasCancellationDateChanged: true,
        };
        break;
      default:
        break;
    }
    // Mutation for batch process
    this.isLoading = true;
    this.display = false;
    selectedRow.uploadDate = uploadDateSelected;
    selectedRow.insertUserName = this.azureService.accountId;
    selectedRow.comments = this.enteredComment;
    this.batchProcessingService
      .createUpdateBatchProcess(selectedRow)
      .subscribe(({ data, loading }: any) => {
        this.selectedDate = '';
        this.isLoading = loading;
        this.getBatchProcessResults();
      });
  }
  validatePositiveNumber(value) {
    const formattedValue = value ? String(value).replace('$', '').replace(',', '') : null;
    if (formattedValue && this.isItNumber(formattedValue)) {
      return parseFloat(formattedValue) >= 0;
    }
    return false;
  }

  isItNumber(str) {
    return /^\-?[0-9]+(e[0-9]+)?(\.[0-9]+)?$/.test(str);
  }

  getValue(value) {
    return numberWithCommas(parseFloat(String(value).replace(/[-$,]/g, '')).toFixed(2));
  }

  totalAmount(data: any[]) {
    this.totalDealerCost = data
      .reduce(
        (a, b) => a + (parseFloat(String(b.dealerCost).replace('$', '').replace(',', '')) || 0),
        0,
      )
      .toFixed(2);
    this.planCount = data.reduce(
      (a, b) => a + (parseInt(String(b.planCount).replace('$', ''), 10) || 0),
      0,
    );
  }

  onClickRefresh(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.getBatchProcessResults();
  }
  onClickClearFilter(event, overlaypanel: OverlayPanel) {
    this.dataTableRef.reset();
    overlaypanel.hide();
  }

  onClickExport(event, overlaypanel: OverlayPanel) {
    this.exportToCsv(overlaypanel);
  }

  exportToCsv(overlaypanel: OverlayPanel) {
    this.dataTableRef.value = this.admin;
    this.dataTableRef.exportCSV();
    overlaypanel.hide();
  }
}
