import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'crm-alert-modal',
  templateUrl: 'crm-alert-modal.template.html',
  styleUrls: ['crm-alert-modal.scss'],
})
export class CrmAlertModalComponent {
  private privateDisplayDialog: boolean;

  @Input() modalTitle: string;
  @Input() modalContent: string;
  @Input() submitButtonlabel = 'OK';
  @Input() isBackgroundFreeze = true;
  @Output() displayDialogChange = new EventEmitter();

  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }

  onSubmit() {
    this.displayDialog = false;
  }
}
