import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { RateProfileService } from './rate-profile.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { copy } from '../../shared/utilities/common-utilities';
@Component({
  selector: 'rate-profile',
  templateUrl: 'rate-profile.template.html',
  providers: [RateProfileService],
})
export class RateProfileComponent implements OnInit, OnDestroy {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  dropdown: any;
  querySubscriptions: Subscription[] = [];
  constructor(private rateProfileService: RateProfileService) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.querySubscriptions.push(
      this.rateProfileService.getRateProfile().subscribe((data) => {
        this.dataList = data;
      }),
    );
    this.dropdown = {
      status: [
        { label: 'Active', value: 'Active' },
        { label: 'Terminated', value: 'Terminated' },
      ],
      master_profile: [
        { label: 'profile1', value: 'Profile 1' },
        { label: 'profile2', value: 'Profile 2' },
      ],
      product_types: [
        { label: 'type1', value: 'Type 1' },
        { label: 'type2', value: 'Type 2' },
      ],
    };
    this.cols = [
      { field: 'name', header: 'NAME', type: 'text' },
      { field: 'product_types', header: 'PRODUCT TYPES', type: 'select' },
      { field: 'status', header: 'STATUS', type: 'select' },
      { field: 'master_profile', header: 'MASTER PROFILE', type: 'select' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
