import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SubmitProviderTypeService } from '../submit-provider/submit-provider.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../service/service-ellipsis';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ServiceModuleService } from '../../services/service-module.service';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../shared/form-field/form-field';
import { copy } from '../../shared/utilities/common-utilities';

@Component({
  selector: 'submit-provider',
  templateUrl: 'submit-provider.template.html',
  styleUrls: ['submit-provider.scss'],
  providers: [SubmitProviderTypeService, ServiceModuleService],
})
export class SubmitProviderComponent implements OnInit {
  cols: any[];
  dataList: any[];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayVerify: boolean;
  formModalInfo: FormField<any>[] = [];
  constructor(
    private configurationService: SubmitProviderTypeService,
    private cdr: ChangeDetectorRef,
    private modalService: ServiceModuleService,
  ) {
    this.displayAdd = false;
    this.displayVerify = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.configurationService.getSubmitProviderTypes().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'select', header: 'SELECT', type: 'checkbox' },
      { field: 'dealer_name', header: 'DEALER NAME', type: 'text' },
      { field: 'paid_claim_count', header: 'PAID CLAIM COUNT', type: 'text' },
      { field: 'paid_amount', header: 'PAID AMOUNT', type: 'text' },
    ];
    this.modalService
      .getServiceSearchModalFields()
      .subscribe((formModalInfo) => (this.formModalInfo = formModalInfo));
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  headerClick(event) {
    console.log(JSON.stringify(event));
    event.OverlayPanel.toggle();
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e,overlaypanel:OverlayPanel) {
    if (e.value === 'Add') {
      this.displayAdd = true;
      this.header = 'Advanced Search Modal';
    }
    overlaypanel.hide();
    this.cdr.detectChanges();
  }
}
