import { Component, OnInit, Input } from '@angular/core';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { FormField } from '../../../shared/form-field/form-field';
import { ServicerService } from '../servicer.service';
@Component({
  selector: 'servicer-main-tab',
  templateUrl: 'servicer-main-tab.template.html',
  styleUrls: ['servicer-main-tab.scss'],
})
export class ServicerMainTabComponent implements OnInit {
  @Input() searchParams: any;
  contactInfo: FormField<any>[] = [];
  generalInfo: FormField<any>[] = [];
  mailAddressInfo: any;
  billingAddressInfo: any;
  genericFormData: any;
  contactFormData: any;
  constructor(
    public servicerService: ServicerService,
    private roleService: RoleAuthorizationService,
  ) {
    this.servicerService.getContactInformationFields().subscribe((data) => {
      this.contactInfo = data;
    });
  }

  ngOnInit() {
    this.servicerService.billingAddressFinal = {
      ...this.servicerService.billingAddress,
    };
    this.servicerService.mailingAddressFinal = {
      ...this.servicerService.mailingAddress,
    };
    this.contactFormData = { ...this.servicerService.contactFormData };
    this.contactFormData.businessPhoneTypeCode = this.contactFormData.businessPhoneTypeCode
      ? {
          key: this.contactFormData.businessPhoneTypeCode,
          value: this.contactFormData.businessPhoneTypeCode,
        }
      : null;
  }

  // onBillingAddressChange(data) {
  //   debugger;
  //   this.dealerLocationService.billingAddressFinal = { ...data };
  // }
  // onMailAddressChange(data) {
  //   this.dealerLocationService.mailingAddressFinal = { ...data };
  // }
  onContactInfoChange(data) {
    this.servicerService.contactFormDataFinal = { ...data };
  }
}
