<div class="batch-container">
  <app-section-header [title]="'Batch Processing Results'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table #temp *ngIf="!admin"></p-table>
    <!-- <p-table #dt *ngIf="admin && admin.length > 0" [columns]="cols" [value]="admin"
      dataKey="batchProcessingIdOriginal" editMode="row" sortMode="multiple"
        [hidden]="isLoading"> -->
    <p-table #dt *ngIf="admin && admin.length > 0" [columns]="cols" [value]="admin" dataKey="batchProcessingIdOriginal"
      editMode="row" sortMode="multiple" styleClass="p-datatable-gridlines" [tableStyle]="{ 'min-width': '50rem' }"
      [hidden]="isLoading" [paginator]="true" [rows]="10" (onPage)="onPageChange($event)" (onFilter)="onFilterChange($event)">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn="pResizableColumn"
            style="width:22%">
            {{col.header}}<p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th class="last-cell">
            <div class="three-dots">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns">
            <!-- <input class="input-text" *ngIf="col.field !== 'dEdit'" type="text"
              (input)="dt.filter($event, col.field, 'Contains')" /> -->
            <p-columnFilter *ngIf="col.field === 'fileStatus'" field="fileStatus" matchMode="contains"
              [showMenu]="false">
            </p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'fileType'" field="fileType" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'dealerName'" field="dealerName" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'fileName'" field="fileName" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'uploadDate'" field="uploadDate" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'planCount'" field="planCount" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'contractRange'" field="contractRange" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'dealerCost'" field="dealerCost" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'nlfTransactionDate'" field="nlfTransactionDate" matchMode="contains"
              [showMenu]="false"></p-columnFilter>
            <p-columnFilter *ngIf="col.field === 'cancellationTransactionDate'" field="cancellationTransactionDate"
              matchMode="contains" [showMenu]="false"></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <div class="crm-link" *ngIf="col.field === 'fileName'" (click)="openTab(rowData)">{{rowData[col.field]}}
            </div>
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <div
              *ngIf="col.type === 'text' && col.field !== 'dealerCost' && col.field !== 'fileName' && col.field !== 'planCount' ">
              {{rowData[col.field]}}
            </div>
            <div *ngIf="col.type === 'text' && col.field === 'dealerCost' && rowData['fileType'].toLowerCase()==='c'">
              <div class="errorText" *ngIf="getValue(rowData[col.field])!==''">{{'-$'}}{{getValue(rowData[col.field])}}
              </div>
            </div>
            <div *ngIf="col.type === 'text' && col.field === 'dealerCost' && rowData['fileType'].toLowerCase()==='s'">
              {{'$'}}{{getValue(rowData[col.field])}}
            </div>
            <div
              *ngIf="col.type === 'text' && col.field === 'extendedDealerCost' && rowData['fileType'].toLowerCase()==='s'">
              {{'$'}}{{getValue(rowData[col.field])}}
            </div>
            <div
              *ngIf="col.type === 'text' && col.field === 'planCount' && validatePositiveNumber(rowData['planCount'])">
              {{rowData[col.field]}}
            </div>
            <div
              *ngIf="col.type === 'text' && col.field === 'planCount' && !validatePositiveNumber(rowData['planCount'])">
              <div class="errorText">{{rowData[col.field]}}</div>
            </div>
            <div *ngIf="col.field === 'dEdit'">
              <div class="cAddDate" [ngClass]="{'inactive': !showAddOption(rowData)}"
                (click)="showAddOption(rowData) && openModelAdd(rowData['fileType'].toUpperCase(), rowData)">
                Add
              </div>
            </div>
          </td>
          <td class="last-cell">
            <div class="three-dots" (click)="ellipsisClick($event,rowData,op3)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template><ng-template pTemplate="footer" let-columns>
        <tr>
          <td *ngFor="let col of columns">
            <div *ngIf="col.type === 'text' && col.field === 'uploadDate'">Total Plan Count</div>
            <div *ngIf="col.type === 'text' && col.field === 'planCount'">{{planCount}}</div>
            <div *ngIf="col.type === 'text' && col.field === 'contractRange'">Total Dealer Cost</div>
            <div *ngIf="col.type === 'text' && col.field === 'dealerCost'">${{totalDealerCost}}</div>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #op3>
  <p-listbox [options]="ellipsisOptions" [(ngModel)]="selectedEllipsisItem"
    (onClick)="onEllipsisItemSelect($event,op3)"> </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader><p-listbox [options]="ellipsisMainOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event, opHeader)"></p-listbox></p-overlayPanel><p-dialog
  [header]="title" [(visible)]="display" showeffect="fade" [modal]="true" [styleClass]="'error-dailog'"
  [blockScroll]="true">
  <div class="modal-content">
    <div class="grid">
      <div class="col-4" *ngIf="isDate">{{label}}</div>
      <div class="col-8" *ngIf="isDate">
        <input type="date" [attr.disabled]="!isApproved?true:null" [(ngModel)]="selectedDate" />
      </div>
      <div class="col-4" *ngIf="isComment">ENTER COMMENTS</div>
      <div class="col-8" *ngIf="isComment">
        <textarea name="" cols="40" rows="3" [(ngModel)]="enteredComment"> </textarea>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer" *ngIf="isApproved">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onComplete()" label="ok">{{buttonLabel}}</button>
    </div>
  </ng-template>
</p-dialog>