import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'session-modal',
  templateUrl: 'session-modal.template.html',
  styleUrls: ['session-modal.scss'],
})
export class SessionModalComponent {
  @Input() public activityCtx: any;
  @Input() public strings;
  @Input() public isSessionTimingOut: boolean;
  @Output() public refresh: EventEmitter<any> = new EventEmitter();
  @Output() public logout: EventEmitter<any> = new EventEmitter();

  public sessionTimeoutRefresh() {
    this.refresh.emit();
  }

  public sessionLogout() {
    this.logout.emit();
  }
}
