// mapping of css classes in crm-tables.scss file in shared section
export const buttonStatus = {
  active: 'btn-status-green',
  inactive: 'btn-status-red',
  cancel: 'btn-status-red',
  cancelled: 'btn-status-red',
  complete: 'btn-status-green',
  unprocessed: 'btn-status-red',
  terminated: 'btn-status-red',
  paid: 'btn-status-green',
  unpaid: 'btn-status-red',
  open: 'btn-status-red',
  a: 'btn-status-green',
};

export function getStatusClass(status: string) {
  if (
    status.includes('wait') ||
    status.includes('over') ||
    status.includes('pending') ||
    status.includes('reject')
  ) {
    return 'btn-status-red';
  } else {
    return 'btn-status-green';
  }
}
