import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { FormField } from '../../shared/form-field/form-field';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { of } from 'rxjs';

const getSubcategoryQuery = gql`
  query(
    $code: String
    $description: String
    $tier: String
    $offset: Int
    $limit: Int
    $category: String
  ) {
    getSubCategorySearch(
      code: $code
      description: $description
      tier: $tier
      offset: $offset
      limit: $limit
      category: $category
    ) {
      categoryId
      code
      description
      tier
      parentDescription
    }
  }
`;

const createUpdateSubCategory = gql`
  mutation(
    $subCategoriesIdOriginal: Int
    $code: String
    $description: String
    $tierCode: String
    $tierCodeId: Int
    $insertUserName: String!
    $isDeleted: Boolean
  ) {
    createUpdateSubCategories(
      subCategoryInput: {
        code: $code
        description: $description
        tierCode: $tierCode
        tierCodeId: $tierCodeId
        isDeleted: $isDeleted
        insertUserName: $insertUserName
        subCategoriesIdOriginal: $subCategoriesIdOriginal
      }
    )
  }
`;

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {
  constructor(private apollo: Apollo) {}

  getSubcategory = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: getSubcategoryQuery,
      variables: {
        ...searchParams
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateSubCategory = params =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateSubCategory,
      variables: {
        code: params.code,
        description: params.description,
        tierCode: params.tierCode,
        tierCodeId: params.tierCodeId,
        subCategoriesIdOriginal: params.subCategoriesIdOriginal,
        insertUserName: params.insertUserName,
        isDeleted: params.isDeleted
      }
      // tslint:disable-next-line: semicolon
    });
}
