import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../../shared/form-field/form-field';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { TransactionManagementService } from '../transaction-management.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'transaction-management-modal',
  templateUrl: 'transaction-management-modal.template.html',
  styleUrls: ['./transaction-management-modal.scss'],
  providers: [FormFieldControlService, MessageService, ConfirmationService],
})
export class TransactionModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Output() formSubmitEvent = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  displayEmailModal: true;
  isLoading: boolean;
  querySubscription: Subscription;
  selectedRowIndex: number;
  dataList: any[] = [];
  querySubscriptions: Subscription[] = [];
  constructor(
    private qcs: FormFieldControlService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private transactionManagementService: TransactionManagementService,
  ) {
    this.transactionManagementService.getTransactinYearFields().subscribe((data) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    if (this.form.valid) {
      payload.insertUserName = this.azureService.accountId;
      this.isLoading = true;
      this.querySubscriptions.push(
        this.transactionManagementService
          .createTransactionYear({ ...payload })
          .subscribe((data: any) => {
            this.isLoading = false;
            const response = data.createTransactionYear;
            if (response) {
              const item = this.formatData(response);
              item[`isNew`] = false;
              this.dataList[this.selectedRowIndex] = item;
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'successMessage',
              });
            }
            this.isLoading = false;
            this.formSubmitEvent.emit(true);
            this.displayDialog = false;
            this.displayDialogChange.emit(this.displayDialog);
          }),
      );
    }
  }

  formatData(item) {
    return [
      {
        transactionYear: item.transactionYear,
        insertUserName: item.insertUserName,
      },
    ];
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.formSubmitEvent.emit(false);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
