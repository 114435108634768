import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { DealerFormFieldService } from '../../services/dealer-form-field.service';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../interfaces/role-authorization.interface';
import { DealerService } from '../dealer.service';
import { MessageService } from 'primeng/api';
import { dealerLocationFormHeaderOptions } from '../dealer-location/dealer-location-form/dealer-location-form.ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { BuyingGroupService } from '../../services/buying-group.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { TabsService } from '../../services/tabs.service';
import { ConfigurationTypeService } from '../../administration/configuration/configuration.service';

export function dateSubstring(date) {
  return date && date.length > 1 ? new Date(date).toISOString().substring(0, 10) : null;
}

@Component({
  selector: 'dealer-profile-form',
  templateUrl: 'dealer-profile-form.template.html',
  styleUrls: ['../../shared/styles/left-details-form.scss', 'dealer-profile-form.scss'],
  providers: [MessageService, FormFieldControlService],
})
export class DealerProfileFormComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad: any;
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  querySubscription: any;
  dealerGroupsIdOriginal = null;
  isSuccess = false;
  isLoading = false;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = dealerLocationFormHeaderOptions;
  isDealerGroupName = false;
  displayHistory = false;
  historyData: any;

  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: DealerFormFieldService,
    private dealerService: DealerService,
    private messageService: MessageService,
    private dropdownDataService: DropdownDataService,
    private bgService: BuyingGroupService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
    private tabService: TabsService,
  ) {
    super();
    this.service.getDealerMainFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = this.roleService.validateFormFields(data);
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: DealerProfileFormComponent,
      generalArray: this.formFields,
    };
    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: false, type: 'submit' },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: DealerProfileFormComponent,
      generalArray: this.button,
    };
    this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    const profileId = !isNaN(this.searchParams) ? parseInt(this.searchParams, 10) : 0;
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'sales_representative',
        module: 'dealer',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const options = configList
          .filter((el) => el.menuType === 'sales_representative')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.formFields.find((el) => el.key === 'salesRepresentative').options = options;
        this.formValueChanged.emit(true);
        if (profileId && this.dealerService.dealerFormData) {
          this.setFormValues();
          this.isDealerGroupName =
            this.form.controls.dealerGroupName &&
            this.form.controls.dealerGroupName.value &&
            this.form.controls.dealerGroupName.value !== '';
        }
      });
    if (this.roleService.userRole !== 'Admin') {
      this.form.get('isExcludedForCancellation').disable();
      this.form.get('freeLookUpPeriod').disable();
    }
  }

  setFormValues() {
    const modifiedData = this.dealerService.dealerFormData;
    this.formFields = this.formFields.map((formField) => {
      if (formField.key === 'dealerGroupNumber') {
        formField = { ...formField, disabled: true };
      }
      return formField;
    });
    this.form = this.qcs.toFormGroup(this.formFields);
    this.dealerGroupsIdOriginal = this.dealerService.dealerGroupsIdOriginal;
    const contractDate = dateSubstring(modifiedData.contractDate);
    const contractExpireDate = dateSubstring(modifiedData.contractExpireDate);

    // Getting buyingGroup data from dropdownDataService
    const buyingGroup = this.dropdownDataService.buyingGroupData.find(
      (x) => x.agentIdOriginal === parseInt(modifiedData.agentId, 10),
    );

    this.bgService
      .getElegiblePriceTables({
        number: modifiedData.dealerGroupNumber,
        groupType: '',
      })
      .subscribe(
        ({ data }: any) => {
          const info = data.getElegiblePriceTables ? data.getElegiblePriceTables : [];
          this.getPriceTableNameOption(info, modifiedData.priceTableName);
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    const salesRepresentative = this.formFields
      .find((el) => el.key === 'salesRepresentative')
      .options.find((el) => el.value === modifiedData.salesRepresentative);
    // Binding data to form
    this.form.patchValue({
      ...modifiedData,
      dealerGroupStatus: {
        key: modifiedData.dealerGroupStatus,
        value: modifiedData.dealerGroupStatus,
      },
      buyingGroup: {
        key: buyingGroup ? buyingGroup.name : '',
        value: buyingGroup ? buyingGroup.agentIdOriginal : '',
      },
      dealerDispatch: { key: modifiedData.dispatch, value: modifiedData.dispatch },
      billingCycle: { key: modifiedData.billingCycle, value: modifiedData.billingCycle },
      regionCode: { key: modifiedData.regionCode, value: modifiedData.regionCode },
      priceTable: { key: modifiedData.priceTableName, value: modifiedData.priceTableName },
      followUp: { key: modifiedData.followUp, value: modifiedData.followUp },
      freeLookUpPeriod: {
        key: modifiedData.freeLookUpPeriod,
        value: modifiedData.freeLookUpPeriod,
      },
      isExcludedForCancellation: modifiedData.isExcludedForCancellation
        ? ['Excluded For Cancellation']
        : null,
      insuranceOverride: {
        key: modifiedData.insuranceOverride,
        value: modifiedData.insuranceOverride,
      },
      salesRepresentative: {
        key: salesRepresentative ? salesRepresentative.key : null,
        value: salesRepresentative ? salesRepresentative.value : null,
      },
      salesChannel: { key: modifiedData.salesChannel, value: modifiedData.salesChannel },
      contractDate,
      contractExpireDate,
      jsyCheckBox: modifiedData.jsyCheckBox ? ['JSY'] : null,
      tSelfServicing: modifiedData.tSelfServicing ? ['Self Servicing'] : null,
    });
  }

  onChangeEvent(event, key) {
    switch (key) {
      case 'buyingGroup':
        const bgNumber = this.dropdownDataService.buyingGroupData.find(
          (el) => el.agentIdOriginal === event.value.value,
        ).number;
        this.bgService
          .getElegiblePriceTables({
            number: bgNumber,
            groupType: 'BuyingGroup',
          })
          .subscribe(({ data }) => {
            const info = data.getElegiblePriceTables ? data.getElegiblePriceTables : [];
            this.getPriceTableNameOption(info);
          });
        break;
      case 'dealerGroupNumber':
        if (event.target.value && event.target.value.length > 0) {
          this.isLoading = true;
          this.dealerService.getDealerDetailsForm(null, event.target.value).subscribe(
            ({ data }) => {
              if (data.getDealerProfileById) {
                this.form.controls.dealerGroupNumber.setErrors({ key: 'duplicateDealerNumber' });
              }
              this.isLoading = false;
            },
            (err) => {
              this.isLoading = false;
              throw err;
            },
          );
        }
        break;
      default:
        break;
    }
  }

  getPriceTableNameOption(priceTableNameList, defaultProfileName = null) {
    const ptList = [...priceTableNameList];
    if (Array.isArray(priceTableNameList)) {
      if (defaultProfileName && !priceTableNameList.includes(defaultProfileName)) {
        ptList.push(defaultProfileName);
      }
      this.formFields.find((el) => el.key === 'priceTable').options = ptList.map((el) => ({
        key: el,
        value: el,
      }));
    }
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  private disableSubmit(param: boolean) {
    this.button = this.button.map(row => {
      if (row.name === 'Submit') {
        row.disabled = param;
      }
      return row;
    });
  }

  onSubmit() {
    window.scroll(0, 0);
    this.messageService.clear();
    if (this.form.valid) {
      this.isLoading = true;
      this.payLoad = {
        ...this.form.getRawValue(),
        dealerGroupsIdOriginal: this.dealerGroupsIdOriginal,
        insertUserName: this.azureService.accountId,
      };
      if (this.payLoad.dealerGroupNumber === '' || this.payLoad.dealerGroupName === '') {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Please fill the required fields.`,
        });
      } else {
        this.formValueChanged.emit(this.form.dirty);
        this.isLoading = true;
        this.disableSubmit(true);
        this.querySubscription = this.dealerService
          .createDealerProfile(this.payLoad)
          .subscribe(
            (mData: any) => {
              this.dealerGroupsIdOriginal =
                mData.data.createUpdateDealerProfile.dealerGroupsIdOriginal;
              this.dealerService.dealerGroupsIdOriginal = this.dealerGroupsIdOriginal;
              // Refresh Cache
              this.dropdownDataService.getDropdownData().subscribe(({ data }) => {
                this.dropdownDataService.dealerGroupData = data.getDealerGroupCache;
                this.dropdownDataService.buyingGroupData = data.getBuyingGroupCache;
                this.dropdownDataService.masterBuyingGroupData = data.getMasterBuyingGroupCache;
                this.dropdownDataService.userListData = data.getUserListCache;
                this.dropdownDataService.servicerListData = data.getServicerListCache;
                this.dropdownDataService.servicerGroupList = data.getServicerGroupListCache;
                this.dropdownDataService.categoryDescriptionList = data.getCategoryDescriptionList;
                this.dropdownDataService.userProfileTypesList = data.getUserProfileTypes;
                this.dropdownDataService.tierDescriptionData = data.getTierDescriptionList;
              });
              this.isSuccess = true;
              this.isLoading = mData.isLoading;
              this.disableSubmit(false);
              if (this.dealerService.dealerGroupsIdOriginal) {
                this.refreshDealerProfile(this.dealerService.dealerGroupsIdOriginal);
              }
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: `Record Saved Successfully.`,
              });
              setTimeout(() => {
                this.isSuccess = false;
              }, 3000);
            },
            (err) => {
              this.isLoading = false;
              this.disableSubmit(false);
              throw err;
            },
          );
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please fill the required fields.`,
      });
    }
  }

  refreshDealerProfile(id) {
    this.isLoading = true;
    this.querySubscription = this.dealerService.getDealerDetailsForm(id).subscribe(
      ({ data, loading }: any) => {
        const {
          dealerGroupsIdOriginal,
          addresses,
          contactInfo,
          general1,
          general2,
          general3,
          general4,
          ...dealerFormData
        } = data.getDealerProfileById;
        this.dealerService.dealerFormData = dealerFormData;
        this.dealerService.billingAddress = addresses[0];
        this.dealerService.mailingAddress = addresses[1];
        this.dealerService.contactFormData = contactInfo;
        this.dealerService.dealerGroupsIdOriginal = dealerGroupsIdOriginal;
        this.dealerService.generalFormData = { general1, general2, general3, general4 };
        this.isLoading = loading;
        this.setFormValues();
        // this.dealerService.setMainTabAvailability(true);
        this.dealerService.isdealerGroupsIdAvailable.next(true);
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }

  openSearchDealerLocationTab() {
    const dealerGroupsIdOriginal = this.dealerService.dealerGroupsIdOriginal;
    this.tabService.createTab({
      header: 'Search Dealer Location',
      content: {
        dealerGroupNumber: dealerGroupsIdOriginal.toString(),
      },
      type: 'SearchDealerLocation',
    });
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  onViewHistory() {
    const id = this.dealerGroupsIdOriginal;
    if (id) {
      this.historyData = {
        type: 'dealer_profile',
        id,
      };
      this.displayHistory = true;
    }
  }
}
