import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { SecurityTypeService } from '../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';
import {
  permissionEllipsisHeaderOptions,
  ellipsisRowOptions,
} from '../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PermissionType } from '../security';
import { AzureLoginService } from 'src/app/services/azure-login.service';
@Component({
  selector: 'admin-security-permission',
  templateUrl: 'sec-permission.template.html',
  providers: [SecurityTypeService, MessageService],
})
export class SecurityPermissionsComponent extends FormCanDeactivate implements OnInit {
  cols: any[];
  dataList: PermissionType[] = [];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = permissionEllipsisHeaderOptions;
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayUpdate: boolean;
  querySubscription: Subscription;
  isLoading = true;

  @Input() searchParams: any;
  lastOffsetVal = 0;
  offsetLimit = 25;
  disableLoadMore = false;
  clonedata: boolean;
  selectedEllipsisItem: any;

  constructor(
    private confirmationService: ConfirmationService,
    private configurationService: SecurityTypeService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleAuthorizationService,
    private service: AzureLoginService,
    private messageService: MessageService,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
    this.clonedata = true;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'permissionCategory', header: 'PERMISSION CATEGORY', type: 'text' },
      { field: 'permissionDescription', header: 'PERMISSION DESCRIPTION', type: 'text' },
      { field: 'componentName', header: 'COMPONENT NAME', type: 'text' },
      { field: 'elementName', header: 'ELEMENT NAME', type: 'text' },
      { field: 'listName', header: 'LIST NAME', type: 'text' },
      { field: 'parentComponentName', header: 'PARENT COMP NAME', type: 'text' },
    ];
    this.getPermissionsData();
  }

  getPermissionsData() {
    this.isLoading = true;
    if (this.searchParams !== 'Permissions') {
      this.searchParams = {
        category: this.searchParams.category !== null ? this.searchParams.category : '',
        description: this.searchParams.description !== null ? this.searchParams.description : '',
        component: this.searchParams.component !== null ? this.searchParams.component : '',
        listName: this.searchParams.listName !== null ? this.searchParams.listName : '',
        elementName: this.searchParams.elementName !== null ? this.searchParams.elementName : '',
        parentComponentName:
          this.searchParams.parentComponentName !== null
            ? this.searchParams.parentComponentName
            : '',
      };
    } else {
      this.searchParams = {
        category: '',
        description: '',
        component: '',
        listName: '',
        elementName: '',
        parentComponentName: '',
      };
    }
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.lastOffsetVal += this.offsetLimit;
    let length = 0;
    this.querySubscription = this.roleService
      .getPermissionsSearch(this.searchParams)
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        const dataInfo = data.getPermissionsSearch;
        dataInfo.forEach((permission, id) => {
          const info = Object.assign({}, permission);
          info.id = id;
          length++;
          if (this.dataList.length === 0) {
            this.dataList.push(info);
          } else {
            const index = this.dataList.findIndex(
              (e) => e.permissionIdOriginal === info.permissionIdOriginal,
            );
            if (index === -1) {
              this.dataList.push(info);
            } else {
              const index = this.dataList.findIndex(
                (e) => e.permissionIdOriginal === permission.permissionIdOriginal,
              );
              if (index === -1) {
                this.dataList.push(permission);
              } else {
                this.dataList[index] = permission;
              }
            }
          }
        });
        if (length !== 0) {
          this.disableLoadMore = this.dataList.length % this.offsetLimit !== 0;
        } else {
          this.disableLoadMore = true;
        }
      });
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    if (this.isEditing) {
      const btn = document.getElementById(`${this.editConstant + this.selectedItem.id}cancelBtn`);
      if (btn) {
        btn.click();
      }
      this.isEditing = false;
    }
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    if (this.clonedata) {
      this.clonedRowData[rowData.id] = { ...rowData };
    }
    this.clonedata = true;
  }
  onRowEditSave(rowData: any) {
    this.validateAndSavePermission(rowData);
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    if (rowData.__typename === 'new') {
      this.dataList.shift();
      this.resetId();
    }
  }
  handleOpen(e) {
    if (e.value === 'add') {
      const element: PermissionType = {
        permissionIdOriginal: 0,
        permissionCategory: '',
        permissionDescription: '',
        componentName: '',
        listName: '',
        elementName: '',
        parentComponentName: '',
        id: 0,
        __typename: 'new',
      };
      this.dataList.unshift(element);
      this.resetId();
      this.selectedItem = this.dataList[0];
      setTimeout(() => {
        this.onRowEdit();
      }, 100);
    } else if (e.value === 'delete') {
      this.confirmationService.confirm({
        message: `Are you sure you want to delete this Permission?`,
        accept: () => {
          const row = this.selectedItem;
          const params: any = {
            isDeleted: true,
            permissionCategory: row.permissionCategory,
            permissionDescription: row.permissionDescription,
            componentName: row.componentName,
            listName: row.listName,
            elementName: row.elementName,
            permissionIdOriginal: row.permissionIdOriginal,
            parentComponentName: row.parentComponentName,
            insertUserName: this.service.accountId,
          };
          this.createUpdatePermissions(params, row).then(() => {
            this.dataList.splice(row.id, 1);
            this.resetId();
          });
        },
      });
    } else {
      this.onRowEdit();
    }
    this.cdr.detectChanges();
  }
  validateAndSavePermission(rowData: PermissionType) {
    if (rowData.permissionCategory.length === 0) {
      this.displayError('Category');
      return;
    }
    if (rowData.permissionDescription.length === 0) {
      this.displayError('Description');
      return;
    }
    if (rowData.componentName.length === 0) {
      this.displayError('Component name');
      return;
    }
    if (rowData.elementName.length === 0) {
      this.displayError('Element Name');
      return;
    }
    this.isLoading = true;
    const params: any = {
      permissionCategory: rowData.permissionCategory,
      permissionDescription: rowData.permissionDescription,
      componentName: rowData.componentName,
      listName: rowData.listName,
      elementName: rowData.elementName,
      parentComponentName: rowData.parentComponentName,
      insertUserName: this.service.accountId,
    };
    if (rowData.permissionIdOriginal !== 0) {
      params.permissionIdOriginal = rowData.permissionIdOriginal;
    }
    this.createUpdatePermissions(params, rowData);
    this.isEditing = false;
    this.isLoading = false;
  }
  async createUpdatePermissions(params, rowData) {
    this.roleService.createUpdatePermissions(params).subscribe(
      (result: any) => {
        const idOriginal = parseInt(result.data.createUpdatePermissions, 10);
        const index = this.dataList.findIndex((r) => r.id === rowData.id);
        if (index > -1) {
          this.dataList[index].permissionIdOriginal = idOriginal;
          this.dataList[index].__typename = 'Permission';
        }
        this.resetId();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Permission ${params.isDeleted ? 'Deleted' : 'Saved'}`,
        });
      },
      (e) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Could not finish the action`,
        });
      },
    );
  }
  displayError(type) {
    this.clonedata = false;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: `The ${type} is required`,
    });
    this.isLoading = false;
  }
  resetId() {
    this.dataList.forEach((data, id) => {
      data.id = id;
    });
  }
}
