import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { DealerService } from '../../dealer.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';

@Component({
  selector: 'contact-information',
  templateUrl: 'contact-information.template.html',
  styleUrls: ['contact-information.scss'],
  providers: [FormFieldControlService],
})
export class ContactInformationComponent extends FormCanDeactivate implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  @Input() formData: any;
  @Output() modelDataChange = new EventEmitter();
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: FormFieldControlService) {
    super();
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      const data = { ...x, valid: this.form.valid };
      this.modelDataChange.emit(data);
    });
    if (this.formFields && this.formData) {
      this.form.patchValue({
        ...this.formData,
      });
    }
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }
}
