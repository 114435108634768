<div class="crm-container">
  <app-section-header [title]="'Problem Report'" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="col.width ? { width: col.width } : []">
            {{ col.header }}
          </th>
          <th class="last-cell" [ngClass]="{ 'row-edit': isEditing }" [style.width]="'10%'"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template *ngIf="col.type === 'text' || col.type === 'select' || col.type === 'fixed'"
                pTemplate="output">
                {{ rowData[col.field] }}
              </ng-template>
              <ng-template pTemplate="input">
                <textarea name="" cols="30" rows="5" *ngIf="col.type === 'text'"
                  [(ngModel)]="rowData[col.field]"></textarea>
                <p-dropdown *ngIf="col.type === 'select' && rowData['verticalTitle'] !== 'FAILURE'" [options]="
                    rowData['verticalTitle'] === 'CORRECTIVE ACTION'
                      ? dropdown['correctiveAction']
                      : dropdown['cause']
                  " [(ngModel)]="rowData[col.field]" appendTo="body">
                </p-dropdown>
                <p *ngIf="col.type === 'select' && rowData['verticalTitle'] === 'FAILURE'">
                  {{ rowData[col.field] }}
                </p>
              </ng-template>
            </p-cellEditor>
            <strong *ngIf="col.type === 'verticalHeader'">{{ rowData[col.field] }}</strong>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event, rowData, opRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{ editConstant + rowData.id }}"
              pButton="pButton" type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData,opRow)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" [style.margin-right]="'0.5em'"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-toast></p-toast>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions" (onClick)="
      this[$event.option.callback]
        ? this[$event.option.callback]($event, opRow)
        : ellipsisOptionClick($event)
    ">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions" (onClick)="
      this[$event.option.callback]
        ? this[$event.option.callback]($event, opRow)
        : ellipsisOptionClick($event)
    ">
  </p-listbox>
</p-overlayPanel>