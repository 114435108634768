import { Component, Input, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ServicerContactService } from './servicer-contact.service';
import { PriceTable } from '../../../crm-coverage/coverage-models';
import { buttonStatus } from '../../../shared/constants/button-class';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { ServicerService } from '../servicer.service';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { copy, validateEmail } from '../../../shared/utilities/common-utilities';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { RolePermissionService } from '../../../common/role-permission.service';
@Component({
  selector: 'servicer-contact',
  templateUrl: 'servicer-contact.template.html',
  styleUrls: ['servicer-contact.scss'],
  providers: [ServicerContactService, MessageService],
})
export class ServicerContactComponent implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  dataList = [];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  tabKey: any;
  dropdown: any;
  isAdd = false;
  isLoading = false;
  paymentNotifEdit: boolean;
  constructor(
    private servicerContactService: ServicerContactService,
    private confirmationService: ConfirmationService,
    private servicerService: ServicerService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private configurationService: ConfigurationTypeService,
    private tabHandlerService: TabHandlerService,
    private roleService: RoleAuthorizationService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    // this.servicerContactService.getServicerContact().subscribe(data => {
    //   this.dataList = data;
    // });
    this.paymentNotifEdit = !this.roleService.validateIfPermissionsExist({
      type: 'checkBox',
      operation: 'EDIT',
      permission: 'Claim Payment Notification',
      category: 'Claim',
    });
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab && this.servicerService && this.servicerService.serviceFormData) {
          this.getServicerContacts();
        }
      },
    });

    this.dropdown = {
      position: [],
    };
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'position',
        module: 'servicer',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const positionOptions = configList
          .filter((el) => el.menuType === 'position')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        this.dropdown.position = [
          ...positionOptions.filter((e) => e.label === null),
          ...positionOptions.filter((e) => e.label !== null),
        ];
        this.formValueChanged.emit(true);
      });
    this.cols = [
      { field: 'position', header: 'POSITION', type: 'select' },
      { field: 'firstName', header: 'FIRST NAME', type: 'text' },
      { field: 'lastName', header: 'LAST NAME', type: 'text' },
      { field: 'businessPhone', header: 'BUSSINESS PHONE', type: 'text' },
      { field: 'fax', header: 'FAX', type: 'text' },
      { field: 'email', header: 'EMAIL', type: 'text' },
      { field: 'phoneNumber1', header: 'PHONE', type: 'text' },
      { field: 'paymentNotification', header: 'PAYMENT NOTIFICATION RECIPIENT', type: 'checkbox' },
    ];
    // TODO: Dynamically set primaryAddress
    this.primaryAddress = 'pAddress1';
    this.selectedColumns = this.cols.slice(0, 8);
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Contacts',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Contacts',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any,overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    if (rowData.position && rowData.email) {
      if (validateEmail(rowData.email)) {
        delete this.clonedRowData[rowData.id];
        this.isEditing = false;
        this.saveServicerContact(rowData);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Please enter valid email.`,
        });
        document.getElementById(this.editConstant + this.selectedItem.id).click();
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please add values for position and email.`,
      });
      document.getElementById(this.editConstant + this.selectedItem.id).click();
    }
    this.isAdd = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new PriceTable();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.isAdd = true;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        const rowData = { ...this.dataList[this.selectedRowIndex], isDeleted: true };
        this.saveServicerContact(rowData);
        // this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  saveServicerContact(rowData: any) {
    const { servicerProfilesIdOriginal } = this.servicerService.serviceFormData;
    if (servicerProfilesIdOriginal) {
      const payload = {
        categoryType: 'ServicerProfile',
        contactPosition: rowData.position,
        businessPhone: rowData.businessPhone,
        firstName: rowData.firstName,
        lastName: rowData.lastName,
        fax: rowData.fax,
        email: rowData.email,
        phoneNumber1: rowData.phoneNumber1,
        servicerProfilesId: servicerProfilesIdOriginal,
        isDeleted: rowData.isDeleted,
        contactIdOriginal: rowData.contactIdOriginal || null,
        paymentNotification: rowData.paymentNotification,
      };
      this.servicerContactService
        .createUpdateServicerContact(payload)
        .subscribe(({ data, loading }: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Record Saved Successfully.`,
          });
          this.getServicerContacts();
        });
    }
  }

  getServicerContacts() {
    this.isLoading = true;
    const servicerProfileId = this.servicerService.serviceFormData
      ? this.servicerService.serviceFormData.servicerProfilesIdOriginal
      : null;
    if (servicerProfileId) {
      this.servicerService.getServicerProfilesContactInfo(servicerProfileId).subscribe(
        ({ data, errors }) => {
          if (!errors) {
            const { getServicerProfilesContactInfo } = data;
            const dataTable = [];
            if (getServicerProfilesContactInfo && getServicerProfilesContactInfo.length) {
              getServicerProfilesContactInfo.forEach((contact, index) => {
                dataTable.push({
                  ...contact,
                  paymentNotification: contact.notification,
                  id: index,
                });
              });
            }
            this.dataList = dataTable;
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }
}
