import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContractService } from '../contract.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import { InquiryTabService } from './inquiry-tab.service';
import { inquiryHeaderOptions, ellipsisCrudOptions } from './inquiry.ellipsis';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormField } from '../../shared/form-field/form-field';
import { ConfirmationService, MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { dateTimeFormatterCST } from '../../shared/utilities/date-utilities';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'inquiry-tab',
  templateUrl: 'inquiry-tab.template.html',
  providers: [InquiryTabService, MessageService],
})
export class InquiryTabComponent implements OnInit, OnDestroy {
  dataList: any[];
  dataListDefault: any[];
  cols: any[];
  tabKey: string;
  querySubscription: any;
  selectedItem: any;
  header: string;
  formModalInfo: FormField<any>[] = [];
  ellipsisHeaderOptions: any[] = inquiryHeaderOptions;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptionsSec: any;
  ellipsisOptionsSec: any;
  displayServiceInfoModal = false;
  isReadOnly = false;
  isEditing: boolean;
  selectedRowIndex: number;
  selectedRow: any;
  editConstant: string;
  clonedRowData: KeyMap = {};
  querySubscriptions: Subscription[] = [];
  isLoading = false;
  constructor(
    private contractService: ContractService,
    private tabHandlerService: TabHandlerService,
    private cdr: ChangeDetectorRef,
    private inquiryTabService: InquiryTabService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'status', header: 'STATUS' },
      { field: 'userName', header: 'ASSIGNED TO' },
      { field: 'content', header: 'CONTENT' },
      { field: 'enteredTime', header: 'ENTERED TIME' },
      { field: 'enteredBy', header: 'ENTERED BY' },
    ];

    this.editConstant = uuidv4();
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.loadInquiry();
        }
      },
    });
    this.dataList = [];
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Inquiry',
      category: 'Contract',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Inquiry',
      category: 'Contract',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  loadInquiry() {
    this.isLoading = true;
    this.contractService.isLoading.next(true);
    this.querySubscription = this.inquiryTabService
      .getInquiry(this.contractService.contractConfig.searchParams.id)
      .subscribe(
        ({ data }) => {
          this.isLoading = false;
          this.contractService.isLoading.next(false);
          if (data.getContractProfileById) {
            const modifiedData = data.getContractProfileById;
            this.dataList = modifiedData.contractInquiry.map((el) => {
              return { ...el, enteredTime: dateTimeFormatterCST(el.enteredTime) };
            });
          } else {
            this.dataList = this.dataListDefault;
          }
        },
        (err) => {
          this.isLoading = false;
          this.contractService.isLoading.next(false);
          throw err;
        },
      );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
    this.selectedRow = item;
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    this.selectedRow = this.dataList[this.selectedRowIndex];
    this.displayServiceInfoModal = true;
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }

  onRowEditSave(rowData: any, isDelete: boolean) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    rowData.enteredBy = this.azureService.displayName;
    rowData.insertUserName = this.azureService.accountId;
    rowData.contractNumber = this.contractService.contractDataModel.leftBaseSection.contractNumber;
    this.contractService.isLoading.next(true);
    if (isDelete) {
      rowData.isDeleted = true;
    }
    this.querySubscriptions.push(
      this.inquiryTabService.createUpdateContractInquiry(rowData).subscribe(
        ({ data, loading }: any) => {
          let successMessage = 'Saved successfully.';
          if (isDelete) {
            this.dataList.splice(this.selectedRowIndex, 1);
            successMessage = 'Deleted successfully.';
          } else {
            this.dataList[this.selectedRowIndex][`contractInquiryEventsIdOriginal`] =
              data[`createUpdateContractInquiry`][`contractInquiryEventsIdOriginal`];

            this.dataList[this.selectedRowIndex][`isNew`] = false;
          }
          this.contractService.isLoading.next(false);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: successMessage,
          });
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving, please try again.',
          });
        },
      ),
    );
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    this.selectedRow = {};
    this.displayServiceInfoModal = true;
  }

  onCreateUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
    }
    this.loadInquiry();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.onRowEditSave(this.selectedItem, true);
      },
    });
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
