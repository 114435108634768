import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ExceptionsService } from './exceptions.service';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { exceptionsEllipsisHeaderOptions } from '../../../coverage.constant';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { CoverageExceptions } from '../../../coverage-models';

@Component({
  selector: 'cv-exceptions',
  templateUrl: 'exceptions.template.html',
  providers: [ExceptionsService],
})
export class ExceptionsComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = exceptionsEllipsisHeaderOptions;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  constructor(
    private exceptionsService: ExceptionsService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.exceptionsService.getExceptions().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'included', header: 'CODE', type: 'checkbox' },
      { field: 'name', header: 'DESCRIPTION', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = new CoverageExceptions();
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }
}
