import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PositionService {
  constructor() {}
  getPosition(): Observable<any[]> {
    return of([
      {
        id: 1,
        name: 'Billing Contact'
      },
      {
        id: 2,
        name: 'Contract Entry'
      },
      {
        id: 3,
        name: 'It Contract'
      }
    ]);
  }
}
