<div class="crm-container">
  <app-section-header [title]="'Job Availability'" />
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner><p-toast></p-toast>
  <div class="sub-section" *ngIf="!isLoading">
    <div class="grid">
      <div [ngClass]="'col-2 label-color'">Customer Zip Code</div>
      <div class="col-2">{{customerDetails.zipCode}}</div>
      <div [ngClass]="'col-2 label-color'">Customer Address 1</div>
      <div class="col-2">{{customerDetails.address1}}</div>
      <div [ngClass]="'col-2 label-color'">Customer City</div>
      <div class="col-2">{{customerDetails.city}}</div>
      <div [ngClass]="'col-2 label-color'">Customer State</div>
      <div class="col-2">{{customerDetails.state}}</div>
      <div [ngClass]="'col-2 label-color'">Customer Phone</div>
      <div class="col-2">{{customerDetails.customerPhone}}</div>
      <div [ngClass]="'col-2 label-color'">Customer Last Name</div>
      <div class="col-2">{{customerDetails.customerLastName}}</div>
    </div>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" #dt [columns]="selectedColumns"
      [value]="dataList" dataKey="id" editMode="row"><ng-template pTemplate="caption">
        <div class="table-header">
          <div class="multi-select">
            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
              selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
          </div>
          <div class="multi-select">
            <label for="daysNeeded">Days Needed </label><p-dropdown [options]="daysOptions"
              [(ngModel)]="selectedDaysNeeded" placeholder="Select" (onChange)="onDaysChange($event)"></p-dropdown>
          </div>
        </div>
      </ng-template><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <p-multiSelect *ngSwitchCase="'serviceProviderName'" [options]="servicerProviders"
              defaultLabel="All service providers"
              (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect><p-multiSelect
              *ngSwitchCase="'timeRange'" [options]="timeRanges" defaultLabel="All time ranges"
              (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect><p-multiSelect
              *ngSwitchCase="'availableDateFormatted'" [options]="availableDates" defaultLabel="All available dates"
              (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect>
          </th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing"
        let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor><ng-template pTemplate="input"><input class="input-text" *ngIf="col.type === 'text'"
                  type="text" [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'radio'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" /><input type="radio" *ngIf="col.type === 'radio'" name="rowData[col.id]"
                  label="" (click)="onRadioButtonClick($event, rowData)"
                  value="rowData"></ng-template><ng-template *ngIf="col.type === 'text'"
                pTemplate="output">{{rowData[col.field]}} </ng-template><ng-template *ngIf="col.type === 'checkbox'"
                pTemplate="output"><input type="checkbox" [(ngModel)]="rowData[col.field]"
                  disabled="disabled" /></ng-template><ng-template *ngIf="col.type === 'radio'"
                pTemplate="output"><input type="radio" name="rowData[col.id]" label=""
                  (click)="onRadioButtonClick($event,rowData)"
                  value="rowData"></ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #opRow appendTo="body"><p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel><p-overlayPanel
  #opHeader appendTo="body"><p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)"></p-listbox></p-overlayPanel>