import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { FormField } from '../form-field/form-field';
import { ContactInformationFormFieldService } from './contact-information.service';

@Component({
  selector: 'gn-contact-information',
  templateUrl: 'contact-information.template.html',
  styleUrls: ['contact-information.scss'],
  providers: [FormFieldControlService, ContactInformationFormFieldService],
})
export class GenericContactInformationComponent implements OnInit {
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';

  // to use model instead of service
  @Input() enableModelData: boolean;
  private privateModelData: any;
  @Output() modelDataChange = new EventEmitter();

  get modelData(): any {
    return this.privateModelData;
  }
  @Input() set modelData(value: any) {
    this.privateModelData = value;
    this.modelDataChange.emit(this.modelData);
  }

  constructor(
    private qcs: FormFieldControlService,
    private contactInfoService: ContactInformationFormFieldService,
  ) {}

  ngOnInit() {
    this.contactInfoService.getContactTabInfoFields().subscribe((contactInfo) => {
      this.formFields = contactInfo;
      this.form = this.qcs.toFormGroup(this.formFields);
      this.form.valueChanges.subscribe((x) => {
        this.modelDataChange.emit(x);
        this.modelData = this.modelData ? this.modelData : {};
        this.modelData.firstName = x.firstName;
        this.modelData.lastName = x.lastName;
        this.modelData.businessPhone = x.businessPhone;
        this.modelData.businessPhoneTypeCode =
          x.businessPhoneTypeCode && x.businessPhoneTypeCode.value
            ? {
                key: x.businessPhoneTypeCode.value,
                value: x.businessPhoneTypeCode.value,
              }
            : null;
        this.modelData.fax = x.fax;
        this.modelData.email = x.email;
        this.modelData.valid = this.form.valid;
      });

      if (this.enableModelData && this.modelData) {
        this.form.patchValue({
          ...this.modelData,
        });
      }
    });
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
  }
}
