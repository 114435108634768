import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckBoxField } from '../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../shared/form-field/textbox-field';
import { TextboxSearchField } from '../../../shared/form-field/textbox-search';
import { FormField } from '../../../shared/form-field/form-field';
import { TextAreaField } from '../../../shared/form-field/textarea-field';

@Injectable()
export class ServiceBenchModalService {
  constructor() {}

  getSendEmailFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'type',
        label: 'Type ',
        options: [{ key: 'Dispatch', value: 'Dispatch' }],
        isSingleselect: true,
        order: 1
      }),
      new DropdownField({
        key: 'code',
        label: 'Code',
        options: [{ key: 'standardDispatch', value: 'Standard Dispatch' }],
        isSingleselect: true,
        order: 2
      }),
      new TextboxField({
        key: 'ccEmailAddress',
        label: 'CC Email Address',
        order: 3
      }),
      new TextboxField({
        key: 'toEmailAddress',
        label: 'To Email Address',
        order: 4
      }),
      new TextAreaField({
        key: 'customText',
        label: 'Custom Text',
        rows: 6,
        order: 5
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
