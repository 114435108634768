<div class="crm-container">
  <app-section-header [title]="'Process Claim Results'" />
  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id"><ng-template
        pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <p-cellEditor *ngIf="col.type === 'text'">
              <ng-template pTemplate="input"><input class="input-number" type="text" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
            <p-cellEditor *ngIf="col.type === 'number'">
              <ng-template pTemplate="input"><input class="input-number" type="number"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>