import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormField } from '../../shared/form-field/form-field';
import { Observable } from 'rxjs';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DashboardService } from './dashboard.service';
import { TabsService } from '../../services/tabs.service';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { ClaimsLookup } from '../../shared/constants/lookup-list';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.template.html',
  providers: [DashboardService],
  styleUrls: ['dashboard.scss'],
})
export class ServiceDashboardComponent implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields$: Observable<FormField<any>[]>;
  authDetails: IRoleAuthorizationService;
  isLoading: boolean;
  cols: any[];
  summaryData: any[];
  optionsBuyingGroup: any;
  optionsDealerGroup: any;
  optionsServicerList: any;
  optionsStatus: any;
  selectedBuyingGroup: any;
  selectedDealer: any;
  selectedServicer: any;
  selectedStatus: any;
  constructor(
    private service: DashboardService,
    private roleService: RoleAuthorizationService,
    private tabService: TabsService,
    private dropdownDataService: DropdownDataService,
    private claimsLookupService: ClaimsLookup,
  ) {}

  ngOnInit() {
    // create column list
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'status', width: '20%' },
      { field: 'lessThan2Hrs', header: '<2 Hrs', type: 'link', width: '5%' },
      { field: 'lessThan4Hrs', header: '<4 Hrs', type: 'link', width: '5%' },
      { field: 'lessThan6Hrs', header: '<6 Hrs', type: 'link', width: '5%' },
      { field: 'lessThan8Hrs', header: '<8 Hrs', type: 'link', width: '5%' },
      { field: 'lessThan12Hrs', header: '<12 Hrs', type: 'link', width: '5%' },
      { field: 'lessThan1Days', header: '<1 Day', type: 'link', width: '5%' },
      { field: 'lessThan2Days', header: '<2 Days', type: 'link', width: '5%' },
      { field: 'lessThan3Days', header: '<3 Days', type: 'link', width: '5%' },
      { field: 'lessThan4Days', header: '<4 Days', type: 'link', width: '5%' },
      { field: 'lessThan5Days', header: '<5 Days', type: 'link', width: '5%' },
      { field: 'lessThan10Days', header: '<10 Days', type: 'link', width: '5%' },
      { field: 'lessThan20Days', header: '<20 Days', type: 'link', width: '5%' },
      { field: 'lessThan30Days', header: '<30 Days', type: 'link', width: '5%' },
      { field: 'lessThan45Days', header: '<45 Days', type: 'link', width: '5%' },
      { field: 'lessThan60Days', header: '<60 Days', type: 'link', width: '5%' },
      { field: 'moreThan60Days', header: '>60 Days', type: 'link', width: '5%' },
    ];

    this.optionsBuyingGroup = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map((x) => ({
          key: x.name,
          value: x.agentIdOriginal,
        }))
      : [];

    this.optionsDealerGroup = this.dropdownDataService.dealerGroupData ? this.dropdownDataService.dealerGroupData.map((x) => ({
      key: x.dealerGroupName,
      value: x.dealerGroupsIdOriginal,
    }))
    : [];

    this.optionsServicerList = this.dropdownDataService.servicerListData
      ? this.dropdownDataService.servicerListData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];

    this.optionsStatus = this.claimsLookupService.claimsLookup;

    this.getDashboardSummary(null);
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }

  populateTableData(data: any[]) {
    const result = [];

    data.forEach((element) => {
      if (!result.find((r) => r.status === element.status)) {
        result.push({
          status: element.status,
          lessThan2Hrs: '',
          lessThan4Hrs: '',
          lessThan6Hrs: '',
          lessThan8Hrs: '',
          lessThan12Hrs: '',
          lessThan1Days: '',
          lessThan2Days: '',
          lessThan3Days: '',
          lessThan4Days: '',
          lessThan5Days: '',
          lessThan10Days: '',
          lessThan20Days: '',
          lessThan30Days: '',
          lessThan45Days: '',
          lessThan60Days: '',
          moreThan60Days: '',
        });
      }
    });

    data.forEach((element) => {
      const index = result.indexOf(result.find((t) => t.status === element.status));
      const columnName = this.getColumnName(element.columnTimeStamp);

      // cannot check if(index && result[index]) as index can be zero
      if (index !== null && index !== undefined && result[index]) {
        result[index][columnName] = element.count;
      }
    });

    return result.sort((a, b) => a.status.localeCompare(b.status));
  }

  searchClaim(status, duration) {
    const searchParams = {
      status,
      duration,
      buyingGroup: this.generateSelectedItem(this.selectedBuyingGroup, true),
      dealer: this.generateSelectedItem(this.selectedDealer),
      servicer: this.generateSelectedItem(this.selectedServicer),
      fromDashboard: true,
    };
    this.tabService.createTab({
      header: 'SearchServiceOrder',
      content: searchParams,
      type: 'SearchServiceOrder',
    });
  }

  getDashboardSummary(searchParams) {
    // get summary data
    this.isLoading = true;
    this.service.getDashboardSummary(searchParams).subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        this.summaryData = [];
        if (data.getDashboardSummary) {
          this.summaryData = this.populateTableData(data.getDashboardSummary);
        }
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
  }

  refreshDashboard() {
    const searchParams = {
      buyingGroup: this.generateSelectedItem(this.selectedBuyingGroup),
      dealer: this.generateSelectedItem(this.selectedDealer),
      servicer: this.generateSelectedItem(this.selectedServicer),
      status: this.generateSelectedItem(this.selectedStatus),
    };

    this.getDashboardSummary(searchParams);
  }

  private getColumnName(timestmp) {
    let columnName = 'moreThan60Days';
    switch (timestmp) {
      case '<02 hrs':
        columnName = 'lessThan2Hrs';
        break;
      case '<04 hrs':
        columnName = 'lessThan4Hrs';
        break;
      case '<06 hrs':
        columnName = 'lessThan6Hrs';
        break;
      case '<08 hrs':
        columnName = 'lessThan8Hrs';
        break;
      case '<12 hrs':
        columnName = 'lessThan12Hrs';
        break;
      case '<01 days':
        columnName = 'lessThan1Days';
        break;
      case '<02 days':
        columnName = 'lessThan2Days';
        break;
      case '<03 days':
        columnName = 'lessThan3Days';
        break;
      case '<04 days':
        columnName = 'lessThan4Days';
        break;
      case '<05 days':
        columnName = 'lessThan5Days';
        break;
      case '<10 days':
        columnName = 'lessThan10Days';
        break;
      case '<20 days':
        columnName = 'lessThan20Days';
        break;
      case '<30 days':
        columnName = 'lessThan30Days';
        break;
      case '<45 days':
        columnName = 'lessThan45Days';
        break;
      case '<60 days':
        columnName = 'lessThan60Days';
        break;
      case '>60 days':
        columnName = 'moreThan60Days';
        break;
    }

    return columnName;
  }

  private generateSelectedItem(list, withQuotes = false) {
    let result = '';

    if (list) {
      list.forEach((element) => {
        if (result) {
          result += ', ';
        }
        if (withQuotes) {
          result += `'${element.key}'`;
        } else {
          result += `${element.key}`;
        }
      });
    }

    return result;
  }
}
