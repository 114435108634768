import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable, of } from 'rxjs';
import { CrmGraphql } from 'src/app/modules/crm/shared/constants/graphql-constants';

const service = gql`
  query(
    $state: String!
    $latitude: Float
    $longitude: Float
    $serviceType: String
    $city: String
    $zipCode: String
    $manufacturerName: String
    $modelName: String
    $contractNumber: String
    $limit: Int
    $offset: Int
    $productId: Int
  ) {
    getServicerEligibleProfiles(
      state: $state
      latitude: $latitude
      longitude: $longitude
      serviceType: $serviceType
      city: $city
      zipCode: $zipCode
      manufacturerName: $manufacturerName
      modelName: $modelName
      contractNumber: $contractNumber
      limit: $limit
      offset: $offset
      productId: $productId
    ) {
      servicerProfilesId
      servicerNumber
      selfServicingDealer
      servicerGroup
      servicerStatus
      sealedSystemsRepair
      customerSatisfaction
      distance(fromLat: $latitude, fromLong: $longitude)
      statusCode
      servicerName
      rating
      validInsurance
      facilityType
      contracted
      servicerProfilesIdOriginal
      selfServicing
      preferred
      restricted
      isMFGAuthorized
    }
  }
`;
@Injectable({
  providedIn: 'root'
})
export class ServicersTableService {
  constructor(private apollo: Apollo) {}

  getServiceDetails = data =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: service,
      variables: {
        state: data.state,
        latitude: data.latitude,
        longitude: data.longitude,
        serviceType:
          data.serviceType && data.serviceType.value ? data.serviceType.value : data.serviceType,
        city: data.city,
        zipCode: data.zipCode,
        manufacturerName: data.manufacturerName,
        modelName: data.modelName,
        contractNumber: data.contractNumber,
        productId: data.productId,
        limit: data.limit,
        offset: data.offset
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getPriceTable(): Observable<any[]> {
    return of([
      {
        id: 1,
        select: true,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false
      },
      {
        id: 3,
        select: false,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false
      },
      {
        id: 3,
        select: false,
        servicerAddress: 'SERVICER NAME & ADDRESS',
        selfServicing: true,
        servicerStatus: 'Cell Phone Repair',
        servicerGroup: 'Cell Phone Repair',
        distance: 'Cell Phone Repair',
        sealedSystems: true,
        customerSatisfaction: 'Cell Phone Repair',
        rating: 'Cell Phone Repair',
        validInsurance: false,
        facilityType: 'Facility type',
        contracted: true,
        mfgAuthorized: false
      }
    ]);
  }
}
