<!-- <app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner> -->
<div class="form-details-container">
  <div class="form-details-title">
    <div class="form-thumnail"></div>
    <div class="form-name"><input disabled="disabled" value="Insurance Company Profile" /></div>
    <div class="form-dot-button"></div>
  </div>
  <div class="form-details-body">
    <div>
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="form-row" *ngFor="let formField of formFields; let i = index">
          <form-field [formField]="formField" [form]="form" *ngIf="i&lt;12"
            (changeEvent)="onChangeEvent($event, formField.key)"></form-field>
        </div>
        <div class="sub-section">
          <div class="title">SMTP</div>
        </div>
        <div class="form-row" *ngFor="let formField of formFields; let i = index">
          <form-field [formField]="formField" [form]="form" *ngIf="i>=12"
            (changeEvent)="onChangeEvent($event, formField.key)"></form-field>
        </div>
        <div class="button-bar">
          <div *ngFor="let singleButton of button">
            <button type="singleButton.type" [disabled]="singleButton.disabled" [hidden]="singleButton.hidden">
              {{singleButton.name}}
            </button>
          </div>
        </div>
        <p-toast></p-toast>
        <!-- <p-message severity="success" text="Record Saved" *ngIf="isSuccess"> </p-message> -->
      </form>
    </div>
  </div>
</div>