import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SalesRepresentativeService } from './sales-representative.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisCrudOptions } from '../../shared/constants/ellipsis-options';
import { salesRepEllipsisHeaderOptions } from '../catalog.constant';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormField } from '../../shared/form-field/form-field';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { CatalogFormFieldService } from '../catalog-form-field.service';
import { FormGroup } from '@angular/forms';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
@Component({
  selector: 'sales-representative',
  templateUrl: 'sales-representative.template.html',
  providers: [SalesRepresentativeService, FormFieldControlService, CatalogFormFieldService],
})
export class SalesRepresentativeComponent extends FormCanDeactivate implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = salesRepEllipsisHeaderOptions;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  displayDialog: boolean;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  constructor(
    private salesRepresentativeService: SalesRepresentativeService,
    private service: CatalogFormFieldService,
    private qcs: FormFieldControlService,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.salesRepresentativeService.getSalesRepresentative().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'code', header: 'CODE', type: 'text' },
      { field: 'name', header: 'NAME', type: 'text' },
    ];
    this.service.getSalesRepresentativeFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onClickAdd(event) {
    this.displayDialog = true;
  }

  addNewRowItem(formData: FormGroup) {
    const rowItem = formData.getRawValue();
    rowItem.id = this.dataList.length + 1;
    rowItem.lossCodeType = rowItem.lossCodeType ? rowItem.lossCodeType.key : '';
    this.dataList.push(rowItem);
    this.displayDialog = false;
    this.formValueChanged.emit(formData.dirty);
  }
}
