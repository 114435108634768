<app-tab-spinner *ngIf="isLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 1; width:100%"></app-tab-spinner>
<div class="import-file">
  <div class="title" *ngIf="title">{{title}}</div>
  <div class="title" *ngIf="!title">Import Documents</div>
  <p-toast></p-toast>
  <div class="sub-section">
    <div class="grid field-container">
      <div class="col-6 form-group" *ngIf="isGeneric">
        <label for="documentType">Type</label>
        <p-dropdown id="documentType" name="documentType" [options]="documentOptions" [(ngModel)]="selectedDocument"
          optionLabel="key" placeholder="Choose">
        </p-dropdown>
      </div>
      <div class="col-6 form-group">
        <label for="documentDescription">Description</label>
        <input id="documentDescription" name="documentDescription" pInputText="pInputText"
          [(ngModel)]="documentDescription" type="text" />
        <p-checkbox *ngIf="contractBankCheckBox" id="isBankContracts" name="isBankContracts" [binary]="true"
          label="Is this a Bank Contracts Import file?" (onChange)="onChecked($event)">
        </p-checkbox>
      </div>
    </div>
    <p-table styleClass="p-datatable-gridlines" class="import-file__content">
      <ng-template pTemplate="header" let-columns="">
        <tr>
          <th *ngFor="let col of columns"></th>
          <label class="import-file__label crm-label">File</label>
          <p-fileUpload name="myfile[]" *ngIf="5000000"
            [disabled]="(!(selectedDocument && selectedDocument.key) || isLoading)" chooseLabel="Browse"
            (uploadHandler)="onUpload($event)" [customUpload]="true" (onRemove)="fileReset()">
          </p-fileUpload>
        </tr>
        <label class="import-file__label" *ngIf="!isGeneric">Maximum size supported 200MB</label>
        <label class="import-file__label" *ngIf="isGeneric">
          File supported formats (CSV), Maximum size supported 10MB
        </label>
        <p-progressBar [value]="uploadProgress" *ngIf="isLoading"> </p-progressBar>
      </ng-template>
    </p-table>
    <div *ngIf="!attachmentId">
      <div class="title">Results</div>
      <div class="spinner" *ngIf="isLoading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="content import-file__content"
        *ngIf="importSummary.length>0 && this.selectedDocument.key ==='ImportPriceTable'">
        <div class="grid" *ngFor="let item of importSummary">
          <div class="col-4">
            <div class="form-group"><label>{{item.label}}</label></div>
          </div>
          <div class="col-4 text-align-center">
            <div class="form-group"><span>{{item.value}}</span></div>
          </div>
          <div class="col-4 text-align-right">
            <div *ngIf="item.key==='ValidationStatus' && item.value==='Failed'">
              <div class="link" (click)="downloadErrorFile()">Download File</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content import-file__content" *ngIf="fieldsNames && this.selectedDocument.key !=='ImportPriceTable'">
        <div class="grid">
          <div class="col-6">
            <div class="form-group"><label>{{fieldsNames.validation_name}}</label></div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <span>{{records.validation}}</span>
              <div *ngIf="records.validation === 'Failed'">
                <div class="link" (click)="downloadErrorFile()">Download File</div>
              </div>
            </div>
          </div>
          <div class="col-6" *ngIf="contractsCount">
            <div class="form-group"><label>{{fieldsNames.total_count_name}}</label></div>
          </div>
          <div class="col-6" *ngIf="contractsCount">
            <div class="form-group"><span>{{records.total_count}}</span></div>
          </div>
          <div class="col-6">
            <div class="form-group"><label>{{fieldsNames.cost_name}} </label></div>
          </div>
          <div class="col-6">
            <div class="form-group"><span>{{records.cost}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>