import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Dialog } from 'primeng/dialog';
import {
  filterTableData,
  exportExcel,
  exportPdf,
  parseHTMLToString,
} from '../utilities/crm-table-utilities';

@Component({
  selector: 'crm-export-dialog',
  templateUrl: 'export-dialog.template.html',
  styleUrls: ['export-dialog.scss'],
})
export class ExportDialogComponent {
  private privateDisplayDialog: boolean;

  @Input() rows: any;
  @Input() filterColumnList: any;
  @Input() filterByColumns: any;
  @Input() fileName: any;
  @Input() dataTable: any;
  @Input() pdfTableOptions: any;
  @Output() displayDialogChange = new EventEmitter();

  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }

  export(fileType) {
    this.displayDialog = false;
    let filteredData = [...this.rows];
    if (this.filterByColumns) {
      filteredData = filterTableData(this.rows, this.filterColumnList);
    }

    const orignalFilterData = JSON.parse(JSON.stringify(filteredData));
    filteredData = parseHTMLToString(filteredData);

    switch (fileType) {
      case 'CSV':
        this.dataTable.value = filteredData;
        this.dataTable.exportCSV();
        this.dataTable.value = orignalFilterData; // Updating back HTML Format Data
        break;
      case 'EXCEL':
        exportExcel(filteredData, this.fileName);
        break;
      case 'PDF':
        exportPdf(this.filterColumnList, filteredData, this.fileName, this.pdfTableOptions);
        break;
      default:
        exportPdf(this.filterColumnList, filteredData, this.fileName, this.pdfTableOptions);
    }
  }
}
