import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { NavigationPanel } from '../../../shared/navigation-panel/navigation-panel.model';
import { StandardProblemComponent } from './standard-problem/standard-problem.component';
import { ProblemService } from './problem.service';
import { ADHProblemComponent } from './adh-problem/adh-problem.component';
import { TheftProblemComponent } from './theft-problem/theft-problem.component';
import { ServiceOrderDataService } from '../service-order-data.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'problem-modal',
  templateUrl: 'problem-modal.template.html',
  styleUrls: ['./problem-modal.scss'],
  providers: [FormFieldControlService, ProblemService, MessageService],
})
export class ProblemModalComponent {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formFields: FormField<string>[] = [];
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  navigationPanel: NavigationPanel[];
  constructor(
    private qcs: FormFieldControlService,
    private serviceOrderDataService: ServiceOrderDataService,
    private messageService: MessageService,
  ) {
    this.navigationPanel = [
      {
        component: StandardProblemComponent,
        data: { name: 'Main' },
        title: 'STD',
        tabname: 'Standard',
      },
      // ,
      // {
      //   component: ADHProblemComponent,
      //   data: { name: 'Main' },
      //   title: 'ADH',
      //   tabname: 'ADH'
      // },
      // {
      //   component: TheftProblemComponent,
      //   data: { name: 'Main' },
      //   title: 'THF',
      //   tabname: 'Theft'
      // }
    ];
  }

  onSubmit() {
    this.serviceOrderDataService.saveStandardFormData.next(true);
    this.serviceOrderDataService.saveDataComplete.subscribe((data) => {
      if (data) {
        this.displayDialog = false;
        this.displayDialogChange.emit(this.displayDialog);
        this.serviceOrderDataService.standardProblemDataModelInitial = {
          ...this.serviceOrderDataService.standardProblemDataModel,
        };
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill required fields',
        });
      }
    });

    // const payload = this.form.getRawValue();
    // if (!this.form.valid) {
    //   return;
    // }
    // this.formSubmitEvent.emit(this.form);
  }
  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.serviceOrderDataService.standardProblemDataModel = {
      ...this.serviceOrderDataService.standardProblemDataModelInitial,
    };
  }
}
