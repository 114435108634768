import { Component, Input, OnInit } from '@angular/core';
import { ResultService } from './result.service';

@Component({
  selector: 'claim-result',
  templateUrl: 'result.template.html',
  styleUrls: ['../../../shared/styles/crm-styles.scss', 'result.scss'],
  providers: [ResultService],
})
export class ResultComponent implements OnInit {
  admin: any[];
  cols: any[];
  resultCount = 33; // todo: need to be replace with actual value
  constructor(private workQueueService: ResultService) {}

  ngOnInit() {
    this.workQueueService.getResult().subscribe((data) => {
      this.admin = data;
    });
    this.cols = [
      { field: 'status', header: '' },
      { field: 'count', header: '' },
      { field: 'link', header: '' },
    ];
  }
}
