import { Component, OnInit } from '@angular/core';
import { buttonStatus } from 'src/app/modules/crm/shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  attachementEllipsisRowOptions,
  attachementEllipsisHeaderOptions,
} from '../../service.constant';
import { KeyMap } from 'src/app/modules/crm/shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { ServicerDocumentService } from './servicer-documents.service';

import { ViewFile } from '../../../shared/view-file/view-file.interface';
import { CrmDocumentType } from '../../../shared/constants/enums';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { ServicerService } from '../servicer.service';

@Component({
  selector: 'servicer-documents',
  templateUrl: 'servicer-documents.template.html',
  providers: [ServicerDocumentService],
})
export class ServicerDocumentsComponent implements OnInit {
  documentSearchParams: ViewFile;
  tabKey: string;

  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = attachementEllipsisRowOptions;
  ellipsisHeaderOptions: any[] = attachementEllipsisHeaderOptions;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  constructor(
    // private serviceAttachmentsService: ServicerDocumentService,
    private tabHandlerService: TabHandlerService,
    private servicerService: ServicerService,
  ) {
    this.documentSearchParams = {
      fileName: '',
      attachmentsType: { key: 'ServicerProfile', value: CrmDocumentType.ServicerProfile },
      uploadedDate: null,
      offset: 0,
      limit: 20,
      identifierId: null,
    };
  }

  ngOnInit() {
    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (
          this.tabKey === tab &&
          this.servicerService.serviceFormData &&
          this.servicerService.serviceFormData.servicerProfilesIdOriginal
        ) {
          this.documentSearchParams.identifierId =
            this.servicerService.serviceFormData.servicerProfilesIdOriginal;
        }
      },
    });
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'fileName', header: 'FILE NAME', type: 'text' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'uploadedDate', header: 'UPLOADED DATE', type: 'text' },
      { field: 'uploadedBy', header: 'UPLOADED BY', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick() {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
}
