import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ContractFormFieldService } from '../services/contract-form-field.service';
import { FormField } from '../shared/form-field/form-field';
import { Observable } from 'rxjs';
import { NavigationPanel } from '../shared/navigation-panel/navigation-panel.model';
import { BuyingGroupMainTabComponent } from './buying-group-main-tab/buying-group-main-tab.component';
import { BuyingGroupDocumentsTabComponent } from './document/document.component';
import { BuyingContactTabComponent } from './contact-tab/contact-tab.component';
import { ImportFileComponent } from '../shared/import-file/import-file.component';
import { BuyingGroupService } from '../services/buying-group.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../shared/constants/button-class';
import { buyingGroupEllipsisOptions } from './constants/buying-group-ellipsis';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { BuyingGroupDataService } from './buying-group.service';
import { AddressCategoryType } from '../shared/constants/enums';
import { NoteComponent } from '../shared/notes/note.component';
import { BuyingGroupDocumentUploadComponent } from './buying-group-import-file/buying-group-file.component';
@Component({
  selector: 'buying-group',
  templateUrl: 'buyingGroup.template.html',
  styleUrls: ['buyingGroup.scss'],
  providers: [BuyingGroupService, BuyingGroupDataService],
})
export class BuyingGroupComponent implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields$: Observable<FormField<any>[]>;
  navigationPanel: NavigationPanel[];
  authDetails: IRoleAuthorizationService;
  @Input() searchParams: any;
  addressCategoryType = AddressCategoryType;
  component = BuyingGroupComponent;
  componentDisplay = false;
  agentId = 0;
  constructor(
    service: BuyingGroupService,
    private roleService: RoleAuthorizationService,
    private buyingGroupDataService: BuyingGroupDataService,
  ) {
    this.formFields$ = service.getBuyingGroupDetailsFormFields();

    // set up left-tab navigation
    this.navigationPanel = [
      {
        component: BuyingGroupMainTabComponent,
        data: { name: 'Main' },
        title: 'MN',
        tabname: 'Main',
        tabKey: 'main-tab',
      },
      // This has been move to a generic tabs
      {
        component: BuyingContactTabComponent,
        data: { name: 'Contact' },
        title: 'CON',
        tabname: 'Contact',
        tabKey: 'contact-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Contacts',
          category: 'Agent',
        },
      },
      {
        component: BuyingGroupDocumentsTabComponent,
        data: { name: 'Documents' },
        title: 'DC',
        tabname: 'Documents',
        tabKey: 'documents-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'READ_ONLY',
          permission: 'Documents',
          category: 'Agent',
        },
      },
      {
        component: BuyingGroupDocumentUploadComponent,
        data: { name: 'Import Documents' },
        title: 'ID',
        tabname: 'Import Documents',
        tabKey: 'import-tab',
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'ADD',
          permission: 'Documents',
          category: 'Agent',
        },
      },
    ];
    this.authDetails = {
      authorizationType: AuthorizationType.RightPanel,
      component: BuyingGroupComponent,
      generalArray: this.navigationPanel,
    };
    this.navigationPanel = Object.assign(roleService.applyAuthorization(this.authDetails));
  }

  ngOnInit() {
    this.componentDisplay = this.roleService.isVisible(NoteComponent, BuyingGroupComponent);

    this.agentId = this.buyingGroupDataService.buyingGroupConfig.searchParams.id = !isNaN(
      this.searchParams.agentIdOriginal,
    )
      ? parseInt(this.searchParams.agentIdOriginal, 10)
      : 0;
    this.buyingGroupDataService.isAgentIdOriginalAvailable.subscribe({
      next: (isAgentId) => {
        if (isAgentId) {
          this.agentId = this.buyingGroupDataService.buyingGroupFormData.agentIdOriginal;
        }
      },
    });
  }

  setChangeData(isDirty: boolean) {
    this.formValueChanged.emit(isDirty);
  }
}
