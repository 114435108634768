import { Injectable } from '@angular/core';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { TextboxSearchField } from '../shared/form-field/textbox-search';
import { DropdownDataService } from './dropdown.data.service';
import { FaxPattern, PhonePattern } from '../shared/constants/validation-regex';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Injectable()
export class DealerFormFieldService {
  constructor(
    private dropdownDataService: DropdownDataService,
    private roleService: RoleAuthorizationService
  ) {}
  getDealerMainFormFields() {
    let formFields: FormField<string>[] = [
      new DropdownField({
        key: 'dealerGroupStatus',
        label: 'Status',
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Terminated', value: 'Terminated' }
        ],
        isSingleselect: true,
        order: 1
      }),
      new TextboxField({
        key: 'dealerGroupNumber',
        label: 'Number',
        order: 2,
        required: true,
        isChangeEvent: true,
        errorMessages: [
          { key: 'duplicateDealerNumber', value: 'This number is already present in the system' }
        ],
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'Dealer Number',
          category: 'Seller'
        }
      }),
      new TextboxSearchField({
        key: 'fedTaxId',
        label: 'Fed Tax ID',
        order: 3,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'READ_ONLY',
          permission: 'Federal Tax Id',
          category: 'Seller'
        }
      }),
      new TextboxField({
        key: 'dealerGroupName',
        label: 'Name',
        order: 4,
        required: true
      }),
      new TextboxField({
        key: 'accountingNumber',
        label: 'Account Number',
        order: 5
      }),
      new DropdownField({
        key: 'salesRepresentative',
        label: 'Sales Representative',
        options: [],
        isSingleselect: true,
        order: 6
      }),
      new TextboxField({
        key: 'contractDate',
        label: 'Contract Date',
        type: 'Date',
        required: true,
        order: 7
      }),
      new TextboxField({
        key: 'contractExpireDate',
        label: 'Contract Exp Date',
        type: 'Date',
        order: 8
      }),
      new DropdownField({
        key: 'dealerDispatch',
        label: 'Dispatch',
        isSingleselect: true,
        options: [
          { key: 'Cust. Disptach Howar', value: 'Cust. Disptach Howar' },
          { key: 'Cust. Disp. EdgeStar', value: 'Cust. Disp. EdgeStar' },
          { key: 'Cust. Disp. Colder`s', value: 'Cust. Disp. Colder`s' },
          { key: 'Cust Disp. Depot/CI', value: 'Cust Disp. Depot/CI' },
          { key: 'Cust. Disp. Upsie', value: 'Cust. Disp. Upsie' },
          { key: 'CRP Standard', value: 'CRP Standard' },
          { key: 'Cust. Disp. Karl`s', value: 'Cust. Disp. Karl`s' },
          { key: 'Cust. Disp. Stand. A', value: 'Cust. Disp. Stand. A' },
          { key: 'Cust. Disp. 365 HW', value: 'Cust. Disp. 365 HW' },
          { key: 'Cust. Disp. Dannmar', value: 'Cust. Disp. Dannmar' },
          { key: 'Cust Disp. Ferguson', value: 'Cust Disp. Ferguson' },
          { key: 'Cust. Disp. Standard', value: 'Cust. Disp. Standard' },
          { key: 'Cust. Disp. ClearWarranty', value: 'Cust. Disp. ClearWarranty' },
          { key: 'Cust. Disp. Kostch', value: 'Cust. Disp. Kostch' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        order: 9
      }),
      new DropdownField({
        key: 'billingCycle',
        label: 'Billing Cycle',
        isSingleselect: true,
        options: [],
        order: 10
      }),
      new DropdownField({
        key: 'regionCode',
        label: 'Region Code',
        isSingleselect: true,
        options: [],
        order: 11
      }),
      new DropdownField({
        key: 'buyingGroup',
        label: 'Buying Group',
        options: [],
        isChangeEvent: true,
        isSingleselect: true,
        filter: true,
        order: 12
      }),
      new DropdownField({
        key: 'priceTable',
        label: 'Price Table',
        isSingleselect: true,
        options: [],
        order: 13
      }),
      new DropdownField({
        key: 'followUp',
        label: 'Follow Up',
        isSingleselect: true,
        options: [
          { key: 'Cust. Disptach Howar', value: 'Cust. Disptach Howar' },
          { key: 'Cust. Disp. EdgeStar', value: 'Cust. Disp. EdgeStar' },
          { key: 'Cust. Disp. Colder`s', value: 'Cust. Disp. Colder`s' },
          { key: 'Cust Disp. Depot/CI', value: 'Cust Disp. Depot/CI' },
          { key: 'Cust. Disp. Upsie', value: 'Cust. Disp. Upsie' },
          { key: 'CRP Standard', value: 'CRP Standard' },
          { key: 'Cust. Disp. Karl`s', value: 'Cust. Disp. Karl`s' },
          { key: 'Cust. Disp. Stand. A', value: 'Cust. Disp. Stand. A' },
          { key: 'Cust. Disp. 365 HW', value: 'Cust. Disp. 365 HW' },
          { key: 'Cust. Disp. Dannmar', value: 'Cust. Disp. Dannmar' },
          { key: 'Cust Disp. Ferguson', value: 'Cust Disp. Ferguson' },
          { key: 'Cust. Disp. Standard', value: 'Cust. Disp. Standard' },
          { key: 'Cust. F/U. ClearWarranty', value: 'Cust. F/U. ClearWarranty' },
          { key: 'Cust. F/U. Kostch', value: 'Cust. F/U. Kostch' }
        ],
        order: 14
      }),
      new DropdownField({
        key: 'insuranceOverride',
        label: 'Insurance Override',
        isSingleselect: true,
        options: [],
        order: 15
      }),
      new DropdownField({
        key: 'salesChannel',
        label: 'Sales Channel',
        isSingleselect: true,
        options: [],
        order: 16
      }),
      new TextboxField({
        key: 'dealerCatalogNumber',
        label: 'Catalog Number',
        order: 17
      }),
      new TextboxField({
        key: 'csmCode',
        label: 'CSM',
        order: 18
      }),
      new TextboxField({
        key: 'insuranceCarrierCode',
        label: 'Insurance Carrier Code',
        order: 19
      }),
      new TextboxField({
        key: 'insuranceCarrierName',
        label: 'Insurance Carrier Name',
        order: 20
      }),
      new DropdownField({
        key: 'freeLookUpPeriod',
        label: 'Free Lookup Period',
        isSingleselect: true,
        options: [
          { key: 30, value: 30 },
          { key: 60, value: 60 },
          { key: 90, value: 90 },
          { key: 120, value: 120 },
          { key: 150, value: 150 },
          { key: 180, value: 180 }
        ],
        order: 21
      }),
      new CheckBoxField({
        key: 'isExcludedForCancellation',
        label: '',
        type: 'checkbox',
        options: [{ key: 'isExcludedForCancellation', value: 'Excluded For Cancellation' }],
        order: 22
      }),
      new CheckBoxField({
        key: 'buckets',
        label: '',
        type: 'checkbox',
        options: [{ key: 'jsyCheckBox', value: 'JSY' }],
        order: 23
      }),
      new CheckBoxField({
        key: 'selfServicing',
        label: '',
        type: 'checkbox',
        options: [{ key: 'tSelfServicing', value: 'Self Servicing' }],
        order: 24
      })
    ];
    // let dealerDropdown = formFields.find(x => x.key === 'dealerGroupName');
    // const optionsDealerGroup = this.dropdownDataService.dealerGroupData ? this.dropdownDataService.dealerGroupData.map(x =>
    //   ({key: x.dealerGroupName, value: x.dealerGroupsIdOriginal})) : [];
    // dealerDropdown = {...dealerDropdown, options: [...optionsDealerGroup]};
    // formFields = [...formFields.filter((x => x.key !== 'dealerGroupName')), new DropdownField(dealerDropdown)];
    let buyingGroupDropdown = formFields.find(x => x.key === 'buyingGroup');
    const optionsBuyingGroup = this.dropdownDataService.buyingGroupData
      ? this.dropdownDataService.buyingGroupData.map(x => ({
          key: x.name,
          value: x.agentIdOriginal
        }))
      : [];
    buyingGroupDropdown = { ...buyingGroupDropdown, options: [...optionsBuyingGroup] };
    formFields = [
      ...formFields.filter(x => x.key !== 'buyingGroup'),
      new DropdownField(buyingGroupDropdown)
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getContactInformationFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'firstName',
        label: 'Primary Contact First Name',
        required: true,
        order: 1
      }),
      new TextboxField({
        key: 'lastName',
        label: 'Primary Contact Last Name',
        order: 2
      }),
      new TextboxField({
        key: 'businessPhone',
        label: 'Business Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 3
      }),
      new DropdownField({
        key: 'businessPhoneTypeCode',
        label: 'Business Phone Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        required: true,
        order: 4
      }),
      new TextboxField({
        key: 'fax',
        label: 'Fax',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid Fax number.',
        order: 5
      }),
      new TextboxField({
        key: 'email',
        label: 'Email Address',
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getGeneralInfoFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'general1',
        label: 'Do Not Call Customers',
        order: 1
      }),
      new TextboxField({
        key: 'general2',
        label: 'General2',
        order: 2
      }),
      new TextboxField({
        key: 'general3',
        label: 'General3',
        order: 3
      }),
      new TextboxField({
        key: 'general4',
        label: 'General4',
        order: 4
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getDealerLocationMainFormFields() {
    let formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'A' },
          { key: 'Pending', value: 'P' },
          { key: 'Terminated', value: 'T' },
          { key: 'Cancelled', value: 'C' }
        ],
        isSingleselect: true,
        order: 1
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        isChangeEvent: true,
        required: true,
        errorMessages: [
          {
            key: 'duplicateDealerLocationNumber',
            value: 'This number is already present in the system'
          }
        ],
        order: 2
      }),
      new TextboxSearchField({
        key: 'fedTaxId',
        label: 'Fed Tax ID',
        order: 3
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 4
      }),
      new DropdownField({
        key: 'refundPayMethod',
        label: 'Refund Pay Method',
        options: [
          { key: 'ACH', value: 'ACH' },
          { key: 'Check', value: 'Check' },
          { key: 'Credit Card', value: 'Credit Card' },
          { key: 'Dealer Statement Credit', value: 'Dealer Statement Credit' },
          { key: 'New Leaf Gift Card', value: 'New Leaf Gift Card' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        isSingleselect: true,
        order: 5
      }),
      new DropdownField({
        key: 'dealerGroupNumber',
        label: 'Dealer',
        options: [],
        isSingleselect: true,
        filter: true,
        order: 6
      }),
      new DropdownField({
        key: 'claimPayMethod',
        label: 'Claim Pay Method',
        options: [
          { key: 'ACH', value: 'ACH' },
          { key: 'Check', value: 'Check' },
          { key: 'Credit Card', value: 'Credit Card' },
          { key: 'Dealer Statement Credit', value: 'Dealer Statement Credit' },
          { key: 'New Leaf Gift Card', value: 'New Leaf Gift Card' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        isSingleselect: true,
        order: 7
      }),
      new DropdownField({
        key: 'storeType',
        label: 'Store Type',
        options: [],
        isSingleselect: true,
        order: 8
      }),
      new DropdownField({
        key: 'insuranceOverride',
        label: 'Insurance Override',
        options: [],
        isSingleselect: true,
        order: 9
      }),
      new DropdownField({
        key: 'obligor',
        label: 'Obligor',
        options: [],
        isSingleselect: true,
        order: 10
      })
    ];
    let dealerDropdown = formFields.find(x => x.key === 'dealerGroupNumber');
    const options =
      this.dropdownDataService.dealerGroupData &&
      this.dropdownDataService.dealerGroupData.length > 0
        ? this.dropdownDataService.dealerGroupData.map(x => ({
            key: x.dealerGroupName,
            value: x.dealerGroupsIdOriginal
          }))
        : [];
    dealerDropdown = { ...dealerDropdown, options: [...options] };
    formFields = [
      ...formFields.filter(x => x.key !== 'dealerGroupNumber'),
      new DropdownField(dealerDropdown)
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }
  getPayeeMainFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        options: [
          { key: 'Active', value: 'Active' },
          { key: 'Pending', value: 'Pending' },
          { key: 'Terminated', value: 'Terminated' }
        ].sort((a, b) => a.key.localeCompare(b.key)),
        isSingleselect: true,
        order: 1
      }),
      new TextboxField({
        key: 'number',
        label: 'Number',
        order: 2
      }),
      new TextboxSearchField({
        key: 'fedTaxId',
        label: 'Fed Tax ID',
        order: 3
      }),
      new TextboxField({
        key: 'name',
        label: 'Name',
        order: 4
      }),
      new DropdownField({
        key: 'refundPayMethod',
        label: 'Refund Pay Method',
        options: [
          { key: 'ACH', value: 'ACH' },
          { key: 'Check', value: 'Check' },
          { key: 'Credit Card', value: 'Credit Card' },
          { key: 'Dealer Statement Credit', value: 'Dealer Statement Credit' },
          { key: 'New Leaf Gift Card', value: 'New Leaf Gift Card' }
        ],
        isSingleselect: true,
        order: 5
      }),
      new CheckBoxField({
        key: 'buckets',
        label: 'Applicable Buckets',
        type: 'checkbox',
        options: [
          { key: 'adminBucket', value: 'Admin' },
          { key: 'reserveAndFees', value: 'Reserves Fees' },
          { key: 'commission', value: 'Commission' }
        ],
        order: 6
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServicerDealerFields() {
    const optionsServicerList =
      this.dropdownDataService && this.dropdownDataService.servicerListLinkData
        ? this.dropdownDataService.servicerListLinkData.map(x => ({
            key: x.key + ' | ' + x.value + ' ',
            value: x.value
          }))
        : [];

    const formFields: FormField<string>[] = [
      new CheckBoxField({
        key: 'selfServicing',
        label: '',
        type: 'checkbox',
        options: [{ key: 'tSelfServicing', value: 'Self Servicing' }],
        order: 1
      }),
      new DropdownField({
        disabled: false,
        isChangeEvent: true,
        isSingleselect: true,
        key: 'servicer',
        label: 'Servicer',
        options: optionsServicerList,
        filter: true,
        order: 2
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
