<div class="crm-container">
  <app-section-header [title]="'Coverage'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <app-tab-spinner *ngIf="isLoading" />
  <div class="sub-section">
    <app-section-header [title]="'Coverage Rates'" [optionHeader]="opHeaderCoverage" />

    <p-table styleClass="p-datatable-gridlines" [columns]="coveredRatesCols" [value]="coveredRatesList" dataKey="id"
      editMode="row"><ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing"
        let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor><ng-template *ngIf="col.type === 'text'"
                pTemplate="output">{{rowData[col.field]}}</ng-template></p-cellEditor>
          </td>
        </tr>
      </ng-template></p-table>
  </div>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="coverageEllipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeaderCoverage>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<rating-modal [(displayDialog)]="displayRatingModal" (formSubmitEvent)="onRatingUpdate($event)"
  [formData]="coverageRatings" *ngIf="displayRatingModal">
</rating-modal>