<p-toast></p-toast>
<div class="transaction-management">
  <app-section-header [title]="'Transaction Management Results'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" />
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" [hidden]="isLoading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{col.header}}<p-sortIcon
              [field]="col.field"></p-sortIcon></th>
          <th class="last-cell"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
        <tr>
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text"
                  [(ngModel)]="rowData[col.field]" /><input *ngIf="col.type === 'checkbox'" type="checkbox"
                  [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output">{{rowData[col.field]}}</ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <p-checkbox [(ngModel)]="rowData[col.field]" [binary]="true" (onChange)="onChecked(rowData)"
                  [disabled]="transactionEdit">
                </p-checkbox>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" (click)="ellipsisClick($event,'item',opRow,ri)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<history-item-modal modalTitle="Transaction History" [(displayDialog)]="displayHistory" [formData]="historyData"
  *ngIf="displayHistory">
</history-item-modal>
<transaction-management-modal modalTitle="Add New Transaction Year" [(displayDialog)]="displayServiceInfoModal"
  (formSubmitEvent)="onCreateUpdate($event)" [formData]="selectedRow" *ngIf="displayServiceInfoModal">
</transaction-management-modal>