import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const manufacturerSearchQuery = gql`
  query($manufacturersName: String!, $limit: Int, $offset: Int) {
    getManufacturerByName(name: $manufacturersName, limit: $limit, offset: $offset) {
      manufacturersName
      ManufacturesIDOriginal
    }
  }
`;

const createUpdateNewManufacturer = gql`
  mutation(
    $manufacturersName: String!
    $ManufacturesIDOriginal: Int
    $IsDeleted: Boolean
    $InsertUserName: String
  ) {
    createUpdateNewManufacturer(
      name: $manufacturersName
      ManufacturesIDOriginal: $ManufacturesIDOriginal
      IsDeleted: $IsDeleted
      InsertUserName: $InsertUserName
    ) {
      manufacturersName
      ManufacturesIDOriginal
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {
  constructor(private apollo: Apollo) {}

  getManufacturer = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: manufacturerSearchQuery,
      variables: {
        ...searchParams
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateNewManufacturer = params =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateNewManufacturer,
      variables: {
        ...params
      }
      // tslint:disable-next-line: semicolon
    });
}
