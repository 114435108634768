import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';
import {
  getServiceOrderSearchQuery,
  getDashboardRecordDetailsQuery
} from '../so-data-queries.constant';

@Injectable()
export class SearchServiceOrderService {
  constructor(private apollo: Apollo) {}

  getServiceOrderSearchResult = (searchParams, offset, limit) => {
    const searchQuery = searchParams.fromDashboard
      ? getDashboardRecordDetailsQuery
      : getServiceOrderSearchQuery;
    const variable = searchParams.fromDashboard
      ? {
          ...searchParams,
          offset,
          limit
        }
      : {
          ...searchParams,
          status: searchParams.status && searchParams.status.value,
          offset,
          limit,
          contractsId: parseInt(searchParams.contractsId, 10),
          claimId: parseInt(searchParams.claimId, 10),
          claimPriorityCode: searchParams.claimPriorityCode && searchParams.claimPriorityCode.value,
          customerFeedback: searchParams.customerFeedback && searchParams.customerFeedback.value,
          assignedTo: searchParams.assignedTo && searchParams.assignedTo.value,
          claimStartDate: searchParams.claimDate && searchParams.claimDate[0],
          claimEndDate: searchParams.claimDate && searchParams.claimDate[1],
          dateOfRepairStartDate: searchParams.dateOfRepair && searchParams.dateOfRepair[0],
          dateOfRepairEndDate: searchParams.dateOfRepair && searchParams.dateOfRepair[1],
          dateSubmitForPaymentStartDate:
            searchParams.submitPaymentDate && searchParams.submitPaymentDate[0],
          dateSubmitForPaymentEndDate:
            searchParams.submitPaymentDate && searchParams.submitPaymentDate[1],
          approvedStartDate: searchParams.approvedDate && searchParams.approvedDate[0],
          approvedEndDate: searchParams.approvedDate && searchParams.approvedDate[1],
          preApprovedStartDate: searchParams.preApprovedDate && searchParams.preApprovedDate[0],
          preApprovedEndDate: searchParams.preApprovedDate && searchParams.preApprovedDate[1],
          claimPaymentStartDate: searchParams.claimPaymentDate && searchParams.claimPaymentDate[0],
          claimPaymentEndDate: searchParams.claimPaymentDate && searchParams.claimPaymentDate[1]
        };

    return this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: searchQuery,
      variables: variable,
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });
    // tslint:disable-next-line: semicolon
  };
}
