import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { InquiryTabService } from '../inquiry-tab.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ContractService } from '../../contract.service';
import { EmailService } from '../../../services/email.service';
import { dateTimeFormatterCST } from '../../../shared/utilities/date-utilities';

@Component({
  selector: 'inquiry-tab-form-modal',
  templateUrl: 'inquiry-tab-modal.template.html',
  styleUrls: ['./inquiry-tab-modal.scss'],
  providers: [FormFieldControlService, MessageService, ConfirmationService],
})
export class InquiryFormModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formData: any;
  @Output() formSubmitEvent = new EventEmitter();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  displayEmailModal: true;
  isLoading: boolean;
  querySubscription: Subscription;
  selectedRowIndex: number;
  dataList: any[] = [];
  querySubscriptions: Subscription[] = [];
  constructor(
    private qcs: FormFieldControlService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private inquiryTabService: InquiryTabService,
    private contractService: ContractService,
    private emailService: EmailService,
  ) {
    this.inquiryTabService.getInquiryFormModalFields().subscribe((data) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    if (this.formData && this.formData.status) {
      this.form.patchValue({
        ...this.formData,
        status: {
          key: this.formData.status,
          value: this.formData.status,
        },
        assignedTo: {
          key: this.formData.userName,
          value: this.formData.assignedTo,
        },
        content: this.formData.content,
      });
    }
  }

  onSubmit() {
    const payload = this.form.getRawValue();
    const azureUserId = payload.assignedTo.value;
    const assignedToUser = payload.assignedTo.key;
    if (this.form.valid) {
      payload.contractInquiryEventsIdOriginal =
        this.formData && this.formData.contractInquiryEventsIdOriginal
          ? this.formData.contractInquiryEventsIdOriginal
          : null;
      payload.contractsId =
        this.contractService.contractDataModel.leftBaseSection.contractsIdOriginal;
      payload.contractNumber =
        this.contractService.contractDataModel.leftBaseSection.contractNumber;
      payload.status = payload.status.value;
      payload.assignedTo = payload.assignedTo.value;
      payload.enteredBy = this.azureService.displayName;
      payload.insertUserName = this.azureService.accountId;
      this.isLoading = true;
      this.querySubscriptions.push(
        this.inquiryTabService
          .createUpdateContractInquiry({ ...payload })
          .subscribe((data: any) => {
            this.isLoading = false;
            const response = data.data.createUpdateContractInquiry;
            if (response) {
              const item = this.formatData(response);
              item[`isNew`] = false;
              this.dataList[this.selectedRowIndex] = item;
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'successMessage',
              });
              const typeList = [{ label: 'Inquiry', value: 133 }];
              const note = {
                text: `Inquiry ${payload.contractInquiryEventsIdOriginal ? 'Updated' : 'Created'}, Assigned To: ${assignedToUser}, Content: ${payload.content}`,
                type: typeList[0].value,
                enteredBy: payload.enteredBy
              }
              console.log(note);
              this.contractService.isEnquiryNotesAdded.next(note);
            }
            this.isLoading = true;
            this.getInquiryEmail(azureUserId);
            this.formSubmitEvent.emit(true);
            this.displayDialog = false;
            this.displayDialogChange.emit(this.displayDialog);
          }),
      );
    }
  }

  getInquiryEmail(azureUserId) {
    this.inquiryTabService.getInquiryQueueEmail({ azureUserId }).subscribe(({ data }) => {
      const inquiryResponse = data.getUserDetails;
      const emailAddress = inquiryResponse[0].value;
      const request = this.inquiryTabService.getEmailRequest(
        emailAddress,
        this.contractService.contractDataModel.leftBaseSection.contractNumber,
        this.contractService.contractDataModel.mainTab.additionalInformation.dealerGroupName,
      );
      this.emailService.sendEmail(request).subscribe(
        (userData) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Email sent successfully to ${emailAddress}.`,
          });
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Email send Failed to ${emailAddress}.`,
          });
        },
      );
    });
  }

  formatData(item) {
    return [
      {
        status: item.status,
        assignedTo: item.assignedTo,
        content: item.content,
        enteredBy: dateTimeFormatterCST(item.enteredBy),
        enteredTime: item.enteredTime,
      },
    ];
  }

  onCancel() {
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.formSubmitEvent.emit(false);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
