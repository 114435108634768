import { Component, Input, OnInit } from '@angular/core';
import { PricePaidService } from './price-paid.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { copy } from '../../shared/utilities/common-utilities';
@Component({
  selector: 'price-paid',
  templateUrl: 'price-paid.template.html',
  providers: [PricePaidService],
})
export class PricePaidComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  constructor(private pricePaidService: PricePaidService) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.pricePaidService.getPricePaid().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'price_from', header: 'PRICE FROM', type: 'text' },
      { field: 'price_to', header: 'PRICE TO', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
}
