<div class="crm-container">
  <p-toast></p-toast>
  <div class="sub-sec">
    <div class="sub-sec-header">
      <div class="title">
        <div>Associated Contracts</div>
      </div>
      <div *ngIf="displayOptions">
        <div class="three-dots" (click)="ellipsisClick($event,'item',opHeader,null)">
          <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
        </div>
      </div>
    </div>
  </div>
  <p-table [columns]="cols" [value]="associatedContracts" styleClass="p-datatable-gridlines"><ng-template
      pTemplate="header" let-columns="">
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
      </tr>
    </ng-template><ng-template pTemplate="body" let-rowData="" let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div class="crm-link" *ngIf="col.type === 'link'" (click)="openTab(rowData)">
            {{rowData[col.field]}}
          </div>
          <div *ngIf="col.type === 'text'">{{rowData[col.field]}}</div>
        </td>
      </tr>
    </ng-template></p-table>
</div>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="'Un-Associate Contracts'" [(visible)]="displayUnassociate" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true">
  <div class="spinner" *ngIf="isLoading"><p-progressSpinner></p-progressSpinner></div>
  <div class="modal-content" *ngIf="!isLoading">
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-8" *ngFor="let formField of formFields">
          <form-field [formField]="formField" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Cancel" [disabled]="disableSave">
        Save</button><button class="crm-button" type="button" (click)="onCancel()" label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>