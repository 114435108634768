import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor() {}
  getForm(): Observable<any[]> {
    return of([
      {
        active: 'ACTIVE',
        type: 'Customer Dispatch',
        message_code: 'CRP Food loss',
        description: 'Cusotmer Reimbursement - Food loss'
      },
      {
        active: 'INACTIVE',
        type: 'Dispatch',
        message_code: 'Parts OEM coverage',
        description: 'Parts OEM coverage'
      },
      {
        active: 'ACTIVE',
        type: 'Customer Dispatch',
        message_code: 'Cust. Disp. Standard',
        description: 'Cusotmer dispatch'
      }
    ]);
  }
}
