import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisCrudOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { coverageTabEllipsisHeaderOptions } from '../../../coverage.constant';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { FormField } from 'src/app/modules/crm/shared/form-field/form-field';
import { FormGroup } from '@angular/forms';
import { CoverageFormFieldService } from '../../../coverage-form-field.service';
import { FormFieldControlService } from 'src/app/modules/crm/services/form-field-control.service';
import { CoverageService } from '../../coverage.service';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'cv-coverage-tab',
  templateUrl: 'coverage-tab.template.html',
  providers: [CoverageFormFieldService, FormFieldControlService],
})
export class CoverageTabComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = coverageTabEllipsisHeaderOptions;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  displayDialog: boolean;
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  queryGetSubscription: any;
  queryPostSubscription: any;
  isLoading: boolean;
  formData = null;
  constructor(
    private confirmationService: ConfirmationService,
    private service: CoverageFormFieldService,
    private qcs: FormFieldControlService,
    private coverageService: CoverageService,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.service.getAddCoveredItemFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
    this.cols = [
      { field: 'lossCode', header: 'LOSS CODE', type: 'text' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'termMonth', header: 'TERM MONTH', type: 'text' },
      { field: 'type', header: 'LOSS CODE TYPE', type: 'text' },
      { field: 'cost', header: 'COST', type: 'text' },
    ];

    if (this.coverageService.coverageConfig && this.coverageService.coverageConfig.searchParams) {
      this.getCoverageMainTab();
    }
  }

  getCoverageMainTab() {
    this.isLoading = true;
    this.queryGetSubscription = this.coverageService
      .getCoverageMainTab(this.coverageService.coverageConfig.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifiedData = copy(data.getCoverageProfileById);
          if (modifiedData) {
            const coverageLossCodes = modifiedData.coverageLossCodes || [];
            coverageLossCodes.sort(
              (el1, el2) => el1.coverageLossCodeIdOriginal - el2.coverageLossCodeIdOriginal,
            );
            this.dataList = this.coverageService.coverageDataModel.mainTab = coverageLossCodes;
            this.coverageService.coverageDataModel.coverageCode = modifiedData.coverageCode;
            this.coverageService.isMainTabDataAvailable.next(true);
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.formData = {
      description: item.description,
      lossCode: item.lossCode,
    };
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.displayDialog = true;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  onRowEditSave(rowData: any) {}
  onRowEditInit(rowData: any) {}
  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.selectedItem = { ...this.selectedItem, isDeleted: true };
        this.createUpdateCoverageLossCode();
      },
    });
  }

  onClickAdd(event) {
    this.displayDialog = true;
  }

  editRowItem(formData: FormGroup) {
    const rowItem = formData.getRawValue();
    this.selectedItem = { ...this.selectedItem, ...rowItem };
    this.createUpdateCoverageLossCode();
    this.displayDialog = false;
    this.formValueChanged.emit(formData.dirty);
  }

  createUpdateCoverageLossCode() {
    this.isLoading = true;
    delete this.selectedItem.__typename;
    this.queryGetSubscription = this.coverageService
      .createUpdateCoverageLossCode(this.selectedItem)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          this.getCoverageMainTab();
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ngOnDestroy() {
    if (this.queryGetSubscription) {
      this.queryGetSubscription.unsubscribe();
    }
    if (this.queryPostSubscription) {
      this.queryPostSubscription.unsubscribe();
    }
  }
}
