export function getMarkup(price, percentage) {
  if (price && percentage) {
    return (price * percentage) / 100;
  }
  return 0.0;
}
export function getTotalAmount(price, markup, quantity) {
  if (price) {
    markup = markup || 0;
    return (price + markup) * quantity;
  }
  return null;
}

export function getApprovedAmount(price, markup, discount, quantity) {
  if (price) {
    markup = markup || 0;
    discount = discount || 0;
    const adjustedAmount = (price + markup) * quantity;
    return (adjustedAmount - (adjustedAmount * discount) / 100).toFixed(2);
  }
  return null;
}

export function getAmount(price, markup, quantity) {
  if (price) {
    const amount = price || 0;
    return ((amount + markup) * quantity).toFixed(2);
  }
  return null;
}
