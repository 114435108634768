import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { DealerFormFieldService } from '../../../services/dealer-form-field.service';
import { FormField } from '../../../shared/form-field/form-field';
import { RoleAuthorizationService } from '../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../interfaces/role-authorization.interface';
import { DealerLocationService } from '../dealer-location.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';
import { dealerLocationFormHeaderOptions } from './dealer-location-form.ellipsis';
import { getMenuTypeKey } from '../../../shared/utilities/common-utilities';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DealerLocationDisplayStatus } from '../../dealer.constants';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { DropdownField } from '../../../shared/form-field/dropdown-field';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfigurationTypeService } from '../../../administration/configuration/configuration.service';

@Component({
  selector: 'dealer-location-form',
  templateUrl: 'dealer-location-form.template.html',
  styleUrls: ['../../../shared/styles/left-details-form.scss'],
  providers: [FormFieldControlService, MessageService],
})
export class DealerLocationFormComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  payLoad = '';
  globalOptions: any[];
  formData: any;
  authDetails: IRoleAuthorizationService;
  button: Array<any>;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = dealerLocationFormHeaderOptions;
  isLoading = false;

  sellerIdOriginal = null;
  querySubscription: any;
  isSuccess = false;
  displayHistory = false;
  historyData: any;
  constructor(
    private qcs: FormFieldControlService,
    private roleService: RoleAuthorizationService,
    private service: DealerFormFieldService,
    private dealerLocationService: DealerLocationService,
    private dropdownDataService: DropdownDataService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
  ) {
    super();
    this.service.getDealerLocationMainFormFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
    });
  }

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.FormField,
      component: DealerLocationFormComponent,
      generalArray: this.formFields,
    };

    this.formFields = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.form = this.qcs.toFormGroup(this.formFields);
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'obligor',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.globalOptions = configList;
        const options = configList
          .filter((el) => el.menuType === 'obligor')
          .map((e) => {
            return {
              key: e.key,
              value: e.value,
            };
          });
        this.formFields.find((el) => el.key === 'obligor').options = options;
        this.formValueChanged.emit(true);
        if (this.dealerLocationService.dealerLocationData) {
          this.setFormValues();
        }
      });
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });

    this.button = [
      { name: 'Cancel', hidden: false, disabled: false, type: 'button' },
      { name: 'Submit', hidden: false, disabled: false, type: 'submit' },
    ];

    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: DealerLocationFormComponent,
      generalArray: this.button,
    };

    this.button = Object.assign(this.roleService.applyAuthorization(this.authDetails));
  }

  setFormValues() {
    this.sellerIdOriginal = this.dealerLocationService.dealerLocationData.sellerIdOriginal;
    const modifiedData = this.dealerLocationService.dealerLocationData;
    // Getting dealerGroup data from dropdownDataService
    const dealerGroup = this.dropdownDataService.dealerGroupData.find(
      (x) => x.dealerGroupsIdOriginal === parseInt(modifiedData.dealerGroupNumber, 10),
    );
    this.formFields = this.formFields.map((formField) => {
      if (formField.key === 'number') {
        formField = { ...formField, disabled: true };
      }
      return formField;
    });
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.patchValue({
      ...modifiedData,
      status: {
        key: DealerLocationDisplayStatus[modifiedData.status],
        value: modifiedData.status,
      },
      dealerGroupNumber: {
        key: dealerGroup ? dealerGroup.dealerGroupName : '',
        value: dealerGroup ? dealerGroup.dealerGroupsIdOriginal : '',
      },
      refundPayMethod: { key: modifiedData.refundPayMethod, value: modifiedData.refundPayMethod },
      storeType: { key: modifiedData.storeType, value: modifiedData.storeType },
      insuranceOverride: {
        key: modifiedData.insuranceOverride,
        value: modifiedData.insuranceOverride,
      },
      claimPayMethod: { key: modifiedData.claimPayMethod, value: modifiedData.claimPayMethod },
      obligor: {
        key: getMenuTypeKey(this.globalOptions, 'obligor', modifiedData.obligor),
        value: modifiedData.obligor,
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onChangeEvent(event, key) {
    if (key === 'number' && event.target.value && event.target.value.length > 0) {
      this.isLoading = true;
      this.dealerLocationService.getDealerLocationDetails(null, event.target.value).subscribe(
        ({ data }) => {
          if (data.getDealerLocationProfileById) {
            this.form.controls.number.setErrors({ key: 'duplicateDealerLocationNumber' });
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }

  private disableSubmit(param: boolean) {
    this.button = this.button.map(row => {
      if (row.name === 'Submit') {
        row.disabled = param;
      }
      return row;
    });
  }

  onSubmit() {
    window.scroll(0, 0);
    if (this.form.valid) {
      this.payLoad = {
        ...this.form.getRawValue(),
        sellerIdOriginal: this.sellerIdOriginal,
        insertUserName: this.azureService.accountId || null,
      };
      this.formValueChanged.emit(this.form.dirty);
      this.isLoading = true;
      this.disableSubmit(true);
      this.querySubscription = this.dealerLocationService
        .createDealerLocation(this.payLoad)
        .subscribe(
          ({ data, isLoading }: any) => {
            this.isSuccess = true;
            if (data.createUpdateDealerLocation) {
              this.refreshDealerLocation(data.createUpdateDealerLocation.sellerIdOriginal);
            }
            this.disableSubmit(false);
            this.isLoading = isLoading;
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Record Saved Successfully.`,
            });
            setTimeout(() => {
              this.isSuccess = false;
            }, 3000);
          },
          (err) => {
            this.isLoading = false;
            this.disableSubmit(false);
            throw err;
          },
        );
    }
  }

  refreshDealerLocation(id) {
    if (id) {
      this.isLoading = true;
      this.querySubscription = this.dealerLocationService.getDealerLocationDetails(id).subscribe(
        ({ data, loading }: any) => {
          const { addresses, contactInfo, ...dealerLocationData } =
            data.getDealerLocationProfileById;
          this.dealerLocationService.dealerLocationData = dealerLocationData;
          this.dealerLocationService.billingAddress = addresses[0];
          this.dealerLocationService.mailingAddress = addresses[1];
          this.dealerLocationService.contactFormData = contactInfo;
          if (this.dealerLocationService.dealerLocationData) {
            this.setFormValues();
          }
          this.isLoading = loading;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
    }
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  onViewHistory() {
    const id = this.sellerIdOriginal;
    if (id) {
      this.historyData = {
        type: 'dealer_location',
        id,
      };
      this.displayHistory = true;
    }
  }
}
