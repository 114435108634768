import { Component, OnInit } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { InsuranceCompanyService } from '../../insurance-company.service';

@Component({
  selector: 'cv-insurance-company-main',
  templateUrl: 'insurance-company-main.template.html',
  styleUrls: ['insurance-company-main.scss'],
})
export class InsuranceCompanyMainComponent implements OnInit {
  contactInfo: any;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  isDataAvailable: boolean;
  maillingAddress: any;
  billingAddress: any;
  searchParams: any;
  constructor(public insuranceCompanyService: InsuranceCompanyService) {}

  ngOnInit() {
    this.searchParams = this.insuranceCompanyService.insuranceCompanyConfig.searchParams;
    this.insuranceCompanyService.isDataAvailable.subscribe({
      next: (isDataAvailable) => {
        if (isDataAvailable) {
          this.maillingAddress =
            this.insuranceCompanyService.insuranceCompanyDataModel.mainTab.mailingAddress;
          this.billingAddress =
            this.insuranceCompanyService.insuranceCompanyDataModel.mainTab.billingAddress;
          this.contactInfo =
            this.insuranceCompanyService.insuranceCompanyDataModel.mainTab.contactInformation;
          this.isDataAvailable = isDataAvailable;
        }
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
}
