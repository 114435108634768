import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisCrudOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { buttonStatus } from 'src/app/modules/crm/shared/constants/button-class';
import { KeyMap } from 'src/app/modules/crm/shared/interface/key-map.interface';
import { Table } from 'primeng/table';
import { ServiceOrderService } from '../../service-order.service';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { v4 as uuidv4 } from 'uuid';
import { TechnicalInformationService } from './technical-information.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { DatePipe } from '@angular/common';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import {
  generalDateFormatter,
  getDateOnly,
  getMMDDYYYFormat,
} from 'src/app/modules/crm/shared/utilities/date-utilities';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { ConfigurationTypeService } from 'src/app/modules/crm/administration/configuration/configuration.service';
import { REDEMPTION_DATE_OVERRIDE_ROLES } from 'src/app/modules/crm/shared/constants/service-order-constants';

@Component({
  selector: 'technical-information',
  templateUrl: 'technical-information.template.html',
  styleUrls: ['technical-information.scss'],
  providers: [FormFieldControlService, MessageService],
})
export class TechnicalInformationComponent implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Input() formFields: FormField<any>[] = [];
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  // Need a copy of the objects within the array so we do not update those by reference for other uses.
  ellipsisOptions: any[] = ellipsisCrudOptions.map((object) => ({ ...object }));
  ellipsisHeaderOptions: any[] = ellipsisHeaderOptions.map((object) => ({ ...object }));
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  dropdown: any;
  totalRequestedAmount: any;
  totalAdjustedAmount: any;
  totalDiscount: any;
  totalApprovedAmount: any;
  claimTechInfoIdOriginal: any;
  @ViewChild(Table) table: Table;
  authDetails: IRoleAuthorizationService;
  permissionsColsRows = [];
  permissionsCols = {};
  newRowFlag = true;
  querySubscriptions: Subscription[] = [];
  dateOfRepairFlag = true;
  dateOfInvoiceFlag = true;
  servicerEditFlag = true;
  statusAvailable = false;
  isExpiredContract = false;
  isEditEnable = true;
  globalOptions: any[];
  constructor(
    private serviceOrderService: ServiceOrderService,
    private cdr: ChangeDetectorRef,
    private serviceOrderDataService: ServiceOrderDataService,
    private technicalInformationService: TechnicalInformationService,
    private roleService: RoleAuthorizationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService,
    private configurationService: ConfigurationTypeService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.dropdown = {
      insuranceCarrier: [],
      serviceStatus: [],
      numberOfVisits: [],
    };
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'service_status,number_of_visits',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        this.globalOptions = configList;
        const serviceStatusOptions = configList
          .filter((el) => el.menuType === 'service_status')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        const numberOfVisitsOptions = configList
          .filter((el) => el.menuType === 'number_of_visits')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        this.dropdown.serviceStatus = serviceStatusOptions;
        this.dropdown.numberOfVisits = numberOfVisitsOptions;
        this.formValueChanged.emit(true);
      });
    // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
    // No user can edit the details anymore(PD-907057)
    this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
      this.isEditEnable = isEnable;
      this.ellipsisOptions
        .filter((c) => c.label === 'Update' || c.label === 'Delete')
        .forEach((x) => (x.disabled = !this.isEditEnable));
    });
    this.cols = [
      { field: 'technicianName', header: 'Technician Name', type: 'text', label: 'technicianName' },
      {
        field: 'fPBRedemptionDate',
        header: '50% Back Redemption Date',
        type: 'Date',
        label: 'fPBRedemptionDate',
      },
      { field: 'invoiceNumber', header: 'Invoice Number', type: 'text', label: 'invoiceNumber' },
      {
        field: 'numberOfVisits',
        header: 'Number of Visits',
        type: 'select',
        label: 'numberOfVisits',
      },
      {
        field: 'insuranceCarrier',
        header: 'Insurance Carrier',
        type: 'select',
        label: 'insuranceCarrier',
      },
      { field: 'serviceStatus', header: 'Service Status', type: 'select', label: 'serviceStatus' },
      { field: 'dateOfRepair', header: 'Date of Repair', type: 'Date', label: 'dateOfRepair' },
      { field: 'dateOfInvoice', header: 'Date of Invoice', type: 'Date', label: 'dateOfInvoice' },
      {
        field: 'serviceScheduleDate',
        header: 'Service Schedule Date',
        type: 'Date',
        label: 'serviceScheduleDate',
      },
    ];
    this.dateOfInvoiceFlag = !this.serviceOrderService
      .getAdminsList()
      .includes(this.azureService.roleName);
    this.dateOfRepairFlag = !this.serviceOrderService
      .getAdminListExtended()
      .includes(this.azureService.roleName);

    this.querySubscriptions.push(
      this.technicalInformationService
        .getInsuranceCompaniesList()
        .subscribe(({ data, loading }: any) => {
          let dt = this.technicalInformationService.getLabelValueList(
            data.getInsuranceCompaniesList,
          );
          dt = [{ label: 'Default', value: '' }, ...dt];
          this.dropdown.insuranceCarrier = dt;
        }),
    );

    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: TechnicalInformationComponent,
      generalArray: this.cols,
    };
    this.permissionsColsRows = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    for (const singlePermission of this.permissionsColsRows) {
      this.permissionsCols[singlePermission.label] = true;
    }
    this.cols = this.cols.filter((el) => el.label !== 'fPBRedemptionDate');
    this.serviceOrderDataService.fPBFlag.subscribe((isExpired) => {
      this.isExpiredContract = isExpired;
      if (isExpired) {
        if (this.cols.filter((el) => el.label === 'fPBRedemptionDate').length === 0) {
          this.cols.splice(
            1,
            0,
            ...[
              {
                field: 'fPBRedemptionDate',
                header: '50% Back Redemption Date',
                type: 'Date',
                label: 'fPBRedemptionDate',
              },
            ],
          );
        }
      } else {
        this.cols = this.cols.filter((el) => el.label !== 'fPBRedemptionDate');
      }
    });
    this.dataList = [{}];
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          this.claimTechInfoIdOriginal = null;
          const claimNumber =
            this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber;
          const serviceOrderNumber =
            this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber;
          if (
            serviceOrderNumber &&
            !this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag
          ) {
            this.querySubscriptions.push(
              this.technicalInformationService
                .getClaimTechInfoByClaimQuery(serviceOrderNumber)
                .subscribe(({ data, loading }) => {
                  data = copy(data.getClaimTechInfoByClaim);
                  if (data && data.claimTechInfoIdOriginal) {
                    this.newRowFlag = false;
                    this.claimTechInfoIdOriginal = data.claimTechInfoIdOriginal;
                    this.dataList = [
                      {
                        technicianName: data.technicianName,
                        fPBRedemptionDate: getMMDDYYYFormat(data.fPBRedemptionDate),
                        invoiceNumber: data.invoiceNumber,
                        numberOfVisits: data.numberOfVisits,
                        insuranceCarrier: data.insuranceCarrier,
                        dateOfRepair: generalDateFormatter(data.dateOfRepair),
                        dateOfInvoice: generalDateFormatter(data.dateOfInvoice),
                        serviceScheduleDate: generalDateFormatter(data.serviceScheduleDate),
                        serviceStatus: this.getMenuTypeValue('service_status', data.serviceStatus),
                      },
                    ];
                    const techInfo = {
                      claimNumber:
                        this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber,
                      claimTechInfoIdOriginal: this.claimTechInfoIdOriginal
                        ? this.claimTechInfoIdOriginal
                        : null,
                      serviceOrderNumber:
                        this.serviceOrderDataService.serviceOrderDataModel.claimData
                          .serviceOrderNumber,
                      technicianName: data.technicianName,
                      fPBRedemptionDate: data.fPBRedemptionDate,
                      invoiceNumber: data.invoiceNumber,
                      numberOfVisits: data.numberOfVisits,
                      insuranceCarrier: data.insuranceCarrier,
                      dateOfRepair: data.dateOfRepair
                        ? new Date(data.dateOfRepair).toISOString().slice(0, 10)
                        : null,
                      dateOfInvoice: data.dateOfInvoice
                        ? new Date(data.dateOfInvoice).toISOString().slice(0, 10)
                        : null,
                      serviceScheduleDate: new Date(data.serviceScheduleDate)
                        .toISOString()
                        .slice(0, 10),
                      serviceStatus: data.serviceStatus,
                    };
                    this.serviceOrderDataService.serviceOrderDataModel.techInfo = techInfo;
                  } else {
                    // Clear out the fields if nothing was found (mainly for new sub claims)
                    this.dataList = [
                      {
                        technicianName: null,
                        fPBRedemptionDate: null,
                        invoiceNumber: null,
                        numberOfVisits: null,
                        insuranceCarrier: null,
                        dateOfRepair: null,
                        dateOfInvoice: null,
                        serviceScheduleDate: null,
                        serviceStatus: null,
                      },
                    ];
                    const techInfo = {};
                    this.serviceOrderDataService.serviceOrderDataModel.techInfo = techInfo;
                  }
                }),
            );
          }
        },
      }),
    );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    if (
      rowData &&
      !rowData.insuranceCarrier &&
      this.serviceOrderDataService.serviceOrderDataModel &&
      this.serviceOrderDataService.serviceOrderDataModel.contractInfo &&
      this.serviceOrderDataService.serviceOrderDataModel.contractInfo.insurerName
    ) {
      rowData.insuranceCarrier =
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.insurerName;
    }
    // Need to convert Int to String and String to Date for options to show during edit.
    if (rowData) {
      if (rowData.numberOfVisits) {
        rowData.numberOfVisits = rowData.numberOfVisits.toString();
      }
      if (rowData.dateOfRepair && rowData.dateOfRepair !== '') {
        rowData.dateOfRepair = getMMDDYYYFormat(rowData.dateOfRepair, 'YYYY-MM-DD');
      }
      if (rowData.dateOfInvoice && rowData.dateOfInvoice !== '') {
        rowData.dateOfInvoice = getMMDDYYYFormat(rowData.dateOfInvoice, 'YYYY-MM-DD');
      }
      if (rowData.serviceScheduleDate && rowData.serviceScheduleDate !== '') {
        rowData.serviceScheduleDate = getMMDDYYYFormat(rowData.serviceScheduleDate, 'YYYY-MM-DD');
      }
      if (rowData.fPBRedemptionDate && rowData.fPBRedemptionDate !== '') {
        rowData.fPBRedemptionDate = getMMDDYYYFormat(rowData.fPBRedemptionDate, 'YYYY-MM-DD');
      }
    }
    this.clonedRowData[rowData.id] = { ...rowData };
  }

  continueSave(rowData) {
    delete this.clonedRowData[rowData.id];
    this.techInfoDataUpdate(rowData);
  }

  onRowEditSave(rowData: any, index: number) {
    let isRedemptionWarning = false;
    const user = this.azureService.roleName;
    const currentDispatchDate =
      this.serviceOrderDataService.serviceOrderDataModel.claimData.dispatchDate;
    const dateOfRepair = rowData.dateOfRepair ? rowData.dateOfRepair : null;
    const dateOfInvoice = rowData.dateOfInvoice ? rowData.dateOfInvoice : null;
    const dispatchDate = currentDispatchDate
      ? currentDispatchDate.includes('.')
        ? this.datePipe.transform(currentDispatchDate, 'yyyy-MM-dd')
        : currentDispatchDate.substring(0, 10)
      : null;
    if (dateOfRepair && !dispatchDate) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Repair Date cannot be populated if not Dispatched (Empty Dispatch Date)',
      });
      this.revertData(rowData, index);
    } else if (!this.isExpiredContract && dateOfRepair && dateOfRepair < dispatchDate) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Date of Repair can not be earlier than Dispatch Date',
      });
      this.revertData(rowData, index);
    } else {
      if (this.serviceOrderService.getAdminsList().includes(this.azureService.roleName)) {
        // 50% Validation
        if (this.isExpiredContract) {
          const { correctiveAction } =
            this.serviceOrderDataService.serviceOrderDataModel.problemReport;
          const fPBRedemptionDate = rowData.fPBRedemptionDate;
          if (dispatchDate && correctiveAction) {
            if (!fPBRedemptionDate) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Redemption Date is required',
              });
              this.revertData(rowData, index);
              return false;
            }
            if (fPBRedemptionDate && !this.isGivenDateInRange(dispatchDate, fPBRedemptionDate)) {
              if (!REDEMPTION_DATE_OVERRIDE_ROLES.includes(this.azureService.roleName)) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Redemption Date should be within 30 Days of dispatch Date',
                });
                this.revertData(rowData, index);
                return false;
              } else {
                isRedemptionWarning = true;
              }
            }
            if (fPBRedemptionDate && dateOfRepair && dateOfRepair < fPBRedemptionDate) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Date of Repair can not be earlier than Redemption Date',
              });
              this.revertData(rowData, index);
              return false;
            }
          } else if (!dispatchDate && correctiveAction) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Please dispatch it before updating the RedemptionDate',
            });
            this.revertData(rowData, index);
            return false;
          }
        }
        if (!dateOfInvoice || !dateOfRepair || dateOfInvoice >= dateOfRepair) {
          const fPBRedemptionDate = rowData.fPBRedemptionDate;
          if (fPBRedemptionDate && dateOfInvoice) {
            this.isDateOfIvoiceInRange(
              fPBRedemptionDate,
              rowData.dateOfInvoice,
              rowData,
              index,
              isRedemptionWarning,
            );
          } else {
            this.overrideRedemptionAlert(rowData, index, isRedemptionWarning);
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Message',
            detail: 'Date of Invoice can not be earlier than Repair Date',
          });
          this.revertData(rowData, index);
        }
      } else {
        if (dateOfRepair && dispatchDate && dateOfRepair < dispatchDate) {
          rowData.dateOfRepair = this.clonedRowData[rowData.id].dateOfRepair;
        }
        if (dateOfInvoice && dispatchDate && dateOfInvoice < dispatchDate) {
          rowData.dateOfInvoice = this.clonedRowData[rowData.id].dateOfInvoice;
        }
        if (this.isExpiredContract) {
          const fPBRedemptionDate = rowData.fPBRedemptionDate;
          if (fPBRedemptionDate && !this.isGivenDateInRange(dispatchDate, fPBRedemptionDate)) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Redemption Date should be within 30 Days of dispatch Date',
            });
            this.revertData(rowData, index);
            return false;
          }
          if (fPBRedemptionDate && dateOfRepair && dateOfRepair < fPBRedemptionDate) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Date of Repair can not be earlier than Redemption Date',
            });
            this.revertData(rowData, index);
            return false;
          }
        }
        // If date of repair and Invoice are populated ensure date of invoice is not earlier than date of repair
        // and both dates at all times can never be earlier than dispatch date
        let errorMsg = '';
        if (!dateOfInvoice || !dateOfRepair || !dispatchDate || dateOfInvoice >= dateOfRepair) {
          const fPBRedemptionDate = rowData.fPBRedemptionDate;
          if (fPBRedemptionDate && dateOfInvoice) {
            this.isDateOfIvoiceInRange(
              fPBRedemptionDate,
              rowData.dateOfInvoice,
              rowData,
              index,
              false,
            );
          } else {
            this.continueSave(rowData);
          }
        } else if (dateOfInvoice < dateOfRepair) {
          errorMsg = 'Date of Invoice can not be earlier than Repair Date';
        }

        if (errorMsg) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error Message',
            detail: errorMsg,
          });
          this.revertData(rowData, index);
        }
        delete this.clonedRowData[rowData.id];
      }
    }
    return 0; // ADDED FOR CODE PATH ISSUE
  }

  isGivenDateInRange(start, tDate) {
    const mStart = new Date(start);
    const allowedFPBRedemptionDate = new Date(mStart);
    allowedFPBRedemptionDate.setDate(allowedFPBRedemptionDate.getDate() + 30);
    const mEnd = new Date(allowedFPBRedemptionDate);
    const mfPBRedemptionDate = new Date(tDate);
    const sHalf = (mEnd.getTime() - mfPBRedemptionDate.getTime()) / (1000 * 3600 * 24);
    const fHalf = (mfPBRedemptionDate.getTime() - mStart.getTime()) / (1000 * 3600 * 24);
    if (sHalf >= 0 && fHalf >= 0) {
      return true;
    }
    return false;
  }

  isDateOfIvoiceInRange(redemptionDate, dateOfInvoice, rowData, index, isRedeptionWarning = false) {
    if (!this.isGivenDateInRange(redemptionDate, dateOfInvoice)) {
      this.confirmationService.confirm({
        header: `Warning Message`,
        message: `Invoice is outside the 30 days period of Redemption,${
          isRedeptionWarning ? 'Redemption Date should be within 30 Days of dispatch Date. ' : ''
        } Please contact New Leaf for Assistance `,
        acceptLabel: 'Proceed',
        rejectLabel: 'Cancel',
        accept: () => {
          this.continueSave(rowData);
        },
        reject: () => {
          this.revertData(rowData, index);
        },
      });
    } else {
      this.overrideRedemptionAlert(rowData, index, isRedeptionWarning);
    }
  }

  overrideRedemptionAlert(rowData, index, isRedeptionWarning) {
    if (isRedeptionWarning) {
      this.confirmationService.confirm({
        header: `Warning Message`,
        message: `Redemption Date should be within 30 Days of dispatch Date`,
        acceptLabel: 'Proceed',
        rejectLabel: 'Cancel',
        accept: () => {
          this.continueSave(rowData);
        },
        reject: () => {
          this.revertData(rowData, index);
        },
      });
    } else {
      this.continueSave(rowData);
    }
  }

  onRowEditCancel(rowData: any, index: number) {
    this.revertData(rowData, index);
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  techInfoDataUpdate(rowData: any) {
    this.isEditing = false;
    const techoInfo = {
      claimNumber: this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber,
      dateOfRepair: rowData.dateOfRepair,
      claimTechInfoIdOriginal: this.claimTechInfoIdOriginal ? this.claimTechInfoIdOriginal : null,
      numberOfVisits: rowData.numberOfVisits ? parseInt(rowData.numberOfVisits, 10) : null,
      technicianName: rowData.technicianName,
      fPBRedemptionDate: rowData.fPBRedemptionDate,
      invoiceNumber: rowData.invoiceNumber,
      serviceStatus: rowData.serviceStatus,
      dateOfInvoice: rowData.dateOfInvoice ? rowData.dateOfInvoice : null,
      serviceScheduleDate: rowData.serviceScheduleDate,
      insuranceCarrier: rowData.insuranceCarrier,
      serviceOrderNumber:
        this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber,
      insertUserName: this.azureService.accountId
    };
    this.serviceOrderDataService.serviceOrderDataModel.techInfo = techoInfo;

    this.querySubscriptions.push(
      this.technicalInformationService
        .createUpdateClaimTechInfo(techoInfo)
        .subscribe(({ data }) => {
          this.serviceOrderDataService.serviceOrderDataModel.techInfo = techoInfo;
          if (data.createUpdateClaimTechInfo) {
            this.serviceOrderDataService.serviceOrderDataModel.techInfo.claimTechInfoIdOriginal =
              data.createUpdateClaimTechInfo.claimTechInfoIdOriginal;
            this.claimTechInfoIdOriginal = data.createUpdateClaimTechInfo.claimTechInfoIdOriginal;
          }
          this.serviceOrderService.refreshClaimOnUpdate.next(true);
          this.newRowFlag = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Saved successfully.',
          });
          this.serviceOrderService.refreshClaimOnUpdate.next(false);
        },
          (error) => {
            if (error.message === 'Failure in createUpdateClaimTechInfo : This invoice number is already associated with another claim. Please enter a different invoice number.') {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: `This invoice number is already associated with another claim. Please enter a different invoice number.`,
              });
              rowData.invoiceNumber = null;
            }
          }
        ),
    );
  }

  revertData(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  getMenuTypeValue(menuType, desc) {
    try {
      return this.globalOptions
        .filter((el) => el.menuType === menuType)
        .find((el) => el.key === desc).value;
    } catch (e) {
      console.log(`ERROR ${e}`);
    }
    return desc;
  }
}
