import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormField } from '../shared/form-field/form-field';

@Injectable()
export class FormFieldControlService {
  constructor() {}

  toFormGroup(formFields: FormField<string>[]) {
    const group: any = {};
    formFields.forEach(formField => {
      if (formField.type === 'checkbox' || formField.type === 'radio-button') {
        formField.options.forEach(element => {
          group[element.key] = new FormControl();
        });
      } else {
        group[formField.key] = formField.required
          ? new FormControl(
              { value: formField.value || '', disabled: formField.disabled },
              Validators.required
            )
          : new FormControl({ value: formField.value || '', disabled: formField.disabled });
      }
    });
    return new FormGroup(group);
  }
}
