import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { ContractService } from '../../contract.service';
import { FormCanDeactivate } from '../../../shared/form-field/form-can-deactivate';

@Component({
  selector: 'general-fields',
  templateUrl: 'general-fields.template.html',
  styleUrls: ['general-fields.scss'],
  providers: [FormFieldControlService],
})
export class GeneralFieldsComponent extends FormCanDeactivate implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  @Input() formData: any;
  @Input() selected = true;
  @Output() modelDataChange = new EventEmitter();
  form: FormGroup;
  payLoad = '';

  constructor(private qcs: FormFieldControlService) {
    super();
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.form.valueChanges.subscribe((x) => {
      this.modelDataChange.emit(x);
    });
    this.form.patchValue({
      ...this.formData,
    });
  }

  onSubmit() {
    // functionality for on submit
  }
}
