export const manufacturerEllipsisHeaderOptions = [
  { label: 'Add New Manufacturer', value: 'add', callback: 'onClickAdd' }
];

export const optionEllipsisHeaderOptions = [
  { label: 'Add New Manufacturer', value: 'add', callback: 'onClickAdd' }
];

export const categoryEllipsisHeaderOptions = [
  { label: 'Add New Category', value: 'add', callback: 'onClickAdd' }
];

export const subCategoryEllipsisHeaderOptions = [
  { label: 'Add New Subategory', value: 'add', callback: 'onClickAdd' }
];

export const salesRepEllipsisHeaderOptions = [
  { label: 'Add New Sales Representative', value: 'add', callback: 'onClickAdd' }
];
