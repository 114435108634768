import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';

const defaultStatus = 'A';

const buyingGroupSearchQuery = gql`
  query(
    $status: String!
    $number: String
    $name: String
    $city: String
    $state: String
    $zipCode: String
    $phoneNumber: String
    $masterBuyingGroupId: Int
  ) {
    getBuyingGroupSearchResults(
      status: $status
      number: $number
      name: $name
      city: $city
      state: $state
      zipCode: $zipCode
      phoneNumber: $phoneNumber
      masterBuyingGroupId: $masterBuyingGroupId
    ) {
      agentIdOriginal
      status
      number
      name
      city
      state
      zipCode
      phoneNumber
      masterBuyingGroupId
    }
  }
`;
@Injectable()
export class SearchBuyingGroupService {
  constructor(private apollo: Apollo) {}

  getBuyingGroupSearchResult = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: buyingGroupSearchQuery,
      variables: {
        ...searchParams,
        status: searchParams.status.value || defaultStatus,
        masterBuyingGroupId: searchParams.masterBuyingGroupId.value || null,
        state: searchParams.state.value || ''
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;
}
