import { Component, ChangeDetectorRef, OnInit, EventEmitter, Output } from '@angular/core';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { ContactTabService } from './contact-tab.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService } from 'primeng/api';
import { buttonStatus } from '../../shared/constants/button-class';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { ConfigurationTypeService } from '../../administration/configuration/configuration.service';
import { copy } from '../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'contact-tab',
  templateUrl: 'contact-tab.template.html',
  styleUrls: ['contact-tab.scss'],
  providers: [ContactTabService],
})
export class BuyingContactTabComponent extends FormCanDeactivate implements OnInit {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  isLoading = false;
  constructor(
    private contactTabService: ContactTabService,
    private confirmationService: ConfirmationService,
    private configurationService: ConfigurationTypeService,
    private cdr: ChangeDetectorRef,
    private rps: RolePermissionService,
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.isLoading = true;
    this.contactTabService.getContact().subscribe((data) => {
      this.isLoading = false;
      this.dataList = data;
    });
    this.dropdown = {
      position: [],
    };
    this.configurationService
      .getAdminConfigMenu({
        module: 'buying_group',
        menuType: 'position',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const positionOptions = configList
          .filter((el) => el.menuType === 'position')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        this.dropdown.position = positionOptions;
        this.formValueChanged.emit(true);
      });
    this.cols = [
      { field: 'position', header: 'POSITION', type: 'select' },
      { field: 'firstName', header: 'FIRST NAME', type: 'text' },
      { field: 'lastName', header: 'LAST NAME', type: 'text' },
      { field: 'businessPhone', header: 'BUSSINESS PHONE', type: 'text' },
      { field: 'fax', header: 'FAX', type: 'text' },
      { field: 'email', header: 'EMAIL', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
    ];
    // TODO: Dynamically set primaryAddress
    this.primaryAddress = 'pAddress1';
    this.selectedColumns = this.cols.slice(0, 8);
    this.doEvaluateRolePermissions();
  }
  private doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Contacts',
      category: 'Agent',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );

    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Contacts',
      category: 'Agent',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  onClickAdd(event) {
    let rowItem = {
      id: null,
      name: '',
      description: '',
      active: '',
      defaultProfile: '',
    };
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }
}
