import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { AddressCategoryType } from '../../../shared/constants/enums';
import { FormField } from '../../../shared/form-field/form-field';
import { Note } from '../../../shared/notes/note.model';
import { NoteService } from '../../../shared/notes/note.service';
import { SendVtShortKeyEmailService } from '../send-vt-short-key-email/send-vt-short-key-email.service';
import { ServiceOrderDataService } from '../service-order-data.service';
import { ServiceOrderService } from '../service-order.service';

@Component({
  selector: 'validator-tool-modal',
  templateUrl: 'validator-tool-modal.template.html',
  styleUrls: ['./validator-tool-modal.scss'],
  providers: [FormFieldControlService, SendVtShortKeyEmailService],
})
export class ValidatorToolModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() formFields: FormField<string>[] = [];
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  form: FormGroup;
  querySubscriptions: Subscription[] = [];
  selectedCode: string;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displayEmailModal: true;
  public note: Note = {};
  public currentUser: any;
  currentType: number;
  constructor(
    private qcs: FormFieldControlService,
    private serviceOrderDataService: ServiceOrderDataService,
    private service: ServiceOrderService,
    private noteService: NoteService,
    private azureService: AzureLoginService,
    private sendVtShortKeyEmailService: SendVtShortKeyEmailService,
  ) {
    this.querySubscriptions.push(
      azureService.getFullName().subscribe((currentUser: any) => {
        this.currentUser = currentUser;
      }),
    );
  }
  ngOnInit() {
    this.serviceOrderDataService.isValidatorDataAvailable.subscribe({
      next: (isValidatorDataAvailable) => {
        if (isValidatorDataAvailable) {
          this.serviceOrderDataService.validatorDataModel.attempt =
            this.serviceOrderDataService.validatorDataModel.attemptFee;
          this.serviceOrderDataService.validatorDataModel.estimate = '';
          this.serviceOrderDataService.validatorDataModel.total =
            this.serviceOrderDataService.validatorDataModel.totalFee;
          let attemptFeeSubstracted = false;
          this.querySubscriptions.push(
            this.service
              .getValidatorToolFields(this.serviceOrderDataService.validatorDataModel)
              .subscribe((data: FormField<string>[]) => {
                this.form = this.qcs.toFormGroup(data);
                this.form.get('rsp').valueChanges.subscribe((x) => {
                  let total = parseFloat(this.form.get('total').value);
                  x && x.length ? (total += 250) : (total -= 250);
                  this.form.patchValue({
                    total: total.toFixed(2),
                  });
                });
                this.form.get('estimate').valueChanges.subscribe((x) => {
                  let total = parseFloat(this.form.get('total').value);
                  if (
                    x &&
                    !attemptFeeSubstracted &&
                    (x.value === 'Pending' || x.value === 'Denied' || x.value === 'Approved')
                  ) {
                    total -= this.serviceOrderDataService.validatorDataModel.attemptFee;
                    attemptFeeSubstracted = true;
                  }
                  this.form.patchValue({
                    total: total.toFixed(2),
                  });
                });
                this.form.valueChanges.subscribe((x) => {
                  this.formValueChanged.emit(true);
                });
              }),
          );
        }
      },
    });
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 3);
    this.formFields2 = this.formFields.slice(10, 29);
  }
  onSubmit() {
    const payload = this.form.getRawValue();
    if (!this.form.valid) {
      return;
    }
    this.saveNote(payload);
    this.formSubmitEvent.emit(this.form);
  }
  onCancel() {
    const payload = this.form.getRawValue();
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
    this.serviceOrderDataService.validatorDataModel.attempt = payload.attempt;
  }

  onSendEmail() {
    const payload = this.form.getRawValue();
    this.serviceOrderDataService.validatorDataModel.warranty = payload.warranty;
    this.serviceOrderDataService.validatorDataModel.partDist = payload.partDist;
    this.serviceOrderDataService.validatorDataModel.estimate = payload.estimate
      ? payload.estimate.value
      : null;
    this.serviceOrderDataService.validatorDataModel.total = payload.total;
    this.serviceOrderDataService.validatorDataModel.serial = payload.serial;
    this.serviceOrderDataService.validatorDataModel.failureDescription = payload.failureDescription;
    if (
      this.serviceOrderDataService.validatorDataModel.estimate &&
      (this.serviceOrderDataService.validatorDataModel.estimate === 'Pending' ||
        this.serviceOrderDataService.validatorDataModel.estimate === 'Denied')
    ) {
      this.serviceOrderDataService.validatorDataModel.attempt = payload.attempt;
    } else {
      this.serviceOrderDataService.validatorDataModel.attempt = '';
    }
    if (parseFloat(payload.total) > 1000) {
      this.selectedCode = 'VESTS';
    } else {
      this.selectedCode = '';
    }
    this.displayEmailModal = true;
  }

  onValidatorSubmit(event) {}

  saveNote(formData) {
    const typeList = [{ label: 'External', value: 38 }];
    const identifierId = this.serviceOrderDataService.serviceOrderDataModel.claimData.claimId;
    const category = AddressCategoryType.ClaimContact;
    const note = {
      tenantId: null,
      noteType: typeList[0].value,
      contractUniqueId:
        this.serviceOrderDataService.serviceOrderDataModel.contractInfo.contractsId.toString(),
      noteText: JSON.stringify(formData),
      enteredBy: this.currentUser,
      claimId: identifierId,
      noteIdOriginal: null,
      external: null,
      isDeleted: null,
    };

    this.querySubscriptions.push(
      this.noteService.saveNotes(note, identifierId, category).subscribe((data) => {
        this.serviceOrderDataService.loadNote.next(true);
        this.displayDialog = false;
        this.displayDialogChange.emit(this.displayDialog);
      }),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
