<div class="container dark" [class.collapsed]="sidebarCollapsed">
  <nav id="sidebar-nav">
    <div class="collapse" (click)="toggleCollapse()">
      <div [ngClass]="{'gray-open':sidebarCollapsed, 'gray-closed': !sidebarCollapsed }"
        [inlineSVG]="'assets/sidebar-expander.svg'">
      </div>
    </div>
    <sidebar-left-navigation [myCallback]="theBoundCallback"></sidebar-left-navigation>
  </nav>
  <div id="right-sidebar">
    <div *ngIf="selectedNavItemId === 1">
      <h1>Contracts</h1>
      <sidebar-right-navigation [data]="{list: contractsList, navtype:'CT'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 2">
      <h1>Service</h1>
      <sidebar-right-navigation [data]="{list: serviceList, navtype:'SV'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 3">
      <h1>Notifications</h1>
      <sidebar-right-navigation [data]="{list: notificationList, navtype:'NO'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 4">
      <h1>Buying Group</h1>
      <sidebar-right-navigation [data]="{list: buyingGroupList, navtype:'BG'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 5">
      <h1>TroubleShooting</h1>
      <sidebar-right-navigation [data]="{list: troubleshootingList, navtype:'TS'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 6">
      <h1>Dealer</h1>
      <sidebar-right-navigation [data]="{list: dealerLocationList, navtype:'DL'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 7">
      <h1>Coverage</h1>
      <sidebar-right-navigation [data]="{list: coverageList, navtype:'CV'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 8">
      <h1>Catalog</h1>
      <sidebar-right-navigation [data]="{list: catalogList, navtype:'CL'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 9">
      <h1>Form</h1>
      <sidebar-right-navigation [data]="{list: formList, navtype:'FM'}"></sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 10">
      <h1>Claim Payment</h1>
      <sidebar-right-navigation [data]="{list: claimList, navtype:'CP'}"></sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 11">
      <h1>Sales Register</h1>
      <sidebar-right-navigation [data]="{list: salesregisterlist, navtype:'SR'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 12">
      <h1>Imports</h1>
      <sidebar-right-navigation [data]="{list: documentList, navtype:'DO'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 13">
      <h1>Security</h1>
      <sidebar-right-navigation [data]="{list: securityList, navtype:'SEC'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 14">
      <h1>Configuration</h1>
      <sidebar-right-navigation [data]="{list: configurationList, navtype:'CFG'}">
      </sidebar-right-navigation>
    </div>
    <div *ngIf="selectedNavItemId === 15">
      <h1>Reports</h1>
      <sidebar-right-navigation [data]="{list: reportsList, navtype:'RPT'}">
      </sidebar-right-navigation>
    </div>
  </div>
</div>