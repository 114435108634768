<div class="summary-section">
  <div class="title">{{title}}</div>
  <div class="row">
    <div class="column" *ngFor="let item of data">
      <div class="row">
        <div class="column label">{{item.label}}</div>
        <div class="column value">{{item.value}}</div>
      </div>
    </div>
  </div>
  <form class="content" *ngIf="formFields" [formGroup]="form">
    <div class="form-row" *ngFor="let formField of formFields">
      <form-field [formField]="formField" [form]="form"></form-field>
    </div>
  </form>
</div>