import gql from 'graphql-tag';

export const serviceDetailsFormQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      contractNumber
      uniqueId
      dop
      cultureCode
      effectiveDate
      printedDate
      effectiveBasedOn
      expirationDate
      shipDate
    }
  }
`;
export const serviceMainTabQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      contractsIdOriginal
      customerId
      contractStatusDescription
      contractNumber
      uniqueId
      productTypeDescription
      insurerName
      contactInformation(categoryType: Customer) {
        customerFirstName
        customerLastName
        customerPhone
        phoneNumber2
        customerEmail
        customerFax
        guardian
        customerIdOriginal
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
      addresses(categoryType: Contract) {
        id
        address1
        address2
        state
        city
        zipCode
        country
        addressIDOriginal
      }
      associatedContracts {
        contractsId
        manufacturer
        modelName
        serialNumber
        planDateOfPurchase
        planStartDate
        planExpDate
        sKUDescription
      }
      general1
      general2
      general3
      general4
      storeLocationName
      storeLocationNumber
      dealerGroupNumber
      agentName
      buyingGroup
      saleDate
      coverageInformation {
        planType
        deductibleCollectedBy
      }
    }
  }
`;

export const soClaimsQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      soClaims {
        claimStatusDescription
        approvedAmount
        claimNumber
        checkAmount
        productSubCategory
        productModelName
        failureFunctionalityStatusCode
        claimDate
        repairOrderDate
      }
    }
  }
`;

export const inquiryQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      contractInquiry {
        contractInquiryEventsId
        contractsId
        status
        assignedTo
        content
        enteredBy
        enteredTime
      }
    }
  }
`;

export const rateDetailsQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      contractsOriginalId
      retailRate
      cancelRetailRate
      rateDetails {
        contractNumber
        contractRatesId
        contractsId
        bucketCode
        bucketLabelDescription
        writtenAmount
        balanceAmount
        cancelledAmount
        categoryCode
      }
    }
  }
`;

export const contractCoverageQuery = gql`
  query ($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      coverageCode
      coverageDescription
      netRate
      retailRate
      retailTax1
      retailTax2
      grandTotal
      expirationDate
      coveredProducts {
        partsTerm
      }
      coverageInformation {
        serviceLocation
        planType
      }
    }
  }
`;

export const servicerProfileByIdQuery = gql`
  query ($id: Int!) {
    getServicerProfileById(id: $id) {
      servicerProfilesId
      servicerProfilesIdOriginal
      servicerNumber
      servicerName
      claimPayMethod
      fedTax
      isCreditCardProfile
      addresses(categoryType: ServicerProfile) {
        id
        address1
        address2
        state
        city
        country
        zipCode
        isMailingAddress
        isBillingAddress
      }
      contactInfo(categoryType: ServicerProfile) {
        id
        firstName
        lastName
        phoneNumber1
        email
        fax
        businessPhone
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
      servicerMarkupRates {
        servicerMarkupId
        servicerName
        rangeFrom
        rangeTo
        percentage
        effectiveDate
      }
      servicerRatesSchedule {
        effectiveDate
        description
        tripDiag
        repair
        highEndRepair
        sealedSys
        highEndSealedSys
        categoryDescription
        subcategoryDescription
        addedTimeStamp
      }
    }
  }
`;

export const getClaimServicerByClaimQuery = gql`
  query ($id: Int) {
    getClaimServicerByclaimId(id: $id) {
      claimServicerIdOriginal
      claimId
      cause
      causeNote
      correctiveAction
      correctiveActionNote
      failureNote
    }
  }
`;

export const getServicerEligibleProfilesQuery = gql`
  query (
    $state: String!
    $city: String
    $zipCode: String
    $selfServicing: Boolean
    $customerSatisfaction: Int
    $offset: Int
    $limit: Int
  ) {
    getServicerEligibleProfiles(
      state: $state
      city: $city
      zipCode: $zipCode
      selfServicing: $selfServicing
      customerSatisfaction: $customerSatisfaction
      offset: $offset
      limit: $limit
    ) {
      servicerProfilesId
      servicerNumber
      servicerName
    }
  }
`;

export const getServiceOrderSearchQuery = gql`
  query (
    $status: String
    $claimNumber: String
    $contractNumber: String
    $uniqueId: String
    $invoiceNumber: String
    $phoneNumber: String
    $offset: Int
    $limit: Int
    $contractsId: Int
    $claimId: Int
    $azureUserId: String
    $dealer: String
    $buyingGroup: String
    $servicer: String
    $workQueueFlag: Boolean
    $claimPriorityCode: String
    $claimStartDate: Date
    $claimEndDate: Date
    $dateOfRepairStartDate: Date
    $dateOfRepairEndDate: Date
    $dateSubmitForPaymentStartDate: Date
    $dateSubmitForPaymentEndDate: Date
    $approvedStartDate: Date
    $approvedEndDate: Date
    $preApprovedStartDate: Date
    $preApprovedEndDate: Date
    $contactFirstName: String
    $contactLastName: String
    $manufacturer: String
    $modelNumber: String
    $customerFeedback: String
    $authorizationCode: String
    $technicianName: String
    $assignedTo: String
    $claimPaymentStartDate: Date
    $claimPaymentEndDate: Date
    $createdBy: String
  ) {
    getClaimsSearchResults(
      status: $status
      claimNumber: $claimNumber
      contractNumber: $contractNumber
      uniqueId: $uniqueId
      invoiceNumber: $invoiceNumber
      phoneNumber: $phoneNumber
      offset: $offset
      limit: $limit
      contractsId: $contractsId
      claimId: $claimId
      azureUserId: $azureUserId
      dealer: $dealer
      buyingGroup: $buyingGroup
      servicer: $servicer
      workQueueFlag: $workQueueFlag
      claimPriorityCode: $claimPriorityCode
      claimStartDate: $claimStartDate
      claimEndDate: $claimEndDate
      dateOfRepairStartDate: $dateOfRepairStartDate
      dateOfRepairEndDate: $dateOfRepairEndDate
      dateSubmitForPaymentStartDate: $dateSubmitForPaymentStartDate
      dateSubmitForPaymentEndDate: $dateSubmitForPaymentEndDate
      approvedStartDate: $approvedStartDate
      approvedEndDate: $approvedEndDate
      preApprovedStartDate: $preApprovedStartDate
      preApprovedEndDate: $preApprovedEndDate
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      manufacturer: $manufacturer
      modelNumber: $modelNumber
      customerFeedback: $customerFeedback
      authorizationCode: $authorizationCode
      technicianName: $technicianName
      assignedTo: $assignedTo
      claimPaymentStartDate: $claimPaymentStartDate
      claimPaymentEndDate: $claimPaymentEndDate
      createdBy: $createdBy
    ) {
      claimId
      claimNumber
      status
      contractId
      uniqueId
      invoiceNumber
      phoneNumber
      contractsIdOriginal
      serviceOrderNumber
      contractNumber
      claimPriorityCode
      servicer
      claimDate
      dateOfRepair
      submitForPaymentDate
      approvedForPaymentDate
      preApprovedDate
      contactFirstName
      contactLastName
      manufacturer
      modelNumber
      customerFeedback
      authorizationCode
      technicianName
      assignedTo
      claimPaymentDate
      createdBy
      dispatchDate
      customerId
      checkNumber
      checkAmount
      insuranceCarrier
    }
  }
`;

export const getClaimLastUpdateTimeQuery = gql`
  query ($id: Int, $userId: String!) {
    getClaimProfileById(id: $id, azureUserId: $userId) {
      lastUpdateTime
      serviceOrderNumber
    }
  }
`;

export const getClaimProfileByIdQuery = gql`
  query ($id: Int, $dealerGroupNumber: String, $userId: String!) {
    getClaimProfileById(id: $id, azureUserId: $userId) {
      claimId
      claimIdOriginal
      claimNumber
      contractNumber
      claimStatusCode
      claimStatusDescription
      claimActivityDescription
      claimPaymentDate
      checkAmount
      checkNumber
      externalBatchNumber
      externalBatchSequenceNumber
      productsId
      productModelNumber
      productSerialNumber
      claimPriorityCode
      claimPriorityDescription
      sUniqueId
      paymentMethodCode
      dispatchDate
      isBusinessLocation
      servicerProfilesIdOriginal
      deductibleCollectedBy
      productCondition
      contractsIdOriginal
      callerType
      dispatchMethod
      customerDispatch
      serviceType
      addedTimeStamp
      insertUserName
      sendFollowUpEmail
      serviceOrderNumber
      jsyFlag
      customerFeedback
      importFileName
      assignedTo
      assignedToName
      createdBy
      lastUpdatedBy
      lastUpdateTime
      startedOn
      claimDate
      transferredContractsId
      goodwillReason
      goodwillInsertUserName
      goodwillAddedTimeStamp
      modelSuffix
      contactInfo(categoryType: ClaimContact) {
        id
        categoryType
        contactId
        contactIdOriginal
        firstName
        lastName
        phoneNumber1
        phoneNumber2
        email
        phoneTypeCode1
        phoneTypeCode2
        businessPhoneTypeCode
      }
      addresses(categoryType: ClaimProduct) {
        id
        address1
        address2
        state
        city
        zipCode
        country
        addressIDOriginal
      }
      coveredProduct {
        productsId
        contractsId
        productNo
        contractUniqueId
        manufacturerManufacturer
        modelName
        modelNumber
        serialNumber
        type
        subcategoryCode
        subcategoryDescription
        categoryCode
        categoryDescription
        tierCode
        tierDescription
        productPurchaseDate
        installDate
        productPurchasePrice
        productRetailAmount
        partsTerm
        laborTerm
        majorComponentTerm
        otherTerm
        serviceLocation
        fulfilled
        isDeleted
        productsIdOriginal
        lolVirtualCalculatedFields {
          liabilitySpent
          liabilityRemaining
          totalLiability
        }
      }
      problem {
        failureDate
        reportedDate
        functionalityStatus
        failureDescription
        symptom
        symptomFixNote
        claimProblemSymptomIdOriginal
        failure
      }
      eligibleStatus(userId: $userId, type: "Claim")
    }
    getDealerProfileById(number: $dealerGroupNumber) {
      name
      number
      dealerGroupNumber
      dealerGroupName
      tSelfServicing
      dispatch
      followUp
      dealerGroupsIdOriginal
      contactInfo(categoryType: DealersGroup) {
        phoneNumber1
        businessPhone
      }
    }
  }
`;

export const getClaimCoverageDetailsQuery = gql`
  query ($id: Int!, $claimId: Int) {
    getClaimCoverageDetails(productsIdOriginal: $id, claimId: $claimId) {
      name
      policySku
      covered
      description
      coverageStDate
      coverageExpDate
      covDateSummary
      cvTerm
      covered
      coverageReason
      selection
    }
  }
`;

export const getClaimDealerInfoQuery = gql`
  query ($dealerGroupNumber: String) {
    getDealerProfileById(number: $dealerGroupNumber) {
      name
      number
      dealerGroupNumber
      dealerGroupName
      dealerGroupsIdOriginal
      tSelfServicing
      dispatch
      followUp
      contactInfo(categoryType: DealersGroup) {
        phoneNumber1
        businessPhone
      }
    }
  }
`;

export const claimProblemSymptomQuery = gql`
  mutation (
    $claimNumber: String
    $serviceOrderNumber: String
    $claimId: Int
    $functionalityStatus: String
    $failureDate: Date
    $reportedDate: Date
    $failureDescription: String
    $symptom: String
    $symptomNote: String
    $symptomFixNote: String
    $claimProblemSymptomIdOriginal: Int
    $enteredBy: String
    $insertUserName: String
  ) {
    createUpdateClaimProblemSymptom(
      claimProblemSymptom: {
        claimNumber: $claimNumber
        serviceOrderNumber: $serviceOrderNumber
        claimId: $claimId
        functionalityStatus: $functionalityStatus
        failureDate: $failureDate
        reportedDate: $reportedDate
        failureDescription: $failureDescription
        symptom: $symptom
        symptomNote: $symptomNote
        symptomFixNote: $symptomFixNote
        claimProblemSymptomIdOriginal: $claimProblemSymptomIdOriginal
        enteredBy: $enteredBy
        insertUserName: $insertUserName
      }
    ) {
      claimProblemSymptomId
      claimNumber
      claimProblemSymptomIdOriginal
      claimNumber
      serviceOrderNumber
      claimId
      functionalityStatus
      failureDate
      failureDescription
      symptom
      symptomNote
      symptomFixNote
    }
  }
`;

export const createUpdateExpenseChargesQuery = gql`
  mutation ($expenseCharges: [expenseChargeInput]) {
    createUpdateExpenseCharges(expenseCharges: $expenseCharges) {
      claimNumber
      claimId
      expenseType
      description
      partNumber
      requestedPrice
      requestedMarkup
      requestedAmount
      adjustedPrice
      adjustedMarkup
      adjustedAmount
      discount
      expenseChargesIdOriginal
      modelNumber
      serialNumber
      quantity
      serviceOrderNumber
      insertUserName
      isAddedFromApp
    }
  }
`;

export const createUpdateCreditCardInfoQuery = gql`
  mutation ($outOfNetworkInfo: OutOfNetworkInfoInput) {
    createUpdateOutofNetworkInfo(outOfNetworkInfo: $outOfNetworkInfo) {
      outOfNetworkInfoIdOriginal
      companyName
      nameCC
      providerAddress
      phoneNumber
      initialFee
      poc
      dispatchMethod
      emailFax
      partOrderNumber
      customerAddressVerified
      cCProvidedDate
      approvedAmount
      agentInitials
      serviceOrderNumber
      isDeleted
      insertUserName
    }
  }
`;

export const createUpdateBookingAppointmentQuery = gql`
  mutation ($bookingAppointment: BookingAppointmentInput) {
    createUpdateBookingAppointment(bookingAppointment: $bookingAppointment) {
      appointmentsId
      serviceType
      serviceOrderNumber
      status
      jobNumber
      servicerName
      address
      phone
      isAuthorized
      scheduledDate
      timePeriod
      appointmentsIdOriginal
      isDeleted
      insertUserName
      addedTimeStamp
      isHistoryItem
      servicerAccount
      timeSlotStart
      timeSlotLength
    }
  }
`;

export const saveClaimsQuery = gql`
  mutation (
    $claimNumber: String
    $contractNumber: String
    $productTypeCode: String
    $productTypeDescription: String
    $productTypeSortOrder: Int
    $claimPriorityCode: String
    $claimPriorityDescription: String
    $sUniqueId: String
    $claimStatusCode: String
    $claimStatusDescription: String
    $repairOrderNumber: String
    $repairOrderDate: Date
    $claimDate: Date
    $claimActivityCode: String
    $claimActivityDescription: String
    $callerType: String
    $failureFunctionalityStatusCode: String
    $failureDate: Date
    $failureReportedDate: Date
    $dedcuctibleStatus: String
    $dedcutbilePaidAmount: String
    $approvedAmount: Float
    $paymentMethodCode: String
    $sDealerGroupNumber: String
    $sDealerGroupName: String
    $dealerGroupSelfServicing: Boolean
    $isBusinessLocation: Boolean
    $productManufacturerName: String
    $productModelName: String
    $productModelNumber: String
    $productSerialNumber: String
    $productCondition: String
    $productCategory: String
    $productSubCategory: String
    $productServiceLocaction: String
    $deductibleCollectedBy: String
    $dispatchDate: Date
    $dispatchMethod: String
    $paymentDate: Date
    $checkAmount: String
    $claimDeductible: String
    $checkNumber: String
    $claimPaymentDate: Date
    $externalBatchNumber: String
    $externalBatchSequenceNumber: String
    $claimIdOriginal: Int
    $isDeleted: Boolean
    $servicerProfilesIdOriginal: Int
    $addresses: [AddressInputType]
    $contactInput: ContactInputType
    $contractsIdOriginal: Int
    $modelSuffix: String
    $productPurchaseDate: Date
    $productPurchasePrice: String
    $productRetailAmount: Float
    $productsIdOriginal: Int
    $customerDispatch: Boolean
    $serviceType: String
    $addedTimeStamp: Date
    $insertUserName: String
    $sendFollowUpEmail: Boolean
    $buyingGroup: String
    $servicerName: String
    $dealerName: String
    $serviceOrderNumber: String
    $jsyFlag: Boolean
    $customerFeedback: String
    $importFileName: String
    $assignedTo: String
    $lastUpdatedBy: String
    $lastUpdateTime: Date
    $createdBy: String
    $transferredContractsId: Int
    $goodwillReason: String
    $goodwillInsertUserName: String
    $goodwillAddedTimeStamp: Date
  ) {
    createUpdateClaims(
      claim: {
        claimNumber: $claimNumber
        contractNumber: $contractNumber
        productTypeCode: $productTypeCode
        productTypeDescription: $productTypeDescription
        productTypeSortOrder: $productTypeSortOrder
        claimPriorityCode: $claimPriorityCode
        claimPriorityDescription: $claimPriorityDescription
        sUniqueId: $sUniqueId
        claimStatusCode: $claimStatusCode
        claimStatusDescription: $claimStatusDescription
        repairOrderNumber: $repairOrderNumber
        repairOrderDate: $repairOrderDate
        claimDate: $claimDate
        claimActivityCode: $claimActivityCode
        claimActivityDescription: $claimActivityDescription
        callerType: $callerType
        failureFunctionalityStatusCode: $failureFunctionalityStatusCode
        failureDate: $failureDate
        failureReportedDate: $failureReportedDate
        dedcuctibleStatus: $dedcuctibleStatus
        dedcutbilePaidAmount: $dedcutbilePaidAmount
        approvedAmount: $approvedAmount
        paymentMethodCode: $paymentMethodCode
        sDealerGroupNumber: $sDealerGroupNumber
        sDealerGroupName: $sDealerGroupName
        dealerGroupSelfServicing: $dealerGroupSelfServicing
        isBusinessLocation: $isBusinessLocation
        productManufacturerName: $productManufacturerName
        productModelName: $productModelName
        productModelNumber: $productModelNumber
        productSerialNumber: $productSerialNumber
        productCondition: $productCondition
        productCategory: $productCategory
        productSubCategory: $productSubCategory
        productServiceLocaction: $productServiceLocaction
        deductibleCollectedBy: $deductibleCollectedBy
        productPurchaseDate: $productPurchaseDate
        productPurchasePrice: $productPurchasePrice
        productRetailAmount: $productRetailAmount
        dispatchDate: $dispatchDate
        dispatchMethod: $dispatchMethod
        paymentDate: $paymentDate
        checkAmount: $checkAmount
        claimDeductible: $claimDeductible
        checkNumber: $checkNumber
        claimPaymentDate: $claimPaymentDate
        externalBatchNumber: $externalBatchNumber
        externalBatchSequenceNumber: $externalBatchSequenceNumber
        claimIdOriginal: $claimIdOriginal
        isDeleted: $isDeleted
        addresses: $addresses
        contactInput: $contactInput
        servicerProfilesIdOriginal: $servicerProfilesIdOriginal
        contractsIdOriginal: $contractsIdOriginal
        productsId: $productsIdOriginal
        customerDispatch: $customerDispatch
        serviceType: $serviceType
        addedTimeStamp: $addedTimeStamp
        insertUserName: $insertUserName
        sendFollowUpEmail: $sendFollowUpEmail
        buyingGroup: $buyingGroup
        servicerName: $servicerName
        dealerName: $dealerName
        serviceOrderNumber: $serviceOrderNumber
        jsyFlag: $jsyFlag
        customerFeedback: $customerFeedback
        importFileName: $importFileName
        assignedTo: $assignedTo
        lastUpdatedBy: $lastUpdatedBy
        lastUpdateTime: $lastUpdateTime
        createdBy: $createdBy
        transferredContractsId: $transferredContractsId
        goodwillReason: $goodwillReason
        goodwillInsertUserName: $goodwillInsertUserName
        goodwillAddedTimeStamp: $goodwillAddedTimeStamp
        modelSuffix: $modelSuffix
      }
    ) {
      claimIdOriginal
      claimNumber
      sUniqueId
      claimStatusDescription
      contractsIdOriginal
      productModelNumber
      productSerialNumber
      addedTimeStamp
      serviceOrderNumber
      contractNumber
      lastUpdateTime
    }
  }
`;

export const getSymptomByProductTierQuery = gql`
  query ($productsIdOriginal: Int!) {
    getSymptomByProductTier(productsIdOriginal: $productsIdOriginal)
  }
`;

export const getSymptomOptionsByTierAndSymptomQuery = gql`
  query ($productsIdOriginal: Int!, $failureType: String!) {
    getSymptomOptionsByTierAndSymptom(
      productsIdOriginal: $productsIdOriginal
      failureType: $failureType
    )
  }
`;

export const createUpdateClaimCoverageQuery = gql`
  mutation (
    $claimId: Int
    $name: String
    $covered: Boolean
    $coverageStart: Date
    $coverageExp: Date
    $policy: String
    $description: String
    $term: String
    $coverageReason: String
    $claimCoverageIdOriginal: Int
  ) {
    createUpdateClaimCoverage(
      claimCoverage: {
        claimId: $claimId
        name: $name
        covered: $covered
        coverageStart: $coverageStart
        coverageExp: $coverageExp
        policy: $policy
        description: $description
        term: $term
        coverageReason: $coverageReason
        claimCoverageIdOriginal: $claimCoverageIdOriginal
      }
    ) {
      claimCoverageId
      claimCoverageIdOriginal
      claimId
      coverageExp
      coverageStart
    }
  }
`;

export const createUpdateClaimServicerquery = gql`
  mutation (
    $claimServicerIdOriginal: Int
    $cause: String
    $correctiveAction: String
    $claimId: Int
    $failureNote: String
    $causeNote: String
    $correctiveActionNote: String
    $insertUserName: String
  ) {
    createUpdateClaimServicer(
      claimServicer: {
        claimServicerIdOriginal: $claimServicerIdOriginal
        cause: $cause
        correctiveAction: $correctiveAction
        claimId: $claimId
        failureNote: $failureNote
        causeNote: $causeNote
        correctiveActionNote: $correctiveActionNote
        insertUserName: $insertUserName
      }
    ) {
      claimServicerIdOriginal
      claimId
      cause
      failureNote
      causeNote
      correctiveActionNote
    }
  }
`;

export const getRealLimitOfLiabilityQuery = gql`
  query ($productsIdOriginal: Int!) {
    getRealLimitOfLiabilityById(productId: $productsIdOriginal) {
      productPurchasePrice
      adjustedProductValue
      remainingForProduct
      usedForProduct
      popupFlag
      popupType
      popupDescription
      approvedForProduct
    }
  }
`;

export const getExpenseChargesByClaimQuery = gql`
  query ($serviceOrderNumber: String!) {
    getExpenseChargesByClaim(serviceOrderNumber: $serviceOrderNumber) {
      claimNumber
      claimId
      expenseType
      description
      partNumber
      requestedPrice
      requestedMarkup
      requestedAmount
      adjustedPrice
      adjustedMarkup
      adjustedAmount
      discount
      status
      paidAmount
      expenseChargesIdOriginal
      isDeleted
      serviceOrderNumber
      insertUserName
      addedTimeStamp
      modelNumber
      serialNumber
      quantity
      productId
      serviceOrderNumber
    }
  }
`;
export const getScheduledJobQuery = gql`
  query ($serviceOrderNumber: String, $insertUserName: String) {
    getBookAppointmentByServiceOrder(
      serviceOrderNumber: $serviceOrderNumber
      insertUserName: $insertUserName
    ) {
      serviceType
      serviceOrderNumber
      status
      jobNumber
      servicerName
      address
      phone
      isAuthorized
      scheduledDate
      timePeriod
      appointmentsIdOriginal
      isDeleted
      insertUserName
      isHistoryItem
      servicerAccount
      timeSlotStart
      timeSlotLength
      isRescheduled
    }
  }
`;
export const getOutOfNetworkInfoQuery = gql`
  query ($serviceOrderNumber: String) {
    getOutOfNetworkInfo(serviceOrderNumber: $serviceOrderNumber) {
      outOfNetworkInfoIdOriginal
      companyName
      nameCC
      providerAddress
      phoneNumber
      initialFee
      poc
      dispatchMethod
      emailFax
      partOrderNumber
      customerAddressVerified
      cCProvidedDate
      approvedAmount
      agentInitials
      serviceOrderNumber
      isDeleted
      insertUserName
    }
  }
`;

export const getOutOfNetworkInfoCountQuery = gql`
  query ($serviceOrderNumber: String) {
    getOutOfNetworkInfoCount(serviceOrderNumber: $serviceOrderNumber)
  }
`;

export const getDashboardRecordDetailsQuery = gql`
  query (
    $status: String!
    $offset: Int
    $limit: Int
    $duration: String
    $buyingGroup: String
    $dealer: String
    $servicer: String
  ) {
    getDashboardRecordDetails(
      status: $status
      offset: $offset
      limit: $limit
      duration: $duration
      buyingGroup: $buyingGroup
      dealer: $dealer
      servicer: $servicer
    ) {
      claimId
      claimNumber
      status
      contractId
      uniqueId
      invoiceNumber
      phoneNumber
      contractsIdOriginal
      serviceOrderNumber
      contractNumber
      claimPriorityCode
      servicer
      claimDate
      dateOfRepair
      submitForPaymentDate
      approvedForPaymentDate
      preApprovedDate
      contactFirstName
      contactLastName
      manufacturer
      modelNumber
      customerFeedback
      authorizationCode
      technicianName
      assignedTo
      claimPaymentDate
      createdBy
      dispatchDate
      customerId
      checkNumber
      checkAmount
      insuranceCarrier
    }
  }
`;
