import { Component, Input, OnInit } from '@angular/core';
import { BuyingGroupDocumentsTabService } from './document.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { buyingGroupDocumentEllipsisOptions } from '../constants/buying-group-ellipsis';
import { TabHandlerService } from '../../services/tab-handler.service';
import { BuyingGroupDataService } from '../buying-group.service';
import { ViewFile } from '../../shared/view-file/view-file.interface';
import { CrmDocumentType } from '../../shared/constants/enums';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'buying-document',
  templateUrl: 'document.template.html',
  styleUrls: ['document.scss'],
  providers: [BuyingGroupDocumentsTabService],
})
export class BuyingGroupDocumentsTabComponent implements OnInit {
  documentSearchParams: ViewFile;
  tabKey: string;
  editConstant: string;

  admin: any[];
  cols: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  options: any[] = buyingGroupDocumentEllipsisOptions;
  constructor(
    private tabHandlerService: TabHandlerService,
    private buyingGroupService: BuyingGroupDataService,
  ) {
    this.documentSearchParams = {
      fileName: '',
      attachmentsType: { key: 'Buying Group', value: CrmDocumentType.Agent },
      uploadedDate: null,
      offset: 0,
      limit: 20,
      identifierId: null,
    };
  }

  ngOnInit() {
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (
          this.tabKey === tab &&
          this.buyingGroupService.buyingGroupConfig &&
          this.buyingGroupService.buyingGroupConfig.searchParams.id
        ) {
          this.documentSearchParams.identifierId =
            this.buyingGroupService.buyingGroupConfig.searchParams.id;
        }
      },
    });
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'fileName', header: 'FILE NAME', type: 'text' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
      { field: 'uploadedDate', header: 'UPLOADED DATE', type: 'text' },
      { field: 'uploadedBy', header: 'UPLOADED BY', type: 'text' },
    ];
  }
  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
}
