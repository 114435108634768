import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs';
import { catchError } from 'rxjs';
import { takeUntil } from 'rxjs';
import { LanguageUtilsService } from './language-utils.service';
import { share } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StringService implements OnDestroy {
  public strings$: BehaviorSubject<{ [key: string]: string }> = new BehaviorSubject<{
    [key: string]: string;
  }>({});

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private http: HttpClient, // , private store: Store<IAppState>
  ) {
    const selectedLang = LanguageUtilsService.getSelectedLanguage();

    // After attempting to load a config file, load the strings and use the langs detected
    this.getStringFile(selectedLang)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((strings) => {
        this.setLanguage(selectedLang);
        this.strings$.next(strings);
      });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public setLanguage(lang: string): boolean {
    if (lang) {
      // this.store.dispatch({
      //   type: stringActions.SET_CURRENT_LANG,
      //   payload: lang,
      // });
      return true;
    }
    return false;
  }

  private getStringFile(selectedLang: string): Observable<any> {
    // Request the load of the strings.json file to get all the strings
    return this.http
      .get(`assets/languages/strings.${selectedLang}.json`, {
        observe: 'response',
      })
      .pipe(share())
      .pipe(
        map((resp: any) => {
          if (resp.status === 200) {
            return resp.body;
          } else {
            console.error('Failed to load the strings file.');
            return null;
          }
        }),
        catchError((error) => {
          console.error('Failed to load the strings file.', error);
          return of(null);
        }),
      );
  }
}
