import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';
import { Injectable } from '@angular/core';

const getFeatureQuery = gql`
  query($apiName: String!) {
    getApiConfigs(apiName: $apiName) {
      configType
      configKey
      configValue
    }
  }
`;

@Injectable()
export class ApiConfigService {
  private apiConfig = [];

  constructor(private apollo: Apollo) {}

  getApiConfigDetails = featureName =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getFeatureQuery,
      variables: {
        apiName: featureName
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getApiConfig(apiName: string) {
    return this.apiConfig[apiName];
  }

  addApiConfig(apiName: string, apiConfig = null) {
    this.apiConfig[apiName] = { ...apiConfig };
  }
}
