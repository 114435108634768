import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SubcategoryService } from './subcategory.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisCrudOptions } from '../../shared/constants/ellipsis-options';
import { subCategoryEllipsisHeaderOptions } from '../catalog.constant';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { FormField } from '../../shared/form-field/form-field';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { CategoryService } from '../category/category.service';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { RolePermissionService } from '../../common/role-permission.service';
@Component({
  selector: 'subcategory',
  templateUrl: 'subcategory.template.html',
  styleUrls: ['../catalog.scss'],
  providers: [
    SubcategoryService,
    CategoryService,
    ContractFormFieldService,
    MessageService,
    FormFieldControlService,
  ],
})
export class SubcategoryComponent implements OnInit, OnDestroy {
  dataList: any[] = [];
  cols: any[];
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = subCategoryEllipsisHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  header: string;
  tierList: any[];
  displayAdd: boolean;

  @Input() searchParams: any = {};
  @Input() formFields: FormField<string>[] = [];
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  form = new FormGroup({});
  querySubscription: Subscription;
  categorySubscription: Subscription;
  querySubscriptions: Subscription[] = [];
  isLoading = true;
  lastOffsetVal = 0;
  offsetLimit = 50;

  constructor(
    private subcategoryService: SubcategoryService,
    private categoryService: CategoryService,
    private contractService: ContractFormFieldService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formFieldService: FormFieldControlService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'code', header: 'SUBCATEGORY CODE', type: 'text' },
      { field: 'description', header: 'SUBCATEGORY DESCRIPTION', type: 'text' },
      { field: 'tier', header: 'TIER CODE', type: 'text' },
      { field: 'category', header: 'CATEGORY', type: 'text' },
    ];
    this.getSubcategorySearchData();
    this.querySubscriptions.push(
      this.contractService.getSubCategoryUpdateFields().subscribe((formModalInfo) => {
        this.formFields = formModalInfo;
        this.form = this.formFieldService.toFormGroup(this.formFields);
        this.querySubscriptions.push(
          this.form.valueChanges.subscribe((x) => {
            this.formValueChanged.emit(true);
          }),
        );
      }),
    );
    this.categorySubscription = this.categoryService
      .getCategories({})
      .subscribe(({ data, loading }: any) => {
        if (data.getCategorySearch) {
          this.tierList = data.getCategorySearch;
        }
      });
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Subcategories',
      category: 'Catalogs',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Subcategories',
      category: 'Catalogs',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getSubcategorySearchData(reload: boolean = false) {
    this.isLoading = true;
    if (this.searchParams === 'Subcategory') {
      this.searchParams = {};
    }
    const category = this.searchParams.category
      ? this.searchParams.category.map((x) => x.key).join()
      : null;

    if (reload) {
      this.lastOffsetVal = 0;
      this.dataList = [];
    }
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.searchParams.azureUserId = this.azureService.accountId;
    this.lastOffsetVal += this.offsetLimit;
    this.querySubscription = this.subcategoryService
      .getSubcategory({
        ...this.searchParams,
        category,
      })
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        data.getSubCategorySearch.map((subCategory) => {
          const dataObj = {
            categoryId: subCategory.categoryId,
            code: subCategory.code,
            description: subCategory.description,
            category: subCategory.parentDescription,
            tier: subCategory.tier,
          };
          if (this.dataList.length === 0) {
            this.dataList.push(dataObj);
          } else {
            const index = this.dataList.findIndex((e) => e.categoryId === subCategory.categoryId);
            if (index === -1) {
              this.dataList.push(dataObj);
            } else {
              this.dataList[index] = dataObj;
            }
          }
        });
      });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    if (event.value === 'delete') {
      this.deleteSubCategory();
    } else {
      this.updateSubCategory(event);
    }
  }

  updateSubCategory(event) {
    this.header = event.value === 'add' ? 'Create SubCategory' : 'Update SubCategory';
    this.isLoading = true;
    if (!this.tierList) {
      this.categorySubscription = this.categoryService
        .getCategories({})
        .subscribe(({ data, loading }: any) => {
          if (data.getCategorySearch) {
            this.tierList = data.getCategorySearch;
          }
        });
    }
    const tierOptions = this.tierList.map((cat) => ({
      key: cat.tierCode,
      value: cat.categoriesId,
    }));
    this.formFields[2].options = tierOptions;
    if (event.value === 'add') {
      this.selectedItem = null;
      this.form.reset();
    }
    else {
      const seletedTier = this.tierList.filter(
        (tier) => tier.tierCode === this.selectedItem?.tier,
      )[0];

      this.form.patchValue({
        code: this.selectedItem?.code,
        description: this.selectedItem?.description,
        tier: seletedTier ? { key: seletedTier?.tierCode, value: seletedTier?.categoriesId } : {},
        category: this.selectedItem?.category,
      });
    }

    this.displayAdd = true;
    this.isLoading = false;
  }

  changeEventDetection(event, key) {
    if (key === 'tier') {
      const selectedCategory: any = this.tierList.filter(
        (tier) => tier.tierCode === event.value.key,
      )[0];
      this.form
        .get('category')
        .setValue(selectedCategory[`category`] as never, { emitEvent: false }); // ###CHANGE###
    }
  }

  deleteSubCategory() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this Menu Type?`,
      accept: () => {
        const seletedTier = this.tierList
          ? this.tierList.filter((tier) => tier.tierCode === this.selectedItem.tier)[0]
          : null;
        const params = {
          subCategoriesIdOriginal: this.selectedItem.categoryId,
          code: this.selectedItem.code,
          description: this.selectedItem.description,
          tierCode: this.selectedItem.tier,
          tierCodeId: seletedTier ? seletedTier.categoriesId : null,
          insertUserName: this.azureService.accountId,
          isDeleted: true,
        };
        this.querySubscription = this.subcategoryService.createUpdateSubCategory(params).subscribe(
          (data) => {
            this.showToastStatus(true);
            this.getSubcategorySearchData(true);
          },
          () => {
            this.showToastStatus(false);
          },
        );
      },
    });
  }

  showToastStatus(success: boolean) {
    if (success) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Success.`,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Failed`,
      });
    }
  }

  onRowEdit(event) {
    this.updateSubCategory(event);
  }

  onSubmit() {
    this.isLoading = true;
    const payload: any = this.form.getRawValue();
    if (!this.validateData(payload)) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please fill the required fields.`,
      });
      return;
    }
    const params = {
      subCategoriesIdOriginal: this.selectedItem ? this.selectedItem.categoryId : null,
      code: payload.code,
      description: payload.description,
      tierCode: payload.tier.key,
      tierCodeId: payload.tier.value,
      insertUserName: this.azureService.accountId,
      isDeleted: false,
    };
    this.querySubscription = this.subcategoryService.createUpdateSubCategory(params).subscribe(
      ({ data }: any) => {
        if (data.createUpdateSubCategories) {
          this.showToastStatus(true);
          this.form.reset();
          this.displayAdd = false;
          this.getSubcategorySearchData(true);
        } else {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Entry Already Exists`,
          });
        }
      },
      () => {
        this.showToastStatus(false);
        this.isLoading = false;
      },
    );
  }

  onCancel() {
    this.form.reset();
    this.isLoading = false;
    this.displayAdd = false;
  }

  validateData(payload) {
    if (payload.tier.key && payload.code) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
