import { Injectable } from '@angular/core';
import { RolePermissionInterface } from 'src/app/interfaces/role-permission-directive.interface';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  appSetRolePermissionsDirective: RolePermissionInterface;
  hasAllPermission: boolean = false;
  isAlreadyDisplayed = false;
  ellipsisOptions: any[];

  constructor(private azureService: AzureLoginService) {}

  public evaluate(app: RolePermissionInterface, ellipsisOptions: any[]) {
    this.ellipsisOptions = ellipsisOptions && ellipsisOptions.length ? ellipsisOptions : [];
    this.appSetRolePermissionsDirective = app;
    if (this.appSetRolePermissionsDirective.operation === 'MULTIPLE') {
      this.appSetRolePermissionsDirective.data.forEach((item) => {
        const ocur = this.azureService.rolePermissions.filter(
          (e) =>
            e.permissionDescription === item.permission && e.permissionCategory === item.category,
        );
        this.hasAllPermission = false;
        this.setPermissions(ocur, item);
      });
    } else {
      const ocur = this.azureService.rolePermissions.filter(
        (e) =>
          e.permissionDescription === this.appSetRolePermissionsDirective.permission &&
          e.permissionCategory === this.appSetRolePermissionsDirective.category,
      );
      this.setPermissions(ocur, this.appSetRolePermissionsDirective);
    }
    return [...this.ellipsisOptions];
  }
  setPermissions(permissions, permissionItem) {
    if (
      permissions.findIndex((e) => e.operationName === 'ALL') !== -1 ||
      permissionItem.category === undefined
    ) {
      this.hasAllPermission = true;
    }
    /* logic for ALL" */
    switch (permissionItem.operation) {
      case 'ADD':
        this.evaluateAdd(permissions, permissionItem);
        break;
      case 'READ_ONLY':
        this.evaluateReadOnly(permissions);
        break;
      case 'EDIT-DELETE':
        this.evaluateEditDelete(permissions);
        break;
      case 'READ-DELETE':
        this.evaluateReadDelete(permissions);
        break;
      case 'DELETE':
        this.evaluateDelete(permissions, permissionItem);
        break;
      case 'PRINT':
        this.evaluateEllipsisCustom(permissions, 'READ_ONLY', 'print', false);
        break;
      case 'CANCEL':
        this.evaluateEllipsisCustom(permissions, 'EDIT', 'cancel', true);
        break;
      case 'EXPORT':
        this.evaluateEllipsisCustom(permissions, 'EDIT', 'export', false);
        break;
      default:
        this.evaluateEllipsisCustom(permissions, 'EDIT', permissionItem.operation, false);
        break;
    }
  }
  /**
   * This Method evaluates when the permissions is for add in case for a button or three dots.
   * remove the element in case the permission is missing or
   * if the three dots has more options only remove the add one
   * @param permissions permissions filtered by category and description
   */
  evaluateAdd(permissions, permissionItem) {
    const canAdd =
      permissions.findIndex((e) => e.operationName === 'ADD') !== -1 || this.hasAllPermission;
    if (permissionItem.type === 'button') {
      if (canAdd) {
        return true;
      }
    } else if (permissionItem.type === 'optionRow') {
      if (!canAdd) {
        if (this.ellipsisOptions.length > 1) {
          const index = this.ellipsisOptions.findIndex((e) =>
            e.label.toLowerCase().includes('create'),
          );
          if (index !== -1) {
            this.ellipsisOptions.splice(index, 1);
          }
        }
      }
    } else {
      if (!canAdd) {
        if (this.ellipsisOptions.length > 1) {
          let add = true;
          while (add) {
            const index = this.ellipsisOptions.findIndex((e) =>
              e.label.toLowerCase().includes('add'),
            );
            if (index !== -1) {
              this.ellipsisOptions.splice(index, 1);
            } else {
              add = false;
            }
          }
        }
      }
    }
  }

  /**
   * This Method evaluates when the permissions are for Edit or deletes,
   * it validates if has both permissions before altering the dom
   * if the permissions are there don't alter the dom but in case one of the permissions is missing
   * it removes in the ellipsis the corresponding element
   * @param permissions permissions filtered
   */
  evaluateEditDelete(permissions) {
    const canDelete =
      permissions.findIndex((e) => e.operationName === 'DELETE') !== -1 || this.hasAllPermission;
    const canEdit =
      permissions.findIndex((e) => e.operationName === 'EDIT') !== -1 || this.hasAllPermission;
    if (!canDelete || !canEdit) {
      if (!canEdit) {
        const idEdit =
          this.ellipsisOptions &&
          this.ellipsisOptions.findIndex(
            (e) =>
              e.value.toUpperCase().includes('EDIT') || e.value.toUpperCase().includes('UPDATE'),
          );
        if (idEdit !== -1) {
         this.ellipsisOptions.splice(idEdit, 1);
        }
      }
      if (!canDelete) {
        const idDelete = this.ellipsisOptions?.findIndex(
          (e) =>
            e.value.toUpperCase().includes('DELETE') || e.value.toUpperCase().includes('REMOVE'),
        );
        if (idDelete !== -1) {
          this.ellipsisOptions.splice(idDelete, 1);
        }
      }
    }
  }
  /**
   * This Method evaluates when the permissions are for read or deletes,
   * it validates if has both permissions before altering the dom
   * if the permissionsare there don't alter the dom but in case one of the permissions is missing
   * it removes in the ellipsis the corresponding element
   * @param permissions permissions filtered by category and description
   */
  evaluateReadDelete(permissions) {
    const canRead =
      permissions.findIndex((e) => e.operationName === 'READ_ONLY') !== -1 || this.hasAllPermission;
    const canDelete =
      permissions.findIndex((e) => e.operationName === 'DELETE') !== -1 || this.hasAllPermission;
    if (!canDelete || !canRead) {
      if (!canRead) {
        const idEdit =
          this.ellipsisOptions &&
          this.ellipsisOptions.findIndex((e) => e.label.toUpperCase().includes('VIEW'));
        if (idEdit !== -1) {
          this.ellipsisOptions.splice(idEdit, 1);
        }
      }
      if (!canDelete) {
        const idDelete =
          this.ellipsisOptions &&
          this.ellipsisOptions.findIndex((e) => e.label.toUpperCase().includes('DELETE'));
        if (idDelete !== -1) {
          this.ellipsisOptions.splice(idDelete, 1);
        }
      }
    }
    return true;
  }
  /**
   * This Method evaluates when the permissions are for reading only
   * if the permission exists displayed in dom else remove the item
   * @param permissions permissions filtered by category and description
   */
  evaluateReadOnly(permissions) {
    const hasPermissions = permissions.findIndex((e) => e.operationName === 'READ_ONLY') !== -1;
    // if (hasPermissions || this.hasAllPermission) {
    // }
  }
  /**
   * For the items who only contains delete operation hide if is missing the permission
   * else display the element
   * @param permissions permissions filtered by category and description
   */
  evaluateDelete(permissions, permissionItem) {
    const canDelete =
      permissions.findIndex((e) => e.operationName === 'DELETE') !== -1 || this.hasAllPermission;
    if (permissionItem.type === 'three-dots') {
      if (!canDelete) {
        const idDelete = this.ellipsisOptions.findIndex(
          (e) =>
            e.label.toUpperCase().includes('DELETE') ||
            e.label.toUpperCase().includes('UN-ASSOCIATE'),
        );
        if (idDelete !== -1) {
          this.ellipsisOptions.splice(idDelete, 1);
        }
      }
    }
  }
  /**
   * Single case for export is included if the permissions exist keep the custom element else remove it
   * @param permissions permissions filtered by category and description
   */
  evaluateEllipsisCustom(permissions, operationToDo, ellipsisCase, isHeader) {
    const canDisplay =
      permissions.findIndex((e) => e.operationName === operationToDo) !== -1 ||
      this.hasAllPermission;
    if (isHeader) {
      if (canDisplay) {
        return true;
      }
      if (this.ellipsisOptions && this.ellipsisOptions.length > 1) {
        const index =
          this.ellipsisOptions &&
          this.ellipsisOptions.findIndex((e) => e.label.toLowerCase().includes(ellipsisCase));
        if (index !== -1) {
          this.ellipsisOptions.splice(index, 1);
        }
      }
    } else {
      if (canDisplay) {
        return true;
      }
      const idDisplay =
        this.ellipsisOptions &&
        this.ellipsisOptions.findIndex((e) =>
          e.value.toLowerCase().includes(ellipsisCase.toLowerCase()),
        );
      if (idDisplay !== -1 && this.ellipsisOptions) {
        this.ellipsisOptions.splice(idDisplay, 1);
      }
      if (this.ellipsisOptions && this.ellipsisOptions.length > 0) {
        return true;
      }
    }
  }
  getEllipseOptions() {
    return this.ellipsisOptions;
  }
}
