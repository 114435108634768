import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisHeaderAddOptions,
  ellipsisCrudOptions,
} from '../../shared/constants/ellipsis-options';
import { DealerService } from '../dealer.service';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { Subscription } from 'rxjs';
import { validateEmail } from '../../shared/utilities/common-utilities';
import { DealerContactService } from './dealer-contact.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import { ConfigurationTypeService } from '../../administration/configuration/configuration.service';
import { RolePermissionService } from '../../common/role-permission.service';
@Component({
  selector: 'app-delear-contact',
  templateUrl: './dealer-contact.component.html',
  styleUrls: ['dealer-contact.scss'],
  providers: [DealerContactService, MessageService],
})
export class DealerContactComponent implements OnInit, OnDestroy {
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  dataList: any[] = [];
  isLoading: boolean;
  selectedItem: any;
  selectedRowIndex: any;
  selectedColumns: any[];
  editConstant: any;
  isEditing: boolean;
  clonedRowData: KeyMap = {};
  lastMaxId = 1;
  cols: any[];
  ellipsisHeaderOptions: any[] = ellipsisHeaderAddOptions;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  querySubscriptions: Subscription[] = [];
  tabKey: any;
  dropdown: any;

  constructor(
    private dealerService: DealerService,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private dealerContactService: DealerContactService,
    private messageService: MessageService,
    private configurationService: ConfigurationTypeService,
    private tabHandlerService: TabHandlerService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab && this.dealerService && this.dealerService.dealerFormData) {
          this.getDealerContacts();
        }
      },
    });

    this.dropdown = {
      position: [],
    };
    this.configurationService
      .getAdminConfigMenu({
        menuType: 'position',
        module: 'dealer',
      })
      .subscribe(({ data }: any) => {
        const configList = data.getAdminConfigMenuSearchList;
        const positionOptions = configList
          .filter((el) => el.module === 'dealer')
          .map((e) => {
            return {
              label: e.key,
              value: e.value,
            };
          });
        this.dropdown.position = [
          ...positionOptions.filter((e) => e.label === null),
          ...positionOptions.filter((e) => e.label !== null),
        ];
        this.formValueChanged.emit(true);
      });
    this.cols = [
      { field: 'firstName', header: 'FIRST NAME', type: 'text' },
      { field: 'lastName', header: 'LAST NAME', type: 'text' },
      { field: 'position', header: 'POSITION', type: 'select' },
      { field: 'email', header: 'EMAIL', type: 'text' },
      { field: 'phoneNumber1', header: 'PHONE NUMBER', type: 'text' },
      { field: 'cellphone', header: 'CELLPHONE', type: 'text' },
      { field: 'businessPhone', header: 'BUSINESS PHONE', type: 'text' },
      { field: 'fax', header: 'FAX', type: 'text' },
    ];

    this.selectedColumns = this.cols.slice(0, 4);
    this.doEvaluateRolePermissions();
  }

  private doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Contacts',
      category: 'Seller',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );

    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Contacts',
      category: 'Seller',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisOptionClick(event) {
    //
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  onClickAdd(event) {
    let rowItem = {
      id: null,
      firstName: null,
      lastName: null,
      contactType: null,
      position: null,
      phoneNumber1: null,
      cellphone: null,
      businessPhone: null,
      email: null,
      fax: null,
    };
    rowItem.id = this.dataList.length + 1;
    rowItem[`isNew`] = true;
    rowItem = { ...rowItem };
    this.dataList.push(rowItem);
    this.cdr.detectChanges();
    this.selectedItem = rowItem;
    this.selectedRowIndex = this.dataList.length - 1;
    this.onRowEdit();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }
  onRowEditSave(rowData: any) {
    if (rowData.position && rowData.email) {
      if (validateEmail(rowData.email)) {
        delete this.clonedRowData[rowData.id];
        this.isEditing = false;
        this.saveDealerContact(rowData);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Please enter valid email.`,
        });
        document.getElementById(this.editConstant + this.selectedItem.id).click();
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Please add values for position and email.`,
      });
      document.getElementById(this.editConstant + this.selectedItem.id).click();
    }
  }

  onRowEditCancel(rowData: any, index: number) {
    if (rowData[`isNew`]) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to discard, these changes?',
        accept: () => {
          this.dataList[index] = this.clonedRowData[rowData.id];
          delete this.clonedRowData[rowData.id];
          this.isEditing = false;
          this.dataList.splice(this.selectedRowIndex, 1);
        },
      });
    } else {
      this.dataList[index] = this.clonedRowData[rowData.id];
      delete this.clonedRowData[rowData.id];
      this.isEditing = false;
    }
  }

  setDataId(data) {
    this.dataList = data.map((d) => {
      d.id = this.lastMaxId++;
      return { ...d };
    });
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        const rowData = { ...this.dataList[this.selectedRowIndex], isDeleted: true };
        this.saveDealerContact(rowData);
        // this.dataList.splice(this.selectedRowIndex, 1);
      },
    });
  }

  saveDealerContact(rowData: any) {
    const dealerGroupsIdOriginal = this.dealerService.dealerGroupsIdOriginal;
    if (dealerGroupsIdOriginal) {
      const payload = {
        categoryType: 'DealersGroup',
        contactPosition: rowData.position,
        businessPhone: rowData.businessPhone,
        firstName: rowData.firstName,
        lastName: rowData.lastName,
        fax: rowData.fax,
        email: rowData.email,
        phoneNumber1: rowData.phoneNumber1,
        dealerGroupId: dealerGroupsIdOriginal,
        isDeleted: rowData.isDeleted,
        contactIdOriginal: rowData.contactIdOriginal || null,
        cellPhone: rowData.cellphone,
      };
      this.isLoading = true;
      this.dealerContactService
        .createUpdateServicerContact(payload)
        .subscribe(({ data, loading }: any) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Record(s) Saved Successfully.`,
          });
          this.getDealerContacts();
        });
    }
  }

  getDealerContacts() {
    this.isLoading = true;
    const dealerGroupId = parseInt(this.dealerService.dealerGroupsIdOriginal, 10);
    if (dealerGroupId) {
      this.querySubscriptions.push(
        this.dealerService.getDealerGroupContactInfo({ dealerGroupId }).subscribe(
          ({ data, errors }) => {
            if (!errors) {
              const { getDealerGroupContactInfo } = data;
              const dataTable = [];
              if (getDealerGroupContactInfo && getDealerGroupContactInfo.length) {
                getDealerGroupContactInfo.forEach((contact, index) => {
                  dataTable.push({
                    ...contact,
                    id: index,
                  });
                });
              }
              this.dataList = dataTable;
            }
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        ),
      );
    }
  }
}
