import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { SecurityTypeService } from '../../../security/security.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../../../security/security-ellipsis';
import { RoleAuthorizationService } from '../../../../../../services/role-authorization.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../../../../interfaces/role-authorization.interface';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../../../services/contract-form-field.service';
import { Subscription } from 'rxjs';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';

@Component({
  selector: 'security-operation-option',
  templateUrl: 'operation.template.html',
  providers: [SecurityTypeService, ContractFormFieldService],
})
export class PermissionOperationComponent extends FormCanDeactivate implements OnInit {
  @Input() searchParams: any;
  cols: any[];
  dataList: any[];
  header: string;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayUpdate: boolean;
  formModalInfo: FormField<any>[] = [];
  querySubscriptionRoles: Subscription;
  isLoading = true;
  constructor(
    private configurationService: SecurityTypeService,
    private cdr: ChangeDetectorRef,
    private contractService: ContractFormFieldService,
    private roleService: RoleAuthorizationService,
  ) {
    super();
    this.displayAdd = false;
    this.displayUpdate = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'operation_id', header: 'OPERATION_ID', type: 'text' },
      { field: 'operation', header: 'OPERATION', type: 'text' },
    ];
    const tableValues = [];
    this.querySubscriptionRoles = this.roleService.getOperationDetails().subscribe(
      ({ data, loading }: any) => {
        this.isLoading = loading;
        for (const singleOperation of data.getAdminDisplayPermissions) {
          tableValues.push({
            operation_id: singleOperation.operationId,
            operation: singleOperation.operationName,
          });
          this.dataList = tableValues;
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );
    this.contractService
      .getSecFormModalFields()
      .subscribe((formModalInfo) => (this.formModalInfo = formModalInfo));
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }
  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e) {
    console.log(e);
    if (e.value === 'Add New User') {
      this.displayAdd = true;
      this.header = 'Add New User';
    } else {
      this.displayUpdate = true;
      this.header = 'Update User';
    }
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayUpdate = false;
    this.cdr.detectChanges();
  }
}
