import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisModificationOptions,
  ellipsisHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ServiceType } from '../../../crm-coverage/coverage-models';
import { buttonStatus } from '../../../shared/constants/button-class';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { ServicerService } from '../servicer.service';
import { TabHandlerService } from '../../../services/tab-handler.service';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { Subscription } from 'rxjs';
import { getDateFromString } from '../../../shared/utilities/date-utilities';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { copy } from '../../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../../common/role-permission.service';

@Component({
  selector: 'servicer-service-tab',
  templateUrl: 'servicer-service-type.template.html',
  styleUrls: ['servicer-service-type.scss'],
  providers: [MessageService],
})
export class ServicerServiceTypeComponent implements OnInit, OnDestroy {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisModificationOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  selectedRowIndex: number;
  selectedColumns: any[];
  primaryAddress: string;
  dropdown: any;
  tabKey: string;
  disableLoadMore: boolean;
  lastOffsetVal = 0;
  offsetLimit = 10;
  isTableLoading: boolean;
  querySubscriptions: Subscription[] = [];
  currentMaxId = 0;
  defaultOption = { label: 'ALL', value: 'ALL' };
  displayServiceInfoModal = false;
  selectedRow: any;
  isLoading = false;

  constructor(
    private servicerTypeService: ServicerService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private tabHandlerService: TabHandlerService,
    private messageService: MessageService,
    private dropdownDataService: DropdownDataService,
    private azureService: AzureLoginService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'categoryDescription', header: 'CATEGORY', type: 'text' },
      { field: 'subcategoryDescription', header: 'SUB-CATEGORY', type: 'text' },
      { field: 'tierDescription', header: 'TIER', type: 'text' },
      { field: 'type', header: 'SERVICE TYPE', type: 'text' },
      { field: 'manufacturerName', header: 'MANUFACTURER', type: 'text' },
      { field: 'modelName', header: 'MODEL', type: 'text' },
      { field: 'coverageCode', header: 'SKU', type: 'text' },
      { field: 'beginDate', header: 'BEGIN DATE', type: 'date' },
      { field: 'endDate', header: 'END DATE', type: 'date' },
      { field: 'isActive', header: 'ACTIVE', type: 'checkbox' },
      { field: 'isMFGAuthorized', header: 'MFG AUTHORIZED', type: 'checkbox' },
    ];
    this.editConstant = uuidv4();
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          this.loadServiceType();
        }
      },
    });
    this.dataList = [];
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Service Types',
      category: 'Servicer',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Service Types',
      category: 'Servicer',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  loadServiceType() {
    if (this.servicerTypeService && this.servicerTypeService.serviceFormData) {
      this.isLoading = true
      // reset defaults in case of a reload/refresh
      this.currentMaxId = 0;
      this.lastOffsetVal = 0;
      this.offsetLimit = 10;

      let firstCall = true;
      this.querySubscriptions.push(
        this.servicerTypeService
          .getServicerServiceTypes(this.servicerTypeService.serviceFormData.servicerNumber)
          .subscribe(({ data, loading }: any) => {
            if (firstCall) {
              this.isLoading = false;
              const modiftData = copy(data.getServicerServiceTypesByNumber);
              this.dataList = this.populateRowId(modiftData);
              this.disableLoadMore =
                Boolean(
                  data.getServicerServiceTypesByNumber &&
                    data.getServicerServiceTypesByNumber.length < this.offsetLimit,
                ) || Boolean(!data.getServicerServiceTypesByNumber);
            }
            firstCall = false;
          }),
      );
    }
  }

  onCreateUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
    }
    this.loadServiceType();
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEditCancel(event, index) {}

  onRowEdit(event) {
    this.isEditing = true;
    if(event.value === 'add'){
      this.selectedRowIndex = undefined;
    }
    this.selectedRow = this.dataList[this.selectedRowIndex];
    this.displayServiceInfoModal = true;
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any, isDelete: boolean = false) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    rowData.insertUserName = this.azureService.accountId;
    if (rowData && rowData.manufacturerName) {
      rowData.manufacturerName = rowData.manufacturerName.replace(/^\s+|\s+$/g, '');
    }
    this.isTableLoading = true;

    if (isDelete) {
      rowData.isDeleted = true;
    }
    this.querySubscriptions.push(
      this.servicerTypeService.createUpdateServicerTypes(rowData).subscribe(
        ({ data, loading }: any) => {
          let successMessage = 'Saved successfully.';
          if (isDelete) {
            this.dataList.splice(this.selectedRowIndex, 1);
            successMessage = 'Deleted successfully.';
          } else {
            this.dataList[this.selectedRowIndex][`serviceTypesIdOriginal`] =
              data[`createUpdateServicerTypes`][`serviceTypesIdOriginal`];

            this.dataList[this.selectedRowIndex][`isNew`] = false;
          }

          this.isTableLoading = false;

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: successMessage,
          });
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error while saving, please try again.',
          });
        },
      ),
    );
  }

  onClickAdd(event) {
    this.onRowEdit(event);
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      accept: () => {
        this.onRowEditSave(this.selectedItem, true);
      },
    });
  }

  loadTableData() {
    this.isTableLoading = true;
    this.lastOffsetVal += this.offsetLimit;

    if (
      this.servicerTypeService.serviceFormData &&
      this.servicerTypeService.serviceFormData.servicerNumber
    ) {
      this.querySubscriptions.push(
        this.servicerTypeService
          .getServicerServiceTypes(
            this.servicerTypeService.serviceFormData.servicerNumber,
            this.lastOffsetVal,
            this.offsetLimit,
          )
          .subscribe(({ data, loading }: any) => {
            this.isTableLoading = false;
            this.disableLoadMore =
              Boolean(
                data.getServicerServiceTypesByNumber &&
                  data.getServicerServiceTypesByNumber.length < this.offsetLimit,
              ) || Boolean(!data.getServicerServiceTypesByNumber);

            this.dataList = this.dataList.concat(
              this.populateRowId(data.getServicerServiceTypesByNumber),
            );
          }),
      );
    }
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  private populateRowId(modelData: any) {
    const updatedData = modelData.map((data) => ({
      ...data,
      servicersId: this.servicerTypeService.serviceFormData.servicersId,
      id: this.currentMaxId = this.currentMaxId + 1,
      beginDate: getDateFromString(data.beginDate),
      endDate: getDateFromString(data.endDate)
    }));
    return updatedData;
  }
}
