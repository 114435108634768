import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisOptions } from '../../shared/constants/ellipsis-options';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../shared/form-field/form-field';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { InsuranceExtractService } from './insurance-extract.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ViewFileService } from '../../shared/view-file/view-file.service';
import { base64ToArrayBuffer, getFileExtension } from '../../shared/utilities/common-utilities';
import { switchMap, takeWhile } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'insurance-extract',
  templateUrl: 'insurance-extract.template.html',
  styleUrls: ['insurance-extract.scss'],
  providers: [InsuranceExtractService, FormFieldControlService, MessageService, ViewFileService],
})
export class InsuranceExtractComponent implements OnInit, OnDestroy {
  public uploadSubscription: Subscription;
  private fileStatus: number;
  selectedItem: any;
  btnStatus: any = buttonStatus;
  options: any[] = ellipsisOptions;
  selectedEllipsisItem: any;
  querySubscriptions: Subscription[] = [];
  formFields: FormField<string>[] = [];
  form: FormGroup;
  isLoading = false;
  daysBack: 1;
  exportRequestsId = null;
  isGenerateButtonDisabled = true;
  uploadStartTime: any;
  CHECK_FILE_STATUS_TIMER = 6; // in Seconds
  MAX_TIMEOUT = 15 * 60; // Timeout in Seconds
  constructor(
    private qcs: FormFieldControlService,
    private messageService: MessageService,
    private insuranceService: InsuranceExtractService,
    private azureService: AzureLoginService,
    private viewFileService: ViewFileService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit() {
    this.options = [
      { label: 'Add', value: 'add' },
      { label: 'Update', value: 'update' },
    ];
    this.querySubscriptions.push(
      this.insuranceService.getFormFields().subscribe((data: FormField<string>[]) => {
        this.formFields = data;
      }),
    );
    this.form = this.qcs.toFormGroup(this.formFields);

    this.getFiles();
    this.form.valueChanges.subscribe((x) => {
      const currentDaysBack = x.daysToRetrieve.value;
      if (currentDaysBack !== this.daysBack) {
        this.daysBack = currentDaysBack ? currentDaysBack : this.daysBack;
        this.getFiles();
      }
    });
  }

  getFiles() {
    this.daysBack = this.daysBack ? this.daysBack : 1;
    this.isLoading = true;
    this.querySubscriptions.push(
      this.insuranceService.getSalesAndCancelFileList(this.daysBack).subscribe(
        ({ data, loading }: any) => {
          this.isLoading = false;
          if (data && data.getSalesAndCancelFilesList) {
            const response = data.getSalesAndCancelFilesList;
            const newOptions = [];
            response.map((fileDetails) => {
              newOptions.push({
                key: `${fileDetails.fileType} | ${fileDetails.fileName}`,
                value: fileDetails.fileType === 'S' ? fileDetails.s3FileName : fileDetails.fileName,
              });
            });
            const newFormFields: FormField<any>[] = [];
            for (const singleForm of this.formFields) {
              const singleFormField: FormField<any> = singleForm;
              if (singleFormField.key === 'salesFiles') {
                singleFormField.options = newOptions;
              }
              if (singleFormField.key === 'daysToRetrieve') {
                singleFormField.value = { key: this.daysBack.toString(), value: this.daysBack };
              }
              newFormFields.push(singleFormField);
            }
            this.formFields = newFormFields;
            this.form = this.qcs.toFormGroup(this.formFields);
            this.form.valueChanges.subscribe((x) => {
              const currentDaysBack = x.daysToRetrieve.value;
              if (currentDaysBack !== this.daysBack) {
                this.daysBack = currentDaysBack;
                this.getFiles();
              }
              const { salesFileList, cancelFileList } = this.getFileLists();
              if (
                (salesFileList && salesFileList !== '' && salesFileList !== `''`) ||
                (cancelFileList && cancelFileList !== '' && cancelFileList !== `''`)
              ) {
                this.isGenerateButtonDisabled = false;
              } else {
                this.isGenerateButtonDisabled = true;
              }
            });
          }
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to retrieving restatus`,
          });
        },
      ),
    );
  }

  selectItem(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }
  handleClose(event) {
    // TODO: Close event
  }
  handleChange(event) {
    // TODO: Change event
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  onSubmit() {
    const { salesFileList, cancelFileList } = this.getFileLists();
    const requestPayload = {
      exportRequestsType: 'SalesExtract',
      insertUserName: this.azureService.accountId,
      requestInfo: JSON.stringify({
        cancelFileList,
        salesFileList,
      }),
    };
    this.isLoading = true;
    this.querySubscriptions.push(
      this.insuranceService.addExportRequest(requestPayload).subscribe(
        ({ data }) => {
          if (data && data.addExportRequest) {
            const exportRequestsIdOriginal = data.addExportRequest.exportRequestsIdOriginal;
            this.exportRequestsId = data.addExportRequest.exportRequestsIdOriginal;
            this.onFileStatusCheck();
          }
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error adding file to export process.',
          });
          this.isLoading = false;
          throw err;
        },
      ),
    );
  }

  getFileLists() {
    const payload = this.form.getRawValue();
    let salesFileList = '';
    let cancelFileList = '';
    if (payload.salesFiles) {
      const cancelFileListArray = payload.salesFiles
        .map((file) => {
          if (file.key.indexOf('C |') === 0) {
            return `'${file.value}'`;
          }
          return null;
        })
        .filter((value) => value);
      const salesFileListArray = payload.salesFiles
        .map((file) => {
          if (file.key.indexOf('S |') === 0) {
            return `'${file.value}'`;
          }
          return null;
        })
        .filter((value) => value);
      salesFileList = salesFileListArray.join(',');
      cancelFileList = cancelFileListArray.join(',');
    }
    salesFileList = salesFileList === '' ? (salesFileList = `''`) : salesFileList;
    cancelFileList = cancelFileList === '' ? (cancelFileList = `''`) : cancelFileList;
    return { salesFileList, cancelFileList };
  }

  onFileStatusCheck() {
    this.isLoading = true;
    if (this.exportRequestsId) {
      this.uploadStartTime = moment();
      this.uploadSubscription = timer(0, this.CHECK_FILE_STATUS_TIMER * 1000)
        .pipe(
          switchMap(() => this.insuranceService.getExportStatus(this.exportRequestsId)),
          takeWhile(() => {
            const res = this.checkMaxTimeOut() && (!this.fileStatus || this.checkFileStatusCall());
            return res;
          }),
        )
        .subscribe(
          ({ data }) => {
            const results = data.getExportStatus;
            this.fileStatus = results.requestStatus;
            if (results.requestStatus === 1) {
              this.isLoading = false;
              const preSignedUrl = results.fileName;
              this.createFile(preSignedUrl);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'File is generated successfully.',
              });
            } else if (results.requestStatus === 2 || results.requestStatus === 3) {
              this.isLoading = false;
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error generating file.',
              });
            }
          },
          (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error retrieving file generation status.',
            });
            this.isLoading = false;
            throw err;
          },
        );
    }
  }

  createFile(preSignedUrl) {
    this.viewFileService.getFile(preSignedUrl).subscribe(
      (response) => {
        const momentDate = moment(new Date());
        const momentDateStr = momentDate.format('MMM D YYYY hhmmssa');
        const fileName = decodeURIComponent(`${momentDateStr} Insurance Extract.csv`);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.download = fileName;
        link.click();
        this.isLoading = false;
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error generating file.',
        });
        this.isLoading = false;
      },
    );
  }

  checkMaxTimeOut() {
    const timeDiffSec = moment.duration(moment().diff(this.uploadStartTime)).asSeconds();
    const diffRes = timeDiffSec < this.MAX_TIMEOUT;
    const errMsg =
      'File is still being generated. If you have multiple files, try less files and regenerate.';
    if (!diffRes) {
      this.showConfirmationMsg('File Generate Status', errMsg);
      this.isLoading = false;
    }
    return diffRes;
  }

  showConfirmationMsg(header, message) {
    this.confirmationService.confirm({
      header,
      message,
      rejectVisible: false,
      acceptLabel: 'OK',
    });
    this.isLoading = false;
  }

  checkFileStatusCall() {
    if (this.fileStatus === 1) {
      return false;
    }
    if (this.fileStatus === 2 || this.fileStatus === 3) {
      return false;
    }
    return true;
  }
}
