import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { FormField } from '../../shared/form-field/form-field';
import { DealerFormFieldService } from '../../services/dealer-form-field.service';
import { DealerService } from '../dealer.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'dealer-profile-tab',
  templateUrl: 'dealer-profile-tab.template.html',
  styleUrls: ['dealer-profile-tab.scss'],
})
export class DealerProfileTabComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  contactInfo: FormField<any>[] = [];
  generalInfo: FormField<any>[] = [];
  mailAddressInfo: any[];
  billingAddressInfo: any[];
  genericFormData: any;
  contactFormData: any;
  querySubscriptions: Subscription[] = [];
  constructor(
    private dealerService: DealerFormFieldService,
    private roleService: RoleAuthorizationService,
    public dealerDataService: DealerService,
  ) {
    this.querySubscriptions.push(
      this.dealerService.getContactInformationFields().subscribe((data) => {
        this.contactInfo = data;
      }),
    );
    this.querySubscriptions.push(
      this.dealerService.getGeneralInfoFields().subscribe((data) => {
        this.generalInfo = data;
      }),
    );

    this.searchParams =
      this.dealerDataService.dealerConfig && this.dealerDataService.dealerConfig.searchParams;
  }

  ngOnInit() {
    this.dealerDataService.billingAddressFinal = { ...this.dealerDataService.billingAddress };
    this.dealerDataService.mailingAddressFinal = { ...this.dealerDataService.mailingAddress };
    this.genericFormData = { ...this.dealerDataService.generalFormData };
    this.contactFormData = { ...this.dealerDataService.contactFormData };
    this.contactFormData.businessPhoneTypeCode = this.contactFormData.businessPhoneTypeCode
      ? {
          key: this.contactFormData.businessPhoneTypeCode,
          value: this.contactFormData.businessPhoneTypeCode,
        }
      : null;
  }
  // onBillingAddressChange(data) {
  //   this.dealerDataService.billingAddressFinal = { ...data };
  // }
  // onMailAddressChange(data) {
  //   this.dealerDataService.mailingAddressFinal = { ...data };
  // }

  onGeneralInfoChange(data) {
    this.dealerDataService.generalFormDataFinal = { ...data };
  }
  onContactInfoChange(data) {
    this.dealerDataService.contactFormDataFinal = { ...data };
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
