export const coverageTabEllipsisHeaderOptions = [
  { label: 'Add Covered Item', value: 'add', callback: 'onClickAdd' },
  { label: 'Multiple Entry', value: 'addMultiple', callback: 'onClickMultipleAdd' }
];

export const exceptionsEllipsisHeaderOptions = [
  { label: 'Add', value: 'add', callback: 'onClickAdd' }
];

export const adminEllipsisHeaderOptions = [
  { label: 'Add New Rate Bucket', value: 'add', callback: 'onClickAdd' }
];
export const ellipsisOptions = [
  { label: 'Update Rate Bucket Row', value: 'update Rate Bucket Row', callback: 'onRowUpdate' },
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' }
];

export const modalEllipsisHeaderOptions = [
  { label: 'Edit', value: 'edit', callback: 'onClickEdit' }
];
