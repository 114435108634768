<div class="spinner" *ngIf="isLoading"><p-progressSpinner></p-progressSpinner></div>
<div class="crm-panel" *ngIf="!isLoading">
    <div class="crm-top">
        <div class="crm-panel__left">
            <payee-main-form (formValueChanged)="setChangeData($event)">
            </payee-main-form>
        </div>
        <div class="crm-panel__main"><navigation-panel [navigationPanel]="navigationPanel"></navigation-panel></div>
    </div>
    <div class="crm-bottom" *ngIf="componentDisplay"><crm-notes [parentComponent]="component"></crm-notes></div>
</div>