<app-tab-spinner *ngIf="isLoading" />
<div id="config-main-layout">
  <div id="config-layout-nav">
    <kairos-nav-bar *ngIf="!isLoading"></kairos-nav-bar>
  </div>
  <div id="config-layout-content">
    <crm-sidebar *ngIf="!isLoading"> </crm-sidebar>
    <div class="crm-container" *ngIf="!isLoading">
      <p-tabView *ngIf="tabs.length" [controlClose]="true" (onClose)="handleClose($event)"
        (onChange)="handleChange($event)">
        <p-tabPanel [header]="item.header" *ngFor="let item of tabs; let i = index" [selected]="item.selected"
          [ngSwitch]="item.type" [closable]="true">
          <admin-sec-user *ngSwitchCase="'Users'" [searchParams]="item.content"> </admin-sec-user>
          <user-registration *ngSwitchCase="'User Registration'"> </user-registration>
          <work-queue *ngSwitchCase="'My Work Queue'" [searchParams]="item.content"> </work-queue>
          <inquiry-queue *ngSwitchCase="'My Inquiry Queue'" [searchParams]="item.content">
          </inquiry-queue>
          <batch-processing *ngSwitchCase="'Batch Processing'" [searchParams]="item.content">
          </batch-processing>
          <transaction-management *ngSwitchCase="'Transaction Management'" [searchParams]="item.content">
          </transaction-management>
          <dealer *ngSwitchCase="'Dealer'" (formValueChanged)="setChangeData(true)" [searchParams]="item.content">
          </dealer>
          <payee *ngSwitchCase="'Payee'" (formValueChanged)="setChangeData(true)" [searchParams]="item.content">
          </payee>
          <dealer-location *ngSwitchCase="'Dealer Location'" (formValueChanged)="setChangeData(true)"
            [searchParams]="item.content">
          </dealer-location>
          <contracts *ngSwitchCase="'Contract'" (formValueChanged)="setChangeData(true)" [searchParams]="item.content">
          </contracts>
          <search-dealer-location *ngSwitchCase="'SearchDealerLocation'" [searchParams]="item.content">
          </search-dealer-location>
          <setup-Alerts *ngSwitchCase="'Setup Alerts'"></setup-Alerts>
          <setup-Alerts *ngSwitchCase="'SearchSetupAlerts'" [searchParams]="item.content"
            (formValueChanged)="setChangeData(true)">
          </setup-Alerts>
          <buying-group *ngSwitchCase="'Buying Group'" (formValueChanged)="setChangeData(true)"
            [searchParams]="item.content">
          </buying-group>
          <search-buying-group *ngSwitchCase="'SearchBuyingGroup'" (formValueChanged)="setChangeData(true)"
            [searchParams]="item.content">
          </search-buying-group>
          <failure-type *ngSwitchCase="'Failure Type'" [searchParams]="item.content">
          </failure-type>
          <resolution *ngSwitchCase="'Resolutions'"></resolution>
          <queue *ngSwitchCase="'Queue'"></queue>
          <oem-warranty *ngSwitchCase="'OEM Warranty'" [searchParams]="item.content"></oem-warranty>
          <manufacturer *ngSwitchCase="'Manufacturer'" [searchParams]="item.content">
          </manufacturer>
          <position *ngSwitchCase="'Position'"></position>
          <category *ngSwitchCase="'Category'" [searchParams]="item.content"></category>
          <subcategory *ngSwitchCase="'Subcategory'" [searchParams]="item.content"></subcategory>
          <sales-representative *ngSwitchCase="'Sales Representative'"></sales-representative>
          <price-paid *ngSwitchCase="'Price Paid'"></price-paid>
          <work-queue-list *ngSwitchCase="'Work Queue List'" [searchParams]="item.content">
          </work-queue-list>
          <admin-menu-configuration *ngSwitchCase="'Admin Menu Configuration'" [searchParams]="item.content">
          </admin-menu-configuration>
          <email-template *ngSwitchCase="'Search Message'"></email-template>
          <rate-profile *ngSwitchCase="'Rate Profile'"></rate-profile>
          <search-payee *ngSwitchCase="'SearchPayee'" [searchParams]="item.content"></search-payee>
          <search-profile *ngSwitchCase="'SearchProfile'" [searchParams]="item.content">
          </search-profile>
          <admin-security-permission *ngSwitchCase="'Permissions'" [searchParams]="item.content">
          </admin-security-permission>
          <admin-security-roles *ngSwitchCase="'Roles'"></admin-security-roles>
          <admin-sec-user *ngSwitchCase="'Users'"></admin-sec-user>
          <security-operation-option *ngSwitchCase="'Operation'"></security-operation-option>
          <security-permission-list-option *ngSwitchCase="'Permission List'" [searchParams]="item.content">
          </security-permission-list-option>
          <security-permission-option *ngSwitchCase="'Permission Configuration'">
          </security-permission-option>
          <admin-security-role-permission *ngSwitchCase="'Role Permissions'" [searchParams]="item.content">
          </admin-security-role-permission>
          <admin-security-restatus *ngSwitchCase="'Restatus'" [searchParams]="item.content">
          </admin-security-restatus>
          <cv-coverage *ngSwitchCase="'Coverage'" [searchParams]="item.content"></cv-coverage>
          <cv-components *ngSwitchCase="'Components'"></cv-components>
          <cv-loss-codes *ngSwitchCase="'Loss Codes'"></cv-loss-codes>
          <cv-price-table *ngSwitchCase="'Price Table'"></cv-price-table>
          <cv-rate-bucket-config *ngSwitchCase="'Rate Bucket Configuration'">
          </cv-rate-bucket-config>
          <cv-cancellation-method *ngSwitchCase="'Cancellation Methods'"></cv-cancellation-method>
          <cv-cancellation-rule *ngSwitchCase="'Cancellation Rules'"></cv-cancellation-rule>
          <cv-coverage-group *ngSwitchCase="'Coverage Groups'"></cv-coverage-group>
          <cv-insurance-company *ngSwitchCase="'Insurance Company'" [searchParams]="item.content">
          </cv-insurance-company>
          <cv-product-type *ngSwitchCase="'Product Types'"></cv-product-type>
          <search-coverage *ngSwitchCase="'SearchCoverage'" [searchParams]="item.content">
          </search-coverage>
          <search-insurance-company *ngSwitchCase="'SearchInsuranceCompany'" [searchParams]="item.content">
          </search-insurance-company>
          <process-claim *ngSwitchCase="'Process Claims'" [searchParams]="item.content">
          </process-claim>
          <claim-payment *ngSwitchCase="'Claim Payment History'"></claim-payment>
          <import-claim *ngSwitchCase="'Import Claim Payment'"></import-claim>
          <service-order *ngSwitchCase="'Service Order'" (formValueChanged)="setChangeData($event)"
            [searchParams]="item.content">
          </service-order>
          <search-service-order *ngSwitchCase="'SearchServiceOrder'" [searchParams]="item.content">
          </search-service-order>
          <search-contract *ngSwitchCase="'SearchContract'" [searchParams]="item.content">
          </search-contract>
          <search-contract *ngSwitchCase="'Contracts'" [searchParams]="item.content">
          </search-contract>
          <submit-provider *ngSwitchCase="'Submit to Provider'"> </submit-provider>
          <servicer *ngSwitchCase="'Servicer'" [searchParams]="item.content"> </servicer>
          <search-servicer *ngSwitchCase="'SearchServicer'" [searchParams]="item.content">
          </search-servicer>
          <dashboard *ngSwitchCase="'Dashboard'"></dashboard>
          <service-center-lookup *ngSwitchCase="'Service Center Lookup'" [searchParams]="item.content">
          </service-center-lookup>
          <claim-adjudication-tool *ngSwitchCase="'Claim Adjudication Tool'">
          </claim-adjudication-tool>
          <service-claim-payment *ngSwitchCase="'Claim Payment Notification'" [searchParams]="item.content">
          </service-claim-payment>
          <import-file *ngSwitchCase="'Import File'" [title]="'Document'" [isGeneric]="true" [duplicateCheck]="true">
          </import-file>
          <view-file *ngSwitchCase="'Search Documents'" [parentCategory]="'Import'" [searchParams]="item.content"
            [isGeneric]="true">
          </view-file>
          <dashboard *ngSwitchDefault></dashboard>
          <insurance-extract *ngSwitchCase="'Insurance Extract'"> </insurance-extract>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<p-dialog header="You have unsaved changes on the screen." [(visible)]="display" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <p>Select Force Close to close without saving. Hit Cancel to return back.</p>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onYes()" label="Yes">
        Force Close
      </button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="display=false" label="No">
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog>
<crm-issue-resolver></crm-issue-resolver>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"> </p-confirmDialog>
<session-modal [strings]="strings" [activityCtx]="activityCtx" [isSessionTimingOut]="isTimingOut()"
  (refresh)="restart()" (logout)="logOut()">
</session-modal>
<p-dialog [header]="'Oops!'" [(visible)]="errorDisplay" [modal]="true" [styleClass]="'error-dailog'"
  [blockScroll]="true">
  <div class="modal-content">
    <i class="pi pi-exclamation-triangle errorSvg" style="font-size: 2em"></i>
    <p>{{ erMessage }}</p>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" (click)="errorDisplay=false" type="submit" label="ok">
        Close
      </button>
    </div>
  </ng-template>
</p-dialog>