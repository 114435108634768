import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { CrmDocumentType } from '../constants/enums';
import { ImportFile, FieldNames } from './import-file';
import { ChangeDetectorRef } from '@angular/core';
import { RequiredColummns, DisableList } from './import-file.constants';
import { ViewFileService } from '../view-file/view-file.service';
import { base64ToArrayBuffer, getFileExtension } from '../utilities/common-utilities';
import { ImportFileService } from './import-file.service';
import { Subscription, timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs';
import moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';

@Component({
  selector: 'import-file',
  templateUrl: 'import-file.template.html',
  styleUrls: ['import-file.scss'],
  providers: [MessageService, ViewFileService],
})
export class ImportFileComponent implements OnInit, OnDestroy {
  @Input() title: any;
  @Input() isGeneric: boolean;
  @Input() documentType: any;
  @Input() attachmentId: number;
  @Input() duplicateCheck: boolean;
  @Input() requiredColumns: any;
  public records: ImportFile;
  public fieldsNames: FieldNames;
  public validCSV: string;
  public headersRow: any[];
  public csvArr: any[];
  public sumCost: any;
  public contractsCount: number;
  public index: any;
  public row = 0;
  public uploadProgress = 0;
  public results: string;
  public failedUrl: string;
  public uploadSubscription: Subscription;
  public importSummary = [];
  private fileStatus: number;
  private file: any;
  msgs: any[];
  documentOptions: { key: string; value: number }[];
  selectedDocument: any;
  selectedDocumentType: any;
  documentDescription: string;
  searchFileName: string;
  isBankContracts = false;
  currentUser: any;
  currentUserAzureId: any;
  queryAddFile: any;
  queryFileCheck: any;
  queryRemoveFile: any;
  uploadStartTime: any;
  CHECK_FILE_STATUS_TIMER = 6; // in Seconds
  MAX_TIMEOUT = 15 * 60; // Timeout in Seconds
  presentAttachmentsID: number;
  contractBankCheckBox = false;

  constructor(
    private importFileService: ImportFileService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private chRef: ChangeDetectorRef,
    private viewFileService: ViewFileService,
    private confirmationService: ConfirmationService,
    private roleService: RoleAuthorizationService,
  ) {
    azureService.getFullName().subscribe((currentUser: any) => {
      this.currentUser = currentUser;
    });
    this.currentUserAzureId = this.azureService.accountId;
  }
  isLoading = false;

  ngOnInit() {
    if (this.title === 'Document') {
      this.contractBankCheckBox = true;
    }
    this.selectedDocument = {
      key: CrmDocumentType[this.documentType],
      value: this.documentType,
    };
    this.selectedDocumentType = {
      key: CrmDocumentType[this.documentType],
      value: this.documentType,
    };
    this.documentOptions = this.roleService.validateSideMenu(
      this.importFileService.getDocumentOptions(),
    );
    this.cleanRecords();
  }

  // Upload and read CSV file
  onUpload(event: { files: any }) {
    let reqColumns = 'None';
    switch (this.selectedDocument.key) {
      case 'Import Contracts':
        reqColumns = 'contractColumnNames';
        break;
      case 'Cancellation Upload':
        reqColumns = 'cancellationColumnNames';
        break;
      case 'ClaimPayee':
        reqColumns = 'claimPayeeCoulmnNames';
        break;
      default:
        break;
    }

    this.requiredColumns = RequiredColummns[reqColumns];
    this.uploadProgress = 0;
    const uploadedFiles: any[] = [];
    this.isLoading = true;
    this.chRef.detectChanges();

    for (const file of event.files) {
      uploadedFiles.push(file);
    }
    // Start validation for required columns
    const reader = new FileReader();
    reader.readAsText(uploadedFiles[0]);
    reader.onload = () => {
      const csvData = reader.result;
      const csvRecordsArray = (csvData as string).split(/\r\n|\n/);
      this.csvArr = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);

      this.file = uploadedFiles[0];
      this.searchFileName = this.file.name.replace(/[^\w.]/g,'');

      if (this.isGeneric) {
        const extType = getFileExtension(this.searchFileName);
        if (extType !== 'csv') {
          this.showConfirmationMsg(
            'File Upload Failed',
            `File extension type "${extType}" not supported`,
          );
          this.fileReset();
          return 0;
        }
      }
      // TODO: Only basic integration is done. Need to refactor te code
      const fileData: any = {};
      if (this.attachmentId) {
        fileData.attachmentId = this.attachmentId.toString();
      }
      fileData.attachmentsType = this.selectedDocument.key;
      fileData.filename = this.file.name.replace(/[^\w.]/g,'');
      fileData.description = this.documentDescription ? this.documentDescription : '';
      fileData.isBankContracts = this.isBankContracts ? 'true' : 'false';
      fileData.user = this.currentUser;
      fileData.insertUserName = this.currentUserAzureId;

      this.messageService.clear();

      if (this.requiredColumns && this.requiredColumns.length > 0) {
        const headerCSV = this.getHeaderArray(csvRecordsArray);

        if (headerCSV) {
          this.uploadFile(fileData);
        } else {
          this.uploadProgress = 0;
          this.isLoading = false;
          this.showConfirmationMsg(
            'File Upload Failed',
            `File does not contains the required columns`,
          );
          this.chRef.detectChanges();
        }
      } else {
        this.uploadFile(fileData);
      }
      return 0;
    };
  }

  uploadFile(fileData) {
    this.doRelaoad();
    if (this.duplicateCheck) {
      this.queryFileCheck = this.importFileService
        .fileExistsCheck(fileData.attachmentsType, fileData.filename)
        .subscribe((isExists) => {
          if (isExists.data.getFileExists) {
            this.isLoading = false;
            this.validCSV = 'Failed';
            this.uploadProgress = 0;
            this.showErrorForFileExists();
          } else {
            this.doUpload(fileData);
          }
        });
    } else {
      this.doUpload(fileData);
    }

    this.uploadProgress = 0;
  }

  doRelaoad() {
    if (this.queryAddFile) {
      this.queryAddFile.unsubscribe();
    }
    if (this.queryFileCheck) {
      this.queryFileCheck.unsubscribe();
    }
    if (this.queryRemoveFile) {
      this.queryRemoveFile.unsubscribe();
    }
  }

  doUpload(fileData) {
    this.isLoading = true;
    this.queryAddFile = this.importFileService
      .addFileToAttachmentsTable(fileData)
      .subscribe(({ data }: any) => {
        this.presentAttachmentsID = data.addFileToAttachmentsTable.attachmentsID;
        this.viewFileService.getPreSignedUrl(this.presentAttachmentsID, 'put').subscribe(
          (preSignedInfo) => {
            const preSignedUrl = preSignedInfo.data.getPreSignedUrl;
            console.log(`put preSignedUrl :${preSignedUrl}`);
            this.importFileService.sendFileToS3(preSignedUrl, this.file).subscribe(
              (event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  this.uploadProgress = Math.round((100 * event.loaded) / event.total);
                } else if (event instanceof HttpResponse) {
                  if (this.attachmentId) {
                    this.showSuccess();
                    this.isLoading = false;
                  } else {
                    this.onFileStatusCheck();
                  }
                }
              },
              (err: any) => {
                this.fileUploadError(err);
              },
            );
          },
          (err) => {
            this.fileUploadError(err);
          },
        );
      });
  }

  fileUploadError(err) {
    this.isLoading = false;
    this.validCSV = 'Failed';
    this.uploadProgress = 0;
    this.removeFileFromS3(this.presentAttachmentsID);
    this.showError(true);
    throw err;
  }

  removeFileFromS3(attachmentId) {
    this.queryRemoveFile = this.importFileService
      .removeFileFromAttachmentsTable({ attachmentId })
      .subscribe(() => {
        console.log(`removed file`);
      });
  }

  displayUploadingResults() {
    this.fieldsNames = {
      validation_name: 'Validation',
      total_count_name: 'Total Count',
    };

    this.records = {
      validation: this.validCSV,
      total_count: this.contractsCount,
    };

    if (this.sumCost) {
      this.fieldsNames.cost_name = 'Dealer Cost';
      if (this.selectedDocument.key === 'ClaimPayee') {
        this.fieldsNames.cost_name = 'Total Amount Paid';
      }
      this.records.cost = `$${this.sumCost}`;
    }
  }

  // Extract header from CSV array and validate name of the columns with the required columns array for the document type
  getHeaderArray(csvRecordsArr: any) {
    let headers: string[];
    for (let i = 0; i < csvRecordsArr.length; i++) {
      if (
        this.requiredColumns.some((v: any) =>
          (csvRecordsArr[i] as string).trim().split(',').includes(v.trim()),
        )
      ) {
        this.index = i;
        headers = (csvRecordsArr[i] as string).split(',');
      }
    }
    if (headers) {
      const headerArray = [];
      for (const row of headers) {
        headerArray.push(row);
      }
      return headerArray;
    }
    return [];
  }

  // Extract only data rows from CSV array.
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
    const csvArr = [];
    for (let i = this.row + 1; i < csvRecordsArray.length - 1; i++) {
      const currentRecord = (csvRecordsArray[i] as string).split(/,(?!\s)/);
      csvArr.push(currentRecord);
    }

    return csvArr;
  }

  splitByColumns(headersRow: any, csvArr: any) {
    const rowToObject = (headers: any[], cells: { [x: string]: any }) =>
      headers.reduce((acc, header, i) => {
        acc[header] = cells[i];
        return acc;
      }, {});

    return csvArr.map((row) => rowToObject(headersRow, row));
  }

  showConfirmationMsg(header, message) {
    this.confirmationService.confirm({
      header,
      message,
      rejectVisible: false,
      acceptLabel: 'OK',
    });
    this.isLoading = false;
  }

  showError(isFileError = false) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: isFileError ? 'File not uploaded to S3' : 'File Upload is Failed.',
    });
  }

  showErrorForFileExists() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'File is already exists',
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'File is uploaded successfully.',
    });
  }

  cleanRecords() {
    this.records = {
      validation: '',
      total_count: null,
      validation_results: '',
      cost: '',
    };

    this.fieldsNames = {
      validation_name: '',
      total_count_name: '',
      validation_results_name: '',
      cost_name: '',
    };
  }

  fileReset() {
    this.cleanRecords();
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }

  onChecked(e: boolean) {
    this.isBankContracts = e;
  }

  downloadErrorFile() {
    // actual url this.selectedItem.fileS3URL
    // test url
    // const url = `https://gmophy6eo2.execute-api.us-east-1.amazonaws.com/dev/
    // csg-wfx-crm-newleaf-dev-email-templates-us-east-1/crm-notes_1600940833907.xlsx`;
    this.viewFileService.getPreSignedUrl(this.presentAttachmentsID, 'get').subscribe(
      (preSignedInfo) => {
        this.isLoading = true;
        const preSignedUrl = preSignedInfo.data.getPreSignedUrl;
        console.log(`get preSignedUrl :${preSignedUrl}`);
        this.viewFileService.getFile(preSignedUrl).subscribe(
          (response) => {
            const fileName = decodeURIComponent('Invalid_' + this.file.name);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(response);
            link.download = fileName;
            link.click();
            this.isLoading = false;
            this.chRef.detectChanges();
          },
          (err) => {
            const buffer = base64ToArrayBuffer(err.error.text);
            const file = new Blob([buffer as BlobPart], {
              type: 'text/csv',
            });
            const fileURL = URL.createObjectURL(file);
            // create <a> tag dinamically
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = this.file.name;
            fileLink.click();
            this.isLoading = false;
            this.chRef.detectChanges();

            // fileURL = fileURL + '.' + getFileExtension(this.selectedItem.fileS3URL);
            // window.open(fileURL, '_blank');
          },
        );
      },
      (err) => {
        console.log('Failed to Download the file');
      },
    );
  }

  checkFileStatusCall() {
    if (this.fileStatus === 1) {
      return false;
    }
    if (this.fileStatus === 2 || this.fileStatus === 3) {
      return false;
    }
    return true;
  }

  onFileStatusCheck() {
    this.isLoading = true;
    if (this.selectedDocument && this.searchFileName) {
      const documentType =
        this.selectedDocument.key === 'Import Contracts'
          ? CrmDocumentType.Customer
          : this.selectedDocument.value;
      this.uploadStartTime = moment();
      this.uploadSubscription = timer(0, this.CHECK_FILE_STATUS_TIMER * 1000)
        .pipe(
          switchMap(() =>
            this.importFileService.getDocumentUploadState(documentType, this.searchFileName),
          ),
          takeWhile(() => {
            const res = this.checkMaxTimeOut() && (!this.fileStatus || this.checkFileStatusCall());
            return res;
          }),
        )
        .subscribe(
          ({ data }) => {
            this.fileStatus = data.getDocumentUploadState.uploadState;
            const uploadInfObject = JSON.parse(data.getDocumentUploadState.uploadInfo);
            this.importSummary = [];
            // TODO: Need to implement the same for all documenttypes.
            if (this.selectedDocument.key === 'ImportPriceTable') {
              if (this.fileStatus === 1) {
                this.isLoading = false;
                this.importSummary = [
                  { key: 'ValidationStatus', value: 'Passed', label: 'Validation Status' },
                ];
                this.importSummary =
                  uploadInfObject.importSummary && uploadInfObject.importSummary.length > 0
                    ? [...this.importSummary, ...uploadInfObject.importSummary]
                    : this.importSummary;
                this.importSummary = this.removeDisabledKeys(
                  this.importSummary,
                  this.selectedDocument.key,
                );
                this.showSuccess();
              } else if (this.fileStatus === 2 || this.fileStatus === 3) {
                this.isLoading = false;
                this.failedUrl = data.getDocumentUploadState.fileUrl;
                this.importSummary = [
                  {
                    key: 'ValidationStatus',
                    value: `${this.fileStatus === 2 ? 'Failed' : 'Validation Exception'}`,
                    label: 'Validation Status',
                  },
                ];
                this.showError();
              }
            } else {
              // TODO: Need to remove after the generic logic implementation for all import types
              this.sumCost = uploadInfObject ? uploadInfObject.dealerCost : 0;

              if (this.fileStatus === 1) {
                this.isLoading = false;
                this.validCSV = 'Passed';
                this.contractsCount = uploadInfObject
                  ? uploadInfObject.contractsCreated
                    ? uploadInfObject.contractsCreated
                    : uploadInfObject.createdContractsNum
                  : 0;
                this.uploadProgress = 100;
                this.chRef.detectChanges();
                this.displayUploadingResults();
                this.showSuccess();
              } else if (this.fileStatus === 2 || this.fileStatus === 3) {
                this.isLoading = false;
                this.validCSV = `${this.fileStatus === 2 ? 'Failed' : 'Validation Exception'}`;
                this.failedUrl = data.getDocumentUploadState.fileUrl
                  ? data.getDocumentUploadState.fileUrl
                  : '';
                this.uploadProgress = 0;
                this.chRef.detectChanges();
                this.displayUploadingResults();
                this.showError();
              }
            }
          },
          (err) => {
            this.showError();
            this.isLoading = false;
            throw err;
          },
        );
      this.sumCost = null;
      this.contractsCount = null;
      this.fileStatus = null;
    }
  }

  checkMaxTimeOut() {
    const timeDiffSec = moment.duration(moment().diff(this.uploadStartTime)).asSeconds();
    const diffRes = timeDiffSec < this.MAX_TIMEOUT;
    const errMsg =
      'File is still being processed, Please check the updated status in Search Documents Tab';
    if (!diffRes) {
      this.showConfirmationMsg('File Upload Status', errMsg);
      this.isLoading = false;
    }
    return diffRes;
  }

  ngOnDestroy() {
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }

  removeDisabledKeys(data, attachmentType) {
    if (!Array.isArray(data)) {
      return data;
    }
    if (!DisableList || !DisableList.hasOwnProperty(attachmentType)) {
      return data;
    }

    const newData = data.reduce((rows, row) => {
      const isExists = DisableList[attachmentType].includes(row.key);
      if (!isExists) {
        rows.push(row);
      }
      return rows;
    }, []);

    return newData;
  }
}
