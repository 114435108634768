<div class="crm-panel" style="position: relative; box-shadow: 0 0 100px rgba(0,0,0,0.3);">
  <div class="crm-top">
    <div class="crm-panel__left">
      <buying-group-details-form (formValueChanged)="setChangeData($event)"></buying-group-details-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <ng-container *ngIf="componentDisplay && agentId">
    <crm-notes
      *appSetRolePermissionsDirective="{type:'component', operation:'READ_ONLY', permission:'Notes', category: 'Agent'}"
      [parentCategory]="'Agent'" [category]="addressCategoryType.Agent" [identifierId]="agentId"
      [parentComponent]="component"></crm-notes>
  </ng-container>
</div>