import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { Observable, of } from 'rxjs';
import { CrmGraphql } from '../../../shared/constants/graphql-constants';

const batchProcessQuery = gql`
  query(
    $fileStatus: String
    $fileType: String
    $dealerName: String
    $fileName: String
    $uploadDateFrom: String
    $uploadDateTo: String
    $planCount: Int
    $contractRange: String
    $dealerCost: String
    $nlfTransactionDateFrom: Date
    $nlfTransactionDateTo: Date
    $cancellationTransactionDateFrom: Date
    $cancellationTransactionDateTo: Date
    $offset: Int
    $limit: Int
  ) {
    getBatchProcessSearchResults(
      fileStatus: $fileStatus
      fileType: $fileType
      dealerName: $dealerName
      fileName: $fileName
      uploadDateFrom: $uploadDateFrom
      uploadDateTo: $uploadDateTo
      planCount: $planCount
      contractRange: $contractRange
      dealerCost: $dealerCost
      nlfTransactionDateFrom: $nlfTransactionDateFrom
      nlfTransactionDateTo: $nlfTransactionDateTo
      cancellationTransactionDateFrom: $cancellationTransactionDateFrom
      cancellationTransactionDateTo: $cancellationTransactionDateTo
      offset: $offset
      limit: $limit
    ) {
      batchProcessingIdOriginal
      fileStatus
      fileType
      dealerName
      fileName
      uploadDate
      planCount
      contractRange
      dealerCost
      extendedDealerCost
      nlfTransactionDate
      cancellationTransactionDate
    }
  }
`;

const createUpdateBatchProcessQuery = gql`
  mutation(
    $batchProcessingIdOriginal: Int
    $fileStatus: String
    $fileType: String
    $dealerName: String
    $dealerNumber: String
    $uploadDate: Date
    $fileName: String
    $planCount: Int
    $contractRange: String
    $dealerCost: String
    $extendedDealerCost: String
    $nlfTransactionDate: String
    $cancellationTransactionDate: String
    $insertUserName: String
    $hasStatusChanged: Boolean
    $hasNLFDateChanged: Boolean
    $hasCancellationDateChanged: Boolean
    $operationType: String
    $comments: String
  ) {
    createUpdateBatchProcess(
      batchProcess: {
        batchProcessingIdOriginal: $batchProcessingIdOriginal
        fileStatus: $fileStatus
        fileType: $fileType
        dealerName: $dealerName
        dealerNumber: $dealerNumber
        uploadDate: $uploadDate
        fileName: $fileName
        planCount: $planCount
        contractRange: $contractRange
        dealerCost: $dealerCost
        extendedDealerCost: $extendedDealerCost
        nlfTransactionDate: $nlfTransactionDate
        cancellationTransactionDate: $cancellationTransactionDate
        hasStatusChanged: $hasStatusChanged
        hasNLFDateChanged: $hasNLFDateChanged
        hasCancellationDateChanged: $hasCancellationDateChanged
        insertUserName: $insertUserName
        operationType: $operationType
        comments: $comments
      }
    ) {
      batchProcessingIdOriginal
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class BatchProcessingService {
  constructor(private apollo: Apollo) {}
  getBatchProcessing(): Observable<any[]> {
    return of([
      {
        fileStatus: 'COMPLETE',
        fileType: 's',
        dealerName: 'Matrix',
        fileName: 'batch-processing-22072020.csv',
        uploadDate: '12/06/2020',
        planCount: '-1654',
        contractRange: 'NL-2344834-3485309302',
        dealerCost: '-5.0',
        extendedDealerCost: '-123',
        nlfTransactionDate: '',
        cancellationTransactionDate: '12/06/2020'
      },
      {
        fileStatus: 'COMPLETE',
        fileType: 's',
        dealerName: 'Matrix',
        fileName: 'batch-processing-22072020.csv',
        uploadDate: '12/06/2020',
        planCount: '1654',
        contractRange: 'NL-2344834-3485309302',
        dealerCost: '5.0',
        extendedDealerCost: '-123',
        nlfTransactionDate: 'UNPROCESSED',
        cancellationTransactionDate: ''
      },
      {
        fileStatus: 'COMPLETE',
        fileType: 's',
        dealerName: 'Matrix',
        fileName: 'batch-processing-22072020.csv',
        uploadDate: '12/06/2020',
        planCount: '1654',
        contractRange: 'NL-2344834-3485309302',
        dealerCost: '5.0',
        extendedDealerCost: '123',
        nlfTransactionDate: '06/16/2020',
        cancellationTransactionDate: 'UNPROCESSED'
      }
    ]);
  }

  getBatchProcessSearchResults = data =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: batchProcessQuery,
      variables: { ...data, fileStatus: data.fileStatus.value, planCount: data.planCount || null },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateBatchProcess = data =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateBatchProcessQuery,
      variables: {
        ...data
      }
      // tslint:disable-next-line: semicolon
    });
}
