<div class="main-tab">
  <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
  <app-section-header [title]="'Main'" />
  <customer-information *ngIf="componentDisplay.CustomerInformationComponent" [formFields]="customerInfo"
    [parentComponent]="component" (modelDataChange)="modelDataChange($event, 'customerInfo')">
  </customer-information>
  <address *ngIf="componentDisplay.AddressComponent && (isMainTabDataAvailable || !searchParams)"
    [caller]="'contract-main'" [(modelData)]="contractDataService.contractDataModel.mainTab.addresses"
    [enableModelData]="true" [parentComponent]="component"></address>
  <div *ngIf="componentDisplay.CoveredProductsComponent">
    <covered-products
      *appSetRolePermissionsDirective="{type:'div', operation:'READ_ONLY', permission:'Covered Products', category: 'Contract'}">
    </covered-products>
  </div>
  <associated-contracts *ngIf="componentDisplay.AssociatedContractsComponent"></associated-contracts>
  <general-fields *ngIf="componentDisplay.GeneralFieldsComponent && (isMainTabDataAvailable || !searchParams)"
    [formFields]="generalInfo" [formData]="genericFormData" [selected]="true"
    (modelDataChange)="modelDataChange($event, 'generalInfo')">
  </general-fields>
  <additional-info *ngIf="componentDisplay.AdditionalInfoComponent" [formFields]="additionalInfo"
    (modelDataChange)="modelDataChange($event, 'additionalInfo')">
  </additional-info>
</div>