import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const templateQuery = gql`
  query {
    getEmailTemplateDetails(limit: 250) {
      emailTemplateName
      emailTemplateType
      emailTemplateMessageCode
      emailTemplateDescription
      emailTemplateLocation
      emailTemplateVariables
      emailTemplateVersion
      emailTemplateIsActive
      emailTemplateInsertDateTime
      emailTemplateUser
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  templateDetails = {};
  typeMapping = {};
  selectedItem = {};

  constructor(private apollo: Apollo, private azureService: AzureLoginService, private http: HttpClient) {}

  getConfigurationTypes(): Observable<any[]> {
    return of([
      {
        id: 1,
        description: 'Abuse/Misuse',
        code: 'AM'
      },
      {
        id: 2,
        description: 'Accidental Damage',
        code: 'AD'
      },
      {
        id: 3,
        description: 'Environmental Conditions',
        code: 'EC'
      }
    ]);
  }
  getEmailConfigurationTypes(): Observable<any[]> {
    return of([
      {
        id: 1,
        active: 'true',
        type: 'Dispatch',
        message_code: '!Standard Dispatch',
        description: 'Dispatch'
      },
      {
        id: 2,
        active: 'true',
        type: 'Customer Dispatch',
        message_code: 'CRP Apple Designer',
        description: 'Customer Reimbursement Apple Designer'
      },
      {
        id: 3,
        active: 'true',
        type: 'Customer Follow Up',
        message_code: 'Customer Follow Up',
        description: 'Customer Follow Up Standard'
      }
    ]);
  }
  getFileAttachmentTypes(): Observable<any[]> {
    return of([
      {
        file: '!Standard',
        uploaded_by: 'John Doe',
        uploaded_bys: '12/12/2017 3:56:24 P.M'
      },
      {
        file: 'Doc2',
        uploaded_by: 'John Doe',
        uploaded_bys: '12/12/2017 3:34:24 P.M'
      }
    ]);
  }
  getTemplateDetails = () =>
  this.apollo.use('crm').watchQuery<any>({
    query: templateQuery,
    variables: {},
    fetchPolicy: 'network-only'
    // tslint:disable-next-line: semicolon
  }).valueChanges;

  retrieveTemplateFileDetails(templateName: string): Observable<any> {
    const headers = {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.azureService.accessToken}`
    };
    const payload = {
      operation: 'retrieve_template',
      data: {
        template: templateName
      }
    };
    const response = this.http.post(environment.emailUrl, JSON.stringify( payload ), {
      headers
    });
    return response;
  }
}
