import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CoverageGroupService {
  constructor() {}
  getCoverageGroup(): Observable<any[]> {
    return of([
      {
        id: 1,
        code: 'PSP',
        description: 'Service Contract'
      },
      {
        id: 2,
        code: 'PRP',
        description: 'Single Replacement Contract'
      }
    ]);
  }
}
