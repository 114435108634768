import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { copy } from '../../shared/utilities/common-utilities';
import { RateBucketService } from './rate-bucket-config.service';

@Component({
  selector: 'cv-rate-bucket-config',
  templateUrl: 'rate-bucket-config.template.html',
  providers: [MessageService, RateBucketService],
})
export class RateBucketConfigComponent implements OnInit {
  isLoading = false;
  querySubscription: Subscription;
  adminList: any[] = [];
  commissionList: any[] = [];
  reservesAndFeesList: any[] = [];
  params = {
    categoryCode: null,
    bucketCode: null,
    bucketLabel: null,
    azureUserId: null,
    limit: null,
    offset: null,
  };
  constructor(
    private rateBucketService: RateBucketService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.getRateBucketData();
  }
  getRateBucketData(event = null) {
    this.isLoading = true;
    this.querySubscription = this.rateBucketService.getRateBucketSearch(this.params).subscribe(
      ({ data }) => {
        this.isLoading = false;
        const modifedData = copy(data.getRateBucketSearch);
        const rateBucktList = [];
        for (const singleRole of data.getRateBucketSearch) {
          const rateBucketDetails = {
            rateBucketId: singleRole.rateBucketId,
            rateBucketIdOriginal: singleRole.rateBucketIdOriginal,
            categoryCode: singleRole.categoryCode,
            bucketCode: singleRole.bucketCode,
            bucketLabel: singleRole.bucketLabel,
            isDefaultLabel: singleRole.isDefaultLabel,
          };
          rateBucktList.push(rateBucketDetails);
        }
        this.adminList = [...rateBucktList.filter((el) => el.categoryCode === 'Admin')];
        this.commissionList = [...rateBucktList.filter((el) => el.categoryCode === 'Commissions')];
        this.reservesAndFeesList = [
          ...rateBucktList.filter((el) => el.categoryCode === 'Reserves and Fees'),
        ];
        if (event === true) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Success`,
          });
        } else if (event === false) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Entry already exists`,
          });
        }
      },
      (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to retrieve Data`,
        });
      },
    );
  }
}
