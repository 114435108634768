import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CancellationRuleService {
  constructor() {}
  getCancellationRules(): Observable<any[]> {
    return of([
      {
        id: 1,
        ruleName: 'Pro-Rata',
        productType: 'Service Contract',
        saleFrom: '04/16/2014',
        saleTo: '',
        defaultRule: true
      }
    ]);
  }

  getState(): Observable<any[]> {
    return of([
      {
        id: 1,
        state: 'Default',
        ruleConfiguration:
          '1-9999 days, Default Method: Pro-Rata, Customer Fee $15.00 of retail if greater, Seller Fee 0.00% if greater'
      }
    ]);
  }
}
