import { Component, OnInit } from '@angular/core';
import { CrmDocumentType } from '../../../shared/constants/enums';
import { ServicerService } from '../servicer.service';
import { RequiredColummns } from './servicer-import-file.constants';

@Component({
  selector: 'ct-file-upload',
  templateUrl: 'servicer-import-file.template.html',
})
export class ServicerDocumentUploadComponent implements OnInit {
  documentType = CrmDocumentType;
  contractRequiredColumns = RequiredColummns.ColumnNames;
  attachmentId: number;
  constructor(private servicerService: ServicerService) {}

  ngOnInit() {
    if (this.servicerService.serviceFormData) {
      this.attachmentId = this.servicerService.serviceFormData.servicerProfilesIdOriginal;
    }
  }
}
