<div class="crm-panel">
  <div class="crm-top">
    <div class="crm-panel__left">
      <cv-details-form (formValueChanged)="setChangeData($event)" [searchParams]="searchParams">
      </cv-details-form>
    </div>
    <div class="crm-panel__main">
      <navigation-panel [navigationPanel]="navigationPanel"></navigation-panel>
    </div>
  </div>
  <div class="crm-bottom" *ngIf="componentDisplay">
    <crm-notes
      *appSetRolePermissionsDirective="{type:'component', operation:'READ_ONLY', permission:'Notes', category: 'Coverage'}"
      [parentCategory]="'Coverage'"
      [category]="addressCategoryType.CoveragePlans"
      [identifierId]="searchParams"
      [parentComponent]="component"
      style="width: 100%;"
    >
    </crm-notes>
  </div>
</div>
