<div class="main-tab__title">
  <div class="title">
    <div>Symptoms: Doesn't fit with water</div>
  </div>
</div>
<p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="searchResults">
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        <button *ngIf="col.field==='status'; else normal" [class]="btnStatus[rowData[col.field].toLowerCase()]">
          {{rowData[col.field]}}
        </button>
        <ng-template #normal> {{rowData[col.field]}} </ng-template>
      </td>
      <td class="last-cell">
        <div (click)="selectItem($event,'item',op3)" class="three-dots">
          <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-overlayPanel #op3>
  <p-listbox [options]="options" [(ngModel)]="selectedEllipsisItem" (onClick)="handleOpen($event)"></p-listbox>
</p-overlayPanel>
<p-dialog [(visible)]="displayAdd" [modal]="true" [styleClass]="'note-dailog'" header="Resolution" showeffect="fade">
  <p>Have you referred to your owner's manual for any troubleshooting?</p>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button (click)="displayAdd=false" class="crm-button" type="button" icon="fa fa-close" label="No">Cancel</button>
      <button (click)="onYes()" class="crm-button" type="button" icon="fa fa-close" label="Yes">Save</button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="displayUpdate" [modal]="true" [styleClass]="'note-dailog'" header="Add Resolution Modal"
  showeffect="fade">
  <form-modal [formFields]="formModalInfo"></form-modal>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button (click)="displayUpdate=false" class="crm-button" type="button" icon="fa fa-close"
        label="No">Cancel</button>
      <button (click)="onYes()" class="crm-button" type="button" icon="fa fa-close" label="Yes">Save</button>
    </div>
  </ng-template>
</p-dialog>