<div class="title"><img [src]="'assets/NLeafLogo.png'" /></div>
<div class="global-nav">
  <div class="title-wrapper">
  </div>
  <div class="filler">
    <div class="process-bar" [style.visibility]="processes.length || processIsLoading ? 'visible' : 'hidden'"
      [class.warning]="processes.length && !processIsLoading">
      <div class="left-content">
        <div class="loading-icon" [inlineSVG]="'assets/icon-reset.svg'" *ngIf="processIsLoading"></div>
        <div class="loading-icon" [inlineSVG]="'assets/Alert.svg'" *ngIf="!processIsLoading"></div>
        <div class="text">
          {{ processIsLoading ? strings?.ProcessingAutoAssignment :
          strings?.ProcessesRequireAttention }}
        </div>
      </div>
      <div class="right-content">
        <div class="details-button" (click)="showSummary=true">{{ strings?.ViewDetails }}</div>
      </div>
    </div>
  </div>
  <div class="user-info">
    <div class="info">
      <div class="name">
        {{ (fullName$ | async).indexOf('undefined') !== -1 ? (currentUser$ | async) : (fullName$ |
        async) }}
      </div>
    </div>
    <div *ngFor="let singleButton of button">
      <button type="singleButton.type" [disabled]="singleButton.disabled" [hidden]="singleButton.hidden"
        (click)="launchDash()">
        {{singleButton.name}}
      </button>
    </div>
    <div class="nav-bar-button" id="logoutButton" (click)="confirmLogOut()">
      <div class="icon" [inlineSVG]="'assets/LogOut.svg'"></div>
    </div>
  </div>
</div>
<modal class="dark" id="logout-confirmation-modal" [visible]="showLogoutConfirmation" (close)="cancelLogOut()">
  <div id="logout-confirmation-body">
    <div class="confirmation">{{ strings?.AreYouSureYouWantToLogout }}</div>
    <div class="buttons">
      <button type="button" (click)="logOut()">Yes</button>
      <button type="btn btn-secondary" (click)="cancelLogOut()">No</button>
    </div>
  </div>
</modal>