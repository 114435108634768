import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../../shared/constants/graphql-constants';
import { Subject } from 'rxjs';
import {
  coverageDetailsFormQuery,
  coverageLossCodesFormQuery,
  coverageSettingFormQuery,
  associatedSubcategoriesQuery,
  createUpdateCoverageLossCodeQuery
} from '../coverage-data-queries.constant';

@Injectable()
export class CoverageService {
  constructor(private apollo: Apollo) {}

  /**
   * Properties start
   */
  public coverageConfig = {
    searchParams: null
  };

  public coverageDataModel = {
    leftBaseSection: null,
    mainTab: null,
    associatedSubcategories: null,
    coverageCode: null
  };
  /**
   * Properties end
   */

  /**
   * Subscriber subjects start
   */
  public isMainTabDataAvailable = new Subject<boolean>();
  public setMainTabAvailability(value) {
    this.isMainTabDataAvailable.next(value);
  }

  /**
   * subscriber subjects end
   */

  /**
   * Methods start
   */
  getCoverageDetailsForm = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: coverageDetailsFormQuery,
      variables: { id: searchParams }
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getCoverageMainTab = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: coverageLossCodesFormQuery,
      variables: { coverageId: searchParams },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getCoverageSettingTab = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: coverageSettingFormQuery,
      variables: { coverageCode: searchParams }
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  getAssociatedSubcategoriesTab = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).watchQuery<any>({
      query: associatedSubcategoriesQuery,
      variables: { coverageCode: searchParams }
      // tslint:disable-next-line: semicolon
    }).valueChanges;

  createUpdateCoverageLossCode = payload =>
    this.apollo.use(CrmGraphql.NameSpace).mutate({
      mutation: createUpdateCoverageLossCodeQuery,
      variables: { coverageLossCodeInput: payload }
      // tslint:disable-next-line: semicolon
    });
  /**
   * Methods end
   */
}
