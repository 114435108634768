import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ContractService } from '../contract.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import _forEach from 'lodash/forEach';
import { rateDetailType } from './rate-details.constant';
import { FormField } from '../../shared/form-field/form-field';
import { FormGroup } from '@angular/forms';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { ContractFormFieldService } from '../../services/contract-form-field.service';
import { FormFieldControlService } from '../../services/form-field-control.service';

@Component({
  selector: 'rate-details-tab',
  templateUrl: 'rate-details-tab.template.html',
  styleUrls: ['rate-details-tab.scss'],
  providers: [FormFieldControlService],
})
export class RateDetailsTabComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  admin: any[];
  commission: any[];
  reserves: any[];
  total: any[];
  cols: any[];
  tabKey: string;
  querySubscription: any;
  isLoading: boolean;
  formFields: FormField<string>[] = [];
  form: FormGroup;
  constructor(
    private contractService: ContractService,
    private tabHandlerService: TabHandlerService,
    private contractFormFieldService: ContractFormFieldService,
    private qcs: FormFieldControlService,
  ) {
    super();
  }

  ngOnInit() {
    this.cols = [
      { field: 'bucketCode', header: 'CODE' },
      { field: 'bucketLabelDescription', header: 'DESCRIPTION' },
      { field: 'writtenAmount', header: 'WRITTEN' },
      { field: 'cancelledAmount', header: 'CANCELLED' },
      { field: 'balanceAmount', header: 'BALANCE' },
    ];

    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab && this.contractService.contractConfig.searchParams.id) {
          this.isLoading = true;
          console.log(
            `this.contractService.contractConfig ${JSON.stringify(
              this.contractService.contractConfig,
            )}`,
          );
          this.querySubscription = this.contractService
            .getRateDetails(this.contractService.contractConfig.searchParams.id)
            .subscribe(
              ({ data, loading }: any) => {
                const modifiedData = data.getContractProfileById;
                if (modifiedData) {
                  this.fillRateDetailsModel(modifiedData);
                }
                this.isLoading = loading;
              },
              (err) => {
                this.isLoading = false;
                throw err;
              },
            );
        }
      },
    });

    this.contractFormFieldService.getRateDetailsFormFields().subscribe((additionalInfo) => {
      this.formFields = additionalInfo;
      this.form = this.qcs.toFormGroup(this.formFields);
    });
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  private fillRateDetailsModel(data: any) {
    this.admin = [];
    this.commission = [];
    this.reserves = [];

    let adminWrittenTotal = 0;
    let adminCancelledTotal = 0;
    let adminBalanceTotal = 0;
    let reservesWrittenTotal = 0;
    let reservesCancelledTotal = 0;
    let reservesBalanceTotal = 0;
    let commissionsWrittenTotal = 0;
    let commissionsCancelledTotal = 0;
    let commissionsBalanceTotal = 0;
    let markup = null;

    // populate other tables
    _forEach(data[`rateDetails`], (el) => {
      const obj = Object.assign({}, el);
      // check for $ sign
      if (typeof obj.writtenAmount === 'string') {
        obj.writtenAmount =
          obj.writtenAmount && obj.writtenAmount.indexOf('$') > -1
            ? obj.writtenAmount
            : '$' + parseFloat(obj.writtenAmount).toFixed(2);
      } else {
        obj.writtenAmount = '$' + parseFloat(obj.writtenAmount).toFixed(2);
      }
      if (typeof obj.cancelledAmount === 'string') {
        obj.cancelledAmount =
          obj.cancelledAmount && obj.cancelledAmount.indexOf('$') > -1
            ? obj.cancelledAmount
            : '$' + parseFloat(obj.cancelledAmount).toFixed(2);
      } else {
        obj.cancelledAmount = '$' + parseFloat(obj.cancelledAmount).toFixed(2);
      }

      // calculate balance
      const { balanceStr, balance } = this.calculateBalance(obj.writtenAmount, obj.cancelledAmount);
      obj.balanceAmount = balanceStr;
      const written = this.getFloat(obj.writtenAmount);
      const cancelled = this.getFloat(obj.cancelledAmount);

      // separate rates
      switch (obj.categoryCode.toLowerCase()) {
        case rateDetailType.admin:
          this.admin.push(obj);
          adminWrittenTotal += written;
          adminCancelledTotal += cancelled;
          adminBalanceTotal += balance;
          break;
        case rateDetailType.commission:
        case rateDetailType.commissions:
          this.commission.push(obj);
          commissionsWrittenTotal += written;
          commissionsCancelledTotal += cancelled;
          commissionsBalanceTotal += balance;
          break;
        case rateDetailType.reserve:
        case rateDetailType.reserves:
          this.reserves.push(obj);
          reservesWrittenTotal += written;
          reservesCancelledTotal += cancelled;
          reservesBalanceTotal += balance;
          break;
      }

      if (
        markup === null &&
        obj.bucketLabelDescription &&
        obj.bucketLabelDescription.toLowerCase() === 'dealermarkup'
      ) {
        markup = this.getFloat(obj.writtenAmount);
      }
    });

    // populate total
    this.admin.push({
      bucketCode: 'TOTAL',
      bucketLabelDescription: null,
      writtenAmount: '$' + adminWrittenTotal.toFixed(2),
      cancelledAmount: '$' + adminCancelledTotal.toFixed(2),
      balanceAmount: '$' + adminBalanceTotal.toFixed(2),
    });
    this.commission.push({
      bucketCode: 'TOTAL',
      bucketLabelDescription: null,
      writtenAmount: '$' + commissionsWrittenTotal.toFixed(2),
      cancelledAmount: '$' + commissionsCancelledTotal.toFixed(2),
      balanceAmount: '$' + commissionsBalanceTotal.toFixed(2),
    });
    this.reserves.push({
      bucketCode: 'TOTAL',
      bucketLabelDescription: null,
      writtenAmount: '$' + reservesWrittenTotal.toFixed(2),
      cancelledAmount: '$' + reservesCancelledTotal.toFixed(2),
      balanceAmount: '$' + reservesBalanceTotal.toFixed(2),
    });

    // populate total
    const writtenTotal = adminWrittenTotal + commissionsWrittenTotal + reservesWrittenTotal;
    const cancelledTotal = adminCancelledTotal + commissionsCancelledTotal + reservesCancelledTotal;
    const balanceTotal = adminBalanceTotal + commissionsBalanceTotal + reservesBalanceTotal;

    this.total = [
      {
        bucketCode: 'BASE DEALER COST',
        bucketLabelDescription: '',
        writtenAmount: '$' + writtenTotal.toFixed(2),
        cancelledAmount: '$' + cancelledTotal.toFixed(2),
        balanceAmount: '$' + balanceTotal.toFixed(2),
      },
    ];

    // once implemented get data from service
    const extendedDealerCost = markup + balanceTotal;
    this.form.patchValue({
      markup: markup ? '$' + markup.toFixed(2) : '$0.00',
      extendedDealerCost: '$' + extendedDealerCost.toFixed(2),
    });
  }

  private calculateBalance(writtenAmount: any, cancelledAmount: any) {
    const written = this.getFloat(writtenAmount);
    const cancelled = this.getFloat(cancelledAmount);

    const balance = written - cancelled;
    const balanceStr = '$' + parseFloat(balance.toString()).toFixed(2);
    return { balanceStr, balance };
  }

  private getFloat(value) {
    const rawString = value ? value.replace('$', '') : value;
    return parseFloat(rawString);
  }

  onSubmit() {
    // functionality for on submit
  }
}
