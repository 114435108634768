import { FormField } from './form-field';

export class TextboxSearchField extends FormField<string> {
  override controlType = 'textboxsearch';
  override type: string;

  constructor(options: any = {}) {
    super(options);
    this.type = options[`type`] || '';
  }
}
