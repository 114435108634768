import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ServiceStatusLogService {
  constructor() {}
  getServiceStatusLog(): Observable<any[]> {
    return of([
      {
        id: 1,
        statusChangeTime: '08/13/2020 6:37:20 PM',
        userName: 'John Doe:',
        statusChangeFrom: '',
        statusChangeTo: 'DRAFT'
      },
      {
        id: 2,
        statusChangeTime: '08/14/2020 6:37:20 PM',
        userName: 'John Doe:',
        statusChangeFrom: 'DRAFT',
        statusChangeTo: 'PENDING ACCEPTANCE'
      }
    ]);
  }
}
