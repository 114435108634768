import { Component, Input, OnInit } from '@angular/core';
import { ServiceAuditTableService } from './service-audit-table.service';
import { buttonStatus } from '../../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisOptions,
  ellipsisHeaderOptions,
} from '../../../../shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
@Component({
  selector: 'service-audit-table',
  templateUrl: 'service-audit-table.template.html',
  providers: [ServiceAuditTableService],
})
export class ServiceAuditTableComponent implements OnInit {
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  constructor(private serviceAuditTableService: ServiceAuditTableService) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.serviceAuditTableService.getAuditTableDetails().subscribe((data) => {
      this.dataList = data;
    });
    this.cols = [
      { field: 'verticalTitle', header: '', type: 'abcd' },
      { field: 'auditReason', header: 'AUDIT REASON DESCRIPTION', type: 'text' },
      { field: 'auditOutcome', header: 'AUDIT OUTCOME DESCRIPTION', type: 'text' },
    ];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
}
