import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../../shared/form-field/form-field';
import { Subscription } from 'rxjs';
import { ServiceOrderDataService } from '../service-order-data.service';

@Component({
  selector: 'limit-of-liability-modal',
  templateUrl: 'limit-of-liability-modal.template.html',
  styleUrls: ['./limit-of-liability-modal.scss'],
})
export class LimitOfLiabilityModalComponent implements OnChanges, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Output() formSubmitEvent = new EventEmitter<FormGroup>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();
  @Output() formChange = new EventEmitter<FormField<string>[]>();
  formFields: FormField<string>[] = [];
  form: FormGroup;
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displayEmailModal: true;
  isLoading = false;
  querySubscription: Subscription;
  validForm = true;
  templateResponse = null;
  usedForProduct = 0.0;
  remainingForProduct = 0.0;
  total = 0.0;
  usedForProductPercent = 0.0;
  remainingForProductPercent = 0.0;
  lolResponseData = [];
  inflightAmount = 0.0;
  inflightAmountPercent = 0.0;
  spentAmount = 0.0;
  spentAmountPercent = 0.0;

  data: any;
  options: any;
  tableValues: any;
  @ViewChild('chart') chart;

  constructor(private serviceOrderDataService: ServiceOrderDataService) {}

  ngOnChanges() {
    if (
      this.serviceOrderDataService.serviceOrderDataModel.productInfoModel &&
      this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal
    ) {
      const productsIdOriginal =
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal;
      const productPurchasePrice =
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productPurchasePrice;
      const productPurchasePriceNumber = Number(productPurchasePrice.replace(/[^0-9\.]+/g, ''));
      if (productsIdOriginal in this.lolResponseData) {
        this.createPieChart(this.lolResponseData[productsIdOriginal], productPurchasePriceNumber);
      } else {
        this.isLoading = true;
        this.querySubscription = this.serviceOrderDataService
          .getRealLimitOfLiabilityResult(productsIdOriginal)
          .subscribe(
            ({ data, loading }: any) => {
              this.isLoading = false;
              this.lolResponseData[productsIdOriginal] = data.getRealLimitOfLiabilityById;
              this.createPieChart(
                this.lolResponseData[productsIdOriginal],
                productPurchasePriceNumber,
              );
            },
            (err) => {
              this.isLoading = false;
              throw err;
            },
          );
      }
    }
  }

  createPieChart(lolDetails, productPurchasePrice) {
    if (lolDetails.productPurchasePrice === 0) {
      this.usedForProduct = 0.0;
      this.remainingForProduct = productPurchasePrice;
    } else {
      this.usedForProduct = lolDetails.usedForProduct;
      this.remainingForProduct = lolDetails.remainingForProduct;
      this.inflightAmount = lolDetails.approvedForProduct;
      this.spentAmount = this.usedForProduct + this.inflightAmount;
    }
    this.total = productPurchasePrice;
    this.usedForProductPercent = Math.round((this.usedForProduct / this.total) * 100);
    this.remainingForProductPercent = Math.round((this.remainingForProduct / this.total) * 100);
    this.inflightAmountPercent = Math.round((this.inflightAmount / this.total) * 100);
    this.spentAmountPercent = Math.round((this.spentAmount / this.total) * 100);

    this.data = {
      labels: [
        `Amount Spent ${this.spentAmountPercent}%`,
        `Remaining for Product ${this.remainingForProductPercent}%`,
      ],
      datasets: [
        {
          data: [this.spentAmount, this.remainingForProduct],
          backgroundColor: ['#FF6961', '#77DD77'],
          hoverBackgroundColor: ['#FF0000', '#52D726'],
        },
      ],
    };
    this.options = {
      title: {
        display: true,
        text: `Product Purchase Price $${this.total}`,
        fontSize: 16,
      },
      legend: {
        position: 'bottom',
      },
    };

    setTimeout(() => {
      this.chart.reinit();
    }, 100);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
