import { Component, Input, OnInit } from '@angular/core';
import { ContractFormFieldService } from '../../../services/contract-form-field.service';
import { FormField } from '../../../shared/form-field/form-field';

@Component({
  selector: 'user-registration',
  templateUrl: 'user-registration.template.html',
  styleUrls: ['user-registration.scss'],
  providers: [ContractFormFieldService],
})
export class UserRegistrationComponent implements OnInit {
  cardInfo: FormField<any>[] = [];
  customerInfo: FormField<any>[] = [];
  servicePlanInfo: FormField<any>[] = [];
  productCoveredInfo: FormField<any>[] = [];

  constructor(private contractService: ContractFormFieldService) {}

  ngOnInit() {
    this.contractService.getCardFields().subscribe((cardInfo) => (this.cardInfo = cardInfo));
    this.contractService
      .getCustomerInformationFields()
      .subscribe((customerInfo) => (this.customerInfo = customerInfo));
    this.contractService
      .getServicePlanFields()
      .subscribe((servicePlanInfo) => (this.servicePlanInfo = servicePlanInfo));
    this.contractService
      .getProductCoveredFields()
      .subscribe((productCoveredInfo) => (this.productCoveredInfo = productCoveredInfo));
  }

  onSubmit() {
    // functionality for on submit
  }
}
