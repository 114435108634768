import { FormField } from './form-field';

export class RadioButtonField extends FormField<string> {
  override controlType = 'radio-button';
  override type: string;
  override options: { key: string; value: string }[] = [];

  constructor(options = {}) {
    super(options);
    this.type = options[`type`] || '';
    this.options = options[`options`] || [];
  }
}
