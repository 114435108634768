<div class="summary-section">
  <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="productInfoTable">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of cols; let i = index">
          <strong *ngIf="i===0">{{rowData[col.field]}}</strong><span *ngIf="i!==0">{{rowData[col.field]}}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>