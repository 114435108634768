<div class="import-file">
  <div class="main-tab__titles">
    <div class="title">Template</div>
    <form class="content" [formGroup]="form">
      <div class="form-row" *ngFor="let formField of formFields">
        <form-field [formField]="formField" [form]="form"></form-field>
      </div>
      <div class="button-bar"><button class="crm-button">Verify</button></div>
    </form>
    <div class="crm-panel__main">
      <label>Content</label>
      <div class="modal-content">
        <div class="left">
          <div class="email" *ngIf="currentScreen ==='des'">
            <p-editor [(ngModel)]="emailTemplate"></p-editor>
          </div>
          <div class="email" *ngIf="currentScreen ==='html'">
            <textarea [(ngModel)]="emailTemplate"></textarea>
          </div>
          <div class="email email-container" *ngIf="currentScreen ==='pre'">
            <div [innerHTML]="emailTemplate"></div>
          </div>
        </div>
        <div class="right">
          <ul>
            <li (click)="displaySection('des')" [class]="currentScreen ==='des' ? 'active' : ''">
              DES
            </li>
            <li (click)="displaySection('html')" [class]="currentScreen ==='html' ? 'active' : ''">
              HTML
            </li>
            <li (click)="displaySection('pre')" [class]="currentScreen ==='pre' ? 'active' : ''">
              PRE
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
