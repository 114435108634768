import { Component, OnInit } from '@angular/core';
import { CrmDocumentType } from '../../shared/constants/enums';
import { ContractService } from '../contract.service';
import { RequiredColummns } from './contract-document-upload-tab.constants';
import { TabHandlerService } from '../../services/tab-handler.service';

@Component({
  selector: 'ct-file-upload',
  templateUrl: 'contract-document-upload-tab.template.html',
})
export class ContractDocumentUploadComponent implements OnInit {
  documentType = CrmDocumentType;
  contractRequiredColumns = RequiredColummns.ColumnNames;
  attachmentId: number;
  tabKey: string;
  constructor(
    private contractService: ContractService,
    private tabHandlerService: TabHandlerService,
  ) {}

  ngOnInit() {
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        const customerId = this.contractService.contractConfig.searchParams.customerId
          ? this.contractService.contractConfig.searchParams.customerId
          : this.contractService.contractDataModel.leftBaseSection?.customerId;
        if (this.tabKey === tab && customerId) {
          this.attachmentId =
            this.contractService.contractDataModel.leftBaseSection.contractsIdOriginal;
        }
      },
    });
  }
}
