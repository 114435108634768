import { Component } from '@angular/core';

@Component({
  selector: 'catalog',
  templateUrl: 'catalog.template.html',
  styleUrls: ['catalog.scss'],
})
export class CatalogComponent {
  constructor() {}
}
