import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ExpenseChargeService } from './expense-charges.service';
import { buttonStatus } from '../../../../shared/constants/button-class';
import {
  ellipsisCrudOptions,
  ellipsisHeaderOptions,
  ellipsisHeaderCrudOptions,
} from '../../../../shared/constants/ellipsis-options';
import { KeyMap } from '../../../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { getAmount, getApprovedAmount } from './expense-charges.helper';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { Subscription } from 'rxjs';
import { ALLOWED_ROLES_AWAITING_INVOICE_STATUS } from '../../../service.constant';

const PRE_APPROVAL_REQUEST_ALLOW_ROLES = [
  'Call Center Manager',
  'Call Center Supervisor',
  'Claims Manager',
  'Claims 1',
  'Claims 1 Plus',
  'CSR 2',
  'CSR 2 Plus',
  'CSR 3',
  'Data Administration',
  'Special Team A-CC-SN',
  'Back Office',
  'Back Office Lead',
];
const FIFTYBACK_ALLOW_ROLES = ['Admin', 'Claims Manager', 'Claims 1', 'Claims 1 Plus'];
@Component({
  selector: 'expense-charges',
  templateUrl: 'expense-charges.template.html',
  styleUrls: [
    '../../../../shared/styles/crm-styles.scss',
    '../../../../shared/styles/crm-table.scss',
    './expense-charges.scss',
  ],
  providers: [ExpenseChargeService, MessageService],
})
export class ExpenseChargesComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  // Need a copy of the objects within the array so we do not update those by reference for other uses.
  ellipsisOptions: any[] = ellipsisCrudOptions.map((object) => ({ ...object }));
  ellipsisHeaderOptions: any[] = ellipsisHeaderOptions.map((object) => ({ ...object }));
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  dropdown: any;
  totalRequestedAmount: any;
  totalAdjustedAmount: any;
  totalDiscount: any;
  totalApprovedAmount: any;
  displayExpenseChargeModal = false;
  selectedColumns: any[];
  selectedRowIndex: number;
  selectedRow: any;
  expenseReadOnly: boolean;
  expenseEnableAdd: boolean;
  servicerMarkupRates = [];
  servicerRatesSchedule = [];
  isEditEnable = true;
  querySubscriptions: Subscription[] = [];
  @ViewChild(Table) table: Table;
  constructor(
    private expenseChargeService: ExpenseChargeService,
    private serviceOrderDataService: ServiceOrderDataService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.expenseChargeService.getRateProfile().subscribe((data) => {
      this.dataList = [];
      this.totalAmount(this.dataList);
    });
    this.dropdown = {
      status: [
        { label: 'select', value: '' },
        { label: 'paid', value: 'paid' },
        { label: 'unpaid', value: 'unpaid' },
      ],
      expenseType: [
        { label: 'select', value: '' },
        { label: 'type1', value: 'type 1' },
        { label: 'type2', value: 'type 2' },
      ],
    };
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'select' },
      { field: 'expenseType', header: 'EXPENSE TYPE', type: 'select' },
      { field: 'description', header: 'DESC', type: 'text' },
      { field: 'partNumber', header: 'PART #', type: 'text' },
      { field: 'modelNumber', header: 'Model #', type: 'text' },
      { field: 'serialNumber', header: 'Serial #', type: 'text' },
      { field: 'quantity', header: 'Quantity', type: 'number' },
      { field: 'requestedPrice', header: 'REQ. PRICE', type: 'text', isAmount: true },
      { field: 'requestedMarkup', header: 'REQ. MARKUP', type: 'text', isAmount: true },
      { field: 'adjustedAmount', header: 'ADJ. AMOUNT', type: 'text', isAmount: true },
      { field: 'requestedAmount', header: 'REQ. AMOUNT', type: 'text', isAmount: true },
      { field: 'totalAmount', header: 'TOTAL AMOUNT', type: 'text', isAmount: true },
      { field: 'adjustedPrice', header: 'ADJ. PRICE', type: 'text', isAmount: true },
      { field: 'adjustedMarkup', header: 'ADJ. MARKUP', type: 'text', isAmount: true },
      { field: 'discount', header: 'DISCOUNT %', type: 'text' },
      { field: 'approvedAmount', header: 'APP. AMOUNT', type: 'text', isAmount: true },
    ];

    this.querySubscriptions.push(
      this.serviceOrderDataService.isServicerInfoAvailable.subscribe({
        next: (isServicerInfoAvailable) => {
          if (isServicerInfoAvailable) {
            const { servicerInfo } = this.serviceOrderDataService.serviceOrderDataModel;
            this.servicerMarkupRates = servicerInfo && servicerInfo.servicerMarkupRates;
            this.servicerRatesSchedule = servicerInfo && servicerInfo.servicerRatesSchedule;
          }
        },
      }),
    );
    // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
    // No user can edit the expense charges details anymore(PD-907057)
    this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
      this.isEditEnable = isEnable;
      this.ellipsisOptions
        .filter((c) => c.label === 'Update' || c.label === 'Delete')
        .forEach((x) => (x.disabled = !this.isEditEnable));
      this.ellipsisHeaderOptions
        .filter((c) => c.label === 'Add' || c.label === 'Print' || c.label === 'Export')
        .forEach((x) => (x.disabled = !this.isEditEnable));
    });

    this.querySubscriptions.push(
      this.serviceOrderDataService.isServiceOrderInfoAvailable.subscribe({
        next: (isServiceOrderInfoAvailable) => {
          if (
            isServiceOrderInfoAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave
          ) {
            this.ellipsisOptions
              .filter((c) => c.label === 'Update' || c.label === 'Delete')
              .forEach((x) => (x.disabled = true));
            this.ellipsisHeaderOptions
              .filter((c) => c.label === 'Add')
              .forEach((x) => (x.disabled = true));
          }
        },
      }),
    );

    this.selectedColumns = this.cols.slice(0, 10);
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          if (
            isClaimDataAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.claimNumber &&
            !this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag
          ) {
            // Retrieve call
            const { claimStatusDescription } =
              this.serviceOrderDataService.serviceOrderDataModel.claimData;
            if (
              claimStatusDescription &&
              (claimStatusDescription.toUpperCase() === 'IN PROGRESS' ||
                this.azureService.roleName === 'Admin' ||
                (PRE_APPROVAL_REQUEST_ALLOW_ROLES.includes(this.azureService.roleName) &&
                  claimStatusDescription.toUpperCase() === 'PRE-APPROVAL REQUEST') ||
                (ALLOWED_ROLES_AWAITING_INVOICE_STATUS.includes(this.azureService.roleName) &&
                  claimStatusDescription.toUpperCase() === 'AWAITING INVOICE') ||
                (FIFTYBACK_ALLOW_ROLES.includes(this.azureService.roleName) &&
                  claimStatusDescription.toUpperCase() === 'DRAFT'))
            ) {
              // Need a copy of the objects within the array so we do not update those by reference for other uses.
              this.ellipsisHeaderOptions = ellipsisHeaderOptions.map((object) => ({ ...object }));
            }
            const { claimNumber, serviceOrderNumber } =
              this.serviceOrderDataService.serviceOrderDataModel.claimData;
            let expenseNumber = serviceOrderNumber;
            if (!expenseNumber) {
              expenseNumber = claimNumber;
            }
            this.querySubscriptions.push(
              this.expenseChargeService
                .getExpenseCharges(expenseNumber, this.azureService.accountId)
                .subscribe(({ data, loading }) => {
                  if (data.getExpenseChargesByClaim && data.getExpenseChargesByClaim.length) {
                    const expenseCharges = data.getExpenseChargesByClaim.map((it) => {
                      const ex = Object.assign({},it);
                      if (ex.__typename) {
                        delete ex.__typename;
                      }
                      return ex;
                    });
                    const list = expenseCharges.map((it) => {
                      const ex = Object.assign({},it);
                      if(!ex.status){
                        ex.status = 'Requested';
                      }
                      if (ex.status?.toUpperCase() === 'DENIED') {
                        ex.adjustedAmount = 0;
                        ex.approvedAmount = 0;
                      } else {
                        ex.requestedAmount = getAmount(
                          ex.requestedPrice,
                          ex.requestedMarkup,
                          ex.quantity,
                        );
                        ex.approvedAmount = getApprovedAmount(
                          ex.adjustedPrice,
                          ex.adjustedMarkup,
                          ex.discount,
                          ex.quantity,
                        );
                        ex.adjustedAmount = getAmount(
                          ex.adjustedPrice,
                          ex.adjustedMarkup,
                          ex.quantity,
                        );
                      }
                      return ex;
                    });
                    this.dataList = [...list];
                    this.populateValidatorTool(this.dataList);
                  } else {
                    // Clear out the fields if nothing was found (mainly for new sub claims)
                    this.dataList = [];
                  }
                  this.requestLoLData();
                  this.serviceOrderDataService.serviceOrderDataModel.expenseCharges = [
                    ...this.dataList,
                  ];
                  this.serviceOrderDataService.isExpenseDataAvailable.next(true);
                  this.totalAmount(this.dataList);
                }),
            );
          }
        },
      }),
    );
  }

  requestLoLData() {
    const { productsIdOriginal } =
      this.serviceOrderDataService.serviceOrderDataModel.productInfoModel;
    this.querySubscriptions.push(
      this.serviceOrderDataService.getRealLimitOfLiabilityResult(productsIdOriginal).subscribe(
        ({ data }) => {
          this.serviceOrderDataService.setvalidatorDataLolModel(data.getRealLimitOfLiabilityById);
          this.serviceOrderDataService.serviceOrderDataModel.lolDetailsInfo =
            data.getRealLimitOfLiabilityById;
        },
        (err) => {
          throw err;
        },
      ),
    );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, index?: number) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
    this.selectedRowIndex = index;
    this.selectedRow = item;
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onRowEdit() {
    let readOnlyFlag = false;
    if (this.selectedRow.componentModifySettings) {
      readOnlyFlag = this.selectedRow.componentModifySettings.readOnly;
    }
    const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
    if (
      claimData &&
      (claimData.claimStatusDescription.toUpperCase() === 'IN PROGRESS' ||
        this.azureService.roleName === 'Admin' ||
        (PRE_APPROVAL_REQUEST_ALLOW_ROLES.includes(this.azureService.roleName) &&
          claimData.claimStatusDescription.toUpperCase() === 'PRE-APPROVAL REQUEST') ||
        (ALLOWED_ROLES_AWAITING_INVOICE_STATUS.includes(this.azureService.roleName) &&
          claimData.claimStatusDescription.toUpperCase() === 'AWAITING INVOICE') ||
        (FIFTYBACK_ALLOW_ROLES.includes(this.azureService.roleName) &&
          claimData.claimStatusDescription.toUpperCase() === 'DRAFT')) &&
      !readOnlyFlag
    ) {
      this.onAddExpenseCharges();
    }
  }

  onRowEditInit(rowData: any, rowIndex: number) {
    // this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.totalAmount(this.table.value);
    this.isEditing = false;
  }
  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  totalAmount(data: any[]) {
    this.totalRequestedAmount = 0.0;
    this.totalAdjustedAmount = 0.0;
    this.totalApprovedAmount = 0.0;
    this.totalDiscount = 0.0;
    for (const singleExpense of data) {
      const status = singleExpense.status;
      this.totalRequestedAmount += parseFloat(singleExpense.requestedAmount) || 0.0;
      if (status === 'Approved' || status === 'Paid' || status === 'Requested') {
        this.totalApprovedAmount += parseFloat(singleExpense.approvedAmount) || 0.0;
        this.totalAdjustedAmount += parseFloat(singleExpense.adjustedAmount) || 0.0;
      }
    }
    this.totalDiscount += this.totalAdjustedAmount - this.totalApprovedAmount;
    this.totalRequestedAmount = this.totalRequestedAmount.toFixed(2);
    this.totalAdjustedAmount = this.totalAdjustedAmount.toFixed(2);
    this.totalApprovedAmount = this.totalApprovedAmount.toFixed(2);
    this.totalDiscount = this.totalDiscount.toFixed(2);
  }
  onClickAdd($event) {
    const { claimData } = this.serviceOrderDataService.serviceOrderDataModel;
    if (
      claimData &&
      (claimData.claimStatusDescription.toUpperCase() === 'IN PROGRESS' ||
        this.azureService.roleName === 'Admin' ||
        (PRE_APPROVAL_REQUEST_ALLOW_ROLES.includes(this.azureService.roleName) &&
          claimData.claimStatusDescription.toUpperCase() === 'PRE-APPROVAL REQUEST') ||
        (ALLOWED_ROLES_AWAITING_INVOICE_STATUS.includes(this.azureService.roleName) &&
          claimData.claimStatusDescription.toUpperCase() === 'AWAITING INVOICE') ||
        (FIFTYBACK_ALLOW_ROLES.includes(this.azureService.roleName) &&
          claimData.claimStatusDescription.toUpperCase() === 'DRAFT'))
    ) {
      this.selectedRow = {
        expenseType:'Parts',
        status:'Requested'
      };
      this.onAddExpenseCharges();
    }
  }

  onAddExpenseCharges() {
    this.displayExpenseChargeModal = true;
  }
  onRowDelete(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    const selectedItem = Object.assign({},this.selectedItem);
    selectedItem.adjustedAmount = selectedItem.adjustedAmount
      ? parseFloat(selectedItem.adjustedAmount)
      : 0;
    selectedItem.requestedAmount = selectedItem.adjustedAmount
      ? parseFloat(selectedItem.requestedAmount)
      : 0;

    delete selectedItem.componentModifySettings;
    delete selectedItem.approvedAmount;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this expense charge?',
      accept: () => {
        this.querySubscriptions.push(
          this.serviceOrderDataService
            .createUpdateExpenseCharges([{...selectedItem, isDeleted: true}])
            .subscribe((res) => {
              this.getExpenseReports();
            }),
        );
      },
    });
  }

  onCreateUpdate(d) {
    this.getExpenseReports();
  }

  getExpenseReports() {
    const { claimNumber, serviceOrderNumber } =
      this.serviceOrderDataService.serviceOrderDataModel.claimData;
    let expenseNumber = serviceOrderNumber;
    if (!expenseNumber) {
      expenseNumber = claimNumber;
    }
    if (expenseNumber && !this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag) {
      this.querySubscriptions.push(
        this.expenseChargeService
          .getExpenseCharges(expenseNumber, this.azureService.accountId)
          .subscribe(({ data, loading }) => {
            if (data.getExpenseChargesByClaim && data.getExpenseChargesByClaim.length) {
              const expenseCharges = data.getExpenseChargesByClaim.map((it) => {
                  const ex = Object.assign({},it);
                  if (ex.__typename) {
                    delete ex.__typename;
                  }
                  return ex;
              });
              const list = expenseCharges.map((it) => {
                const ex = Object.assign({},it);
                if(!ex.status){
                  ex.status = 'Requested';
                }
                if (ex.status?.toUpperCase() === 'DENIED') {
                  ex.adjustedAmount = 0;
                  ex.approvedAmount = 0;
                } else {
                  ex.requestedAmount = getAmount(
                    ex.requestedPrice,
                    ex.requestedMarkup,
                    ex.quantity,
                  );

                  ex.approvedAmount = getApprovedAmount(
                    ex.adjustedPrice,
                    ex.adjustedMarkup,
                    ex.discount,
                    ex.quantity,
                  );

                  ex.adjustedAmount = getAmount(ex.adjustedPrice, ex.adjustedMarkup, ex.quantity);
                }
                return ex;
              });
              this.dataList = [...list];
              this.populateValidatorTool(expenseCharges);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Saved successfully.',
              });
            } else {
              this.dataList = [];
            }
            this.serviceOrderDataService.serviceOrderDataModel.expenseCharges = [...this.dataList];
            this.totalAmount(this.dataList);
            this.serviceOrderDataService.isExpenseDataAvailable.next(true);
          }),
      );
      this.totalAmount(this.dataList);
    }
  }

  populateValidatorTool(data) {
    this.serviceOrderDataService.setvalidatorPricesDataModel(data);
    this.requestLoLData();
    this.serviceOrderDataService.isValidatorDataAvailable.next(true);
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
