<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<div class="crm-container">
  <app-section-header [title]="'Service Info'" [optionHeader]="opHeader" />

  <div class="sub-section">
    <p-table styleClass="p-datatable-gridlines" [columns]="cols" [value]="dataList" dataKey="id" editMode="row">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="output">{{rowData[col.field]}}</ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots"
                (click)="ellipsisClick($event,rowData,opRow, ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData,ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<service-info-modal modalTitle="Service Info" [(displayDialog)]="displayServiceInfoModal"
  (formSubmitEvent)="onCreateUpdate($event)" (formCancelEvent)="onCancel($event)" [formData]="selectedRow"
  *ngIf="displayServiceInfoModal">
</service-info-modal>
<p-toast> </p-toast>
