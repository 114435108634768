import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SearchInsuranceCompanyService } from './search-insurance-company.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';
import { Subscription } from 'rxjs';
import { copy } from '../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'search-insurance-company',
  templateUrl: 'search-insurance-company.template.html',
  providers: [SearchInsuranceCompanyService],
})
export class SearchInsuranceCompanyComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  status: any[];
  newTab: Tab;
  op: OverlayPanel;
  querySubscription: Subscription;
  isLoading: boolean;
  constructor(
    private searchInsuranceCompanyService: SearchInsuranceCompanyService,
    private tabService: TabsService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'status' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'name', header: 'NAME', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
    ];

    this.isLoading = true;
    this.searchParams.status = this.searchParams.status ? this.searchParams.status : '';
    this.querySubscription = this.searchInsuranceCompanyService
      .getInsuranceCompanySearchResult(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifedData = data.getInsuranceCompanySearchResults.map((item) => {
            return { ...item };
          });

          this.dataList = [...modifedData];
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );

    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Insurance Company',
      category: 'Insurance Company',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Insurance Company',
      category: 'Insurance Company',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.tabService.createTab({
      header: 'Insurance Company',
      content: this.selectedItem.insuranceComapaniesIdOriginal,
      type: 'Insurance Company',
    });
  }

  openTab(data: any) {
    this.newTab = {
      header: 'Insurance Company',
      content: data.insuranceComapaniesIdOriginal,
      type: 'Insurance Company',
    };
    this.tabService.createTab(this.newTab);
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
