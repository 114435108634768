export const ellipsisHeaderOptions = [
  { label: 'Add Configuration Row', value: 'Add Configuration row', callback: 'onRowCreate' },
];
export const ellipsisRowOptions = [
  { label: 'Update Configuration Row', value: 'update configuration row', callback: 'onRowUpdate' },
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' },
];
export const adminConfigMenuEllipsisHeaderOptions = [
  { label: 'Add New MenuType', value: 'add', callback: 'onClickAdd' },
];
