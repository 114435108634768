import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckBoxField } from '../../../../shared/form-field/checkbox-field';
import { DropdownField } from '../../../../shared/form-field/dropdown-field';
import { TextboxField } from '../../../../shared/form-field/textbox-field';
import { FormField } from '../../../../shared/form-field/form-field';
import { TextAreaField } from '../../../../shared/form-field/textarea-field';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable()
export class ExpenseChargeModalService {
  templateDetails = {};
  typeMapping = {};

  constructor(private apollo: Apollo) {}

  getExpenseChargesFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        required: true,
        key: 'status',
        label: 'Status',
        value: { key: 'Requested', value: 'Requested' },
        options: [
          { key: 'Requested', value: 'Requested' },
          { key: 'Paid', value: 'Paid' },
          { key: 'Approved', value: 'Approved' },
          { key: 'Denied', value: 'Denied' },
        ],
        isSingleselect: true,
        isChangeEvent: true,
        order: 0,
      }),
      new DropdownField({
        required: true,
        key: 'expenseType',
        label: 'Expense Type ',
        value: { key: 'Parts', value: 'Parts' },
        options: [],
        filter: true,
        isSingleselect: true,
        isChangeEvent: true,
        order: 1,
      }),
      new TextboxField({
        key: 'description',
        label: 'Description',
        order: 2,
      }),
      new TextboxField({
        key: 'partNumber',
        label: 'Part Number',
        order: 3,
      }),
      new TextboxField({
        key: 'modelNumber',
        label: 'Model Number',
        order: 4,
      }),
      new TextboxField({
        key: 'serialNumber',
        label: 'Serial Number',
        order: 5,
      }),
      new TextboxField({
        key: 'quantity',
        label: 'Quantity',
        type: 'number',
        required: true,
        value: 1,
        isChangeEvent: true,
        order: 6,
      }),
      new TextboxField({
        key: 'requestedPrice',
        label: 'Requested Price',
        isChangeEvent: true,
        required: true,
        order: 7,
      }),
      new TextboxField({
        key: 'requestedMarkup',
        label: 'Requested Markup',
        disabled: true,
        order: 8,
      }),
      new TextboxField({
        key: 'requestedAmount',
        label: 'Requested Amount',
        disabled: true,
        order: 9,
      }),
      new TextboxField({
        key: 'adjustedPrice',
        label: 'Adjusted Price',
        isChangeEvent: true,
        order: 11,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Adjusted Price',
          category: 'Claim',
        },
      }),
      new TextboxField({
        key: 'adjustedMarkup',
        label: 'Adjusted Markup',
        isChangeEvent: true,
        order: 12,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Adjusted Markup',
          category: 'Claim',
        },
      }),
      new TextboxField({
        key: 'adjustedAmount',
        label: 'Adjusted Amount',
        disabled: true,
        order: 13,
      }),
      new TextboxField({
        key: 'discount',
        label: 'Discount %',
        isChangeEvent: true,
        order: 14,
        hasPermissions: true,
        permissions: {
          type: 'sideMenu',
          operation: 'EDIT',
          permission: 'Adjusted Discount',
          category: 'Claim',
        },
      }),
      new TextboxField({
        key: 'approvedAmount',
        label: 'Approved Amount',
        disabled: true,
        order: 15,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
