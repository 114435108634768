<app-tab-spinner *ngIf="isLoading" [spinnerType]="'transparent'"></app-tab-spinner>
<div class="crm-container">
  <app-section-header [optionHeader]="opHeader" [title]="'Service Area'" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading" />
    <p-table #temp *ngIf="!dataList"></p-table>
    <div class="text-left">ZipCode <input type="text" (input)="getZipSearch($event)" /></div>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" #dt *ngIf="dataList && dataList.length > 0"
      [columns]="cols" [value]="dataList" [scrollable]="true" [rows]="100" scrollHeight="1000px" [virtualScroll]="true"
      [totalRecords]="totalRecords" [virtualRowHeight]="20">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }" *ngIf="ellipsisOptions.length > 0"></th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <p-multiSelect *ngSwitchCase="'zipCode'" [options]="zipCodes" defaultLabel="All Zip Codes"
              (onChange)="dt.filter($event.value, col.field, 'in')" appendTo="body" [virtualScroll]="true"
              [itemSize]="30">
            </p-multiSelect>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="input-text" *ngIf="col.type === 'text'" type="text" [(ngModel)]="rowData[col.field]" />
                <input *ngIf="col.type === 'checkbox'" type="checkbox" [(ngModel)]="rowData[col.field]" />
              </ng-template>
              <ng-template *ngIf="col.type === 'text' || col.type === 'select'"
                pTemplate="output">{{rowData[col.field]}}</ng-template>
              <ng-template *ngIf="col.type === 'checkbox'" pTemplate="output">
                <input type="checkbox" [(ngModel)]="rowData[col.field]" /></ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div *ngIf="!editing">
              <div class="three-dots" 
                (click)="ellipsisClick($event,rowData,opRow, ri)">
                <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
              </div>
            </div>
            <button class="ui-button-info" [style.display]="'none'" id="{{editConstant + rowData.id}}" pButton="pButton"
              type="button" pInitEditableRow="pInitEditableRow" icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"></button>
            <button class="ui-button-success" *ngIf="editing" pButton="pButton" type="button"
              pSaveEditableRow="pSaveEditableRow" icon="pi pi-check" style="margin-right: 0.5em"
              (click)="onRowEditSave(rowData)"></button>
            <button class="ui-button-danger" *ngIf="editing" pButton="pButton" type="button"
              pCancelEditableRow="pCancelEditableRow" icon="pi pi-times"
              (click)="onRowEditCancel(rowData, ri)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-toast></p-toast>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opRow) : ellipsisOptionClick($event)">
  </p-listbox></p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event, opHeader) : ellipsisOptionClick($event)">
  </p-listbox>
</p-overlayPanel>
<p-dialog [header]="'Service Area Zip Code'" [(visible)]="displaySADialog" showeffect="fade" [modal]="true"
  [styleClass]="'add-dialog'" [blockScroll]="true" *ngIf="displaySADialog">
  <app-tab-spinner *ngIf="isLoading" spinnerType="transparent"></app-tab-spinner>
  <div class="modal-content" *ngIf="!isLoading">
    <form class="content" (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="grid">
        <div class="col-6">
          <form-field [formField]="formFields[0]" [form]="form" (changeEvent)="onChange($event)"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[1]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[2]" [form]="form"></form-field>
        </div>
        <div class="col-6">
          <form-field [formField]="formFields[3]" [form]="form"></form-field>
        </div>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="submit" (click)="onSubmit()" label="Cancel"
        [disabled]="!form.valid">Save</button><button class="crm-button" type="button" (click)="onCancel()"
        label="Cancel">Cancel</button>
    </div>
  </ng-template>
</p-dialog>