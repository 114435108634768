import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { IPersonnelStatus } from '../../../interfaces/personnel-state.interface';
import { StringService } from '../../../services/string.service';
import { PollingService } from '../../../services/polling.service';
import { MsalService } from '@azure/msal-angular';
import { AzureLoginService } from '../../../services/azure-login.service';
import { environment } from 'src/environments/environment';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from '../../../interfaces/role-authorization.interface';
import { RoleAuthorizationService } from '../../../services/role-authorization.service';

@Component({
  selector: 'kairos-nav-bar',
  templateUrl: 'kairos-nav-bar.template.html',
  styleUrls: ['kairos-nav-bar.scss'],
})
export class KairosNavBarComponent implements OnInit, OnDestroy {
  public strings: any = null;
  public showLogoutConfirmation = false;
  public currentUser$: Observable<string>;
  public fullName$: Observable<string>;
  public currentStatus: any;
  public statuses = [];
  public showStatusList = false;
  public authorizations: string[];
  public isStatusOpen = false;
  public isNavLoading = true;
  public showSummary = false;
  public processes = [];
  public processIsLoading = false;
  private unsubscribe: Subject<void> = new Subject();

  private gotStatuses = false;
  private loginStatus: IPersonnelStatus = null;
  private logoffStatus: IPersonnelStatus = null;
  private authDetails: IRoleAuthorizationService;
  public button: Array<any>;

  constructor(
    private stringService: StringService,
    private pollingService: PollingService,
    public router: Router,
    private msalService: MsalService,
    private azureService: AzureLoginService,
    private roleService: RoleAuthorizationService,
  ) {
    // this.currentUser$ = this.store.select(getCurrentUser);
    this.fullName$ = azureService.getFullName(); // this.store.select(getFullName);
    this.currentStatus = { value: 'available', checked: true, display: 'available' };

    const classes = {
      BREAK: 'break',
      LOGIN: 'available',
      LOGOFFS: 'unavailable',
    };
    // this.store
    //   .select(getAvailableStatuses)
    //   .pipe(takeUntil(this.unsubscribe))
    //   .subscribe((statuses) => {
    //     this.statuses = statuses
    //       .filter((status) => status && status.display && status.display.toUpperCase() === 'Y')
    //       .map((status) => {
    //         return {
    //           display: status.status,
    //           customClass: classes[status.code] || 'unavailable',
    //           value: status.status,
    //         };
    //       });
    //     if (this.currentStatus) {
    //       const currentStatus = this.statuses.find((item) => item.value === this.currentStatus.value);
    //       if (currentStatus) {
    //         currentStatus.checked = true;
    //       }
    //     }
    //     for (const status of statuses) {
    //       if (status.code === 'LOGIN') {
    //         this.loginStatus = status;
    //       } else if (status.code === 'LOGOFFU') {
    //         this.logoffStatus = status;
    //       }
    //     }

    //     if (this.loginStatus && this.logoffStatus && !this.gotStatuses) {
    //       this.gotStatuses = true;
    //       this.store.dispatch({
    //         type: personnelActions.SET_PERSONNEL_STATUS_ON_SERVER,
    //         payload: { status: this.loginStatus.status },
    //       });
    //     }
    //   });

    this.stringService.strings$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((strings) => (this.strings = strings));
  }

  public ngOnInit() {
    this.button = [{ name: 'Dash', hidden: false, disabled: false, type: 'button' }];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: KairosNavBarComponent,
      generalArray: this.button,
    };
    // this.store.dispatch({
    //   type: personnelActions.GET_AVAILABLE_STATUSES,
    // });

    const routerUrl = this.router.url;
    let globalNavTab;

    if (routerUrl.includes('support-task')) {
      globalNavTab = 'support-task';
    } else if (routerUrl.includes('fulfillment')) {
      globalNavTab = 'fulfillment';
    } else {
      globalNavTab = 'resource-management';
    }

    // this.store.dispatch(new navigationActions.SetGlobalNavTabAction({ globalNavTab }));
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public confirmLogOut() {
    this.showLogoutConfirmation = true;

    this.pollingService.stopPolling();
  }

  public cancelLogOut() {
    this.showLogoutConfirmation = false;

    this.pollingService.startPolling();
  }

  public async onApply(process) {
    if (!process.assigned || !process.assigned.length) {
      return;
    }
    const fulfillmentIds = this.getFulfillmentCenters(process.assigned);
    const request = fulfillmentIds.map((fulfillmentCenterId) => {
      const jobs = process.assigned.filter(
        (p) => p.tech && p.tech.FulfillmentCenter === fulfillmentCenterId,
      );
      const techIds = this.getTechIds(jobs);
      const techsWorkOrders = techIds.map((techId) => {
        const workOrderNumbers = process.assigned
          .filter((p) => p.tech && p.tech.TechId === techId)
          .map((p) => p.workOrderNumber);
        return { workOrderNumbers, techId };
      });
      return { fulfillmentCenterId, techsWorkOrders };
    });

    const index = this.processes.indexOf(process);
    if (index > -1) {
      this.processes.splice(index, 1);
    }
  }

  public onCancel(process) {
    const index = this.processes.indexOf(process);
    if (index > -1) {
      this.processes.splice(index, 1);
    }
  }

  public onStatusSelected(statusList) {
    if (statusList.length) {
      const status = statusList[0];
      if (status.value !== this.currentStatus.value) {
        this.isStatusOpen = false;
        // this.store.dispatch(new personnelActions.SetPersonnelStatusOnServerAction({ status: status.value }));
      } else {
        console.log('Status not changed');
      }
    }
  }

  public logOut() {
    this.msalService.logout().subscribe((data) => {
      localStorage.setItem('isLogout', 'true');
      this.router.navigate(['']);
    });
  }

  public handleTabClick(tab) {
    // this.store.dispatch(
    //   new navigationActions.SetGlobalNavTabAction({
    //     globalNavTab: tab,
    //   })
    // );
  }

  public getClass(value) {
    const option = this.statuses.find((item) => item.value === value);
    return option && option.customClass;
  }

  private getFulfillmentCenters(processes) {
    return processes.reduce((items, item) => {
      if (item.tech && items.indexOf(item.tech.FulfillmentCenter) === -1) {
        items.push(item.tech.FulfillmentCenter);
      }
      return items;
    }, []);
  }

  private getTechIds(processes) {
    return processes.reduce((items, item) => {
      if (item.tech && items.indexOf(item.tech.TechId) === -1) {
        items.push(item.tech.TechId);
      }
      return items;
    }, []);
  }

  public launchDash() {
    window.open(environment.dashUrl);
  }
}
