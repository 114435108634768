import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
})
export class SectionHeaderComponent {
  @Input() title: string;
  @Output() click = new EventEmitter<OverlayPanel>();
  @Input() optionHeader: OverlayPanel;
  @Input() permission: any = null;

  ellipsisClick(event: Event) {
    this.optionHeader.toggle(event);
  }
}
