import { Component, Input, OnInit } from '@angular/core';
import { ManufacturerService } from './manufacturer.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisCrudOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { manufacturerEllipsisHeaderOptions } from '../catalog.constant';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RolePermissionService } from '../../common/role-permission.service';
@Component({
  selector: 'manufacturer',
  templateUrl: 'manufacturer.template.html',
  styleUrls: ['../catalog.scss'],
  providers: [ManufacturerService, MessageService],
})
export class ManufacturerComponent implements OnInit {
  dataList: any[] = [];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisCrudOptions;
  ellipsisHeaderOptions: any[] = manufacturerEllipsisHeaderOptions;
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  globalIndex: number;
  usrRoll: string;
  clonedata: boolean;

  @Input() searchParams: any;
  querySubscription: Subscription;
  isLoading = true;
  lastOffsetVal = 0;
  offsetLimit = 50;

  constructor(
    private manufacturerService: ManufacturerService,
    private azureService: AzureLoginService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {
    this.usrRoll = azureService.roleName;
    this.clonedata = true;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.cols = [{ field: 'name', header: 'Name', type: 'text' }];
    this.getManufacturerSearchData();
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Manufacturers',
      category: 'Catalogs',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Manufacturers',
      category: 'Catalogs',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  getManufacturerSearchData() {
    this.isLoading = true;
    if (this.searchParams !== 'Manufacturer') {
      this.searchParams = {
        manufacturersName: this.searchParams.name
          ? `${this.searchParams.name.toUpperCase()}`
          : `${
              this.searchParams.manufacturersName
                ? this.searchParams.manufacturersName.toUpperCase()
                : ''
            }`,
      };
    } else {
      this.searchParams = { manufacturersName: '' };
    }
    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;
    this.searchParams.azureUserId = this.azureService.accountId;

    this.lastOffsetVal += this.offsetLimit;
    this.querySubscription = this.manufacturerService
      .getManufacturer(this.searchParams)
      .subscribe(({ data, loading }: any) => {
        this.isLoading = loading;
        data.getManufacturerByName.map((manufacturer) => {
          if (this.dataList.length === 0) {
            this.dataList.push({
              name: manufacturer.manufacturersName,
              __typename: manufacturer.__typename,
              id: this.dataList.length,
              ManufacturesIDOriginal: manufacturer.ManufacturesIDOriginal,
            });
          } else {
            const index = this.dataList.findIndex(
              (e) => e.id === manufacturer.ManufacturesIDOriginal,
            );
            if (index === -1) {
              this.dataList.push({
                name: manufacturer.manufacturersName,
                __typename: manufacturer.__typename,
                id: this.dataList.length,
                ManufacturesIDOriginal: manufacturer.ManufacturesIDOriginal,
              });
            } else {
              this.dataList[index].name = manufacturer.manufacturersName;
            }
          }
        });
      });
  }

  ellipsisOptionClick(event) {
    if (event.value === 'add') {
      this.dataList.unshift({
        name: '',
        __typename: 'new',
        id: this.dataList.length,
      });
      this.selectedItem = this.dataList[0];
      setTimeout(() => {
        this.onRowEdit();
      }, 100);
    } else if (event.value === 'delete') {
      this.confirmationService.confirm({
        message: `Are you sure you want to delete this Manufacturer?`,
        accept: () => {
          this.createUpdateNewManufacturerRequest({
            manufacturersName: this.selectedItem.name,
            ManufacturesIDOriginal: this.selectedItem.ManufacturesIDOriginal,
            IsDeleted: true,
          }).then(() => {
            this.dataList.splice(this.selectedItem.id, 1);
            this.dataList.forEach((data, id) => (data.id = id));
          });
        },
      });
    }
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    if (this.isEditing) {
      const btn = document.getElementById(`${this.editConstant + this.selectedItem.id}cancelBtn`);
      if (btn) {
        btn.click();
      }
      this.isEditing = false;
    }
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  onRowEdit() {
    this.isEditing = true;
    const btn = document.getElementById(this.editConstant + this.selectedItem.id);
    if (btn) {
      btn.click();
    }
  }

  onRowEditInit(rowData: any,overlaypanel:OverlayPanel) {
    overlaypanel.hide();
    if (this.clonedata) {
      this.clonedRowData[rowData.id] = { ...rowData };
    }
    this.clonedata = true;
  }

  onRowEditSave(rowData: any) {
    this.validateManufacturerName(rowData);
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
    if (rowData.__typename === 'new') {
      this.dataList.shift();
    }
  }

  async validateManufacturerName(rowData: any) {
    if (rowData.name.length >= 65) {
      this.clonedata = false;
      document.getElementById(this.editConstant + this.selectedItem.id).click();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `The manufacturer ${rowData.name.toUpperCase()}'s name exceed the names length allowed (65 characters), try other one.`,
      });
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.manufacturerService
        .getManufacturer({
          limit: 10,
          offset: 0,
          azureUserId: this.azureService.accountId,
          manufacturersName: rowData.name.toUpperCase(),
        })
        .subscribe(({ data }) => {
          if (data.getManufacturerByName.length === 0) {
            this.createUpdateNewManufacturerRequest(
              rowData.ManufacturesIDOriginal
                ? {
                    manufacturersName: rowData.name.toUpperCase(),
                    ManufacturesIDOriginal: rowData.ManufacturesIDOriginal,
                  }
                : {
                    manufacturersName: rowData.name.toUpperCase(),
                  },
            );
            this.isEditing = false;
          } else {
            this.isLoading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `The manufacturer ${rowData.name} already exist, try other one.`,
            });
            this.onRowEdit();
          }
          this.isLoading = false;
        });
    }
  }

  async createUpdateNewManufacturerRequest(params) {
    this.manufacturerService
      .createUpdateNewManufacturer({
        ...params,
        InsertUserName: this.azureService.accountId,
      })
      .subscribe(
        (response: any) => {
          const index = this.dataList
            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            .findIndex(
              (e) => e.name === response.data?.createUpdateNewManufacturer.manufacturersName,
            );
          if (index > -1) {
            this.dataList[index].ManufacturesIDOriginal =
              response.data.createUpdateNewManufacturer.ManufacturesIDOriginal;
          }
          this.dataList.forEach((data, id) => (data.id = id));
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Record ${params.IsDeleted ? 'Deleted' : 'Saved'} Successfully.`,
          });
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Could not finish the action`,
          });
        },
      );
  }
}
