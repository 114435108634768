import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Tab } from './tab';

@Injectable()
export class TabsService {
  private subject = new Subject<any>();

  createTab(tab: Tab) {
    this.subject.next(tab);
  }

  getTab(): Observable<any> {
    return this.subject.asObservable();
  }
}
