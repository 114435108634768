import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CoverageFormService {
  constructor() {}
  getCoverageForm(): Observable<any[]> {
    return of([
      {
        id: 1,
        state: 'x:',
        form: 'x',
        dealerException: 'x',
        action: 'x'
      }
    ]);
  }
}
