import { LargeNumberLike } from 'crypto';

export class FormField<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  disabled: boolean;
  validationRegex: string;
  validationMessage: string;
  isChangeEvent: boolean;
  suggestions: any[];
  errorMessages: any[];
  keyword: any;
  cols: number;
  rows: number;
  virtualScroll: boolean;
  filter: boolean;
  showToggleAll: boolean;
  isSingleselect: boolean;
  // Variables(isPDate, minDate, maxDate, selectionMode) are specific to p-calendar
  isPDate: boolean;
  minDate: Date;
  maxDate: Date;
  selectionMode: string;
  options: { key: string; value: string }[];
  maxLength: number;
  // Permissions to form
  hasPermissions: boolean;
  permissions: {
    type: string;
    operation: string;
    permission: string;
    category: string;
  };

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      disabled?: boolean;
      isPDate?: boolean;
      minDate?: Date;
      maxDate?: Date;
      selectionMode?: string;
      isChangeEvent?: boolean;
      validationRegex?: string;
      validationMessage?: string;
      suggestions?: any[];
      errorMessages?: any[];
      keyword?: any;
      options?: { key: string; value: string }[];
      maxlength?: number;
      hasPermissions?: boolean;
      permissions?: any;
    } = {},
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.disabled = !!options.disabled;
    this.isPDate = !!options.isPDate;
    this.minDate = options.minDate || null;
    this.maxDate = options.maxDate || null;
    this.selectionMode = options.selectionMode || 'single';
    this.isChangeEvent = !!options.isChangeEvent;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.validationRegex = options.validationRegex || '';
    this.validationMessage = options.validationMessage || '';
    this.suggestions = options.suggestions || [];
    this.errorMessages = options.errorMessages || [];
    this.keyword = options.keyword || '';
    this.options = options.options;
    this.maxLength = options.maxlength || 524288; // 524288 is default value length.
    this.hasPermissions = !!options.hasPermissions;
    this.permissions = options.permissions;
  }
}
