import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EmailTemplateService } from './email-temp.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../shared/constants/button-class';
import { ellipsisHeaderOptions, ellipsisRowOptions } from '../email-temp/email-temp-ellipsis';
import { RoleAuthorizationService } from '../../../../services/role-authorization.service';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { AdminConfigFieldService } from '../../services/admin-config.service';
import { FormField } from '../../shared/form-field/form-field';
import { Subscription, Subject } from 'rxjs';
import { EmailService } from '../../services/email.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { MessageService } from 'primeng/api';
import { copy } from '../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../common/role-permission.service';

@Component({
  selector: 'email-template',
  templateUrl: 'email-temp.template.html',
  styleUrls: ['email-temp.scss'],
  providers: [
    EmailTemplateService,
    RoleAuthorizationService,
    AdminConfigFieldService,
    EmailService,
    MessageService,
  ],
})
export class EmailTemplateComponent implements OnInit {
  cols: any[];
  dataList: any[];
  selectedItem: any;
  btnStatus: any = buttonStatus;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisRowOptions: any[] = ellipsisRowOptions;
  ellipsisHeaderOptionsSec: any;
  ellipsisRowOptionsSec: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayAdd: boolean;
  displayVerify: boolean;
  formModalInfo: FormField<any>[] = [];
  modalTempInfo: FormField<any>[] = [];
  modalImgInfo: FormField<any>[] = [];
  fileAttachInfo: FormField<any>[] = [];
  querySubscription: Subscription;
  isLoading = true;
  selectedItemTrigger: any;
  constructor(
    private emailTemplateService: EmailTemplateService,
    private cdr: ChangeDetectorRef,
    private adminService: AdminConfigFieldService,
    private emailService: EmailService,
    private azureService: AzureLoginService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {
    this.displayAdd = false;
    this.displayVerify = false;
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    const tableData = [];
    this.querySubscription = this.emailTemplateService.getTemplateDetails().subscribe(
      // Disable the Rule Shadowed name: 'data'
      /* tslint:disable */
      ({ data }) => {
        for (const singleTemplate of data.getEmailTemplateDetails) {
          tableData.push({
            template_name: singleTemplate.emailTemplateName,
            active: singleTemplate.emailTemplateIsActive,
            type: singleTemplate.emailTemplateType,
            message_code: singleTemplate.emailTemplateMessageCode,
            description: singleTemplate.emailTemplateDescription,
            variables: singleTemplate.emailTemplateVariables,
          });
        }
        this.dataList = tableData;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        throw err;
      },
    );

    this.cols = [
      { field: 'active', header: 'ACTIVE', type: 'checkbox' },
      { field: 'type', header: 'TYPE', type: 'text' },
      { field: 'message_code', header: 'MESSAGE CODE', type: 'text' },
      { field: 'description', header: 'DESCRIPTION', type: 'text' },
    ];
    this.adminService
      .getEmailTemplateFormFields()
      .subscribe((formModalInfo) => (this.formModalInfo = formModalInfo));
    this.adminService
      .getImagesFormFields()
      .subscribe((modalImgInfo) => (this.modalImgInfo = modalImgInfo));
    this.adminService
      .getModalTemplateFields()
      .subscribe((modalTempInfo) => (this.modalTempInfo = modalTempInfo));
    this.doEvaluateRolePermissions();
  }

  doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Message',
      category: 'Form Definition',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );
    this.ellipsisRowOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Message',
      category: 'Form Definition',
    };
    this.ellipsisRowOptions = this.rps.evaluate(
      this.ellipsisRowOptionsSec,
      this.ellipsisRowOptions,
    );
  }
  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    this.emailTemplateService.selectedItem = item;
    overlaypanel.toggle(event);
  }
  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  openTemp(rowData: any) {
    this.selectedItem = rowData;
    if (!(this.selectedItem.template_name in this.emailTemplateService.templateDetails)) {
      this.isLoading = true;
      this.emailTemplateService
        .retrieveTemplateFileDetails(this.selectedItem.template_name)
        .subscribe(
          (data) => {
            this.emailTemplateService.templateDetails[this.selectedItem.template_name] =
              data.Body[0];
            this.selectedItemTrigger = this.selectedItem;
            this.displayAdd = true;
            this.isLoading = false;
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
    } else {
      this.selectedItemTrigger = this.selectedItem;
      this.displayAdd = true;
    }
  }

  onRowEdit() {
    this.isEditing = true;
    document.getElementById(this.editConstant + this.selectedItem.id).click();
  }

  onRowEditInit(rowData: any) {
    this.clonedRowData[rowData.id] = { ...rowData };
  }
  onRowEditSave(rowData: any) {
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }

  onRowEditCancel(rowData: any, index: number) {
    this.dataList[index] = this.clonedRowData[rowData.id];
    delete this.clonedRowData[rowData.id];
    this.isEditing = false;
  }
  handleOpen(e) {
    if (e.value === 'Add New Email Template Row') {
      this.selectedItemTrigger = null;
      this.displayAdd = true;
    } else if (e.value === 'Update Row') {
      if (!(this.selectedItem.template_name in this.emailTemplateService.templateDetails)) {
        this.isLoading = true;
        this.emailTemplateService
          .retrieveTemplateFileDetails(this.selectedItem.template_name)
          .subscribe(
            (data) => {
              this.emailTemplateService.templateDetails[this.selectedItem.template_name] =
                data.Body[0];
              this.selectedItemTrigger = this.selectedItem;
              this.displayAdd = true;
              this.isLoading = false;
            },
            (err) => {
              this.isLoading = false;
              throw err;
            },
          );
      } else {
        this.selectedItemTrigger = this.selectedItem;
        this.displayAdd = true;
      }
    } else {
      this.displayVerify = true;
    }
    this.cdr.detectChanges();
  }
  onYes() {
    this.displayAdd = false;
    this.displayVerify = false;
    this.cdr.detectChanges();
  }
  sendEmail() {
    this.messageService.clear();
    if (this.selectedItem && this.selectedItem.template_name && this.azureService.emailAddress) {
      const variablesKeyValues = {};
      const variablesArray = this.selectedItem.variables.split(',');
      for (const variableName of variablesArray) {
        const variableValue = 'NOT_FOUND';
        variablesKeyValues[variableName] = variableValue;
      }
      const request = {
        operation: 'send_template',
        data: {
          from: 'donotreply@newleafsc.net',
          receivers: [
            {
              Email: this.azureService.emailAddress,
              FirstName: 'Test',
              LastName: 'Send',
            },
          ],
          variables: variablesKeyValues,
          template: this.selectedItem.template_name,
        },
      };
      this.isLoading = true;
      this.emailService.sendEmail(request).subscribe(
        (response) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Email sent successfully.',
          });
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Email send Failed.',
          });
        },
      );
    } else {
      if (!this.azureService.emailAddress) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Email send Failed. No email address found for user.',
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Email send Failed. No template selected.',
        });
      }
    }
  }
}
