import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { ServicerServiceFormService } from './servicer-service-form.service';
import { FormField } from '../../../shared/form-field/form-field';
import { OverlayPanel } from 'primeng/overlaypanel';
import { buttonStatus } from '../../../shared/constants/button-class';

@Component({
  selector: 'servicer-service-form-info',
  templateUrl: 'servicer-service-form.template.html',
  providers: [FormFieldControlService],
})
export class ServicerServiceFormComponent implements OnInit {
  @Input() formFields: FormField<string>[] = [];
  form: FormGroup;
  constructor(private qcs: FormFieldControlService) {}
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
  }
}
