import { Component, AfterContentInit, ContentChildren, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { TabHandlerService } from '../../../services/tab-handler.service';

@Component({
  selector: 'tabs',
  templateUrl: 'tabs.template.html',
  styleUrls: ['tabs.scss'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  constructor(private tabHandlerService: TabHandlerService) {}

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    } else {
      this.tabHandlerService.setSelectedTab(activeTabs[0].tabKey);
    }
  }

  selectTab(tabRight: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    tabRight.active = true;

    // set the active tab subject
    this.tabHandlerService.setSelectedTab(tabRight.tabKey);
  }
}
