export const ellipsisOptions = [
  { label: 'Print', value: 'print', callback: 'onClickPrint' },
  { label: 'Export', value: 'export', callback: 'onClickExport' },
  { label: 'Edit', value: 'edit', callback: 'onRowEdit' },
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' }
];
export const coverageEllipsisOptions = [{ label: 'Edit', value: 'edit', callback: 'onRowEdit' }];
export const coverageEllipsisHeaderOptions = [
  { label: 'Print', value: 'print', callback: 'onClickPrint' },
  { label: 'Export', value: 'export', callback: 'onClickExport' }
];
export const ellipsisHeaderOptions = [
  { label: 'Print', value: 'print', callback: 'onClickPrint' },
  { label: 'Export', value: 'export', callback: 'onClickExport' },
  { label: 'Add', value: 'add', callback: 'onClickAdd' }
];

export const ellipsisHeaderAddOptions = [{ label: 'Add', value: 'add', callback: 'onClickAdd' }];

export const ellipsisCrudOptions = [
  { label: 'Update', value: 'edit', callback: 'onRowEdit' },
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' }
];

export const ellipsisHeaderCrudOptions = [
  { label: 'Print', value: 'print', callback: 'onClickPrint' },
  { label: 'Export', value: 'export', callback: 'onClickExport' }
];

export const ellipsisSubHeaderOptions = [
  { label: 'Add New Row', value: 'add', callback: 'onClickAdd' }
];

export const ellipsisSubHeaderOptionsWithExport = [
  { label: 'Add New Row', value: 'add', callback: 'onClickAdd' },
  { label: 'Export', value: 'export', callback: 'onClickExport' }
];

export const ellipsisBatchProcessingMainOptions = [
  { label: 'Refresh', value: 'refresh', callback: 'onClickRefresh' },
  { label: 'Clear Filters', value: 'clear_filters', callback: 'onClickClearFilter' },
  { label: 'Export CSV', value: 'export', callback: 'onClickExport' }
];

export const ellipsisBatchProcessOptions = [
  { label: 'VOID FILE', value: 'voidFile', callback: 'onVoidFile' },
  {
    label: 'NLF TRANSACTION DATE OVERRIDE',
    value: 'nlfTransactionDateOverride',
    callback: 'onNlfTransactionDateOverride'
  },
  {
    label: 'CANCELLATION DATE OVERRIDE',
    value: 'cancellationDateOverride',
    callback: 'onCancellationDateOverride'
  }
];

export const ellipsisModificationOptions = [
  { label: 'Edit', value: 'edit', callback: 'onRowEdit' },
  { label: 'Delete', value: 'delete', callback: 'onRowDelete' }
];

export const ellipsisWorkQueueListOptions = [
  { label: 'Add Work Queue List', value: 'addList', callback: 'onClickAdd' },
  { label: 'Add Work Queue Type', value: 'addType', callback: 'onClickAddType' }
];

export const ellipsisOEMWarrantyOptions = [
  { label: 'Export', value: 'export', callback: 'onClickExport' }
];

export const ellipsisTransactionManagment = [
  { label: 'View History', value: 'view', callback: 'onClickView' }
];

export const ellipsisTransactionManagmentAddOptions = [
  { label: 'Add New Year', value: 'add', callback: 'onClickAdd' }
];
