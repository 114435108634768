// removed subscription cient
// remove websocketclient
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { AzureLoginService } from './azure-login.service';

@Injectable()
export class ApolloService {
  // private webSocketClient: SubscriptionClient;

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private msal: MsalService,
    private azureService: AzureLoginService,
  ) {
    const apolloClient = apollo.getClient();
    if (typeof apolloClient !== 'undefined') {
      console.log('clearing apollo cache');
      apolloClient.clearStore();
    }
  }

  async asyncTokenLookup() {
    const account = await this.msal.instance.getActiveAccount();
    const loginRequest = {
      scopes: [],
    };
    if (account) {
      try {
        const result = await this.msal.instance.acquireTokenSilent({
          ...loginRequest,
          account,
        });
        return result.idToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return this.msal.instance.acquireTokenRedirect(loginRequest);
        }
      }
    } else if (!account) {
      return this.msal.instance.acquireTokenRedirect(loginRequest);
    }
  }

  async createCRM() {
    const link = this.httpLink.create({ uri: environment.backendUrl });
    const headerLink = setContext((request, previousContext) => ({
      uri: environment.backendUrl,
      headers: {
        // Make sure you include any existing headers!
        ...previousContext['headers'],
        Authorization: `Bearer ${this.azureService.getAccessToken()}`,
      },
    }));

    this.apollo.createNamed('crm', {
      cache: new InMemoryCache(),
      link: headerLink.concat(link),
    });
  }
}
